import ChartHeader from "../../component/ChartHeader/chartHeader";
import CustomChart from "../../chart_types/CustomChart";
import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import modalStyle from "../../styles/modal";
import Chart, { ChartData } from "chart.js";
import Table from "../../Table/dataTable";
import getFAF from "../../service/functionalHealthFacilities";
import { ColorRing } from "react-loader-spinner";
import CustomChartProp from "../../models/CustomChart";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import Dataset from "../../models/Dataset";
import { TableColumn } from "react-data-table-component";

import { DataObject } from "@mui/icons-material";
import theme from "../../utils/theme";
import "./styles.css";
import getMentalHealthScreend from "../../service/mentalHealthScreened";
import Backdrop from "@mui/material/Backdrop";
import getSgbvMhpss from "../../service/SGBV&MHPSS";
import { getCurrentQuarter } from "../../utils/getCurrentQuarter";
import exportData from "../../utils/exporttocsv";
const SGBVMHPSS = () => {
  const [modalIsOpen, setModal] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [fhfData, setFhfData] = useState<ChartData>({
    // datasets: [
    //   {
    //     type: "line",
    //     label: "Referred to Mental Health",
    //     fill: false,
    //     barThickness: 2,
    //     borderColor:'#32cd32',
    //     pointStyle: "circle",
    //     // barThickness: 20,
    //     data: [
    //       2,12,22,24,19,4,12,71,2,9,2,12,22,32,14,4,12,34,12,4,2,12,22,32,14,9,12,71,2,4
    //     ],
    //   },
    //   {
    //     type: "line",
    //     label: "Referred to Medical Care",
    //     fill: false,
    //     barThickness: 2,
    //     borderColor: '#f4c430',
    //     pointStyle: "circle",
    //     // barThickness: 20,
    //     data: [
    //       2,12,33,24,19,5,7,81,2,9,2,12,22,22,11,33,22,18,15,7,9,19,28,35,19,11,90,71,2,4
    //     ],
    //   },
    //   {
    //     type: "line",
    //     label: "SGBV-Councelled",
    //     fill: false,
    //     borderColor: '#ED7014',
    //     pointStyle: "circle",
    //     barThickness: 2,
    //     data: [
    //       17,27,37,47,29,11,27,86,17,19,17,27,37,47,29,11,27,86,17,19,17,27,37,47,29,11,27,86,17,19
    //     ],
    //   },
    //   {
    //     type: "bar",
    //     label: "SGBV- case refered",
    //     fill: true,
    //     backgroundColor: "#98D9D9",
    //     pointStyle: "circle",
    //     // barThickness: 20,
    //     data: [
    //       7, 22, 30, 39, 24, 6, 22, 78, 12, 14, 12, 22, 32, 42, 24, 6, 22, 81,
    //       12, 14, 12, 22, 32, 42, 24, 6, 22, 81, 12, 14,
    //     ],
    //   },
    //   {
    //     type: "bar",
    //     label: "SGBV-Clinical",
    //     fill: true,
    //    backgroundColor: "#7172AD",
    //     pointStyle: "circle",
    //     // barThickness: 20,
    //     data: [
    //       2,12,22,32,14,4,12,71,2,4,2,12,22,32,14,4,12,71,2,4,2,12,22,32,14,9,12,71,2,4
    //     ],
    //   },
    //   {
    //     type: "bar",
    //     label: "gbvsurvivorsprovidedclinicalservice",
    //     fill: true,
    //     backgroundColor: theme.secondaryChartColor,
    //     pointStyle: "circle",
    //     // barThickness: 20,
    //     data: [
    //       2,12,22,24,19,4,12,71,2,9,2,12,22,32,14,4,12,34,12,4,2,12,22,32,14,9,12,71,2,4
    //     ],
    //   },
    // ],
    // labels: Array.from({ length: 31 }, (_, index) => '2023 - '+(index + 1).toString()),
  });
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<any[]>([]);
  const [rawData, setRawData] = useState<any>({});
  const [tableColumns, setTableColumns] = useState<TableColumn<any>[]>([]);
  const [error, setError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  //todo: chartType has to be initialized by the type from the api.
  const [chartType, setChartType] = useState("line");

  function closeModal() {
    setModal(false);
  }

  const expandChart = () => {
    setModal(true);
  };

  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      console.log("under ctx");

      chartRef.current = new Chart(ctx, {
        type: "line",
        data: fhfData,
        options: {
          responsive: true,
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Week",
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Screened",
                },
              },
            ],
          },
          plugins: {
            fill: true,
            legend: {
              display: false,
              labels: {
                color: "red",
                usePointStyle: true,
              },
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "week",
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  ticks: {},
                  scaleLabel: {
                    display: true,
                    labelString: "percentage",
                  },
                },
              ],
            },
            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  };

  async function fetchData() {
    setLoading(true);
    let datasets: any[] = [];
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    try {
      // Process data here, set state variables, etc.
      await getSgbvMhpss({
        year: (currentYear - 1).toString(),
        quarter: currentQuarter,
      }).then((res: any) => {
        setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any }) => {
          let dataObj = {
            type:
              element.label === "Councelled" ||
              element.label === "Referred to Medical Care" ||
              element.label === "Referred to Specialized Mental Health Care"
                ? "line"
                : "bar",
            label:
              element.label === "Councelled"
                ? "SGBV-Councelled"
                : element.label === "Referred to Medical Care"
                ? "Referred to Medical Care"
                : element.label === "Referred to Specialized Mental Health Care"
                ? "Referred to Mental Health Care"
                : element.label === "Clinical Service"
                ? "gbvsurvivorsprovidedclinicalservice"
                : "",
            fill:
              element.label === "Councelled" ||
              element.label === "Referred to Medical Care" ||
              element.label === "Referred to Specialized Mental Health Care"
                ? false
                : true,
            borderColor:
              element.label === "Referred to Specialized Mental Health Care"
                ? "#32cd32"
                : element.label === "Referred to Medical Care"
                ? "#f4c430"
                : element.label === "Clinical Service"
                ? "white"
                : element.label === "Councelled"
                ? "#ED7014"
                : "",
            backgroundColor:
              element.label === "Clinical Service"
                ? theme.secondaryChartColor
                : "",
            borderWidth: 2,
            pointStyle: "circle",
            pointRadius: 4,
            pointBorderColor:    element.label === "Referred to Specialized Mental Health Care"
            ? "#32cd32"
            : element.label === "Referred to Medical Care"
            ? "#f4c430"
            : element.label === "Clinical Service"
            ? "white"
            : element.label === "Councelled"
            ? "#ED7014"
            : "",
            data: element.data,
          };

          datasets.push(dataObj);
        });


        datasets.sort((a, b) => {
          if (a.type === "line") {
            return -1;
          } else if (b.type === "line") {
            return 1;
          } else {
            return 0;
          }
        });
        let dataObject = {
          labels: res.data.labels,
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets: datasets,
        };

        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    setError(false);

    fetchData();
  }, []);

  useEffect(() => {
    setError(false);

    fetchData();
  }, [tryAgain]);
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "white",
          zIndex: 10000,
          height: "100%",
          padding: "50px",
        }}
        open={modalIsOpen}
      >
        <div
          style={{
            height: "100%",
            width: "90%",
            alignSelf: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <FaTimesCircle
              size={20}
              color="#25476A"
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            />
          </div>
          {loading ? (
            <div className="loadingContainer">
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
              />
            </div>
          ) : (
            <div>
              {error ? (
                <div className="loadingContainer">
                  <p className="errorMessage">Error fetching data</p>
                  <p
                    className="tryAgain"
                    onClick={() => {
                      setTryAgain(!tryAgain);
                    }}
                  >
                    Try Again!
                  </p>
                </div>
              ) : (
                <div>
                  <ChartHeader
                    forExpanded={true}
                    title={"SGBV & MHPSS"}
                    onExpand={expandChart}
                    type={"bar"}
                    onChangeChartType={(newType) => {
                      setChartType(newType);
                    }}
                    onExport = {()=>exportData(rawData,"SGBV & MHPSS")}
                    showTypes={false}
                  />
                  <div>
                    <div style={{ flex: "1" }}>
                      <canvas
                        ref={canvasCallback}
                        style={{ width: "80%", height: "50vh" }}
                      ></canvas>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Backdrop>
      <>
        {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  title={"SGBV & MHPSS"}
                  onExpand={expandChart}
                  type={"line"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  onExport = {()=>exportData(rawData,"SGBV & MHPSS")}
                  showTypes={false}
                />
                <div>
                  <div className="self-center w-full">
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "80%", height: "40vh" }}
                    ></canvas>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default SGBVMHPSS;

import ChartHeader from "../../component/ChartHeader/chartHeader";
import CustomChart from "../../chart_types/CustomChart";
import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import modalStyle from "../../styles/modal";
import Chart, { ChartData } from "chart.js";
import Table from "../../Table/dataTable";
import getFAF from "../../service/functionalHealthFacilities";
import { ColorRing } from "react-loader-spinner";
import CustomChartProp from "../../models/CustomChart";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import Dataset from "../../models/Dataset";
import HeatMap from "../../component/HeatMap";
import { TableColumn } from "react-data-table-component";

import { DataObject } from "@mui/icons-material";
import theme from "../../utils/theme";
import "./styles.css";
import MAMByRegion from "../MMAMbyRegion";
import getChronicIllness from "../../service/chronicIllness";
import { Backdrop } from "@mui/material";
import exportData from "../../utils/exporttocsv";

interface Region {
  name: string;
  key: string;
}

interface componentprops {
  regions: Region[];
}
const PatientsLinked = (props: componentprops) => {
  const [modalIsOpen, setModal] = useState(false);
  const [rawData,setRawData] = useState<any>({});
  const [displayTable, setDisplayTable] = useState(false);
  const [chronicIllnessData, setchronicIllnessData] = useState<ChartData>({});
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumn<any>[]>([]);
  const [error, setError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  //todo: chartType has to be initialized by the type from the api.
  const [chartType, setChartType] = useState("line");

  function closeModal() {
    setModal(false);
  }

  const expandChart = () => {
    setModal(true);
  };

  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      console.log("under ctx");

      chartRef.current = new Chart(ctx, {
        type: "bar",
        data: chronicIllnessData,

        options: {
          responsive: true,

          scales: {
            xAxes: [
              {
                stacked: false,
                scaleLabel: {
                  display: true,
                  labelString: "Week",
                },
              },
            ],
            yAxes: [
              {
                stacked: false,
              },
            ],
          },

          plugins: {
            fill: true,
            legend: {
              display: false,
              labels: {
                color: "red",
                usePointStyle: true,
              },
            },

            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  };

  async function fetchData() {
    setLoading(true);
    let datasets: any[] = [];

    try {
      // Process data here, set state variables, etc.
      await getChronicIllness().then((res: any) => {
        console.log("chronic illness: ", res.data.datasets);
        setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any }) => {
          let dataObj = {
            label: element.label,
            type:
              element.label === "HIV" || element.label === "TB"
                ? "line"
                : "bar",
            fill:
              element.label === "HIV" || element.label === "TB" ? false : true,
            backgroundColor:
              element.label === "Dm Screened"
                ? theme.greenChartColor
                : theme.secondaryChartColor,
            borderWidth: 1,
            pointStyle: "circle",
            borderColor:
              element.label === "HIV"
                ? theme.primaryChartColor
                : element.label === "TB"
                ? "#ED7014"
                : "transparent",
            pointRadius: 2,
            pointBorderColor:  element.label === "HIV"
            ? theme.primaryChartColor
            : element.label === "TB"
            ? "#ED7014"
            : "transparent",
            data: element.data,
          };

          datasets.push(dataObj);
        });

        let dataObject = {
          labels: res.data.labels,
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets: datasets,
        };

        // console.log("filterdfhf, ", dataObject);
        setchronicIllnessData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    setError(false);
    console.log("selected regions: ", props.regions);

    fetchData();
  }, []);

  useEffect(() => {
    setError(false);

    fetchData();
  }, [tryAgain]);
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "white",
          zIndex: 10000,
          height: "100%",
          padding: "50px",
        }}
        open={modalIsOpen}
      >
        <div
          style={{
            height: "100%",
            width: "90%",
            alignSelf: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <FaTimesCircle
              size={20}
              color="#25476A"
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            />
          </div>
          {loading ? (
            <div className="loadingContainer">
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
              />
            </div>
          ) : (
            <div>
              {error ? (
                <div className="loadingContainer">
                  <p className="errorMessage">Error fetching data</p>
                  <p
                    className="tryAgain"
                    onClick={() => {
                      setTryAgain(!tryAgain);
                    }}
                  >
                    Try Again!
                  </p>
                </div>
              ) : (
                <div>
                  <ChartHeader
                    forExpanded={true}
                    title={"Screened for Chronic Illness"}
                    onExpand={expandChart}
                    type={"bar"}
                    onChangeChartType={(newType) => {
                      setChartType(newType);
                    }}
                    onExport = {()=>exportData(rawData,'Screened for Chronic Illness')}
                    showTypes={false}
                  />
                  <div>
                    <div style={{ flex: "1" }}>
                      <canvas
                        ref={canvasCallback}
                        style={{ width: "80%", height: "50vh" }}
                      ></canvas>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Backdrop>
      {loading ? (
        <div className="loadingContainer">
          <ColorRing
            visible={true}
            height="50"
            width="50"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
          />
        </div>
      ) : (
        <div>
          {error ? (
            <div className="loadingContainer">
              <p className="errorMessage">Error fetching data</p>
              <p
                className="tryAgain"
                onClick={() => {
                  setTryAgain(!tryAgain);
                }}
              >
                Try Again!
              </p>
            </div>
          ) : (
            <div>
              {/* <div className="firstCol"> */}
              <ChartHeader
                title={"Screened for Chronic Illness"}
                onExpand={expandChart}
                type={"bar"}
                onChangeChartType={(newType) => {
                  setChartType(newType);
                }}
                showTypes={false}
                onExport = {()=>exportData(rawData,'Screened for Chronic Illness')}
              />
              <div>
                <div className="self-center w-full">
                  <canvas
                    ref={canvasCallback}
                    style={{ width: "80%", height: "30vh" }}
                  ></canvas>
                </div>
                {/* <div className="secondCol">
                      <div>
                        <HeatMap
                          regions={props.regions.map((region: Region) => {
                            return region.name;
                          })}
                        />
                      </div>
                    </div> */}
              </div>
              {/* <div className="secondRow">
                    <div>
                      <p className="charttitle">Number of DM Screened</p>
                      <div style={{ borderColor: theme.secondaryChartColor }}>
                        <h1
                          className="number
                        "
                        >
                          2000
                        </h1>
                      </div>
                    </div>
                    <div>
                      <p className="charttitle">
                        Number of DM Linked for Treatment
                      </p>
                      <div style={{ borderColor: theme.greenChartColor }}>
                        <h1
                          className="number
                        "
                        >
                          2000
                        </h1>
                      </div>
                    </div>
                    <div>
                      <p className="charttitle">Number of HTN Screened</p>
                      <div style={{ borderColor: theme.secondaryChartColor }}>
                        <h1
                          className="number
                        "
                        >
                          2000
                        </h1>
                      </div>
                    </div>
                    <div>
                      <p className="charttitle">
                        Number of HTN linked for treatment
                      </p>
                      <div style={{ borderColor: theme.greenChartColor }}>
                        <h1
                          className="number
                        "
                        >
                          2000
                        </h1>
                      </div>
                    </div>
                  </div> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PatientsLinked;

type GeoJSONTypes = "Point" | "MultiPoint" | "LineString" | "MultiLineString" | "Polygon" | "MultiPolygon" | "GeometryCollection" | "Feature" | "FeatureCollection";



interface GeoJsonGeometry {
    type: GeoJSONTypes;
    coordinates: any; // You can use a specific type for coordinates if known
  }
  
 export interface GeoJsonFeature {
    type: GeoJSONTypes;
    properties: {
      OBJECTID: number;
      REGIONNAME: string;
      latitude:number;
      longtiude:number;
      data?: number;
      RID: string;
      GlobalID: string;
      Shape__Area: number;
      Shape__Length: number;
    }
    geometry: GeoJsonGeometry;
  }
  
  interface GeoJsonObject {
    type: GeoJSONTypes;
    name: string;
    crs: {
      type: string;
      properties: {
        name: string;
      };
    };
    features: GeoJsonFeature[];
  }

const ethiopianRegions:GeoJsonObject = {

  type: "FeatureCollection",
  name: "Ethiopia_AdminBoundaries",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID: 1,
        REGIONNAME: "Addis Ababa",
        data: 300,
        latitude:9.010243, 
        longtiude:38.745584,
        RID: "14",
        GlobalID: "3bd5d8df-3b55-4bfd-97bd-9f8f870158b7",
        Shape__Area: 556495861.58691394,
        Shape__Length: 120010.42246676399,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [38.701832718591, 8.925360563393079],
              [38.701827155324402, 8.925379463156339],
              [38.701826968474798, 8.92538507087577],
              [38.701832718591, 8.925360563393079],
            ],
          ],
          [
            [
              [38.701917455773398, 8.92569941274065],
              [38.7019149503721, 8.92569773637211],
              [38.7018985839659, 8.925689130007751],
              [38.701923446637998, 8.925705286686251],
              [38.701917455773398, 8.92569941274065],
            ],
          ],
          [
            [
              [38.701832718591, 8.925360563393079],
              [38.701802654673301, 8.925531490094039],
              [38.701806369206999, 8.92555021591077],
              [38.701809627396599, 8.925570660692619],
              [38.7018151610187, 8.925588664133389],
              [38.701823969000102, 8.925607755571299],
              [38.701917455773398, 8.92569941274065],
              [38.702020385636999, 8.925768277846959],
              [38.701923446637998, 8.925705286686251],
              [38.701957941944897, 8.925739105134131],
              [38.7019889284323, 8.92576340049399],
              [38.702054051798903, 8.925780556423049],
              [38.702089797560703, 8.925785611263169],
              [38.702183021127603, 8.9259155710364],
              [38.702177364436203, 8.925936370772391],
              [38.702168162094502, 8.9259546351014],
              [38.702162518877799, 8.925959334077071],
              [38.702145727568599, 8.92598970214903],
              [38.699491136733698, 8.92946756978408],
              [38.697307041223901, 8.930909485443349],
              [38.696786307616598, 8.931654463219591],
              [38.6957503668377, 8.93471182867027],
              [38.694594417425002, 8.941090956174889],
              [38.694006179915696, 8.942149462564821],
              [38.692864979043399, 8.942412824141829],
              [38.688997572743503, 8.940573680849861],
              [38.688730837782799, 8.940603850701249],
              [38.688727401726801, 8.94057915522537],
              [38.688726343511398, 8.94053890361053],
              [38.688710181022799, 8.940504428971609],
              [38.6886596552797, 8.940444598618029],
              [38.688638834128, 8.94041389631268],
              [38.688055683778202, 8.939967968064019],
              [38.6880326725339, 8.9399653271548],
              [38.687988100824398, 8.93996293826782],
              [38.687944623263, 8.939959102915079],
              [38.687920711906699, 8.939944429717681],
              [38.687898539688902, 8.93991736660748],
              [38.687877008868199, 8.939883520190699],
              [38.687850839147302, 8.93985030382659],
              [38.687832572804297, 8.93982966639182],
              [38.687786901556997, 8.939773687255411],
              [38.687747969470898, 8.939728292164499],
              [38.687745887176, 8.93971707450471],
              [38.687744178580402, 8.93969346514992],
              [38.6877448352488, 8.93966985845581],
              [38.687746578878802, 8.939651680004671],
              [38.687750141597199, 8.939633140379611],
              [38.687753707908897, 8.939611797447011],
              [38.687761366046701, 8.93959136232667],
              [38.6877607336327, 8.93958593496864],
              [38.687759198411896, 8.939572636343829],
              [38.6877547589378, 8.93955191991445],
              [38.687725424452204, 8.93949617855945],
              [38.687718726613397, 8.939484472820579],
              [38.687660849057998, 8.9393583922897],
              [38.6876410591723, 8.93932805195359],
              [38.687638969690902, 8.939324794294061],
              [38.687628613013999, 8.939309680918759],
              [38.687607331026598, 8.93930405477807],
              [38.6875872357137, 8.939295626215721],
              [38.687555141603603, 8.939282581377819],
              [38.687542184303901, 8.939281993916399],
              [38.687528302637801, 8.939281105625049],
              [38.687512660273804, 8.939276659731179],
              [38.687457918737003, 8.939253459441099],
              [38.687538314361703, 8.939280683220749],
              [38.687418553662901, 8.939226382081101],
              [38.687391650916801, 8.939199767944039],
              [38.687330746937199, 8.93916428065015],
              [38.687262801962298, 8.9391428578237],
              [38.682591603807403, 8.938900503193331],
              [38.6813524982472, 8.939728332097649],
              [38.679923566091098, 8.941686708711661],
              [38.678610845389997, 8.9439911491512],
              [38.680491193552797, 8.94684608809918],
              [38.680427061027999, 8.94842573915618],
              [38.680387397713297, 8.951165156801951],
              [38.679318362999297, 8.95586337679854],
              [38.678292051261899, 8.95795396374942],
              [38.676483391353699, 8.95775341270531],
              [38.674377610970403, 8.95799298444639],
              [38.668856704473001, 8.960393559412751],
              [38.668836055797897, 8.96039209350648],
              [38.668816953123397, 8.960391443964459],
              [38.668797760617302, 8.960388622183631],
              [38.668778480076398, 8.96038299814359],
              [38.668759379198498, 8.960379905720171],
              [38.668738627217103, 8.960392097943251],
              [38.668737443237603, 8.96039354432776],
              [38.668717002073301, 8.96042336203131],
              [38.6687040061461, 8.96045703439084],
              [38.668696672300101, 8.960492274698661],
              [38.6686683798602, 8.960514141718759],
              [38.668667014420997, 8.960514864910779],
              [38.668642174206802, 8.96052090334222],
              [38.668623068837299, 8.960523509495481],
              [38.668604870766302, 8.960526930237981],
              [38.668586040281298, 8.960527908987389],
              [38.668567581698802, 8.96051758109575],
              [38.668551218885902, 8.96050481298531],
              [38.668539043120603, 8.960489335788081],
              [38.668531146030901, 8.96046924879515],
              [38.668524161629598, 8.96044681031743],
              [38.668514078938799, 8.96043097285267],
              [38.668505899778197, 8.96042110726888],
              [38.668476521275103, 8.96040038137841],
              [38.668443480340599, 8.96038560163578],
              [38.668407824410401, 8.96037859155112],
              [38.668371669915203, 8.960374496419311],
              [38.668362664304397, 8.96037367473089],
              [38.668336738925298, 8.96037319910995],
              [38.668312359546803, 8.960372725263699],
              [38.668286523100903, 8.96037333487488],
              [38.668261052269401, 8.9603737643535],
              [38.668226209314497, 8.96037382469347],
              [38.668211836269897, 8.96037399329045],
              [38.668191911636903, 8.960376055497671],
              [38.668166253955803, 8.96038100692461],
              [38.668144236248096, 8.960385239595981],
              [38.668122762919602, 8.96038901883017],
              [38.668100657177099, 8.96039053709025],
              [38.668082096186701, 8.96039260018474],
              [38.668046032421302, 8.960396990804711],
              [38.668009672211902, 8.960397633247871],
              [38.667973321883899, 8.96039616645427],
              [38.667937090133499, 8.96039238189603],
              [38.6679278132316, 8.96039146881035],
              [38.667905342773103, 8.960392172480841],
              [38.667887325263401, 8.96039857827823],
              [38.6678653075558, 8.960401271392911],
              [38.667838923137602, 8.9604046859251],
              [38.6678127237723, 8.960404753363891],
              [38.667793166550297, 8.960404011537239],
              [38.667785980028, 8.96040373379593],
              [38.667767516055697, 8.96039937689544],
              [38.667747049738502, 8.96039781959189],
              [38.667723405181903, 8.960389838743859],
              [38.667700397530901, 8.960381678650441],
              [38.667681842828699, 8.960377320862341],
              [38.667658285408699, 8.960373229279931],
              [38.667652279272701, 8.96037566594992],
              [38.667623571811198, 8.96039682043304],
              [38.667597272732898, 8.960421785206099],
              [38.667577460389303, 8.96045203593526],
              [38.667552755820701, 8.96047832818353],
              [38.667547475523399, 8.960484202455399],
              [38.667540180304997, 8.96050400460746],
              [38.667530156903098, 8.960522085295279],
              [38.667520041872997, 8.96054070992904],
              [38.667506197037802, 8.960559873184531],
              [38.667496354197297, 8.960581028544279],
              [38.667482693516703, 8.96059802133475],
              [38.667464858365101, 8.96060361342677],
              [38.667442932285603, 8.9606058548778],
              [38.6674240092742, 8.960607194779209],
              [38.6674030830196, 8.96061097489847],
              [38.667382799958801, 8.96060616367626],
              [38.667364889348697, 8.96059511259501],
              [38.6673422437187, 8.960588308380039],
              [38.667337877008102, 8.96058848585044],
              [38.6673026271163, 8.96059557224333],
              [38.667269311297403, 8.96060976277573],
              [38.667244149486301, 8.96063546137418],
              [38.667226458963398, 8.960666625170539],
              [38.667223986799698, 8.96068516549847],
              [38.667223057941698, 8.96070723393583],
              [38.6672176716433, 8.96072730938004],
              [38.667216107676403, 8.96074729697532],
              [38.6672158175205, 8.96076565805412],
              [38.667203342616197, 8.960779304633389],
              [38.667185147240097, 8.960780826441249],
              [38.667164498565, 8.96077927091404],
              [38.667143942416303, 8.960775090601009],
              [38.667125660802, 8.96077172842605],
              [38.6671059293068, 8.96076275641453],
              [38.667086200506503, 8.960749443478839],
              [38.667068103945098, 8.96074146707528],
              [38.6670452795504, 8.9607319484544],
              [38.667042460636999, 8.960730137369721],
              [38.667011892764499, 8.960710547307039],
              [38.6669798300954, 8.960693452476381],
              [38.666949173289701, 8.960674208478901],
              [38.6669158026735, 8.9606598360419],
              [38.666904981567598, 8.96065521293894],
              [38.666885612093502, 8.96064832176461],
              [38.666866243517603, 8.96063944025995],
              [38.6668437838389, 8.96062793308082],
              [38.666820230910403, 8.96061886789381],
              [38.666801410306903, 8.96060808745527],
              [38.666783769191397, 8.960599478366669],
              [38.666765307913998, 8.960593221647949],
              [38.666747299387502, 8.960589857696609],
              [38.666721743216002, 8.96058350425635],
              [38.666700370498702, 8.960576158756441],
              [38.666675364994497, 8.96056410674116],
              [38.666667816451103, 8.960561476629699],
              [38.666633312161103, 8.960550026238639],
              [38.666599045026203, 8.960537861528771],
              [38.666564915333701, 8.96052534720177],
              [38.666531133289098, 8.960511878083381],
              [38.666527950557999, 8.960510519547199],
              [38.666497041325698, 8.96048842181529],
              [38.666474403780597, 8.96047383906844],
              [38.666460225670399, 8.960458179075321],
              [38.666445045040497, 8.960445684267309],
              [38.666428597785902, 8.960426313365341],
              [38.666412597892403, 8.960413907291469],
              [38.666391680620997, 8.96040728498037],
              [38.666372034465702, 8.96040283668262],
              [38.666344928700397, 8.960400007803029],
              [38.666325551141398, 8.960400984778079],
              [38.666299528744197, 8.960407293853899],
              [38.666279875402402, 8.96041044129295],
              [38.6662443111003, 8.960419755831181],
              [38.666209649605101, 8.960430723506651],
              [38.666175230655, 8.960442091377701],
              [38.666145556606203, 8.96046269126176],
              [38.666121704538803, 8.96048311278685],
              [38.666102130248703, 8.96050027950294],
              [38.666087378115101, 8.960517090390001],
              [38.666070896724598, 8.96053399001149],
              [38.666052778603699, 8.96055115938724],
              [38.666040396225803, 8.960562453604309],
              [38.666033295043498, 8.960568959669461],
              [38.666018000327398, 8.960581518362581],
              [38.6659989802979, 8.96058891887821],
              [38.665975872933899, 8.960590255230301],
              [38.665957222111899, 8.960593313932559],
              [38.665942382841799, 8.96060569160503],
              [38.665931359614902, 8.9606227775663],
              [38.6659228894001, 8.96063362278109],
              [38.665898169560101, 8.96066010313479],
              [38.665871042235203, 8.960684215144751],
              [38.665844475458897, 8.96070887376176],
              [38.665814548983597, 8.96072940264256],
              [38.665792248306602, 8.960743764428599],
              [38.665769679033403, 8.96075369389294],
              [38.665749929571902, 8.9607630829599],
              [38.665730183703701, 8.960768763784539],
              [38.665712530011703, 8.960775169575291],
              [38.665693874698199, 8.960781483968731],
              [38.6656805931067, 8.960782286134499],
              [38.665668492799803, 8.96078299956511],
              [38.665647932159601, 8.96078515227987],
              [38.6656243675531, 8.960788748715499],
              [38.665602989446, 8.960789453272589],
              [38.665583521157103, 8.9607893450157],
              [38.6655636908472, 8.96078860407721],
              [38.665539767812902, 8.960786954490761],
              [38.665535491832102, 8.96078686043152],
              [38.665499169351897, 8.96078550988252],
              [38.665462878312802, 8.960782964071051],
              [38.665426696868103, 8.96077919637651],
              [38.665390311505803, 8.96077795585909],
              [38.665383216611701, 8.96077786002512],
              [38.6653576532537, 8.960777384404709],
              [38.665331637144703, 8.96077609508294],
              [38.6653068966435, 8.960773630907729],
              [38.66528624707, 8.96077306921422],
              [38.665262867516503, 8.96077268587835],
              [38.665244947024803, 8.96077239926381],
              [38.665239488861197, 8.960772304317191],
              [38.665218654234799, 8.960774727674229],
              [38.665196452372498, 8.960779681757799],
              [38.665174252306898, 8.960783551497739],
              [38.6651505044441, 8.960789047752961],
              [38.6651263900686, 8.960797619568311],
              [38.665106102516198, 8.960799499866081],
              [38.665102644002403, 8.96079967733638],
              [38.665066847036599, 8.96080477871994],
              [38.665032706564197, 8.9608170578892],
              [38.6649967559866, 8.960821185848021],
              [38.664960623949199, 8.960818273560649],
              [38.6649418877873, 8.96081445794951],
              [38.6649216900665, 8.960817514875179],
              [38.664903034753003, 8.960823648248191],
              [38.664883011305299, 8.96083439407362],
              [38.664861260397302, 8.96084468646219],
              [38.664843606705404, 8.9608510922516],
              [38.664824133026599, 8.960858219457529],
              [38.6648148489382, 8.960863819531721],
              [38.6647879605651, 8.960887524233931],
              [38.6647821610416, 8.960896443001291],
              [38.664771798974797, 8.96091963037563],
              [38.664749059021702, 8.96094673629103],
              [38.664722143699201, 8.960970919270039],
              [38.664718227942799, 8.960975076509699],
              [38.664700184381999, 8.96101096808286],
              [38.664681139199701, 8.96104595544193],
              [38.664670476197301, 8.9610678339643],
              [38.664660089876001, 8.96108519232053],
              [38.664652800047399, 8.96109784860437],
              [38.664648427048597, 8.96110553306171],
              [38.664638492579897, 8.961125784179361],
              [38.664630741915602, 8.961145585409099],
              [38.664618081958302, 8.961162488550761],
              [38.664606238569597, 8.961181200999389],
              [38.6646104974824, 8.961199204454021],
              [38.664618849119599, 8.96121974395534],
              [38.664614385390898, 8.96122679484189],
              [38.664586931079199, 8.96124921108769],
              [38.664553716769902, 8.96126346282173],
              [38.664525496195303, 8.961247648463671],
              [38.664507413108602, 8.96121647761834],
              [38.664498689568902, 8.96120624912953],
              [38.664483238545998, 8.96119113045178],
              [38.664467693200002, 8.96118008116302],
              [38.664450598260103, 8.96117102042653],
              [38.6644342363455, 8.96115780067759],
              [38.664419328803397, 8.96114367760525],
              [38.664400503708301, 8.96113778116027],
              [38.664383323428503, 8.9611241984837],
              [38.664360772121597, 8.96111377566249],
              [38.664343114836399, 8.96112325789198],
              [38.664323002455497, 8.961131922871269],
              [38.664300896713002, 8.9611327170501],
              [38.664281698817, 8.961136588561001],
              [38.664260131165399, 8.96114525353997],
              [38.664258310280303, 8.96114669903413],
              [38.664229963043198, 8.961169334460459],
              [38.6642062277568, 8.961196584995349],
              [38.664183943249498, 8.96122517542736],
              [38.664158602673702, 8.961251078072859],
              [38.664154687815703, 8.961254782760619],
              [38.664116725910098, 8.96128441671199],
              [38.664097428301098, 8.961298056172989],
              [38.6640846794106, 8.96131197426144],
              [38.664091211959402, 8.961331156110621],
              [38.664076370892602, 8.96134452847747],
              [38.664064619131999, 8.961363421936129],
              [38.664055324263799, 8.96138078027824],
              [38.6640473903432, 8.961401938252241],
              [38.664038367664503, 8.9614213765408],
              [38.664005410273298, 8.961449114211881],
              [38.663933655543403, 8.96151360235897],
              [38.663911752820098, 8.961542408392001],
              [38.663881679919299, 8.961561794319429],
              [38.663866756207497, 8.961566754603711],
              [38.663849647792901, 8.96157307164543],
              [38.663830537033597, 8.961581828010999],
              [38.663809967410202, 8.961592029868729],
              [38.663789677162903, 8.96159590226209],
              [38.663772204032298, 8.961604297475841],
              [38.663758180432403, 8.96161920938397],
              [38.663753627770497, 8.961624360447599],
              [38.6637457055281, 8.961633308479399],
              [38.663728500993699, 8.96164505077428],
              [38.663710210396196, 8.96165181770078],
              [38.663692195581497, 8.961654243713999],
              [38.663669179845598, 8.9616546749658],
              [38.663646341077801, 8.961660082472889],
              [38.663626510767898, 8.961659069119911],
              [38.663617868076599, 8.961659423172311],
              [38.6635829137306, 8.961668110322529],
              [38.663552260518102, 8.96168698069331],
              [38.663522848777397, 8.96170824779343],
              [38.663490813057798, 8.961725156233021],
              [38.663481076218403, 8.96172939776222],
              [38.663460784174397, 8.96173634393168],
              [38.663440311569097, 8.961739581868841],
              [38.663424289217701, 8.96175295422065],
              [38.663405540479403, 8.961762434658761],
              [38.663381789921601, 8.961771186583009],
              [38.663357311728397, 8.96177812920228],
              [38.663337653895098, 8.961781128442169],
              [38.663301874895602, 8.961787677965599],
              [38.663265756332997, 8.961791746461021],
              [38.663229665618097, 8.96179492938203],
              [38.663216563689701, 8.96179735805635],
              [38.663189080631902, 8.96180827866074],
              [38.663164503624103, 8.961825713293599],
              [38.663151016318402, 8.961850936187041],
              [38.663142891954998, 8.961880686332471],
              [38.6631425173575, 8.961893349687401],
              [38.663142086166097, 8.96190710625558],
              [38.663142926989302, 8.96194327813885],
              [38.663144631991699, 8.96196914790125],
              [38.663149789219702, 8.961999453517191],
              [38.663154673359898, 8.962029396205089],
              [38.663159468566903, 8.96205906824926],
              [38.663162627043398, 8.962086568062301],
              [38.663162898334598, 8.962088648894129],
              [38.663163418459199, 8.96211659770314],
              [38.663159118223902, 8.962144633470141],
              [38.663137812880301, 8.962163246485209],
              [38.663107602537302, 8.96217253524541],
              [38.663081036659399, 8.96217603938311],
              [38.663044785146099, 8.96217916728518],
              [38.663008420445102, 8.96218045039053],
              [38.662972046760999, 8.96218143268487],
              [38.662935659602098, 8.96218214877479],
              [38.662930838343897, 8.962182234847541],
              [38.6629014571459, 8.96218039981169],
              [38.662871711231901, 8.96217820185044],
              [38.662840237857601, 8.962176455549359],
              [38.662807219381001, 8.962172264604231],
              [38.662778931432797, 8.962168078095781],
              [38.662751555274497, 8.96216172290845],
              [38.662743187467598, 8.962160539186019],
              [38.6627070365656, 8.96215716992509],
              [38.662667015721397, 8.962156724476319],
              [38.662634267637699, 8.962156520386239],
              [38.662598000853102, 8.962158146008059],
              [38.662578439139402, 8.962160841771739],
              [38.662547324193, 8.962164883642521],
              [38.6625169332886, 8.96217136926998],
              [38.662485450931101, 8.962178666820851],
              [38.662457553750002, 8.96218465908253],
              [38.662426580737304, 8.962192182019541],
              [38.662398281110903, 8.96220165268536],
              [38.662371429568701, 8.96221736673157],
              [38.662348306035, 8.962236883950469],
              [38.662333278118602, 8.96225658751138],
              [38.662309609307499, 8.96228368445356],
              [38.6622734224729, 8.962284844217191],
              [38.662238073766503, 8.962276819930549],
              [38.662206098234002, 8.96226009076093],
              [38.662203188590802, 8.96225827879649],
              [38.662181461937301, 8.962240802479039],
              [38.662159100174897, 8.96222278221642],
              [38.662138557501002, 8.96220385951994],
              [38.662118925718801, 8.962184938597179],
              [38.6620952003138, 8.96216655629375],
              [38.662077168431097, 8.96215246076841],
              [38.6620462942331, 8.962127166020689],
              [38.662026846605499, 8.96210399292098],
              [38.662012315457503, 8.96207874431202],
              [38.662001695574197, 8.962052776061739],
              [38.661978607973097, 8.9620322232973],
              [38.661952778713697, 8.96202541555984],
              [38.661949049806999, 8.96202441729282],
              [38.661919297604797, 8.962028731581089],
              [38.6618957231168, 8.962043363957029],
              [38.661877418146197, 8.962065868887279],
              [38.661861657204597, 8.962090276290949],
              [38.661849174215398, 8.96211242539164],
              [38.657784455658202, 8.962195281526331],
              [38.656716103663904, 8.961897988746889],
              [38.656584371117297, 8.961941692446761],
              [38.656535754294197, 8.96194729871568],
              [38.656497557030001, 8.96193722375877],
              [38.656459730770003, 8.96191774201772],
              [38.656426370035298, 8.96189075774155],
              [38.656402093963003, 8.96187743863574],
              [38.6563625429377, 8.9618544287923],
              [38.656334988014699, 8.96184436359365],
              [38.656198198851399, 8.96181267015095],
              [38.656191101262401, 8.96181528694313],
              [38.656176712946497, 8.961830378087081],
              [38.656145213521, 8.961855404876079],
              [38.656100716371697, 8.961870106472221],
              [38.656076957729098, 8.961886908422411],
              [38.656050103491999, 8.961906058296689],
              [38.6560073490744, 8.961925915386869],
              [38.655936836714503, 8.961937044514331],
              [38.655893236982202, 8.96194182288702],
              [38.655852462451399, 8.961963945376301],
              [38.655815773458599, 8.96199403181758],
              [38.655780271140202, 8.962019053271209],
              [38.655368894842397, 8.962224355484411],
              [38.655033498051303, 8.962309429963041],
              [38.654993726938699, 8.96232811661951],
              [38.654949060906098, 8.96232762236682],
              [38.654930590645598, 8.962330681054469],
              [38.654895206904897, 8.962338957346651],
              [38.654859578822403, 8.96234593633633],
              [38.654823224002797, 8.96234711384672],
              [38.654786843132101, 8.96234628683867],
              [38.654777019156199, 8.96234582541787],
              [38.654758191366099, 8.962342551104859],
              [38.654733906310803, 8.96233827763831],
              [38.654711074729498, 8.9623358152484],
              [38.654692701487001, 8.96233317450158],
              [38.654670690965901, 8.962328721790669],
              [38.654649952459302, 8.962325989646979],
              [38.654643039024798, 8.962324987831391],
              [38.654623847417099, 8.96232207644341],
              [38.654603109808797, 8.96231852971443],
              [38.654582735119803, 8.962314169287509],
              [38.654564636761798, 8.96230944859734],
              [38.654541081138397, 8.962303999394569],
              [38.654522435706397, 8.96230090698748],
              [38.654500333557102, 8.96229699822039],
              [38.654464973172601, 8.96228842466572],
              [38.654429369344598, 8.9622810055504],
              [38.6543937062278, 8.962273837554379],
              [38.654358450047901, 8.96226529949308],
              [38.654356902250598, 8.962264846058311],
              [38.654335616669997, 8.96226355940386],
              [38.654309416406399, 8.96226498359723],
              [38.654290126882302, 8.96226957739723],
              [38.654267738170503, 8.96227932491357],
              [38.654249076568803, 8.9622929661119],
              [38.654235505719797, 8.96231004844444],
              [38.6542269402835, 8.96232595947715],
              [38.654222101957401, 8.962343683360521],
              [38.654212716359297, 8.962360859749539],
              [38.654205149849702, 8.962378490459709],
              [38.654202219545198, 8.962398658095729],
              [38.654202200680601, 8.962418104317081],
              [38.654198268754598, 8.962440080365029],
              [38.6541888885464, 8.962451197941229],
              [38.654164684339399, 8.96247818128843],
              [38.654134979747901, 8.962497928316269],
              [38.654099517853702, 8.96250600406416],
              [38.654065379178, 8.96251783417822],
              [38.654052095789901, 8.96251999220677],
              [38.654028714439697, 8.96252159919268],
              [38.654009699800099, 8.962523751897139],
              [38.653991413694101, 8.96252563484801],
              [38.653968027852301, 8.962531130189509],
              [38.653947735808401, 8.96253825736248],
              [38.6539235298048, 8.96254655672098],
              [38.653905874316202, 8.962554137328061],
              [38.653888854834797, 8.96256181021908],
              [38.653870650475497, 8.962572918030739],
              [38.653851719379198, 8.962583302654171],
              [38.653830060997699, 8.96259088059845],
              [38.653829424092201, 8.96259124174871],
              [38.653798840050101, 8.962610746524669],
              [38.653774976304497, 8.962637894019201],
              [38.653754615988603, 8.96266783310509],
              [38.653734987799702, 8.96269824603171],
              [38.653732709672099, 8.96270195159207],
              [38.653723686095098, 8.96272084764219],
              [38.653715664139597, 8.962738296428819],
              [38.653703363508399, 8.962759630905749],
              [38.653689427045101, 8.962778432894121],
              [38.651881006088701, 8.96347520076379],
              [38.643378177040098, 8.96574070954626],
              [38.643096203058903, 8.965793787523291],
              [38.642585985130403, 8.96586665226752],
              [38.6421227383114, 8.965959567306699],
              [38.638419440739099, 8.973557810429201],
              [38.638732739872601, 8.973807387809121],
              [38.639090148981403, 8.97391311725767],
              [38.640108773834903, 8.97447340225208],
              [38.640562165236901, 8.974361262979279],
              [38.648432742022301, 8.97596643868952],
              [38.648541644784203, 8.975998369584129],
              [38.652861306117302, 8.976807453824311],
              [38.656382597826401, 8.977039372351941],
              [38.659283450118302, 8.98486585778152],
              [38.659452706192603, 8.985322500866269],
              [38.659355078185797, 8.985979409196339],
              [38.658368478272202, 8.992617704422811],
              [38.658368601341401, 8.9926818897916],
              [38.658421130327604, 8.992741815349801],
              [38.658488908215801, 8.992937971021989],
              [38.658411939663999, 8.993139211545801],
              [38.658228827974803, 8.993430789530899],
              [38.657156972550801, 8.99668553836622],
              [38.651206298546803, 9.00231839788502],
              [38.650851440653398, 9.00264169206465],
              [38.650524242785899, 9.00832569487155],
              [38.653314374125699, 9.01221674815932],
              [38.6580073824774, 9.009229425206639],
              [38.659379760296602, 9.00925343727662],
              [38.660707031010297, 9.009403745132721],
              [38.662125347774797, 9.00940449839308],
              [38.666100889675299, 9.00907675723756],
              [38.667926393893403, 9.01095237590043],
              [38.666513946721103, 9.01671939685051],
              [38.667200263191397, 9.026219269120981],
              [38.672058775354401, 9.033132148251269],
              [38.672575059606103, 9.038659441948109],
              [38.676740977871603, 9.04315365816112],
              [38.683021004801397, 9.047375338053589],
              [38.683686844174801, 9.04782292869298],
              [38.6836828412819, 9.05244990520217],
              [38.6836767129751, 9.05952853027572],
              [38.683670843382998, 9.06630327828829],
              [38.684101640359003, 9.07093426241584],
              [38.684101868531101, 9.070936708984879],
              [38.684103542990798, 9.07095471406482],
              [38.684105517487701, 9.07097593658581],
              [38.684107068878198, 9.070992613703909],
              [38.6841088520341, 9.07101178440506],
              [38.6841106594444, 9.071031216793971],
              [38.684111971883098, 9.071045323149949],
              [38.684114236535898, 9.07106966996326],
              [38.6841163448819, 9.07109232866077],
              [38.684120435809703, 9.071136302124721],
              [38.684122238728399, 9.071155688378701],
              [38.684123617642399, 9.07117051149066],
              [38.684125472663503, 9.07119045571628],
              [38.684127069867998, 9.071207617170209],
              [38.684128749717601, 9.071225678122589],
              [38.684131646784401, 9.07125681729589],
              [38.684133355380098, 9.07127519404629],
              [38.684135365809702, 9.07129679533149],
              [38.684141507591299, 9.071362818914031],
              [38.684144823273002, 9.071398468875859],
              [38.684145168226102, 9.071402167998301],
              [38.684146629784998, 9.07141787906683],
              [38.684147726627998, 9.07142967456514],
              [38.684148691418599, 9.07144003920361],
              [38.684150282334997, 9.071457140324201],
              [38.684152509258602, 9.07148107816591],
              [38.684154152277202, 9.071498747015401],
              [38.6841556057514, 9.071514364936361],
              [38.684158934907799, 9.07155015592876],
              [38.684162268555802, 9.07158599570695],
              [38.6841655276437, 9.071621025578249],
              [38.684168557661103, 9.071653597349989],
              [38.684171281353102, 9.07168287805561],
              [38.684173563073898, 9.07170740401561],
              [38.684175860964402, 9.07173210561556],
              [38.684178674487903, 9.071762358553171],
              [38.684180491779699, 9.071781884044711],
              [38.684180758579302, 9.071784746647911],
              [38.684181051430102, 9.07178790287416],
              [38.684181063108198, 9.071788024403959],
              [38.684181105329003, 9.071788482136601],
              [38.684181728759803, 9.071795190226149],
              [38.684182034187003, 9.07179846265962],
              [38.684182242596201, 9.071800715839609],
              [38.684183781410198, 9.0718172509868],
              [38.684184184753803, 9.07182158702742],
              [38.684184465926499, 9.071824609304461],
              [38.684184940237003, 9.071829703797251],
              [38.684187063854303, 9.07185253897541],
              [38.6841911925113, 9.071896909757371],
              [38.684192836428302, 9.071914589231341],
              [38.684196528504103, 9.07195427179656],
              [38.684196797100398, 9.07195715923659],
              [38.684198727579897, 9.071977912433891],
              [38.684204977159403, 9.072045089979749],
              [38.684207596646701, 9.072073253822159],
              [38.684210657206897, 9.072106156432911],
              [38.684211176433102, 9.072111738814581],
              [38.684211448622698, 9.072114667058919],
              [38.684211597743001, 9.072116262025091],
              [38.684211624692502, 9.072116556534979],
              [38.684213141048701, 9.072132855704879],
              [38.684217181670803, 9.072176288810949],
              [38.684219230727997, 9.072198318500449],
              [38.6842204335722, 9.072211244997179],
              [38.684222349678599, 9.072231846489499],
              [38.684222776378398, 9.072236425584411],
              [38.684222991075799, 9.072238738195839],
              [38.684224775129898, 9.07225791503995],
              [38.684224781418102, 9.07225797802246],
              [38.684225753395303, 9.07226843223185],
              [38.684227515889802, 9.072287382869609],
              [38.684228471697303, 9.07229764990479],
              [38.684228742988502, 9.07230057016392],
              [38.684229840729799, 9.072312378052279],
              [38.684230235988501, 9.07231662360501],
              [38.684231216948803, 9.07232716474628],
              [38.684232964171997, 9.0723459406267],
              [38.684234725768299, 9.07236487618005],
              [38.684235882798397, 9.07237732542319],
              [38.684236498144401, 9.0723839288266],
              [38.684237339865803, 9.0723929867727],
              [38.684238091755702, 9.072401066272381],
              [38.684238273215399, 9.07240301340692],
              [38.684238669372398, 9.072407276700121],
              [38.684240444443397, 9.07242635506968],
              [38.684242285091401, 9.072446146648231],
              [38.684242523145002, 9.072448704978671],
              [38.684242669570402, 9.072450272443961],
              [38.684243389120901, 9.07245800420811],
              [38.684243696344701, 9.07246131211863],
              [38.684244627897698, 9.07247131834818],
              [38.6842464110535, 9.07249048897032],
              [38.684248312786998, 9.07251093609522],
              [38.6842500618069, 9.072529742126619],
              [38.684250746323102, 9.072537092445989],
              [38.6842534179127, 9.07256581599524],
              [38.684256087705798, 9.072594510268679],
              [38.684258857211802, 9.07262428588631],
              [38.684262048027698, 9.072658591801749],
              [38.684262313928997, 9.07266144464012],
              [38.684264179729901, 9.072681496119131],
              [38.6842699289477, 9.07274330760815],
              [38.684274920885699, 9.07279696153325],
              [38.684282409241902, 9.072877468131059],
              [38.686577628149003, 9.07411208694967],
              [38.693645355412201, 9.077317779758239],
              [38.695855182264999, 9.07832004659056],
              [38.700658933128302, 9.08049872340162],
              [38.7087762331048, 9.084180013250471],
              [38.7084537343246, 9.084188091597509],
              [38.7166200373998, 9.087737029239451],
              [38.727078660518799, 9.085700601198599],
              [38.7379974079128, 9.08684937145788],
              [38.748731310767397, 9.08923390848261],
              [38.752936024575, 9.09179723703093],
              [38.759267414477897, 9.09588173592401],
              [38.7651915477568, 9.098137141698951],
              [38.771224164284398, 9.098181545593659],
              [38.778164161893898, 9.09363829342943],
              [38.790604835042302, 9.078035600094029],
              [38.792315575889504, 9.077117463550801],
              [38.798572715542598, 9.077308694437489],
              [38.799012086937999, 9.077743847512521],
              [38.800943185422803, 9.07946252464067],
              [38.8012354496055, 9.07968853232237],
              [38.804854559764301, 9.08197907514206],
              [38.8051260836437, 9.082387166559711],
              [38.805328205480997, 9.082581832849399],
              [38.8133537209916, 9.080008259371491],
              [38.824713584309301, 9.075875520792531],
              [38.841310998534198, 9.077858966229289],
              [38.844471015683801, 9.078258958131631],
              [38.847201035358196, 9.078498950220281],
              [38.849921047800201, 9.07808894630363],
              [38.851392671491901, 9.077879442321271],
              [38.856729007436002, 9.083509355464679],
              [38.8681972722556, 9.0848952997463],
              [38.875322298559198, 9.08351601632134],
              [38.876271072212802, 9.081227982158129],
              [38.877301196400197, 9.07996888195839],
              [38.877803263015899, 9.07753293112177],
              [38.877972019626903, 9.07712594833303],
              [38.878821194369998, 9.07567887890702],
              [38.880011197118399, 9.07423887183834],
              [38.880831202666002, 9.073008876007769],
              [38.881521197737399, 9.07031887170872],
              [38.8816911949218, 9.06870886766221],
              [38.8825911954454, 9.066608866381371],
              [38.8824011900847, 9.0636088680846],
              [38.882571186370697, 9.061788870815031],
              [38.883411178534999, 9.05946886863874],
              [38.883431178626502, 9.05698886825552],
              [38.882801169456499, 9.054278869777169],
              [38.882751162939599, 9.05179886907483],
              [38.8832111605522, 9.05019886340909],
              [38.8850011678418, 9.048178858012781],
              [38.8876011743451, 9.04542885748819],
              [38.892161185322301, 9.038918842265319],
              [38.894481188748102, 9.035508839076339],
              [38.896361188364601, 9.03209883111661],
              [38.896821183282299, 9.02940883400521],
              [38.901136954011903, 9.01957098699617],
              [38.905055308263201, 9.01063853992142],
              [38.904901401702197, 9.00833473579713],
              [38.906571165897802, 8.99857880589099],
              [38.905491156465999, 8.99645881226307],
              [38.904481143761103, 8.993748815051889],
              [38.9041511332684, 8.99053881456228],
              [38.904341130544303, 8.98637881187431],
              [38.904731119751901, 8.98368880897149],
              [38.905561122650198, 8.982238810830889],
              [38.906171121847201, 8.979838807129701],
              [38.906121115330301, 8.97742880440815],
              [38.906371107490699, 8.97494880299554],
              [38.904071087986701, 8.9695288082369],
              [38.9018510580689, 8.963668812278289],
              [38.899061028238698, 8.95554881605509],
              [38.897521022092597, 8.95545882488193],
              [38.894871009072403, 8.955638829390081],
              [38.894871009072403, 8.95557882937621],
              [38.895030999024499, 8.95382882846096],
              [38.894493660059197, 8.95252896529748],
              [38.900554621128997, 8.94845940020341],
              [38.901598061942202, 8.943796312318909],
              [38.895892294735802, 8.938768575347501],
              [38.897580975654598, 8.935258822044879],
              [38.891400938402398, 8.93431883402514],
              [38.884990902793099, 8.935208849998119],
              [38.883900901400402, 8.93556884966965],
              [38.879670881152798, 8.9369788592312],
              [38.872160842308404, 8.937118880009979],
              [38.8707408421011, 8.93972888140004],
              [38.8674908245398, 8.94078889056801],
              [38.863280809773599, 8.942138900765981],
              [38.859510790732003, 8.94318890464587],
              [38.855532623055801, 8.93827960853903],
              [38.858266414756002, 8.933763365291099],
              [38.859697927771897, 8.9325790356338],
              [38.862640082803999, 8.931158278322769],
              [38.868047560827002, 8.92760560828196],
              [38.8730993185739, 8.923913185781769],
              [38.873453813547997, 8.92375453430456],
              [38.873855599431799, 8.923556599242669],
              [38.879424485846798, 8.9183009785222],
              [38.877160991842302, 8.91251359804048],
              [38.873840632600903, 8.907147040251539],
              [38.872900907677099, 8.90378799824199],
              [38.872564799420303, 8.9022448047398],
              [38.8774690241532, 8.89577397994074],
              [38.873389428596603, 8.88827898325323],
              [38.869808705246598, 8.88076166056047],
              [38.8699234111252, 8.873615652282799],
              [38.868098603999798, 8.87268200900397],
              [38.868061436204897, 8.87276954426429],
              [38.8637863609543, 8.882837371975031],
              [38.858365347116496, 8.883625057587221],
              [38.852959491450903, 8.88177045024047],
              [38.847054795918098, 8.880006816057969],
              [38.8406653155591, 8.87983835854225],
              [38.834117750574698, 8.882800152052191],
              [38.832861848315297, 8.88309691550891],
              [38.831903712419802, 8.88279918019464],
              [38.829500066857896, 8.88173322932175],
              [38.8282352227682, 8.881693313816269],
              [38.826557335107402, 8.880739108014311],
              [38.824902309570703, 8.88031938011488],
              [38.824509306515402, 8.879545661860419],
              [38.824088559890797, 8.87898857355286],
              [38.824058788823997, 8.878198673300959],
              [38.826913370692203, 8.87365259178009],
              [38.829405846161002, 8.869482944998071],
              [38.829406311488299, 8.86946114619607],
              [38.829349146296899, 8.86736256432062],
              [38.827023770047397, 8.86324472299817],
              [38.826254770841601, 8.85760471834106],
              [38.824527085971503, 8.853888128746251],
              [38.823243019731301, 8.851601925039541],
              [38.823747707631, 8.846998589120719],
              [38.823751385333701, 8.846965038570019],
              [38.823755085494398, 8.846931283861879],
              [38.8237556325684, 8.84692629273078],
              [38.8237577921183, 8.846906604257921],
              [38.823760229247704, 8.846884362078519],
              [38.823762436408401, 8.846864236002849],
              [38.8237646615353, 8.846843944827279],
              [38.823767190292799, 8.84682087626237],
              [38.823769454047401, 8.84680023180843],
              [38.823771764514298, 8.846779149752621],
              [38.823774110015499, 8.846757759688609],
              [38.823776548941503, 8.84673551483737],
              [38.823778678846999, 8.846716083766511],
              [38.823783259356603, 8.84667429866141],
              [38.823784925731502, 8.846659106900811],
              [38.823788538755601, 8.846626151030311],
              [38.823792105067199, 8.84659362299384],
              [38.823795713599701, 8.84656070794839],
              [38.823799462269399, 8.846526514714579],
              [38.823801435868099, 8.84650851005221],
              [38.823804126322401, 8.84648396977763],
              [38.823806901218298, 8.846458654601371],
              [38.823809075141298, 8.846438826745731],
              [38.823810958908403, 8.846421651124579],
              [38.8238131319331, 8.84640182948038],
              [38.8238150911587, 8.84638395884393],
              [38.823816112543199, 8.8463746493897],
              [38.823819350071503, 8.846345113529059],
              [38.823822813975198, 8.84631352102901],
              [38.823826232963199, 8.84628232707183],
              [38.823829753460799, 8.846250217966061],
              [38.823830942830199, 8.84623936758371],
              [38.823833071837498, 8.84621995157636],
              [38.823834919672002, 8.84620310081743],
              [38.823836914830203, 8.846184896422571],
              [38.823838865072702, 8.8461671074375],
              [38.823840596126303, 8.846151318281191],
              [38.823842416113003, 8.846134718718311],
              [38.823844197472297, 8.846118466217909],
              [38.823846341750802, 8.84609890729582],
              [38.823848445605201, 8.846079716739061],
              [38.8238507084614, 8.84605908200745],
              [38.823852487125698, 8.846042850806841],
              [38.823854544267697, 8.84602409696109],
              [38.823856298677399, 8.84600808855407],
              [38.823860146161799, 8.845972994324381],
              [38.823861363379002, 8.845961890071599],
              [38.823863601980698, 8.84594146925228],
              [38.823865804649799, 8.845921386618951],
              [38.824370374870099, 8.8413189658362],
              [38.817239015443803, 8.841779171180271],
              [38.817189680866797, 8.841741983491699],
              [38.817720329179799, 8.83679898062676],
              [38.812646452215603, 8.84015415755894],
              [38.812402713227897, 8.839849778048331],
              [38.807878783084, 8.83780165699697],
              [38.807406452500899, 8.83782831666996],
              [38.803304826660302, 8.836647079335689],
              [38.799434643667801, 8.83284162119846],
              [38.798891913912499, 8.832719961810991],
              [38.796940709334997, 8.833685886851949],
              [38.794977874269499, 8.83464044113393],
              [38.792002186924599, 8.8357633180566],
              [38.788232578413002, 8.83567861093371],
              [38.786785323319997, 8.834899907741431],
              [38.784874790865302, 8.835224175723161],
              [38.781666738102501, 8.83475708018663],
              [38.7812989031464, 8.83487449323918],
              [38.780398043833401, 8.836026718844179],
              [38.779919663995202, 8.836706111777881],
              [38.779577757113202, 8.837166856362209],
              [38.778305868434998, 8.840806918932669],
              [38.778415278745101, 8.84278401225969],
              [38.780087605834197, 8.850849589378241],
              [38.778391136521797, 8.85260640733765],
              [38.7756459020815, 8.853095311961861],
              [38.773875392725103, 8.859507024774491],
              [38.772103103806103, 8.856951791487431],
              [38.771492482076603, 8.85577001815677],
              [38.761536063015498, 8.85360124920529],
              [38.756442334181898, 8.853227303530421],
              [38.754008849281199, 8.85668006307872],
              [38.754180016970402, 8.86047993857578],
              [38.745494931428702, 8.86341037891315],
              [38.745177968067203, 8.869477159794959],
              [38.747028402330997, 8.87625371539966],
              [38.747515559607898, 8.87803771654989],
              [38.742790024158097, 8.87810915831877],
              [38.742920027447703, 8.88029915202762],
              [38.743700038202199, 8.882789149204649],
              [38.747007403312999, 8.889250659003521],
              [38.746275032912997, 8.89014947539374],
              [38.745777702215499, 8.89065229251497],
              [38.746685788474899, 8.892545454821841],
              [38.745318924514997, 8.89256851430137],
              [38.744900448831501, 8.8923466233299],
              [38.744831013551597, 8.89230116465489],
              [38.744756798336098, 8.892244682997759],
              [38.744677910982801, 8.892215227153381],
              [38.744507796119102, 8.89217100832532],
              [38.744337668679002, 8.89214884077685],
              [38.7441453321902, 8.8921487103116],
              [38.743989944307302, 8.892204959453769],
              [38.743866622686802, 8.89224652992327],
              [38.743689047314703, 8.892295412649959],
              [38.743580527235103, 8.892329642447541],
              [38.743526263602, 8.89234920689478],
              [38.7434744676411, 8.892368773116059],
              [38.743430070204802, 8.892385895111939],
              [38.743393070394902, 8.89240302065711],
              [38.743358531968802, 8.89242749928658],
              [38.743336317530201, 8.892459336324009],
              [38.743304236894701, 8.892493617582289],
              [38.743254885249698, 8.89254503857615],
              [38.743220331552301, 8.892591568454369],
              [38.743163575992597, 8.892650334527451],
              [38.743127591278899, 8.892673615879669],
              [38.743075792623003, 8.89269563251848],
              [38.743009195121097, 8.89272498892235],
              [38.742945060799698, 8.89275679930838],
              [38.742903123848997, 8.89278127258912],
              [38.7428636617569, 8.89279349635988],
              [38.742816799343501, 8.892808166126761],
              [38.7427724046021, 8.89282283678059],
              [38.742760065343397, 8.892837528734191],
              [38.742730455973302, 8.89286446043096],
              [38.742718107731399, 8.892893854984489],
              [38.742715615804798, 8.89293060704825],
              [38.742715592448597, 8.89296490956281],
              [38.742735282621297, 8.893018827961569],
              [38.742750050026302, 8.893058041095451],
              [38.742767291391502, 8.893085004726551],
              [38.742774671051599, 8.893111962143051],
              [38.7427820408302, 8.8931511708294],
              [38.742774601881301, 8.89321242008219],
              [38.742762249147802, 8.893246715470211],
              [38.742596925897899, 8.89340586566038],
              [38.742256635983402, 8.893408083561461],
              [38.742128474240197, 8.89331733775993],
              [38.742029895815797, 8.8932364140739],
              [38.741931294035297, 8.89318734243189],
              [38.741830045817899, 8.89315702311019],
              [38.741783837378001, 8.893102421344331],
              [38.741694252886298, 8.89307363919373],
              [38.741572861745396, 8.89306494064696],
              [38.7414138752114, 8.893082063497809],
              [38.7413068984375, 8.89312507253139],
              [38.741269270705203, 8.89319972061687],
              [38.741234521175102, 8.893291604242711],
              [38.741237314935603, 8.893432336694829],
              [38.741283270948898, 8.8938516927933],
              [38.7413062893798, 8.89400392987411],
              [38.741332212063902, 8.89413606382017],
              [38.741364576567001, 8.894341650978239],
              [38.741395572935801, 8.89445901747583],
              [38.741401733582101, 8.894539310757651],
              [38.741401660818497, 8.894644303493701],
              [38.741376729874403, 8.894743104027141],
              [38.741376683162002, 8.894811040416821],
              [38.741370420107899, 8.894878974131361],
              [38.741357941610303, 8.894946902508231],
              [38.741326816782298, 8.895014816672351],
              [38.741301200423699, 8.895054483110799],
              [38.741291408787099, 8.895095223433669],
              [38.741301144728098, 8.895134038740149],
              [38.741308940308201, 8.89515732792205],
              [38.741320630983303, 8.895194203122649],
              [38.741332332438198, 8.89521943594991],
              [38.741347935276302, 8.89524661242447],
              [38.7413694023167, 8.895266031164081],
              [38.7414611382735, 8.895330126310119],
              [38.741562632629197, 8.89540393081098],
              [38.741666078125803, 8.895479676282219],
              [38.741808573387701, 8.89556320963993],
              [38.741859309336597, 8.89561757496112],
              [38.741927632502197, 8.89565448916581],
              [38.741978372942697, 8.89570109319968],
              [38.742036926031197, 8.895743820585009],
              [38.7421112032305, 8.89581635231367],
              [38.742004698072101, 8.900498348161999],
              [38.738298549247098, 8.900986849053909],
              [38.734688648616697, 8.901567730501821],
              [38.731495052441701, 8.90451699976256],
              [38.730604060223698, 8.905638354835339],
              [38.728046706628497, 8.910012595137511],
              [38.727334175521499, 8.9106710052562],
              [38.7272293834504, 8.910724028246941],
              [38.726194859314703, 8.91191532181224],
              [38.724898656835002, 8.913452484090151],
              [38.724062700801298, 8.91400515539185],
              [38.724023949276599, 8.914009197796981],
              [38.723979687485901, 8.914008504686491],
              [38.723910061763199, 8.914026950655209],
              [38.723856559003202, 8.914045435671481],
              [38.723767602433803, 8.91404238545306],
              [38.723732308524703, 8.91404471149975],
              [38.723722666906703, 8.91404579065761],
              [38.723700560265897, 8.914050929969431],
              [38.723684907121999, 8.914061681611861],
              [38.723667254328397, 8.91406980901907],
              [38.723648693337999, 8.91407721225449],
              [38.723628315954102, 8.91408072484202],
              [38.723606116786797, 8.91408785385042],
              [38.723561383380598, 8.914101611336459],
              [38.723495254799303, 8.91408469181173],
              [38.723459394951398, 8.914095072492691],
              [38.723441380136698, 8.9141020240078],
              [38.723411526424897, 8.914127780022319],
              [38.723394309314202, 8.91416213823406],
              [38.723394929151702, 8.91418466120934],
              [38.7233972791445, 8.914205647093921],
              [38.723400536435697, 8.91423257544227],
              [38.7234003244333, 8.91426869348687],
              [38.723400140278699, 8.91427194959522],
              [38.723400941575903, 8.91429573897593],
              [38.723401655736602, 8.914313919578211],
              [38.7234021875392, 8.914332100179591],
              [38.723400627165603, 8.9143515463046],
              [38.723393973344301, 8.91436981210073],
              [38.723387047333397, 8.91438672096263],
              [38.723380212052398, 8.91440444451619],
              [38.723378662458501, 8.914409327345719],
              [38.723373648062598, 8.914425152611759],
              [38.7233642642612, 8.91444486408369],
              [38.723354880459702, 8.914465931600031],
              [38.723344318068598, 8.914481752426299],
              [38.723330573844798, 8.914494405891951],
              [38.723318643319502, 8.91451303021644],
              [38.723309530809203, 8.914534460701701],
              [38.7232910722268, 8.91456579883492],
              [38.723270972422299, 8.9145957738207],
              [38.723247917160499, 8.91462328964144],
              [38.723229762208597, 8.914654609130279],
              [38.723222387040103, 8.914665367854621],
              [38.723210457413202, 8.91468381112781],
              [38.723201713212198, 8.914699904396031],
              [38.723194878829503, 8.914716995172739],
              [38.7231817679179, 8.91473417114516],
              [38.723173477366203, 8.91475162134309],
              [38.723165185916102, 8.91476997675298],
              [38.723157357098401, 8.914779197499589],
              [38.723151257537602, 8.9147863380314],
              [38.723145786797602, 8.91480388141045],
              [38.7231373129895, 8.91482341270685],
              [38.7231294751886, 8.914843395721061],
              [38.7231206393595, 8.914860756279939],
              [38.723112892288498, 8.914881282419669],
              [38.7231041462909, 8.9148999999081],
              [38.723096681290897, 8.914909762891471],
              [38.723075615797399, 8.914939156560649],
              [38.723058378923803, 8.914971018262371],
              [38.723033930375003, 8.91499670144364],
              [38.7230006433022, 8.91501126826171],
              [38.722998732585502, 8.91501217081159],
              [38.722983896908602, 8.91502500352637],
              [38.722975334167302, 8.91504145975189],
              [38.722963041621, 8.915059359878789],
              [38.722951293453697, 8.915078979020359],
              [38.722944093456697, 8.915098872386849],
              [38.7229396234398, 8.915116507159549],
              [38.722933331639602, 8.91513622036909],
              [38.722933058551803, 8.91513694365117],
              [38.7229284978051, 8.91515475946443],
              [38.722921204383297, 8.91517637095443],
              [38.722912005634697, 8.91519210300366],
              [38.7229032614338, 8.915208648855369],
              [38.722902336169, 8.915229722539911],
              [38.722902956904903, 8.915250707476959],
              [38.722905671613702, 8.91527051830008],
              [38.722905850378403, 8.915274317526499],
              [38.719119604169499, 8.919702303680539],
              [38.711578595007303, 8.920696517908929],
              [38.7096892044028, 8.920296448496391],
              [38.707505994631802, 8.920618928218209],
              [38.706652002223798, 8.9212668801686],
              [38.705656693143503, 8.921634413827849],
              [38.7048912916906, 8.92170780212988],
              [38.7019209178805, 8.925288815811101],
              [38.701910443524298, 8.92530581202365],
              [38.7018981518763, 8.92532063578734],
              [38.701877770000799, 8.925329212874949],
              [38.701860119003797, 8.925333540022249],
              [38.701843376203499, 8.92534166806599],
              [38.701832718591, 8.925360563393079],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 2,
        REGIONNAME: "Tigray",
        latitude:13.833271,
        longtiude: 39.344733,
        RID: "01",
        GlobalID: "19f66fac-f14d-47f4-bd4d-413422160e4b",
        Shape__Area: 56053006323.359398,
        Shape__Length: 1889939.7977205601,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.554626450609497, 12.254663077906001],
            [39.553985278075501, 12.256943273356599],
            [39.543251533324302, 12.264996221640899],
            [39.5413179096753, 12.2775190055015],
            [39.539375302873403, 12.290096051754899],
            [39.5382119845804, 12.292537221532999],
            [39.537093582051703, 12.2948730432177],
            [39.535327269624297, 12.2989148763338],
            [39.535250912825198, 12.3009258955493],
            [39.531169192752898, 12.3060471777553],
            [39.529262518562398, 12.312284080026901],
            [39.522639689130202, 12.3264029576842],
            [39.5151365107196, 12.3293495055999],
            [39.507418859534901, 12.332841220729801],
            [39.501002642618097, 12.3317003575663],
            [39.487134900419498, 12.3267024407973],
            [39.471540147087197, 12.321160302294199],
            [39.469450441157498, 12.321614472443899],
            [39.455541151877, 12.325016335442101],
            [39.441628493914202, 12.3160974586247],
            [39.439812774146198, 12.3148786316161],
            [39.4288342384801, 12.304281948442],
            [39.427769734868498, 12.302366403399001],
            [39.426052829781703, 12.300639548417401],
            [39.4194917595253, 12.296585673361101],
            [39.402862820722099, 12.295268013363],
            [39.388572870339999, 12.300484855009399],
            [39.372553663036001, 12.3012835547471],
            [39.367993590074903, 12.3007196378766],
            [39.363516611277703, 12.300301636458],
            [39.3600614661162, 12.2987777358922],
            [39.358163775078502, 12.298491386419499],
            [39.356039259431498, 12.299199031100899],
            [39.353525099530103, 12.299736620360401],
            [39.350129467756098, 12.298402519278399],
            [39.343687424274897, 12.2996576277218],
            [39.342361286336697, 12.300953323419201],
            [39.340919490305701, 12.301461287050801],
            [39.334956922607297, 12.301043286812099],
            [39.3338183079847, 12.300548487769801],
            [39.332603336562897, 12.3001875362178],
            [39.328272333999401, 12.302281926005101],
            [39.324087307669501, 12.3044662613177],
            [39.321610203273501, 12.305030170146299],
            [39.319344202969297, 12.305068568563399],
            [39.314464105188399, 12.3023345872408],
            [39.311805091947399, 12.300257751756099],
            [39.309886065921702, 12.298531980041901],
            [39.307304532373898, 12.2972691863121],
            [39.305272094043602, 12.297117782312499],
            [39.302980267175002, 12.297419493094999],
            [39.299620568012401, 12.297367928003901],
            [39.298353943461798, 12.2973701222634],
            [39.2927664223946, 12.295955918282599],
            [39.291310028740199, 12.295460012524901],
            [39.288478089807001, 12.296765603106399],
            [39.285622570097601, 12.2986768004815],
            [39.283901173434401, 12.300676850360301],
            [39.2812904446399, 12.303581997176201],
            [39.279062622735303, 12.306397150336799],
            [39.276670858291297, 12.3101700226263],
            [39.274481214786299, 12.313871531761301],
            [39.271643661382598, 12.318197206729],
            [39.269418085266203, 12.3218075493707],
            [39.267111660774198, 12.326412830759301],
            [39.264995005385998, 12.331298860310101],
            [39.2635442262022, 12.3349254771451],
            [39.262477476802303, 12.335505775059699],
            [39.259011102699702, 12.3382580590056],
            [39.255259513494401, 12.340504620877899],
            [39.252249034398503, 12.3428498880535],
            [39.250082971669599, 12.3453739337006],
            [39.248254900066399, 12.348468351936701],
            [39.247399254758299, 12.3529733470377],
            [39.246970309210099, 12.355243914145399],
            [39.246909672928503, 12.357630730589801],
            [39.246569436014603, 12.35993635704],
            [39.245927140586502, 12.3621717642246],
            [39.244936747985697, 12.364062741443],
            [39.244205743923303, 12.3653921209501],
            [39.2439631987966, 12.3677163251455],
            [39.243646542658901, 12.3725906154533],
            [39.243121028217701, 12.375240506491799],
            [39.242944733843203, 12.379400649556899],
            [39.2429874038192, 12.3811730499805],
            [39.240883100266103, 12.386031734465799],
            [39.240176799874, 12.3874333878026],
            [39.240150973309603, 12.388880000211399],
            [39.240301441119698, 12.390743365554799],
            [39.239897199241803, 12.394532569492],
            [39.241819593949799, 12.403583663530799],
            [39.241086344099202, 12.4103841150935],
            [39.241176175627601, 12.414281600722401],
            [39.242108177734799, 12.416731481627099],
            [39.2426303234937, 12.418783266554399],
            [39.241569188564398, 12.4252236386584],
            [39.241251409532602, 12.426009894855801],
            [39.240971808900397, 12.430096853553801],
            [39.241160455110098, 12.431228514150201],
            [39.241072869369901, 12.432050938817699],
            [39.2404170992125, 12.4331738184281],
            [39.239303188260202, 12.434602631855199],
            [39.238866382453303, 12.438211365090901],
            [39.237386408022701, 12.440238856846999],
            [39.236790151252897, 12.4418661021673],
            [39.236192771588897, 12.443305832955099],
            [39.236071499025599, 12.4467148818695],
            [39.236404998574798, 12.447925417898899],
            [39.236252284976501, 12.450069061600001],
            [39.235825585216503, 12.4512368191328],
            [39.235972684344297, 12.453858497886801],
            [39.235796389969799, 12.455576664447801],
            [39.235898573333401, 12.4573299062779],
            [39.235736876582202, 12.458651134472801],
            [39.235577425619297, 12.4623318981845],
            [39.235231574234902, 12.4647374688707],
            [39.235750351311502, 12.4680015097134],
            [39.236399384104303, 12.4715823684959],
            [39.236417350410001, 12.473155694043101],
            [39.236178173965598, 12.474485612644701],
            [39.233412485784598, 12.479796447852101],
            [39.232197514362802, 12.481723846238401],
            [39.231099323927999, 12.4848988693946],
            [39.2308073714606, 12.486092782043199],
            [39.230764701484603, 12.487484028675301],
            [39.231234071220598, 12.4911830199718],
            [39.2319134221542, 12.4933164390304],
            [39.231569816558, 12.494899499144999],
            [39.230604127627601, 12.4982914249551],
            [39.230015731116502, 12.500308580155799],
            [39.2294295803936, 12.502315853207699],
            [39.228954596187101, 12.503364978794901],
            [39.227327522628798, 12.505880889568999],
            [39.225375932673998, 12.5082345320612],
            [39.223292964109, 12.5102340698935],
            [39.224695458846298, 12.512475857879201],
            [39.222404754871803, 12.5153172550703],
            [39.222069009534401, 12.517985421333],
            [39.222637193951599, 12.5207511208428],
            [39.223246925450702, 12.522261664775501],
            [39.222191404991797, 12.523854413057601],
            [39.218796896111897, 12.527618653113],
            [39.2179704460506, 12.5292015029342],
            [39.2176751249009, 12.530801881377901],
            [39.2179704460506, 12.532538171137499],
            [39.217706565935799, 12.5348356678917],
            [39.217360714551504, 12.5370608002801],
            [39.216700452817598, 12.539456906575699],
            [39.216000889790102, 12.5423791176656],
            [39.215637072100101, 12.5431200781491],
            [39.213941502001298, 12.5442611092804],
            [39.212322288701699, 12.5447148897085],
            [39.210977061563703, 12.546262559935901],
            [39.2089030761515, 12.5489863054111],
            [39.208486482438502, 12.5501163499037],
            [39.209078247631901, 12.5535524876891],
            [39.209951859245699, 12.5553960361619],
            [39.210261778018697, 12.556400008252201],
            [39.209195028618801, 12.560579164378],
            [39.2077902880933, 12.56525583685],
            [39.207372571486196, 12.566802287515699],
            [39.205972322537001, 12.570085861335199],
            [39.205423227319599, 12.5716498184931],
            [39.205077375935197, 12.573875728486],
            [39.204731524550802, 12.5753760971117],
            [39.2039556047242, 12.576606853144201],
            [39.203249304331997, 12.5780809031413],
            [39.202815867207498, 12.580912808907801],
            [39.201348244611999, 12.582713420052199],
            [39.200548744009197, 12.583283300436801],
            [39.198899212568698, 12.585455409838699],
            [39.198575819066399, 12.587752433887401],
            [39.197758352157898, 12.5901316293871],
            [39.19402248347, 12.594700368083901],
            [39.190526914120703, 12.5994049112825],
            [39.188668524376702, 12.6022102814616],
            [39.186650683669697, 12.6053761486602],
            [39.185654676598503, 12.606977148643701],
            [39.184866404936599, 12.6083436342127],
            [39.183649187726701, 12.610659081891299],
            [39.182954116275603, 12.6123334673595],
            [39.1824645344457, 12.613852239472999],
            [39.181981689980503, 12.616918246922101],
            [39.182171459084302, 12.617994299444],
            [39.182793542418501, 12.6194681114936],
            [39.1839658438643, 12.6219905567339],
            [39.1843094494605, 12.6239705821231],
            [39.184242075814197, 12.6284280486051],
            [39.183657047985399, 12.6299302893483],
            [39.182245570095198, 12.632499747039301],
            [39.179533780831299, 12.6343372495589],
            [39.178564723218599, 12.6350615089979],
            [39.177911198849401, 12.6356498997997],
            [39.177054430647097, 12.6386247040653],
            [39.176507581217898, 12.6397379184143],
            [39.175167968550497, 12.6423971224281],
            [39.173589179438601, 12.644541338878801],
            [39.176306583173101, 12.6473703184247],
            [39.177208267139498, 12.648418851681701],
            [39.178013382212903, 12.6493128954463],
            [39.180515190279202, 12.6519698084985],
            [39.182283748494797, 12.6533524879786],
            [39.185317808366896, 12.655692727144499],
            [39.186811257526799, 12.6567138360101],
            [39.189674637494903, 12.6565297737697],
            [39.192578441650802, 12.657613328739],
            [39.1950566689409, 12.6585599291116],
            [39.1979357694265, 12.659978727490399],
            [39.199761595241498, 12.6607905614686],
            [39.202359972200803, 12.6615662384832],
            [39.204923539442802, 12.6618269881362],
            [39.207640943177303, 12.661806171997201],
            [39.210577311262298, 12.6615695252452],
            [39.2133564741725, 12.661684561888199],
            [39.2154248451142, 12.662189626916399],
            [39.216889099027298, 12.6634626931132],
            [39.219078742532297, 12.6649176181272],
            [39.221834324666403, 12.66532078865],
            [39.2236949601986, 12.6654281546056],
            [39.225992401537702, 12.6654993666941],
            [39.228672749766702, 12.664891324375301],
            [39.229301570465601, 12.664700694593],
            [39.230209991796698, 12.665314215222599],
            [39.232087470740503, 12.6680805175824],
            [39.235656028206698, 12.663464884271599],
            [39.236148978718802, 12.662740705404801],
            [39.237477362445198, 12.6625128241862],
            [39.240112794909997, 12.6627012644392],
            [39.2423720578496, 12.663838476498601],
            [39.246102312066903, 12.667036447386799],
            [39.247739491672199, 12.668500116466801],
            [39.251878479343802, 12.670421717154101],
            [39.2535527144545, 12.670908140571701],
            [39.2544184658096, 12.671278435253701],
            [39.255478477844903, 12.6718831757077],
            [39.256307173694502, 12.672678538684901],
            [39.258255394966902, 12.6743404668096],
            [39.261722891963601, 12.675946512297401],
            [39.263288206346203, 12.6764329251678],
            [39.264563814049602, 12.676820740169299],
            [39.267418210864903, 12.677460524078899],
            [39.2680795954929, 12.677712493328301],
            [39.268790387461401, 12.6780553902107],
            [39.272974290897203, 12.6819028149511],
            [39.274272356482697, 12.6830498007707],
            [39.2754873279045, 12.6841157151473],
            [39.276341850318502, 12.684829973876599],
            [39.279060376947101, 12.686979310907899],
            [39.280526876648402, 12.6879268976668],
            [39.281791255410802, 12.689085906501001],
            [39.285499051746001, 12.7043595720959],
            [39.269339482678802, 12.720855808704799],
            [39.268778035626298, 12.7236171257987],
            [39.270419706807999, 12.7251527599871],
            [39.272948464332799, 12.726137444922101],
            [39.274726005701197, 12.7270892668828],
            [39.280909783538299, 12.743326645837801],
            [39.2788301836556, 12.758536812720999],
            [39.267364311947901, 12.768865142652],
            [39.2679178987417, 12.773783298875699],
            [39.275748962230999, 12.787836367090399],
            [39.295707282056, 12.7854863954827],
            [39.302860117505702, 12.7925406247612],
            [39.304298544854397, 12.794068175605],
            [39.305084570727999, 12.7976313299407],
            [39.305923372624598, 12.8008265189582],
            [39.306633041699101, 12.8033077447382],
            [39.306675711674998, 12.8057550022033],
            [39.309120252142002, 12.8102892092804],
            [39.306747576897799, 12.8126050068238],
            [39.303783136460197, 12.813382409264699],
            [39.2965045368706, 12.817179602482],
            [39.293934232263901, 12.818136556216],
            [39.292056753320097, 12.819738405414601],
            [39.286651141097899, 12.8220497365021],
            [39.282340350628203, 12.823572728950399],
            [39.278918892289902, 12.8260843872359],
            [39.290366797691902, 12.837908771136],
            [39.295398486177, 12.8410322863836],
            [39.298407842378801, 12.843496693909],
            [39.3012734681352, 12.846034428156599],
            [39.303914515070502, 12.847523337096201],
            [39.309644643689097, 12.851794048263899],
            [39.310573277114003, 12.852597603847499],
            [39.306804844497101, 12.8662992538788],
            [39.302966792445702, 12.8701109733111],
            [39.294871848841701, 12.8708914819477],
            [39.278999740665398, 12.8562365617931],
            [39.2752503972483, 12.867312944827299],
            [39.269573044652702, 12.8797757540249],
            [39.268607355722303, 12.881894972009199],
            [39.263053521478199, 12.8940834111939],
            [39.258936991688699, 12.913362591224599],
            [39.256290330282901, 12.9141604768815],
            [39.250783657591199, 12.917971467600401],
            [39.235797512863897, 12.916690928668499],
            [39.235155217435803, 12.916832116616201],
            [39.234347856574203, 12.916901068840801],
            [39.2337617058513, 12.9169525093769],
            [39.233199135904599, 12.916964548649799],
            [39.233128393576003, 12.916915297075301],
            [39.2321402467634, 12.916757691971499],
            [39.231049916587303, 12.9166449604822],
            [39.230638937344899, 12.9161272705624],
            [39.2297249015433, 12.916159010567201],
            [39.2290511650802, 12.916480788319401],
            [39.227637441401797, 12.9179419167759],
            [39.226967073620997, 12.919093301868701],
            [39.226470754426501, 12.920073945062899],
            [39.225506188390199, 12.920529242380001],
            [39.224595521270899, 12.9207153011409],
            [39.2227943991263, 12.9203716395565],
            [39.221787163114001, 12.9204197959853],
            [39.220051168827403, 12.920956082861],
            [39.217981674991599, 12.9212811378149],
            [39.216260278328399, 12.9213697890926],
            [39.214495088795097, 12.9222519223135],
            [39.212468264935303, 12.923571832951099],
            [39.2109096879174, 12.924820597703301],
            [39.210070886020802, 12.9256939632048],
            [39.209451048474797, 12.9263845545648],
            [39.208620106837003, 12.9258406182651],
            [39.207421978826801, 12.925189425361699],
            [39.206248554486898, 12.925687396558301],
            [39.2055534830358, 12.926750096603399],
            [39.204935891277998, 12.927554506090599],
            [39.204427220248299, 12.9285373049117],
            [39.203934269736202, 12.929097651727901],
            [39.203042691816698, 12.9291162569717],
            [39.201700833361002, 12.9288645388497],
            [39.1928097578365, 12.928074361183],
            [39.177934779625602, 12.9368384077997],
            [39.176115691175198, 12.937400924752801],
            [39.174516689969501, 12.937154686943099],
            [39.1739709634344, 12.936847162936999],
            [39.172275393335603, 12.937113100089],
            [39.171779074141099, 12.937469871296001],
            [39.171958737197997, 12.9389221218321],
            [39.172200159430602, 12.9398611007216],
            [39.172389928534301, 12.9405472753665],
            [39.172324800676201, 12.9408427566893],
            [39.172020496373698, 12.941474210046399],
            [39.171204152359302, 12.941722632332599],
            [39.170329417851399, 12.9419458838676],
            [39.169422119414399, 12.9423354792048],
            [39.1688584265736, 12.942935192185701],
            [39.168565351212202, 12.9435294319099],
            [39.168512575189197, 12.943810682998199],
            [39.168311577144401, 12.944977269727699],
            [39.167795045856103, 12.9455244473635],
            [39.165358365647897, 12.947468012614401],
            [39.161305840822401, 12.9495855622308],
            [39.1574374706302, 12.9484047706331],
            [39.156432480406103, 12.9492189593216],
            [39.155757621048899, 12.94887643134],
            [39.155325306818398, 12.948974921862099],
            [39.154365232358501, 12.9496720147785],
            [39.153955376010103, 12.950293596770299],
            [39.153000916020702, 12.951273022994499],
            [39.1520049089495, 12.951906638578899],
            [39.150777585692502, 12.952738324983001],
            [39.149890499349503, 12.9535492165537],
            [39.141841594403701, 12.9558286722401],
            [39.123363249009401, 12.9633398946358],
            [39.122114590764497, 12.963086022520899],
            [39.1211444102576, 12.9626461228123],
            [39.120407791724702, 12.9621132084423],
            [39.1197250721087, 12.9613603418439],
            [39.119160256373803, 12.960657810202701],
            [39.1185123464752, 12.9599060334937],
            [39.1179385475874, 12.959355604565699],
            [39.117045846773799, 12.9592472695042],
            [39.115547906037598, 12.9594584681159],
            [39.1139253240556, 12.959825055954401],
            [39.1134132843437, 12.9600406312373],
            [39.112837239667698, 12.960282469276599],
            [39.112228631062699, 12.9600274997533],
            [39.111907483348702, 12.959791132923399],
            [39.111307857896499, 12.959702495304301],
            [39.110567870681201, 12.959666383672699],
            [39.109806548477899, 12.959682798051301],
            [39.109151901214602, 12.959825055954401],
            [39.108080660238301, 12.960204774728499],
            [39.100476421358302, 12.958758119700001],
            [39.084264076268099, 12.957902378805199],
            [39.081407433664602, 12.960619510134199],
            [39.074125465392697, 12.9760747504849],
            [39.0571955909694, 12.988184185867601],
            [39.057105759441001, 12.9890420108991],
            [39.055619047645699, 12.9898144885583],
            [39.055238386544097, 12.9901285120757],
            [39.0546702021269, 12.9904983371725],
            [39.053985236722802, 12.9908320546125],
            [39.0524333970694, 12.9912609629262],
            [39.0506502412305, 12.9918725936533],
            [39.0487503044045, 12.9925389302444],
            [39.048093411353001, 12.992515953150299],
            [39.046707760027303, 12.9924163857181],
            [39.045630904580399, 12.9925542483059],
            [39.044661846967699, 12.9927665127755],
            [39.043279564324301, 12.9935236608491],
            [39.042446376898198, 12.9941024617933],
            [39.0413459406752, 12.994803804796801],
            [39.034238020989598, 13.0004571478808],
            [39.0154497568222, 13.001197860861099],
            [39.014317879564203, 13.001503117118],
            [39.0132129517648, 13.0019856181777],
            [39.0121956097055, 13.002836830911299],
            [39.011299540209599, 13.003800732807701],
            [39.010418068337103, 13.004356533788],
            [39.010053127752897, 13.004299640831499],
            [39.009854375496303, 13.004304017213199],
            [39.009406340748299, 13.004172725727599],
            [39.009120002751501, 13.0039112366451],
            [39.008521500193503, 13.0033094824794],
            [39.0078702216125, 13.003578630886601],
            [39.007133603079502, 13.0043094876903],
            [39.000196363297903, 13.0034331157271],
            [38.993828430827598, 13.0097602110854],
            [38.994132735130101, 13.012257963413299],
            [38.994435916538499, 13.0128006167461],
            [38.9947963655462, 13.0132776255497],
            [38.9955969890432, 13.0146659785184],
            [38.9962224410598, 13.015497454902899],
            [39.004311770193297, 13.023386495321001],
            [39.000246893532598, 13.0387732848847],
            [39.000095302828399, 13.0388137607803],
            [38.999759557490997, 13.0390511463051],
            [38.993939597344003, 13.0452777857183],
            [38.990503541382203, 13.0610723117837],
            [38.9904327990536, 13.0616990839069],
            [38.990358688042697, 13.0621355263831],
            [38.9903777772425, 13.063117792592999],
            [38.990406972489197, 13.0640748967786],
            [38.9904911895471, 13.064814325586299],
            [38.990764052814598, 13.0662384854817],
            [38.9907696672852, 13.0665075654608],
            [38.990788756484903, 13.0669407179819],
            [38.990774158861598, 13.067426372935101],
            [38.990841532507901, 13.0678715558028],
            [38.990919012201097, 13.0688931763508],
            [38.990919012201097, 13.069518834812699],
            [38.990884202483898, 13.070145585492901],
            [38.990865113284102, 13.0710271902129],
            [38.990716891262203, 13.073149153920401],
            [38.990710153897602, 13.074043873354499],
            [38.9898006096724, 13.081279168617799],
            [38.981337356801902, 13.098072110344599],
            [38.976256260976101, 13.0987020694695],
            [38.988206100043101, 13.1033195331554],
            [39.010219316080402, 13.113970429396799],
            [39.019420310378003, 13.126511626531601],
            [39.020982256078298, 13.127219154945401],
            [39.022878824221898, 13.1269238960405],
            [39.023904026539903, 13.1265061587616],
            [39.024677700578302, 13.1258511190328],
            [39.025093171397302, 13.125497899890201],
            [39.025891549105999, 13.125519771059199],
            [39.026068966374602, 13.126230582994999],
            [39.026219434184704, 13.127339445508699],
            [39.026338460959899, 13.128063374745301],
            [39.026704524438102, 13.128823388793601],
            [39.027085185539804, 13.1290836520567],
            [39.027817312496403, 13.129349382750901],
            [39.028202465174402, 13.129852411261499],
            [39.028279944867698, 13.1302198401312],
            [39.028677449380901, 13.1313614906121],
            [39.036911631853997, 13.134228715832601],
            [39.041798466999602, 13.153934242044301],
            [39.041759165705898, 13.154325690321301],
            [39.042001710832601, 13.154685428549801],
            [39.042512627650403, 13.1551741905858],
            [39.042862970611203, 13.1554311456278],
            [39.043185241219398, 13.156045649785],
            [39.043234648560102, 13.157014420114001],
            [39.0430224215742, 13.1582565399],
            [39.042975260021798, 13.159466944585899],
            [39.042849495882002, 13.1602727843184],
            [39.042586738661399, 13.1607779362323],
            [39.042289171723503, 13.1611781207754],
            [39.0417580428118, 13.1615291017629],
            [39.041367275663198, 13.161872428473],
            [39.040836146751403, 13.1622124745174],
            [39.040539702707697, 13.1624617684238],
            [39.039972641184598, 13.1628149343569],
            [39.036457982635497, 13.1680095855906],
            [39.037159791451202, 13.168329942499801],
            [39.034680441267, 13.1707528330614],
            [39.016723118737502, 13.178699220995799],
            [39.011736346016498, 13.186385148731899],
            [39.000497298918098, 13.202708470549201],
            [38.999936974759599, 13.2028003004986],
            [38.999402477165503, 13.203249610824599],
            [38.998727617808299, 13.2036923610671],
            [38.998189751532003, 13.203749207953599],
            [38.9976485165733, 13.2037470215352],
            [38.997044399544698, 13.203634420957499],
            [38.996435790939699, 13.203694547486],
            [38.995479085162202, 13.2035611758937],
            [38.994232672705401, 13.203255076881799],
            [38.993198487234601, 13.203238678709701],
            [38.984789133281097, 13.202743453391101],
            [38.964747719292397, 13.2077514141458],
            [38.955801621956702, 13.2120027981339],
            [38.956802120604401, 13.216144792717101],
            [38.957062632036802, 13.2179211610372],
            [38.9567886458751, 13.219281030566499],
            [38.956424828185099, 13.219871325269301],
            [38.956148596235202, 13.220033109494],
            [38.955739862780902, 13.2203719815087],
            [38.955164940999097, 13.2206955491865],
            [38.954015097435402, 13.221596289380001],
            [38.953261635490797, 13.222444556229201],
            [38.9533391151841, 13.2232031841334],
            [38.953159452127302, 13.2242820932402],
            [38.953213351044297, 13.225329297310999],
            [38.953195384738599, 13.225744679725601],
            [38.951718778990397, 13.237504080783999],
            [38.930813859434799, 13.2369313177521],
            [38.930566822731699, 13.236329040668499],
            [38.929869505492398, 13.235944282115],
            [38.929564078295797, 13.235368236212899],
            [38.928623093035696, 13.2347386285075],
            [38.927966199984098, 13.2347768859666],
            [38.9185338895009, 13.2299061165868],
            [38.914807003965898, 13.2248822138686],
            [38.914165831431902, 13.2247357364744],
            [38.913685232754901, 13.224744481396],
            [38.913063149420601, 13.224781647308999],
            [38.912333268252297, 13.224154198489201],
            [38.911812245387502, 13.223472092016999],
            [38.911111559465901, 13.2226937887117],
            [38.910209875499397, 13.222499212497199],
            [38.909513681154202, 13.222401924331701],
            [38.909234080521998, 13.222470791239299],
            [38.909029713794901, 13.222640225611899],
            [38.908835453114698, 13.2227746799012],
            [38.908460406483599, 13.2231015237587],
            [38.908067393546801, 13.2232556539877],
            [38.907802390538002, 13.2234054116345],
            [38.907413869177603, 13.223686343614499],
            [38.906957974170901, 13.224027396789401],
            [38.906366208977502, 13.2242012025508],
            [38.905966458676097, 13.224194643845101],
            [38.90555997101, 13.224027396789401],
            [38.905092847062299, 13.2243914049401],
            [38.904932273205198, 13.2250669501581],
            [38.904837950100401, 13.225646299744],
            [38.9044056358699, 13.226010305477701],
            [38.903865523805301, 13.226009212368201],
            [38.903238948894597, 13.225620065075599],
            [38.8931733261361, 13.223402132271],
            [38.877256302195597, 13.221168875455399],
            [38.876640956225998, 13.220779720437701],
            [38.876027856044601, 13.2204353833172],
            [38.875686496236597, 13.220114001566101],
            [38.875364225628402, 13.219989384038399],
            [38.867151378143397, 13.2153096218491],
            [38.863606401453403, 13.2098055119778],
            [38.862558741253302, 13.2091681859741],
            [38.850234978449301, 13.214497404506499],
            [38.838636605237198, 13.2289146801043],
            [38.837839350422499, 13.2292349571598],
            [38.835370106285303, 13.2306603489164],
            [38.834652576952102, 13.2312429644657],
            [38.833782334020597, 13.232108687061899],
            [38.8330524528523, 13.2326563206008],
            [38.832440475565001, 13.232945986545801],
            [38.8259434102726, 13.235019547424301],
            [38.825633491499602, 13.234617297669001],
            [38.825215774892399, 13.2341494628789],
            [38.823998557682501, 13.2333919621201],
            [38.822727441555401, 13.2333515182904],
            [38.820458072568897, 13.2333154467609],
            [38.8176665578235, 13.236091845836601],
            [38.812214906942998, 13.238190520200501],
            [38.811775855347904, 13.2386583472293],
            [38.810984215003799, 13.239438785646399],
            [38.808562132418999, 13.2426610735239],
            [38.805825639484702, 13.2435770349534],
            [38.802647849167201, 13.245516063650101],
            [38.801730444683301, 13.247569843266],
            [38.7996968834588, 13.248148046321999],
            [38.798596447235802, 13.2479622345256],
            [38.7906328822421, 13.2510259250285],
            [38.790099507542102, 13.2503209407984],
            [38.788290525138699, 13.248838827756201],
            [38.781353285357099, 13.2490486850187],
            [38.7782642036738, 13.2502564537669],
            [38.776927959688699, 13.2488585018822],
            [38.775734323254902, 13.2498979492799],
            [38.772518354537802, 13.2514565655532],
            [38.769753789250899, 13.2531813060045],
            [38.767671943579998, 13.2540141610839],
            [38.760799831656399, 13.2558700404918],
            [38.759849863243502, 13.255908294629901],
            [38.759590474705199, 13.256183724246],
            [38.761381490802897, 13.259557711727499],
            [38.759478185294697, 13.260175232712699],
            [38.751535955289, 13.2605184206883],
            [38.750791476497298, 13.260709687662001],
            [38.750205325774402, 13.2610561537397],
            [38.7476226693325, 13.2623010230487],
            [38.746237018006802, 13.2626212561772],
            [38.743228784699099, 13.2650126083175],
            [38.738079192332897, 13.264067218258701],
            [38.733469712031201, 13.2622234238312],
            [38.732025670212003, 13.261658369627201],
            [38.7303884906067, 13.2621622187971],
            [38.730375015877399, 13.2630158090422],
            [38.730765783026001, 13.267541645263901],
            [38.730188615456001, 13.268094663664799],
            [38.729545197133703, 13.269089219281801],
            [38.729366656971003, 13.2702018052463],
            [38.729602464733098, 13.2711143842329],
            [38.731442888171401, 13.275167942150301],
            [38.7335561748773, 13.276614922443301],
            [38.734608326653799, 13.277369009963801],
            [38.734780129451899, 13.279044387333901],
            [38.734891295968303, 13.2812530700343],
            [38.736072580566997, 13.2826114928074],
            [38.740045379911002, 13.2867741424351],
            [38.740643882469001, 13.2950773714451],
            [38.7391257296389, 13.297778755547199],
            [38.7364319066806, 13.2987076225759],
            [38.733328227374002, 13.2999796182384],
            [38.732744322439302, 13.300900827424],
            [38.7330407664831, 13.3023935504142],
            [38.734098532730101, 13.303769338936799],
            [38.735038395096097, 13.304864281805299],
            [38.736209573647798, 13.304856632521],
            [38.737089922626197, 13.304568145050601],
            [38.7382599782838, 13.3041080942451],
            [38.740369896307399, 13.304390025721201],
            [38.741191854792298, 13.3047353366946],
            [38.741603956928898, 13.3062269433144],
            [38.741783619985704, 13.307664996386],
            [38.741256982650398, 13.308532632179899],
            [38.740849372090302, 13.3096264607858],
            [38.740031905181702, 13.3108284645458],
            [38.738743945643101, 13.3118119177302],
            [38.737516622386202, 13.3128510955884],
            [38.737052867120802, 13.315437547296201],
            [38.737405455869798, 13.3165848871149],
            [38.737644632314201, 13.3181408897614],
            [38.737998343957301, 13.3195165888256],
            [38.738235274613501, 13.3209545629673],
            [38.738414937670299, 13.322274519551099],
            [38.738532841551297, 13.3230787279794],
            [38.738302648259797, 13.324633596205199],
            [38.737718743325097, 13.3258453593832],
            [38.737016934509398, 13.3268844769852],
            [38.736668837336801, 13.328492860516899],
            [38.736378007763598, 13.329930781303],
            [38.736791232794303, 13.331080237666299],
            [38.737788362759602, 13.3317052249546],
            [38.738962909993603, 13.332285412757001],
            [38.740076820945902, 13.3327923930491],
            [38.741013314629598, 13.332738854232201],
            [38.742010444595003, 13.332676574369099],
            [38.743125478441399, 13.3335375647381],
            [38.743421922485197, 13.3348028216705],
            [38.743257979945803, 13.335915105973299],
            [38.742782995739297, 13.337732117354999],
            [38.742667337646502, 13.3387722764712],
            [38.742034025371197, 13.3403991550183],
            [38.741081811169998, 13.340677766431201],
            [38.740382248142502, 13.341526709814101],
            [38.738303771153902, 13.3432977905884],
            [38.737343696693998, 13.3440560408183],
            [38.735221426835302, 13.3454010004282],
            [38.734437646749903, 13.346141762961301],
            [38.7336179340531, 13.3468552090937],
            [38.733286680292103, 13.3473708993196],
            [38.733101402764703, 13.348058120387901],
            [38.7330890509296, 13.348636083810399],
            [38.733138458270197, 13.3494227236749],
            [38.733233904269099, 13.3512866129008],
            [38.733624671417701, 13.352118038330399],
            [38.733875076803201, 13.3528871862437],
            [38.734027790401498, 13.3540813272243],
            [38.7340614772246, 13.354640703217999],
            [38.733994103578297, 13.3553650495023],
            [38.733677447440698, 13.3560522478135],
            [38.733043012271303, 13.356630192096199],
            [38.732371521596399, 13.357098883135],
            [38.731897660283998, 13.357469246738599],
            [38.731088053634203, 13.3581651791699],
            [38.730650124933199, 13.358561761431501],
            [38.730261603572799, 13.359086166727],
            [38.729955053482101, 13.359646623626499],
            [38.729732720449299, 13.3602606119517],
            [38.729567655015799, 13.3608494711728],
            [38.729327355677299, 13.361780280961501],
            [38.7292027144317, 13.3632092636471],
            [38.729231909678397, 13.3641040122571],
            [38.730074080257197, 13.3663381380664],
            [38.730516500534698, 13.3667816833195],
            [38.731481066571, 13.367461201804501],
            [38.732428789195701, 13.3679233169792],
            [38.734059231436397, 13.368512157490599],
            [38.734432032279301, 13.3688835960345],
            [38.734618432700799, 13.3694527698693],
            [38.734584745877598, 13.3701224486875],
            [38.734429786491098, 13.370602038084501],
            [38.734149062964804, 13.3712979326198],
            [38.733866093650299, 13.372482150412999],
            [38.7338312839331, 13.3730327441582],
            [38.73394806492, 13.3736390512915],
            [38.734335463386302, 13.3742464493405],
            [38.737546940526997, 13.3751619132915],
            [38.738217308307803, 13.3758053578297],
            [38.7381847443787, 13.3765733377712],
            [38.737994975275001, 13.3770343430468],
            [38.737768150665701, 13.377459297365499],
            [38.736956298227703, 13.3786074349768],
            [38.736075949249297, 13.379816742414301],
            [38.735018183002197, 13.3810457071674],
            [38.733739206616498, 13.3828798571222],
            [38.733288926080299, 13.3838564625367],
            [38.732666842745999, 13.385673112854899],
            [38.731991983388802, 13.3884029783276],
            [38.7321031499052, 13.3893172960972],
            [38.732268215338699, 13.389941040157501],
            [38.732781377944796, 13.391054162581501],
            [38.733127229329099, 13.3918504952882],
            [38.733458483090203, 13.3926009463325],
            [38.7337212403108, 13.393469369292401],
            [38.733815563415597, 13.3941105802087],
            [38.733830161039002, 13.3948162379472],
            [38.733756050027999, 13.395873628331399],
            [38.733845881556398, 13.3968425346548],
            [38.734064845907, 13.397863869113401],
            [38.7340165614604, 13.398434066785899],
            [38.733624671417701, 13.399383300908999],
            [38.7331990945519, 13.399807123450501],
            [38.732721864557199, 13.400033234655499],
            [38.732243511668401, 13.4002036371622],
            [38.7305153776406, 13.400762906079599],
            [38.727585746920198, 13.402242996255101],
            [38.726929976762797, 13.4029114915619],
            [38.726406708109799, 13.4044024915554],
            [38.726207955853198, 13.405070980858],
            [38.726004712020199, 13.405495885673901],
            [38.724625798059101, 13.4064625686398],
            [38.7234029663786, 13.406994515687201],
            [38.722354183284303, 13.407399755871699],
            [38.720802343631, 13.408356600604201],
            [38.720752936290403, 13.4084275992054],
            [38.720526111681203, 13.4085728732009],
            [38.720910141465097, 13.408202588181901],
            [38.718707023230799, 13.402839399020101],
            [38.705662362411303, 13.3892878020298],
            [38.699481953256601, 13.3890540341086],
            [38.696956564414101, 13.388916394945999],
            [38.694677089380598, 13.3888683304579],
            [38.692756940460796, 13.3890376484981],
            [38.6841140245335, 13.3902381646056],
            [38.672392132969797, 13.3917707528999],
            [38.670448403273802, 13.391975024444401],
            [38.667956701254496, 13.391800246663101],
            [38.665645785186101, 13.3918515876495],
            [38.658358202443701, 13.394255862853401],
            [38.656562694769498, 13.3947244807113],
            [38.651842047951497, 13.3955863419409],
            [38.649543483718197, 13.3961073896279],
            [38.647433565694698, 13.396737670122601],
            [38.645648164067502, 13.397611539827],
            [38.642532132925702, 13.399271883519001],
            [38.640623212946899, 13.4003172387663],
            [38.633982417209097, 13.4032282615648],
            [38.632358712333001, 13.404039846253999],
            [38.630591277011497, 13.4051583649431],
            [38.627249544154601, 13.4072151586756],
            [38.6254327014925, 13.408732346809201],
            [38.623748360334801, 13.410330353903801],
            [38.622640063852998, 13.411092762293499],
            [38.617829585506499, 13.4283194884233],
            [38.615765706141197, 13.451389917431101],
            [38.633258150511303, 13.4727011141522],
            [38.648156709498402, 13.481367015327701],
            [38.661282218693501, 13.4847160136807],
            [38.674495313628803, 13.48400188494],
            [38.673232057760501, 13.491838621281699],
            [38.6737171480139, 13.4973843463334],
            [38.6724696126631, 13.5091960163578],
            [38.669259258416503, 13.514495682639],
            [38.666046658381603, 13.5217233029363],
            [38.664806983289502, 13.5267846901905],
            [38.662126635060602, 13.531171284370799],
            [38.654417967028699, 13.5241251825995],
            [38.652717905353498, 13.522616368293599],
            [38.651423208450304, 13.5212658512917],
            [38.646969810429198, 13.517453356171499],
            [38.644784658500598, 13.515069969850501],
            [38.642315414363402, 13.513050132279499],
            [38.639441928348297, 13.5107005483425],
            [38.636852534541902, 13.507842147822601],
            [38.6350738702793, 13.504667077598601],
            [38.6306204722583, 13.5018053291983],
            [38.622443557384599, 13.4953687265733],
            [38.614021851596, 13.4906757387949],
            [38.605366583833501, 13.490680106439299],
            [38.590089609532903, 13.506113770613799],
            [38.580888615235303, 13.504691098233501],
            [38.578197038065298, 13.502216960135399],
            [38.574142267451599, 13.499244903313301],
            [38.571775206677898, 13.496148334500999],
            [38.570559112361998, 13.4916169643055],
            [38.568726549182401, 13.4877887084068],
            [38.567537404325101, 13.4862392628075],
            [38.566083256458903, 13.4852980161064],
            [38.563356869571599, 13.4838184386804],
            [38.560747263671203, 13.4828193092586],
            [38.551778708453398, 13.482840056250801],
            [38.545087382480801, 13.4833794774162],
            [38.536493873894102, 13.4824414952958],
            [38.532919701957397, 13.481296038330401],
            [38.530974849367297, 13.4810208657725],
            [38.524979717739903, 13.4783237211534],
            [38.522869799716297, 13.4765743827462],
            [38.520759881692797, 13.4752421688819],
            [38.519081155005601, 13.4743522022058],
            [38.511719461252198, 13.472900948828499],
            [38.507789331884197, 13.472088505169401],
            [38.5053245793234, 13.4716047504432],
            [38.5032730517933, 13.4712924387459],
            [38.502035622489402, 13.471579634482699],
            [38.5007409255862, 13.471930165694101],
            [38.486503751226998, 13.469703573971],
            [38.481761769420899, 13.4697483462501],
            [38.475619538665804, 13.4667693334133],
            [38.472823532343902, 13.4647567315328],
            [38.4710448680814, 13.4635773367808],
            [38.469379616123398, 13.462715719718499],
            [38.467752542565101, 13.4619163459819],
            [38.460968016381798, 13.459550970354099],
            [38.460982614005097, 13.4635118146797],
            [38.461778745925699, 13.4639377080161],
            [38.4640323943947, 13.4648735786594],
            [38.464545557000797, 13.465156413991201],
            [38.464973379654801, 13.465879335212099],
            [38.465596585883198, 13.466965896986901],
            [38.466490409590897, 13.468423738450801],
            [38.467204570241798, 13.4697821984555],
            [38.467865954869701, 13.4709233425778],
            [38.4695637707567, 13.4745968068738],
            [38.469357158241301, 13.4752476287899],
            [38.468070321596798, 13.476410586359799],
            [38.465952543314501, 13.478594528951101],
            [38.4655920943068, 13.4792639033606],
            [38.465155288499901, 13.480076322638],
            [38.465150796923503, 13.480898566756199],
            [38.466886791210001, 13.4851615241075],
            [38.467872692234302, 13.4846210150264],
            [38.4720274004234, 13.4844987178525],
            [38.475345552504102, 13.4844015352322],
            [38.476230393058998, 13.4845751535935],
            [38.477411677657599, 13.4848306671788],
            [38.478573873056398, 13.485394106426799],
            [38.479976367793697, 13.4860296118911],
            [38.498233503049399, 13.484445212819301],
            [38.489907243259701, 13.494567278234801],
            [38.491363636914102, 13.4969672476496],
            [38.492718970098998, 13.4996095882513],
            [38.493442113902702, 13.500858684534499],
            [38.494791832617103, 13.503167966826499],
            [38.496781600971403, 13.506778700535801],
            [38.500612915658202, 13.512175591892801],
            [38.503247225228897, 13.511412413578499],
            [38.504846226434601, 13.511017175780401],
            [38.506852838200501, 13.510912361226801],
            [38.508121708539299, 13.510951666689801],
            [38.510816654391697, 13.5116984692558],
            [38.515609166432398, 13.5146103218317],
            [38.517443975400298, 13.5161508488724],
            [38.518844224349401, 13.5167775381009],
            [38.521230374322798, 13.517307056460901],
            [38.5230674290789, 13.517535240298701],
            [38.5303583805036, 13.517974138740801],
            [38.533133051837403, 13.5185768039415],
            [38.534746650666499, 13.5192580758114],
            [38.536616269351597, 13.5202919894957],
            [38.542436229498598, 13.52579119945],
            [38.5463248117847, 13.5315664887382],
            [38.548359495903199, 13.534083990661401],
            [38.550287505081798, 13.5370174035899],
            [38.551046581496898, 13.538754290145601],
            [38.553923436194196, 13.5502975496061],
            [38.550439095785997, 13.5521456854108],
            [38.548017013201203, 13.552972047450799],
            [38.539100111112198, 13.553833338514],
            [38.533710219407503, 13.553407605679],
            [38.531072541154501, 13.553158715360601],
            [38.524389075440602, 13.5545570825835],
            [38.518518585058899, 13.5550788755829],
            [38.5164086670353, 13.5552731831436],
            [38.514254956141599, 13.555549361594],
            [38.512616653642198, 13.5557993410213],
            [38.506805676648099, 13.556394269168599],
            [38.501427013884403, 13.5578482891238],
            [38.491559020488403, 13.559664709959801],
            [38.489001067716799, 13.5574258382971],
            [38.4871999455722, 13.555251350841299],
            [38.479894396524102, 13.551449224815901],
            [38.479014047545597, 13.551916443120501],
            [38.475739688334997, 13.5529862385836],
            [38.4737353223573, 13.5555679190277],
            [38.473492777230597, 13.557158394879],
            [38.473125590858203, 13.5590032025373],
            [38.4728280239204, 13.5641020017306],
            [38.466734077611697, 13.5680392782912],
            [38.463648364610798, 13.5672282505047],
            [38.459762028112799, 13.5617856693571],
            [38.458361779163702, 13.5593961774658],
            [38.457360157621899, 13.558245632368299],
            [38.455166022540503, 13.556704286181599],
            [38.453319984631598, 13.556763233032701],
            [38.452311625725201, 13.5573396014727],
            [38.445394598037502, 13.5598382734835],
            [38.444044879323101, 13.559237895975601],
            [38.443311629472397, 13.559154934600601],
            [38.442150556967697, 13.559803342470101],
            [38.4416508690909, 13.560679891035701],
            [38.441428536058098, 13.561565169056299],
            [38.441412815540602, 13.5634274077347],
            [38.4432487474025, 13.5689299866384],
            [38.445525976647801, 13.5744313464741],
            [38.447686424906102, 13.579590938496899],
            [38.451015805927803, 13.5845888792681],
            [38.443394723636203, 13.586995581023199],
            [38.442463844423003, 13.586252289347099],
            [38.441562160456598, 13.585155356845499],
            [38.440310133529302, 13.5832179761004],
            [38.437872330427098, 13.5820915592862],
            [38.434715875097503, 13.580610400352001],
            [38.432053493174202, 13.579501435212601],
            [38.430665596060201, 13.5788378000512],
            [38.429210325299898, 13.5779307562272],
            [38.425238648849998, 13.574106071205501],
            [38.425178012568303, 13.571963391096601],
            [38.425969652912499, 13.5710061100331],
            [38.427184624334302, 13.5701950923824],
            [38.428484935707999, 13.5693578747542],
            [38.427505772048299, 13.5641107343264],
            [38.4252442633206, 13.5626316463346],
            [38.423712635761099, 13.5614712926285],
            [38.422531351162498, 13.560338223050501],
            [38.4216128237845, 13.5593503304243],
            [38.420226049564597, 13.557529540766],
            [38.4190840662597, 13.5558364558484],
            [38.418015071071601, 13.553753650118299],
            [38.416719251274301, 13.551417567467601],
            [38.414978765411298, 13.549605450161099],
            [38.413176520372502, 13.547341375756201],
            [38.408159429510697, 13.541542452359399],
            [38.4072712202735, 13.540537014701499],
            [38.405917009982701, 13.538571977696],
            [38.404593117832697, 13.5369497182506],
            [38.399360431302803, 13.539668033671701],
            [38.399961179648997, 13.542662511456699],
            [38.400411460185197, 13.5447705226079],
            [38.399664735605199, 13.547390500068101],
            [38.3970495152344, 13.547077195949599],
            [38.393185636618497, 13.5524480665698],
            [38.3951529470908, 13.555617041639501],
            [38.395370788547197, 13.5568560197131],
            [38.394880083823203, 13.5579399837777],
            [38.394207470254202, 13.5583275024598],
            [38.390144839381797, 13.555423825974501],
            [38.388326873825598, 13.5530604691106],
            [38.386173162931897, 13.5523585530538],
            [38.384924504687, 13.550790969431199],
            [38.381624318911904, 13.546166755855101],
            [38.378181525585497, 13.541343766861599],
            [38.376523010992202, 13.539021754265001],
            [38.374623074166301, 13.536358016561801],
            [38.371285832885803, 13.5316822114399],
            [38.368963687876402, 13.5313143003916],
            [38.368031685769097, 13.5326691272892],
            [38.368260756166499, 13.534839454388299],
            [38.377837919989403, 13.543939765394599],
            [38.371030935923898, 13.5487670683241],
            [38.3706345543048, 13.549915476029099],
            [38.369873232101497, 13.550925240578101],
            [38.367008729239302, 13.5520299726633],
            [38.363937613861701, 13.552275589277199],
            [38.361260634315002, 13.5531095922407],
            [38.358594883709401, 13.552877076001799],
            [38.350438180929601, 13.5438950070392],
            [38.349027825933497, 13.542308809153599],
            [38.348774051865803, 13.5409977043621],
            [38.341173181667997, 13.5376320292572],
            [38.338775802753503, 13.540836135577999],
            [38.337526021614501, 13.5421887249188],
            [38.3362279560289, 13.543433231320201],
            [38.334541369082999, 13.5446504396159],
            [38.332588656234101, 13.545892749820601],
            [38.330994146604802, 13.547985449261301],
            [38.329865638029197, 13.549375113779099],
            [38.32899876378, 13.5505486261938],
            [38.327377304692099, 13.552271222761799],
            [38.324156844398601, 13.5503816057106],
            [38.324625091240399, 13.544948463657301],
            [38.324749732486097, 13.543864440352699],
            [38.3249754342012, 13.5427793204351],
            [38.325778303486402, 13.541605769681],
            [38.326610368018301, 13.5354147842598],
            [38.326015234142602, 13.534437705190401],
            [38.3253976423848, 13.5333230659578],
            [38.320956596198897, 13.5263392574919],
            [38.320413115451998, 13.5256601893728],
            [38.318565954649003, 13.5228707493782],
            [38.320135760607997, 13.520487417238099],
            [38.324022097106003, 13.510579356349799],
            [38.322921660882898, 13.5095999917706],
            [38.322101948186202, 13.508485236335099],
            [38.319833702093803, 13.5021416219003],
            [38.317026466830903, 13.5002254022709],
            [38.315928276396001, 13.4984773161289],
            [38.313490473293797, 13.499682743546099],
            [38.31171966929, 13.5015159860721],
            [38.298445938073002, 13.4927514527688],
            [38.296943505760296, 13.4924762934057],
            [38.294474261623101, 13.4917097763649],
            [38.2932997143891, 13.4912719215096],
            [38.290856296816301, 13.490288110041],
            [38.2899085741915, 13.489852436409],
            [38.278894105914098, 13.485508759599499],
            [38.268037965705503, 13.487547393015699],
            [38.267264291667097, 13.4880332993236],
            [38.259118817828302, 13.486192309753701],
            [38.253924309697901, 13.4904529888273],
            [38.253054066766403, 13.4918615513019],
            [38.249760618355999, 13.4961559777033],
            [38.249333918596101, 13.5009733302819],
            [38.2505331695004, 13.502549976687],
            [38.2459360410339, 13.501542190746999],
            [38.2431052249948, 13.496723757766899],
            [38.235534672937902, 13.4938258815317],
            [38.228486266639798, 13.4897989325748],
            [38.225583585377997, 13.489427681150501],
            [38.220676538138498, 13.4892409633042],
            [38.219646844244103, 13.489246422892499],
            [38.214785835662902, 13.4890684402523],
            [38.213895380637503, 13.488812931199501],
            [38.212451338818298, 13.4879230150573],
            [38.211190328738198, 13.4870418310215],
            [38.209903492093702, 13.485906223505401],
            [38.209118589114198, 13.485009744913301],
            [38.200874300594201, 13.4801276447308],
            [38.190712108942598, 13.4834045921371],
            [38.186144175722902, 13.4852980161064],
            [38.184034257699302, 13.4865657500661],
            [38.183212299214297, 13.4874862451856],
            [38.182390340729398, 13.488404552915201],
            [38.180748669547597, 13.489719222758801],
            [38.175646238733798, 13.493165283118801],
            [38.174590718274999, 13.493560550488001],
            [38.172949047093297, 13.49361842107],
            [38.171602697061203, 13.493388030556501],
            [38.165923098677297, 13.489761807458301],
            [38.165339193742703, 13.4884394944],
            [38.164520603939998, 13.4875146352515],
            [38.163642500749802, 13.4864270749305],
            [38.159485546772501, 13.483601142035999],
            [38.158782615062698, 13.4832549957169],
            [38.158140319634597, 13.482565977418901],
            [38.157555291805799, 13.481930462742699],
            [38.156448118218101, 13.4773333045386],
            [38.155394843547498, 13.4766977759498],
            [38.154691911837602, 13.476180179253101],
            [38.154165274502297, 13.4758886211267],
            [38.150006074736901, 13.4749080222728],
            [38.149304265921103, 13.474561863365199],
            [38.145499900692897, 13.471800218046299],
            [38.144838516065001, 13.471535954544899],
            [38.140662472887897, 13.4749746332354],
            [38.139373390455198, 13.475612350364299],
            [38.138024794634902, 13.476241329976],
            [38.135851994541397, 13.478131534788201],
            [38.134678570201601, 13.4792224087535],
            [38.1310403933009, 13.4826085633935],
            [38.129159545674803, 13.485144053126101],
            [38.128571149163697, 13.4870396471661],
            [38.128452122388502, 13.488359784130299],
            [38.128682315680102, 13.4918047722001],
            [38.127977138181997, 13.492788577420599],
            [38.125569653220602, 13.4954287804958],
            [38.124570277467001, 13.497325384783601],
            [38.123747196087898, 13.498876942197301],
            [38.123276703457897, 13.500194829981201],
            [38.122212199846203, 13.506059178583801],
            [38.121879823191101, 13.506854037306599],
            [38.121151064916802, 13.506407475520501],
            [38.120604215487603, 13.506659690226201],
            [38.1189906166585, 13.5054881452016],
            [38.118469593793698, 13.503649473935299],
            [38.118138340032701, 13.501976751186801],
            [38.118027173516303, 13.5000234067127],
            [38.116849257600002, 13.495904843783],
            [38.116040773844297, 13.4949527162593],
            [38.114886438704197, 13.4936653726639],
            [38.113416570320602, 13.492485028628399],
            [38.111279702838502, 13.4919980394723],
            [38.1088340394775, 13.491194396002699],
            [38.106260366188401, 13.490578558687501],
            [38.104443523526299, 13.4903634520171],
            [38.1025087769831, 13.4899681793562],
            [38.100159682515198, 13.4895172183115],
            [38.097008841656098, 13.4888009200835],
            [38.093714270351597, 13.4879928981626],
            [38.091612212586803, 13.4874338327473],
            [38.088965551180898, 13.4864751198685],
            [38.086706288241402, 13.485462898337],
            [38.083821573285199, 13.484168951946801],
            [38.081904793047798, 13.483157812590701],
            [38.079712903754498, 13.4816127047708],
            [38.0775816507429, 13.4803405767002],
            [38.075048401641702, 13.478883899847499],
            [38.0723489642129, 13.477355143979],
            [38.070609601244101, 13.476345067773901],
            [38.069047655543798, 13.4754441853961],
            [38.067103925847803, 13.4727611737719],
            [38.064676228792401, 13.4696227654462],
            [38.060899935916801, 13.465984169527999],
            [38.059298688922901, 13.467487881693],
            [38.058054522254402, 13.468071018072299],
            [38.055201248333198, 13.466387126002401],
            [38.054493825046897, 13.466311776469301],
            [38.053213725767101, 13.466760597250801],
            [38.0520290724861, 13.4674507531114],
            [38.050636683795801, 13.468540583786501],
            [38.049785530064099, 13.4696937459086],
            [38.048993889719902, 13.4738684520586],
            [38.047707053075399, 13.4750663597765],
            [38.047404994561099, 13.475472576892599],
            [38.047477982677997, 13.478853324826],
            [38.046249536526901, 13.479744366707701],
            [38.0451367484687, 13.4805262095445],
            [38.043455775993301, 13.4812796590204],
            [38.037854780196803, 13.4820352900222],
            [38.037336003120302, 13.482042933676199],
            [38.036913794936702, 13.482276610980399],
            [38.036275991084999, 13.4835301657023],
            [38.036126646169002, 13.4840903321934],
            [38.029957465955299, 13.4830180435311],
            [38.029693585840597, 13.482474253755599],
            [38.025129021303201, 13.486362651021199],
            [38.024465390887002, 13.4858636381185],
            [38.023596270849701, 13.4846930829745],
            [38.020705941423003, 13.481245808442701],
            [38.016675874479603, 13.4842235489825],
            [38.015751732631102, 13.4851233063339],
            [38.015099331156001, 13.485754444784201],
            [38.014762462924502, 13.4864762117988],
            [38.014379556034598, 13.487170678456399],
            [38.013948364698201, 13.487747215280001],
            [38.013650797760299, 13.489038958452401],
            [38.012033830248903, 13.494629516144901],
            [38.010658284970098, 13.4950313324372],
            [38.009335515714298, 13.4961286805509],
            [38.009040194564598, 13.4974935343502],
            [38.008619109275202, 13.499444715788499],
            [38.008039695916899, 13.5007702434918],
            [38.007937512553397, 13.501275776417801],
            [38.007725285567503, 13.5017824001331],
            [38.001953609867002, 13.501360941686499],
            [38.000357977343597, 13.5008128262202],
            [37.996180811272403, 13.4998738207026],
            [37.994530156937898, 13.4997580825575],
            [37.9930075125313, 13.4995353410636],
            [37.989609634969099, 13.498807062277301],
            [37.987605268991402, 13.498691323614899],
            [37.985302213181697, 13.497976144786],
            [37.983178820428897, 13.4977064508392],
            [37.980910574336498, 13.496937766825001],
            [37.978596289585802, 13.4963219443231],
            [37.975433096891599, 13.4956679041463],
            [37.974713321770203, 13.495537969407],
            [37.969907335000201, 13.494749624346699],
            [37.967367348534303, 13.4931849373129],
            [37.967037217667396, 13.492839896782501],
            [37.9666093950133, 13.4923856654507],
            [37.966279264146401, 13.4920580942425],
            [37.965248447357901, 13.491259910517201],
            [37.964517443295399, 13.4907761945947],
            [37.963502347024402, 13.4901210473137],
            [37.963125054605101, 13.489857895983199],
            [37.9626511932927, 13.489521585977],
            [37.961586689680999, 13.4890193039172],
            [37.960580576562798, 13.488418747894],
            [37.958643584231403, 13.487832385374601],
            [37.946463551872803, 13.466404598354501],
            [37.933489633381903, 13.460478121757999],
            [37.931386452722997, 13.4595171167014],
            [37.929775099682097, 13.4587810715832],
            [37.928277158945797, 13.458095258961899],
            [37.926195313274903, 13.4571440729184],
            [37.924377347718703, 13.4563141026753],
            [37.922390948046598, 13.455406592446099],
            [37.920557261972903, 13.454568971649399],
            [37.918507980230999, 13.453631968642499],
            [37.916245348609202, 13.452598858514101],
            [37.904077668085797, 13.4470378974396],
            [37.887170251544497, 13.453358948428701],
            [37.875123843584497, 13.4676942728212],
            [37.866376498505403, 13.4751165909626],
            [37.854006697042998, 13.4721310930104],
            [37.849709381302603, 13.4710926027256],
            [37.847737579254002, 13.470616490004399],
            [37.844676569923401, 13.469878295012601],
            [37.841250620008601, 13.469050552948399],
            [37.838602835708599, 13.468410634297401],
            [37.836980253726701, 13.468019693391801],
            [37.834759169186697, 13.4674824216078],
            [37.8326065811871, 13.466962620928699],
            [37.830165409402497, 13.4663729297154],
            [37.816881572138598, 13.463164547244499],
            [37.811900413888203, 13.4796613776531],
            [37.8108404018529, 13.4939809308117],
            [37.810641649596299, 13.496668071705001],
            [37.810385629740303, 13.5001282260507],
            [37.810162173813403, 13.503150497162499],
            [37.810025180732602, 13.5049946315025],
            [37.809949946827501, 13.5060078620642],
            [37.808982012108899, 13.5190833909022],
            [37.792636042620202, 13.520604237097],
            [37.784916145647301, 13.5392182583244],
            [37.7771344894986, 13.5506457818457],
            [37.769001367495001, 13.562587983080499],
            [37.773843286876399, 13.5743352920788],
            [37.784125628197302, 13.587708309325601],
            [37.784621947391798, 13.5973031918017],
            [37.774741602160603, 13.611642947378501],
            [37.7654249497701, 13.599781797524599],
            [37.763660883130903, 13.5992120806714],
            [37.762962442997498, 13.5975323902488],
            [37.7630062358676, 13.596253241795001],
            [37.760339362367901, 13.596550109628399],
            [37.757040299487002, 13.597595692637899],
            [37.757577042869201, 13.5961430076886],
            [37.7587010598685, 13.5945047700225],
            [37.759532001506301, 13.593350029405],
            [37.760942356502397, 13.5933118290049],
            [37.761340983909697, 13.592815223242599],
            [37.761187147417303, 13.592250946860601],
            [37.760832312880098, 13.5916189993755],
            [37.760526885683497, 13.591400709697099],
            [37.7595600738589, 13.5905744814439],
            [37.759336617932, 13.590230673594199],
            [37.759287210591403, 13.589447008123599],
            [37.758980660500697, 13.5889176504551],
            [37.758839175843399, 13.588848888444399],
            [37.7584641292123, 13.589081369447999],
            [37.755413225928599, 13.5910012390651],
            [37.754932627251598, 13.5912217120108],
            [37.752701436664701, 13.591976994012599],
            [37.752533002548901, 13.590410763482399],
            [37.751257394845503, 13.5877901692538],
            [37.750750969603999, 13.5873874181346],
            [37.750055898153001, 13.5868482326382],
            [37.749854900108097, 13.586146416481199],
            [37.749863883261, 13.585144442068801],
            [37.7503759229729, 13.583357686340101],
            [37.746809611294999, 13.580707543704801],
            [37.746808488400902, 13.580397558035401],
            [37.746852281271003, 13.5791641603379],
            [37.746151595349303, 13.576333870406099],
            [37.7477955123193, 13.570881674126699],
            [37.744434690262601, 13.570995194605301],
            [37.7395287659172, 13.573484990679001],
            [37.738835940254297, 13.573590869196501],
            [37.736839434535298, 13.5740001929179],
            [37.736005124215197, 13.573967447046099],
            [37.735487470032702, 13.5735548486753],
            [37.734823839616602, 13.571668675559099],
            [37.734188281553102, 13.5710355816855],
            [37.734138874212498, 13.5703228032864],
            [37.734150103153503, 13.5698730859517],
            [37.733184414223103, 13.568932169743],
            [37.729476617887897, 13.5663964802336],
            [37.728431203475999, 13.566538383504399],
            [37.727276868335899, 13.565390056141201],
            [37.726874872246199, 13.5643312322638],
            [37.726330268605203, 13.562709148590899],
            [37.726033824561497, 13.561569535400899],
            [37.725842932563602, 13.560464847664599],
            [37.725759838399803, 13.559889578400201],
            [37.725708185271003, 13.557804624990499],
            [37.725445428050399, 13.5560209382851],
            [37.725031080125603, 13.5551345479902],
            [37.724406751003102, 13.5541815655737],
            [37.723594898565104, 13.5536543124917],
            [37.7232288350868, 13.5530899429898],
            [37.723097456476502, 13.552031064293301],
            [37.723258030333596, 13.550568275654699],
            [37.721911680301503, 13.5475542477009],
            [37.720440689023697, 13.5465684852474],
            [37.719698456020197, 13.5456951596157],
            [37.718427339893204, 13.5450543548935],
            [37.715297834022202, 13.543277122540999],
            [37.714226593045801, 13.5425555274822],
            [37.713943623731303, 13.5418994308314],
            [37.714139007305597, 13.540276102389001],
            [37.715917671568199, 13.537033779072299],
            [37.716502699396997, 13.536030505769499],
            [37.715166455411897, 13.532463884064599],
            [37.714273754598302, 13.532535937557199],
            [37.711817985190301, 13.5323350610992],
            [37.710795028660499, 13.532027195763799],
            [37.710112309044597, 13.531661468695599],
            [37.709288104771403, 13.5307302267883],
            [37.708683987742802, 13.528682136813799],
            [37.708934393128303, 13.5251110380149],
            [37.709037699386002, 13.5241208155675],
            [37.7090455596447, 13.5224853564703],
            [37.708714305883703, 13.5215540786833],
            [37.706770576187701, 13.5190932169318],
            [37.706488729767301, 13.5141080925794],
            [37.706392160874202, 13.5131986182188],
            [37.7056611568118, 13.5119910754713],
            [37.704591038729603, 13.511660255731],
            [37.702300334755101, 13.5117465091068],
            [37.701628844080197, 13.510816281178901],
            [37.701321171095401, 13.509999599208401],
            [37.700526162068897, 13.5065461390497],
            [37.700344253223903, 13.5040589179912],
            [37.700505949975003, 13.502873166076199],
            [37.700456542634399, 13.501963648873501],
            [37.700573323621299, 13.501443923201199],
            [37.700665400938, 13.5007527736523],
            [37.700828220583197, 13.4999469759163],
            [37.700646311738197, 13.4977468503567],
            [37.7005519886333, 13.4975524958585],
            [37.700434084752303, 13.497379978811701],
            [37.699891726899502, 13.496483543289401],
            [37.696936269614802, 13.4953927481441],
            [37.697039575872402, 13.4943499913689],
            [37.697003643261098, 13.4941436232578],
            [37.6968273488866, 13.493739621677401],
            [37.6971507423888, 13.491867010830299],
            [37.697343880174898, 13.491555817517201],
            [37.695811129721399, 13.4884875389332],
            [37.693923544730602, 13.4881719735268],
            [37.692483994487802, 13.4874720501514],
            [37.691721549390401, 13.4871007951105],
            [37.690846814882498, 13.486415063694499],
            [37.690660414461099, 13.486056910197901],
            [37.690339266747003, 13.4851997418751],
            [37.690232591807003, 13.483985505815401],
            [37.690256172583197, 13.4835924064884],
            [37.690487488768902, 13.481735003421701],
            [37.6908288485768, 13.4805404049914],
            [37.691335273818297, 13.4791743623573],
            [37.691795660401397, 13.4780179700453],
            [37.692423358206199, 13.4764946685189],
            [37.692921923188798, 13.4751864778128],
            [37.693545129417203, 13.4738946597666],
            [37.694277256373802, 13.4703817092999],
            [37.690119179502403, 13.469546324924501],
            [37.688458419120899, 13.4691302696571],
            [37.686811133468602, 13.465858586748199],
            [37.683906206418598, 13.4646224118682],
            [37.683507579011298, 13.461365955955699],
            [37.683110074498003, 13.458109455739899],
            [37.680201778765699, 13.4572805809066],
            [37.677713445428701, 13.4556315592014],
            [37.678560107583998, 13.453195136151001],
            [37.6781502512356, 13.451972000936401],
            [37.677738149099, 13.4511562099597],
            [37.676495105324598, 13.4503360478238],
            [37.6756697781573, 13.4491118059101],
            [37.6740101406699, 13.4482894525663],
            [37.671927172104901, 13.4486847941011],
            [37.671095107573002, 13.4486815177927],
            [37.669861046951397, 13.446224273890399],
            [37.6669460138544, 13.446616342495799],
            [37.664453188941003, 13.446194786810601],
            [37.663646950973501, 13.4417159848957],
            [37.660729672088301, 13.4425143339169],
            [37.656984820247601, 13.4424957676907],
            [37.656471657641603, 13.441136060752299],
            [37.6561639846568, 13.4404567504613],
            [37.657017384176697, 13.4367904075147],
            [37.657859554755603, 13.434761171265],
            [37.654534665310202, 13.4343363182298],
            [37.653293867324003, 13.4331098102583],
            [37.652477523309599, 13.4302570354947],
            [37.650820131610402, 13.4290283222839],
            [37.647920819030901, 13.426563231563501],
            [37.648767481186198, 13.4241265131062],
            [37.645449329105404, 13.4224816322478],
            [37.643778462676998, 13.423692903509],
            [37.6417022314766, 13.422868278562399],
            [37.642128931236499, 13.4208367401198],
            [37.643805412135499, 13.4188117379218],
            [37.642566859937503, 13.4171679129014],
            [37.638827622567398, 13.4163345275468],
            [37.637589070369401, 13.414701608147899],
            [37.635939538928902, 13.412251662103801],
            [37.632609035013097, 13.412640509421101],
            [37.632203670241097, 13.410603423089301],
            [37.630130807722999, 13.409363686493601],
            [37.6272337409317, 13.4069060399765],
            [37.625138420531499, 13.4080168993145],
            [37.6236674292538, 13.409554835724901],
            [37.619846220613901, 13.4076629977818],
            [37.617325323347899, 13.407543938198399],
            [37.615454581768702, 13.4084942286428],
            [37.613170615158801, 13.410413366968999],
            [37.610574483987698, 13.4128906384022],
            [37.609973735641503, 13.4125159909666],
            [37.609379724659803, 13.4121249589094],
            [37.608789082360502, 13.4117328339448],
            [37.608202931637599, 13.4113407083402],
            [37.607615658020599, 13.4109398438992],
            [37.607028384403598, 13.4105389787895],
            [37.605844854016802, 13.4097547230442],
            [37.604636619959699, 13.409006510093899],
            [37.604022396884197, 13.4086504254483],
            [37.603404805126303, 13.4082943402752],
            [37.600329198172297, 13.4065051681387],
            [37.5997295727202, 13.406132695341499],
            [37.598562885744897, 13.4053396868186],
            [37.597455712157199, 13.4044658451965],
            [37.596418158004099, 13.4034926003512],
            [37.5959229617037, 13.402983584082],
            [37.5949707475026, 13.401938240425],
            [37.5945137296018, 13.401403005236601],
            [37.593708614528403, 13.400249514739601],
            [37.592700255621899, 13.398381634872401],
            [37.592452096024701, 13.397648679219101],
            [37.5912157896149, 13.397352656258001],
            [37.590494891599398, 13.3973581179396],
            [37.589010425592399, 13.397440043147901],
            [37.588282790212297, 13.397453151178601],
            [37.586853346016397, 13.3974728132234],
            [37.584714232746101, 13.397416011756301],
            [37.583995580518803, 13.3973668566298],
            [37.583273559609196, 13.3973078704646],
            [37.5811793621031, 13.396908074964101],
            [37.580526960627999, 13.3966502829776],
            [37.579886910988101, 13.3963575358105],
            [37.578633761166699, 13.395681375879899],
            [37.577406437909801, 13.394942950263101],
            [37.576798952198899, 13.3945682748601],
            [37.575589595247699, 13.3938211070083],
            [37.574991092689601, 13.393437691025699],
            [37.573218042897601, 13.3922350061593],
            [37.5726307692806, 13.3918341098681],
            [37.571458467834802, 13.391021391631799],
            [37.570280551918501, 13.3902195943383],
            [37.569089161272998, 13.389445103680799],
            [37.568488412926698, 13.3890791587091],
            [37.567879804321699, 13.3887241369363],
            [37.5663223501979, 13.3879103158196],
            [37.565653105311199, 13.3876175580201],
            [37.564949050707298, 13.3873608484627],
            [37.562785233766697, 13.386895493418301],
            [37.562093530997899, 13.386873645741501],
            [37.5614400066287, 13.386942465916601],
            [37.560844872753002, 13.387201360684999],
            [37.560291285959103, 13.387650329433299],
            [37.558652983459702, 13.3891687333506],
            [37.558063464054499, 13.3896351763719],
            [37.5574649614965, 13.3901201887657],
            [37.556861967362003, 13.390586629943099],
            [37.556260096121598, 13.3909711428336],
            [37.555664962245899, 13.3912125554423],
            [37.553928967959301, 13.390334293024999],
            [37.553373135377299, 13.389771722752799],
            [37.552478188775503, 13.389205874045899],
            [37.552004327463102, 13.3882992024951],
            [37.5513249765295, 13.3878251102281],
            [37.550695032936503, 13.387505042800999],
            [37.550037016990899, 13.387211192126401],
            [37.5487928503224, 13.386536098883299],
            [37.548206699599497, 13.386135193100399],
            [37.547626163347097, 13.3857157160352],
            [37.547044504200699, 13.3853148088862],
            [37.546450493219098, 13.3849313793535],
            [37.545196220503598, 13.3842737580496],
            [37.5445426961344, 13.383989735004199],
            [37.542554050674198, 13.3832010230983],
            [37.541905017881398, 13.382908259572799],
            [37.539997220796799, 13.381956775656899],
            [37.538730596246097, 13.381316624776099],
            [37.537449374072203, 13.3806939507853],
            [37.536795849702997, 13.3804186628235],
            [37.536129973498603, 13.380153206276701],
            [37.535464097294302, 13.379895396346299],
            [37.534158171450002, 13.3793371713566],
            [37.5335338423275, 13.3790072608718],
            [37.532366032458199, 13.378196684185999],
            [37.531816937240698, 13.3777509750887],
            [37.530760293887802, 13.376804932948801],
            [37.5302426397053, 13.376323171032],
            [37.529736214463902, 13.375831576246499],
            [37.527939583895702, 13.3736609001687],
            [37.527556677005798, 13.3730622402162],
            [37.527176015904203, 13.3724624863274],
            [37.525884687683202, 13.3708096049536],
            [37.525374893759498, 13.3703190913802],
            [37.524847133530102, 13.369837316498],
            [37.523736591260104, 13.368934941905801],
            [37.523153809219501, 13.368515434892],
            [37.522555306661502, 13.3681243313415],
            [37.520664352988398, 13.367200100876801],
            [37.519315757168101, 13.3667401692664],
            [37.517215945191502, 13.366222335476801],
            [37.516509644799299, 13.366081405835301],
            [37.515805590195399, 13.3659503084204],
            [37.514400849669897, 13.365771141838],
            [37.513684443230801, 13.3657667719197],
            [37.512965791003502, 13.365798453825599],
            [37.512248261670301, 13.3658399680409],
            [37.511535223913498, 13.365871649937199],
            [37.510822186156801, 13.365913164139799],
            [37.5101080255059, 13.3659535858566],
            [37.5086819499924, 13.366054093879701],
            [37.507970035129702, 13.366113087699899],
            [37.505847765271, 13.366363265036],
            [37.505121252784903, 13.3664583105062],
            [37.504368913734503, 13.3665347838458],
            [37.503616574684003, 13.3666210894428],
            [37.502887816409803, 13.3667609262938],
            [37.501111397935396, 13.367673141261699],
            [37.5011405931822, 13.3705932984229],
            [37.497976277593899, 13.370374806780699],
            [37.496509777892499, 13.369525964871499],
            [37.495882080087803, 13.3689709932558],
            [37.495017451626801, 13.368206266495401],
            [37.493374657551001, 13.3662802367786],
            [37.490866112120102, 13.3639150119115],
            [37.4900632428349, 13.3630956445385],
            [37.4892457759263, 13.3625843578874],
            [37.485850144152302, 13.3624565360552],
            [37.484749707929303, 13.3629284932526],
            [37.483212465899399, 13.3638778673175],
            [37.481804356691498, 13.3648818618313],
            [37.4811059165581, 13.3653844037614],
            [37.4801952494388, 13.3659393836326],
            [37.477284707918301, 13.3674306125891],
            [37.475746342994199, 13.367963738359],
            [37.473355701444298, 13.367795497976999],
            [37.471522015370603, 13.367043877174099],
            [37.469476102311098, 13.3659382911538],
            [37.467229191206698, 13.3638986245914],
            [37.466896814551497, 13.363301032888099],
            [37.4659984992674, 13.3618578482817],
            [37.464886834103297, 13.3607577009991],
            [37.463751588162999, 13.359990763182701],
            [37.462958824924797, 13.359571240610499],
            [37.462084090416901, 13.359312316158899],
            [37.461089206239699, 13.3593418138953],
            [37.459906798746999, 13.3593166861941],
            [37.4571029321664, 13.358116016034201],
            [37.455789146063402, 13.357212505068],
            [37.454099190435102, 13.355810799981199],
            [37.452727013838597, 13.354852653939499],
            [37.452265504361399, 13.3546702015254],
            [37.451483970064302, 13.354538005378799],
            [37.450538493227697, 13.3546046497263],
            [37.448598132214002, 13.354737938365901],
            [37.447462886273698, 13.35490181774],
            [37.4446578967991, 13.3545511157437],
            [37.443309300978797, 13.354588261773699],
            [37.4426344416216, 13.354602464666],
            [37.441973056993596, 13.3547958424241],
            [37.441345359188901, 13.3551268785657],
            [37.438179920706403, 13.3534345471155],
            [37.438055279460798, 13.357656067101701],
            [37.437121031565297, 13.3588337968208],
            [37.432084851503703, 13.3576888425891],
            [37.43172552539, 13.3612329390208],
            [37.430270254629797, 13.3614579939411],
            [37.429631327883897, 13.3614000914817],
            [37.427312551556803, 13.361584723803899],
            [37.426756718974801, 13.361761708500801],
            [37.426188534557603, 13.362047942735799],
            [37.425502446259301, 13.362458721044399],
            [37.423336383530497, 13.364697232033],
            [37.423217356755302, 13.3655100390803],
            [37.419557844866603, 13.3681472731874],
            [37.418697707982098, 13.3721314739792],
            [37.418475374949303, 13.3727432456197],
            [37.418274376904499, 13.3731354341223],
            [37.417117795976203, 13.374664853940599],
            [37.4166899733221, 13.3749772913666],
            [37.414639568686098, 13.3750712410029],
            [37.412830586282702, 13.3736980432554],
            [37.411867143140498, 13.3692899928446],
            [37.408723039646098, 13.370782293533701],
            [37.407860656973298, 13.3703027044953],
            [37.404910814159102, 13.365677188576999],
            [37.406852298066902, 13.3630049677115],
            [37.404988293852298, 13.360669208298599],
            [37.404561594092399, 13.360133881393301],
            [37.401344502481102, 13.3615705213226],
            [37.400055420048403, 13.360621138176],
            [37.396054548351799, 13.3583093909768],
            [37.395533525486996, 13.357223430251],
            [37.3905052056841, 13.356821383189301],
            [37.387884370842698, 13.355603220203699],
            [37.386785057513798, 13.3556884371866],
            [37.385617247644397, 13.356008546866301],
            [37.384617871890804, 13.3567897001044],
            [37.383601652725702, 13.357492189598],
            [37.382385558409801, 13.357791539087399],
            [37.381017873389702, 13.3579051606942],
            [37.380084748388299, 13.357635309290799],
            [37.379220119927403, 13.357110900842001],
            [37.377234843149502, 13.357108715804401],
            [37.375707707166498, 13.3577139704598],
            [37.375084500938101, 13.358497303202],
            [37.373591051778199, 13.358478730488599],
            [37.3682202492733, 13.359441232165301],
            [37.3664505681636, 13.3601295113729],
            [37.3631009750479, 13.3598946226577],
            [37.360114076728202, 13.3582482102208],
            [37.357779579883598, 13.3566924657831],
            [37.356139031596001, 13.353347144265101],
            [37.355742649976897, 13.3519913034959],
            [37.355260928405798, 13.350776393775201],
            [37.354299731051803, 13.3492107681859],
            [37.3522223769573, 13.3448219366889],
            [37.352084260982302, 13.344277834023501],
            [37.351805783244203, 13.3429252200789],
            [37.351687879363197, 13.341311469624801],
            [37.351607030987601, 13.339729393719301],
            [37.351592433364303, 13.3364646905322],
            [37.353885383127, 13.333169349689101],
            [37.352855689232598, 13.331271448983699],
            [37.351434105295397, 13.3286523871048],
            [37.350705347021197, 13.327308426943301],
            [37.350055191334299, 13.3261108756619],
            [37.3493500138363, 13.3248117006416],
            [37.345652323548002, 13.3252717121069],
            [37.344045462083599, 13.3243068889533],
            [37.343210028869301, 13.323675326526599],
            [37.340019886716597, 13.3207524166626],
            [37.338627498026199, 13.318624953151399],
            [37.338350143182197, 13.3178928478748],
            [37.338141284878702, 13.3169279952997],
            [37.337792064812, 13.3159828075643],
            [37.337391191616398, 13.314751326215401],
            [37.337054323384898, 13.3138061299816],
            [37.336645589930598, 13.312450067437601],
            [37.336224504641201, 13.311473173195401],
            [37.335728185446698, 13.3106153824962],
            [37.335323943568902, 13.309925869553],
            [37.334966863243402, 13.3094406960361],
            [37.333719327892602, 13.308603660161401],
            [37.323372981607797, 13.2965548312044],
            [37.320233369689802, 13.297778755547199],
            [37.312720085232201, 13.2975208577174],
            [37.306055708718098, 13.295194300911],
            [37.302314225559797, 13.292887392340999],
            [37.294815538725601, 13.2905553269817],
            [37.289197699517501, 13.2880276225708],
            [37.280623280130598, 13.284932706079999],
            [37.275835259666302, 13.2883521930935],
            [37.2706138020773, 13.288732497395999],
            [37.267900889919297, 13.288713919326099],
            [37.264972382092999, 13.289523703286999],
            [37.262668203389303, 13.290545491607],
            [37.256796590113403, 13.294447918398699],
            [37.254910128016803, 13.295471871566599],
            [37.250493785501199, 13.294983390712099],
            [37.246197592654902, 13.292178158341599],
            [37.242569521801201, 13.286577431799801],
            [37.241829534585897, 13.284610316675399],
            [37.241482560307396, 13.283868272994599],
            [37.240804332267899, 13.2817295570858],
            [37.240862722761399, 13.277649879644599],
            [37.238594476669, 13.276189790910299],
            [37.233777260957901, 13.2771274836361],
            [37.230818434990802, 13.276252085294599],
            [37.228543451533803, 13.275578867944001],
            [37.224375268615503, 13.274719857380701],
            [37.220846012442998, 13.271998544316601],
            [37.218568783197703, 13.2694925049598],
            [37.215037281237002, 13.267185352109699],
            [37.213178891493001, 13.2644748840183],
            [37.212163795221997, 13.260526071370199],
            [37.2098843201885, 13.2582275771278],
            [37.205939593197101, 13.2555028004607],
            [37.203431047766202, 13.255900643802701],
            [37.200486819422501, 13.260103097598799],
            [37.198369041140197, 13.2631261962942],
            [37.1975223789849, 13.264779813187699],
            [37.196878960662701, 13.2670574798994],
            [37.194341219984999, 13.271604006036499],
            [37.191179150184901, 13.275939520328199],
            [37.189065863479001, 13.279452027959],
            [37.1857027956341, 13.282539364355699],
            [37.181508786151397, 13.285207009948101],
            [37.1796178324783, 13.2868539194807],
            [37.1766870788639, 13.2878691620569],
            [37.174174041856602, 13.2890964087133],
            [37.169991261314898, 13.2901039921551],
            [37.167693819975703, 13.2902952358282],
            [37.164343103965997, 13.2917224552784],
            [37.162661008596501, 13.2933704133843],
            [37.159089082447998, 13.2964575726657],
            [37.156355958196002, 13.299133807433201],
            [37.151337744440099, 13.3001358852863],
            [37.1492413011458, 13.3013652554011],
            [37.144228701860399, 13.3017444465249],
            [37.141924523156703, 13.302763997814299],
            [37.138999384012699, 13.3031573926633],
            [37.1362808573842, 13.303760596858099],
            [37.134389903711103, 13.305406287621899],
            [37.129372812849297, 13.306407246789201],
            [37.125614486279403, 13.303792286892],
            [37.122676995300303, 13.308432100232],
            [37.117440940088002, 13.3106754825804],
            [37.113877997092303, 13.3125167233888],
            [37.1096940936566, 13.3137307326664],
            [37.106348992117297, 13.3143284469693],
            [37.104671388324199, 13.3153534088288],
            [37.100279749479, 13.3163576049923],
            [37.097147997819697, 13.3163335655245],
            [37.093618741647198, 13.3138170571268],
            [37.0929854293719, 13.314849670120299],
            [37.089004769769197, 13.3166876010811],
            [37.083840579779597, 13.314695597984899],
            [37.078349627605398, 13.3118042686653],
            [37.078751623695098, 13.316121581023699],
            [37.077348006063602, 13.3173858363556],
            [37.0746586746818, 13.3192259334398],
            [37.0702951081892, 13.3167793879927],
            [37.068188558847901, 13.318079698835],
            [37.068166100965797, 13.3220876718298],
            [37.067521559749501, 13.3251373145484],
            [37.0655295456069, 13.3298488331596],
            [37.060265418042, 13.330991734091],
            [37.0560568109359, 13.331692113349799],
            [37.0534213784711, 13.3321324443567],
            [37.050262677353302, 13.3339691513962],
            [37.047921443144098, 13.338848758582399],
            [37.043827371236702, 13.342750406738499],
            [37.042129555349703, 13.347056241007699],
            [37.039669294365297, 13.351022216829],
            [37.034931804135702, 13.3532531861657],
            [37.027092880387698, 13.354223356407999],
            [37.023464809533898, 13.355025273461701],
            [37.019428005225897, 13.3569197099782],
            [37.016795941443398, 13.3584623427991],
            [37.015155393155801, 13.3613968139836],
            [37.0108300050628, 13.3596083858677],
            [37.001823271445403, 13.356672800409999],
            [36.997731445326203, 13.358235100056801],
            [36.996687153808402, 13.3591287746072],
            [36.995818033771002, 13.359612755897601],
            [36.9895141062647, 13.3602016167],
            [36.9884350050297, 13.360220189280801],
            [36.984646360318898, 13.3601830441178],
            [36.983471813084897, 13.360234391841599],
            [36.982813797139301, 13.360251871915301],
            [36.981249605650802, 13.361317061515599],
            [36.980292899873199, 13.362464183517201],
            [36.9797628938556, 13.3634277617825],
            [36.977893275170501, 13.364496214813901],
            [36.976796207629803, 13.3647092493615],
            [36.975690156936203, 13.3648195902583],
            [36.974270818787303, 13.3648403474511],
            [36.972651605487698, 13.36482286771],
            [36.971536571641302, 13.364841439934899],
            [36.970792092849599, 13.3650413643834],
            [36.969797208672396, 13.3654182706799],
            [36.969189722961502, 13.365748199766101],
            [36.968474439416497, 13.3662125031789],
            [36.967134826749103, 13.367178251414],
            [36.966694652259903, 13.3673978384255],
            [36.966514989203098, 13.367530027525],
            [36.965526842390503, 13.368516527359199],
            [36.965092282371799, 13.3690114144599],
            [36.964752045457999, 13.3698995867751],
            [36.9636864189522, 13.3707003592553],
            [36.962263712121, 13.371284823165199],
            [36.959928092382299, 13.3681931568726],
            [36.959649614644199, 13.366288976596501],
            [36.958226907813, 13.362806134067901],
            [36.9577013933717, 13.3622871993357],
            [36.957139946319202, 13.362227112084099],
            [36.956198961059101, 13.362306864251201],
            [36.955063715118698, 13.362209632153499],
            [36.954076691200299, 13.361793390932799],
            [36.953442256030897, 13.3613869814889],
            [36.952636018063401, 13.3608385461539],
            [36.951907259789202, 13.3608888012365],
            [36.951350304313003, 13.3610920064637],
            [36.950629406297502, 13.362200892187801],
            [36.950535083192698, 13.3630945520468],
            [36.950456480605297, 13.364287550023301],
            [36.950621546038803, 13.365601807445801],
            [36.951118988127398, 13.366752186493001],
            [36.951635519415703, 13.367650199370701],
            [36.948218552653799, 13.3690922568478],
            [36.947381996545403, 13.368324253068099],
            [36.946738578223197, 13.3682849242169],
            [36.945594349129998, 13.3693937768663],
            [36.9453562955797, 13.369973874102101],
            [36.944821797985703, 13.371120954922199],
            [36.944262596721302, 13.372301896235101],
            [36.943364281437198, 13.3739110696718],
            [36.941773140490199, 13.3770660234717],
            [36.939885555499501, 13.3808097464212],
            [36.937242262775897, 13.379135073927801],
            [36.934979631154, 13.3755147701865],
            [36.9341689016101, 13.3751269553048],
            [36.931002340233597, 13.3719676063118],
            [36.928296165440202, 13.3722669378334],
            [36.927731349705297, 13.3767448493156],
            [36.927222678675697, 13.377801226645801],
            [36.925803340526798, 13.378225087188699],
            [36.921989992145697, 13.3773806426381],
            [36.920746948371303, 13.376902159159901],
            [36.917221060881097, 13.376215020265301],
            [36.9167146356397, 13.3767535887541],
            [36.917111017258797, 13.3799467398714],
            [36.917426550502398, 13.381311162730499],
            [36.917427673396503, 13.382632974142],
            [36.917312015303601, 13.383433704303201],
            [36.9149898702942, 13.384270480862501],
            [36.912031044327101, 13.383057918583599],
            [36.908965543420102, 13.384455095664901],
            [36.905629425033702, 13.3859746121227],
            [36.905299294166703, 13.386943558300199],
            [36.9049534427824, 13.3880151841984],
            [36.904809712336899, 13.389436364702901],
            [36.904868102830399, 13.390334293024999],
            [36.904411084929599, 13.393873539915701],
            [36.903272470306902, 13.3952356991516],
            [36.899922877191301, 13.400734505736301],
            [36.899410837479302, 13.402330381368101],
            [36.8988100891331, 13.4033145540105],
            [36.898176776857802, 13.4041949536487],
            [36.8968584991783, 13.405678299593299],
            [36.895500920205201, 13.4068852864101],
            [36.894702542496397, 13.4077722267055],
            [36.893886198482001, 13.4084756597851],
            [36.892606099202098, 13.4093735170292],
            [36.888969045195601, 13.409809337037],
            [36.888890442608201, 13.409692463077301],
            [36.888352576331798, 13.409149599174],
            [36.888139226451798, 13.408823006416],
            [36.888061746758602, 13.4086482408783],
            [36.887984267065299, 13.4084396143513],
            [36.887855134243203, 13.4080005149958],
            [36.887818078737801, 13.407654259465801],
            [36.887743967726799, 13.4073538796587],
            [36.887624940951703, 13.4069475471041],
            [36.887527249164499, 13.406670104588899],
            [36.887339725849003, 13.405768960352001],
            [36.887253263002897, 13.4054795011834],
            [36.881670233512097, 13.406749842037501],
            [36.881021200719303, 13.4073200186387],
            [36.880770795333902, 13.4083478623134],
            [36.876253392348801, 13.413207395253499],
            [36.8727319964351, 13.412796703531599],
            [36.872382776368397, 13.413580949348299],
            [36.872598372036599, 13.417802507149601],
            [36.870583900011901, 13.4185332168662],
            [36.869839421220199, 13.4196178088037],
            [36.869483463788903, 13.4206423231741],
            [36.8687704260321, 13.422290493303001],
            [36.864365312457601, 13.4233608694341],
            [36.863990265826502, 13.4233630538703],
            [36.863162692871001, 13.42446837604],
            [36.862302555986503, 13.4254808554189],
            [36.861171801622604, 13.4260717394691],
            [36.860017466482503, 13.426237754689099],
            [36.859251652702802, 13.426355712802],
            [36.858421833959099, 13.4261962508949],
            [36.857753711966502, 13.4261798678163],
            [36.856843044847203, 13.4264671176331],
            [36.8551710555247, 13.4288481104839],
            [36.854921773033297, 13.430278879228201],
            [36.8542435449938, 13.432386790154499],
            [36.8522470392749, 13.434196520665401],
            [36.845552344619897, 13.4344149543239],
            [36.844468751808499, 13.4350200145192],
            [36.844250910352102, 13.435680773458101],
            [36.844363199762597, 13.436304397167101],
            [36.843916287908698, 13.4402907450814],
            [36.843675988570197, 13.4409525816351],
            [36.843425583184803, 13.441543427576001],
            [36.838884599423601, 13.4419551622412],
            [36.838284973971398, 13.4419857419759],
            [36.837588779626202, 13.4421397327237],
            [36.8376000085673, 13.443024358507],
            [36.837693208777999, 13.4469800155871],
            [36.833537377694903, 13.445736098435001],
            [36.832201133709702, 13.446092128059901],
            [36.828792027206497, 13.447210450803601],
            [36.826377804880401, 13.447528254459201],
            [36.825859027803801, 13.447595965149199],
            [36.824205004786997, 13.4480349920621],
            [36.823350482372902, 13.4483582551455],
            [36.8228530402844, 13.4489774774691],
            [36.821923283965297, 13.4499418011108],
            [36.817774190246801, 13.4488922935407],
            [36.817859530198803, 13.447906123549201],
            [36.817521539073098, 13.447368806629701],
            [36.8170914706308, 13.4472082665846],
            [36.816780428963703, 13.446579210677699],
            [36.816615363530197, 13.445702242829601],
            [36.816759093975698, 13.444752099696],
            [36.8165861682835, 13.443737517409801],
            [36.815690098787599, 13.443024358507],
            [36.815238695357301, 13.442828867411199],
            [36.814706443551501, 13.442102600211999],
            [36.814320167979297, 13.4411731934137],
            [36.814047304711799, 13.4400056961018],
            [36.813823848784899, 13.4378050192008],
            [36.8137138051625, 13.436687744736799],
            [36.813559968670098, 13.4362705402297],
            [36.8126178605159, 13.435533331538601],
            [36.812056413463303, 13.435013461528801],
            [36.811543250857298, 13.4340468934946],
            [36.8113635878005, 13.433318415351399],
            [36.811491597728498, 13.4324818095373],
            [36.813025471076102, 13.429121158612899],
            [36.809889227840401, 13.428797869594399],
            [36.809269390294403, 13.4265544939351],
            [36.809801642100197, 13.4220283596455],
            [36.809517549891602, 13.4207810364043],
            [36.809180681660102, 13.4197838284867],
            [36.809315428952701, 13.4183038463425],
            [36.809314306058603, 13.4178909788479],
            [36.808860656840103, 13.4173044436472],
            [36.808091474378102, 13.416698246543399],
            [36.807199896458599, 13.4166764015742],
            [36.806101706023703, 13.4169341720846],
            [36.8042410704915, 13.4167484899651],
            [36.803633584780599, 13.4161816124889],
            [36.803187795820897, 13.4153307476388],
            [36.8029766917291, 13.4144525733108],
            [36.802803766036902, 13.413482645695501],
            [36.802724040555397, 13.412928867699501],
            [36.802497215946197, 13.4122101558923],
            [36.802264776866402, 13.411721911234601],
            [36.802058164351102, 13.4115209332797],
            [36.801019487303797, 13.411322139702399],
            [36.800094222561199, 13.4112522342296],
            [36.798550243166602, 13.411500180111799],
            [36.797267898098497, 13.411650913606699],
            [36.795371329954897, 13.411464135131601],
            [36.794054175169599, 13.4111419146138],
            [36.792789796407199, 13.410604515365399],
            [36.7909954116272, 13.4097077550005],
            [36.790024108226199, 13.409188921348999],
            [36.789756859429197, 13.4091124615583],
            [36.7887383944758, 13.408579426913001],
            [36.787928787825997, 13.408145789249501],
            [36.787310073174098, 13.4075777991868],
            [36.787343759997199, 13.4067640418543],
            [36.787369586561603, 13.4062353711849],
            [36.7873639720911, 13.405602930984699],
            [36.7873022129153, 13.4052686873053],
            [36.786598158311399, 13.4039863232566],
            [36.785779568508701, 13.4038618003186],
            [36.784639830991999, 13.403947000230501],
            [36.783936899282203, 13.4038716310792],
            [36.781153244795497, 13.401834470430501],
            [36.7793307876629, 13.398052842821301],
            [36.779009639948796, 13.396918998347299],
            [36.778201156193099, 13.3955765108417],
            [36.777711574363202, 13.3950347074157],
            [36.777020994488602, 13.394098564346899],
            [36.776333783296202, 13.3930455355853],
            [36.775578075563502, 13.391923683484301],
            [36.774569716656998, 13.391011560346],
            [36.773976828569502, 13.390398742739199],
            [36.773427733352101, 13.3898918835039],
            [36.7727270474305, 13.389310741860299],
            [36.771727671676899, 13.3885373406548],
            [36.771321184010802, 13.387940902434799],
            [36.770855182957199, 13.3873881580031],
            [36.770412762679797, 13.386825580845599],
            [36.770583442583799, 13.383532020237601],
            [36.770825987710502, 13.3815285520479],
            [36.769865913250598, 13.3805716006189],
            [36.768546512676998, 13.3794802780819],
            [36.7679648535306, 13.3788565069238],
            [36.767312452055499, 13.3782884477213],
            [36.7664118909831, 13.378161726639499],
            [36.765749383461099, 13.3775117338361],
            [36.765532664898799, 13.3764040109674],
            [36.762157245218702, 13.374133927875],
            [36.758394427072403, 13.3735636726498],
            [36.757590434893103, 13.3733604779332],
            [36.756034103663303, 13.372802237802899],
            [36.754942650593101, 13.372371813023101],
            [36.753834354111298, 13.3716988630961],
            [36.749396676607802, 13.371411547863399],
            [36.746145898173403, 13.3679943275068],
            [36.744874782046402, 13.366847231809899],
            [36.743275780840598, 13.3661043478756],
            [36.739779088597203, 13.3648534572561],
            [36.7372379792372, 13.362061052692599],
            [36.736129682755497, 13.3624871259016],
            [36.734878778722297, 13.3629678229773],
            [36.733987200802801, 13.363310865304699],
            [36.733166365212, 13.363626594913701],
            [36.732415149055598, 13.3639150119115],
            [36.732170358140699, 13.3681483656562],
            [36.730543284582303, 13.3692889003809],
            [36.723629625576898, 13.367292961071801],
            [36.721708353762999, 13.3663348606354],
            [36.720519208905699, 13.3660748509662],
            [36.717859072770601, 13.3659699730371],
            [36.716138799001499, 13.3666942853046],
            [36.7140389870249, 13.3670995933314],
            [36.712479287112799, 13.367508178092001],
            [36.710952151129803, 13.368881411103599],
            [36.708804054706597, 13.3702371569444],
            [36.707466687827399, 13.371087088804799],
            [36.7067446669178, 13.371746931010399],
            [36.7030716802999, 13.373086274038601],
            [36.6996288869735, 13.3724450071389],
            [36.698200565671698, 13.3727749266088],
            [36.696341053033599, 13.3729781218191],
            [36.694367005196703, 13.3730993833952],
            [36.691989838376102, 13.373359385488],
            [36.690079795503301, 13.373711152578799],
            [36.689228641771599, 13.37395258249],
            [36.685077302264801, 13.3747740978419],
            [36.679968134086401, 13.3719861779864],
            [36.6779177294504, 13.371035743391801],
            [36.676687037511201, 13.3705780040144],
            [36.674888161154698, 13.369864628025001],
            [36.673057843763303, 13.369047465798401],
            [36.671762023965996, 13.3683930785422],
            [36.666333953861702, 13.366538061274101],
            [36.665444621730401, 13.3664670503177],
            [36.663976999135002, 13.3665216741321],
            [36.662529588633397, 13.366689915403001],
            [36.660082802378298, 13.3669357222436],
            [36.658477063808, 13.3673180879419],
            [36.657476565160302, 13.368280554344199],
            [36.656421044701403, 13.369125030781101],
            [36.6547906024608, 13.3695412593468],
            [36.654083179174499, 13.3701213562276],
            [36.653535206851203, 13.3708303616307],
            [36.6523921006522, 13.3722931566351],
            [36.651317490993499, 13.3734260246347],
            [36.650840260998798, 13.374160146473599],
            [36.6508458754694, 13.375653509443399],
            [36.6509458130447, 13.3766902278179],
            [36.651152425560099, 13.378557183587899],
            [36.649102020924097, 13.380180516634599],
            [36.646508135541197, 13.378101643344699],
            [36.644656483161803, 13.377593665798701],
            [36.6429777564746, 13.3780896266839],
            [36.641288923740397, 13.378468697421001],
            [36.637478944041703, 13.3800909386493],
            [36.637687802345198, 13.3818901388698],
            [36.636156174785803, 13.3838367993795],
            [36.634640267743798, 13.383085228612099],
            [36.632815564822998, 13.3833004315279],
            [36.628056739605398, 13.3818639211124],
            [36.621939212520502, 13.379967495761401],
            [36.620774771333501, 13.3796485103069],
            [36.618879326083999, 13.3777837478445],
            [36.616625677614898, 13.37527661915],
            [36.613133476947901, 13.3776996310956],
            [36.609472842165097, 13.3752023334574],
            [36.604783636382002, 13.377616606743199],
            [36.601997736107201, 13.3820692934686],
            [36.598704287696698, 13.384060740797],
            [36.5971502022552, 13.3850996077707],
            [36.5957106520124, 13.385830416867799],
            [36.593099923217899, 13.387369587516],
            [36.592155569275498, 13.3906401559221],
            [36.591420073636598, 13.394916733927399],
            [36.589963679982297, 13.4017558236681],
            [36.587342845140803, 13.403787523447599],
            [36.584005603860298, 13.408039837358899],
            [36.5792872028305, 13.4115908386744],
            [36.576280092416901, 13.417560028994499],
            [36.569486583080803, 13.4252580447894],
            [36.568694942736599, 13.430624009953499],
            [36.567505797879299, 13.4386787394947],
            [36.567232934611702, 13.4405277395689],
            [36.566713034640998, 13.444057509918499],
            [36.5660830910481, 13.448322215701699],
            [36.570243413707601, 13.448685886203901],
            [36.571609975833603, 13.450755412036401],
            [36.572783400173499, 13.4548791210213],
            [36.5729304993013, 13.457449850703901],
            [36.571850275172103, 13.461381244584601],
            [36.570927256217701, 13.466854510981699],
            [36.571906419877401, 13.473033079861599],
            [36.574285832486197, 13.477853082678299],
            [36.578752705236496, 13.482180519406301],
            [36.584462621761098, 13.483605509809699],
            [36.5880895697208, 13.4851822708964],
            [36.590683455103701, 13.486063461791099],
            [36.594134108688799, 13.487981978928801],
            [36.594997614255597, 13.488333578008501],
            [36.597240033783599, 13.489554343466001],
            [36.599311773407599, 13.4906014888288],
            [36.601684448651802, 13.4962782689081],
            [36.601493556653899, 13.4979892473182],
            [36.601136476328499, 13.4990156101028],
            [36.600082078763798, 13.500374988060701],
            [36.5980777127861, 13.5047249454877],
            [36.594476591390901, 13.5072907717412],
            [36.591841158926101, 13.5079709840302],
            [36.590899050771903, 13.5102114127154],
            [36.588789132748303, 13.5125577260394],
            [36.5866230700194, 13.512844872152201],
            [36.5852755970933, 13.513581842532],
            [36.581106291280797, 13.519024434716499],
            [36.578173291878201, 13.521608667165101],
            [36.572403861965903, 13.522238617340699],
            [36.564687333675401, 13.5329900924028],
            [36.5670139702612, 13.537707356272501],
            [36.566366060362498, 13.5389114935828],
            [36.566071862107002, 13.540752076099601],
            [36.5640046140594, 13.547407966487601],
            [36.558476606379799, 13.5515365554099],
            [36.561704926932102, 13.555498055740101],
            [36.560869493717803, 13.561010642642801],
            [36.559628695731703, 13.5656498493197],
            [36.556573300871499, 13.5701274164908],
            [36.5527621982787, 13.572413104470799],
            [36.549188026342001, 13.5739488910502],
            [36.545731758286301, 13.574565604257],
            [36.539779296634897, 13.5732219314774],
            [36.540047668326103, 13.5777713955823],
            [36.537813109056799, 13.581949665315801],
            [36.531526024962098, 13.5816615111455],
            [36.526054161987702, 13.584270166818399],
            [36.522503570827197, 13.5846336299544],
            [36.521453664838901, 13.586215179275801],
            [36.520571070072201, 13.5876526445583],
            [36.5205643327076, 13.5902907035721],
            [36.521029210867098, 13.5914978486288],
            [36.521902822481003, 13.5932856344412],
            [36.522947113998697, 13.5961593790938],
            [36.523873501635499, 13.600008810754799],
            [36.5270995763996, 13.6043416657877],
            [36.525768946885002, 13.607571064601499],
            [36.524232827749103, 13.609652306390201],
            [36.522585542096898, 13.6120576621433],
            [36.520855162280803, 13.6150752358466],
            [36.519287602110097, 13.618212816870599],
            [36.516746492750102, 13.6244311066209],
            [36.5159088137477, 13.6260298507208],
            [36.515004883993001, 13.6274856260442],
            [36.513932520122601, 13.6291192702834],
            [36.513133019519699, 13.630609947156101],
            [36.512381803363397, 13.6319489292723],
            [36.5117204187355, 13.6334963335448],
            [36.5110231014962, 13.6362179071584],
            [36.510714305617199, 13.638302170476701],
            [36.510670512747097, 13.6399641117619],
            [36.510814243192598, 13.6415191020431],
            [36.511172446412097, 13.644170746055799],
            [36.510685110370503, 13.648520250696301],
            [36.509585797041602, 13.650713522930699],
            [36.505788169177997, 13.652446302695999],
            [36.505369329676697, 13.660951797090201],
            [36.505184052149403, 13.6630467531041],
            [36.5051615942673, 13.665132961630301],
            [36.503496342309298, 13.6672027852063],
            [36.5000591634535, 13.665885825235099],
            [36.496775821089997, 13.6655268514395],
            [36.495605765432501, 13.667449373413501],
            [36.494265029870903, 13.668014561339399],
            [36.494083121025902, 13.6687445028127],
            [36.493710320182998, 13.671739530470999],
            [36.490477508054198, 13.676450757379101],
            [36.488945880494803, 13.6779160410192],
            [36.486658545202602, 13.679230751638601],
            [36.4855873042263, 13.680104675548],
            [36.485865781964399, 13.682483128682],
            [36.485846692764603, 13.6842636796994],
            [36.484293730217203, 13.6877974662317],
            [36.4814090152611, 13.6882207751678],
            [36.4795674689286, 13.690424587735301],
            [36.478030226898703, 13.692332723620799],
            [36.477249815495597, 13.6945474073796],
            [36.476149379272499, 13.698240318879799],
            [36.475516066997201, 13.7007538656382],
            [36.475651937183997, 13.702237543816301],
            [36.476082005626203, 13.7044641344455],
            [36.476671525031399, 13.7060961541565],
            [36.477806770971704, 13.7100747001521],
            [36.4748704028868, 13.721436148759301],
            [36.477454182222701, 13.7195838828946],
            [36.478181817602803, 13.721007445415999],
            [36.478742141761302, 13.7219117576588],
            [36.479204774132597, 13.722355731151501],
            [36.479458548200398, 13.7225662637425],
            [36.4804062708251, 13.723488023381],
            [36.4808801321375, 13.723943993082299],
            [36.481919932078902, 13.724878837282599],
            [36.482256800310402, 13.725148272434099],
            [36.482465658613997, 13.7252551737445],
            [36.483616625071797, 13.725905307195401],
            [36.484174703442001, 13.7262456447934],
            [36.484472270379896, 13.726698336666599],
            [36.4849685895744, 13.72793532639],
            [36.484842825434598, 13.7284163761872],
            [36.484470024591701, 13.7290305040863],
            [36.484358858075304, 13.729327204733099],
            [36.484162351606898, 13.7299107876307],
            [36.483795165234497, 13.730857606887101],
            [36.483489738037903, 13.731633166816801],
            [36.482923799408901, 13.732510168203699],
            [36.482352246309397, 13.7330522930423],
            [36.482062539630199, 13.7335878718832],
            [36.481613381988197, 13.734475773824],
            [36.480575827834997, 13.735552377685901],
            [36.479706707797597, 13.7356418217559],
            [36.479423738483099, 13.736464269287699],
            [36.478398536165102, 13.736700967843699],
            [36.477633845279499, 13.736850404136099],
            [36.476923053310998, 13.7373096201756],
            [36.475147757730703, 13.7379117263035],
            [36.474045075719502, 13.7382869510791],
            [36.472213635434002, 13.739097390913599],
            [36.471153623398699, 13.740091075768399],
            [36.468739401072597, 13.741396712360601],
            [36.468429482299598, 13.741976992955101],
            [36.468134161149997, 13.7425310941363],
            [36.467662545625799, 13.7434135084695],
            [36.4670438309739, 13.7439785136766],
            [36.466462171827402, 13.7443711805854],
            [36.463351755156097, 13.745906938173899],
            [36.463217007863499, 13.747224540351899],
            [36.463101349770703, 13.7483534402951],
            [36.463045205065399, 13.7488998913564],
            [36.462475897754103, 13.7493056386249],
            [36.461413639930697, 13.7497135666215],
            [36.460066167004499, 13.7503014616019],
            [36.459901101570999, 13.750367995078101],
            [36.459352006353598, 13.750932983507701],
            [36.457728301477601, 13.7519866069371],
            [36.456695238900799, 13.752718468868],
            [36.456207902859198, 13.753147114212201],
            [36.454839094945001, 13.7543316110056],
            [36.454349513115197, 13.7548976805609],
            [36.4536712850757, 13.7562305017372],
            [36.453456812301603, 13.7571237712562],
            [36.453550012512302, 13.757809808657299],
            [36.453679145334398, 13.7587488804401],
            [36.453813892626997, 13.7597370285604],
            [36.454401166243997, 13.761101451389999],
            [36.454740280263799, 13.761933625436299],
            [36.454872781768202, 13.762406970820701],
            [36.454948015673203, 13.7628563208524],
            [36.454670660829201, 13.763322029804501],
            [36.454419132549702, 13.7636514065364],
            [36.453590436700097, 13.764075669259199],
            [36.4519566257771, 13.764990721715],
            [36.451170599903499, 13.765701820793799],
            [36.4506237504743, 13.766865531953099],
            [36.450472159770101, 13.7682975322081],
            [36.450509215275602, 13.770332637055599],
            [36.450467668193703, 13.770751434569201],
            [36.452775215579699, 13.771683910713101],
            [36.499053050335299, 13.8467769033398],
            [36.462785816527102, 13.970956074806301],
            [36.540497948862203, 14.1980606790496],
            [36.543041304010401, 14.2506673870505],
            [36.544122651033597, 14.2504540722204],
            [36.546347104256, 14.2498358934102],
            [36.547720403746503, 14.2496029875741],
            [36.550023459556201, 14.249981731521601],
            [36.5515528413274, 14.250526991088099],
            [36.552021088169298, 14.2509895355953],
            [36.552406240847297, 14.2520158368445],
            [36.553487587870599, 14.2531553196115],
            [36.553944605771399, 14.253628741991101],
            [36.554777793197403, 14.253509026081],
            [36.555998379089701, 14.2532902722992],
            [36.5567271373639, 14.2538616436707],
            [36.556897817267902, 14.255318906195701],
            [36.557004492207902, 14.2565682918114],
            [36.557390767780099, 14.2570449719554],
            [36.558024080055397, 14.257524916013899],
            [36.5588370553875, 14.258164839835899],
            [36.559940860292897, 14.2591889318334],
            [36.561059262821601, 14.260407823790301],
            [36.562350591042502, 14.260797432508101],
            [36.562678476121199, 14.2643180282336],
            [36.561812724766199, 14.265163615986999],
            [36.560783030871697, 14.2661147623838],
            [36.5603552082177, 14.267065904765801],
            [36.559830816670598, 14.2679604537771],
            [36.558719151506502, 14.268902882348399],
            [36.5580600126668, 14.269547126862999],
            [36.557545727166598, 14.269729952673501],
            [36.556003993560203, 14.270357871142499],
            [36.554803619761799, 14.2709564053155],
            [36.554317406614302, 14.2720109098347],
            [36.554135497769302, 14.2735094077642],
            [36.553716658268002, 14.274402842961701],
            [36.553405616600898, 14.275327833037201],
            [36.552719528302703, 14.275778356239099],
            [36.552448910823301, 14.275771826923799],
            [36.551401250623201, 14.2764127871424],
            [36.551503433986802, 14.2779863421821],
            [36.551964943464, 14.278103868673201],
            [36.5523152864248, 14.2785902971087],
            [36.552575797857202, 14.279229072898699],
            [36.553698691962403, 14.2800811338654],
            [36.555572802223899, 14.2801583959391],
            [36.556490206707799, 14.280083310262199],
            [36.5569640680201, 14.280235657986401],
            [36.557689457612099, 14.280612174062499],
            [36.558656269436597, 14.2800637226901],
            [36.559326637217403, 14.2800615462931],
            [36.560205863301697, 14.280435886267099],
            [36.5610907038566, 14.281142124813501],
            [36.562429193629903, 14.2821389076505],
            [36.563601495075702, 14.283046454996899],
            [36.565324014632999, 14.2832314462132],
            [36.568248030882799, 14.2840464939377],
            [36.5706251977034, 14.285171668456],
            [36.571321392048603, 14.2858256599571],
            [36.5727418530916, 14.2856254364715],
            [36.573601989976098, 14.285631965501],
            [36.575482837602301, 14.2861118486517],
            [36.576742724788197, 14.2867070764439],
            [36.5775175217208, 14.287174987496799],
            [36.578652767661097, 14.2874274414282],
            [36.5794073524998, 14.2874481165221],
            [36.5803865161595, 14.2869334149352],
            [36.581859753225402, 14.285227165312699],
            [36.582422323172104, 14.2845263804696],
            [36.582986016012903, 14.283802741656601],
            [36.583481212313202, 14.2832760617191],
            [36.584324505786199, 14.282465363951999],
            [36.585291317610697, 14.2819180052847],
            [36.586006601155702, 14.2817428067024],
            [36.587224941259798, 14.282051852556901],
            [36.5895190139166, 14.283718951749],
            [36.590022070475698, 14.2841683699793],
            [36.590799113196503, 14.284751632978599],
            [36.5908283084432, 14.2850497929578],
            [36.5909484581125, 14.2857255482366],
            [36.591224690062297, 14.2865928189459],
            [36.591685076645497, 14.2866755196169],
            [36.592459873578001, 14.2865688792724],
            [36.593132487147003, 14.2866472272856],
            [36.593367172015, 14.2866080532824],
            [36.594257627040399, 14.286935591265699],
            [36.595044775808098, 14.286873565837],
            [36.596230551983098, 14.287264216936],
            [36.597200732490002, 14.287531905041099],
            [36.597794743471603, 14.287773476960099],
            [36.5985762777688, 14.2879987262185],
            [36.599008591999201, 14.288427460898699],
            [36.599023189622599, 14.2891630545379],
            [36.598953570188101, 14.2897735083796],
            [36.599585759569301, 14.2907724292744],
            [36.600563800334903, 14.291442726647],
            [36.601413831172501, 14.291552629110001],
            [36.602873593509202, 14.291511279674699],
            [36.6043692884572, 14.2915276018211],
            [36.6052035987774, 14.2914536080813],
            [36.606040154885697, 14.2914481673642],
            [36.606841901276802, 14.2915363069654],
            [36.607927739876402, 14.2916298872449],
            [36.609013578476102, 14.2917920204276],
            [36.610896671890501, 14.291708233495401],
            [36.611729859316497, 14.2915765682532],
            [36.612176771170297, 14.290936739367099],
            [36.613634287718803, 14.290240324905399],
            [36.6161922404903, 14.2909258579077],
            [36.617209582549599, 14.291766993165499],
            [36.618692925662501, 14.292956329112201],
            [36.619386874219501, 14.2935167185266],
            [36.6197035303571, 14.2940161712161],
            [36.619857366849601, 14.2946679607482],
            [36.619891053672703, 14.295150000873001],
            [36.620206586916296, 14.296108637418101],
            [36.620503030960002, 14.2980694227067],
            [36.620519874371602, 14.298954055532199],
            [36.6205209972657, 14.2995971259177],
            [36.620559175665299, 14.300930049369001],
            [36.620554684088802, 14.3018843088949],
            [36.6200471359533, 14.304181261333101],
            [36.620162794046202, 14.305271516661101],
            [36.620677079546297, 14.3062039963124],
            [36.621252001328202, 14.3067317106196],
            [36.6224759559028, 14.307259423687199],
            [36.623268719141002, 14.307438954654501],
            [36.625945698687701, 14.3076511274308],
            [36.627429041800603, 14.307620661608601],
            [36.629962290901801, 14.307684857443499],
            [36.631792608293203, 14.3073617019528],
            [36.634480816780901, 14.3075140311983],
            [36.636199967655898, 14.307457451776299],
            [36.636823173884302, 14.3074226336634],
            [36.638025793470902, 14.3074781250283],
            [36.640664594618002, 14.306930827242301],
            [36.641786365828999, 14.3065412983836],
            [36.643576259032599, 14.3059200093372],
            [36.645822047242902, 14.3051387712853],
            [36.6479566689368, 14.304728565816699],
            [36.6497914779046, 14.3046230221135],
            [36.651847497011197, 14.304362970921799],
            [36.653718238590301, 14.304268307860999],
            [36.655128593586397, 14.3041475307945],
            [36.656894906013797, 14.303526235129199],
            [36.658210937904997, 14.3028222430439],
            [36.660365771692803, 14.3021922389833],
            [36.662418422117, 14.3011846640516],
            [36.6650156761822, 14.300385999935401],
            [36.6670323939951, 14.299931173598299],
            [36.668881800586298, 14.2993425094368],
            [36.6707121179777, 14.299007372774501],
            [36.672778243131098, 14.2987124956228],
            [36.675439502160302, 14.2980955373787],
            [36.677069944400998, 14.2977756324374],
            [36.678940685980201, 14.297117323217901],
            [36.680784478100897, 14.2968387653479],
            [36.682035382134003, 14.2964231041346],
            [36.683827521125799, 14.2959269211565],
            [36.686029516466, 14.295928009278301],
            [36.687406184638903, 14.295831166420699],
            [36.689421779557698, 14.2959182161822],
            [36.690947792646597, 14.2962881772928],
            [36.6923480415957, 14.296801769354801],
            [36.694972245119402, 14.298461142468399],
            [36.697210173070999, 14.300230401555201],
            [36.6991168472615, 14.3013076189018],
            [36.700827014983702, 14.3020431704066],
            [36.702700002351101, 14.3032008974844],
            [36.704085653676799, 14.304160587777799],
            [36.705784592457903, 14.3037590851945],
            [36.7072668126767, 14.3053727077556],
            [36.709323954677302, 14.3057796478261],
            [36.7110565802816, 14.3064226986808],
            [36.712647721228599, 14.307067923830701],
            [36.714196192199601, 14.307324707691899],
            [36.716106235072402, 14.3074694205014],
            [36.716838362029002, 14.307523823789101],
            [36.718273420695397, 14.307437866588501],
            [36.720610163328203, 14.3071364720936],
            [36.722625758246899, 14.3071767305874],
            [36.726403174016703, 14.3082027757115],
            [36.727678781720101, 14.3089470091151],
            [36.7290846451398, 14.310333191659],
            [36.730361375737303, 14.3117204536934],
            [36.732196184705103, 14.3139139422944],
            [36.733787325652102, 14.3150988089204],
            [36.735105603331597, 14.3156352065399],
            [36.736066800685599, 14.315995342965101],
            [36.738778589949497, 14.316686237510901],
            [36.740154135228302, 14.316601371838701],
            [36.741966486313999, 14.3165186821786],
            [36.744461557015697, 14.3177818722188],
            [36.744709716612903, 14.317741615626099],
            [36.7448860109874, 14.317745967690501],
            [36.745144276631599, 14.317807984599501],
            [36.745709092366504, 14.317797104441199],
            [36.745944900128599, 14.317802544520401],
            [36.746440096428898, 14.3177677280114],
            [36.7467006078613, 14.317704623075],
            [36.746891499859203, 14.3176284619213],
            [36.747056565292702, 14.3175979974526],
            [36.747304724889901, 14.317556652809801],
            [36.7475764652634, 14.317540332554],
            [36.748340033254898, 14.317603437536601],
            [36.749268666679797, 14.3176806867152],
            [36.750750886898601, 14.3177492317388],
            [36.752147767165397, 14.3179635708044],
            [36.752832732569601, 14.3178874097385],
            [36.754739406760102, 14.3179287543204],
            [36.756496736034698, 14.317841713086599],
            [36.757609524092899, 14.317613229687399],
            [36.758522437000401, 14.3173216410117],
            [36.759661051622999, 14.3170028514664],
            [36.760645829753201, 14.316714526061199],
            [36.762023620820202, 14.316180307067601],
            [36.763601287037901, 14.3156863437714],
            [36.766256931596601, 14.315445889879401],
            [36.766801535237597, 14.315353407544601],
            [36.7679547474836, 14.315378432180101],
            [36.7695795752538, 14.315427393415501],
            [36.770156742823801, 14.315393664565599],
            [36.771110079919097, 14.3154143370871],
            [36.772403653928201, 14.315523139800399],
            [36.773376080223301, 14.3157418330946],
            [36.775468031941202, 14.3164316403981],
            [36.776065411605103, 14.316582875470001],
            [36.776969341359703, 14.3166949416806],
            [36.778814256374503, 14.316885345308201],
            [36.780335777886997, 14.3167798073175],
            [36.781531660109003, 14.316530650420701],
            [36.783236213360603, 14.3161770429966],
            [36.784183935985297, 14.315934413580299],
            [36.785323673501999, 14.3155209637466],
            [36.786415126572301, 14.315189115303101],
            [36.787895101002803, 14.314864882579601],
            [36.789442449079701, 14.3146570687421],
            [36.791203147036597, 14.314431846198501],
            [36.7921811878022, 14.314394853102099],
            [36.794055298063697, 14.3143437155765],
            [36.795208510309699, 14.3143926770374],
            [36.7979843045376, 14.314558057893301],
            [36.799353112451797, 14.314425318005499],
            [36.8002862374532, 14.314308898531101],
            [36.801963841246298, 14.314115228617499],
            [36.802968831470402, 14.313906326051599],
            [36.805829965650297, 14.313463495490501],
            [36.8068349558744, 14.313278529150001],
            [36.807533396007798, 14.313144700467401],
            [36.808667519053998, 14.312973878374599],
            [36.8094119978457, 14.312863986386301],
            [36.810745996042598, 14.3127203650929],
            [36.8125179229406, 14.312506021021999],
            [36.8144144910842, 14.312478819983101],
            [36.815946118643602, 14.3124886123575],
            [36.8174036351921, 14.312600680611499],
            [36.818330022828803, 14.312770414948],
            [36.819457408510402, 14.3129445013137],
            [36.820803758542503, 14.3132513282047],
            [36.822827213719897, 14.313894357669501],
            [36.823842309991001, 14.314330659185099],
            [36.825329021786203, 14.314213151628399],
            [36.825999389567002, 14.3137442088222],
            [36.8263373806926, 14.3123134375959],
            [36.826488971396799, 14.310360392957699],
            [36.826770817817199, 14.309376791902],
            [36.8271447415542, 14.308475879191301],
            [36.827543368961599, 14.3075183834609],
            [36.828443930033899, 14.306260576050001],
            [36.829405127387901, 14.3053944692754],
            [36.830781795560803, 14.305977677219399],
            [36.831077116710503, 14.306478190292699],
            [36.831353348660301, 14.3072670401554],
            [36.831985538041501, 14.308443237358601],
            [36.8328928364785, 14.3089470091151],
            [36.833567695835697, 14.308305053547601],
            [36.834053908983201, 14.307591283847501],
            [36.834579423424401, 14.306773057255199],
            [36.835431700050201, 14.3060908367944],
            [36.836589403872701, 14.305909128603],
            [36.838304063171201, 14.306682747488599],
            [36.839393270453201, 14.307454187578401],
            [36.840832820696001, 14.3093724396753],
            [36.842043300541398, 14.3095378242281],
            [36.843090960741499, 14.310561682465799],
            [36.8437500995812, 14.312129558351501],
            [36.845102064083797, 14.313770321672401],
            [36.845656773771701, 14.314277345579001],
            [36.846763947359399, 14.3153022702373],
            [36.847158083190301, 14.3155873333765],
            [36.8475398671861, 14.315872396153701],
            [36.848341613577098, 14.3168679369833],
            [36.848690833643801, 14.317565356945799],
            [36.848895200370997, 14.3179146101222],
            [36.849219716767401, 14.3181289490299],
            [36.850069747604998, 14.3180440839031],
            [36.8511342512166, 14.3178482411803],
            [36.851454276036598, 14.3177644639634],
            [36.851997756783497, 14.317682862748001],
            [36.852850033409297, 14.3175185722111],
            [36.853829197068997, 14.3174239146948],
            [36.854939739339002, 14.3172987926293],
            [36.855813350952801, 14.3171910787953],
            [36.856592639461802, 14.3171268856778],
            [36.8572293204194, 14.317060516503],
            [36.858324142171902, 14.317084452929],
            [36.859559325687599, 14.3171682303997],
            [36.861871364650099, 14.3175414205711],
            [36.863428818773897, 14.317932018365999],
            [36.864418088480598, 14.3179537786688],
            [36.866170926178697, 14.317013731663099],
            [36.867384774706402, 14.316488217559201],
            [36.868815341796299, 14.3156787275888],
            [36.869950587736597, 14.3149780376772],
            [36.871384523508901, 14.3140662670961],
            [36.872487205520201, 14.3132371837118],
            [36.873360817133999, 14.3126235294719],
            [36.875759318942599, 14.3112482415393],
            [36.8765801545334, 14.310921827027601],
            [36.878162312327603, 14.3107368585937],
            [36.879350334290798, 14.310819550383],
            [36.880831431615498, 14.3109207389784],
            [36.882502298044002, 14.310992550211999],
            [36.8835959969024, 14.3110959148262],
            [36.884242783906998, 14.311132908466],
            [36.885418454035097, 14.311227568634299],
            [36.887507037070598, 14.3115594229322],
            [36.888366051061098, 14.3116247056871],
            [36.889339600250302, 14.3117835603116],
            [36.890321009698198, 14.3121262942215],
            [36.891612337919099, 14.312339550612499],
            [36.892528619508901, 14.3124396504813],
            [36.893981644480903, 14.3122644756816],
            [36.895121381997697, 14.3113113482902],
            [36.895297676372202, 14.3108543679693],
            [36.895417826041403, 14.3101884806944],
            [36.895644650650702, 14.3090427582628],
            [36.896959559647797, 14.308162517401501],
            [36.897787132603298, 14.3069950232743],
            [36.898072347705998, 14.3063443575584],
            [36.8985147679834, 14.3055598567566],
            [36.898951573790299, 14.304972295416199],
            [36.899916139826701, 14.3039908469358],
            [36.900287817775499, 14.3037112094999],
            [36.901501666303098, 14.303161726364999],
            [36.902859245276197, 14.303547996827801],
            [36.9036958013846, 14.303496856832799],
            [36.904741215796498, 14.303139964629001],
            [36.906435663001197, 14.302151979596401],
            [36.907428301390098, 14.301471921295899],
            [36.909338344262999, 14.3008332086662],
            [36.910599354342999, 14.3008147109991],
            [36.912853002812099, 14.300631910442499],
            [36.913750195202098, 14.3000410719124],
            [36.914838279590001, 14.2993294521736],
            [36.915766913014899, 14.2988539496553],
            [36.916728110369, 14.2985645128473],
            [36.918530355407697, 14.298522076592301],
            [36.919731852100199, 14.298502490625699],
            [36.920605463713997, 14.298440468387],
            [36.9214319137754, 14.298377358021201],
            [36.922615444162197, 14.298080303820401],
            [36.924193110380003, 14.2976200322504],
            [36.925152061945802, 14.2973752065633],
            [36.926477076989897, 14.297128204377699],
            [36.927350688603703, 14.297042243201],
            [36.927870588574301, 14.296938872122199],
            [36.9291731457363, 14.296621141771899],
            [36.930260107230097, 14.296482950691701],
            [36.932099407774302, 14.2958442238871],
            [36.933026918305202, 14.295461204558199],
            [36.934287928385302, 14.2943828710684],
            [36.934778633109197, 14.2935069253255],
            [36.935170523151903, 14.2928366341049],
            [36.935534340841997, 14.2923839687738],
            [36.935941951402199, 14.292036852193601],
            [36.936468588737497, 14.2917017046423],
            [36.937163660188602, 14.291165249892501],
            [36.937690297523901, 14.2907506663392],
            [36.9387143769478, 14.2902544708456],
            [36.940036023309503, 14.289626607873799],
            [36.941587862962898, 14.2892218148728],
            [36.942781499396602, 14.289029211495601],
            [36.943701149668698, 14.289025947030099],
            [36.944842010079597, 14.2890727376969],
            [36.945595472024102, 14.2890890600203],
            [36.946930593115198, 14.2894285640772],
            [36.947572888543299, 14.2896374893961],
            [36.948309507076303, 14.289884499809199],
            [36.949212313936798, 14.2900988654544],
            [36.950174634184897, 14.290257735293199],
            [36.9516197988983, 14.290380696118801],
            [36.952787608767601, 14.2903012612569],
            [36.9541552937877, 14.2902381486069],
            [36.954931213614302, 14.2903241123844],
            [36.955966521979299, 14.290380696118801],
            [36.957177001824597, 14.2904753650273],
            [36.958658099149297, 14.290622264978699],
            [36.960845496866199, 14.290242501204],
            [36.961637137210303, 14.290121716602499],
            [36.962987978818802, 14.289770243924901],
            [36.964774503340102, 14.2893600104147],
            [36.967732206412997, 14.288238121318299],
            [36.9688674523534, 14.2875373458529],
            [36.969467077805497, 14.2870313497887],
            [36.970773003649803, 14.285504649391299],
            [36.971812803591199, 14.2832608271571],
            [36.972139565775798, 14.2823119295493],
            [36.972282173327102, 14.281728660226999],
            [36.972766140686403, 14.280644819935301],
            [36.973142310211699, 14.280123573599299],
            [36.974909745533203, 14.279033196358499],
            [36.975371255010401, 14.278916758556599],
            [36.976398703116601, 14.2788112027403],
            [36.977192589248901, 14.278575062229599],
            [36.978092027427202, 14.278363950227],
            [36.980066075263998, 14.277002599517701],
            [36.980635382575301, 14.2762658779156],
            [36.981206935674798, 14.275472566434599],
            [36.981583105200102, 14.274421342800499],
            [36.981887409502598, 14.273484378468901],
            [36.982079424394499, 14.272751998292099],
            [36.982415169732, 14.2719652038588],
            [36.982992337302001, 14.2703774595588],
            [36.983280921087101, 14.2695775978417],
            [36.983417914167902, 14.269246769852399],
            [36.983838999457298, 14.2682999896982],
            [36.984192711100398, 14.2672030256476],
            [36.984351039169297, 14.2664586541432],
            [36.984602567448803, 14.265762163959],
            [36.985119098737201, 14.2647718382718],
            [36.9855076200976, 14.264204847650101],
            [36.985823153341101, 14.263796744112399],
            [36.986309366488598, 14.263116569907501],
            [36.9866001960619, 14.2627432894314],
            [36.986949416128603, 14.2624168045432],
            [36.987250351748798, 14.262112084887599],
            [36.9875793597216, 14.261624532581701],
            [36.988470937641097, 14.2607114574579],
            [36.989426520524503, 14.2600639227984],
            [36.989654468027901, 14.2599082965442],
            [36.990593207499799, 14.259546982029301],
            [36.991305122362398, 14.259297761740299],
            [36.992395452538602, 14.2589418877496],
            [36.994320093034801, 14.258222519955201],
            [36.995524958409597, 14.2580636275156],
            [36.996949911028999, 14.2580592742965],
            [36.997326080554302, 14.258044038029],
            [36.998817283925902, 14.258984331466101],
            [36.998994701194498, 14.2588929142207],
            [36.999279916297198, 14.258398825133799],
            [36.999364133355101, 14.2582388445145],
            [36.999483160130303, 14.257917794630099],
            [36.999733565515697, 14.2573899658603],
            [37.000234376286599, 14.25650625802],
            [37.000509485342398, 14.255736819924699],
            [37.000819404115397, 14.255094712574],
            [37.001166378393897, 14.2543709792158],
            [37.001416783779298, 14.253889940120001],
            [37.001703121776103, 14.2532456508549],
            [37.002107363653998, 14.252662306284201],
            [37.002509359743598, 14.252284655169801],
            [37.003018030773298, 14.251862381705401],
            [37.004189209324899, 14.2509253235909],
            [37.004804555294598, 14.2504573372448],
            [37.005808422624597, 14.2499795548341],
            [37.006598940074603, 14.249650874775],
            [37.007471428794297, 14.249413615361901],
            [37.008685277322002, 14.2490762276935],
            [37.0094522139958, 14.2489314773456],
            [37.010465064478602, 14.248764959913199],
            [37.011242107199401, 14.248734486121499],
            [37.012018027026002, 14.2488074055449],
            [37.0131835911072, 14.248848762819399],
            [37.013995443545198, 14.2488999152273],
            [37.014924076970203, 14.248975011294601],
            [37.016087395263099, 14.2492590701055],
            [37.016521955281803, 14.2495028597576],
            [37.017775105103098, 14.2504878108039],
            [37.0188047989976, 14.2513465104724],
            [37.019403301555599, 14.251740488187099],
            [37.020504860672801, 14.2524152550503],
            [37.021665933177502, 14.2530660766609],
            [37.022451959051097, 14.2534165182887],
            [37.023473792686801, 14.2538594670206],
            [37.024294628277602, 14.254267588546499],
            [37.025398433183, 14.254813926073201],
            [37.026313591878697, 14.255130627101201],
            [37.027489262006803, 14.2554484160018],
            [37.028887265167697, 14.2557781759287],
            [37.0297103465468, 14.2560132520178],
            [37.031130807589797, 14.256597676233],
            [37.031904481628203, 14.256993821394699],
            [37.0326545748905, 14.2575738898399],
            [37.033717955607997, 14.2586621943413],
            [37.034162621673701, 14.2591824020373],
            [37.0349430330768, 14.2605329775021],
            [37.035595434551901, 14.261573383059901],
            [37.036634111599099, 14.262880412943399],
            [37.037288758862402, 14.263597589317699],
            [37.038575595506899, 14.2649056957345],
            [37.039501983143701, 14.265566275706799],
            [37.040510342050098, 14.266066878679499],
            [37.041438975475103, 14.266245354253],
            [37.042403541511398, 14.266355269017501],
            [37.043180584232097, 14.2664281827427],
            [37.044592062122298, 14.266517420404],
            [37.045745274368301, 14.266616452401401],
            [37.046863676896997, 14.266633864616299],
            [37.048064050695402, 14.2666403941965],
            [37.0487243124293, 14.266541362209599],
            [37.050103226390398, 14.2661582930153],
            [37.050623126361103, 14.2657686935645],
            [37.0512261204955, 14.2653932409424],
            [37.053624622304099, 14.2639110131775],
            [37.054334291378602, 14.2634539365697],
            [37.055102350946498, 14.262976181696001],
            [37.055562737529598, 14.2627596136634],
            [37.056317322368301, 14.262441835068101],
            [37.057508713013803, 14.262080524613999],
            [37.058039841925599, 14.261945577187401],
            [37.058958369303603, 14.2617670982088],
            [37.059830858023297, 14.2615766479234],
            [37.060785318012698, 14.261409051539101],
            [37.062199041691102, 14.261221865819699],
            [37.0628357226487, 14.261144597250601],
            [37.062964855470803, 14.2611108602615],
            [37.0634128902187, 14.2610901827495],
            [37.064023744611902, 14.2611391558011],
            [37.064341523643698, 14.261210982924201],
            [37.065116320576202, 14.261536381271],
            [37.065902346449803, 14.2619216348936],
            [37.066595172112699, 14.2623286535423],
            [37.067332913539801, 14.262816204325199],
            [37.067966225815098, 14.2633255196417],
            [37.068737654065302, 14.2641362863074],
            [37.069625863302498, 14.2650961432069],
            [37.070245700848602, 14.2656870734825],
            [37.070983442275597, 14.266393358279901],
            [37.0716863739855, 14.266984285153701],
            [37.072589180846002, 14.267588269583401],
            [37.073940022454501, 14.267918011750901],
            [37.075284126698399, 14.267741714018401],
            [37.076097102030502, 14.2676198290856],
            [37.077144762230603, 14.2675447392282],
            [37.078145260878301, 14.2674816201982],
            [37.079170463196299, 14.2673499407856],
            [37.0801833136791, 14.267240026506199],
            [37.081114192892301, 14.2671877899982],
            [37.082103462598901, 14.267112699996799],
            [37.082761478544498, 14.267115964779901],
            [37.083774329027399, 14.267064816504501],
            [37.084634465911897, 14.2670691695497],
            [37.086610759537002, 14.267207378690101],
            [37.087315937035001, 14.267327087326001],
            [37.088005394015603, 14.267722125373],
            [37.0891271652266, 14.267727566663501],
            [37.089796410113301, 14.267949571207],
            [37.090465655, 14.268265165524699],
            [37.091039453887703, 14.2685785828923],
            [37.091825479761297, 14.2691357682471],
            [37.092808012103298, 14.2701543691614],
            [37.093380688097, 14.2708475810387],
            [37.093788298657103, 14.2715864968447],
            [37.094159976605901, 14.2723602337726],
            [37.094519302719597, 14.273374467182601],
            [37.0950504316313, 14.2750470717453],
            [37.095198653653199, 14.275748974318599],
            [37.0953906685452, 14.2771201265223],
            [37.095481622967696, 14.277707760624899],
            [37.095499589273402, 14.278617502247499],
            [37.0951155594894, 14.279777526304599],
            [37.095038079796197, 14.2809157804975],
            [37.095347998569203, 14.282334781488199],
            [37.095590543695899, 14.2829691939145],
            [37.096025103714602, 14.283257562608],
            [37.097151366501997, 14.2838060061978],
            [37.0978677729411, 14.284062816625701],
            [37.098878377635799, 14.2844828533888],
            [37.099639699839102, 14.2849703562136],
            [37.100237079503003, 14.285456769810001],
            [37.101210628692201, 14.2861303475282],
            [37.101888856731698, 14.286847449862],
            [37.102355980679398, 14.287575431532099],
            [37.102868020391398, 14.2885917727042],
            [37.103261033328202, 14.2896407538526],
            [37.103385674573801, 14.290480805767899],
            [37.1036124991831, 14.2918866887421],
            [37.103675381252998, 14.2931881019908],
            [37.103440696385, 14.2951195333562],
            [37.103328406974498, 14.296178277144101],
            [37.103016242413197, 14.2971673765486],
            [37.102869143285503, 14.298178233820099],
            [37.102567084771202, 14.2992924565904],
            [37.102239199692498, 14.3009485470266],
            [37.1022796238803, 14.3021693890619],
            [37.1023481204207, 14.3043792921352],
            [37.102705200746101, 14.3057742074557],
            [37.102954483237397, 14.3074095768697],
            [37.1029702037549, 14.308733749502901],
            [37.1029118132615, 14.310597588142899],
            [37.103043191871798, 14.3121632876916],
            [37.103374445632802, 14.313868244833699],
            [37.103748369369796, 14.3143894129404],
            [37.104157102824097, 14.3148507381883],
            [37.104940882909503, 14.3157657696611],
            [37.105477626291702, 14.316481689426],
            [37.1056741327601, 14.317138853887499],
            [37.105463028668403, 14.318921021999699],
            [37.105163215942298, 14.319875205093799],
            [37.104339011669097, 14.3217694151576],
            [37.103579935253997, 14.3226746264982],
            [37.101445313560099, 14.325182434518499],
            [37.100651427427799, 14.326145294793101],
            [37.100258414491002, 14.3267415045074],
            [37.099344378689402, 14.328072090911199],
            [37.098705451943601, 14.330485186591],
            [37.098804266624803, 14.331705868024899],
            [37.099208508502699, 14.3330429526301],
            [37.099440947582401, 14.3334117646375],
            [37.100280872373098, 14.3346944423821],
            [37.100743504744401, 14.3362055774857],
            [37.100983804082901, 14.339175597357199],
            [37.101173573186699, 14.340845536301],
            [37.101591289793802, 14.341941053005399],
            [37.1019393869664, 14.3427265145158],
            [37.102545749783197, 14.3438698891621],
            [37.103011750836799, 14.3445965980835],
            [37.103396903514898, 14.3451971102639],
            [37.104212124635197, 14.346709262428799],
            [37.104760096958501, 14.347656800058401],
            [37.107128280626299, 14.3532516506392],
            [37.107707693984601, 14.354763748422901],
            [37.108041193533801, 14.356158350419401],
            [37.108471261976099, 14.357056895933599],
            [37.109162964744797, 14.357602983575999],
            [37.109736763632597, 14.3579858968255],
            [37.111229089898302, 14.3586614325234],
            [37.112202639087499, 14.3593108586394],
            [37.113056038607397, 14.3602028646382],
            [37.113711808764798, 14.3609436620397],
            [37.114248552146996, 14.3617279690977],
            [37.114304696852301, 14.362212040736599],
            [37.114418109156901, 14.3629386901299],
            [37.1143877910161, 14.36391661725],
            [37.113800517399099, 14.365662516553],
            [37.113561340954703, 14.366408734777901],
            [37.1135905362014, 14.367249587025199],
            [37.113903823656798, 14.3680795583946],
            [37.114265395558597, 14.3686114781377],
            [37.115282737617903, 14.369848268233],
            [37.115983423539497, 14.370853359103901],
            [37.116894090658803, 14.372101014322601],
            [37.117594776580397, 14.3731996413145],
            [37.1182842335609, 14.3739969583152],
            [37.119605879922702, 14.3756503214787],
            [37.120903945508303, 14.377130723776901],
            [37.121733764251999, 14.3781249046813],
            [37.122399640456301, 14.3791876073882],
            [37.122755597887704, 14.3805711800035],
            [37.123239565246998, 14.382449648923799],
            [37.123756096535303, 14.386561123930599],
            [37.124030082696997, 14.388104539060199],
            [37.124870007487701, 14.3914817421501],
            [37.125228210707199, 14.392519365305899],
            [37.125906438746703, 14.393410151149499],
            [37.126572314951098, 14.3942998457948],
            [37.127015858122597, 14.395004638058101],
            [37.127643555927399, 14.3964109530075],
            [37.128190405356598, 14.397496408913],
            [37.128513798858897, 14.398431767482901],
            [37.129025838570797, 14.399619449692199],
            [37.1295019456714, 14.400668998764599],
            [37.130051040888802, 14.4015010212053],
            [37.130997640619498, 14.402738716821],
            [37.132178925218099, 14.4041145303392],
            [37.132962705303498, 14.4050041823103],
            [37.133524152356003, 14.4056415102697],
            [37.133935131598498, 14.405976487375],
            [37.134344987946903, 14.406244033662601],
            [37.134580795708999, 14.4063136391483],
            [37.135675617461501, 14.4063886825382],
            [37.136159584820803, 14.4062646977934],
            [37.137385785183703, 14.405903619021499],
            [37.138413233289903, 14.4056099701917],
            [37.139108304741001, 14.405429430348899],
            [37.140510799478299, 14.4053337224211],
            [37.1413698134687, 14.405477284297399],
            [37.142730761124199, 14.406289712265],
            [37.143247292412497, 14.406638826988701],
            [37.144689088443499, 14.4079243475223],
            [37.146001751652499, 14.4090238858556],
            [37.147044920276102, 14.409858052270501],
            [37.148463135530903, 14.4110173970207],
            [37.149835312127401, 14.4121647726163],
            [37.1511962597829, 14.413103332338499],
            [37.151957581986203, 14.413672121533599],
            [37.153190519713597, 14.414381202780801],
            [37.154929882682502, 14.415104419827699],
            [37.155469994747101, 14.415291477087999],
            [37.156870243696197, 14.4156677662174],
            [37.158080723541602, 14.416076680851701],
            [37.159220461058297, 14.41656607238],
            [37.160946349297902, 14.4174959133233],
            [37.162462256339801, 14.4182180327363],
            [37.163367308988597, 14.4184409757571],
            [37.164381282365497, 14.4184583761786],
            [37.165115655110299, 14.417737345074199],
            [37.165802866302698, 14.4169467094924],
            [37.165841044702198, 14.416441005758299],
            [37.166200370815901, 14.4154643961599],
            [37.166910039890297, 14.415065268288201],
            [37.167946471149399, 14.414967389409499],
            [37.169149090735999, 14.414766193801601],
            [37.169588142331101, 14.414285498695101],
            [37.169931747927301, 14.4139646721988],
            [37.170192259359702, 14.413702572921901],
            [37.170559445732103, 14.4133241053724],
            [37.170903051328303, 14.4129467247337],
            [37.171470112851402, 14.412637859412801],
            [37.172342601571103, 14.4124932145907],
            [37.173048901963199, 14.412739001977],
            [37.173812469954697, 14.412949987393199],
            [37.1761065426115, 14.4135492283887],
            [37.177647153323797, 14.4139255204592],
            [37.178611719360099, 14.4141615183411],
            [37.179752579770899, 14.414489957373901],
            [37.180917020957999, 14.414818395922699],
            [37.1817513312781, 14.4150413423441],
            [37.182845030136498, 14.4154034938605],
            [37.184196994639102, 14.4158939744057],
            [37.185665740128698, 14.4164660190883],
            [37.186734735316797, 14.4169891233018],
            [37.187627436130299, 14.4174665499844],
            [37.188541471931899, 14.418358323736401],
            [37.189092812937602, 14.4188096468972],
            [37.189831677258802, 14.419284894635201],
            [37.191642905450401, 14.4198134298308],
            [37.1926669848743, 14.420013533363701],
            [37.1933957431485, 14.420201674021399],
            [37.194453509395601, 14.420714981829001],
            [37.195180021881598, 14.421270701448501],
            [37.197925497968697, 14.4233750283459],
            [37.200699046408403, 14.4251487375432],
            [37.203060492711501, 14.4272182283364],
            [37.205070473159701, 14.4306122386044],
            [37.206429175026997, 14.433253680197099],
            [37.207493678638698, 14.434318295180599],
            [37.2085581822503, 14.435385079959801],
            [37.2107399654966, 14.436468171202],
            [37.213215946998503, 14.437389229587399],
            [37.218990991381297, 14.438933380311401],
            [37.226006833750198, 14.4412822087536],
            [37.227768654601199, 14.439496666429401],
            [37.229706769826699, 14.437530595944301],
            [37.2332349031051, 14.436148464094501],
            [37.235409948986799, 14.4334994446801],
            [37.236646255396501, 14.4326414427169],
            [37.240586490811502, 14.431304953984499],
            [37.246766899966303, 14.4298064241241],
            [37.248888046930901, 14.429162640419401],
            [37.251184365375899, 14.4299108213062],
            [37.253305512340503, 14.430026093137901],
            [37.255193097331301, 14.430590489264],
            [37.256139697061897, 14.4326055566312],
            [37.257204200673598, 14.4345009865148],
            [37.2586224159284, 14.4359929601312],
            [37.260213556875399, 14.4358787788303],
            [37.2636282778492, 14.435932063444699],
            [37.266274939254998, 14.4353546315354],
            [37.2684005777961, 14.4362104481614],
            [37.270989971602503, 14.436662822583401],
            [37.273290781623999, 14.437294622821501],
            [37.2771209734167, 14.4374033662271],
            [37.281599075107998, 14.437968831079999],
            [37.284369254865403, 14.439462956296399],
            [37.286611674393399, 14.441187603642801],
            [37.287733445604403, 14.4418096043237],
            [37.288855216815499, 14.4424413899483],
            [37.290387967268998, 14.443130806293601],
            [37.292040867391798, 14.443304791153601],
            [37.293630885444699, 14.4438136960877],
            [37.296988338819098, 14.4444433012219],
            [37.299052218184301, 14.4448467260275],
            [37.301703371166603, 14.444603148873901],
            [37.304114224810299, 14.444024652065201],
            [37.305586338982202, 14.4438550173774],
            [37.3077074859468, 14.443850667768301],
            [37.309176231436297, 14.442868741341499],
            [37.309706237454002, 14.441949880460699],
            [37.3102328747893, 14.4410234038723],
            [37.310759512124598, 14.439418371919199],
            [37.312224888931802, 14.436482307900301],
            [37.312800933607797, 14.4328534967417],
            [37.313380346965999, 14.429576967645501],
            [37.315086023111803, 14.427726082571001],
            [37.317435117579699, 14.4264069641372],
            [37.318556888790802, 14.4262525408584],
            [37.319674168425401, 14.426105729895401],
            [37.321909850588703, 14.425417348311701],
            [37.324320704232498, 14.4240873412165],
            [37.326793317052001, 14.423102065100201],
            [37.3289099724403, 14.4222407605548],
            [37.329905979511501, 14.420855271257],
            [37.332251705297203, 14.417863498497599],
            [37.333484643024597, 14.415213174067899],
            [37.334888260656101, 14.412386634135499],
            [37.3365928139077, 14.410952143254899],
            [37.338534297815499, 14.409971159340699],
            [37.341769355732403, 14.409498066886201],
            [37.3462440887414, 14.408570367734001],
            [37.343207783081098, 14.416703101815299],
            [37.342585699746898, 14.420921609638],
            [37.342545275559097, 14.421419690621599],
            [37.347386072046397, 14.4147933824079],
            [37.360753003474102, 14.4165769477351],
            [37.371111701594103, 14.4208878966928],
            [37.392969958244898, 14.453744721029301],
            [37.401512936596902, 14.468253897751101],
            [37.416359842455201, 14.475566842023699],
            [37.426112177758398, 14.479338470068599],
            [37.435593895582301, 14.479845118014801],
            [37.444374927484603, 14.4741186241977],
            [37.448982161998003, 14.471192803951499],
            [37.4533131645615, 14.474493726568401],
            [37.4693413550184, 14.4684343866374],
            [37.482670108046598, 14.4683321821053],
            [37.496437912669798, 14.471158011356801],
            [37.511256746175498, 14.458887845462399],
            [37.516477080870303, 14.4649583188598],
            [37.5234221809106, 14.462932671540701],
            [37.524905524023602, 14.457609143190099],
            [37.5401926043711, 14.4501662105473],
            [37.551179000295797, 14.4454078223841],
            [37.559197587100698, 14.4522615653639],
            [37.568455848997701, 14.4523550784034],
            [37.572940688053599, 14.456096655068601],
            [37.570358031611804, 14.4639079855188],
            [37.582057465293303, 14.471671201575001],
            [37.593468315189902, 14.477913108804101],
            [37.599785717425398, 14.4834383712118],
            [37.610668807092502, 14.4783142983697],
            [37.639411527502098, 14.4901224283483],
            [37.657145394104703, 14.494735266982801],
            [37.667752251821902, 14.5026604714122],
            [37.669198539429402, 14.5168403352911],
            [37.678571336525003, 14.5251191120316],
            [37.684002775311598, 14.5344954089789],
            [37.6884819998971, 14.545756004949601],
            [37.691949496893798, 14.5571192771555],
            [37.698973199521497, 14.5652650409302],
            [37.698663280748498, 14.575287340974301],
            [37.706014868454901, 14.5811590102902],
            [37.717918668863597, 14.5971691909304],
            [37.720103820792197, 14.6101423096028],
            [37.723649920376303, 14.623747016729601],
            [37.734970938744397, 14.6371868269859],
            [37.744380791345499, 14.643274127399801],
            [37.755460387481001, 14.6412653260764],
            [37.765111662314801, 14.633940487418201],
            [37.775386143376899, 14.6329376760279],
            [37.782049396996896, 14.648347653995801],
            [37.775196374273101, 14.678447437735899],
            [37.773550211515001, 14.698196677148999],
            [37.779148961523298, 14.718708708912599],
            [37.784431055393902, 14.7388039808126],
            [37.804736349497297, 14.768918438512401],
            [37.821201345761096, 14.793553746452],
            [37.832598720928402, 14.8051451625117],
            [37.844994348955098, 14.8124468827871],
            [37.863650111618099, 14.8085355101473],
            [37.875637006190502, 14.7998581553287],
            [37.887176988909196, 14.8023323170389],
            [37.902104743142999, 14.8100249448547],
            [37.912902492858102, 14.810238805994899],
            [37.920517960679298, 14.817794373748001],
            [37.927137421429101, 14.8376840137062],
            [37.935502982512503, 14.850080598678501],
            [37.9383012346225, 14.8500914525697],
            [37.9373321770098, 14.8486500510414],
            [37.936648334499701, 14.8474626241869],
            [37.936655071864401, 14.845939799395],
            [37.939243342776699, 14.845614176165499],
            [37.941141033814397, 14.8454535351916],
            [37.942004539381301, 14.8451192279172],
            [37.943561993505099, 14.843941550445701],
            [37.944257064956197, 14.8429288512315],
            [37.944264925215002, 14.8412377523661],
            [37.943408157012698, 14.840049199356899],
            [37.9430701658871, 14.838695652037901],
            [37.943075780357603, 14.837511426866],
            [37.943775343385099, 14.835483792543],
            [37.945505723201201, 14.8343071480645],
            [37.947058685748601, 14.8341454132217],
            [37.948446582862502, 14.8322914239701],
            [37.9503442739002, 14.8319614382692],
            [37.9524126448419, 14.832309877103],
            [37.959162361308003, 14.832958992188001],
            [37.967677267307302, 14.8329665905136],
            [37.967733412012599, 14.8319657801896],
            [37.9680556826208, 14.8317280599203],
            [37.968280261441798, 14.831660760070699],
            [37.968600286261797, 14.831629281101501],
            [37.969676018814503, 14.831696580960999],
            [37.970230728502401, 14.8317714791671],
            [37.970714695861801, 14.831867001479299],
            [37.971257053714503, 14.832045020221299],
            [37.971674770321698, 14.831635793992101],
            [37.971834221284603, 14.8311418992363],
            [37.971894857566298, 14.830911776459599],
            [37.972079012199501, 14.8302355180156],
            [37.972191301610003, 14.829579881808201],
            [37.972294607867703, 14.828649613118399],
            [37.9724192491134, 14.828040648601601],
            [37.972679760545802, 14.8267662681145],
            [37.9726741460752, 14.8260530893886],
            [37.972504589065402, 14.8251998770494],
            [37.973204152092897, 14.8238603486028],
            [37.973317564397497, 14.8231135090468],
            [37.9736566784172, 14.822241831132301],
            [37.974080009494898, 14.821221431605901],
            [37.9742854991161, 14.8207068878932],
            [37.974514569513602, 14.8202715872998],
            [37.974641456547502, 14.8194433196643],
            [37.974731288075901, 14.8186747539396],
            [37.974736902546397, 14.818122209946599],
            [37.975053558684102, 14.8171582398412],
            [37.975200657811797, 14.816550328559799],
            [37.975312947222299, 14.8159641267798],
            [37.9753331593162, 14.8150783077468],
            [37.9752646627758, 14.8147558947731],
            [37.975012011602203, 14.813936290609099],
            [37.974853683533297, 14.813186160106801],
            [37.974720059134803, 14.812380662522299],
            [37.9746324733946, 14.8116316122161],
            [37.974708830193798, 14.8110681944099],
            [37.974739148334599, 14.81036690546],
            [37.974606646830203, 14.8092845711558],
            [37.974534781607503, 14.808214173036999],
            [37.974482005584498, 14.807487905988699],
            [37.974277638857401, 14.8066693599409],
            [37.973697102605001, 14.8055370689996],
            [37.973483752725102, 14.8042690698227],
            [37.973280508892003, 14.803323493691099],
            [37.973332162020903, 14.8027611399144],
            [37.973364725949899, 14.801886123222401],
            [37.973678013405198, 14.801244514446299],
            [37.974012635848602, 14.8008916830919],
            [37.9744056487854, 14.8006072463207],
            [37.974868281156702, 14.800438972482601],
            [37.975364600351199, 14.800420516634301],
            [37.976142765966003, 14.8007386083874],
            [37.976931037627899, 14.801206517251099],
            [37.977834967382499, 14.8018470405086],
            [37.978634467985401, 14.8023496870959],
            [37.97917458005, 14.8027795955635],
            [37.980089738745598, 14.803457025358201],
            [37.980803899396498, 14.8042788403926],
            [37.981387804331199, 14.805101737926501],
            [37.982146880746299, 14.806110271672701],
            [37.983038458665803, 14.806796375909601],
            [37.983909824491398, 14.807219761934199],
            [37.985014752290802, 14.8078960922526],
            [37.986123048772598, 14.8082738809821],
            [37.986605893237801, 14.808417179983],
            [37.9873380201944, 14.8085735060579],
            [37.9881902968202, 14.8085702492658],
            [37.9887708330726, 14.8083335889106],
            [37.989317682501799, 14.808039391503399],
            [37.9897881751318, 14.807123143145001],
            [37.989938642941901, 14.8062047196949],
            [37.990185679645002, 14.805044200337701],
            [37.990242947244397, 14.8039629250743],
            [37.990053178140599, 14.802823020613801],
            [37.989719678591399, 14.8017840738994],
            [37.989467027417803, 14.800942707969501],
            [37.989391793512702, 14.8002055601685],
            [37.989408636924303, 14.7996301706001],
            [37.989535523958203, 14.7987801111422],
            [37.989744382261698, 14.7979072496877],
            [37.989977944235598, 14.7969887872001],
            [37.990788673779498, 14.796190829044701],
            [37.990985180247897, 14.7955253188416],
            [37.991624106993697, 14.7941856069552],
            [37.9920485609655, 14.792981596264401],
            [37.992393289455798, 14.791591927287101],
            [37.9925403885835, 14.7910642850161],
            [37.992690856393601, 14.790144707868199],
            [37.992863782085799, 14.789318496505199],
            [37.993056919871897, 14.788940674755001],
            [37.993050182507297, 14.7884455969832],
            [37.9927256661109, 14.787672578555],
            [37.992623482747298, 14.7872567543009],
            [37.992672890088002, 14.786946242719599],
            [37.992750369781199, 14.786314361137],
            [37.992661661146897, 14.785658592051201],
            [37.992526913854299, 14.7849083637305],
            [37.992407887079104, 14.783722758840501],
            [37.992291106092203, 14.7822602867468],
            [37.992202397457902, 14.7816490204359],
            [37.992062035694801, 14.7802679648152],
            [37.991956483648899, 14.778920558532],
            [37.991881249743798, 14.778069332203801],
            [37.991761100074598, 14.7770552393409],
            [37.991652179346403, 14.7760183407793],
            [37.9917566084982, 14.775065041190301],
            [37.991926165507998, 14.7745482164297],
            [37.992240575857501, 14.773872867935699],
            [37.992467400466701, 14.7735862242103],
            [37.992921049685201, 14.773084596781],
            [37.993349995233402, 14.7727317196487],
            [37.994063032990098, 14.7723245530147],
            [37.995364467258, 14.772335410801499],
            [37.9958113791118, 14.772592740190101],
            [37.996338016447197, 14.7731855738242],
            [37.996722046231099, 14.773809893210201],
            [37.9970375794747, 14.774250715904801],
            [37.9973879224355, 14.774679594268999],
            [37.997916805559001, 14.7750748130752],
            [37.998552363622501, 14.7753343107464],
            [37.999247435073599, 14.775571007096801],
            [38.000121046687397, 14.7757393002165],
            [38.000510690941901, 14.7757664442559],
            [38.001293348133203, 14.775612266067199],
            [38.001873884385503, 14.775352768728],
            [38.002719423646703, 14.774739311442399],
            [38.003425724038898, 14.773859838683601],
            [38.003680621000697, 14.7730921972052],
            [38.003621107613199, 14.771894584220901],
            [38.003743503070602, 14.771492844932499],
            [38.003961344526999, 14.7709542959646],
            [38.003980433726802, 14.770136697903499],
            [38.003797401987697, 14.769467850455801],
            [38.003686235471299, 14.768730595770499],
            [38.003652548648098, 14.767258250505201],
            [38.003763715164503, 14.766764209114299],
            [38.004145499160302, 14.766446067580301],
            [38.004729404094903, 14.7658412716395],
            [38.005854543988299, 14.765724004009201],
            [38.006375566853102, 14.765647997178],
            [38.007509689899301, 14.7656707992302],
            [38.0084068822893, 14.7658977338094],
            [38.009302951785202, 14.766135526248201],
            [38.010272009398001, 14.766201760625499],
            [38.0111108112945, 14.7662321632837],
            [38.0118564129803, 14.7661941599602],
            [38.012399893727199, 14.7661757012007],
            [38.013655289336803, 14.7660030574331],
            [38.014247054530202, 14.765904248674101],
            [38.014934265722502, 14.765772865529501],
            [38.015492344092799, 14.765536158506601],
            [38.015943747523103, 14.7652408172726],
            [38.016800515725301, 14.7647315698641],
            [38.017394526706902, 14.7644145111736],
            [38.0179413761361, 14.764016015519701],
            [38.018526403964898, 14.763457903212601],
            [38.019167576499001, 14.763153872559201],
            [38.019739129598499, 14.762676109245],
            [38.019990657877997, 14.7623557900714],
            [38.020195024605201, 14.761965977288099],
            [38.020531892836701, 14.7613589972174],
            [38.021155099065098, 14.7603871740773],
            [38.021405504450499, 14.7589397467571],
            [38.021451543108803, 14.7578234946529],
            [38.021470632308599, 14.757006933065201],
            [38.021463894943999, 14.7563977674375],
            [38.021505442025898, 14.7557875142426],
            [38.021475123884997, 14.755271729513399],
            [38.021541374637202, 14.7544888202507],
            [38.021486352826102, 14.754015381382301],
            [38.0213841694625, 14.753600578591101],
            [38.021246053487602, 14.7531966337518],
            [38.021217981135003, 14.752321417360401],
            [38.021344868168804, 14.751402762125499],
            [38.021376309203802, 14.750574231678501],
            [38.021087725418802, 14.7498477718496],
            [38.020376933450201, 14.748702153888599],
            [38.019909809502501, 14.7480647316159],
            [38.019327027461898, 14.747128683941],
            [38.0188655179847, 14.7458701169755],
            [38.018370321684301, 14.744461686126799],
            [38.017767327549798, 14.7432248222235],
            [38.016834202548402, 14.7419271391968],
            [38.016582674268903, 14.740890073142801],
            [38.016714052879202, 14.739521789954701],
            [38.016694963679399, 14.738969044546399],
            [38.016855537536401, 14.7382924996325],
            [38.016995899299602, 14.737166368044999],
            [38.017617982633801, 14.7363204080856],
            [38.018607252340502, 14.735511367567099],
            [38.019439316872401, 14.734978159354601],
            [38.020235448792903, 14.7345600623892],
            [38.021005754149101, 14.734325493350701],
            [38.022172441124297, 14.733450201098099],
            [38.0233391280996, 14.7325868510767],
            [38.0241779299961, 14.732593366938801],
            [38.0249852908577, 14.7322447680466],
            [38.0261014475983, 14.731690918192401],
            [38.026697704368097, 14.7311674744873],
            [38.027259151420701, 14.7305517206355],
            [38.028067635176399, 14.7300054684077],
            [38.028653785899301, 14.729240930187199],
            [38.0297093063581, 14.728893411927601],
            [38.029883354944403, 14.7279855178598],
            [38.0301595868943, 14.727516365376299],
            [38.030754720769998, 14.7270157177465],
            [38.031256654434998, 14.7263988661573],
            [38.031841682263803, 14.7258059050617],
            [38.032654657595899, 14.7248350092359],
            [38.0331554683668, 14.7243560758098],
            [38.033527146315599, 14.7238184962213],
            [38.033885349535097, 14.723407980006501],
            [38.034387283200097, 14.7228834303851],
            [38.034458025528799, 14.7227922042352],
            [38.034661269361798, 14.722574998962701],
            [38.035022841263697, 14.721829983234199],
            [38.035335005824898, 14.7213727638664],
            [38.035703315091403, 14.7211349225335],
            [38.035954843370902, 14.720768928742901],
            [38.036410738377597, 14.719968518817],
            [38.036899197313403, 14.719580802233599],
            [38.037565073517698, 14.7191148897973],
            [38.0383162896741, 14.7184545739636],
            [38.038815977550897, 14.7181483083753],
            [38.039304436486603, 14.717646553184199],
            [38.039831073821901, 14.716926499886799],
            [38.040477860826499, 14.715976199664301],
            [38.041275115641099, 14.715477697663699],
            [38.042046543891402, 14.7151627393855],
            [38.042697822472398, 14.714949870774401],
            [38.043920654152899, 14.714454624630701],
            [38.044584284568998, 14.7142417553287],
            [38.045484845641298, 14.7140082504984],
            [38.046114789234302, 14.713577080457201],
            [38.047100690258603, 14.7130329578542],
            [38.047883347449897, 14.712810312485701],
            [38.048842299015703, 14.712634368180201],
            [38.049662011712499, 14.712181473852199],
            [38.050805117911501, 14.711351708184299],
            [38.051649534278603, 14.710772825293599],
            [38.052371555188202, 14.710618601075],
            [38.053174424473397, 14.7107511035793],
            [38.053729134161301, 14.7107684809509],
            [38.054355709071999, 14.710773911379301],
            [38.055218091744798, 14.7109205328938],
            [38.056426325801901, 14.710584932392999],
            [38.057549219907102, 14.7105947071722],
            [38.0584576412381, 14.710718521003701],
            [38.059225700806103, 14.7108401625943],
            [38.059827572046402, 14.7109031555343],
            [38.0618588874826, 14.711162729698399],
            [38.063040172081202, 14.711173590535299],
            [38.063712785650203, 14.711316953532499],
            [38.064338237666803, 14.711461402517701],
            [38.065034432011998, 14.7115124483768],
            [38.065591387488098, 14.711483124161299],
            [38.066158449011198, 14.7114537999419],
            [38.067119646365299, 14.711001989248199],
            [38.068542353196499, 14.7105545219662],
            [38.069575415773201, 14.710023424899401],
            [38.071000368392603, 14.709299002072299],
            [38.071735864031503, 14.7091100218089],
            [38.072708290326602, 14.708750524419299],
            [38.074192756333602, 14.707946812101699],
            [38.075223573122102, 14.7075992599126],
            [38.075980403749, 14.7076057765212],
            [38.076666492047202, 14.70748521923],
            [38.077604108625003, 14.7071713355197],
            [38.078254264311902, 14.707119202541399],
            [38.078951581551202, 14.707101824879199],
            [38.079707289284002, 14.707212607452],
            [38.080381025747101, 14.7072984096021],
            [38.0814298088413, 14.707561246358299],
            [38.082598741604698, 14.707755658466199],
            [38.083330868561298, 14.707831685500301],
            [38.084121386011297, 14.7079772228919],
            [38.084900674520298, 14.708063024741399],
            [38.0858001126985, 14.708002203180699],
            [38.086616456713003, 14.7078827322084],
            [38.087374410233899, 14.7077285059475],
            [38.088049269591103, 14.707630756852501],
            [38.088874596758401, 14.707811049593699],
            [38.089370915952898, 14.7078620963066],
            [38.090260248084199, 14.707592743303801],
            [38.0909126495593, 14.7073223038657],
            [38.091389879554001, 14.7068194376746],
            [38.0922219440859, 14.706182979020101],
            [38.092822692432101, 14.705175068003401],
            [38.093225811415898, 14.703671881721601],
            [38.093802978985899, 14.702596619289499],
            [38.0943419681564, 14.701772247838999],
            [38.095177401370599, 14.7009283228538],
            [38.095937600679797, 14.700531883349999],
            [38.097026807961797, 14.700322258883199],
            [38.097913894304902, 14.7002038698467],
            [38.0992860709014, 14.700147390650701],
            [38.100551572557897, 14.7000083648753],
            [38.101497049394403, 14.7000507243007],
            [38.102359432067203, 14.700024656963],
            [38.1033644222913, 14.699963833162901],
            [38.1041684144706, 14.6999703499995],
            [38.1045120200667, 14.699881286550101],
            [38.1054979210911, 14.6993143208135],
            [38.105904408757098, 14.698891810907099],
            [38.106038033155599, 14.698433457333801],
            [38.106248014353298, 14.697434199763901],
            [38.1062300480476, 14.696812919926799],
            [38.106118881531202, 14.695995043600901],
            [38.106223310682999, 14.694868693635501],
            [38.106107652590097, 14.6945102589666],
            [38.1060548765672, 14.693878109117501],
            [38.1061716575541, 14.6926583371432],
            [38.106222187788902, 14.691001912830799],
            [38.106691557524798, 14.6899363637355],
            [38.107205843025, 14.6893650274225],
            [38.107668475396302, 14.6891401855361],
            [38.108388250517699, 14.689295511115199],
            [38.108867726300602, 14.689909209001501],
            [38.109798605513802, 14.690170980495999],
            [38.110379141766103, 14.6900840854289],
            [38.111383009096102, 14.690161204802701],
            [38.1122633580746, 14.6894899394816],
            [38.1135243681547, 14.6885351750607],
            [38.115053749925899, 14.687972524677599],
            [38.116018315962201, 14.687232821873801],
            [38.117293923665599, 14.685967392037201],
            [38.118544827698798, 14.6847812485783],
            [38.119452126135698, 14.683798221879201],
            [38.120641270993097, 14.6829118647349],
            [38.121871962932303, 14.6828075871876],
            [38.1229488183792, 14.682597945717999],
            [38.124344575751898, 14.6825523242317],
            [38.1260379000624, 14.682175403492501],
            [38.126582503703403, 14.682100453758],
            [38.128261230390599, 14.681953812898699],
            [38.129482939177002, 14.681572546204499],
            [38.130562040412102, 14.681132622269899],
            [38.131419931508397, 14.680300566186],
            [38.132054366677799, 14.6793283817647],
            [38.1322564876168, 14.677891278889399],
            [38.132504647213999, 14.676535635777199],
            [38.132316001004298, 14.6751419650702],
            [38.132394603591699, 14.674256659113899],
            [38.1330054579849, 14.6733072595434],
            [38.134230535453597, 14.672351338193801],
            [38.134680815989803, 14.672228588899801],
            [38.135595974685501, 14.6716485160833],
            [38.136274202724998, 14.671103202782501],
            [38.136860353447901, 14.6702797988491],
            [38.1374004655124, 14.6693640570632],
            [38.137370147371598, 14.668788321831601],
            [38.1379327173183, 14.6679757721733],
            [38.1383414507726, 14.6671849455453],
            [38.138639017710403, 14.666900334164101],
            [38.138817557873097, 14.6668329833252],
            [38.139114001916901, 14.6666287580738],
            [38.139339703631997, 14.666492969902301],
            [38.140096534258902, 14.6665451125701],
            [38.140579378724098, 14.6666635198322],
            [38.141299153845502, 14.666900334164101],
            [38.141981873461503, 14.6671360619375],
            [38.1429834950033, 14.667523871592801],
            [38.143641510948903, 14.667909507963801],
            [38.144229907460002, 14.6682712451253],
            [38.144570144373802, 14.668468951069],
            [38.145668334808697, 14.668674260898401],
            [38.146707011855902, 14.668883915710699],
            [38.146637392421397, 14.669060981535701],
            [38.146043381439803, 14.670298265725901],
            [38.1460826827335, 14.6711618620474],
            [38.147102270580902, 14.671461675822499],
            [38.148407073531097, 14.671519248710601],
            [38.149366025096903, 14.671764748025501],
            [38.150009443419201, 14.672890130740701],
            [38.149784864598097, 14.6744576184489],
            [38.149224540439697, 14.675974040777],
            [38.1497006475403, 14.6764802367214],
            [38.150255357228197, 14.676974482704299],
            [38.151519735990597, 14.6774383125341],
            [38.152752673717998, 14.6772091133735],
            [38.153571263520703, 14.6770005527911],
            [38.154794095201197, 14.676542153312299],
            [38.156152797068401, 14.6764813229775],
            [38.1576237883462, 14.676301004385699],
            [38.159318235550899, 14.6758219645134],
            [38.160014429896101, 14.675502604015801],
            [38.160363649962797, 14.6753711663966],
            [38.160935203062301, 14.6749420926275],
            [38.1613001436465, 14.675156086485901],
            [38.161912120933799, 14.675590591138199],
            [38.162462339045298, 14.6759696957423],
            [38.162945183510502, 14.676179343565099],
            [38.1632517336012, 14.676396594983],
            [38.163919855593797, 14.676770266917],
            [38.165332456378003, 14.6765932073302],
            [38.167222287157003, 14.676508479379001],
            [38.167399704425598, 14.6765008755869],
            [38.168320477591799, 14.6772091133735],
            [38.168502386436899, 14.6773155660941],
            [38.169359154639103, 14.6779119176219],
            [38.170268698864298, 14.678390952917599],
            [38.171114238125497, 14.678757017731099],
            [38.172515609968698, 14.6796173219435],
            [38.173467824169798, 14.6797487570113],
            [38.174069695410203, 14.6799518837789],
            [38.1751914666213, 14.679901916784001],
            [38.177743804922301, 14.678750500262099],
            [38.179660585159702, 14.677972747558901],
            [38.179817790334504, 14.676234742697201],
            [38.1802422443062, 14.675177811738999],
            [38.180729580347801, 14.6741762753282],
            [38.181370752881897, 14.673974229466101],
            [38.182568880892099, 14.674267521785399],
            [38.182769878936902, 14.674776980471901],
            [38.183168506344202, 14.677008156565799],
            [38.183196578696901, 14.677641441447401],
            [38.183247108931603, 14.678735292833499],
            [38.183365012812601, 14.679999678285],
            [38.183625524245002, 14.680506951149599],
            [38.184628268680903, 14.681730049620301],
            [38.185067320275998, 14.683557080953101],
            [38.185063951593698, 14.684768213999],
            [38.184618162634003, 14.685364545204299],
            [38.184458711670999, 14.687044908721701],
            [38.1843958296012, 14.688259281038301],
            [38.184771999126397, 14.688703535415],
            [38.185692772292597, 14.689411733679],
            [38.186347419555901, 14.689498629013499],
            [38.187061580206802, 14.689582265740301],
            [38.1893096142053, 14.6895399042852],
            [38.191473431145901, 14.6889816012647],
            [38.1924189079825, 14.6889392396932],
            [38.193368876395397, 14.689013100889399],
            [38.194072930999297, 14.688866464666701],
            [38.195031882565097, 14.687842180752799],
            [38.195940303896201, 14.686993857143801],
            [38.196509611207503, 14.6865072372496],
            [38.197063198001402, 14.6856752016177],
            [38.197863821498302, 14.685062578057799],
            [38.199399940634201, 14.684993060382199],
            [38.200110732602703, 14.685019129513099],
            [38.200884406641201, 14.685100595527199],
            [38.2016064275508, 14.6853569417201],
            [38.202156645662299, 14.6857360293952],
            [38.203125703274999, 14.686211788928301],
            [38.204328322861699, 14.686619116473],
            [38.205640986070598, 14.6868493919758],
            [38.206723455987998, 14.687204580601099],
            [38.207763255929301, 14.686638668176],
            [38.208525701026701, 14.686488771741301],
            [38.208938926057399, 14.686471392437801],
            [38.209655332496503, 14.6866115130324],
            [38.211043229610503, 14.687185028948701],
            [38.211706860026602, 14.687443545100701],
            [38.212394071219002, 14.686951495187101],
            [38.213278911773799, 14.685585046130599],
            [38.213601182382, 14.684877921683499],
            [38.213408044595901, 14.6832714046469],
            [38.213849341979198, 14.682559927813401],
            [38.214808293544998, 14.681536700583701],
            [38.215447220290898, 14.681277091215099],
            [38.215973857626203, 14.6811380534349],
            [38.216962004438699, 14.682016814168801],
            [38.217750276100503, 14.682442615380401],
            [38.219433494364097, 14.683002021324199],
            [38.220658571832899, 14.683869912373501],
            [38.223017772347802, 14.683706979397799],
            [38.223963249184301, 14.6836646168043],
            [38.225515088837597, 14.6839416028443],
            [38.2257194555648, 14.684509694683401],
            [38.225737421870399, 14.684911594328099],
            [38.225673416906403, 14.686068409452],
            [38.2259945646205, 14.686629978530499],
            [38.2264650572506, 14.6865517717046],
            [38.227114090043401, 14.686523530343999],
            [38.2281729791845, 14.6863605993459],
            [38.229173477832198, 14.686257409651001],
            [38.230175099374001, 14.6861553061155],
            [38.2319829588833, 14.685554632223001],
            [38.232506227536298, 14.6853591141442],
            [38.234007536954898, 14.6845411949525],
            [38.235111341860197, 14.6840882423705],
            [38.2358176422524, 14.6839991724478],
            [38.237286387741896, 14.6837602041832],
            [38.238702357208503, 14.6823698381912],
            [38.238804540572097, 14.682018986626099],
            [38.239473785458699, 14.6811239324054],
            [38.239959998606302, 14.680121336980701],
            [38.240207035309403, 14.6790731147478],
            [38.240228370297402, 14.678263862023],
            [38.240260934226399, 14.677685977707901],
            [38.240913335701499, 14.6777142202099],
            [38.241327683626302, 14.677695753959],
            [38.241870041479103, 14.6779021413805],
            [38.242291126768599, 14.6780553024463],
            [38.243311837510099, 14.6783561930221],
            [38.243730677011399, 14.678453955214],
            [38.244508842626203, 14.678649479466699],
            [38.2452286177476, 14.678847176033299],
            [38.2459259349869, 14.679854122256501],
            [38.246707469284097, 14.678838486078099],
            [38.2466030401323, 14.677863036410301],
            [38.245741780353697, 14.6771515419826],
            [38.245068043890598, 14.6766627276759],
            [38.244576216272499, 14.676281451758401],
            [38.244977089468101, 14.674705286997099],
            [38.2459225663046, 14.6746640089252],
            [38.2474822662167, 14.6751126359732],
            [38.248150388209197, 14.675485223839299],
            [38.249055440858001, 14.6758491209968],
            [38.249528179276197, 14.6758273958103],
            [38.2502344796684, 14.675738322523401],
            [38.250767854368299, 14.675771996575101],
            [38.2532168864117, 14.674970335483801],
            [38.254197172965497, 14.6744076501991],
            [38.254530672514697, 14.6739307787188],
            [38.255427864904703, 14.6715833392663],
            [38.255444708316297, 14.670659998939501],
            [38.256338532024003, 14.6695237418497],
            [38.256776460725, 14.6687535604106],
            [38.256932543005597, 14.6682853669843],
            [38.258586566022501, 14.6682114987885],
            [38.2592468277563, 14.6684124636747],
            [38.2601676009225, 14.667851934126199],
            [38.260817756609399, 14.6678226040522],
            [38.261626240365104, 14.667382652470099],
            [38.262351629957102, 14.666426705244],
            [38.2628647925631, 14.665999786554799],
            [38.2628165081166, 14.664963446121],
            [38.262081012477701, 14.663150381719401],
            [38.261598168012497, 14.6616729784164],
            [38.260943520749201, 14.6603183211874],
            [38.2614937388607, 14.6594286099161],
            [38.262367350474499, 14.6578327695337],
            [38.262394299933099, 14.655869728692499],
            [38.262870407033603, 14.6546367084342],
            [38.2633083357346, 14.6538675610055],
            [38.264408771957697, 14.6520880824534],
            [38.265131915761401, 14.6510744902252],
            [38.265000537151103, 14.649523130984599],
            [38.263693488412699, 14.648139065923401],
            [38.263726052341802, 14.6462921837068],
            [38.2644593021924, 14.645509970077701],
            [38.264623244731801, 14.6452144664249],
            [38.264501972168397, 14.643892299983101],
            [38.2643009741236, 14.643381683036001],
            [38.264283007817902, 14.642979707156],
            [38.263439714344898, 14.6414022163127],
            [38.262862546774898, 14.6404472421686],
            [38.262908585433202, 14.6388882026343],
            [38.264270655982699, 14.638249373478301],
            [38.264705216001403, 14.6380570725007],
            [38.265000537151103, 14.638045121586901],
            [38.265811266695003, 14.6376616055536],
            [38.266861172683299, 14.6373258926682],
            [38.267907709989302, 14.636932597307601],
            [38.268439961795202, 14.6369097818092],
            [38.269397790466897, 14.635885254842099],
            [38.269559487217997, 14.635532155892299],
            [38.269553872747501, 14.6341480025291],
            [38.269498850936301, 14.632939848971899],
            [38.269155245340201, 14.6319163899452],
            [38.268800410802903, 14.630663678292001],
            [38.267184566185598, 14.629005698536099],
            [38.266758989319797, 14.6287362484806],
            [38.266195296478998, 14.628069140871601],
            [38.2657921774952, 14.6270489186366],
            [38.265336282488498, 14.6261471208072],
            [38.264212265489299, 14.624870472968199],
            [38.263932664857101, 14.6239599740597],
            [38.263893363563398, 14.6230961917725],
            [38.264841086188198, 14.621843429789401],
            [38.265555246839, 14.6206569391712],
            [38.265818004059597, 14.6199528647851],
            [38.2659639802933, 14.619255307380699],
            [38.265933662152499, 14.6186218537998],
            [38.2656652904613, 14.617940590362799],
            [38.264707461789698, 14.6164270262347],
            [38.263996669821097, 14.6151329369346],
            [38.263669907636498, 14.614454922369401],
            [38.263569970061099, 14.613594362408501],
            [38.264714199154298, 14.612734885649401],
            [38.266235720666799, 14.612378489749499],
            [38.268443330477503, 14.6114722853765],
            [38.269607771664496, 14.6110735108055],
            [38.270482506172399, 14.6108040387511],
            [38.271831101992703, 14.610511748125001],
            [38.272566597631602, 14.6097859094988],
            [38.273736653289198, 14.6095023102227],
            [38.275624238279903, 14.608091914518599],
            [38.276037463310601, 14.608072355808901],
            [38.276522553564, 14.6083396580247],
            [38.277437712259697, 14.6089318508005],
            [38.278599907658602, 14.6097446192055],
            [38.279093981064797, 14.610184686406001],
            [38.279966469784497, 14.6111256666488],
            [38.280623362836003, 14.611269095154199],
            [38.281509326284997, 14.611229978298301],
            [38.283300342382702, 14.6102835655817],
            [38.284121177973603, 14.610131443754501],
            [38.284891483329702, 14.6101542620353],
            [38.285821239648797, 14.6097663509398],
            [38.286808263567202, 14.609375179394],
            [38.286958731377297, 14.608791680544501],
            [38.286918307189502, 14.607927838621899],
            [38.286183934444701, 14.606115389442699],
            [38.286382686701401, 14.6052993477941],
            [38.287141763116402, 14.6050928920367],
            [38.288774451145301, 14.6045571927728],
            [38.289718805087801, 14.604514814884901],
            [38.290531780419897, 14.6041899174727],
            [38.291060663543398, 14.6041073347763],
            [38.292046564567698, 14.603659649093199],
            [38.292094849014298, 14.6034271129661],
            [38.292786551783003, 14.6017797939224],
            [38.293254798624901, 14.6003747818484],
            [38.293469271398898, 14.599904269225201],
            [38.292600151361597, 14.596482427749899],
            [38.291555859843797, 14.5956641770478],
            [38.291534524855798, 14.5952034342776],
            [38.293604018691603, 14.5938994400761],
            [38.294312564871902, 14.593866840122001],
            [38.295071641287002, 14.593659286968],
            [38.296744753503702, 14.5927193186628],
            [38.297525164906801, 14.5917032790432],
            [38.298076505912398, 14.5908132896831],
            [38.298696343458403, 14.590151500390199],
            [38.300005637985002, 14.5890528605697],
            [38.301042069244097, 14.5884301869017],
            [38.302378313229198, 14.587908574096399],
            [38.303264276678199, 14.587868366391399],
            [38.304032336246102, 14.5878335921541],
            [38.304735267955898, 14.5876858015842],
            [38.306337637844003, 14.5865176005737],
            [38.307660407099803, 14.5857080064697],
            [38.308559845278097, 14.5846865009366],
            [38.309038198166803, 14.5835117637154],
            [38.309007880026002, 14.581610001923901],
            [38.308962964261802, 14.5806308598778],
            [38.308903450874197, 14.579364815152701],
            [38.309585047596102, 14.5787562417083],
            [38.310731522477397, 14.577955313023599],
            [38.312113805120902, 14.57714351395],
            [38.313224347390801, 14.5755927194531],
            [38.3151141781698, 14.574238618065401],
            [38.315374689602201, 14.5734767965325],
            [38.3153960245902, 14.572668241268801],
            [38.315181551816103, 14.5706022821561],
            [38.313922787524199, 14.5689862343607],
            [38.313160342426897, 14.567866839904401],
            [38.3126505485031, 14.5670821736059],
            [38.312129525638298, 14.5660671025339],
            [38.312119419591397, 14.5632989995374],
            [38.312094715921098, 14.561511179959901],
            [38.312071135144897, 14.560993850740401],
            [38.312564085657002, 14.5601645999017],
            [38.313405133341803, 14.5591451496446],
            [38.314714427868402, 14.5580485289167],
            [38.315573441858803, 14.5574333755571],
            [38.315793529103402, 14.557076890139699],
            [38.315274752026802, 14.5561193761521],
            [38.314746991797399, 14.554931444397001],
            [38.314567328740601, 14.5536130826387],
            [38.314507815352997, 14.5523468827691],
            [38.314776187044203, 14.5517588853382],
            [38.315264645979902, 14.5508133039298],
            [38.315546492400301, 14.5492427603408],
            [38.315784545950599, 14.548021097947901],
            [38.318060652301703, 14.546072293323499],
            [38.318985917044401, 14.5456266635252],
            [38.319804506846999, 14.5454158042341],
            [38.320639940061199, 14.544283247276899],
            [38.321350732029799, 14.5430398191302],
            [38.3219514803761, 14.541973552180799],
            [38.322928398247498, 14.540083388435599],
            [38.324152352822203, 14.5384127702121],
            [38.325434697890202, 14.538008428704201],
            [38.3261398753883, 14.536649748861899],
            [38.3268888457564, 14.5349432351438],
            [38.327508683302398, 14.533011706584601],
            [38.328230704212103, 14.531998652780899],
            [38.329781420971301, 14.5310051598847],
            [38.330894209029502, 14.530782329902101],
            [38.332019348922799, 14.5307888517584],
            [38.333139997239797, 14.5307377638786],
            [38.334132635628698, 14.530462758705699],
            [38.335404874649903, 14.529827962850201],
            [38.336370563580303, 14.5289757682878],
            [38.337685472577398, 14.5279942186943],
            [38.338307555911697, 14.527388764557401],
            [38.339821217165401, 14.5255897842257],
            [38.341066506727998, 14.524378861126999],
            [38.341487592017501, 14.5232646755787],
            [38.341530261993498, 14.5216482812984],
            [38.341296700019598, 14.520447119862499],
            [38.340808241083799, 14.518853532724],
            [38.340563450168901, 14.517422990427299],
            [38.339594392556201, 14.515679368617199],
            [38.339586532297403, 14.513868335408899],
            [38.340176051702599, 14.5116713778734],
            [38.341069875410298, 14.509265678926599],
            [38.342252282903097, 14.508001399186499],
            [38.343393143313897, 14.507084982102],
            [38.3442779838688, 14.507043672583],
            [38.344930385343801, 14.5070730240841],
            [38.346121775989403, 14.507249133009299],
            [38.347025705744102, 14.5076122213388],
            [38.3477825363709, 14.5086156030574],
            [38.348475362033803, 14.509507010954099],
            [38.349402872564703, 14.5103875444991],
            [38.350273115496201, 14.5112713357577],
            [38.3519844061124, 14.5111941536359],
            [38.353247661980703, 14.510386457422801],
            [38.355061135960497, 14.5086884377397],
            [38.355976294656202, 14.5067436347927],
            [38.356652276907496, 14.5047520693],
            [38.356782532623697, 14.502497404218801],
            [38.356522021191303, 14.5007210511497],
            [38.355875234186698, 14.499538257363101],
            [38.355477729673503, 14.498633763736301],
            [38.3557674363526, 14.4972357050247],
            [38.3562828447469, 14.496866076830299],
            [38.358985650858003, 14.4945961113413],
            [38.359352837230396, 14.4945254463337],
            [38.359493198993498, 14.4941688594899],
            [38.3596706162621, 14.4937872674454],
            [38.359928881906299, 14.493221944690401],
            [38.3602926995964, 14.492276113010901],
            [38.360491451853001, 14.4917107864018],
            [38.360680098062701, 14.4913183184273],
            [38.360831688766901, 14.4906953692184],
            [38.361065250740701, 14.489947392986499],
            [38.361264002997302, 14.489232029636399],
            [38.361695194333699, 14.487618648501901],
            [38.362000621530299, 14.4867304141203],
            [38.3629348694258, 14.4859193671344],
            [38.364722516841198, 14.485000684325099],
            [38.365492822197297, 14.4846853958566],
            [38.366132871837301, 14.484601681256899],
            [38.366988517145401, 14.4847930288669],
            [38.367427568740503, 14.4848354297354],
            [38.368187768049701, 14.4848789177973],
            [38.368746969314103, 14.485265961172001],
            [38.369174791968099, 14.4854018610732],
            [38.370016962546998, 14.485109404382699],
            [38.370323512637697, 14.4846593029978],
            [38.370595253011103, 14.484369019736],
            [38.371078097476399, 14.483688429026],
            [38.371290324462201, 14.483295946852399],
            [38.371537361165402, 14.482823010346801],
            [38.371690074763698, 14.482406607945],
            [38.371865246244099, 14.481795593084],
            [38.372016836948298, 14.4812074081494],
            [38.372215589204899, 14.480711635533099],
            [38.372416587249703, 14.480296316392099],
            [38.372792756774899, 14.4795928814137],
            [38.373148714206202, 14.4795559156808],
            [38.373587765801403, 14.479472199145301],
            [38.374692693600799, 14.479697255214401],
            [38.375523635238601, 14.4798875198291],
            [38.375940228951599, 14.4799897190404],
            [38.377401114182398, 14.4803735092755],
            [38.378483584099797, 14.480861672099699],
            [38.379528998511702, 14.4811323899957],
            [38.380669858922502, 14.4814248519299],
            [38.381157194964203, 14.481445509077901],
            [38.381370544844202, 14.481444421859599],
            [38.381951081096503, 14.481245460826401],
            [38.3825675499602, 14.4809540858764],
            [38.382850519274697, 14.4807235949733],
            [38.383133488589202, 14.4801582389138],
            [38.383392877127498, 14.4799831956879],
            [38.383760063499899, 14.4796820340382],
            [38.384706663230602, 14.4790666627537],
            [38.3851681727078, 14.478880746358501],
            [38.386472975658002, 14.478733970147101],
            [38.3874465248471, 14.478729621220801],
            [38.3885873852579, 14.4791025413383],
            [38.389111776805102, 14.479456977953699],
            [38.389766424068398, 14.479889694280899],
            [38.390469355778201, 14.480473533816999],
            [38.390766922715997, 14.4807018505361],
            [38.391196991158303, 14.481217193124101],
            [38.392859997328003, 14.4815651030552],
            [38.393585386920002, 14.4817347089487],
            [38.394500545615699, 14.482096751864599],
            [38.394832922270801, 14.482048914396101],
            [38.395435916405297, 14.4815509692248],
            [38.395919883764599, 14.480995400258299],
            [38.3963555666774, 14.480245216862899],
            [38.397086570739802, 14.479251491764099],
            [38.397558186264, 14.4785806704447],
            [38.397828803743302, 14.477969645046899],
            [38.398074717552397, 14.477345571106699],
            [38.398354318184502, 14.476285511175201],
            [38.398609215146401, 14.4748112040217],
            [38.398713644298198, 14.474315417114701],
            [38.398756314274202, 14.473417344152001],
            [38.398763051638802, 14.4723126876795],
            [38.398866357896502, 14.471482017183501],
            [38.399075216200004, 14.470423016520501],
            [38.398975278624697, 14.4694096764546],
            [38.398758560062397, 14.4687551345974],
            [38.398768666109298, 14.468386546224],
            [38.398660868275201, 14.465647665371501],
            [38.3990493896356, 14.4649104776976],
            [38.399239158739398, 14.4648278429386],
            [38.4005439616896, 14.4647060653429],
            [38.401542214549103, 14.465022469493],
            [38.402504534797202, 14.4652703734606],
            [38.403441028480898, 14.4650800963172],
            [38.403997983956998, 14.464777827148399],
            [38.404469599481203, 14.4642809305347],
            [38.405619443044898, 14.4638710171741],
            [38.406877084442598, 14.4636916118844],
            [38.407542960647, 14.4639753983669],
            [38.408598481105798, 14.463969961847599],
            [38.408907276984699, 14.4638405726503],
            [38.409522622954299, 14.463515468368101],
            [38.410221063087803, 14.4632240701455],
            [38.411145204936297, 14.4628272025485],
            [38.411808835352403, 14.462754352803],
            [38.412070469678902, 14.4627184715763],
            [38.412320875064403, 14.463005521227799],
            [38.413036158609401, 14.4636100639775],
            [38.414832789177602, 14.464613644801601],
            [38.415652501874398, 14.4648735095197],
            [38.416650754733801, 14.465132286635599],
            [38.417423305878202, 14.465369317510801],
            [38.418704528052203, 14.4652018737077],
            [38.419510766019698, 14.4650474773619],
            [38.420789742405397, 14.464475557568299],
            [38.421463478868503, 14.463839485345799],
            [38.421896915993102, 14.4626162644138],
            [38.4221866226722, 14.461245161656301],
            [38.422022680132898, 14.4591857733362],
            [38.421909267828298, 14.4577363613479],
            [38.422125986390597, 14.455927029590599],
            [38.422511139068597, 14.4545787199049],
            [38.423215193672497, 14.4529857467124],
            [38.424029291898798, 14.451991899634301],
            [38.424689553632597, 14.451125270054799],
            [38.426042641029298, 14.4486547591624],
            [38.427597849364901, 14.443728878679501],
            [38.427806707668502, 14.442716504298099],
            [38.427932471808298, 14.4417476218164],
            [38.428152559052897, 14.4408265814711],
            [38.428372646297497, 14.4396967522748],
            [38.42857027566, 14.438763741741701],
            [38.428801591845698, 14.4373805301164],
            [38.428868965492001, 14.4367585170549],
            [38.428891423374097, 14.436424673663399],
            [38.428911635467998, 14.435746110959499],
            [38.428932970456003, 14.435307871447099],
            [38.428990238055299, 14.434754361747901],
            [38.429104773254103, 14.433913763835699],
            [38.429278821840299, 14.433107961304801],
            [38.429511260920101, 14.432035728313201],
            [38.429732471058799, 14.4311701084594],
            [38.429855989410399, 14.4297661876971],
            [38.429906519645101, 14.4295541307319],
            [38.4303949785809, 14.429228976326399],
            [38.431252869677202, 14.428894034168099],
            [38.431906394046401, 14.428328546264],
            [38.432591359450498, 14.428341596001101],
            [38.433842263483697, 14.4281251877629],
            [38.435233529279998, 14.427930528916701],
            [38.436166654281301, 14.4278228683644],
            [38.436755050792399, 14.427628209253999],
            [38.437423172785003, 14.427295439654401],
            [38.438058730848503, 14.427111654923801],
            [38.438683059970998, 14.426963757042399],
            [38.439610570501799, 14.4265907494493],
            [38.440114749955001, 14.426329752511201],
            [38.440641387290398, 14.4260328681223],
            [38.441319615329903, 14.425594609491601],
            [38.4417609127132, 14.425080225433],
            [38.4420663399098, 14.424292878400101],
            [38.442480687834603, 14.4236164537267],
            [38.442715372702601, 14.422817138965099],
            [38.442823170536698, 14.4221929102046],
            [38.442834399477697, 14.4214664536262],
            [38.442799589760497, 14.4208563587716],
            [38.442741199266997, 14.4201994989858],
            [38.442498654140302, 14.4191772299046],
            [38.4424615986348, 14.4183822493245],
            [38.442532340963403, 14.417677530925999],
            [38.4424514925879, 14.4170913514247],
            [38.442517743340098, 14.416145195730399],
            [38.442599714609699, 14.415405668942901],
            [38.442877069453701, 14.4143746775036],
            [38.443056732510499, 14.413795014610701],
            [38.443447499659101, 14.413120733176701],
            [38.443967399629798, 14.4124323114797],
            [38.4444064512249, 14.411860256446101],
            [38.445082433476202, 14.4112849372609],
            [38.445832526738499, 14.4108346864283],
            [38.446511877672101, 14.410477965316399],
            [38.447451740038098, 14.410126681468601],
            [38.448119862030701, 14.409805848987901],
            [38.448777877976298, 14.409541569682499],
            [38.449553797802899, 14.409251188356199],
            [38.450307259747497, 14.409102191049399],
            [38.4511236037619, 14.4091000159055],
            [38.4519758803877, 14.409120679771799],
            [38.452897776448097, 14.4091174170562],
            [38.453902766672201, 14.409111979196799],
            [38.454519235535898, 14.409159832355099],
            [38.455856602415103, 14.4092533634986],
            [38.456625784877197, 14.409288165774599],
            [38.456958161532299, 14.409375171440599],
            [38.457518485690798, 14.4095731092045],
            [38.458161904012997, 14.409862402543499],
            [38.459006320380098, 14.4101712717091],
            [38.459873194629303, 14.4103648585032],
            [38.4606547289264, 14.4104442507915],
            [38.461651858891798, 14.410601947718799],
            [38.4624345160831, 14.410728105180301],
            [38.463086917558201, 14.410878188963901],
            [38.463634889881497, 14.4110652497702],
            [38.463993093101003, 14.4113132138529],
            [38.464863336032501, 14.4116905972566],
            [38.465841376798103, 14.4121821735277],
            [38.466484795120401, 14.4124714634816],
            [38.466997957726399, 14.412739001977],
            [38.467700889436202, 14.413028291208301],
            [38.468318481194103, 14.4132022995877],
            [38.469363895606001, 14.413439385786001],
            [38.469871443741503, 14.413373045177901],
            [38.470600202015703, 14.4131435717748],
            [38.471126839351101, 14.412810780542999],
            [38.471714112968101, 14.4125606430157],
            [38.472266576867803, 14.412400772362099],
            [38.472730332133203, 14.412508440365899],
            [38.473182858457598, 14.412720513554699],
            [38.473648859511201, 14.413023940997],
            [38.473995833789701, 14.413238188798999],
            [38.474259713904402, 14.4134404733368],
            [38.474456220372801, 14.4138765807749],
            [38.474784105451498, 14.4150348170861],
            [38.4754219093033, 14.415657978357901],
            [38.475903630874399, 14.416181084467899],
            [38.4766211602076, 14.4166302369674],
            [38.477345426905401, 14.4167694414324],
            [38.4781258383085, 14.4167672663633],
            [38.478836630277002, 14.4168607943153],
            [38.479277927660299, 14.417082651160699],
            [38.479517104104701, 14.417195754565601],
            [38.479877553112502, 14.4175111387567],
            [38.480312113131198, 14.418023365233299],
            [38.4805793619282, 14.4184116125427],
            [38.480642243998098, 14.4186519557762],
            [38.4808196612667, 14.4193523210057],
            [38.480994832747101, 14.4199145691389],
            [38.481368756484102, 14.4203006381187],
            [38.4818302659613, 14.4203278260498],
            [38.4825826050118, 14.4200733468848],
            [38.482908244302301, 14.419756878799801],
            [38.483196828087301, 14.4193838591396],
            [38.483483166084099, 14.4188368350103],
            [38.483827894574397, 14.418208244988801],
            [38.484043490242598, 14.417709069297],
            [38.4843174764043, 14.417174003915299],
            [38.4845375636489, 14.416859706781199],
            [38.484962017620603, 14.4167716165014],
            [38.4859187233982, 14.4167444281365],
            [38.486910238893003, 14.4166356746434],
            [38.487854592835497, 14.416528008633],
            [38.488467693016901, 14.416414904889301],
            [38.490260954902801, 14.415510072873399],
            [38.491127829151999, 14.4150000157072],
            [38.4914882781597, 14.4146813653287],
            [38.491965508154401, 14.4142017575863],
            [38.492508988901299, 14.4135133392274],
            [38.493098508306502, 14.4127531401812],
            [38.493584721453999, 14.412111482316501],
            [38.494223648199899, 14.4114904862518],
            [38.495196074494899, 14.410966281572399],
            [38.4961988189308, 14.410811847593701],
            [38.496647976572902, 14.4107813958106],
            [38.497402561411597, 14.410654150814899],
            [38.497747289901803, 14.4107868336294],
            [38.498342423777601, 14.411007608957],
            [38.4987859669491, 14.4113001631186],
            [38.499372117672003, 14.4116829843412],
            [38.499777482443903, 14.4119178970388],
            [38.500243483497599, 14.4121995744378],
            [38.5008060534443, 14.4125475923544],
            [38.501452840448799, 14.413020678338601],
            [38.502119839547298, 14.413320842718299],
            [38.502914848573702, 14.413446998641399],
            [38.504014161902703, 14.4134285102778],
            [38.504710356247898, 14.413348031500901],
            [38.505241485159601, 14.413292566380999],
            [38.506034248397803, 14.4132914788295],
            [38.506803430859897, 14.413336068436999],
            [38.507380598429897, 14.413165322818401],
            [38.507907235765202, 14.412834706726599],
            [38.5086539603452, 14.4122691780646],
            [38.509277166573497, 14.411416532139301],
            [38.509879037813903, 14.410622611445801],
            [38.510314720726697, 14.4098656652481],
            [38.5107739844157, 14.409063038456001],
            [38.511174857611202, 14.408330013631399],
            [38.511387084597096, 14.407645927460001],
            [38.512364002468601, 14.4067084323512],
            [38.512534682372497, 14.4063364784435],
            [38.512881656650997, 14.4058579403401],
            [38.513229753823602, 14.4054946857306],
            [38.513520583396897, 14.405212999862799],
            [38.514147158307502, 14.4045463056387],
            [38.5147321861363, 14.404154771397399],
            [38.515107232767399, 14.403976405570701],
            [38.516124574826698, 14.403615323094799],
            [38.516797188395699, 14.403529402781],
            [38.517505734575998, 14.40348916161],
            [38.518169364992197, 14.403945952854301],
            [38.51857360687, 14.4043940424043],
            [38.518802677267502, 14.4051988611805],
            [38.518948653501198, 14.405808998885201],
            [38.518976725853797, 14.4064789520896],
            [38.519051959758798, 14.407204369929801],
            [38.519368615896497, 14.408562754712101],
            [38.5199783473956, 14.4098156371043],
            [38.520822763762702, 14.4106976533857],
            [38.5216211414714, 14.412007076794399],
            [38.522881028657402, 14.4131859863079],
            [38.5241600050432, 14.413879843420901],
            [38.525095375832699, 14.4141963198509],
            [38.526077908174798, 14.4146280756305],
            [38.526869548518903, 14.414737917647599],
            [38.527802673520299, 14.4148803859257],
            [38.528643721205, 14.415232749825501],
            [38.529248961127699, 14.4157819578796],
            [38.530125941423798, 14.4162137105879],
            [38.531084892989597, 14.4167487782751],
            [38.531774349970199, 14.4173436589315],
            [38.532579465043597, 14.4177993342653],
            [38.533467674280701, 14.418208244988801],
            [38.534876906382699, 14.418867285692899],
            [38.5363815844836, 14.419732953359301],
            [38.537636980093197, 14.420277800254],
            [38.538324191285497, 14.4206758312751],
            [38.539424627508602, 14.4210945243415],
            [38.540998925044001, 14.4217187561794],
            [38.542241968818402, 14.422239673046899],
            [38.543258187983497, 14.422462612044001],
            [38.544286758983901, 14.4226746757609],
            [38.544985199117299, 14.422842151579401],
            [38.5459194470127, 14.4231477402483],
            [38.547076027941003, 14.4229780896511],
            [38.5477576246629, 14.4225572251119],
            [38.548321317503699, 14.422114609609499],
            [38.548991685284399, 14.4217644316111],
            [38.549581204689602, 14.4217133186274],
            [38.550562614137498, 14.4219253830576],
            [38.551288003729503, 14.422727963534999],
            [38.552370473646803, 14.423838303845899],
            [38.552752257642602, 14.4244473030375],
            [38.553015014863199, 14.4250225882446],
            [38.5535506353513, 14.4255489348452],
            [38.554417509600498, 14.426326490047501],
            [38.555257434391201, 14.426574437149601],
            [38.556049074735299, 14.4266723109297],
            [38.556744146186396, 14.426505925477899],
            [38.557521188907202, 14.4262003414159],
            [38.558665418000302, 14.425973056201199],
            [38.559644581660002, 14.425941518999901],
            [38.560921312257499, 14.4261633668035],
            [38.5623496335593, 14.426061142843],
            [38.563599414698302, 14.425994805992501],
            [38.565076020446597, 14.4260992049614],
            [38.566082133564798, 14.426356939706499],
            [38.567089369577097, 14.426880020698301],
            [38.567271278422197, 14.4276978080616],
            [38.567316194186397, 14.429047368140701],
            [38.567124179294403, 14.4303512463795],
            [38.5675104548666, 14.4315822570256],
            [38.568208895, 14.4319465565742],
            [38.569996542415403, 14.4326142562889],
            [38.571260921177803, 14.432881770596399],
            [38.572786934266702, 14.433215619301601],
            [38.574190551898099, 14.4330796874788],
            [38.575628979246801, 14.432849146917601],
            [38.576608142906501, 14.432612081374501],
            [38.577373956686202, 14.4325022481703],
            [38.578073519713698, 14.4329589799506],
            [38.5786563017543, 14.433450509295801],
            [38.579947629975202, 14.4340975429518],
            [38.581332158406802, 14.4346532291787],
            [38.582786306273, 14.4348848553177],
            [38.583921552213297, 14.4351306179997],
            [38.585365594032503, 14.435661290476],
            [38.586536772584203, 14.4360103591815],
            [38.587755112688299, 14.436382263555],
            [38.588915062298902, 14.4368574737946],
            [38.589767338924702, 14.437196753711],
            [38.590987924817, 14.4378111535251],
            [38.592184929933097, 14.438528857354701],
            [38.593215746721597, 14.4390062378626],
            [38.594347623979601, 14.438835511921599],
            [38.595042695430699, 14.4385419064938],
            [38.595690605329402, 14.438387491632],
            [38.596246437911397, 14.438521245356601],
            [38.597098714537204, 14.438896407813701],
            [38.598224977324698, 14.4395684363729],
            [38.599492724769398, 14.4401817422187],
            [38.600424726876703, 14.439954471278],
            [38.601444314724198, 14.4388746592827],
            [38.602303328714598, 14.4384299013582],
            [38.603189292163599, 14.4386887092561],
            [38.603808006815498, 14.439318328893799],
            [38.605322790963399, 14.439734812061999],
            [38.606702827818602, 14.439666304440401],
            [38.607797649571097, 14.4392084989989],
            [38.6090811175333, 14.4386104144616],
            [38.610018734111101, 14.437668699913999],
            [38.610851921537098, 14.4368900968859],
            [38.611777186279802, 14.435879866271501],
            [38.612503698765799, 14.4350784206386],
            [38.612900080384897, 14.4343715801686],
            [38.6136614025882, 14.433432022592299],
            [38.6142385701582, 14.433209094576],
            [38.614779805116903, 14.4329046071687],
            [38.615179555418401, 14.432729526720999],
            [38.616311432676298, 14.4325935946014],
            [38.617032330691899, 14.4325413966454],
            [38.6178599036474, 14.432812173409101],
            [38.618357345735902, 14.433062288199499],
            [38.618797520225201, 14.433462471279601],
            [38.619191656056103, 14.434094280602],
            [38.619444307229699, 14.4348881176559],
            [38.620097831598898, 14.435413353492899],
            [38.620749110179901, 14.4357319742145],
            [38.6220033828954, 14.4360451572778],
            [38.6230207249546, 14.436325716731201],
            [38.624002134402502, 14.436583439638399],
            [38.625291216835201, 14.436862910976799],
            [38.625884104922697, 14.437226114448601],
            [38.626502819574704, 14.437903585208799],
            [38.627121534226603, 14.438601715038301],
            [38.627695333114403, 14.439623894949699],
            [38.628232076496602, 14.4404960064386],
            [38.628884477971702, 14.440813532465899],
            [38.630078114405499, 14.4410353654491],
            [38.631140372228899, 14.4409472847322],
            [38.632601257459797, 14.440601486025299],
            [38.633993646150103, 14.4403492048686],
            [38.6355960160382, 14.4399098869992],
            [38.636749228284202, 14.4394390329731],
            [38.637693582226603, 14.439306367224701],
            [38.638567193840402, 14.439333552835301],
            [38.639289214750001, 14.439570611219301],
            [38.639915789660698, 14.439623894949699],
            [38.640635564782102, 14.4395945345284],
            [38.641423836443899, 14.4393346402597],
            [38.642036936625303, 14.439192187623799],
            [38.643122775225002, 14.4391486906175],
            [38.644033442344302, 14.4393607384426],
            [38.6445084265508, 14.439738074329201],
            [38.645398881576099, 14.440389439382599],
            [38.646228700319803, 14.4409124874024],
            [38.646763197913899, 14.441265897530499],
            [38.647404370447902, 14.441849838573599],
            [38.648435187236501, 14.4423152504489],
            [38.6496041199999, 14.442432690674799],
            [38.651034687089897, 14.442663221308401],
            [38.651570307577998, 14.443304791153601],
            [38.652153089618601, 14.4438310945264],
            [38.652437181827203, 14.4439800686021],
            [38.652763944011802, 14.4450381078895],
            [38.652944729962698, 14.445555708009801],
            [38.653633064049203, 14.446126589098499],
            [38.655036681680599, 14.445862352891099],
            [38.656027074281397, 14.445647049082501],
            [38.657016343987998, 14.445316481213201],
            [38.658230192515703, 14.444926106024299],
            [38.658948844743001, 14.4448064923196],
            [38.660022331507498, 14.4446140228597],
            [38.661109293001303, 14.444696665134],
            [38.6618908272985, 14.444956553138899],
            [38.662814969147, 14.445468716477199],
            [38.663525761115601, 14.445866702460799],
            [38.663897439064399, 14.4466126524134],
            [38.6641377384029, 14.447233550538799],
            [38.664217463884299, 14.4483524676525],
            [38.6644397969172, 14.449734523681901],
            [38.664718274655201, 14.4507131576894],
            [38.665112410486103, 14.4513209959766],
            [38.665942229229799, 14.451913609522499],
            [38.667281841897299, 14.452526881327],
            [38.6684878301662, 14.4527943716905],
            [38.669621953212399, 14.4528324292364],
            [38.670365309109997, 14.4528269924445],
            [38.671426444039398, 14.4526562771121],
            [38.672263000147701, 14.4523616025675],
            [38.673301677194999, 14.452330069106001],
            [38.674187640644, 14.4524627270862],
            [38.675229686373498, 14.452892233938201],
            [38.676272854997201, 14.453345661790401],
            [38.676382898619501, 14.453874116105601],
            [38.676144845069203, 14.455307243089599],
            [38.677353079126398, 14.455885710544299],
            [38.6781941268111, 14.4561216639422],
            [38.679127251812503, 14.456241271559801],
            [38.680169297542101, 14.456591395306599],
            [38.681376408705098, 14.456985012027999],
            [38.682264617942302, 14.457439518866501],
            [38.683131492191499, 14.4579744874473],
            [38.684553076128601, 14.458747580011201],
            [38.685976905853899, 14.4595902587153],
            [38.687210966475497, 14.460434021544801],
            [38.688560685189898, 14.460954847774399],
            [38.689683579295, 14.4611875338373],
            [38.691066984832602, 14.461419132340099],
            [38.692639036579799, 14.461683350055599],
            [38.694021319223197, 14.4617529382082],
            [38.6951329843873, 14.4619410435741],
            [38.696553445430297, 14.4625173191373],
            [38.6977369758172, 14.4628772187779],
            [38.6984140809626, 14.463287133972401],
            [38.699057499284798, 14.464112398608],
            [38.6995583100557, 14.4648354540361],
            [38.700166918660699, 14.4656715858745],
            [38.700869850370502, 14.4664968416505],
            [38.7015682905039, 14.466849123814599],
            [38.702263361954998, 14.4665783884974],
            [38.7031212530513, 14.4659716792388],
            [38.703614203563497, 14.4655421976684],
            [38.704129611957804, 14.465018120298099],
            [38.704587752752701, 14.464703890742401],
            [38.705541089847898, 14.4642233035031],
            [38.706237284193101, 14.4642744067205],
            [38.707242274417197, 14.464336382947099],
            [38.708480826615201, 14.4641537161244],
            [38.709375773216998, 14.4639275569929],
            [38.710495298639898, 14.4636655165575],
            [38.711412703123798, 14.463080545507299],
            [38.712481698311898, 14.4624270723082],
            [38.713706775780601, 14.4619432182017],
            [38.7144939245483, 14.461419132340099],
            [38.715328234868402, 14.460847203204899],
            [38.716339962457198, 14.460342686547801],
            [38.716976643414803, 14.4601763262782],
            [38.717815445311302, 14.460250264191201],
            [38.718808083700303, 14.4604492440406],
            [38.720087060086101, 14.4609581097302],
            [38.721046011651801, 14.4612734319017],
            [38.721871338819099, 14.461002689788501],
            [38.723112136805298, 14.4611886211548],
            [38.724441643425799, 14.4605547141618],
            [38.724878449232698, 14.4604709904615],
            [38.725689178776598, 14.4600371489636],
            [38.726173146135999, 14.4599305912731],
            [38.726643638765999, 14.4597533574502],
            [38.727009702244303, 14.4596924672699],
            [38.727845135458502, 14.459363007611801],
            [38.728244885759999, 14.4591759873905],
            [38.728681691566898, 14.4591031364477],
            [38.729317249630398, 14.458797597159],
            [38.7299281040236, 14.458365927056301],
            [38.730307642231097, 14.458250669909701],
            [38.731225046714997, 14.458572519905299],
            [38.7312957890436, 14.4585833932078],
            [38.7314979099826, 14.4587312700693],
            [38.732326605832199, 14.4590716039427],
            [38.732552307547301, 14.4591618521348],
            [38.733073330412097, 14.4594576049893],
            [38.733735837934098, 14.4595565516286],
            [38.7343635357389, 14.4596902926203],
            [38.735024920366897, 14.459707689816501],
            [38.735474078008899, 14.459762056046101],
            [38.7360063298147, 14.4599316785967],
            [38.736646379454697, 14.4601795882455],
            [38.737027040556299, 14.4605112213344],
            [38.737537957374201, 14.4609581097302],
            [38.738239766189899, 14.461506117630901],
            [38.739103271756797, 14.4616713895897],
            [38.740567525669903, 14.461625722350201],
            [38.7416544871637, 14.461628984296199],
            [38.742468585389901, 14.4615996267806],
            [38.743012066136799, 14.461549610263599],
            [38.7434275369557, 14.461799692735999],
            [38.743582496342199, 14.461983448808001],
            [38.7441181168304, 14.462497747538899],
            [38.744593101036799, 14.4628870045605],
            [38.7450905431254, 14.463125125139699],
            [38.745659850436702, 14.463408912346001],
            [38.7460629694205, 14.463543738324599],
            [38.746534584944598, 14.4635176429802],
            [38.746959038916401, 14.463353459702001],
            [38.7473475602768, 14.463164268224199],
            [38.7476776911437, 14.4630348785576],
            [38.748231277937499, 14.462904401506499],
            [38.7486433800741, 14.4627739243788],
            [38.749305887596101, 14.462826115239],
            [38.750026785611603, 14.4629130999789],
            [38.750511875865101, 14.463059886650299],
            [38.750607321864003, 14.463116426675599],
            [38.750630902640196, 14.463196887456],
            [38.7505983387112, 14.4634393569289],
            [38.750377128572502, 14.4638557949127],
            [38.750249118644497, 14.4641232716394],
            [38.750180622104097, 14.4645038274026],
            [38.7502682078443, 14.4650692233326],
            [38.750281682573501, 14.4653453969752],
            [38.750273822314803, 14.4658770846351],
            [38.7504433793247, 14.4664055091463],
            [38.750743192050699, 14.466888266242799],
            [38.751053110823797, 14.467266642692801],
            [38.751730215969197, 14.4677809291905],
            [38.752403952432203, 14.467960331176901],
            [38.752758786969501, 14.467887483115],
            [38.753027158660601, 14.467504758576601],
            [38.753671699877003, 14.4668643458708],
            [38.754492535467797, 14.4660162582909],
            [38.755080931978902, 14.4657466092536],
            [38.755634518772801, 14.465534586595099],
            [38.756539571421499, 14.4650648741386],
            [38.757234642872596, 14.464830017537899],
            [38.757741068114001, 14.464594073388],
            [38.758273319919901, 14.4645788511761],
            [38.758967268476802, 14.4643081130915],
            [38.7596275302107, 14.464186335210901],
            [38.759993593689003, 14.4640917398469],
            [38.760537074435803, 14.464122184336199],
            [38.760915489749301, 14.4641537161244],
            [38.761481428378303, 14.464034112766299],
            [38.761693655364198, 14.4640319381592],
            [38.761990099407903, 14.4641558907303],
            [38.762216924017103, 14.4644201051906],
            [38.762880554433302, 14.464657136825901],
            [38.763422912286103, 14.464548406749399],
            [38.764106754796103, 14.4643820496293],
            [38.764306629946802, 14.4643570416854],
            [38.765026405068198, 14.4641319700641],
            [38.765414926428598, 14.464083041420601],
            [38.765663086025903, 14.4640928271501],
            [38.766467078205103, 14.4641678510625],
            [38.7669050069062, 14.464267882906199],
            [38.767318231936798, 14.464264620999],
            [38.767720228026498, 14.4643189861133],
            [38.767970633411899, 14.464674533633101],
            [38.768102012022197, 14.464776739848499],
            [38.768375998183899, 14.4650866201076],
            [38.768663459074801, 14.4655911259905],
            [38.769105879352203, 14.4662924307559],
            [38.769228274809699, 14.4668099813795],
            [38.769279927938499, 14.4673405782457],
            [38.769531456218097, 14.4678809594068],
            [38.769817794214902, 14.468201708166299],
            [38.770658841899703, 14.468414815560401],
            [38.771142809258997, 14.4684343866374],
            [38.772026526919703, 14.468172351519399],
            [38.772660962089098, 14.467764619911801],
            [38.773167387330602, 14.467529766166001],
            [38.773578366572998, 14.467305785047399],
            [38.774025278426898, 14.4670491850417],
            [38.7744261516224, 14.466873044188199],
            [38.774920225028701, 14.4666033961912],
            [38.775344679000497, 14.4664848814437],
            [38.775851104241902, 14.466377239554101],
            [38.7763811102595, 14.466188050652001],
            [38.777361396813298, 14.4660880196731],
            [38.778176617933603, 14.4660934561405],
            [38.779131077922997, 14.4658770846351],
            [38.779956405090303, 14.465652014554101],
            [38.780901881926802, 14.4656900698978],
            [38.782092149678299, 14.465414983980599],
            [38.782671563036601, 14.465421507761199],
            [38.784037002268398, 14.464811533442999],
            [38.784178486925697, 14.4647636922492],
            [38.785217163972902, 14.464698454241001],
            [38.786027893516803, 14.4642298273188],
            [38.787017163223503, 14.4637720724565],
            [38.787721217827396, 14.4632360305279],
            [38.788891273485, 14.463202323994199],
            [38.789833381639198, 14.462906576124601],
            [38.7910865314605, 14.463024005472899],
            [38.792039868555797, 14.4626119151718],
            [38.792630510855098, 14.4625847324074],
            [38.793505245363001, 14.462739130465099],
            [38.794417035376398, 14.463007695844899],
            [38.795200815461797, 14.463440444235401],
            [38.796184470697902, 14.4638818902173],
            [38.797073802829203, 14.464383136931099],
            [38.798120340135199, 14.4651823023459],
            [38.798644731682302, 14.465765093270599],
            [38.7990748001246, 14.466431604151399],
            [38.799827139175001, 14.4675101950093],
            [38.800589584272402, 14.4682778179734],
            [38.801008423773602, 14.468966066901601],
            [38.802319964088497, 14.469012819911001],
            [38.803041984998103, 14.469133507866401],
            [38.803752776966597, 14.4694281601667],
            [38.804583718604398, 14.4698837288208],
            [38.805260823749798, 14.470489340118901],
            [38.806224266892102, 14.4713004435066],
            [38.806831752602903, 14.4718951782899],
            [38.807388708079102, 14.4720984967626],
            [38.808179225529102, 14.472034348173599],
            [38.8085924505598, 14.471950628800901],
            [38.808982094814297, 14.471958239654301],
            [38.8093964427391, 14.472082187801],
            [38.810221769906398, 14.4718679966618],
            [38.8106709275485, 14.4719560651248],
            [38.810931438980802, 14.471989770329801],
            [38.811167246742897, 14.471975635889599],
            [38.811887021864301, 14.4718658221314],
            [38.8126090427739, 14.472009341091599],
            [38.813107607756599, 14.472363789035199],
            [38.813547782245799, 14.4727410688932],
            [38.814377600989502, 14.473069421446599],
            [38.814900869642599, 14.4734445255904],
            [38.815483651683103, 14.4739598850325],
            [38.8161001205468, 14.474289323036301],
            [38.817097250512198, 14.4748807880603],
            [38.817300494345297, 14.475260238137301],
            [38.817231997804797, 14.475514654158101],
            [38.816578473435598, 14.4764431614343],
            [38.816133807370001, 14.477069412391799],
            [38.815595941093598, 14.4776510848752],
            [38.814880657548699, 14.4783371302743],
            [38.814541543528897, 14.4788590017407],
            [38.814334931013597, 14.479541781722499],
            [38.814056453275498, 14.4801441049938],
            [38.814003677252501, 14.4809758302889],
            [38.814361880472099, 14.4813998459059],
            [38.814623514798598, 14.481697743572701],
            [38.815335429661197, 14.482038042242699],
            [38.815727319703903, 14.482334851838599],
            [38.816215778639702, 14.4827925684222],
            [38.816856951173698, 14.483260068946],
            [38.817331935380203, 14.483520999042399],
            [38.817817025633602, 14.4836677720868],
            [38.818607543083601, 14.4835568769067],
            [38.819089264654799, 14.4831839642767],
            [38.819405920792398, 14.4829730454854],
            [38.819665309330702, 14.4827980044805],
            [38.820112221184502, 14.4825642538548],
            [38.820829750517703, 14.4821315427443],
            [38.821665183732001, 14.4818477794724],
            [38.822443349346798, 14.481598806800701],
            [38.823127191856898, 14.4814422474231],
            [38.823565120557902, 14.481532486522299],
            [38.824038981870302, 14.4817694998852],
            [38.824631869957798, 14.481949977780699],
            [38.825059692611802, 14.482304409847],
            [38.825980465778102, 14.4822391769939],
            [38.826536298360097, 14.482338113480299],
            [38.827353765268697, 14.482562079429201],
            [38.828514837773398, 14.483106772371199],
            [38.829604045055397, 14.4833513945146],
            [38.830872915394202, 14.483917829642399],
            [38.831785828301697, 14.484349450064199],
            [38.832542658928503, 14.4844114206856],
            [38.833193937509499, 14.4845571059377],
            [38.833864305290298, 14.484612553284601],
            [38.833940662089503, 14.484619076501],
            [38.834115833569903, 14.4846984422849],
            [38.834542533329802, 14.484799552078],
            [38.834743531374599, 14.484935452264899],
            [38.835134298523201, 14.484956109085999],
            [38.835774348163199, 14.4853083619501],
            [38.836023630654502, 14.485433389838301],
            [38.836415520697201, 14.485672573428101],
            [38.836868047021603, 14.486060702613401],
            [38.837321696240103, 14.486633653978799],
            [38.837809032281697, 14.4870804897848],
            [38.838239100724003, 14.487618648501901],
            [38.838669169166202, 14.488249216650001],
            [38.839028495279898, 14.488869998368299],
            [38.839292375394599, 14.489283127095099],
            [38.839936916611002, 14.490119166821099],
            [38.840255818536797, 14.490175699952699],
            [38.840973347869998, 14.4912411293533],
            [38.842150140892201, 14.4920739001971],
            [38.8428036652614, 14.4925533399557],
            [38.843980458283603, 14.493455684081299],
            [38.8454559411378, 14.4948048447707],
            [38.846466545832399, 14.4955114929424],
            [38.847533295232303, 14.4959289578804],
            [38.8479229394868, 14.496007232468701],
            [38.848513581786101, 14.4959441779414],
            [38.849445583893399, 14.495833288901499],
            [38.850391060729898, 14.4957908901364],
            [38.851239968673397, 14.4955995520182],
            [38.852597547646504, 14.4955299744797],
            [38.853339780650003, 14.495292975825301],
            [38.853987690548699, 14.4949755276893],
            [38.855057808630903, 14.494436299676501],
            [38.855421626320997, 14.494202561319799],
            [38.856002162573297, 14.494383029095999],
            [38.856793802917501, 14.494410207965201],
            [38.857159866395698, 14.4942808365179],
            [38.8575124551448, 14.494035139275599],
            [38.858231107372099, 14.493844886799099],
            [38.858538780356902, 14.4939459924212],
            [38.859097981621197, 14.4944710886201],
            [38.859925554576698, 14.4945461022613],
            [38.860620626027803, 14.4942634420318],
            [38.861339278255102, 14.4940492723102],
            [38.862248822480304, 14.4940416622147],
            [38.863042708612603, 14.4942775750518],
            [38.863798416345404, 14.494271052119601],
            [38.8645181914668, 14.494126460406299],
            [38.865284005246501, 14.493843799641599],
            [38.866357492010998, 14.493661157109599],
            [38.867572463432801, 14.4933861058697],
            [38.868173211779002, 14.493230641974501],
            [38.869224240661502, 14.493221944690401],
            [38.869946261571101, 14.493330660716801],
            [38.869995668911699, 14.4936763773264],
            [38.870024864158403, 14.4942656163426],
            [38.870113572792697, 14.495048366856601],
            [38.870381944483903, 14.4961192085678],
            [38.870611014881298, 14.4965790709836],
            [38.8713453876261, 14.496873686828801],
            [38.872128044817401, 14.4972237464751],
            [38.873206023158303, 14.497480311579],
            [38.874578199754801, 14.497780361916799],
            [38.8756640383545, 14.497702087954799],
            [38.8767465082719, 14.497069372413501],
            [38.877496601534098, 14.4963822978208],
            [38.8782242369142, 14.4958800362486],
            [38.878754242931898, 14.4956212949945],
            [38.879511073558703, 14.495753927103699],
            [38.8804711480186, 14.496069199831499],
            [38.881206643657499, 14.496396430705699],
            [38.881929787461203, 14.4967834539731],
            [38.882691109664499, 14.4973194148536],
            [38.883747753017502, 14.4980032251295],
            [38.884778569806002, 14.4983978555366],
            [38.885774576877303, 14.4987348671735],
            [38.886768338160302, 14.4989348996447],
            [38.887914813041697, 14.4989936047921],
            [38.889072516864097, 14.499065355506801],
            [38.890124668640603, 14.4991371061982],
            [38.891060039430201, 14.4993599680462],
            [38.892668023788801, 14.4994730295809],
            [38.893976195421303, 14.499115363566901],
            [38.894637580049199, 14.499120799224899],
            [38.895358478064701, 14.4991849399796],
            [38.895890729870601, 14.4991805914545],
            [38.896648683391497, 14.4993588809158],
            [38.897549244463903, 14.499720895051301],
            [38.8982005230448, 14.499910055355199],
            [38.898912437907498, 14.500331860508901],
            [38.899579437005997, 14.500856941575501],
            [38.900738263722502, 14.5010547978865],
            [38.9012682697401, 14.50087650979],
            [38.902294594952203, 14.500740619376201],
            [38.903108693178503, 14.5005612439023],
            [38.903581431596699, 14.500591683386901],
            [38.904042941073897, 14.5006340812334],
            [38.905428592399701, 14.5011178509594],
            [38.906577313069299, 14.501397241084],
            [38.907701330068498, 14.501628797612801],
            [38.909101579017602, 14.502345208063399],
            [38.910692719964601, 14.5032420767575],
            [38.911597772613398, 14.504065018534],
            [38.912205258324299, 14.5046716235926],
            [38.912584796531803, 14.5048879572528],
            [38.912940753963099, 14.5051042907017],
            [38.913570697556104, 14.505502169759399],
            [38.913470759980797, 14.506207696713499],
            [38.914124284350002, 14.506697976832401],
            [38.915068638292396, 14.506608835073299],
            [38.915283111066501, 14.506894740832101],
            [38.915752480802396, 14.5078144199707],
            [38.916440814888901, 14.508177507373301],
            [38.917114551352, 14.508206858724099],
            [38.918262149127401, 14.508380792575201],
            [38.918938131378702, 14.508675392723701],
            [38.9191660788821, 14.509146099896601],
            [38.919706190946698, 14.5101103398719],
            [38.9201620859534, 14.510949562226999],
            [38.920915547897899, 14.512073593402199],
            [38.921379303163299, 14.5124268902232],
            [38.921866639205, 14.512687785975199],
            [38.922199015860102, 14.5128704128186],
            [38.9234196017524, 14.513333501639],
            [38.924390905153402, 14.5136476622824],
            [38.925136506839202, 14.515152148594201],
            [38.9253711917071, 14.516360948105],
            [38.925757467279297, 14.5173501586192],
            [38.9264603989891, 14.517967597665599],
            [38.926998265265503, 14.518736132944699],
            [38.927875245561602, 14.5190524599857],
            [38.928727522187401, 14.519299216504301],
            [38.929214858229102, 14.5196046724302],
            [38.929739249776198, 14.520109054245999],
            [38.929981794902901, 14.520810186540899],
            [38.930332137863701, 14.5217189376504],
            [38.931152973454601, 14.5224852858638],
            [38.931914295657897, 14.523033141682401],
            [38.932576803179899, 14.5230831443284],
            [38.933357214582998, 14.523180975559701],
            [38.934070252339701, 14.523706002428201],
            [38.934372310854002, 14.524464734706999],
            [38.934673246474198, 14.525097371952301],
            [38.934375679536302, 14.526171329317499],
            [38.933484101616799, 14.5269539670269],
            [38.933220221502097, 14.527855083958601],
            [38.933405499029497, 14.528822503356],
            [38.934426209771097, 14.5307529815462],
            [38.935329016631599, 14.531298642933599],
            [38.936158835375302, 14.5316029953135],
            [38.937199758210802, 14.531824737497899],
            [38.937989152766697, 14.531530168174999],
            [38.938823463086798, 14.5310029859347],
            [38.939446669315203, 14.5305703694663],
            [38.941427454516699, 14.5310421170308],
            [38.940680729936801, 14.5320660449163],
            [38.939037935860902, 14.533673667345001],
            [38.936588903817601, 14.5360334489312],
            [38.938278859445802, 14.535619320498901],
            [38.939124398707001, 14.5354127994684],
            [38.940179919165899, 14.5354127994684],
            [38.942080978885897, 14.5345834525441],
            [38.943982038605903, 14.533339969803899],
            [38.944826454972997, 14.532924749358701],
            [38.9454608901424, 14.532304091165599],
            [38.948839678504797, 14.530230145367501],
            [38.949685217765897, 14.531060595601501],
            [38.950318530041301, 14.5320964800675],
            [38.950950719422501, 14.5349997568283],
            [38.953062883234203, 14.5360367097819],
            [38.956230567504903, 14.536451924380099],
            [38.958342731316598, 14.5374888705191],
            [38.9596093558673, 14.5381116880306],
            [38.961299311495502, 14.538319293477601],
            [38.962143727862603, 14.538734503786401],
            [38.9651003080414, 14.538734503786401],
            [38.967001367761497, 14.538734503786401],
            [38.969746843848498, 14.5379051693295],
            [38.973126755105, 14.539980130030701],
            [38.975660004206297, 14.542675702045299],
            [38.976927751650997, 14.5437126189703],
            [38.977349959834498, 14.5459929495472],
            [38.977349959834498, 14.5476515546112],
            [38.979251019554503, 14.548688448171101],
            [38.978827688476898, 14.550968727359001],
            [38.979038792568701, 14.5517990996302],
            [38.980306540013402, 14.5520056053382],
            [38.981573164563997, 14.5520056053382],
            [38.981784268655701, 14.5530424784464],
            [38.981995372747498, 14.5540793466834],
            [38.982840912008697, 14.554909707251401],
            [38.9841075365593, 14.5565671584661],
            [38.985375284004, 14.5588484430876],
            [38.986430804462898, 14.5588484430876],
            [38.987276343723998, 14.5590560290357],
            [38.989176280549898, 14.5607145359306],
            [38.9898107157194, 14.5619589513298],
            [38.992603353358902, 14.564110849600199],
            [38.994035043342897, 14.5654834888918],
            [38.994879459709999, 14.5671430342497],
            [38.9953016678935, 14.5710815516185],
            [38.995724998971198, 14.5725323949992],
            [38.998048266874697, 14.575436226620001],
            [38.999949326594702, 14.5789605486949],
            [38.999527118411201, 14.583108591587299],
            [38.999527118411201, 14.5841442317122],
            [38.999527118411201, 14.585180953674699],
            [39.001217074039502, 14.586425230927],
            [39.002273717392399, 14.5872543829561],
            [39.004808089387701, 14.588497561798899],
            [39.007343584277201, 14.588497561798899],
            [39.008399104736, 14.5891202352764],
            [39.009172778774399, 14.589867875798401],
            [39.010706652122103, 14.5904688117609],
            [39.011933975379002, 14.5910686594037],
            [39.012087811871403, 14.5925715313733],
            [39.011933975379002, 14.5943743121924],
            [39.011168161599301, 14.595577244523501],
            [39.011014325106899, 14.5963270364163],
            [39.011320875197598, 14.598280829953],
            [39.011628548182401, 14.599482654287501],
            [39.011935098273099, 14.601736328872899],
            [39.012855871439299, 14.6032380413545],
            [39.013162421529998, 14.605341725530799],
            [39.012088934765501, 14.607145488291099],
            [39.011168161599301, 14.608648250422601],
            [39.0102473884331, 14.610149915696301],
            [39.008867351577798, 14.610750796249601],
            [39.007486191828498, 14.612854408535201],
            [39.007486191828498, 14.614057239776001],
            [39.007946578411598, 14.6151079460503],
            [39.008406964994698, 14.617211516625099],
            [39.008714637979502, 14.618113351147301],
            [39.0094815746534, 14.618865239268199],
            [39.0104023478196, 14.619614951739701],
            [39.012090057659599, 14.621118715127899],
            [39.012703157841003, 14.6218684199075],
            [39.013623931007302, 14.622620295174],
            [39.015005090756603, 14.623672047008199],
            [39.015311640847301, 14.6244228295852],
            [39.015465477339703, 14.6253246344969],
            [39.015772027430401, 14.626526310948201],
            [39.016079700415197, 14.6272770837653],
            [39.016692800596601, 14.6278790039668],
            [39.018688183421503, 14.628028940511101],
            [39.019761670186, 14.628328813292599],
            [39.020529729753903, 14.628628685664401],
            [39.0211428299353, 14.6292295156695],
            [39.022217439594002, 14.6302823223274],
            [39.0225239896847, 14.6311830166881],
            [39.0229843762678, 14.6320847938286],
            [39.023598599343302, 14.6329865672637],
            [39.0243655360171, 14.633586298869901],
            [39.025286309183301, 14.6340371829655],
            [39.026820182530997, 14.634488066134599],
            [39.027894792189599, 14.6343370475378],
            [39.029122115446498, 14.6341871153029],
            [39.030744697428503, 14.6348150916607],
            [39.0337282270659, 14.634996530862001],
            [39.034472705857603, 14.6328083858184],
            [39.033540703750297, 14.6315328631994],
            [39.034472705857603, 14.630621305439799],
            [39.035404707964801, 14.6315328631994],
            [39.036150309650701, 14.631531776720401],
            [39.039320239709497, 14.630802748108501],
            [39.040439765132298, 14.631166719476701],
            [39.042117368925403, 14.6318957468796],
            [39.043608572297103, 14.6326247718607],
            [39.0465921019345, 14.63335379442],
            [39.046219301091497, 14.634629306454899],
            [39.048084428200198, 14.6353594088144],
            [39.0486436294646, 14.6359058975324],
            [39.049390354044498, 14.6368163468864],
            [39.051440758680499, 14.6368163468864],
            [39.054610688739302, 14.6360862493752],
            [39.055729091267999, 14.6362676875252],
            [39.059271822169798, 14.636814173980801],
            [39.061323349699897, 14.6366316498289],
            [39.063746555178803, 14.635355062974201],
            [39.066169760657701, 14.6338948557969],
            [39.068780489452202, 14.633530888953301],
            [39.073441622882697, 14.6333461891301],
            [39.075678427940097, 14.633345102660099],
            [39.076797953362998, 14.633527629546],
            [39.078476680050201, 14.636261168791901],
            [39.078849480893098, 14.6369912657211],
            [39.081833010530502, 14.6371727031226],
            [39.082765012637701, 14.636625131106401],
            [39.083697014744999, 14.6353485442138],
            [39.085374618538097, 14.633888336993],
            [39.087052222331202, 14.633888336993],
            [39.088357025281397, 14.6337058104072],
            [39.088170624859899, 14.6317001808989],
            [39.0894754278101, 14.631882709153],
            [39.0907813536544, 14.6324292065297],
            [39.091713355761698, 14.6327920887278],
            [39.092645357868903, 14.6331571432678],
            [39.094883285820501, 14.632427033580599],
            [39.095628887506301, 14.6318794497212],
            [39.096747290034997, 14.6324259471061],
            [39.097865692563801, 14.632972443130001],
            [39.098612417143698, 14.6337014645342],
            [39.0991716184081, 14.6346119230358],
            [39.100104743409403, 14.6355234642187],
            [39.102526825994303, 14.6329702701863],
            [39.1028996268372, 14.6322401598774],
            [39.103458828101502, 14.6306006622524],
            [39.104950031473201, 14.6295054840537],
            [39.105880910686302, 14.6284113868851],
            [39.106440111950697, 14.626404622492901],
            [39.107557391585303, 14.6240338571668],
            [39.107929069534102, 14.6218456028432],
            [39.1096066733272, 14.6218456028432],
            [39.110352275013, 14.622574661199399],
            [39.111098999592997, 14.6231211817481],
            [39.112403802543099, 14.6229375596876],
            [39.112590202964597, 14.621843429789401],
            [39.112402679649001, 14.620751467504901],
            [39.112587957176402, 14.6194737017595],
            [39.1127743575978, 14.618744633109999],
            [39.1135199592837, 14.6172864885518],
            [39.114823639339697, 14.616555239537099],
            [39.1159420418685, 14.6160076160957],
            [39.120974853247802, 14.6141821947668],
            [39.122466056619402, 14.6138181952479],
            [39.123771982463701, 14.613452021989101],
            [39.125448463362702, 14.6129043908136],
            [39.126754389207001, 14.612538216032201],
            [39.128431993, 14.613631305708299],
            [39.1302971201087, 14.614541847430599],
            [39.131789446374398, 14.615088387964899],
            [39.133467050167503, 14.6154512988211],
            [39.135145776854699, 14.6159978370933],
            [39.136823380647797, 14.616543287452799],
            [39.138315706913602, 14.6172723634081],
            [39.140740035286598, 14.6178167240564],
            [39.1461467704029, 14.618361083355801],
            [39.152113829677702, 14.619450884446501],
            [39.155283759736498, 14.620178864216699],
            [39.159572092324098, 14.6203581424466],
            [39.162740899488803, 14.620173431540801],
            [39.171316441769797, 14.6196203844294],
            [39.175977575200299, 14.618886970100499],
            [39.179332782786503, 14.6181546398681],
            [39.184924795430099, 14.617056139941999],
            [39.187160477593501, 14.616143435528899],
            [39.189024481807998, 14.614683100581701],
            [39.192379689394201, 14.6139518429025],
            [39.195920174507698, 14.612854408535201],
            [39.198902581250998, 14.611575510283799],
            [39.201697464678702, 14.610479150633299],
            [39.207284985746, 14.6048223260402],
            [39.207656663694799, 14.603546640912599],
            [39.209146744172301, 14.6015385627892],
            [39.211752981390298, 14.598619862437401],
            [39.212870261025003, 14.596431355085899],
            [39.214730896557199, 14.593512586976701],
            [39.216033453719199, 14.590594866836],
            [39.2178952121455, 14.588222628621001],
            [39.219196646413401, 14.584574569723101],
            [39.220683358208603, 14.5805623958427],
            [39.221240313684802, 14.578556281495301],
            [39.221235822108298, 14.5739984435154],
            [39.221791654690399, 14.5708989728986],
            [39.226075495701501, 14.5677951115222],
            [39.229245425760404, 14.567974432433999],
            [39.233159834610902, 14.568152666407901],
            [39.2350227159314, 14.568150492823699],
            [39.2372595209888, 14.5681483192395],
            [39.238190400202001, 14.566871334817],
            [39.238934878993703, 14.5652291763188],
            [39.239491834469902, 14.564317343784699],
            [39.240236313261597, 14.563040337176099],
            [39.238371186152897, 14.562130669235399],
            [39.23799838531, 14.561401410626001],
            [39.2378097391003, 14.560125473965901],
            [39.238367817470603, 14.559031031785301],
            [39.2396714975267, 14.5573888149114],
            [39.240227330108702, 14.555018392958299],
            [39.240599008057501, 14.5539239254446],
            [39.240784285584901, 14.552829452503101],
            [39.240596762269298, 14.5513719582631],
            [39.239477236846497, 14.5502785595472],
            [39.238730512266599, 14.549186242300999],
            [39.2381701881081, 14.548092832759201],
            [39.237794018582903, 14.5455407981681],
            [39.234436565208497, 14.5428093928656],
            [39.232572560993901, 14.542082244256299],
            [39.230706310991202, 14.539348622315201],
            [39.231448543994702, 14.5369780066773],
            [39.231444052418198, 14.5327856177262],
            [39.2325590862647, 14.5291377289868],
            [39.232177302268902, 14.5211167274042],
            [39.231055531057898, 14.5180176014566],
            [39.2303088064779, 14.516377253808701],
            [39.227881109422597, 14.511639852841601],
            [39.224149732311197, 14.5063555418304],
            [39.221909558571397, 14.503441018048401],
            [39.2198569081472, 14.5003438188907],
            [39.217431456880099, 14.497246576432801],
            [39.215939130614302, 14.494877683994099],
            [39.205880245220399, 14.495826766015099],
            [39.2059622164901, 14.495694133949501],
            [39.206329402862501, 14.4952897143742],
            [39.206621355329801, 14.4949994450305],
            [39.206792035233804, 14.494842895114299],
            [39.206929028314597, 14.4946167672624],
            [39.207002016431503, 14.494538492182899],
            [39.207187293958803, 14.494291708071],
            [39.207430961979597, 14.494022093396699],
            [39.207579184001503, 14.4938307537514],
            [39.208002515079201, 14.4935100420438],
            [39.208395528015899, 14.4930262557085],
            [39.208991784785802, 14.4924685411623],
            [39.209479120827403, 14.4920108445508],
            [39.209958596610299, 14.4914270353874],
            [39.210303325100597, 14.4909530290511],
            [39.210568328109403, 14.4904322732152],
            [39.211000642339897, 14.489812583048399],
            [39.211283611654402, 14.4894527270863],
            [39.2115284025693, 14.4888036802642],
            [39.211621602779999, 14.4884731766297],
            [39.211623848568301, 14.4884155558799],
            [39.211628340144699, 14.488254652574501],
            [39.211600267792001, 14.4880122102116],
            [39.2115834243805, 14.487816516631099],
            [39.211432956570398, 14.487328369113699],
            [39.211336387677299, 14.4866358283645],
            [39.211362214241802, 14.486187904467499],
            [39.211545245980901, 14.485618213544001],
            [39.211767579013703, 14.485314885146],
            [39.212014615716797, 14.4849648066944],
            [39.212736636626502, 14.4841809333748],
            [39.213362088643002, 14.4838308531333],
            [39.213601265087398, 14.4837112603776],
            [39.214169449504602, 14.4833013828984],
            [39.214840940179499, 14.482607742361999],
            [39.215063273212301, 14.482304409847],
            [39.215679742076098, 14.481481387277899],
            [39.216205256517298, 14.481290036811099],
            [39.217377557962998, 14.4808181832502],
            [39.218326403481903, 14.4803050141831],
            [39.219016983356603, 14.479760314361901],
            [39.219270757424297, 14.4795733113205],
            [39.2196233461733, 14.479260189595999],
            [39.219891717864499, 14.4786013278576],
            [39.219807500806603, 14.477885927913],
            [39.219587413562003, 14.477373839302199],
            [39.219388661305402, 14.4769432925494],
            [39.219170819848998, 14.476349658535501],
            [39.219006877309603, 14.475919109795701],
            [39.218836197405601, 14.475316775055999],
            [39.218755349030097, 14.4745089481005],
            [39.218726153783301, 14.473944663462801],
            [39.218840688981999, 14.4733042693326],
            [39.218836197405601, 14.4730748577431],
            [39.2188260913587, 14.4726377790823],
            [39.2187575948183, 14.471795149882],
            [39.2187508574536, 14.471266738197],
            [39.218801387688401, 14.4703936601673],
            [39.218873252911102, 14.4700055035772],
            [39.218921537357602, 14.469568418873401],
            [39.218955224180803, 14.4692715922035],
            [39.219015860462399, 14.4688366806151],
            [39.219010245991903, 14.4682484613366],
            [39.219021474933001, 14.4675254170202],
            [39.218959715757201, 14.4668556475531],
            [39.218840688981999, 14.4657487838439],
            [39.218895710793198, 14.465129024741],
            [39.218903571051897, 14.464853938128901],
            [39.219352728693998, 14.464463597252699],
            [39.219807500806603, 14.464292890860101],
            [39.220129771414797, 14.464175462182601],
            [39.220635073762097, 14.463891675955701],
            [39.220967450417199, 14.463819913863899],
            [39.221445803305997, 14.4636274608668],
            [39.2219701948531, 14.463089243972901],
            [39.222076869793099, 14.462702161925799],
            [39.22208697584, 14.462391191028599],
            [39.222003881676301, 14.462021504780299],
            [39.221832078878201, 14.461475672782999],
            [39.221701823162, 14.461126644049999],
            [39.221487350387903, 14.4604307595813],
            [39.221203258179301, 14.4596750700724],
            [39.221125778485998, 14.459511971280101],
            [39.221019103545999, 14.459152066188],
            [39.220901199665001, 14.458781287220701],
            [39.2207125534553, 14.457994059444101],
            [39.220713676349398, 14.4571894314867],
            [39.220866389947702, 14.4561010044381],
            [39.220879864677002, 14.455284408817],
            [39.220827088654097, 14.4547081144957],
            [39.2210527903692, 14.453541385757299],
            [39.2209562214762, 14.4520897622353],
            [39.22129645839, 14.4510176207244],
            [39.221652415821303, 14.4501999190582],
            [39.221844430713297, 14.4497453974168],
            [39.222367699366302, 14.449611650441099],
            [39.2229662019244, 14.4497551837777],
            [39.223166077075099, 14.4497606206447],
            [39.223513051353599, 14.4496442716621],
            [39.223625340764102, 14.4494518063889],
            [39.223626463658199, 14.449394175511999],
            [39.223470381377602, 14.449125593681099],
            [39.2232446796624, 14.4487863319574],
            [39.223169445757399, 14.4485416717392],
            [39.223168322863302, 14.4482089334105],
            [39.223294087003097, 14.447567377711099],
            [39.2233580919671, 14.447420580807799],
            [39.223428834295703, 14.447054131819],
            [39.223528771871003, 14.446493039615699],
            [39.2231312673578, 14.4459874029872],
            [39.222870755925399, 14.445600291157101],
            [39.222697830233201, 14.445112050837601],
            [39.222623719222298, 14.444822803283101],
            [39.222826963055297, 14.444357396654],
            [39.222954972983302, 14.4440616237108],
            [39.223157093922197, 14.443620138365301],
            [39.2233614606494, 14.443130806293601],
            [39.223330019614401, 14.442613200530801],
            [39.223343494343702, 14.4421869360571],
            [39.223484979000901, 14.441823740682601],
            [39.223894835349299, 14.441570373498401],
            [39.224258653039399, 14.4416388805337],
            [39.224456282401903, 14.441735660277899],
            [39.224749357763301, 14.4418128665605],
            [39.224971690796202, 14.4418889854044],
            [39.225168197264601, 14.442009688089099],
            [39.225491590766801, 14.4422380443407],
            [39.225737504575903, 14.442323949726999],
            [39.226053037819398, 14.442403330624201],
            [39.226359587910103, 14.442412029898801],
            [39.226596518566303, 14.4424185543546],
            [39.227101820913603, 14.4421347403512],
            [39.2272096187477, 14.4420803698111],
            [39.227169194559899, 14.4418367896285],
            [39.227022095432197, 14.441269159775199],
            [39.226720036917897, 14.440720014573399],
            [39.226566200425502, 14.440348117449201],
            [39.226324778192897, 14.439789183175201],
            [39.226212488782402, 14.4392106738488],
            [39.226151852500699, 14.4388637850164],
            [39.226173187488698, 14.4385767041944],
            [39.226236069558603, 14.438072137003701],
            [39.226277616640502, 14.437866612539899],
            [39.226480860473501, 14.4374001039258],
            [39.226691964565198, 14.4370847478983],
            [39.227093960654898, 14.436693270828799],
            [39.227372438392997, 14.4364714335171],
            [39.228020348291601, 14.436123452974799],
            [39.228457154098599, 14.4357548104944],
            [39.228969193810499, 14.4352643736816],
            [39.229188158161001, 14.435040360055099],
            [39.229805749918803, 14.434574932964599],
            [39.230674869956196, 14.433587528344599],
            [39.230956716376603, 14.433239543293899],
            [39.231125150492403, 14.432773024981699],
            [39.231007246611298, 14.432402202036201],
            [39.230919660871102, 14.4318008368263],
            [39.230958962164799, 14.431308216375299],
            [39.230975805576399, 14.4311244349565],
            [39.2312329483265, 14.4308558310717],
            [39.231540621311296, 14.4304393312896],
            [39.232106559940298, 14.430098953604601],
            [39.232650040687197, 14.4297118141355],
            [39.233027333106499, 14.429320324100299],
            [39.233150851458099, 14.428771149418701],
            [39.233120533317198, 14.4282415479975],
            [39.233093583858697, 14.4275966722871],
            [39.233318162679701, 14.4264537260949],
            [39.233783040839299, 14.425926294142499],
            [39.234022217283702, 14.425070437986999],
            [39.233953720743301, 14.4246310899665],
            [39.233831325285799, 14.424398365664],
            [39.233584288582698, 14.423988378801299],
            [39.233427083407904, 14.4237197663097],
            [39.233451787078302, 14.4232825906308],
            [39.233635941711498, 14.4230803150264],
            [39.233731387710399, 14.4230379023764],
            [39.234360208409299, 14.4221939977127],
            [39.234537625677902, 14.4221755100747],
            [39.234883477062297, 14.422092859439299],
            [39.235549353266698, 14.4219166829823],
            [39.236184911330199, 14.4216361053745],
            [39.2365453603379, 14.421416428085999],
            [39.236489215632702, 14.4209422724084],
            [39.236320781516902, 14.420283237841099],
            [39.236174805283198, 14.419657914461],
            [39.236126520836699, 14.4193109951681],
            [39.236152347401102, 14.418864023119999],
            [39.2361669450245, 14.418403999856899],
            [39.2362185981533, 14.417876548847699],
            [39.2363937696337, 14.417167478719801],
            [39.236846295958102, 14.4163072387722],
            [39.237361704352402, 14.415309965297],
            [39.237897324840503, 14.414405128795799],
            [39.238251036483703, 14.4136797343811],
            [39.238449788740297, 14.4129826139862],
            [39.238459894787198, 14.4122713531776],
            [39.238368940364701, 14.411785214897399],
            [39.238254405166003, 14.4109086407337],
            [39.2383453595885, 14.4102898164513],
            [39.238592396291601, 14.4095252561349],
            [39.2386238373266, 14.4092968663427],
            [39.239298696683797, 14.409213123360299],
            [39.239700692773397, 14.409201160074501],
            [39.2403968871186, 14.40922073636],
            [39.240785408478999, 14.409255538641],
            [39.241137997228002, 14.409333843753499],
            [39.241487217294697, 14.409481753335299],
            [39.241756711879901, 14.409570934065201],
            [39.242118283781799, 14.409673165589799],
            [39.242187903216298, 14.4097210186277],
            [39.242543860647601, 14.4100527269038],
            [39.243284970757003, 14.4105453943508],
            [39.243845294915502, 14.410733543000299],
            [39.244315787545602, 14.4104148865238],
            [39.244334876745398, 14.409805848987901],
            [39.244311295969197, 14.4094023607043],
            [39.244133878700502, 14.408304999388699],
            [39.2440799797835, 14.4077622983877],
            [39.2442416765346, 14.4070999621102],
            [39.2444786071908, 14.4067040820167],
            [39.244805369375399, 14.4064495872998],
            [39.245201750994497, 14.406219019185899],
            [39.245274739111402, 14.4061515888434],
            [39.245434190074299, 14.405984100485201],
            [39.245447664803599, 14.4059155824842],
            [39.245479105838498, 14.405283693260699],
            [39.246551469708898, 14.405951472868299],
            [39.247520527321697, 14.4065550830083],
            [39.2478147255772, 14.40656052093],
            [39.248156085385197, 14.4065768346945],
            [39.248519903075199, 14.4066301263169],
            [39.248789397660502, 14.4067258336884],
            [39.249153215350603, 14.406800876939601],
            [39.2494361846651, 14.4068052272722],
            [39.249661886380203, 14.406694293763699],
            [39.249796633672801, 14.4064093466556],
            [39.249723645556003, 14.4058329258201],
            [39.249469871488202, 14.4055545031471],
            [39.249225080573297, 14.405400065420899],
            [39.248432317335102, 14.4049389267851],
            [39.2483301339715, 14.4046963935824],
            [39.248263883219302, 14.4044418965749],
            [39.248249285595897, 14.4039709675859],
            [39.248406490770599, 14.403031281828801],
            [39.248606365921297, 14.4024363629898],
            [39.248659141944302, 14.402081803658501],
            [39.248595136980299, 14.401689177484],
            [39.248355960535903, 14.4012030161387],
            [39.248172928796798, 14.4009474275346],
            [39.247709173531298, 14.400491717765201],
            [39.247595761226698, 14.400293771943],
            [39.247324020853299, 14.399599872564201],
            [39.2471791675137, 14.3991844019975],
            [39.247116285443802, 14.398723250515101],
            [39.2471892735607, 14.3985862100324],
            [39.2474059921229, 14.398303427818099],
            [39.247740614566297, 14.3980674131572],
            [39.248469372840503, 14.397482269741699],
            [39.248590645403901, 14.397299548063501],
            [39.248811855542598, 14.396775310037301],
            [39.248898318388697, 14.396580623817799],
            [39.249049909092903, 14.395985687781801],
            [39.249236309514302, 14.3947707951927],
            [39.2491824105973, 14.3945054104348],
            [39.249044294622401, 14.394273742334899],
            [39.248652404579701, 14.3938071424745],
            [39.248424457076297, 14.393344892233999],
            [39.248349223171303, 14.392941374182801],
            [39.2483817871003, 14.3924704209258],
            [39.248531132016304, 14.3919907654328],
            [39.248857894200903, 14.391536125034801],
            [39.249239678196702, 14.391232668368801],
            [39.249710170826702, 14.391240281981901],
            [39.250123395857401, 14.3912587721842],
            [39.250451280936097, 14.3913436095633],
            [39.250781411802997, 14.391361012098599],
            [39.251194636833702, 14.391275087067299],
            [39.251348473326097, 14.391220704119],
            [39.251561823206103, 14.391143480309699],
            [39.251980662707297, 14.390783464733399],
            [39.254161323059499, 14.3920418852283],
            [39.254877729498602, 14.392320324759901],
            [39.255724391653899, 14.3925291541807],
            [39.256867497852902, 14.3924551937831],
            [39.257915158053002, 14.392355129676901],
            [39.258127385038897, 14.392345340794501],
            [39.2584451640707, 14.392357304983999],
            [39.258563067951698, 14.392426914801099],
            [39.2589021819715, 14.3928521868062],
            [39.259267122555599, 14.392980529605101],
            [39.259831938290503, 14.3930860316812],
            [39.2603147827557, 14.393007720866599],
            [39.260420334801601, 14.393007720866599],
            [39.2613141585093, 14.3932296014366],
            [39.261538737330397, 14.3932306890859],
            [39.262092324124197, 14.3932448285265],
            [39.2622798474398, 14.393256792667801],
            [39.262339360827298, 14.3932796332994],
            [39.262855892115702, 14.393489549470999],
            [39.263466746508897, 14.393893066531399],
            [39.264265124217701, 14.3944934463604],
            [39.265286957853299, 14.394841491898701],
            [39.265992135351397, 14.395222166085],
            [39.2667085417905, 14.3957768615942],
            [39.266801742001199, 14.395869310711801],
            [39.267271111737102, 14.3964098653737],
            [39.267481092934801, 14.3966513199506],
            [39.268231186196999, 14.397423537790001],
            [39.268921766071699, 14.3983904377684],
            [39.269413593689798, 14.399115881908299],
            [39.269916650248902, 14.399645552527],
            [39.270644285628997, 14.4001763095039],
            [39.271561690112897, 14.4006276694644],
            [39.273066368213797, 14.401219330295],
            [39.2741129055198, 14.401602168819901],
            [39.274958444780999, 14.4018914724967],
            [39.275981401310801, 14.402205790728001],
            [39.276593378598101, 14.402368931503901],
            [39.2777342390089, 14.4027528556592],
            [39.278310283684903, 14.4029725513376],
            [39.279237794215703, 14.4034478328322],
            [39.280178779475797, 14.403853507082699],
            [39.281214087840802, 14.403994894717901],
            [39.282296557758102, 14.404216764364699],
            [39.2829433447627, 14.4042994216277],
            [39.283427312122001, 14.4042559178089],
            [39.284452514439998, 14.403915500133801],
            [39.285277841607297, 14.403677316212001],
            [39.286208720820497, 14.4036359874691],
            [39.286927373047803, 14.403615323094799],
            [39.287891939084098, 14.403733871321499],
            [39.288679087851797, 14.403978580764599],
            [39.289209093869403, 14.404140632648],
            [39.289972661860901, 14.4045800210551],
            [39.290689068299997, 14.4049041238305],
            [39.291548082290497, 14.405160795493],
            [39.291781644264297, 14.4053913647007],
            [39.292003977297199, 14.4059079693717],
            [39.291977027838598, 14.4066192504766],
            [39.2918456492283, 14.407101049691899],
            [39.291607595678002, 14.4077318461882],
            [39.291404351845003, 14.408419194821899],
            [39.291307782952003, 14.40904998759],
            [39.291306660057899, 14.409302304197601],
            [39.291435792880002, 14.409394747710801],
            [39.2918456492283, 14.4098439138827],
            [39.291880458945599, 14.409889591747699],
            [39.2920792112022, 14.410246313800901],
            [39.2924879446565, 14.410993470642101],
            [39.292297052658597, 14.4116818967819],
            [39.292447520468698, 14.4121528094889],
            [39.2926114630081, 14.4125204034742],
            [39.2929516999219, 14.4127292139889],
            [39.2935052867157, 14.412787941910899],
            [39.293682703984402, 14.412684624260001],
            [39.293966796192997, 14.4121702104012],
            [39.294109403744301, 14.4118493808609],
            [39.294192497908099, 14.4116199058895],
            [39.2943710380708, 14.411426320185001],
            [39.294630426609103, 14.411277324331699],
            [39.295313146224998, 14.411131591064899],
            [39.295655628927101, 14.4111446418091],
            [39.296231673603103, 14.411214245765199],
            [39.296608966022397, 14.411263186033899],
            [39.297222066203801, 14.411092438827801],
            [39.297327618249703, 14.4110696000196],
            [39.297543213917898, 14.4104518637492],
            [39.297990125771697, 14.410430112440901],
            [39.2983326084738, 14.410317005603501],
            [39.298791872162802, 14.410158220907901],
            [39.299699170599801, 14.4100233625845],
            [39.300405470991898, 14.410049464201901],
            [39.300664859530201, 14.410050551769199],
            [39.301253256041299, 14.410053814471199],
            [39.302617572378999, 14.410310480207199],
            [39.3035821384154, 14.4107270176162],
            [39.303898794553, 14.410958668632301],
            [39.304098669703698, 14.4110391482717],
            [39.304517509204899, 14.4115078872158],
            [39.3042525061961, 14.411813491426001],
            [39.303787628036602, 14.412173473072199],
            [39.303193617055001, 14.4124170856993],
            [39.302909524846399, 14.4125051776999],
            [39.302492931133401, 14.412750965072901],
            [39.302251508900802, 14.413034816525],
            [39.302248140218403, 14.413241451454301],
            [39.302267229418199, 14.4135285649329],
            [39.302264983630003, 14.413654720738499],
            [39.302317759653, 14.4139885982585],
            [39.302435663533998, 14.414645476349699],
            [39.302404222499099, 14.415127258222499],
            [39.302288564406197, 14.415677554076501],
            [39.3022492631126, 14.4158841865561],
            [39.302028052973803, 14.416385541407999],
            [39.301858495963899, 14.4167270275811],
            [39.301613705049, 14.417218592746099],
            [39.301455376980201, 14.417526364189101],
            [39.301227429476903, 14.417763445788299],
            [39.300725495811903, 14.4181571311894],
            [39.300367292592298, 14.4183822493245],
            [39.300178646382598, 14.418435538125101],
            [39.300059619607502, 14.4184790391774],
            [39.299656500623797, 14.418633467844399],
            [39.299619445118303, 14.418713944713],
            [39.299464485731797, 14.418803121749599],
            [39.2992500129577, 14.4190064887606],
            [39.299104036724003, 14.4192457438301],
            [39.299171410370299, 14.4194306225715],
            [39.299484697825697, 14.419723165678301],
            [39.299972033867299, 14.4201211976902],
            [39.300205595841199, 14.420274537701699],
            [39.300426805979903, 14.4204159149239],
            [39.300717635553099, 14.420604055241901],
            [39.300892807033499, 14.420745432255],
            [39.301064609831599, 14.421000998166299],
            [39.301083699031402, 14.421231550992299],
            [39.300972532514997, 14.4215393168929],
            [39.300782763411199, 14.421628492798799],
            [39.300436912026797, 14.421852519917101],
            [39.299987754384802, 14.421936258151099],
            [39.299835040786498, 14.421946045734799],
            [39.299371285520998, 14.4221341847608],
            [39.2988648602796, 14.42216028496],
            [39.298594242800299, 14.422143972335901],
            [39.298216950380997, 14.422150497385701],
            [39.297734105915701, 14.422141797319201],
            [39.297485946318503, 14.422161372468199],
            [39.2971984854276, 14.422466962070899],
            [39.2970300513118, 14.422670325738],
            [39.296924499265899, 14.423255403060301],
            [39.297034542888198, 14.423739341319999],
            [39.297183887804202, 14.4239601038174],
            [39.297389377425503, 14.4243081033691],
            [39.2977722843153, 14.4246365274473],
            [39.297946332901603, 14.424811614257999],
            [39.298227056427898, 14.4249301512132],
            [39.298602103058997, 14.425063913022701],
            [39.298718884046004, 14.4251215502004],
            [39.298494305224899, 14.4258545198009],
            [39.2982876927096, 14.4262188287199],
            [39.298023812594899, 14.426466775941799],
            [39.297841903749799, 14.426740822550199],
            [39.2975420910238, 14.427045318386201],
            [39.2970300513118, 14.427393313117699],
            [39.296767294091197, 14.427560785388399],
            [39.296644898633701, 14.4278348306506],
            [39.296510151341103, 14.4281197503672],
            [39.2965180115999, 14.4283394210449],
            [39.296688691503803, 14.4286863262779],
            [39.296827930372899, 14.4288146484528],
            [39.297083950228902, 14.429036493394401],
            [39.297420818460402, 14.429272475271],
            [39.297699296198502, 14.4295302063436],
            [39.298140593581799, 14.4299042964838],
            [39.298431423155002, 14.4301054784213],
            [39.2984976739072, 14.430358858657],
            [39.2984460207784, 14.4306329004758],
            [39.298312396379899, 14.4308503937427],
            [39.298096800711697, 14.4310526622903],
            [39.297822814550003, 14.4312092571687],
            [39.297489315000803, 14.4314332746504],
            [39.297141217828198, 14.4318084490543],
            [39.2969480800421, 14.432092276226699],
            [39.2968481424668, 14.432354353869201],
            [39.296689814398, 14.4327077775874],
            [39.296540469481997, 14.433095999301999],
            [39.296260868849799, 14.4336081899399],
            [39.296138473392297, 14.4338941897197],
            [39.2959924971587, 14.434144303294399],
            [39.295763426761198, 14.434450963902499],
            [39.295557937140003, 14.434701076851599],
            [39.295318760695601, 14.4349609765314],
            [39.2954804574467, 14.435553633662],
            [39.2958206943606, 14.4357722095633],
            [39.296174406003701, 14.435808095138499],
            [39.2963259967079, 14.4360603814433],
            [39.296324873813802, 14.4364170615932],
            [39.296698797550803, 14.4370847478983],
            [39.296992995806299, 14.4370967096868],
            [39.297736351704003, 14.436639986396701],
            [39.2981024151822, 14.436663910020799],
            [39.298583013859201, 14.437034725866701],
            [39.298830050562401, 14.4371956662762],
            [39.2989232507731, 14.4372870107811],
            [39.299264610581098, 14.4373576940032],
            [39.299842901045203, 14.437233726491099],
            [39.300288690004997, 14.4374762242792],
            [39.301030923008497, 14.4374566504765],
            [39.301385757545702, 14.437206540624],
            [39.301750698129901, 14.437034725866701],
            [39.3026445218376, 14.4373587814373],
            [39.303493429781, 14.4373631311732],
            [39.304271595395903, 14.4370673489321],
            [39.304636535980102, 14.4368857471407],
            [39.305651632251099, 14.4365334174941],
            [39.306405094195703, 14.4364333731718],
            [39.307159679034399, 14.4362985307532],
            [39.307219192421897, 14.4362985307532],
            [39.307960302531299, 14.4363246292922],
            [39.308349946785803, 14.4361201906547],
            [39.308833914145097, 14.435857030004399],
            [39.309048386919201, 14.4353296203266],
            [39.309356059903998, 14.4349533644112],
            [39.310063483190298, 14.434726088131001],
            [39.3103341006696, 14.4347956847199],
            [39.310932603227698, 14.4352687234586],
            [39.311213326754, 14.435625404877101],
            [39.311692802536903, 14.436614975332301],
            [39.312656245679101, 14.4371684804044],
            [39.313032415204297, 14.437239163664101],
            [39.313373775012302, 14.4373903170213],
            [39.3138554965834, 14.437541470275701],
            [39.314526987258198, 14.437554519472799],
            [39.315386001248697, 14.437650213560801],
            [39.315986749594899, 14.4377100223449],
            [39.316810953868099, 14.437793754615701],
            [39.317481321648899, 14.4378763994235],
            [39.318293174086897, 14.4381547817081],
            [39.3186693436122, 14.4383516064727],
            [39.318985999749799, 14.43865064929],
            [39.319372275322003, 14.4392269852226],
            [39.319430665815403, 14.4393759623813],
            [39.3196417699072, 14.439606496182],
            [39.320004464703203, 14.439951209014],
            [39.320272836394302, 14.440813532465899],
            [39.320188619336399, 14.4411799917356],
            [39.320079698608197, 14.4417410973409],
            [39.319581133625498, 14.442875265783901],
            [39.319460983956297, 14.4433700354409],
            [39.319471090003198, 14.4436679841101],
            [39.319540709437703, 14.4440464000927],
            [39.319622680707397, 14.4441616646032],
            [39.320879199211099, 14.445015272562401],
            [39.321055493585597, 14.445154459282],
            [39.321383378664301, 14.4454426190108],
            [39.322064975386098, 14.445858003321201],
            [39.322888056765201, 14.446320144639801],
            [39.323722367085303, 14.4466333127992],
            [39.324286059926102, 14.4471400353454],
            [39.324308517808198, 14.4472890072048],
            [39.324249004420601, 14.447323803537101],
            [39.324143452374699, 14.447392308800501],
            [39.324071587151998, 14.4476097856873],
            [39.323799846778599, 14.447757669848899],
            [39.3235516871813, 14.4479523113528],
            [39.322985748552298, 14.448145865305101],
            [39.322124488773703, 14.448578642633899],
            [39.321876329176497, 14.4489331279591],
            [39.321851625506099, 14.4490820986173],
            [39.322014445151403, 14.4494387578898],
            [39.3229093917532, 14.4495453206102],
            [39.323274332337398, 14.4495116120001],
            [39.323686434473899, 14.449548582733501],
            [39.3237684057436, 14.4497312615614],
            [39.323543826922602, 14.4500172406154],
            [39.323141830833002, 14.450222753853],
            [39.322882442294699, 14.450348888867699],
            [39.322717376861199, 14.4504967712076],
            [39.322703902131899, 14.4507490408545],
            [39.322984625658201, 14.451243792994701],
            [39.3233248625721, 14.4514862755097],
            [39.323642641603897, 14.451682001113699],
            [39.323971649576698, 14.451878813908399],
            [39.324277076773299, 14.452132169348999],
            [39.324263602043999, 14.4524083590711],
            [39.324251250208803, 14.4526138700984],
            [39.324062603999202, 14.4527052082718],
            [39.323507894311199, 14.452841128103101],
            [39.3233080191605, 14.4528976707285],
            [39.323108144009801, 14.4528389533865],
            [39.3223906146766, 14.4525605894799],
            [39.321944825716898, 14.4522267698043],
            [39.321404713652299, 14.451616759264899],
            [39.321018438080102, 14.4511220079547],
            [39.320678201166302, 14.450856690315],
            [39.320077452820001, 14.450842554530199],
            [39.320052749149703, 14.4510371933338],
            [39.319719249600503, 14.4519766765592],
            [39.319459861062199, 14.451998423809099],
            [39.319212824358999, 14.451905997982401],
            [39.318872587445199, 14.451572177323801],
            [39.318568283142703, 14.451238356164],
            [39.3183324753806, 14.4511220079547],
            [39.318074209736402, 14.4511328816217],
            [39.318002344513701, 14.4513623158714],
            [39.318047260277901, 14.451878813908399],
            [39.318116879712399, 14.4521430429666],
            [39.3180337855486, 14.4524638144464],
            [39.317550941083397, 14.45233550591],
            [39.3173398369917, 14.452174576454601],
            [39.316846886479503, 14.451748330307099],
            [39.316588620835297, 14.451493887064199],
            [39.3159654146069, 14.451205735176901],
            [39.315823929949701, 14.451204647810499],
            [39.3158351588907, 14.451480838684899],
            [39.315928359101498, 14.451653729648299],
            [39.316115882417002, 14.451963628208199],
            [39.316196730792598, 14.4522398181398],
            [39.316066475076397, 14.4523768256163],
            [39.315841896255399, 14.452593210268301],
            [39.315804840749898, 14.452880272999099],
            [39.315839650467197, 14.453144500866999],
            [39.316004715900597, 14.453258673305299],
            [39.3162506297097, 14.4533869813088],
            [39.3167795128332, 14.4535957534953],
            [39.317062482147698, 14.4536305488406],
            [39.317652001552901, 14.453610976459601],
            [39.318075332630499, 14.4536577327004],
            [39.318393111662303, 14.4538773781653],
            [39.318357179050899, 14.453957842291301],
            [39.3181438291709, 14.4541861862737],
            [39.318024802395797, 14.454403656515099],
            [39.317918127455798, 14.4546439608845],
            [39.3179170045617, 14.4549190601376],
            [39.317938339549698, 14.455310505128301],
            [39.318125862865301, 14.455609525142799],
            [39.318431290061902, 14.455794373677801],
            [39.318667097823898, 14.4558520028956],
            [39.319172400171198, 14.4559792220592],
            [39.319960671833101, 14.4562477956098],
            [39.320618687778698, 14.456628364862601],
            [39.321006086244999, 14.4568251732777],
            [39.321934719669898, 14.457402549445501],
            [39.322463602793398, 14.457737448682201],
            [39.323202467114598, 14.4583365691096],
            [39.323648256074399, 14.458809557779601],
            [39.324105273975199, 14.459521757211],
            [39.324162541574502, 14.459866439169501],
            [39.324161418680397, 14.4600621573937],
            [39.323937962753497, 14.460094777081],
            [39.3234899280056, 14.460104562986301],
            [39.3233248625721, 14.460116523536501],
            [39.322760046837203, 14.4598957969141],
            [39.322336715759597, 14.4598131602897],
            [39.3218785749647, 14.4597087771413],
            [39.321312636335698, 14.459534805118199],
            [39.320760172435897, 14.459314077917901],
            [39.319902281339601, 14.458967220445899],
            [39.319372275322003, 14.458814994425101],
            [39.318290928298701, 14.458605139811301],
            [39.317866474326998, 14.4584877081274],
            [39.317561047130397, 14.4583604903998],
            [39.317326362262399, 14.458095181400299],
            [39.316997354289597, 14.4578429200906],
            [39.316233786298099, 14.457483012295199],
            [39.315668970563202, 14.4574819249596],
            [39.315137841651399, 14.457595007834801],
            [39.314923368877402, 14.4581104040564],
            [39.315112015087003, 14.458247407914101],
            [39.315429794118799, 14.4583409184352],
            [39.316147323452, 14.4584811841431],
            [39.316405589096199, 14.458574694565799],
            [39.316476331424802, 14.458655156991099],
            [39.316476331424802, 14.458723658762599],
            [39.316073212440998, 14.459192297299801],
            [39.315873337290299, 14.4592955933641],
            [39.315743081574098, 14.459374968202001],
            [39.315671216351397, 14.4595587262795],
            [39.315717255009702, 14.4597881518315],
            [39.315775645503201, 14.459915368741701],
            [39.316009207477002, 14.460317678149201],
            [39.316232663404001, 14.4606275646286],
            [39.316325863614701, 14.460835242693999],
            [39.316453873542699, 14.461260384096599],
            [39.316218065780603, 14.461259296779501],
            [39.316569531635501, 14.4617888195909],
            [39.316615570293798, 14.461914948041599],
            [39.316638028175902, 14.462109577148199],
            [39.316636905281797, 14.4623629209255],
            [39.316530230341797, 14.4626380106225],
            [39.316447136178098, 14.4627989325008],
            [39.316115882417002, 14.4630370531744],
            [39.315974397759803, 14.463071847041499],
            [39.315480324353501, 14.4631153393675],
            [39.314890804948298, 14.4631131647514],
            [39.314631416410002, 14.4631936255331],
            [39.314147449050701, 14.463443706154701],
            [39.313911641288598, 14.4635676590541],
            [39.313734224020003, 14.463842747259299],
            [39.313733101125898, 14.4640493350154],
            [39.3137420842788, 14.4648180572414],
            [39.313857742371603, 14.4650713979296],
            [39.313986875193699, 14.4653247383288],
            [39.314278827660999, 14.465773791631101],
            [39.314384379706901, 14.465991250530999],
            [39.314478702811698, 14.466106503661701],
            [39.3146392766688, 14.466967638367599],
            [39.314567411445999, 14.4673242689347],
            [39.3144124520595, 14.4677243903526],
            [39.314375396553999, 14.468069059583],
            [39.3143607989307, 14.4686659775838],
            [39.314370904977601, 14.469216141023299],
            [39.314462982294302, 14.4696184335846],
            [39.314754934761602, 14.4703316856312],
            [39.314859363913399, 14.4708361795978],
            [39.314857118125197, 14.471305879846399],
            [39.314843643395903, 14.4716396708543],
            [39.314783007114201, 14.4720528316673],
            [39.314650505609798, 14.4727519415021],
            [39.314578640387097, 14.4729585209696],
            [39.314531478834702, 14.4731531403974],
            [39.3145292330465, 14.473509761029],
            [39.314516881211297, 14.4738196291003],
            [39.314516881211297, 14.473876166386001],
            [39.314503406481997, 14.4740936173504],
            [39.314397854436201, 14.4741512418203],
            [39.3143024084372, 14.474415444386899],
            [39.313971154676203, 14.4746198478029],
            [39.313711766137899, 14.474779673746999],
            [39.313474835481699, 14.474892747814801],
            [39.3132165698375, 14.4749840768275],
            [39.312920125793802, 14.4752123991949],
            [39.3125776430917, 14.4756244661111],
            [39.312328360600397, 14.4760604516458],
            [39.3121722783198, 14.476609509517299],
            [39.312524867068802, 14.476967211835399],
            [39.313077330968497, 14.4771531298338],
            [39.313418690776501, 14.4773151284306],
            [39.313641023809303, 14.477510831275699],
            [39.313687062467601, 14.4777978618029],
            [39.313590493574601, 14.4783947536424],
            [39.313517505457703, 14.4789101015892],
            [39.313527611504703, 14.479414576057099],
            [39.313630917762303, 14.4798744731178],
            [39.313772402419602, 14.4800364697278],
            [39.314370904977601, 14.480336543672699],
            [39.314593238010502, 14.4806811933188],
            [39.314815571043297, 14.4810834650992],
            [39.315190617674403, 14.481579236884601],
            [39.3155667871996, 14.481730360081301],
            [39.316675083681403, 14.481470515096699],
            [39.318244889640397, 14.4806844549848],
            [39.319153310971501, 14.4804126493189],
            [39.319907895810097, 14.4801288838483],
            [39.3203323497819, 14.480049516429499],
            [39.321099286455699, 14.479800541739801],
            [39.322089679056397, 14.4796668128609],
            [39.322195231102299, 14.479654853363799],
            [39.3227858734016, 14.4793928314916],
            [39.324260233361699, 14.478711138283201],
            [39.325358423796501, 14.4782555877468],
            [39.326148941246501, 14.477982691870199],
            [39.327526732313601, 14.4780468387399],
            [39.327832159510201, 14.478160998377399],
            [39.328655240889198, 14.4786230725006],
            [39.329243637400303, 14.4786263341969],
            [39.3295849972083, 14.4787535403142],
            [39.329841017064297, 14.4793395572972],
            [39.329945446216101, 14.4799255727324],
            [39.330109388755403, 14.4801441049938],
            [39.330332844682303, 14.4802484785353],
            [39.330957173804798, 14.480226734051501],
            [39.331380504882397, 14.480309363078501],
            [39.3314265435408, 14.480664884988],
            [39.331141328438001, 14.4812161059047],
            [39.330810074676997, 14.481719487912301],
            [39.330477698021902, 14.482142414893101],
            [39.330192482919202, 14.482714289168401],
            [39.330037523532702, 14.4831165572621],
            [39.329777012100301, 14.483688429026],
            [39.329303150787901, 14.484226595974301],
            [39.329113381684103, 14.4844777401033],
            [39.3289359644155, 14.4846723494276],
            [39.328191485623798, 14.485393163482],
            [39.327872583697904, 14.4855638536465],
            [39.327352683727298, 14.4857562876236],
            [39.326726108816601, 14.4864999292195],
            [39.326336464562097, 14.486751070774201],
            [39.325934468472497, 14.4868880569569],
            [39.325581879723401, 14.4868750106574],
            [39.3250979123641, 14.487102233596699],
            [39.324991237424101, 14.487343589765199],
            [39.3249193722014, 14.4875381965747],
            [39.324846384084601, 14.4879741586956],
            [39.324774518861801, 14.488387289091399],
            [39.3245375882057, 14.4887406236878],
            [39.323852622801503, 14.489278778375599],
            [39.323167657397399, 14.4895864500649],
            [39.3227544323667, 14.489813670225599],
            [39.321679822707999, 14.4904290116926],
            [39.321125113020102, 14.490759512411501],
            [39.320969030739498, 14.491253088225699],
            [39.320991488621601, 14.4915042243966],
            [39.321038650174003, 14.4915618443437],
            [39.321403590758202, 14.491655340829899],
            [39.3218752062824, 14.4915433624755],
            [39.322063852492001, 14.4915085730724],
            [39.322475954628601, 14.491510747410199],
            [39.322971150929, 14.4915357522941],
            [39.323300158901802, 14.491640120474299],
            [39.323465224335202, 14.491755360282999],
            [39.323605586098402, 14.491779277971601],
            [39.324007582188003, 14.491596633738499],
            [39.324243389950098, 14.491597720907],
            [39.324192859715403, 14.4921489146495],
            [39.324192859715403, 14.4922739386805],
            [39.3243455733137, 14.4922869846623],
            [39.324875579331298, 14.492242410888],
            [39.3249227408837, 14.4923119894585],
            [39.324957550600999, 14.4926098724666],
            [39.3249328469307, 14.4928055618161],
            [39.324659983663103, 14.4931599765315],
            [39.3244589856183, 14.493377408591799],
            [39.324328729902099, 14.4936176707703],
            [39.324198474185899, 14.493903593294901],
            [39.324172647621502, 14.494202561319799],
            [39.324501655594297, 14.4945482765694],
            [39.324535342417398, 14.494754835737799],
            [39.324535342417398, 14.495019013762199],
            [39.324451125359602, 14.495304934479099],
            [39.324308517808198, 14.4955343230765],
            [39.324260233361699, 14.4957756700648],
            [39.3239402085417, 14.496095291347499],
            [39.323721244191198, 14.4965116680414],
            [39.323524737722799, 14.4972204850524],
            [39.323174394761999, 14.4979662624658],
            [39.323296790219501, 14.4986794233588],
            [39.323962666423803, 14.4994491127226],
            [39.324763289920803, 14.499870918753899],
            [39.325799721179798, 14.500286201226899],
            [39.3270495023189, 14.501297225982601],
            [39.327890550003602, 14.501971239923],
            [39.328204960353098, 14.502287590920099],
            [39.328672084300798, 14.502588721861899],
            [39.329659108219197, 14.502901810638001],
            [39.330689925007803, 14.503629088066299],
            [39.331152557379099, 14.503688879078901],
            [39.331806081748297, 14.503916084779901],
            [39.332451745858698, 14.5042704817238],
            [39.332762787525901, 14.504437896033799],
            [39.333043511052203, 14.5048705635995],
            [39.333167029403697, 14.5050988551905],
            [39.3336498738689, 14.505617402358499],
            [39.334154053322202, 14.5059989740275],
            [39.334813192161903, 14.5064001127654],
            [39.335111881993903, 14.506567525465501],
            [39.335554302271298, 14.506811034621601],
            [39.336248250828298, 14.507256742651],
            [39.336717620564201, 14.5076513565723],
            [39.336901775197497, 14.5079470447796],
            [39.337289173663699, 14.508399273038799],
            [39.337677695024098, 14.508899332057799],
            [39.338205455253501, 14.5098222641072],
            [39.338980252186097, 14.510659313404499],
            [39.339324980676402, 14.511297425482899],
            [39.339811193823898, 14.5119333615846],
            [39.340346814312099, 14.512129033863699],
            [39.3410710810099, 14.51178986847],
            [39.342186114856297, 14.51146483448],
            [39.343516744370902, 14.5107321474144],
            [39.344287049727001, 14.5103223199122],
            [39.345342570185899, 14.5088917224725],
            [39.3455278477132, 14.5087493144707],
            [39.346303767539901, 14.5080166184181],
            [39.347371639833902, 14.507692665977901],
            [39.348191352530598, 14.5073980645218],
            [39.3489740097219, 14.5070110597994],
            [39.349789230842298, 14.5070164952637],
            [39.350356292365397, 14.507038237119399],
            [39.351167021909298, 14.506328364426601],
            [39.351985611711903, 14.5059533159137],
            [39.352764900220897, 14.5059120061836],
            [39.353225286803998, 14.505371717688099],
            [39.354197713099097, 14.504461812353499],
            [39.354751299892897, 14.50384868407],
            [39.355478935272998, 14.5031409753772],
            [39.356249240629197, 14.5021886633373],
            [39.356847743187203, 14.5014602940595],
            [39.357588853296598, 14.50077540733],
            [39.358365896017403, 14.500134003552301],
            [39.359205820808, 14.4996774099011],
            [39.359812183624797, 14.498820750702301],
            [39.361569512899401, 14.4976172911314],
            [39.362313991691103, 14.497093289528699],
            [39.363347054267798, 14.496262711835501],
            [39.3640847956949, 14.4959811409423],
            [39.364932580744302, 14.495858293297699],
            [39.365681551112402, 14.496107249957999],
            [39.366059966425901, 14.496145300078],
            [39.366903259898798, 14.4957908901364],
            [39.367343434388097, 14.495412562334],
            [39.367920601958097, 14.4948113676873],
            [39.3684281500936, 14.494246047544401],
            [39.3691322046976, 14.493538308179099],
            [39.369845242454304, 14.4926577076569],
            [39.370452728165198, 14.4918358106799],
            [39.371082671758202, 14.490921501070799],
            [39.371675559845698, 14.4899506545162],
            [39.372190968239998, 14.4892005014113],
            [39.372663706658301, 14.488140498131701],
            [39.373311616556897, 14.4870304790094],
            [39.373840499680398, 14.486360768408799],
            [39.374264953652201, 14.485773682777101],
            [39.374332327298497, 14.4856693118354],
            [39.375488908226799, 14.4845473211103],
            [39.376064952902802, 14.483853684470199],
            [39.376319849864601, 14.483639504693199],
            [39.377005938162903, 14.4831622200807],
            [39.3774539729108, 14.483129603782601],
            [39.378174870926301, 14.4831828770669],
            [39.378648732238702, 14.4832535456896],
            [39.379081046469203, 14.483566661777701],
            [39.379287658984502, 14.483827591513499],
            [39.379776117920301, 14.484578849997],
            [39.379960272553497, 14.484898487422299],
            [39.380134321139799, 14.4852746587682],
            [39.3799344459891, 14.4858563097377],
            [39.379534695687703, 14.4865118883476],
            [39.379431389430003, 14.4872272604735],
            [39.379260709526001, 14.4875001449773],
            [39.379104627245397, 14.4876936644618],
            [39.3789968294113, 14.487806731947501],
            [39.378734072190703, 14.4880426514197],
            [39.378159150408898, 14.4885514538508],
            [39.3775707538978, 14.489208111672999],
            [39.377414671617103, 14.4893548809534],
            [39.377159774655297, 14.4897538754691],
            [39.377013798421601, 14.4900495875623],
            [39.376891402964098, 14.4903344275146],
            [39.376816169059097, 14.4905986108073],
            [39.376678053084198, 14.4910900126377],
            [39.376424279016398, 14.4914422557575],
            [39.376245738853697, 14.4915890235589],
            [39.375874060904898, 14.491892343373801],
            [39.375481047968101, 14.4921619606387],
            [39.375146425524697, 14.4924554951912],
            [39.374930829856602, 14.492637051553301],
            [39.374680424471102, 14.492828392229001],
            [39.374236881299602, 14.4932458622208],
            [39.373840499680398, 14.4936763773264],
            [39.373551915895398, 14.4939699098734],
            [39.3734441180613, 14.494094932877299],
            [39.373024155666002, 14.4945363178748],
            [39.372795085268599, 14.4948668124698],
            [39.372671566916999, 14.495128816058401],
            [39.372500887012997, 14.4955397588224],
            [39.372376245767299, 14.4958822105436],
            [39.372240375580603, 14.496258363253],
            [39.3721797392989, 14.4963725135154],
            [39.372566014871097, 14.4967193125233],
            [39.373457592790601, 14.4970074053304],
            [39.374068447183802, 14.4971933065278],
            [39.374528833766902, 14.497566195594001],
            [39.372083170405901, 14.498491349138799],
            [39.370388723201202, 14.4990468742643],
            [39.368319229365397, 14.499970934499199],
            [39.366627027949001, 14.5016309718514],
            [39.364747303217001, 14.5036617013479],
            [39.364562025689601, 14.505872870642699],
            [39.364565394371901, 14.5078992128905],
            [39.364569885948299, 14.5101103398719],
            [39.364949424155903, 14.511951841751699],
            [39.364763023734398, 14.5134259018745],
            [39.367779117300898, 14.515564141255499],
            [39.3682249062606, 14.516810985085],
            [39.368212554425398, 14.5178610678123],
            [39.368427027199502, 14.519010065666199],
            [39.368878430629799, 14.519657936943499],
            [39.369406190859202, 14.520568866839],
            [39.369363520883198, 14.5213428281942],
            [39.369106378133097, 14.5224918079648],
            [39.368870570371101, 14.5241581984837],
            [39.3684281500936, 14.5249756274684],
            [39.367967763510499, 14.526612650366401],
            [39.367411930928498, 14.527132234117801],
            [39.366852729664103, 14.5279768268572],
            [39.366248612635502, 14.528970333362899],
            [39.365559155654999, 14.5304051488831],
            [39.364783235828298, 14.5316453872189],
            [39.364091533059501, 14.532953010460901],
            [39.363603074123802, 14.534383452295801],
            [39.363540192053897, 14.5352856258957],
            [39.363988226801901, 14.536325838354299],
            [39.364923597591499, 14.5375280004668],
            [39.365677059535997, 14.5379269081493],
            [39.366970633545201, 14.5382551640672],
            [39.368101387909, 14.538622549251199],
            [39.369297270131, 14.5382269036431],
            [39.370333701390102, 14.538110601090599],
            [39.371486913636097, 14.537900821565399],
            [39.372728834516401, 14.5374116975464],
            [39.373546301424902, 14.5370127889337],
            [39.374101011112799, 14.5370117019883],
            [39.374780362046501, 14.536767139130101],
            [39.3759526634922, 14.536360620713401],
            [39.377078926279701, 14.535964971055],
            [39.378016542857502, 14.5356682333463],
            [39.379603192228103, 14.535310625834599],
            [39.381184227128102, 14.5352399738261],
            [39.382264451257299, 14.5354812775157],
            [39.383034756613398, 14.5356258422125],
            [39.383938686368097, 14.535860623774299],
            [39.384592210737303, 14.5360758399868],
            [39.385268192988597, 14.536187795812401],
            [39.385835254511697, 14.536198665306999],
            [39.386777362665903, 14.536096492036499],
            [39.387921591759003, 14.535447581971299],
            [39.3889984472059, 14.535586711928],
            [39.389556525576097, 14.5356769289625],
            [39.390153905240098, 14.5359986665205],
            [39.390425645613497, 14.5365464888523],
            [39.391138683370301, 14.5373040901169],
            [39.391834877715503, 14.5377845188412],
            [39.392989212855603, 14.538174730543],
            [39.394026767008697, 14.5386475488125],
            [39.395045231962101, 14.5387518947771],
            [39.396674551308699, 14.538726895227599],
            [39.397803059884303, 14.538482334267799],
            [39.399023645776602, 14.538108427210499],
            [39.4002610750805, 14.5374464797346],
            [39.4018174063103, 14.536811703937699],
            [39.402568622466603, 14.536599749284401],
            [39.403188460012601, 14.5362964907345],
            [39.404170992354601, 14.5358595368232],
            [39.4051265752381, 14.535272582448201],
            [39.406079912333396, 14.534651930848399],
            [39.407109606227799, 14.534167147475699],
            [39.4080157817707, 14.533500840342599],
            [39.408795070279602, 14.532953010460901],
            [39.409688893987301, 14.5322290903202],
            [39.410639985294402, 14.5314464712848],
            [39.411959385868002, 14.530759503403401],
            [39.413521331568198, 14.530367104652401],
            [39.4150046746811, 14.5301714485052],
            [39.416964124894598, 14.530094272977299],
            [39.417482901971198, 14.530577978306001],
            [39.418476663254197, 14.530694284822699],
            [39.419399682208699, 14.53074319876],
            [39.420958259226602, 14.530742111783701],
            [39.422660566689999, 14.5307855908303],
            [39.423871046535403, 14.531057334677399],
            [39.425024258781399, 14.530859505189801],
            [39.426019142958502, 14.5310225514836],
            [39.4271712323104, 14.531295382013001],
            [39.428164993593498, 14.531354078576801],
            [39.4291295596298, 14.531159510277501],
            [39.429627001718401, 14.530710589469599],
            [39.430137918536197, 14.5297986143676],
            [39.430299615287403, 14.529079031834399],
            [39.429814525033898, 14.5279942186943],
            [39.429621387247899, 14.5272713693084],
            [39.429148648829603, 14.526208287271601],
            [39.428173976746301, 14.525400645866201],
            [39.426101114228203, 14.524569087366901],
            [39.424498744340198, 14.524212548908899],
            [39.423705981101897, 14.523641868778199],
            [39.423009786756701, 14.523103797591901],
            [39.422101365425704, 14.522651599380699],
            [39.421471421832699, 14.5224461532534],
            [39.420284522763502, 14.5221385272138],
            [39.4191548912938, 14.5212863030184],
            [39.418837112261997, 14.520819969766499],
            [39.419290761480497, 14.5199557831693],
            [39.420050960789702, 14.5196275000804],
            [39.420971733955902, 14.5190644173564],
            [39.421630872795603, 14.518426327672501],
            [39.422675164313397, 14.5181371756936],
            [39.423241102942399, 14.5181143478897],
            [39.424291008930702, 14.5185632942674],
            [39.425130933721398, 14.519144857833201],
            [39.425836111219397, 14.520063399042799],
            [39.426274039920401, 14.521173252623299],
            [39.4273531411555, 14.521910253197699],
            [39.428179591216796, 14.5224461532534],
            [39.429220514052297, 14.5225374626669],
            [39.429861686586399, 14.5226842098596],
            [39.430309721334297, 14.522640729220001],
            [39.432160250819599, 14.521941776766299],
            [39.432757630483501, 14.521697197236801],
            [39.433288759395303, 14.5211776007165],
            [39.434053450280899, 14.519948173964201],
            [39.4345924394514, 14.518689390422701],
            [39.435101110481, 14.517686054370399],
            [39.4354480847595, 14.5168022887269],
            [39.4360353583765, 14.51614245156],
            [39.436405913431201, 14.5158228592101],
            [39.436759625074302, 14.5157913347697],
            [39.437147023540597, 14.516220719003901],
            [39.437544528053799, 14.517078397929501],
            [39.437935295202401, 14.518200223901699],
            [39.438363117856397, 14.518825269819899],
            [39.439079524295501, 14.5197438123563],
            [39.439817265722603, 14.520500384172999],
            [39.440823378840797, 14.521146077039001],
            [39.4426873830554, 14.5221320051023],
            [39.443870913442197, 14.522797259482401],
            [39.444536789646499, 14.524006017222099],
            [39.445704599515899, 14.5250071506001],
            [39.446728678939799, 14.525421298913299],
            [39.447792059657402, 14.525930015465001],
            [39.4491058457604, 14.5266148243596],
            [39.4504286150163, 14.527117016201],
            [39.4513561255471, 14.5274420271961],
            [39.452141028526597, 14.5276659475368],
            [39.452983199105503, 14.527809430354299],
            [39.453801788908102, 14.527478984937799],
            [39.454864046731601, 14.5268898343188],
            [39.455206529433703, 14.5258550124572],
            [39.455143647363798, 14.5246103936146],
            [39.455374963549502, 14.523335331414399],
            [39.455271657291803, 14.5224037595843],
            [39.455230110209897, 14.521608061518799],
            [39.454878644354999, 14.521176513693201],
            [39.454604658193297, 14.5211025960971],
            [39.453828738366703, 14.521271084699301],
            [39.453000042517097, 14.521703719361099],
            [39.452206156384698, 14.522114612804099],
            [39.451532419921598, 14.522107003673201],
            [39.451271908489197, 14.522066783976999],
            [39.450868789505499, 14.521983083504599],
            [39.4506520709432, 14.521779810797],
            [39.450707092754399, 14.5210862907417],
            [39.4509990452217, 14.520399290674799],
            [39.451231484301502, 14.519689460833099],
            [39.451353879758898, 14.519364438445599],
            [39.451612145403097, 14.518792658772201],
            [39.451909712340999, 14.5183241461893],
            [39.452165732196903, 14.5176262671381],
            [39.452235351631501, 14.517082746103201],
            [39.451878271306001, 14.516409865212401],
            [39.451603162250301, 14.5157011096221],
            [39.451113580420397, 14.5149499566645],
            [39.4510057825863, 14.5143401185965],
            [39.451017011527398, 14.5137748482984],
            [39.451063050185702, 14.51267800239],
            [39.4512292385132, 14.5116702908034],
            [39.451496487310301, 14.510913688797601],
            [39.451752507166198, 14.510250572844299],
            [39.452099481444698, 14.509885314684],
            [39.452504846216698, 14.5095407104505],
            [39.452984321999601, 14.508815626604999],
            [39.453286380513902, 14.508600383867],
            [39.453553629310903, 14.5083981859528],
            [39.453810772060997, 14.508254690546799],
            [39.4543834480546, 14.507444809428501],
            [39.4548539406847, 14.506857779652099],
            [39.455162736563601, 14.506434899830399],
            [39.4557129546751, 14.5061913902607],
            [39.456216011234197, 14.505995712733901],
            [39.456510209489799, 14.505919615871299],
            [39.457055936024901, 14.5060696353755],
            [39.4576645446299, 14.506309883790101],
            [39.4584685368091, 14.5068914795518],
            [39.458843583440299, 14.507841597192099],
            [39.459099603296202, 14.508723224445101],
            [39.459003034403203, 14.5091645802963],
            [39.458802036358399, 14.5101842611398],
            [39.458586440690198, 14.511088707595],
            [39.458297856905197, 14.5119605383004],
            [39.4591624853661, 14.513130220984999],
            [39.459942896769199, 14.5131704423057],
            [39.460330295235501, 14.5130573877641],
            [39.461256682872197, 14.5127834476739],
            [39.461954000111497, 14.5127671417054],
            [39.463266663320397, 14.5128878058435],
            [39.4634092708718, 14.512884544651399],
            [39.464014510794499, 14.5130443430056],
            [39.4643918032138, 14.513543304471099],
            [39.464537779447497, 14.514278156345799],
            [39.464799413774003, 14.514872775825401],
            [39.465160985675801, 14.5157532880252],
            [39.465326051109301, 14.516753371706301],
            [39.465867286067997, 14.517709969258901],
            [39.466155869852997, 14.5184643739573],
            [39.466836343680697, 14.518819834645599],
            [39.467862668892799, 14.51876222179],
            [39.4685083330033, 14.518586122025001],
            [39.469113572925998, 14.518179570180299],
            [39.469920933787598, 14.517883895642299],
            [39.470199411525599, 14.5176349634638],
            [39.4708686564123, 14.516940343371299],
            [39.4708068972365, 14.516260939762899],
            [39.470888868506201, 14.515208675337201],
            [39.4710988497039, 14.5145694874788],
            [39.471148257044497, 14.5141411870986],
            [39.470715942814003, 14.513273713230699],
            [39.470069155809398, 14.5123301413037],
            [39.4693359059588, 14.5117931296782],
            [39.468312949428999, 14.510927820755301],
            [39.467881758092602, 14.5106256140783],
            [39.467259674758402, 14.5097624747506],
            [39.4670856261721, 14.5093624291854],
            [39.467042956196103, 14.5085449425218],
            [39.467049693560703, 14.508290564407],
            [39.467230479511599, 14.507420893431],
            [39.467473024638302, 14.507173036577299],
            [39.467987310138497, 14.5069317020059],
            [39.468702593683503, 14.5067066735875],
            [39.469183192360497, 14.5065349126118],
            [39.470027608727499, 14.506260964450799],
            [39.470572212368502, 14.5057989479354],
            [39.4708136346012, 14.5055054310602],
            [39.471099972597997, 14.505106464906101],
            [39.471358238242203, 14.504535735507],
            [39.471433472147197, 14.5036790950961],
            [39.471378450335997, 14.502781141058],
            [39.471304339325101, 14.5015592219676],
            [39.471284127231201, 14.5001285679191],
            [39.471696229367801, 14.499024044492099],
            [39.471827607978099, 14.498547880135],
            [39.472281257196599, 14.4977520963226],
            [39.473007769682603, 14.4975172743237],
            [39.473678137463402, 14.497374859008699],
            [39.474559609335898, 14.497343331941799],
            [39.477346632504897, 14.498298926216799],
            [39.480361603177201, 14.49958282967],
            [39.483565220059198, 14.500680827567701],
            [39.485827851681101, 14.501782081379],
            [39.492432714807599, 14.5078481197248],
            [39.493754361169401, 14.509872269738599],
            [39.4939508676378, 14.513556349200201],
            [39.494897467368403, 14.5157652455741],
            [39.495470143361999, 14.519080722860799],
            [39.496046188038001, 14.5238690539971],
            [39.496425726245498, 14.524973453459101],
            [39.497188171342898, 14.529394257099501],
            [39.498330154647803, 14.534733452611601],
            [39.499088108168799, 14.536758443556799],
            [39.500034707899502, 14.5389681951092],
            [39.500303079590601, 14.5429017807836],
            [39.5004243521539, 14.5446777951295],
            [39.499304826731098, 14.548917781414101],
            [39.498183055520101, 14.552605557667601],
            [39.497059038520803, 14.555555299565301],
            [39.496121421943002, 14.5577670366912],
            [39.495187174047501, 14.560901470395001],
            [39.494905327627102, 14.5624393264004],
            [39.494815496098703, 14.562929481785501],
            [39.494255171940203, 14.5653259020756],
            [39.493319801150697, 14.567907051260001],
            [39.492952614778297, 14.572512833129499],
            [39.493336644562199, 14.575644883706],
            [39.493722920134402, 14.579696269625099],
            [39.492972826872197, 14.581540451467101],
            [39.489965716458599, 14.5843115854831],
            [39.487523421779898, 14.5876325534869],
            [39.485643697047898, 14.589294105391801],
            [39.484327665156599, 14.590402524149299],
            [39.482261540003201, 14.5933539335427],
            [39.481322800531203, 14.5946448910407],
            [39.477749751488702, 14.597601676769999],
            [39.476816626487299, 14.601471191884899],
            [39.477574580008302, 14.604048657578501],
            [39.478142764425499, 14.6053373790966],
            [39.4802212414141, 14.607728991509999],
            [39.481732656879601, 14.609568592078601],
            [39.482677010822101, 14.6110398268167],
            [39.483621364764502, 14.61214270312],
            [39.484383809861903, 14.615826161202],
            [39.486650933060197, 14.618953249125701],
            [39.487596409896703, 14.6204255075618],
            [39.489110071150499, 14.6231852864549],
            [39.491002147717602, 14.6268663863629],
            [39.489124668773798, 14.630002008973699],
            [39.488750745036803, 14.631292751212801],
            [39.488188175090102, 14.632399871715],
            [39.486497096567803, 14.634244697984901],
            [39.486233216453101, 14.6379549464892],
            [39.486600402825403, 14.639559623109401],
            [39.486421862662702, 14.6411653744213],
            [39.486972080774301, 14.6422344201965],
            [39.487705330624898, 14.6441943239157],
            [39.488987675692997, 14.646331294315001],
            [39.4913603509372, 14.647397055702699],
            [39.492273263844702, 14.6475730524977],
            [39.4922777554211, 14.6493569132718],
            [39.492826850638501, 14.650425919105301],
            [39.495749743994203, 14.6523814040885],
            [39.498426723540902, 14.656293408060399],
            [39.499723666232299, 14.6641204653786],
            [39.500470390812303, 14.6707045366211],
            [39.500764589067799, 14.6781628410028],
            [39.504539759049301, 14.6758382584038],
            [39.507604137062302, 14.6732822752885],
            [39.508545122322403, 14.6711955368035],
            [39.510906568625501, 14.670726262316199],
            [39.512798645192703, 14.671880979906],
            [39.514221352023903, 14.674426116727499],
            [39.5142258436003, 14.676047906352601],
            [39.515174689119199, 14.677667511454599],
            [39.5161224117439, 14.679055734855],
            [39.517069011474597, 14.679517387789501],
            [39.518486103835301, 14.6795141290663],
            [39.5196651426457, 14.6785843047352],
            [39.521077743429998, 14.6762640716438],
            [39.521309059615596, 14.674410908998301],
            [39.5224858526378, 14.6723230949995],
            [39.524139875654697, 14.672550126727501],
            [39.5248495447292, 14.6730117933903],
            [39.526266637089797, 14.673009620843599],
            [39.5283922756309, 14.6725403502464],
            [39.529097453128898, 14.6711488266566],
            [39.530985038119702, 14.670217880486099],
            [39.532637938242502, 14.6702146216244],
            [39.535471000069798, 14.669512878942699],
            [39.536889215324599, 14.6695096200705],
            [39.537833569267001, 14.669507447489],
            [39.539487592283898, 14.669503102326001],
            [39.541377423062798, 14.6694987571629],
            [39.542559830555597, 14.6699593439743],
            [39.543505307392103, 14.670188550729099],
            [39.544924645541002, 14.671111893045],
            [39.5458701223776, 14.6711097204794],
            [39.546339492113503, 14.670182033004799],
            [39.547755461580103, 14.669483549091201],
            [39.549408361702902, 14.669247823846],
            [39.550591892089699, 14.670172256418001],
            [39.553897692335298, 14.669469427309499],
            [39.554839800489503, 14.668308179216201],
            [39.556021085088098, 14.668306006622799],
            [39.557203492580797, 14.6689979765346],
            [39.559095569147999, 14.669688857969],
            [39.560986522821104, 14.670378650936099],
            [39.5616961918955, 14.6706089435372],
            [39.562640545838001, 14.6706067709666],
            [39.563347969124202, 14.669909374697299],
            [39.564763938590801, 14.669443356325401],
            [39.565945223189402, 14.669440097452201],
            [39.5680731075187, 14.6698985118095],
            [39.569017461461101, 14.6698963392318],
            [39.570198746059702, 14.6698930803654],
            [39.571143100002097, 14.6698909077877],
            [39.575159692216303, 14.6698811311878],
            [39.5784632466736, 14.6682506054835],
            [39.5791706699599, 14.6680170515067],
            [39.579878093246101, 14.6675521154064],
            [39.580822447188503, 14.667549942805501],
            [39.581767924025101, 14.6675477702046],
            [39.584360686513897, 14.665224161157299],
            [39.585305040456298, 14.664989517638601],
            [39.586955694790902, 14.6640596315913],
            [39.589314895305797, 14.6631264826276],
            [39.590730864772397, 14.662427976202601],
            [39.5919110264769, 14.661960855577099],
            [39.592853134631099, 14.6610320430012],
            [39.5942646125213, 14.658945187746699],
            [39.595913021067602, 14.6570875330788],
            [39.596619321459798, 14.655927305680301],
            [39.598033045138202, 14.6549962947945],
            [39.600153069208702, 14.652906122923],
            [39.600387754076699, 14.652209756660699],
            [39.600622438944598, 14.651514474565699],
            [39.602038408411197, 14.6512787299795],
            [39.603684571169403, 14.648262915115399],
            [39.605100540636002, 14.6477957642887],
            [39.606514264314399, 14.646634401291999],
            [39.607927987992703, 14.6457033509291],
            [39.609342834565197, 14.644773383025701],
            [39.610287188507698, 14.644307311179199],
            [39.611465104423999, 14.643145929703801],
            [39.612879950996501, 14.6424473595862],
            [39.617359175581903, 14.6394238181629],
            [39.617827422423701, 14.638032084225699],
            [39.6190064612341, 14.637333497820199],
            [39.622311138585601, 14.6363980621512],
            [39.6230163160836, 14.635470227710201],
            [39.623475579772602, 14.630835342584],
            [39.623709141746502, 14.6299074846225],
            [39.624413196350403, 14.628052843429],
            [39.625118373848501, 14.627123887205],
            [39.625824674240597, 14.6261949270502],
            [39.625820182664199, 14.624805282241001],
            [39.625107144907403, 14.622953857509801],
            [39.625573145961098, 14.6211002441083],
            [39.625567531490503, 14.6190151819672],
            [39.625327232152003, 14.6176254917269],
            [39.626743201618602, 14.6171582756761],
            [39.6272114484605, 14.6157674871324],
            [39.627197973731199, 14.6109018310671],
            [39.626718497948303, 14.608586314659201],
            [39.629787367537702, 14.608114733011099],
            [39.631439144766397, 14.6074149648305],
            [39.633556923048701, 14.604628909179899],
            [39.633782624763803, 14.601153896380801],
            [39.636845879882699, 14.598828497552599],
            [39.637417432982197, 14.5985470574287],
            [39.638732341979299, 14.5978961578476],
            [39.642033650648401, 14.596266183836301],
            [39.6439201127451, 14.595333833272701],
            [39.646750928784201, 14.594399305433299],
            [39.648169144039002, 14.594858963227001],
            [39.649349305743499, 14.5943916987951],
            [39.649818675479402, 14.5934636869579],
            [39.650995468501598, 14.5923020366487],
            [39.652174507311997, 14.591371842644699],
            [39.655475815981198, 14.590204747878101],
            [39.657836139390199, 14.5895027493491],
            [39.659489039512998, 14.589498402602],
            [39.660669201217502, 14.5892636781315],
            [39.663269823965003, 14.5904144776545],
            [39.664453354351799, 14.591569617867099],
            [39.6649339530289, 14.5943482322858],
            [39.665643622103303, 14.594577518025201],
            [39.667998331041801, 14.5922542230341],
            [39.668938193407797, 14.5908611036108],
            [39.670820163927999, 14.588307390664999],
            [39.671526464320202, 14.587610819566001],
            [39.672942433786801, 14.587375006385599],
            [39.674597579697803, 14.5880650580678],
            [39.675779987190502, 14.5887561942774],
            [39.677198202445297, 14.5889843991531],
            [39.680267072034702, 14.5887431539917],
            [39.684038873333897, 14.586878385175201],
            [39.684272435307697, 14.5859514283656],
            [39.684265697943097, 14.583866032954599],
            [39.685204437415003, 14.5820099166242],
            [39.685201068732702, 14.580852552797699],
            [39.685670438468598, 14.5796930094196],
            [39.689207554899902, 14.5780607271417],
            [39.689910486609698, 14.5764371267801],
            [39.696268313033002, 14.5710902458395],
            [39.696262698562499, 14.5692361954532],
            [39.697671930664498, 14.566683318613601],
            [39.6974271397496, 14.5639043552224],
            [39.698606178559999, 14.563437025127101],
            [39.699311356057997, 14.562508882885099],
            [39.6997773571116, 14.5606536735127],
            [39.700009796191402, 14.5594951107116],
            [39.704014036570399, 14.556471515718],
            [39.705662445116701, 14.5550759963613],
            [39.708013785372898, 14.552056688299301],
            [39.710606547861701, 14.550658967269399],
            [39.713440732583102, 14.5511132819188],
            [39.714624262969899, 14.551805620866],
            [39.7174584476913, 14.552028429641499],
            [39.720991072546099, 14.549468832355601],
            [39.723349150166896, 14.5485351969139],
            [39.727828374752399, 14.5468994233213],
            [39.729717082637201, 14.5466613927985],
            [39.732550144464497, 14.5466526976147],
            [39.733732551957303, 14.5471124549872],
            [39.735385452080003, 14.5471070205085],
            [39.736329806022503, 14.5471037598212],
            [39.739868045347798, 14.546398363337801],
            [39.741048207052302, 14.5463940157408],
            [39.742464176518901, 14.546158158474],
            [39.744351761509698, 14.545688616990001],
            [39.750252570032202, 14.5452060316467],
            [39.753794178039897, 14.5451951625953],
            [39.7563869405286, 14.544259335263099],
            [39.7570909951326, 14.5428680863709],
            [39.7587438952554, 14.542862651787701],
            [39.760159864721899, 14.5428572172044],
            [39.762048572606801, 14.542851782621],
            [39.766293112324298, 14.5414474817795],
            [39.767232974690302, 14.540055128245401],
            [39.766980323516599, 14.5354225820479],
            [39.7665053393101, 14.534497582894501],
            [39.766030355103702, 14.533804102729],
            [39.7686219946984, 14.5321736548964],
            [39.770509579689097, 14.531936695477],
            [39.772160234023701, 14.531235598459601],
            [39.774039958755701, 14.5286811951687],
            [39.774270152047301, 14.5270583185122],
            [39.775208891519199, 14.5256658743246],
            [39.776381192964898, 14.5231135807161],
            [39.777321055330901, 14.5219515599419],
            [39.779204148745301, 14.520323198764],
            [39.780142888217199, 14.518930712174701],
            [39.780140642428997, 14.5180034699516],
            [39.780135027958501, 14.5166142298996],
            [39.782259543605399, 14.5163750797149],
            [39.784613129649799, 14.5145140476286],
            [39.784369461628998, 14.512661696413801],
            [39.783422861898302, 14.511737689132501],
            [39.786718556096901, 14.509178712365699],
            [39.788837457273402, 14.507780720211599],
            [39.790726165158198, 14.507775284766099],
            [39.7918894834512, 14.5068153829969],
            [39.793550243832698, 14.5054489018396],
            [39.792600275419701, 14.503830203226601],
            [39.791650307006798, 14.5019799368637],
            [39.791171954117999, 14.500361212899501],
            [39.792819239770203, 14.498965339352701],
            [39.794709070549203, 14.4994219344714],
            [39.797779063032699, 14.4996437089038],
            [39.799195032499298, 14.499638273258601],
            [39.801557601696501, 14.5000937798637],
            [39.802732148930502, 14.4989316382471],
            [39.803672011296499, 14.4977694905349],
            [39.8057740690613, 14.495367989188299],
            [39.806511810488402, 14.494151464994999],
            [39.807002515212403, 14.4926946712059],
            [39.807493219936298, 14.491236780672301],
            [39.809462776196703, 14.487832824436],
            [39.8106979597124, 14.487101146406101],
            [39.812668638866903, 14.483940660970401],
            [39.813647802526603, 14.480782304943],
            [39.816863771243803, 14.4795580901358],
            [39.818100077653597, 14.478825297578901],
            [39.819338629851501, 14.4788209486545],
            [39.821815734247501, 14.4788122508053],
            [39.824790280732003, 14.479287370318801],
            [39.826530766594999, 14.4809790919506],
            [39.827281982751401, 14.483403580537001],
            [39.828770940334799, 14.483884126249199],
            [39.830510303303697, 14.4855758128251],
            [39.832492211399298, 14.485569289636899],
            [39.833483726894102, 14.485566028042699],
            [39.8352163524983, 14.4850735267696],
            [39.839658521578301, 14.4806909783167],
            [39.841633692309301, 14.478985120494301],
            [39.842869998719003, 14.4784947790759],
            [39.845831070474297, 14.475329822035199],
            [39.847806241205298, 14.4736250102569],
            [39.854491952707299, 14.4731150863328],
            [39.858694945342897, 14.470916637581301],
            [39.861157452115499, 14.4672677299806],
            [39.862632934969703, 14.4643516051755],
            [39.865353707386397, 14.4633708566114],
            [39.868078971379603, 14.463602452838],
            [39.871545345482197, 14.463104466286801],
            [39.873517147530897, 14.460671057433199],
            [39.876711781259999, 14.454350376325699],
            [39.879681836168203, 14.4536120638141],
            [39.881402109937198, 14.450208618027901],
            [39.888073223815901, 14.446301659005499],
            [39.889799112055599, 14.444110556761601],
            [39.892019073701398, 14.4419183455229],
            [39.895214830324697, 14.435839630942199],
            [39.8994122084897, 14.4326697165986],
            [39.901138096729298, 14.430964577622801],
            [39.910520999871999, 14.423892678841399],
            [39.912246888111603, 14.4219449582256],
            [39.922620183855003, 14.415353466959299],
            [39.924846882865502, 14.414617200180301],
            [39.931028414914302, 14.412651997623399],
            [39.933005831433498, 14.4114306704274],
            [39.938441761796497, 14.408740029297601],
            [39.943147810991199, 14.408964069369601],
            [39.946123480369799, 14.4099222187885],
            [39.949841382751998, 14.4106356622198],
            [39.954064587481497, 14.4137721760794],
            [39.954809066273199, 14.4142539598396],
            [39.957048117118802, 14.416671563302],
            [39.958046369978298, 14.4183659364238],
            [39.958789725875903, 14.418605192180999],
            [39.962266206025497, 14.420774795161799],
            [39.965490035001402, 14.421731806303701],
            [39.966733078775803, 14.4229411145038],
            [39.968714986871298, 14.423174927831999],
            [39.970705878119801, 14.4251074127809],
            [39.971953413470601, 14.427529248411901],
            [39.9699849801043, 14.4302066130564],
            [39.971477306369998, 14.4318987083093],
            [39.9729673868476, 14.4326196935748],
            [39.974707872710503, 14.434311770487399],
            [39.975454597290501, 14.4352785104563],
            [39.977448857221198, 14.4381819674594],
            [39.979934944770001, 14.4403546419655],
            [39.980631139115197, 14.441608432965101],
            [39.981916852865602, 14.440345942610399],
            [39.985105872124201, 14.4335396804324],
            [39.983362017578898, 14.431121172563101],
            [39.983824649950201, 14.423597966206801],
            [39.981061207557502, 14.414874948206799],
            [39.979313984329799, 14.411727574270399],
            [39.979550914986, 14.409300129055699],
            [39.979795705900997, 14.4085714553086],
            [39.981254345343601, 14.402256908184],
            [39.980993833911199, 14.399347544982399],
            [39.981469941011703, 14.3949774470398],
            [39.981953908371104, 14.392548731928899],
            [39.9849138572322, 14.389867652101399],
            [39.987134941772197, 14.3881600104414],
            [39.990101627998001, 14.387176750976201],
            [39.991574865064003, 14.3845021328101],
            [39.9915658819111, 14.382318036966099],
            [39.990568751945801, 14.380867037186899],
            [39.989574990662703, 14.380142621477001],
            [39.987838996376098, 14.3794225542851],
            [39.985853719598197, 14.3784599230708],
            [39.9841132337353, 14.3767685101603],
            [39.9808770529242, 14.3726579427397],
            [39.9786335105021, 14.369269577500701],
            [39.973440125265803, 14.3707478465299],
            [39.970959652187503, 14.3695447821147],
            [39.968231019511997, 14.368342798998199],
            [39.965996460242799, 14.366896059622],
            [39.958306881410699, 14.363531538560499],
            [39.956321604632798, 14.3625688389372],
            [39.954830401261198, 14.361118798805601],
            [39.951855854776603, 14.3604019337844],
            [39.948637640271301, 14.3604149874927],
            [39.944678315656503, 14.3609164668774],
            [39.938491169137102, 14.3614266475712],
            [39.9337952259894, 14.363386861932399],
            [39.928850000350302, 14.364861906476801],
            [39.924892921523799, 14.3658474396687],
            [39.920939211379597, 14.3678032642046],
            [39.917979262518401, 14.370484608757099],
            [39.914510642627597, 14.3700136081672],
            [39.910286315004001, 14.366147667460501],
            [39.9075588052226, 14.365187154665801],
            [39.905078332144299, 14.363982972550399],
            [39.9021094001303, 14.3647226696493],
            [39.901622064088698, 14.3664228759156],
            [39.900640654640803, 14.368852962757],
            [39.895448392298597, 14.370571629542701],
            [39.891230802039601, 14.368403714135001],
            [39.8885133983052, 14.369870023494499],
            [39.884802233287601, 14.3706107888852],
            [39.883563681089697, 14.370373657206301],
            [39.879344967936603, 14.368205739879601],
            [39.877121637608397, 14.3691847317412],
            [39.8758830854104, 14.369189082806599],
            [39.874894938597897, 14.3699200605879],
            [39.862022080576502, 14.370210493061],
            [39.863490826065998, 14.3660802250206],
            [39.853338740461297, 14.3656320586134],
            [39.8528491586315, 14.3670896839305],
            [39.853101809805203, 14.3685451242102],
            [39.854605365011999, 14.3731485172079],
            [39.849410856881498, 14.3746234973739],
            [39.846443047761603, 14.375363159280401],
            [39.845699691863999, 14.375365334753001],
            [39.8439670662598, 14.375613338489],
            [39.8380437998551, 14.3804874262444],
            [39.829135880918997, 14.3824605259416],
            [39.828393647915497, 14.3824627013451],
            [39.827652537806102, 14.3827085218044],
            [39.826662145205297, 14.382954341993299],
            [39.8259187893077, 14.382956517392],
            [39.8236887216149, 14.382236459267499],
            [39.822198641137298, 14.381270577051801],
            [39.821204879854299, 14.380304690661101],
            [39.819967450550401, 14.380309041509999],
            [39.820217855935901, 14.381035632097801],
            [39.818495336378597, 14.3839528477674],
            [39.808840692862503, 14.3847142326137],
            [39.800925412315301, 14.3871680495457],
            [39.795968957735198, 14.385971599621699],
            [39.7882681499621, 14.378960274276],
            [39.787028474869999, 14.3784795020521],
            [39.777652309091998, 14.375152138338899],
            [39.773600907160599, 14.371888902548999],
            [39.774222990494799, 14.3647912002862],
            [39.774294855717599, 14.364000377216099],
            [39.773995042991501, 14.3636381423318],
            [39.773058549307798, 14.363323769839701],
            [39.772208518470201, 14.362815769066],
            [39.7717110763816, 14.3623121183381],
            [39.771064289377101, 14.361589818123299],
            [39.770736404298297, 14.360826178915],
            [39.774850688299601, 14.354053390088501],
            [39.776181317814199, 14.352743627005699],
            [39.774075891367097, 14.3512717661603],
            [39.773950127227302, 14.3503971302995],
            [39.773966970638902, 14.3500870906483],
            [39.7739647248507, 14.349539896813001],
            [39.773963601956503, 14.349030776895001],
            [39.774501468232899, 14.347214036131],
            [39.779209763215803, 14.338797003606],
            [39.779853181538101, 14.3376949440177],
            [39.7800081409246, 14.3369399267202],
            [39.7799295383372, 14.3360989606561],
            [39.7796443232345, 14.3349348756465],
            [39.779455677024799, 14.334253828716999],
            [39.779601653258503, 14.3340264499685],
            [39.779762227115498, 14.333558636507],
            [39.778751622420899, 14.332197620165401],
            [39.7784933567767, 14.3314806625917],
            [39.7791974113807, 14.3302980583686],
            [39.779343387614297, 14.33003694896],
            [39.779701590833902, 14.329768223543001],
            [39.779602776152601, 14.329352623276201],
            [39.774739521783196, 14.3227333780696],
            [39.773719933935702, 14.322509251622099],
            [39.772261294493099, 14.322449411998701],
            [39.771403403396803, 14.322307972825399],
            [39.7712641645278, 14.321536583765701],
            [39.7714775144077, 14.3207815120759],
            [39.771908705744103, 14.3197446443627],
            [39.770442206042802, 14.3181115408014],
            [39.770012137600503, 14.317816688725699],
            [39.762613388341698, 14.3186860116299],
            [39.752033480083, 14.3150781363699],
            [39.7515798308645, 14.314129373055099],
            [39.751593305593801, 14.313371012339299],
            [39.751231733691903, 14.312561511130999],
            [39.7507589952736, 14.3120251061692],
            [39.750361490760397, 14.311822729927799],
            [39.750209900056198, 14.3117737679065],
            [39.750011147799597, 14.3116442905099],
            [39.749837099213302, 14.311502844529199],
            [39.749444086276498, 14.3110948267779],
            [39.748843337930303, 14.3111296443216],
            [39.748417761064402, 14.311203631584],
            [39.747567730226798, 14.3106835441345],
            [39.747269040394798, 14.3102874934697],
            [39.747000668703699, 14.3095127989417],
            [39.746748017530003, 14.3091069536913],
            [39.746215765724202, 14.308603182293499],
            [39.745949639821298, 14.308369249186899],
            [39.7450793968898, 14.308284380371701],
            [39.744602166895099, 14.3086673778476],
            [39.7438251241743, 14.3086880509883],
            [39.743050327241797, 14.3078817970936],
            [39.733241847233302, 14.3065837331245],
            [39.7271063538428, 14.304673073772699],
            [39.7253434100977, 14.3012303640922],
            [39.726885143704102, 14.296078170031199],
            [39.726974975232501, 14.2950694795696],
            [39.726897495539198, 14.2947930954171],
            [39.726619017801099, 14.2925820099682],
            [39.726696497494402, 14.291561334253201],
            [39.7334316163371, 14.288270766084899],
            [39.7340671744006, 14.2876037237468],
            [39.741678150645299, 14.281478376285801],
            [39.742299111085401, 14.2810420110095],
            [39.752096362152898, 14.278249688493499],
            [39.756556497538497, 14.2777545536449],
            [39.7578365968184, 14.2772485355868],
            [39.758356496789098, 14.276499844416801],
            [39.757009023862899, 14.2637510364702],
            [39.755890621334203, 14.2500307069856],
            [39.757652442185098, 14.2412846089083],
            [39.7576030348445, 14.240756741223301],
            [39.757495237010403, 14.2398392280626],
            [39.757390807858599, 14.239450671543199],
            [39.757242585836799, 14.2392014290541],
            [39.756304969258998, 14.2384667607278],
            [39.7550810146844, 14.2376112772643],
            [39.754393803492, 14.2374327790337],
            [39.753718944134803, 14.2372542806621],
            [39.753246205716501, 14.2372085676417],
            [39.752426493019797, 14.236929935698701],
            [39.751594428487898, 14.236617562760101],
            [39.7507241855564, 14.2362137629553],
            [39.742650576940299, 14.234839096751401],
            [39.741737664032897, 14.235024127527399],
            [39.732706226745201, 14.236939731358801],
            [39.720194940625603, 14.239838139669899],
            [39.719106856237701, 14.2400590832759],
            [39.7176953783475, 14.2400841162817],
            [39.716050338483498, 14.2401929554049],
            [39.706451839672603, 14.2420530079097],
            [39.694065194798704, 14.241300934693401],
            [39.687875802491199, 14.231334367543701],
            [39.687221155227903, 14.219332979288],
            [39.687056089794403, 14.2184404145277],
            [39.68692358829, 14.2179146704652],
            [39.686815790455903, 14.217387836679601],
            [39.6865249608827, 14.216682487044899],
            [39.6860713116642, 14.2159923743165],
            [39.685574992469697, 14.215463359032601],
            [39.685139309556902, 14.215025777554001],
            [39.684535192528301, 14.214442334265801],
            [39.683752535337, 14.2137837817313],
            [39.6830821675563, 14.213362524807099],
            [39.682173746225203, 14.212845477122301],
            [39.681166510212897, 14.2121455559527],
            [39.6805500413492, 14.211505501727901],
            [39.680003191920001, 14.210851294773001],
            [39.6794642027495, 14.210094763573199],
            [39.679196953952498, 14.209803035758499],
            [39.678535569324502, 14.2092315529418],
            [39.6781863492578, 14.20897574588],
            [39.676276306384999, 14.207090384488099],
            [39.672247362335703, 14.200324942815699],
            [39.665538070057401, 14.196729324929301],
            [39.664245618942402, 14.196359200562],
            [39.661821290569399, 14.197638304264601],
            [39.659410436925597, 14.199433391660101],
            [39.657310624948998, 14.197909364461999],
            [39.656408940982601, 14.192813596196199],
            [39.6499399480428, 14.188602778739799],
            [39.655656601932101, 14.1828274739911],
            [39.656797462342901, 14.182788281976499],
            [39.657520606146697, 14.182574903111499],
            [39.657997836141298, 14.1823560806996],
            [39.658299894655599, 14.182233061041901],
            [39.658660343663399, 14.182087179237399],
            [39.659290287256397, 14.181886863770901],
            [39.660358159550398, 14.1817801739393],
            [39.661616923842203, 14.1818073907378],
            [39.662310872399203, 14.1817899719872],
            [39.662719605853503, 14.181653887951301],
            [39.663172132177898, 14.181457926796201],
            [39.664328713106201, 14.1811302358196],
            [39.665321351495102, 14.1808537121686],
            [39.665952417982197, 14.1807110955081],
            [39.666968637147399, 14.1804672316976],
            [39.667562648129, 14.180223367624899],
            [39.669903882338303, 14.1793502449997],
            [39.6705169825197, 14.1789888017321],
            [39.6707853542108, 14.178890820024201],
            [39.671182858724002, 14.1787884835285],
            [39.671452353309199, 14.1787590889946],
            [39.672532577438403, 14.1782724456019],
            [39.672976120609903, 14.1781461576604],
            [39.673488160321902, 14.177915355378801],
            [39.674503256592899, 14.177602900972399],
            [39.674862582706602, 14.1774102023957],
            [39.675431890017897, 14.177178310671099],
            [39.6756160446511, 14.177034603004399],
            [39.676043867305196, 14.1762888458478],
            [39.6764301428774, 14.1757847779429],
            [39.676754659273797, 14.1751783707934],
            [39.6819761168627, 14.164676491888001],
            [39.691658832731399, 14.161275198656799],
            [39.692240491877897, 14.1610422016733],
            [39.692679543472998, 14.1611804756129],
            [39.692896262035298, 14.1613481462614],
            [39.693160142149999, 14.161560456320499],
            [39.693920341459197, 14.161852246231801],
            [39.694073055057501, 14.1618370034845],
            [39.694281913361003, 14.1617161502377],
            [39.694432381171097, 14.161643202751399],
            [39.694706367332799, 14.1613372585607],
            [39.695038743987901, 14.1609964732652],
            [39.695723709392098, 14.1606687527383],
            [39.6963547758792, 14.1605032591363],
            [39.696619778888, 14.1603247001147],
            [39.697208175399098, 14.159884834609],
            [39.697392330032301, 14.159730228214],
            [39.697905492638398, 14.159142287440901],
            [39.6981693727531, 14.158907110705499],
            [39.698662323265197, 14.1592849176572],
            [39.699479790173797, 14.1599436285625],
            [39.700270307623803, 14.160476039782299],
            [39.700860949923097, 14.1605424550003],
            [39.7015605129506, 14.1599033438182],
            [39.701715472337099, 14.159520094001399],
            [39.702100625015198, 14.158981147852201],
            [39.702632876820999, 14.1586458029361],
            [39.703847848242802, 14.158800410069301],
            [39.704763006938499, 14.1586849991207],
            [39.705286275591497, 14.1584650648864],
            [39.705830879232501, 14.1581994014679],
            [39.706495632542698, 14.1579522471069],
            [39.707101995359501, 14.157764975782101],
            [39.714348031020101, 14.1560337975032],
            [39.715107107435102, 14.156289664306099],
            [39.715597812159103, 14.1565879937465],
            [39.720914715747, 14.1570082674028],
            [39.731466551653099, 14.1553435000951],
            [39.739976966076, 14.1538202654285],
            [39.749519320181498, 14.1517885441099],
            [39.757982573052097, 14.145137959507499],
            [39.767110579232799, 14.1400333840815],
            [39.775398660622898, 14.139054485896899],
            [39.784777072189101, 14.140934968448599],
            [39.785240827454601, 14.141096120833399],
            [39.786666902968101, 14.141635109398599],
            [39.788930657484102, 14.1422579390384],
            [39.789958105590301, 14.142415823903301],
            [39.7905498707837, 14.142550842597499],
            [39.791554861007803, 14.1427217935709],
            [39.792507075209002, 14.1427196158523],
            [39.797782431715, 14.1425181767917],
            [39.806219858021102, 14.149056688687301],
            [39.806652172251503, 14.149826489453099],
            [39.806854293190497, 14.1503055720645],
            [39.807460656007301, 14.1509806413028],
            [39.808131023788, 14.1514118135087],
            [39.808920418344002, 14.1518397184498],
            [39.810478995361898, 14.1525136943182],
            [39.811084235284603, 14.152717302143699],
            [39.812017360285999, 14.152831627313301],
            [39.813132394132403, 14.1527456112387],
            [39.814372069224497, 14.152933975511701],
            [39.815601638269598, 14.1531669808125],
            [39.816926653313701, 14.153432650116301],
            [39.817529647448097, 14.1535317316213],
            [39.818340376992097, 14.153523021161099],
            [39.818810869622098, 14.1535121330853],
            [39.819761960929199, 14.15344136058],
            [39.820467138427198, 14.1534348277322],
            [39.821234075101003, 14.1535186659308],
            [39.822116669867697, 14.1535437085036],
            [39.822554598568701, 14.1536351683113],
            [39.824518540358604, 14.1540456481852],
            [39.8267744346158, 14.1548056328374],
            [39.827598638889, 14.155267284164401],
            [39.8283509779395, 14.155650541157099],
            [39.836064137547702, 14.1559619369875],
            [39.8364156034026, 14.1559303619053],
            [39.837337499462997, 14.155675583494901],
            [39.837840556022101, 14.155549282980401],
            [39.837949476750303, 14.155246596978801],
            [39.838158335053798, 14.1547130846991],
            [39.838129139807101, 14.154092466797399],
            [39.837958459903099, 14.153440271772],
            [39.8379854093616, 14.152727101446199],
            [39.837997761196803, 14.1519344453888],
            [39.837791148681397, 14.150799897154901],
            [39.837091585653901, 14.141733107182199],
            [39.837553095131199, 14.128681608525101],
            [39.837884348892203, 14.127488141958599],
            [39.838093207195698, 14.1265287906658],
            [39.838471622509203, 14.125346202237701],
            [39.838651285566002, 14.124616609627999],
            [39.841226081749099, 14.1160987152068],
            [39.841456275040699, 14.1154834369109],
            [39.841560704192403, 14.1150336837603],
            [39.841902064000401, 14.1137356018919],
            [39.842633068062902, 14.11214239231],
            [39.842819468484301, 14.1112058455943],
            [39.842902562648099, 14.110800733496999],
            [39.842849786625102, 14.1101810848368],
            [39.842884596342401, 14.109317494351201],
            [39.843222587468098, 14.108792586555699],
            [39.843502188100203, 14.1082905470562],
            [39.843524645982299, 14.1073812102236],
            [39.843430322877502, 14.1060961531859],
            [39.842840803472299, 14.103926786227801],
            [39.841567441557103, 14.1021178756107],
            [39.841485470287402, 14.101718192642],
            [39.841638183885699, 14.1008393232693],
            [39.841862762706697, 14.098787525827699],
            [39.841827952989497, 14.0979478518079],
            [39.841825707201302, 14.097016692179899],
            [39.8419503484469, 14.096025629219699],
            [39.842168189903298, 14.095364555159399],
            [39.842351221642502, 14.094324475948699],
            [39.842487091829199, 14.0937352771407],
            [39.842841926366397, 14.092943504812601],
            [39.842923897636098, 14.0925274692835],
            [39.8427958877081, 14.0917139107039],
            [39.8425724317812, 14.091270645304199],
            [39.842199630938303, 14.0909820322348],
            [39.842182787526703, 14.0903950030168],
            [39.842318657713399, 14.0897818335945],
            [39.842598258345603, 14.0892808413696],
            [39.843140616198397, 14.088898561780301],
            [39.843837933437698, 14.088606678358],
            [39.8441736787751, 14.087562211860799],
            [39.844440927572101, 14.087026362752299],
            [39.846992142978998, 14.0797966333594],
            [39.847951094544797, 14.078357848521099],
            [39.848303683293899, 14.077913468026599],
            [39.8487674385593, 14.077245806492],
            [39.849215473307197, 14.076440906877099],
            [39.8470460418961, 14.0715744168836],
            [39.844693578745797, 14.0605242244878],
            [39.844223086115697, 14.0596735172628],
            [39.843140616198397, 14.05200611699],
            [39.844411732325398, 14.0499473452301],
            [39.844789024744699, 14.047913609129999],
            [39.846608113195103, 14.0463330122522],
            [39.846574426371902, 14.043927782565],
            [39.847997133203201, 14.0423351754586],
            [39.849157082813797, 14.0400301239498],
            [39.849717406972204, 14.037220674899199],
            [39.854707548375501, 14.030970916172199],
            [39.8596280703443, 14.029030700184601],
            [39.861650402627703, 14.027411843737299],
            [39.864446408949497, 14.0244072333155],
            [39.865464873902901, 14.022465872366601],
            [39.866586645113898, 14.020411192996701],
            [39.868711160760803, 14.0190722642481],
            [39.8810405380354, 14.0163159420675],
            [39.883516519537203, 14.0157842840204],
            [39.885652264125198, 14.014457312456701],
            [39.886640410937801, 14.0121803072527],
            [39.885450143186297, 14.0099414116962],
            [39.880403857077802, 14.0037464754959],
            [39.877862747717799, 14.002505507057901],
            [39.875585518472597, 14.000478977024899],
            [39.872434677613498, 13.997871700001401],
            [39.869156949720598, 13.994475549303701],
            [39.867775789971297, 13.9899875850475],
            [39.8688672430415, 13.985333912720099],
            [39.869202988378902, 13.9804927296631],
            [39.858571426991297, 13.973176825651899],
            [39.859141857196803, 13.9657626160769],
            [39.850121648850099, 13.969963376997001],
            [39.847787152005502, 13.9705234726647],
            [39.845776048663197, 13.971176189437999],
            [39.838646793989597, 13.975854120447501],
            [39.836602003824098, 13.975780023981599],
            [39.8336184741867, 13.9754553068348],
            [39.830300322105998, 13.974623898092901],
            [39.828056779683898, 13.9739210666049],
            [39.825028334282301, 13.9728455668259],
            [39.822396270499901, 13.9716011626367],
            [39.820007874738202, 13.9702957296489],
            [39.815628587728099, 13.968229687027099],
            [39.813922911582402, 13.963693263665499],
            [39.813556848104099, 13.9611618504783],
            [39.812640566514297, 13.958736113777199],
            [39.810851796204801, 13.9572333647133],
            [39.809136014012097, 13.9552282310552],
            [39.807403388407899, 13.953727638643199],
            [39.805802141413899, 13.952198702561899],
            [39.801635081389698, 13.9455913590386],
            [39.811519918197398, 13.9450551783781],
            [39.813591657821398, 13.9446465032575],
            [39.8158722557489, 13.944234558003499],
            [39.817412866461197, 13.9425050337929],
            [39.819546365260997, 13.9409727538784],
            [39.826526275018601, 13.932598435603101],
            [39.8285227807376, 13.932301993992301],
            [39.828640684618598, 13.929947885340299],
            [39.827168570446702, 13.927835706325499],
            [39.825157467104397, 13.927165428762899],
            [39.824425340147897, 13.924677210658199],
            [39.826069257117801, 13.9213671742224],
            [39.833255779390697, 13.918392787581499],
            [39.833489341364597, 13.907832261543099],
            [39.828830453722396, 13.900487893928201],
            [39.8299488562511, 13.8987329719534],
            [39.829620971172403, 13.890716842468599],
            [39.827751352487297, 13.889843706362999],
            [39.826538626853697, 13.885947800551801],
            [39.828724901676502, 13.885255600025999],
            [39.830020721473801, 13.8831288418556],
            [39.830906684922802, 13.8812974814099],
            [39.828892212898097, 13.8805583925613],
            [39.827141620988201, 13.8789068802045],
            [39.828682231700498, 13.8692199511494],
            [39.829466011785897, 13.8692995325654],
            [39.829464888891799, 13.869153451589],
            [39.829395269457301, 13.8688787318917],
            [39.829070753060897, 13.868395791317999],
            [39.828961832332702, 13.868319480074501],
            [39.828724901676502, 13.8679935217659],
            [39.828630578571598, 13.867664292508],
            [39.828601383324902, 13.8675040383624],
            [39.837484598590699, 13.864608543039701],
            [39.8376350664008, 13.8645573046572],
            [39.837831572869199, 13.8644232126664],
            [39.837921404397598, 13.864316375089899],
            [39.838134754277597, 13.864043830029001],
            [39.837995515408601, 13.8637843668338],
            [39.837767567905203, 13.863665536954301],
            [39.833502816093898, 13.856583602397899],
            [39.834143988627901, 13.8570087862128],
            [39.834924400030999, 13.8564625884006],
            [39.834803127467701, 13.855731051993301],
            [39.835014231559398, 13.855079099572601],
            [39.835309552709099, 13.853561504242601],
            [39.836709801658202, 13.8533303753483],
            [39.837714791882298, 13.8534633835137],
            [39.838453656203498, 13.853184284324801],
            [39.838431198321402, 13.8528975531684],
            [39.838165072418498, 13.8524123988849],
            [39.838048291431498, 13.852106042512199],
            [39.837932633338703, 13.8515467502988],
            [39.8379135441389, 13.851339604693001],
            [39.837866382586498, 13.851053961502],
            [39.838023587761199, 13.850829371570899],
            [39.838349227051701, 13.8507257984714],
            [39.8385041864382, 13.8507672277167],
            [39.838572682978601, 13.8507181667674],
            [39.838869127022399, 13.850431432567801],
            [39.839072370855398, 13.8498950315182],
            [39.8394799814156, 13.8497772847806],
            [39.839618097390499, 13.8496791624537],
            [39.839842676211603, 13.8493728024775],
            [39.839870748564202, 13.849188550197701],
            [39.839906681175599, 13.848898543354901],
            [39.840059394773903, 13.8488821895748],
            [39.840210985478002, 13.8488418502456],
            [39.840411983522898, 13.8485703772806],
            [39.840603998414799, 13.8483337921489],
            [39.840948726905097, 13.8480906652418],
            [39.841275489089703, 13.848021979118901],
            [39.8417201551554, 13.8479478416936],
            [39.8421019391511, 13.8477494149389],
            [39.8422220888204, 13.847481211473699],
            [39.842251284067103, 13.847020031621099],
            [39.842099693362897, 13.8467387441695],
            [39.841893080847598, 13.8465730242712],
            [39.841687591226297, 13.846465088221301],
            [39.8414315713703, 13.846220869095101],
            [39.841425956899798, 13.8457607770086],
            [39.841503436593101, 13.8456201325635],
            [39.841698820167402, 13.84517639333],
            [39.841641552567999, 13.844913638249499],
            [39.841470872663997, 13.844460085212599],
            [39.841384409817898, 13.8443477870885],
            [39.841320404853903, 13.8442115028844],
            [39.841367566406397, 13.8438996843248],
            [39.8414349400527, 13.843827726136301],
            [39.840791521730402, 13.8427123713666],
            [39.840804996459703, 13.842435439832199],
            [39.840628702085198, 13.8421312508768],
            [39.840596138156101, 13.8418902980527],
            [39.840738745707498, 13.841299362920299],
            [39.8408375603887, 13.8411118333761],
            [39.8412563998899, 13.8409700958297],
            [39.841824584307098, 13.840731322382901],
            [39.842009861834498, 13.840632105947099],
            [39.8423523445366, 13.8403322758014],
            [39.842703810391498, 13.8399986461668],
            [39.842896948177597, 13.839794761154399],
            [39.842852032413397, 13.8395429029512],
            [39.842343361383698, 13.839182014747401],
            [39.842098570468799, 13.8389486909581],
            [39.842066006539802, 13.8386968318386],
            [39.842147977809397, 13.838361019255199],
            [39.8422939540431, 13.8381833000619],
            [39.842421963971098, 13.8378333127873],
            [39.842437684488601, 13.837637058010399],
            [39.842392768724402, 13.8373742944109],
            [39.842570185992997, 13.837069008528401],
            [39.8428093624374, 13.836854217863801],
            [39.842876736083703, 13.8367582707008],
            [39.843045170199403, 13.836569647185399],
            [39.843297821373099, 13.836076826643099],
            [39.843346105819599, 13.835786803446601],
            [39.843232693514999, 13.835595998514901],
            [39.843112543845798, 13.8352318332394],
            [39.843004746011701, 13.834878570571201],
            [39.842852032413397, 13.8345743717327],
            [39.842557834157802, 13.8339507083542],
            [39.842186156209003, 13.833804605153199],
            [39.836593020671302, 13.8289308114765],
            [39.833679110468402, 13.821373503650101],
            [39.834961455536501, 13.813229305852801],
            [39.835348854002802, 13.8125815960165],
            [39.8356868451284, 13.8121672352444],
            [39.836870375515197, 13.810434544982],
            [39.837200506382104, 13.810123771609],
            [39.837577798801497, 13.809522941913199],
            [39.840164946819698, 13.8053345379794],
            [39.842889087918799, 13.8016356818401],
            [39.845417845443599, 13.800579010808001],
            [39.846610358983298, 13.800328200675301],
            [39.847732130194302, 13.8000817521954],
            [39.848424955857197, 13.800033771045101],
            [39.8492480372363, 13.7999814279608],
            [39.850182285131801, 13.7997633316498],
            [39.850817843195301, 13.799119946344501],
            [39.851862134713102, 13.7968277354619],
            [39.852012602523203, 13.7961319981129],
            [39.852280974214302, 13.794822305092801],
            [39.852434810706697, 13.7939182745653],
            [39.852661635315897, 13.792738340430001],
            [39.8537115413043, 13.786222422983499],
            [39.852989520394701, 13.775917632804299],
            [39.853174797922001, 13.766500168717],
            [39.857350841098999, 13.760713175199699],
            [39.862882217460999, 13.7574771516631],
            [39.8646103514888, 13.7527217409729],
            [39.873608101953401, 13.7495445056396],
            [39.874239168440504, 13.7494059846151],
            [39.874680465823801, 13.7492292883861],
            [39.884046525554901, 13.7482356422944],
            [39.877461874522297, 13.740082349684901],
            [39.874492942508297, 13.736312651193099],
            [39.874115650088903, 13.7242483360022],
            [39.873802362633597, 13.7222259208068],
            [39.874284084204703, 13.720003862007699],
            [39.873615962212099, 13.7124615772225],
            [39.873982025690403, 13.709805247697499],
            [39.8759964977151, 13.7091365242782],
            [39.877178905207799, 13.7068652504243],
            [39.8781131531033, 13.7050695976263],
            [39.877473103463302, 13.6975497433841],
            [39.878895810294601, 13.690700608349401],
            [39.877683084661001, 13.688878648110901],
            [39.876313153852699, 13.686704278290801],
            [39.877129497867202, 13.6839134620894],
            [39.877777407765798, 13.6818612418804],
            [39.875939230115698, 13.6808313064404],
            [39.875450771179899, 13.6782848153612],
            [39.871838420843702, 13.6673511746008],
            [39.871978782606803, 13.6652158858554],
            [39.869613967621397, 13.6653784608962],
            [39.867572546138199, 13.6653719142524],
            [39.866935865180601, 13.6607433915936],
            [39.867470362774696, 13.6578660675554],
            [39.8678364262529, 13.655805982359199],
            [39.8682867067891, 13.6537218736211],
            [39.8680115977333, 13.650781175779899],
            [39.867440044633803, 13.648236542350899],
            [39.867976788016101, 13.6454616385126],
            [39.868907667229202, 13.642887485018401],
            [39.869262501766499, 13.6403012999978],
            [39.868716775231398, 13.637870042001801],
            [39.867904922793301, 13.636018209759699],
            [39.867478223033402, 13.6340321390088],
            [39.866722515300602, 13.631718672904199],
            [39.865696190088499, 13.629240401771201],
            [39.8662217045297, 13.6269596272993],
            [39.867367056516997, 13.624587161981999],
            [39.868656138949703, 13.6218338064658],
            [39.868702177608, 13.6191022453107],
            [39.868423699869901, 13.616528895951101],
            [39.868996375863603, 13.614109398180201],
            [39.869892445359497, 13.6116396733117],
            [39.870254017261303, 13.6094427642595],
            [39.8684618782695, 13.607402992887501],
            [39.866070113825501, 13.6056622431175],
            [39.864467743937503, 13.603574417889901],
            [39.864593508077299, 13.600693123358001],
            [39.865093195954103, 13.598379331158],
            [39.865562565689999, 13.596203036168699],
            [39.865772546887698, 13.5938946572972],
            [39.865585023572102, 13.5913068450736],
            [39.865322266351498, 13.589295295428199],
            [39.8664586351859, 13.586680146220599],
            [39.867500680915498, 13.584952341915599],
            [39.868369800952898, 13.582859968235599],
            [39.867866744393801, 13.580702086214099],
            [39.867542227997397, 13.577099023463401],
            [39.866733744241699, 13.5743069123636],
            [39.866294692646598, 13.571838955692099],
            [39.867000993038701, 13.5654500924052],
            [39.865428941291498, 13.5631425058115],
            [39.863479597125, 13.562493015475001],
            [39.862717152027599, 13.560273819125999],
            [39.863048405788597, 13.5582478155711],
            [39.864337488221302, 13.556514346228701],
            [39.865787144511003, 13.547084837518399],
            [39.863670489122804, 13.545672234774599],
            [39.862087208434602, 13.549429695880301],
            [39.854076481888399, 13.5530047962175],
            [39.852435933600802, 13.555157471918699],
            [39.8461488495061, 13.555432558889301],
            [39.844753092133402, 13.557053600641201],
            [39.842391645830197, 13.556252359837501],
            [39.8402356891484, 13.5572348073149],
            [39.837885471786301, 13.555904135812201],
            [39.836009115736601, 13.555208777846101],
            [39.833434319553497, 13.554835445100201],
            [39.824594897157702, 13.5563396886667],
            [39.822474873087202, 13.5568745770448],
            [39.820062896549402, 13.557151845240501],
            [39.817419603825797, 13.5578220906448],
            [39.815062649099097, 13.557697647829899],
            [39.812459780563401, 13.5561890464163],
            [39.810747367052997, 13.554882384629799],
            [39.807749239792301, 13.5539665163212],
            [39.804945373211702, 13.5537471003859],
            [39.802469391709899, 13.5536695952062],
            [39.800088856206997, 13.5534752863345],
            [39.797117678404703, 13.5537743909362],
            [39.794474385681198, 13.555017745081599],
            [39.791807512181499, 13.5570306768956],
            [39.789396658537697, 13.559348147231701],
            [39.786997033835, 13.561196212649699],
            [39.783551994720398, 13.563371737270799],
            [39.773889490945599, 13.566428135585999],
            [39.771879510497399, 13.567315575298499],
            [39.764504342014803, 13.571609732407699],
            [39.761514075012798, 13.5709111457946],
            [39.756425118928199, 13.5708500193681],
            [39.753659430747199, 13.570914420424099],
            [39.745023252184502, 13.569325128241999],
            [39.744495491955099, 13.568663647728],
            [39.739433485329101, 13.5631381394958],
            [39.7397052257026, 13.5472889764791],
            [39.733922321061002, 13.528684320287899],
            [39.729143283749501, 13.524062952386499],
            [39.728191069548302, 13.5166170446749],
            [39.723401926189901, 13.5091818226146],
            [39.722412656483201, 13.498949005843301],
            [39.720486893092897, 13.486842008167301],
            [39.719495377598101, 13.4756702252935],
            [39.721352644447997, 13.4654294207512],
            [39.724183460487097, 13.450522796040399],
            [39.726044096019301, 13.4411917597422],
            [39.726016023666702, 13.431894217808701],
            [39.7268896352805, 13.424276146463001],
            [39.727114214101498, 13.423936467686501],
            [39.727236609559, 13.423667782526],
            [39.727684644306898, 13.4225515344525],
            [39.727860938681403, 13.422155057615701],
            [39.7280304956913, 13.421564163924799],
            [39.728123695901999, 13.421146933123],
            [39.728235985312601, 13.420603002873801],
            [39.7283022360647, 13.420060163625701],
            [39.728681774272303, 13.4186216882953],
            [39.728858068646801, 13.4182142825548],
            [39.729175847678597, 13.41743551309],
            [39.729240975536698, 13.4172727685211],
            [39.729464431463597, 13.416875190721001],
            [39.729699116331602, 13.4164776122629],
            [39.729760875507303, 13.4161641364761],
            [39.729774350236603, 13.415830999738599],
            [39.729867550447302, 13.415448711109899],
            [39.7301505197618, 13.4150609605936],
            [39.730478404840497, 13.4146262423692],
            [39.730626626862403, 13.4144722339653],
            [39.730903981706398, 13.414258151197201],
            [39.7313666140777, 13.4140036545973],
            [39.731692253368202, 13.413845276748701],
            [39.732316582490697, 13.4130796002982],
            [39.732505228700298, 13.4123259363591],
            [39.732714087003899, 13.411848614641601],
            [39.732961123707, 13.410691897438801],
            [39.7330363576121, 13.4097110318411],
            [39.732976844224503, 13.409321087501301],
            [39.7328993645312, 13.4091048155779],
            [39.7327342990978, 13.4087061319767],
            [39.7326208867932, 13.4084680137844],
            [39.732497368441599, 13.4082877865554],
            [39.732111092869403, 13.4079983304199],
            [39.731728185979598, 13.4078366717472],
            [39.730427874605802, 13.4073320338399],
            [39.733925689743302, 13.4030589535117],
            [39.735973848591101, 13.402220057657701],
            [39.737023754579397, 13.401488206019801],
            [39.737762618900597, 13.401007585822001],
            [39.738254446518702, 13.400522595376],
            [39.738663179973003, 13.399256590932],
            [39.738895619052698, 13.398756304336301],
            [39.739181957049503, 13.398138044791301],
            [39.739491875822601, 13.397495752273599],
            [39.7396367291621, 13.397204098472301],
            [39.740367733224602, 13.395722884945],
            [39.740750640114399, 13.395148311461],
            [39.741249205097098, 13.394501641564],
            [39.741747770079797, 13.393865893450799],
            [39.741995929677103, 13.393515248154101],
            [39.742322691861702, 13.3930105801947],
            [39.742647208257999, 13.392461124481301],
            [39.743103103264701, 13.3915970673327],
            [39.743221007145799, 13.3912365874535],
            [39.743432111237503, 13.390424959567101],
            [39.743538786177503, 13.3896854254491],
            [39.743798174715799, 13.388574481447399],
            [39.743908218338099, 13.3875345370868],
            [39.743706097399198, 13.3863438389726],
            [39.743518574083602, 13.3860161228619],
            [39.741202043544703, 13.382431971882699],
            [39.741195306180103, 13.382190550469],
            [39.741387321072096, 13.381931650313099],
            [39.745357874627899, 13.3765864469381],
            [39.746140531819201, 13.373353923262099],
            [39.745710463376902, 13.3723903846666],
            [39.736739662370802, 13.376111239180799],
            [39.736694746606602, 13.376193171619599],
            [39.736263555270298, 13.3767459417454],
            [39.735954759391298, 13.377041990046299],
            [39.735476406502599, 13.3775849263907],
            [39.735076656201102, 13.377986938831199],
            [39.734141285411503, 13.378610712240899],
            [39.7332665509036, 13.379232299198501],
            [39.732135796539701, 13.3800352254954],
            [39.731743906497002, 13.380345471129999],
            [39.7313531393484, 13.3806546239528],
            [39.730501985616698, 13.381334103321],
            [39.730019141151502, 13.381728462653999],
            [39.7293431589002, 13.3819764389675],
            [39.729001799092302, 13.381975346561401],
            [39.728544781191502, 13.381664010618801],
            [39.727991194397603, 13.3809320964666],
            [39.727684644306898, 13.3801936256052],
            [39.727665555107102, 13.378823734313899],
            [39.727881150775303, 13.377794672095501],
            [39.728177594819101, 13.3770835023251],
            [39.728357257875899, 13.3768027480897],
            [39.7292881370891, 13.375649139704199],
            [39.729764244189703, 13.3750712410029],
            [39.730576096627701, 13.3742442644582],
            [39.731104979751201, 13.3738269515709],
            [39.731679901532999, 13.3733615703784],
            [39.732136919433799, 13.3729245919147],
            [39.732856694555203, 13.372190466312199],
            [39.733722445910303, 13.370820529520699],
            [39.734206413269597, 13.3700820276696],
            [39.734896993144297, 13.369188393706301],
            [39.735535919890097, 13.3685263595631],
            [39.736264678164403, 13.367700453033899],
            [39.736617266913399, 13.367310440633901],
            [39.737065301661303, 13.3668963931659],
            [39.737577341373303, 13.366329398250301],
            [39.738039973744598, 13.365672818657],
            [39.738640722090899, 13.3648851392821],
            [39.739152761802799, 13.3643072147756],
            [39.739787196972202, 13.3635075135526],
            [39.740241969084799, 13.3629754704231],
            [39.740878650042397, 13.362199799692],
            [39.741365986084098, 13.361586908801],
            [39.742144151698902, 13.360472557736699],
            [39.742463053624803, 13.359727469153601],
            [39.742468668095299, 13.359152809818299],
            [39.742404663131303, 13.3586251271319],
            [39.742322691861702, 13.357866922659399],
            [39.742114956452198, 13.356883656827],
            [39.741965611536202, 13.356277307566399],
            [39.741942030760001, 13.3558982019394],
            [39.7418870089489, 13.3552547042811],
            [39.741867919749097, 13.3546232227378],
            [39.741837601608196, 13.3536694421189],
            [39.741872411325502, 13.3529090370009],
            [39.741946522336399, 13.352227292444701],
            [39.741880271584201, 13.3516548003366],
            [39.741816266620198, 13.351092139785299],
            [39.7416399722457, 13.350337189296001],
            [39.741343528202002, 13.3495396268805],
            [39.741118949380898, 13.3487868566496],
            [39.740522692611101, 13.3474222497691],
            [39.740396928471299, 13.347150201518801],
            [39.740291376425397, 13.346795118],
            [39.740177964120797, 13.346166892033899],
            [39.740161120709203, 13.3456490140957],
            [39.740219511202703, 13.344841603027399],
            [39.740220634096801, 13.344140169299401],
            [39.740064551816197, 13.3433152718885],
            [39.739698488337901, 13.3421396516377],
            [39.739526685539801, 13.341535450528401],
            [39.739486261351999, 13.3406045626793],
            [39.739508719234102, 13.3398211718863],
            [39.7396299917975, 13.3391732609852],
            [39.739652449679603, 13.3383898655518],
            [39.739612025491802, 13.3378512114303],
            [39.7394099045529, 13.337038311253799],
            [39.7392762801544, 13.336535710314401],
            [39.739162867849799, 13.335918383817299],
            [39.738967484275499, 13.3349568810001],
            [39.738922568511299, 13.3342455849775],
            [39.738975344534197, 13.333287353386099],
            [39.738897864840901, 13.3327038901005],
            [39.738680023384497, 13.332133536988501],
            [39.738500360327699, 13.331679001744201],
            [39.738247709154102, 13.3311217578509],
            [39.737765987583003, 13.3302017562991],
            [39.7376099053023, 13.329903465258299],
            [39.737232612882998, 13.3291823205626],
            [39.7368193878523, 13.3287671150653],
            [39.735954759391298, 13.3282513851392],
            [39.734328808727099, 13.327489807368501],
            [39.7357335492526, 13.3260704472761],
            [39.736165863483102, 13.3255863987812],
            [39.736551016161201, 13.3250815887095],
            [39.736880024134003, 13.324668561503699],
            [39.7370495811439, 13.3244456576393],
            [39.737666050007597, 13.3234393096948],
            [39.737753635747801, 13.3232185900419],
            [39.737744652594998, 13.3223029291335],
            [39.7377401610185, 13.321828707205601],
            [39.737732300759802, 13.3211359481564],
            [39.737728932077502, 13.3207447678802],
            [39.737725563395202, 13.3203754406704],
            [39.737722194712902, 13.320076044826299],
            [39.737722194712902, 13.3200181324488],
            [39.737717703136397, 13.3196160234828],
            [39.737713211559999, 13.319165835478101],
            [39.737705351301301, 13.318421712207099],
            [39.737691876572001, 13.3170744171158],
            [39.736858689145997, 13.316051647951699],
            [39.736627372960299, 13.315792676868799],
            [39.736122070613, 13.315105364086],
            [39.735817766310497, 13.314767716869],
            [39.735476406502599, 13.3144213274871],
            [39.735149644318, 13.3141415931136],
            [39.7345691080656, 13.3136629843343],
            [39.733807785862297, 13.313063082952899],
            [39.733214897774801, 13.3125505977176],
            [39.732601797593396, 13.3121637744549],
            [39.731638354451199, 13.3114764513706],
            [39.7311330521038, 13.311134428186699],
            [39.730674911308903, 13.3108251863619],
            [39.730181960796799, 13.3104929968239],
            [39.729855198612199, 13.3102143506429],
            [39.729203920031203, 13.3097368268343],
            [39.728987201468897, 13.309581658712199],
            [39.728648087449102, 13.3092920841338],
            [39.728210158748098, 13.3088440624058],
            [39.727720576918301, 13.308259447441401],
            [39.727387077369102, 13.307796125420699],
            [39.726868300292502, 13.3070169993641],
            [39.726488762084898, 13.3065208925467],
            [39.725665680705902, 13.3054620179899],
            [39.725354639038699, 13.304941867389701],
            [39.724842599326799, 13.3040228590831],
            [39.724665182058203, 13.3035901262596],
            [39.724193566533998, 13.3024416321147],
            [39.723733179950898, 13.3016493756072],
            [39.723470422730301, 13.3011619999724],
            [39.722785457326196, 13.3003752031575],
            [39.7209079783823, 13.297975457097399],
            [39.7202275045546, 13.297280444239099],
            [39.719240480636202, 13.2962368284219],
            [39.718981092097899, 13.295864185455301],
            [39.718855327958103, 13.2956150279617],
            [39.718823886923197, 13.295350571043601],
            [39.718982214992003, 13.2939889411889],
            [39.7193067313884, 13.293048035192999],
            [39.719675040654899, 13.292405463146601],
            [39.720539669115901, 13.291426302469],
            [39.721168489814701, 13.290787004581],
            [39.722795563373097, 13.289684348366899],
            [39.723674789457398, 13.289165256739],
            [39.724554015541798, 13.2886461640003],
            [39.725177221770103, 13.2882254248603],
            [39.7256836470115, 13.2878549552471],
            [39.726074414160102, 13.2875456837209],
            [39.726687514341499, 13.287171934565899],
            [39.727786827670499, 13.286543553841],
            [39.728101238019903, 13.286418970338699],
            [39.729077032997303, 13.286357771401899],
            [39.730330182818598, 13.286484540610999],
            [39.730873663565497, 13.286562132077],
            [39.7314081611596, 13.2866615802578],
            [39.7326175181108, 13.286938067842801],
            [39.7339503934137, 13.2873391382064],
            [39.734259189292601, 13.2874232864002],
            [39.734602794888801, 13.287494320567101],
            [39.734956506531901, 13.2875074345648],
            [39.735365239986102, 13.2873817587238],
            [39.735617891159798, 13.287189419919301],
            [39.735926687038699, 13.2868823332166],
            [39.736381459151303, 13.286341378826901],
            [39.736686886347897, 13.285941399654099],
            [39.737017017214797, 13.285586226338699],
            [39.737689630783798, 13.2848540212479],
            [39.738088258191098, 13.284417975674099],
            [39.739422256388103, 13.283368840255999],
            [39.7406675459507, 13.282943720550501],
            [39.7415299286234, 13.282649742735201],
            [39.741957751277504, 13.2825033001218],
            [39.742259809791797, 13.2824005716686],
            [39.742592186446899, 13.282286914605899],
            [39.743899235185303, 13.281841028692201],
            [39.745290500981604, 13.282250850334499],
            [39.746246083865003, 13.2823054931679],
            [39.746504349509202, 13.282297843172],
            [39.746916451645802, 13.282286914605899],
            [39.748326806641899, 13.2813732847456],
            [39.749030861245799, 13.2812268413621],
            [39.750322189466701, 13.280966740209299],
            [39.751542775358999, 13.2806487169926],
            [39.752670161040598, 13.280318671839099],
            [39.7531878152231, 13.2801252345704],
            [39.7545038471143, 13.2794859069078],
            [39.755691869077602, 13.2787897482047],
            [39.756703596666298, 13.278046593854199],
            [39.757433477834603, 13.2776127218066],
            [39.758055561168902, 13.2774422328302],
            [39.758311581024898, 13.2776837588448],
            [39.758905592006499, 13.278637838897399],
            [39.759523183764301, 13.279408313179401],
            [39.759989184817996, 13.279971140364699],
            [39.760443956930601, 13.280397358141601],
            [39.761203033345602, 13.280903354171899],
            [39.762018254466, 13.2820082360059],
            [39.762059801547899, 13.283051913039801],
            [39.762324804556698, 13.283890129983799],
            [39.763188310123503, 13.2848212358937],
            [39.7651859387366, 13.2861687103031],
            [39.7660898684912, 13.286068169080799],
            [39.7675361560987, 13.2853031801551],
            [39.7680549331753, 13.284788450535],
            [39.768629854957098, 13.284662773285699],
            [39.769286748008597, 13.2844704323259],
            [39.769781944309003, 13.2839895792596],
            [39.770396167384497, 13.2831775911233],
            [39.770670153546099, 13.282270521756001],
            [39.771130540129299, 13.2815142637403],
            [39.771675143770302, 13.280529594787],
            [39.772383689950601, 13.2796476515012],
            [39.773161855565498, 13.278846577561399],
            [39.774184812095299, 13.2780356650967],
            [39.775856801417802, 13.276811641152699],
            [39.776810138513099, 13.275965749571601],
            [39.777364848201003, 13.2753362469478],
            [39.7780846233224, 13.2743286021985],
            [39.7786651595748, 13.273308931401299],
            [39.779974454101399, 13.2715220381695],
            [39.7808469428211, 13.270653177077699],
            [39.781991171914299, 13.269359169389601],
            [39.783064658678803, 13.2679274467712],
            [39.783938270292602, 13.266587521625199],
            [39.784850060305999, 13.2650759985021],
            [39.785523796769098, 13.2638278645854],
            [39.7860212388576, 13.2627841049443],
            [39.786226728478901, 13.261603722149101],
            [39.7860111328107, 13.260179604537001],
            [39.785620365662098, 13.258618858233801],
            [39.785252056395599, 13.257206745803501],
            [39.784673765931501, 13.2556907924507],
            [39.784600777814603, 13.253946396079],
            [39.7848770097645, 13.2524905368987],
            [39.785223984043, 13.2511144577874],
            [39.785581064368401, 13.250232407750699],
            [39.786685992167897, 13.2472517763489],
            [39.787018368822999, 13.2463008521591],
            [39.787269897102597, 13.2452799707902],
            [39.7874473143712, 13.2448219937099],
            [39.787591044816601, 13.244443806736401],
            [39.789078879506, 13.242279604083899],
            [39.790280376198503, 13.2409395376462],
            [39.791083245483698, 13.2397830959176],
            [39.791791791663996, 13.238844166126899],
            [39.792299339799499, 13.2380418646948],
            [39.7926216104077, 13.2369990874941],
            [39.794225103189902, 13.2350643632527],
            [39.7949819338167, 13.2338729152558],
            [39.795843193595402, 13.2325907358107],
            [39.796338389895801, 13.232236577639201],
            [39.797231090709303, 13.2317228287594],
            [39.7977375159508, 13.231183937813],
            [39.798400023472801, 13.2299815399248],
            [39.799079374406404, 13.227883887952601],
            [39.798989542877997, 13.2271372987622],
            [39.798696467516599, 13.220070276125],
            [39.799665525129299, 13.2197095409373],
            [39.800005762043199, 13.219571805543101],
            [39.800978188338199, 13.2189979072299],
            [39.801178063488898, 13.218917014787699],
            [39.801693471883198, 13.218778185804],
            [39.802069641408401, 13.2186295184583],
            [39.802654669237199, 13.2183649778102],
            [39.802889354105197, 13.218399958408201],
            [39.803405885393602, 13.2186983864312],
            [39.805130650739102, 13.2188142594845],
            [39.806033457599597, 13.219250422654101],
            [39.806326532961101, 13.2194111141515],
            [39.806844187143497, 13.2197346831041],
            [39.806984548906698, 13.219791526254101],
            [39.806984548906698, 13.219802457627599],
            [39.807348366596699, 13.2201478887775],
            [39.807512309136101, 13.220331535518699],
            [39.808288228962802, 13.221009278247999],
            [39.808709314252198, 13.2209753911562],
            [39.809084360883297, 13.220573118223699],
            [39.809047305377803, 13.2194515602941],
            [39.809030461966302, 13.218932318765299],
            [39.809016987237001, 13.218549719036799],
            [39.809003512507701, 13.2181452558141],
            [39.808981054625598, 13.2174685982746],
            [39.808975440155102, 13.217286042526499],
            [39.808964211214096, 13.2169526319752],
            [39.8089282786027, 13.215851827345499],
            [39.808906943614701, 13.2152188897633],
            [39.808877748367998, 13.2143323371251],
            [39.808689102158297, 13.2133364646606],
            [39.808259033715998, 13.211060481816901],
            [39.808232084257497, 13.210913996123001],
            [39.807994030707199, 13.2096568391652],
            [39.807822227909099, 13.208745123025301],
            [39.807641441958197, 13.207791862221001],
            [39.807556102006203, 13.207336001094101],
            [39.807296713467899, 13.205965133008499],
            [39.807108067258199, 13.2051375797289],
            [39.806990163377201, 13.204287066302401],
            [39.806954230765797, 13.203598345033299],
            [39.807011498365199, 13.2030910951105],
            [39.8070350791414, 13.2029085286089],
            [39.807046308082498, 13.2024482855052],
            [39.807046308082498, 13.202229642396601],
            [39.806963213918699, 13.20180438099],
            [39.806750986932798, 13.2007691014113],
            [39.806716177215499, 13.200667431691199],
            [39.8065746925583, 13.1999546492397],
            [39.806502827335599, 13.1995862317278],
            [39.806103077034102, 13.198471136763001],
            [39.805833582448898, 13.1978971888412],
            [39.805117176009801, 13.1969307668178],
            [39.804764587260799, 13.196483631353001],
            [39.804270513854497, 13.195758811000699],
            [39.804024600045501, 13.1952985544272],
            [39.803753982566199, 13.1947475572941],
            [39.804013371104404, 13.1938346922463],
            [39.8031420052789, 13.192838736191099],
            [39.803140882384703, 13.1921160915511],
            [39.803210501819301, 13.1909769106396],
            [39.8035372640039, 13.1895523803984],
            [39.803794406753902, 13.1886449623331],
            [39.804471511899401, 13.1871624744896],
            [39.804952110576401, 13.1863917084533],
            [39.806721791686101, 13.184328667407501],
            [39.8070901009526, 13.1830352946975],
            [39.807374193161202, 13.1820360147339],
            [39.808028840424498, 13.1803238909973],
            [39.808544248818698, 13.179116869361501],
            [39.809046182483698, 13.177726163137899],
            [39.809374067562402, 13.176657981686001],
            [39.809606506642197, 13.1751633944902],
            [39.809705321323399, 13.1740361613871],
            [39.809734516570202, 13.173702691821401],
            [39.809929900144503, 13.1714831942859],
            [39.810042189554999, 13.1702072474316],
            [39.810148864494998, 13.1689958024483],
            [39.810222975505901, 13.168152816888499],
            [39.810404884351001, 13.1660841552021],
            [39.810333019128201, 13.164912051608599],
            [39.810319544399, 13.16382085311],
            [39.810342002281097, 13.1629581686924],
            [39.810435202491803, 13.1618429067685],
            [39.810702451288797, 13.15941774126],
            [39.8109348903686, 13.1584074307832],
            [39.8111684523425, 13.157591744177701],
            [39.811332394881802, 13.1571314158201],
            [39.8115187953033, 13.1564064789103],
            [39.8115771857967, 13.1558674218695],
            [39.811576062902603, 13.1547871173758],
            [39.811587291843701, 13.154395669835599],
            [39.811644559443003, 13.1537177424495],
            [39.812276748824203, 13.1528101915627],
            [39.812792157218503, 13.1524876274866],
            [39.813624221750402, 13.1519015438758],
            [39.813776935348699, 13.1516828556025],
            [39.8138690126654, 13.1507993529926],
            [39.813880241606398, 13.14991475375],
            [39.813891470547503, 13.149385523505799],
            [39.814041938357498, 13.148511853723701],
            [39.8142642713904, 13.147638180829601],
            [39.814579804633901, 13.147178927246999],
            [39.815048051475799, 13.146603765595],
            [39.815690346903899, 13.145771637700401],
            [39.815976684900697, 13.145400951698001],
            [39.816659404516599, 13.144515239512801],
            [39.817266890227501, 13.1437279371083],
            [39.817738505751699, 13.143117776007299],
            [39.818324656474601, 13.142357806206],
            [39.819589035237001, 13.1407197625732],
            [39.820093214690203, 13.1400647607576],
            [39.820889346610699, 13.138823642339499],
            [39.821603507261599, 13.137582517646001],
            [39.822445677840498, 13.1360537933198],
            [39.823544991169399, 13.133445754526001],
            [39.823720162649799, 13.133020373663401],
            [39.824070505610599, 13.132330358409201],
            [39.824643181604301, 13.131226986089899],
            [39.825392151972402, 13.129687286798699],
            [39.826430829019699, 13.126285260751001],
            [39.8269563434609, 13.124411993896899],
            [39.827283105645499, 13.123354515875899],
            [39.827891714250399, 13.1220334821313],
            [39.8283824189744, 13.1204696664516],
            [39.8288495429222, 13.118987859897199],
            [39.829340247646101, 13.1177346054636],
            [39.829585038561, 13.117044547284801],
            [39.829982543074202, 13.116044998406201],
            [39.830508057515502, 13.114505203999601],
            [39.831057152732903, 13.1130572635323],
            [39.8315018187985, 13.1118269448808],
            [39.8317353807724, 13.110735510384],
            [39.832049791121797, 13.1091377202884],
            [39.8321890299909, 13.108217974672201],
            [39.832397888294402, 13.1069427925416],
            [39.832665137091404, 13.104781748388501],
            [39.832875118289103, 13.1034146813069],
            [39.833105311580702, 13.1009550350949],
            [39.833208617838302, 13.098943772108701],
            [39.833243427555601, 13.097943056019499],
            [39.8331580876036, 13.096522360291599],
            [39.833110926051198, 13.095732716782299],
            [39.833030077675602, 13.0943852860012],
            [39.832916665371002, 13.092494276963199],
            [39.8328212193721, 13.0909051181781],
            [39.832751599937502, 13.0897479678925],
            [39.8328796098655, 13.0882200399565],
            [39.833100820004198, 13.087416151376701],
            [39.833310801201897, 13.0870027219432],
            [39.833627457339603, 13.086565229723901],
            [39.833978923194501, 13.0860489879064],
            [39.834387656648701, 13.0851280623184],
            [39.834632447563699, 13.084461975039799],
            [39.834703189892302, 13.084324163654101],
            [39.8345841631171, 13.083393387912199],
            [39.834572934176101, 13.083082763968401],
            [39.8347009441041, 13.0827962020957],
            [39.835075990735199, 13.0823247961529],
            [39.835543114682899, 13.0817494828067],
            [39.835918161314098, 13.0813710440182],
            [39.836293207945197, 13.081037448650701],
            [39.8368310742216, 13.0800377536008],
            [39.837017474642998, 13.079566342384499],
            [39.837427330991403, 13.078784300908399],
            [39.8379651972677, 13.078038351343499],
            [39.838561454037603, 13.0773372441971],
            [39.839228453136002, 13.0762675324179],
            [39.839381166734299, 13.075681267143599],
            [39.839555215320601, 13.074589672491699],
            [39.839764073624202, 13.073429164263199],
            [39.840431072722701, 13.0717184711154],
            [39.840946481116902, 13.0703949727554],
            [39.841250785419398, 13.0696107145931],
            [39.841519157110497, 13.068922709215901],
            [39.841877360330102, 13.0680006258698],
            [39.8422872166785, 13.06694946853],
            [39.842498320770197, 13.0664058401373],
            [39.8429777965531, 13.0651763823753],
            [39.8437267669213, 13.0642805369651],
            [39.844124271434502, 13.0638090956454],
            [39.845024832506802, 13.0618664416393],
            [39.845234813704501, 13.061269203721499],
            [39.845618843488403, 13.0597990734632],
            [39.845758082357499, 13.0584197245428],
            [39.845979292496203, 13.0572810175287],
            [39.846189273693902, 13.0562123127036],
            [39.846761949687497, 13.054855915071199],
            [39.847147102365597, 13.0539479995928],
            [39.847555835819797, 13.0531439993661],
            [39.848129634707597, 13.052350935355401],
            [39.848632691266701, 13.0517525805486],
            [39.849369309799599, 13.050718856308301],
            [39.849779166147997, 13.049661062054801],
            [39.850058766780201, 13.0489948790696],
            [39.850397880800003, 13.0484544928663],
            [39.850807737148301, 13.047696418732],
            [39.851380413142003, 13.0466615836219],
            [39.8518250792076, 13.045868498814199],
            [39.852408984142301, 13.0448347499704],
            [39.852912040701398, 13.0438797591032],
            [39.8535431071885, 13.042766145393401],
            [39.854256144945303, 13.0415584486302],
            [39.854888334326503, 13.040512648480901],
            [39.856337990616197, 13.038511832318401],
            [39.856855644798699, 13.037798579560899],
            [39.857240797476699, 13.037266920493201],
            [39.857622581472498, 13.036740730044199],
            [39.858181782736899, 13.0359683985853],
            [39.8586590127316, 13.035310930387499],
            [39.859286710536303, 13.0344445137221],
            [39.859537115921803, 13.0331262855787],
            [39.859769555001499, 13.0319021321612],
            [39.859896442035399, 13.031228243514001],
            [39.860057015892501, 13.0303825981846],
            [39.860223204219999, 13.0295074123958],
            [39.860492698805302, 13.0280896048529],
            [39.8607341210379, 13.026815102717901],
            [39.860916029882901, 13.0258578543118],
            [39.861078849528099, 13.0249968745849],
            [39.8614763540414, 13.024030867084599],
            [39.862691325463103, 13.0216295070898],
            [39.862901306660802, 13.0208483763156],
            [39.863065249200098, 13.0203079286524],
            [39.863204488069201, 13.019387852084799],
            [39.863203365175103, 13.018376967351299],
            [39.863155080728603, 13.0176067666913],
            [39.8629540826837, 13.0162162421085],
            [39.862822704073402, 13.0151014099202],
            [39.862504925041698, 13.0133421748356],
            [39.862492573206502, 13.0129176809182],
            [39.862362317490302, 13.011686863260101],
            [39.862014220317697, 13.010206591797299],
            [39.861848031990199, 13.009502010110801],
            [39.861677352086197, 13.0087744508337],
            [39.8614729853591, 13.0079090354405],
            [39.861159697903702, 13.0065742548302],
            [39.860932873294502, 13.0056092696204],
            [39.860715031838097, 13.004681479847401],
            [39.8604938216994, 13.0037427456258],
            [39.860241170525697, 13.0026661509572],
            [39.860087334033302, 13.0023893428331],
            [39.859747097119403, 13.0018729252704],
            [39.859465250699003, 13.0013324362465],
            [39.861011475881803, 12.9945893537923],
            [39.862062504764303, 12.994501822716799],
            [39.861980533494602, 12.9936987236589],
            [39.861887333283903, 12.9924918819066],
            [39.861483091406001, 12.986686267453599],
            [39.861032810869801, 12.977310121552399],
            [39.859987396457903, 12.969622052704899],
            [39.862340982502303, 12.9620103459891],
            [39.866124012742603, 12.9552246130432],
            [39.866730375559399, 12.953939888068501],
            [39.867277224988598, 12.952600440323801],
            [39.871566680470202, 12.945166593325601],
            [39.871675601198397, 12.944874400198101],
            [39.871723885644997, 12.9445285831702],
            [39.872394253425703, 12.9434156181497],
            [39.873229686640002, 12.9419524500862],
            [39.875314900993203, 12.9390884680003],
            [39.879261873772798, 12.935950854151599],
            [39.879992877835299, 12.9348148684261],
            [39.885189631753903, 12.9309581692424],
            [39.887538726221898, 12.9308399720718],
            [39.896562303250903, 12.9263801768126],
            [39.8975605561103, 12.92628714956],
            [39.9003363503383, 12.925486019314899],
            [39.903413080186397, 12.9244058027421],
            [39.904570784008797, 12.9225879201278],
            [39.904502287468397, 12.920567548606799],
            [39.904413578834102, 12.919412886904],
            [39.905145705790602, 12.915798925448501],
            [39.906415699023498, 12.9142141069646],
            [39.907676709103598, 12.9129740451607],
            [39.909418317860698, 12.9112852308838],
            [39.910915135702901, 12.9099948070993],
            [39.9124579922034, 12.9092811856572],
            [39.912815072528801, 12.9090579049103],
            [39.913291179629397, 12.9087809927265],
            [39.915326986642, 12.9070407075546],
            [39.916284815313702, 12.906257028012099],
            [39.916812575543098, 12.9037899537512],
            [39.916997853070498, 12.9033904474219],
            [39.917308894737602, 12.902648349039699],
            [39.918728232886501, 12.8997412285665],
            [39.920102655271201, 12.898677319105699],
            [39.920582131054097, 12.8982274547937],
            [39.921661232289203, 12.8972729954728],
            [39.922807707170499, 12.8959157305357],
            [39.924245011625104, 12.8946799562995],
            [39.925314006813203, 12.894128288948499],
            [39.926281941531897, 12.8928826546242],
            [39.927261105191498, 12.891176191212001],
            [39.928964535549099, 12.883778827904599],
            [39.929358671380001, 12.882001151364401],
            [39.929384497944397, 12.8809076113149],
            [39.929247504863604, 12.879288637853501],
            [39.929054367077498, 12.8775558101795],
            [39.928958921078497, 12.876630827773401],
            [39.928691672281502, 12.875587618668],
            [39.9290364007718, 12.873404856504401],
            [39.929530474178101, 12.872379148763599],
            [39.930743199811602, 12.8707349425422],
            [39.932246755018397, 12.8691553121631],
            [39.933611071356196, 12.8684963088835],
            [39.936472205536099, 12.866601391134299],
            [39.936837146120297, 12.866033241428701],
            [39.939241262399399, 12.863552634384099],
            [39.940267587611501, 12.8623648681353],
            [39.9419586661338, 12.860329779069501],
            [39.943166900191002, 12.858800440922799],
            [39.944478440505797, 12.8554877552621],
            [39.9444975297056, 12.854680924672699],
            [39.945675445621902, 12.8519987690722],
            [39.947230653957497, 12.8507080399019],
            [39.948391726462198, 12.848774670652601],
            [39.948809443069401, 12.8460180063176],
            [39.9499985879267, 12.8428748465774],
            [39.951228156971901, 12.840482690650401],
            [39.952172510914302, 12.8377949093493],
            [39.952859722106602, 12.8360234760654],
            [39.9547574131443, 12.832665601348699],
            [39.956052110047601, 12.8324652441854],
            [39.956521479783497, 12.8324761926684],
            [39.957285047775002, 12.832377656304301],
            [39.958322601928202, 12.830671876238901],
            [39.959794716099999, 12.8304178694888],
            [39.959936200757298, 12.8293832272747],
            [39.960315738964802, 12.8282390941581],
            [39.961727216855003, 12.828040923204499],
            [39.962438008823597, 12.827769395898599],
            [39.963008439028997, 12.825937673797],
            [39.965190222275297, 12.825469066120601],
            [39.965750546433704, 12.82409827417],
            [39.9654978952601, 12.822363970781099],
            [39.965015050794896, 12.8205070266342],
            [39.9654428734489, 12.8197679678126],
            [39.965870696103003, 12.8190278119149],
            [39.967272067946197, 12.8168128057504],
            [39.968117607207397, 12.815794531426899],
            [39.969366265452301, 12.8150729775592],
            [39.970466701675399, 12.813138239460701],
            [39.970974249810901, 12.8115363482855],
            [39.971048360821797, 12.808425606393],
            [39.970674437084803, 12.806917851786199],
            [39.9701433081731, 12.8045998120425],
            [39.970233139701499, 12.8032759904583],
            [39.970374624358797, 12.802242331717],
            [39.970828273577197, 12.8004651733613],
            [39.971110119997597, 12.798454768405101],
            [39.972015172646401, 12.797380574874399],
            [39.973025777341, 12.796826503843899],
            [39.974082420694003, 12.796735618558699],
            [39.976154160318004, 12.7959176495183],
            [39.976917728309502, 12.7958782292565],
            [39.977975494556503, 12.7957293082123],
            [39.9791623936257, 12.795122673050001],
            [39.979418413481604, 12.7942061474839],
            [39.979223029907303, 12.7925888057176],
            [39.978832262758701, 12.7917719182583],
            [39.977817166487696, 12.7900768135926],
            [39.977590341878397, 12.7897833447037],
            [39.964264957532599, 12.7831374970654],
            [39.963407066436297, 12.7822537799765],
            [39.962085420074501, 12.781128049144399],
            [39.953656976921302, 12.778166958876101],
            [39.942685178619897, 12.7714682401533],
            [39.929294666415998, 12.7688114815465],
            [39.9257755162904, 12.765312533862],
            [39.920363166703602, 12.7670395635432],
            [39.918743953403997, 12.7667175944147],
            [39.909194861933798, 12.764576598797101],
            [39.8990270558117, 12.762296500670899],
            [39.888625687715702, 12.7557649242795],
            [39.869991260040699, 12.7349917704248],
            [39.869527504775299, 12.732824219812001],
            [39.867582652185199, 12.723726657788999],
            [39.849723021442799, 12.717800909214599],
            [39.839391272781299, 12.7132628533349],
            [39.826081608952997, 12.6937306526369],
            [39.822470381510797, 12.6733774919791],
            [39.820055036290597, 12.6588162994414],
            [39.823318166560199, 12.645657814410599],
            [39.832546110316301, 12.636857356756099],
            [39.832556216363201, 12.631876286117301],
            [39.824315296525597, 12.6225274771417],
            [39.818680613905897, 12.6187427121254],
            [39.8095570993016, 12.604900558784401],
            [39.809047305377803, 12.603810205391399],
            [39.808835078392001, 12.603397075305899],
            [39.808562215124397, 12.602823952094999],
            [39.8082309613634, 12.6020086465041],
            [39.807185546951501, 12.5977019489402],
            [39.806243438797303, 12.594842831642399],
            [39.811053917143703, 12.587853256990901],
            [39.811770323582799, 12.586916258142899],
            [39.812346368258801, 12.585992406806101],
            [39.812774190912798, 12.5854258201772],
            [39.814003759957998, 12.5846827897815],
            [39.814456286282301, 12.5844603186104],
            [39.815176061403697, 12.5839035918788],
            [39.815519666999897, 12.583096993527199],
            [39.815584794857998, 12.5825216330998],
            [39.815543247776098, 12.5816810565951],
            [39.815459030718202, 12.581175832625901],
            [39.815371444977998, 12.5803484029366],
            [39.815296211072997, 12.579599878152001],
            [39.815698207162598, 12.5787932662782],
            [39.826803629862603, 12.5719588824812],
            [39.825883979590401, 12.5609430425787],
            [39.830186909801398, 12.551598224202101],
            [39.830371064434601, 12.551274886972701],
            [39.830520409350598, 12.5509055147932],
            [39.830610240878997, 12.5505372381459],
            [39.830641681914003, 12.549972765476101],
            [39.831295206283201, 12.549053165601601],
            [39.831634320302904, 12.549341431469999],
            [39.832112673191702, 12.549594622898001],
            [39.8327661975609, 12.5497787619619],
            [39.832935754570798, 12.5496472340726],
            [39.833066010286998, 12.549582566169001],
            [39.833338873554503, 12.549474055582399],
            [39.833480358211801, 12.5494444617781],
            [39.833808243290498, 12.5494181561714],
            [39.834216976744798, 12.549464190981301],
            [39.834650413869298, 12.5494883044499],
            [39.835105185981902, 12.549523378582],
            [39.835404998708, 12.5496932688415],
            [39.835948479454899, 12.5499267307571],
            [39.840526518721603, 12.546720721150599],
            [39.851896944430301, 12.547924212514699],
            [39.852121523251398, 12.5478376227046],
            [39.852371928636799, 12.5476841723366],
            [39.853000749335699, 12.546194602939901],
            [39.862309541467397, 12.534494771527999],
            [39.863616590205801, 12.5342262194127],
            [39.863597501005998, 12.5328396091403],
            [39.863385274020096, 12.5325118638041],
            [39.863341481150002, 12.5319703705965],
            [39.862758699109399, 12.531481491136301],
            [39.862598125252397, 12.530569498275201],
            [39.862859759578903, 12.5300247125388],
            [39.862942853742702, 12.5296015984764],
            [39.862821581179297, 12.5285054448261],
            [39.862921518754703, 12.528300463576301],
            [39.862894569296202, 12.526781185703801],
            [39.862641918122499, 12.525808887014501],
            [39.862698062827803, 12.525487709753699],
            [39.862668867581, 12.5252805337657],
            [39.862851899320198, 12.5250097797104],
            [39.862948468213197, 12.524919893685899],
            [39.8632011193869, 12.5247094287254],
            [39.8635245128892, 12.524454020495901],
            [39.863814219568297, 12.5241503803403],
            [39.863979285001697, 12.5236724478189],
            [39.864084837047599, 12.523295362819299],
            [39.864100557565102, 12.522733022827801],
            [39.864173545681901, 12.5222638571405],
            [39.8642802206219, 12.5217716707244],
            [39.864421705279199, 12.521372659221299],
            [39.864576664665698, 12.5208717015977],
            [39.864727132475799, 12.520495708694201],
            [39.862451026124603, 12.5178330493926],
            [39.8608789743774, 12.5171555960794],
            [39.863429066890198, 12.5062777325171],
            [39.867775789971297, 12.4956241515453],
            [39.868542726645103, 12.4929875472089],
            [39.880994499377103, 12.485326442050701],
            [39.8810921911642, 12.483573389671101],
            [39.866263251611599, 12.4726129800385],
            [39.871369051107699, 12.4701340234302],
            [39.871106293887102, 12.4674697490605],
            [39.882027561953798, 12.466944565818901],
            [39.882549707712698, 12.4576511681928],
            [39.863089952870503, 12.463335135710199],
            [39.8494198400344, 12.4523475545199],
            [39.849510794456897, 12.452039443798199],
            [39.849661262266999, 12.4516874734514],
            [39.849796009559597, 12.4514605016661],
            [39.849922896593498, 12.451142521536401],
            [39.849949846051999, 12.4510175223443],
            [39.849827450594503, 12.4507949359144],
            [39.849748848007202, 12.450688576814001],
            [39.849244668554, 12.450296034602299],
            [39.849015598156498, 12.4501512982179],
            [39.848613602066898, 12.4498749830782],
            [39.848360950893202, 12.4497072201713],
            [39.8481902709892, 12.4495186238327],
            [39.8476849686419, 12.4491929658789],
            [39.847314413587199, 12.4490679657477],
            [39.847154962624302, 12.448902395305799],
            [39.8467125423469, 12.4488212547858],
            [39.846407115150299, 12.448859632062],
            [39.845991644331399, 12.448651298208899],
            [39.8459085501676, 12.448280682836],
            [39.845936622520199, 12.448120594375199],
            [39.845986029860804, 12.4480295851377],
            [39.846055649295302, 12.447653486384],
            [39.846020839578102, 12.447628266914499],
            [39.845856897038701, 12.4476249774183],
            [39.845484096195797, 12.4471655440401],
            [39.845092206153097, 12.446959401830499],
            [39.844951844390003, 12.4469550158242],
            [39.844853029708702, 12.4467444874376],
            [39.844848538132297, 12.4464692651747],
            [39.838980293538803, 12.4389537205882],
            [39.833345610919203, 12.433470985950599],
            [39.822058279374197, 12.4154879012746],
            [39.821125154372801, 12.414160970359699],
            [39.8150199791231, 12.4076062710713],
            [39.814794277407998, 12.4064953445003],
            [39.820978055245099, 12.3967095546608],
            [39.8210982049143, 12.3965867227322],
            [39.821304817429599, 12.3963158342565],
            [39.821375559758302, 12.3959155329573],
            [39.8213867886993, 12.395916629674],
            [39.821350856088003, 12.395536068692399],
            [39.821058903620603, 12.395124798995401],
            [39.820879240563798, 12.394842941788401],
            [39.820707437765698, 12.3935159057076],
            [39.820225716194599, 12.3931912743653],
            [39.819873127445597, 12.393251594408801],
            [39.817905816973401, 12.392057254950601],
            [39.818386415650401, 12.391633915684499],
            [39.818306690168903, 12.391550563830901],
            [39.817973190619703, 12.3913685057423],
            [39.818003508760498, 12.391127223742201],
            [39.818088848712499, 12.391004389183699],
            [39.818283109392702, 12.390779557922301],
            [39.8190399400196, 12.390490018841501],
            [39.819516047120104, 12.3906073699471],
            [39.819846177987102, 12.3905481460313],
            [39.820085354431498, 12.390369377462999],
            [39.820206626994803, 12.3902125435875],
            [39.820413239510202, 12.3899427452314],
            [39.820948859998303, 12.390038161755101],
            [39.821124031478703, 12.390043645462301],
            [39.821291342700398, 12.3899317778127],
            [39.821252041406701, 12.3896784303111],
            [39.821119539902298, 12.3894097281465],
            [39.820550232591003, 12.3888536782984],
            [39.820592902567, 12.388612393971799],
            [39.820850045317101, 12.388217564592299],
            [39.8209847926097, 12.3880256334281],
            [39.821298080064999, 12.3876889309874],
            [39.821761835330399, 12.3874377748122],
            [39.822326651065303, 12.3869595903342],
            [39.822374935511903, 12.3868806240058],
            [39.822387287346999, 12.386868559703499],
            [39.822625340897297, 12.386335536336899],
            [39.822792652118999, 12.386213796279399],
            [39.8229464886114, 12.386149087577101],
            [39.823172190326503, 12.386040508532099],
            [39.823754972367098, 12.385723545202699],
            [39.824421971465497, 12.385293615088701],
            [39.824603880310598, 12.3851038752977],
            [39.824693711838997, 12.3848066525733],
            [39.824040187469798, 12.3839599498196],
            [39.823909931753597, 12.3835760810857],
            [39.823419227029603, 12.3831944053276],
            [39.822945365717302, 12.3829860189022],
            [39.822533263580702, 12.382652600275501],
            [39.822237942431002, 12.382333439283199],
            [39.822117792761802, 12.381628212751],
            [39.822121161444102, 12.381548148032101],
            [39.822181797725797, 12.381043630059899],
            [39.822372689723601, 12.3805424014566],
            [39.8225927769683, 12.3801925279662],
            [39.822904941529501, 12.3799139449769],
            [39.823686475826698, 12.379212002268501],
            [39.823938004106203, 12.378999225512199],
            [39.824927273812897, 12.3788204490314],
            [39.825022719811798, 12.378743673818001],
            [39.824237816832301, 12.3775470743594],
            [39.823988534340998, 12.3768846099724],
            [39.823807748390003, 12.3766619601404],
            [39.823816731542898, 12.376350469416501],
            [39.823820100225198, 12.376212272708701],
            [39.823801011025402, 12.3756726467684],
            [39.823715671073401, 12.374979467094899],
            [39.823903194388997, 12.374191960106799],
            [39.823984042764501, 12.373802592960899],
            [39.824416356995002, 12.373043599560001],
            [39.824594897157702, 12.3729229501168],
            [39.825391029078297, 12.3721047260587],
            [39.825851415661397, 12.3715431552488],
            [39.826128770505399, 12.3712645630317],
            [39.826323031185602, 12.371050682978399],
            [39.826636318640901, 12.370713958642201],
            [39.827411115573497, 12.369826627612399],
            [39.8280354446959, 12.3693045377365],
            [39.828276866928498, 12.369057751192599],
            [39.828754096923198, 12.3687265084427],
            [39.829233572706102, 12.3683601666343],
            [39.829512050444201, 12.368012470490701],
            [39.8296569037838, 12.367843558001599],
            [39.829517664914697, 12.3674026300805],
            [39.829463765997701, 12.3672633318015],
            [39.829372811575098, 12.3671569385778],
            [39.829380671833903, 12.3669035689736],
            [39.829472749150497, 12.366561355092299],
            [39.829679361665903, 12.3658780228283],
            [39.829964576768603, 12.365332891314001],
            [39.8298354439465, 12.3641570705747],
            [39.829689467712797, 12.3639333130439],
            [39.829596267502097, 12.3635165092896],
            [39.8294401852215, 12.3628463313332],
            [39.829428956280402, 12.362822200507299],
            [39.829289717411399, 12.362369198680501],
            [39.829285225834901, 12.3621048558474],
            [39.829462643103597, 12.3616496590561],
            [39.829654657995498, 12.3610924532304],
            [39.829768070300197, 12.3603937505462],
            [39.8300622685557, 12.359952810057701],
            [39.8302958305296, 12.359557937347301],
            [39.830267758177001, 12.358923946026099],
            [39.8301588374487, 12.35865630909],
            [39.830073497496798, 12.358354668772201],
            [39.831233447107401, 12.3571108101133],
            [39.831383914917502, 12.356735676975701],
            [39.831537751409897, 12.3562914396695],
            [39.831683727643501, 12.3556859594594],
            [39.831712922890297, 12.3555137483464],
            [39.8316152311031, 12.355247204808],
            [39.831307558118297, 12.3549389792254],
            [39.817554351118403, 12.341807793445],
            [39.817589160835702, 12.324018050649199],
            [39.800305574769197, 12.3163772065993],
            [39.777118934392, 12.3108732475536],
            [39.761524181059698, 12.3032407976856],
            [39.758846078618902, 12.3180030301016],
            [39.747936039493297, 12.3164934939049],
            [39.741660184339601, 12.314670190786799],
            [39.741216641168101, 12.314162252704399],
            [39.740408157412404, 12.313593975239799],
            [39.740156629132798, 12.313392115767],
            [39.739607533915397, 12.312887466406],
            [39.7395165794929, 12.312499105150099],
            [39.738993310839902, 12.312108549183501],
            [39.738558750821198, 12.3120372397448],
            [39.738126436590697, 12.3120449192237],
            [39.737670541584002, 12.3120888019562],
            [39.737078776390597, 12.3122610416104],
            [39.736024378825903, 12.312167790856201],
            [39.726530309166797, 12.3102029349114],
            [39.721479531481897, 12.3082172196596],
            [39.706242981369101, 12.306195285331],
            [39.688450724272997, 12.3069094918015],
            [39.6792923999514, 12.307804715602099],
            [39.665021538769103, 12.307608337113001],
            [39.659870823508697, 12.306323645162401],
            [39.657486919323503, 12.306416897992101],
            [39.651818549880701, 12.307057598890999],
            [39.647671701950401, 12.3067043359174],
            [39.640921985484297, 12.3076193079822],
            [39.633215563240697, 12.3081053170272],
            [39.631288676956302, 12.307180472857301],
            [39.620912012530603, 12.3002105756934],
            [39.625100407542803, 12.287987301687799],
            [39.614190368417198, 12.275719570868899],
            [39.607209335765397, 12.270428722937901],
            [39.602745831697497, 12.270673407797],
            [39.596940469173802, 12.269859253708599],
            [39.591847021512898, 12.270964176504499],
            [39.586609843406499, 12.2658564757244],
            [39.572520891069203, 12.255914005666201],
            [39.556873361713897, 12.2548946097195],
            [39.554626450609497, 12.254663077906001],
          ],
          [
            [37.764110040772998, 13.604888451179001],
            [37.764185274677999, 13.6113493725399],
            [37.763850652234701, 13.609184110436001],
            [37.763154457889499, 13.6083110152733],
            [37.763046660055402, 13.6072403779339],
            [37.7635834034377, 13.605844504038201],
            [37.764110040772998, 13.604888451179001],
          ],
          [
            [37.764519897121403, 13.613338907903699],
            [37.764595131026397, 13.619749397889301],
            [37.768114281152002, 13.626331046351],
            [37.767394506030598, 13.6254591057747],
            [37.764595131026397, 13.619749397889301],
            [37.763464376662498, 13.616941419488599],
            [37.764519897121403, 13.613338907903699],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 3,
        REGIONNAME: "Somali",
        RID: "05",
        latitude:13.833271,
        longtiude: 39.344733,
        GlobalID: "4e466203-6c62-4df6-a413-f0d805fbe18e",
        Shape__Area: 319248277556.70001,
        Shape__Length: 3949757.4884920102,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.933027552878301, 10.958304809564501],
            [42.897007242585602, 10.9242250122991],
            [42.8646569212328, 10.881095208410899],
            [42.843136702419997, 10.852865343884501],
            [42.796516245723502, 10.791965622182101],
            [42.767685882081601, 10.734205826129999],
            [42.740825568773602, 10.685916006567799],
            [42.7215753738191, 10.6605361184271],
            [42.706865149326298, 10.618966238656199],
            [42.704664965888, 10.5746263140107],
            [42.715964846897499, 10.531166340822899],
            [42.736364809957102, 10.498546314031801],
            [42.749684688528497, 10.4495963350438],
            [42.775564627247903, 10.4046563028605],
            [42.785714497607103, 10.3586963301893],
            [42.804494413730502, 10.3147463265],
            [42.819354394567704, 10.290856309825701],
            [42.839794390149699, 10.266456265853201],
            [42.856014310732597, 10.2255562658183],
            [42.883654284452199, 10.186426217245099],
            [42.908544287813299, 10.1587661676945],
            [42.937744310889499, 10.130206103890499],
            [42.953414302618398, 10.1115060699076],
            [42.966704315799902, 10.098786042436799],
            [42.970254297902699, 10.0898360407559],
            [42.981424331929396, 10.0859360053913],
            [42.993904363863599, 10.080465967022899],
            [43.004464366354, 10.069435939891401],
            [43.012054356157002, 10.0572459307248],
            [43.021304361638201, 10.047765911662699],
            [43.026164341648403, 10.0372659095757],
            [43.040644347696798, 10.021555882997299],
            [43.052014322451299, 10.003265861857599],
            [43.062944346397103, 9.996505829302089],
            [43.0656442877807, 9.97757585274894],
            [43.078084251260002, 9.954875834565669],
            [43.081024210809503, 9.938795848429169],
            [43.092194243937897, 9.934895814097439],
            [43.098234217667603, 9.921405812141749],
            [43.116664279513799, 9.91826574642303],
            [43.136384349057401, 9.913565683293619],
            [43.142794344242503, 9.904345676544731],
            [43.158244387067398, 9.90004561971484],
            [43.1672543815693, 9.88771560897435],
            [43.185564443764797, 9.883155546574191],
            [43.199714495016202, 9.88040549733112],
            [43.213374519771598, 9.87195545783139],
            [43.222264501148203, 9.85820545043841],
            [43.231524509369997, 9.84872543000276],
            [43.237924498221297, 9.839515415439269],
            [43.251344513794102, 9.82821538515131],
            [43.250124452517902, 9.813965407984639],
            [43.249144407610899, 9.802565429334379],
            [43.249594366550198, 9.791025449037891],
            [43.256124366775801, 9.78323543716067],
            [43.2635843452044, 9.769615425669221],
            [43.264974288680598, 9.75224544620839],
            [43.274114285573802, 9.741345430278921],
            [43.274194224854298, 9.72553545490709],
            [43.272034186227799, 9.717115477219799],
            [43.274974137692404, 9.70103548270378],
            [43.287204158601597, 9.69272545236471],
            [43.288844111306098, 9.67820547139833],
            [43.297984107300998, 9.667305447540009],
            [43.301044063806103, 9.652655462772829],
            [43.306954035144599, 9.63773546048203],
            [43.317764044168399, 9.62955543178952],
            [43.325474038113597, 9.618785419866549],
            [43.335784032273899, 9.604905403147541],
            [43.345044032410897, 9.59542538276267],
            [43.3564240134993, 9.57713537057618],
            [43.3553239590602, 9.564305395434131],
            [43.367683982360703, 9.557415354474919],
            [43.379303972631803, 9.54197534013837],
            [43.387953952012197, 9.52536532872843],
            [43.405403967154697, 9.51082528539941],
            [43.412143915340401, 9.48864529423382],
            [43.4154438819265, 9.476845300961671],
            [43.433163911778799, 9.46579525275531],
            [43.447253937602902, 9.45708521703833],
            [43.455653935602598, 9.447045200734919],
            [43.466223950715097, 9.440515170365909],
            [43.482463998051998, 9.435305122312119],
            [43.492393985982403, 9.421425106632791],
            [43.496813959488399, 9.4080651088265],
            [43.520274040749896, 9.40682502582721],
            [43.534274097603301, 9.40552497813483],
            [43.546234128955497, 9.40089494195335],
            [43.554084117252899, 9.38959492464682],
            [43.569534160077801, 9.384644881903039],
            [43.581344179964098, 9.37826484126299],
            [43.589344178829101, 9.368714829423061],
            [43.597494177481998, 9.36091481350495],
            [43.610904201992099, 9.352624776343619],
            [43.624454229837703, 9.34608473740364],
            [43.62880420482, 9.33511473382436],
            [43.6436542242391, 9.323164704394619],
            [43.653714238815297, 9.316944671715721],
            [43.6668142448395, 9.305164647493751],
            [43.682224214717898, 9.279074627410401],
            [43.709614280718696, 9.269484541787],
            [43.7246243008696, 9.259284501915401],
            [43.735824299100997, 9.24591448217789],
            [43.761034342553401, 9.23125441221686],
            [43.770484326491498, 9.21804439931616],
            [43.8039943773678, 9.19732431160122],
            [43.8217443768146, 9.17805427310995],
            [43.842854376359597, 9.15672423040264],
            [43.866314413603703, 9.14222416741854],
            [43.884064412152199, 9.122954130295939],
            [43.900674426147297, 9.110854092476149],
            [43.912324444403303, 9.102724063284059],
            [43.928784448729097, 9.08886402331874],
            [43.958484474990797, 9.06497394847251],
            [43.975494439611602, 9.036973932394501],
            [44.002734509417799, 9.03052384778281],
            [44.018364551269499, 9.02446379572527],
            [44.035314545260199, 9.006763768219519],
            [44.0530445760566, 8.99821371459165],
            [44.061224562270198, 8.985933701341891],
            [44.085824616407102, 8.97673362765523],
            [44.1214746914453, 8.96191352089974],
            [44.156344735966201, 8.93794343843037],
            [45.620807574758203, 8.442898915788881],
            [46.735788893981898, 8.070335457868209],
            [46.9993090986735, 7.98507463749865],
            [47.958210759463803, 8.01257070280859],
            [46.803534471564603, 6.80323798207937],
            [46.177231272518597, 6.1534310078024],
            [46.121760981054003, 6.0900012573322],
            [46.062940688638498, 6.03034150073435],
            [45.994760357000999, 5.96007177829752],
            [45.9453001136891, 5.90970197525482],
            [45.898399882087197, 5.85686217063747],
            [45.668208770742801, 5.62231302376944],
            [45.080096001510199, 5.01106485074243],
            [45.001165645851998, 4.93267505184224],
            [45.002615640806603, 4.931225047489],
            [44.962115517531899, 4.91891513993006],
            [44.903155361918301, 4.91269526809859],
            [44.827015171736001, 4.91254542642962],
            [44.731274945796699, 4.91838562772509],
            [44.678224803742303, 4.91308573894366],
            [44.6264246853721, 4.92224584133003],
            [44.547004476592903, 4.92122600595747],
            [44.451774245799903, 4.92965619662819],
            [44.383294077181901, 4.93637633545962],
            [44.306923869777798, 4.93606649395469],
            [44.201223591092102, 4.93845670479552],
            [44.150123471432302, 4.945646811185],
            [44.122833388821, 4.94325686075752],
            [44.077813246667198, 4.93511695312725],
            [44.028943114750199, 4.93963704967819],
            [43.981262960428801, 4.9292871482111],
            [43.9362328182293, 4.92115723834304],
            [43.893642680004497, 4.91279731503701],
            [43.8639025843895, 4.91062737616423],
            [43.843732519075701, 4.90511741341355],
            [43.806242395196598, 4.89874748493083],
            [43.787882321094301, 4.88567752472943],
            [43.755702218809603, 4.88373758133795],
            [43.733082142288801, 4.87844762246629],
            [43.712912076975002, 4.87293766516249],
            [43.700272032630799, 4.86918768529349],
            [43.682541965003601, 4.86344772463212],
            [43.6373218156467, 4.85287780322811],
            [43.622241759243003, 4.8493478355479],
            [43.612041727856599, 4.8453678507621],
            [43.599621681823798, 4.8440578759078],
            [43.579441617362598, 4.83854791049525],
            [43.569041568891798, 4.83212792738711],
            [43.551731514863803, 4.83126796119681],
            [43.541331473579397, 4.8248579766863],
            [43.525831409864601, 4.81644800396874],
            [43.510541353398601, 4.81047803805231],
            [43.488141281477397, 4.80763807336828],
            [43.4730612322601, 4.80410810476779],
            [43.455541169186702, 4.80080813292759],
            [43.440251112720702, 4.79484815708105],
            [43.427401061127703, 4.78865818447789],
            [43.397450960060503, 4.78404823645466],
            [43.384190900303899, 4.77296825939782],
            [43.3740008671667, 4.76899827167058],
            [43.356480804093202, 4.76569830691266],
            [43.353200777411502, 4.75615831064966],
            [43.324650660290501, 4.73911834922264],
            [43.307130597217103, 4.73582838276307],
            [43.286530516461603, 4.7254284188893],
            [43.270410437334299, 4.70969844080187],
            [43.2500303620767, 4.70174847275541],
            [43.2294302813212, 4.69134850823811],
            [43.200880163301903, 4.67431855138388],
            [43.182940092917399, 4.66612858119957],
            [43.162570008722398, 4.65817861204632],
            [43.151539954674803, 4.64443862200857],
            [43.130529858569197, 4.6291686491752],
            [43.109729776898803, 4.61632868365339],
            [43.096469716243803, 4.60525869585764],
            [43.083209656487199, 4.59418871396763],
            [43.0670795782124, 4.57845873275932],
            [43.033019427802301, 4.55454877668726],
            [43.026669377196001, 4.53791878467571],
            [43.012779304676101, 4.51951879216011],
            [43.011739283749201, 4.50731878809468],
            [43.007619238852001, 4.48801878511476],
            [42.999249188887198, 4.47649879395927],
            [42.991079146125401, 4.46740880264622],
            [42.982289090646098, 4.45100880494499],
            [42.973279035957098, 4.43215880948461],
            [42.957578963242497, 4.42130882344662],
            [42.947948896734303, 4.39513882249245],
            [42.9346888369776, 4.38406883842585],
            [42.916328752993799, 4.37100886308607],
            [42.902648688620999, 4.35505887289762],
            [42.889388628864403, 4.34397888203201],
            [42.8738885651495, 4.33557890773572],
            [42.860418498144099, 4.32205891702415],
            [42.847368445636199, 4.31342893159186],
            [42.829008362550702, 4.30036894735454],
            [42.815328298177903, 4.28441896222343],
            [42.802268238437797, 4.27577896985969],
            [42.777008144451003, 4.26827900304711],
            [42.7557780509323, 4.25055902424934],
            [42.730517956945498, 4.24305905702294],
            [42.714807893168299, 4.23221907378763],
            [42.691987803156302, 4.22448910282411],
            [42.669577728494403, 4.22163913850988],
            [42.652057663624298, 4.21833915888349],
            [42.638797603867701, 4.20726917118635],
            [42.611917524030098, 4.20975921364578],
            [42.596207452168102, 4.19890923031846],
            [42.574217382122399, 4.20093926571502],
            [42.545877270453502, 4.18634929223088],
            [42.518997181632798, 4.18882933581279],
            [42.491287080930597, 4.18155937033837],
            [42.468666998121599, 4.1762693972059],
            [42.4358568731923, 4.16699943957959],
            [42.396136736417503, 4.16329949193149],
            [42.344616569920802, 4.16560957110094],
            [42.304896430451201, 4.16189963159534],
            [42.258046269146703, 4.16131969334746],
            [42.203886085183903, 4.16140977544243],
            [42.158025903255101, 4.143509825443],
            [42.115225738916898, 4.13270987162314],
            [42.078155610670798, 4.13121992711856],
            [42.057355519119, 4.11837993916333],
            [42.042065457263099, 4.11241995180083],
            [42.029225408410703, 4.10622996896264],
            [42.013725342001003, 4.09781998218258],
            [42.005355300121003, 4.08629997447021],
            [41.994535244338998, 4.07499997909498],
            [41.9886052091287, 4.06323997741972],
            [41.982465167567902, 4.04904996693358],
            [41.972065117300403, 4.04263997074712],
            [41.958795057498001, 4.03155997963313],
            [41.948395016213702, 4.0251499842409],
            [41.937994965946203, 4.01872999608434],
            [41.926754907344701, 4.00254999131557],
            [41.913914858492397, 3.99635999957692],
            [41.900654796939101, 3.98528000772378],
            [41.883554733091998, 3.98687003110124],
            [41.858904646387103, 3.98669006528325],
            [41.8436145854294, 3.98072007755035],
            [41.818764498707999, 3.97810010295478],
            [41.798794424427598, 3.97503013062867],
            [41.767254321477203, 3.98041017604768],
            [41.751964259621303, 3.97444018758936],
            [41.724464151794898, 3.96961022295663],
            [41.7098041009056, 3.97096024409392],
            [41.697584063872398, 3.97209026267122],
            [41.677403982343201, 3.96658027934033],
            [41.6527638929891, 3.96640031357279],
            [41.627913796386203, 3.96378033831551],
            [41.595723684174402, 3.9618303826361],
            [41.5784136193666, 3.96098040483682],
            [41.555793531167701, 3.95569042573395],
            [41.533803453037102, 3.95772045729169],
            [41.497153323118901, 3.96111050892233],
            [41.472093227352097, 3.95605053216197],
            [41.432993090718497, 3.95967059382439],
            [41.413243018342698, 3.95904061231393],
            [41.378612889579699, 3.95732065917176],
            [41.331972719354297, 3.95917072208138],
            [41.288002565833899, 3.96324078409089],
            [41.246672418999601, 3.96953084198346],
            [41.215122310613701, 3.97491089460766],
            [41.191662203301, 3.95986090017797],
            [41.172112129145098, 3.9616609287905],
            [41.1462820505173, 3.97635098029184],
            [41.1127019409304, 3.98684103823902],
            [41.101321909536303, 3.99773106812617],
            [41.082601852651798, 4.00930110812428],
            [41.056981779476203, 4.02643116796687],
            [41.041761750279797, 4.04998122645458],
            [41.025491705500599, 4.06133126195249],
            [41.007201659566299, 4.07778130546029],
            [40.981361580892703, 4.0924713640999],
            [40.967951548297698, 4.10847140030765],
            [40.954551513053403, 4.12447144351496],
            [40.936881478938297, 4.14824149775319],
            [40.9134914051835, 4.16270155427438],
            [40.880961314772598, 4.18539162824756],
            [40.860431260388502, 4.20451168281968],
            [40.839901205106003, 4.22363173688547],
            [40.826281165262202, 4.23719177180563],
            [40.805551109963197, 4.25387182565783],
            [40.792151076515601, 4.26987186837914],
            [40.775161021256402, 4.27718189734261],
            [40.758540942536897, 4.26858191057601],
            [40.743880878172902, 4.25796191651832],
            [40.721080767590998, 4.24534192485403],
            [40.705990708446599, 4.24298194018896],
            [40.687110616694298, 4.23111194962181],
            [40.670370548205597, 4.22936196914709],
            [40.648940451975399, 4.2211319827459],
            [40.439569522105799, 4.13409211141302],
            [40.399799341983197, 4.11591212498049],
            [40.384549258750702, 4.10102211917049],
            [40.372569214730603, 4.10250214129555],
            [40.230838577197403, 4.04624221211552],
            [40.208988483537503, 4.03967222940105],
            [40.190748408187503, 4.03606224443374],
            [40.169028306139097, 4.02365224853084],
            [40.126378110147698, 4.0024222559637],
            [40.119018069294498, 3.99633225356805],
            [40.106578015085297, 3.98955225760066],
            [40.018047603836003, 3.94738228682714],
            [39.86186687056, 3.86407230300341],
            [39.854326825290102, 3.84619227708489],
            [39.836596712747102, 3.81327222911828],
            [39.771016296882998, 3.66835201221258],
            [39.706345963334897, 3.60468194497675],
            [39.616515505714801, 3.51244183963312],
            [39.6089354683468, 3.50256182667123],
            [39.581865331944599, 3.47810179754038],
            [39.578605311642498, 3.47255179168755],
            [39.564715213071501, 3.42609170095465],
            [39.5588751728829, 3.41242167863725],
            [39.554405151537601, 3.4079616735518],
            [39.543865100629702, 3.40148166799771],
            [39.529685042054602, 3.39853167559018],
            [39.5250950183637, 3.39908167647003],
            [39.516064986939398, 3.4043917029533],
            [39.511624972019497, 3.41037172033945],
            [39.5045149673441, 3.43689178423512],
            [39.498384961761602, 3.45377182538598],
            [39.493674942013399, 3.45745183685215],
            [39.485984914023703, 3.45950185275865],
            [39.467484833891099, 3.45656185710297],
            [39.425244655944802, 3.45680190218025],
            [39.422144643561097, 3.45735190004269],
            [39.415544620557398, 3.46224192009027],
            [39.405564580295902, 3.46457193259201],
            [39.347614339470802, 3.46589199375047],
            [39.332544277722903, 3.46623200731667],
            [39.329044268899402, 3.47247202787113],
            [39.328384270371998, 3.47817204013897],
            [39.298814144991901, 3.47692205860558],
            [39.279924061278699, 3.47285206801785],
            [39.234033866636302, 3.4770221206729],
            [39.200433731804999, 3.47909215690308],
            [39.175083629321698, 3.4906922095963],
            [39.134903477866402, 3.51057228807017],
            [39.106433362908, 3.51910233129741],
            [39.092843316913203, 3.53396238192947],
            [39.220473926779903, 3.60246241349629],
            [39.284674218652199, 3.62306239738418],
            [39.399594871889398, 3.7956426527547],
            [39.431795067690302, 3.85302273612808],
            [39.4828253774727, 3.94198286072929],
            [39.499655471525799, 3.96571289126156],
            [39.4782554643661, 4.04031305514792],
            [39.509075672719099, 4.11440316632515],
            [39.528765813092299, 4.16672323986414],
            [39.539665943800401, 4.23515335180166],
            [39.566946087451399, 4.26457337596157],
            [39.585756208527997, 4.29652340895821],
            [39.603186325986897, 4.33375345407552],
            [39.618796427035903, 4.3684534956804],
            [39.631436520787403, 4.40269353996681],
            [39.642036607004002, 4.43592358225922],
            [39.655566704826697, 4.4688736288491],
            [39.674626831538397, 4.50517366111037],
            [39.690126946457198, 4.54327370248639],
            [39.703247028031299, 4.56815373350139],
            [39.709807098462797, 4.59735376820091],
            [39.716987150170802, 4.61566379069475],
            [39.725107206178599, 4.63304380714112],
            [39.7396872926345, 4.65471382130395],
            [39.745877336220701, 4.66656383170457],
            [39.743537402771999, 4.72346392514108],
            [39.758487568175603, 4.80105402092576],
            [39.765047654776701, 4.84683408391561],
            [39.783967790729498, 4.89057411981057],
            [39.804427964269301, 4.95146418258568],
            [39.812838069214401, 5.00205424363131],
            [39.8335682035651, 5.03275425646342],
            [39.878378545082597, 5.14030433309641],
            [39.907278781483903, 5.21959439663018],
            [39.942269032267902, 5.28497442757545],
            [40.033659591347003, 5.39677442393167],
            [40.063379690463698, 5.37853435940787],
            [40.097929812572602, 5.36486428602416],
            [40.134569961309602, 5.35785422252943],
            [40.174410120192803, 5.35336416015215],
            [40.208330264572702, 5.35553410663146],
            [40.240610422111999, 5.3660640699498],
            [40.276650577985897, 5.36815401593078],
            [40.310350728545899, 5.3762939697221],
            [40.369480990040103, 5.3863938856051],
            [40.455871371737601, 5.39868376167883],
            [40.501144049182301, 5.40571104423891],
            [40.532871709584199, 5.41063365090755],
            [40.548961801150703, 5.42766364280659],
            [40.563481901705302, 5.45389364568471],
            [40.581022009785997, 5.47593363307892],
            [40.605362137293703, 5.49027361274949],
            [40.615942204554202, 5.50688360747835],
            [40.6330323132714, 5.53005360245833],
            [40.6795625234178, 5.54062353133158],
            [40.694352627902298, 5.56715353605275],
            [40.752862918899702, 5.5943834545003],
            [40.783423073062799, 5.61463341995256],
            [40.811463229173498, 5.63489338629193],
            [40.832183347308799, 5.65588337418385],
            [40.8649835009385, 5.66900332417322],
            [40.922273760304201, 5.68336323780016],
            [40.901253772848897, 5.74010332018192],
            [40.874773790062498, 5.80788341421727],
            [40.875713879702303, 5.85463344861684],
            [40.876433942284599, 5.89047347005819],
            [40.877514044242801, 5.9443335078456],
            [40.878384131765799, 5.98750354146438],
            [40.871164228995198, 6.05575359308019],
            [40.866654248789402, 6.07662361489522],
            [40.869494291424701, 6.09448362373641],
            [40.866414319556696, 6.11375364459095],
            [40.866214348286299, 6.13128365000355],
            [40.866600783758599, 6.13599379037617],
            [40.867854392619002, 6.15127366410365],
            [40.8730044521092, 6.16797366354758],
            [40.873954456454797, 6.17175366414806],
            [40.877954503498003, 6.18765366686862],
            [40.880004573960697, 6.21721368060489],
            [40.881784625222103, 6.24218368553042],
            [40.881414637004298, 6.24647368873969],
            [40.879674666774299, 6.26649370428387],
            [40.872974684635601, 6.29084373289846],
            [40.864484703292199, 6.32024376431307],
            [40.861924744582602, 6.34570378817832],
            [40.864414766752297, 6.35226378441964],
            [40.8609847681305, 6.36109379196812],
            [40.856724803441303, 6.38589381526994],
            [40.860334918769198, 6.4361838374953],
            [40.885595097197601, 6.47210379941097],
            [40.900035238708703, 6.51162379077309],
            [40.9361254720665, 6.55114373745637],
            [40.972215691051296, 6.5870636735963],
            [40.989945805390903, 6.60422365003171],
            [41.0155159942206, 6.64454360622676],
            [41.071186318939397, 6.68788350923557],
            [41.072056333698796, 6.69431350979274],
            [41.131006572810001, 6.69124337979868],
            [41.156266648830503, 6.67687332692682],
            [41.1743066873331, 6.65891327935767],
            [41.228446835271697, 6.62299315045535],
            [41.283027004986003, 6.59884302939718],
            [41.318517116123303, 6.58314295111926],
            [41.358367244509502, 6.5655128587098],
            [41.380017334559497, 6.56551281230377],
            [41.437767523265798, 6.54395268420383],
            [41.476247605859399, 6.51024259789903],
            [41.527987715870303, 6.46492247112825],
            [41.571867786334103, 6.4155223616225],
            [41.619667858559303, 6.36170224670071],
            [41.633327974044597, 6.39137222651343],
            [41.656108103399603, 6.40916218688671],
            [41.684148245137202, 6.42412212999033],
            [41.690308278704698, 6.42940211260533],
            [41.704478389336302, 6.45239209070868],
            [41.738908668999699, 6.51989203274756],
            [41.757858788919997, 6.54052198941315],
            [41.798748988538797, 6.55745190209305],
            [41.819639095773702, 6.57046186021659],
            [41.853619228749899, 6.56853178582588],
            [41.858789169753898, 6.53484177087135],
            [41.858869174611399, 6.53486176864296],
            [41.862589190729402, 6.53580176385666],
            [41.887959283422802, 6.53533170221222],
            [41.908429378854997, 6.54224166107152],
            [41.913659405473403, 6.54400164826466],
            [41.936389514836698, 6.55316159595865],
            [41.964679658616198, 6.57026154066268],
            [41.987899778305703, 6.58238148291425],
            [41.999519839543701, 6.58983145666305],
            [42.004019876297697, 6.59761144772237],
            [42.006459921594903, 6.61437144928289],
            [42.000359932319299, 6.62863145903758],
            [41.9991599582712, 6.64160146280347],
            [42.013440051439801, 6.65859142938094],
            [42.014620106244799, 6.6804314326098],
            [42.025470205283099, 6.70640140566667],
            [42.055510423542898, 6.74874133984431],
            [42.059611851754099, 6.79181520599204],
            [42.062859955005599, 6.82590884694559],
            [42.066900684951399, 6.84107130444108],
            [42.075500767409402, 6.86397128244325],
            [42.0762108281493, 6.88672128490391],
            [42.0987109849695, 6.91572122822001],
            [42.106031043606102, 6.93020121160311],
            [42.134141215308297, 6.95415113724552],
            [42.139921265103801, 6.96725112010865],
            [42.147561331492398, 6.98061110158667],
            [42.162621455178297, 7.00710106402824],
            [42.157591536374198, 7.05131107176597],
            [42.186941766749101, 7.09638099139502],
            [42.204681943118302, 7.14102093590025],
            [42.2044019876516, 7.15921092860326],
            [42.203022069374001, 7.19268093010605],
            [42.203712078818498, 7.19591092487406],
            [42.208362148597899, 7.21759090627969],
            [42.209822177734203, 7.2268909016118],
            [42.2098322703064, 7.26399089399916],
            [42.198492294790903, 7.29094091682623],
            [42.174912224658598, 7.30010097194817],
            [42.156722177384999, 7.30830101251096],
            [42.154242169634003, 7.30912102219185],
            [42.145972144381098, 7.31322104444964],
            [42.133562105580602, 7.31732107004044],
            [42.111242045703399, 7.32634112520611],
            [42.070721920540699, 7.34274122503842],
            [41.965211605893202, 7.38410148031914],
            [41.896101394867401, 7.41119164616509],
            [41.893581402204902, 7.41867165365029],
            [41.882711472856698, 7.46547167403415],
            [41.872731541291401, 7.50845168964468],
            [41.867081581023697, 7.53276169801822],
            [41.867771595858002, 7.53816169035229],
            [41.878051706661999, 7.56260166259691],
            [41.888331820161, 7.59111162606797],
            [41.904782006799003, 7.63591156977709],
            [41.929442266924497, 7.69700148604362],
            [41.942472400189303, 7.72745144370058],
            [41.957122581288502, 7.77477138953463],
            [41.912382583247698, 7.84438148314733],
            [41.9130026112366, 7.85380147669912],
            [41.915142699179, 7.88333145624067],
            [41.917112787241997, 7.9128614435138],
            [41.918862857230799, 7.93591143238654],
            [41.922142984523802, 7.97809140077085],
            [41.925423165715799, 8.03973136703384],
            [41.926323282122098, 8.080551348623841],
            [41.9297933572836, 8.10138132833222],
            [41.937453508205301, 8.143561288683991],
            [41.948383709119199, 8.20088122828327],
            [41.962583836057703, 8.223591172676461],
            [42.0087691820682, 8.29473615993083],
            [42.027032859754101, 8.32301142918902],
            [42.025984683921003, 8.425820884596041],
            [42.041284810501402, 8.44745082369206],
            [42.0697050680632, 8.493960715217939],
            [42.098275261632502, 8.516910610310809],
            [42.215596041730699, 8.61119020143671],
            [42.2326561835484, 8.6350201290937],
            [42.239646228098998, 8.63987010443436],
            [42.263056315420002, 8.635940042499611],
            [42.274976299876897, 8.615090019925359],
            [42.294666293824697, 8.586029984098509],
            [42.307126266378603, 8.559629966847909],
            [42.323016266300399, 8.53675993183947],
            [42.502536870981501, 8.492069428937279],
            [42.520116904684997, 8.478699385292369],
            [42.529746873276899, 8.454819378258019],
            [42.534465224001003, 8.429124746996029],
            [42.534526808903699, 8.42878938037628],
            [42.538706736396001, 8.39720939069873],
            [42.550056640092102, 8.350489394947351],
            [42.559976505805899, 8.29197941182718],
            [42.5670064211821, 8.255599418566259],
            [42.578076390970999, 8.22851940522075],
            [42.5947563612689, 8.197829379473051],
            [42.615956334276099, 8.1595893438652],
            [42.630606338407297, 8.14041931388395],
            [42.651386385850202, 8.12965925889144],
            [42.661336432262601, 8.130269231562091],
            [42.678496547588203, 8.1473091679263],
            [42.701576734909303, 8.179599079212499],
            [42.711136849606298, 8.206749027440621],
            [42.719127000240903, 8.245648976607059],
            [42.734857211433201, 8.2943588869287],
            [42.747377346856702, 8.32241883067644],
            [42.7476473507867, 8.32300882484685],
            [42.753927416344098, 8.33710879509594],
            [42.768147552357298, 8.36315873107718],
            [42.779357629686103, 8.37324868993265],
            [42.7981676914738, 8.370058631278519],
            [42.830897852868198, 8.381138525773739],
            [42.858077994265201, 8.391678427418819],
            [42.884388124495999, 8.398498340615699],
            [42.8988282139048, 8.40908828851458],
            [42.898558273755199, 8.429158272534339],
            [42.897008352903299, 8.457738246151861],
            [42.910808539097602, 8.49876817016284],
            [42.9123486162106, 8.52208814024721],
            [42.9075886851669, 8.54901813357699],
            [42.905298778896302, 8.582208105936701],
            [42.898768823586401, 8.60265810938389],
            [42.884018803726498, 8.61582814082837],
            [42.883998806329899, 8.61583813904603],
            [42.8598587740602, 8.6362881983893],
            [42.838398772914204, 8.664378239495271],
            [42.8319688459095, 8.695218224928871],
            [42.837108977219103, 8.72781817638776],
            [42.843899099008297, 8.75790812969904],
            [42.856569234219599, 8.78275805885907],
            [42.858159251374197, 8.7866780536554],
            [42.865339336319799, 8.8043380120126],
            [42.8653094008613, 8.82509799076634],
            [42.859759403321803, 8.831848003264209],
            [42.849529405035902, 8.84427802168584],
            [42.828049353492702, 8.85577808028625],
            [42.805139307086399, 8.87001813535865],
            [42.783979295634602, 8.892828185844589],
            [42.761159280182703, 8.91769823455564],
            [42.730619256366801, 8.948008299494949],
            [42.703049247933102, 8.98089835413982],
            [42.680109262475, 9.015008396996199],
            [42.6730992789184, 9.0283484017829],
            [42.6730492777913, 9.028438405006289],
            [42.662269301244102, 9.0489484148711],
            [42.644329305419703, 9.072968449302531],
            [42.619279254614398, 9.089268514400629],
            [42.599379210298601, 9.101948567100051],
            [42.5993492227377, 9.104638567165759],
            [42.599309221656497, 9.10464856628742],
            [42.599309230639598, 9.107538561500659],
            [42.599309236029498, 9.109358557006431],
            [42.607819298312798, 9.11701852326838],
            [42.610999319147297, 9.119878511906171],
            [42.619009374651903, 9.126418480281821],
            [42.625939423419602, 9.13206844977868],
            [42.635089500308602, 9.14395840901029],
            [42.634679548738802, 9.15850839556683],
            [42.624749532062303, 9.166018418875179],
            [42.614279511119001, 9.17394844079733],
            [42.595459500489497, 9.19357848096517],
            [42.583569521559802, 9.215368503495091],
            [42.579589575219401, 9.23748849142766],
            [42.570969606756002, 9.258168493348739],
            [42.561059609933899, 9.27028851454955],
            [42.551339617574101, 9.28655853338199],
            [42.546929662978599, 9.30521852241888],
            [42.556269758703003, 9.321038477763359],
            [42.5515598171081, 9.34522846803876],
            [42.537432664811703, 9.372969005961769],
            [42.552161214936397, 9.38208656184093],
            [42.567101515939797, 9.39609292482651],
            [42.584843111647103, 9.40916551588565],
            [42.603518453989999, 9.41663553962996],
            [42.617524977815101, 9.42690686015982],
            [42.640869194371298, 9.44744951864169],
            [42.6558094765101, 9.45585331450337],
            [42.666080911385102, 9.45865456076274],
            [42.681954968007197, 9.469859635667961],
            [42.700630292383799, 9.471727093873071],
            [42.714636789259501, 9.47452832770869],
            [42.7286433049998, 9.482932126856751],
            [42.736113474815298, 9.495070990606701],
            [42.747318687766899, 9.5016072763951],
            [42.776265463188203, 9.511878544859931],
            [42.787470692309398, 9.52308363395527],
            [42.801477271830102, 9.54922888447711],
            [42.813616310469001, 9.57257285867218],
            [42.840695554889898, 9.58284413243774],
            [42.8640397139539, 9.586579092369259],
            [42.879913725660202, 9.584711515678521],
            [42.892052644823202, 9.5744401061869],
            [42.908860389690403, 9.56323491965113],
            [42.920999351074201, 9.565102398290771],
            [42.937858277454701, 9.58356670326463],
            [42.938796348579999, 9.58341177264278],
            [42.943409810215002, 9.587512575255239],
            [42.968621502075898, 9.592181288637549],
            [42.979826744671897, 9.607121418627139],
            [42.977959290926698, 9.62859791909159],
            [42.963952901848799, 9.65567702559059],
            [42.950880241440203, 9.6724847625197],
            [42.935006249496801, 9.67995490397522],
            [42.925668564850596, 9.672484852845299],
            [42.917264618755198, 9.65754471233299],
            [42.907926933210703, 9.650074660412979],
            [42.897655510013799, 9.65007469672274],
            [42.853768559353199, 9.662213739510729],
            [42.822954330186803, 9.674352736739291],
            [42.790272591579502, 9.69302806370432],
            [42.779067421747101, 9.698630667497399],
            [42.750120722682603, 9.71076965743225],
            [42.730511675925001, 9.72104109324861],
            [42.713703959803901, 9.74065012651281],
            [42.703432598590702, 9.758391614418169],
            [42.694094975928301, 9.76866301383758],
            [42.6810222732989, 9.774265623910249],
            [42.663764693459903, 9.77661897902334],
            [42.663787815196997, 9.77641285374134],
            [42.647406703614401, 9.77519950244613],
            [42.618459899447103, 9.75839191188494],
            [42.595115714332003, 9.74812062606299],
            [42.568036461826203, 9.736915591385049],
            [42.529752025115002, 9.72757811772351],
            [42.498937725879998, 9.721041898977941],
            [42.474659810298903, 9.721041982207129],
            [42.448514366412503, 9.72197583270728],
            [42.4242364400517, 9.719174633751329],
            [42.395289682596797, 9.71543969040999],
            [42.376738769497202, 9.71329927645608],
            [42.376270315144502, 9.714618642779209],
            [42.355740232912602, 9.717848706681769],
            [42.3461701999165, 9.72134873561823],
            [42.3386701548296, 9.71800876586012],
            [42.338710156809199, 9.71814876921205],
            [42.340520172275198, 9.72023876118396],
            [42.340500174878599, 9.720268756521151],
            [42.340130171389497, 9.720718757974989],
            [42.338790167056601, 9.72176876343981],
            [42.293500092838102, 9.757478876210239],
            [42.250969990820302, 9.783478992824289],
            [42.213329815051097, 9.783729116867139],
            [42.1857496547564, 9.773549224512839],
            [42.122349149151297, 9.710099504908451],
            [42.122139152682301, 9.709889504548],
            [42.030788424004498, 9.61846990554427],
            [42.018888366875601, 9.617909944549821],
            [42.008368336720501, 9.622149973332981],
            [41.996408311656502, 9.631150000187841],
            [41.968175881938997, 9.64620434040836],
            [41.963978727454503, 9.648442387251441],
            [41.958170714900803, 9.65153938609377],
            [41.952318185434898, 9.65466012221499],
            [41.935827940223398, 9.663451376518751],
            [41.9272780385555, 9.66800947838019],
            [41.906118054053103, 9.679290251868929],
            [41.873217918830598, 9.683870352981669],
            [41.853257792493601, 9.67373042636155],
            [41.808657473496297, 9.6405406057454],
            [41.801017447531898, 9.64240062893691],
            [41.790977422267403, 9.649020652685939],
            [41.773807359896701, 9.653690704893821],
            [41.755727228684599, 9.640310776948301],
            [41.749657177562497, 9.634370803666521],
            [41.749647177516799, 9.63392080345632],
            [41.749047070567599, 9.600540834009109],
            [41.728006613201998, 9.489020999552141],
            [41.734596581362801, 9.47147099428957],
            [41.721926514423501, 9.467521037479059],
            [41.702256419057498, 9.46664109754798],
            [41.670176280399303, 9.470621197991621],
            [41.654136242960497, 9.481221240454939],
            [41.642576295184703, 9.51513124768527],
            [41.633416250589299, 9.513441277542411],
            [41.632016241490298, 9.51318127812311],
            [41.597706077360201, 9.51140139230497],
            [41.564135907631901, 9.506931494978421],
            [41.540585769364803, 9.499571577815241],
            [41.522045635150299, 9.48531164517388],
            [41.506635517049901, 9.470491702185489],
            [41.490945404855204, 9.457411759435599],
            [41.479015359267201, 9.460011801694391],
            [41.4588552814227, 9.466861854961641],
            [41.4325951891499, 9.475841926956811],
            [41.393065031684699, 9.485742044493239],
            [41.359014881320299, 9.49011214235756],
            [41.336474792589001, 9.49462220856242],
            [41.308674684288597, 9.503062289809369],
            [41.279654537169897, 9.501522374499061],
            [41.267534478136398, 9.49935241586922],
            [41.260484447684298, 9.50017243267486],
            [41.254544415123199, 9.500312454407529],
            [41.249914393045898, 9.50042246922804],
            [41.239294339077198, 9.498782502186449],
            [41.232524301821101, 9.4970425232514],
            [41.228604270415197, 9.49429253495952],
            [41.219864216961199, 9.490702569411731],
            [41.214944180981, 9.486082585521819],
            [41.211604152228198, 9.48114259695463],
            [41.211464154282702, 9.480972597818029],
            [41.211274148023698, 9.480762596318639],
            [41.194784015101597, 9.46228266222734],
            [41.170433831852598, 9.44139274464021],
            [41.145423663550901, 9.42461283447658],
            [41.114693482559098, 9.41404293521075],
            [41.083523300452804, 9.40440303268487],
            [41.056463135350299, 9.39105311809541],
            [41.030062952605498, 9.37244320657601],
            [41.014052829065598, 9.357193265256241],
            [40.996062732401199, 9.354093319233041],
            [40.976432630929899, 9.35208337522123],
            [40.976002627166402, 9.3520433739906],
            [40.970692601080302, 9.351493395382141],
            [40.953532472280003, 9.335563450071289],
            [40.926952308474696, 9.323113538565661],
            [40.902942174391697, 9.318203611403669],
            [40.874082014530202, 9.30943369518722],
            [40.844081853945497, 9.304783786207199],
            [40.824731760941603, 9.305343842556329],
            [40.812541718655801, 9.310913868002009],
            [40.8084917432491, 9.32674387468432],
            [40.800301756989697, 9.345113891126591],
            [40.792601781056597, 9.36738389531094],
            [40.788801821166501, 9.386883898551799],
            [40.797621887562798, 9.39554387214514],
            [40.828042027610699, 9.393083783482041],
            [40.843112088460302, 9.389223744397221],
            [40.858202153892996, 9.38535370040761],
            [40.891192329052998, 9.389523603070771],
            [40.913832458665901, 9.397183534248461],
            [40.927492548100098, 9.40512348429748],
            [40.918602555045403, 9.42075350797097],
            [40.916402551270203, 9.42289350600176],
            [40.8899724950506, 9.44863357288064],
            [40.862162401077498, 9.463003648632281],
            [40.842342308618598, 9.4635737050455],
            [40.815742142026998, 9.452723783375109],
            [40.783201966230401, 9.447873880333781],
            [40.748581792597399, 9.444853985842339],
            [40.719511641656702, 9.44386406579258],
            [40.707071591040901, 9.446214103539891],
            [40.698541554717202, 9.44763412538626],
            [40.692791509550403, 9.44210414507503],
            [40.6936215196353, 9.446124138751239],
            [40.692181513048297, 9.44469414605525],
            [40.693431525952697, 9.447464139803589],
            [40.692961675618101, 9.498884117348579],
            [40.698981799275003, 9.532754077922389],
            [40.714501980760602, 9.56598401409317],
            [40.721802086018101, 9.590243978508751],
            [40.730512174369103, 9.606803946618991],
            [40.735302243890601, 9.62171392133274],
            [40.748672398473602, 9.651283862887819],
            [40.763072524530401, 9.667463811967901],
            [40.777362660863801, 9.690013757663699],
            [40.801452890534101, 9.72673366242131],
            [40.8255431220012, 9.763443569426039],
            [40.847313313211401, 9.790313483606329],
            [40.877513546576203, 9.818293372603121],
            [40.913023828484803, 9.85092323876534],
            [40.946654087420796, 9.880343116927129],
            [40.978724348204103, 9.9115329965305],
            [40.996534485367597, 9.92868292892838],
            [41.0139146043945, 9.93919286434808],
            [41.0195446642837, 9.948812842066779],
            [41.014784672154498, 9.957992851445461],
            [41.011884666075602, 9.96197285436395],
            [40.998914618425303, 9.967822891326771],
            [40.980634532112397, 9.969032944160141],
            [40.965784467777603, 9.97167298930815],
            [40.953454459885698, 9.987483014111501],
            [40.946014465379001, 10.0013030259296],
            [40.936534525321001, 10.0352330320479],
            [40.9486646886049, 10.067742967295001],
            [40.953824800243098, 10.0957129300844],
            [40.974655099442998, 10.156632814197],
            [40.978805245986997, 10.194662769309801],
            [40.989925447769998, 10.2398926971153],
            [40.999805658253898, 10.289202621362501],
            [41.005075785666598, 10.3188325841327],
            [41.015005915530899, 10.3431625276185],
            [41.030416182751601, 10.4004324245394],
            [41.031826261066598, 10.421992398259301],
            [41.038426375698499, 10.446542360392399],
            [41.047126523292498, 10.477612297127701],
            [41.056956647309498, 10.5002722458481],
            [41.061356724928501, 10.5166422136164],
            [41.071286857487699, 10.5409721622849],
            [41.080307017325303, 10.5743520949461],
            [41.0870171423419, 10.600552051078299],
            [41.082427229143804, 10.632502031815701],
            [41.077867228048, 10.639462044081601],
            [41.0789673040466, 10.6588320178617],
            [41.077247367145702, 10.6811419972331],
            [41.089957485471999, 10.696961942903201],
            [41.114847699937201, 10.7203018370886],
            [41.1330878750002, 10.744071748203099],
            [41.149128009457101, 10.7596716812383],
            [41.166408172942397, 10.7797016011859],
            [41.1908583557505, 10.7964014991484],
            [41.207438525016798, 10.820291412460801],
            [41.220038638348299, 10.8344513541025],
            [41.231958807858099, 10.8636412843621],
            [41.233578907795, 10.8911712482215],
            [41.240729053688803, 10.9213511890118],
            [41.268139328190202, 10.9578410532751],
            [41.280439399725203, 10.960221006026901],
            [41.300259521828501, 10.9640509329155],
            [41.348559765224202, 10.9625207658218],
            [41.392200000778502, 10.9663006082923],
            [41.410060067203801, 10.958460553988999],
            [41.48405048291, 10.966890277454601],
            [41.582210966955799, 10.9620599379551],
            [41.634161245013999, 10.965289744765199],
            [41.680811477268797, 10.9638695796367],
            [41.699521594294701, 10.9692995035773],
            [41.717591667968797, 10.9647694448084],
            [41.7395217934356, 10.9683193617767],
            [41.760431944779398, 10.981929265998801],
            [41.763341996718097, 10.9908592413985],
            [41.768892023003701, 10.9911992197482],
            [41.816512238136099, 10.9830090579882],
            [41.904042558572897, 10.9523987745275],
            [41.972082915621101, 10.9573685148025],
            [42.0345232470534, 10.9628382754391],
            [42.078363541014703, 10.9812980918164],
            [42.126603794017399, 10.985277900756699],
            [42.186254106399197, 10.991007670575801],
            [42.237764395021003, 11.0003174605285],
            [42.333514865347901, 10.9998970918198],
            [42.404345223480199, 11.004596816915701],
            [42.439085436289702, 11.0154566621335],
            [42.474305676447599, 11.031896503937601],
            [42.5115858882994, 11.0396963444155],
            [42.535386062744301, 11.0543862254857],
            [42.578746318577302, 11.0672660390514],
            [42.610206493925297, 11.072795906636101],
            [42.6554067141575, 11.0742357212619],
            [42.701086961738298, 11.0812655314158],
            [42.759587183519699, 11.0673553178039],
            [42.796287254377802, 11.0415052167253],
            [42.852117351656503, 11.0025750594467],
            [42.917067628094799, 10.9949248094824],
            [42.943397714399701, 10.985464725146601],
            [42.933027552878301, 10.958304809564501],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 4,
        REGIONNAME: "Dire Dawa",
        RID: "15",
        latitude:13.833271,
        longtiude: 39.344733,
        GlobalID: "638c1942-7199-46f5-a876-60b538692d46",
        Shape__Area: 1090634613.8476601,
        Shape__Length: 189812.09104487699,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.262629603083603, 9.65688909496486],
            [42.260599573136602, 9.65070910478018],
            [42.256629563959798, 9.652429119978329],
            [42.240749503896701, 9.65662916559508],
            [42.2204994103692, 9.65614923742215],
            [42.200509312641003, 9.653129304794991],
            [42.180359193519799, 9.644329380977091],
            [42.159569049911497, 9.630669465386831],
            [42.142228939152403, 9.620279535434429],
            [42.124838843435903, 9.61450959328833],
            [42.127828803214101, 9.59835960275122],
            [42.130028791717997, 9.59208960316219],
            [42.133388771154998, 9.582479603602851],
            [42.146108792508898, 9.570619569071299],
            [42.152578768205601, 9.556359567421961],
            [42.1388786327744, 9.534679630026989],
            [42.121848511755204, 9.51920970431636],
            [42.1096584119773, 9.507949751819019],
            [42.0962383371157, 9.5012998009604],
            [42.081038220874198, 9.48814986513711],
            [42.0751581544516, 9.475359902278891],
            [42.074478142357897, 9.47259990654999],
            [42.068188115382299, 9.474109918695881],
            [42.066998115328801, 9.475479926938871],
            [42.057798106483098, 9.485069947578801],
            [42.052118101162399, 9.491939960716021],
            [42.043888090465501, 9.49829997550145],
            [42.031698049078102, 9.504390010388979],
            [42.019078010215203, 9.508160047630801],
            [42.002307926317997, 9.50770010439256],
            [41.995337895333599, 9.506450128895359],
            [41.989987869962903, 9.50661014724901],
            [41.983207846135798, 9.508140167252771],
            [41.981797841482702, 9.5088201705014],
            [41.956777663253703, 9.49011026665109],
            [41.9521576340357, 9.48809028165037],
            [41.938727546552002, 9.47953033831646],
            [41.913357405349601, 9.47245042271371],
            [41.908277387502103, 9.47388043556268],
            [41.882397298764097, 9.481170517091639],
            [41.859687213746803, 9.48800058300117],
            [41.8386570834137, 9.47790065939868],
            [41.8151769733146, 9.47673073310936],
            [41.792526907436397, 9.48950079174965],
            [41.772976826094002, 9.49104085679749],
            [41.754506715555898, 9.48456091976194],
            [41.734596581362801, 9.47147099428957],
            [41.728006613201998, 9.489020999552141],
            [41.749047070567599, 9.600540834009109],
            [41.749647177516799, 9.63392080345632],
            [41.749657177562497, 9.634370803666521],
            [41.755727228684599, 9.640310776948301],
            [41.773807359896701, 9.653690704893821],
            [41.790977422267403, 9.649020652685939],
            [41.801017447531898, 9.64240062893691],
            [41.808657473496297, 9.6405406057454],
            [41.853257792493601, 9.67373042636155],
            [41.873217918830598, 9.683870352981669],
            [41.906118054053103, 9.679290251868929],
            [41.9272780385555, 9.66800947838019],
            [41.935827940223398, 9.663451376518751],
            [41.952318185434898, 9.65466012221499],
            [41.958170714900803, 9.65153938609377],
            [41.963978727454503, 9.648442387251441],
            [41.968175881938997, 9.64620434040836],
            [41.996408311656502, 9.631150000187841],
            [42.008368336720501, 9.622149973332981],
            [42.018888366875601, 9.617909944549821],
            [42.030788424004498, 9.61846990554427],
            [42.122139152682301, 9.709889504548],
            [42.122349149151297, 9.710099504908451],
            [42.1857496547564, 9.773549224512839],
            [42.213329815051097, 9.783729116867139],
            [42.250969990820302, 9.783478992824289],
            [42.293500092838102, 9.757478876210239],
            [42.338790167056601, 9.72176876343981],
            [42.340130171389497, 9.720718757974989],
            [42.340500174878599, 9.720268756521151],
            [42.340520172275198, 9.72023876118396],
            [42.338710156809199, 9.71814876921205],
            [42.3386701548296, 9.71800876586012],
            [42.334110125885999, 9.715978783279111],
            [42.328320095807697, 9.713368808071969],
            [42.318120022200603, 9.705648851092571],
            [42.311259965668199, 9.6990788845784],
            [42.302739900644099, 9.691118923060831],
            [42.285919775195701, 9.676128994535899],
            [42.2718296784047, 9.666939051849511],
            [42.262629603083603, 9.65688909496486],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 5,
        REGIONNAME: "Hareri",
        RID: "13",
        latitude:13.833271,
        longtiude: 39.344733,
        GlobalID: "3d859d1a-bb42-4fab-a89a-6957f3b653d8",
        Shape__Area: 383190092.29980499,
        Shape__Length: 85627.725216916602,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.2612486481502, 9.373499394805579],
            [42.266118642579201, 9.365969382239079],
            [42.2695586403484, 9.36065937719011],
            [42.264588580783297, 9.349039404843911],
            [42.258498505315202, 9.33439944393837],
            [42.264948489005199, 9.321309435683681],
            [42.266028461606098, 9.30975944143427],
            [42.259888397587403, 9.29881947198437],
            [42.250288313250103, 9.28551951394509],
            [42.249328258553099, 9.270019530564349],
            [42.253628247679799, 9.261519529966311],
            [42.256308226701201, 9.25253953178704],
            [42.262238222385697, 9.24360951620266],
            [42.268698208816403, 9.22958951286642],
            [42.271888175797699, 9.21645951362124],
            [42.272148174291999, 9.214849511497141],
            [42.265838095122703, 9.199279546082961],
            [42.263558079303401, 9.19646956185237],
            [42.2594280496319, 9.19225957703104],
            [42.2528880116312, 9.1896895961761],
            [42.246007972075297, 9.186989620451859],
            [42.233697909477698, 9.185899663387699],
            [42.220767888061502, 9.196359698319419],
            [42.208467885696699, 9.21191971641411],
            [42.197437866683401, 9.21985974713852],
            [42.182127784973098, 9.21687979656344],
            [42.165697694546303, 9.21040985991324],
            [42.148537606170102, 9.206249916817759],
            [42.137247588662497, 9.21580994044036],
            [42.1273275783199, 9.2265399681357],
            [42.120597549331599, 9.228429983721121],
            [42.112387524353203, 9.23073000910736],
            [42.0908274122569, 9.225110079718441],
            [42.073667316694198, 9.22095013687797],
            [42.060987282048501, 9.2259101726099],
            [42.061797287550299, 9.227250171249651],
            [42.059717310375198, 9.23764016615713],
            [42.058857345967198, 9.249420160187681],
            [42.060487407322199, 9.265860144169141],
            [42.0646574874824, 9.284880112836721],
            [42.070447569663003, 9.30393007940943],
            [42.085237723554897, 9.3291000066483],
            [42.099607851271102, 9.350339937229039],
            [42.108687936823003, 9.36410989389436],
            [42.1142079899208, 9.37249987010718],
            [42.129128112966299, 9.38865981079037],
            [42.146598182099297, 9.38749975184586],
            [42.157448198492602, 9.37630973061324],
            [42.168558204397101, 9.362819708825681],
            [42.187418281684899, 9.36166964485307],
            [42.204758383460799, 9.370219582660591],
            [42.221858495817003, 9.379459519176089],
            [42.238058585191702, 9.384929461329429],
            [42.2529786435585, 9.3837894086305],
            [42.2612486481502, 9.373499394805579],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 6,
        REGIONNAME: "SNNPR",
        RID: "07",
        latitude:13.833271,
        longtiude: 39.344733,
        GlobalID: "7c7d4165-e4fa-42c0-be5c-f9824521f52f",
        Shape__Area: 111031464583.961,
        Shape__Length: 2719004.4036908098,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.712963254472101, 4.43996675263156],
            [36.657183489798797, 4.44147475031372],
            [36.641102523318999, 4.4513365536847],
            [36.617646388356498, 4.45126042720332],
            [36.543610611321697, 4.44110530795951],
            [36.454721191064003, 4.4445209633983],
            [36.397955525366299, 4.44060600072619],
            [36.353508008002201, 4.44416383726747],
            [36.265875106248103, 4.44508296090896],
            [36.244853405705598, 4.45615265712723],
            [36.212736388510201, 4.44613642520767],
            [36.2076901024016, 4.44639727213936],
            [36.200870766500998, 4.44656743832829],
            [36.193640451358, 4.44696710481482],
            [36.185840828903601, 4.44737684670198],
            [36.180850687500303, 4.44724698317771],
            [36.174940895824903, 4.44688649982658],
            [36.169260174546999, 4.44727721003454],
            [36.164730419726801, 4.44748655897189],
            [36.160890121887199, 4.44752686143427],
            [36.156300853679497, 4.44772725420065],
            [36.150690874730103, 4.44823663221613],
            [36.145870290336703, 4.44919717265821],
            [36.142950765663301, 4.44950727693899],
            [36.137730430968503, 4.44960691330473],
            [36.132460688933001, 4.44981738108916],
            [36.125809787148199, 4.45067716375382],
            [36.121390075950401, 4.4509469651382],
            [36.116750277507897, 4.45149664317135],
            [36.113140172959802, 4.45146753599454],
            [36.1077401752082, 4.45139700706137],
            [36.102980227096403, 4.45120669085878],
            [36.096550535450397, 4.45039728669456],
            [36.089149540403298, 4.45028757485831],
            [36.084560272195603, 4.45072754161317],
            [36.080999574988098, 4.45069731489796],
            [36.075780363187398, 4.45085740452364],
            [36.070910371453401, 4.45088763123227],
            [36.067119480954403, 4.45074769275596],
            [36.059770139036203, 4.45017674349691],
            [36.049849369617199, 4.45082717781376],
            [36.044359540337098, 4.45265757078419],
            [36.039909510998399, 4.45550670552136],
            [36.0353494380374, 4.45961750050654],
            [36.030519870491197, 4.46430704763252],
            [36.026479697500797, 4.46795768651662],
            [36.0215198742384, 4.47225760308536],
            [36.0171394643342, 4.47636718473623],
            [36.011499167243997, 4.48117752653323],
            [36.006999730564701, 4.48506764482163],
            [36.0029595575744, 4.4880767350605],
            [35.999029428206299, 4.49138694388511],
            [35.9963591860243, 4.49427734728661],
            [35.966129753819601, 4.51295722610241],
            [35.951189647750603, 4.53217728640612],
            [35.939809115994898, 4.54541722648101],
            [35.922379553694697, 4.56780748438287],
            [35.913088727868697, 4.57900738830944],
            [35.912577811050902, 4.57963532097014],
            [35.9036968415733, 4.59100741058938],
            [35.873928918845699, 4.62554901956537],
            [35.846659435502197, 4.6526698467746],
            [35.826781964052799, 4.68229764132995],
            [35.787058462189101, 4.73288123309485],
            [35.7869731222371, 4.75145299062325],
            [35.794130449263299, 4.80472818854544],
            [35.799652842472398, 4.87773674763139],
            [35.799048725443903, 4.88177794019497],
            [35.801449473040698, 4.89852871444765],
            [35.805378479514602, 4.92351871961218],
            [35.805188710410803, 4.94689908087825],
            [35.807078541189803, 4.96822808783441],
            [35.807508609632102, 4.97280904706503],
            [35.808348534422699, 4.98262863687695],
            [35.808908858581198, 4.98730234318905],
            [35.809858826994102, 4.99522899830479],
            [35.809269307588899, 5.00070914032775],
            [35.809259201541998, 5.00526861933514],
            [35.809658951843403, 5.01138851777103],
            [35.8102686833425, 5.01518839662355],
            [35.810769494113401, 5.01979922963451],
            [35.811028882651698, 5.0236091267412],
            [35.810788583313197, 5.02781833244246],
            [35.810478664540199, 5.03214831608503],
            [35.810588708162499, 5.03814827678706],
            [35.810359637765004, 5.0440385646383],
            [35.810559512915702, 5.04691881317133],
            [35.810588708162499, 5.05032923460829],
            [35.810449469293403, 5.05361883697638],
            [35.810359637765004, 5.05586931357258],
            [35.810178851814101, 5.06186905490045],
            [35.810449469293403, 5.06567870516842],
            [35.810769494113401, 5.06953866555418],
            [35.810519088728, 5.07334827060046],
            [35.809809419653497, 5.07727864988924],
            [35.809149157919698, 5.08062851315792],
            [35.808439488845202, 5.08443917117104],
            [35.808119464025303, 5.08668842189346],
            [35.808778602864997, 5.09026864017135],
            [35.809848720947201, 5.09418885007425],
            [35.810439363246502, 5.09567863541457],
            [35.812019275252403, 5.09970842034119],
            [35.814938799925798, 5.10511833607287],
            [35.817498998485597, 5.10850943633473],
            [35.820729564826102, 5.11027879616945],
            [35.823669301593398, 5.11112880294931],
            [35.823229127104099, 5.11332874946947],
            [35.824329563327197, 5.11452881878788],
            [35.826239606200097, 5.11595928058155],
            [35.828019393356698, 5.11658895117862],
            [35.829359006024198, 5.11796907959157],
            [35.830969236170901, 5.11870835298692],
            [35.833099366288401, 5.11961874295333],
            [35.8336495843999, 5.12243937783884],
            [35.835619140660398, 5.12467842965915],
            [35.836899239940202, 5.12628893161112],
            [35.8381198258325, 5.12783903590442],
            [35.838599301615403, 5.13014852774451],
            [35.839879400895299, 5.13250945725303],
            [35.841609780711302, 5.13358870445001],
            [35.841819761909001, 5.13676939748202],
            [35.843719698734901, 5.1382590836979],
            [35.846219261012997, 5.14077879277014],
            [35.845719573136201, 5.14279857899608],
            [35.847389316670501, 5.14394938456157],
            [35.849239846155797, 5.14491901212549],
            [35.851249826603997, 5.14536859672301],
            [35.852538909036802, 5.14778874317784],
            [35.854729675435898, 5.14921913039879],
            [35.856069288103299, 5.15139881513594],
            [35.855589812320403, 5.15514866060898],
            [35.855669537801901, 5.15762916059876],
            [35.857139406185603, 5.16096853797185],
            [35.8585497611816, 5.16430901610195],
            [35.858749636332298, 5.16747949044664],
            [35.858029861210902, 5.17025853515736],
            [35.859420004113097, 5.17203890668748],
            [35.859279642350003, 5.174458951416],
            [35.859689498698401, 5.17618898695997],
            [35.858389187324597, 5.17844909737058],
            [35.857679518250102, 5.18144951845029],
            [35.856889000800102, 5.18364922175954],
            [35.855999668668801, 5.18601889865356],
            [35.854128927089697, 5.18925859825731],
            [35.852779208375303, 5.19151866189635],
            [35.850859059455502, 5.19516874262195],
            [35.848419010565003, 5.19800917071637],
            [35.845219885259397, 5.20009922557065],
            [35.841619886758302, 5.20144897908468],
            [35.838069295597798, 5.20290943820146],
            [35.835619140660398, 5.20499947678325],
            [35.832979216619201, 5.21083902341694],
            [35.831578967669998, 5.21465896557923],
            [35.830529061681702, 5.21852920528924],
            [35.829248962401898, 5.22274942790614],
            [35.827489387339099, 5.22552934704978],
            [35.825169488117901, 5.22853960760948],
            [35.824148777376301, 5.22981885214336],
            [35.822539670123597, 5.23576887036619],
            [35.822439732548197, 5.23790911866886],
            [35.8231494016227, 5.23980894631441],
            [35.823518833783297, 5.24205876416514],
            [35.823198808963298, 5.24470888712108],
            [35.822649713745903, 5.24747864495308],
            [35.8233694888673, 5.25139899977193],
            [35.824048839800902, 5.25606962419763],
            [35.825049338448601, 5.25842896389798],
            [35.827538794679697, 5.26072903195579],
            [35.828319206082803, 5.26400971713738],
            [35.831209535509402, 5.2667089454785],
            [35.832719828080897, 5.26802948332591],
            [35.834619764906797, 5.26904923628868],
            [35.8377795889187, 5.26972907066733],
            [35.837579713768001, 5.27249871718024],
            [35.838859813047797, 5.27369960165517],
            [35.841789443768199, 5.27402945312939],
            [35.8433592497272, 5.27644910400748],
            [35.845009904061698, 5.28047885282144],
            [35.8477194475375, 5.28189887460343],
            [35.848759247478803, 5.28322944324182],
            [35.847519572386702, 5.2850788170908],
            [35.849129802533497, 5.2855897978881],
            [35.852509713789999, 5.28481941311114],
            [35.854699357295097, 5.28572956280299],
            [35.8543299251345, 5.28901906073072],
            [35.853889750645301, 5.29069958266697],
            [35.856880017647299, 5.29113899982572],
            [35.856739655884098, 5.29483881283629],
            [35.858299355796198, 5.29574894779074],
            [35.860149885281501, 5.2966590814051],
            [35.8598893738491, 5.29989932508655],
            [35.860539529535899, 5.30230881204269],
            [35.860619255017397, 5.30444883108754],
            [35.860409273819698, 5.30617962476158],
            [35.858759742379299, 5.30762977567103],
            [35.858949511483097, 5.30987934766634],
            [35.857819880013302, 5.3117890176498],
            [35.857669412203201, 5.31398937947338],
            [35.856769974024999, 5.31594934962142],
            [35.855059806302798, 5.31763986079069],
            [35.853839220410499, 5.31550883205969],
            [35.851779832621702, 5.31678901547154],
            [35.848999546817303, 5.32003921589453],
            [35.846298986494403, 5.32027959811302],
            [35.841259437750502, 5.32082968172012],
            [35.837828996259297, 5.32321896192288],
            [35.836419764157299, 5.3255892260441],
            [35.835929059433397, 5.32801985532477],
            [35.8361199514313, 5.33002897447634],
            [35.835039727302103, 5.33165907299832],
            [35.831489136141599, 5.33242939925765],
            [35.828629124855802, 5.33370954743437],
            [35.826388951116002, 5.33360892453027],
            [35.823559257971098, 5.33223933336187],
            [35.820919333929901, 5.33172950925667],
            [35.818219896501098, 5.3322695203022],
            [35.816689391835801, 5.33440943517753],
            [35.815688893188103, 5.33712959832947],
            [35.814269555039203, 5.33892961745686],
            [35.812088894687001, 5.33939918679493],
            [35.809809419653497, 5.34131994524719],
            [35.807509732526199, 5.34115895059057],
            [35.803959141365702, 5.3414093867048],
            [35.801449473040698, 5.34309982779864],
            [35.799669685883998, 5.34315908259364],
            [35.797419406097298, 5.34230939064548],
            [35.7948895256784, 5.34162963623853],
            [35.7927593955609, 5.34164976055746],
            [35.790539433915001, 5.34361970457821],
            [35.7866295166409, 5.34352914542899],
            [35.785369629454898, 5.34476902200018],
            [35.763293531347699, 5.35326697227852],
            [35.741169148793901, 5.37105739119042],
            [35.6564580175015, 5.38232971685892],
            [35.579428604782301, 5.41576983040871],
            [35.541058190315297, 5.41208975319719],
            [35.540457441968996, 5.41503985332625],
            [35.539727560800699, 5.4177495953621],
            [35.5381880729825, 5.41827946924066],
            [35.535087762358202, 5.41863942549222],
            [35.533077781910002, 5.41894013412175],
            [35.529557508890299, 5.41716941646836],
            [35.527498121101502, 5.41758973880537],
            [35.524047467516397, 5.41709004705808],
            [35.521177350183599, 5.41796981726259],
            [35.520357637486804, 5.41594980930886],
            [35.518337550991703, 5.41475032158994],
            [35.514777976678303, 5.41539981150773],
            [35.5119381774864, 5.41812967371874],
            [35.509877666803497, 5.41917935946733],
            [35.507058079705402, 5.41896025588344],
            [35.504477669051802, 5.41915029471077],
            [35.502067938302197, 5.41979977989865],
            [35.500677795400001, 5.41847956917096],
            [35.500547539683801, 5.41628964509841],
            [35.497667316304103, 5.41577989134243],
            [35.494778109771502, 5.41383030283557],
            [35.491787842769497, 5.41320987627518],
            [35.488977238824297, 5.41438030206483],
            [35.486397951064802, 5.41457034233262],
            [35.483448108250599, 5.41274036386034],
            [35.481487535143003, 5.41210987518696],
            [35.478337817178002, 5.41236028211465],
            [35.475498017986098, 5.40971982527625],
            [35.473417295209302, 5.40852032520038],
            [35.471007564459597, 5.4084197146479],
            [35.469217671255997, 5.40749968626453],
            [35.468167765267701, 5.4056596253027],
            [35.4664778096395, 5.40301019599149],
            [35.464627280154197, 5.4015792757635],
            [35.462547680271399, 5.40066929817322],
            [35.460917238030703, 5.39825013734595],
            [35.458947681770297, 5.39635974201441],
            [35.457147682519803, 5.39406018161956],
            [35.455467832938503, 5.39262029687706],
            [35.453677939734902, 5.39106973394407],
            [35.451757790815002, 5.38900939076947],
            [35.450427161300397, 5.38721958074355],
            [35.447607574202401, 5.38700940907662],
            [35.446637393695603, 5.38827938144566],
            [35.444967650161203, 5.38776960412352],
            [35.4432271642982, 5.38605022043973],
            [35.441957171065297, 5.38443927201495],
            [35.440627664444797, 5.38340964902965],
            [35.438997222204101, 5.38127996728959],
            [35.4370871793313, 5.38012960066508],
            [35.4338678419318, 5.37899935495342],
            [35.4318477554366, 5.37848956985047],
            [35.428486933379901, 5.37550016352655],
            [35.4269171274209, 5.37297022441946],
            [35.424497290624302, 5.37159960344027],
            [35.421327360565499, 5.37085951154815],
            [35.418727860712103, 5.36880021789854],
            [35.416127237964503, 5.36799975225712],
            [35.4133076508665, 5.36656986758157],
            [35.410657620778402, 5.36572020817248],
            [35.409316885216803, 5.36404995320023],
            [35.4084971725201, 5.3619202037832],
            [35.407567416200997, 5.36071949138748],
            [35.405726992762702, 5.36054955797128],
            [35.404106656568899, 5.3596898283184],
            [35.402507655363202, 5.35993019498824],
            [35.400377525245702, 5.36029018582379],
            [35.398647145429699, 5.35873954055785],
            [35.396166672351399, 5.35782949889309],
            [35.392947334951998, 5.35732975799196],
            [35.389557317648503, 5.35792005592539],
            [35.386677094268798, 5.35706926648771],
            [35.383046777626902, 5.35569972778241],
            [35.379956573049498, 5.35612009264209],
            [35.3764374229239, 5.35515973739213],
            [35.373147343195903, 5.35314958263666],
            [35.3693968768847, 5.3506899849586],
            [35.3658271965244, 5.34977993131107],
            [35.361856642968597, 5.34898950278171],
            [35.358346475995901, 5.34831981783977],
            [35.355227076171801, 5.34648964006226],
            [35.3530868400074, 5.34481933253845],
            [35.3508365602206, 5.34396964289322],
            [35.348237060367197, 5.34225013576829],
            [35.345986780580503, 5.3404098776738],
            [35.343836438369102, 5.33839967448619],
            [35.342796638427799, 5.33724922709377],
            [35.337836815165304, 5.3347392542047],
            [35.3329971415721, 5.33344010162078],
            [35.3272973310944, 5.33219013982631],
            [35.322417233313402, 5.33181000782775],
            [35.316597273166401, 5.33033978920606],
            [35.311146745179997, 5.3304795439793],
            [35.306496840690599, 5.33124987171855],
            [35.3015763187218, 5.33294928508372],
            [35.300146874526, 5.3347493164773],
            [35.298646688001497, 5.34064018974269],
            [35.296066277347897, 5.34682951466313],
            [35.2941169331813, 5.35480980547661],
            [35.291477009140102, 5.36100010501729],
            [35.289797159558802, 5.36574033169848],
            [35.287896099838797, 5.37239000270104],
            [35.286326293879803, 5.37723970018153],
            [35.2853965375607, 5.38244039322549],
            [35.286236462351397, 5.38578974121282],
            [35.287586181065798, 5.39005018355614],
            [35.288136399177297, 5.39287965525063],
            [35.287766967016701, 5.39715010908216],
            [35.287696224688098, 5.40177043795828],
            [35.288066779742799, 5.40568980860783],
            [35.288446317950303, 5.40944035203208],
            [35.289106579684201, 5.41295946969929],
            [35.290236211153903, 5.41728008647376],
            [35.290396785010998, 5.42190026163557],
            [35.290486616539397, 5.42554003599184],
            [35.2921069527331, 5.42771986577157],
            [35.294376321719596, 5.43071013226519],
            [35.296296470639398, 5.43300955326894],
            [35.299086862490697, 5.43698014194041],
            [35.300726287884203, 5.4406701278101],
            [35.300477005392899, 5.44477033396917],
            [35.300626350308903, 5.44869054309447],
            [35.302896842189497, 5.45215018743794],
            [35.304646311205303, 5.4555997515745],
            [35.305186423269902, 5.4580801614869],
            [35.305096591741503, 5.46125024231916],
            [35.3042768790447, 5.46536036799434],
            [35.303777191167903, 5.46766971641308],
            [35.305137015929297, 5.47192958017134],
            [35.305346997126897, 5.47615029166858],
            [35.304336392432297, 5.47920961820836],
            [35.302707073085699, 5.48343027827845],
            [35.301066524798102, 5.4862101299842],
            [35.300397279911401, 5.48795046480881],
            [35.3002569181483, 5.49187039111663],
            [35.301206886561197, 5.49590988899761],
            [35.300776818118997, 5.49942961730206],
            [35.297236333005401, 5.50199032112953],
            [35.293236584202901, 5.50443030059612],
            [35.290386678963998, 5.50704016218988],
            [35.288336274328003, 5.50919063482181],
            [35.285767092615401, 5.51105049676622],
            [35.282336651124197, 5.5129707086958],
            [35.277886621785498, 5.51570012613887],
            [35.274506710529003, 5.51786062662109],
            [35.270856181793199, 5.52013065253417],
            [35.2665060900298, 5.52262979459504],
            [35.262736534518801, 5.52484056532488],
            [35.258676149434599, 5.52752075052353],
            [35.255706094526502, 5.53000980239224],
            [35.252056688684704, 5.53285984892711],
            [35.249596427700403, 5.53454975238748],
            [35.246576965451602, 5.53687000879422],
            [35.243596804496597, 5.53792060359359],
            [35.239146775157799, 5.54135067084231],
            [35.2323465284571, 5.5462906479664],
            [35.2267567616016, 5.55035995099811],
            [35.218635991433203, 5.55559043382883],
            [35.2113259508087, 5.56036041486072],
            [35.202816659279797, 5.56641000951276],
            [35.197216786377503, 5.57007013493272],
            [35.191496763805802, 5.57356036459033],
            [35.1855566539896, 5.57798039830921],
            [35.177325840198797, 5.58309999624484],
            [35.170975874034198, 5.58699023493764],
            [35.1641756273334, 5.5914101673936],
            [35.158756540382001, 5.59560097011944],
            [35.154296404996302, 5.59879043025642],
            [35.149326475686898, 5.60228048880244],
            [35.144185866473599, 5.60571018019957],
            [35.138015563365798, 5.6098908227143],
            [35.132415690463397, 5.61339087247489],
            [35.126915755136402, 5.61653106526329],
            [35.123495419692098, 5.61880071008355],
            [35.121896418486401, 5.62071051268322],
            [35.121346200374802, 5.6235411213954],
            [35.121395607715499, 5.62931069295597],
            [35.122035657355397, 5.63710058530516],
            [35.122655494901402, 5.64160059455408],
            [35.122995731815301, 5.64864051911322],
            [35.123225925106901, 5.65546022476708],
            [35.123976018369099, 5.66255026445666],
            [35.124956304922897, 5.66896083696412],
            [35.125696292138201, 5.67496013865485],
            [35.126436279353499, 5.68073031517595],
            [35.126165661874097, 5.68292037479033],
            [35.124936092829003, 5.68627025598126],
            [35.121815570110797, 5.69212076888095],
            [35.117556432770002, 5.6978606049729],
            [35.113695922836399, 5.70324060170297],
            [35.109545706223798, 5.70927083090311],
            [35.106096175532798, 5.71523060597853],
            [35.101675341440803, 5.7230405659952],
            [35.096346086017803, 5.73150067697254],
            [35.093685949882698, 5.73584129539967],
            [35.0928359190451, 5.73781104262345],
            [35.090006225900098, 5.74336048671923],
            [35.087865989735697, 5.74782056044929],
            [35.084465866385301, 5.75366038871877],
            [35.081575536958702, 5.75835053069392],
            [35.077946343210797, 5.7644304522522],
            [35.075455764085604, 5.76901101901195],
            [35.0722757279798, 5.77427080467115],
            [35.069615591844702, 5.77867142470068],
            [35.066955455709603, 5.78423052412913],
            [35.064305425621498, 5.78955049537045],
            [35.059485964122203, 5.79743093190167],
            [35.0559656911025, 5.80316076910337],
            [35.053185405298201, 5.80762148916154],
            [35.051705430867599, 5.80959098692877],
            [35.050936248405499, 5.81265078857034],
            [35.048325519611097, 5.81693157842939],
            [35.045655277428999, 5.8207610256719],
            [35.041386034041302, 5.82592090762916],
            [35.038025211984497, 5.83038144717712],
            [35.034225338332703, 5.8355311870254],
            [35.030365951293298, 5.84150080455546],
            [35.025645304475297, 5.84781105899128],
            [35.021955474445697, 5.85326112232932],
            [35.016495963306497, 5.86056092918039],
            [35.011655166819203, 5.86699160572322],
            [35.0090455609188, 5.87104076039228],
            [35.005185050985297, 5.87718089823412],
            [35.002075757208203, 5.88227100663727],
            [34.999575072036002, 5.88598160710472],
            [34.994675885055202, 5.89195068482738],
            [34.991885493203903, 5.89537081234669],
            [34.991395911374099, 5.8980313933769],
            [34.991775449581603, 5.90259076410259],
            [34.9928556737108, 5.90778116326594],
            [34.994385055481999, 5.91227120463658],
            [34.996335522542701, 5.91809145029873],
            [34.997995160030101, 5.92357098078288],
            [34.998035584217902, 5.92813125791267],
            [34.994235710566002, 5.93479122754751],
            [34.991105081800903, 5.93879186155014],
            [34.9875949148282, 5.94452136214693],
            [34.987515189346702, 5.94874082756327],
            [34.987895850448403, 5.95329084477519],
            [34.988735775239, 5.95797149326635],
            [34.989815999368197, 5.96264093366163],
            [34.990235961763503, 5.96518169115891],
            [34.9908557993095, 5.97020174663221],
            [34.992835461616899, 5.97284186413775],
            [34.995685366855803, 5.9767015054018],
            [34.997495472153297, 5.97987094740613],
            [34.998614997576098, 5.98234126790362],
            [34.999095596253099, 5.98546153923351],
            [34.9993561076855, 5.989041900204],
            [34.999965839184597, 5.9925518819517],
            [35.000505951249202, 5.99573128277945],
            [35.001385177333503, 5.99769117816798],
            [35.0030358316681, 6.00236139622128],
            [35.002375569934301, 6.00553180599502],
            [34.998855296914599, 6.01046103624208],
            [34.9965252916464, 6.01382124033261],
            [34.995055423262798, 6.01671128959103],
            [34.993805642123803, 6.01903180488396],
            [34.992896097898601, 6.02059183774708],
            [34.993575448832203, 6.03748167027586],
            [34.993526041491599, 6.0434211828338],
            [34.9927355240415, 6.05511130200234],
            [34.987995788023703, 6.06885125135724],
            [34.980775578927599, 6.07707160461189],
            [34.972855806803999, 6.08598187015081],
            [34.966125179537698, 6.09146194506764],
            [34.962294987745103, 6.10703206441755],
            [34.958705095290902, 6.12124184566796],
            [34.958185195320198, 6.12857156469966],
            [34.960655562351498, 6.13661125192028],
            [34.966335160735397, 6.14581191718897],
            [34.969706088838997, 6.15569232340567],
            [34.968415883512201, 6.17151175487713],
            [34.966455310404598, 6.18526210782777],
            [34.966765229177703, 6.20223158428802],
            [34.964825991058099, 6.21346165923295],
            [34.960345643578499, 6.22468144807701],
            [34.953065921094797, 6.2388624003079],
            [34.949715205085099, 6.25330193031871],
            [34.9518756533434, 6.26913182263007],
            [34.985332283206297, 6.29944190064799],
            [35.011652921031001, 6.28089733394918],
            [35.0386315748013, 6.30526351912302],
            [35.073826444738998, 6.30804706897443],
            [35.083807850439698, 6.31869339531539],
            [35.106558807904101, 6.31954495803613],
            [35.119673088158201, 6.34774952930746],
            [35.131913756798397, 6.39648671422295],
            [35.143786116172102, 6.39837816768277],
            [35.165150299416702, 6.38340257467076],
            [35.178864205122899, 6.37990188292655],
            [35.200239617308497, 6.4121325618793],
            [35.1873813569105, 6.44555064360655],
            [35.174605067782103, 6.46779801702903],
            [35.186331450922097, 6.49102176173119],
            [35.187535193402901, 6.55874216371647],
            [35.196097260954602, 6.59870384468387],
            [35.187004064491099, 6.62818779683701],
            [35.188254968524298, 6.65775028922073],
            [35.1984171601759, 6.68147149407785],
            [35.178974248745199, 6.71087464384335],
            [35.180313861412699, 6.72861716157698],
            [35.147529845118697, 6.76234399945471],
            [35.117792240531998, 6.78427047889368],
            [35.080614339604601, 6.81204288348301],
            [35.043663263286497, 6.81174184225926],
            [34.986120554868101, 6.79944804934838],
            [34.934410158431902, 6.79753917172667],
            [34.945983827973599, 6.82718055335956],
            [34.969356868772302, 6.85987704293963],
            [34.992967963121302, 6.86450701557789],
            [34.998632963881803, 6.89410076210073],
            [34.974707459183399, 6.92640027245829],
            [34.946456566391902, 6.94536426646946],
            [34.912073548892202, 6.98938324084656],
            [34.902829884618697, 7.03214097472608],
            [34.885088157757302, 7.0319849520805],
            [34.877355908949198, 7.070321480897],
            [34.893423400699803, 7.09262142285539],
            [34.891339309240699, 7.16055090418411],
            [34.898508988102002, 7.18572634837774],
            [34.942811652126601, 7.19350697998044],
            [34.966280138924198, 7.21587332451841],
            [34.970547136523798, 7.23511504945596],
            [34.978258050343896, 7.21387257480778],
            [35.011249802047203, 7.19531063256143],
            [35.021176185936802, 7.18638030980049],
            [35.054147725546301, 7.1846579494121],
            [35.068346721505897, 7.15619349597185],
            [35.084073976342601, 7.1254420034596],
            [35.114631293625997, 7.10274696964019],
            [35.1458208002907, 7.10218760223194],
            [35.1573102527746, 7.10228343016292],
            [35.204928823091599, 7.10021643954738],
            [35.218814531595903, 7.1085278025128],
            [35.2252722955946, 7.12169931667251],
            [35.235027999580197, 7.13890272097313],
            [35.264987937199699, 7.13683366696889],
            [35.280233470465298, 7.12998132117495],
            [35.291206391660801, 7.12268429585384],
            [35.300386050970403, 7.11657939647054],
            [35.313970823854497, 7.11585513764004],
            [35.3311488578751, 7.12769272625906],
            [35.354910420034102, 7.1190842089666],
            [35.372134492713002, 7.11758221450265],
            [35.381249024164497, 7.12378292398294],
            [35.369456390272198, 7.14539728364568],
            [35.353805492234699, 7.15509828813088],
            [35.334744364799697, 7.18114413572963],
            [35.334679236941597, 7.18933036598369],
            [35.340429577654099, 7.18773947703799],
            [35.3485413646697, 7.19844667603443],
            [35.373686332366297, 7.23221430577538],
            [35.346567316832903, 7.24018462238651],
            [35.3537605764704, 7.26316574826297],
            [35.370858885009604, 7.27803919401082],
            [35.375672732038304, 7.29117682722954],
            [35.381188387882801, 7.31905807353479],
            [35.383516147362798, 7.3354509833071],
            [35.388303044933103, 7.3518644489199],
            [35.393058501468403, 7.39737528366543],
            [35.368595130493603, 7.41581859887626],
            [35.374408353275903, 7.42916816858866],
            [35.356567811733299, 7.43788876286851],
            [35.346069874744302, 7.45405903842263],
            [35.329709307632299, 7.46279138480212],
            [35.3208137405313, 7.4641953579294],
            [35.310313557754, 7.48036354814407],
            [35.273361358541699, 7.47266363630786],
            [35.2627780816007, 7.48166837804382],
            [35.254464173646198, 7.47956528424245],
            [35.244664676790499, 7.474860284919],
            [35.210268184561599, 7.47305331122134],
            [35.190007806222397, 7.45681359311747],
            [35.183508495141801, 7.4600034660427],
            [35.162167892673402, 7.47254339369294],
            [35.123156305672303, 7.47978683880505],
            [35.121473087408702, 7.4846821639273],
            [35.127111138710703, 7.49619047046018],
            [35.138447877596299, 7.5126602794902],
            [35.146583245388101, 7.52009898688161],
            [35.161280806330403, 7.52759434084915],
            [35.172712991214901, 7.53342421840868],
            [35.170870321988303, 7.5563266283218],
            [35.165461341083798, 7.58158421598863],
            [35.167060342289602, 7.61604483510439],
            [35.146413688378203, 7.63141270392472],
            [35.116390868688804, 7.63196806486482],
            [35.116265104549001, 7.64582625685814],
            [35.173300264831902, 7.65480735369434],
            [35.212690267146399, 7.65207520608379],
            [35.221078286111897, 7.66370034505548],
            [35.2209222038313, 7.68141009876524],
            [35.223039982113598, 7.7037615714967],
            [35.248310713949998, 7.72708658931269],
            [35.275933908936601, 7.746581719549],
            [35.290383310281698, 7.74800367938467],
            [35.293399403848099, 7.75247425422111],
            [35.3111692030621, 7.76155433729248],
            [35.323418854855198, 7.76533384599368],
            [35.327108684884699, 7.7664742562792],
            [35.341628828558399, 7.77737419714939],
            [35.342179046669898, 7.80455439114738],
            [35.342788778169002, 7.82252410068392],
            [35.350359330225899, 7.83569412795952],
            [35.3584194641127, 7.84356435256593],
            [35.372359071533999, 7.8330443594912],
            [35.389509033202003, 7.82759348134299],
            [35.400840157616997, 7.82995405406291],
            [35.410250010218199, 7.84451433144492],
            [35.418720000453298, 7.8645935291346],
            [35.429059609373603, 7.87984443808879],
            [35.433900405860904, 7.8833236711558],
            [35.445029409337003, 7.8785441673291],
            [35.460099771122202, 7.87308387540335],
            [35.474680551077597, 7.8740738297804],
            [35.485289654582999, 7.88034385665347],
            [35.490599820806302, 7.88313347087116],
            [35.497560641364103, 7.88086440810186],
            [35.505170494714697, 7.88527349710162],
            [35.512550154773699, 7.89291365634004],
            [35.519240357852198, 7.89662412675485],
            [35.522950399975599, 7.8957243184948],
            [35.5308903841931, 7.88055407943258],
            [35.533489884046602, 7.86790381714061],
            [35.5365800886239, 7.84995386426078],
            [35.544770478226901, 7.83156373005346],
            [35.555000043524799, 7.82078423320852],
            [35.570540897940099, 7.81349428759565],
            [35.5899905467354, 7.80920347266955],
            [35.606390415141099, 7.8127033213967],
            [35.618651295875203, 7.81531317042744],
            [35.628621472634798, 7.82136715636806],
            [35.641961454604001, 7.82946347431095],
            [35.667341107168603, 7.84546319577021],
            [35.6767913839575, 7.85403402333185],
            [35.681361562965499, 7.85690390401265],
            [35.694101919482499, 7.86492389172529],
            [35.7038419029506, 7.85967365376614],
            [35.716811329865003, 7.85604405424623],
            [35.739911507396201, 7.86719415412802],
            [35.762522103097503, 7.88317351304362],
            [35.776331454802602, 7.89957379348665],
            [35.784152412245, 7.91112311528707],
            [35.792671809820703, 7.92128411550737],
            [35.800491644369004, 7.93375368684005],
            [35.800691519519702, 7.9418133307681],
            [35.796041615030298, 7.94916329454529],
            [35.783282169313502, 7.95509410442915],
            [35.772141936896297, 7.9640340849161],
            [35.768181489387402, 7.97025381194155],
            [35.739281563803203, 7.98996326078451],
            [35.7306420165582, 8.00701421327714],
            [35.723621682612801, 8.023604124978579],
            [35.725731600636301, 8.031003763424749],
            [35.728381630724499, 8.040313440049459],
            [35.744412066969602, 8.05143407069183],
            [35.753791601429903, 8.068573608779779],
            [35.766142313692498, 8.081793548731619],
            [35.783222655925897, 8.080734057263721],
            [35.798452468674, 8.079693462726141],
            [35.8098217714887, 8.081743520340829],
            [35.816721955764798, 8.082984222601681],
            [35.837712215272397, 8.080053668831241],
            [35.861242461245801, 8.077793481376879],
            [35.869033100547298, 8.065664074384509],
            [35.873092362737403, 8.059333513388539],
            [35.878392422913699, 8.04079376153333],
            [35.888413129908102, 8.0010830446094],
            [35.892142261231299, 7.99258310914597],
            [35.899162595176698, 7.97165387692213],
            [35.905502455294297, 7.94658320423002],
            [35.902803017865601, 7.92677379853861],
            [35.895692852391797, 7.90993305180748],
            [35.887422737307297, 7.89332296646203],
            [35.874992299563303, 7.87693356110305],
            [35.8597826989091, 7.86121313720407],
            [35.8515024777777, 7.84852335189473],
            [35.848991686558598, 7.83953303632363],
            [35.860362112267303, 7.82990288250579],
            [35.875432474052502, 7.82236390858546],
            [35.898162096528999, 7.80748357440611],
            [35.921023097615702, 7.78883346008497],
            [35.924842060467299, 7.78571278893928],
            [35.942002128182203, 7.77449375646964],
            [35.952520277265201, 7.77042839635349],
            [35.961463005918603, 7.7669726980464],
            [35.9788330548311, 7.75874277021368],
            [35.985203233089599, 7.75582326000901],
            [35.9867730390486, 7.75510339290766],
            [35.9887931255438, 7.75417323822942],
            [35.997613458739799, 7.74569271405161],
            [36.012543458761797, 7.71006308059991],
            [36.018612701400102, 7.69742324703627],
            [36.0209326006214, 7.69259265908389],
            [36.0422732030897, 7.67494346445455],
            [36.052282681142998, 7.65448350330362],
            [36.0475025209374, 7.63490289617053],
            [36.0401733911131, 7.61461241656819],
            [36.032863350488597, 7.59203255008915],
            [36.035703149680501, 7.57316258728465],
            [36.045443133148602, 7.56606318004185],
            [36.062572882722598, 7.5580631278555],
            [36.087323714588301, 7.55447324130972],
            [36.127993816182702, 7.55622310601369],
            [36.156933043060697, 7.5432024734973],
            [36.181263912531001, 7.52418231771105],
            [36.200974072758697, 7.50422283660409],
            [36.207153359019401, 7.49929210578169],
            [36.216823723052897, 7.49156580878482],
            [36.225073626043397, 7.48497274210829],
            [36.241273619298397, 7.47788302457277],
            [36.265794257872599, 7.46991252152924],
            [36.279774289481701, 7.4636720695032],
            [36.287314523397797, 7.46031187486247],
            [36.302134479797502, 7.45046272630553],
            [36.324084813764998, 7.44754223192779],
            [36.349044503934202, 7.44600236754457],
            [36.362024036895697, 7.43200193533931],
            [36.375014798798098, 7.41616267077677],
            [36.406674798092801, 7.40913197021052],
            [36.427455076402701, 7.41379202106072],
            [36.453545520935897, 7.41801219182094],
            [36.470605651075402, 7.41722160882176],
            [36.482015378077797, 7.41670160486357],
            [36.486585557085803, 7.41649226690822],
            [36.506935766953397, 7.40642165751115],
            [36.541615228496802, 7.3936315183147],
            [36.575366056615302, 7.37992229590725],
            [36.598715516637803, 7.36962144713323],
            [36.606345582082298, 7.36895216207363],
            [36.613325491839902, 7.37346229974234],
            [36.623175518930303, 7.37981205025447],
            [36.633335464793703, 7.37845235161137],
            [36.6384356498193, 7.37179188373882],
            [36.652125974749197, 7.34560227879075],
            [36.6729455543528, 7.32863172193228],
            [36.694455713830997, 7.31372208980445],
            [36.714566747254302, 7.30273138847089],
            [36.720126195968902, 7.29889215295973],
            [36.739776842809, 7.28531144229707],
            [36.762906215586902, 7.26650181821843],
            [36.784746505931999, 7.25609141585415],
            [36.791496222398102, 7.25287779873171],
            [36.803841320190102, 7.26985902688595],
            [36.8415582102879, 7.32694331772184],
            [36.879540103394604, 7.33028559452285],
            [36.914517131875897, 7.29883423560656],
            [36.931517748627897, 7.28330877334592],
            [36.955849740992399, 7.26108500828862],
            [36.966184858336099, 7.24251943619619],
            [36.9651675162769, 7.2255316076231],
            [37.003678292507097, 7.2350315029517],
            [37.039898364762799, 7.24061126149963],
            [37.0548575600316, 7.23812159936238],
            [37.068517566820702, 7.23584134641481],
            [37.105218237753398, 7.22509047254522],
            [37.109908566430597, 7.22587137045771],
            [37.112347492426998, 7.22627685757188],
            [37.128978677018402, 7.2290406213137],
            [37.154568310780597, 7.24126068788658],
            [37.183139228392101, 7.2654514322523],
            [37.200819196077603, 7.28255136562666],
            [37.2119392164009, 7.29331089049823],
            [37.238219430037901, 7.31265065311176],
            [37.262659220236401, 7.32602115229867],
            [37.2880298896482, 7.33501107003818],
            [37.332079902499103, 7.34413110704537],
            [37.331429746812198, 7.35052136953843],
            [37.338539912286002, 7.35323091108701],
            [37.356980079280802, 7.36152091411966],
            [37.380270025915699, 7.38062051773677],
            [37.391090233512898, 7.40361111022445],
            [37.397530031205903, 7.43465085047681],
            [37.401429842433103, 7.46178043228918],
            [37.407950488501697, 7.49299084217097],
            [37.408099833417701, 7.49373118970735],
            [37.426080736723499, 7.52500053777814],
            [37.439460019986299, 7.54731118940179],
            [37.445911046620402, 7.56549103708463],
            [37.436210364445998, 7.58523064329368],
            [37.428810492293103, 7.61166075115539],
            [37.420950233557001, 7.63441097666705],
            [37.4077809314918, 7.65877144059928],
            [37.402000272638503, 7.68383158372153],
            [37.402450553174702, 7.71072071105512],
            [37.400830216980999, 7.74221122139797],
            [37.402900833710902, 7.7716310973031],
            [37.404511063857598, 7.80129143705382],
            [37.405650801374399, 7.82841111761121],
            [37.412581303791299, 7.85507074220702],
            [37.425051042828997, 7.8787510541389],
            [37.435210988692397, 7.89921120361382],
            [37.437520781866702, 7.91967145159868],
            [37.429661646024798, 7.93185080041988],
            [37.417420977384502, 7.94472152554122],
            [37.401481495562003, 7.95368174983278],
            [37.398030841976798, 7.95668105138773],
            [37.390620863776903, 7.96311107070923],
            [37.388541263894197, 7.97552154543104],
            [37.390620863776903, 7.98586109602361],
            [37.392241199970698, 7.99276102693079],
            [37.392241199970698, 7.99437117935529],
            [37.402790790088602, 7.99264093243454],
            [37.4062414436737, 7.99566107588431],
            [37.409701080411601, 7.99868119697299],
            [37.417080740470702, 8.001721310999489],
            [37.424930893159797, 8.00338146611009],
            [37.433011239140399, 8.003900749675751],
            [37.446411857391297, 8.005821092359071],
            [37.460041546039598, 8.008210666531649],
            [37.478031432498199, 8.014771081922859],
            [37.491191751410497, 8.017620937898821],
            [37.497441779999797, 8.01512133841525],
            [37.502081578442301, 8.01238154625336],
            [37.507191869514799, 8.00803164354525],
            [37.514391866517002, 8.00023127989097],
            [37.5188216837619, 7.99242075909965],
            [37.5207317266347, 7.98407076452074],
            [37.522121869536903, 7.97763106628988],
            [37.525351312983297, 7.97258132002991],
            [37.528812072615402, 7.96660072071473],
            [37.533201465672398, 7.96017075616553],
            [37.539671581506298, 7.95442129044469],
            [37.549831527369697, 7.94982054771836],
            [37.557911873350299, 7.94890083782332],
            [37.573161898192303, 7.95280097190532],
            [37.589102502909, 7.96014073015173],
            [37.6013925788899, 7.96544084353121],
            [37.601772117097397, 7.97299055085257],
            [37.602002310388997, 7.97750095909797],
            [37.6027018734165, 7.99613144231064],
            [37.602202185539703, 8.00281103179454],
            [37.602391954643501, 8.01180111713729],
            [37.600732317156101, 8.021011161941431],
            [37.598102499161797, 8.037131295853889],
            [37.595232381829, 8.05810052115573],
            [37.592612669881703, 8.07514083008353],
            [37.592932694701702, 8.09348112248454],
            [37.592971995995399, 8.095431056620351],
            [37.595902749609799, 8.11204068851513],
            [37.596582100543401, 8.11366148327526],
            [37.599982223893797, 8.128200542436129],
            [37.606572489397003, 8.151521519778671],
            [37.617782341248699, 8.17832117911416],
            [37.623922326215599, 8.20094126405688],
            [37.622492882019799, 8.210151422474389],
            [37.611142668404902, 8.21447135441797],
            [37.596781975694199, 8.21924134041722],
            [37.574322970697096, 8.226031692170841],
            [37.551871825958699, 8.230291439384541],
            [37.534511883093103, 8.233650972905931],
            [37.518071590499602, 8.237481673171439],
            [37.501842401997898, 8.246380934638481],
            [37.490512400476902, 8.25297079313088],
            [37.487691690484802, 8.254611017385541],
            [37.4728324327914, 8.26720140075164],
            [37.464892448573799, 8.28353162916383],
            [37.463382156002403, 8.307041256206],
            [37.464091825076899, 8.312061231478481],
            [37.466961942409597, 8.332421583034289],
            [37.472192383151402, 8.351810985708619],
            [37.486631678449498, 8.3809410517822],
            [37.499012708852902, 8.40430151974374],
            [37.512601973313402, 8.41751135341045],
            [37.532933093981299, 8.423841738792699],
            [37.537582998470697, 8.424241619284111],
            [37.5583722599334, 8.426051073340769],
            [37.576473312908398, 8.41461104927185],
            [37.587683164760101, 8.39024141688839],
            [37.598432630028697, 8.365861368056841],
            [37.6123632542972, 8.354630657080021],
            [37.631052703783297, 8.36510148051752],
            [37.664273525884198, 8.38509136165168],
            [37.668642706847301, 8.38307064618664],
            [37.679103588330896, 8.37825154735005],
            [37.683902837736298, 8.369670781932809],
            [37.692613127309897, 8.3541107192104],
            [37.704943627478599, 8.337581222350551],
            [37.717443684657098, 8.334641436199799],
            [37.731283354503098, 8.34370070628418],
            [37.742983911078703, 8.34960122901224],
            [37.748363696736497, 8.35231092892711],
            [37.763864126964002, 8.348931300302921],
            [37.7673114118668, 8.347001499237811],
            [37.779393752438203, 8.340241009820129],
            [37.796983888595399, 8.335261392941289],
            [37.8166536246353, 8.332350476418361],
            [37.8395438209687, 8.332690454808951],
            [37.863344684421499, 8.336250210799671],
            [37.888024773958499, 8.350421140196399],
            [37.9103849642744, 8.37103056368891],
            [37.928384956779901, 8.37871035301526],
            [37.948774467941199, 8.36796104960446],
            [37.973754370204396, 8.36346060719433],
            [37.988565343451299, 8.36006996555083],
            [38.008634829792598, 8.37305023076763],
            [38.027994647059501, 8.39087017219182],
            [38.028885102084899, 8.390780191371579],
            [38.039325771474601, 8.38976040728544],
            [38.052755584972203, 8.38290067898641],
            [38.067105048741901, 8.37651074708422],
            [38.077495187896801, 8.381159899527839],
            [38.081865491754101, 8.38555015924678],
            [38.086935358638797, 8.390640221165331],
            [38.103355439138397, 8.38955045140599],
            [38.110285941555397, 8.390730202017989],
            [38.1190355324227, 8.402740792370601],
            [38.1229454496969, 8.407130808084281],
            [38.1393554241495, 8.40996007577642],
            [38.157155541504302, 8.40979012034969],
            [38.162695901019099, 8.410269994303871],
            [38.166326217661101, 8.40672979909623],
            [38.178926212415, 8.394440505444701],
            [38.181935568616801, 8.39237984728938],
            [38.198775611511699, 8.401879890242411],
            [38.217946782568902, 8.40932024319382],
            [38.238736044031697, 8.409850104626461],
            [38.2667163193438, 8.40717968449767],
            [38.282446942862798, 8.400100316173971],
            [38.289657045912001, 8.38123988445998],
            [38.3012262238773, 8.376899567219271],
            [38.311866768417701, 8.37048953998055],
            [38.312606755632999, 8.352989739694159],
            [38.3082768759636, 8.330409483020359],
            [38.307436951172903, 8.30024007616314],
            [38.322286102819398, 8.272889721945811],
            [38.331797015889997, 8.25979945968141],
            [38.334906309667197, 8.25881933937417],
            [38.347746603759603, 8.254789930323801],
            [38.3673568264119, 8.263369877358651],
            [38.375206979101002, 8.268779337498451],
            [38.382806726404603, 8.27401982013181],
            [38.401267105493297, 8.280059178781981],
            [38.422037277756203, 8.289569730715311],
            [38.431017061915099, 8.299730066245729],
            [38.433297659842701, 8.31332010409024],
            [38.441557668880201, 8.336369090980559],
            [38.448197341723898, 8.364239384087959],
            [38.448647622260097, 8.36654015523419],
            [38.452537327440297, 8.38613004308367],
            [38.4624378847654, 8.4008901281444],
            [38.466097396654099, 8.41723920783304],
            [38.468607064979103, 8.435439215910741],
            [38.4782875350596, 8.447899367969409],
            [38.501858205220799, 8.44450946448279],
            [38.513628381231001, 8.45005969393725],
            [38.527247963832302, 8.45815997550833],
            [38.548507717925098, 8.45360948863074],
            [38.5660776419884, 8.448819035207491],
            [38.577857924045503, 8.444709394086241],
            [38.584578445264803, 8.433218827482619],
            [38.591287737543098, 8.426559807211021],
            [38.604678249747003, 8.43210918386235],
            [38.617158094831602, 8.431679321063219],
            [38.640048291165101, 8.41263936324161],
            [38.654858141517899, 8.39517922929028],
            [38.662048032473201, 8.376559627351121],
            [38.6676389222227, 8.35102885103325],
            [38.666288080614201, 8.327319650542741],
            [38.658017965529801, 8.3049790255302],
            [38.651358080592203, 8.285629529939969],
            [38.6624679948685, 8.27138958660394],
            [38.6828080986892, 8.258549305800679],
            [38.702458745529299, 8.24501849824952],
            [38.7163287335161, 8.2317094927357],
            [38.717268595882103, 8.22066943550757],
            [38.715428172443801, 8.219279126657041],
            [38.6939887552942, 8.20449887559225],
            [38.682467861775301, 8.19088953356809],
            [38.679048649225201, 8.17177898705153],
            [38.681858130276197, 8.149009411133781],
            [38.6849180167128, 8.123009272776409],
            [38.679888574015798, 8.0965294190131],
            [38.673677846720203, 8.090988661576329],
            [38.667427818131003, 8.09672952519934],
            [38.654927760952397, 8.11486873767622],
            [38.644517409703603, 8.125889490853449],
            [38.635967693986998, 8.133459552472811],
            [38.630857402914501, 8.14517897086955],
            [38.624588285125398, 8.1640295850955],
            [38.6136782459998, 8.195768722392829],
            [38.604378437020898, 8.22404905484181],
            [38.601557727028798, 8.250739366001129],
            [38.594377942120502, 8.261309640288349],
            [38.580057673597501, 8.264718915652431],
            [38.561578205309097, 8.26719917830107],
            [38.549808029298902, 8.263709915452401],
            [38.542457564486597, 8.246659866794889],
            [38.542957252363401, 8.22639954587601],
            [38.5519976728039, 8.21353891103313],
            [38.5684278593505, 8.194479466469019],
            [38.581867778895003, 8.1724292097194],
            [38.587667526948103, 8.15678911583424],
            [38.5883974081164, 8.142979174473311],
            [38.588888112840401, 8.127789245148429],
            [38.5849579834724, 8.110999063192921],
            [38.584087740540902, 8.107289432203141],
            [38.593817617962003, 8.0914389065476],
            [38.607467518704198, 8.07974905010901],
            [38.622257156963101, 8.068969401676149],
            [38.616518045191697, 8.057218857138441],
            [38.6130876037005, 8.043859133158341],
            [38.628567821834103, 8.03538900018167],
            [38.627657154714797, 8.033138570196961],
            [38.625127274295899, 8.02685864822717],
            [38.610168079027098, 8.012089107932431],
            [38.611557099035103, 8.008868936711799],
            [38.613338009085901, 8.007098721243191],
            [38.598616867367397, 7.99234959193569],
            [38.596997654067799, 7.99005889221737],
            [38.592337643531401, 7.97515902256406],
            [38.589076759050101, 7.96162868329903],
            [38.587166716177201, 7.93914867227805],
            [38.576056801900798, 7.92472963419857],
            [38.567007398307403, 7.90592907470124],
            [38.5637274246263, 7.88597868003544],
            [38.550316700328501, 7.87201939373191],
            [38.5332273749422, 7.86242892464021],
            [38.5289873268012, 7.85957910438043],
            [38.514887145522799, 7.85010959625848],
            [38.513136553612902, 7.84893938010456],
            [38.503867062774901, 7.83151923321955],
            [38.500367001849099, 7.81178886915591],
            [38.492946917602303, 7.79597920878122],
            [38.469166266243498, 7.77881941659826],
            [38.445146438440197, 7.76027928783274],
            [38.443026414369697, 7.73939952512889],
            [38.439055860813902, 7.71943894712377],
            [38.426335716390803, 7.69743882589939],
            [38.412706027742502, 7.68208891717217],
            [38.411285566699398, 7.66280893982714],
            [38.418625925464802, 7.64305955127015],
            [38.420666224053903, 7.6249197131045],
            [38.4091060292413, 7.60588869514423],
            [38.402866106699001, 7.59800959093296],
            [38.399865733650103, 7.59420967179911],
            [38.401445645655997, 7.57927902576972],
            [38.419176143576301, 7.56983885928115],
            [38.426066221805499, 7.55444875205874],
            [38.429945820938798, 7.53447953252381],
            [38.427375516332098, 7.51542893629314],
            [38.414685690049801, 7.50534947125136],
            [38.406155063532999, 7.50447889018928],
            [38.392555693025599, 7.50309953887756],
            [38.379185392915502, 7.49599898327679],
            [38.362585649359097, 7.48890944504751],
            [38.3420546535406, 7.47975900532347],
            [38.326365577103402, 7.4632590052182],
            [38.3279746843561, 7.46026956608417],
            [38.3281947716007, 7.45636934719047],
            [38.325175309351899, 7.4338792261529],
            [38.319384544451701, 7.41482980822835],
            [38.309695091218401, 7.40267908246561],
            [38.293314312012399, 7.39259924905467],
            [38.279484748213399, 7.38113945063133],
            [38.2771547429452, 7.36116900418914],
            [38.281504834708599, 7.33866957601949],
            [38.276184562438402, 7.31961940061209],
            [38.266034722621903, 7.30791937177885],
            [38.247364362335603, 7.29783961527537],
            [38.234454448808698, 7.28912963171808],
            [38.2208539554072, 7.27673933264577],
            [38.1994145382575, 7.26274915548448],
            [38.181904127581802, 7.25909893968576],
            [38.164613804150697, 7.24624887515608],
            [38.144793600300801, 7.23340958375115],
            [38.125663976325399, 7.2230897636058],
            [38.105153192600802, 7.21207902217154],
            [38.101033294129003, 7.20829917027958],
            [38.087633798772202, 7.19600914216673],
            [38.078413715274799, 7.18085892962287],
            [38.076793379081103, 7.15467936765145],
            [38.069872982711097, 7.12298959440131],
            [38.066863626509303, 7.08830905567721],
            [38.064503303100302, 7.07310958975824],
            [38.063402866877198, 7.06603899314801],
            [38.0645527104409, 7.04398962700776],
            [38.073762687891303, 7.0302096335727],
            [38.088272725518102, 7.02354962121244],
            [38.102313393408799, 7.01137947279111],
            [38.109223683731898, 7.00103905472323],
            [38.1094527541294, 6.99942968255393],
            [38.117512888016101, 6.98151891300213],
            [38.127872709030299, 6.97416939189413],
            [38.131052745136003, 6.97481919496701],
            [38.146983243805799, 6.97806931138258],
            [38.170473065591402, 7.00147929060319],
            [38.194193080668597, 7.01294868195484],
            [38.216763252182098, 7.01500937544726],
            [38.238403667376502, 7.02050934289331],
            [38.2423012328155, 7.02929466764996],
            [38.242494370601598, 7.02972930454924],
            [38.247163364290799, 7.04024850791104],
            [38.254774340535498, 7.06044930073917],
            [38.272293734363998, 7.08407910798921],
            [38.289584057795103, 7.1077088184679],
            [38.305944624907198, 7.12238902516747],
            [38.314254041285302, 7.14831863687047],
            [38.323245054385197, 7.1639289531901],
            [38.342594765605099, 7.16504865058602],
            [38.357314784429498, 7.14644906209177],
            [38.378474600947001, 7.13035903851604],
            [38.401034666413501, 7.12206924122851],
            [38.418765164333799, 7.12273889305426],
            [38.423865349359403, 7.12370492815795],
            [38.440514500256498, 7.12685929158054],
            [38.445245253121499, 7.12775846502614],
            [38.460425658528997, 7.11878893596252],
            [38.464315363709197, 7.10042926758154],
            [38.480425525435798, 7.09099899694388],
            [38.505505365274303, 7.08843831584876],
            [38.528535923370903, 7.09162857910604],
            [38.546222628421098, 7.09159960720242],
            [38.547175965516402, 7.0915984928984],
            [38.566765976074798, 7.1129190920592],
            [38.584046193459002, 7.12688826126057],
            [38.611896213054898, 7.12477904092364],
            [38.630746236397997, 7.11144827658802],
            [38.644076112320299, 7.09673874866356],
            [38.640826456779997, 7.07861889685215],
            [38.637355591100999, 7.06669869720339],
            [38.6539160333637, 7.06230808155041],
            [38.668405858896499, 7.06045821577289],
            [38.681276471129799, 7.04895879393204],
            [38.685166176309998, 7.03472871410384],
            [38.6784860792785, 7.02969809986233],
            [38.674786143201999, 7.01707786120364],
            [38.677278968115402, 7.00942129740879],
            [38.677996497448603, 7.00721792232817],
            [38.689716143224103, 7.00122852338072],
            [38.697556189866198, 7.01475861550418],
            [38.699845770946602, 7.01455800745249],
            [38.710906277882401, 7.013588400651],
            [38.714785877015601, 6.99201802858054],
            [38.711766414766899, 6.97435775685711],
            [38.7011764004612, 6.9697879346106],
            [38.695175654363297, 6.95809790367712],
            [38.701606468903499, 6.94569866261331],
            [38.705035787500599, 6.93123804169287],
            [38.7022555016963, 6.91563773001114],
            [38.700165795766601, 6.90394858428992],
            [38.705206467404601, 6.88604867208143],
            [38.711395859712198, 6.87181811107822],
            [38.712525491181999, 6.86102757248972],
            [38.699175403165903, 6.84980756111007],
            [38.683986014605502, 6.84156853331495],
            [38.670646032636299, 6.83423802034102],
            [38.666715903268297, 6.83151761886219],
            [38.6630451624386, 6.82896778588592],
            [38.6561461010565, 6.82461843295529],
            [38.6485250187649, 6.81934809256833],
            [38.640256026574598, 6.81362836414321],
            [38.636795266942499, 6.80031774575055],
            [38.637526271004901, 6.7881552332275],
            [38.637705934061799, 6.7851781137397],
            [38.636075491821103, 6.77232834276745],
            [38.643895326369297, 6.76933774473179],
            [38.658615345193702, 6.77023760224206],
            [38.672405607699098, 6.76288815928911],
            [38.678825193298202, 6.74957836927114],
            [38.688945837867898, 6.74497851171243],
            [38.705955437772701, 6.74265793669229],
            [38.721345824377899, 6.7307181182732],
            [38.727765409977103, 6.71258758892479],
            [38.742695409999101, 6.70155816909502],
            [38.754646371960199, 6.69764818542471],
            [38.779705999704902, 6.70082769820043],
            [38.810285774870401, 6.70193734439515],
            [38.818335802710202, 6.70422800302457],
            [38.833946276559999, 6.69227720412409],
            [38.839676405178601, 6.67644718694937],
            [38.855526055472701, 6.66931715493345],
            [38.877356239770997, 6.66882753997957],
            [38.902176691071197, 6.6662779653668],
            [38.918256534656898, 6.65982701396301],
            [38.933486347405101, 6.65638736412568],
            [38.9366371882641, 6.65567801699813],
            [38.950407238675602, 6.64625787924616],
            [38.949256272217802, 6.64258725573943],
            [38.944866879160699, 6.63089706877078],
            [38.9420865933564, 6.61095696995436],
            [38.959306174458902, 6.60152705410726],
            [38.960686211314098, 6.60169437142636],
            [38.983666239176003, 6.60447740787356],
            [39.011707150769801, 6.60971772820312],
            [39.032607578748902, 6.61242710150463],
            [39.0597176111295, 6.6094076384222],
            [39.065217546456601, 6.59977691156048],
            [39.0532474952957, 6.58465783199452],
            [39.0488569793445, 6.57044744057402],
            [39.052976877816299, 6.55760765308964],
            [39.070197581812899, 6.55391740794155],
            [39.0853869703733, 6.54640742932911],
            [39.087867443451501, 6.54517695117419],
            [39.097026890667202, 6.52223684785948],
            [39.096297009498898, 6.49518663724333],
            [39.095807427669001, 6.47271732269058],
            [39.100377606677, 6.45620750181277],
            [39.107936929792899, 6.43831688477958],
            [39.112266809462298, 6.41859675352776],
            [39.117067181761797, 6.39841722417011],
            [39.121637360769803, 6.38121645853174],
            [39.125056573320002, 6.36126646274963],
            [39.127797557830597, 6.34498739759713],
            [39.127176597390502, 6.336486635129],
            [39.126157009543, 6.32252709061607],
            [39.120167492386202, 6.29983700488824],
            [39.110287147154899, 6.28861659718753],
            [39.0917156015499, 6.2721097800207],
            [39.091217036567201, 6.27166665925223],
            [39.0863762400799, 6.2526467129033],
            [39.094397072672997, 6.23452689167674],
            [39.100117095244599, 6.21916702601211],
            [39.104006800424798, 6.20288686462058],
            [39.113616528176699, 6.17719636401034],
            [39.130827126126299, 6.16663647297121],
            [39.132656320623603, 6.15930616169519],
            [39.103736182945497, 6.15863631400797],
            [39.0805562799329, 6.15429679694211],
            [39.0564567266482, 6.14720634948614],
            [39.0353261053775, 6.13873698702889],
            [39.012156308411797, 6.14356675070411],
            [38.988966299352299, 6.14839647055735],
            [38.961436304576303, 6.16056658946866],
            [38.950885591564401, 6.18166742473557],
            [38.9430556509691, 6.19320716381981],
            [38.937345734444499, 6.20162653767005],
            [38.926315545649601, 6.22159717420269],
            [38.921755472688602, 6.22984753833617],
            [38.898575569675998, 6.25989759105936],
            [38.886415749411299, 6.28605728793049],
            [38.874945386127202, 6.3021172192445],
            [38.8533757132614, 6.32161749735888],
            [38.835005165701098, 6.34364706087347],
            [38.833647586727999, 6.34448184218993],
            [38.8129661230994, 6.35719758174256],
            [38.795055962122198, 6.37509773881633],
            [38.784264949771803, 6.3893270252395],
            [38.772325216751703, 6.40103734970145],
            [38.767315986148603, 6.40447763891938],
            [38.759945309242397, 6.40954708544122],
            [38.757635516068099, 6.41113720521683],
            [38.739485055752503, 6.41022776889947],
            [38.718335345282, 6.39830786599804],
            [38.707994613467697, 6.39372819255811],
            [38.696745460322298, 6.40244783760773],
            [38.692375156465097, 6.40703747579381],
            [38.690534733026702, 6.40795807658688],
            [38.672844659294199, 6.41025789749056],
            [38.662284963129402, 6.42035757038889],
            [38.656124766068501, 6.42244755613634],
            [38.648035436934997, 6.42518806902398],
            [38.6449721818162, 6.42415814673411],
            [38.627575183445103, 6.41830774688336],
            [38.6057449991469, 6.42450740555573],
            [38.584145008140197, 6.43828787366201],
            [38.567374584679797, 6.44172791116037],
            [38.540934920079998, 6.438068058602],
            [38.535884142394998, 6.438068058602],
            [38.504623893401799, 6.43761838587314],
            [38.476113612071998, 6.4316476315816],
            [38.463364272402202, 6.42363816350768],
            [38.455654481476202, 6.41879760746973],
            [38.454991973954201, 6.41821401496349],
            [38.441864218970899, 6.40663799032682],
            [38.427613569882404, 6.38597814005757],
            [38.426233533027201, 6.36669791113542],
            [38.423723864702197, 6.35742859148253],
            [38.422914258052401, 6.35444777667633],
            [38.421873335216901, 6.35062771059044],
            [38.423943951946796, 6.32629825835064],
            [38.425334094848999, 6.30724794392268],
            [38.427863975267897, 6.30082811250621],
            [38.425793358538002, 6.29072832477741],
            [38.4333841226888, 6.26616835864707],
            [38.436383372843601, 6.24114734627125],
            [38.439373639845599, 6.21751826955789],
            [38.435243635326898, 6.19937825873016],
            [38.414113014056198, 6.19041742122436],
            [38.3950429034685, 6.18306734459624],
            [38.377593129074398, 6.16560827062773],
            [38.364503552490703, 6.14126798515295],
            [38.362913534437801, 6.12083767544682],
            [38.355512539390801, 6.10461813935902],
            [38.352122522087299, 6.09718760336127],
            [38.3378527837991, 6.07611804113626],
            [38.333763203468102, 6.07008732715897],
            [38.320692716084203, 6.04872749425234],
            [38.316803010904003, 6.0303572959559],
            [38.299343130463001, 6.02024789387871],
            [38.280063038677604, 6.00759775523323],
            [38.2718120127929, 5.98600764072333],
            [38.283082500926298, 5.97201766638424],
            [38.2964022708016, 5.97524744235646],
            [38.304213122196998, 5.98168795185923],
            [38.321673002638001, 5.97572766329788],
            [38.328353099669499, 5.95668713890678],
            [38.340533132028099, 5.95692725755886],
            [38.3566129756138, 5.95028765894609],
            [38.374082962101703, 5.94547738996854],
            [38.389023068170701, 5.93470746186851],
            [38.397303289302101, 5.92070724598841],
            [38.381702921499297, 5.90531720966649],
            [38.358963192975899, 5.90345750941523],
            [38.3502427973553, 5.89357809627189],
            [38.340612857509498, 5.88415758534609],
            [38.326152227223403, 5.87013710332092],
            [38.304572448310701, 5.86276704942642],
            [38.291012379096898, 5.86481789566497],
            [38.287802024850301, 5.86595725142837],
            [38.271252811528598, 5.86984779870781],
            [38.256602412138697, 5.86063800390815],
            [38.254042213578899, 5.85902725158798],
            [38.240052075922897, 5.84569759525767],
            [38.224202425628697, 5.8399380309626],
            [38.208812039023499, 5.84151756050317],
            [38.199151781036903, 5.84977818164512],
            [38.199271930706203, 5.85177769161823],
            [38.200052342109302, 5.8653875738376],
            [38.209682281954997, 5.88032745527111],
            [38.215632497818198, 5.89456772093952],
            [38.2126119126753, 5.91798757771217],
            [38.207518465014402, 5.9295832118625],
            [38.204551778788598, 5.93633809813137],
            [38.1817918381713, 5.94296781339425],
            [38.170971630574101, 5.95833781291563],
            [38.1633718832705, 5.97692821380808],
            [38.160142439824, 5.98633820495915],
            [38.151161532771098, 6.00171815479089],
            [38.139651868193297, 6.01157775642115],
            [38.122412074996902, 6.0179876858988],
            [38.119861982484103, 6.03451803126742],
            [38.117311889971297, 6.05150792704757],
            [38.105802225393496, 6.0673382549857],
            [38.100961428906203, 6.07857787415372],
            [38.097102041866798, 6.08767793028094],
            [38.092891188972501, 6.09762863729827],
            [38.080681961367198, 6.1182887164285],
            [38.083872103520001, 6.13849806554373],
            [38.087062245672698, 6.16169862745103],
            [38.101762052403203, 6.17939784981738],
            [38.1020450217177, 6.17918685588478],
            [38.117401721499697, 6.1676981651556],
            [38.125461855386497, 6.15576823994015],
            [38.141781998310698, 6.15509838779083],
            [38.158791598215501, 6.16016803114282],
            [38.171432017157201, 6.16752847317211],
            [38.187962141279101, 6.18291775206067],
            [38.198741924688498, 6.20772832285334],
            [38.194132444386902, 6.22746766037356],
            [38.179872812145597, 6.23962814295715],
            [38.172732328530998, 6.25661818849149],
            [38.1745626459224, 6.2680982397403],
            [38.179151914130102, 6.2770577503154],
            [38.1825924616683, 6.28555837183738],
            [38.189242240558997, 6.30553808003735],
            [38.198202935518097, 6.32000812721055],
            [38.205802682821698, 6.32511858557646],
            [38.214292885150797, 6.33080822186524],
            [38.231762871638701, 6.3317378877465],
            [38.247622627979801, 6.33679800857778],
            [38.250382701690299, 6.33702791143369],
            [38.272912449015998, 6.34851845949955],
            [38.2878435719322, 6.35816849154543],
            [38.289986053884803, 6.36339686510855],
            [38.294053176333598, 6.37331779478512],
            [38.293582683703598, 6.39719866035867],
            [38.2965673362351, 6.41424155107701],
            [38.297483617824902, 6.41946823589111],
            [38.283683249272599, 6.43874870391042],
            [38.2609030965614, 6.45342810411301],
            [38.255613142431997, 6.45929818306464],
            [38.251923312402504, 6.46338968043804],
            [38.246413271028601, 6.46949841297158],
            [38.2222732935561, 6.46236876177564],
            [38.214941917943499, 6.46130321059159],
            [38.214912722696802, 6.46129874754526],
            [38.193752906179398, 6.45822816234096],
            [38.171442123204201, 6.45109835234046],
            [38.156042753446101, 6.44052841970757],
            [38.144311878729603, 6.45946220063984],
            [38.147284179426002, 6.47729739976526],
            [38.1505046397195, 6.49785646832506],
            [38.1401010258353, 6.50726937857301],
            [38.115329981875703, 6.50082863302039],
            [38.101954067295203, 6.51395771478893],
            [38.096009465902497, 6.52287498584639],
            [38.091550453411003, 6.52733300407451],
            [38.074211845533398, 6.53030610978814],
            [38.052413102270101, 6.53600349040727],
            [38.034331138494899, 6.56275476317267],
            [38.016744371020003, 6.57687390310434],
            [38.006587793839003, 6.57662626106034],
            [37.997670891749998, 6.57687390310434],
            [37.9842219890526, 6.55820223961045],
            [37.969582818603797, 6.55021709540959],
            [37.953479394241803, 6.55088308675712],
            [37.933516582840497, 6.55846885509954],
            [37.919276039799001, 6.57563680715755],
            [37.916496876888701, 6.58177986734221],
            [37.911690890118699, 6.5924059437948],
            [37.893590960037798, 6.59732960179605],
            [37.872297519121801, 6.58348880030283],
            [37.855240757664603, 6.55903889861655],
            [37.843290918597603, 6.54294914212298],
            [37.824210701962897, 6.52179952205654],
            [37.814480824541803, 6.50941925048949],
            [37.808120752330197, 6.50132957046027],
            [37.800321129875897, 6.47880920658252],
            [37.796680707187001, 6.45008856675502],
            [37.797850762844497, 6.42986900050125],
            [37.808230795952497, 6.41080913880592],
            [37.819990865915798, 6.39405850176198],
            [37.840480314652403, 6.37937850681062],
            [37.8458106929696, 6.35250928919122],
            [37.848601084820899, 6.33229925781027],
            [37.850680684703597, 6.32747904448573],
            [37.852550303388703, 6.3054186572185],
            [37.851674445986703, 6.29988722998198],
            [37.848440510963798, 6.2794586095613],
            [37.840661100603299, 6.24888840567033],
            [37.826210576364197, 6.22175903484524],
            [37.8106102085613, 6.1973889592443],
            [37.791099923484403, 6.17345872921115],
            [37.778489822683497, 6.15023858516314],
            [37.775089699333201, 6.12449864261569],
            [37.776269861037697, 6.11443901721836],
            [37.778120390523, 6.09876862460449],
            [37.785530368722803, 6.07327855581801],
            [37.7901701671653, 6.05008867980519],
            [37.786759937767997, 6.02939806662008],
            [37.7835697956152, 6.01124832420665],
            [37.791880334887502, 5.99655879502472],
            [37.8019998565631, 5.99289807682882],
            [37.806400478561102, 5.99497859173882],
            [37.813720625232598, 5.99843828173546],
            [37.8274906756441, 6.01063859490377],
            [37.843360538032101, 6.01158892361136],
            [37.856940819339798, 5.99965888371728],
            [37.865930709545601, 5.98727852510391],
            [37.8808809216616, 5.98568824400822],
            [37.895380853241399, 5.97514804773689],
            [37.904370743447203, 5.96115779593324],
            [37.923220766790401, 5.96072781926086],
            [37.943210527650201, 5.9635187527475],
            [37.946891374526899, 5.96375775159372],
            [37.953090872881397, 5.9639978671521],
            [37.968970841316498, 5.95666815277632],
            [37.977980943616203, 5.92842835033948],
            [37.9793912986122, 5.90822793518325],
            [37.982871147444101, 5.88847803869337],
            [37.996700711243101, 5.86759814304038],
            [38.0075310248873, 5.84992786587952],
            [38.009410749619299, 5.82719774488461],
            [38.012670511206601, 5.80492808089791],
            [38.020271381404299, 5.79069782405752],
            [38.039610986577301, 5.77304747315261],
            [38.0545510926463, 5.7648080711056],
            [38.073170922697898, 5.76300823461163],
            [38.089281084424499, 5.74626758282916],
            [38.087481085173899, 5.72191768131179],
            [38.080861624424102, 5.7016975589929],
            [38.075600865541404, 5.68951732033729],
            [38.056320773755999, 5.68167785219457],
            [38.041631073072502, 5.67498807342085],
            [38.024890967752903, 5.66140714809759],
            [38.008830213366899, 5.65149781012273],
            [37.978280756342301, 5.65074801005282],
            [37.964550007224503, 5.64703810570652],
            [37.944300857826299, 5.64079826121788],
            [37.930570108708601, 5.61733790210119],
            [37.9375006111255, 5.598057325332],
            [37.942350390765696, 5.58657785521431],
            [37.940520073374302, 5.58496744814851],
            [37.941930428370398, 5.56911794571956],
            [37.948210775100399, 5.53835760577392],
            [37.949650325343299, 5.50826740791981],
            [37.9472102764528, 5.4749576307145],
            [37.940609904902701, 5.45059754176347],
            [37.935619763499403, 5.42370785502276],
            [37.919619645395102, 5.39655761335717],
            [37.901529821361201, 5.37399762915092],
            [37.896769873249497, 5.35055694280224],
            [37.901429883785802, 5.3275972351008],
            [37.905170244050097, 5.30463666939555],
            [37.904779476901503, 5.28005731817025],
            [37.902830132734898, 5.26006707838345],
            [37.901429883785802, 5.24558666798267],
            [37.887509365564298, 5.23671711085262],
            [37.883319847658001, 5.23404682618313],
            [37.853469953660799, 5.23602717658462],
            [37.827939833286102, 5.25111721810282],
            [37.810889809193498, 5.26967763585548],
            [37.793418699811497, 5.27559708384103],
            [37.773638920149303, 5.28472772719581],
            [37.747939242764701, 5.27798765714418],
            [37.729749481155402, 5.29355746577689],
            [37.701688357467702, 5.30725745506121],
            [37.675038711670197, 5.30901731085874],
            [37.655818133272398, 5.30318762953889],
            [37.6474986108473, 5.30065739206599],
            [37.6376789018978, 5.28384776534256],
            [37.630148774028697, 5.26658706673811],
            [37.601018655152899, 5.25661752852687],
            [37.5803686325592, 5.24895800592742],
            [37.578318227923198, 5.24297792200882],
            [37.552158163955497, 5.23530704989154],
            [37.518667847269398, 5.22185707795613],
            [37.492077714859498, 5.20429720670214],
            [37.466387020627799, 5.19455704245501],
            [37.442317785483901, 5.18136788213533],
            [37.418017234154398, 5.16793800570558],
            [37.367976581252499, 5.15648732659527],
            [37.3415369166527, 5.160987549782],
            [37.318047094866998, 5.1749174616202],
            [37.293157024132299, 5.19574801206811],
            [37.263966268974798, 5.19838826625311],
            [37.238906641230201, 5.20081827440604],
            [37.217706400525003, 5.21406741282468],
            [37.197955816109499, 5.21169784147794],
            [37.182626065786003, 5.19530740948766],
            [37.146106180804203, 5.19032768429332],
            [37.113025720466503, 5.18765832069043],
            [37.082915315036999, 5.18776791355331],
            [37.0530160136991, 5.19315806964066],
            [37.023345782758803, 5.19785820340371],
            [37.006534935110601, 5.20109784216072],
            [36.991384847843896, 5.19955798142654],
            [36.982039000206797, 5.19294000405417],
            [36.949738951272103, 5.19607343154266],
            [36.921567783962097, 5.14572423804528],
            [36.8933505779939, 5.11067024680027],
            [36.882569671690298, 5.07567361741503],
            [36.871823575104003, 5.02975234316201],
            [36.868051773804801, 5.0079454527327],
            [36.861092076141098, 4.97946172890521],
            [36.865181656472103, 4.94489434705883],
            [36.861232437904299, 4.93576109679634],
            [36.863858887216203, 4.79374226665948],
            [36.855322646228899, 4.73690426100805],
            [36.8292681343071, 4.71060364295907],
            [36.646335209848999, 4.71219276484643],
            [36.681527833998501, 4.60525696502026],
            [36.6576562282171, 4.58114638661076],
            [36.6918741802833, 4.49622405326623],
            [36.712963254472101, 4.43996675263156],
          ],
          [
            [38.572877888689199, 6.7912427208415],
            [38.572905961041798, 6.79128174637975],
            [38.5729048381477, 6.79128174637975],
            [38.572877888689199, 6.7912427208415],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 7,
        REGIONNAME: "Gambela",
        latitude:13.833271,
        longtiude: 39.344733,
        RID: "12",
        GlobalID: "cc5adc24-1560-4472-b8e1-ec2abbe67ca3",
        Shape__Area: 31410022759.4109,
        Shape__Length: 1213089.07525557,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.312404124209301, 8.68208614179475],
            [34.345446491487401, 8.60008626116873],
            [34.353561150416901, 8.55565911430781],
            [34.358198509933402, 8.52202977523738],
            [34.357407982601899, 8.499914470038521],
            [34.344032312363197, 8.46922788936075],
            [34.334885835023499, 8.438592385471839],
            [34.315182938573201, 8.40677903902332],
            [34.313271529362801, 8.3899146787942],
            [34.3291625839068, 8.38694573599598],
            [34.3472552798547, 8.37663350319739],
            [34.394891470626, 8.400770473178319],
            [34.436327817191497, 8.42176047412592],
            [34.471171085628697, 8.44756296292028],
            [34.522662316491797, 8.47553116565677],
            [34.556501988890197, 8.45512034508687],
            [34.574248744520503, 8.403465755348799],
            [34.6303111703862, 8.401986930140421],
            [34.667401982341701, 8.395025790797799],
            [34.729807856196302, 8.389388945712319],
            [34.767615230897803, 8.363365285684409],
            [34.785225786659602, 8.355228846963771],
            [34.795047407224097, 8.326895367641439],
            [34.785778687140599, 8.30362549764774],
            [34.826402491361897, 8.26192170473022],
            [34.855070219513202, 8.250633263549791],
            [34.882559873644702, 8.250917002286579],
            [34.925922630387397, 8.249028736539961],
            [34.964767530495998, 8.24733309930394],
            [35.018234388434202, 8.21962977923452],
            [35.073221500362102, 8.220170958222379],
            [35.118889017916899, 8.20059494993448],
            [35.138106995691999, 8.181813194131481],
            [35.157634850019299, 8.13669609192789],
            [35.143246766777203, 8.14283933859903],
            [35.103854311163701, 8.11510378412803],
            [35.089063245481803, 8.113908231251219],
            [35.117911784736798, 8.082574302111871],
            [35.140691946431097, 8.021675491321609],
            [35.140999646365401, 7.98901357538957],
            [35.108533509714498, 7.95709906932053],
            [35.048316073825902, 7.95442245680482],
            [35.008455560915003, 7.92348806121054],
            [35.013076828909803, 7.88244388621489],
            [35.000590758500202, 7.86230757564357],
            [34.950662096768198, 7.89027239506988],
            [34.944250613972898, 7.87937923304679],
            [34.9322518158246, 7.82794012549873],
            [34.937087410168097, 7.76477933533829],
            [34.931953019991397, 7.72815448957584],
            [34.995448745220003, 7.74796011808355],
            [35.038481483385297, 7.73949865916007],
            [35.0770713760392, 7.73099111913433],
            [35.116265521367303, 7.64582677324879],
            [35.116390825569702, 7.63196806664554],
            [35.146413790786099, 7.63141252496259],
            [35.1670605506987, 7.61604443888049],
            [35.165461622256501, 7.58158421598863],
            [35.170870716348801, 7.55632625519627],
            [35.172712768432703, 7.53342381053151],
            [35.161281058756998, 7.52759406566192],
            [35.146583761919402, 7.52009846321534],
            [35.138447840765302, 7.51266042911171],
            [35.127111635478997, 7.49619087837233],
            [35.121472753235402, 7.4846825077226],
            [35.123156194281201, 7.47978647541112],
            [35.162167864825697, 7.47254376867182],
            [35.183507977712203, 7.46000373058408],
            [35.190008010139998, 7.45681371603658],
            [35.210268142340801, 7.47305371826443],
            [35.244664547433104, 7.47486055123275],
            [35.254464336241298, 7.47956552739585],
            [35.262778615199998, 7.48166893025769],
            [35.273361047724698, 7.47266368885831],
            [35.3103130717654, 7.48036359891228],
            [35.320813618360397, 7.46419503193203],
            [35.329709499871697, 7.46279120933305],
            [35.3460697974892, 7.45405952564853],
            [35.356568339942697, 7.43788914589388],
            [35.374407973288598, 7.42916785592684],
            [35.368594969695103, 7.41581891511076],
            [35.3930585113498, 7.39737555448038],
            [35.388303564159301, 7.35186391970733],
            [35.383515859901898, 7.33545097261554],
            [35.381188111201702, 7.31905792206547],
            [35.375672850615899, 7.29117738057253],
            [35.370858901179197, 7.27803942479991],
            [35.353760665403698, 7.26316552637729],
            [35.346567140763099, 7.24018441563908],
            [35.3736858346997, 7.23221432983694],
            [35.348541858743097, 7.19844716175742],
            [35.340430095083697, 7.1877394725817],
            [35.334679192924199, 7.18933084726018],
            [35.334744840008497, 7.18114460364573],
            [35.353805855154, 7.15509836745836],
            [35.369456753191599, 7.145397618789],
            [35.381248733110397, 7.12378303362276],
            [35.372134918514398, 7.11758253094711],
            [35.354910813496197, 7.11908450223389],
            [35.331148457226398, 7.12769308993932],
            [35.313970262407402, 7.11585512070353],
            [35.300386221650299, 7.1165794526284],
            [35.2912065093401, 7.12268419512756],
            [35.280233197377399, 7.12998115538038],
            [35.264988095303103, 7.13683317226664],
            [35.235027929511602, 7.13890320854229],
            [35.225272614496603, 7.12169880769176],
            [35.218814120167501, 7.1085282464352],
            [35.204929344114497, 7.10021617211941],
            [35.157310275232398, 7.10228328307821],
            [35.145821294364097, 7.10218786876733],
            [35.114630828298701, 7.1027471639701],
            [35.0840742440406, 7.12544248212935],
            [35.0683470062718, 7.15619340773141],
            [35.054147894429498, 7.18465778898497],
            [35.021176737502401, 7.18638041407775],
            [35.011250076931702, 7.19531036162415],
            [34.978257612864297, 7.2138729945651],
            [34.970547441950998, 7.23511545137032],
            [34.966280313197402, 7.21587322737756],
            [34.942811683567598, 7.1935067749942],
            [34.898508475164, 7.18572606673966],
            [34.8913393469699, 7.16055059579116],
            [34.8934238965699, 7.09262099318071],
            [34.877356358106901, 7.07032185888634],
            [34.885088324843899, 7.03198510632003],
            [34.902829355511003, 7.03214082583592],
            [34.912073911811603, 6.98938369201622],
            [34.946457109872703, 6.94536403640482],
            [34.974707499607597, 6.92640028850998],
            [34.9986332540376, 6.89410125349368],
            [34.9929685236701, 6.86450728314064],
            [34.969356946027503, 6.85987735509919],
            [34.945983476732401, 6.82718099130481],
            [34.934409664358498, 6.79753904149457],
            [34.986120852210497, 6.7994482955396],
            [35.043662801552401, 6.81174184671914],
            [35.080613820378403, 6.81204329646685],
            [35.117792027631303, 6.78427047443355],
            [35.147529683422, 6.76234389062266],
            [35.180313714088904, 6.72861663700575],
            [35.178974277491299, 6.71087439849921],
            [35.198416653526003, 6.68147205081951],
            [35.188254434026703, 6.65775079423844],
            [35.1870044292071, 6.62818787803732],
            [35.196096938459398, 6.59870411953208],
            [35.187535661425102, 6.55874167019932],
            [35.186331695263902, 6.49102194916812],
            [35.174605286971001, 6.46779777067202],
            [35.187381576997701, 6.44555119257833],
            [35.200239237321199, 6.41213274488193],
            [35.178864464736002, 6.37990153207504],
            [35.165150400926301, 6.38340312549497],
            [35.143785999391199, 6.39837805341462],
            [35.131913330098598, 6.39648701596332],
            [35.1196728644777, 6.34774907397547],
            [35.106558413543702, 6.31954461071491],
            [35.083807698624398, 6.31869315960084],
            [35.0738259093431, 6.30804708504621],
            [35.038631484071502, 6.30526369412775],
            [35.011652386533399, 6.28089780005507],
            [34.985332773686402, 6.29944143991607],
            [34.987878471640897, 6.34566295098321],
            [34.986731173004401, 6.35430080453849],
            [34.986481753969102, 6.38636387650457],
            [34.998298344133403, 6.39510283628968],
            [35.0029239231433, 6.40234461859197],
            [35.003548737355999, 6.4145992820921],
            [35.013756911970297, 6.44494341887582],
            [35.006559423962699, 6.464258458951],
            [34.9842074164008, 6.4891967066659],
            [34.956028030404298, 6.50374504228606],
            [34.954432691629897, 6.51850490603814],
            [34.939436597162498, 6.54645179074311],
            [34.920151437082303, 6.5566357817108],
            [34.905260584742003, 6.57128605123687],
            [34.884533763378002, 6.57702440783095],
            [34.888806544331402, 6.59626245851529],
            [34.856213182880097, 6.60780903462047],
            [34.844470909440901, 6.59884866289122],
            [34.806057438722299, 6.60000354923398],
            [34.788471773480303, 6.58360851909089],
            [34.772113885144499, 6.59676254077406],
            [34.746767591518001, 6.62460743756879],
            [34.730318159464801, 6.64809566928004],
            [34.715407986159299, 6.6642116474286],
            [34.707739665958499, 6.69663465811982],
            [34.6811015706989, 6.70230792417544],
            [34.649809414445102, 6.73304181842563],
            [34.628985610962999, 6.749098939699],
            [34.612884695595703, 6.73271128258008],
            [34.561125999441501, 6.73962586755526],
            [34.535897798342297, 6.75268375445248],
            [34.535748519003299, 6.76892356707515],
            [34.533876170338097, 6.81172387700502],
            [34.530633972611298, 6.84269950003695],
            [34.520100915986198, 6.86327150202912],
            [34.512631983150797, 6.87206010887028],
            [34.484258465831502, 6.90427311169237],
            [34.442642399615401, 6.93044963137436],
            [34.420341661601697, 6.94499719375243],
            [34.409929529891997, 6.95227741162577],
            [34.383224617043197, 6.96382669814345],
            [34.359589917663499, 6.96359494294221],
            [34.322559705158703, 6.97356172021135],
            [34.286873415438102, 6.99682165062553],
            [34.255600913424402, 7.02159713803572],
            [34.232027857337798, 7.01545425847182],
            [34.215462967516103, 7.04627747685834],
            [34.193215442106599, 7.05490338099441],
            [34.192802434468199, 7.09474476145644],
            [34.192587343653202, 7.11540321916146],
            [34.1923561783845, 7.13753725334263],
            [34.165562612597, 7.15644102728654],
            [34.144880195855798, 7.15622294956168],
            [34.128426048545698, 7.17523192719308],
            [34.099038877649697, 7.17942995966441],
            [34.099038887531101, 7.18571867997819],
            [34.092009556958203, 7.19644650622309],
            [34.076471019297998, 7.21013374432922],
            [34.054273094366899, 7.22049167164849],
            [34.0439140557854, 7.2212315373152],
            [34.033925004523297, 7.23528869156982],
            [34.019126399281298, 7.24934585355814],
            [34.011727101151799, 7.25970375698338],
            [34.010247254282, 7.26969172665561],
            [34.018016531645998, 7.26784209110599],
            [34.026895705391397, 7.26599245239682],
            [34.032815171922401, 7.27524056086279],
            [34.0350349961261, 7.29373679217291],
            [34.031335373561603, 7.31556235584485],
            [34.030623597039501, 7.33647729504093],
            [34.033185265243098, 7.35403452340767],
            [34.028745701277401, 7.36957136816421],
            [34.012467247368903, 7.39250672732232],
            [34.004697993360999, 7.40804357833551],
            [33.998038627649599, 7.41840148060348],
            [33.9921527096012, 7.42398568949299],
            [33.984719891735203, 7.43615789011075],
            [33.957712411925698, 7.44725567638008],
            [33.956602540697297, 7.46316244119789],
            [33.945133612022701, 7.4679714821718],
            [33.942937439562201, 7.481136927086],
            [33.943505425451697, 7.49240836300956],
            [33.947470846932298, 7.50077353163825],
            [33.952856996255498, 7.51025931541595],
            [33.970523241276801, 7.53219625879311],
            [33.946906003349604, 7.50345895616887],
            [33.926502652742997, 7.51195945005733],
            [33.903548874552101, 7.51790981057183],
            [33.887396200952999, 7.51195951507155],
            [33.871243547116798, 7.51790986400744],
            [33.8559410633572, 7.54171117919293],
            [33.840365751364203, 7.55852651558312],
            [33.833837461530898, 7.56806264585862],
            [33.816419060798097, 7.58331593265565],
            [33.793030794453699, 7.60461469553361],
            [33.777728268473197, 7.60376467473812],
            [33.7692268785256, 7.61141510400685],
            [33.752457294091698, 7.61646736716212],
            [33.736921575344901, 7.62756603389366],
            [33.732955303159699, 7.63982595797569],
            [33.719918810721097, 7.65136735464181],
            [33.712267556714004, 7.65646764509121],
            [33.694273946738598, 7.65999644505678],
            [33.690163931531501, 7.66921837386394],
            [33.6816625352957, 7.67346861915613],
            [33.683653190287203, 7.68198456972234],
            [33.672311022882397, 7.69216965052318],
            [33.664328976848303, 7.6905918657416],
            [33.652505370841702, 7.69044431013732],
            [33.636229726863398, 7.69171517448482],
            [33.630206548934503, 7.7004866476444],
            [33.622723781145403, 7.70776500153259],
            [33.612453139482803, 7.70173771919185],
            [33.599154564458402, 7.70555351702518],
            [33.585908224671101, 7.69697908628577],
            [33.580128413827403, 7.68658518704946],
            [33.562301629983303, 7.69373087741786],
            [33.549991601521697, 7.70172314286518],
            [33.540414405359499, 7.70363838724239],
            [33.530837219977101, 7.71321454327771],
            [33.523813955727299, 7.72215228633057],
            [33.514875252424702, 7.7317284405173],
            [33.502744125248498, 7.72725959600417],
            [33.500190217590699, 7.73492051188211],
            [33.490613010648701, 7.73045166289203],
            [33.473374072648497, 7.74385828766985],
            [33.464433137930698, 7.74683828685423],
            [33.461190125431997, 7.74107896747438],
            [33.461190080516197, 7.74107896569412],
            [33.447807363892302, 7.73983379289793],
            [33.431599460971299, 7.75140962617871],
            [33.424153164782801, 7.7479649291513],
            [33.416549247499603, 7.74041263430751],
            [33.407287544156802, 7.73462474791265],
            [33.397447014055601, 7.73115202263951],
            [33.390500756086801, 7.7276792937155],
            [33.381239090473201, 7.72999446938597],
            [33.374292838792698, 7.72999448184807],
            [33.366188869814998, 7.72478538216252],
            [33.3597246109968, 7.71959892123727],
            [33.3514243762992, 7.72342939170634],
            [33.346955007130802, 7.71768371526765],
            [33.342485636165698, 7.71129962886422],
            [33.332908432817, 7.70874600685343],
            [33.320138826255999, 7.70619239080161],
            [33.319301674564599, 7.71205207154702],
            [33.315249709389498, 7.72131272372774],
            [33.303350183165001, 7.72532207100927],
            [33.3030937869149, 7.7317309685392],
            [33.304200338868398, 7.73552262475053],
            [33.299478508969102, 7.73975735285186],
            [33.292298376412802, 7.73467259751764],
            [33.294989848479901, 7.74562195046858],
            [33.286347410456301, 7.74317306956774],
            [33.281246567864002, 7.74147298548754],
            [33.271835652555801, 7.74851594157561],
            [33.255048893232001, 7.7583554048912],
            [33.247523795030098, 7.76356453102639],
            [33.238262143789598, 7.77340398214281],
            [33.224366928414703, 7.76939507922955],
            [33.216844535040103, 7.77108885561178],
            [33.1994789390764, 7.77919194819489],
            [33.193111519544203, 7.78092833151583],
            [33.1838498548289, 7.78382229841826],
            [33.165326534381599, 7.7953981361391],
            [33.157222578878603, 7.79713452081802],
            [33.156049590221599, 7.79173952296393],
            [33.145186205998598, 7.7898781065108],
            [33.137533656620903, 7.78407864005522],
            [33.1334895588911, 7.78787391303327],
            [33.123070148132598, 7.78324361023661],
            [33.114966214189202, 7.78440120207995],
            [33.108802057354602, 7.7789714128976],
            [33.101778779630003, 7.78024824354245],
            [33.104971188232199, 7.78727074213464],
            [33.099337133534902, 7.7919255044844],
            [33.095285187224498, 7.79597704249082],
            [33.088338941832198, 7.8000285857734],
            [33.084539833545001, 7.79237805059852],
            [33.073047191143502, 7.79110125157225],
            [33.062193021838603, 7.78599399429224],
            [33.0564467001888, 7.79110128005299],
            [33.050061892865799, 7.78727083380783],
            [33.047819123893198, 7.79192558814638],
            [33.045189972448902, 7.80174427296884],
            [33.044279136446299, 7.81170420190185],
            [33.036820935783702, 7.81970754440234],
            [33.0229284638636, 7.83938643895275],
            [33.010193699359299, 7.85790775244634],
            [33.004303866452801, 7.87186652726548],
            [33.0015109179962, 7.87932301243038],
            [33.003826341810701, 7.88511091155555],
            [33.013666871911902, 7.88858363761492],
            [33.008457197524599, 7.8990018738843],
            [33.004405229654601, 7.90594736495702],
            [33.002089828297898, 7.91578680615338],
            [33.004992170894901, 7.92637557654736],
            [33.002089866925502, 7.94009600172263],
            [33.0091024133757, 7.94852932248406],
            [33.028353891124397, 7.96091823683342],
            [33.0362423300109, 7.97424457833308],
            [33.030453813593802, 7.99045071931725],
            [33.036355992047099, 8.00489793861488],
            [33.0462314037308, 8.014544603912929],
            [33.058817716244597, 8.014181077626761],
            [33.071770614157799, 8.01965183604757],
            [33.074592866557303, 8.02042291017167],
            [33.077469722152998, 8.02464181614617],
            [33.080730190714398, 8.04784582142049],
            [33.078812301955899, 8.053790654349971],
            [33.0866757003038, 8.06107785054558],
            [33.083415287438001, 8.06989921550119],
            [33.085716774789198, 8.07507696701088],
            [33.096840588697901, 8.07546048504895],
            [33.104512184156299, 8.07546047081842],
            [33.111416612972199, 8.071433316694669],
            [33.118129242491698, 8.060502489731229],
            [33.119088199447397, 8.06510493618806],
            [33.117086337074397, 8.07570876663741],
            [33.116403179978903, 8.08696657180846],
            [33.115829946131498, 8.098281032244589],
            [33.117745736425597, 8.102499833341531],
            [33.119998211694799, 8.106764246184509],
            [33.1258160104952, 8.108895272405441],
            [33.130616175283897, 8.110623130279929],
            [33.1364531737769, 8.112274193639839],
            [33.146207100894799, 8.112312574290881],
            [33.152274289984398, 8.11039172835196],
            [33.153157733659, 8.11008553266381],
            [33.157285874173503, 8.11045029698063],
            [33.160108369118099, 8.110776666832569],
            [33.163314883066903, 8.11404041125231],
            [33.166367801271797, 8.12296771833722],
            [33.171129567573701, 8.12688420821528],
            [33.174470482963699, 8.12747935704143],
            [33.186182869453297, 8.123543639163559],
            [33.191347854451898, 8.13060868626232],
            [33.190061421151, 8.133085297319729],
            [33.1881755501458, 8.136732451596011],
            [33.183926846737002, 8.144877999419739],
            [33.183420404427601, 8.15876533012729],
            [33.184818165941699, 8.16165480388943],
            [33.176920977464299, 8.1662610059089],
            [33.175604788367899, 8.17218325094905],
            [33.175036458423598, 8.179932682158251],
            [33.172642608989797, 8.188077620322259],
            [33.168571546530202, 8.186626790524921],
            [33.165627517612499, 8.18883018673249],
            [33.165805571786599, 8.19460395631411],
            [33.175204741622402, 8.20223677003897],
            [33.190579994309999, 8.21066172591344],
            [33.1966762798316, 8.217976434626809],
            [33.200003516574803, 8.22702422958826],
            [33.202575053670103, 8.23382889602936],
            [33.200762486090397, 8.2397838504068],
            [33.198190100985499, 8.247563805660279],
            [33.1892424414795, 8.24902222001484],
            [33.180176810718798, 8.24806903615247],
            [33.175543959846699, 8.25451244854581],
            [33.173020277803303, 8.263225701118429],
            [33.175795645331398, 8.28383692331389],
            [33.1693014330884, 8.294025743983269],
            [33.170621097766599, 8.29788065317284],
            [33.178752565971799, 8.296402352392169],
            [33.195015513161898, 8.2978806096162],
            [33.201668550242097, 8.30601117333797],
            [33.200929337374603, 8.312663462418151],
            [33.1931427243222, 8.326236607488889],
            [33.191972691122501, 8.3328661270828],
            [33.204536274463003, 8.332937688686689],
            [33.210539297790099, 8.33483774171971],
            [33.214759851591197, 8.34192825406153],
            [33.216459251208001, 8.355249501338591],
            [33.211278555573401, 8.353316318577519],
            [33.200190176609397, 8.349620623375721],
            [33.194329419473803, 8.349008486814361],
            [33.192397868238103, 8.353387769781531],
            [33.191192722588902, 8.367573609662371],
            [33.189101857832497, 8.379925513979691],
            [33.189790034713802, 8.38645482386732],
            [33.195015677553599, 8.39175179472328],
            [33.189790062561499, 8.40177377720225],
            [33.192627228791402, 8.406880089759911],
            [33.207947869299602, 8.40517795572096],
            [33.216453224410799, 8.408012906428731],
            [33.216893397103398, 8.414690066685271],
            [33.2147882715919, 8.426968741673001],
            [33.217192491177201, 8.43240463150816],
            [33.234049738606501, 8.422766335470969],
            [33.238589197028404, 8.42673790833839],
            [33.2467614864767, 8.43536114998024],
            [33.248540126484698, 8.44397637879049],
            [33.258781563917402, 8.446707107209919],
            [33.267202304915401, 8.45080321291473],
            [33.2733736078481, 8.45236139609294],
            [33.275623041421802, 8.452851257894689],
            [33.280174779813599, 8.44761731669529],
            [33.286679625414699, 8.436100221414961],
            [33.291114976281698, 8.43683935630308],
            [33.295650702203503, 8.436694300430849],
            [33.298507229822697, 8.44053505928788],
            [33.300657634916099, 8.442838471909999],
            [33.3052094065456, 8.45535438955983],
            [33.311809448413896, 8.459905621755439],
            [33.315906020332797, 8.45899536486726],
            [33.3199447614085, 8.45014388265044],
            [33.3209129225026, 8.44784480542055],
            [33.332511571497498, 8.444230714459589],
            [33.337071615712397, 8.4423832813834],
            [33.349513743233203, 8.44496982578665],
            [33.355051018831603, 8.443065935906541],
            [33.360740697211298, 8.440107615710479],
            [33.366885558233598, 8.44101785408823],
            [33.370212018832397, 8.436100069465081],
            [33.372429683486097, 8.43092606432975],
            [33.380540785087099, 8.431687776146971],
            [33.387140808090898, 8.425088458165609],
            [33.395345639010301, 8.423534590306589],
            [33.400520222507801, 8.428708583284781],
            [33.411608610454998, 8.439056567654831],
            [33.421957769365001, 8.44570883706651],
            [33.425603114821598, 8.44716192743428],
            [33.437437645240401, 8.43965235176578],
            [33.447830620376699, 8.437578215201929],
            [33.458603284637498, 8.44852724146882],
            [33.462615139136801, 8.45014362141286],
            [33.472964290860297, 8.452361032672661],
            [33.476582693146703, 8.45239576640957],
            [33.480996691059303, 8.45520435587326],
            [33.484320670511302, 8.45808480816582],
            [33.489113066669397, 8.468951707268889],
            [33.496038653505103, 8.47085852842087],
            [33.500175073791603, 8.470992183339471],
            [33.5109562684544, 8.47099216290362],
            [33.5194677294146, 8.467587936111681],
            [33.524744693904999, 8.467345886366861],
            [33.533577325935497, 8.468750905275829],
            [33.541647020591803, 8.467587369233989],
            [33.546957082610497, 8.46758735946023],
            [33.556361495014301, 8.469152303020691],
            [33.560322778854598, 8.46872259790487],
            [33.5634878184851, 8.467747253590369],
            [33.568354192703502, 8.45604808268136],
            [33.569014229858503, 8.451250471100421],
            [33.575880773125498, 8.44730656796556],
            [33.595148186088998, 8.446099221854841],
            [33.599649193023502, 8.44675778242126],
            [33.610269343112002, 8.4614609248133],
            [33.6200104207281, 8.46855944242013],
            [33.640087378357599, 8.461052448951159],
            [33.649073630386603, 8.457785062736789],
            [33.661735803829501, 8.449963874795831],
            [33.680117034155103, 8.44063131706589],
            [33.6793000846761, 8.431646052550599],
            [33.676440814500403, 8.427970267255899],
            [33.681750853162903, 8.415717623056411],
            [33.682976233363902, 8.40591551363433],
            [33.688694748562398, 8.399380764299121],
            [33.691962464731503, 8.39162075627852],
            [33.693777646408698, 8.381092349573681],
            [33.694821701669603, 8.377734431613071],
            [33.700540219563102, 8.37242494651481],
            [33.709637827652998, 8.370500779950151],
            [33.716470390347801, 8.36548175775645],
            [33.725456645071702, 8.363848057447759],
            [33.732400571013898, 8.36384804411637],
            [33.737710635727503, 8.365481718651131],
            [33.742232283252299, 8.370356324941721],
            [33.745063038205899, 8.371199602004291],
            [33.747513840828702, 8.373650123640569],
            [33.751190040720402, 8.376100644069281],
            [33.7552746946903, 8.371608003795821],
            [33.7724302726254, 8.369565867018681],
            [33.776772366687801, 8.37630293922645],
            [33.778278981556497, 8.375208566514811],
            [33.790402825192302, 8.385494257510491],
            [33.799389097882496, 8.394071085229889],
            [33.807558423788699, 8.39447949143638],
            [33.813685426303103, 8.399788955190679],
            [33.820629364821798, 8.406323679875619],
            [33.825122509700897, 8.415308937078541],
            [33.827164849262303, 8.420209987477291],
            [33.8373765156282, 8.42592786456137],
            [33.840235788498802, 8.43082891286012],
            [33.845954318070397, 8.431645745093009],
            [33.852032177349002, 8.43153217058657],
            [33.8651522364652, 8.43532149987821],
            [33.876180824462303, 8.43532147944048],
            [33.878223159532098, 8.437363581028521],
            [33.874955446057903, 8.44553201001413],
            [33.881490920616898, 8.45329199970066],
            [33.881490933193398, 8.46023515917069],
            [33.888843329383498, 8.46227725148608],
            [33.892969066904698, 8.47320040376886],
            [33.898279141499799, 8.48055197513834],
            [33.925289504901301, 8.48684260749547],
            [33.935449596291797, 8.4948466459121],
            [33.946069723922399, 8.497705574281669],
            [33.950562847241898, 8.494438196641809],
            [33.956281370525303, 8.49239608059135],
            [33.972190739022999, 8.49834213994064],
            [33.980380876199298, 8.49157919327928],
            [33.985282465275198, 8.4887202352527],
            [33.991409457908198, 8.487903381875769],
            [33.9989565630379, 8.491310965714201],
            [34.002029591826997, 8.49443809979954],
            [34.014992225681297, 8.48684243957304],
            [34.0204105598445, 8.4887201695056],
            [34.027354501058198, 8.49648015878039],
            [34.033073053986001, 8.510366466448829],
            [34.041744455490999, 8.517460444368909],
            [34.041650859123202, 8.51771803094741],
            [34.052270992143697, 8.52384432791656],
            [34.061257263037298, 8.531195891847959],
            [34.070463075897997, 8.542107635244619],
            [34.0812721407925, 8.5487579635139],
            [34.0963853935393, 8.5499831981382],
            [34.1017049785982, 8.55560677190376],
            [34.121140604692002, 8.57504018027554],
            [34.142071309489403, 8.61091726982743],
            [34.142071387642901, 8.651279039669291],
            [34.154029716687603, 8.651169232998649],
            [34.189614062894798, 8.651619039348279],
            [34.198454242570399, 8.656157500599511],
            [34.223493689662099, 8.669753757612421],
            [34.235151630160701, 8.68613366028378],
            [34.2557087292248, 8.70262499325767],
            [34.279364059313302, 8.708823369550281],
            [34.312404124209301, 8.68208614179475],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 8,
        REGIONNAME: "Beneshangul Gumu",
        RID: "06",
        latitude:13.833271,
        longtiude: 39.344733,
        GlobalID: "d4db241d-c987-406e-bdcc-23c070d41f80",
        Shape__Area: 52276234634.618698,
        Shape__Length: 1801811.64941868,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [36.247897862678897, 8.973517163171669],
              [36.223415794169703, 8.951976809399749],
              [36.188145887956402, 8.969134710988129],
              [36.185685777888999, 8.971563992706759],
              [36.185125777124, 8.973343997232099],
              [36.174269215605499, 8.97940960506067],
              [36.178967031740598, 8.986303859022721],
              [36.221015126523, 8.98852398263919],
              [36.247897862678897, 8.973517163171669],
            ],
          ],
          [
            [
              [35.9723140329166, 11.9703223715766],
              [35.968999832524801, 11.958404167325],
              [35.978009037407503, 11.960484793628201],
              [35.982153417665799, 11.9533251183367],
              [35.986112329055501, 11.9457369417329],
              [35.986515943011, 11.944963288578499],
              [35.9868786476884, 11.944268044788499],
              [35.987017501180198, 11.944001883545599],
              [35.987202311583601, 11.9436476304581],
              [35.9876786711107, 11.9427345156094],
              [35.988108914724499, 11.9419097895556],
              [35.988532057155901, 11.941098669514799],
              [35.988875896314099, 11.940439560570599],
              [35.989157517257297, 11.939899714115199],
              [35.989538569126097, 11.939169262472999],
              [35.9898982186333, 11.938479833457601],
              [35.990423174322402, 11.9374735109306],
              [35.990727187570698, 11.936890722150199],
              [35.991046780300998, 11.9362780629698],
              [35.991302759732797, 11.935787348054699],
              [35.991634503973998, 11.935151387733301],
              [35.992102516355601, 11.934254193628799],
              [35.995955470559203, 11.9268676237542],
              [36.003477970833302, 11.9229817603767],
              [36.005166313087301, 11.9221095731119],
              [36.0072077237906, 11.921054970234],
              [36.009274051963402, 11.919987469136601],
              [36.011806123373198, 11.918679320857001],
              [36.012417963218297, 11.9183632189199],
              [36.013780816200502, 11.917659104214801],
              [36.021186591183103, 11.913832732700101],
              [36.027185347512699, 11.908576950319899],
              [36.027682848890102, 11.908141048813],
              [36.028339873994, 11.9075653730675],
              [36.028938320856398, 11.9070410175097],
              [36.029854964467297, 11.906237854433799],
              [36.030313032498697, 11.9058364917534],
              [36.031268347684197, 11.9049994302546],
              [36.032431525839897, 11.903980222299101],
              [36.033075697848702, 11.9034157757357],
              [36.033857935526797, 11.902730344122],
              [36.034577763648798, 11.9020995935489],
              [36.035443988415999, 11.901340555122299],
              [36.035895751172397, 11.900944690428],
              [36.0359681419095, 11.9008812560359],
              [36.036006770365098, 11.900847407242701],
              [36.036058375883201, 11.9008021867322],
              [36.042618161548702, 11.895053807062601],
              [36.031913129648999, 11.8938220486046],
              [36.032247129559899, 11.892749048525999],
              [36.034611142349803, 11.891273044368999],
              [36.035470147357103, 11.890744041555299],
              [36.035908147923301, 11.889844041638501],
              [36.036088147848403, 11.8890590407019],
              [36.036280146570697, 11.8880790409683],
              [36.036624147425599, 11.887213040082299],
              [36.037520152242799, 11.8863130390374],
              [36.038003153913202, 11.885795037631899],
              [36.038511156596201, 11.8850220364658],
              [36.038948158235897, 11.8844230360361],
              [36.039244158511899, 11.8838230347953],
              [36.039611158932999, 11.883142034404401],
              [36.050585164395102, 11.8830082918104],
              [36.044497179306603, 11.8765800253452],
              [36.045604186706001, 11.8762640230351],
              [36.056349554758597, 11.8742919810771],
              [36.049100198026402, 11.870236017183],
              [36.048892194918999, 11.8696360172226],
              [36.048711190677501, 11.8682040183566],
              [36.048609188773597, 11.8675240180391],
              [36.048649187159903, 11.8664620184868],
              [36.048816186666201, 11.8658160181862],
              [36.049032185678001, 11.864904018517899],
              [36.049400185924, 11.864005017191101],
              [36.049873188447002, 11.8630810166343],
              [36.050419188968199, 11.861686015957501],
              [36.050798189084901, 11.8608320140332],
              [36.051072189619603, 11.8600590141473],
              [36.0513341895604, 11.859125014056501],
              [36.051257187770901, 11.8581090138268],
              [36.051213185593298, 11.8574970140884],
              [36.0511571837202, 11.856862014446],
              [36.050997181191697, 11.855938015193001],
              [36.050790177909199, 11.8551080155377],
              [36.050761175800297, 11.853988016196499],
              [36.050835173623497, 11.853112015556301],
              [36.050977171218697, 11.8512730149404],
              [36.051000169886599, 11.850538015566601],
              [36.051250169233597, 11.8497080144395],
              [36.051699171467099, 11.849039013969],
              [36.052194173731401, 11.848358013742599],
              [36.0531231790589, 11.8478620119848],
              [36.054025184622198, 11.847944009366801],
              [36.0553851935381, 11.848036006486],
              [36.056155201551903, 11.848869005405],
              [36.056760207912603, 11.8497460043309],
              [36.057794215516999, 11.8502580018041],
              [36.058426218946998, 11.8499190006516],
              [36.066240368956102, 11.851588719157199],
              [36.073018921342801, 11.8453777977271],
              [36.074132939194598, 11.844357018060601],
              [36.074600851863202, 11.843928263597601],
              [36.075059917024497, 11.843507612851701],
              [36.075635318506698, 11.842980358389701],
              [36.076280527171299, 11.8423891344198],
              [36.076787155432001, 11.841924890726499],
              [36.077436022934698, 11.841330305933599],
              [36.078063919267201, 11.8407549312609],
              [36.078991539392497, 11.839904896775],
              [36.079581779246602, 11.839364019991599],
              [36.080383644215303, 11.8386292092577],
              [36.080852730981903, 11.8381993459176],
              [36.081581270067197, 11.837531720487499],
              [36.0820477319566, 11.837104255671401],
              [36.082631708756502, 11.8365690979095],
              [36.083267711486101, 11.835986258342199],
              [36.0837315502948, 11.8355611904939],
              [36.084380656749502, 11.834966334247],
              [36.085591623481697, 11.8338565656145],
              [36.081109324861401, 11.8267589568839],
              [36.081434319161502, 11.822719957455501],
              [36.082084320338097, 11.8211959562241],
              [36.088163356409702, 11.818292944179801],
              [36.090449367585101, 11.816270939787501],
              [36.0917353741863, 11.815390937152401],
              [36.092394377380401, 11.8150959360709],
              [36.093544385335797, 11.8145819330637],
              [36.094271388301998, 11.8137909317521],
              [36.094814390246803, 11.813199931349001],
              [36.095319392556803, 11.8126499310111],
              [36.095899395209898, 11.8118129291537],
              [36.096429396735999, 11.8110489283071],
              [36.105206201166503, 11.8158788530252],
              [36.107768045439897, 11.8069881769585],
              [36.1080271016015, 11.8060890959733],
              [36.108362042048398, 11.804926633360701],
              [36.108623007129999, 11.804020907637501],
              [36.108895591919897, 11.8030748444399],
              [36.109149035407597, 11.802195203917201],
              [36.109343243087203, 11.8015211507399],
              [36.109565455745802, 11.800749890483999],
              [36.109808586573998, 11.799906022518201],
              [36.110179701279201, 11.7986179284923],
              [36.11318164099, 11.7881978869616],
              [36.113668865640498, 11.786506571320199],
              [36.117217260420098, 11.7741879798381],
              [36.118379898688403, 11.766468950479],
              [36.119576105202199, 11.7585262318348],
              [36.121009362746399, 11.749008411672699],
              [36.1211703668965, 11.7479391569074],
              [36.121411068680203, 11.746340590611601],
              [36.123205208220199, 11.7344241368722],
              [36.116581352013696, 11.723499897416399],
              [36.125503865878201, 11.719153991531501],
              [36.118043325104303, 11.7060928956317],
              [36.118623327757298, 11.705504894814601],
              [36.127101375880599, 11.701089877950499],
              [36.127566377109297, 11.6998468771523],
              [36.1278353767228, 11.699126876345099],
              [36.128009376800101, 11.698294875854801],
              [36.128275375142202, 11.6970228759363],
              [36.128703376561099, 11.696294875167],
              [36.1290523783371, 11.6956968746104],
              [36.130074382652701, 11.6948478730367],
              [36.130599385054197, 11.6939238717124],
              [36.1427364509927, 11.6861528473313],
              [36.144771462996403, 11.685543843616401],
              [36.146900476507902, 11.685119840014099],
              [36.148861489790001, 11.6850068348531],
              [36.150029497288699, 11.6851188327598],
              [36.151664510157602, 11.685976830019101],
              [36.156218542846602, 11.6866138202275],
              [36.158011551407597, 11.6851568172415],
              [36.160043561241601, 11.6833188132895],
              [36.160891564581597, 11.6821858113977],
              [36.162070570154299, 11.6806128091449],
              [36.163005570838003, 11.6784948072209],
              [36.163819574741098, 11.6778858060294],
              [36.164628580417997, 11.6778828044212],
              [36.166338592731599, 11.678147800913401],
              [36.167063598742899, 11.6784127997915],
              [36.1761916722973, 11.6842327800621],
              [36.185176743939898, 11.6895927617126],
              [36.186114752183201, 11.690447759474701],
              [36.189635782482597, 11.6936127524447],
              [36.197454852564597, 11.7019727354862],
              [36.199311869682901, 11.704111731586099],
              [36.1999048745514, 11.7047927304542],
              [36.200810883007598, 11.7058347280816],
              [36.201677891105703, 11.7068317269991],
              [36.202316896903298, 11.707566725617699],
              [36.207049256854297, 11.7214228271081],
              [36.211224519395401, 11.7215383708192],
              [36.212960373544803, 11.7215863898502],
              [36.2136367726144, 11.7216050994409],
              [36.214787165048698, 11.7216369139223],
              [36.215672921885101, 11.7216614067605],
              [36.216797783300798, 11.721692508774201],
              [36.2173372602564, 11.721707422962499],
              [36.219418392664998, 11.7217649476022],
              [36.220733237881802, 11.721801283993299],
              [36.221557738599003, 11.7218240669108],
              [36.222330663442499, 11.7218454213864],
              [36.223637258531703, 11.721881516761799],
              [36.231554368947101, 11.722100102274601],
              [36.234651175243499, 11.7361356554298],
              [36.235698183266699, 11.7368446526861],
              [36.237193196393498, 11.7378566499799],
              [36.239353213460497, 11.7393176452638],
              [36.240408221879598, 11.7401606437595],
              [36.241094228431102, 11.740626641870699],
              [36.241739234076498, 11.7410656405693],
              [36.243161245611702, 11.742031636830699],
              [36.243778250769601, 11.7424506357462],
              [36.244390255904698, 11.742866634001601],
              [36.245017260210098, 11.743292633450199],
              [36.245540264758397, 11.7436196317627],
              [36.246366271051997, 11.744136630347301],
              [36.247086277040502, 11.744586628749399],
              [36.248477287715197, 11.745455626041],
              [36.249686297916803, 11.7462106237555],
              [36.251074309116802, 11.7470776203384],
              [36.2520203159593, 11.747614617523199],
              [36.253233326358803, 11.748304615386299],
              [36.254086333315001, 11.7487886135522],
              [36.255781346458299, 11.749751610057601],
              [36.2567383549681, 11.750396607851],
              [36.257561360888701, 11.750949607016],
              [36.2590853734295, 11.7519746031985],
              [36.260190382975601, 11.752857600533099],
              [36.261421393816804, 11.753839598033601],
              [36.263127407729002, 11.7552005940874],
              [36.272987495052298, 11.7645595729647],
              [36.276698526220798, 11.767770564451],
              [36.280750560566197, 11.771273555811099],
              [36.283890586607498, 11.773357548628599],
              [36.286138604436303, 11.774705544511001],
              [36.290365646768699, 11.7808565346694],
              [36.290791651232603, 11.7814765332009],
              [36.291192654683897, 11.7820605325463],
              [36.291511659017701, 11.783018531987899],
              [36.292385670381798, 11.785647528984599],
              [36.294795692185602, 11.788092523543],
              [36.295742700649697, 11.7890525218465],
              [36.296010704031602, 11.7896155207986],
              [36.296770710202999, 11.790321519692499],
              [36.2990977312678, 11.7924825147663],
              [36.3024897607974, 11.795968507204799],
              [36.308773819366301, 11.803217492361799],
              [36.329041942081602, 11.796675450875901],
              [36.342155999553398, 11.782507425366701],
              [36.340899984106301, 11.779599428260299],
              [36.3397899754356, 11.778572431478],
              [36.337138950912397, 11.7756754372275],
              [36.336404942883703, 11.7744174391351],
              [36.336195939951402, 11.7737954398358],
              [36.336573941141502, 11.773056438768201],
              [36.3375609470936, 11.7724214368054],
              [36.338207949693803, 11.7719814354368],
              [36.338865953062999, 11.7714854343608],
              [36.339594957475597, 11.7708954333341],
              [36.340511961310902, 11.770110430430099],
              [36.340935963430098, 11.7696144294967],
              [36.341513964636697, 11.768610429493201],
              [36.341622963518297, 11.7677564284167],
              [36.341379960250798, 11.7668564292236],
              [36.341264957928203, 11.766162429796999],
              [36.341010953891697, 11.7650324299063],
              [36.340311946921297, 11.7638434320562],
              [36.339868942739002, 11.7631274335134],
              [36.339507938572403, 11.7625734342491],
              [36.338702931296801, 11.761396436491401],
              [36.338376927290298, 11.7608654368152],
              [36.337934922034599, 11.760034437705301],
              [36.337504916474401, 11.758788439587599],
              [36.337532914266802, 11.7576224392103],
              [36.338052915747099, 11.756502438133101],
              [36.338454916328303, 11.7556714372845],
              [36.3390199180145, 11.7547714363842],
              [36.3397739216432, 11.753835435458299],
              [36.340432924837302, 11.7532004339874],
              [36.341244928192303, 11.7523574322891],
              [36.3410719243467, 11.751433432552499],
              [36.340773919030902, 11.7497604331242],
              [36.340436915153802, 11.7487224341554],
              [36.339785907863998, 11.7473944357082],
              [36.338748896293303, 11.745490437766501],
              [36.338494893155101, 11.744496438626101],
              [36.338651891717298, 11.7433194383642],
              [36.338983891079998, 11.7422814388792],
              [36.339090891210198, 11.741691438587001],
              [36.339388889339403, 11.7404214380116],
              [36.339580888061697, 11.739106437663599],
              [36.3397408852003, 11.737109436996001],
              [36.339696883022697, 11.736116437572999],
              [36.339467878202299, 11.734674438355301],
              [36.339098872741502, 11.733138439005399],
              [36.338807867996699, 11.7324334403209],
              [36.338329863654302, 11.7317774413092],
              [36.337780859166699, 11.7311304420701],
              [36.337080853269804, 11.7303344439849],
              [36.336192845255198, 11.7295494459789],
              [36.335748841247899, 11.7289484468356],
              [36.335212835382499, 11.728211448692701],
              [36.334874832578798, 11.727563448945499],
              [36.334560828267797, 11.726814448952],
              [36.333759818495302, 11.7247374519564],
              [36.333609816012498, 11.7241254511785],
              [36.333576814603902, 11.723525451737601],
              [36.333862813935902, 11.722115451833201],
              [36.334182813603, 11.721354451863199],
              [36.334783816172497, 11.7203614494042],
              [36.335195817697802, 11.7197504495741],
              [36.335924821212103, 11.7192644477665],
              [36.336383823491403, 11.718790446244],
              [36.3360468187159, 11.718006447177199],
              [36.3349108110042, 11.717671450262101],
              [36.3343728063873, 11.7171754517047],
              [36.334058802076399, 11.7165064520377],
              [36.333721799097503, 11.7157674530192],
              [36.333232793087802, 11.7148674538411],
              [36.3329657904291, 11.714244454595899],
              [36.332674787481103, 11.7134934551945],
              [36.329002395547803, 11.705502136284499],
              [36.329545215134701, 11.7052409501581],
              [36.329599117645003, 11.705215014143899],
              [36.329741783586798, 11.7051463674799],
              [36.329867557608097, 11.705085848623],
              [36.330245923514198, 11.704903791460501],
              [36.331202236727997, 11.704443638938701],
              [36.332582560145802, 11.703779454386799],
              [36.333182780282598, 11.703490637151299],
              [36.334152263696801, 11.7030241312691],
              [36.335205059565403, 11.702517530483499],
              [36.335846637239698, 11.702208802953001],
              [36.337536453628999, 11.7013956502383],
              [36.338541593871803, 11.7009119621792],
              [36.340001096595401, 11.7002096187228],
              [36.347532258425403, 11.696585269230701],
              [36.344045883333003, 11.689548848768901],
              [36.343689910630303, 11.6888303852575],
              [36.343298278404099, 11.688039944631599],
              [36.3416727598295, 11.684759087905],
              [36.341391369753303, 11.684191141175599],
              [36.3410868902793, 11.683576586620999],
              [36.340811716544799, 11.683021179418599],
              [36.340350883498999, 11.682091038742399],
              [36.340067205413803, 11.6815184636705],
              [36.3395146714454, 11.6804032193441],
              [36.339245429286798, 11.679859776061001],
              [36.338800133502097, 11.678960977033199],
              [36.338408701600201, 11.6781708970874],
              [36.337920981978002, 11.677186456813599],
              [36.337588797562297, 11.676515953790499],
              [36.337066519751097, 11.675461750437099],
              [36.336681649143998, 11.6746848960754],
              [36.336268957712598, 11.6738518799724],
              [36.3358571097992, 11.6730205636573],
              [36.3323940028693, 11.6660301016724],
              [36.338324056045998, 11.6508775852942],
              [36.340309954458, 11.6419033965014],
              [36.340725363293203, 11.640026070221699],
              [36.340989135610101, 11.6388340018674],
              [36.341205197503903, 11.6378575404893],
              [36.341381649083601, 11.637060086584],
              [36.341530618503803, 11.6363868233645],
              [36.341686174371603, 11.6356837956709],
              [36.341870350564399, 11.634851404319299],
              [36.342261938773198, 11.633081585802399],
              [36.3427202592312, 11.6310101246022],
              [36.342991495649699, 11.629784199690899],
              [36.343224209614, 11.628732375922599],
              [36.3437573327856, 11.626322706057801],
              [36.344146016741099, 11.6245658504115],
              [36.344497933550301, 11.6229751474881],
              [36.344630139509, 11.622377556536],
              [36.344684880147398, 11.622130120645901],
              [36.344991824598502, 11.620742664761799],
              [36.345629398481499, 11.617860618952699],
              [36.346045777497203, 11.6159784036297],
              [36.346349823983203, 11.614603952271899],
              [36.348397304602997, 11.6053477053426],
              [36.355907482686298, 11.603109229742399],
              [36.357500804742799, 11.602634294214401],
              [36.359281151549901, 11.602103597408499],
              [36.361202549127903, 11.601530839144599],
              [36.363496353188502, 11.6008470484153],
              [36.3649104146334, 11.6004255009844],
              [36.366030476350502, 11.6000915914969],
              [36.366572509013103, 11.5999300004462],
              [36.375130844963202, 11.6076673799954],
              [36.379334687404999, 11.596124960122699],
              [36.385010291879098, 11.590082086923699],
              [36.385849437830402, 11.5891886026025],
              [36.387403882598001, 11.5875334768232],
              [36.388061790745802, 11.586832946611599],
              [36.3887857942373, 11.5860620313463],
              [36.3891865326855, 11.5856353258451],
              [36.390327383214903, 11.584420534345099],
              [36.390983587258603, 11.5837217924489],
              [36.391117949173903, 11.583578719851699],
              [36.391789841395699, 11.5828632660052],
              [36.392727529838801, 11.5818647734558],
              [36.388075859261498, 11.5743373566511],
              [36.389230863646503, 11.572758354691601],
              [36.390264868555903, 11.5716193523178],
              [36.391420871867503, 11.5697503501233],
              [36.393523877355001, 11.565744346626801],
              [36.394457879112103, 11.563445343879],
              [36.395356879810798, 11.561239342442599],
              [36.3966888855444, 11.559650340017701],
              [36.398057890189598, 11.557781337136699],
              [36.399141894429498, 11.5561453350745],
              [36.400021897556499, 11.555165333338],
              [36.400079894587897, 11.5533123335839],
              [36.399510887313902, 11.551815336107699],
              [36.3996018848555, 11.5503793355256],
              [36.400924889469998, 11.5483143319435],
              [36.402076895278597, 11.5472683306863],
              [36.402670897276998, 11.5465303291515],
              [36.404878907736403, 11.5445763244877],
              [36.414510348667001, 11.5451132429982],
              [36.413684943346297, 11.537452408197201],
              [36.413615437997798, 11.536807307034501],
              [36.413252877050802, 11.5334422727788],
              [36.412930833716402, 11.530453294892199],
              [36.4229749840447, 11.5239632888578],
              [36.423888986608702, 11.5223002880601],
              [36.425111989867403, 11.5202552850883],
              [36.427861000825096, 11.516781280073699],
              [36.429638008594303, 11.514749276762799],
              [36.432490022179103, 11.5124572705015],
              [36.434739031748002, 11.5101152667518],
              [36.436292038312899, 11.5080602634907],
              [36.437690043270401, 11.5064452599663],
              [36.438922049444898, 11.5050942582616],
              [36.4391040463249, 11.5034282577307],
              [36.439356046219999, 11.502281257492401],
              [36.445036065913698, 11.4937582458651],
              [36.444457058593898, 11.4925552479309],
              [36.443346050996603, 11.492075249993499],
              [36.439842027602097, 11.492201257861099],
              [36.436858005688002, 11.4914122642789],
              [36.436189996883201, 11.4887842653972],
              [36.436155992954703, 11.4874622656593],
              [36.435474984629401, 11.4857262673912],
              [36.4346759772016, 11.484721268793599],
              [36.432917964309098, 11.483956272361199],
              [36.432143957893999, 11.4836202742941],
              [36.4315399540532, 11.4833572757165],
              [36.430513945946402, 11.4829102773136],
              [36.429068933048299, 11.4812382807962],
              [36.426782911991403, 11.4786542854935],
              [36.426241907001398, 11.4780422871476],
              [36.425620902543798, 11.477583288177399],
              [36.423946886621998, 11.475303292239699],
              [36.421221857987398, 11.470529297744299],
              [36.420129846165104, 11.4682273004703],
              [36.418591829787303, 11.4652203034222],
              [36.409505125448398, 11.459959961827201],
              [36.409521323869598, 11.458180649848901],
              [36.409527432413498, 11.4575096739953],
              [36.409533042392503, 11.456893280575301],
              [36.409542412719198, 11.455863797901999],
              [36.409553429657898, 11.4546531548605],
              [36.4095734045965, 11.4524579221184],
              [36.409585548022498, 11.451123206998901],
              [36.409626464487097, 11.446624517258099],
              [36.409719650324803, 11.436371955427701],
              [36.409756698643697, 11.4322929815305],
              [36.409811523723803, 11.426254101694701],
              [36.409835571624001, 11.4236041039076],
              [36.4099235885555, 11.4138996803669],
              [36.423741962939403, 11.4182277954779],
              [36.437947159969397, 11.423257818327199],
              [36.439198994657097, 11.423889024858999],
              [36.441182289751502, 11.4248890367277],
              [36.4419120676136, 11.4252569994573],
              [36.442412604398399, 11.4255093740924],
              [36.442867181576503, 11.4257385742893],
              [36.443570985550501, 11.4260934339037],
              [36.444604190061, 11.4266143749199],
              [36.446715781645999, 11.4276790213429],
              [36.447218282147901, 11.427932375585801],
              [36.448804944993199, 11.4260232439108],
              [36.449381949069902, 11.425779243408201],
              [36.4600980230611, 11.427364220652001],
              [36.467131072659299, 11.4286332063561],
              [36.468993379956302, 11.435729346285701],
              [36.469598926204498, 11.4358605776477],
              [36.471917356680201, 11.436363005351501],
              [36.472620606393697, 11.4365154031417],
              [36.474153586815902, 11.43684760072],
              [36.476751112172302, 11.4374104669857],
              [36.478587989960303, 11.437808491371101],
              [36.486359488858, 11.4394923283054],
              [36.487671258243203, 11.4397765253836],
              [36.488359944469302, 11.439925728757499],
              [36.488935587598299, 11.4400504391881],
              [36.489098355141202, 11.440085702819699],
              [36.490255030392703, 11.440336287462801],
              [36.491649740329699, 11.4406384337634],
              [36.494472605380203, 11.4412499513694],
              [36.497127603151903, 11.443187223775199],
              [36.4998123234814, 11.4451461367691],
              [36.5034843758514, 11.458128127996201],
              [36.512198432959401, 11.4582651095033],
              [36.519459272855798, 11.4490674455988],
              [36.520443150874101, 11.4462370979503],
              [36.520798690588798, 11.445214289939701],
              [36.521393645699803, 11.4435027138886],
              [36.521858625368999, 11.4421650281097],
              [36.522096610647303, 11.4414803723618],
              [36.522248914613797, 11.4410422065707],
              [36.5293334969667, 11.4355700759093],
              [36.530003499133102, 11.4344410745061],
              [36.530321499150403, 11.4335940741841],
              [36.530446468281198, 11.418587075090301],
              [36.5309414651556, 11.4160090745902],
              [36.531460462319401, 11.4127480734094],
              [36.533099450233401, 11.402244070836201],
              [36.533070917045002, 11.394046901570499],
              [36.537978112506501, 11.394088026982899],
              [36.538405043133501, 11.394091601382501],
              [36.538809790762897, 11.394094989092601],
              [36.539471205933502, 11.394100524612499],
              [36.540320902597799, 11.3941076320226],
              [36.543060478550103, 11.394130534067701],
              [36.550989534762202, 11.3870460343778],
              [36.551671537522502, 11.386218034127801],
              [36.556623554425201, 11.3789530236623],
              [36.563386585720401, 11.373002010124299],
              [36.563762586362301, 11.3718040097512],
              [36.564021585930099, 11.3708520090939],
              [36.5642925851934, 11.3696900083063],
              [36.563602555986002, 11.3580170110216],
              [36.558815474261102, 11.354335168466299],
              [36.556128191038098, 11.3531722900867],
              [36.555433842730899, 11.3528718157946],
              [36.554822987439401, 11.352607472631201],
              [36.554042438594003, 11.352269692234801],
              [36.553373331149601, 11.351980135539799],
              [36.553203532492901, 11.3519066544211],
              [36.552739263391103, 11.3517057405237],
              [36.551892088297798, 11.3513391191299],
              [36.5513669727086, 11.351111870595799],
              [36.5511258020043, 11.351007502440901],
              [36.539680785584203, 11.346054253383601],
              [36.539414257236103, 11.3459388967831],
              [36.538955475044098, 11.3457403285542],
              [36.5384857028629, 11.345537004078],
              [36.538061314468102, 11.345353322201399],
              [36.537666469866501, 11.345182425348799],
              [36.534519153996598, 11.3352811741634],
              [36.547458479264201, 11.337258615365201],
              [36.554759439717799, 11.3313500315661],
              [36.558029453777401, 11.327600024162299],
              [36.562471465652202, 11.319735016223801],
              [36.562320457954598, 11.3169170166905],
              [36.562290450630897, 11.313496016960199],
              [36.561837441012898, 11.3100250186891],
              [36.558668402082802, 11.3018240250462],
              [36.557034391733801, 11.3020660289689],
              [36.556387387336997, 11.302162030628599],
              [36.555330381963003, 11.3023650318117],
              [36.547676757301801, 11.3048101502081],
              [36.544982899309304, 11.3047878399986],
              [36.544086946594398, 11.304780413245],
              [36.543275344786302, 11.3047736850343],
              [36.542171364709503, 11.3047645282193],
              [36.5413163293574, 11.3047574335594],
              [36.539774832008, 11.304744636920701],
              [36.538798856469199, 11.304736531002501],
              [36.538058820744901, 11.304730381533201],
              [36.537391180249301, 11.3047248328284],
              [36.536697167912003, 11.304719063901899],
              [36.535652171216697, 11.304710373074199],
              [36.534806879890503, 11.304703340074999],
              [36.530787950259999, 11.3046698716644],
              [36.530485246755397, 11.304667348804699],
              [36.5286122027941, 11.300302088107101],
              [36.5210120341806, 11.2944463701632],
              [36.514747089065999, 11.289619117206801],
              [36.504934015073196, 11.284976137189],
              [36.494941958048202, 11.2889401575456],
              [36.490315906524501, 11.2790971688488],
              [36.483184844440601, 11.271773183757],
              [36.467096238687503, 11.2675139896416],
              [36.467111299841598, 11.2657922120351],
              [36.4671172287225, 11.2651143572369],
              [36.4671240693934, 11.264332212118401],
              [36.467128570851301, 11.2638175420387],
              [36.467132982477601, 11.2633129780271],
              [36.471935731719398, 11.2530722075229],
              [36.475806739365296, 11.2446541990015],
              [36.4707846835149, 11.233284209560299],
              [36.4595936010627, 11.2288592329712],
              [36.459937597426098, 11.2261852323276],
              [36.460972600363803, 11.2242172307181],
              [36.467540624478801, 11.2153272169862],
              [36.468246610460596, 11.206451215548499],
              [36.468669604669998, 11.2024772147895],
              [36.468826598740598, 11.198889214445501],
              [36.468223587538198, 11.195548216381299],
              [36.464911553882899, 11.1896802237448],
              [36.465720554169899, 11.1875082219759],
              [36.466330554265198, 11.185490220229701],
              [36.466631552767502, 11.1841282195553],
              [36.466925550698697, 11.182185219572199],
              [36.467833550719902, 11.179443217842399],
              [36.468486550473003, 11.1770872160747],
              [36.468611540265002, 11.1717132166659],
              [36.468486533404999, 11.169094216073301],
              [36.468408528197301, 11.1665322160523],
              [36.468155519494204, 11.163021217518301],
              [36.467984512603401, 11.1604492172413],
              [36.467646505308103, 11.1577292179936],
              [36.465529484305598, 11.154497222886301],
              [36.462756456888698, 11.149921227953399],
              [36.463506461217698, 11.149514226576301],
              [36.464237465280199, 11.1494152251071],
              [36.465893479862103, 11.1513062215512],
              [36.4695755089217, 11.1539632143961],
              [36.472153528260002, 11.154876209050901],
              [36.473011535239003, 11.155554206926301],
              [36.473330538674503, 11.156272206360001],
              [36.4735335408607, 11.156911206181499],
              [36.474385548890297, 11.1574612046266],
              [36.477005565366497, 11.157795198286699],
              [36.4779895718438, 11.157638197174499],
              [36.478895577605101, 11.1577011950487],
              [36.482622597887399, 11.155417187282501],
              [36.482653595154602, 11.154220187722901],
              [36.482898594478698, 11.153028187536499],
              [36.483220595592201, 11.152341186046501],
              [36.4832335933161, 11.1511821866759],
              [36.483380591832599, 11.150507185792],
              [36.484353597541002, 11.1500001836233],
              [36.484888599988203, 11.149576183562599],
              [36.485412601666503, 11.1488031829062],
              [36.486268604504097, 11.1477751800611],
              [36.486699607194303, 11.1470961795522],
              [36.487380610129698, 11.146618178178],
              [36.4880896144508, 11.1465331771581],
              [36.489461623062397, 11.1461821742074],
              [36.4901526269418, 11.145960171873799],
              [36.490747629663502, 11.145632171164999],
              [36.491646634853801, 11.1451011695018],
              [36.492209637788399, 11.144802168578501],
              [36.494834649795898, 11.142585162560801],
              [36.495860656106103, 11.1419431609871],
              [36.496400657677903, 11.141648159183401],
              [36.500055675295201, 11.138015151703],
              [36.500758678870298, 11.1377901516791],
              [36.501944685912299, 11.1375591482887],
              [36.502674691048199, 11.137542147028499],
              [36.503922698014499, 11.1374181450647],
              [36.504917704362597, 11.137166142958],
              [36.505890710969297, 11.1371971408365],
              [36.507323720578498, 11.137493137842901],
              [36.508367727330402, 11.137527135079701],
              [36.5092867335104, 11.137507133542901],
              [36.510482740598199, 11.137437131238199],
              [36.513017757583903, 11.137490126109901],
              [36.513830762560502, 11.1373791247326],
              [36.514597767506302, 11.137194122930699],
              [36.519015798854497, 11.138709113023999],
              [36.5194948030219, 11.1391111126387],
              [36.520110807456597, 11.139390110953],
              [36.521016814116201, 11.1398971104651],
              [36.521637819472097, 11.140128107592499],
              [36.522374824280703, 11.1405201067852],
              [36.523088829522997, 11.1409151056894],
              [36.523629834513002, 11.141344104373699],
              [36.524304840295599, 11.1417071035121],
              [36.524922843481797, 11.141916101635299],
              [36.525559847832902, 11.1418171002236],
              [36.528055863562201, 11.141571095341201],
              [36.5290468706106, 11.1417410932055],
              [36.531722888061701, 11.141516087248799],
              [36.5327638944404, 11.141526085676199],
              [36.537358922645801, 11.140888076209199],
              [36.538443929405503, 11.14069407415],
              [36.539186934061803, 11.1405100721864],
              [36.540506940998199, 11.140120069326001],
              [36.541257945152203, 11.1397250675797],
              [36.541980949717001, 11.139300067143701],
              [36.542752952889003, 11.138972065094899],
              [36.543386957765399, 11.138850064501799],
              [36.544688966056697, 11.138872061419301],
              [36.547891986457401, 11.138787055491701],
              [36.550739000917702, 11.1368190482863],
              [36.552828008856402, 11.1341250454335],
              [36.552738003054799, 11.131833044944701],
              [36.5527130011438, 11.1307490447637],
              [36.5510918528731, 11.1280200998093],
              [36.554138002272303, 11.1268810419662],
              [36.554361001855, 11.126243041830801],
              [36.554737001598703, 11.1251500407944],
              [36.555067998441501, 11.1223920404071],
              [36.555060996972202, 11.121620040274999],
              [36.555220996805801, 11.120862039886701],
              [36.555554995818298, 11.119905038784699],
              [36.556035997838897, 11.1190780391268],
              [36.556817000333403, 11.1179320365981],
              [36.557305000230002, 11.1163430358942],
              [36.557544000604601, 11.115656035090399],
              [36.559573010065499, 11.1137540311429],
              [36.5600330112714, 11.1133050300559],
              [36.560916015669797, 11.112658027703899],
              [36.564442036110599, 11.111534021845999],
              [36.566007045964298, 11.1114840175072],
              [36.567372054903103, 11.1112630147635],
              [36.5683940619137, 11.1112170131623],
              [36.572733093619298, 11.112912004348701],
              [36.573918098141498, 11.1118910015211],
              [36.576076111067003, 11.1110579974346],
              [36.577403118574402, 11.110759994656499],
              [36.577838120564202, 11.110267993935199],
              [36.5780031195224, 11.109375992927101],
              [36.576331105046897, 11.107613996992701],
              [36.577138105684099, 11.1053349954749],
              [36.577820106647799, 11.103582993385199],
              [36.576501092349901, 11.100882996778299],
              [36.5746330789542, 11.1007860009623],
              [36.5737590738783, 11.1004210018409],
              [36.570195052006, 11.1012140097213],
              [36.569198046008097, 11.1014650115468],
              [36.566142026818902, 11.101954017651501],
              [36.563407010714997, 11.102101023442],
              [36.560345988807903, 11.1013700301655],
              [36.558503969961599, 11.098203033213901],
              [36.558320962476998, 11.0950570333502],
              [36.556243944352403, 11.092752038833201],
              [36.553117923046301, 11.0924670446656],
              [36.551854915113097, 11.092543046898699],
              [36.546394883849302, 11.094613058177799],
              [36.542832865220099, 11.0965400651267],
              [36.540453850564901, 11.097003070253299],
              [36.538301835690497, 11.096502075110701],
              [36.534025810920703, 11.097943083838],
              [36.532953810867902, 11.101138086435199],
              [36.533033813928803, 11.102359086226301],
              [36.533164817941604, 11.1036260854793],
              [36.533558824415103, 11.1060360847877],
              [36.533442826759099, 11.107328084713799],
              [36.533360827641701, 11.108244084250099],
              [36.535282492019398, 11.111051102523],
              [36.534700167221303, 11.111513742623099],
              [36.534365644490897, 11.111779510869001],
              [36.533550718017999, 11.112426937395099],
              [36.532621673164698, 11.1131650208512],
              [36.531509695386802, 11.114048424777801],
              [36.530566260420997, 11.114797924397999],
              [36.5271137994255, 11.113722098176201],
              [36.525061785008504, 11.112930101833401],
              [36.524146777229703, 11.1123791033954],
              [36.5233877717816, 11.1119221043396],
              [36.522857767560602, 11.1116021061138],
              [36.521690758090102, 11.110951108656399],
              [36.520891751560697, 11.1102761105955],
              [36.519745742905002, 11.1093081126436],
              [36.518780733999201, 11.1084921147168],
              [36.5179947278885, 11.1078291168146],
              [36.516542715317797, 11.1064681186696],
              [36.515002701985203, 11.105025122811099],
              [36.514577699142798, 11.104445123189199],
              [36.513698690450802, 11.1032481248007],
              [36.512751681088403, 11.1019571267171],
              [36.512156674773401, 11.1006381279871],
              [36.510980662387198, 11.0980311305649],
              [36.5087456404856, 11.094697134632799],
              [36.508091634619397, 11.094163136425699],
              [36.506969626253102, 11.093246138903201],
              [36.506085619334797, 11.0927441411873],
              [36.503958602778098, 11.091413144584701],
              [36.503376598678599, 11.090919146318701],
              [36.502915593156303, 11.0899811464962],
              [36.502946590423399, 11.088907147072501],
              [36.501482577258798, 11.086577149120499],
              [36.499778560301401, 11.084036152844201],
              [36.496689536110303, 11.081779159934801],
              [36.494878521717801, 11.080377163842501],
              [36.493280507760502, 11.0788461656278],
              [36.491943494817498, 11.076977169023699],
              [36.491197487093203, 11.075746170086701],
              [36.490760482758603, 11.0750261712808],
              [36.489804473175397, 11.072978173124699],
              [36.489440468635699, 11.0721981741628],
              [36.489078464644201, 11.071424175031],
              [36.4890204631213, 11.070620174811999],
              [36.488969461270997, 11.0699141746832],
              [36.488868457395398, 11.0685111757978],
              [36.490097461400197, 11.0665041719601],
              [36.486471436908701, 11.065970180479299],
              [36.485417431009502, 11.0663931815029],
              [36.482746413581303, 11.066493187084999],
              [36.478818398847501, 11.067854536178199],
              [36.477783110245497, 11.0663970041703],
              [36.4760960867183, 11.064021891790199],
              [36.475102157450301, 11.062622549868699],
              [36.474752361563503, 11.062130073177],
              [36.474370077206103, 11.0615918542629],
              [36.473866153772804, 11.060882375513399],
              [36.473307719669698, 11.060096147364099],
              [36.472640071089202, 11.059156147962801],
              [36.471471945911198, 11.0575115005845],
              [36.469829821080303, 11.0551994596333],
              [36.466728286950499, 11.055421219755999],
              [36.465656279711297, 11.0553192214054],
              [36.463930269300803, 11.0551532252759],
              [36.4631082632051, 11.055164227327399],
              [36.462236256880701, 11.0551762291641],
              [36.460096242600201, 11.0546512327656],
              [36.457735225691799, 11.0540662374853],
              [36.453269196459502, 11.053626246912501],
              [36.450423178230899, 11.0534592517965],
              [36.447929160354803, 11.053114257388099],
              [36.445440877323399, 11.0512870643825],
              [36.442998120910701, 11.049493267064401],
              [36.441724110411798, 11.047975268992801],
              [36.440947102725303, 11.0468812708966],
              [36.440474098405701, 11.046216272191],
              [36.439744091473202, 11.0451872725793],
              [36.439405087946298, 11.0446692738089],
              [36.437693831465999, 11.0396462440417],
              [36.434603263969301, 11.0384213219402],
              [36.427235125701998, 11.035500861039001],
              [36.422930592896599, 11.0337946012661],
              [36.422516600704697, 11.0312034415123],
              [36.422175284914204, 11.0290671581217],
              [36.421061001161, 11.022092826646899],
              [36.419156882677498, 11.0198993563548],
              [36.419928328894002, 11.0150033078934],
              [36.4190478002525, 11.009491914157699],
              [36.4187930030036, 11.0078970795843],
              [36.418688994263398, 11.0072460616444],
              [36.4185320990075, 11.0062640168691],
              [36.418253145162303, 11.004517970149299],
              [36.417835354893299, 11.0019028964089],
              [36.417455938856698, 10.999528006063301],
              [36.4153651774065, 10.9973263819456],
              [36.414438940686701, 10.9963510174232],
              [36.413839185877201, 10.995719448168501],
              [36.413196970399099, 10.9950431610738],
              [36.412553278090698, 10.994365315120801],
              [36.4104254027446, 10.9921245131276],
              [36.408807288982899, 10.9904204966666],
              [36.406140925726199, 10.9876125304531],
              [36.403371615537999, 10.9846960806611],
              [36.400988617752901, 10.9821864141114],
              [36.400324968472098, 10.981487478781901],
              [36.399292793430902, 10.9804004155071],
              [36.398037456211803, 10.9790783088244],
              [36.396419481689001, 10.977374257615001],
              [36.394579829903499, 10.975436705504],
              [36.392828290270899, 10.973591926952199],
              [36.390198971739999, 10.9708225901089],
              [36.389278513882402, 10.9698530996647],
              [36.388632993502398, 10.969173188666799],
              [36.388207816386803, 10.9687253580876],
              [36.387623184715103, 10.968109574059101],
              [36.387012384220803, 10.967466225600299],
              [36.386492477961902, 10.966918609681301],
              [36.3859837458469, 10.9663827609702],
              [36.385265368504001, 10.9656260899621],
              [36.384412901435397, 10.964728175549499],
              [36.383691937842897, 10.9639687690067],
              [36.383031457818397, 10.9632730678043],
              [36.382564233259401, 10.962780924947999],
              [36.382139627472299, 10.962333672930599],
              [36.381706109499397, 10.961877031268401],
              [36.381079971169903, 10.9612174920732],
              [36.380599192829898, 10.9607110629728],
              [36.380035359851902, 10.9601171473458],
              [36.379376832764898, 10.959423481403199],
              [36.377419782849799, 10.9573619805238],
              [36.375630913725701, 10.955563305692699],
              [36.374738687222496, 10.9546661765399],
              [36.373980602547498, 10.953903923700899],
              [36.3733335190989, 10.9532532770794],
              [36.372725268023402, 10.952641673931],
              [36.372106258724102, 10.952019249708499],
              [36.371544311309897, 10.951454199805699],
              [36.371024885649597, 10.9509319025836],
              [36.370369462241896, 10.950272854062099],
              [36.369446224996899, 10.949344504186101],
              [36.3689076203054, 10.948802912937801],
              [36.368337733580702, 10.948229863647301],
              [36.367572234211302, 10.947460113391401],
              [36.3669815362165, 10.9468661306088],
              [36.366467312700102, 10.9463490447989],
              [36.366001487716197, 10.9458806244351],
              [36.365618335586298, 10.9454953372693],
              [36.365234925639903, 10.945109790301901],
              [36.364335803668602, 10.944205648631399],
              [36.362963919922898, 10.942826093728399],
              [36.362050269498603, 10.941907325322701],
              [36.360257652603003, 10.940104647655801],
              [36.357417344964702, 10.939604431353301],
              [36.355603330199102, 10.9382024347194],
              [36.354432321429002, 10.9373794369275],
              [36.3532753120043, 10.936484439185501],
              [36.352723308042002, 10.936056440354101],
              [36.350065284744403, 10.932815444822401],
              [36.349758280106201, 10.9316534449793],
              [36.349978281112499, 10.9309914446989],
              [36.350555281595902, 10.9298134440429],
              [36.3508362827016, 10.929167443063101],
              [36.351123282756802, 10.9283924420145],
              [36.351326282248102, 10.9277794415939],
              [36.351654283209498, 10.927045441533901],
              [36.352133284681898, 10.926384440015299],
              [36.353908166138801, 10.923354709900501],
              [36.353683964610198, 10.922763240280901],
              [36.353156867337397, 10.921372693272],
              [36.352649135945597, 10.920033228037701],
              [36.352406562971098, 10.9193932839871],
              [36.352020141871897, 10.918373840358401],
              [36.351712943197498, 10.9175633956879],
              [36.348979248515398, 10.918204446249501],
              [36.348254243402401, 10.9179884468999],
              [36.346877232971401, 10.9174854501171],
              [36.345741225259701, 10.9169264523705],
              [36.3455932206301, 10.9155314527261],
              [36.346526222562296, 10.9131124510257],
              [36.346489220955696, 10.9124734499963],
              [36.346245216965002, 10.9114314509103],
              [36.345600211319599, 10.910385452359201],
              [36.345149206741397, 10.909826452722999],
              [36.344727202475397, 10.909135452990199],
              [36.343762195366303, 10.9082604551561],
              [36.3417621790312, 10.906697459026899],
              [36.340146165530598, 10.9053604618934],
              [36.339261158787401, 10.9045564633712],
              [36.337033140151803, 10.9024284677078],
              [36.336547135413397, 10.901873468446199],
              [36.335941131024398, 10.901394470272599],
              [36.335300126475303, 10.901095471137699],
              [36.334573120814198, 10.900691471941499],
              [36.333502112501499, 10.8999014748624],
              [36.332316102764501, 10.898694476752],
              [36.3316350962358, 10.897828477530799],
              [36.330810087970498, 10.8964904799093],
              [36.329955074178002, 10.8921504813892],
              [36.333009085632597, 10.887802474132],
              [36.332311080283802, 10.8874274759642],
              [36.330921069433899, 10.8870544780334],
              [36.328223045055097, 10.883540483444801],
              [36.328150040768598, 10.881157483044399],
              [36.326470029490501, 10.8806574868764],
              [36.324207011593103, 10.8796314909809],
              [36.321127988346099, 10.877734496394],
              [36.3195759726229, 10.8750724992451],
              [36.3186819647606, 10.873612501542199],
              [36.317553956546597, 10.872912503250999],
              [36.3166999497655, 10.872464504541099],
              [36.315706939473998, 10.870270506571201],
              [36.315521935034504, 10.869220506460101],
              [36.314803927797598, 10.8677705081305],
              [36.314291924018299, 10.8674285092292],
              [36.310075889641404, 10.863791516910201],
              [36.3091648820606, 10.8629495184792],
              [36.308113871144499, 10.860862519977401],
              [36.307980864786899, 10.8577105195474],
              [36.308052861163702, 10.8561815200122],
              [36.308072859458498, 10.855014519545501],
              [36.308223859071298, 10.854039519217],
              [36.308887861389898, 10.853640517701001],
              [36.310056869611898, 10.8539795159982],
              [36.310858874717802, 10.8533995145527],
              [36.312086880694302, 10.852502511249],
              [36.313359886876697, 10.851362509220101],
              [36.314105889211099, 10.850230506667399],
              [36.313730877614297, 10.8457455074403],
              [36.313252872373504, 10.8446805080769],
              [36.312836868853701, 10.844051509155101],
              [36.312066861738202, 10.842716510077899],
              [36.311729857861103, 10.842136511487199],
              [36.311196852368703, 10.841256512147501],
              [36.310008841185201, 10.8390935144833],
              [36.309429833865501, 10.8376845148103],
              [36.309658834194302, 10.836795514435099],
              [36.309716832124003, 10.835569514866],
              [36.309728830022898, 10.8345655142901],
              [36.309630827418701, 10.8336595149948],
              [36.309428825057402, 10.8329595148733],
              [36.309337823024201, 10.832159513991],
              [36.311556114701901, 10.830073250844601],
              [36.311351044593899, 10.8296587044769],
              [36.311202839640103, 10.829359112029101],
              [36.311008762216197, 10.828966785863599],
              [36.310798904087697, 10.8285425624469],
              [36.310570938618099, 10.8280817291669],
              [36.310432838812801, 10.827802560893801],
              [36.309219320865203, 10.825349419012101],
              [36.307455582110997, 10.8217839377077],
              [36.306683969706199, 10.819265106125201],
              [36.3031317579849, 10.8194655250033],
              [36.301633747180098, 10.819155527430899],
              [36.298418722592302, 10.8169255336262],
              [36.295470579390603, 10.815615903186],
              [36.2926632147703, 10.814912867949801],
              [36.292890362773001, 10.81254030863],
              [36.299855720721403, 10.8110805307003],
              [36.300038721019497, 10.810422529981],
              [36.298618707337504, 10.8084455323133],
              [36.295564684204798, 10.806795538486501],
              [36.2947856786651, 10.8064055396471],
              [36.293261668819298, 10.8061295427822],
              [36.291237654889699, 10.8055135460892],
              [36.289816644077803, 10.8050565493403],
              [36.289223639209297, 10.804698549875701],
              [36.288463633936203, 10.804391551291401],
              [36.284723610540098, 10.8048475590117],
              [36.283904605715797, 10.8050505605675],
              [36.280474277412303, 10.804357878376299],
              [36.279816798659198, 10.801163443281499],
              [36.281770579605002, 10.798068562940999],
              [36.282012579454303, 10.797425562931799],
              [36.283062582460701, 10.795561560526901],
              [36.283342582843197, 10.7948495597185],
              [36.283665582883401, 10.7939555596286],
              [36.284192584036397, 10.792741557988901],
              [36.285010582747603, 10.7893215558523],
              [36.285238583251498, 10.7887135548703],
              [36.286099584315302, 10.7866285533458],
              [36.286213583219798, 10.7856995530777],
              [36.286176578918401, 10.7834995533496],
              [36.286317578485303, 10.7827455526881],
              [36.286839579615503, 10.7816385523225],
              [36.287104579929299, 10.780985551559301],
              [36.287324579139103, 10.7798145502808],
              [36.288400577593102, 10.775390547705999],
              [36.288372574410801, 10.7742445473597],
              [36.288385568541301, 10.7704915480822],
              [36.288592566433898, 10.7693325467293],
              [36.288618562780002, 10.767042546502701],
              [36.287916555436603, 10.765755547633599],
              [36.287565552213998, 10.7649905479845],
              [36.287076548000897, 10.764415548665699],
              [36.286860545395903, 10.7637735498704],
              [36.2867205420606, 10.7627605494684],
              [36.2865455385651, 10.7617465501841],
              [36.286500536562599, 10.7607155492937],
              [36.286241532503297, 10.7597325505334],
              [36.285955527781397, 10.7578465506824],
              [36.285893526060498, 10.757176550250501],
              [36.286462527046297, 10.7558025488603],
              [36.286590524397901, 10.7538205483281],
              [36.286043518661799, 10.7527525488869],
              [36.285576514189998, 10.7519575494513],
              [36.285022507882999, 10.7510175516234],
              [36.284585504446703, 10.750586552314401],
              [36.283959499067898, 10.749677553356801],
              [36.283667496294903, 10.7489045531727],
              [36.283748492892499, 10.747200553068],
              [36.283912991488997, 10.744951186643201],
              [36.286110499744403, 10.743121548017299],
              [36.2871005060695, 10.743008545457901],
              [36.291023531560299, 10.742889537843199],
              [36.292233539790097, 10.743090536259899],
              [36.293808549689601, 10.7432745335999],
              [36.295192558895003, 10.7435205304345],
              [36.296404567672901, 10.743931528487501],
              [36.2977425777459, 10.744396525822699],
              [36.298819584109701, 10.744708523542201],
              [36.299972593336399, 10.7451285221356],
              [36.3006755978098, 10.745386520326299],
              [36.303618618817801, 10.746387514469101],
              [36.305025627589401, 10.7466575126347],
              [36.305941634294697, 10.7468785108685],
              [36.308339649216499, 10.746507506525001],
              [36.309087653895702, 10.746311504795701],
              [36.310031659291703, 10.7461945036621],
              [36.311064666172904, 10.7461655009301],
              [36.312094672681098, 10.7462174996794],
              [36.313139679257802, 10.7462214967994],
              [36.311308671960298, 10.748224500875301],
              [36.310758669444397, 10.7491775012254],
              [36.3110166736789, 10.750196501813001],
              [36.3126556885423, 10.752255498086701],
              [36.313365693586697, 10.752851496849701],
              [36.314312701152502, 10.7536704954742],
              [36.316761720619397, 10.7559574907679],
              [36.3184207337777, 10.757104488201399],
              [36.319791745259202, 10.758366485870299],
              [36.320562751301303, 10.7589304845922],
              [36.3227607697997, 10.7608304805849],
              [36.323437775232101, 10.7616474789398],
              [36.3242837825156, 10.762625477313501],
              [36.325143790042702, 10.7637904764099],
              [36.325679795908101, 10.764601475398599],
              [36.326570803397402, 10.7656054742442],
              [36.325231303917697, 10.7687104057337],
              [36.328341123209299, 10.7695076643001],
              [36.329330293202901, 10.769761248588001],
              [36.3302384989383, 10.7699940755763],
              [36.331282555097502, 10.77026172283],
              [36.332391316008298, 10.7705459544822],
              [36.333338007367097, 10.7707886365052],
              [36.334316128082797, 10.7710393710414],
              [36.3339718676147, 10.774359461135401],
              [36.334363872641802, 10.775502459744899],
              [36.334707876191601, 10.776665459658799],
              [36.3354078847835, 10.7782324586962],
              [36.335754888706397, 10.7791044580228],
              [36.336563896179896, 10.780348456627401],
              [36.337185901360797, 10.781081455473601],
              [36.3380149089258, 10.781918453607499],
              [36.338578913482003, 10.7828934528752],
              [36.339368921587202, 10.7845574518219],
              [36.339931929013403, 10.785814451007299],
              [36.340587936326102, 10.787577449769699],
              [36.341180942092898, 10.7886834478903],
              [36.342890957101503, 10.790991445631899],
              [36.345245972365497, 10.790804440985699],
              [36.346855982424998, 10.79048843799],
              [36.348155990168202, 10.790272435427299],
              [36.349233996356901, 10.7900454331836],
              [36.3508320067209, 10.789985430388899],
              [36.352461016148702, 10.789786426667],
              [36.3563540415022, 10.7895614201228],
              [36.357718049717803, 10.789733416226101],
              [36.358687056126598, 10.789595415902401],
              [36.3613520728086, 10.7892854095474],
              [36.363169083455702, 10.789071405719699],
              [36.364439091960001, 10.788761403236601],
              [36.365621098804098, 10.788563401153199],
              [36.370052125181303, 10.787873392686601],
              [36.3707511294567, 10.787733391901],
              [36.372476140940599, 10.787713387875501],
              [36.373652147038598, 10.787410386158699],
              [36.376409162883803, 10.7866353808065],
              [36.377837172470201, 10.786598378209],
              [36.377332164770301, 10.783998378393999],
              [36.376398154928403, 10.782163379372699],
              [36.375582146883801, 10.780845381665401],
              [36.375406144461799, 10.780272382273701],
              [36.374416135441699, 10.7790133840931],
              [36.374065132219101, 10.778325384278499],
              [36.373307124799197, 10.7771213858928],
              [36.3726586893861, 10.7748189728381],
              [36.372651227979397, 10.7748189101822],
              [36.372649109751897, 10.7715543865475],
              [36.3729901084372, 10.769774384486601],
              [36.376253833391402, 10.7677532946621],
              [36.376194807789098, 10.7672515578082],
              [36.376063042004901, 10.7661315135603],
              [36.376000905536699, 10.7656033394658],
              [36.375864507140598, 10.764443922179799],
              [36.375714859492298, 10.763171875198299],
              [36.375642903539699, 10.7625602233931],
              [36.375298502036202, 10.759632722306],
              [36.373511963141901, 10.758015289846099],
              [36.3729939307687, 10.7575462874031],
              [36.371478033608199, 10.756173855957],
              [36.370428202180101, 10.755223369983501],
              [36.369508740554103, 10.7543909107027],
              [36.368343025556001, 10.753335490364799],
              [36.367452744803799, 10.752529437734299],
              [36.366947366099701, 10.7520718689467],
              [36.365897552637897, 10.751121364754001],
              [36.364257325947101, 10.7496362826145],
              [36.363682897340396, 10.7491161823461],
              [36.363135124442998, 10.748620215510799],
              [36.3625894077894, 10.7481261074053],
              [36.362050153615897, 10.7476378480796],
              [36.361665301873501, 10.747289388735201],
              [36.361223030716403, 10.7468889391895],
              [36.361766986672102, 10.74426440381],
              [36.360252747801603, 10.7423889766974],
              [36.3586229595345, 10.7403704093577],
              [36.356648944036898, 10.739516412976499],
              [36.354285292369802, 10.741106343776201],
              [36.352010794003, 10.7396329427516],
              [36.3514195767819, 10.7392499528739],
              [36.349564513499402, 10.738048231748801],
              [36.3489967351207, 10.737680419462899],
              [36.347946566824298, 10.7370001035904],
              [36.347274650347998, 10.7365648213664],
              [36.346668538161197, 10.736172168527901],
              [36.345591491373199, 10.735474425998399],
              [36.344486780067697, 10.7347587563592],
              [36.343975726705899, 10.734427677235701],
              [36.342884020310798, 10.733720422452899],
              [36.341806444415099, 10.7330223159277],
              [36.340858114529198, 10.732407937569],
              [36.339554315895597, 10.731563258401801],
              [36.338490217424102, 10.7308738643954],
              [36.337804661826802, 10.730429712853301],
              [36.336258728596498, 10.7294281398037],
              [36.335139867926898, 10.728703248437601],
              [36.334284263043003, 10.7281489108376],
              [36.333010690023698, 10.7273237697274],
              [36.331471447445601, 10.7263264918082],
              [36.329460469867399, 10.725023557470299],
              [36.329087787602099, 10.7222735727429],
              [36.328909473814797, 10.7209578042076],
              [36.328826124529499, 10.7203427773177],
              [36.328742659361502, 10.719726896549],
              [36.328662113718202, 10.719132558013399],
              [36.328384232461502, 10.717082090154699],
              [36.328138030293204, 10.7152653778982],
              [36.3279673054735, 10.7140056028021],
              [36.325487692981298, 10.712924469057199],
              [36.324322682262299, 10.7111484711999],
              [36.322516666096, 10.708685473963399],
              [36.321353656823497, 10.707540475651401],
              [36.320771651825702, 10.706911476996201],
              [36.317572624436501, 10.703592482435001],
              [36.316342609826997, 10.699921484523101],
              [36.3166266077125, 10.6981414842096],
              [36.316619604446501, 10.696304483252099],
              [36.3166136010055, 10.694525483009601],
              [36.3175886027704, 10.6918234815234],
              [36.3190896085585, 10.6902114780194],
              [36.3202456136667, 10.689231475614299],
              [36.321975620681798, 10.68692947177],
              [36.321848618663601, 10.686228472377],
              [36.321620614566399, 10.684978472152],
              [36.3213816088019, 10.6829704725951],
              [36.319572591364199, 10.6795314756647],
              [36.318992586016201, 10.679188476360901],
              [36.317195574562298, 10.679195480151799],
              [36.3159795682813, 10.6793704821829],
              [36.312272545395501, 10.679843489009601],
              [36.310819533898197, 10.678584491917499],
              [36.310643530577799, 10.6779534920469],
              [36.310635526588698, 10.6756564918773],
              [36.311326528671501, 10.674449490274201],
              [36.312481533954802, 10.673526487995399],
              [36.313659540601002, 10.6728514854164],
              [36.314217543512697, 10.6726014843098],
              [36.317402966815301, 10.6706106966547],
              [36.319478570093302, 10.669313474679701],
              [36.320055573271702, 10.6688524732559],
              [36.320459575299303, 10.668276472088101],
              [36.321034576132902, 10.667069471203799],
              [36.321204575113903, 10.665863470151301],
              [36.321837576572101, 10.664712469741],
              [36.322414578852197, 10.664136467796601],
              [36.324778888086101, 10.6626408194068],
              [36.324474796684299, 10.662142455464901],
              [36.324191327906497, 10.6616778872263],
              [36.3232393517589, 10.660117713509701],
              [36.320612069708702, 10.655811841800199],
              [36.3198112351077, 10.654499327070299],
              [36.3193075865588, 10.6536738744595],
              [36.319000221696101, 10.6531701176261],
              [36.318665096196199, 10.6526208613619],
              [36.317256497406497, 10.650312208910201],
              [36.316876262106298, 10.6496890085846],
              [36.314135498581201, 10.649754481038499],
              [36.312802490327698, 10.649758483844201],
              [36.311469482074301, 10.649590486026799],
              [36.309670469173902, 10.6489074891748],
              [36.309196464131098, 10.648442490222299],
              [36.3081004557041, 10.6471914915974],
              [36.3072264470349, 10.6459874935796],
              [36.306585440689197, 10.644841494467],
              [36.305769433542999, 10.643638496275599],
              [36.305246429893003, 10.6430094969122],
              [36.303092409978802, 10.6402035010115],
              [36.302913406285398, 10.638883500274],
              [36.302909403392498, 10.637678500933699],
              [36.302905401397901, 10.6365295005854],
              [36.3031354015517, 10.635955499824],
              [36.305817443104999, 10.6329721193352],
              [36.304986718859503, 10.6317762169446],
              [36.304706623255598, 10.6313729918951],
              [36.304527683342599, 10.6311153894444],
              [36.304106138115699, 10.630508530290999],
              [36.303983008734697, 10.630331272627499],
              [36.305843273262703, 10.628342861877501],
              [36.306313521550997, 10.6278402127411],
              [36.308081414115001, 10.6259504881611],
              [36.307551406300703, 10.6235974897284],
              [36.307144403001701, 10.623024490603401],
              [36.303778377902802, 10.621370496492],
              [36.301403363445701, 10.621665500732901],
              [36.2995950943046, 10.6240142601565],
              [36.297991836881103, 10.6214268764914],
              [36.297626869347397, 10.6208378737992],
              [36.296774532534499, 10.619462327218301],
              [36.296746837474302, 10.6194176306666],
              [36.293523312128997, 10.621231515369701],
              [36.292305304401502, 10.620833517443801],
              [36.291258294581702, 10.619803519304201],
              [36.290792290833103, 10.6191745202656],
              [36.289689282733399, 10.618258521765799],
              [36.288353272310303, 10.6174605248661],
              [36.287772268035603, 10.6169445254762],
              [36.287248262764102, 10.6163165262208],
              [36.286086254214901, 10.6151715282182],
              [36.285271245995197, 10.6140255290818],
              [36.284805241348302, 10.613395531018201],
              [36.284453237402602, 10.6122485310229],
              [36.2842752344324, 10.610986530799099],
              [36.284151228295599, 10.608575530239801],
              [36.283801224897999, 10.6080025312112],
              [36.282640216173697, 10.6073175332338],
              [36.282059211899004, 10.6068605341853],
              [36.281651207876799, 10.6062305346077],
              [36.281357203657301, 10.605025535383399],
              [36.281297202484502, 10.604393534742499],
              [36.2812351998653, 10.603188535348499],
              [36.281345198571898, 10.6020045346578],
              [36.281402196676602, 10.6013515340005],
              [36.281876197227902, 10.599632532851601],
              [36.282378199164803, 10.598822532570701],
              [36.282957201993, 10.598589531046599],
              [36.284694212274204, 10.5983555280338],
              [36.286546223979698, 10.598004524062301],
              [36.290196246964001, 10.5981655181128],
              [36.292106258395798, 10.597584513807901],
              [36.292684261399103, 10.597181512973799],
              [36.293375263481899, 10.5959735112578],
              [36.293952265762002, 10.595454509636101],
              [36.2945302687652, 10.5948795081244],
              [36.295736270508897, 10.592176506823201],
              [36.298342260690198, 10.5904406725088],
              [36.298503021396897, 10.589182770531799],
              [36.298578040604497, 10.5885957623238],
              [36.298887940512898, 10.5861708164337],
              [36.298933260519, 10.585816179859499],
              [36.299071438477696, 10.584734918788801],
              [36.299104751601703, 10.584474235023199],
              [36.302544300213299, 10.5845194921038],
              [36.304211303885999, 10.5809544875528],
              [36.305712309674099, 10.579341484914],
              [36.306231312227801, 10.578766484083401],
              [36.306169308710203, 10.577618483713501],
              [36.3052943011145, 10.576014485707599],
              [36.303723286921397, 10.574124487982401],
              [36.3031372790307, 10.5721734887886],
              [36.300792637511499, 10.571264990029199],
              [36.300942414517202, 10.5700927336379],
              [36.301164075610203, 10.568357843753899],
              [36.301517786354999, 10.5655893372835],
              [36.301675632926802, 10.5643538353973],
              [36.302065406538702, 10.561302899664501],
              [36.301057238973399, 10.557771490034099],
              [36.301016237168902, 10.557143490527],
              [36.300935235181399, 10.555935489257299],
              [36.300875233110297, 10.555246489277],
              [36.300870230392498, 10.5540404896481],
              [36.301556230655898, 10.5516274879853],
              [36.302715237933803, 10.5516244853602],
              [36.303352241386598, 10.551736485025099],
              [36.304572251458801, 10.552479482574199],
              [36.307015411215097, 10.5540915930399],
              [36.307642853898301, 10.553177478921899],
              [36.308330679538301, 10.552175387105599],
              [36.308663057990103, 10.5516911409825],
              [36.309283353676904, 10.5507874203225],
              [36.310262794017703, 10.5493604375923],
              [36.310974151026599, 10.5483240209178],
              [36.3113723822769, 10.547743813350699],
              [36.312148061355003, 10.546613665423299],
              [36.310680274367897, 10.544076468668299],
              [36.310791272001097, 10.5427564688337],
              [36.310902270532502, 10.5414344682937],
              [36.311303270390503, 10.540113467994599],
              [36.313611280408999, 10.537694462892199],
              [36.313599274425201, 10.5343654621064],
              [36.315449284684199, 10.533497457492301],
              [36.316429288268701, 10.5323464557327],
              [36.317230289058102, 10.5297594541875],
              [36.318552290254402, 10.526769450788301],
              [36.319764296337397, 10.525675449451301],
              [36.324043319683703, 10.5238794404488],
              [36.326532334842, 10.5234694354009],
              [36.327687341023598, 10.522548433220001],
              [36.330522357584996, 10.5219064283528],
              [36.332321371383699, 10.522818424616],
              [36.332902374760003, 10.523104423899801],
              [36.335854393293801, 10.5227494187118],
              [36.336433396121997, 10.5225744172105],
              [36.337589402128501, 10.521997414893599],
              [36.3381664053069, 10.5215934140631],
              [36.339438411664297, 10.520785410815],
              [36.340595419292399, 10.5205514086178],
              [36.342391430023099, 10.520659405373801],
              [36.3436084388239, 10.5207704032515],
              [36.344768446825, 10.521225400953499],
              [36.346577513670098, 10.5236586930936],
              [36.348799821715502, 10.5244124504356],
              [36.349341330660302, 10.5245961151111],
              [36.3500766062119, 10.524845499616401],
              [36.351308068007398, 10.525263170783001],
              [36.352541671386497, 10.5256815620748],
              [36.354743151991997, 10.526428208267101],
              [36.355593046285698, 10.526716450172],
              [36.359108553742701, 10.530303376373499],
              [36.361528563015902, 10.5267933703936],
              [36.359843203168602, 10.524546860667201],
              [36.360386311114702, 10.5236244397429],
              [36.360736850806497, 10.523029074866599],
              [36.361558692510499, 10.5216332248032],
              [36.362224603749198, 10.5205021994551],
              [36.362960288034202, 10.5192526562791],
              [36.3632087440755, 10.518830654500199],
              [36.363622849455197, 10.518127296016701],
              [36.364409479895002, 10.516791188962401],
              [36.364686735025998, 10.516320261036499],
              [36.364879730878201, 10.515992451441299],
              [36.365156574580801, 10.5155222200473],
              [36.364309553099403, 10.5122003628948],
              [36.364418550184404, 10.5103063626464],
              [36.363600541591701, 10.5085283637326],
              [36.363424538271403, 10.5079553636635],
              [36.363190536123, 10.507324363795799],
              [36.363185534303497, 10.5061193637376],
              [36.3638765363864, 10.5050833616851],
              [36.364455539214497, 10.504851361417799],
              [36.365614546492402, 10.505018359160699],
              [36.366772554843699, 10.5053323574228],
              [36.367744560727203, 10.5052753555004],
              [36.371044943233301, 10.50552006287],
              [36.371293374121798, 10.505098049388399],
              [36.371663347966503, 10.5044695680425],
              [36.372012423404399, 10.5038765819469],
              [36.373663954494702, 10.5010710310869],
              [36.375282889316601, 10.498891210568001],
              [36.377322621966997, 10.496144739728701],
              [36.3777468010543, 10.4955735777838],
              [36.378660759600798, 10.4943429147689],
              [36.379520343123097, 10.493185453254901],
              [36.381386616482096, 10.490672402925499],
              [36.382041186490198, 10.489790969414599],
              [36.382481562202102, 10.489197963120599],
              [36.382886465239999, 10.488652720428901],
              [36.383736579620901, 10.4875079449063],
              [36.384711166364198, 10.4861955398978],
              [36.385163766350502, 10.4855860511415],
              [36.386021739193502, 10.4844306591694],
              [36.386353724183103, 10.483983587196001],
              [36.385038624743203, 10.4812383178615],
              [36.386186626760498, 10.478880315690301],
              [36.386763629040502, 10.478304314778599],
              [36.3873416320438, 10.4779003124941],
              [36.388263635003597, 10.4766913111339],
              [36.3886086365818, 10.4761163105113],
              [36.388775634291399, 10.474392309256],
              [36.388943633622603, 10.4731293091869],
              [36.389281631036397, 10.4707153085303],
              [36.389680629448002, 10.4689343066044],
              [36.390428632330597, 10.4677843055482],
              [36.390715633284103, 10.467209304304101],
              [36.391292635564099, 10.4667473033034],
              [36.393588649480201, 10.466451298988799],
              [36.395515659732197, 10.4654682954365],
              [36.3959186619349, 10.464893294315599],
              [36.396147661365497, 10.464318293895699],
              [36.396376661694298, 10.463743292409999],
              [36.396777662450603, 10.4624792922666],
              [36.397758666758399, 10.4616142898542],
              [36.398335669936699, 10.4612102887898],
              [36.400084679015102, 10.4606662857123],
              [36.401285686125803, 10.460223283675701],
              [36.402842692888598, 10.4587822802009],
              [36.403472693973796, 10.456942278446901],
              [36.406132707937999, 10.455956273019],
              [36.407284715543199, 10.455871271521101],
              [36.408563724268298, 10.4557742690246],
              [36.409354727706898, 10.455807267509501],
              [36.409718729551599, 10.4550242667764],
              [36.410296732554897, 10.454677264846399],
              [36.411451738736503, 10.4539272625987],
              [36.414981759375301, 10.453282255789199],
              [36.416314768527101, 10.4535642530227],
              [36.417474775629898, 10.453962251589401],
              [36.418749785055297, 10.454358249621601],
              [36.421040153059899, 10.4566984337565],
              [36.4226957112976, 10.455922926491001],
              [36.425352826398402, 10.4546782365888],
              [36.425932831746501, 10.454906235527099],
              [36.429059850182497, 10.454836230369001],
              [36.430271856265499, 10.453855227156399],
              [36.433625875380599, 10.4526372211379],
              [36.435680887475598, 10.452184216286099],
              [36.436527892787403, 10.452716215465401],
              [36.440548921780703, 10.454279208008099],
              [36.444347946524601, 10.4550882011608],
              [36.444920951301597, 10.4552801994665],
              [36.445493955180297, 10.4554701985195],
              [36.446119960559102, 10.455834197924201],
              [36.447251968072798, 10.4566751960138],
              [36.448754981595499, 10.4585611939942],
              [36.4492569853291, 10.459152192664201],
              [36.4497599906842, 10.4597421919808],
              [36.450145994066801, 10.460329191080101],
              [36.452329016089898, 10.464480187282801],
              [36.452366016798202, 10.465113187874801],
              [36.452403018404702, 10.4657461880584],
              [36.452438019463102, 10.4664371880583],
              [36.452534022417503, 10.4670721872491],
              [36.452687023476699, 10.467709187728399],
              [36.452937028213597, 10.4689251876631],
              [36.454849046481698, 10.472491184737001],
              [36.455004049885702, 10.4730711838952],
              [36.455157050944898, 10.473707183582601],
              [36.455351054706902, 10.474864183490901],
              [36.456452064953403, 10.476623181805699],
              [36.457018069159403, 10.4770431812483],
              [36.458828083727099, 10.4784221781235],
              [36.459398087232699, 10.4787271765932],
              [36.460894097489501, 10.4790621745084],
              [36.462054105490601, 10.4790411715066],
              [36.464017118422497, 10.47927516891],
              [36.464591122126102, 10.4794651673964],
              [36.465222125731103, 10.479658165558201],
              [36.466496134433299, 10.479698163665599],
              [36.468349145963799, 10.479756160514601],
              [36.469621154117903, 10.479854157206599],
              [36.470249158248201, 10.4801611558911],
              [36.471823488122901, 10.4822044405479],
              [36.475189192523203, 10.4815241480421],
              [36.479161219316097, 10.482396139401001],
              [36.482321240957297, 10.4832421348259],
              [36.483074244761099, 10.483266131999899],
              [36.484414253585598, 10.4830781303846],
              [36.485116258234001, 10.482871129287],
              [36.487042268661099, 10.482471124695699],
              [36.490606009360697, 10.4831077958431],
              [36.491124552875299, 10.4827880422595],
              [36.491601379526401, 10.4824940107092],
              [36.493988309237501, 10.481022111265901],
              [36.494683314213297, 10.4810441096429],
              [36.497752333821303, 10.481141104234901],
              [36.498389338172402, 10.481160102825999],
              [36.499728345375402, 10.480972099932201],
              [36.500950353300802, 10.4808380982792],
              [36.501772357599798, 10.4805190967147],
              [36.504522373772303, 10.479744091566999],
              [36.5083663960271, 10.479174083021601],
              [36.509528403678097, 10.4790950812988],
              [36.5101734075268, 10.478885079506201],
              [36.511574415552303, 10.478585077329299],
              [36.512213419553298, 10.478547076296801],
              [36.513375426305899, 10.478468074414099],
              [36.516851448314597, 10.4785200674708],
              [36.518007455219497, 10.478613064883699],
              [36.520936475983703, 10.479453059570901],
              [36.523305495084699, 10.481481054996999],
              [36.523926499542299, 10.482018053888099],
              [36.524492504646602, 10.4824380530825],
              [36.525058508852602, 10.482858052590601],
              [36.526193516739397, 10.4835840504347],
              [36.528582006824202, 10.4825252299885],
              [36.532328991265302, 10.480864131709501],
              [36.5324360408028, 10.480209615346],
              [36.532549059645298, 10.479518595699201],
              [36.532634511886698, 10.4789961215338],
              [36.5329320500785, 10.477176881558],
              [36.533043743007603, 10.476493946540799],
              [36.533244686255202, 10.4752652879507],
              [36.536290562566599, 10.4743570284152],
              [36.537023564482197, 10.4732300278074],
              [36.537564566777299, 10.472615027046199],
              [36.537932567921601, 10.4719940262158],
              [36.538608569935803, 10.470807023885399],
              [36.5391675717742, 10.4696760228865],
              [36.539419571669299, 10.469051022493],
              [36.540099572983202, 10.4677500203909],
              [36.540351573776597, 10.467127019591199],
              [36.5406015740218, 10.4665590195892],
              [36.540796574015502, 10.4659330191223],
              [36.542647579607902, 10.462483015713],
              [36.5449805888424, 10.4602570095978],
              [36.546061592709201, 10.4590840082461],
              [36.546527593762796, 10.4579460069042],
              [36.546793593003301, 10.4561750059422],
              [36.546311583072999, 10.453171005789001],
              [36.543860562159601, 10.4501050103128],
              [36.543415558327403, 10.4495160112641],
              [36.543051553787798, 10.448240011119299],
              [36.542573548546997, 10.446903012363901],
              [36.541024533196897, 10.444613015462799],
              [36.540017525356703, 10.4434900165835],
              [36.539076516740401, 10.4421380178236],
              [36.5376545025102, 10.439507020308501],
              [36.536526489804601, 10.436769021456801],
              [36.535874483588202, 10.4354260232887],
              [36.533251449670999, 10.4350070321101],
              [36.5331474076931, 10.433642103228999],
              [36.533101610681598, 10.433041283169601],
              [36.533056849427602, 10.4324540487631],
              [36.532864453649999, 10.4299300285534],
              [36.532037446633097, 10.428639028782101],
              [36.531321439046003, 10.427466030029001],
              [36.530673432129298, 10.426010031292099],
              [36.530230427946897, 10.424996032567501],
              [36.529720422020802, 10.423925032221799],
              [36.529336418287997, 10.4231170332068],
              [36.529048414814604, 10.422509033886801],
              [36.528603410982399, 10.4219210339445],
              [36.5282224076227, 10.421162035565001],
              [36.526898392403503, 10.417327037188301],
              [36.525646378951002, 10.414816039328301],
              [36.525319376017897, 10.4141730400685],
              [36.524606368803802, 10.4129440405595],
              [36.523765357949998, 10.4102730420074],
              [36.523494355991701, 10.4096900418486],
              [36.520411146595499, 10.407288214141101],
              [36.520415719918603, 10.406708898059399],
              [36.520425769371698, 10.4054357910015],
              [36.520434925000998, 10.4042757089374],
              [36.520446754915, 10.4027766640279],
              [36.520460376968003, 10.4010502345999],
              [36.520465355431298, 10.400419119068401],
              [36.520486970693703, 10.397678767519499],
              [36.521915116823898, 10.3955934570865],
              [36.522683977689098, 10.394470786037401],
              [36.523084414303398, 10.393886074034899],
              [36.523853268880302, 10.392763395082],
              [36.524633100870098, 10.391624673333],
              [36.525032746068597, 10.3910411011266],
              [36.525377337116602, 10.390537918084901],
              [36.525791854822998, 10.3899326226607],
              [36.526285875228702, 10.3892112297167],
              [36.527074245705201, 10.3880600010454],
              [36.527488441814697, 10.3874551620573],
              [36.527919806525901, 10.386825247253601],
              [36.528344829131299, 10.3862045903864],
              [36.528768911200501, 10.385585302747399],
              [36.529388605914399, 10.384680354770101],
              [36.529939627119802, 10.383875685164901],
              [36.530528217666998, 10.383016146905099],
              [36.530831017291398, 10.382573956031999],
              [36.532143892502702, 10.3806566856089],
              [36.531568342798501, 10.3770040199988],
              [36.532107345443698, 10.376446019971899],
              [36.532708348013202, 10.3757750182561],
              [36.533676351003798, 10.3745400153784],
              [36.534927356546497, 10.3734870138286],
              [36.535521359443301, 10.373047012384999],
              [36.535944361737599, 10.372485011272399],
              [36.5361953618077, 10.371918010418099],
              [36.536292360095501, 10.3707130104598],
              [36.536622359808398, 10.3694580093127],
              [36.537124359948699, 10.3682670074587],
              [36.537511360461302, 10.367072007859299],
              [36.537839360524401, 10.365876006938899],
              [36.538089360769597, 10.365308006191],
              [36.538737364093102, 10.364984004083301],
              [36.539975371013703, 10.3643320020772],
              [36.540627373636802, 10.3638930004432],
              [36.541243375376602, 10.362762999631499],
              [36.541841377573, 10.3622059983919],
              [36.545375396613103, 10.3604179909652],
              [36.546847405682101, 10.3600899878451],
              [36.549077420374303, 10.3606489846345],
              [36.549648424603198, 10.3608969825526],
              [36.550276428733497, 10.3612039809506],
              [36.550957433465499, 10.361627979941799],
              [36.5521584423728, 10.3621249778747],
              [36.553366450952801, 10.362392975465699],
              [36.554519457484602, 10.362600973886501],
              [36.5552134626355, 10.3626219723684],
              [36.5563714700885, 10.362657971025],
              [36.5570664741659, 10.362679968925701],
              [36.558281481520297, 10.362717966420499],
              [36.558920485521199, 10.362679965390999],
              [36.561344500382198, 10.362476960604001],
              [36.562872509527701, 10.3623429574548],
              [36.564733520555698, 10.362113954162499],
              [36.566364530531601, 10.3618769508839],
              [36.567585537733798, 10.3617429492454],
              [36.5718505653279, 10.362449940830199],
              [36.572422570279997, 10.362696939667799],
              [36.574252583142602, 10.3634439368349],
              [36.5780216095458, 10.3651709300145],
              [36.578591613949797, 10.3654769283457],
              [36.579161618353801, 10.3657809275527],
              [36.580304625738101, 10.3662769256721],
              [36.581669636473599, 10.367066922451601],
              [36.582917646134902, 10.3679089208097],
              [36.585948666108102, 10.369152915304801],
              [36.5884936822412, 10.369289910210499],
              [36.591410699716697, 10.3686909041074],
              [36.592004702613401, 10.3682489032992],
              [36.594495714726598, 10.3664868982436],
              [36.595258719474501, 10.3661658964733],
              [36.596543726250701, 10.365860893809201],
              [36.597131730198001, 10.3655918927023],
              [36.598872740677102, 10.3655298893101],
              [36.599509744130003, 10.365548888768901],
              [36.601651758060299, 10.365616884218101],
              [36.603388768341503, 10.3656708808017],
              [36.604028772167297, 10.3655758799985],
              [36.604672776191101, 10.3653648782416],
              [36.605318778966399, 10.365096877007201],
              [36.6065027854604, 10.364329875112199],
              [36.606868786056602, 10.3637648733527],
              [36.607352787551903, 10.3631488727116],
              [36.609663799739998, 10.361552868349101],
              [36.610363803840301, 10.3614028669099],
              [36.614204823027102, 10.3591058588965],
              [36.616016830058101, 10.3568058551669],
              [36.616806832773399, 10.3556798530928],
              [36.6177658364415, 10.354674850909101],
              [36.618305838911603, 10.354116849986299],
              [36.618846840308301, 10.353500848942],
              [36.623357866153199, 10.3519738401609],
              [36.624052870230599, 10.351994838471001],
              [36.624638873629799, 10.3517828369515],
              [36.625178876099902, 10.351225836528201],
              [36.625895879020298, 10.3505578348526],
              [36.627185886717797, 10.350079832235499],
              [36.629285898120401, 10.3496278282145],
              [36.631048907442498, 10.3488768250068],
              [36.631815911490001, 10.3484408231117],
              [36.634289924782998, 10.3471948181709],
              [36.634874927459002, 10.3470398169202],
              [36.635464931056099, 10.3467138155449],
              [36.636055933579897, 10.3463298142605],
              [36.638896231336801, 10.3468901558357],
              [36.639397987135403, 10.346363003710101],
              [36.639906506349703, 10.345828744785299],
              [36.639555946894902, 10.342011806624599],
              [36.640230949084199, 10.340824805416601],
              [36.640829951105601, 10.3402108037799],
              [36.641311953849403, 10.3396508028898],
              [36.642336957639699, 10.338417800744599],
              [36.643185959906297, 10.337235798493699],
              [36.643612961500203, 10.336560797902299],
              [36.645786017613503, 10.3343463899719],
              [36.647446977420998, 10.3326537895919],
              [36.648095981467698, 10.3322707883096],
              [36.648742984067901, 10.332003786606499],
              [36.649330988015301, 10.331733786113301],
              [36.649869989762102, 10.3311767845888],
              [36.6516219965186, 10.328929781085501],
              [36.6522419975581, 10.3276857799995],
              [36.6525369971109, 10.3265607790785],
              [36.652709997363203, 10.3259567785481],
              [36.652960997433397, 10.325120778000899],
              [36.653268998303297, 10.3245547777029],
              [36.654174001544703, 10.3234317752816],
              [36.654540002140898, 10.322868774127199],
              [36.654905002912201, 10.3223057728489],
              [36.656161009376099, 10.321078770696101],
              [36.65729201437, 10.320136768706799],
              [36.660242394376802, 10.3177736112563],
              [36.662407037767402, 10.316039758093501],
              [36.663058041463898, 10.315600756112699],
              [36.663655043835497, 10.3150437551844],
              [36.664197045955397, 10.314428754],
              [36.667983067585503, 10.3137967464138],
              [36.668620072834997, 10.313815745467499],
              [36.673242101702797, 10.3142467356483],
              [36.6745691101085, 10.3144017336276],
              [36.675458115253001, 10.314629732364001],
              [36.676351121493802, 10.3148597309685],
              [36.6775561305991, 10.3152407286751],
              [36.678236135506197, 10.3157227277808],
              [36.6788001400624, 10.316198726074299],
              [36.681663159806099, 10.3172637202119],
              [36.682233164210103, 10.3175697201614],
              [36.683538977764997, 10.3197986890334],
              [36.688175151505298, 10.317164978699299],
              [36.692167678751296, 10.2962764983392],
              [36.671442804934799, 10.284002964178001],
              [36.659666873418601, 10.2770281956288],
              [36.610546641543401, 10.259209186896801],
              [36.571935804668598, 10.2637393115498],
              [36.562914322510899, 10.277106545691],
              [36.547896856224497, 10.269528128854001],
              [36.522417414306602, 10.2504230317804],
              [36.515464559110399, 10.2399188628369],
              [36.509754532991302, 10.239356812513201],
              [36.490888949030797, 10.2342560514345],
              [36.445326943995902, 10.245831487051699],
              [36.416169240552698, 10.2167521451673],
              [36.395249903036799, 10.1870852139975],
              [36.369397924380699, 10.1616400456367],
              [36.332293143622501, 10.1383819879198],
              [36.289878181369701, 10.121185064012],
              [36.271868132224498, 10.11307489703],
              [36.269437281184203, 10.103600655392601],
              [36.267858728329301, 10.0884759584112],
              [36.258895526172203, 10.078439226151],
              [36.218900143797903, 10.053199709912199],
              [36.184543003168301, 10.0496485080402],
              [36.167302319741502, 10.033835158845701],
              [36.139874278360402, 10.00969226],
              [36.120624646649603, 9.97156030818201],
              [36.119774640964799, 9.961320305759349],
              [36.134005391471398, 9.91587642638464],
              [36.152169799928501, 9.86635022632848],
              [36.1523193811014, 9.84891856163164],
              [36.128183002279897, 9.85220071729287],
              [36.132394046515401, 9.82982309617074],
              [36.136447063617602, 9.80743435634211],
              [36.105262528229801, 9.77331068198675],
              [36.132469195080503, 9.762583356144111],
              [36.143392878716902, 9.75773884419009],
              [36.159033170346, 9.74692353380714],
              [36.161863901943399, 9.73045370065579],
              [36.177472077902699, 9.701301424584891],
              [36.1918418274281, 9.68786451488241],
              [36.192587669862498, 9.658482473176081],
              [36.200753346812, 9.614915726018619],
              [36.229112061692, 9.57981231407839],
              [36.231753008914303, 9.570309978517001],
              [36.266241477848602, 9.568682199662851],
              [36.301001237998598, 9.55550240275042],
              [36.314152048243699, 9.540675790782791],
              [36.335931173199498, 9.511980918062591],
              [36.355112565288003, 9.494709597424761],
              [36.380284054844999, 9.48046736151367],
              [36.400605588692201, 9.51097523534931],
              [36.461144281889297, 9.528044617705349],
              [36.4944059890137, 9.509352663538269],
              [36.498639687862301, 9.47287148485681],
              [36.514887235233502, 9.44412089041403],
              [36.507241690020003, 9.419332321733821],
              [36.499104848991202, 9.41472333197015],
              [36.483620065619299, 9.40514702193763],
              [36.475167002339099, 9.39462409435326],
              [36.484086717951499, 9.367854528893441],
              [36.461387214189898, 9.357143769013611],
              [36.436326293769604, 9.35795895993974],
              [36.408213315730499, 9.35777139985187],
              [36.374692617123202, 9.358005999075999],
              [36.349237532125898, 9.344878326790729],
              [36.333743997366497, 9.33978614185509],
              [36.326154742385398, 9.330759895948029],
              [36.318972249954797, 9.305836812673769],
              [36.295458540169101, 9.301678430278381],
              [36.289612793881901, 9.32151672568614],
              [36.275093431742498, 9.34381986220613],
              [36.270203637546402, 9.32835667621654],
              [36.253155428050597, 9.30181010170525],
              [36.218349051625601, 9.29710511060685],
              [36.214738272442702, 9.31485810067152],
              [36.214562972503202, 9.33240377648335],
              [36.234244727103302, 9.3469937429919],
              [36.205868522922998, 9.35472650778917],
              [36.200974114081198, 9.340258300167971],
              [36.194573206253501, 9.326275833314231],
              [36.194870697732902, 9.28896026033356],
              [36.2263120210363, 9.27171491244829],
              [36.228901183077397, 9.22901775909849],
              [36.2039239029965, 9.157333520489701],
              [36.171888293827003, 9.10828386400723],
              [36.1614494102881, 9.10533855024298],
              [36.184561931569597, 9.07193834785271],
              [36.207626472933399, 9.067139424808341],
              [36.235783951715099, 9.050932700970799],
              [36.246453859487197, 9.040948707127271],
              [36.225925228932802, 9.03095457811432],
              [36.176766550959996, 9.044511873405719],
              [36.167063861949302, 9.02797891084818],
              [36.159609012002598, 9.020646570975471],
              [36.131405012167299, 9.02077483060695],
              [36.128662374756502, 9.051104008444749],
              [36.108529422440398, 9.08001823523049],
              [36.0946193740835, 9.06496555388674],
              [36.084149447463297, 9.0584073096243],
              [36.075513203323602, 9.06262427380946],
              [36.096950575907599, 9.03240152857029],
              [36.086521781347699, 9.012603413267589],
              [36.063917523260699, 9.019894660545321],
              [36.041839774049997, 9.01554797106126],
              [36.042425020169397, 9.03216425329232],
              [36.028050443995902, 9.05596396649857],
              [36.033998770702098, 9.10740335755685],
              [36.053586590269497, 9.11942380647203],
              [36.043941071840102, 9.195049617488211],
              [36.031553111832601, 9.233412797590869],
              [35.975389065847097, 9.24289407757354],
              [35.949296897446899, 9.249140387601241],
              [35.930702549905, 9.26721861443116],
              [35.917847821682599, 9.285625810582671],
              [35.911064623209299, 9.31594476214835],
              [35.859846148714198, 9.324958366010341],
              [35.808428727028101, 9.30886270705018],
              [35.797167484355597, 9.30860430363783],
              [35.773930380218999, 9.324310813451341],
              [35.756723747433099, 9.32362488360161],
              [35.750533726304802, 9.331114899261969],
              [35.749413743639501, 9.3473249160824],
              [35.748933740545503, 9.34987491731744],
              [35.7551538058284, 9.364774916711539],
              [35.750097104652497, 9.403070115699631],
              [35.7539784231612, 9.416535019449221],
              [35.765897004449599, 9.42758698698699],
              [35.778391156103503, 9.430685851758509],
              [35.777301604766699, 9.44012624737735],
              [35.770108229246503, 9.45945823855981],
              [35.764968752808699, 9.47333758779458],
              [35.752866851421899, 9.481681061528951],
              [35.731827496289199, 9.481485337279111],
              [35.710736448501699, 9.486758775680631],
              [35.686462593305698, 9.51040230569488],
              [35.671366811892902, 9.51722141938833],
              [35.647467670053203, 9.5532972705448],
              [35.645224714230999, 9.57764444456684],
              [35.643555357870497, 9.59453722244058],
              [35.650523544613598, 9.613015228143681],
              [35.653413593765897, 9.634915235065771],
              [35.634703500994597, 9.65147526774815],
              [35.624733444609198, 9.65115528867309],
              [35.594403261853799, 9.65602533388663],
              [35.578493178297201, 9.66550536397928],
              [35.569203136700402, 9.67806538760709],
              [35.561063110245499, 9.69200540604527],
              [35.553703066697402, 9.69610542180085],
              [35.541192997795001, 9.69775543963193],
              [35.537542972115702, 9.697495442700831],
              [35.534312952849199, 9.69694545258116],
              [35.533582946815002, 9.6958654470273],
              [35.533252934525699, 9.690405449000281],
              [35.505081773504003, 9.68868920637555],
              [35.484857125655203, 9.677990561167929],
              [35.468399176674801, 9.67036313328139],
              [35.455350581234299, 9.6722176395125],
              [35.441184686396298, 9.68499883908812],
              [35.424480676283203, 9.70073584845899],
              [35.410741902514999, 9.71366450853256],
              [35.4002521437883, 9.71612566195793],
              [35.389362081397898, 9.720035676999681],
              [35.3694365878101, 9.721563369250189],
              [35.365056556096597, 9.727453380842659],
              [35.375340204466198, 9.74993769003521],
              [35.383006723545002, 9.75898338331544],
              [35.378806699841398, 9.76539338546622],
              [35.419604098999798, 9.785132031235809],
              [35.408012896140299, 9.794507218359771],
              [35.386867140053702, 9.801880603284509],
              [35.363764537969601, 9.813030190374411],
              [35.349776146638398, 9.866062846563249],
              [35.324668370991198, 9.88478334253322],
              [35.310964870470997, 9.911223721158199],
              [35.2974295049275, 9.92247031340734],
              [35.2784473609656, 9.907066033662989],
              [35.230719059640002, 9.893239254230339],
              [35.2228632376831, 9.91024324904682],
              [35.220773862333502, 9.925412752678151],
              [35.159559115019803, 9.94838913786865],
              [35.088316890109397, 9.95047595620494],
              [35.080792479118699, 9.938994777629601],
              [35.080996001429497, 9.92190765697403],
              [35.048488908788201, 9.917728284463291],
              [35.019629721802097, 9.92877898415172],
              [35.017277591028503, 9.964826216592471],
              [35.014992841986, 9.995177941784959],
              [34.988157553046797, 9.996754002441291],
              [34.966091754124299, 9.96589428978095],
              [34.942971302515502, 9.933554358717039],
              [34.927630793069902, 9.935266496499359],
              [34.904395387647398, 9.95586573571865],
              [34.887756749734201, 9.90820098989863],
              [34.866770387118301, 9.902247933460419],
              [34.897658819822198, 9.8817441912069],
              [34.8921282016383, 9.864590876274841],
              [34.877051421390398, 9.84542277306336],
              [34.869702271711702, 9.82065404635183],
              [34.854439189856997, 9.816670652516381],
              [34.831640742056798, 9.80310256302656],
              [34.807288181865502, 9.76104095629546],
              [34.796117783122803, 9.736226389261949],
              [34.809935720027099, 9.70222806489762],
              [34.810430450101798, 9.662370959520009],
              [34.810829382936298, 9.63010559583789],
              [34.7920327168261, 9.603301727054809],
              [34.782617937864003, 9.553785662718679],
              [34.735112792427898, 9.54528859562576],
              [34.661224794545603, 9.574451139737089],
              [34.631989942887401, 9.588008234688459],
              [34.585369394562797, 9.63034658907724],
              [34.548492145388003, 9.62961552987325],
              [34.548408326283699, 9.629672628223959],
              [34.532117833153798, 9.59055125765085],
              [34.523206061343402, 9.541099301726881],
              [34.498707400052702, 9.51421784861205],
              [34.512949915794501, 9.4479873944479],
              [34.451781563770297, 9.44909737550565],
              [34.466245595078099, 9.36579755812825],
              [34.451703221694402, 9.308688999363801],
              [34.452364638661699, 9.25746758935111],
              [34.439535148607199, 9.21556215001592],
              [34.401717294159603, 9.18662117584334],
              [34.340852129832001, 9.16876561875376],
              [34.393245086882303, 9.116316489342241],
              [34.426810139806797, 9.071781996703701],
              [34.458305160804599, 9.07316971078806],
              [34.483641847847501, 9.047132106626981],
              [34.503683163919803, 9.00412055221191],
              [34.430626143607903, 8.96742312067339],
              [34.375929088702101, 8.96636924521847],
              [34.305364361500899, 8.96499771554817],
              [34.267217335587198, 8.964250678182561],
              [34.221456898994298, 8.963349366356731],
              [34.145062088531198, 8.962214184701599],
              [34.143567118523499, 8.999586204588031],
              [34.1397289613693, 9.07887178136853],
              [34.134651748960401, 9.17173653143343],
              [34.1220290178467, 9.2569106811442],
              [34.116999735948198, 9.344082385209081],
              [34.108458375462, 9.40844575509132],
              [34.100708777490901, 9.550573991329561],
              [34.117113965719703, 9.605804226042199],
              [34.131909469977401, 9.64015123160743],
              [34.144413137485103, 9.701018826131779],
              [34.146078075032698, 9.7181118080495],
              [34.168984539855899, 9.781408932192249],
              [34.203370766137802, 9.863627335647161],
              [34.2168262615711, 9.902494224298451],
              [34.218321438191197, 9.96079459978472],
              [34.225942816028599, 10.0132419175112],
              [34.233095814073401, 10.047487398103501],
              [34.2595524469507, 10.070630269773],
              [34.297606833202501, 10.086350583929001],
              [34.333588073966901, 10.113421086852],
              [34.333176066153399, 10.1418712485192],
              [34.346191797627498, 10.168615691645501],
              [34.342410756257301, 10.2014702695843],
              [34.351381073969698, 10.2209037120838],
              [34.337925744724799, 10.255286024063601],
              [34.323297319025002, 10.293493550101701],
              [34.317102754218098, 10.3237561561155],
              [34.304876307705399, 10.372899906817301],
              [34.300874939240401, 10.3842231688757],
              [34.293074735575999, 10.4062691996177],
              [34.311432044663903, 10.4469788242893],
              [34.326334411609601, 10.475653316657599],
              [34.306684817594999, 10.5076124901722],
              [34.2797396718831, 10.514799909611501],
              [34.279479639252699, 10.5318682661925],
              [34.2930751020887, 10.560242055182],
              [34.297560298674099, 10.585655041567801],
              [34.309164667242399, 10.596807719321101],
              [34.345100181962202, 10.629592280604101],
              [34.382731984534701, 10.677578222507099],
              [34.406698925822198, 10.7127197743153],
              [34.4306197736542, 10.762050833548701],
              [34.451550483841601, 10.791948430733999],
              [34.464989176287403, 10.8210872168533],
              [34.504945878104003, 10.844442206283199],
              [34.531700864843302, 10.852422198344099],
              [34.544910203024102, 10.867794439675601],
              [34.554281858361001, 10.883110980162201],
              [34.583018551973304, 10.8873217128422],
              [34.598179897825403, 10.900823685558199],
              [34.609753907828697, 10.897194492896601],
              [34.6253336062952, 10.8822354867951],
              [34.648672746471703, 10.8616917558964],
              [34.687609798456997, 10.8242829274129],
              [34.728349229330099, 10.794482403571299],
              [34.749809202628299, 10.770103725147999],
              [34.765375607825199, 10.7551323179586],
              [34.776890885076902, 10.755289368585901],
              [34.790325599782697, 10.755472056751101],
              [34.790536256513498, 10.740289775223101],
              [34.7775239134846, 10.709743258624901],
              [34.7851997211434, 10.709847269522999],
              [34.800394435815001, 10.7214416113843],
              [34.830839840980403, 10.740833583476499],
              [34.857502776454197, 10.7563768206321],
              [34.866841018055801, 10.7754845816724],
              [34.867173135996197, 10.8083911274769],
              [34.889598849428801, 10.821845013247801],
              [34.927193070083099, 10.8560192446076],
              [34.957636139628697, 10.8773047103889],
              [34.9823221670298, 10.89851313556],
              [34.956556801238399, 10.9570276358698],
              [34.959934023837199, 10.991245565571001],
              [34.970685510315199, 11.048344192564301],
              [34.9837874054964, 11.0983980270489],
              [34.992757804955502, 11.147729131344599],
              [35.000233117626003, 11.1806165298211],
              [34.996496469200501, 11.198582641187899],
              [34.985282750101497, 11.213503987475301],
              [35.014348555891203, 11.2292913906838],
              [34.983787704635397, 11.213503990118801],
              [34.961362083729199, 11.235927289268099],
              [34.929640279444698, 11.235738953750699],
              [34.9256881386573, 11.243278520035],
              [34.937118236755403, 11.251028692142899],
              [34.948309626431403, 11.275861536013],
              [34.962857257654399, 11.2852584172192],
              [34.9763127288332, 11.309176507641901],
              [34.989771803154497, 11.337178817860099],
              [35.004718727436497, 11.3614973345147],
              [35.0241543804797, 11.385415410568401],
              [35.038708367283398, 11.415684672702399],
              [35.066415565528899, 11.499600806250101],
              [35.077976375687399, 11.521449623028399],
              [35.0779764439594, 11.5468626355939],
              [35.0794716142913, 11.59320400909],
              [35.076481666191199, 11.645524928070399],
              [35.0555511581248, 11.691866365771499],
              [35.046581004803997, 11.7352180061078],
              [35.097412764450802, 11.8144466902421],
              [35.1572147070626, 11.860787917446901],
              [35.2020661500976, 11.890685465873601],
              [35.257082523747499, 11.934227524326101],
              [35.275278081287098, 11.9042521230934],
              [35.280236479821497, 11.8940743827088],
              [35.282411218479297, 11.8909427694564],
              [35.283194121808997, 11.8884200824081],
              [35.284324981275802, 11.8845055683209],
              [35.287021644706897, 11.873631918872],
              [35.287097179547601, 11.8721847949957],
              [35.287456588306298, 11.8705872970391],
              [35.287456582916398, 11.868064611339699],
              [35.287630540772803, 11.855886129409001],
              [35.284672871835802, 11.846491304456899],
              [35.286321813981502, 11.8384161534628],
              [35.2880654421513, 11.829876368746101],
              [35.291284055508697, 11.825526905873399],
              [35.294241702886097, 11.8226562577987],
              [35.294328691246598, 11.8221343231064],
              [35.295198586530098, 11.8210034625115],
              [35.295894504194202, 11.8204815264265],
              [35.296068481813599, 11.81969862439],
              [35.297286336827398, 11.818915718356299],
              [35.2976342956594, 11.818480772620999],
              [35.298069243750398, 11.8177848585352],
              [35.298678169460601, 11.8170019549431],
              [35.299287096967603, 11.8163930294543],
              [35.297479446765699, 11.807163593796901],
              [35.295907879210503, 11.799139438416701],
              [35.300310910490097, 11.799838431788899],
              [35.301336920393602, 11.801138429699799],
              [35.301818924933997, 11.801701429352899],
              [35.302414930175502, 11.8021474275751],
              [35.303258936910801, 11.803163426412],
              [35.303698942516803, 11.804361425329001],
              [35.305477956224003, 11.805354422771099],
              [35.306055960125597, 11.8051104217193],
              [35.306611961590903, 11.8039464205452],
              [35.307020960048199, 11.8016334196274],
              [35.307634963036399, 11.8004674181655],
              [35.308127962956, 11.799132417811601],
              [35.309585969086299, 11.7966804156419],
              [35.311497977473003, 11.7936404117655],
              [35.312227979913899, 11.7924724100906],
              [35.314540994446801, 11.791437406522601],
              [35.316292005869897, 11.7913374031631],
              [35.3253839860146, 11.7946456938339],
              [35.3259929144198, 11.7942977362121],
              [35.334952836827703, 11.781945259572399],
              [35.336953587086398, 11.774116231941299],
              [35.337040574548602, 11.772985372125101],
              [35.338264212017897, 11.7719488651889],
              [35.363747942492999, 11.752971679928899],
              [35.394653770174102, 11.746194075015101],
              [35.415257640023398, 11.732638937735601],
              [35.421764126846497, 11.729385702368401],
              [35.4410125018142, 11.7299278723727],
              [35.4616164049012, 11.7350787733407],
              [35.473273872885997, 11.7361631602705],
              [35.5169215608638, 11.7179992477041],
              [35.534543333888699, 11.729656601477499],
              [35.576564503466997, 11.7673397017761],
              [35.589848603937298, 11.7730328193632],
              [35.602319384122403, 11.7743883068035],
              [35.623465487856997, 11.7757437770066],
              [35.644882704042701, 11.781436878825099],
              [35.661691165765497, 11.7933653353977],
              [35.666571097358201, 11.825897564723499],
              [35.6832199437263, 11.8253679717895],
              [35.694369983051502, 11.8313145913477],
              [35.705519993630503, 11.822394609590299],
              [35.719643374799404, 11.8290845534921],
              [35.736740091523799, 11.832801170867199],
              [35.749376787846998, 11.832057816615],
              [35.7560668022793, 11.831314473530799],
              [35.762756830186298, 11.837261100961999],
              [35.773906850646803, 11.833544429901099],
              [35.781340203010302, 11.8342877449436],
              [35.793233572181997, 11.837261042933999],
              [35.803640265490998, 11.838004352268101],
              [35.814790274273399, 11.8283410405477],
              [35.822966957381702, 11.8268543646916],
              [35.837090342143902, 11.835030968285],
              [35.848240379672397, 11.8402342558339],
              [35.8601337614204, 11.849897523378701],
              [35.868310467885003, 11.859560796238499],
              [35.870540483475502, 11.8647641034388],
              [35.874399580359103, 11.8859249795811],
              [35.887637245115698, 11.890780619265101],
              [35.894004961092001, 11.8985073948297],
              [35.897516361451601, 11.895288621060301],
              [35.911269393280001, 11.905530125524001],
              [35.917706971236001, 11.9049448825551],
              [35.924729800701201, 11.912552863721],
              [35.936434507493502, 11.9207460669656],
              [35.942872119585402, 11.9365472745218],
              [35.939945984209899, 11.9543968084739],
              [35.933215798016398, 11.9599565110497],
              [35.935264132035698, 11.9661014259792],
              [35.939360782107897, 11.9701980309869],
              [35.956371366566302, 11.9794493886093],
              [35.965791864203503, 11.984572169927899],
              [35.9723140329166, 11.9703223715766],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 9,
        REGIONNAME: "Amhara",
        RID: "03",
        latitude:13.833271,
        longtiude: 39.344733,
        GlobalID: "3db4177c-22c6-4a8a-8257-54522f15c0d7",
        Shape__Area: 159685631971.21899,
        Shape__Length: 3443338.9349246202,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [38.740643882469001, 13.2950762786461],
              [38.736433029574698, 13.2987087153586],
              [38.7391257296389, 13.2977798483341],
              [38.740643882469001, 13.2950762786461],
            ],
          ],
          [
            [
              [37.407898835372897, 11.608023355525701],
              [37.407838199091202, 11.608029955091499],
              [37.407838199091202, 11.6080585532081],
              [37.407883114855402, 11.608036554657099],
              [37.407898835372897, 11.608023355525701],
            ],
          ],
          [
            [
              [37.407919047466798, 11.6080211556704],
              [37.407919047466798, 11.608023355525701],
              [37.407923539043203, 11.6080211556704],
              [37.407919047466798, 11.6080211556704],
            ],
          ],
          [
            [
              [37.407903326949302, 11.6080211556704],
              [37.407898835372897, 11.608023355525701],
              [37.4079078185257, 11.608023355525701],
              [37.407912310102098, 11.6080211556704],
              [37.407903326949302, 11.6080211556704],
            ],
          ],
          [
            [
              [37.407928030619601, 11.608012356249001],
              [37.407912310102098, 11.6080211556704],
              [37.407919047466798, 11.6080211556704],
              [37.407928030619601, 11.608012356249001],
            ],
          ],
          [
            [
              [37.407535017682797, 11.607979358416401],
              [37.407433957213399, 11.6080585532081],
              [37.407537263470999, 11.6080211556704],
              [37.407535017682797, 11.607979358416401],
            ],
          ],
          [
            [
              [37.407829215938399, 11.607523987928101],
              [37.407658536034397, 11.6075657852503],
              [37.407838199091202, 11.607609782424801],
              [37.407840444879398, 11.6075261877873],
              [37.407829215938399, 11.607523987928101],
            ],
          ],
          [
            [
              [37.406499709317899, 11.6058146920717],
              [37.406492971953199, 11.6058190918171],
              [37.406508692470702, 11.6058190918171],
              [37.406499709317899, 11.6058146920717],
            ],
          ],
          [
            [
              [39.500728656456403, 8.71479974331656],
              [39.492230593868697, 8.72527067667016],
              [39.470221869407702, 8.74173899363513],
              [39.449203537547596, 8.752224708185061],
              [39.439708344994401, 8.767677502480449],
              [39.431221511347701, 8.79010532827847],
              [39.424225881072601, 8.803064242078671],
              [39.417728815780201, 8.814529140034869],
              [39.405462320575602, 8.809948549816189],
              [39.386162016696296, 8.798621110197059],
              [39.378142306997297, 8.791157327574529],
              [39.374625402660001, 8.779705022673101],
              [39.373610306388898, 8.766754170564541],
              [39.374598453201401, 8.752804033085321],
              [39.377077803385603, 8.730884503276471],
              [39.3610473671405, 8.72093760607788],
              [39.342025540999302, 8.726931081787461],
              [39.331524235327898, 8.740888847618001],
              [39.324519621900002, 8.746873124039031],
              [39.303968413987498, 8.719989740184291],
              [39.298960306278602, 8.718500231778],
              [39.285793250001603, 8.717778783693911],
              [39.272426318573899, 8.723004256161641],
              [39.268424323983098, 8.72599654986934],
              [39.261929504478999, 8.74692195675974],
              [39.272960816167902, 8.76684073302914],
              [39.272468988549903, 8.78078812401724],
              [39.271471858584498, 8.785273639147499],
              [39.266470488240202, 8.79374514435386],
              [39.258965064041298, 8.8037145061554],
              [39.254464504467897, 8.813182044056459],
              [39.243832943080399, 8.827666951906259],
              [39.242961577254803, 8.83510780577812],
              [39.244964820338403, 8.83809475706745],
              [39.265504799309703, 8.843063339339411],
              [39.260501183177198, 8.850539387052169],
              [39.237468379292402, 8.862011684871661],
              [39.240992020994298, 8.88890915678018],
              [39.257985900381698, 8.908465277168309],
              [39.260132873910699, 8.91154259731324],
              [39.265358823076099, 8.92237180691713],
              [39.258576542680999, 8.96262187563115],
              [39.269612345946399, 8.98054806120637],
              [39.288167048139897, 8.998965789028169],
              [39.2914503905033, 9.01978470013217],
              [39.291616578830897, 9.02084269287432],
              [39.291690689841801, 9.02131734689126],
              [39.291740097182497, 9.021632303885029],
              [39.291758063488103, 9.02173655015342],
              [39.291762555064601, 9.021772038237909],
              [39.2918074708288, 9.02204928877787],
              [39.291975904944501, 9.02312945484986],
              [39.292182517459899, 9.024433634627499],
              [39.292380146822403, 9.02568014218517],
              [39.2927664223946, 9.02814209285442],
              [39.293179647425298, 9.03075928324216],
              [39.293478337257199, 9.032644535731871],
              [39.2967279927975, 9.05325524662506],
              [39.3152782034146, 9.06021467121205],
              [39.347002207673299, 9.064162277256489],
              [39.356989227844501, 9.06206650304488],
              [39.354186484157999, 9.07072895724666],
              [39.348446249492497, 9.076565905261219],
              [39.348580996785103, 9.088051020612991],
              [39.345744566275499, 9.093841117775121],
              [39.3429058899777, 9.09963112128135],
              [39.340103146291199, 9.10829044989576],
              [39.337266715781602, 9.11408021942345],
              [39.335023173359502, 9.121009636346949],
              [39.3288225521109, 9.137194012563381],
              [39.320142580678102, 9.14020951589128],
              [39.323147445303498, 9.148772519191301],
              [39.314433787047498, 9.14891885562383],
              [39.294104912167903, 9.149255872633431],
              [39.269518022841503, 9.141715040717081],
              [39.249499066734899, 9.135573168976419],
              [39.239662514373798, 9.14844215338745],
              [39.233655030911301, 9.157911779019139],
              [39.214615238464397, 9.17336506301578],
              [39.201589666844598, 9.190309690983231],
              [39.1885663410131, 9.20924645391124],
              [39.213660778475003, 9.228109050320739],
              [39.199721171053703, 9.24995639065205],
              [39.1940640305519, 9.25239905892778],
              [39.177721429745603, 9.271059863732219],
              [39.207165958970798, 9.29192713820605],
              [39.233639310393798, 9.293598239534051],
              [39.257563692198097, 9.25610071082219],
              [39.260402368495903, 9.250311044085841],
              [39.266147094737903, 9.24447473305645],
              [39.282916395304198, 9.278108110197239],
              [39.2661830273493, 9.288438633078719],
              [39.2643931341456, 9.32417754071753],
              [39.278258630556003, 9.31223690572822],
              [39.285415957582202, 9.315782793926809],
              [39.291445898926902, 9.33171890055401],
              [39.292966297545298, 9.349152268858949],
              [39.303002725057098, 9.35661553122207],
              [39.306521875182597, 9.36607733486923],
              [39.319563167319799, 9.36556768924685],
              [39.320072961243604, 9.374034357132579],
              [39.3250945436818, 9.380506643102089],
              [39.337134214277199, 9.38049556431795],
              [39.346660847865301, 9.37849251432913],
              [39.3566838006479, 9.369017753921909],
              [39.371234262462401, 9.374481948065871],
              [39.374748921011502, 9.37697027723345],
              [39.376260336477102, 9.383941049125211],
              [39.394828513399801, 9.391891014585211],
              [39.403845353064099, 9.380424660091061],
              [39.411862816974903, 9.374437632157621],
              [39.420387829021202, 9.371439647826371],
              [39.425914713806797, 9.37940319446378],
              [39.440462929833103, 9.381377434428661],
              [39.445596801681802, 9.38306804849595],
              [39.445643963234197, 9.384716555454689],
              [39.447144149758699, 9.38761693374876],
              [39.447665172623502, 9.38904828032563],
              [39.448904847715603, 9.390266914392219],
              [39.450564485203003, 9.39190652437299],
              [39.451945644952303, 9.39326695162816],
              [39.452495863063902, 9.39453653120073],
              [39.4535244340642, 9.397476708953089],
              [39.453865793872097, 9.399027659275131],
              [39.454254315232497, 9.40013769231026],
              [39.454604658193297, 9.401296465512811],
              [39.454254315232497, 9.4032373465436],
              [39.454025244835101, 9.40394855707223],
              [39.4524352267822, 9.403197465536079],
              [39.450474653674597, 9.403627293930381],
              [39.448615141036498, 9.405767565510301],
              [39.448774591999403, 9.40769734354148],
              [39.449205783335799, 9.40835758659521],
              [39.449425870580399, 9.4095584281123],
              [39.448145771300503, 9.40951854783366],
              [39.4465355411537, 9.40912860708911],
              [39.446205410286801, 9.41021645204555],
              [39.4454845122713, 9.41033830819203],
              [39.443865298971701, 9.410847887975629],
              [39.442535792351201, 9.413267273915309],
              [39.443115205709397, 9.414237682198181],
              [39.443544151257598, 9.415598021641751],
              [39.445554131705798, 9.417638520764619],
              [39.447123937664799, 9.41863771843628],
              [39.448945271903398, 9.41891687292331],
              [39.448815016187197, 9.41997810101714],
              [39.448745396752599, 9.42215815016897],
              [39.449556126296599, 9.42400807945593],
              [39.449645957824998, 9.424767987655789],
              [39.449735789353397, 9.425937755609061],
              [39.449434853733202, 9.42688818915172],
              [39.448684760470996, 9.42780760372424],
              [39.449594304696099, 9.42966857993936],
              [39.450144522807697, 9.430118314351921],
              [39.450494865768498, 9.43068768170812],
              [39.449866045069598, 9.431737796468051],
              [39.449246207523601, 9.432816708513849],
              [39.448285010169499, 9.433067051072831],
              [39.447025122983597, 9.43339714850555],
              [39.445805659985403, 9.433718383958871],
              [39.444455941271002, 9.433707306879249],
              [39.442234856730998, 9.434008603318],
              [39.4396948702652, 9.434176974742631],
              [39.438154259552903, 9.434387438907811],
              [39.436575470440999, 9.434327622789731],
              [39.4354346100302, 9.434068419491419],
              [39.4341253155036, 9.433767123104939],
              [39.432454449075102, 9.43388675552521],
              [39.43154490485, 9.43361647481297],
              [39.430965491491698, 9.433246500051959],
              [39.429013901536997, 9.434017464973969],
              [39.427533927106403, 9.43454694850532],
              [39.426184208392002, 9.435938223532579],
              [39.425425131976901, 9.43646770411419],
              [39.424724446055301, 9.436888629519361],
              [39.4238553260179, 9.43756653988088],
              [39.422534802550302, 9.438698602904481],
              [39.420655077818203, 9.44061712000744],
              [39.4198353651215, 9.44121748517451],
              [39.419244722822199, 9.44151655968289],
              [39.417695128957099, 9.44202830879507],
              [39.416704736356301, 9.44311826540944],
              [39.415815404225, 9.44316700322627],
              [39.412455705062399, 9.44661850926355],
              [39.410005550125, 9.448317665446879],
              [39.407193823285702, 9.44965793266125],
              [39.405004179780697, 9.45032695665714],
              [39.4035938247846, 9.45086749165114],
              [39.401374986032799, 9.451328274910219],
              [39.396083909009398, 9.455517386719061],
              [39.394354652087401, 9.455847462636809],
              [39.3937640097881, 9.45526706047632],
              [39.393074552807597, 9.45458696967599],
              [39.392245856957999, 9.453957829106679],
              [39.390345920131999, 9.451237447489699],
              [39.389755277832698, 9.4508076783944],
              [39.3878957651946, 9.4484882452003],
              [39.386144050390598, 9.448798389989999],
              [39.385063826261401, 9.448576858025859],
              [39.383725336488098, 9.448217975941461],
              [39.381263952609601, 9.44705714490906],
              [39.380114109045898, 9.446496665929541],
              [39.378804814519299, 9.44620867242322],
              [39.376693773601602, 9.445548501784501],
              [39.374504130096597, 9.445568439842461],
              [39.3733138623451, 9.445597239257459],
              [39.371845116855603, 9.445557363143729],
              [39.371625029611003, 9.44768851341785],
              [39.372305503438703, 9.449677870481249],
              [39.372714236893003, 9.4509671803893],
              [39.372914112043702, 9.4521169217491],
              [39.3730353846071, 9.453368562600589],
              [39.373105004041598, 9.455716761472351],
              [39.373154411382203, 9.458297546460811],
              [39.373695646340899, 9.45992797231737],
              [39.375204816018197, 9.462028020317071],
              [39.3772237796193, 9.46333722096476],
              [39.379195581667901, 9.46374703740169],
              [39.380015294364703, 9.464517934023821],
              [39.3797457997794, 9.465058446716199],
              [39.378315232689502, 9.465567945606921],
              [39.377594334674001, 9.46591794875504],
              [39.376395083769701, 9.46647839609154],
              [39.375123967642601, 9.46701669049879],
              [39.373623781118198, 9.46763694638328],
              [39.371124218840102, 9.468777771246989],
              [39.369354537730402, 9.46991859232222],
              [39.367703883395798, 9.471077131012221],
              [39.365435637303399, 9.4721581349079],
              [39.364633890912302, 9.472536928500681],
              [39.363084297047202, 9.473928049922961],
              [39.362534078935703, 9.474548293326389],
              [39.361525720029299, 9.474898287323789],
              [39.3611439360335, 9.475017905443631],
              [39.357505759132799, 9.474997969093209],
              [39.355304886686802, 9.4742270959895],
              [39.353975380066302, 9.47343849987405],
              [39.352425786201202, 9.47247711901381],
              [39.3506336472093, 9.47200750342156],
              [39.348035270250001, 9.47135845817326],
              [39.345854609897799, 9.47159769683755],
              [39.344835022050297, 9.47191668146403],
              [39.3436447542989, 9.47228882982045],
              [39.3430743240935, 9.472497055510591],
              [39.341365279265403, 9.47313723795858],
              [39.340105392079401, 9.474198298972199],
              [39.339555173967902, 9.47466791156817],
              [39.338564781367197, 9.47490714792669],
              [39.338144818971898, 9.4749470206369],
              [39.337785492858202, 9.47497803274165],
              [39.337114002183299, 9.47490714792669],
              [39.334704271433701, 9.4737973556343],
              [39.333204084909198, 9.47343849987405],
              [39.330545071668197, 9.47279831798773],
              [39.327695166429301, 9.471896744933559],
              [39.324825049096603, 9.47069833580932],
              [39.322314257877501, 9.46923853050628],
              [39.316513386930303, 9.46746859132535],
              [39.314705527420998, 9.466888208782731],
              [39.313263731390002, 9.4666179539818],
              [39.311294175129497, 9.466307825259801],
              [39.310173526812598, 9.4671784001766],
              [39.309333602021901, 9.46783852930465],
              [39.3078738396852, 9.469347074664901],
              [39.306463484689203, 9.47202743994559],
              [39.3059739028593, 9.47297774624926],
              [39.305223809597102, 9.47442867504294],
              [39.304363672712498, 9.47548751691847],
              [39.304094178127301, 9.477197606105049],
              [39.304085194974498, 9.47893869851822],
              [39.305055375481302, 9.48044719510785],
              [39.304334477465801, 9.482017708005261],
              [39.303685444673, 9.483346768310049],
              [39.302894927223001, 9.48478879291566],
              [39.301965170903898, 9.486647245791611],
              [39.301114017172203, 9.487677254072221],
              [39.300045021984097, 9.488997432203631],
              [39.297224311991997, 9.490448293245541],
              [39.295804973843097, 9.49110837761053],
              [39.294643901338397, 9.49144727949191],
              [39.2933435899646, 9.49173745011749],
              [39.292593496702402, 9.491896933104769],
              [39.290823815592603, 9.493728767094449],
              [39.292943839663202, 9.49709780983927],
              [39.2952435267905, 9.49916883544763],
              [39.298374155555699, 9.50217678402235],
              [39.299544211213203, 9.5028279859036],
              [39.300624435342399, 9.50324882999773],
              [39.3021942413014, 9.503787066690339],
              [39.303404721146698, 9.50421676945045],
              [39.304714015673298, 9.50442719070835],
              [39.307175399551802, 9.504688555880129],
              [39.307835661285701, 9.505428351131609],
              [39.307644769287798, 9.50609726641923],
              [39.306463484689203, 9.50729776673534],
              [39.305385506348202, 9.50807742520098],
              [39.302863486188102, 9.510708759403011],
              [39.302744459412899, 9.5119181031803],
              [39.302144833960803, 9.51287715848304],
              [39.299135477759002, 9.51960818915782],
              [39.298863737385503, 9.5214376599374],
              [39.298863737385503, 9.5222770869555],
              [39.3005143917201, 9.52271784068523],
              [39.301533979567601, 9.52254729785224],
              [39.3027646715068, 9.522558372064751],
              [39.303584384203603, 9.522666899328449],
              [39.3042042217496, 9.522976977034689],
              [39.304893678730203, 9.523346855073241],
              [39.305533728370101, 9.524658036270321],
              [39.305655000933498, 9.525687930133049],
              [39.306723996121598, 9.528108722183809],
              [39.307395486796402, 9.528737726640729],
              [39.309275211528501, 9.5290677320366],
              [39.309443645644201, 9.5300068060481],
              [39.309124743718399, 9.531428700930251],
              [39.309075336377802, 9.532248170367341],
              [39.309075336377802, 9.53290817405067],
              [39.309205592093903, 9.533656767287111],
              [39.3094750866792, 9.53475750642939],
              [39.309724369170503, 9.535787369815059],
              [39.309895049074498, 9.536547030491651],
              [39.310714761771301, 9.53747722493401],
              [39.311453626092501, 9.537858160973339],
              [39.312585503350398, 9.537556955768149],
              [39.313364791859399, 9.536956759308669],
              [39.314114885121697, 9.535997771629591],
              [39.314514635423102, 9.53516723739615],
              [39.315035658287897, 9.53397790885251],
              [39.315554435364497, 9.532407634461491],
              [39.316953561419503, 9.53132682080863],
              [39.317703654681701, 9.531107556965461],
              [39.318714259376399, 9.53036781730361],
              [39.3188445150926, 9.529408811103719],
              [39.318754683564102, 9.52881745951729],
              [39.318094421830303, 9.52773884930353],
              [39.317784503057297, 9.526957020878511],
              [39.319444140544697, 9.525608196525591],
              [39.320975768104098, 9.52575880443518],
              [39.322044763292197, 9.525938204946661],
              [39.323223802102603, 9.525927130843749],
              [39.3241153800221, 9.525827463901321],
              [39.324975516906697, 9.52564806333165],
              [39.3256649738872, 9.525337988050691],
              [39.328584498560602, 9.525907197457579],
              [39.329624298501997, 9.52659822083192],
              [39.330805583100599, 9.527127561509159],
              [39.334704271433701, 9.52974767503418],
              [39.334834527149901, 9.530538356230149],
              [39.3345740157175, 9.531258162448641],
              [39.334154053322202, 9.53168783066771],
              [39.333725107774001, 9.53213743136924],
              [39.334005831300303, 9.535707638567221],
              [39.336844507598101, 9.539107273837651],
              [39.337895536480502, 9.540088400059499],
              [39.338883683292998, 9.541297639780121],
              [39.338744444424002, 9.542566672456211],
              [39.339218305736402, 9.543155785495831],
              [39.340963283175803, 9.54494969487075],
              [39.341794224813597, 9.545806781571461],
              [39.346674322594602, 9.54690748148462],
              [39.3486955319838, 9.54828722705696],
              [39.3508739465478, 9.5498374963132],
              [39.351624039810098, 9.5502184185362],
              [39.353205074710097, 9.549527443026459],
              [39.3535060103303, 9.54888740356326],
              [39.354004575312999, 9.54834702328767],
              [39.355275691439999, 9.5476781907758],
              [39.355994343667298, 9.54730833912091],
              [39.357804448964799, 9.5458377872515],
              [39.358294030794703, 9.54532840786483],
              [39.358794841565597, 9.54476808965995],
              [39.360575751616302, 9.544387161342369],
              [39.362084921293601, 9.543837915111601],
              [39.3636345151588, 9.5443384379222],
              [39.363475064195796, 9.54550779808352],
              [39.362814802461997, 9.54630730148995],
              [39.362814802461997, 9.54715774072724],
              [39.363055101800498, 9.548127770390391],
              [39.3634458689491, 9.54869694176068],
              [39.3633942158202, 9.549328122907379],
              [39.363365020573497, 9.55020734522178],
              [39.364755163475699, 9.55190820209714],
              [39.365803946569898, 9.55296680403991],
              [39.366814551264497, 9.55380836808942],
              [39.368325966730097, 9.5552877440596],
              [39.368714488090397, 9.55575724526893],
              [39.369745304878997, 9.55668738726162],
              [39.369635261256697, 9.558197755272101],
              [39.369675685444399, 9.559446793547551],
              [39.369684668597301, 9.56108781026912],
              [39.370365142425001, 9.562607017073059],
              [39.370814300067103, 9.56304771865168],
              [39.371306127685102, 9.563408694896131],
              [39.372184230875398, 9.56374752501009],
              [39.374034760360601, 9.564848166906931],
              [39.375424903262797, 9.565917801393059],
              [39.376035757655998, 9.56672832963207],
              [39.376496144239098, 9.567357262781091],
              [39.376965513975101, 9.56802827116057],
              [39.377336069029802, 9.568577478353371],
              [39.3777358193312, 9.570198520214641],
              [39.377985101822603, 9.571657894183049],
              [39.378744178237604, 9.5731770537495],
              [39.3788250266132, 9.57416693978243],
              [39.379236005855702, 9.57619763655239],
              [39.378524090992997, 9.578438696755709],
              [39.377008183951098, 9.580557946600941],
              [39.3764265248046, 9.580726245917109],
              [39.3759594008569, 9.5812975771838],
              [39.374969008256102, 9.58171832440097],
              [39.373697892129101, 9.58225865164116],
              [39.372539065412603, 9.582480096982909],
              [39.372071941464803, 9.58304921084831],
              [39.372869196279503, 9.58489162739918],
              [39.373774248928299, 9.588286342271219],
              [39.372840001032799, 9.58942897838234],
              [39.372489658071999, 9.59000029499424],
              [39.371845116855603, 9.591145139726549],
              [39.372503132801199, 9.592799289601221],
              [39.372988223054598, 9.59345031826226],
              [39.373565390624698, 9.593857764135111],
              [39.374142558194698, 9.59426078076666],
              [39.375874060904898, 9.594907377877719],
              [39.377610055191397, 9.594860876071859],
              [39.378768881908002, 9.59486973355917],
              [39.379923217048002, 9.595394539269879],
              [39.380722717650897, 9.597121742199439],
              [39.381174121081202, 9.599021655253511],
              [39.383193084682198, 9.599838747451489],
              [39.384351911398802, 9.59990517762486],
              [39.384753907488403, 9.600480905248389],
              [39.384920095816, 9.60163235755982],
              [39.384383352433701, 9.60398175099882],
              [39.384091399966401, 9.604553043075439],
              [39.383909491121301, 9.6057576247601],
              [39.3837275822763, 9.60696441645149],
              [39.383199822046898, 9.608109203859399],
              [39.382907869579498, 9.608680488970609],
              [39.382553035042299, 9.60982527057404],
              [39.382371126197299, 9.6109744768378],
              [39.384163265189102, 9.611446115273081],
              [39.385378236610897, 9.611339830612661],
              [39.385921717357803, 9.61099440523569],
              [39.3865415549038, 9.61060026559278],
              [39.387698135832103, 9.610839406554501],
              [39.388560518504903, 9.611877896362721],
              [39.388841242031098, 9.613144451046621],
              [39.3888884035836, 9.61486713494582],
              [39.388704248950297, 9.616587595841009],
              [39.388686282644599, 9.619517009115579],
              [39.388677299491803, 9.6207791067841],
              [39.388322464954598, 9.621926061630649],
              [39.3879608930527, 9.62433509697393],
              [39.3878373747011, 9.625482039751089],
              [39.385836377405802, 9.63080929433932],
              [39.382692273911303, 9.63087350429355],
              [39.381683915004899, 9.630747298509871],
              [39.380639623487099, 9.630667589569549],
              [39.3795257125348, 9.63025575974482],
              [39.378110865962299, 9.629941351691061],
              [39.376439999533901, 9.62956937558744],
              [39.375559650555402, 9.62914425953872],
              [39.374295271793002, 9.628938343760231],
              [39.3725817353886, 9.626363278853599],
              [39.371629521187401, 9.62584516367982],
              [39.370585229669601, 9.625524109006721],
              [39.369215298861299, 9.624997135847419],
              [39.368040751627397, 9.62385904815279],
              [39.3671671400135, 9.622526107885641],
              [39.366131831648602, 9.6216869285089],
              [39.364880927615502, 9.62141236804935],
              [39.3636726935583, 9.62048240354537],
              [39.362554291029603, 9.61935537170536],
              [39.361718857815397, 9.61873317766528],
              [39.360721727849999, 9.61862025264556],
              [39.358334454982398, 9.61857818253052],
              [39.357234018759399, 9.618509541805279],
              [39.356131336748099, 9.61842982997784],
              [39.35506234156, 9.617510928387791],
              [39.353056852688198, 9.61711236788549],
              [39.351040134875397, 9.617054797996341],
              [39.351770016043702, 9.62042704843468],
              [39.352052985358199, 9.621474365591959],
              [39.352185486862602, 9.62262574645953],
              [39.352095655334203, 9.62363762986792],
              [39.352088917969603, 9.624731434783129],
              [39.352093409546001, 9.625997941313679],
              [39.352212436321203, 9.627091738587159],
              [39.3524954056357, 9.628103608619449],
              [39.353126472122803, 9.629967921397069],
              [39.353335330426297, 9.632799797241979],
              [39.3532342699569, 9.634099485239769],
              [39.3533443135792, 9.635388097700551],
              [39.353532959788801, 9.636619138686189],
              [39.354036016347898, 9.63768854654468],
              [39.354898399020698, 9.63870038481384],
              [39.355632771765499, 9.63994248409788],
              [39.356295279287501, 9.640896749781611],
              [39.356798335846598, 9.641806731480299],
              [39.3575327085914, 9.64317502050773],
              [39.357923475740002, 9.645083530881269],
              [39.357683176401501, 9.646132985578131],
              [39.357283426099997, 9.647802364587349],
              [39.356616427001597, 9.64929904218671],
              [39.356587231754801, 9.650910841398289],
              [39.357063338855397, 9.65443109033728],
              [39.357335079228903, 9.655593428605769],
              [39.357781991082703, 9.657504082603779],
              [39.358729713707497, 9.6598730128298],
              [39.360878933024701, 9.661323144134659],
              [39.362084921293601, 9.66167737373291],
              [39.363360528997099, 9.661548965546629],
              [39.364750671899301, 9.661110606196759],
              [39.365756785017503, 9.66063682322712],
              [39.366854975452299, 9.660187392971331],
              [39.3683349498829, 9.661010978992159],
              [39.368844743806598, 9.66234598108359],
              [39.369291655660497, 9.6639931405448],
              [39.369543183940003, 9.66597237794093],
              [39.370538068117199, 9.66868661521104],
              [39.371272440862001, 9.66994852883138],
              [39.372018042547801, 9.671228148684159],
              [39.369536446575403, 9.673499572620811],
              [39.368074438450499, 9.673178563346591],
              [39.366879679122597, 9.672904044827529],
              [39.365069573825103, 9.672618456775419],
              [39.365588350901703, 9.67479246204198],
              [39.366300265764401, 9.675806402663101],
              [39.367032392720901, 9.676472768086549],
              [39.367683671301897, 9.677300741887951],
              [39.368139566308599, 9.678381089662389],
              [39.366679803971898, 9.678739728934209],
              [39.364952792838203, 9.678385517310049],
              [39.363016923400899, 9.678181845457059],
              [39.361195589162399, 9.67806894035491],
              [39.358981241987003, 9.67736937064088],
              [39.358619670085098, 9.6785294158274],
              [39.359637012144397, 9.67935959837782],
              [39.360407317500602, 9.680601547642709],
              [39.3613123701493, 9.681429511268091],
              [39.361896275084, 9.68226854184363],
              [39.3635109968072, 9.683359942595191],
              [39.364254352704798, 9.684026293034091],
              [39.365278432128697, 9.685095550277071],
              [39.366035262755602, 9.68577296614705],
              [39.368182236284603, 9.686565496704221],
              [39.369042373169101, 9.68721855818727],
              [39.369624032315599, 9.688117345811589],
              [39.370425778706696, 9.691291862144761],
              [39.370481923411901, 9.69232567653145],
              [39.370672815409797, 9.69580343130534],
              [39.370677306986202, 9.697058601924439],
              [39.370614424916397, 9.698486435804879],
              [39.370618916492802, 9.69968404061831],
              [39.370437007647702, 9.701016675325629],
              [39.369374749824303, 9.701860084254641],
              [39.368249609930899, 9.701643144627891],
              [39.366580989290703, 9.701552384130141],
              [39.365363772080698, 9.701474905636999],
              [39.363890535014697, 9.701614366911761],
              [39.362855226649799, 9.70129559819846],
              [39.361873817201896, 9.701260179433829],
              [39.359677436332198, 9.70200397270513],
              [39.358489414368997, 9.70176711014609],
              [39.359118235067797, 9.69897123383258],
              [39.357712371648198, 9.69905092659195],
              [39.356665834342202, 9.70110300862059],
              [39.354424537708297, 9.700892709529869],
              [39.351880059666001, 9.700627068384531],
              [39.350799835536897, 9.70022639259204],
              [39.348967272357299, 9.69965304905045],
              [39.3487539224773, 9.698422238753221],
              [39.3474311532214, 9.698090184841471],
              [39.346456481138198, 9.697769199080829],
              [39.345495283784203, 9.69795293579522],
              [39.3447339615809, 9.698933601134071],
              [39.343182121927597, 9.69936084269832],
              [39.342479190217702, 9.70025959778642],
              [39.341787487448997, 9.701180487199711],
              [39.340722983837303, 9.701955272005719],
              [39.339961661634, 9.702725627694081],
              [39.339245255194903, 9.703451708445799],
              [39.338156047912904, 9.704556322181549],
              [39.338281812052699, 9.706411356694259],
              [39.337837145987002, 9.70836820774273],
              [39.337459853567701, 9.70970523514203],
              [39.3378349001988, 9.71065930275417],
              [39.338894912234103, 9.712073797064461],
              [39.339364281969999, 9.714006265546271],
              [39.339822422764897, 9.715812549021569],
              [39.3411743872676, 9.717652026216969],
              [39.341771766931501, 9.71926349732656],
              [39.342714997979797, 9.720850611696919],
              [39.344718241063397, 9.72282730009546],
              [39.346124104483003, 9.724277159297669],
              [39.3470224197672, 9.72553000966661],
              [39.347815183005402, 9.72665668558221],
              [39.343494286488799, 9.72792944850214],
              [39.3416729522502, 9.727413703416049],
              [39.340118866808702, 9.72699977867255],
              [39.338331219393297, 9.72655265083363],
              [39.336509885154797, 9.72610552239659],
              [39.335050122818103, 9.72577349594253],
              [39.333343323778202, 9.72547688536466],
              [39.332357422753901, 9.725235612387079],
              [39.3306640984434, 9.72472207666241],
              [39.328865222086897, 9.724286013380359],
              [39.326720494346098, 9.724058020676511],
              [39.323787494943403, 9.72383224134086],
              [39.321550689886003, 9.724248383527319],
              [39.320450253662898, 9.72437676771434],
              [39.318745700411299, 9.72410229111661],
              [39.317609331576897, 9.724000469095641],
              [39.313643269597499, 9.72673194474187],
              [39.3133266134598, 9.72890781253002],
              [39.312693301184503, 9.7303709262405],
              [39.312722496431299, 9.73165916931703],
              [39.3127494458898, 9.732856655419489],
              [39.312902159488097, 9.7338350050193],
              [39.312744954313402, 9.7349395184313],
              [39.312077955214903, 9.736654937830741],
              [39.311390744022603, 9.73812244446456],
              [39.3108562464285, 9.739392950277111],
              [39.310187001541799, 9.74039784169355],
              [39.308075960624201, 9.74400347343477],
              [39.304615200992103, 9.74538683929817],
              [39.303424933240599, 9.745862715829141],
              [39.301230798159203, 9.746739212221099],
              [39.298904161573297, 9.747668827089701],
              [39.296761679620701, 9.7483704632628],
              [39.293604101397001, 9.749525835700149],
              [39.293547956691697, 9.7478658165744],
              [39.2929999843684, 9.74681668022577],
              [39.292858499711201, 9.745816234895569],
              [39.292436291527601, 9.74474938502091],
              [39.290700297241102, 9.74354308800333],
              [39.289992873954802, 9.74302958042658],
              [39.289240534904401, 9.73947041998732],
              [39.289496554760397, 9.7380759624529],
              [39.288937353496003, 9.735705371277851],
              [39.288459000607197, 9.735094459900351],
              [39.287684203674701, 9.734293190385049],
              [39.287414709089397, 9.733560536189991],
              [39.286945339353501, 9.732874363130019],
              [39.285927997294202, 9.73218376172389],
              [39.283516020756302, 9.73136256396295],
              [39.282287574605299, 9.73176984288914],
              [39.2812837072753, 9.732301075524949],
              [39.279990133266203, 9.73335911134112],
              [39.278541599870501, 9.734246707840359],
              [39.277908287595203, 9.73482663245858],
              [39.277063871228201, 9.735712011612771],
              [39.275940977123, 9.736719127546889],
              [39.274970796616202, 9.737239285136489],
              [39.2729585703797, 9.73734110312302],
              [39.272248901305304, 9.737728453875],
              [39.2715190201369, 9.738124657893531],
              [39.2697358642979, 9.73944828578623],
              [39.269246282468103, 9.739817926751901],
              [39.268188516221102, 9.740118951427521],
              [39.267433931382399, 9.7400879636058],
              [39.265870862787999, 9.739173821570891],
              [39.264352709957898, 9.737442921078509],
              [39.263905798103998, 9.73672355442341],
              [39.2636677445537, 9.73607058950236],
              [39.263452148885499, 9.73536892746908],
              [39.263178162723896, 9.733305988283821],
              [39.262830065551299, 9.732356412209271],
              [39.261289454839002, 9.73062104942572],
              [39.260487708447897, 9.72958735328004],
              [39.259528756882098, 9.72889231809941],
              [39.258801121502003, 9.728088820213269],
              [39.258239674449399, 9.726539369799569],
              [39.258075731910097, 9.72516920694985],
              [39.257774796289901, 9.72427494577696],
              [39.257366062835601, 9.723717138091461],
              [39.256874235217602, 9.723081855982899],
              [39.256059014097197, 9.72201271995347],
              [39.255394260787, 9.72096350239034],
              [39.255032688885102, 9.719943057679],
              [39.254781160605603, 9.718792010016781],
              [39.254612726489803, 9.717213740149489],
              [39.253534748148901, 9.715434027188961],
              [39.252883469567898, 9.714847428181461],
              [39.252429820349398, 9.71434937161329],
              [39.252018841106903, 9.713711858124499],
              [39.251962696401598, 9.712441254702339],
              [39.252097443694304, 9.711420784001881],
              [39.252160325764102, 9.71070357496715],
              [39.252133376305601, 9.710103685983491],
              [39.251524767700602, 9.708578502141091],
              [39.251387774619801, 9.707726255604589],
              [39.251091330576003, 9.70657737956868],
              [39.2505770450759, 9.705561318288069],
              [39.249945978588798, 9.70491493347299],
              [39.249135249044897, 9.70403390010709],
              [39.248665879308902, 9.703383085027429],
              [39.2470242081272, 9.70170955473262],
              [39.244863759868899, 9.70114285474923],
              [39.243936249337999, 9.702019468380721],
              [39.243174927134703, 9.70236258672824],
              [39.241537747529399, 9.7026370811533],
              [39.240176799874, 9.702564030239561],
              [39.239089838380202, 9.702566243903849],
              [39.237674991807701, 9.70219213443178],
              [39.236531885608699, 9.70193092165337],
              [39.234041306483498, 9.701886648280921],
              [39.233390027902502, 9.700301657694579],
              [39.232947607625, 9.69934756058535],
              [39.232505187347599, 9.698358041689261],
              [39.232197514362802, 9.69784003734409],
              [39.231636067310198, 9.697156004739],
              [39.231355343783903, 9.695418245728799],
              [39.231146485480402, 9.694444211262519],
              [39.230706310991202, 9.693516662175339],
              [39.230090965021503, 9.69297208562125],
              [39.229480110628302, 9.69253155353428],
              [39.227095083549003, 9.69219285259161],
              [39.225572439142397, 9.69279055990674],
              [39.2246808612229, 9.694244976591371],
              [39.224754972233903, 9.69526107217678],
              [39.224820100092003, 9.69735081028321],
              [39.224793150633403, 9.698045910961721],
              [39.2247976422099, 9.6987144459235],
              [39.224932389502499, 9.69941618483001],
              [39.225585913871697, 9.70096133345819],
              [39.225518540225401, 9.70318164200849],
              [39.224523656048198, 9.70414015551026],
              [39.223742121751002, 9.70481753291328],
              [39.223097580534699, 9.705490481656851],
              [39.222531641905697, 9.70641578397198],
              [39.221426714106201, 9.70713300218987],
              [39.220137631673502, 9.707878996052459],
              [39.219625591961602, 9.70824867192059],
              [39.218888973428598, 9.70840583938161],
              [39.218264644306103, 9.708463393644671],
              [39.217572941537298, 9.70860506563465],
              [39.216510683713899, 9.7091385486842],
              [39.216097458683201, 9.709807061513301],
              [39.2152216012812, 9.709964228242921],
              [39.214893716202397, 9.70891940014844],
              [39.214655662652198, 9.70780373265208],
              [39.214547864818101, 9.707194983939059],
              [39.214314302844201, 9.70619220637402],
              [39.213550734852703, 9.704512049155969],
              [39.212960092553402, 9.70404054107077],
              [39.210554853380202, 9.703781543389519],
              [39.2099821773865, 9.704868446845749],
              [39.209802514329702, 9.706468911300041],
              [39.209714928589499, 9.7084213347611],
              [39.209881116917103, 9.70948165974001],
              [39.210029338938902, 9.710218793916081],
              [39.2100854836442, 9.71105996606842],
              [39.209941753198699, 9.711825873611041],
              [39.2092949661942, 9.71189892250478],
              [39.208091223713502, 9.71144070645313],
              [39.207224349464298, 9.71156466834541],
              [39.206166583217197, 9.71230401153538],
              [39.205708442422299, 9.710754488004831],
              [39.2056635266581, 9.70945952355348],
              [39.2055669577651, 9.70841026663296],
              [39.205477126236701, 9.70717506123521],
              [39.205059409629499, 9.705313390013281],
              [39.204239696932802, 9.704602808852091],
              [39.203224600661699, 9.70430175220465],
              [39.200985549816103, 9.70550597717121],
              [39.200583553726403, 9.70612801079853],
              [39.200134396084401, 9.707467260772921],
              [39.199943504086498, 9.70903229486659],
              [39.199885113592998, 9.709968655473681],
              [39.199626847948799, 9.711141869559819],
              [39.199563965879001, 9.711814805595431],
              [39.199415743857102, 9.7128219935161],
              [39.199321420752199, 9.713669799900259],
              [39.199240572376702, 9.71453752640393],
              [39.199029468284898, 9.71583689836457],
              [39.198885737839397, 9.71647662137762],
              [39.198077254083699, 9.71746387317752],
              [39.197017242048503, 9.71771843314681],
              [39.196287360880099, 9.717700724633479],
              [39.195629344934503, 9.71776270442604],
              [39.1942975925258, 9.71783796558729],
              [39.193419489335596, 9.71791322673163],
              [39.192474012498998, 9.718096952395189],
              [39.191874387046902, 9.718214271139621],
              [39.190917681269298, 9.71832716272278],
              [39.190165342218798, 9.71843562714915],
              [39.189338892157501, 9.71866362368584],
              [39.188532654189999, 9.718853989607171],
              [39.1873019622507, 9.71915946027779],
              [39.186623734211203, 9.719416232509751],
              [39.185765843114901, 9.719794749837121],
              [39.1847664673613, 9.72018654802307],
              [39.1840343404047, 9.720536288388971],
              [39.182812631618297, 9.721189283662881],
              [39.181359606646303, 9.721417278322649],
              [39.182239955624702, 9.71968628585142],
              [39.182444322351799, 9.7190576367519],
              [39.182945133122701, 9.71732663207024],
              [39.183239331378303, 9.716233128333419],
              [39.183529038057401, 9.71483857384893],
              [39.183782812125202, 9.71348164462181],
              [39.183971458334803, 9.71223981713152],
              [39.184225232402603, 9.710927149553291],
              [39.184492481199598, 9.709616690446209],
              [39.184710322656002, 9.708629415501891],
              [39.184858544677901, 9.707644351277761],
              [39.184836086795798, 9.70675447054402],
              [39.184618245339401, 9.705477199786831],
              [39.184283622896103, 9.70377268876439],
              [39.184146629815203, 9.701649785638971],
              [39.184115188780297, 9.70085286337148],
              [39.184074764592502, 9.69980136581354],
              [39.184000653581599, 9.69825621183846],
              [39.184187054002997, 9.69607793110139],
              [39.184355488118797, 9.69455932457412],
              [39.1843060807782, 9.69303628371628],
              [39.184160104544503, 9.691882929860791],
              [39.184474514893999, 9.68798009268477],
              [39.1845396427521, 9.686904203326931],
              [39.184589050092697, 9.68615373525164],
              [39.184613753763003, 9.68527265259727],
              [39.185029224581903, 9.6839798035115],
              [39.1839175594178, 9.68434065058938],
              [39.182913692087801, 9.685403265498559],
              [39.181941265792702, 9.685921289047281],
              [39.180283874093497, 9.68714771768107],
              [39.179448440879298, 9.688000016529241],
              [39.178936401167398, 9.688345362978829],
              [39.178446819337502, 9.68913124620304],
              [39.178118934258798, 9.68991270009122],
              [39.177692234498799, 9.69086903778391],
              [39.1773621036319, 9.69151323594315],
              [39.176984811212598, 9.693532158243221],
              [39.176542390935197, 9.69513710425282],
              [39.175720432450198, 9.6952345076255],
              [39.1738092666832, 9.69526771331428],
              [39.173081631303099, 9.695732592611829],
              [39.172535904767997, 9.69616205196165],
              [39.171736404165102, 9.69607571739426],
              [39.170642705306697, 9.694134290611821],
              [39.170157615053299, 9.692861399221661],
              [39.168572088576802, 9.692885750232699],
              [39.167815257949997, 9.69328422107138],
              [39.166582320222503, 9.69343918182526],
              [39.165612139715599, 9.693160252416741],
              [39.164644204997003, 9.692013540189389],
              [39.164242208907403, 9.691247587367551],
              [39.163337156258599, 9.689394682702959],
              [39.161987437544198, 9.688730556676431],
              [39.161381074727402, 9.688513608556301],
              [39.160334537421399, 9.688422844505251],
              [39.158275149632601, 9.68847818844174],
              [39.157255561785099, 9.688500326013781],
              [39.156447078029402, 9.68851803607035],
              [39.155236598184104, 9.68837192807562],
              [39.153880142105102, 9.688770404275081],
              [39.152914453174603, 9.68945224023005],
              [39.150136413158499, 9.69086461030086],
              [39.149428989872199, 9.69081148049986],
              [39.147650325609703, 9.690594533725109],
              [39.145570725726898, 9.68991270009122],
              [39.145004787098003, 9.6903997242568],
              [39.143778586735102, 9.69181430407453],
              [39.143583203160802, 9.693204526941511],
              [39.144041343955699, 9.69440879177407],
              [39.1450339823447, 9.6946877201443],
              [39.1458581866179, 9.694725353319329],
              [39.146498236257798, 9.694920160275601],
              [39.1469990470287, 9.69544259655421],
              [39.147376339448002, 9.69616205196165],
              [39.147733419773502, 9.696985549794951],
              [39.146978834934799, 9.699128405645411],
              [39.146325310565601, 9.698922532692521],
              [39.145626870432203, 9.70101888900013],
              [39.146235479037202, 9.70176932381564],
              [39.146527431504502, 9.70360002352778],
              [39.146460057858199, 9.704651509166689],
              [39.146179334331897, 9.70541079042883],
              [39.145557250997697, 9.705990765044129],
              [39.1437898156762, 9.70612136987622],
              [39.142502979031697, 9.707896705085391],
              [39.143071163448901, 9.70879543727683],
              [39.1440615560496, 9.70906328556687],
              [39.146713831926, 9.70898138156703],
              [39.147493120435001, 9.71051320440978],
              [39.147084386980701, 9.711746183890581],
              [39.147439221517899, 9.7133798193682],
              [39.147937786500599, 9.71423647868812],
              [39.148393681507301, 9.71486292326281],
              [39.148582327717001, 9.715458376559511],
              [39.148496987765, 9.716120236043709],
              [39.147758123443801, 9.71629953554487],
              [39.146945148111598, 9.716210992593339],
              [39.146215266943301, 9.71619328400023],
              [39.144899235052101, 9.717725073839061],
              [39.144557875244097, 9.71837807460077],
              [39.144358000093398, 9.71913511117658],
              [39.144634232043202, 9.72044774655876],
              [39.143902105086703, 9.72217430801984],
              [39.143897613510298, 9.72285607598811],
              [39.143884138780997, 9.72416205620145],
              [39.144196303342198, 9.72635122176027],
              [39.145139534390601, 9.727451332912761],
              [39.145651574102502, 9.72793608899161],
              [39.145972721816598, 9.728704171384679],
              [39.144789191429801, 9.730441757604],
              [39.1434911258442, 9.73089773414701],
              [39.143922317180603, 9.732770330317241],
              [39.1434978632088, 9.73335911134112],
              [39.142725312064499, 9.733536188137791],
              [39.143399048527598, 9.734968293280989],
              [39.143866172475299, 9.735962130798599],
              [39.1431991733769, 9.73707327748318],
              [39.142141407129799, 9.73734110312302],
              [39.141366610197302, 9.737416359875731],
              [39.138932175777299, 9.738290665024071],
              [39.138132675174397, 9.739151687350139],
              [39.138370728724702, 9.73986219510358],
              [39.139927059954502, 9.74083388394407],
              [39.140558126441597, 9.7411083467944],
              [39.1410791493064, 9.741595296457261],
              [39.141427246478898, 9.74247623086015],
              [39.1413194486449, 9.743206652094051],
              [39.140764738956896, 9.743671464774049],
              [39.139057939917102, 9.743494393353259],
              [39.138548145993298, 9.743153530603671],
              [39.136964865305103, 9.742389908245061],
              [39.134424878839198, 9.74182106378716],
              [39.1335580045901, 9.74144699822609],
              [39.132360999474002, 9.740849377820661],
              [39.129861437195899, 9.739620932515759],
              [39.128761000972901, 9.739182675258769],
              [39.127957008793601, 9.73893477190912],
              [39.126712842125102, 9.73836149470799],
              [39.126194065048502, 9.73798078498028],
              [39.125241850847303, 9.737031222197899],
              [39.1243120945283, 9.736010826547499],
              [39.123651832794401, 9.735539362861941],
              [39.122859069556199, 9.734943945331411],
              [39.121843973285102, 9.73416923691817],
              [39.120678409203997, 9.733974452805841],
              [39.118967118587697, 9.734012081563661],
              [39.117797062930201, 9.73446583978011],
              [39.116925697104598, 9.73395231824043],
              [39.1160520854908, 9.73245823168447],
              [39.115142541265598, 9.73143118164289],
              [39.112762005762697, 9.732380760347461],
              [39.111093385122402, 9.73240510848389],
              [39.10967853855, 9.73237854688043],
              [39.108786960630503, 9.73240953541759],
              [39.107980722663001, 9.73252020874112],
              [39.107185713636497, 9.732686218657671],
              [39.105195945282198, 9.7333082017447],
              [39.103538553583, 9.733680062965959],
              [39.102822147143897, 9.73381065698711],
              [39.101955272894699, 9.73385271267796],
              [39.101014287634598, 9.733781882037681],
              [39.100203558090698, 9.733717691756951],
              [39.099550033721499, 9.73358267078136],
              [39.098573115850002, 9.73296732859489],
              [39.0969763604325, 9.732170480913631],
              [39.096626017471699, 9.732883216984931],
              [39.096673179024101, 9.734361807462999],
              [39.097019030408497, 9.735636754475941],
              [39.097279541840898, 9.73626758583338],
              [39.097533315908599, 9.737150747731659],
              [39.097468188050598, 9.73819327418415],
              [39.097205430830002, 9.73876433824946],
              [39.096852842080899, 9.73945492604672],
              [39.095720964822902, 9.741387236233701],
              [39.095402062897101, 9.74258468744747],
              [39.095312231368702, 9.743556368361229],
              [39.097003309891001, 9.744860054253641],
              [39.097744420000403, 9.74490432193646],
              [39.0984675638041, 9.745083605991789],
              [39.099431006946403, 9.745176568056619],
              [39.100071056586302, 9.745209768787779],
              [39.100731318320101, 9.745218622315519],
              [39.101542047864001, 9.745280597003211],
              [39.102530194676604, 9.745411186486001],
              [39.103287025303402, 9.745464307616921],
              [39.104216781622497, 9.74563916461309],
              [39.105456456714599, 9.74606191975681],
              [39.105485651961303, 9.746765772681879],
              [39.105213911587903, 9.74748954442385],
              [39.10502301959, 9.74817568743846],
              [39.104708609240603, 9.749452795032409],
              [39.104542420912999, 9.750163280848581],
              [39.104486276207702, 9.75112387297172],
              [39.104501996725197, 9.75220840913925],
              [39.104553649853997, 9.754184911896379],
              [39.103300500032702, 9.75368027400873],
              [39.102631255146001, 9.75378872695255],
              [39.102027138117499, 9.754215898408461],
              [39.101122085468702, 9.75503261329491],
              [39.1008480993071, 9.75560586184365],
              [39.099947538234701, 9.756654970533511],
              [39.09957698318, 9.757170670429661],
              [39.098348537028997, 9.758018364232759],
              [39.097703995812601, 9.758182148165419],
              [39.097036996714202, 9.758301666119509],
              [39.0963542770982, 9.75842118403073],
              [39.0935852202349, 9.76018074831666],
              [39.093255089368, 9.760869078482941],
              [39.092841864337302, 9.76191817059811],
              [39.092774490690999, 9.7629362737104],
              [39.092563386599302, 9.76371534181574],
              [39.092004185334901, 9.76445678277986],
              [39.090834129677297, 9.76538413497509],
              [39.089668565596199, 9.76508534636371],
              [39.088467068903697, 9.76383928430079],
              [39.087997699167701, 9.763226211201181],
              [39.0875889657135, 9.76258879104153],
              [39.087202690141297, 9.76199563507477],
              [39.086791710898801, 9.76136927893317],
              [39.086436876361603, 9.76061233813709],
              [39.086205560175898, 9.75975137122242],
              [39.086014668178002, 9.758598247524089],
              [39.084590838452698, 9.756827608355851],
              [39.083382604395602, 9.756725796317591],
              [39.082645985862598, 9.756894007494481],
              [39.0819340709999, 9.757128617678321],
              [39.0802879082418, 9.75716403052191],
              [39.079578239167297, 9.757053365373199],
              [39.077422282485401, 9.755621355033981],
              [39.076892276467802, 9.75483562804475],
              [39.076216294216501, 9.75423581830754],
              [39.075526837235898, 9.753591740967209],
              [39.073373126342297, 9.75225267584665],
              [39.072185104379002, 9.75247622262925],
              [39.071075685003102, 9.75299635565214],
              [39.070480551127403, 9.753286301793439],
              [39.069905629345598, 9.753923739751681],
              [39.069191468694697, 9.75405432584961],
              [39.068266203952, 9.75325531519495],
              [39.067289286080602, 9.752626729289529],
              [39.065930584213298, 9.75248507596408],
              [39.065200703045001, 9.75252270263452],
              [39.063067204245201, 9.752870195801989],
              [39.061524347744701, 9.75283478250214],
              [39.060358783663602, 9.75305611556449],
              [39.059592969883902, 9.753454514706361],
              [39.058782240340001, 9.75388832656375],
              [39.057722228304698, 9.75407203243523],
              [39.056976626618898, 9.753950299640151],
              [39.056055853452598, 9.753682487334469],
              [39.054816178360603, 9.75330400841985],
              [39.053210439790199, 9.75300299564293],
              [39.052260471377203, 9.75301184896378],
              [39.0510387625908, 9.75324646188057],
              [39.050041632625501, 9.753684700660189],
              [39.048557166618501, 9.75410965892652],
              [39.047465713548299, 9.75348550128634],
              [39.046095782739997, 9.752000355535699],
              [39.045413063124002, 9.75165950147907],
              [39.043636644649702, 9.75101541916092],
              [39.041590731590098, 9.750783018019],
              [39.040166901864801, 9.74943730155538],
              [39.039194475569701, 9.74808272620947],
              [39.0386487490346, 9.74765554689554],
              [39.037748187962301, 9.746821106968349],
              [39.037155299874797, 9.746278830564989],
              [39.036416435553598, 9.74592469039716],
              [39.035120615756199, 9.745501935079529],
              [39.033858482782101, 9.7460597063805],
              [39.033865220146701, 9.74673257210558],
              [39.034089798967699, 9.747363382490111],
              [39.034449125081402, 9.747852536388089],
              [39.0352126930729, 9.748735667626811],
              [39.035500153963802, 9.75090253862651],
              [39.035201464131802, 9.751902968697941],
              [39.034848875382799, 9.753053902234591],
              [39.034669212326001, 9.753728967171551],
              [39.034512007151299, 9.75436861760439],
              [39.032571646137498, 9.75550847605933],
              [39.0305055209841, 9.755380103845621],
              [39.028574143123201, 9.75552618256769],
              [39.0264271695942, 9.75675235598275],
              [39.025872459906203, 9.757190590152121],
              [39.025079696668001, 9.757484959245319],
              [39.023905149434, 9.75774170200147],
              [39.023040520972998, 9.75792097915355],
              [39.0221803840885, 9.75829945282446],
              [39.021481943955102, 9.75860267411022],
              [39.020767783304201, 9.758757604587659],
              [39.0187241160328, 9.75862702033289],
              [39.017183505320602, 9.758129029060941],
              [39.0161437053792, 9.757781541376939],
              [39.015600224632301, 9.75740085381665],
              [39.015186999601603, 9.756623984248231],
              [39.014904030287099, 9.75465413591343],
              [39.014904030287099, 9.752828142507999],
              [39.014701909348197, 9.75212651571505],
              [39.013931603992098, 9.751867555295339],
              [39.012505528478499, 9.751874195308609],
              [39.011775647310202, 9.751867555295339],
              [39.011079452964999, 9.751834355226981],
              [39.010302410244201, 9.751770168418791],
              [39.009222186115103, 9.751690488225901],
              [39.008449634970702, 9.75139611401481],
              [39.0071964851494, 9.750831711605009],
              [39.006271220406703, 9.75047093623073],
              [39.005687315472102, 9.75027616163327],
              [39.004634040801399, 9.749884398746079],
              [39.0039603043383, 9.74981578485971],
              [39.003127116912303, 9.750285015026529],
              [39.002316387368403, 9.749829064967861],
              [39.0007128945863, 9.74918940582498],
              [38.999583263116499, 9.74878657487003],
              [38.998734355172999, 9.747808269096531],
              [38.998204349155301, 9.74912964521954],
              [38.9961157661198, 9.7481314201898],
              [38.994995117802802, 9.747334608709069],
              [38.993867732121302, 9.746641823846851],
              [38.992394495055301, 9.746584276157771],
              [38.9912334225506, 9.745729913144601],
              [38.990384514607101, 9.74474938502091],
              [38.989362680971396, 9.745187634967371],
              [38.988974159610997, 9.74639835278678],
              [38.988143217973203, 9.747097778263081],
              [38.987584016708801, 9.74822880812912],
              [38.9863443416168, 9.74901897740342],
              [38.985053013395799, 9.74933770061442],
              [38.984403980603098, 9.74953911581985],
              [38.982452390648298, 9.750417815897251],
              [38.981823569949398, 9.751099526200941],
              [38.981271106049697, 9.751657288139899],
              [38.981082459840003, 9.75184763525472],
              [38.980974662005899, 9.751905182035481],
              [38.980103296180303, 9.752358915920439],
              [38.979312778730304, 9.752918889083141],
              [38.978863621088301, 9.75354968776424],
              [38.978203359354403, 9.754547896571211],
              [38.977713777524599, 9.75583825962301],
              [38.977424070845501, 9.75694934009989],
              [38.977212966753697, 9.757719569013039],
              [38.976323634622403, 9.76028919914466],
              [38.975923884320999, 9.7608580120928],
              [38.975247902069697, 9.762088592422939],
              [38.974014964342203, 9.76165921778826],
              [38.973127877999197, 9.76095539631339],
              [38.971748964037999, 9.7607030825923],
              [38.970349837983001, 9.759339699880391],
              [38.9683196454409, 9.75823305396592],
              [38.968110787137299, 9.757511518849951],
              [38.967672858436302, 9.75568996772537],
              [38.967131623477698, 9.75442837727071],
              [38.966644287435997, 9.753295155106761],
              [38.966242291346397, 9.75236112925495],
              [38.965721268481602, 9.751143793055579],
              [38.965236178228203, 9.750488643006671],
              [38.964423202896, 9.749384181057231],
              [38.963949341583699, 9.748740094343921],
              [38.963179036227501, 9.74782818937846],
              [38.962190889414998, 9.746659530826101],
              [38.961056766368799, 9.745785247602919],
              [38.960308918894803, 9.745209768787779],
              [38.959224203189201, 9.74488218809579],
              [38.958323642116902, 9.74439967000419],
              [38.957328757939699, 9.743868456619269],
              [38.956630317806301, 9.74316902437257],
              [38.955875732967598, 9.742414255651569],
              [38.955240174904098, 9.742113233046179],
              [38.9544766069126, 9.741752448242259],
              [38.9539713045653, 9.741022023825479],
              [38.953034810881597, 9.739802432827419],
              [38.952217343973103, 9.739421724743],
              [38.9515076748986, 9.73836370813537],
              [38.951500937534, 9.73701351470778],
              [38.951864755224001, 9.73506789851076],
              [38.952644043733002, 9.73264637628523],
              [38.953578291628503, 9.730295667900339],
              [38.954389021172403, 9.728093247204161],
              [38.954948222436798, 9.72659249393266],
              [38.956104803365101, 9.72396947977875],
              [38.957517404149399, 9.72129110653985],
              [38.958175420095003, 9.71993641703077],
              [38.959855269676297, 9.717222594419161],
              [38.960758076536798, 9.71557126907248],
              [38.961577789233601, 9.71356354752073],
              [38.961627196574199, 9.71243904110332],
              [38.961357701989002, 9.71070578857764],
              [38.961272362037001, 9.708941536370681],
              [38.961276853613398, 9.707099797676641],
              [38.9600528990388, 9.708217681144911],
              [38.959363442058198, 9.70895703172539],
              [38.957908171298001, 9.7104135918657],
              [38.957135620153601, 9.71090279985313],
              [38.954079102399398, 9.713556906745881],
              [38.953508672193998, 9.714028401432181],
              [38.951411106005601, 9.716206565445161],
              [38.950602622249903, 9.7167975892106],
              [38.950029946256201, 9.71711191603141],
              [38.948882348480801, 9.717532493710049],
              [38.948197383076597, 9.71778262649979],
              [38.946980165866599, 9.718836281153941],
              [38.945872992279, 9.7198368072915],
              [38.944981414359503, 9.72091480444884],
              [38.944424458883297, 9.721585507292341],
              [38.943315039507397, 9.722570530482249],
              [38.942769312972302, 9.723130553608851],
              [38.941460018445703, 9.724469735543151],
              [38.940359582222698, 9.72508730689243],
              [38.938920031979897, 9.725895239013971],
              [38.937338997079799, 9.727382714415571],
              [38.935948854177703, 9.728212775936059],
              [38.934428455559299, 9.72829910221582],
              [38.933027083716098, 9.728688676944641],
              [38.932182667348997, 9.727708098777271],
              [38.931472998274501, 9.72755979437958],
              [38.930112050619101, 9.72670980969667],
              [38.929492213072997, 9.726218411315839],
              [38.928533261507198, 9.72536842322198],
              [38.927652912528799, 9.72462910890378],
              [38.926882607172701, 9.72407794237529],
              [38.926163954945402, 9.723469223265869],
              [38.924832202536699, 9.72166962174545],
              [38.924142745556097, 9.720387980810109],
              [38.923902446217603, 9.71968849940251],
              [38.9234330764817, 9.71851974239436],
              [38.922763831594999, 9.717178323068451],
              [38.922433700728099, 9.71630838983873],
              [38.922042933579498, 9.71543845434738],
              [38.921393900786697, 9.71469911808088],
              [38.919828586404101, 9.71317838236218],
              [38.918892092720398, 9.71228851633558],
              [38.917782673344497, 9.711248122707991],
              [38.917302074667496, 9.710889518197749],
              [38.916473378817898, 9.710289629547241],
              [38.915792904990198, 9.709809275129709],
              [38.915002387540198, 9.709317851924959],
              [38.914247802701503, 9.710196657778271],
              [38.910052670324703, 9.709129694200669],
              [38.909313806003503, 9.70859842476146],
              [38.908592907988002, 9.7084279756378],
              [38.907299333978898, 9.709386474127511],
              [38.905814867971898, 9.70973179854623],
              [38.904680744925699, 9.710116967670039],
              [38.9037936585826, 9.71053976774987],
              [38.902998649556203, 9.710880663760539],
              [38.901794907075399, 9.711301249269439],
              [38.899888232884898, 9.711487192168111],
              [38.899151614351901, 9.71165321252608],
              [38.898498089982702, 9.711969757780469],
              [38.897739013567602, 9.71245896349398],
              [38.897330280113401, 9.712928246131041],
              [38.896524042145899, 9.713857955072941],
              [38.896133274997297, 9.714351585199671],
              [38.895558353215399, 9.715887810621471],
              [38.8927578553172, 9.718884979398061],
              [38.891913438950098, 9.717908799605951],
              [38.890783807480297, 9.71788887753973],
              [38.888544756634701, 9.720250740902451],
              [38.887758730761099, 9.72064032500951],
              [38.886858169688701, 9.721246835727589],
              [38.8850817512144, 9.7202684492808],
              [38.884196910659497, 9.72188433485878],
              [38.883082999707199, 9.72236909900975],
              [38.881317810173897, 9.72396947977875],
              [38.880482376959698, 9.724757492944621],
              [38.879932158848199, 9.725335220518209],
              [38.879507704876403, 9.725784563496299],
              [38.878557736463499, 9.726647831562319],
              [38.8778772626357, 9.72727425289127],
              [38.873583315577697, 9.728768362628671],
              [38.872633347164701, 9.729319521423029],
              [38.871642954564003, 9.729689173599111],
              [38.8708816323607, 9.730078746707379],
              [38.870302219002397, 9.73036871276015],
              [38.868842456665703, 9.73118991296395],
              [38.8677824446305, 9.73183846048545],
              [38.867133411837699, 9.732208109874669],
              [38.865323306540198, 9.73429983074805],
              [38.865222246070701, 9.73563896792138],
              [38.864813512616401, 9.736548692756189],
              [38.863533413336597, 9.73802948043477],
              [38.862971966284, 9.73880860674085],
              [38.862751879039401, 9.739408444220579],
              [38.862143270434402, 9.74077854866465],
              [38.8615324160412, 9.7424895112605],
              [38.860602659722097, 9.743669251381871],
              [38.860101848951203, 9.744098649189899],
              [38.859012641669203, 9.7445789543307],
              [38.8577527544833, 9.745178781438799],
              [38.856953253880398, 9.745778607468401],
              [38.856362611581098, 9.746227922939021],
              [38.854532294189703, 9.747879096760199],
              [38.853292619097601, 9.749249166419711],
              [38.852522313741503, 9.750039333276259],
              [38.8514825138001, 9.750909178659009],
              [38.849953132028901, 9.75150899438213],
              [38.848852695805803, 9.75172811498603],
              [38.8480329831091, 9.75181886186062],
              [38.847233482506198, 9.751898542022831],
              [38.846303726187202, 9.75222832915831],
              [38.844783327568798, 9.753248675209189],
              [38.843792934968, 9.75395915293584],
              [38.843031612764698, 9.75455896317094],
              [38.841592062521897, 9.75567890116319],
              [38.840282767995298, 9.75688958088565],
              [38.839793186165501, 9.757358801094361],
              [38.839292375394599, 9.758058203575141],
              [38.837992064020803, 9.76032903821547],
              [38.837183580265098, 9.76167913724232],
              [38.836972476173401, 9.76233869182615],
              [38.835541909083403, 9.76376846002926],
              [38.834273038744598, 9.764819756261801],
              [38.832871666901397, 9.764919352574839],
              [38.830152017378701, 9.76638894804155],
              [38.829902734887298, 9.767008655028519],
              [38.829502984585901, 9.767929360421521],
              [38.829062810096701, 9.768898754212451],
              [38.828712467135901, 9.769469765671859],
              [38.828263309493799, 9.77034841317421],
              [38.827863559192402, 9.770979178842181],
              [38.827562623572199, 9.771718390418879],
              [38.827333553174803, 9.7729998341407],
              [38.826931557085103, 9.774759321363859],
              [38.826401551067498, 9.776439125008279],
              [38.825792942462499, 9.777439478351461],
              [38.823661689450901, 9.780199287123031],
              [38.823241727055603, 9.780849952413019],
              [38.822302987583697, 9.78181930854382],
              [38.821972856716798, 9.78232833003743],
              [38.821451833852002, 9.782919236619479],
              [38.820973480963197, 9.78442858873696],
              [38.820742164777499, 9.78621899899994],
              [38.8206523332491, 9.78753800953467],
              [38.820423262851698, 9.79011847472735],
              [38.821503486980802, 9.79171853012207],
              [38.821651709002701, 9.792798507827531],
              [38.821162127172897, 9.79324997287614],
              [38.8206523332491, 9.793719141785861],
              [38.8197832132117, 9.794560103266219],
              [38.819383462910302, 9.795038122526259],
              [38.8192127830063, 9.79575957621684],
              [38.818943288421103, 9.79673995402487],
              [38.8186423528009, 9.79772918106681],
              [38.818372858215703, 9.798379812010021],
              [38.818042727348796, 9.800119247653599],
              [38.818312221934001, 9.801139446758089],
              [38.818581716519198, 9.801898508264021],
              [38.819091510443002, 9.802369877945781],
              [38.819241978253103, 9.80267969802898],
              [38.8185323091786, 9.80472007734617],
              [38.8175014923901, 9.804649261788439],
              [38.816322453579701, 9.804164617409249],
              [38.814031749605199, 9.80548798257899],
              [38.8133130973779, 9.806888796476461],
              [38.812751650325303, 9.8083582062724],
              [38.812473172587197, 9.80940936139131],
              [38.812111600685398, 9.8108986765141],
              [38.8118735471351, 9.81155813337516],
              [38.811592823608798, 9.812368068529249],
              [38.810831501405502, 9.813408146287459],
              [38.810283529082199, 9.81391933224401],
              [38.809591826313401, 9.81455001512926],
              [38.808742918369902, 9.81566975090694],
              [38.808172488164502, 9.81631813372719],
              [38.8075032432778, 9.81703954110681],
              [38.807002432506899, 9.817479908642699],
              [38.806521833829898, 9.81804862363901],
              [38.806101871434599, 9.818528821921911],
              [38.807103492976402, 9.82093865439883],
              [38.807732313675302, 9.821367952374549],
              [38.8085924505598, 9.82233940295461],
              [38.809232500199798, 9.822899258143989],
              [38.808383592256298, 9.825328974432599],
              [38.808331939127399, 9.82593971998301],
              [38.808192700258402, 9.82728955216327],
              [38.808091639788898, 9.82889827938665],
              [38.808091639788898, 9.829889623214729],
              [38.808111851882799, 9.83135007968999],
              [38.808091639788898, 9.831978516551761],
              [38.808091639788898, 9.83273972016481],
              [38.808192700258402, 9.833328324082521],
              [38.808262319692901, 9.833927990905851],
              [38.808291514939597, 9.83460952973843],
              [38.808363380162398, 9.836328860093991],
              [38.808502619031401, 9.83793975609392],
              [38.808612662653701, 9.83858809526196],
              [38.808102868730003, 9.84111948242507],
              [38.807712101581401, 9.841770028090609],
              [38.806162507716301, 9.843159624744059],
              [38.8057133500742, 9.843628722931131],
              [38.8049228326242, 9.844759423841079],
              [38.805041859399303, 9.846668994080799],
              [38.8055516533231, 9.84847898209448],
              [38.805881784189999, 9.849237934995021],
              [38.806301746585298, 9.85020930365353],
              [38.805461821794701, 9.854420009897209],
              [38.805371990266302, 9.858104057188379],
              [38.805342795019499, 9.85930993760056],
              [38.805261946643903, 9.862628851736829],
              [38.804033500492899, 9.86219960739016],
              [38.801383470404801, 9.86192966993737],
              [38.800911854880603, 9.86408916336879],
              [38.800732191823798, 9.86491003267207],
              [38.800193202653297, 9.86703853061055],
              [38.799582348260103, 9.86839925753314],
              [38.799173614805802, 9.869368357182161],
              [38.798693016128802, 9.870319753606919],
              [38.798163010111203, 9.8712689347427],
              [38.797763259809798, 9.87181985680431],
              [38.796981725512602, 9.872738058191739],
              [38.796303497473097, 9.87362970672671],
              [38.794603435797903, 9.874839955340841],
              [38.793172868707899, 9.876118787249331],
              [38.792703498972003, 9.876868826489289],
              [38.792393580198897, 9.87753921409762],
              [38.792052220391, 9.878360049870301],
              [38.791713106371198, 9.87927823301164],
              [38.791232507694197, 9.880559260148811],
              [38.790861952639503, 9.881900018959669],
              [38.790682289582698, 9.883568216185919],
              [38.790543050713602, 9.88442886104793],
              [38.793372743858598, 9.886738652574991],
              [38.795113229721601, 9.88784929368096],
              [38.796671806739603, 9.88907940176103],
              [38.797329822685199, 9.89015906117417],
              [38.798212417451801, 9.89160818862228],
              [38.798392080508599, 9.89238916610816],
              [38.798571743565503, 9.893798460726391],
              [38.798771618716202, 9.894878104632751],
              [38.798973739655104, 9.896059514185319],
              [38.799121961677002, 9.896639155520379],
              [38.799472304637803, 9.898128076855791],
              [38.799723832917401, 9.89925859062642],
              [38.800022522749302, 9.900428922699071],
              [38.800343670463398, 9.90165013432649],
              [38.800572740860801, 9.902749663372109],
              [38.800763632858697, 9.90379830534793],
              [38.8010129153501, 9.904988532053579],
              [38.8014216488043, 9.90639998489311],
              [38.802203183101497, 9.908169823132789],
              [38.802362634064501, 9.908809174849001],
              [38.802573738156198, 9.909928590423471],
              [38.802623145496803, 9.91089977989159],
              [38.802542297121299, 9.91215856104966],
              [38.802533313968397, 9.912979310309231],
              [38.802522085027398, 9.914019070571911],
              [38.802513101874503, 9.91460974142249],
              [38.802443482439998, 9.91597912031145],
              [38.802382846158302, 9.916698097243721],
              [38.802223395195398, 9.91783960814827],
              [38.801983095856897, 9.91939922417702],
              [38.801983095856897, 9.92061815295431],
              [38.801612540802203, 9.921739740399749],
              [38.801221773653602, 9.92287902154567],
              [38.800772616011599, 9.923699743954259],
              [38.800283034181703, 9.92463992208334],
              [38.799431880450001, 9.92590971131869],
              [38.798623396694303, 9.926779093791369],
              [38.798122585923402, 9.927429470009489],
              [38.797442112095702, 9.928020116679839],
              [38.796703247774502, 9.92881870467814],
              [38.796483160529903, 9.92966817010563],
              [38.796292268532, 9.93156839858716],
              [38.796352904813702, 9.93243997815747],
              [38.796413541095397, 9.933369070574329],
              [38.7964225242482, 9.93399952464029],
              [38.796433753189298, 9.93492861262818],
              [38.796323709566998, 9.935908576384399],
              [38.796222649097501, 9.93723804847969],
              [38.7962024370036, 9.938638301930821],
              [38.796263073285303, 9.94011818413183],
              [38.7973230853205, 9.941870143638729],
              [38.797433128942799, 9.94196968651063],
              [38.797873303432098, 9.942359009451829],
              [38.798693016128802, 9.94315977449808],
              [38.799211793205401, 9.943668546850789],
              [38.799683408729599, 9.9441286533399],
              [38.800482909332402, 9.94491835383281],
              [38.801062322690697, 9.945500120762221],
              [38.801533938214902, 9.94613939957768],
              [38.801971866915899, 9.947238779252141],
              [38.801962883762997, 9.94869871434879],
              [38.802252590442201, 9.950269243303859],
              [38.8025625092152, 9.95169819688997],
              [38.802712977025301, 9.952868341532101],
              [38.802793825400798, 9.954268528002819],
              [38.802793825400798, 9.955429815543599],
              [38.802753401213003, 9.956719392866731],
              [38.802367125640899, 9.961198588404979],
              [38.801574362402597, 9.961344575965571],
              [38.798960264925803, 9.96317162688039],
              [38.798378605779398, 9.96351226235976],
              [38.797967626536902, 9.964715543282971],
              [38.7975566472944, 9.96586131032962],
              [38.797376984237602, 9.967009285244121],
              [38.797257957462399, 9.96769718349703],
              [38.797138930687296, 9.96884515194726],
              [38.796263073285303, 9.96993118388877],
              [38.794351907518298, 9.96992233639266],
              [38.792919094640098, 9.97051954184078],
              [38.791852345240301, 9.970623499714369],
              [38.790929326285799, 9.970711974474289],
              [38.7897704995693, 9.97082256789041],
              [38.787789714367797, 9.97310963132243],
              [38.785054344327698, 9.975852316783531],
              [38.784760146072102, 9.97700025650453],
              [38.7848814186355, 9.97813934490671],
              [38.784629890355902, 9.979983996121071],
              [38.784337937888601, 9.98067186698046],
              [38.782590714660998, 9.98261603431591],
              [38.780560522118897, 9.983009732317139],
              [38.778763891550597, 9.98294337875479],
              [38.777607310622301, 9.982421396925931],
              [38.7763945849888, 9.98167159952915],
              [38.775354785047398, 9.980689561388539],
              [38.7739758710863, 9.97833177303953],
              [38.7733987035162, 9.977811995637911],
              [38.772760899664497, 9.97752003520166],
              [38.771604318736202, 9.977343089355349],
              [38.769455099418899, 9.97842466933883],
              [38.767422661088602, 9.979450950500761],
              [38.766782611448697, 9.9797915689646],
              [38.764981489303999, 9.980930647602801],
              [38.763124222454103, 9.981554374411299],
              [38.761965395737597, 9.98143493745537],
              [38.760229401450999, 9.98085102281784],
              [38.759014430029303, 9.98050377005575],
              [38.757797212819298, 9.98038212091137],
              [38.756072447473798, 9.98082005761842],
              [38.753923228156502, 9.98371971458757],
              [38.752122106011903, 9.98479906161438],
              [38.750262593373698, 9.985599722465111],
              [38.747893286811902, 9.985869557998621],
              [38.746152800948899, 9.98585849916968],
              [38.744933337950698, 9.985568757717459],
              [38.7443516788042, 9.985279016007251],
              [38.741342322602399, 9.985438263391361],
              [38.739592853586601, 9.9874996252742],
              [38.739723109302801, 9.98960963238771],
              [38.737933216099201, 9.99141883094245],
              [38.737192105989799, 9.991858964521199],
              [38.735862599369298, 9.99231900300202],
              [38.734602712183303, 9.992668454874631],
              [38.733852618921098, 9.99286972128905],
              [38.732952057848699, 9.993199267127221],
              [38.732071708870301, 9.99354871805386],
              [38.731182376739, 9.99386941635856],
              [38.730533343946298, 9.99413924503199],
              [38.729322864100901, 9.992779040832049],
              [38.726513383049799, 9.9913790198356],
              [38.724332722697604, 9.99127949204715],
              [38.721772524137897, 9.9935000603524],
              [38.720672087914799, 9.9945285056745],
              [38.720321744953999, 9.99509912554675],
              [38.719913011499699, 9.99566974441746],
              [38.719502032257303, 9.99624920906734],
              [38.7181635424839, 9.99669818287591],
              [38.716622931771703, 9.99702993652325],
              [38.715502283454697, 9.997268798939659],
              [38.714161547893198, 9.99725995218664],
              [38.712602970875203, 9.997248893745009],
              [38.711091555409702, 9.9971891781537],
              [38.709872092411501, 9.99741034695545],
              [38.708652629413301, 9.998779378489139],
              [38.708073216055098, 9.99912882341715],
              [38.706442773814402, 10.000548716031201],
              [38.702133106238797, 10.004469976018299],
              [38.7014616155639, 10.004688928226299],
              [38.700763175430502, 10.003649456834999],
              [38.697841404968898, 10.0042687167878],
              [38.696033545459599, 10.006349863906101],
              [38.6957618050862, 10.0077785714225],
              [38.694611961522497, 10.0084287860745],
              [38.692642405262099, 10.008400035114001],
              [38.691422942263898, 10.0080793511513],
              [38.689902543645502, 10.0075198121682],
              [38.688561808084003, 10.0070199860162],
              [38.686821322221, 10.006989023309],
              [38.684952826429999, 10.0077188577668],
              [38.684663119750901, 10.008329263507999],
              [38.684452015659097, 10.009309006555799],
              [38.683733363431799, 10.010419230995399],
              [38.682821573418501, 10.011998308616899],
              [38.681992877568902, 10.0130488111823],
              [38.6814134642106, 10.0136193985189],
              [38.680252391705899, 10.0139290191344],
              [38.678992504519897, 10.014459796644999],
              [38.676562561676398, 10.015008265826999],
              [38.674532369134198, 10.0150392277693],
              [38.672731246989599, 10.0150701897087],
              [38.671343349875599, 10.015428463363399],
              [38.669501803543199, 10.016200298472899],
              [38.6693019283925, 10.018159734083399],
              [38.669481591449298, 10.018739158598899],
              [38.669562439824901, 10.0204597336927],
              [38.669533244578098, 10.0222400106513],
              [38.668331747885603, 10.023409901615601],
              [38.667943226525203, 10.024049027410101],
              [38.666973046018398, 10.025210065854401],
              [38.666402615812999, 10.025738613406601],
              [38.664552086327703, 10.0266298443563],
              [38.663981656122303, 10.026808974846899],
              [38.662191762918702, 10.027578571383501],
              [38.661562942219803, 10.0278196227964],
              [38.6606331859007, 10.0320302534099],
              [38.660711788488101, 10.0332399150039],
              [38.660842044204301, 10.034389863335299],
              [38.660981283073298, 10.035539807582801],
              [38.661183404012199, 10.0373797098833],
              [38.661311413940197, 10.038518586475901],
              [38.661562942219803, 10.039668516057001],
              [38.661641544807097, 10.040880360501401],
              [38.6616123495604, 10.042720232441701],
              [38.661282218693501, 10.0439298540864],
              [38.660943104673699, 10.0450886098845],
              [38.659642793300002, 10.0469992210662],
              [38.657143231021898, 10.050548416736399],
              [38.657282469890902, 10.0523285282414],
              [38.659231814057499, 10.0546592432658],
              [38.660763441616901, 10.056249161611699],
              [38.661183404012199, 10.0568196728243],
              [38.661111538789498, 10.0590486372062],
              [38.663761568877597, 10.0585090877151],
              [38.665661505703603, 10.0574985356289],
              [38.667552459376601, 10.0560987941476],
              [38.668702302940297, 10.055329265441801],
              [38.670083462689597, 10.0547985549217],
              [38.672041790008997, 10.054079883036399],
              [38.673202862513698, 10.053659735655501],
              [38.674471732852602, 10.0533501530252],
              [38.6756822126979, 10.052879144597499],
              [38.676832056261603, 10.0523992902353],
              [38.678222199163798, 10.0520388461675],
              [38.679441662161999, 10.052018944336],
              [38.680652142007297, 10.0521184534808],
              [38.681833426605898, 10.0528503975821],
              [38.682242160060198, 10.0534187034903],
              [38.682882209700097, 10.0535801287217],
              [38.683461623058399, 10.053339096497201],
              [38.684142096886099, 10.052759733901199],
              [38.686441784013503, 10.050939820976399],
              [38.688573037025002, 10.050218929183901],
              [38.689152450383297, 10.049869539331899],
              [38.690481957003797, 10.049849637366901],
              [38.692292062301298, 10.0502896916396],
              [38.693621568921799, 10.050610333822799],
              [38.694791624579402, 10.050709843401201],
              [38.696172784328702, 10.050059713602],
              [38.697383264174, 10.0495201491023],
              [38.697733607134801, 10.0494294844873],
              [38.701082077356403, 10.0487793521133],
              [38.701791746430899, 10.0499181885744],
              [38.702341964542399, 10.0517602204163],
              [38.7030628625579, 10.052419192043301],
              [38.703242525614698, 10.0534894652455],
              [38.704632668516901, 10.0537393425696],
              [38.705443398060801, 10.0534386052356],
              [38.706891931456397, 10.053188727679],
              [38.708163047583497, 10.0528282844915],
              [38.711641773521201, 10.053009611789401],
              [38.713202596327399, 10.0530781623268],
              [38.714082945305798, 10.053378899996201],
              [38.714842021720898, 10.053358998247299],
              [38.7161019089069, 10.0528282844915],
              [38.716443268714798, 10.0521892155207],
              [38.7166723391123, 10.0516098508634],
              [38.718731726901098, 10.050318438882799],
              [38.720133098744398, 10.0504688090363],
              [38.7217231167973, 10.0520587479976],
              [38.722563041587897, 10.0521582571302],
              [38.723521993153703, 10.052149411875201],
              [38.725323115298401, 10.052178158953],
              [38.726322491051903, 10.0534894652455],
              [38.727562166143997, 10.054568580092299],
              [38.729021928480698, 10.0555194364994],
              [38.730302027760601, 10.055908623431099],
              [38.732282812962097, 10.0563995290058],
              [38.7340232988251, 10.0563685710187],
              [38.735182125541598, 10.056178400460899],
              [38.737423422175503, 10.058338819860801],
              [38.738642885173597, 10.0588982710452],
              [38.739343571095297, 10.059059693538799],
              [38.739931967606402, 10.059508580323],
              [38.740513626752801, 10.0598491147086],
              [38.741501773565403, 10.0599088187522],
              [38.741072828017202, 10.062369942499499],
              [38.742361910449901, 10.0629890901747],
              [38.743642009729797, 10.0633097197541],
              [38.746662594872603, 10.0633782681057],
              [38.748573760639601, 10.063128398238],
              [38.749263217620097, 10.0628895843784],
              [38.750473697465502, 10.062469448456],
              [38.752272573821898, 10.0624384910506],
              [38.754022042837803, 10.0633981692372],
              [38.754803577135, 10.065169365035301],
              [38.755942191757597, 10.0675685348561],
              [38.758313744107703, 10.0683689912339],
              [38.759443375577398, 10.068778063478],
              [38.761772257951499, 10.069328651787901],
              [38.762942313609102, 10.069658119845201],
              [38.764164022395498, 10.070160060467099],
              [38.766672567826397, 10.0707482365898],
              [38.766912867164898, 10.071378424102701],
              [38.766924096105903, 10.072068312179001],
              [38.767052106033901, 10.073280035100201],
              [38.770052479082899, 10.075769801851401],
              [38.7714538509261, 10.0762584660707],
              [38.773252727282603, 10.0766984843494],
              [38.773863581675798, 10.0781290923917],
              [38.774683294372501, 10.079270036449801],
              [38.775002196298402, 10.0810499895607],
              [38.775433387634799, 10.0823081119786],
              [38.7774837922708, 10.0837696504826],
              [38.779403941190601, 10.083858094354801],
              [38.7809131108679, 10.084068148454101],
              [38.782433509486303, 10.0849680628868],
              [38.783882042881899, 10.085348369950401],
              [38.785234007384503, 10.0860780275944],
              [38.786294019419799, 10.0872100992129],
              [38.787354031455003, 10.0883488000492],
              [38.788003064247803, 10.088737947373099],
              [38.788703750169397, 10.0891293052871],
              [38.789292146680502, 10.0897594568609],
              [38.789943425261498, 10.0904382503343],
              [38.791113480919101, 10.0909998568821],
              [38.791333568163701, 10.0903586524767],
              [38.7914233996921, 10.0890386518013],
              [38.7926226505964, 10.0872985421405],
              [38.794203685496399, 10.087689901803699],
              [38.794333941212599, 10.089109405743599],
              [38.794403560647197, 10.090259155126899],
              [38.795753279361499, 10.09110819819],
              [38.797543172565099, 10.090679254838999],
              [38.798803059751101, 10.0903188535405],
              [38.8001437953127, 10.0901198587856],
              [38.801533938214902, 10.0899297081271],
              [38.804343419265898, 10.091377945777801],
              [38.805003680999803, 10.092578540906301],
              [38.8054932628296, 10.0944181181251],
              [38.806263568185699, 10.095559004483],
              [38.808653086841502, 10.0960387946308],
              [38.809793947252302, 10.095218507815099],
              [38.810312724328902, 10.0946281653358],
              [38.810593447855197, 10.094059932039],
              [38.810813535099797, 10.0934187337289],
              [38.812843727641898, 10.093389990326401],
              [38.813434369941199, 10.094017922533601],
              [38.816183214710598, 10.0994990111466],
              [38.816603177106003, 10.100118087492699],
              [38.817072546841899, 10.1006885210712],
              [38.817854081139103, 10.1021190225333],
              [38.818152770971103, 10.1032687254331],
              [38.8186423528009, 10.1045798239155],
              [38.819354267663599, 10.1057184632848],
              [38.819363250816401, 10.1063596370771],
              [38.819502489685398, 10.1076198714934],
              [38.821914466223298, 10.109538955814299],
              [38.8238233862021, 10.109737938555901],
              [38.825042849200301, 10.1097290948811],
              [38.8269540149672, 10.1094682063653],
              [38.827652455100598, 10.1092891217531],
              [38.828862934946002, 10.109149833652401],
              [38.830264306789203, 10.109618548925701],
              [38.831153638920497, 10.1107284285919],
              [38.832384330859703, 10.1116879629026],
              [38.833612777010799, 10.1125900109776],
              [38.834264055591703, 10.113158211528599],
              [38.835503730683797, 10.1142879769533],
              [38.837343031228102, 10.1157095743757],
              [38.838544527920597, 10.1158599140152],
              [38.839173348619497, 10.1152187591806],
              [38.839503479486403, 10.1140580445862],
              [38.839723566731003, 10.1133682464973],
              [38.8401031049385, 10.1120991910156],
              [38.840713959331701, 10.1101889656473],
              [38.842854195496102, 10.1095787523724],
              [38.844192685269498, 10.109967874014901],
              [38.8465934328663, 10.1113098978827],
              [38.847673656995397, 10.113598179358201],
              [38.8480329831091, 10.114738997656801],
              [38.8487336690307, 10.116178280078101],
              [38.848924561028603, 10.117938131231201],
              [38.849948640452503, 10.1180928915495],
              [38.849768977395598, 10.118879957157199],
              [38.849375964458901, 10.1206110525331],
              [38.848814517406304, 10.1228882135861],
              [38.848320444, 10.124866898662299],
              [38.847033607355499, 10.128264902549599],
              [38.846782079076, 10.1292686001294],
              [38.848257561930097, 10.1299738395014],
              [38.847738784853497, 10.132211140347501],
              [38.847168354648097, 10.1325692838951],
              [38.847377212951699, 10.133944374539601],
              [38.847947643157099, 10.1358279296427],
              [38.847709589606801, 10.137903806774901],
              [38.846952758979903, 10.1398801884805],
              [38.846173470471001, 10.141277354768601],
              [38.845234730999103, 10.1430834926636],
              [38.844340907291397, 10.144370110998199],
              [38.843323565232097, 10.145431235631801],
              [38.840040222868602, 10.148389101983],
              [38.8383266864642, 10.1494900049012],
              [38.836976967749798, 10.154185379644099],
              [38.835696868469903, 10.1556222743401],
              [38.834755883209802, 10.157076847319299],
              [38.8336779048689, 10.158843105614199],
              [38.833403918707198, 10.1606469338028],
              [38.833549894940901, 10.162813282636501],
              [38.8342550724389, 10.165054775055401],
              [38.834843468949998, 10.169820657367501],
              [38.834944529419502, 10.1734790206393],
              [38.834706475869197, 10.1770842911077],
              [38.834331429238098, 10.179462730508],
              [38.834091129899598, 10.181151500137601],
              [38.834944529419502, 10.1884369797177],
              [38.834847960526403, 10.190112439646599],
              [38.837563118472701, 10.1903754727935],
              [38.8379246903745, 10.1916199039823],
              [38.838263804394302, 10.193441229679699],
              [38.838376093804797, 10.1945066119731],
              [38.838403043263298, 10.1962417186634],
              [38.838412026416201, 10.1972098377792],
              [38.838421009568997, 10.198666431544],
              [38.837071290854603, 10.2000898642347],
              [38.834924317325601, 10.201539813974099],
              [38.8344886344128, 10.201992921913501],
              [38.8334578176243, 10.202985336562101],
              [38.833008659982198, 10.2057128032008],
              [38.832685266479899, 10.209936572911101],
              [38.832606663892498, 10.211795367453],
              [38.832689758056297, 10.2145713828321],
              [38.834841223161803, 10.224205471087201],
              [38.835658690070296, 10.227237750423299],
              [38.838382831169397, 10.2314369232659],
              [38.839454072145699, 10.2330922661387],
              [38.839865051388202, 10.2335453291051],
              [38.840325437971302, 10.234051432824799],
              [38.841014894951897, 10.234809481864399],
              [38.8415044767818, 10.235350944355501],
              [38.842209654279799, 10.2360780496758],
              [38.842822754461203, 10.236610669840999],
              [38.843682891345701, 10.237355452556001],
              [38.844172473175597, 10.2377797790183],
              [38.844834980697598, 10.238336706638799],
              [38.845775965957699, 10.239125685760699],
              [38.848408029740199, 10.242173292563701],
              [38.847666919630797, 10.245105951078999],
              [38.8467169512179, 10.246588844944601],
              [38.8460230026609, 10.2476739387428],
              [38.845479521914001, 10.24852477344],
              [38.844996677448798, 10.249280577930399],
              [38.8443117120446, 10.2505048889813],
              [38.843795180756302, 10.2520739449636],
              [38.843550389841297, 10.2528186913509],
              [38.844208405786901, 10.2546109373489],
              [38.8445767150534, 10.2558109188282],
              [38.844675529734701, 10.256537977217601],
              [38.844772098627701, 10.2572584042485],
              [38.845935416920703, 10.2597887254623],
              [38.847166108859902, 10.2603323551534],
              [38.849003163615897, 10.262009645755001],
              [38.850130549297504, 10.2630173417627],
              [38.851260180767298, 10.2641951928972],
              [38.8521629876278, 10.2654371249916],
              [38.853231982815899, 10.266561931168599],
              [38.8552689127227, 10.269154052767201],
              [38.855994302314599, 10.2703429300766],
              [38.860551006593298, 10.276322605777599],
              [38.860928299012599, 10.277520295146299],
              [38.860984443717904, 10.280063712678499],
              [38.860950756894702, 10.2829827617759],
              [38.860833975907802, 10.285285278062901],
              [38.860005280058203, 10.287771181307299],
              [38.858947513811103, 10.287782229722501],
              [38.857341775240798, 10.2878927138529],
              [38.855700104058997, 10.2881424078452],
              [38.855652942506602, 10.293074376126],
              [38.855657434083, 10.296377779509999],
              [38.855585568860299, 10.3001407446875],
              [38.8553991684389, 10.308046581571],
              [38.8551588691004, 10.3124987752264],
              [38.855491245755502, 10.316005247704799],
              [38.854548014707198, 10.325388791277399],
              [38.853395925355301, 10.3322489632593],
              [38.8530253703006, 10.3352802057264],
              [38.852432482213104, 10.3380065314775],
              [38.8512422144616, 10.340118644397901],
              [38.848410275528401, 10.3450011941518],
              [38.845668168123602, 10.352510451612501],
              [38.844897862767503, 10.354823505297301],
              [38.844565486112401, 10.356915622253799],
              [38.845726558617102, 10.361300848499701],
              [38.845052822154003, 10.361532810899799],
              [38.843833359155802, 10.361550484218499],
              [38.842613896157602, 10.361512928415101],
              [38.841158625397398, 10.361300848499701],
              [38.839878526117502, 10.3610909776088],
              [38.838129057101703, 10.360655771208201],
              [38.836274036040002, 10.360739719698],
              [38.835699114258098, 10.3611506251394],
              [38.8350658019828, 10.361561530042099],
              [38.833799177432198, 10.3622132329495],
              [38.833105228875198, 10.362451822149501],
              [38.831834112748197, 10.3627588950192],
              [38.829920701192997, 10.362959928173799],
              [38.828645093489598, 10.362977601412],
              [38.826729436146202, 10.363118987281601],
              [38.824180466527501, 10.363386294767],
              [38.821577597991798, 10.364115314022699],
              [38.818678285412297, 10.3643296011782],
              [38.817169115734899, 10.364351692629199],
              [38.8153679935903, 10.3642611176702],
              [38.813971113323497, 10.364051248762101],
              [38.812578724633099, 10.364071131085799],
              [38.811368244787801, 10.364720619630701],
              [38.807938926190602, 10.368105007535499],
              [38.807307859703499, 10.368632986423901],
              [38.805773986355902, 10.369194101086901],
              [38.804763381661303, 10.3693597843581],
              [38.803018404221902, 10.3690969001937],
              [38.802432253498999, 10.3688185520143],
              [38.801848348564299, 10.368537994472],
              [38.800035997478602, 10.367760384852],
              [38.799395947838597, 10.3676521379052],
              [38.798641363, 10.3676079554672],
              [38.797480290495301, 10.367680856486601],
              [38.796092393381301, 10.3680453613289],
              [38.794291271236602, 10.3678995594429],
              [38.792009550415003, 10.366836970920501],
              [38.790666569065202, 10.366339916839699],
              [38.789332570868297, 10.366357589887301],
              [38.787522465570802, 10.366878944340099],
              [38.786033507987398, 10.367038001458001],
              [38.784578237227102, 10.3668877808487],
              [38.783181356960299, 10.366677913700499],
              [38.781721594623598, 10.366065984475901],
              [38.778757154186003, 10.3658185614897],
              [38.777474809117898, 10.3655490469431],
              [38.776021784145897, 10.365456263193099],
              [38.774164517295901, 10.3654827728387],
              [38.773219040459402, 10.365686013381],
              [38.771274187869302, 10.366271433771299],
              [38.769836883414698, 10.367155085118201],
              [38.7685747504405, 10.368264064030701],
              [38.768181737503703, 10.369017372167701],
              [38.767728088285203, 10.3697154508175],
              [38.767456347911804, 10.370985684874499],
              [38.7670700723396, 10.3723111410045],
              [38.7664412516407, 10.3728965490085],
              [38.763966393033002, 10.3743700050632],
              [38.762753667399402, 10.3748471647574],
              [38.760793094291799, 10.375737419093699],
              [38.759470325035998, 10.3764465280557],
              [38.758475440858803, 10.3768110226912],
              [38.7575075061402, 10.3771644716292],
              [38.755371761552198, 10.3780017022082],
              [38.7546216682899, 10.378414794043],
              [38.754165773283198, 10.378995772545499],
              [38.752438762149502, 10.3799390319225],
              [38.7511137471054, 10.3806481313612],
              [38.749730341567897, 10.3812423598708],
              [38.747709132178599, 10.3819625014584],
              [38.747078065691497, 10.382433022520299],
              [38.745643007025102, 10.383488931058601],
              [38.745139950465997, 10.3848165432193],
              [38.744926600586098, 10.385971848382299],
              [38.742546065083097, 10.3860624170579],
              [38.741901523866801, 10.385609573417799],
              [38.741254736862203, 10.3850440701951],
              [38.740664094562902, 10.3845360000212],
              [38.739965654429497, 10.3843725337896],
              [38.739332342154199, 10.3846685401461],
              [38.7386451309618, 10.3853113589552],
              [38.737490795821799, 10.3857862929652],
              [38.736855237758199, 10.3859696393899],
              [38.735121489259903, 10.386568275763],
              [38.733839144191798, 10.3862413453397],
              [38.731449625536101, 10.385700142198299],
              [38.7308005927433, 10.3849623372229],
              [38.729466594546402, 10.384980009218699],
              [38.728831036482902, 10.3852760150001],
              [38.727620556637497, 10.385755367051599],
              [38.726984998573997, 10.385993938305599],
              [38.726407831003897, 10.386289943126901],
              [38.7239150060905, 10.386440154421599],
              [38.722522617400102, 10.3865174690304],
              [38.720137590320803, 10.3863208689876],
              [38.719549193809698, 10.3857531580577],
              [38.719192113484198, 10.385183237098699],
              [38.717916505780799, 10.385258343021],
              [38.717402220280597, 10.3858415178032],
              [38.716768908005299, 10.386252390292],
              [38.716193986223502, 10.3866632622408],
              [38.714289557821203, 10.387381182360301],
              [38.713593363476001, 10.387332584742699],
              [38.711664231403297, 10.386552812845199],
              [38.710902909200001, 10.3861618216715],
              [38.709090558114298, 10.385267179010601],
              [38.708385380616299, 10.3847591091992],
              [38.7064540027554, 10.383751803132],
              [38.705870097820799, 10.3835286932351],
              [38.703415451306903, 10.382528010254401],
              [38.702943835782698, 10.381958083416899],
              [38.702759681149502, 10.381385946516],
              [38.702218446190798, 10.3801290088768],
              [38.701302164601003, 10.380058319791001],
              [38.699550449797002, 10.3803388670062],
              [38.698391623080497, 10.3804117650603],
              [38.697749327652303, 10.3801908618138],
              [38.696206471151797, 10.377968566475801],
              [38.694987008153603, 10.3779862388668],
              [38.6921573150087, 10.378887529484199],
              [38.690879461517, 10.3788499757603],
              [38.689424190756696, 10.3786953427322],
              [38.688083455195198, 10.378370613124201],
              [38.685525502423701, 10.378118781767499],
              [38.684153325827197, 10.378494319681201],
              [38.683679464514803, 10.3788919475691],
              [38.683104542732998, 10.3793028291889],
              [38.681132740684298, 10.379444207685999],
              [38.679913277686097, 10.379461879993601],
              [38.678462498502299, 10.3794839703768],
              [38.676526629065002, 10.3781872622442],
              [38.676055013540797, 10.3776173274976],
              [38.6753969975952, 10.376420019338299],
              [38.673919268952801, 10.3746571845959],
              [38.6721091636553, 10.373992253122699],
              [38.671471359803597, 10.3739988803536],
              [38.670191260523701, 10.373903890029901],
              [38.6689134070321, 10.37386412663],
              [38.667026944935401, 10.373431147059501],
              [38.666290326402397, 10.3731505936508],
              [38.6657041756796, 10.372697731318601],
              [38.6652303143672, 10.3721322047546],
              [38.664010851369, 10.3721476683852],
              [38.662389392281199, 10.372459149924],
              [38.661812224711099, 10.372695522232499],
              [38.660601744865801, 10.3732301206268],
              [38.658755706956903, 10.3740032985075],
              [38.6581179031052, 10.374014343892],
              [38.656193262608902, 10.373521719366099],
              [38.655553212968996, 10.3734156834924],
              [38.6536892087545, 10.3730379304001],
              [38.652510169944101, 10.375644638362999],
              [38.650895448220801, 10.3763559565955],
              [38.6496759852227, 10.3764288555777],
              [38.649101063440803, 10.376839740432301],
              [38.648467751165498, 10.3771379629727],
              [38.645909798394001, 10.3768264860906],
              [38.645269748753996, 10.376835322318501],
              [38.644113167825701, 10.377082736597799],
              [38.642370436174602, 10.377104827148999],
              [38.6390938311757, 10.3792785297533],
              [38.638637936168998, 10.3798617156872],
              [38.636841305600797, 10.380058319791001],
              [38.635100819737801, 10.3801400540451],
              [38.634525897956003, 10.380436064407901],
              [38.633950976174098, 10.380959604438299],
              [38.633322155475298, 10.381602430871901],
              [38.631952224667003, 10.383175251488501],
              [38.630728270092398, 10.3829035428742],
              [38.629670503845297, 10.3819404112503],
              [38.6286621449389, 10.380630459120599],
              [38.626158091084399, 10.3799743764809],
              [38.625513549868103, 10.379581168044099],
              [38.623570943066099, 10.3782645788935],
              [38.622870257144498, 10.377546637844199],
              [38.622513176819098, 10.3769767019306],
              [38.621924780307999, 10.376466409592201],
              [38.620756970438599, 10.376079823933299],
              [38.6192478007613, 10.376044478934899],
              [38.6177431226604, 10.376408974038799],
              [38.616411370251697, 10.3766585977134],
              [38.615021227349501, 10.376906012132499],
              [38.613633330235601, 10.377270506232801],
              [38.612299332038702, 10.3773456140552],
              [38.611138259533902, 10.377363286481399],
              [38.609916550747499, 10.377149008246599],
              [38.607223850683397, 10.375633593036],
              [38.606458036903703, 10.375010635966801],
              [38.604355979138802, 10.3742352514993],
              [38.603192660845899, 10.3740209711225],
              [38.601973197847698, 10.3740364346597],
              [38.6006391996508, 10.3740563163494],
              [38.5994197366526, 10.3740717798849],
              [38.597214372630098, 10.3741601429303],
              [38.5951280353827, 10.374418604694901],
              [38.5938569192557, 10.374838328473301],
              [38.593106825993402, 10.375021681315699],
              [38.591359602765799, 10.3747565928343],
              [38.590122173461999, 10.3735659009696],
              [38.588963346745402, 10.3736388006017],
              [38.587110571471896, 10.374067361731999],
              [38.5864143771268, 10.374018762045701],
              [38.5851365236351, 10.374233042424001],
              [38.584191046798601, 10.376062151434599],
              [38.583427478807003, 10.376110750803599],
              [38.582794166531698, 10.376119587051701],
              [38.581554491439697, 10.376479663949199],
              [38.580746007683999, 10.376806604576901],
              [38.580121678561497, 10.3769767019306],
              [38.579290736923703, 10.3773478231086],
              [38.578482253167998, 10.377696853338501],
              [38.576809140951298, 10.377608491291801],
              [38.575924300396402, 10.377367704587799],
              [38.574823864173403, 10.376797768348199],
              [38.572786934266702, 10.3753729232076],
              [38.572115443591798, 10.3750791171237],
              [38.571340646659202, 10.3750238903854],
              [38.570604028126198, 10.3749730817776],
              [38.567608146653697, 10.374723456758399],
              [38.567015258566201, 10.374537894668199],
              [38.566226986904397, 10.374217578896401],
              [38.564955870777297, 10.373930398960299],
              [38.563886875589198, 10.3740960797249],
              [38.562860550377103, 10.3752028249821],
              [38.561456932745699, 10.376126214237599],
              [38.559691743212397, 10.3769126393018],
              [38.559105592489502, 10.376634298072499],
              [38.557704220646301, 10.3759627436109],
              [38.556354501931899, 10.3751166713002],
              [38.554539905058, 10.374107125106001],
              [38.553311458906897, 10.3734930013206],
              [38.551389064198901, 10.3730003759742],
              [38.549300481163399, 10.373026884981201],
              [38.547964237178199, 10.372989330553899],
              [38.5448785241773, 10.372339859158499],
              [38.542944900528198, 10.371157994488801],
              [38.541363865628199, 10.3702588907144],
              [38.539201171581603, 10.369311183941001],
              [38.536512963093898, 10.368080707230501],
              [38.535980711288097, 10.367512963204399],
              [38.535392314776999, 10.3668325526655],
              [38.534976843958098, 10.3662603881136],
              [38.534327811165298, 10.3656374123938],
              [38.5337461520188, 10.365471727153301],
              [38.532470544315402, 10.3656042753527],
              [38.531556508513802, 10.3665365294371],
              [38.527103110492803, 10.3678045672685],
              [38.526463060852798, 10.3676985294584],
              [38.5251874531494, 10.3677162024293],
              [38.522400429980401, 10.3678111946305],
              [38.521762626128698, 10.367877468242099],
              [38.521066431783503, 10.3678863047226],
              [38.519846968785302, 10.3678465405584],
              [38.516936427264703, 10.367424598282801],
              [38.515016278344902, 10.366989400680501],
              [38.513556516008201, 10.3665497842145],
              [38.512267433575502, 10.3657036864654],
              [38.505824267200197, 10.363761850353001],
              [38.5042252659944, 10.3635100072451],
              [38.500616284340502, 10.362867143656899],
              [38.497946042158397, 10.362904699297999],
              [38.496674926031403, 10.3632670005455],
              [38.495347665199098, 10.363859052901899],
              [38.494712107135598, 10.364212516461899],
              [38.492913230779202, 10.364236817067001],
              [38.492383224761497, 10.3636668569238],
              [38.491853218743898, 10.3631013140514],
              [38.491150287034102, 10.3627655224879],
              [38.489814043049002, 10.3626108815153],
              [38.488480044851997, 10.3627434309251],
              [38.487902877282004, 10.362979810566699],
              [38.486750787930099, 10.363514425547001],
              [38.486117475654801, 10.363925327349801],
              [38.485542553873003, 10.364278790835],
              [38.484909241597698, 10.3646874824927],
              [38.484217538828901, 10.365100591896701],
              [38.483647108623501, 10.3656838042454],
              [38.482378238284703, 10.3663907268486],
              [38.481740434432901, 10.3663995633711],
              [38.4810981390048, 10.3662338785338],
              [38.479348669989001, 10.365624157577701],
              [38.478762519266098, 10.365173493499601],
              [38.478174122755, 10.364778057328399],
              [38.477587972032097, 10.364210307315901],
              [38.477001821309202, 10.3639297456458],
              [38.4755353216079, 10.362915745074],
              [38.474881797238702, 10.3617736097811],
              [38.474980611919896, 10.360565195180801],
              [38.475065951871898, 10.3584929852582],
              [38.475452227444102, 10.3571652614652],
              [38.475957529791401, 10.3559502014858],
              [38.476530205784996, 10.3553095316006],
              [38.477325214811501, 10.354149694857901],
              [38.478122469626101, 10.3529876446004],
              [38.478917478652598, 10.351825590037199],
              [38.4802874094609, 10.350139940500201],
              [38.4807410586793, 10.3495014679959],
              [38.481282293638003, 10.346790706944001],
              [38.4812665731206, 10.3455844438864],
              [38.481134071616196, 10.344435617616501],
              [38.481172250015703, 10.343169694568999],
              [38.481037502723098, 10.341791091936001],
              [38.479910117041499, 10.3400788767126],
              [38.478762519266098, 10.3395552683867],
              [38.477857466617301, 10.3386096781433],
              [38.477212925400998, 10.3383335122313],
              [38.476038378166997, 10.337370243629699],
              [38.474580861618499, 10.3329029364111],
              [38.474852601991898, 10.331634757505901],
              [38.4753466753982, 10.3296153819284],
              [38.475387099586001, 10.328464287856701],
              [38.475348921186402, 10.3258196277392],
              [38.475321971727901, 10.3238642882884],
              [38.475216419681999, 10.320528031332399],
              [38.475256843869801, 10.3193216674591],
              [38.474922221426503, 10.316334460821601],
              [38.474567386889198, 10.315762204243301],
              [38.474086788212198, 10.314619897460499],
              [38.473496145912897, 10.313994609787301],
              [38.473082920882199, 10.3134245584588],
              [38.472036383576203, 10.3134400249814],
              [38.470109497291801, 10.3126600693983],
              [38.469525592357101, 10.312379461676199],
              [38.467542561367402, 10.311656950879],
              [38.467068700055101, 10.311089104838301],
              [38.466646491871501, 10.309944571574601],
              [38.466513990367098, 10.308738167145799],
              [38.466147926888802, 10.307593625339701],
              [38.465957034890899, 10.306444660271101],
              [38.4657638971049, 10.305008448043401],
              [38.466037883266502, 10.303855054407499],
              [38.466433141991502, 10.303161248539601],
              [38.467277558358603, 10.301307409444901],
              [38.4679557863981, 10.300089923965499],
              [38.4684678261101, 10.299451349847001],
              [38.469500888686802, 10.298576346532499],
              [38.471337943442798, 10.2971688234804],
              [38.4737454284043, 10.295067470467799],
              [38.474017168777699, 10.2937416884941],
              [38.473767886286403, 10.2925374317057],
              [38.4731031329761, 10.290705626786799],
              [38.471942060471399, 10.290049356333601],
              [38.471120101986401, 10.290097969006601],
              [38.469846740071198, 10.2903476612543],
              [38.467879429599002, 10.290716675099301],
              [38.466662212388997, 10.2907918036137],
              [38.464807191327303, 10.2909884634637],
              [38.4641693874756, 10.2909950924453],
              [38.462253730132197, 10.290964157196701],
              [38.461555289998799, 10.2909729958395],
              [38.460219046013599, 10.290760868345201],
              [38.458512246973797, 10.28911687543],
              [38.457683551124198, 10.2879766817662],
              [38.457423039691797, 10.286140430987601],
              [38.457755416346899, 10.2849847586556],
              [38.458669452148499, 10.28399481035],
              [38.4583527960109, 10.2821585364143],
              [38.457521854373098, 10.2809034116248],
              [38.457330962375202, 10.2796416526283],
              [38.458069826696402, 10.278479327272001],
              [38.4590983976967, 10.2774319049421],
              [38.459554292703402, 10.276850738734],
              [38.460124722908802, 10.276267361693501],
              [38.4606996446906, 10.275798891472199],
              [38.462543436811302, 10.2748553196262],
              [38.4631183585931, 10.274501755679699],
              [38.464989100172303, 10.2713130329708],
              [38.4645062557071, 10.270055655299901],
              [38.463848239761496, 10.2687408174141],
              [38.463077934405298, 10.267600550216599],
              [38.462067329710699, 10.2663917741058],
              [38.4603178606949, 10.2653929282035],
              [38.4578025778994, 10.2640471330518],
              [38.4572186729647, 10.2638239382285],
              [38.455294032468501, 10.2631035261407],
              [38.454348555631903, 10.261965448488199],
              [38.454440632948497, 10.2602373305187],
              [38.454422666642898, 10.2590307361637],
              [38.4545214813241, 10.257764470109199],
              [38.455192971998997, 10.2560296994917],
              [38.455581493359396, 10.2547590016079],
              [38.4560194220604, 10.2529689663741],
              [38.456118236741602, 10.2517601343888],
              [38.455801580604003, 10.250038590564801],
              [38.455253608280699, 10.248206539430999],
              [38.454779746968299, 10.2475789103101],
              [38.453888169048803, 10.2460385617414],
              [38.453059473199197, 10.2448407532332],
              [38.452224039984998, 10.2433578511904],
              [38.450101770126203, 10.2409688394229],
              [38.449517865191602, 10.2407478383499],
              [38.448931714468699, 10.240409706410199],
              [38.448291664828702, 10.2401312445421],
              [38.446310879627298, 10.239695871131399],
              [38.445147561334302, 10.239481499232401],
              [38.443169021921101, 10.239163256146099],
              [38.442463844423003, 10.2385378191503],
              [38.441875447911897, 10.2379720517597],
              [38.441289297189002, 10.237576455992601],
              [38.440642510184503, 10.237010686887499],
              [38.440058605249803, 10.236672550964],
              [38.438832404887002, 10.236113410378399],
              [38.437431033043701, 10.2354990176106],
              [38.436034152776898, 10.2352316016818],
              [38.434868588695799, 10.2349575553721],
              [38.432591359450498, 10.2340094417172],
              [38.431877198799697, 10.232639202525601],
              [38.430747567329902, 10.230813681122299],
              [38.429961541456301, 10.230603723387],
              [38.428297412392503, 10.2299848997806],
              [38.427228417204297, 10.2281571530152],
              [38.427208205110503, 10.2267758380203],
              [38.427131848311298, 10.225513863389301],
              [38.425180258356598, 10.222950116650599],
              [38.424596353421897, 10.222784356451101],
              [38.422676204502103, 10.2223489592484],
              [38.420037403355003, 10.2204880162567],
              [38.4186854388524, 10.219181813419601],
              [38.4178297935443, 10.216085361898701],
              [38.418159924411199, 10.214931644198],
              [38.418499038430902, 10.2141801783101],
              [38.419406336867901, 10.2128452168827],
              [38.419981258649699, 10.2124937939858],
              [38.420776267676203, 10.2111013598222],
              [38.421115381695898, 10.2105222822325],
              [38.421589243008299, 10.209876896806501],
              [38.422480820927802, 10.2086634802516],
              [38.423051251133202, 10.2079650453091],
              [38.4232758299542, 10.207388172254401],
              [38.423954057993697, 10.206112859145801],
              [38.425038773699299, 10.204833120404],
              [38.425874206913498, 10.2024062442032],
              [38.426368280319799, 10.200558446275],
              [38.426873582667099, 10.199344994194799],
              [38.427374393438001, 10.197786729277899],
              [38.428737586881702, 10.195697979239499],
              [38.429815565222597, 10.193956238577501],
              [38.431010324550499, 10.192331636865299],
              [38.4305184969324, 10.190497042661899],
              [38.4286904253293, 10.188277832670201],
              [38.428095291453502, 10.187364945988],
              [38.4261616678045, 10.186067448797001],
              [38.425523863952698, 10.1859613499857],
              [38.424358299871599, 10.1857469418634],
              [38.422611076644003, 10.185136872603101],
              [38.421789118158998, 10.184515750164501],
              [38.420255244811401, 10.1827518442598],
              [38.4200149454729, 10.182179346387001],
              [38.420288931634502, 10.1809680347599],
              [38.421654370866399, 10.1792240031901],
              [38.422923241205197, 10.1785763439189],
              [38.424133721050602, 10.178098887778299],
              [38.4252925477671, 10.177968471393401],
              [38.426449128695403, 10.177667850371099],
              [38.427607955411901, 10.177537433810199],
              [38.428131224064899, 10.173618280769601],
              [38.4276012180473, 10.172992714946],
              [38.426898286337398, 10.172541776277701],
              [38.426253745121102, 10.1720908369726],
              [38.424964662688403, 10.171186745957399],
              [38.423673334467402, 10.170225179355],
              [38.422388743611201, 10.1695509757579],
              [38.420279948481699, 10.1682556166563],
              [38.418820186144998, 10.1674708829051],
              [38.417474959007002, 10.166741410365301],
              [38.416248758644201, 10.166182146956899],
              [38.4135695333093, 10.165527829631801],
              [38.411525866037898, 10.1645750881578],
              [38.410465854002702, 10.163438868394101],
              [38.408406466213798, 10.1615112645365],
              [38.407121875357497, 10.1608370425534],
              [38.405311770060003, 10.160114186585499],
              [38.404310148518199, 10.1589757403865],
              [38.402834665664102, 10.157213904006699],
              [38.402475339550399, 10.1565286199823],
              [38.401691559465, 10.154410862008],
              [38.401675838947597, 10.153259131795799],
              [38.401484946949701, 10.152054342395999],
              [38.400993119331602, 10.1500470868626],
              [38.400972907237701, 10.1487251209458],
              [38.400775277875198, 10.1471157637649],
              [38.399531111206699, 10.1452366963789],
              [38.3988843242022, 10.144611075025701],
              [38.398123001998897, 10.144160096330999],
              [38.397482952358899, 10.143939028111401],
              [38.396032173175101, 10.143958924257401],
              [38.394810464388698, 10.143687010154901],
              [38.393123877442797, 10.1434769950397],
              [38.391902168656301, 10.1431498133226],
              [38.390743341939803, 10.143335511095],
              [38.389297054332403, 10.1435853186427],
              [38.387374659624399, 10.1430348575048],
              [38.386788508901503, 10.1425838765905],
              [38.377888450224098, 10.1408595319445],
              [38.374566929461103, 10.1398094457216],
              [38.373347466462903, 10.1398249207014],
              [38.372071858759398, 10.139840395680499],
              [38.369520643352502, 10.1398757670585],
              [38.368069864168703, 10.139950931223799],
              [38.3668548927469, 10.140256009125499],
              [38.365527631914603, 10.140673832736701],
              [38.3642542699994, 10.1409214316565],
              [38.363445786243702, 10.141160187576601],
              [38.360009730281902, 10.141350307904199],
              [38.3586914526025, 10.142110788086001],
              [38.358121022397, 10.142630301125701],
              [38.357721272095603, 10.143209501904099],
              [38.357290080759199, 10.145230064356801],
              [38.3572519023597, 10.146379612797],
              [38.357321521794198, 10.1475291571097],
              [38.357350717040902, 10.1494900049012],
              [38.357240673418602, 10.150179725281999],
              [38.35684990627, 10.1515105277131],
              [38.3565197754031, 10.152140556834],
              [38.355720274800198, 10.1533608202348],
              [38.355259888217098, 10.153940001596901],
              [38.354631067518199, 10.1545302349598],
              [38.353490207107399, 10.155580272894399],
              [38.352861386408499, 10.1561594502381],
              [38.352351592484801, 10.1568005231742],
              [38.350631318715699, 10.158610994630299],
              [38.350051905357397, 10.1589602663325],
              [38.349391643623598, 10.158969108649099],
              [38.347619716725703, 10.1589293182224],
              [38.346310422199103, 10.158829842133899],
              [38.345531133690102, 10.158670680327999],
              [38.343200005527798, 10.1581799309276],
              [38.341971559376802, 10.1575101230982],
              [38.341331509736797, 10.156999476582699],
              [38.338310924593998, 10.155080676328],
              [38.337459770862303, 10.154629712385599],
              [38.335420595167299, 10.1535111422158],
              [38.3339496038896, 10.1525495224416],
              [38.332831201360897, 10.151240830657599],
              [38.3322899664022, 10.1500404549402],
              [38.3319912765702, 10.1494104216846],
              [38.331441058458701, 10.146830588365599],
              [38.3314208463648, 10.145570507978899],
              [38.3313108027425, 10.1438196512094],
              [38.3309604597817, 10.1416598058705],
              [38.330780796724902, 10.141089445100301],
              [38.330239561766199, 10.140519083314601],
              [38.329781420971301, 10.139740913659001],
              [38.326980923073002, 10.1348109881214],
              [38.325610992264799, 10.132810256797599],
              [38.324959713683803, 10.131439582827101],
              [38.3241197888931, 10.129960574825301],
              [38.3231608373273, 10.127320893369401],
              [38.323131642080597, 10.1254792936938],
              [38.323230456761799, 10.1242699781927],
              [38.323511180288101, 10.1230606581339],
              [38.323720038591702, 10.1219110260647],
              [38.323940125836302, 10.121221244846501],
              [38.324550980229503, 10.1202705824048],
              [38.325310056644597, 10.1193000194727],
              [38.3259815473195, 10.117509223673199],
              [38.326381297620898, 10.1169299764742],
              [38.326711428487798, 10.1155393367579],
              [38.327030330413699, 10.1136998803787],
              [38.327189781376603, 10.112950387319099],
              [38.323870506401803, 10.107759160257199],
              [38.323291093043501, 10.107529223217799],
              [38.321431580405402, 10.107040606463],
              [38.320780301824399, 10.1068195126647],
              [38.319560838826199, 10.1067200204059],
              [38.318231332205698, 10.106680223493701],
              [38.317009623419302, 10.1067509735564],
              [38.315680116798802, 10.107060504897399],
              [38.314521290082297, 10.107190950159],
              [38.313250173955304, 10.107259489173501],
              [38.311860031053101, 10.107449629589],
              [38.3105305244326, 10.107699465080101],
              [38.3092594083056, 10.1080001517853],
              [38.3078715111916, 10.1080797452779],
              [38.306649802405197, 10.107980253409099],
              [38.3041300280332, 10.1061694960169],
              [38.3030093797163, 10.104750067339699],
              [38.302531026827502, 10.1041796409658],
              [38.301650677849103, 10.102919393063701],
              [38.3011610960192, 10.101659140222401],
              [38.300981432962402, 10.100920673982101],
              [38.3005906658138, 10.0978208660591],
              [38.300521046379302, 10.097131033128701],
              [38.300321171228603, 10.095289260399101],
              [38.300240322853, 10.093739333043599],
              [38.299991040361697, 10.092419346229599],
              [38.299869767798299, 10.0917891998619],
              [38.298511065931102, 10.090080059819799],
              [38.297349993426302, 10.0898103111446],
              [38.296770580068099, 10.0896113160755],
              [38.294201398355497, 10.088930309797],
              [38.292921299075601, 10.088541162705599],
              [38.291760226570901, 10.088439453729199],
              [38.287809885108999, 10.088320056194201],
              [38.286651058392501, 10.088331111523299],
              [38.285260915490298, 10.0885212631258],
              [38.284690485284898, 10.088930309797],
              [38.283419369157897, 10.0895803612759],
              [38.282269525594202, 10.0901707130124],
              [38.2809512479147, 10.0908804602964],
              [38.279280381486302, 10.091760456313899],
              [38.277829602302397, 10.092180554066999],
              [38.2751099527798, 10.0926205505995],
              [38.272439710597702, 10.092140755356001],
              [38.270050191941998, 10.0914199556276],
              [38.269349506020298, 10.090971113263601],
              [38.267299101384303, 10.0897904116433],
              [38.265329545123898, 10.0896400598153],
              [38.264049445844002, 10.089421165116701],
              [38.262760363411303, 10.088919254488401],
              [38.2613702205092, 10.088541162705599],
              [38.259389435307703, 10.0882205582145],
              [38.2581093360278, 10.0878314102654],
              [38.256759617313399, 10.0868695937148],
              [38.252881141074198, 10.086980147487401],
              [38.252710461170203, 10.0875506043509],
              [38.252090623624198, 10.088711414616],
              [38.2517290517223, 10.090420561923199],
              [38.251080018929599, 10.0944910819086],
              [38.250830736438203, 10.096379290430701],
              [38.250981204248298, 10.097250427395601],
              [38.251814391674301, 10.098198946941601],
              [38.250502851359499, 10.0995299649922],
              [38.2491351663394, 10.101329704893599],
              [38.2479808311993, 10.1018050644885],
              [38.247289128430602, 10.102158820010001],
              [38.246655816155297, 10.1024550899599],
              [38.244692997259499, 10.1031692320754],
              [38.243305100145498, 10.103359374909999],
              [38.2419711019486, 10.103317366619001],
              [38.2406932484569, 10.103335054321199],
              [38.239233486120199, 10.1026629209559],
              [38.2378860131941, 10.101701148729999],
              [38.237304354047602, 10.101477840284501],
              [38.234555509278202, 10.099788650585401],
              [38.233967112767097, 10.0992204264021],
              [38.2320896338233, 10.0976351419541],
              [38.229688886226498, 10.0959392990368],
              [38.228402049582002, 10.0952074527225],
              [38.227238731288999, 10.0949907728297],
              [38.226598681649101, 10.094884643849401],
              [38.224849212633302, 10.094216914878899],
              [38.222861690067198, 10.093034014129699],
              [38.221042601616801, 10.0914464881616],
              [38.218495877786303, 10.087738545344401],
              [38.21785133657, 10.087172510961301],
              [38.215978349202601, 10.0858723969711],
              [38.215336053774401, 10.085593800433299],
              [38.214172735481498, 10.085379325156801],
              [38.212138051362999, 10.085058717518301],
              [38.210745662672601, 10.0850764062239],
              [38.208358389804999, 10.084415290191499],
              [38.207772239082203, 10.0839620158737],
              [38.207129943654003, 10.0835684405828],
              [38.206487648225902, 10.0832898420527],
              [38.204446226742697, 10.0824496228044],
              [38.203860076019801, 10.0819985568305],
              [38.202858454477997, 10.0808620446654],
              [38.201095510732898, 10.0790997795502],
              [38.2004487237284, 10.078533729990699],
              [38.199918717710702, 10.077907978735],
              [38.198917096168898, 10.076711751222801],
              [38.197091270354001, 10.0747792042387],
              [38.195977359401702, 10.073872627863899],
              [38.193482288699997, 10.073731113273],
              [38.1917418028371, 10.073753224932],
              [38.190468440921798, 10.0726255283942],
              [38.1883371879102, 10.0707482365898],
              [38.187807181892602, 10.0701799611805],
              [38.185574868411599, 10.068021832219999],
              [38.184970751382998, 10.0674889313509],
              [38.183755779961203, 10.066491674663199],
              [38.182812548912899, 10.0652954048153],
              [38.180357902399003, 10.064001836312499],
              [38.179136193612599, 10.0637298545831],
              [38.177858340120999, 10.063515364764999],
              [38.176636631334603, 10.063300874804501],
              [38.175412676759997, 10.062913908020301],
              [38.174013550704899, 10.0621841979655],
              [38.171549921038299, 10.0602007050287],
              [38.171078305514101, 10.059632411050201],
              [38.170179990229997, 10.0575715077673],
              [38.169883546186199, 10.057000997882399],
              [38.169690408400101, 10.055738354206101],
              [38.169672442094402, 10.0543585067878],
              [38.169888037762597, 10.0531467128496],
              [38.169977869291003, 10.0512494059153],
              [38.169789223081402, 10.0500442343051],
              [38.168174501358202, 10.0464972444531],
              [38.166458719165497, 10.0456436612868],
              [38.163739069642801, 10.0449824644879],
              [38.162061465849703, 10.0446750849138],
              [38.1608397570633, 10.0445180779683],
              [38.158447992619401, 10.0434544091897],
              [38.156451486900401, 10.0416388666673],
              [38.156155042856597, 10.0410683286674],
              [38.155800208319398, 10.0404955782681],
              [38.155618299474398, 10.039922826854999],
              [38.1552050744437, 10.0393522858295],
              [38.154733458919502, 10.0387839552034],
              [38.153963153563403, 10.037642868131501],
              [38.152368643934103, 10.035477887612],
              [38.151784738999403, 10.0351970361671],
              [38.151200834064703, 10.0349736816944],
              [38.149287422509602, 10.034940510225001],
              [38.148070205299597, 10.0349559902445],
              [38.146855233877801, 10.035143961850901],
              [38.146275820519499, 10.035323087633101],
              [38.145642508244201, 10.0355597104281],
              [38.145009195968903, 10.035971035622699],
              [38.144670081949201, 10.0365504282899],
              [38.144445503128203, 10.037127608503599],
              [38.144113126473002, 10.0382819658437],
              [38.1438391403114, 10.039436319067001],
              [38.143057606014203, 10.041747224545899],
              [38.142718491994401, 10.0423841027722],
              [38.142154799153701, 10.0435981484182],
              [38.140609696864999, 10.0452279256646],
              [38.138662598486597, 10.047092097710999],
              [38.138027040423097, 10.047215933195799],
              [38.136118120444401, 10.0475830166758],
              [38.134903149022598, 10.047713486005099],
              [38.132120617429997, 10.047806362444801],
              [38.130955053348899, 10.047417165757601],
              [38.129674954069003, 10.0470876750142],
              [38.127341580118497, 10.0461390051602],
              [38.126811574100898, 10.045455695779101],
              [38.1260412687448, 10.044257136679001],
              [38.125684188419299, 10.043686603296299],
              [38.12408967879, 10.0414044597039],
              [38.121462106583998, 10.035858252960001],
              [38.1211656625402, 10.035230207610301],
              [38.1208669727082, 10.034657446880599],
              [38.120327983537798, 10.033284143861399],
              [38.119135469998099, 10.030884719618401],
              [38.118239400502198, 10.028938629758899],
              [38.117536468792402, 10.027114159899099],
              [38.1172040921372, 10.025154778403699],
              [38.116945826493001, 10.023204231109199],
              [38.117051378538903, 10.022454527836],
              [38.117543206157002, 10.020320407257501],
              [38.117893549117802, 10.019811754684],
              [38.118904153812402, 10.0179452140574],
              [38.119043392681498, 10.015357693290101],
              [38.117882320176697, 10.015253749717001],
              [38.1157712792591, 10.014729608213299],
              [38.114861735033898, 10.014831340385699],
              [38.1134760837081, 10.015366539550101],
              [38.112501411624898, 10.0161273170082],
              [38.111297669144101, 10.0171755946524],
              [38.110724993150498, 10.0176444434729],
              [38.108313016612698, 10.019398197438401],
              [38.107313640859097, 10.0193760820629],
              [38.106397359269302, 10.019307524388999],
              [38.105180142059297, 10.0193782936005],
              [38.104021315342798, 10.019508774293699],
              [38.101593618287403, 10.019999735075899],
              [38.098867231400099, 10.019915696796501],
              [38.0944070960145, 10.020200984551],
              [38.093710901669297, 10.020207619147],
              [38.090232175731501, 10.0203093496013],
              [38.0890711032268, 10.0203226187887],
              [38.087680960324597, 10.0203403110376],
              [38.085650767782496, 10.020249638251601],
              [38.085066862847903, 10.019968773606999],
              [38.082270856526002, 10.019081947424199],
              [38.081686951591401, 10.018630793023901],
              [38.079694937448799, 10.0172751145059],
              [38.079398493405101, 10.016702322042599],
              [38.079438917592803, 10.0154373096215],
              [38.0796500216846, 10.0137078615821],
              [38.080379902852997, 10.0122836033342],
              [38.081837419401403, 10.0086145614501],
              [38.083532989500199, 10.006387461552899],
              [38.082342721748802, 10.0023622770128],
              [38.081186140820499, 10.002010623906401],
              [38.080025068315699, 10.002116783374801],
              [38.077649024389203, 10.0023888168545],
              [38.076431807179297, 10.002379970240799],
              [38.074637422399199, 10.0021964029534],
              [38.073478595682701, 10.0021322649611],
              [38.072319768966203, 10.0018956177764],
              [38.070525384186197, 10.0017098385573],
              [38.069020706085297, 10.001311739873],
              [38.066480719619399, 10.000192637625201],
              [38.065903552049399, 9.99978569039769],
              [38.065384774972799, 9.999210655402949],
              [38.063772299037801, 9.997706712913841],
              [38.063370302948201, 9.99713167424059],
              [38.062741482249301, 9.99586437388071],
              [38.062397876653101, 9.995287120246569],
              [38.061784776471697, 9.99155374632387],
              [38.061557951862397, 9.99097869676805],
              [38.0617353691311, 9.99034835281762],
              [38.061912786399702, 9.989775512869841],
              [38.062153085738203, 9.988627618200891],
              [38.062512411851799, 9.986851580510271],
              [38.062689829120401, 9.986221228563011],
              [38.0631030541511, 9.98501802707036],
              [38.061843166965197, 9.982697870456491],
              [38.061382780381997, 9.98231080744795],
              [38.060805612811997, 9.98196134445063],
              [38.059651277671897, 9.981381854349779],
              [38.058494696743601, 9.98120048700703],
              [38.057277479533603, 9.981193851614529],
              [38.055422458471902, 9.98118058082912],
              [38.053681972608899, 9.981399548719271],
              [38.053100313462501, 9.98179545998515],
              [38.052460263822503, 9.98219358256029],
              [38.0512969455296, 9.982817306949061],
              [38.0495587054548, 9.983093780143349],
              [38.048404370314699, 9.98216704107042],
              [38.047485842936702, 9.981014695965809],
              [38.046915412731302, 9.979804839806629],
              [38.046068750575998, 9.97635661450026],
              [38.045675737639201, 9.974631382236209],
              [38.045103061645598, 9.97330648612866],
              [38.044541614593001, 9.970893347657031],
              [38.044195763208599, 9.970316049736921],
              [38.043045919645003, 9.969101730084731],
              [38.042468752074903, 9.968756676680609],
              [38.0381927713225, 9.967119879916449],
              [38.035816727396004, 9.967219415089501],
              [38.034657900679498, 9.96715305831084],
              [38.034020096827803, 9.96732116212385],
              [38.032800633829602, 9.967599860359689],
              [38.031639561324802, 9.967823261154489],
              [38.029840684968399, 9.968327571296721],
              [38.028102444893598, 9.96848903885282],
              [38.027260274314798, 9.968482403201399],
              [38.025726400967102, 9.96847355566598],
              [38.023932016187103, 9.96823024834757],
              [38.022775435258801, 9.967821049266179],
              [38.020403882908703, 9.967232686443589],
              [38.018609498128697, 9.96693408084551],
              [38.017450671412199, 9.96686772400879],
              [38.016233454202201, 9.966858876429541],
              [38.013803511358702, 9.966558058592209],
              [38.010915427720199, 9.96527515587627],
              [38.009758846791897, 9.96480844346374],
              [38.008548366946499, 9.964111691462829],
              [38.007333395524803, 9.96364497738529],
              [38.005601892814603, 9.96277126914142],
              [38.003931026386198, 9.96132688050715],
              [38.0034144950978, 9.960747353713741],
              [38.001516804060103, 9.95849780675122],
              [38.001289979450902, 9.957920486888399],
              [37.999973947559603, 9.9555603218852],
              [37.999747122950403, 9.954927697437689],
              [37.999495594670798, 9.954202168589999],
              [37.999237329026599, 9.95320235180429],
              [37.998606262539603, 9.952049904321481],
              [37.998204266449903, 9.95147257305522],
              [37.994743506817798, 9.949152177709831],
              [37.994107948754298, 9.94909245330315],
              [37.9929491220378, 9.949028304854179],
              [37.991790295321302, 9.94901945679127],
              [37.987559230333098, 9.94939328724057],
              [37.985818744470102, 9.9498401136068],
              [37.984657671965401, 9.94994850208866],
              [37.982281628038898, 9.95014094685558],
              [37.980487243258899, 9.949919745964319],
              [37.979272271837097, 9.94950831190812],
              [37.978059546203497, 9.948984064537211],
              [37.976846820570003, 9.94863235380412],
              [37.9756902396417, 9.948163405570931],
              [37.9738419559446, 9.947347168597171],
              [37.9721104532345, 9.94636060302877],
              [37.970783192402202, 9.9456638116507],
              [37.969628857262101, 9.94496701878675],
              [37.968910205034803, 9.944478156874681],
              [37.966435346426998, 9.943250468758089],
              [37.964205278734198, 9.94171529911107],
              [37.963050943594098, 9.941250765088],
              [37.960681637032202, 9.940315058837591],
              [37.958314576258601, 9.939264321676321],
              [37.956987315426296, 9.938510000916271],
              [37.955830734498001, 9.93810076458518],
              [37.954498982089298, 9.937976887540749],
              [37.9533401553728, 9.93791273691],
              [37.952181328656302, 9.93807421950819],
              [37.950380206511603, 9.93875111829858],
              [37.949160743513403, 9.93920238338038],
              [37.94724508617, 9.939649223681011],
              [37.945794306986201, 9.940213303386489],
              [37.944572598199798, 9.94083710804518],
              [37.943350889413402, 9.941516213182011],
              [37.942769230266897, 9.94185687125351],
              [37.941605911974001, 9.94265100135341],
              [37.940325812694098, 9.943447341577359],
              [37.939744153547601, 9.943730484300509],
              [37.9392186391064, 9.94429898105861],
              [37.938057566601699, 9.94463521213884],
              [37.936263181821701, 9.94422155937929],
              [37.9339926899411, 9.9427416957546],
              [37.933381835547799, 9.942135591229761],
              [37.932923694753001, 9.94155824244381],
              [37.932636233861999, 9.9409831047083],
              [37.932178093067101, 9.94040796596094],
              [37.931776096977501, 9.939773100091889],
              [37.931129309972903, 9.937810980711291],
              [37.929988449562103, 9.935930697428519],
              [37.929997432714899, 9.93478040114986],
              [37.930008661655997, 9.93363010082621],
              [37.930428624051302, 9.931860400123121],
              [37.930668923389803, 9.93071008953344],
              [37.931198929407401, 9.92894037303871],
              [37.931847962200202, 9.927221526733311],
              [37.932258941442697, 9.926650788126359],
              [37.932607038615302, 9.926020319903939],
              [37.9332470882552, 9.92540091134525],
              [37.933777094272898, 9.924881049686221],
              [37.934066800952003, 9.92431030699948],
              [37.934307100290503, 9.923730714570899],
              [37.9351268129872, 9.922531707130791],
              [37.935297492891202, 9.92196096034975],
              [37.935427748607403, 9.92017128666958],
              [37.935436731760298, 9.919040844980531],
              [37.934987574118203, 9.9173108855686],
              [37.934587823816798, 9.916731280756361],
              [37.931488636086598, 9.91503006834586],
              [37.929968237468202, 9.9145212514752],
              [37.928697121341202, 9.91417171594988],
              [37.925218395403398, 9.91395049074127],
              [37.9246367362569, 9.91431108775466],
              [37.923358882765299, 9.914760174278049],
              [37.922137173978903, 9.91515174182836],
              [37.921557760620601, 9.91550127630863],
              [37.919927318379997, 9.91721133519666],
              [37.919278285587197, 9.9184103621094],
              [37.917068429988198, 9.920520815795699],
              [37.914907981729897, 9.92291220443227],
              [37.9138479696947, 9.92422181967215],
              [37.911858201340301, 9.92731222541256],
              [37.911447222097898, 9.927880750707629],
              [37.9108588255868, 9.92903107124169],
              [37.910337802721997, 9.93012165986088],
              [37.910036867101802, 9.9307410594869],
              [37.908878040385297, 9.931590519924759],
              [37.907716967880603, 9.93193118833503],
              [37.906497504882402, 9.932041794885469],
              [37.9045885849036, 9.9319710066975],
              [37.903427512398899, 9.93144009479956],
              [37.902277668835197, 9.93080078724601],
              [37.901067188989899, 9.93016147844353],
              [37.899908362273301, 9.92958189637374],
              [37.898697882428003, 9.92894037303871],
              [37.897548038864301, 9.928590852899431],
              [37.8963285758661, 9.928230271605139],
              [37.895118096020802, 9.92800020725887],
              [37.893377610157799, 9.92781217377887],
              [37.891648353235901, 9.92688085342097],
              [37.891066694089403, 9.92648045121755],
              [37.890498509672199, 9.926071199840599],
              [37.883687034030402, 9.92223084848645],
              [37.881888157673899, 9.921761862401469],
              [37.880738314110197, 9.921580461943231],
              [37.879577241605503, 9.921520732502071],
              [37.878937191965598, 9.92151188369505],
              [37.878366761760198, 9.921160143422229],
              [37.8777267121202, 9.920702216840739],
              [37.8765274612159, 9.91880192529845],
              [37.8760670746328, 9.917481227246],
              [37.875846987388201, 9.916271135748],
              [37.875568509650101, 9.915061039782049],
              [37.875516856521301, 9.9138509393487],
              [37.875007062597597, 9.91213201386948],
              [37.874667948577802, 9.911550187631111],
              [37.873637131789302, 9.909771518508091],
              [37.871627151341102, 9.90740215696894],
              [37.869197208497503, 9.907211899370999],
              [37.867977745499303, 9.90777161036138],
              [37.8673983321411, 9.90794195699498],
              [37.865058220825901, 9.910740496166881],
              [37.864768514146803, 9.911311262488949],
              [37.863537822207597, 9.91245057988961],
              [37.8633581591507, 9.913660685492101],
              [37.864177871847502, 9.914631863905569],
              [37.862956163061099, 9.91541499884498],
              [37.861224660350899, 9.914313300005039],
              [37.860196089350602, 9.912583315639971],
              [37.859681803850499, 9.91143293735162],
              [37.858720606496497, 9.9082693762501],
              [37.858734081225698, 9.906373437246829],
              [37.858743064378601, 9.905169941692749],
              [37.858754293319599, 9.903448758394971],
              [37.859001330022799, 9.90155500387376],
              [37.859005821599197, 9.90092448738549],
              [37.859360656136403, 9.89977849291285],
              [37.859428029782698, 9.898574973175551],
              [37.859097898915799, 9.89616128334873],
              [37.858522977134001, 9.89564137533516],
              [37.856784737059201, 9.895455535675319],
              [37.854871325504, 9.89584491388956],
              [37.853710252999299, 9.8961236730094],
              [37.852493035789301, 9.896344910237691],
              [37.849939574594202, 9.896957736581831],
              [37.849036767733701, 9.89776082496939],
              [37.847617429584801, 9.89763029558552],
              [37.846977379944803, 9.897911265550819],
              [37.8452368940818, 9.89858824731437],
              [37.844078067365302, 9.898579397888559],
              [37.843500899795302, 9.898289579061339],
              [37.843040513212202, 9.89765463157627],
              [37.842180376327597, 9.896442254570919],
              [37.84143702043, 9.8952320854689],
              [37.841037270128602, 9.894656866416421],
              [37.8407498092377, 9.89407943396982],
              [37.8383513074291, 9.889528522808391],
              [37.837717995153803, 9.889066127237969],
              [37.835934839314803, 9.88727406170409],
              [37.835359917532898, 9.88675192719203],
              [37.834782749962898, 9.88640457453609],
              [37.834147191899397, 9.88605500907232],
              [37.833511633835897, 9.88582049026172],
              [37.831490424446599, 9.88505940919573],
              [37.830107018908997, 9.88418991617073],
              [37.827863476487003, 9.88216330263862],
              [37.827578261384303, 9.881588060714311],
              [37.827293046281497, 9.880955293435591],
              [37.827066221672297, 9.88038004939864],
              [37.826325111562902, 9.87865209876183],
              [37.825592984606303, 9.87595063662469],
              [37.824434157889797, 9.87582673610944],
              [37.823856990319797, 9.87553689722195],
              [37.822172649162098, 9.873100912973831],
              [37.821325987006801, 9.87031090342029],
              [37.8203063991593, 9.86823110296581],
              [37.818976892538799, 9.86799214632774],
              [37.817647385918299, 9.867640348739529],
              [37.816486313413598, 9.867290763342581],
              [37.8147570564917, 9.866702220000761],
              [37.812526988798801, 9.866270768386739],
              [37.809957807086199, 9.86592118153634],
              [37.808796734581499, 9.865801702401161],
              [37.807527864242701, 9.86556053142219],
              [37.806366791738, 9.865261833175991],
              [37.805216948174298, 9.86485029292654],
              [37.8039480778355, 9.86427059557413],
              [37.802097548350197, 9.862931977562351],
              [37.800366045640097, 9.86154246418875],
              [37.799216202076401, 9.86096054840816],
              [37.796727868739403, 9.860312253191861],
              [37.795578025175701, 9.860301190122501],
              [37.794237289614202, 9.860292339666749],
              [37.792447396410601, 9.8604007577334],
              [37.791167297130698, 9.860500325314231],
              [37.7894762186083, 9.860141881882489],
              [37.788227560363403, 9.85973033523948],
              [37.7864870745004, 9.85897140648061],
              [37.784757817578502, 9.858161585528419],
              [37.783486701451501, 9.85769029537011],
              [37.782907288093199, 9.85746239425381],
              [37.781175785383098, 9.85675213830814],
              [37.7800169586666, 9.85669239708408],
              [37.778867115102898, 9.85668133389326],
              [37.777126629239902, 9.85690038500257],
              [37.775907166241701, 9.857181389747931],
              [37.774166680378698, 9.856931361915381],
              [37.773546842832701, 9.85646228263843],
              [37.772096063648803, 9.854970960127201],
              [37.771417835609299, 9.85324066354052],
              [37.771186519423701, 9.85266094577447],
              [37.770966432179002, 9.852092290334831],
              [37.770447655102501, 9.85151035787797],
              [37.768716152392301, 9.850921786364269],
              [37.768136739034098, 9.85075141020384],
              [37.766407482112101, 9.85010088223661],
              [37.7652464096074, 9.850151773926539],
              [37.763447533250996, 9.85077132430505],
              [37.762167433971101, 9.85134219469548],
              [37.760826698409502, 9.852132118374209],
              [37.759497191789002, 9.8525215478391],
              [37.758327136131498, 9.8529707187533],
              [37.757687086491501, 9.853481843532419],
              [37.755416594610899, 9.855471018575351],
              [37.755005615368503, 9.856050731402529],
              [37.754715908689299, 9.85662159265636],
              [37.754127512178201, 9.85713049914251],
              [37.753545853031802, 9.857701358527089],
              [37.7529664396735, 9.857982362589841],
              [37.7517469766753, 9.858491266975779],
              [37.749957083471699, 9.857792076400299],
              [37.749377670113397, 9.85755089956041],
              [37.747767439966701, 9.855931248689449],
              [37.747197009761202, 9.8547806722],
              [37.747147602420597, 9.85358141320242],
              [37.747275612348602, 9.852430828516191],
              [37.747515911687103, 9.8504814633799],
              [37.748346853324897, 9.84859182942923],
              [37.748465880100099, 9.847392547937201],
              [37.748486092194, 9.845671062459671],
              [37.748497321134998, 9.844460706755889],
              [37.7479156619885, 9.843312303000729],
              [37.7458472910469, 9.841630625555871],
              [37.744636811201502, 9.84128101259085],
              [37.742907554279597, 9.840522041399449],
              [37.741117661075997, 9.839931237544789],
              [37.739837561796101, 9.83988255703024],
              [37.737466009446003, 9.840192342000369],
              [37.734497077432003, 9.8413717349781],
              [37.732576928512202, 9.843420726647411],
              [37.7319975151539, 9.843772550071989],
              [37.730885849989797, 9.8447904018937],
              [37.730306436631601, 9.845241796045091],
              [37.7297157943323, 9.84570204042676],
              [37.728556967615802, 9.846151220622881],
              [37.726127024772197, 9.845730805679301],
              [37.724285478439803, 9.84451159931543],
              [37.722556221517799, 9.84335213209958],
              [37.720186914956003, 9.842590952935041],
              [37.718417233846303, 9.84252235821485],
              [37.716595899607697, 9.842502443616],
              [37.715437072891199, 9.84244048707856],
              [37.714276000386498, 9.842661760373201],
              [37.712486107182897, 9.84299145730718],
              [37.711327280466399, 9.84292064993973],
              [37.710166207961599, 9.84291179901772],
              [37.708555977814797, 9.841581945291781],
              [37.706215866499697, 9.84029191567908],
              [37.705387170650098, 9.8390926040415],
              [37.704237327086403, 9.838510648670569],
              [37.702505824376303, 9.83706128768293],
              [37.701236954037498, 9.836651925031211],
              [37.699965837910398, 9.83635098783949],
              [37.6988070111939, 9.836742648689659],
              [37.696067149577402, 9.839422304538189],
              [37.695126164317301, 9.840672508129931],
              [37.694596158299603, 9.84182092106483],
              [37.694115559622603, 9.84382123001317],
              [37.693527163111497, 9.845662211611391],
              [37.6915261658161, 9.84937069625599],
              [37.689486990121203, 9.85056111984272],
              [37.6882652813348, 9.851362108761039],
              [37.687277134522297, 9.85250163384352],
              [37.686396785543799, 9.85352167140403],
              [37.686035213642, 9.854842626420909],
              [37.68573652381, 9.856101622174579],
              [37.685505207624303, 9.856672483340329],
              [37.684975201606697, 9.85787173109766],
              [37.684445195589099, 9.85913071528624],
              [37.683856799078001, 9.860391907280309],
              [37.681586307197399, 9.861980559801941],
              [37.680366844199199, 9.86237219023592],
              [37.679156364353801, 9.862562473781621],
              [37.676656802075797, 9.86257132417642],
              [37.675495729570997, 9.862622213941901],
              [37.674166222950497, 9.86238104063583],
              [37.669265913075698, 9.86028127659671],
              [37.6686258634357, 9.85981220208574],
              [37.6668359702321, 9.859281173531979],
              [37.665326800554801, 9.85867270226257],
              [37.664466663670197, 9.859212582281421],
              [37.663305591165503, 9.859602003385501],
              [37.661906465110498, 9.86028127659671],
              [37.660276022869802, 9.86153140116067],
              [37.658176210893203, 9.8635227402313],
              [37.656366105595701, 9.86489233200785],
              [37.654625619732698, 9.866020747449239],
              [37.652406780980897, 9.86824216576894],
              [37.650715702458598, 9.869952470661699],
              [37.648665297822603, 9.871830919486881],
              [37.648025248182698, 9.872972586328309],
              [37.647147144992402, 9.87400141061365],
              [37.646325186507497, 9.875140857413401],
              [37.645676153714703, 9.876572351005841],
              [37.644917077299603, 9.87783126371772],
              [37.644036728321197, 9.879141058825621],
              [37.643156379342699, 9.880512798111839],
              [37.642395057139403, 9.881711959059951],
              [37.641225001481899, 9.882731906167351],
              [37.640584951841902, 9.88324077230501],
              [37.639426125125397, 9.88415230446128],
              [37.637616019827902, 9.885172243999889],
              [37.635585827285801, 9.885902350620279],
              [37.632625878424598, 9.88663245561929],
              [37.630076908805897, 9.886951046383439],
              [37.628796809526101, 9.88734264710804],
              [37.627516710246198, 9.88779177052858],
              [37.625666180760902, 9.88847098557021],
              [37.624267054705903, 9.888862584484009],
              [37.622416525220601, 9.889240908415241],
              [37.6218258829213, 9.889530735225829],
              [37.619326320643196, 9.891692259942211],
              [37.618556015287098, 9.892951114765401],
              [37.617615030026997, 9.89483164461968],
              [37.617316340195003, 9.896030753329081],
              [37.617305111253998, 9.8979311767991],
              [37.617296128101103, 9.899132486543531],
              [37.617336552288897, 9.90068112982047],
              [37.617504986404697, 9.90206162852537],
              [37.617615030026997, 9.90275187570049],
              [37.617666683155797, 9.90396201708395],
              [37.617655454214798, 9.90528276958848],
              [37.617296128101103, 9.9064818401223],
              [37.615955392539597, 9.907561442314909],
              [37.614675293259701, 9.9082428287551],
              [37.613446847108698, 9.90915207923525],
              [37.611585088682297, 9.91011220960945],
              [37.610246598909001, 9.9108422607777],
              [37.606936307086997, 9.9122514761633],
              [37.605775234582303, 9.9131009845529],
              [37.6046051789247, 9.913961552005251],
              [37.602905117249499, 9.9160720343422],
              [37.602325703891303, 9.917091874710421],
              [37.601276920797098, 9.91743255820435],
              [37.6006368711571, 9.91771129898139],
              [37.599415162370697, 9.918162593070081],
              [37.597274926206303, 9.91826214315304],
              [37.5942049337228, 9.918060830731809],
              [37.592924834442996, 9.91817144196758],
              [37.591766007726498, 9.918501063228719],
              [37.590595952068902, 9.920270836142221],
              [37.589565135280402, 9.92589201394158],
              [37.589726832031502, 9.927781203548269],
              [37.590056962898402, 9.929741170937939],
              [37.590566756822199, 9.9315816713899],
              [37.590786844066798, 9.932842585195379],
              [37.590825022466298, 9.93468085608942],
              [37.590816039313502, 9.936001484759711],
              [37.589875054053401, 9.93777116305969],
              [37.588536564279998, 9.93879093583085],
              [37.587375491775298, 9.939472257298339],
              [37.585226272458101, 9.940091639218609],
              [37.580465201452199, 9.940852592542839],
              [37.579254721606901, 9.94084153218744],
              [37.5759466755731, 9.941281734043409],
              [37.573974873524499, 9.941662209541841],
              [37.572975497770898, 9.942861146878739],
              [37.570994712569401, 9.94434100996255],
              [37.570415299211099, 9.944792267327511],
              [37.568436759797898, 9.94632078641863],
              [37.566215675257901, 9.948371335530981],
              [37.564416798901398, 9.949101301364079],
              [37.562546057322301, 9.9503024234116],
              [37.560104885537697, 9.95177119280314],
              [37.558294780240203, 9.953131568198989],
              [37.5569562904668, 9.954091569538649],
              [37.556374631320402, 9.95432161552336],
              [37.553576379210298, 9.95665082198809],
              [37.5526960302319, 9.958542045779019],
              [37.552446747740603, 9.960431046670839],
              [37.5520155564042, 9.96312296464002],
              [37.551656230290497, 9.964901343618081],
              [37.551586610855999, 9.96622185009265],
              [37.551575381915001, 9.967652945710929],
              [37.551566398762098, 9.96897122916074],
              [37.5515551698211, 9.970121404996959],
              [37.551824664406297, 9.97248146462859],
              [37.551534957727199, 9.973631628076269],
              [37.550654608748701, 9.97413150553972],
              [37.548505389431497, 9.975202036996359],
              [37.546634647852301, 9.976801192103],
              [37.544995222458802, 9.97868123993254],
              [37.544415809100499, 9.979021859201501],
              [37.541956671010198, 9.98215155853368],
              [37.540905642127797, 9.9834122769721],
              [37.5401959730534, 9.984611060852799],
              [37.539205580452602, 9.98580099325312],
              [37.5389046448324, 9.98643134601445],
              [37.537965905360501, 9.98769204787366],
              [37.5369148764781, 9.98877138172427],
              [37.535515750423102, 9.99042134007442],
              [37.534985744405503, 9.990991967153869],
              [37.532645633090297, 9.993902592027201],
              [37.531484560585596, 9.994751887991839],
              [37.530655864735998, 9.9954817499389],
              [37.529966407755502, 9.99594178329394],
              [37.527875578931699, 9.997083017075401],
              [37.527284936632398, 9.99730197426139],
              [37.526555055464001, 9.997543048164269],
              [37.522694545530499, 9.99879264855657],
              [37.521306648416498, 9.999602121644561],
              [37.518054747088001, 10.0003828410459],
              [37.517414697448103, 10.0006017960087],
              [37.516774647808198, 10.0008318091439],
              [37.512725491665002, 10.0004514027168],
              [37.510066478424001, 9.9991111300121],
              [37.508916634860299, 9.99830165570112],
              [37.507706155015001, 9.997773063464519],
              [37.505395238946598, 9.99695252736916],
              [37.504135351760603, 9.997122827483819],
              [37.502165795500197, 9.997512084553721],
              [37.500095178770302, 9.99839233461765],
              [37.499515765411999, 9.99866215953673],
              [37.498175029850501, 9.998971794413711],
              [37.496124625214499, 9.999491538008281],
              [37.494716516006598, 10.0003120676931],
              [37.493425187785697, 10.000851714119101],
              [37.492834545486403, 10.001181251864701],
              [37.491745338204403, 10.0015616574373],
              [37.490966049695402, 10.001992930658201],
              [37.489616330981001, 10.002640945242],
              [37.488385639041802, 10.0031230849468],
              [37.487565926344999, 10.0033309798281],
              [37.485535733802898, 10.0033309798281],
              [37.484264617675898, 10.003302228416199],
              [37.4829957473371, 10.0032624187649],
              [37.481704419116099, 10.003572049257899],
              [37.481055386323398, 10.0040121663792],
              [37.4796046071395, 10.006102161653899],
              [37.479045405875098, 10.0075220237867],
              [37.478425568329101, 10.0088821662686],
              [37.477814713935899, 10.010072009815699],
              [37.477275724765398, 10.010631544399899],
              [37.476134864354599, 10.011812535177899],
              [37.4744954389611, 10.0129824637525],
              [37.474729000935, 10.014304986627399],
              [37.473951958214201, 10.0144133536474],
              [37.472676350510703, 10.014375756930299],
              [37.470147592985903, 10.0137299773441],
              [37.4688809684353, 10.013522089121601],
              [37.467153957301598, 10.013185929161001],
              [37.466004113737903, 10.0128652499266],
              [37.464045786418602, 10.012522454532499],
              [37.462199748509697, 10.0122393716115],
              [37.460414346882501, 10.01196071162],
              [37.456533624855098, 10.0119098450878],
              [37.454096944646899, 10.012126580691399],
              [37.451069622139499, 10.012617552645899],
              [37.446807116116297, 10.013820651952599],
              [37.445522525260003, 10.014185561706199],
              [37.444824085126598, 10.0143381602088],
              [37.442834316772299, 10.0149728807465],
              [37.442243674472998, 10.015415193975899],
              [37.440997262016303, 10.016472320151101],
              [37.440458272845802, 10.017034055252701],
              [37.440094455155702, 10.0175980009312],
              [37.439380294504801, 10.0182680998157],
              [37.438185535176999, 10.019499928146701],
              [37.435829703344403, 10.022826062418201],
              [37.435039185894297, 10.0241286416077],
              [37.434019598046902, 10.025305160247401],
              [37.433655780356801, 10.0258713030333],
              [37.432580047804102, 10.026990316724101],
              [37.430792400388697, 10.0286644070663],
              [37.429438190097798, 10.029431787726899],
              [37.427560711154001, 10.030298682425],
              [37.424924155795097, 10.0333305841553],
              [37.423738379620097, 10.0342173713459],
              [37.420857033346302, 10.035745470258099],
              [37.4195589677607, 10.0365150455613],
              [37.418368700009303, 10.0375721029093],
              [37.417138008069998, 10.0381138987476],
              [37.415851171425501, 10.0385362777312],
              [37.414568826357502, 10.038788378013701],
              [37.413162962937797, 10.039383245445901],
              [37.411988415703803, 10.039865330518101],
              [37.411400019192698, 10.0402501135009],
              [37.4080695152769, 10.0411921664583],
              [37.406784924420599, 10.041559256774301],
              [37.406142628992399, 10.0417693383942],
              [37.404844563406897, 10.0425388993739],
              [37.404256166895799, 10.0428661833737],
              [37.403663278808303, 10.0433106698973],
              [37.4028952192403, 10.04386351298],
              [37.402243940659297, 10.044363282313601],
              [37.401588170501903, 10.044918334960601],
              [37.400350741198103, 10.045690099807199],
              [37.399706199981701, 10.0459001787445],
              [37.3990639045536, 10.046054973663701],
              [37.397723168992002, 10.0464198471086],
              [37.396970829941601, 10.0463977335782],
              [37.395697468026299, 10.046362351926399],
              [37.391356359415802, 10.0462385161147],
              [37.389972953878299, 10.0460262260415],
              [37.388757982456497, 10.0459930557049],
              [37.388005643405997, 10.0458869106052],
              [37.386220241778901, 10.0456348158536],
              [37.384324796529398, 10.045062073538601],
              [37.382207018247101, 10.0442549253113],
              [37.381638833829903, 10.043834765163],
              [37.380217249892702, 10.0429900204765],
              [37.379478385571502, 10.042510151439201],
              [37.378339770948898, 10.041842314082899],
              [37.3762085179374, 10.041437630510901],
              [37.374928418657497, 10.041632232510301],
              [37.374283877441101, 10.0419573060438],
              [37.372821869316198, 10.0423774686304],
              [37.372235718593302, 10.0425897610983],
              [37.370829855173703, 10.0431249144095],
              [37.3687906794787, 10.043584880184399],
              [37.367517317563497, 10.0435494982249],
              [37.366243955648301, 10.0435119048887],
              [37.363061673754302, 10.043421238589399],
              [37.361280763703498, 10.0429104109158],
              [37.358201788067198, 10.0413292725286],
              [37.356903722481597, 10.0401992514087],
              [37.355776336799998, 10.039131145626699],
              [37.355452943297799, 10.0351749218697],
              [37.355235101841402, 10.0339387302459],
              [37.3548645467867, 10.032720225476],
              [37.354289625004803, 10.030634824642901],
              [37.354017884631403, 10.029993500372401],
              [37.353824746845298, 10.028724116866],
              [37.353687753764497, 10.0274547283861],
              [37.353440717061297, 10.026068125879499],
              [37.352919694196601, 10.024097680533099],
              [37.352248203521697, 10.021317806291799],
              [37.351785571150302, 10.0190841589638],
              [37.351536288658998, 10.01802040666],
              [37.351358871390403, 10.016173759760299],
              [37.351066918923102, 10.0142673898977],
              [37.350352758272201, 10.0129824637525],
              [37.348553881915699, 10.0111490577416],
              [37.348050825356601, 10.010558561682799],
              [37.346936914404303, 10.009032555656599],
              [37.345645586183402, 10.0072323016438],
              [37.3443542579625, 10.005261741890401],
              [37.343754632510297, 10.004562864851801],
              [37.343525562112902, 10.0042930448333],
              [37.340601545863102, 10.0044390130679],
              [37.339620136415199, 10.0044876691315],
              [37.337614647543397, 10.0045894045137],
              [37.333024256441497, 10.004761912262801],
              [37.331214151144003, 10.0052329906495],
              [37.330623508844702, 10.005511656415701],
              [37.328755013053701, 10.006022543032801],
              [37.327414277492203, 10.006562179971899],
              [37.326064558777801, 10.006922674640499],
              [37.324135426705197, 10.0076724138047],
              [37.323214653538898, 10.0080815627659],
              [37.322345533501498, 10.008512827329],
              [37.321494379769803, 10.009671709778299],
              [37.320854330129897, 10.0108018373428],
              [37.320535428204003, 10.0120027317928],
              [37.320283899924497, 10.0125733209687],
              [37.319834742282403, 10.0139334422839],
              [37.319275541018101, 10.015242691888099],
              [37.318725322906502, 10.0164325121149],
              [37.318163875853998, 10.0175714623331],
              [37.317474418873402, 10.0186020429674],
              [37.316834369233497, 10.019541947343001],
              [37.3158641887266, 10.0207826169475],
              [37.315284775368397, 10.0210524232563],
              [37.314054083429099, 10.021362036776599],
              [37.313474670070903, 10.021572131497001],
              [37.311484901716497, 10.0220918389047],
              [37.310784215794897, 10.022361644124],
              [37.3089853394385, 10.022591641838501],
              [37.306315097256402, 10.024701420952599],
              [37.306063568976903, 10.0253316982126],
              [37.305623394487696, 10.0264728286596],
              [37.3051854657867, 10.0277820276337],
              [37.304745291297401, 10.0289120920892],
              [37.303514599358202, 10.0311832664602],
              [37.302845354471501, 10.032313319050701],
              [37.302263695325102, 10.0342527543256],
              [37.301854961870802, 10.034852052956399],
              [37.301414787381603, 10.035493367605801],
              [37.300994824986198, 10.0360528583141],
              [37.300264943817901, 10.0373023103578],
              [37.299535062649497, 10.038602619929801],
              [37.299303746463899, 10.0403827971819],
              [37.299184719688697, 10.042333241015401],
              [37.298973615596999, 10.0436534327198],
              [37.298515474802102, 10.0455928000426],
              [37.298223522334702, 10.0475432124632],
              [37.297504870107403, 10.050101728832599],
              [37.297244358675002, 10.0513621832122],
              [37.297024271430402, 10.052682338055901],
              [37.296723335810199, 10.0532417990346],
              [37.296294390262098, 10.053812315557],
              [37.295703747962797, 10.0541329542457],
              [37.295115351451699, 10.054522142847301],
              [37.293925083700202, 10.055581352633499],
              [37.293433256082203, 10.056773235902799],
              [37.292633755479301, 10.0580734671816],
              [37.291735440195197, 10.0593117778195],
              [37.291133568954798, 10.059873438579499],
              [37.290003937484997, 10.060992334662799],
              [37.288804686580697, 10.0622814927347],
              [37.287623401982103, 10.0631615668156],
              [37.286325336396601, 10.0638116702494],
              [37.285685286756603, 10.0640814406777],
              [37.284454594817397, 10.0644529274559],
              [37.283284539159801, 10.064762499444599],
              [37.282583853238201, 10.0650322690783],
              [37.28136439024, 10.065052170107901],
              [37.280733323752898, 10.065091972163501],
              [37.278303380909399, 10.0667525979967],
              [37.277944054795697, 10.0673717372658],
              [37.277324217249699, 10.068623279446699],
              [37.276785228079198, 10.069293272647601],
              [37.276414673024497, 10.0698615496187],
              [37.275395085177003, 10.070982622264699],
              [37.274865079159397, 10.0715420515363],
              [37.2734345120694, 10.0727029194591],
              [37.272843869770099, 10.0730323840703],
              [37.272194836977398, 10.073421549889],
              [37.270784481981302, 10.0742418922071],
              [37.269484170607498, 10.075013586984401],
              [37.268195088174799, 10.075493407734299],
              [37.265863960012503, 10.0760528291817],
              [37.264004447374397, 10.076291633298499],
              [37.262834391716801, 10.076603405073699],
              [37.260795216021897, 10.0770036223006],
              [37.259405073119702, 10.0770235225917],
              [37.258255229555999, 10.0769815108646],
              [37.256755043031603, 10.076822308480301],
              [37.254783240982903, 10.0767714521466],
              [37.253624414266397, 10.0766830063297],
              [37.252294907645897, 10.0765835047566],
              [37.250904764743701, 10.076541492972099],
              [37.249054235258399, 10.0764928477411],
              [37.247783119131398, 10.0762827887048],
              [37.246464841452003, 10.075953327414201],
              [37.245254361606598, 10.0756924115225],
              [37.243754175082103, 10.0755332085017],
              [37.242653738859097, 10.0755022523497],
              [37.241153552334602, 10.075453606961901],
              [37.239994725618097, 10.075422650802199],
              [37.238714626338201, 10.0757322122654],
              [37.238063347757198, 10.0759422716604],
              [37.236652992761201, 10.076652050288001],
              [37.2360039599684, 10.0770920680296],
              [37.234833904310797, 10.077523240610899],
              [37.232123237941003, 10.078931733690901],
              [37.231483188301098, 10.079201491475001],
              [37.230133469586697, 10.079683517117299],
              [37.228844387153998, 10.0801633309168],
              [37.228204337514001, 10.080373387424499],
              [37.226802965670799, 10.080853200197],
              [37.225513883238101, 10.081333012255101],
              [37.224274208145999, 10.081983079065299],
              [37.223683565846699, 10.082372234079299],
              [37.222394483414, 10.082962599027001],
              [37.221743204832997, 10.083351752858],
              [37.221154808321899, 10.083621506942199],
              [37.219214447308197, 10.0845435335414],
              [37.217873711746599, 10.0850233401042],
              [37.216043394355303, 10.086002850822601],
              [37.214224305904899, 10.0869337149075],
              [37.212553439476501, 10.0885522180272],
              [37.211194737609198, 10.0894830747435],
              [37.210604095309897, 10.0899319191819],
              [37.207904657881102, 10.093268383595399],
              [37.207172530924602, 10.094174905394],
              [37.206550447590303, 10.0949421303847],
              [37.205032294760201, 10.096817070215501],
              [37.202795489702702, 10.0985593394159],
              [37.201663612444698, 10.0994415254254],
              [37.200421691564401, 10.100409937356901],
              [37.199148329649198, 10.101398245157601],
              [37.197288817011099, 10.102848642157801],
              [37.196698174711699, 10.103348320097099],
              [37.195449516466802, 10.104458221414401],
              [37.194308656056002, 10.1055791736367],
              [37.193298051361403, 10.106527668618099],
              [37.192819698472597, 10.107148942368701],
              [37.191398114535403, 10.1100386233538],
              [37.191088195762397, 10.110609039329701],
              [37.190338102500199, 10.1124772551067],
              [37.1900281837272, 10.113047666752999],
              [37.189109656349103, 10.114798691526],
              [37.188548209296599, 10.1160478384655],
              [37.187989008032197, 10.1172991914127],
              [37.187838540222103, 10.1185571720571],
              [37.1876094698247, 10.1205071428569],
              [37.187348958392299, 10.1212477749207],
              [37.1867987402808, 10.1223885660396],
              [37.186358565791501, 10.123518298984701],
              [37.185718516151603, 10.125397493703099],
              [37.185278341662404, 10.1267085018534],
              [37.184638292022399, 10.1287092712489],
              [37.184058878664203, 10.1305884355577],
              [37.1836478994217, 10.131749090364901],
              [37.183158317591896, 10.1335486497013],
              [37.182769796231497, 10.1348574137829],
              [37.182367800141797, 10.1367475957181],
              [37.181849023065197, 10.1385692341915],
              [37.181348212294402, 10.1398182885673],
              [37.180979903027897, 10.1404483406967],
              [37.180519516444797, 10.1423871249141],
              [37.180267988165198, 10.1430083292333],
              [37.179809847370301, 10.1447282409552],
              [37.179448275468403, 10.1452875418769],
              [37.178718394300098, 10.146417194118699],
              [37.176818457474198, 10.149697805428699],
              [37.176007727930298, 10.151517159605],
              [37.175048776364498, 10.152697634181299],
              [37.173797872331299, 10.153687991514801],
              [37.173148839538598, 10.154187590256299],
              [37.1719698007281, 10.155018779353901],
              [37.170307917452497, 10.156177135025899],
              [37.169809352469798, 10.1574283312869],
              [37.167958822984602, 10.1612879977422],
              [37.1675096653425, 10.162477278473199],
              [37.167518648495303, 10.164318665373401],
              [37.1677297525871, 10.164957511410901],
              [37.168098061853598, 10.1678488816569],
              [37.168468616908299, 10.1691287688462],
              [37.169598248378101, 10.1720886264842],
              [37.170808728223399, 10.174078059816001],
              [37.172389763123498, 10.175678439410101],
              [37.174249275761603, 10.177227970570801],
              [37.174808477025998, 10.177877843026801],
              [37.176418707172701, 10.1801081775474],
              [37.178368051339298, 10.1828689962075],
              [37.179868237863801, 10.1848672039349],
              [37.181548087445101, 10.1868189810332],
              [37.183448024271001, 10.1891686130389],
              [37.184979651830403, 10.192207857347601],
              [37.185478216813102, 10.1929085371972],
              [37.186958191243697, 10.1957687096236],
              [37.188498801955902, 10.198578019365801],
              [37.190268483065701, 10.200927564660899],
              [37.192049393116399, 10.203628525695301],
              [37.192709654850297, 10.204848592233001],
              [37.1944299286193, 10.2077572827203],
              [37.196069354012899, 10.210758775879301],
              [37.197109153954202, 10.212747967818],
              [37.1980097150266, 10.2153582108088],
              [37.198669976760399, 10.2165870730792],
              [37.199678335666803, 10.219548700037899],
              [37.200329614247799, 10.221007401679801],
              [37.2005182604575, 10.222329067991399],
              [37.200318385306801, 10.223478338576101],
              [37.199988254439802, 10.224729270679401],
              [37.199619945173303, 10.225359155121],
              [37.198658747819302, 10.2265371461804],
              [37.197538099502403, 10.2289174262246],
              [37.1961479566002, 10.2308291516868],
              [37.195727994204901, 10.231397141889],
              [37.194479335959997, 10.232449136622201],
              [37.1931790245862, 10.233448086376599],
              [37.192049393116399, 10.234449243042199],
              [37.190969168987301, 10.2355078578027],
              [37.1898395375175, 10.2366283501631],
              [37.189239912065297, 10.2373576625911],
              [37.188808720729, 10.2379190110151],
              [37.188029432219999, 10.2387389315344],
              [37.187198490582198, 10.239598630287899],
              [37.185958815490103, 10.2403080457565],
              [37.184728123550897, 10.2408472888518],
              [37.183259378061301, 10.2413290708418],
              [37.182158941838303, 10.241298130736],
              [37.179549335937899, 10.2415589115332],
              [37.1754395435131, 10.2431677917088],
              [37.174678221309797, 10.2433180713084],
              [37.173409350970999, 10.2433976310675],
              [37.1693377369457, 10.2437379698118],
              [37.167709540493199, 10.2440385283991],
              [37.1659084183486, 10.244268367126599],
              [37.164567682787002, 10.244347926647301],
              [37.163179785673002, 10.2444186462044],
              [37.161848033264299, 10.2443788664555],
              [37.160457890362203, 10.2442882570086],
              [37.15906774746, 10.2441888075859],
              [37.157509170441998, 10.244137977869],
              [37.155997754976497, 10.244208697473001],
              [37.154598628921498, 10.2443987563306],
              [37.151357956534, 10.244478315818499],
              [37.148047664712003, 10.244728044081601],
              [37.145837809113097, 10.2448385432503],
              [37.1420087402146, 10.2449490423804],
              [37.139978547672399, 10.245178880447799],
              [37.1370680061519, 10.2454374480743],
              [37.134797514271298, 10.2457777846288],
              [37.132567446578499, 10.246688293614399],
              [37.129769194468402, 10.2472982447728],
              [37.127788409266898, 10.2478175863194],
              [37.125697580443202, 10.247868415446399],
              [37.122598392712902, 10.2486374812415],
              [37.1203795539612, 10.249318147112399],
              [37.118459405041399, 10.249788866486099],
              [37.116467390898798, 10.2502971542265],
              [37.114309188428699, 10.250697154005399],
              [37.1129684528672, 10.2510573743685],
              [37.110909065078303, 10.252149082660701],
              [37.108678997385503, 10.2531170314007],
              [37.106518549127202, 10.2536871916029],
              [37.104998150508798, 10.2539280729303],
              [37.102889355379403, 10.254739112383101],
              [37.100879374931097, 10.2559390933767],
              [37.098707697731797, 10.2569070305333],
              [37.095197530759101, 10.257958940617799],
              [37.093097718782502, 10.258467215246499],
              [37.089019367392503, 10.2590970326762],
              [37.087157608966201, 10.259448403960899],
              [37.085057796989602, 10.2598970094996],
              [37.080447193793802, 10.261088131465501],
              [37.0792075187018, 10.261797498818201],
              [37.078839209435301, 10.2623676433884],
              [37.077938648362903, 10.2635477067937],
              [37.077628729589897, 10.264168674721001],
              [37.076609141742402, 10.265178573694],
              [37.075957863161399, 10.2656779973778],
              [37.075171837287797, 10.266290121790901],
              [37.074322929344397, 10.270716386896201],
              [37.072510578258601, 10.273136111222399],
              [37.071967097511703, 10.2738100950657],
              [37.070945263876098, 10.275045360084],
              [37.069925676028603, 10.2761657125535],
              [37.068025739202703, 10.2776042658174],
              [37.064812016273699, 10.2803841243819],
              [37.063257930832201, 10.2819486127211],
              [37.0612861287836, 10.2820038558118],
              [37.060531543944897, 10.2820414211079],
              [37.059193054171601, 10.282231457243499],
              [37.0572145147583, 10.282518720952201],
              [37.054521814694098, 10.2829429868749],
              [37.051388940140797, 10.2834379630643],
              [37.050104349284503, 10.283745113077501],
              [37.044952511130099, 10.287388905904301],
              [37.043665674485602, 10.2851085019756],
              [37.042648332426303, 10.2850775661502],
              [37.040903354986902, 10.285199099732701],
              [37.038345402215398, 10.2854686831488],
              [37.037123693429002, 10.2856609269197],
              [37.035149645592099, 10.285775831186699],
              [37.033981835722798, 10.2860873983154],
              [37.0332182677313, 10.2863503518941],
              [37.032003296309497, 10.286314996803799],
              [37.030729934394202, 10.286277432016099],
              [37.029458818267202, 10.2861824151801],
              [37.028823260203701, 10.2860476238055],
              [37.027325319467401, 10.285831073607801],
              [37.025369237936303, 10.2853140041677],
              [37.022871921446402, 10.2849317257894],
              [37.0202376118757, 10.284529559597001],
              [37.018209665121802, 10.284467687829601],
              [37.0147915754658, 10.2844831557726],
              [37.011692387735501, 10.285369246669701],
              [37.009271428044798, 10.2868497421212],
              [37.008022769799901, 10.2878463105228],
              [37.0075421711229, 10.288407569390699],
              [37.007351279124997, 10.2889776659592],
              [37.006161011373599, 10.2899764373101],
              [37.004739427436498, 10.2909707861788],
              [37.002700251741501, 10.293209164499601],
              [37.000919341690697, 10.296433020075201],
              [37.000418530919802, 10.297624004307],
              [37.000052467441598, 10.2982471148587],
              [36.9991721184631, 10.300036893638101],
              [36.998801563408399, 10.300878749032],
              [36.998190709015198, 10.302275207615001],
              [36.997171121167803, 10.3033932538565],
              [36.996036998121497, 10.3044538474159],
              [36.9954508473987, 10.3046659656994],
              [36.9947434241124, 10.3049907715444],
              [36.991017661471503, 10.307408023573901],
              [36.989106495704597, 10.309250779116001],
              [36.988511361828799, 10.3097501330779],
              [36.988205934632198, 10.3103157720077],
              [36.986786596483299, 10.3111951617641],
              [36.9841365663952, 10.312551803456399],
              [36.982254595874899, 10.3134731675271],
              [36.981493273671703, 10.3136808607342],
              [36.980267073308802, 10.313987981289401],
              [36.9777562820897, 10.3146044309959],
              [36.974068697848402, 10.315815231923599],
              [36.972909871131897, 10.3157246429643],
              [36.971306378349702, 10.3151104049427],
              [36.9703519183604, 10.314708277243501],
              [36.969498518840403, 10.3140432187675],
              [36.966747428282801, 10.311911045079199],
              [36.964225408122701, 10.3116657889416],
              [36.963398958061298, 10.3117387029484],
              [36.9619683909713, 10.3119309307036],
              [36.961204822979802, 10.3119839590293],
              [36.958711998066399, 10.3119662829217],
              [36.9580719484265, 10.312061291988099],
              [36.956565024537298, 10.312074549064899],
              [36.955293908410297, 10.3121187393167],
              [36.9546156803708, 10.312235843454101],
              [36.953537702029898, 10.312558431984501],
              [36.952383366889798, 10.312721935634],
              [36.951675943603497, 10.312735192683],
              [36.950979749258302, 10.3127705448108],
              [36.949639013696803, 10.313018009594099],
              [36.945241760381002, 10.3128302015174],
              [36.9446106938939, 10.3126379743111],
              [36.943171143651099, 10.3123065478178],
              [36.942605205022097, 10.311888949939499],
              [36.941340826259697, 10.311504494260401],
              [36.940709759772602, 10.311371923227799],
              [36.939503771503702, 10.310991885958201],
              [36.937493791055502, 10.310187619526699],
              [36.933226793455901, 10.3096529137846],
              [36.932469962829003, 10.309873866680601],
              [36.931290924018597, 10.310399733949801],
              [36.930520618662499, 10.3109521145896],
              [36.929927730575002, 10.3114514658541],
              [36.929218061500499, 10.311888949939499],
              [36.928566782919503, 10.3122734051493],
              [36.925514756741698, 10.313561547632],
              [36.923709143020702, 10.314001238285],
              [36.919756555770498, 10.314193464659001],
              [36.918541584348802, 10.314158112691],
              [36.914609209192498, 10.3138664588033],
              [36.913454874052398, 10.313718422257001],
              [36.912008586444998, 10.313674232229699],
              [36.910854251304897, 10.313526195593001],
              [36.910218693241397, 10.3134466534908],
              [36.909059866524899, 10.313413510942301],
              [36.907784258821401, 10.313490843549999],
              [36.902392121328504, 10.3135593381297],
              [36.900606719701301, 10.313159417954401],
              [36.899854380650901, 10.3131373229023],
              [36.898412584619898, 10.3129782384813],
              [36.896685573486103, 10.3125827365864],
              [36.896052261210798, 10.3124479564976],
              [36.895414457359102, 10.3124302804161],
              [36.894082704950399, 10.312388299718499],
              [36.888713025339598, 10.3155036941737],
              [36.885636295491501, 10.317483389806799],
              [36.884392128823002, 10.3183649690113],
              [36.883801486523701, 10.318749416312199],
              [36.883017706438302, 10.3197016946728],
              [36.882577531949003, 10.320839564108701],
              [36.88243829308, 10.321584148288901],
              [36.882245155293901, 10.322154184838199],
              [36.881863371298202, 10.32328983597],
              [36.881495062031703, 10.323912895744201],
              [36.8811289985534, 10.3244762997964],
              [36.878734988321199, 10.325545660006201],
              [36.8791639338694, 10.328040083220399],
              [36.8783734164194, 10.3292243197319],
              [36.877612094216097, 10.3313276541791],
              [36.8767070415673, 10.332450015960999],
              [36.875972668822499, 10.3336916792464],
              [36.874530872791503, 10.3353752077509],
              [36.874164809313299, 10.335938591259501],
              [36.871454142943399, 10.338207580494799],
              [36.867831686560201, 10.3401164350822],
              [36.867243290049103, 10.3404434136392],
              [36.8660148438981, 10.3407527173663],
              [36.862062256648002, 10.341150393139101],
              [36.8614244527962, 10.3411305093625],
              [36.859627822227999, 10.341134927979599],
              [36.858992264164499, 10.3411172535106],
              [36.857777292742703, 10.3410774859518],
              [36.856326513558898, 10.3410929511142],
              [36.852847787621101, 10.341159230372799],
              [36.850186528591898, 10.3409648111745],
              [36.848681850490998, 10.3409184156662],
              [36.846829075217499, 10.340863182909301],
              [36.845557959090499, 10.3407681825447],
              [36.843473867631303, 10.3406466704085],
              [36.840862015942697, 10.3407991128991],
              [36.838313046324103, 10.3407792291002],
              [36.834607495777099, 10.342506905597199],
              [36.833390278567101, 10.3424693475136],
              [36.832756966291797, 10.342392022033099],
              [36.829121035179298, 10.341824231493399],
              [36.827784791194198, 10.341954580385099],
              [36.826268884152199, 10.342255045421],
              [36.825038192213, 10.3427344633019],
              [36.822657656710099, 10.3428360909614],
              [36.822084980716397, 10.3425864403477],
              [36.818451295392201, 10.3420760920152],
              [36.817703447918099, 10.341879464081201],
              [36.817070135642801, 10.341744696549799],
              [36.814712058022003, 10.3412144630777],
              [36.8130232252879, 10.3385411890198],
              [36.811772321254701, 10.336985819097],
              [36.810577561926898, 10.3362589461374],
              [36.809270513188501, 10.335472419095099],
              [36.808639446701399, 10.3352802057264],
              [36.807830962945701, 10.3351432260125],
              [36.8048081320146, 10.335452534959],
              [36.801549493321502, 10.335870101553599],
              [36.800895968952297, 10.336369413260901],
              [36.800249181947699, 10.336691977039299],
              [36.799665277012998, 10.3368488401268],
              [36.7990768805019, 10.3371183793338],
              [36.798483992414397, 10.3375028037043],
              [36.798003393737403, 10.338063974067],
              [36.7976373302591, 10.338627352753401],
              [36.797156731582099, 10.3391885211069],
              [36.796849058597303, 10.339754107094301],
              [36.796258416298002, 10.340081086028601],
              [36.794755983985297, 10.3418176035822],
              [36.792559603115599, 10.3430282997058],
              [36.790215000224102, 10.344322944044301],
              [36.785388801360099, 10.3448089866154],
              [36.781977449068698, 10.342988532389001],
              [36.7810387095968, 10.3435032978221],
              [36.780497474638103, 10.343799342796199],
              [36.779394792626903, 10.3444068969059],
              [36.778804150327602, 10.3447316617113],
              [36.777699222528099, 10.3453392140164],
              [36.777178199663297, 10.3456264201512],
              [36.776535904235203, 10.3459776949896],
              [36.775866659348502, 10.346346643610399],
              [36.775215380767499, 10.3467045454508],
              [36.774552873245497, 10.3470690746835],
              [36.773944264640498, 10.3474026737921],
              [36.774090240874102, 10.350215054827],
              [36.773897103088103, 10.350840270437899],
              [36.773223366624997, 10.3519713994011],
              [36.773030228838898, 10.3525966115113],
              [36.772722555854102, 10.3531621733359],
              [36.772199287201097, 10.354083418343],
              [36.7716827559127, 10.354914082983701],
              [36.770532912348997, 10.3559612475068],
              [36.770005152119602, 10.356586451662301],
              [36.769466162949101, 10.3571453787034],
              [36.769021496883497, 10.358397990231801],
              [36.768653187616998, 10.3590187711625],
              [36.768462295619102, 10.3595865305813],
              [36.767900848566597, 10.360834714014601],
              [36.767359613607901, 10.361451071787901],
              [36.766876769142698, 10.3620696375101],
              [36.766227736349897, 10.362451822149501],
              [36.765917817576899, 10.363017366194301],
              [36.765246326902002, 10.364146242074799],
              [36.7640066518099, 10.366638149382799],
              [36.760671656317598, 10.3676874838511],
              [36.759443210166602, 10.367994551588501],
              [36.758277646085403, 10.368188954029099],
              [36.7540084026977, 10.3675991189789],
              [36.751872658109598, 10.3654628906047],
              [36.750087256482502, 10.3636381379744],
              [36.748450076877198, 10.361965806920599],
              [36.747134044985899, 10.361409097641101],
              [36.746502978498803, 10.361216900160199],
              [36.745755131024801, 10.3610224933929],
              [36.7451195729613, 10.3585261230513],
              [36.7437316758473, 10.358495194444499],
              [36.742395431862199, 10.360978310019901],
              [36.741113086794101, 10.361225736828599],
              [36.740466299789503, 10.3614930459291],
              [36.739181708933302, 10.361797910575399],
              [36.736756257666102, 10.361552693383199],
              [36.7337985545932, 10.3615769941946],
              [36.7323006138569, 10.3613582868243],
              [36.7316650557934, 10.3612809660002],
              [36.730274912891197, 10.3612389918307],
              [36.728826379495601, 10.3612412009976],
              [36.725176973653802, 10.3611395793013],
              [36.723851958609799, 10.3609297083024],
              [36.723047966430499, 10.360671235405301],
              [36.721898122866797, 10.3604083439495],
              [36.720103738086799, 10.357958361711701],
              [36.719412035318001, 10.357942897380701],
              [36.718080282909298, 10.3579119687163],
              [36.717442479057603, 10.357896504383],
              [36.7167462847124, 10.3578810400489],
              [36.716164625565902, 10.3578677849048],
              [36.714253459798897, 10.3578236010871],
              [36.713038488377201, 10.357797090793399],
              [36.711477665571003, 10.3577617437318],
              [36.710085276880598, 10.357728605857901],
              [36.707823768152799, 10.357677794444401],
              [36.707246600582799, 10.357664539291701],
              [36.701513103281897, 10.357534196926901],
              [36.700875299430201, 10.357518732575],
              [36.699601937514899, 10.357490013062201],
              [36.697746916453198, 10.3574480383849],
              [36.693289026855801, 10.357344206264401],
              [36.689969751881002, 10.357269093645099],
              [36.689473432686498, 10.353961911362701],
              [36.689374618005203, 10.353314609746899],
              [36.689181480219197, 10.352031048666801],
              [36.689089402902503, 10.3514124631381],
              [36.688999571374097, 10.350822596515499],
              [36.688795204647001, 10.349461701499401],
              [36.688485285874002, 10.347391627469699],
              [36.688388716980903, 10.346746521565899],
              [36.6882045623477, 10.345522584117401],
              [36.68810125609, 10.344835498006701],
              [36.688006932985203, 10.344208061144901],
              [36.687908118303902, 10.34355190224],
              [36.687703751576798, 10.342199812899199],
              [36.684835880032203, 10.341333765687001],
              [36.684004938394402, 10.3401584120782],
              [36.683564763905203, 10.339400615971501],
              [36.6826799233503, 10.338105951337299],
              [36.682293647778202, 10.337463035687801],
              [36.680966386945897, 10.335468000398301],
              [36.680791215465497, 10.333735866461501],
              [36.681691776537797, 10.3308901971234],
              [36.682235257284702, 10.3302163353162],
              [36.682387970882999, 10.3290718716251],
              [36.682664202832903, 10.3276998353381],
              [36.6851705024756, 10.325349021728],
              [36.686014918842602, 10.323049007570599],
              [36.688175367100897, 10.317165225277],
              [36.683540060234897, 10.319798910863],
              [36.6822330114965, 10.3175695593116],
              [36.6816625812911, 10.3172646517271],
              [36.678799201323002, 10.316199682338899],
              [36.678235508482203, 10.3157224334771],
              [36.677555034654397, 10.315240764910801],
              [36.676351292173699, 10.3148607323108],
              [36.675457468466, 10.3146287354399],
              [36.674568136334699, 10.3144011573909],
              [36.673243121290703, 10.314246492603599],
              [36.668619043365702, 10.313815640295299],
              [36.667983485302102, 10.313795754789901],
              [36.664197086379602, 10.3144276713469],
              [36.663655851420899, 10.3150441201925],
              [36.663058471756997, 10.315600911660599],
              [36.662407193176001, 10.316040599465399],
              [36.660242253341302, 10.3177728308706],
              [36.657291287632901, 10.320136957836199],
              [36.6561616561631, 10.32107818475],
              [36.6549040147654, 10.3223066362984],
              [36.654540197075299, 10.3228678337908],
              [36.654174133597003, 10.323431239714999],
              [36.653269080948299, 10.324555839110801],
              [36.652961407963502, 10.3251214514325],
              [36.652709879683897, 10.3259566115163],
              [36.652536953991699, 10.3265597813085],
              [36.652242755736197, 10.3276865789197],
              [36.6516229181901, 10.3289304704166],
              [36.649868957597903, 10.331177416471],
              [36.649329968427402, 10.3317341793819],
              [36.648743817704499, 10.3320037229765],
              [36.648097030700001, 10.3322710569691],
              [36.6474479979072, 10.332653277902301],
              [36.645786114631598, 10.334345649395701],
              [36.643612191644003, 10.336561625963199],
              [36.643185491883997, 10.3372354741631],
              [36.642336583940498, 10.338417466694199],
              [36.641312504516598, 10.3396502691183],
              [36.640829660051402, 10.340211435643999],
              [36.640230034599298, 10.340825624629],
              [36.639556298136199, 10.342012022252501],
              [36.639906641096999, 10.345829673564801],
              [36.639399092961497, 10.346362108513199],
              [36.6388960364024, 10.346890124022201],
              [36.636055114316299, 10.3463289694349],
              [36.635464472016999, 10.346713382528099],
              [36.634873829717698, 10.347040354213901],
              [36.634289924782998, 10.347195002865501],
              [36.631815066175299, 10.3484410263634],
              [36.631049252395599, 10.348876249717],
              [36.629286308650499, 10.349627395201701],
              [36.6271864966739, 10.350080290874899],
              [36.625895168452899, 10.350557487559801],
              [36.625178762013903, 10.3512268872415],
              [36.624639772843402, 10.3517836146017],
              [36.624053622120499, 10.3519957009552],
              [36.623357427775296, 10.351973608633401],
              [36.618847885049, 10.353500184408199],
              [36.618306650090297, 10.3541165566022],
              [36.6177654151317, 10.354675488046199],
              [36.616806463565901, 10.355680678452201],
              [36.616015946115802, 10.356805162362299],
              [36.6142058408183, 10.359104929272799],
              [36.610363297190503, 10.3614024701437],
              [36.609664857057098, 10.361552693383199],
              [36.607351695200499, 10.3631499154321],
              [36.606868850735303, 10.3637640595022],
              [36.606502787257, 10.3643296011782],
              [36.605319256870203, 10.365096173617101],
              [36.604672469865598, 10.3653656885534],
              [36.604027928649302, 10.3655755565809],
              [36.603387879009297, 10.3656705494313],
              [36.601651884722799, 10.3656175301695],
              [36.599509402770103, 10.3655490469431],
              [36.598873844706603, 10.365529164713299],
              [36.597131113055397, 10.3655910205353],
              [36.596542716544299, 10.365860535045799],
              [36.595258125687998, 10.366165395442099],
              [36.594496803484802, 10.366487928581799],
              [36.592003978571299, 10.3682486002083],
              [36.591411090483803, 10.3686904234041],
              [36.588493811598603, 10.3692890928398],
              [36.5859493335564, 10.3691521279776],
              [36.582917519472502, 10.3679083959228],
              [36.581668861227499, 10.3670667200957],
              [36.580305667783897, 10.3662758520342],
              [36.579162561584802, 10.365781006197899],
              [36.578592131379402, 10.3654761454275],
              [36.578021701174002, 10.3651712843604],
              [36.574253268557101, 10.3634437327093],
              [36.572422951165699, 10.362697038638],
              [36.571850275172103, 10.362449612991099],
              [36.567585523360698, 10.3617426814948],
              [36.566363814574302, 10.3618774404343],
              [36.564733372333698, 10.362113820729199],
              [36.562871613907298, 10.3623435733683],
              [36.561344477924301, 10.3624761228912],
              [36.558921272445403, 10.362679365384],
              [36.558281222805498, 10.3627169210476],
              [36.557066251383702, 10.362679365384],
              [36.556372302826702, 10.362657273815101],
              [36.555213476110197, 10.3626219273016],
              [36.554519527553197, 10.362602044886],
              [36.553367438201299, 10.362392174866599],
              [36.552159204144203, 10.362124866533],
              [36.550957707451701, 10.361627804975999],
              [36.550277233624001, 10.3612036451572],
              [36.549648412925102, 10.3608965707634],
              [36.549077982719702, 10.360649143694801],
              [36.546847915026802, 10.360090222901301],
              [36.545374677960901, 10.360417180640701],
              [36.541842053106102, 10.362206605469099],
              [36.541242427653899, 10.362763313331699],
              [36.540627081684299, 10.363892190127601],
              [36.539975803103303, 10.364331810323399],
              [36.538738373799497, 10.3649835074685],
              [36.538089341006703, 10.3653082509635],
              [36.5378400585153, 10.3658759989861],
              [36.537512173436603, 10.3670711383474],
              [36.537123652076197, 10.3682662731481],
              [36.536622841305402, 10.3694569851697],
              [36.536292710438403, 10.370713965674801],
              [36.536196141545403, 10.371917922938101],
              [36.535944613265798, 10.372485658976901],
              [36.535522405082297, 10.3730467667349],
              [36.534927271206598, 10.373486374078899],
              [36.533676367173399, 10.3745401037413],
              [36.532708432454797, 10.3757749731912],
              [36.532106561214398, 10.3764465280557],
              [36.531567572043997, 10.377003210600799],
              [36.532144739613997, 10.3806569674811],
              [36.530830953511, 10.3825743996025],
              [36.530527772102602, 10.3830162025722],
              [36.529939375591503, 10.383875507560701],
              [36.529389157479997, 10.384679585154],
              [36.5287693199339, 10.3855852744723],
              [36.528344865962197, 10.3862037924989],
              [36.527920411990401, 10.386824518288099],
              [36.527489220654097, 10.387454078772601],
              [36.527073749835203, 10.388059339235699],
              [36.5262854781733, 10.3892102144743],
              [36.525791404767098, 10.3899325466723],
              [36.5253781797364, 10.3905378023354],
              [36.525032328351998, 10.3910414448402],
              [36.5246325780506, 10.3916246088316],
              [36.523853289541599, 10.392764426217299],
              [36.523085229973702, 10.3938865679531],
              [36.522683233884003, 10.394469726633501],
              [36.521915174316099, 10.395594071167499],
              [36.520486853014397, 10.3976792888222],
              [36.520464395132301, 10.400418324751399],
              [36.520459903555803, 10.401050066724601],
              [36.520446428826602, 10.402777410627101],
              [36.520435199885497, 10.404275023306401],
              [36.520426216732702, 10.4054368830593],
              [36.520414987791597, 10.4067091807916],
              [36.520410496215199, 10.407287897834999],
              [36.523493963427903, 10.409691108269101],
              [36.523765703801402, 10.410272028600501],
              [36.524605628591999, 10.412944689947199],
              [36.5253197892429, 10.414172781197999],
              [36.525645428533402, 10.414815539258599],
              [36.526898578354803, 10.4173269214487],
              [36.528221347610597, 10.4211613234661],
              [36.528603131606403, 10.421921130245501],
              [36.529047797672, 10.4225086539846],
              [36.529337504351098, 10.4231160551813],
              [36.529721534135099, 10.423924449118701],
              [36.530231328058797, 10.4249956782895],
              [36.530673748336298, 10.4260094773479],
              [36.531320535340797, 10.427465013144101],
              [36.5320369417799, 10.428640038595301],
              [36.532863391841303, 10.4299299110516],
              [36.533056529627402, 10.4324544233524],
              [36.533101445391601, 10.4330419272146],
              [36.533146361155801, 10.4336426818966],
              [36.533251913201703, 10.4350076275609],
              [36.5358749938313, 10.435425061204199],
              [36.536526272412303, 10.436770121355799],
              [36.537653658093902, 10.4395066050892],
              [36.539077487819199, 10.4421370522261],
              [36.5400184730793, 10.4434909176861],
              [36.541024586197501, 10.444612876764101],
              [36.542574180062601, 10.446903162546],
              [36.5430525329514, 10.4482393388364],
              [36.5434163506415, 10.449515878751599],
              [36.543861016707098, 10.4501055589136],
              [36.546311171644597, 10.453170994304701],
              [36.5467940161098, 10.4561745615931],
              [36.546526767312798, 10.4579457692715],
              [36.546061889153201, 10.459083135457201],
              [36.544981665024103, 10.460258041393001],
              [36.542648291073597, 10.462481958307199],
              [36.540795515800099, 10.465933746135899],
              [36.540602378014, 10.466558729844399],
              [36.540350849734502, 10.467126293463799],
              [36.540099321454903, 10.4677490663568],
              [36.539418847627203, 10.469052026874699],
              [36.539167319347598, 10.4696770042988],
              [36.538608118083303, 10.470807702303],
              [36.537932135832001, 10.4719936057399],
              [36.537563826565503, 10.4726141604788],
              [36.537022591606799, 10.4732302972289],
              [36.5362904646502, 10.4743565655418],
              [36.533245175837102, 10.4752642023216],
              [36.533043054898101, 10.476494255709801],
              [36.532933011275802, 10.4771766351066],
              [36.532634321443901, 10.4789963061504],
              [36.5325489814919, 10.4795196804301],
              [36.532436692081397, 10.480208678133501],
              [36.5323288942473, 10.4808645495234],
              [36.528582919512502, 10.482525200838801],
              [36.526193400856698, 10.483582978084399],
              [36.525059277810499, 10.4828586550161],
              [36.524493339181497, 10.4824390768539],
              [36.523927400552502, 10.4820172898126],
              [36.523305317218302, 10.481480669867],
              [36.520936010656399, 10.479453430570199],
              [36.518007502830201, 10.478612056116299],
              [36.516850921901899, 10.4785193060367],
              [36.513374441752298, 10.478468514314599],
              [36.512213369247597, 10.4785480143976],
              [36.511573319607699, 10.4785855560964],
              [36.5101741935527, 10.4788858895228],
              [36.509527406548102, 10.4790956810817],
              [36.508366334043401, 10.479175181003701],
              [36.504521544627302, 10.4797449298479],
              [36.501772699857902, 10.4805200515928],
              [36.500950741373003, 10.480838049696199],
              [36.4997290325866, 10.4809727571277],
              [36.498388297025002, 10.481160464106599],
              [36.497752738961502, 10.4811405892554],
              [36.494682746477999, 10.4810434232978],
              [36.493988797920998, 10.4810213401214],
              [36.491601525053497, 10.4824942845393],
              [36.491125417952901, 10.4827879892601],
              [36.490606640876301, 10.4831081933376],
              [36.487042574986603, 10.482472201466299],
              [36.485115688702201, 10.482871904843501],
              [36.484415002780501, 10.483077277096299],
              [36.483074267219003, 10.483267191101399],
              [36.482321928168503, 10.483242899781599],
              [36.4791621041567, 10.4823971190064],
              [36.475189304812602, 10.481524836152801],
              [36.4718228682854, 10.482204996158501],
              [36.470248570750002, 10.4801600950123],
              [36.469621995839297, 10.4798531378433],
              [36.468348633924101, 10.479755971481801],
              [36.466495858650603, 10.4796985549812],
              [36.465222496735301, 10.479658805089899],
              [36.464591430248198, 10.479464472214501],
              [36.464016508466401, 10.4792745558776],
              [36.462053689570602, 10.4790404727905],
              [36.460894862854097, 10.479062556108101],
              [36.459399167906, 10.478726889509799],
              [36.4588287377006, 10.4784221392568],
              [36.457018632403098, 10.4770441343705],
              [36.456452693774096, 10.476622339983001],
              [36.455350011762803, 10.4748644891281],
              [36.455156873976797, 10.4737073054846],
              [36.4550041603785, 10.4730712943139],
              [36.454849200992001, 10.4724904913061],
              [36.452938035225003, 10.468926147877101],
              [36.452686506945398, 10.4677093149329],
              [36.452533793347101, 10.4670732914577],
              [36.452437224454101, 10.466437266678099],
              [36.4524035376309, 10.465746029935101],
              [36.452365359231401, 10.4651122108649],
              [36.452329426619997, 10.464480598935101],
              [36.450146520479599, 10.4603287122849],
              [36.449760244907402, 10.459741260006799],
              [36.449257188348298, 10.459151598143199],
              [36.448754131789201, 10.458561935159301],
              [36.447251699476503, 10.45667588948],
              [36.446119822218499, 10.455834453226499],
              [36.445493247307802, 10.4554700509917],
              [36.444920571314199, 10.455280119960401],
              [36.444347895320597, 10.4550879803105],
              [36.440548021668803, 10.454279667377801],
              [36.436528060772297, 10.4527160396672],
              [36.435681398617099, 10.452183786065801],
              [36.4336265024046, 10.452636532963099],
              [36.430271294818397, 10.4538556335224],
              [36.429060814973099, 10.4548362109342],
              [36.425932431996102, 10.454906883060399],
              [36.4253530186379, 10.454677198591201],
              [36.422696251185101, 10.4559227930978],
              [36.421041105274099, 10.4566979743903],
              [36.418750401299597, 10.454359173661199],
              [36.417474793596199, 10.4539616420405],
              [36.416313721091399, 10.453564109910999],
              [36.414981968682703, 10.4532814200871],
              [36.4114515896161, 10.453926305871899],
              [36.410297254475999, 10.454677198591201],
              [36.409717841117804, 10.4550239337341],
              [36.409354023427703, 10.4558079512602],
              [36.408563505977703, 10.455774823799199],
              [36.407285652486003, 10.455871997674899],
              [36.406133563134198, 10.455955920542999],
              [36.403472304104902, 10.4569431167894],
              [36.402843483406102, 10.458782782849999],
              [36.401284906388099, 10.460222705941],
              [36.400085655483799, 10.4606666072644],
              [36.398336186468001, 10.4612098886171],
              [36.397759018897901, 10.4616140363234],
              [36.396777609449998, 10.462479749857399],
              [36.396375613360398, 10.4637429805782],
              [36.396146542963002, 10.464319383096701],
              [36.395919718353703, 10.464893576111701],
              [36.3955154764759, 10.4654677680638],
              [36.393588590191399, 10.466450517207599],
              [36.391293394640499, 10.4667464455533],
              [36.390716227070399, 10.467210213288199],
              [36.390428766179497, 10.467784400951601],
              [36.389680918705501, 10.4689349814927],
              [36.389281168404104, 10.470714949887601],
              [36.388944300172497, 10.4731287120426],
              [36.388775866056697, 10.4743918993835],
              [36.3886096777292, 10.476116627630701],
              [36.388263826344797, 10.476690798797],
              [36.387340807390402, 10.4779009714628],
              [36.386763639820302, 10.478305097413299],
              [36.386186472250301, 10.478879264523799],
              [36.385038874474802, 10.4812377551825],
              [36.386352660577799, 10.4839826800278],
              [36.3860225297109, 10.4844309639139],
              [36.385164638614597, 10.4855858992115],
              [36.384710989396098, 10.4861953853181],
              [36.383736317312803, 10.487507101346599],
              [36.382887409369403, 10.488653192113899],
              [36.382480921703298, 10.4891986326649],
              [36.382040747214099, 10.489790445616499],
              [36.3813872228449, 10.490671538175199],
              [36.379520972842101, 10.493184515198401],
              [36.378660835957596, 10.494343834272801],
              [36.377746800155997, 10.4955738118545],
              [36.377322346184201, 10.496145738722801],
              [36.375283170489297, 10.4988905313287],
              [36.373663957189599, 10.5010700073772],
              [36.372013302855102, 10.5038765845967],
              [36.371662959894302, 10.504470577608799],
              [36.3712924048396, 10.5050976916688],
              [36.3710453681364, 10.505519447239701],
              [36.367744059467299, 10.505274343286899],
              [36.366771633172199, 10.505331755041],
              [36.365615052243903, 10.505018198407701],
              [36.364456225527398, 10.5048503792337],
              [36.363876812169202, 10.505084442793301],
              [36.363185109400398, 10.506120061509399],
              [36.363189600976803, 10.5073234946759],
              [36.363425408738898, 10.507955019195901],
              [36.363600580219298, 10.5085291312756],
              [36.364418047127899, 10.510306663832401],
              [36.364310249293801, 10.5122012152195],
              [36.365156911448999, 10.515522172352901],
              [36.364880679499201, 10.5159924911865],
              [36.364687541713103, 10.5163192850715],
              [36.364409063975003, 10.5167918107527],
              [36.363623038101402, 10.5181276855172],
              [36.363209813070704, 10.5188298454716],
              [36.3629605305794, 10.5192515822862],
              [36.362223912046403, 10.520501333021899],
              [36.3615591587361, 10.5216340532552],
              [36.360737200251201, 10.523029523535801],
              [36.360386857290401, 10.523623479698699],
              [36.359843376543502, 10.5245464278978],
              [36.3615277177012, 10.526794170569399],
              [36.3591090037987, 10.5303026477449],
              [36.355592099461397, 10.526716890887],
              [36.354743191517898, 10.526427643904],
              [36.352542319071802, 10.5256813412749],
              [36.351307135556098, 10.5252640292541],
              [36.350076443616899, 10.5248445086639],
              [36.349342070872098, 10.5245972120487],
              [36.348800835913401, 10.5244117394572],
              [36.346577505585202, 10.5236588079103],
              [36.344767400287701, 10.521225567880601],
              [36.343608573571203, 10.520770713259401],
              [36.342391356361198, 10.5206603115512],
              [36.340594725792997, 10.5205521178388],
              [36.339438144864701, 10.5207861694954],
              [36.338167028737701, 10.521594308756001],
              [36.337589861167601, 10.5219983775924],
              [36.336433280239298, 10.5225746715745],
              [36.335853866881102, 10.522749105173],
              [36.332902901172702, 10.5231045961251],
              [36.332321242026303, 10.5228175537732],
              [36.3305223656698, 10.5219056405292],
              [36.3276881809484, 10.5225481753231],
              [36.326531600020097, 10.5234689187255],
              [36.324043266683098, 10.5238796091409],
              [36.319765040142499, 10.525674717278999],
              [36.318552314508899, 10.526769882671299],
              [36.317229545252999, 10.529759487748599],
              [36.316430044650197, 10.5323472170342],
              [36.315448635202301, 10.5334975572913],
              [36.313600351505201, 10.5343652775229],
              [36.313611580446299, 10.537694827639299],
              [36.311302910166098, 10.540112482390199],
              [36.310903159864601, 10.5414350087841],
              [36.310790870454099, 10.542757529497401],
              [36.310680826831799, 10.544075628795801],
              [36.312147326533101, 10.546614665137101],
              [36.311372529600597, 10.547742873693799],
              [36.310975025087401, 10.5483235351938],
              [36.310263110224703, 10.5493612165144],
              [36.309283946565003, 10.550787470660699],
              [36.308664109018999, 10.5516904671583],
              [36.308331732363797, 10.5521761854492],
              [36.307642275383301, 10.5531785289523],
              [36.307015700472597, 10.554092557404701],
              [36.304572282899798, 10.552478655088301],
              [36.303352819901598, 10.5517368312101],
              [36.302715016049902, 10.5516242327864],
              [36.301556189333397, 10.551626440599],
              [36.3008712239292, 10.5540395703222],
              [36.300875715505697, 10.5552472319764],
              [36.3009363517873, 10.5559360606312],
              [36.301017200162903, 10.5571437148367],
              [36.301057624350697, 10.5577707217306],
              [36.302065983257101, 10.561303131036],
              [36.301675216108499, 10.5643542168916],
              [36.301518010933798, 10.565588330566699],
              [36.301163176396599, 10.568356789359999],
              [36.300943089152, 10.570092030712599],
              [36.300792621341898, 10.571264306533999],
              [36.303137224233403, 10.572173865857801],
              [36.303723374956299, 10.5741254355062],
              [36.305293180915299, 10.5760151789058],
              [36.3061690383173, 10.5776179194443],
              [36.306231920387198, 10.578765882728399],
              [36.305713143310598, 10.579342070373],
              [36.304210710997999, 10.580953623938999],
              [36.3025443361459, 10.584518880163699],
              [36.299103788607702, 10.5844747286373],
              [36.299072347572803, 10.5847352225511],
              [36.2989331087038, 10.5858169321983],
              [36.298888192939501, 10.5861701426851],
              [36.298578274166502, 10.588596246218],
              [36.298501917367403, 10.5891834531039],
              [36.298342466404399, 10.5904417497851],
              [36.295737352080501, 10.5921768662076],
              [36.295391500696098, 10.592867819502899],
              [36.2945313638116, 10.5948788600019],
              [36.293951950453298, 10.5954550173605],
              [36.293374782883298, 10.595973778806],
              [36.292685325902703, 10.597181275448399],
              [36.2921059125444, 10.5975852449259],
              [36.290196992565697, 10.5981658121488],
              [36.286545340935703, 10.5980046662239],
              [36.2846948114505, 10.598355655184401],
              [36.282956571375699, 10.5985896476012],
              [36.282377158017397, 10.598821432366099],
              [36.281876347246502, 10.599631573927899],
              [36.281402485934201, 10.601351186105701],
              [36.281344095440701, 10.6020045920515],
              [36.281236297606597, 10.6031877830524],
              [36.281296933888299, 10.604393043809401],
              [36.281357570170002, 10.605026576406599],
              [36.281651768425498, 10.6062296225019],
              [36.282058256091602, 10.6068609438815],
              [36.282639915238001, 10.607317878472999],
              [36.283800987742801, 10.6080021753772],
              [36.284151330703601, 10.6085761006327],
              [36.284218704349897, 10.6098983320271],
              [36.284274849055102, 10.6109865749468],
              [36.284452266323697, 10.6122491967676],
              [36.2848048550728, 10.6133948228946],
              [36.2852719790205, 10.614026129484699],
              [36.286087200140798, 10.615171748953699],
              [36.287248272645598, 10.6163173641296],
              [36.287771541298603, 10.6169442499999],
              [36.288353200445002, 10.6174607676734],
              [36.289689444430202, 10.6182576158764],
              [36.290792126441403, 10.6191736576425],
              [36.291259250389203, 10.6198027449758],
              [36.291724128548701, 10.620376648080001],
              [36.292305787695199, 10.620833562473001],
              [36.293523004905097, 10.6212308787811],
              [36.2967479567751, 10.6194186707579],
              [36.296774906233701, 10.6194628172442],
              [36.297626059965403, 10.6208379771015],
              [36.297992123443599, 10.6214273294314],
              [36.299595616225801, 10.624014285761101],
              [36.3014034757351, 10.621665718815001],
              [36.303777273873401, 10.6213699393669],
              [36.307143710400602, 10.6230254176687],
              [36.307552443854902, 10.623597107425001],
              [36.308082449872501, 10.625950074075901],
              [36.306312768762801, 10.627839497587701],
              [36.305843399026799, 10.6283427532803],
              [36.3039838863887, 10.630331488054701],
              [36.304105158952098, 10.630508067652601],
              [36.304527367135599, 10.631115059241299],
              [36.304707030192397, 10.6313733062058],
              [36.304987753718699, 10.631777230507],
              [36.305816449568297, 10.6329713423915],
              [36.3031349784452, 10.6359554980582],
              [36.302905908047798, 10.636529370802],
              [36.302910399624203, 10.6376793202435],
              [36.302912645412398, 10.638884444948699],
              [36.303092308469203, 10.6402043379719],
              [36.305246019362897, 10.643009643467201],
              [36.305769288015902, 10.643638681676199],
              [36.306584509136201, 10.6448415756897],
              [36.307226804564401, 10.6459870795215],
              [36.308100416178199, 10.647192171391399],
              [36.309196360824799, 10.648441400790601],
              [36.3096702221372, 10.648907101606101],
              [36.311469098493603, 10.6495913053046],
              [36.312803096690601, 10.649759045331701],
              [36.314134849099297, 10.6497546311217],
              [36.316876956503997, 10.649688417963199],
              [36.3172564947116, 10.650313028186099],
              [36.318664603919402, 10.652621647083899],
              [36.318999226362799, 10.6531712105723],
              [36.319306899347602, 10.653674424463],
              [36.319812201694901, 10.654499870009699],
              [36.320611702297803, 10.655810867162],
              [36.323239274503798, 10.660116813041499],
              [36.324191488704997, 10.6616771792148],
              [36.324474458019502, 10.6621428597901],
              [36.324779885216103, 10.662641644829201],
              [36.322415070230598, 10.664135788040401],
              [36.321837902660597, 10.6647118147044],
              [36.3212045903853, 10.6658638647608],
              [36.321033910481297, 10.667068877967701],
              [36.3204589886995, 10.668276093371899],
              [36.320054746821597, 10.668852112196101],
              [36.319477579251597, 10.669313367862699],
              [36.317402470945197, 10.670611059477899],
              [36.314217943263003, 10.672601725307601],
              [36.313658741998701, 10.672851109354299],
              [36.312481948976497, 10.673526431497899],
              [36.311327613836397, 10.6744489279495],
              [36.3106359110676, 10.675656114053099],
              [36.3106426484322, 10.6779535061711],
              [36.310820065700902, 10.678584679062199],
              [36.312273090672903, 10.679842607137701],
              [36.3159786412199, 10.6793703329972],
              [36.317195858429898, 10.6791959886196],
              [36.318992488998099, 10.679189367945],
              [36.319571902356401, 10.679531435940699],
              [36.321382007653902, 10.6829697496264],
              [36.3216200612042, 10.684977989293399],
              [36.321849131601603, 10.686229270370401],
              [36.321974895741398, 10.686928837966899],
              [36.3202456388195, 10.689230558460499],
              [36.319089057891198, 10.690210384323599],
              [36.317588871366702, 10.691823559202801],
              [36.3166141992834, 10.6945246700318],
              [36.316618690859798, 10.6963055363214],
              [36.3166276740127, 10.6981415610047],
              [36.31634245891, 10.6999224060581],
              [36.317573150849199, 10.703592193796799],
              [36.320771153260701, 10.706911073613201],
              [36.321352812407198, 10.707539979588599],
              [36.322516130700102, 10.708685247117],
              [36.324321744421198, 10.7111478886419],
              [36.325487308502296, 10.7129242439793],
              [36.327966658686499, 10.714005498648801],
              [36.328137338590501, 10.7152654864644],
              [36.328384375293602, 10.717081536735501],
              [36.328662853031702, 10.7191314758964],
              [36.328743701407298, 10.719727257548101],
              [36.328826795570997, 10.7203428973563],
              [36.328909889734803, 10.720958535912001],
              [36.329087307003398, 10.7222736592408],
              [36.329460107846302, 10.725023042901],
              [36.331472334082797, 10.7263271158201],
              [36.333010699006799, 10.727324474055299],
              [36.334284060922101, 10.728149719312],
              [36.335139706230201, 10.7287035591172],
              [36.336258108758898, 10.7294273004413],
              [36.337805456835802, 10.730429061489],
              [36.33849042224, 10.7308747778927],
              [36.339554925851601, 10.731563209858599],
              [36.3408574830136, 10.732408302083501],
              [36.341807451426597, 10.7330217095816],
              [36.342883183979303, 10.7337211691307],
              [36.343974637049499, 10.734427246529],
              [36.344486676761399, 10.7347582197416],
              [36.345591604560902, 10.735475327125499],
              [36.346669582901903, 10.7361725745196],
              [36.347273699930398, 10.7365653270901],
              [36.347947436393497, 10.737000002092699],
              [36.348996219487702, 10.737679594244799],
              [36.3495644039049, 10.7380480737661],
              [36.351419424966601, 10.739250593628199],
              [36.352010067266001, 10.739632309941401],
              [36.354285050723, 10.7411062158027],
              [36.356649865708398, 10.739515368000699],
              [36.358623913545301, 10.7403714702169],
              [36.360252109997703, 10.7423881550225],
              [36.361768017039701, 10.744263615677699],
              [36.3612222905046, 10.7468892410248],
              [36.361664710782001, 10.747288598845699],
              [36.362050986354198, 10.7476372091083],
              [36.362589975524699, 10.7481270279109],
              [36.3631357020598, 10.7486212586899],
              [36.363683674383097, 10.7491154886596],
              [36.364256350376699, 10.7496361943594],
              [36.365898021558401, 10.7511210832215],
              [36.366946804652599, 10.7520720260395],
              [36.367452107, 10.752528742290499],
              [36.368343684919502, 10.753336267883],
              [36.3695092490006, 10.754390901877301],
              [36.370427776378598, 10.7552226921967],
              [36.371478805260999, 10.756173622085999],
              [36.372994712302997, 10.757545956454999],
              [36.373511243591402, 10.758015902319601],
              [36.3752988910068, 10.759633128266101],
              [36.3756424966029, 10.762560880865699],
              [36.3757143618257, 10.7631720208127],
              [36.375864829635702, 10.76444283493],
              [36.376001822716603, 10.765603330640699],
              [36.376062458998298, 10.7661306266448],
              [36.376194960502701, 10.7672514033707],
              [36.376253350996102, 10.767752221544299],
              [36.372990220726599, 10.769775341990799],
              [36.372648860918602, 10.771553558774601],
              [36.372651106706797, 10.774818743393899],
              [36.372657844071398, 10.774818743393899],
              [36.373306876864199, 10.7771220009749],
              [36.374065953279299, 10.7783243650288],
              [36.374416296240099, 10.7790126887105],
              [36.375406688840798, 10.780272405217699],
              [36.375581860321198, 10.780846004681999],
              [36.376397081441603, 10.782163073160399],
              [36.377331329337103, 10.783998576063],
              [36.377836631684403, 10.7865973819308],
              [36.376408310382601, 10.786634885755999],
              [36.373652728248601, 10.7874114344981],
              [36.372475935226397, 10.787713670256201],
              [36.370751169880897, 10.787733525149401],
              [36.370052729747499, 10.787872509364901],
              [36.365621789608603, 10.788563017293001],
              [36.3644382592217, 10.788761565605],
              [36.363169388882902, 10.7890704182741],
              [36.3613525462208, 10.789284408865999],
              [36.358686795615199, 10.789595467083601],
              [36.357718860896497, 10.7897344504385],
              [36.3563534216647, 10.7895623757991],
              [36.352461470696198, 10.789787396461501],
              [36.350831028455502, 10.789985943965],
              [36.349234273038, 10.790045508190399],
              [36.348156294697098, 10.790272734571801],
              [36.346855983323302, 10.7904889303868],
              [36.345245753176499, 10.7908043995114],
              [36.342889921343897, 10.7909919159671],
              [36.341180876515899, 10.788684352388101],
              [36.340587988428403, 10.7875768920675],
              [36.339932218271002, 10.7858142127471],
              [36.339368525430203, 10.7845567255664],
              [36.338578007980097, 10.782893304622901],
              [36.338014315139397, 10.781918191517899],
              [36.337185619289798, 10.7810820626056],
              [36.336563535955499, 10.7803474144405],
              [36.335755052199801, 10.7791053475475],
              [36.335406955027203, 10.7782317059514],
              [36.334708514893798, 10.7766653219931],
              [36.334364909297598, 10.775502662714301],
              [36.333971896360801, 10.774359854720901],
              [36.334315501957001, 10.7710395095921],
              [36.333338584085503, 10.770788000230301],
              [36.332390861460802, 10.770545315559399],
              [36.331281442084901, 10.770260712377899],
              [36.330239396355303, 10.7699937587618],
              [36.329329852130101, 10.769762104605601],
              [36.328341705317598, 10.7695083879445],
              [36.3252312886463, 10.7687097306247],
              [36.326569778419703, 10.7656055369021],
              [36.325680446288402, 10.764601686317899],
              [36.325143702906097, 10.7637897783446],
              [36.324283566021599, 10.7626248630842],
              [36.323436903866302, 10.7616474780573],
              [36.322760921615, 10.7608311495339],
              [36.320562294957099, 10.7589315224405],
              [36.319791989601001, 10.7583667065017],
              [36.318419813004503, 10.757104692065701],
              [36.316762421305299, 10.755957401631999],
              [36.314312266367899, 10.753669426718901],
              [36.313366789531301, 10.752850870245],
              [36.312654874668702, 10.7522551538947],
              [36.311017695063398, 10.750196613896801],
              [36.3107594294192, 10.749177267348999],
              [36.311309647530699, 10.7482241090207],
              [36.313139964922101, 10.7462207016117],
              [36.312095673404301, 10.746218495208501],
              [36.311064856615801, 10.746165541528001],
              [36.310031794038998, 10.746194224772699],
              [36.309088562990702, 10.7463111641271],
              [36.3083407155167, 10.746507533884399],
              [36.3059422137081, 10.7468782090333],
              [36.305025932118298, 10.746657569118501],
              [36.303617822910397, 10.746388388204201],
              [36.300675840354899, 10.7453866800703],
              [36.299972908645103, 10.7451285300787],
              [36.298818573505002, 10.744709311673301],
              [36.297742840952303, 10.7443960006956],
              [36.296404351179, 10.743930446459199],
              [36.295191625545399, 10.743520052082101],
              [36.293808220007797, 10.7432751390429],
              [36.292233922472398, 10.7430897993136],
              [36.2910234426271, 10.7428890144786],
              [36.2871000506237, 10.743008161539599],
              [36.286109658022902, 10.743120689276401],
              [36.283913277153303, 10.7449520171369],
              [36.283749334613901, 10.747200343019101],
              [36.283668486238298, 10.748903675914599],
              [36.283960438705698, 10.749678115541901],
              [36.2845847678282, 10.7505871418568],
              [36.285022696529197, 10.7510173839395],
              [36.285577406217101, 10.751957295278499],
              [36.286044530164801, 10.752751584268299],
              [36.286590256699903, 10.7538194583924],
              [36.286462246771997, 10.755802958355799],
              [36.285894062354799, 10.7571775007344],
              [36.285954698636402, 10.7578460158709],
              [36.286242159527298, 10.759732411977501],
              [36.286500425171504, 10.7607164221048],
              [36.286545340935703, 10.7617467611053],
              [36.286720512416103, 10.7627616526095],
              [36.286859751285199, 10.763774334421701],
              [36.287077592741603, 10.7644163596906],
              [36.287564928783198, 10.764989989358],
              [36.2879175175322, 10.765755562636199],
              [36.288618203453801, 10.7670418096149],
              [36.288593499783502, 10.7693318892715],
              [36.28838464148, 10.7704923661506],
              [36.288373412538903, 10.774245132443401],
              [36.288400361997397, 10.7753901470619],
              [36.287324629444697, 10.7798135248589],
              [36.287104542200098, 10.78098499208],
              [36.286839539191298, 10.781638011376],
              [36.286318516326503, 10.782745493563599],
              [36.286177031669297, 10.7834999910459],
              [36.286212964280601, 10.7856994948362],
              [36.286100674870099, 10.786628267434301],
              [36.285238292197299, 10.788713031585299],
              [36.285011467588099, 10.789321912356099],
              [36.284191754891303, 10.7927413285584],
              [36.283666240450103, 10.7939546604412],
              [36.283342846947903, 10.7948503168338],
              [36.283062123421601, 10.7955606631091],
              [36.282013340327403, 10.7974247625848],
              [36.2817707952006, 10.7980689220815],
              [36.279816959457698, 10.801163956838399],
              [36.280474975403301, 10.8043582286854],
              [36.283904294000401, 10.805050904699099],
              [36.284724006697203, 10.8048479552046],
              [36.288463244067302, 10.804391318340301],
              [36.289224566270597, 10.8046979489683],
              [36.2898174543582, 10.805057522615099],
              [36.2912367925071, 10.805514158466099],
              [36.293262493472703, 10.8061296230793],
              [36.294785137879302, 10.8064053684644],
              [36.295564426388303, 10.806795823496399],
              [36.298618698354304, 10.8084458764411],
              [36.300038036503203, 10.8104223985074],
              [36.299856127658202, 10.8110797656846],
              [36.292889692629799, 10.812540086273501],
              [36.292662868020599, 10.8149136435441],
              [36.295470103283499, 10.815615118769999],
              [36.298418823203598, 10.816925417155501],
              [36.301634791920698, 10.819155558313],
              [36.303132732656998, 10.819466585583699],
              [36.306683323817502, 10.819265851708399],
              [36.307455874961803, 10.821784941810201],
              [36.309218818706903, 10.8253495725374],
              [36.310433790128698, 10.8278024373687],
              [36.310570783209499, 10.8280825744305],
              [36.310799853607001, 10.8285435868201],
              [36.311008711910503, 10.828967099969001],
              [36.311201849696602, 10.829359731415799],
              [36.3113500717185, 10.8296597191407],
              [36.3115566842338, 10.8300722017719],
              [36.309337845482098, 10.832158869905401],
              [36.309429922798699, 10.832959564282501],
              [36.309629797949398, 10.833658791505],
              [36.3097286126307, 10.834565358122701],
              [36.309717383689602, 10.835568974898999],
              [36.309658993196102, 10.836795367974],
              [36.309429922798699, 10.837684279238101],
              [36.310009336156902, 10.8390937385846],
              [36.311197358120197, 10.8412575435354],
              [36.311729609925997, 10.8421354130491],
              [36.312066478157597, 10.8427155113393],
              [36.312836783513703, 10.844052159391],
              [36.313252254332603, 10.8446807789344],
              [36.313730607221402, 10.8457461206172],
              [36.314105653852501, 10.850230219051999],
              [36.313360052166701, 10.8513617098994],
              [36.312086690251498, 10.852502018954],
              [36.310858244100402, 10.8533997060002],
              [36.310056497709397, 10.853979782436101],
              [36.308888687840003, 10.8536401180437],
              [36.3082239345297, 10.8540393339456],
              [36.308073466719698, 10.855014212525401],
              [36.308053254625797, 10.856180974789],
              [36.307981389402997, 10.8577094485158],
              [36.308113890907499, 10.860863417199599],
              [36.3091649197899, 10.8629498704843],
              [36.310076709803198, 10.863792387657099],
              [36.314292054273999, 10.867429299684799],
              [36.314804093985899, 10.8677711539037],
              [36.315522746213198, 10.869220170342],
              [36.3157069008465, 10.870269986075201],
              [36.316699539235401, 10.872464441905599],
              [36.317552938755298, 10.872912153022099],
              [36.3186825702251, 10.8736134922378],
              [36.319576393932799, 10.875073507579501],
              [36.321128233586101, 10.8777354809007],
              [36.3232145708335, 10.8790322745396],
              [36.3242072092225, 10.879632149960999],
              [36.326470963738402, 10.880657669484799],
              [36.328150813319702, 10.8811582981642],
              [36.328222678542502, 10.8835401323462],
              [36.330922115971198, 10.887055508381],
              [36.332310013085198, 10.8874282152023],
              [36.333008453218604, 10.887803126919],
              [36.329954181252603, 10.892149863014801],
              [36.330809826560703, 10.8964899197638],
              [36.331634030833897, 10.897828532221901],
              [36.332316750449799, 10.8986952089053],
              [36.333502526624898, 10.8999014951509],
              [36.334573767601199, 10.9006909814903],
              [36.335299157193099, 10.901094544927201],
              [36.335941452621299, 10.901394460569399],
              [36.336547815438003, 10.9018730018137],
              [36.337032905691501, 10.9024287261635],
              [36.339260727596098, 10.9045567879822],
              [36.340145568150902, 10.9053594933557],
              [36.341762535662397, 10.9066980659039],
              [36.343761287169499, 10.9082593587256],
              [36.344726976099899, 10.9091348258303],
              [36.3451491842835, 10.909827258943601],
              [36.345600587713797, 10.910385173622601],
              [36.3462451289301, 10.9114304338783],
              [36.346489919844998, 10.9124734852792],
              [36.346525852456402, 10.913112986442],
              [36.345593850349097, 10.915532052528601],
              [36.345742072371003, 10.9169257079133],
              [36.346876195417202, 10.917485814406501],
              [36.3482551093783, 10.917988586264601],
              [36.348978253181997, 10.9182046896965],
              [36.351713623222203, 10.9175629943523],
              [36.352019050418797, 10.9183744851398],
              [36.352407571779203, 10.919393255761401],
              [36.352650116905899, 10.920032742028701],
              [36.353157665041401, 10.9213734535948],
              [36.353683179482601, 10.922762671364501],
              [36.353907758303599, 10.9233536382228],
              [36.352133585617501, 10.9263834274446],
              [36.351655232728703, 10.9270449493622],
              [36.351327347649999, 10.9277792369639],
              [36.351122980922902, 10.928392244441],
              [36.350835520032, 10.929168424753501],
              [36.350554796505698, 10.929814505112899],
              [36.349977628935598, 10.930991999487301],
              [36.349757541690998, 10.931653511130399],
              [36.350065214675801, 10.9328155630118],
              [36.352724227916802, 10.9360569360389],
              [36.353274446028301, 10.9364847064316],
              [36.354433272744899, 10.937379935256001],
              [36.355603328402402, 10.938202396793599],
              [36.357417925276401, 10.9396047656278],
              [36.3602566015742, 10.940105295036],
              [36.362050986354198, 10.941906752914999],
              [36.362965022155798, 10.9428262198517],
              [36.3643349529641, 10.944206517379],
              [36.365235514036399, 10.9451105426262],
              [36.365617298032099, 10.945496406221],
              [36.3660013278161, 10.9458800643828],
              [36.366466205975598, 10.946349714214699],
              [36.3669804914758, 10.946865666693],
              [36.367571133775101, 10.947460995358901],
              [36.368336947554802, 10.9482305110098],
              [36.368907377760202, 10.9488037878456],
              [36.369446366930703, 10.9493439900023],
              [36.370369385885098, 10.950272253447899],
              [36.371025156042499, 10.9509315171683],
              [36.371543933119099, 10.951454076331901],
              [36.372105380171703, 10.9520185273882],
              [36.372725217717701, 10.952642509139601],
              [36.3733338263227, 10.9532532603224],
              [36.373980613327298, 10.9539036979222],
              [36.374739689742398, 10.954666582235101],
              [36.375631267661902, 10.955563960976701],
              [36.3774189150773, 10.957360915142701],
              [36.3793772423967, 10.9594224380778],
              [36.380035258342303, 10.9601169586129],
              [36.380598951183103, 10.9607100558109],
              [36.381079549860097, 10.961217163996499],
              [36.381706124770702, 10.961876403338101],
              [36.382139561895301, 10.962332798944701],
              [36.382564015867104, 10.9627803746287],
              [36.383031139814797, 10.963272045658501],
              [36.383691401548603, 10.963968761951399],
              [36.384412299564097, 10.9647272107334],
              [36.385265699084101, 10.9656267637439],
              [36.3859843513114, 10.9663830034958],
              [36.386491899446902, 10.9669187640154],
              [36.387012922311698, 10.9674655474131],
              [36.387623776704899, 10.9681093394721],
              [36.3882076816395, 10.9687244682463],
              [36.388632135611303, 10.969174239013],
              [36.389278922615901, 10.9698533033838],
              [36.390199695782101, 10.9708233926359],
              [36.392827267988103, 10.973592538983601],
              [36.394578982792197, 10.9754356825184],
              [36.396420529124597, 10.9773736164894],
              [36.398037496636, 10.9790778414316],
              [36.399292892245597, 10.9804006483206],
              [36.4003259548223, 10.9814875502131],
              [36.400988462344401, 10.982186428221199],
              [36.403371243635497, 10.984695320500901],
              [36.406140300498798, 10.9876120463187],
              [36.408808296892602, 10.9904207180083],
              [36.410425264403997, 10.9921248676249],
              [36.4125542716274, 10.994364718123499],
              [36.4131965670555, 10.9950437245604],
              [36.413838862483701, 10.995720524875001],
              [36.414438487935797, 10.996351028005099],
              [36.415365998466697, 10.997325439281401],
              [36.417456827290501, 10.9995277732649],
              [36.417836365497998, 11.001902042821699],
              [36.418254082105101, 11.0045187875753],
              [36.4185325598432, 11.006264740820299],
              [36.418689765017902, 11.0072457327371],
              [36.418793071275601, 11.0078960514206],
              [36.419046845343402, 11.009492081696401],
              [36.419929440110003, 11.015003169456101],
              [36.419156888965702, 11.019899133271799],
              [36.421061317368, 11.022092481003099],
              [36.422175228320299, 11.029066997648],
              [36.422516588128303, 11.031202965385001],
              [36.422929813159001, 11.0337952025919],
              [36.427234989158102, 11.035501305418],
              [36.434603420276098, 11.038421936482001],
              [36.437693624853502, 11.039645286524401],
              [36.439404915469702, 11.0446686830854],
              [36.439744029489503, 11.0451866677501],
              [36.440473910657801, 11.0462160217961],
              [36.440947771970201, 11.046881683518301],
              [36.441724814691, 11.0479749524744],
              [36.442998176606203, 11.0494936197294],
              [36.445441594179002, 11.0512877970394],
              [36.447929927516, 11.0531150253065],
              [36.450422752429503, 11.0534588691603],
              [36.453268166091902, 11.0536263826866],
              [36.457735038842202, 11.054067207298701],
              [36.460095362251202, 11.0546512988891],
              [36.462235598415603, 11.055175878269299],
              [36.463109210029401, 11.055164857703801],
              [36.463931168514399, 11.0551538371378],
              [36.4656559338599, 11.055319145584001],
              [36.466727174836201, 11.0554205347182],
              [36.469830854142899, 11.055200123512099],
              [36.4714725253246, 11.0575122288176],
              [36.472640335193901, 11.059156479456901],
              [36.4733073342924, 11.0600954173741],
              [36.473866535556802, 11.060882271481301],
              [36.474369592115899, 11.0615919812171],
              [36.474751376111598, 11.0621297725431],
              [36.475101719072399, 11.062623481744],
              [36.476096603249601, 11.064020850598601],
              [36.477783190195503, 11.066396803162901],
              [36.4788184985605, 11.0678536625056],
              [36.482746382140299, 11.066493780409299],
              [36.485416624322298, 11.066392395105501],
              [36.486472144781203, 11.0659692212846],
              [36.490096846952603, 11.0665048005489],
              [36.4888684008016, 11.0685104590529],
              [36.488969461270997, 11.069914411833899],
              [36.489021114399897, 11.070619691695899],
              [36.489079504893297, 11.071424149464701],
              [36.489441076795202, 11.072197749234499],
              [36.489804894485303, 11.0729779588979],
              [36.4907616002628, 11.0750254483807],
              [36.491197283175602, 11.075746143639201],
              [36.491942884861501, 11.076978153748099],
              [36.493281374634797, 11.0788470965699],
              [36.4948781300523, 11.0803766234405],
              [36.496690481138003, 11.0817783153954],
              [36.499778439927198, 11.0840351134868],
              [36.501482993178797, 11.086576194401101],
              [36.502947247091903, 11.088907886687601],
              [36.502915806056997, 11.089981164127],
              [36.503376192640097, 11.090920003173199],
              [36.503957851786602, 11.091413663809499],
              [36.506084613221702, 11.0927447802981],
              [36.506969453776598, 11.093245049331401],
              [36.5080923478817, 11.0941640435178],
              [36.508745872250898, 11.0946973673557],
              [36.510980431520203, 11.0980317211807],
              [36.512157224542399, 11.1006387935258],
              [36.512752358418098, 11.1019566454158],
              [36.513697835254597, 11.103248046347201],
              [36.514578184233102, 11.104444680677799],
              [36.515002638204798, 11.105024264235],
              [36.516543248917102, 11.1064677087764],
              [36.517994028100901, 11.107829608684099],
              [36.518780053974503, 11.1084929265641],
              [36.519745742905002, 11.1093082986364],
              [36.5208910948922, 11.1102757236262],
              [36.521690595495102, 11.110950054409701],
              [36.522858405364403, 11.1116023467565],
              [36.523388411382101, 11.1119218813266],
              [36.524147487797102, 11.1123780438651],
              [36.525061523598701, 11.112931167532],
              [36.527114174022898, 11.1137222868105],
              [36.530565950502201, 11.1147976767062],
              [36.531509181550497, 11.1140484300666],
              [36.5326208467146, 11.1131647572915],
              [36.533550603033703, 11.112426524865899],
              [36.534365824154001, 11.1117786417351],
              [36.534700446597299, 11.111514199227299],
              [36.535282105743804, 11.111051424262101],
              [36.5333597110358, 11.1082439070716],
              [36.533442805199599, 11.1073271609143],
              [36.533559586186499, 11.106035778028501],
              [36.533164327461499, 11.103627091270001],
              [36.533034071745298, 11.1023599333507],
              [36.532953223369702, 11.101139049040601],
              [36.534026710134199, 11.0979435695535],
              [36.538302690886702, 11.096502286675699],
              [36.540454155992101, 11.0970025492754],
              [36.5428324457068, 11.096539751305301],
              [36.546394265808402, 11.0946136223547],
              [36.551856022735798, 11.0925420278469],
              [36.553118155710003, 11.0924670975576],
              [36.556244292898697, 11.092751391788701],
              [36.558321646993299, 11.0950565890615],
              [36.5585035558383, 11.0982036167772],
              [36.5603451021707, 11.1013704444734],
              [36.563406111501401, 11.1021020935877],
              [36.5661414815415, 11.101954441655],
              [36.569197999295703, 11.101465206359601],
              [36.570195129261101, 11.1012139771056],
              [36.573759195150799, 11.100420620147499],
              [36.574632806764697, 11.1007864461238],
              [36.576501302555599, 11.100883411968301],
              [36.577819580235101, 11.103583016304199],
              [36.577139106407401, 11.1053349910674],
              [36.576330622651597, 11.1076136443978],
              [36.578003734868297, 11.1093766134903],
              [36.577837546540799, 11.1102669088343],
              [36.5774041094162, 11.1107605367653],
              [36.576076848583902, 11.1110580353382],
              [36.573918646113803, 11.1118910297282],
              [36.572732869938697, 11.112911334430599],
              [36.568394007116403, 11.1112167011196],
              [36.567372173480798, 11.111262978622999],
              [36.566006734248901, 11.1114833475858],
              [36.564441419866299, 11.1115340324237],
              [36.5609155323762, 11.112657911349601],
              [36.560032937609499, 11.113305792527401],
              [36.559572551026399, 11.1137531382152],
              [36.557544604272501, 11.1156571043049],
              [36.557304304934, 11.116342440908101],
              [36.556816968892399, 11.117931269731301],
              [36.556035434595202, 11.1190771603186],
              [36.555554835918201, 11.119905724552799],
              [36.555220213474797, 11.1208620962993],
              [36.5550607625119, 11.121620139878299],
              [36.5550674998765, 11.1223914031241],
              [36.554737369009601, 11.125150305224601],
              [36.554360076590299, 11.126243281580001],
              [36.554137743557497, 11.1268801147029],
              [36.5510924547443, 11.128019359411899],
              [36.5527139138321, 11.1307495621557],
              [36.552738617502499, 11.131833703360901],
              [36.552828449030898, 11.134125370672701],
              [36.550739865995297, 11.136818056712601],
              [36.547892206544603, 11.138787976547199],
              [36.544689712556703, 11.138871708862199],
              [36.543387155394797, 11.138849674044801],
              [36.542753843119499, 11.1389730690008],
              [36.541981291975098, 11.1392991839895],
              [36.541258148171401, 11.139724455013599],
              [36.540508054909203, 11.140121080279],
              [36.539187531441499, 11.140511094596199],
              [36.538444175543901, 11.140693982485701],
              [36.537359459838299, 11.140887887592401],
              [36.532764577160101, 11.1415268921431],
              [36.531722531430503, 11.1415158748352],
              [36.529047797672, 11.1417406278347],
              [36.528055159283099, 11.1415709613707],
              [36.5255600885814, 11.141817748922],
              [36.524922284729698, 11.1419169045757],
              [36.524304692971903, 11.1417075759339],
              [36.523630956508804, 11.1413440047771],
              [36.5230897215501, 11.1409143291879],
              [36.522375560899199, 11.140519908472999],
              [36.521638942366202, 11.1401276906956],
              [36.521016859032002, 11.1398963260283],
              [36.520111806383198, 11.139389526590699],
              [36.519494214625396, 11.139111888263599],
              [36.519015861736598, 11.138708651173999],
              [36.514598396327003, 11.137194854490801],
              [36.513830336759, 11.1373799479576],
              [36.513017361426897, 11.1374901225843],
              [36.510481866537503, 11.1374372387686],
              [36.509287107209602, 11.137507750520699],
              [36.508368579831597, 11.137527581947801],
              [36.507324288313797, 11.1374923260764],
              [36.505891475435597, 11.1371970579851],
              [36.504916803352401, 11.1371662090628],
              [36.503921919175198, 11.137417407335301],
              [36.502675506718496, 11.137543006390301],
              [36.5019456255501, 11.1375584308319],
              [36.500757603586898, 11.1377897973579],
              [36.500054671877102, 11.1380145532356],
              [36.496400774458898, 11.141648082503],
              [36.495859539500202, 11.1419433460776],
              [36.494835460076303, 11.1425845517635],
              [36.492210133658503, 11.1448012175752],
              [36.491646440817703, 11.145100884818101],
              [36.490748125533599, 11.1456319120415],
              [36.490152991657901, 11.1459602229934],
              [36.489461288889103, 11.1461827691325],
              [36.488089112292599, 11.1465331136994],
              [36.487381689006298, 11.146619047208],
              [36.486698969390403, 11.1470971895996],
              [36.486267778054, 11.1477758419668],
              [36.4854121327459, 11.148802631173201],
              [36.484888864092902, 11.149576025799099],
              [36.484354366498899, 11.1500012817965],
              [36.483379694415603, 11.150508062742601],
              [36.4832337181819, 11.151182300370801],
              [36.483220243452699, 11.1523412803512],
              [36.482899095738603, 11.153028733973301],
              [36.482654304823697, 11.154220757072499],
              [36.482622863788698, 11.155417181994499],
              [36.478894855359599, 11.157702053469601],
              [36.477989802710901, 11.157638156633],
              [36.477006147474803, 11.157794593690699],
              [36.474385312633302, 11.1574618894249],
              [36.4735341589016, 11.1569110537101],
              [36.473329792174503, 11.1562720829724],
              [36.473010890248602, 11.155553790051499],
              [36.4721529991523, 11.1548751558446],
              [36.469574834286902, 11.1539629632122],
              [36.465893987410197, 11.151305690094199],
              [36.464236595711, 11.1494151775136],
              [36.463506714542703, 11.149514330576899],
              [36.462756621280398, 11.1499219594815],
              [36.465530169720097, 11.154496177610801],
              [36.467645702214199, 11.157728493535799],
              [36.467984816234001, 11.160449604144],
              [36.468155496138003, 11.1630208649906],
              [36.468409270205697, 11.1665329017105],
              [36.468485627004902, 11.169095295667001],
              [36.468611391144698, 11.171712747818001],
              [36.468485627004902, 11.1770863823869],
              [36.467834348423899, 11.1794437880238],
              [36.466924804198698, 11.182184523375801],
              [36.466630605943202, 11.184127697852199],
              [36.466329670322999, 11.185489234109699],
              [36.465721061718, 11.1875072922623],
              [36.464912577962302, 11.1896795531223],
              [36.468222869784299, 11.1955485415517],
              [36.4688269868129, 11.1988883482157],
              [36.468669781638098, 11.2024770552924],
              [36.468247573454597, 11.2064512375782],
              [36.467540150168396, 11.215326860997401],
              [36.460973465441398, 11.224217631633699],
              [36.4599381570765, 11.2261847547567],
              [36.459594551480301, 11.228858963348801],
              [36.4707853141322, 11.233284349656101],
              [36.475806896570496, 11.2446547919621],
              [36.471935157695903, 11.2530733000187],
              [36.467133662502299, 11.2633130634854],
              [36.467129170925901, 11.2638174433653],
              [36.467124679349403, 11.2643328349928],
              [36.467117941984803, 11.265114730784401],
              [36.467111204620203, 11.265793106256201],
              [36.4670954841027, 11.267513265459],
              [36.483184310841303, 11.271772872768301],
              [36.490316934197203, 11.279098192530499],
              [36.494941012122197, 11.288940608584401],
              [36.504934769658, 11.2849763732829],
              [36.514746618348802, 11.289618927806201],
              [36.521012367455597, 11.294446400114399],
              [36.528612114759198, 11.300302244026399],
              [36.530485102126597, 11.3046670713253],
              [36.530788283535003, 11.3046692735423],
              [36.534805998643201, 11.304702306795001],
              [36.535652660798497, 11.3047111156617],
              [36.536696952316298, 11.304719924528101],
              [36.537390900873298, 11.304724328961299],
              [36.538057899971697, 11.304730935610801],
              [36.5387990100811, 11.304737542260201],
              [36.539775927952597, 11.304744148909499],
              [36.541316538664901, 11.304757362207599],
              [36.542172183973001, 11.304763968856401],
              [36.543274865984301, 11.304772777721199],
              [36.544087841316397, 11.3047793843697],
              [36.544983910812299, 11.304788193234],
              [36.547676610876401, 11.304810215393699],
              [36.555330257097097, 11.3023657453358],
              [36.556388023344198, 11.302160937438501],
              [36.557034810348803, 11.302066241264599],
              [36.558667498377602, 11.301823995095701],
              [36.561838551330602, 11.3100250151656],
              [36.562289954760899, 11.313495623215999],
              [36.562321395795799, 11.3169177425101],
              [36.5624718636059, 11.319734243726799],
              [36.558029694525899, 11.327600040897799],
              [36.554759826891697, 11.3313500914608],
              [36.547458769419997, 11.337258027880599],
              [36.534518537752298, 11.3352806553766],
              [36.537667132823103, 11.3451827943881],
              [36.538062391548202, 11.345352341032999],
              [36.538484599731703, 11.345537300894501],
              [36.538956215255901, 11.3457398758434],
              [36.539414356050699, 11.34593804685],
              [36.5396816048478, 11.3460547474896],
              [36.551126141567501, 11.351006774065601],
              [36.551366440906001, 11.3511124633363],
              [36.551891955347202, 11.351339254764399],
              [36.552738617502499, 11.3517047629002],
              [36.553203495661997, 11.3519073334726],
              [36.553374175565999, 11.3519799946212],
              [36.554043420452601, 11.3522706390305],
              [36.554822708961602, 11.352607521952599],
              [36.555433563354804, 11.3528717435741],
              [36.556127511911797, 11.353171194449301],
              [36.558815720399501, 11.354335967291901],
              [36.563601495075702, 11.3580174108696],
              [36.5642931978444, 11.369688946198499],
              [36.564021457471, 11.3708514498603],
              [36.563763191826801, 11.3718047873915],
              [36.5633858994075, 11.3730025094677],
              [36.5566238311063, 11.378953616344999],
              [36.551671868102602, 11.3862189499885],
              [36.5509891484866, 11.387046745929],
              [36.543061516104302, 11.394131347751999],
              [36.540321654487698, 11.394107130955],
              [36.539470500756003, 11.3941005263737],
              [36.538810239022197, 11.3940939217922],
              [36.538405997144302, 11.394091720264999],
              [36.537977051596201, 11.3940873172106],
              [36.533070004356702, 11.3940476897174],
              [36.533099199603399, 11.402243858615099],
              [36.5314597742099, 11.412749050823299],
              [36.5309409971333, 11.4160092797567],
              [36.530446923726998, 11.4185870574796],
              [36.530321159587302, 11.4335931769665],
              [36.530004503449597, 11.4344406457095],
              [36.5293330127747, 11.4355698663545],
              [36.522249796759397, 11.441042010226999],
              [36.5220970831611, 11.4414800413074],
              [36.521859029610802, 11.4421646010858],
              [36.521394151451297, 11.4435028996651],
              [36.520799017575598, 11.445215384341701],
              [36.5204441830384, 11.4462367070309],
              [36.519458282014, 11.4490673364242],
              [36.512197648730101, 11.458265585806],
              [36.503483990474201, 11.458129121981299],
              [36.4998121267503, 11.4451471492893],
              [36.497128409839, 11.4431881359302],
              [36.494471642386301, 11.441248919465],
              [36.491648686605899, 11.4406391971277],
              [36.490254052127298, 11.4403354360509],
              [36.489097471199003, 11.4400867039119],
              [36.488935774447903, 11.4400514851843],
              [36.488360852665998, 11.4399260184317],
              [36.487671395685503, 11.439776338724201],
              [36.486359855370701, 11.4394923872969],
              [36.478587182374802, 11.437808483446799],
              [36.4767501276188, 11.4374100681299],
              [36.474153996447697, 11.4368465626362],
              [36.4726201231001, 11.4365163831151],
              [36.471917191390197, 11.436362299206801],
              [36.469599537957201, 11.4358604253244],
              [36.468993175140398, 11.4357283531023],
              [36.467131416714103, 11.428633783085999],
              [36.460097608039398, 11.427363653604999],
              [36.449382952488101, 11.425778735352401],
              [36.448805784918001, 11.426023077494101],
              [36.447218012653401, 11.427931580489],
              [36.446714956094297, 11.4276784349255],
              [36.4446039151766, 11.42661522109],
              [36.443570852599798, 11.426093518432801],
              [36.442867920890002, 11.425739112282599],
              [36.4424120258833, 11.4255101788817],
              [36.441911215112398, 11.4252570311558],
              [36.441181333944101, 11.424889416229799],
              [36.439198302954402, 11.423890029530501],
              [36.437947398921203, 11.423258256826299],
              [36.423742788491097, 11.4182282392681],
              [36.409924453633103, 11.413900371598199],
              [36.409834622104697, 11.423603860884199],
              [36.4098121642226, 11.4262542117586],
              [36.409756019517403, 11.432292252482799],
              [36.409720086905999, 11.436371104003801],
              [36.409625763801202, 11.446624104328],
              [36.4095853396134, 11.4511231568142],
              [36.409574110672303, 11.452457010873699],
              [36.409553898578402, 11.454653674309901],
              [36.409542669637403, 11.4558642530786],
              [36.409533686484501, 11.456894341476399],
              [36.409526949119901, 11.457510631885601],
              [36.4095202117553, 11.4581797456659],
              [36.409504491237797, 11.459960372976999],
              [36.4185909503367, 11.465220751540601],
              [36.420129315260702, 11.4682272670158],
              [36.421220768330897, 11.47052945269],
              [36.423947155218201, 11.4753032367771],
              [36.425620267434901, 11.4775833559644],
              [36.426242350769201, 11.4780433391657],
              [36.426783585727797, 11.478655181687699],
              [36.429069798125902, 11.4812389912305],
              [36.4305138399451, 11.482909431311899],
              [36.431540165157301, 11.483356200829499],
              [36.432144282185803, 11.4836203007041],
              [36.432919079118399, 11.4839570276856],
              [36.434675285498798, 11.484720714186199],
              [36.4354747861017, 11.485726488353],
              [36.4361552599294, 11.487462925899701],
              [36.436188946752601, 11.4887834035674],
              [36.436858191639303, 11.491413336494301],
              [36.439842844170698, 11.4922012112049],
              [36.443346273778801, 11.4920757676135],
              [36.444457938942897, 11.492555534029901],
              [36.445035106512897, 11.493757147249701],
              [36.439355508129097, 11.5022805312662],
              [36.439103979849499, 11.5034292867671],
              [36.438922071004498, 11.505095193983699],
              [36.437689133277097, 11.506444197491],
              [36.436292253010301, 11.5080594721405],
              [36.434738167568703, 11.510114862717201],
              [36.432490133570198, 11.512456317199],
              [36.429637982543099, 11.514749339259501],
              [36.427861564068799, 11.5167804746643],
              [36.425112719299399, 11.5202551565765],
              [36.423888764724801, 11.522299455379899],
              [36.422974728923201, 11.5239630503218],
              [36.412931564046701, 11.5304522967613],
              [36.413252711760798, 11.5334427128671],
              [36.413616529450898, 11.5368071679683],
              [36.413683903097201, 11.537451888900399],
              [36.414510353158597, 11.545113612657101],
              [36.4048781675246, 11.544576726712499],
              [36.402670557713897, 11.5465306345586],
              [36.402077669626401, 11.5472677471579],
              [36.400925580274503, 11.548315103817499],
              [36.399602811018603, 11.5503790010786],
              [36.399510733702002, 11.551815799050299],
              [36.400078918119199, 11.553311997380201],
              [36.400022773413902, 11.5551646318915],
              [36.399142424435503, 11.5561459511483],
              [36.398057708729901, 11.5577807421889],
              [36.396687777921599, 11.559650949043],
              [36.395356025512903, 11.5612395149401],
              [36.394457710228799, 11.5634463304515],
              [36.393523462333299, 11.5657433363004],
              [36.391421404568497, 11.5697498502477],
              [36.390264823640202, 11.5716199771581],
              [36.3892317610634, 11.5727574482368],
              [36.388075180135097, 11.5743371392791],
              [36.392728453306901, 11.5818658506058],
              [36.391789713835003, 11.582862476626],
              [36.391118223160099, 11.583579693152901],
              [36.3909834758675, 11.583722696228101],
              [36.390327705710099, 11.584420110177099],
              [36.3891868452992, 11.5856345303136],
              [36.3887848492096, 11.5860613361374],
              [36.388061705405903, 11.586833545017701],
              [36.387403689460299, 11.5875331512207],
              [36.385849604018702, 11.589187558920701],
              [36.385009679228098, 11.5900829590024],
              [36.379334572420703, 11.596124084543],
              [36.375130456891, 11.6076669787412],
              [36.3665717580215, 11.599929970527301],
              [36.366030523062797, 11.6000905645753],
              [36.364909874745898, 11.6004249518856],
              [36.363497273961599, 11.600847335283801],
              [36.361202078410699, 11.601531507036199],
              [36.359281929490898, 11.6021034821337],
              [36.3575010194401, 11.602633658004001],
              [36.355906509810801, 11.6031088355214],
              [36.348396594035599, 11.605348318668099],
              [36.346350680976002, 11.614603045073],
              [36.346045253779401, 11.6159779187954],
              [36.345629782960501, 11.617860934840699],
              [36.344991979108798, 11.6207426286859],
              [36.344684306124002, 11.6221306705819],
              [36.3446304072069, 11.6223770417963],
              [36.344497905702497, 11.622975370981401],
              [36.344145316953501, 11.6245657765008],
              [36.343756795593102, 11.626323351012999],
              [36.343224543787301, 11.628732023971899],
              [36.342990981813401, 11.6297834746752],
              [36.342719241439902, 11.631010895367901],
              [36.342261100644997, 11.6330807807278],
              [36.341870333496502, 11.634851499343901],
              [36.341686178863199, 11.6356829633283],
              [36.3415312194767, 11.636386846240701],
              [36.341380751666598, 11.6370599326101],
              [36.341205580186198, 11.6378583957014],
              [36.340989984518004, 11.6388350251269],
              [36.340724981509197, 11.640025012652799],
              [36.340309510690297, 11.6419034668882],
              [36.338324233912402, 11.6508776398422],
              [36.332393107248997, 11.666029696983401],
              [36.335856112669298, 11.673021490899499],
              [36.336269337700003, 11.6738528406418],
              [36.336682562730701, 11.6746841878917],
              [36.337066592514603, 11.675462748929201],
              [36.337589861167601, 11.6765162221068],
              [36.3379199920345, 11.6771870119189],
              [36.338409573864404, 11.678170100940701],
              [36.338800341012998, 11.678961847953699],
              [36.339245007078603, 11.6798591585012],
              [36.3395145016638, 11.6804023818573],
              [36.340066965563601, 11.681517415936501],
              [36.340349934878098, 11.6820914258138],
              [36.340812567249401, 11.6830217151588],
              [36.341086553411102, 11.6835759286031],
              [36.341391980607703, 11.6841917200189],
              [36.341672704133899, 11.6847591266118],
              [36.343298654798197, 11.6880403853557],
              [36.343689421946799, 11.6888299049488],
              [36.344046502272199, 11.689549047577501],
              [36.347531965574603, 11.696584231229901],
              [36.340001837705501, 11.700210612727499],
              [36.3385420753688, 11.700912132830901],
              [36.337535962250598, 11.7013959387623],
              [36.335847129516402, 11.702209610465999],
              [36.335204834088302, 11.7025174856217],
              [36.334151559417698, 11.703023279776399],
              [36.333183624698997, 11.703491688320799],
              [36.332581753458697, 11.703779770177199],
              [36.3312028394975, 11.7044438966723],
              [36.330246133719903, 11.704903506458599],
              [36.329866595512399, 11.705086030707401],
              [36.329740831372597, 11.705145406039399],
              [36.329599346715398, 11.705215776786799],
              [36.329545447798303, 11.705239966727101],
              [36.329001967051397, 11.705501657764],
              [36.332673830775299, 11.713493010992501],
              [36.332965783242599, 11.7142450747177],
              [36.333233032039601, 11.7148673949076],
              [36.333722613869497, 11.715766788041099],
              [36.334059482100997, 11.716505651603301],
              [36.3343738924505, 11.717176344494799],
              [36.334910635832699, 11.717671116895801],
              [36.336047004667201, 11.718005362638401],
              [36.336383872898701, 11.7187903987461],
              [36.335925732103803, 11.719265377492601],
              [36.335195850935399, 11.7197513502721],
              [36.334784871693003, 11.7203604642679],
              [36.334183000452597, 11.7213543946903],
              [36.333861852738501, 11.7221152328177],
              [36.333576637635801, 11.7235247568726],
              [36.333610324459002, 11.7241250668046],
              [36.333760792269103, 11.724736370082701],
              [36.334560292871899, 11.726814351320201],
              [36.334874703221402, 11.7275641798624],
              [36.335213817241097, 11.7282106579674],
              [36.3357483148352, 11.728949488233001],
              [36.336192980900798, 11.7295497863674],
              [36.337080067243903, 11.730334789649699],
              [36.337780753165497, 11.731130785095299],
              [36.338330971277003, 11.731777254844699],
              [36.338807078377599, 11.7324325185551],
              [36.339099030844899, 11.7331383545691],
              [36.339467340111398, 11.7346753557159],
              [36.3396964105089, 11.736115599375401],
              [36.339741326273099, 11.737109473056799],
              [36.339581875310202, 11.7391060049367],
              [36.339388737524096, 11.7404208935874],
              [36.339090047692103, 11.741691800069001],
              [36.338984495646201, 11.7422810765186],
              [36.338652118991099, 11.7433189036204],
              [36.338494913816398, 11.7444974483025],
              [36.338748687884099, 11.745491291766699],
              [36.339786242037299, 11.747395415471001],
              [36.340437520618302, 11.748723457008801],
              [36.340774388849802, 11.749761259847],
              [36.3410708328936, 11.751434488816299],
              [36.341246004374, 11.7523579485462],
              [36.340433029041897, 11.753200053172],
              [36.339775013096201, 11.753835477673301],
              [36.339020428257598, 11.7547721214963],
              [36.338454489628603, 11.7556713845161],
              [36.338052493538903, 11.7565024856245],
              [36.337533716462403, 11.7576216098727],
              [36.3375045212156, 11.7587891000646],
              [36.337935712552003, 11.7600335380183],
              [36.338375887041202, 11.760864625956],
              [36.338703772119899, 11.761396696810101],
              [36.339507764299199, 11.7625729655015],
              [36.339869336201097, 11.7631270192926],
              [36.340311756478499, 11.763843769764],
              [36.341010196611897, 11.765033219646501],
              [36.341263970679698, 11.766163302201299],
              [36.341380751666598, 11.7668558602274],
              [36.341623296793301, 11.767757282347899],
              [36.341513253171001, 11.7686103327556],
              [36.340936085601001, 11.7696150820329],
              [36.340511631629198, 11.7701097603065],
              [36.339595350039403, 11.7708946480078],
              [36.338865468871099, 11.771486060509901],
              [36.338207452925502, 11.771980735417401],
              [36.337560665920897, 11.772420445699201],
              [36.336574764896604, 11.773055825814],
              [36.336195226689, 11.7737945357967],
              [36.336404084992601, 11.774416721533701],
              [36.337138457737296, 11.7756764784483],
              [36.339790733613697, 11.7785719187877],
              [36.340900152989597, 11.779598616576999],
              [36.342155548599202, 11.782507206399201],
              [36.329042391239199, 11.7966759732052],
              [36.308774152641298, 11.803218201094699],
              [36.302490437228897, 11.795968101827],
              [36.299097051243102, 11.792481478885399],
              [36.296770414657203, 11.7903204539051],
              [36.296011338242103, 11.789614764544799],
              [36.295741843656899, 11.7890519704836],
              [36.2947963668204, 11.7880934591923],
              [36.292386636070702, 11.7856466021207],
              [36.291510778668702, 11.7830194491874],
              [36.291191876742801, 11.782060916839299],
              [36.290792126441403, 11.781476122525399],
              [36.290365426681497, 11.780856151254101],
              [36.286138853269698, 11.7747047292956],
              [36.2838908192712, 11.773357026255001],
              [36.280751207353198, 11.771272800386299],
              [36.276697559633597, 11.7677704738692],
              [36.272987517510202, 11.7645605183681],
              [36.263128507266998, 11.7552008658452],
              [36.261421708227097, 11.753839875069501],
              [36.260191016287898, 11.752857055253999],
              [36.259086088488402, 11.751975372748101],
              [36.257561198293601, 11.7509485718581],
              [36.256739239808702, 11.7503966922821],
              [36.255780288242903, 11.7497524649241],
              [36.254086963932302, 11.748789419169899],
              [36.253233564412398, 11.748305696288],
              [36.252020838778797, 11.747615290340001],
              [36.251075361942299, 11.747076596599401],
              [36.249685219040103, 11.746210286905701],
              [36.248476984983, 11.7454561115283],
              [36.247086842080797, 11.744587597968099],
              [36.246365944065303, 11.744136849345301],
              [36.2455394940039, 11.7436201366025],
              [36.245016225350902, 11.743292518234],
              [36.244389650440198, 11.742865954137001],
              [36.243778796047003, 11.742450383325201],
              [36.243161204289201, 11.7420326130945],
              [36.241739620352099, 11.741065142761],
              [36.241095079135697, 11.7406275811996],
              [36.240407867943397, 11.7401614344506],
              [36.239352347484498, 11.7393170911627],
              [36.237194145014399, 11.737857074793],
              [36.235698450066401, 11.7368456131366],
              [36.234651912760398, 11.736135388930499],
              [36.231554970818401, 11.722099840159601],
              [36.223636321588799, 11.7218821439031],
              [36.222331518638697, 11.7218447617004],
              [36.221556721706101, 11.721824971120499],
              [36.2207325174329, 11.721800782631901],
              [36.219418731329903, 11.7217655993721],
              [36.217336885659002, 11.7217084265653],
              [36.216797896488501, 11.721693033884501],
              [36.215672756595097, 11.721662248520399],
              [36.214787916040301, 11.721635861062699],
              [36.213635826688403, 11.721605075692199],
              [36.212959844437101, 11.7215874840503],
              [36.211223850150503, 11.7215391070295],
              [36.207048929867597, 11.7214225623537],
              [36.202317054108498, 11.707566589275601],
              [36.2016770044686, 11.706832099961799],
              [36.200810130219402, 11.705833719143399],
              [36.199905077570598, 11.7047935520338],
              [36.199312189483102, 11.704111833624101],
              [36.197454922633199, 11.701972106540399],
              [36.189635088084898, 11.6936131579731],
              [36.186113692171197, 11.690448523038],
              [36.185177198487501, 11.689593031775599],
              [36.176191799858103, 11.684233505815399],
              [36.167062670783203, 11.678412023879],
              [36.166339526979499, 11.678148107935799],
              [36.1646282363632, 11.6778819924386],
              [36.163819752607502, 11.677886391044],
              [36.163004531487204, 11.6784955972087],
              [36.162070283591703, 11.6806135129148],
              [36.160891244781297, 11.6821859943768],
              [36.160044582626, 11.6833186153561],
              [36.158012144295697, 11.685157190233401],
              [36.156217759515698, 11.6866130865633],
              [36.151665546813398, 11.6859775073845],
              [36.150030612996296, 11.685119803066801],
              [36.148860557338701, 11.6850076415366],
              [36.146899984231098, 11.685119803066801],
              [36.144770977007802, 11.6855442570739],
              [36.142736292889303, 11.6861534464035],
              [36.130600053400798, 11.6939232418686],
              [36.130074538959597, 11.6948468939671],
              [36.1290527053239, 11.6956979706724],
              [36.1287023623631, 11.6962939427246],
              [36.128275662603102, 11.6970218625677],
              [36.1280084138061, 11.6982951679006],
              [36.127835488113902, 11.699126441796199],
              [36.127565993528698, 11.699847756803599],
              [36.1271011153692, 11.7010902614768],
              [36.118623264875303, 11.705503856847301],
              [36.118043851517001, 11.7060932105398],
              [36.125504359951599, 11.7191532298067],
              [36.116581843392098, 11.7235005685329],
              [36.123204672824301, 11.734424686585401],
              [36.121410288044302, 11.7463400136575],
              [36.121169988705802, 11.7479385060788],
              [36.121008291954602, 11.749009292926401],
              [36.119575479076403, 11.7585252609063],
              [36.1183807197486, 11.7664689073865],
              [36.117217401455598, 11.774188073935401],
              [36.113669056083403, 11.7865061923097],
              [36.113181720041702, 11.7881989835351],
              [36.110179101204601, 11.7986171142272],
              [36.1098085461499, 11.7999053383981],
              [36.109566001023097, 11.800749495665301],
              [36.109343667990302, 11.801521105894199],
              [36.109148284416001, 11.802195988274701],
              [36.1088945103483, 11.8030753113597],
              [36.108622769974801, 11.804020580531001],
              [36.1083622585424, 11.804926277237801],
              [36.108027636099102, 11.8060891698355],
              [36.107767124666701, 11.806988264889499],
              [36.105206926107002, 11.8158779359356],
              [36.096430385781098, 11.8110484446943],
              [36.095900379763499, 11.8118134312303],
              [36.095318720617001, 11.812650957143701],
              [36.094813418269702, 11.813200513438399],
              [36.094272183310999, 11.813791834780201],
              [36.093544547930897, 11.814580993987599],
              [36.092394704367202, 11.8150953750882],
              [36.091734442633403, 11.8153899347707],
              [36.090449851777102, 11.8162714137191],
              [36.088163639378998, 11.8182937487144],
              [36.0820842906937, 11.8211953337055],
              [36.0814352579009, 11.822720851661501],
              [36.081109618610398, 11.826758817083901],
              [36.0855922118782, 11.8338564354895],
              [36.084381732032902, 11.8349664520623],
              [36.083730453451899, 11.8355621224643],
              [36.083267821080497, 11.8359863445054],
              [36.082632263016997, 11.836568824474],
              [36.082048358082297, 11.8371051445551],
              [36.081581234134603, 11.837531562229501],
              [36.080853598754501, 11.8381997617834],
              [36.080384229018499, 11.838628375769],
              [36.079582482627401, 11.839364711046899],
              [36.078991840328101, 11.839905421659401],
              [36.0780643297973, 11.8407538516025],
              [36.0774355090984, 11.8413297274207],
              [36.0767864763056, 11.841925383955999],
              [36.0762811739583, 11.8423891599165],
              [36.075634386953702, 11.8429804181749],
              [36.075059465171897, 11.843507933756699],
              [36.074601324376999, 11.843927747512801],
              [36.074131954640997, 11.844356352514501],
              [36.073018043688698, 11.845378407882],
              [36.066240254870102, 11.851589784706199],
              [36.058427157686403, 11.8499193646285],
              [36.057793845411098, 11.8502578453119],
              [36.056760782834402, 11.8497457282709],
              [36.056154420017599, 11.8488687530819],
              [36.0553841146615, 11.848035733941099],
              [36.054025412794203, 11.8479434203179],
              [36.053122605933702, 11.847862096385899],
              [36.052195095402901, 11.848358831376499],
              [36.051698776208397, 11.849037994127199],
              [36.051249618566303, 11.8497083634707],
              [36.051000336074999, 11.8505369816637],
              [36.050977878192903, 11.8512732848446],
              [36.050834147747402, 11.853112935158499],
              [36.050762282524701, 11.853987698820401],
              [36.050789231983202, 11.8551086230334],
              [36.050998090286797, 11.8559372248356],
              [36.051157541249701, 11.8568625305063],
              [36.051213685954899, 11.8574977147683],
              [36.051256355930903, 11.8581087210716],
              [36.051334958518297, 11.859124131398399],
              [36.0510722012977, 11.860058217647801],
              [36.050798215135998, 11.860831858426099],
              [36.050418676928501, 11.8616868168479],
              [36.0498729503934, 11.8630802394845],
              [36.049399089081, 11.8640055209301],
              [36.049033025602697, 11.864904425551799],
              [36.048815184146299, 11.865816514009801],
              [36.048648995818802, 11.8664626663967],
              [36.048608571631, 11.8675241991367],
              [36.048711877888699, 11.868203314187699],
              [36.048891540945498, 11.8696362633727],
              [36.049100399249099, 11.8702362536624],
              [36.0563498035919, 11.8742910984682],
              [36.045603707005597, 11.876264654219099],
              [36.044496533417899, 11.876578927367699],
              [36.050584865256099, 11.8830093598737],
              [36.039611944060503, 11.883141219512099],
              [36.039243634794097, 11.8838224932932],
              [36.038947190750299, 11.884422452340401],
              [36.038511507837498, 11.885022410065501],
              [36.038003959702003, 11.885795979979299],
              [36.0375211152368, 11.886312424578801],
              [36.036625045740799, 11.887213453236299],
              [36.036279194356503, 11.888079316989399],
              [36.0360883023586, 11.889059453852999],
              [36.035908639301802, 11.889844000323601],
              [36.035470710600798, 11.8907450172892],
              [36.034610573716201, 11.891272440470001],
              [36.032248004518998, 11.8927492199374],
              [36.031913382075601, 11.893821638097799],
              [36.042619054474102, 11.895054474243],
              [36.036059107111903, 11.900803240661601],
              [36.036007453982997, 11.900847191007101],
              [36.035967029795202, 11.9008823512783],
              [36.035895164572501, 11.900943881742],
              [36.035443761142197, 11.901341631903801],
              [36.034576886893099, 11.902099772865499],
              [36.0338582346658, 11.902730456634099],
              [36.033076700368603, 11.903416076353199],
              [36.032432159152201, 11.9039808323228],
              [36.031268840859298, 11.905000466587801],
              [36.030312135081701, 11.9058355090418],
              [36.029853994286803, 11.906237646992301],
              [36.028937712697001, 11.907041921108201],
              [36.0283403330331, 11.9075649177532],
              [36.027682317087397, 11.9081406523916],
              [36.027185997893, 11.9085779462063],
              [36.021187497583298, 11.913832009312101],
              [36.013780888065703, 11.917659874177099],
              [36.012417694622002, 11.918363035219301],
              [36.0118068402288, 11.9186794570941],
              [36.009273591127602, 11.9199868907909],
              [36.007207465974098, 11.921054806750901],
              [36.005166044490998, 11.922109534438601],
              [36.003477211756802, 11.922981879032999],
              [35.995956067040503, 11.9268667448315],
              [35.9921022944717, 11.9342539888454],
              [35.991635170523899, 11.935150464013899],
              [35.991302793868797, 11.935787662698599],
              [35.991046774012801, 11.936277645496],
              [35.990727872087, 11.936890672053501],
              [35.990422444890399, 11.937472936137601],
              [35.989899176237401, 11.938479261303501],
              [35.989537604335503, 11.9391691851313],
              [35.989158066127999, 11.9398986568221],
              [35.988875096813501, 11.9404391677118],
              [35.988531491217302, 11.941098325874099],
              [35.988109283033801, 11.941909088214301],
              [35.987678091697397, 11.9427352283739],
              [35.987201984596801, 11.9436470556783],
              [35.987017829963598, 11.944000799902501],
              [35.986878591094502, 11.944268854224299],
              [35.986517019192704, 11.944963157627701],
              [35.986112777314801, 11.9457365567916],
              [35.982153452699997, 11.953325418898901],
              [35.978009973452103, 11.960485646956201],
              [35.968999871152299, 11.9584050479029],
              [35.972314654550701, 11.9703215885822],
              [35.965792885588002, 11.984572852705099],
              [35.956371804045801, 11.979449777896299],
              [35.939359958352803, 11.9701985591349],
              [35.935263640657197, 11.9661012071592],
              [35.933215481809398, 11.959956161281299],
              [35.939946109075699, 11.954397597666199],
              [35.942872371113701, 11.936547904686901],
              [35.936433696314801, 11.9207449797137],
              [35.924730893950901, 11.912553112469199],
              [35.917706068429098, 11.904945529494301],
              [35.9112696394184, 11.9055300595997],
              [35.897516432418499, 11.895289613479299],
              [35.894004019657601, 11.898506825228299],
              [35.887637210081401, 11.890780178866301],
              [35.874400534369897, 11.885925640630401],
              [35.870540024436401, 11.8647637658554],
              [35.868309956743602, 11.859561503946299],
              [35.860133041869901, 11.8498973853488],
              [35.848239347508098, 11.8402351227274],
              [35.837091254832202, 11.8350301954515],
              [35.8229674927777, 11.8268533359745],
              [35.814790577903899, 11.828341454669999],
              [35.803640239439801, 11.838004137741001],
              [35.7932332568733, 11.837261204709],
              [35.781339562511498, 11.834287254337999],
              [35.773906003535501, 11.8335443112059],
              [35.762757910859499, 11.837261204709],
              [35.756067707781099, 11.831315469699501],
              [35.749377504702601, 11.8320584188866],
              [35.736740454443201, 11.832801366055399],
              [35.719643268798201, 11.8290844119347],
              [35.705519506743599, 11.8223955257789],
              [35.694369168279501, 11.831315469699501],
              [35.683218829815402, 11.8253674073109],
              [35.666570801812398, 11.8258971549938],
              [35.6616907040315, 11.7933652298754],
              [35.644883225065598, 11.781436549932801],
              [35.623465142904003, 11.775744632671699],
              [35.6023188011158, 11.774388140594301],
              [35.589847939183997, 11.7730338403674],
              [35.576564101920098, 11.767339550513],
              [35.534543158717199, 11.729657532048201],
              [35.516920458630999, 11.7179987656869],
              [35.473273564763801, 11.736163973841],
              [35.4616156781642, 11.735077745159501],
              [35.441012817122903, 11.729927995510501],
              [35.421764166372398, 11.7293848694276],
              [35.415258117927202, 11.7326392121543],
              [35.394653011097702, 11.746194895592099],
              [35.3637487195357, 11.7529713879408],
              [35.338263514925302, 11.771949955671399],
              [35.337039560350703, 11.7729854723787],
              [35.336954220398702, 11.774115522253901],
              [35.334953223103298, 11.7819443977708],
              [35.325992528144198, 11.7942973422634],
              [35.325383919539199, 11.7946446852209],
              [35.316292968863898, 11.791338314179299],
              [35.314541254059897, 11.7914372420825],
              [35.312228092203299, 11.792472685328899],
              [35.311498211034902, 11.793640027488699],
              [35.309587045268003, 11.7966803699161],
              [35.308127282931302, 11.799131525287301],
              [35.307635455313203, 11.800468110198199],
              [35.307020109343597, 11.801633220020401],
              [35.306611375889297, 11.8039458384361],
              [35.3060566662014, 11.8051109334807],
              [35.305477252843097, 11.805354943325201],
              [35.303698588580602, 11.804361316293599],
              [35.303258414091403, 11.8031632435132],
              [35.302413997724301, 11.802147625423199],
              [35.301818863848503, 11.801701367800099],
              [35.301336019383299, 11.8011385985307],
              [35.3003119399594, 11.7998393885021],
              [35.295907949278998, 11.7991403186304],
              [35.297480001026301, 11.8071641266579],
              [35.2992878605356, 11.816392314600501],
              [35.298679251930601, 11.817001214591899],
              [35.2980683975373, 11.817783769993399],
              [35.2976349604128, 11.8184805941288],
              [35.297286863240203, 11.8189158335408],
              [35.296067400242002, 11.819698383470501],
              [35.295894474549797, 11.820480931163599],
              [35.295198280204602, 11.8210040938232],
              [35.294329160167202, 11.822133945903699],
              [35.294241574426998, 11.8226571054042],
              [35.291283871353997, 11.8255278704102],
              [35.288065656848701, 11.8298757251456],
              [35.286322925197503, 11.8384151678704],
              [35.284672270862899, 11.8464905756148],
              [35.2876299739359, 11.8558866736041],
              [35.287457048243702, 11.868064853974801],
              [35.287457048243702, 11.870587895709299],
              [35.28709772213, 11.872185663545901],
              [35.287021365330901, 11.873631778216801],
              [35.284324173690301, 11.8845059630187],
              [35.2831945422205, 11.888419947913899],
              [35.2824107621352, 11.890942801101801],
              [35.280236839147598, 11.894074358975001],
              [35.2752781387792, 11.9042511236752],
              [35.257082762699397, 11.934227621883799],
              [35.275323054543399, 11.9400173056463],
              [35.297203769076397, 11.9991968068788],
              [35.296684991999797, 12.0023029494995],
              [35.299013874373898, 12.0087721344345],
              [35.3023803109011, 12.0155025578524],
              [35.306521544360898, 12.016280148149599],
              [35.308850426734999, 12.0157617548677],
              [35.312214617473998, 12.01446796389],
              [35.315062276924699, 12.0136903683584],
              [35.319275375607198, 12.0124295165937],
              [35.322372317549203, 12.0127568114345],
              [35.325648922547998, 12.013543196070099],
              [35.330511054023297, 12.015695857345101],
              [35.334380547109703, 12.017857287672999],
              [35.336965449339701, 12.0197375422146],
              [35.339087719198503, 12.022423911130799],
              [35.340385784783997, 12.0243129198173],
              [35.341344736349797, 12.0274648989623],
              [35.342418223114301, 12.0305026250882],
              [35.343615228230398, 12.034515533878601],
              [35.344230574199997, 12.0387633972215],
              [35.343900443333098, 12.041522056369001],
              [35.343408615715099, 12.0460048170526],
              [35.342957212284801, 12.0483614760211],
              [35.341526645194797, 12.0523323885986],
              [35.340909053437002, 12.0556663284501],
              [35.340397013725102, 12.0575638883626],
              [35.3393055606549, 12.0598677364629],
              [35.3371653244904, 12.062808463289899],
              [35.333796642175002, 12.065641545261499],
              [35.331411615095703, 12.0668626163193],
              [35.330324653601899, 12.067688373449799],
              [35.397904912426199, 12.1866322212033],
              [35.399126621212602, 12.185993423124099],
              [35.401518385656601, 12.185635607720499],
              [35.404024685299198, 12.184702651177799],
              [35.407409088132198, 12.183934331562],
              [35.410737346259801, 12.183686273611],
              [35.412255499090001, 12.1833328455104],
              [35.418103531589601, 12.183989211608401],
              [35.422087559874697, 12.185056077454099],
              [35.425069966617997, 12.1856136558395],
              [35.4288855607873, 12.187773712222601],
              [35.4333524335375, 12.191134496683],
              [35.4355375854662, 12.194282312345001],
              [35.4370153141085, 12.196224983278301],
              [35.4389669040633, 12.1992585946111],
              [35.441102648651302, 12.203437984895899],
              [35.441884182948499, 12.2064780986969],
              [35.442434401059998, 12.209691581773701],
              [35.442582623081897, 12.213596438023499],
              [35.442259229579598, 12.217272964472601],
              [35.441688799374198, 12.2191737634896],
              [35.4407118815027, 12.221476214217301],
              [35.440312131201303, 12.2226263346253],
              [35.439973017181501, 12.2243515058602],
              [35.438246006047798, 12.2277513332754],
              [35.434857111638401, 12.2325316493197],
              [35.433035777399901, 12.234386248197501],
              [35.4311156284801, 12.235490222177599],
              [35.429952310187097, 12.2363571567614],
              [35.428146696466101, 12.237287736541401],
              [35.646325021096601, 12.6056117516128],
              [35.648602250341803, 12.6047350883671],
              [35.651398256663697, 12.6030518865274],
              [35.6555439816999, 12.601647018194001],
              [35.659397754268703, 12.600417478122701],
              [35.661737865583902, 12.600115023511499],
              [35.664785400185302, 12.600555555109199],
              [35.667776790081398, 12.6012831976822],
              [35.671358822276801, 12.602580675698301],
              [35.674478222100902, 12.6043427805932],
              [35.677649275053803, 12.6058155753876],
              [35.682235174579297, 12.6081430998983],
              [35.684595497988298, 12.610424579387701],
              [35.685606102682897, 12.6121997799167],
              [35.6865044179671, 12.614606143208],
              [35.687636295224998, 12.6170716559085],
              [35.689599114120803, 12.620676740617199],
              [35.690728745590597, 12.623026045083],
              [35.691456380970799, 12.6260086664072],
              [35.691721383979598, 12.6297977068223],
              [35.691730367132401, 12.630946022617801],
              [35.691451889394301, 12.6326728694861],
              [35.691007223328697, 12.6356619524836],
              [35.690670355097197, 12.637445743423401],
              [35.690162806961602, 12.639746683862301],
              [35.689127498596697, 12.6422229108988],
              [35.686933363515202, 12.6460292414005],
              [35.685774536798696, 12.647589447987899],
              [35.684564056953398, 12.6499516527381],
              [35.683461374942098, 12.651106451744001],
              [35.698820320512297, 12.667726651667699],
              [35.719225552191098, 12.6705268898636],
              [35.720981758571597, 12.670629871432901],
              [35.722850254362498, 12.6696986536013],
              [35.727231787160797, 12.6685789965977],
              [35.729677450521798, 12.666782276450601],
              [35.730658859969701, 12.665051277990701],
              [35.731873831391503, 12.663320267778699],
              [35.732855240839399, 12.6615344664484],
              [35.733724360876799, 12.6603775234517],
              [35.734243137953399, 12.6592249575968],
              [35.7351324700847, 12.6587604238787],
              [35.737057110580899, 12.6584207878419],
              [35.739621800717003, 12.658155652298801],
              [35.7476976551213, 12.6573010813179],
              [35.761673195154003, 12.668927383550599],
              [35.769912992097602, 12.666460180158801],
              [35.770434014962298, 12.665881720490701],
              [35.7708831726044, 12.6635810156383],
              [35.771390720739902, 12.661280290027801],
              [35.771965642521799, 12.660068566195401],
              [35.773724094690401, 12.6602285228358],
              [36.001644648577198, 12.7093173186257],
              [36.002134230407101, 12.712428183974099],
              [36.002549701226002, 12.7141939117211],
              [36.003185259289502, 12.7154579549376],
              [36.0050312971984, 12.7166628429604],
              [36.007229923856301, 12.717598270264499],
              [36.0103088994926, 12.718347485900599],
              [36.013974025851802, 12.7191470849443],
              [36.015914386865497, 12.719267572253001],
              [36.020154435006503, 12.719298241740701],
              [36.022207085430701, 12.718489880435101],
              [36.024497789405203, 12.717874297334999],
              [36.027055742176799, 12.717587316803201],
              [36.029418311374002, 12.7175237867179],
              [36.031226170883301, 12.7174164427446],
              [36.032683687431799, 12.7174602566167],
              [36.034244510237897, 12.71759388888],
              [36.036337584849903, 12.7179619249137],
              [36.037970272878802, 12.718367202071599],
              [36.039099904348603, 12.7187330463018],
              [36.040344071017103, 12.719208423945],
              [36.041626416085201, 12.719718851483099],
              [36.043119865245004, 12.7203410021159],
              [36.043988985282397, 12.720630170200799],
              [36.044694162780502, 12.720801042096101],
              [36.049147560801501, 12.721898562681099],
              [36.052990104429298, 12.722176775728],
              [36.0572773141228, 12.722395840904699],
              [36.060931211540897, 12.7220562898002],
              [36.065838258780403, 12.720945625917601],
              [36.070778992843103, 12.719644368558701],
              [36.074736071669598, 12.7182248074683],
              [36.081835008202397, 12.715512722699],
              [36.0868565906406, 12.713473163292299],
              [36.091844486255702, 12.7109078080637],
              [36.096452843663201, 12.708184691289],
              [36.100311107808501, 12.705139497978999],
              [36.103109359918498, 12.7018642313526],
              [36.104957643615599, 12.698523196833101],
              [36.106275921295101, 12.6952938535483],
              [36.106972115640197, 12.6908945193812],
              [36.106927199875997, 12.687389019373001],
              [36.107450468529002, 12.6852637864824],
              [36.1093998126956, 12.684915421872599],
              [36.111807297657002, 12.6846612687747],
              [36.1142372405006, 12.684479417695499],
              [36.118724325344701, 12.684654695846399],
              [36.120617524806001, 12.684838737774999],
              [36.1236583220428, 12.684718234146301],
              [36.1280735416642, 12.684529810176199],
              [36.132713340106697, 12.684299757465499],
              [36.137831491438, 12.6839579644829],
              [36.143010279050898, 12.6833554436845],
              [36.1460847631108, 12.6829544926902],
              [36.149866670457001, 12.682470283270799],
              [36.153520567875098, 12.6825907879635],
              [36.156929674378297, 12.6841003782814],
              [36.1576528181821, 12.6859145040049],
              [36.157410273055298, 12.687500757930801],
              [36.156967852777903, 12.6889686912917],
              [36.156455813066003, 12.6912845032719],
              [36.156062800129199, 12.6938369111672],
              [36.155894366013399, 12.6959489271773],
              [36.155719194532999, 12.6973204136586],
              [36.155526056746901, 12.6995025078932],
              [36.155472157829898, 12.7016977283768],
              [36.155362114207598, 12.7038556861235],
              [36.155472157829898, 12.705599565495501],
              [36.155829238155299, 12.7075099321778],
              [36.157612393994299, 12.707150644127699],
              [36.157623622935297, 12.7084694916748],
              [36.157798794415697, 12.709762043259399],
              [36.158030110601402, 12.711345957674499],
              [36.158340029374401, 12.7130109193024],
              [36.159007028472899, 12.715247646623901],
              [36.160134414154399, 12.7180079293804],
              [36.161562735456201, 12.721094590980901],
              [36.162833851583201, 12.7236817496787],
              [36.164210519756097, 12.726965497370699],
              [36.165656807363597, 12.7303784469223],
              [36.166968347678399, 12.733381716914],
              [36.167823992986499, 12.736117706532299],
              [36.168724554058798, 12.739101193979501],
              [36.169499350991401, 12.7418086455818],
              [36.169970966515599, 12.744435021232],
              [36.170478514651101, 12.7469781330069],
              [36.170864790223199, 12.7490678026405],
              [36.171109581138197, 12.7504455732913],
              [36.171152251114201, 12.7513304967505],
              [36.171394796240897, 12.754186763247599],
              [36.171686748708197, 12.7568721500525],
              [36.172281882583903, 12.7598510123553],
              [36.172975831140903, 12.762450917187101],
              [36.173777577532, 12.764952232587399],
              [36.1745164418532, 12.7683646702666],
              [36.175015006835899, 12.7714069138061],
              [36.175390053466998, 12.773522664184799],
              [36.175765100098097, 12.776183753642201],
              [36.176254681928, 12.779035359138399],
              [36.176649940653, 12.7819110239412],
              [36.1764612944433, 12.7838865187937],
              [36.176108705694297, 12.785752493086401],
              [36.175951500519602, 12.788533908040399],
              [36.175877389508599, 12.7919482172501],
              [36.175778574827397, 12.795397520569599],
              [36.175693234875403, 12.7969852792636],
              [36.175601157558802, 12.7987372772588],
              [36.1753855618906, 12.8020945094109],
              [36.175511326030403, 12.806152473991901],
              [36.1758392111091, 12.8100943090362],
              [36.1764747691726, 12.8159631491799],
              [36.176555617548097, 12.8185734251898],
              [36.1766364659237, 12.8212581271546],
              [36.176903714720702, 12.824720167929099],
              [36.177159734576698, 12.8268464198415],
              [36.177512323325701, 12.828799665292101],
              [36.177745885299601, 12.830888657659001],
              [36.177988430426304, 12.8323557593292],
              [36.178127669295399, 12.8331790842792],
              [36.178204026094498, 12.8341381668598],
              [36.178264662376201, 12.835320592404299],
              [36.179008018273798, 12.843043446182],
              [36.179243826035901, 12.846242438023999],
              [36.179369590175597, 12.850273416287999],
              [36.1796907378897, 12.854685303678901],
              [36.179886121464001, 12.859429911939801],
              [36.180011885603797, 12.864137210362699],
              [36.180470026398702, 12.868739330227999],
              [36.181020244510201, 12.873415803202599],
              [36.1809034635233, 12.8790883178947],
              [36.180582315809197, 12.882597725255],
              [36.180272397036198, 12.887138208050899],
              [36.180106208708601, 12.8899009880598],
              [36.179805273088398, 12.8934190005677],
              [36.179306708105798, 12.896151063290301],
              [36.178545385902503, 12.8977141049922],
              [36.176775704792803, 12.8997828216428],
              [36.173986435835602, 12.902109833790901],
              [36.171459924098997, 12.904224484568299],
              [36.169016506526198, 12.906934538821901],
              [36.166128422887702, 12.910180873704901],
              [36.1635457664459, 12.9128273825458],
              [36.1614908702335, 12.9149003521139],
              [36.159613391289703, 12.9175271103899],
              [36.157852693332799, 12.9203070661487],
              [36.1565433988062, 12.9229403342877],
              [36.1551173232926, 12.926149250274801],
              [36.154223499584901, 12.928559193482799],
              [36.153552008910097, 12.931080744027],
              [36.1529838244929, 12.933702954459701],
              [36.152541404215398, 12.9352788940487],
              [36.152271909630201, 12.9372269167258],
              [36.152579582614997, 12.9396061103006],
              [36.152920942423002, 12.9415978740347],
              [36.153374591641402, 12.9432284819503],
              [36.154032607587098, 12.9451873860819],
              [36.154980330211799, 12.9469230279414],
              [36.155755127144403, 12.948439789556099],
              [36.156723061862998, 12.949562581168699],
              [36.157320441526899, 12.951166873075801],
              [36.157466417760602, 12.952784286519099],
              [36.1574776467017, 12.9546643249423],
              [36.157414764631802, 12.9567588329933],
              [36.157217135269299, 12.9585447319004],
              [36.1570172601186, 12.9603678238227],
              [36.1569004791316, 12.962291576212699],
              [36.157095862705901, 12.9638542037299],
              [36.157518070889402, 12.9652154762883],
              [36.158396174079698, 12.966821855870901],
              [36.159662798630301, 12.9684435446307],
              [36.161039466803203, 12.9699864370798],
              [36.162243209283901, 12.9710916236612],
              [36.163810769454699, 12.972446291208399],
              [36.165214387086102, 12.974394023216099],
              [36.166752752010197, 12.976578091076201],
              [36.167884629268201, 12.9784776455199],
              [36.1687133251178, 12.9808455029708],
              [36.169714946659603, 12.9834212335663],
              [36.1705616088149, 12.9855811501973],
              [36.1713656009941, 12.9878920432482],
              [36.171974209599099, 12.9901657134417],
              [36.172427858817599, 12.992312441652899],
              [36.173022992693298, 12.994632025180399],
              [36.1733621067131, 12.996080659749],
              [36.173972961106301, 12.9974505088308],
              [36.174936404248498, 12.999612491681001],
              [36.175589928617697, 13.001857607783901],
              [36.176043577836197, 13.0034068573434],
              [36.1765196849368, 13.005111458322199],
              [36.176941893120301, 13.006588477940101],
              [36.177325922904302, 13.0075053197677],
              [36.177837962616202, 13.008459356830199],
              [36.178332036022503, 13.0091420599984],
              [36.179708704195399, 13.008796332346799],
              [36.1804296022109, 13.012605874356],
              [36.180840581453403, 13.014660508269801],
              [36.181599657868503, 13.0163212702014],
              [36.1821476301918, 13.017655998339199],
              [36.182673144633, 13.019216089493399],
              [36.183196413285998, 13.0208199317312],
              [36.1854871172605, 13.0274824394179],
              [36.194169334481501, 13.056924418479699],
              [36.195211380211099, 13.0595562386053],
              [36.198036581779697, 13.065845803197201],
              [36.198550867279799, 13.067361837759901],
              [36.1986362072318, 13.068987243983001],
              [36.199049432262498, 13.0719995768779],
              [36.201699462350703, 13.0811697930964],
              [36.210401891665597, 13.1142077426054],
              [36.2131731943171, 13.124120012312799],
              [36.214392657315301, 13.129795547220599],
              [36.2160904732023, 13.1357858828546],
              [36.218174564661403, 13.1431713565231],
              [36.220458531271298, 13.150366348076],
              [36.222304569180103, 13.157956944682599],
              [36.223807001492801, 13.162617030289899],
              [36.2253161711701, 13.168674353044],
              [36.227088098068101, 13.176290622401901],
              [36.22860400511, 13.1819540167439],
              [36.230865513837799, 13.1890604071666],
              [36.232585787606901, 13.1952570110358],
              [36.233349355598399, 13.197651210747701],
              [36.233744614323399, 13.200102234733899],
              [36.233944489474098, 13.2022274559646],
              [36.2344071218454, 13.2038760201914],
              [36.235866884182101, 13.2082291382814],
              [36.2364687554225, 13.210275579833599],
              [36.2369807951344, 13.2128095579366],
              [36.237198636590797, 13.215361000244201],
              [36.237676989479603, 13.2168564347478],
              [36.2384922106, 13.218708224711399],
              [36.2393276438142, 13.2208748229853],
              [36.240223713310101, 13.224073307757299],
              [36.241719408258199, 13.230231857792299],
              [36.2433543420753, 13.235705993842499],
              [36.247122774692201, 13.2502083617322],
              [36.247929012659696, 13.252953964490001],
              [36.249024957306297, 13.2550983985945],
              [36.249959205201797, 13.257872363288],
              [36.250230945575197, 13.259889971001099],
              [36.250484719642998, 13.262294465369299],
              [36.251133752435699, 13.264316408134],
              [36.252068000331199, 13.266288059367101],
              [36.252919154062901, 13.2680214379804],
              [36.256662883009497, 13.2788913853141],
              [36.256797630302103, 13.2795624077424],
              [36.257098565922298, 13.2810836764763],
              [36.2575409861997, 13.282460678747601],
              [36.258248409486001, 13.285566555186],
              [36.258612227176002, 13.2897083904785],
              [36.259056893241699, 13.293010879712799],
              [36.260098938971304, 13.2979175394353],
              [36.262064003655297, 13.303339883945901],
              [36.262580534943602, 13.305612817745001],
              [36.262964564727604, 13.308019044616801],
              [36.263132998843403, 13.3097477541631],
              [36.2636225806732, 13.3114065169569],
              [36.263986398363301, 13.3125473195569],
              [36.264487209134202, 13.314079308463],
              [36.264803865271801, 13.315320627599901],
              [36.265553958534099, 13.317613117512799],
              [36.265920022012402, 13.3196520821945],
              [36.266708293674199, 13.323941937855899],
              [36.268186022316499, 13.3296215634969],
              [36.269845659803998, 13.33418221288],
              [36.271282964258504, 13.338443403116999],
              [36.273016712756899, 13.3446580504729],
              [36.273888078582502, 13.349808394783301],
              [36.275936237430301, 13.3577620411615],
              [36.276659381233998, 13.360705260884201],
              [36.277389262402401, 13.363423391821801],
              [36.277735113786697, 13.365260953340799],
              [36.278177534064199, 13.3670263975926],
              [36.278622200129803, 13.3683045886433],
              [36.280724257894597, 13.374470399673701],
              [36.282716272037199, 13.377645009814399],
              [36.284598242557401, 13.381062093321701],
              [36.286722758204398, 13.3857179008135],
              [36.289242532576303, 13.389827433654],
              [36.291836417959203, 13.3947255730595],
              [36.293828432101698, 13.3975197836572],
              [36.295692436316301, 13.4015045907823],
              [36.297365548533001, 13.4038989387454],
              [36.2980123355375, 13.404447276027501],
              [36.298560307860797, 13.4058082830801],
              [36.298973532891502, 13.406581628758801],
              [36.299768541917999, 13.408043114222201],
              [36.3011833884905, 13.411193251470999],
              [36.304156812080898, 13.4163465422972],
              [36.305603099688298, 13.4189198695387],
              [36.307163922494503, 13.421193898930101],
              [36.308641651136902, 13.423170823408199],
              [36.310110396626399, 13.425018850795199],
              [36.311311893318901, 13.427074382274],
              [36.312385380083398, 13.4297873947437],
              [36.314013576535899, 13.432911034555699],
              [36.315354312097497, 13.435853334996899],
              [36.316430044650197, 13.4385749853764],
              [36.318428796157299, 13.4420370722362],
              [36.320364665594603, 13.4451823948692],
              [36.321772774802497, 13.4478078339588],
              [36.322998975165298, 13.449491856701],
              [36.324353185456097, 13.4511780517891],
              [36.325035905071999, 13.4532005965621],
              [36.325233534434602, 13.4540961022917],
              [36.325884813015499, 13.455205651087899],
              [36.328418062116803, 13.460082799690399],
              [36.329363538953302, 13.462389199894499],
              [36.330454992023498, 13.4645689023874],
              [36.3316632260806, 13.466431898902099],
              [36.333448627707803, 13.469107337456],
              [36.335234029334998, 13.4718198739976],
              [36.336374889745798, 13.4739536270991],
              [36.336808326870397, 13.475593786704801],
              [36.337962662010497, 13.477683827315699],
              [36.340235399679401, 13.481429256677201],
              [36.341243758585797, 13.483626256733601],
              [36.342784369298002, 13.4862250676992],
              [36.3442935389754, 13.488681900810001],
              [36.345912752274998, 13.491796036952501],
              [36.347545440303897, 13.495211494413899],
              [36.349106263110002, 13.4986247192648],
              [36.3507838669031, 13.5014330045825],
              [36.3527354568579, 13.5040643772405],
              [36.354597215284201, 13.506676067795301],
              [36.356530838933303, 13.510320594862],
              [36.3579097528944, 13.5131527622768],
              [36.359138199045397, 13.515893185227201],
              [36.362832520651402, 13.522636020060901],
              [36.3646718211956, 13.5255553812591],
              [36.366710996890603, 13.5276995714049],
              [36.369906753513803, 13.5307411440651],
              [36.371829148221799, 13.5321079831599],
              [36.373742559777, 13.533754293312301],
              [36.375157406349501, 13.535097097538801],
              [36.376785602802002, 13.5367586708182],
              [36.378660835957596, 13.538872192733299],
              [36.380832513156903, 13.5429245128236],
              [36.381429892820897, 13.5453611117136],
              [36.381816168393001, 13.5474243412548],
              [36.382215918694499, 13.549795395635201],
              [36.382267571823299, 13.552705690647301],
              [36.382233885000097, 13.554928232533401],
              [36.382144053471698, 13.556733759608999],
              [36.382063205096202, 13.558196510299901],
              [36.383213048659798, 13.5657437240958],
              [36.3842461112366, 13.5687084014183],
              [36.3855307020929, 13.571972123403301],
              [36.386608680433802, 13.5749738347725],
              [36.387594581458103, 13.578281130967399],
              [36.388349166296798, 13.582950561740301],
              [36.389653969247, 13.588584754820999],
              [36.3910935194898, 13.594135864841499],
              [36.393220280924901, 13.604213973208999],
              [36.394208427737503, 13.608588223336399],
              [36.395259456619897, 13.612475650236499],
              [36.3954570859824, 13.6137961821807],
              [36.395915226777298, 13.615029399571901],
              [36.397815163603198, 13.6216973928539],
              [36.398917845614498, 13.6254776577354],
              [36.3999306960973, 13.6290636152546],
              [36.401001937073602, 13.6353732842204],
              [36.401906989722399, 13.6417591698055],
              [36.402897382323097, 13.6449411358101],
              [36.403970869087601, 13.648485332764499],
              [36.405345291472301, 13.652319727275],
              [36.409210292982301, 13.663237698689899],
              [36.410512850144201, 13.6670194805312],
              [36.411983841422, 13.6704302334471],
              [36.413515468981402, 13.6741049753082],
              [36.415253709056202, 13.677989141304501],
              [36.417465810443296, 13.6834595967476],
              [36.418564000878099, 13.6862460542898],
              [36.4203089783176, 13.690653695777799],
              [36.422276288789803, 13.695589283411],
              [36.4238101621374, 13.699580007737],
              [36.425894253596603, 13.7049757782085],
              [36.427598806848202, 13.7096296125303],
              [36.429096747584502, 13.7140084526047],
              [36.430399304746402, 13.717870142151799],
              [36.431713090849499, 13.7217055878643],
              [36.432878654930597, 13.725403526502699],
              [36.434556258723703, 13.729738439935399],
              [36.4358700448267, 13.7332115487928],
              [36.438609906443297, 13.7403692195119],
              [36.439537416974098, 13.7429979342718],
              [36.440557004821599, 13.7459451138379],
              [36.441473286411401, 13.748484326892999],
              [36.442205413368001, 13.750541418312499],
              [36.442775843573401, 13.7523999837784],
              [36.443308095379201, 13.7550896421802],
              [36.443694370951398, 13.7568281964937],
              [36.444329929014899, 13.759201509512099],
              [36.445086759641796, 13.7627243516365],
              [36.445412398932298, 13.7640353152544],
              [36.445915455491402, 13.765416072650799],
              [36.450472159770101, 13.7682975322081],
              [36.450624873368398, 13.7668644413174],
              [36.451170599903499, 13.765701820793799],
              [36.4519566257771, 13.764990721715],
              [36.453591559594201, 13.764074578610501],
              [36.454418009655598, 13.7636514065364],
              [36.454669537935096, 13.763322029804501],
              [36.454948015673203, 13.762855230198101],
              [36.4548739046623, 13.7624058801642],
              [36.454739157369701, 13.7619325347776],
              [36.453813892626997, 13.759735937891501],
              [36.453456812301603, 13.757124861937299],
              [36.453670162181503, 13.7562305017372],
              [36.454348390221099, 13.7548976805609],
              [36.454840217839099, 13.7543305203115],
              [36.456207902859198, 13.753146023512601],
              [36.456695238900799, 13.752718468868],
              [36.457728301477601, 13.7519876976422],
              [36.459352006353598, 13.7509340742177],
              [36.459902224465097, 13.750366904365601],
              [36.461640464539897, 13.7496274000718],
              [36.462475897754103, 13.7493045479074],
              [36.463046327959503, 13.748900982075799],
              [36.463351755156097, 13.7459080289072],
              [36.466462171827402, 13.744370089844899],
              [36.4670438309739, 13.743977422934201],
              [36.467661422731702, 13.7434124177245],
              [36.468739401072597, 13.741396712360601],
              [36.471153623398699, 13.7400921665288],
              [36.472213635434002, 13.739097390913599],
              [36.474043952825397, 13.738288041848],
              [36.476923053310998, 13.737308529402201],
              [36.477634968173597, 13.736850404136099],
              [36.479422615589002, 13.736464269287699],
              [36.479705584903499, 13.7356418217559],
              [36.480576950729102, 13.735552377685901],
              [36.481614504882302, 13.734476864610601],
              [36.482353369203501, 13.7330522930423],
              [36.482923799408901, 13.732509077408],
              [36.483489738037903, 13.7316342576166],
              [36.484163474501003, 13.7299107876307],
              [36.484468901697603, 13.7290294132744],
              [36.484843948328702, 13.7284163761872],
              [36.484967466680303, 13.7279364172069],
              [36.484471147485799, 13.726699427489301],
              [36.484174703442001, 13.7262456447934],
              [36.482256800310402, 13.725148272434099],
              [36.4804062708251, 13.723488023381],
              [36.479204774132597, 13.722355731151501],
              [36.478742141761302, 13.7219106668138],
              [36.478182940497, 13.721007445415999],
              [36.477453059328603, 13.7195827920387],
              [36.4748704028868, 13.7214372396065],
              [36.475923677557397, 13.7173596173473],
              [36.477805648077599, 13.7100747001521],
              [36.476671525031399, 13.706097245075],
              [36.476083128520301, 13.7044652253715],
              [36.475651937183997, 13.702237543816301],
              [36.475514944103097, 13.7007538656382],
              [36.476150502166597, 13.698240318879799],
              [36.477248692601499, 13.6945463164075],
              [36.478030226898703, 13.6923316326384],
              [36.479568591822698, 13.690424587735301],
              [36.481410138155198, 13.6882207751678],
              [36.484293730217203, 13.6877974662317],
              [36.485845569870499, 13.684262588679699],
              [36.485865781964399, 13.68248421971],
              [36.4855873042263, 13.680103584508901],
              [36.486658545202602, 13.679230751638601],
              [36.488944757600699, 13.6779171320684],
              [36.490478630948303, 13.676450757379101],
              [36.493710320182998, 13.671739530470999],
              [36.494083121025902, 13.668743411721],
              [36.494265029870903, 13.668014561339399],
              [36.495605765432501, 13.667449373413501],
              [36.496775821089997, 13.6655268514395],
              [36.5000591634535, 13.665886916340099],
              [36.503497465203402, 13.6672027852063],
              [36.5051615942673, 13.665134052738701],
              [36.505184052149403, 13.663045661986001],
              [36.505370452570801, 13.660952888218],
              [36.505788169177997, 13.652446302695999],
              [36.509585797041602, 13.650713522930699],
              [36.510683987476398, 13.648520250696301],
              [36.511173569306202, 13.644170746055799],
              [36.510814243192598, 13.6415191020431],
              [36.510670512747097, 13.639965202986801],
              [36.510715428511297, 13.638302170476701],
              [36.5110231014962, 13.6362179071584],
              [36.511721541629598, 13.6334963335448],
              [36.512381803363397, 13.6319489292723],
              [36.513131896625602, 13.630611038424201],
              [36.513933643016699, 13.6291203615583],
              [36.515004883993001, 13.6274856260442],
              [36.515907690853602, 13.6260298507208],
              [36.516747615644199, 13.6244321979175],
              [36.519287602110097, 13.6182139081959],
              [36.520855162280803, 13.6150752358466],
              [36.522586664991003, 13.6120565707896],
              [36.524232827749103, 13.6096512150254],
              [36.525768946885002, 13.607571064601499],
              [36.527100699293698, 13.6043405743984],
              [36.523873501635499, 13.6000077193455],
              [36.522948236892802, 13.5961593790938],
              [36.521901699586799, 13.593284543000999],
              [36.521030333761203, 13.5914967571804],
              [36.520565455601698, 13.5902896121181],
              [36.520572192966299, 13.5876526445583],
              [36.521452541944797, 13.586216270748601],
              [36.522503570827197, 13.5846336299544],
              [36.526054161987702, 13.584271258300101],
              [36.531524902068, 13.5816626026393],
              [36.537813109056799, 13.5819485738234],
              [36.540047668326103, 13.577772487093901],
              [36.5397781737408, 13.57322302301],
              [36.545731758286301, 13.574565604257],
              [36.549188026342001, 13.5739499825794],
              [36.552763321172797, 13.572413104470799],
              [36.556574423765603, 13.5701274164908],
              [36.559628695731703, 13.5656498493197],
              [36.560868370823698, 13.561010642642801],
              [36.561703804037997, 13.555498055740101],
              [36.558476606379799, 13.5515376470421],
              [36.564005736953497, 13.5474090581388],
              [36.566071862107002, 13.540752076099601],
              [36.566366060362498, 13.5389114935828],
              [36.567015093155298, 13.5377062645768],
              [36.564688456569499, 13.5329900924028],
              [36.572402739071798, 13.522238617340699],
              [36.578172168984104, 13.521607575395601],
              [36.581107414175001, 13.519024434716499],
              [36.5852755970933, 13.5135807507257],
              [36.5866230700194, 13.512844872152201],
              [36.588790255642401, 13.512558817850399],
              [36.590899050771903, 13.5102114127154],
              [36.591840036032004, 13.5079709840302],
              [36.594476591390901, 13.507291863576199],
              [36.598078835680198, 13.5047238536409],
              [36.600082078763798, 13.500376079927401],
              [36.601135353434401, 13.4990156101028],
              [36.601492433759802, 13.4979892473182],
              [36.601683325757698, 13.496279360793499],
              [36.599311773407599, 13.4906014888288],
              [36.597241156677697, 13.489553251549699],
              [36.594997614255597, 13.4883324860866],
              [36.594132985794701, 13.4879808870053],
              [36.590683455103701, 13.486063461791099],
              [36.5880895697208, 13.485183362832601],
              [36.584462621761098, 13.483604417866299],
              [36.578753828130601, 13.482180519406301],
              [36.574286955380302, 13.477854174648],
              [36.571906419877401, 13.4730319878699],
              [36.570927256217701, 13.466853418961801],
              [36.571850275172103, 13.4613823366295],
              [36.5729304993013, 13.4574509427667],
              [36.572782277279401, 13.4548802130958],
              [36.571609975833603, 13.450754319943],
              [36.570244536601699, 13.448685886203901],
              [36.5660830910481, 13.4483233078062],
              [36.5667119117469, 13.444057509918499],
              [36.567232934611702, 13.4405277395689],
              [36.567506920773397, 13.4386798316431],
              [36.568694942736599, 13.430624009953499],
              [36.5694877059749, 13.4252569525799],
              [36.576278969522797, 13.41755893675],
              [36.579288325724598, 13.4115908386744],
              [36.5840044809662, 13.408038745071099],
              [36.587341722246698, 13.403787523447599],
              [36.589964802876402, 13.4017558236681],
              [36.591420073636598, 13.394915641580001],
              [36.592156692169603, 13.3906401559221],
              [36.593099923217899, 13.387369587516],
              [36.595709529118302, 13.3858315092564],
              [36.597151325149298, 13.3850996077707],
              [36.5987031648026, 13.3840618331936],
              [36.601997736107201, 13.3820692934686],
              [36.604782513487898, 13.377616606743199],
              [36.609471719270999, 13.3752023334574],
              [36.613132354053803, 13.3776996310956],
              [36.616626800509003, 13.37527661915],
              [36.618879326083999, 13.3777826554194],
              [36.620774771333501, 13.3796485103069],
              [36.621938089626397, 13.379967495761401],
              [36.628057862499503, 13.3818639211124],
              [36.6328144419289, 13.383301523928001],
              [36.634640267743798, 13.383085228612099],
              [36.636156174785803, 13.3838367993795],
              [36.637687802345198, 13.3818901388698],
              [36.637478944041703, 13.380092031064001],
              [36.641290046634502, 13.378468697421001],
              [36.642976633580503, 13.378088534260201],
              [36.644656483161803, 13.3775925733727],
              [36.646507012647099, 13.378101643344699],
              [36.649103143818202, 13.3801794242203],
              [36.651153548454197, 13.3785560911662],
              [36.650946935938798, 13.3766902278179],
              [36.6508458754694, 13.3756546018781],
              [36.650839138104701, 13.374160146473599],
              [36.651317490993499, 13.3734260246347],
              [36.652393223546298, 13.372294249085099],
              [36.6535363297453, 13.3708303616307],
              [36.654082056280402, 13.3701202637678],
              [36.654791725354897, 13.3695412593468],
              [36.656422167595501, 13.369126123245501],
              [36.657477688054399, 13.368280554344199],
              [36.658477063808, 13.3673169954694],
              [36.660082802378298, 13.3669346297693],
              [36.6625284657393, 13.366689915403001],
              [36.663976999135002, 13.3665216741321],
              [36.665445744624499, 13.3664670503177],
              [36.6663350767558, 13.366536968798],
              [36.671760901071899, 13.368391986074499],
              [36.673056720869198, 13.369047465798401],
              [36.674889284048803, 13.369864628025001],
              [36.676688160405298, 13.3705790964722],
              [36.677918852344497, 13.371035743391801],
              [36.679969256980499, 13.3719861779864],
              [36.685076179370697, 13.3747740978419],
              [36.689228641771599, 13.37395258249],
              [36.690079795503301, 13.373710060135201],
              [36.6919909612702, 13.3733582930428],
              [36.694367005196703, 13.373098290948899],
              [36.696341053033599, 13.3729781218191],
              [36.698200565671698, 13.3727749266088],
              [36.6996288869735, 13.3724450071389],
              [36.7030716802999, 13.3730851815922],
              [36.706745789811897, 13.371745838557899],
              [36.707466687827399, 13.371085996349301],
              [36.7088029318125, 13.370238249403799],
              [36.710952151129803, 13.368881411103599],
              [36.712479287112799, 13.3675070856203],
              [36.714040109918997, 13.3670985008579],
              [36.716137676107401, 13.3666942853046],
              [36.717860195664699, 13.365971065515801],
              [36.720519208905699, 13.366073758488],
              [36.721709476657097, 13.366335953112401],
              [36.723629625576898, 13.367292961071801],
              [36.730544407476401, 13.369287807917299],
              [36.732170358140699, 13.3681472731874],
              [36.732415149055598, 13.3639150119115],
              [36.733167488106098, 13.363626594913701],
              [36.733987200802801, 13.3633119577954],
              [36.734878778722297, 13.362968915469599],
              [36.736129682755497, 13.3624860334071],
              [36.737236856343102, 13.3620599601962],
              [36.739779088597203, 13.3648523647724],
              [36.743275780840598, 13.3661043478756],
              [36.744874782046402, 13.366847231809899],
              [36.746145898173403, 13.3679943275068],
              [36.749395553713697, 13.371411547863399],
              [36.753835477005502, 13.371699955548801],
              [36.754942650593101, 13.372372905472799],
              [36.756034103663303, 13.372801145355201],
              [36.757590434893103, 13.3733604779332],
              [36.758394427072403, 13.3735636726498],
              [36.762156122324598, 13.374133927875],
              [36.765533787792897, 13.3764040109674],
              [36.765749383461099, 13.3775117338361],
              [36.7664118909831, 13.378160634216099],
              [36.767312452055499, 13.378287355298401],
              [36.767965976424698, 13.3788554145035],
              [36.768547635571103, 13.3794802780819],
              [36.769865913250598, 13.3805726930314],
              [36.770824864816397, 13.381527459639701],
              [36.770584565477897, 13.3835331126367],
              [36.770413885573902, 13.386825580845599],
              [36.770854060063101, 13.3873892503847],
              [36.771321184010802, 13.3879398100557],
              [36.771727671676899, 13.388536248278401],
              [36.7727270474305, 13.389309649487499],
              [36.773427733352101, 13.3898907911337],
              [36.773975705675397, 13.3903998351071],
              [36.774570839551103, 13.391011560346],
              [36.775576952669397, 13.391922591123301],
              [36.776333783296202, 13.3930455355853],
              [36.777020994488602, 13.394098564346899],
              [36.777710451469098, 13.395035799762599],
              [36.778200033299001, 13.395577603186],
              [36.779008517054699, 13.396918998347299],
              [36.779331910556998, 13.398052842821301],
              [36.781153244795497, 13.401834470430501],
              [36.783938022176301, 13.4038727233859],
              [36.784638708097901, 13.403947000230501],
              [36.785779568508701, 13.4038618003186],
              [36.786597035417302, 13.4039863232566],
              [36.7873022129153, 13.4052686873053],
              [36.787362849197002, 13.405602930984699],
              [36.787369586561603, 13.4062364634808],
              [36.787344882891297, 13.406762949560701],
              [36.787311196068202, 13.4075777991868],
              [36.787928787825997, 13.408145789249501],
              [36.788737271581702, 13.408580519198299],
              [36.789756859429197, 13.4091135538412],
              [36.790024108226199, 13.4091878290665],
              [36.790996534521298, 13.4097077550005],
              [36.792790919301297, 13.4106056076415],
              [36.794055298063697, 13.411143006887499],
              [36.795371329954897, 13.411464135131601],
              [36.797266775204399, 13.4116520058781],
              [36.7985513660607, 13.411499087839699],
              [36.800094222561199, 13.4112522342296],
              [36.801019487303797, 13.411322139702399],
              [36.8020592872452, 13.4115209332797],
              [36.802263653972297, 13.411721911234601],
              [36.802497215946197, 13.4122112481611],
              [36.802724040555397, 13.412927775433999],
              [36.802802643142797, 13.413482645695501],
              [36.802977814623198, 13.4144525733108],
              [36.803186672926799, 13.4153307476388],
              [36.803633584780599, 13.4161827047397],
              [36.804239947597402, 13.4167484899651],
              [36.806101706023703, 13.4169341720846],
              [36.807199896458599, 13.4166764015742],
              [36.808091474378102, 13.416698246543399],
              [36.808861779734201, 13.417305535892799],
              [36.809315428952701, 13.4178909788479],
              [36.809315428952701, 13.4183038463425],
              [36.809180681660102, 13.419782736252399],
              [36.809517549891602, 13.4207810364043],
              [36.8098005192061, 13.4220283596455],
              [36.809268267400299, 13.4265544939351],
              [36.809888104946303, 13.428797869594399],
              [36.813025471076102, 13.429121158612899],
              [36.811491597728498, 13.4324829017139],
              [36.8113635878005, 13.433317323178599],
              [36.811543250857298, 13.4340468934946],
              [36.8120575363575, 13.4350145536939],
              [36.812618983409997, 13.4355344237013],
              [36.813559968670098, 13.4362705402297],
              [36.813712682268402, 13.436687744736799],
              [36.813824971678997, 13.437806111353201],
              [36.814047304711799, 13.4400056961018],
              [36.814321290873401, 13.4411742855507],
              [36.814705320657403, 13.442102600211999],
              [36.815237572463197, 13.4428277752817],
              [36.815691221681703, 13.443024358507],
              [36.816587291177598, 13.4437364252845],
              [36.8167579710816, 13.444752099696],
              [36.816616486424401, 13.445702242829601],
              [36.816780428963703, 13.4465803027901],
              [36.817092593524897, 13.4472071744751],
              [36.817521539073098, 13.447368806629701],
              [36.817858407304698, 13.447906123549201],
              [36.817773067352697, 13.448893385642499],
              [36.821923283965297, 13.4499418011108],
              [36.8228530402844, 13.448976385367599],
              [36.823351605267, 13.4483582551455],
              [36.824205004786997, 13.4480349920621],
              [36.825860150697899, 13.447595965149199],
              [36.826376681986297, 13.447528254459201],
              [36.828790904312399, 13.447211542913101],
              [36.8322022566038, 13.4460932201745],
              [36.833536254800698, 13.445735006318699],
              [36.837693208777999, 13.4469800155871],
              [36.837601131461398, 13.443024358507],
              [36.837587656732097, 13.4421397327237],
              [36.838286096865502, 13.441986834109199],
              [36.838883476529503, 13.4419540701078],
              [36.843424460290699, 13.441543427576001],
              [36.843675988570197, 13.440951489497101],
              [36.843916287908698, 13.4402918372225],
              [36.844363199762597, 13.4363033050079],
              [36.844250910352102, 13.435680773458101],
              [36.844468751808499, 13.4350189223541],
              [36.845553467514101, 13.4344160464917],
              [36.852248162168998, 13.4341976128342],
              [36.854244667887897, 13.432386790154499],
              [36.854922895927402, 13.430278879228201],
              [36.855172178418798, 13.4288481104839],
              [36.856843044847203, 13.4264671176331],
              [36.8577548348606, 13.426178775611],
              [36.858421833959099, 13.4261962508949],
              [36.859250529808698, 13.426355712802],
              [36.860016343588399, 13.426237754689099],
              [36.861170678728499, 13.4260717394691],
              [36.862302555986503, 13.425479763210401],
              [36.863162692871001, 13.42446837604],
              [36.863989142932397, 13.4233630538703],
              [36.864364189563503, 13.4233608694341],
              [36.8687704260321, 13.422290493303001],
              [36.869484586683001, 13.4206412309437],
              [36.869839421220199, 13.4196167165686],
              [36.870585022905999, 13.4185332168662],
              [36.872599494930697, 13.417803599393],
              [36.872381653474299, 13.413580949348299],
              [36.8727319964351, 13.412796703531599],
              [36.876253392348801, 13.413207395253499],
              [36.880771918228, 13.4083467700271],
              [36.881021200719303, 13.4073200186387],
              [36.881670233512097, 13.406749842037501],
              [36.887253263002897, 13.4054805934828],
              [36.887340848743101, 13.405768960352001],
              [36.887527249164499, 13.4066711968829],
              [36.887626063845801, 13.4069486393968],
              [36.887742844832701, 13.407352787367801],
              [36.887816955843697, 13.407654259465801],
              [36.887855134243203, 13.407999422707899],
              [36.887985389959397, 13.408440706637199],
              [36.888061746758602, 13.4086482408783],
              [36.888138103557701, 13.408823006416],
              [36.888353699225902, 13.4091506914567],
              [36.888890442608201, 13.409692463077301],
              [36.888969045195601, 13.409808244757199],
              [36.892607222096203, 13.4093735170292],
              [36.893885075587903, 13.4084756597851],
              [36.894702542496397, 13.4077722267055],
              [36.895499797311103, 13.4068852864101],
              [36.8968584991783, 13.4056793918918],
              [36.898176776857802, 13.404196045953899],
              [36.8988100891331, 13.403315646319699],
              [36.899411960373399, 13.402330381368101],
              [36.899924000085399, 13.400735598057199],
              [36.903272470306902, 13.395234606805699],
              [36.904411084929599, 13.393873539915701],
              [36.904869225724497, 13.390334293024999],
              [36.904810835230997, 13.389436364702901],
              [36.904954565676498, 13.3880140918197],
              [36.905298171272598, 13.386943558300199],
              [36.905628302139498, 13.385975704510701],
              [36.908965543420102, 13.384455095664901],
              [36.912031044327101, 13.3830590109848],
              [36.914990993188297, 13.3842715732582],
              [36.917313138197699, 13.3834347967026],
              [36.917427673396503, 13.382632974142],
              [36.917427673396503, 13.381311162730499],
              [36.917111017258797, 13.3799478322867],
              [36.916713512745602, 13.3767535887541],
              [36.917221060881097, 13.376213927833],
              [36.920746948371303, 13.376902159159901],
              [36.921988869251599, 13.3773806426381],
              [36.925804463420903, 13.3782239947656],
              [36.927223801569802, 13.3778023190709],
              [36.927731349705297, 13.3767448493156],
              [36.928295042546097, 13.3722680302835],
              [36.931001217339499, 13.371966513860301],
              [36.934167778716002, 13.3751258628676],
              [36.934980754048098, 13.3755147701865],
              [36.937242262775897, 13.379135073927801],
              [36.939885555499501, 13.3808108388326],
              [36.941772017596101, 13.3770660234717],
              [36.943364281437198, 13.3739110696718],
              [36.944262596721302, 13.372302988685],
              [36.944821797985703, 13.371120954922199],
              [36.9453562955797, 13.369973874102101],
              [36.945594349129998, 13.369394869329501],
              [36.946737455329099, 13.3682849242169],
              [36.947381996545403, 13.368324253068099],
              [36.948219675547897, 13.369091164383301],
              [36.951635519415703, 13.3676491068997],
              [36.951118988127398, 13.366753278968099],
              [36.950622668932901, 13.365601807445801],
              [36.950456480605297, 13.3642886425095],
              [36.950535083192698, 13.363093459555101],
              [36.950629406297502, 13.362199799692],
              [36.951350304313003, 13.3610920064637],
              [36.951907259789202, 13.3608888012365],
              [36.952634895169297, 13.3608385461539],
              [36.953443378925002, 13.3613869814889],
              [36.954076691200299, 13.361793390932799],
              [36.9550625922246, 13.3622085396578],
              [36.956198961059101, 13.362306864251201],
              [36.957139946319202, 13.3622260195884],
              [36.9577013933717, 13.3622871993357],
              [36.958226907813, 13.3628072265609],
              [36.959648491750102, 13.366290069073701],
              [36.959926969488201, 13.3681931568726],
              [36.962262589226903, 13.371284823165199],
              [36.9636864189522, 13.3706992667981],
              [36.964750922563901, 13.3698995867751],
              [36.965092282371799, 13.369010321995001],
              [36.9655279652846, 13.368516527359199],
              [36.966516112097203, 13.367531119996601],
              [36.966695775154001, 13.3673978384255],
              [36.967133703854998, 13.3671793438872],
              [36.968474439416497, 13.3662114107014],
              [36.9691908458556, 13.365748199766101],
              [36.969797208672396, 13.3654182706799],
              [36.970792092849599, 13.3650402719006],
              [36.9715376945354, 13.364841439934899],
              [36.972651605487698, 13.364823960193799],
              [36.974270818787303, 13.364841439934899],
              [36.975690156936203, 13.3648195902583],
              [36.976797330523901, 13.3647103418459],
              [36.977893275170501, 13.364496214813901],
              [36.979764016749698, 13.3634277617825],
              [36.980291776979101, 13.362464183517201],
              [36.981248482756698, 13.361317061515599],
              [36.982813797139301, 13.3602529644199],
              [36.983471813084897, 13.360233299337001],
              [36.984646360318898, 13.3601830441178],
              [36.9884350050297, 13.360220189280801],
              [36.989515229158798, 13.360202709204801],
              [36.9958191566651, 13.359612755897601],
              [36.9966882767025, 13.359129867116801],
              [36.9977325682203, 13.3582361925705],
              [37.001822148551298, 13.3566717078893],
              [37.0108300050628, 13.3596083858677],
              [37.015155393155801, 13.3613957214842],
              [37.016797064337602, 13.3584612502864],
              [37.019429128120002, 13.3569186174586],
              [37.023464809533898, 13.3550263659899],
              [37.027091757493601, 13.354224448939799],
              [37.034931804135702, 13.3532520936295],
              [37.039670417259401, 13.3510233093754],
              [37.042129555349703, 13.347055148443401],
              [37.043827371236702, 13.342750406738499],
              [37.047921443144098, 13.338849851183801],
              [37.0502638002474, 13.333970244019699],
              [37.0534213784711, 13.3321324443567],
              [37.056057933829997, 13.3316932059835],
              [37.060266540936098, 13.330991734091],
              [37.065530668500998, 13.3298488331596],
              [37.067520436855403, 13.3251373145484],
              [37.0681649780717, 13.3220865791527],
              [37.068187435953803, 13.3180786061397],
              [37.070293985295102, 13.3167782952916],
              [37.074657551787702, 13.3192259334398],
              [37.077348006063602, 13.3173858363556],
              [37.078751623695098, 13.3161204883196],
              [37.078349627605398, 13.3118042686653],
              [37.083840579779597, 13.314695597984899],
              [37.089003646875099, 13.3166865083796],
              [37.0929854293719, 13.314850762830099],
              [37.093618741647198, 13.3138170571268],
              [37.097149120713802, 13.316334658227699],
              [37.100279749479, 13.3163565122893],
              [37.104670265430101, 13.3153534088288],
              [37.1063478692232, 13.3143284469693],
              [37.1096940936566, 13.3137318253813],
              [37.113877997092303, 13.3125167233888],
              [37.117439817193898, 13.310676575309101],
              [37.122676995300303, 13.308432100232],
              [37.125614486279403, 13.303792286892],
              [37.1293716899552, 13.3064083395371],
              [37.134388780816998, 13.305405194869399],
              [37.136281980278298, 13.303759504098201],
              [37.138999384012699, 13.3031584854259],
              [37.141925646050801, 13.3027650905786],
              [37.144227578966301, 13.3017444465249],
              [37.149240178251702, 13.301364162630501],
              [37.151337744440099, 13.3001358852863],
              [37.156354835301897, 13.2991349002139],
              [37.159090205342103, 13.2964575726657],
              [37.162661008596501, 13.2933693205776],
              [37.164343103965997, 13.2917235480926],
              [37.167693819975703, 13.290294143007699],
              [37.169991261314898, 13.2901039921551],
              [37.174172918962398, 13.2890964087133],
              [37.176688201757997, 13.2878702548884],
              [37.179616709584202, 13.2868539194807],
              [37.1815076632573, 13.2852081027916],
              [37.1857027956341, 13.282539364355699],
              [37.189064740584897, 13.279450935089599],
              [37.191178027290803, 13.275938427443],
              [37.194342342879096, 13.271604006036499],
              [37.196880083556799, 13.2670574798994],
              [37.1975223789849, 13.264779813187699],
              [37.198369041140197, 13.263125103351401],
              [37.200486819422501, 13.2601041905553],
              [37.203431047766202, 13.255900643802701],
              [37.205939593197101, 13.2555028004607],
              [37.209885443082598, 13.2582286700927],
              [37.212162672327899, 13.260526071370199],
              [37.213177768598896, 13.264475976955101],
              [37.215037281237002, 13.2671842591851],
              [37.218567660303599, 13.2694925049598],
              [37.220847135337102, 13.2719974514137],
              [37.224375268615503, 13.27471876449],
              [37.228543451533803, 13.2755799608308],
              [37.230818434990802, 13.276253178178401],
              [37.233776138063803, 13.2771274836361],
              [37.238595599563098, 13.276189790910299],
              [37.240863845655497, 13.277649879644599],
              [37.240803209373802, 13.281730649945001],
              [37.241483683201501, 13.283868272994599],
              [37.241829534585897, 13.2846092238292],
              [37.242570644695299, 13.2865785246371],
              [37.246197592654902, 13.292178158341599],
              [37.250493785501199, 13.2949822979127],
              [37.254909005122698, 13.295471871566599],
              [37.256795467219298, 13.2944468255969],
              [37.262668203389303, 13.290545491607],
              [37.264972382092999, 13.289522610463001],
              [37.267900889919297, 13.288713919326099],
              [37.2706138020773, 13.288731404568299],
              [37.275835259666302, 13.288351100264199],
              [37.280623280130598, 13.284932706079999],
              [37.289197699517501, 13.2880276225708],
              [37.294816661619699, 13.2905542341623],
              [37.302315348453902, 13.2928884851499],
              [37.306056831612203, 13.295194300911],
              [37.312720085232201, 13.2975219505054],
              [37.3202344925839, 13.2977798483341],
              [37.323374104501902, 13.296553738411999],
              [37.3337204507867, 13.3086047528994],
              [37.334966863243402, 13.3094417887704],
              [37.335323943568902, 13.3099269622851],
              [37.335728185446698, 13.3106153824962],
              [37.336224504641201, 13.311472080470301],
              [37.336646712824702, 13.312448974716901],
              [37.3370532004908, 13.3138061299816],
              [37.3373900687223, 13.3147524189257],
              [37.337792064812, 13.3159828075643],
              [37.338140161984597, 13.3169290880001],
              [37.338349020288099, 13.3178928478748],
              [37.338627498026199, 13.318624953151399],
              [37.340019886716597, 13.320753509345799],
              [37.343208905975203, 13.323675326526599],
              [37.344044339189502, 13.3243068889533],
              [37.345652323548002, 13.325270619444201],
              [37.349348890942203, 13.3248117006416],
              [37.350054068440201, 13.3261097830029],
              [37.350705347021197, 13.3273095195969],
              [37.351435228189501, 13.328653479752299],
              [37.352856812126703, 13.331271448983699],
              [37.353885383127, 13.333168257062001],
              [37.351592433364303, 13.33646359792],
              [37.351608153881699, 13.3397283011218],
              [37.3516867564691, 13.3413103770345],
              [37.351805783244203, 13.3429252200789],
              [37.352084260982302, 13.344277834023501],
              [37.352221254063103, 13.3448219366889],
              [37.354298608157698, 13.3492096756314],
              [37.3552598055117, 13.350776393775201],
              [37.355742649976897, 13.3519913034959],
              [37.356140154490099, 13.353348236800899],
              [37.357779579883598, 13.3566913732625],
              [37.3601151996223, 13.3582493027344],
              [37.363099852153802, 13.3598946226577],
              [37.3664505681636, 13.360128418867699],
              [37.3682202492733, 13.359440139657099],
              [37.373592174672297, 13.358478730488599],
              [37.375083378044003, 13.3584983957145],
              [37.375707707166498, 13.3577139704598],
              [37.377234843149502, 13.357108715804401],
              [37.379220119927403, 13.357110900842001],
              [37.380084748388299, 13.357635309290799],
              [37.381016750495597, 13.357904068179],
              [37.382384435515696, 13.3577926316031],
              [37.383601652725702, 13.357491097081001],
              [37.384616748996699, 13.3567897001044],
              [37.385618370538502, 13.3560096393901],
              [37.3867839346197, 13.3556884371866],
              [37.387884370842698, 13.355603220203699],
              [37.3905052056841, 13.3568224757094],
              [37.395533525486996, 13.357222337732701],
              [37.396054548351799, 13.3583082984635],
              [37.400056542942501, 13.360622230678899],
              [37.4013456253752, 13.3615705213226],
              [37.404561594092399, 13.360132788888199],
              [37.404988293852298, 13.360670300801299],
              [37.406852298066902, 13.3630038752194],
              [37.4049119370532, 13.365678281057001],
              [37.407860656973298, 13.3703037969543],
              [37.408723039646098, 13.370782293533701],
              [37.411867143140498, 13.3692899928446],
              [37.412830586282702, 13.3736969508118],
              [37.414640691580203, 13.3750712410029],
              [37.416688850428002, 13.3749772913666],
              [37.417117795976203, 13.374664853940599],
              [37.418274376904499, 13.3731354341223],
              [37.418474252055198, 13.3727443380676],
              [37.418698830876203, 13.3721303815284],
              [37.4195589677607, 13.3681472731874],
              [37.423217356755302, 13.3655100390803],
              [37.423336383530497, 13.364697232033],
              [37.425503569153399, 13.362459813538999],
              [37.426188534557603, 13.3620490352322],
              [37.426756718974801, 13.3617628009985],
              [37.427311428662698, 13.3615858163024],
              [37.429631327883897, 13.3614000914817],
              [37.430271377523901, 13.3614590864403],
              [37.431726648284098, 13.361234031520899],
              [37.432083728609598, 13.3576899351053],
              [37.437121031565297, 13.358834889331799],
              [37.438055279460798, 13.357654974585399],
              [37.441345359188901, 13.3551268785657],
              [37.441971934099499, 13.3547969349534],
              [37.4426344416216, 13.354602464666],
              [37.443310423872902, 13.3545871692435],
              [37.4446578967991, 13.354550023213299],
              [37.447462886273698, 13.35490181774],
              [37.448597009319897, 13.354737938365901],
              [37.450539616121802, 13.3546046497263],
              [37.451482847170098, 13.354539097909299],
              [37.452266627255497, 13.3546702015254],
              [37.452727013838597, 13.3548537464685],
              [37.454099190435102, 13.355810799981199],
              [37.4557902689575, 13.3572135975863],
              [37.457101809272302, 13.358116016034201],
              [37.459906798746999, 13.3593177787029],
              [37.461088083345601, 13.3593418138953],
              [37.462082967522797, 13.359311223650099],
              [37.462958824924797, 13.359571240610499],
              [37.463751588162999, 13.359990763182701],
              [37.464885711209199, 13.3607577009991],
              [37.465999622161497, 13.361858940778999],
              [37.466895691657399, 13.363301032888099],
              [37.4672280683126, 13.3638997170794],
              [37.469476102311098, 13.3659382911538],
              [37.471522015370603, 13.367043877174099],
              [37.473356824338502, 13.367795497976999],
              [37.475746342994199, 13.367963738359],
              [37.477284707918301, 13.3674306125891],
              [37.4801952494388, 13.3659382911538],
              [37.481104793664002, 13.3653833112801],
              [37.4818032337974, 13.364882954314901],
              [37.483211343005301, 13.3638778673175],
              [37.484749707929303, 13.362929585745],
              [37.485850144152302, 13.3624576285498],
              [37.4892457759263, 13.3625843578874],
              [37.4900632428349, 13.3630956445385],
              [37.490864989225898, 13.3639150119115],
              [37.493375780445099, 13.3662791443013],
              [37.495017451626801, 13.368206266495401],
              [37.495882080087803, 13.3689709932558],
              [37.496510900786603, 13.369525964871499],
              [37.497975154699802, 13.3703758992394],
              [37.501139470288102, 13.370592205965201],
              [37.501110275041299, 13.367673141261699],
              [37.502888939303901, 13.366759833818699],
              [37.503616574684003, 13.366619996967],
              [37.504368913734503, 13.3665347838458],
              [37.505121252784903, 13.3664583105062],
              [37.505848888165097, 13.3663621725591],
              [37.5079711580238, 13.366113087699899],
              [37.508683072886498, 13.366054093879701],
              [37.510109148399998, 13.3659535858566],
              [37.510821063262703, 13.365912071660899],
              [37.511535223913498, 13.3658727424163],
              [37.512247138776203, 13.3658399680409],
              [37.512965791003502, 13.365798453825599],
              [37.513684443230801, 13.365767864399301],
              [37.514400849669897, 13.365770049358501],
              [37.515806713089503, 13.3659514008991],
              [37.516509644799299, 13.366082498313499],
              [37.5172170680856, 13.366222335476801],
              [37.519316880062199, 13.3667401692664],
              [37.520664352988398, 13.3671990084038],
              [37.522555306661502, 13.368123238872601],
              [37.523154932113599, 13.368516527359199],
              [37.523736591260104, 13.3689338494406],
              [37.5248482564242, 13.3698362240369],
              [37.525376016653603, 13.3703190913802],
              [37.525883564789098, 13.3708106974104],
              [37.527177138798301, 13.3724624863274],
              [37.527556677005798, 13.3730611477697],
              [37.527938461001597, 13.3736598077249],
              [37.529737337358, 13.375831576246499],
              [37.5302426397053, 13.376323171032],
              [37.530759170993697, 13.3768038405192],
              [37.531816937240698, 13.377749882663499],
              [37.532367155352297, 13.3781977766092],
              [37.533532719433403, 13.3790083532914],
              [37.5341592943441, 13.379336078938501],
              [37.535464097294302, 13.379895396346299],
              [37.536131096392701, 13.380153206276701],
              [37.536795849702997, 13.3804197552367],
              [37.537449374072203, 13.380692858373299],
              [37.538729473351999, 13.381317717185199],
              [37.539998343690897, 13.3819556832507],
              [37.541905017881398, 13.382908259572799],
              [37.542554050674198, 13.3832010230983],
              [37.544541573240302, 13.383989735004199],
              [37.545197343397703, 13.3842737580496],
              [37.546450493219098, 13.3849313793535],
              [37.547043381306601, 13.385313716495199],
              [37.547627286241301, 13.3857157160352],
              [37.548206699599497, 13.386135193100399],
              [37.5487928503224, 13.386535006497899],
              [37.550037016990899, 13.387212284508699],
              [37.550695032936503, 13.387505042800999],
              [37.551323853635402, 13.3878240178485],
              [37.552004327463102, 13.388298110117701],
              [37.552478188775503, 13.3892047816726],
              [37.553374258271397, 13.389770630382101],
              [37.553928967959301, 13.390334293024999],
              [37.555664962245899, 13.3912125554423],
              [37.556260096121598, 13.3909722351989],
              [37.556861967362003, 13.390587722310199],
              [37.557466084390597, 13.3901201887657],
              [37.558063464054499, 13.3896351763719],
              [37.558651860565597, 13.389167640977201],
              [37.560291285959103, 13.3876514218137],
              [37.560843749858897, 13.387201360684999],
              [37.561441129522798, 13.386943558300199],
              [37.562094653891997, 13.386873645741501],
              [37.562786356660801, 13.386895493418301],
              [37.564949050707298, 13.3873608484627],
              [37.565651982417101, 13.387618650400601],
              [37.566323473091998, 13.3879114081988],
              [37.567246492046401, 13.3883767612794],
              [37.567879804321699, 13.3887241369363],
              [37.568488412926698, 13.389080251083],
              [37.569090284167103, 13.389445103680799],
              [37.570280551918501, 13.390220686707],
              [37.5714595907289, 13.391022483996901],
              [37.572629646386503, 13.3918330175067],
              [37.573218042897601, 13.3922350061593],
              [37.574989969795503, 13.393436598671601],
              [37.575589595247699, 13.3938211070083],
              [37.576797829304802, 13.3945682748601],
              [37.577406437909801, 13.394941857915899],
              [37.578632638272602, 13.3956824682239],
              [37.579885788094003, 13.3963575358105],
              [37.580525837733902, 13.3966502829776],
              [37.5811793621031, 13.396908074964101],
              [37.583274682503301, 13.3973078704646],
              [37.583995580518803, 13.3973668566298],
              [37.584714232746101, 13.397414919420299],
              [37.586854468910502, 13.3974717208876],
              [37.588282790212297, 13.397454243514501],
              [37.589010425592399, 13.3974389508119],
              [37.590494891599398, 13.3973581179396],
              [37.5912157896149, 13.397351563921699],
              [37.592450973130603, 13.397648679219101],
              [37.592700255621899, 13.398380542540799],
              [37.593708614528403, 13.4002506070627],
              [37.594514852495898, 13.401401912918599],
              [37.5949707475026, 13.401939332740501],
              [37.5959229617037, 13.402983584082],
              [37.596417035110001, 13.4034926003512],
              [37.597456835051403, 13.4044647528924],
              [37.5985617628508, 13.405338594518501],
              [37.5997295727202, 13.406131603045001],
              [37.600329198172297, 13.4065051681387],
              [37.6034059280204, 13.4082943402752],
              [37.604023519778302, 13.4086504254483],
              [37.604636619959699, 13.409006510093899],
              [37.605844854016802, 13.4097536307642],
              [37.607028384403598, 13.410537886513],
              [37.607616780914697, 13.4109398438992],
              [37.608202931637599, 13.411339616067499],
              [37.608789082360502, 13.4117328339448],
              [37.609379724659803, 13.412123866640201],
              [37.609974858535601, 13.4125170832341],
              [37.610574483987698, 13.4128906384022],
              [37.613170615158801, 13.410413366968999],
              [37.615454581768702, 13.4084953209285],
              [37.617325323347899, 13.4075450304884],
              [37.619847343507999, 13.4076629977818],
              [37.6236674292538, 13.409554835724901],
              [37.625138420531499, 13.4080168993145],
              [37.6272337409317, 13.406907132269501],
              [37.630130807722999, 13.4093625942118],
              [37.632203670241097, 13.410603423089301],
              [37.632610157907202, 13.412639417154301],
              [37.635938416034797, 13.4122505698352],
              [37.637589070369401, 13.414701608147899],
              [37.6388264996733, 13.4163334352967],
              [37.642567982831601, 13.4171679129014],
              [37.643805412135499, 13.418810645682999],
              [37.642130054130597, 13.4208356478902],
              [37.641701108582502, 13.4228693707828],
              [37.643778462676998, 13.423692903509],
              [37.645449329105404, 13.422480540025701],
              [37.6487663582921, 13.424125420891601],
              [37.647921941924999, 13.426563231563501],
              [37.6508212545045, 13.429029414476201],
              [37.652476400415502, 13.4302570354947],
              [37.653293867324003, 13.4331098102583],
              [37.654533542416097, 13.4343374103979],
              [37.657859554755603, 13.434761171265],
              [37.657017384176697, 13.4367904075147],
              [37.6561639846568, 13.440455658321],
              [37.656471657641603, 13.4411371528895],
              [37.656985943141699, 13.4424957676907],
              [37.660729672088301, 13.4425154260478],
              [37.663646950973501, 13.4417159848957],
              [37.664453188941003, 13.4461958789247],
              [37.6669460138544, 13.4466152503836],
              [37.669861046951397, 13.446224273890399],
              [37.6710962304671, 13.4486815177927],
              [37.671927172104901, 13.448685886203901],
              [37.674011263563997, 13.448288360461699],
              [37.675668655263202, 13.4491118059101],
              [37.676495105324598, 13.4503371399191],
              [37.677739271993097, 13.4511562099597],
              [37.6781502512356, 13.451973093024201],
              [37.6785589846899, 13.4531962282332],
              [37.677714568322799, 13.4556315592014],
              [37.680200655871602, 13.4572805809066],
              [37.683108951603899, 13.458108363679999],
              [37.683508701905403, 13.4613670480006],
              [37.683906206418598, 13.4646213198381],
              [37.6868122563627, 13.465857494723799],
              [37.688458419120899, 13.4691291776476],
              [37.690120302396501, 13.469546324924501],
              [37.694277256373802, 13.470380617296099],
              [37.693545129417203, 13.4738946597666],
              [37.692920800294701, 13.475187569794601],
              [37.692422235312101, 13.476495760494799],
              [37.691795660401397, 13.4780179700453],
              [37.691335273818297, 13.479175454321],
              [37.690827725682702, 13.4805404049914],
              [37.690488611663, 13.481735003421701],
              [37.690257295477302, 13.4835934984318],
              [37.690232591807003, 13.483986597757101],
              [37.690340389641101, 13.4852008338112],
              [37.690661537355197, 13.486056910197901],
              [37.6908456919884, 13.486415063694499],
              [37.691721549390401, 13.4871007951105],
              [37.692482871593697, 13.4874720501514],
              [37.6939246676247, 13.4881708816042],
              [37.695811129721399, 13.4884875389332],
              [37.697345003069003, 13.491555817517201],
              [37.697151865282898, 13.491865918924599],
              [37.696826225992503, 13.493739621677401],
              [37.697003643261098, 13.4941436232578],
              [37.697039575872402, 13.4943488994746],
              [37.696936269614802, 13.4953927481441],
              [37.699891726899502, 13.4964824514049],
              [37.7004352076464, 13.497379978811701],
              [37.7005519886333, 13.4975524958585],
              [37.700646311738197, 13.4977468503567],
              [37.700828220583197, 13.499948067785001],
              [37.700666523832098, 13.5007538655173],
              [37.700572200727201, 13.501443923201199],
              [37.700457665528504, 13.501963648873501],
              [37.700507072869101, 13.5028742579315],
              [37.700345376118001, 13.504060009841099],
              [37.700527284963002, 13.5065472308882],
              [37.701322293989499, 13.509999599208401],
              [37.7016277211861, 13.510816281178901],
              [37.702301457649199, 13.5117465091068],
              [37.704592161623701, 13.511661347545999],
              [37.7056611568118, 13.5119910754713],
              [37.7063932837683, 13.5131986182188],
              [37.706487606873203, 13.5141070007755],
              [37.706770576187701, 13.5190943087128],
              [37.708715428777801, 13.5215529869135],
              [37.7090455596447, 13.5224853564703],
              [37.709036576491798, 13.5241208155675],
              [37.708933270234198, 13.5251099462614],
              [37.708683987742802, 13.528682136813799],
              [37.709288104771403, 13.5307302267883],
              [37.710112309044597, 13.5316603769722],
              [37.710795028660499, 13.532027195763799],
              [37.711816862296203, 13.5323350610992],
              [37.714273754598302, 13.532535937557199],
              [37.715167578306001, 13.532463884064599],
              [37.7165015765029, 13.536031597472901],
              [37.715917671568199, 13.537033779072299],
              [37.714139007305597, 13.540276102389001],
              [37.713943623731303, 13.5419005225079],
              [37.714226593045801, 13.5425555274822],
              [37.715297834022202, 13.543278214211201],
              [37.718428462787301, 13.5450532632315],
              [37.719697333126099, 13.5456951596157],
              [37.720440689023697, 13.5465684852474],
              [37.721911680301503, 13.547555339351399],
              [37.723256907439499, 13.550568275654699],
              [37.723097456476502, 13.552031064293301],
              [37.723229957980898, 13.5530899429898],
              [37.723593775670999, 13.553653220869201],
              [37.724406751003102, 13.5541815655737],
              [37.725031080125603, 13.555135639605799],
              [37.725444305156302, 13.5560198466735],
              [37.725707062376898, 13.557805716593901],
              [37.725758715505698, 13.559888486806299],
              [37.725844055457699, 13.560464847664599],
              [37.726034947455602, 13.561569535400899],
              [37.7263313914994, 13.562709148590899],
              [37.726874872246199, 13.5643312322638],
              [37.727276868335899, 13.565390056141201],
              [37.728431203475999, 13.566538383504399],
              [37.729477740782002, 13.5663964802336],
              [37.733183291328999, 13.568931078190699],
              [37.734148980259398, 13.569871994403799],
              [37.734139997106602, 13.570321711740499],
              [37.734187158658997, 13.5710355816855],
              [37.7348249625107, 13.571668675559099],
              [37.735487470032702, 13.5735548486753],
              [37.736004001321099, 13.573967447046099],
              [37.736839434535298, 13.5740001929179],
              [37.738835940254297, 13.573589777665701],
              [37.739529888811298, 13.573484990679001],
              [37.744434690262601, 13.5709941030625],
              [37.747794389425202, 13.5708805825833],
              [37.746152718243501, 13.576333870406099],
              [37.746851158376899, 13.579165251843101],
              [37.746808488400902, 13.580396466535801],
              [37.746808488400902, 13.5807086352029],
              [37.750377045866998, 13.583358777826],
              [37.749862760366902, 13.585144442068801],
              [37.749853777214, 13.586146416481199],
              [37.750055898153001, 13.5868471411684],
              [37.750749846709901, 13.5873863266672],
              [37.751257394845503, 13.5877901692538],
              [37.752533002548901, 13.590411854935899],
              [37.752701436664701, 13.591976994012599],
              [37.754931504357501, 13.5912217120108],
              [37.755414348822697, 13.5910012390651],
              [37.7584641292123, 13.589080277988399],
              [37.758839175843399, 13.588848888444399],
              [37.758980660500697, 13.5889165589948],
              [37.759335495037902, 13.590230673594199],
              [37.7592883334855, 13.589447008123599],
              [37.7595600738589, 13.590575572896601],
              [37.760525762789399, 13.591400709697099],
              [37.760833435774202, 13.5916189993755],
              [37.761186024523198, 13.5922498554156],
              [37.761340983909697, 13.592815223242599],
              [37.760943479396502, 13.593312920444999],
              [37.759533124400399, 13.593350029405],
              [37.758699936974402, 13.5945047700225],
              [37.757577042869201, 13.5961419162615],
              [37.757190767297097, 13.596744383229799],
              [37.757040299487002, 13.597595692637899],
              [37.760339362367901, 13.596550109628399],
              [37.763007358761698, 13.596253241795001],
              [37.762962442997498, 13.5975323902488],
              [37.763660883130903, 13.5992109892585],
              [37.765423826876003, 13.5997828889349],
              [37.763045537161297, 13.607239286558],
              [37.763155580783597, 13.6083110152733],
              [37.763849529340597, 13.609184110436001],
              [37.764184151783901, 13.6113493725399],
              [37.763465499556602, 13.616941419488599],
              [37.764595131026397, 13.619750489207499],
              [37.7647994977536, 13.6207086646421],
              [37.767395628924703, 13.6254580144829],
              [37.768114281152002, 13.626331046351],
              [37.769423575678601, 13.6287231371463],
              [37.770921516414802, 13.6301701657185],
              [37.774640541691099, 13.631163219415001],
              [37.774741602160603, 13.6116440387341],
              [37.784620824497701, 13.5973031918017],
              [37.784126751091399, 13.5877094007915],
              [37.773843286876399, 13.5743363836062],
              [37.769001367495001, 13.562589074661901],
              [37.777135612392698, 13.5506446902093],
              [37.784917268541399, 13.5392171666356],
              [37.792636042620202, 13.5206031453228],
              [37.808983135002997, 13.5190833909022],
              [37.809951069721599, 13.5060078620642],
              [37.810025180732602, 13.5049946315025],
              [37.810162173813403, 13.5031515890166],
              [37.810384506846198, 13.5001271341829],
              [37.810642772490397, 13.496668071705001],
              [37.8108404018529, 13.493979838915701],
              [37.811900413888203, 13.479662469614601],
              [37.816881572138598, 13.463164547244499],
              [37.830165409402497, 13.4663729297154],
              [37.8326065811871, 13.466962620928699],
              [37.834758046292599, 13.4674824216078],
              [37.836979130832603, 13.468019693391801],
              [37.838602835708599, 13.468410634297401],
              [37.841250620008601, 13.469050552948399],
              [37.844677692817498, 13.469878295012601],
              [37.847736456359897, 13.470616490004399],
              [37.8497105041968, 13.4710926027256],
              [37.854006697042998, 13.4721300010146],
              [37.866376498505403, 13.475115498980401],
              [37.875123843584497, 13.4676942728212],
              [37.887170251544497, 13.4533600405101],
              [37.904078790979902, 13.447038989549799],
              [37.9162464715033, 13.4525977664292],
              [37.918507980230999, 13.4536330607227],
              [37.920558384867, 13.454570063725299],
              [37.922390948046598, 13.455406592446099],
              [37.924376224824499, 13.4563130106073],
              [37.926195313274903, 13.4571451649827],
              [37.928277158945797, 13.458095258961899],
              [37.929775099682097, 13.4587810715832],
              [37.931387575617102, 13.4595171167014],
              [37.933489633381903, 13.460478121757999],
              [37.946463551872803, 13.466403506332499],
              [37.958644707125501, 13.487832385374601],
              [37.960580576562798, 13.488417655972601],
              [37.961586689680999, 13.4890203958358],
              [37.9626511932927, 13.489520494060599],
              [37.963125054605101, 13.489856804068401],
              [37.963502347024402, 13.4901210473137],
              [37.964517443295399, 13.4907761945947],
              [37.965249570251999, 13.4912588186088],
              [37.966278141252303, 13.4920580942425],
              [37.966610517907398, 13.4923856654507],
              [37.967037217667396, 13.492839896782501],
              [37.967367348534303, 13.4931849373129],
              [37.969907335000201, 13.494748532454199],
              [37.974713321770203, 13.495539061295901],
              [37.975431973997502, 13.4956679041463],
              [37.978596289585802, 13.4963230362084],
              [37.980911697230603, 13.4969366749425],
              [37.983179943323002, 13.4977053589603],
              [37.985302213181697, 13.497976144786],
              [37.987604146097297, 13.4986924154893],
              [37.989609634969099, 13.498808154151201],
              [37.9930075125313, 13.4995353410636],
              [37.994530156937898, 13.4997580825575],
              [37.996180811272403, 13.4998738207026],
              [38.000357977343597, 13.5008128262202],
              [38.001954732761099, 13.501360941686499],
              [38.007724162673398, 13.5017824001331],
              [38.007937512553397, 13.501275776417801],
              [38.008040818810997, 13.500771335356699],
              [38.008617986381097, 13.4994436239175],
              [38.009040194564598, 13.4974935343502],
              [38.0093343928202, 13.4961286805509],
              [38.010657162076001, 13.495030240546001],
              [38.012033830248903, 13.4946284242519],
              [38.013650797760299, 13.489040050371001],
              [38.013947241804097, 13.487747215280001],
              [38.0143784331405, 13.487170678456399],
              [38.014762462924502, 13.4864762117988],
              [38.015099331156001, 13.485753352850599],
              [38.0157528555252, 13.4851222143974],
              [38.016675874479603, 13.4842224570419],
              [38.020707064317101, 13.481245808442701],
              [38.023595147955596, 13.484694174912899],
              [38.024464267992897, 13.4858625461854],
              [38.025129021303201, 13.486362651021199],
              [38.0296924629465, 13.482473161807],
              [38.029957465955299, 13.4830191354772],
              [38.036126646169002, 13.484089240252199],
              [38.036277113979096, 13.4835301657023],
              [38.036914917830799, 13.482276610980399],
              [38.0373371260144, 13.482042933676199],
              [38.037855903091, 13.482034198071601],
              [38.043456898887399, 13.481278567066299],
              [38.0451367484687, 13.480525117587],
              [38.046248413632803, 13.479743274746699],
              [38.047479105572101, 13.478852232860801],
              [38.047404994561099, 13.475471484911999],
              [38.047708175969497, 13.475065267794101],
              [38.048995012614, 13.4738684520586],
              [38.049785530064099, 13.469694837915601],
              [38.050636683795801, 13.468539491774299],
              [38.0520290724861, 13.4674518451286],
              [38.053214848661199, 13.466759505230399],
              [38.054494947941102, 13.466311776469301],
              [38.055202371227303, 13.466388218024401],
              [38.058054522254402, 13.468072110086601],
              [38.059298688922901, 13.467488973709999],
              [38.060899935916801, 13.465984169527999],
              [38.064677351686498, 13.4696227654462],
              [38.067105048741901, 13.4727611737719],
              [38.069047655543798, 13.4754430934154],
              [38.070608478350003, 13.476345067773901],
              [38.0723489642129, 13.477354052007],
              [38.075048401641702, 13.4788849918125],
              [38.0775816507429, 13.480339484741901],
              [38.079712903754498, 13.4816127047708],
              [38.081904793047798, 13.4831589045362],
              [38.083820450391102, 13.4841700438876],
              [38.086706288241402, 13.485462898337],
              [38.088965551180898, 13.4864740279382],
              [38.091613335480901, 13.4874349246733],
              [38.093715393245702, 13.487993990086],
              [38.097007718762001, 13.4888020120031],
              [38.1001585596211, 13.489516126395101],
              [38.102507654089003, 13.4899681793562],
              [38.104443523526299, 13.4903634520171],
              [38.106260366188401, 13.4905796505991],
              [38.1088340394775, 13.491193304093899],
              [38.111279702838502, 13.4919969475672],
              [38.1134176932147, 13.4924839367256],
              [38.114886438704197, 13.4936653726639],
              [38.116040773844297, 13.4949538081509],
              [38.116849257600002, 13.4959059356702],
              [38.118026050622198, 13.500022314844299],
              [38.118138340032701, 13.501976751186801],
              [38.118470716687803, 13.503649473935299],
              [38.118991739552598, 13.5054881452016],
              [38.120604215487603, 13.5066607820642],
              [38.121149942022697, 13.506407475520501],
              [38.121879823191101, 13.5068529454696],
              [38.122212199846203, 13.506058086743099],
              [38.123276703457897, 13.500194829981201],
              [38.1237460731938, 13.498875850323699],
              [38.124570277467001, 13.497325384783601],
              [38.125569653220602, 13.4954298723852],
              [38.127977138181997, 13.492789669322001],
              [38.128682315680102, 13.4918047722001],
              [38.128450999494397, 13.488360876052001],
              [38.1285700262696, 13.4870396471661],
              [38.1291606685689, 13.485144053126101],
              [38.1310403933009, 13.4826085633935],
              [38.134678570201601, 13.479221316790101],
              [38.1358508716473, 13.478131534788201],
              [38.138024794634902, 13.476240237999001],
              [38.1393722675611, 13.4756134423442],
              [38.140663595782001, 13.4749757252183],
              [38.144838516065001, 13.471535954544899],
              [38.145501023587002, 13.471800218046299],
              [38.149303143026998, 13.4745607713804],
              [38.150006074736901, 13.4749080222728],
              [38.154165274502297, 13.4758886211267],
              [38.1546930347317, 13.4761790872757],
              [38.155395966441603, 13.476698867924799],
              [38.156446995324004, 13.477334396510599],
              [38.157554168911702, 13.4819315546937],
              [38.158140319634597, 13.482564885470801],
              [38.158782615062698, 13.4832549957169],
              [38.159485546772501, 13.483600050092599],
              [38.163642500749802, 13.486425983],
              [38.164520603939998, 13.487515727177099],
              [38.165338070848598, 13.4884394944],
              [38.165924221571501, 13.489760715543101],
              [38.171601574167099, 13.493388030556501],
              [38.172949047093297, 13.4936195129676],
              [38.174590718274999, 13.493560550488001],
              [38.175646238733798, 13.493165283118801],
              [38.180748669547597, 13.489719222758801],
              [38.182390340729398, 13.488404552915201],
              [38.183212299214297, 13.4874851532599],
              [38.184034257699302, 13.4865657500661],
              [38.186145298616999, 13.485296924170701],
              [38.190713231836703, 13.4834056840815],
              [38.200875423488299, 13.4801276447308],
              [38.2091174662201, 13.485008652976299],
              [38.209903492093702, 13.485906223505401],
              [38.211190328738198, 13.4870418310215],
              [38.212452461712402, 13.4879219231336],
              [38.213896503531601, 13.488812931199501],
              [38.214785835662902, 13.4890684402523],
              [38.219645721349998, 13.489247514810099],
              [38.220676538138498, 13.4892409633042],
              [38.225583585377997, 13.4894265892337],
              [38.228487389534003, 13.489797840659699],
              [38.235534672937902, 13.493824789634999],
              [38.2431052249948, 13.4967226658834],
              [38.245937163927998, 13.501542190746999],
              [38.250532046606203, 13.5025510685438],
              [38.249332795702003, 13.5009744221459],
              [38.249761741250097, 13.496154885817299],
              [38.253054066766403, 13.4918615513019],
              [38.253925432591998, 13.4904529888273],
              [38.259117694934197, 13.486192309753701],
              [38.267263168772999, 13.4880332993236],
              [38.268037965705503, 13.487548484941099],
              [38.278894105914098, 13.485508759599499],
              [38.289909697085598, 13.489852436409],
              [38.290855173922203, 13.4902892019539],
              [38.293300837283198, 13.4912719215096],
              [38.294473138729003, 13.4917086844585],
              [38.296943505760296, 13.492477385308501],
              [38.298445938073002, 13.4927503608672],
              [38.311718546395802, 13.5015159860721],
              [38.313490473293797, 13.4996816516761],
              [38.315927153501903, 13.498476224253499],
              [38.317025343936798, 13.5002254022709],
              [38.319832579199598, 13.502140530041601],
              [38.322103071080299, 13.5084863281647],
              [38.3229205379888, 13.5095999917706],
              [38.3240232200001, 13.5105782645298],
              [38.320135760607997, 13.520487417238099],
              [38.318565954649003, 13.5228696576144],
              [38.320414238346103, 13.5256601893728],
              [38.320957719093002, 13.5263392574919],
              [38.3253976423848, 13.533324157673601],
              [38.326015234142602, 13.534437705190401],
              [38.326610368018301, 13.535415875966001],
              [38.3257794263805, 13.541605769681],
              [38.3249754342012, 13.5427782287627],
              [38.324748609592, 13.5438633486852],
              [38.324625091240399, 13.544948463657301],
              [38.324155721504503, 13.550380514073099],
              [38.327378427586297, 13.552271222761799],
              [38.328997640885902, 13.5505486261938],
              [38.3298645151351, 13.549376205421201],
              [38.330994146604802, 13.547985449261301],
              [38.332588656234101, 13.5458916581624],
              [38.334542491977103, 13.544649347951999],
              [38.336226833134802, 13.543433231320201],
              [38.337527144508599, 13.5421887249188],
              [38.338775802753503, 13.540837227259299],
              [38.341174304562102, 13.5376320292572],
              [38.348774051865803, 13.5409987960427],
              [38.349027825933497, 13.542308809153599],
              [38.350438180929601, 13.543896098706499],
              [38.358594883709401, 13.552878167627901],
              [38.361260634315002, 13.5531095922407],
              [38.363937613861701, 13.552275589277199],
              [38.367007606345197, 13.552031064293301],
              [38.369873232101497, 13.550924148943],
              [38.3706345543048, 13.549915476029099],
              [38.3710298130298, 13.5487670683241],
              [38.377839042883501, 13.543939765394599],
              [38.368260756166499, 13.534839454388299],
              [38.368031685769097, 13.5326691272892],
              [38.368963687876402, 13.531315392116699],
              [38.371285832885803, 13.5316822114399],
              [38.374623074166301, 13.536359108263699],
              [38.376523010992202, 13.5390206625753],
              [38.378180402691399, 13.541343766861599],
              [38.381623196017799, 13.546166755855101],
              [38.384924504687, 13.550790969431199],
              [38.386173162931897, 13.5523585530538],
              [38.388326873825598, 13.553059377485299],
              [38.390145962275902, 13.555423825974501],
              [38.394208593148299, 13.5583275024598],
              [38.394880083823203, 13.5579410753805],
              [38.395371911441302, 13.5568560197131],
              [38.395151824196702, 13.5556159500261],
              [38.393186759512602, 13.5524480665698],
              [38.3970495152344, 13.547077195949599],
              [38.399665858499397, 13.5473915917193],
              [38.400411460185197, 13.5447694309446],
              [38.3999600567549, 13.542662511456699],
              [38.399360431302803, 13.539666941985001],
              [38.404593117832697, 13.536950809949801],
              [38.405915887088597, 13.5385730693877],
              [38.407270097379403, 13.540535923018799],
              [38.408159429510697, 13.541542452359399],
              [38.413176520372502, 13.547341375756201],
              [38.414979888305403, 13.549605450161099],
              [38.416718128380197, 13.551417567467601],
              [38.418013948177503, 13.553753650118299],
              [38.419085189153797, 13.5558364558484],
              [38.420226049564597, 13.5575306323707],
              [38.421613946678598, 13.559349238828],
              [38.4225302282684, 13.560338223050501],
              [38.423711512867001, 13.5614712926285],
              [38.425243140426403, 13.5626327379158],
              [38.427506894942397, 13.5641107343264],
              [38.428486058602097, 13.569358966304501],
              [38.427183501440098, 13.5701950923824],
              [38.425970775806597, 13.5710050184903],
              [38.425178012568303, 13.571963391096601],
              [38.425238648849998, 13.574104979676999],
              [38.429209202405801, 13.5779318477381],
              [38.430664473166097, 13.5788378000512],
              [38.432052370280097, 13.579501435212601],
              [38.434715875097503, 13.580610400352001],
              [38.437871207533, 13.582092650778],
              [38.440310133529302, 13.583216884613799],
              [38.441563283350703, 13.585155356845499],
              [38.442463844423003, 13.5862511978745],
              [38.443393600742098, 13.586995581023199],
              [38.451015805927803, 13.5845877877878],
              [38.447685302011998, 13.579590938496899],
              [38.445527099541899, 13.5744324380011],
              [38.443247624508402, 13.568928895086099],
              [38.441412815540602, 13.5634274077347],
              [38.441428536058098, 13.561565169056299],
              [38.4416508690909, 13.560678799445499],
              [38.442149434073599, 13.559803342470101],
              [38.4433105065783, 13.559154934600601],
              [38.444044879323101, 13.559237895975601],
              [38.445394598037502, 13.5598382734835],
              [38.452311625725201, 13.5573406930783],
              [38.453319984631598, 13.5567621414245],
              [38.455166022540503, 13.5567031945731],
              [38.457360157621899, 13.558246723969701],
              [38.458361779163702, 13.559395085869699],
              [38.459763151006896, 13.5617856693571],
              [38.463648364610798, 13.5672282505047],
              [38.466734077611697, 13.5680403698476],
              [38.472826901026302, 13.5641020017306],
              [38.473125590858203, 13.5590021109393],
              [38.473491654336499, 13.5571594864854],
              [38.473736445251397, 13.5555679190277],
              [38.475739688334997, 13.552985146957999],
              [38.479014047545597, 13.551917534751],
              [38.479894396524102, 13.5514481331833],
              [38.4871999455722, 13.555251350841299],
              [38.489001067716799, 13.5574258382971],
              [38.491559020488403, 13.559663618365001],
              [38.501427013884403, 13.557849380726999],
              [38.506805676648099, 13.556395360778501],
              [38.512617776536302, 13.5557993410213],
              [38.514254956141599, 13.5555504532078],
              [38.5164086670353, 13.5552731831436],
              [38.518519707952997, 13.5550788755829],
              [38.524387952546498, 13.5545570825835],
              [38.531073664048598, 13.5531576237358],
              [38.533710219407503, 13.5534065140553],
              [38.539100111112198, 13.5538344301357],
              [38.548015890307099, 13.552972047450799],
              [38.550439095785997, 13.552144593781399],
              [38.553922313300099, 13.5502975496061],
              [38.551047704391003, 13.538754290145601],
              [38.550288627975903, 13.5370184952887],
              [38.548359495903199, 13.534083990661401],
              [38.5463248117847, 13.5315664887382],
              [38.542435106604501, 13.52579119945],
              [38.536616269351597, 13.5202908977201],
              [38.534747773560603, 13.5192580758114],
              [38.533133051837403, 13.5185768039415],
              [38.530359503397698, 13.517974138740801],
              [38.5230674290789, 13.517535240298701],
              [38.521230374322798, 13.517305964671699],
              [38.518845347243499, 13.5167775381009],
              [38.517443975400298, 13.5161508488724],
              [38.515609166432398, 13.5146114136333],
              [38.510816654391697, 13.5116984692558],
              [38.508121708539299, 13.510951666689801],
              [38.506852838200501, 13.510912361226801],
              [38.504847349328699, 13.511017175780401],
              [38.503248348123002, 13.511412413578499],
              [38.500611792764097, 13.5121766837055],
              [38.496780478077298, 13.506778700535801],
              [38.494790709722999, 13.5031668749725],
              [38.4934432367968, 13.500858684534499],
              [38.492720092993103, 13.4996095882513],
              [38.491363636914102, 13.4969672476496],
              [38.489908366153799, 13.494567278234801],
              [38.498233503049399, 13.484445212819301],
              [38.479975244899599, 13.486030703823401],
              [38.478573873056398, 13.485393014491599],
              [38.477410554763502, 13.4848317591166],
              [38.4762292701649, 13.4845740616545],
              [38.475344429609997, 13.4844015352322],
              [38.4720274004234, 13.484499809791799],
              [38.467872692234302, 13.4846199230876],
              [38.466886791210001, 13.4851615241075],
              [38.465150796923503, 13.480898566756199],
              [38.465155288499901, 13.4800752306784],
              [38.465593217200897, 13.4792628113973],
              [38.465952543314501, 13.478594528951101],
              [38.468070321596798, 13.476410586359799],
              [38.469357158241301, 13.4752487207715],
              [38.4695637707567, 13.4745978988584],
              [38.467865954869701, 13.4709222505764],
              [38.4672034473477, 13.4697821984555],
              [38.466489286696799, 13.468423738450801],
              [38.465597708777302, 13.4669648049675],
              [38.464973379654801, 13.465879335212099],
              [38.464544434106699, 13.465156413991201],
              [38.4640323943947, 13.4648746706883],
              [38.461779868819796, 13.4639377080161],
              [38.460982614005097, 13.4635118146797],
              [38.460969139275903, 13.4595498783009],
              [38.467751419671004, 13.4619152539394],
              [38.469379616123398, 13.4627168117573],
              [38.471045990975497, 13.4635773367808],
              [38.472822409449797, 13.4647567315328],
              [38.475618415771699, 13.4667704254336],
              [38.481762892314997, 13.4697494382568],
              [38.486503751226998, 13.469703573971],
              [38.500742048480298, 13.4719290736973],
              [38.502035622489402, 13.471579634482699],
              [38.5032730517933, 13.4712935307455],
              [38.505323456429302, 13.471603658445],
              [38.507789331884197, 13.472088505169401],
              [38.511719461252198, 13.472900948828499],
              [38.519081155005601, 13.4743532941915],
              [38.520758758798699, 13.4752421688819],
              [38.522869799716297, 13.4765743827462],
              [38.524978594845798, 13.4783237211534],
              [38.530974849367297, 13.4810208657725],
              [38.532919701957397, 13.481296038330401],
              [38.536492751, 13.4824425872446],
              [38.545087382480801, 13.4833794774162],
              [38.551779831347503, 13.482840056250801],
              [38.560747263671203, 13.482820401205601],
              [38.563356869571599, 13.4838184386804],
              [38.566083256458903, 13.485296924170701],
              [38.567538527219199, 13.4862403547389],
              [38.568726549182401, 13.4877887084068],
              [38.570559112361998, 13.4916169643055],
              [38.571774083783801, 13.496148334500999],
              [38.574141144557501, 13.499244903313301],
              [38.578197038065298, 13.502216960135399],
              [38.5808897381294, 13.504691098233501],
              [38.590088486638798, 13.506112678773301],
              [38.605366583833501, 13.490680106439299],
              [38.614021851596, 13.4906757387949],
              [38.622443557384599, 13.4953687265733],
              [38.6306204722583, 13.501804237338099],
              [38.6350738702793, 13.504667077598601],
              [38.636852534541902, 13.507842147822601],
              [38.639441928348297, 13.5107005483425],
              [38.642314291469297, 13.513050132279499],
              [38.644784658500598, 13.515069969850501],
              [38.646969810429198, 13.5174522643829],
              [38.651423208450304, 13.5212669430628],
              [38.6527167824594, 13.522616368293599],
              [38.654416844134602, 13.5241251825995],
              [38.662126635060602, 13.531171284370799],
              [38.6648081061836, 13.5267846901905],
              [38.666045535487498, 13.5217233029363],
              [38.669259258416503, 13.514495682639],
              [38.672468489769003, 13.5091960163578],
              [38.6737171480139, 13.4973843463334],
              [38.673232057760501, 13.491839713187399],
              [38.674496436522901, 13.48400188494],
              [38.661282218693501, 13.4847160136807],
              [38.648157832392499, 13.481365923374],
              [38.633257027617198, 13.4727000221589],
              [38.615764583247099, 13.451389917431101],
              [38.617828462612401, 13.4283194884233],
              [38.622641186747103, 13.411092762293499],
              [38.623748360334801, 13.410330353903801],
              [38.6254327014925, 13.408733439093799],
              [38.627249544154601, 13.4072151586756],
              [38.630591277011497, 13.405159457244],
              [38.632358712333001, 13.40404093856],
              [38.633982417209097, 13.4032282615648],
              [38.640623212946899, 13.400318331089199],
              [38.642532132925702, 13.399271883519001],
              [38.645647041173397, 13.397611539827],
              [38.647434688588802, 13.396737670122601],
              [38.649543483718197, 13.3961062972859],
              [38.651843170845602, 13.3955863419409],
              [38.656563817663603, 13.394723388362999],
              [38.658358202443701, 13.394255862853401],
              [38.665645785186101, 13.3918504952882],
              [38.667956701254496, 13.391800246663101],
              [38.670447280379697, 13.391975024444401],
              [38.672392132969797, 13.3917696605383],
              [38.684115147427597, 13.3902381646056],
              [38.692756940460796, 13.389036556123999],
              [38.694677089380598, 13.3888683304579],
              [38.696956564414101, 13.388916394945999],
              [38.699483076150699, 13.3890540341086],
              [38.705663485305401, 13.3892878020298],
              [38.718707023230799, 13.402839399020101],
              [38.720910141465097, 13.408202588181901],
              [38.720526111681203, 13.408571780915601],
              [38.722354183284303, 13.4074008481624],
              [38.7234029663786, 13.406994515687201],
              [38.724624675165003, 13.4064614763449],
              [38.726003589126101, 13.405495885673901],
              [38.726207955853198, 13.4050720731592],
              [38.726405585215701, 13.4044013992511],
              [38.726928853868699, 13.4029114915619],
              [38.7275846240261, 13.402242996255101],
              [38.7305153776406, 13.4007639984004],
              [38.732244634562498, 13.400204729485599],
              [38.732720741663101, 13.4000321423313],
              [38.7331990945519, 13.399807123450501],
              [38.733625794311799, 13.399383300908999],
              [38.7340165614604, 13.398432974454501],
              [38.734063723012802, 13.3978649614474],
              [38.733845881556398, 13.3968425346548],
              [38.733756050027999, 13.395874720674399],
              [38.733830161039002, 13.3948151455994],
              [38.733816686309702, 13.394111672559699],
              [38.733722363204897, 13.393469369292401],
              [38.733459605984301, 13.3926020386904],
              [38.733127229329099, 13.3918504952882],
              [38.732781377944796, 13.3910530702166],
              [38.732267092444602, 13.389941040157501],
              [38.7321031499052, 13.3893162037244],
              [38.731990860494697, 13.3884029783276],
              [38.732666842745999, 13.385672020465501],
              [38.733288926080299, 13.3838564625367],
              [38.733740329510603, 13.3828798571222],
              [38.735018183002197, 13.3810467995777],
              [38.736075949249297, 13.379816742414301],
              [38.736956298227703, 13.3786063425554],
              [38.737767027771604, 13.377459297365499],
              [38.737993852380903, 13.3770332506183],
              [38.7381847443787, 13.376572245340601],
              [38.738218431201901, 13.3758053578297],
              [38.737546940526997, 13.3751630057286],
              [38.734335463386302, 13.3742453568993],
              [38.733946942025902, 13.3736401437354],
              [38.733832406827197, 13.3730327441582],
              [38.733866093650299, 13.372482150412999],
              [38.734149062964804, 13.3712979326198],
              [38.734429786491098, 13.3706031305422],
              [38.734584745877598, 13.3701224486875],
              [38.734618432700799, 13.3694538623322],
              [38.734432032279301, 13.3688835960345],
              [38.734059231436397, 13.368512157490599],
              [38.732428789195701, 13.3679222245094],
              [38.731481066571, 13.367461201804501],
              [38.730517623428803, 13.3667816833195],
              [38.730072957363099, 13.3663381380664],
              [38.729230786784299, 13.364105104744199],
              [38.729201591537503, 13.3632092636471],
              [38.729327355677299, 13.361780280961501],
              [38.729567655015799, 13.3608494711728],
              [38.729731597555201, 13.3602617044562],
              [38.729956176376199, 13.3596477161338],
              [38.730261603572799, 13.359086166727],
              [38.730650124933199, 13.358561761431501],
              [38.731088053634203, 13.358164086655901],
              [38.7318965373899, 13.357469246738599],
              [38.732372644490503, 13.357099975653901],
              [38.7330441351654, 13.356630192096199],
              [38.733677447440698, 13.35605115529],
              [38.733994103578297, 13.3553650495023],
              [38.7340614772246, 13.354641795748],
              [38.734027790401498, 13.3540802346918],
              [38.733875076803201, 13.3528871862437],
              [38.733623548523603, 13.352118038330399],
              [38.733232781375001, 13.351285520355599],
              [38.733138458270197, 13.3494238162285],
              [38.7330890509296, 13.3486371763676],
              [38.733102525658801, 13.348058120387901],
              [38.733286680292103, 13.3473719918825],
              [38.733616811159003, 13.346854116528499],
              [38.734436523855798, 13.346141762961301],
              [38.7352225497294, 13.3454010004282],
              [38.7373425737999, 13.3440571333962],
              [38.738303771153902, 13.3432988831697],
              [38.7403833710366, 13.341526709814101],
              [38.741081811169998, 13.340678859024299],
              [38.742034025371197, 13.3403991550183],
              [38.742667337646502, 13.338771183869399],
              [38.7427818728452, 13.3377310247485],
              [38.743257979945803, 13.335915105973299],
              [38.743421922485197, 13.3348028216705],
              [38.743125478441399, 13.3335375647381],
              [38.742011567489101, 13.332676574369099],
              [38.741014437523702, 13.332739946861199],
              [38.740075698051797, 13.3327923930491],
              [38.738961787099498, 13.332285412757001],
              [38.7377894856537, 13.331704132320899],
              [38.736792355688401, 13.331079145029801],
              [38.736379130657703, 13.3299318739447],
              [38.736668837336801, 13.3284939531652],
              [38.737016934509398, 13.326883384329699],
              [38.737717620430999, 13.3258453593832],
              [38.738301525365699, 13.3246346888708],
              [38.738532841551297, 13.3230787279794],
              [38.738413814776202, 13.322274519551099],
              [38.738236397507599, 13.3209545629673],
              [38.737998343957301, 13.3195165888256],
              [38.737643509420103, 13.3181419824563],
              [38.737405455869798, 13.3165859798169],
              [38.737052867120802, 13.315436454588999],
              [38.737515499492098, 13.3128510955884],
              [38.738743945643101, 13.311813010453699],
              [38.740030782287597, 13.310827371817901],
              [38.7408504949844, 13.3096253680524],
              [38.741256982650398, 13.308532632179899],
              [38.741782497091599, 13.307664996386],
              [38.741605079823003, 13.3062269433144],
              [38.741191854792298, 13.3047342439391],
              [38.740369896307399, 13.3043911184783],
              [38.738258855389702, 13.3041091870034],
              [38.737088799732099, 13.304568145050601],
              [38.736208450753701, 13.304856632521],
              [38.735038395096097, 13.304863189050399],
              [38.734097409835996, 13.303768246177],
              [38.733039643589002, 13.3023935504142],
              [38.7327454453334, 13.300900827424],
              [38.7333293502681, 13.2999785254615],
              [38.735069836131103, 13.2992660338648],
              [38.740643882469001, 13.2950762786461],
              [38.7400465028051, 13.2867752352716],
              [38.736073703461102, 13.2826114928074],
              [38.734892418862401, 13.2812541628956],
              [38.734780129451899, 13.279044387333901],
              [38.734609449547897, 13.2773701028426],
              [38.7335561748773, 13.2766160153255],
              [38.731442888171401, 13.2751668492617],
              [38.729601341839, 13.2711143842329],
              [38.729365534076898, 13.270202898157301],
              [38.729545197133703, 13.2690881263657],
              [38.730187492561903, 13.2680957565853],
              [38.730764660131896, 13.267542738186901],
              [38.730376138771497, 13.2630158090422],
              [38.730389613500797, 13.2621611258499],
              [38.732026793106101, 13.261658369627201],
              [38.733470834925299, 13.262222330884301],
              [38.738079192332897, 13.264067218258701],
              [38.743228784699099, 13.2650115153832],
              [38.746235895112697, 13.262622349122401],
              [38.747621546438403, 13.2623010230487],
              [38.7502064486685, 13.2610572466918],
              [38.7507903536032, 13.260709687662001],
              [38.751535955289, 13.260519513642899],
              [38.759479308188801, 13.2601763256688],
              [38.761381490802897, 13.259557711727499],
              [38.759591597599297, 13.2561826312719],
              [38.759849863243502, 13.255907201654599],
              [38.760799831656399, 13.2558700404918],
              [38.767671943579998, 13.2540141610839],
              [38.769753789250899, 13.2531813060045],
              [38.772518354537802, 13.2514565655532],
              [38.775734323254902, 13.2498979492799],
              [38.776926836794601, 13.248859594889201],
              [38.778263080779702, 13.2502564537669],
              [38.781353285357099, 13.2490497780248],
              [38.788290525138699, 13.248839920763199],
              [38.7901006304362, 13.250322033798801],
              [38.7906328822421, 13.2510259250285],
              [38.798596447235802, 13.247961141514599],
              [38.7996968834588, 13.248149139332099],
              [38.801729321789097, 13.247569843266],
              [38.802647849167201, 13.245514970628101],
              [38.805825639484702, 13.243578127984099],
              [38.808563255313103, 13.242659980489099],
              [38.810984215003799, 13.2394376925971],
              [38.811774732453799, 13.2386572541765],
              [38.812214906942998, 13.238191613255401],
              [38.817665434929403, 13.236092938900899],
              [38.820459195463002, 13.2333165398377],
              [38.822727441555401, 13.2333515182904],
              [38.823998557682501, 13.233390869043699],
              [38.825215774892399, 13.2341494628789],
              [38.825633491499602, 13.234617297669001],
              [38.8259434102726, 13.235019547424301],
              [38.832440475565001, 13.2329470796242],
              [38.833051329958202, 13.2326563206008],
              [38.833781211126499, 13.2321075939797],
              [38.834652576952102, 13.231244057551701],
              [38.835368983391199, 13.2306603489164],
              [38.837839350422499, 13.2292349571598],
              [38.838636605237198, 13.228915773200701],
              [38.850233855555203, 13.214497404506499],
              [38.862558741253302, 13.2091692791591],
              [38.863605278559298, 13.2098055119778],
              [38.867151378143397, 13.2153085286917],
              [38.875364225628402, 13.219989384038399],
              [38.875685373342499, 13.220114001566101],
              [38.876028978938699, 13.2204353833172],
              [38.876642079120103, 13.220778627304799],
              [38.877257425089702, 13.2211677823242],
              [38.8931733261361, 13.223402132271],
              [38.903238948894597, 13.225621158186801],
              [38.903865523805301, 13.226010305477701],
              [38.904406758763997, 13.226010305477701],
              [38.904837950100401, 13.225647392855199],
              [38.904932273205198, 13.225068043271801],
              [38.905091724168201, 13.224390311823401],
              [38.905558848115902, 13.224027396789401],
              [38.905965335782, 13.224195736962701],
              [38.906365086083397, 13.2242022956684],
              [38.906957974170901, 13.224027396789401],
              [38.907413869177603, 13.223686343614499],
              [38.907802390538002, 13.2234043185133],
              [38.908067393546801, 13.2232556539877],
              [38.908460406483599, 13.223100430636199],
              [38.908835453114698, 13.2227746799012],
              [38.909028590900803, 13.2226413187365],
              [38.9092329576279, 13.222470791239299],
              [38.909513681154202, 13.222403017457401],
              [38.910209875499397, 13.222499212497199],
              [38.911110436571697, 13.2226937887117],
              [38.911813368281599, 13.223472092016999],
              [38.912334391146402, 13.224154198489201],
              [38.913064272314699, 13.224781647308999],
              [38.913684109860803, 13.224744481396],
              [38.914166954325999, 13.2247357364744],
              [38.914807003965898, 13.2248822138686],
              [38.918532766606802, 13.2299061165868],
              [38.927965077090001, 13.2347768859666],
              [38.928623093035696, 13.234737535437199],
              [38.929564078295797, 13.235369329280401],
              [38.929869505492398, 13.235944282115],
              [38.930567945625803, 13.236329040668499],
              [38.930814982328897, 13.2369302246916],
              [38.951718778990397, 13.237502987726099],
              [38.953194261844502, 13.225743586615],
              [38.953214473938402, 13.225330390423499],
              [38.9531605750214, 13.224281000123],
              [38.953340238078198, 13.2232031841334],
              [38.953261635490797, 13.222444556229201],
              [38.954013974541297, 13.221596289380001],
              [38.955166063893202, 13.2206955491865],
              [38.955738739886797, 13.2203719815087],
              [38.9561497191293, 13.220033109494],
              [38.956423705291002, 13.219871325269301],
              [38.956787522981003, 13.219281030566499],
              [38.957061509142697, 13.2179211610372],
              [38.956803243498499, 13.2161458858708],
              [38.955801621956702, 13.2120027981339],
              [38.9647465963983, 13.207750320954499],
              [38.984790256175202, 13.202743453391101],
              [38.993198487234601, 13.203239771921201],
              [38.994233795599499, 13.203255076881799],
              [38.995477962267998, 13.2035611758937],
              [38.996436913833797, 13.203694547486],
              [38.997045522438803, 13.203635514167299],
              [38.997649639467397, 13.2037470215352],
              [38.998188628637898, 13.203749207953599],
              [38.998727617808299, 13.2036923610671],
              [38.999403600059601, 13.203248517613099],
              [38.999935851865501, 13.2028003004986],
              [39.000497298918098, 13.202708470549201],
              [39.011737468910603, 13.1863862420188],
              [39.016723118737502, 13.1786981276746],
              [39.034680441267, 13.170753926418101],
              [39.039594225871198, 13.163168099780901],
              [39.039973764078702, 13.1628160277491],
              [39.040539702707697, 13.1624617684238],
              [39.040836146751403, 13.1622124745174],
              [39.0413661527691, 13.161871335076601],
              [39.041756919917702, 13.1615301951609],
              [39.042289171723503, 13.1611781207754],
              [39.042585615767301, 13.1607779362323],
              [39.0428506187761, 13.1602727843184],
              [39.0429741371277, 13.159465851178799],
              [39.043023544468298, 13.1582565399],
              [39.043234648560102, 13.157014420114001],
              [39.043185241219398, 13.156045649785],
              [39.042861847717099, 13.1554311456278],
              [39.042513750544501, 13.155175284012101],
              [39.042001710832601, 13.154685428549801],
              [39.041759165705898, 13.1543267837513],
              [39.041797344105497, 13.1539331486125],
              [39.036910508959799, 13.134228715832601],
              [39.028677449380901, 13.1313614906121],
              [39.028279944867698, 13.1302198401312],
              [39.0282035880685, 13.129852411261499],
              [39.027817312496403, 13.129349382750901],
              [39.027085185539804, 13.129082558514099],
              [39.0267056473323, 13.128824482337301],
              [39.026339583854003, 13.128063374745301],
              [39.026218311290599, 13.127339445508699],
              [39.0260700892687, 13.126230582994999],
              [39.025892672000097, 13.125519771059199],
              [39.025093171397302, 13.125497899890201],
              [39.024677700578302, 13.1258500254759],
              [39.023902903645798, 13.1265061587616],
              [39.022878824221898, 13.1269238960405],
              [39.020983378972403, 13.127219154945401],
              [39.019420310378003, 13.1265105329776],
              [39.010219316080402, 13.1139693357871],
              [38.988206100043101, 13.1033195331554],
              [38.976256260976101, 13.0987020694695],
              [38.981338479695999, 13.098072110344599],
              [38.9898006096724, 13.081279168617799],
              [38.990710153897602, 13.074042779567399],
              [38.990716891262203, 13.0731502477114],
              [38.990865113284102, 13.0710260964124],
              [38.9908830795898, 13.070144491688501],
              [38.990919012201097, 13.069518834812699],
              [38.990919012201097, 13.0688931763508],
              [38.990842655401998, 13.0678715558028],
              [38.990775281755703, 13.0674274667515],
              [38.990788756484903, 13.0669396241634],
              [38.990768544391003, 13.0665086592813],
              [38.990764052814598, 13.0662395793033],
              [38.990492312441198, 13.064815419414201],
              [38.990406972489197, 13.064075990609799],
              [38.9903777772425, 13.063117792592999],
              [38.990359810936802, 13.0621355263831],
              [38.990433921947698, 13.061700177748699],
              [38.990503541382203, 13.0610723117837],
              [38.993939597344003, 13.0452788796328],
              [38.999758434596899, 13.0390500523631],
              [39.000095302828399, 13.0388137607803],
              [39.0002457706385, 13.038772190941501],
              [39.004310647299199, 13.023386495321001],
              [38.996221318165702, 13.015496360856799],
              [38.9955969890432, 13.0146670725681],
              [38.994797488440298, 13.0132776255497],
              [38.994435916538499, 13.0128006167461],
              [38.994132735130101, 13.012257963413299],
              [38.9938273079335, 13.0097613051568],
              [39.000196363297903, 13.0034331157271],
              [39.007133603079502, 13.004310581785701],
              [39.0078702216125, 13.003577536788001],
              [39.008521500193503, 13.003308388379599],
              [39.009118879857397, 13.0039101425479],
              [39.009406340748299, 13.004172725727599],
              [39.009853252602198, 13.004304017213199],
              [39.010053127752897, 13.004299640831499],
              [39.0104191912312, 13.004356533788],
              [39.011299540209599, 13.003800732807701],
              [39.0121956097055, 13.0028357368095],
              [39.0132129517648, 13.001984524072],
              [39.014317879564203, 13.001503117118],
              [39.0154497568222, 13.001196766752001],
              [39.034238020989598, 13.0004571478808],
              [39.0413459406752, 12.994802710659499],
              [39.042446376898198, 12.9941024617933],
              [39.043279564324301, 12.993522566706099],
              [39.044660724073601, 12.992765418629199],
              [39.045630904580399, 12.9925553424531],
              [39.046706637133198, 12.9924152915703],
              [39.048094534247099, 12.992515953150299],
              [39.0487503044045, 12.9925400243916],
              [39.0506502412305, 12.9918725936533],
              [39.0524333970694, 12.9912620570791],
              [39.053985236722802, 12.990833148767299],
              [39.0546702021269, 12.9904983371725],
              [39.055238386544097, 12.9901285120757],
              [39.055620170539797, 12.989815582717601],
              [39.057106882335098, 12.9890431050618],
              [39.057194468075302, 12.9881852800341],
              [39.074125465392697, 12.9760747504849],
              [39.081408556558699, 12.960619510134199],
              [39.084265199162203, 12.957903473104899],
              [39.100477544252399, 12.958759213995901],
              [39.108079537344203, 12.9602058690181],
              [39.1091530241087, 12.959825055954401],
              [39.109806548477899, 12.959682798051301],
              [39.110567870681201, 12.9596652893807],
              [39.111306735002401, 12.959702495304301],
              [39.111906360454597, 12.959790038632001],
              [39.112227508168601, 12.960026405462999],
              [39.112838362561803, 12.960282469276599],
              [39.1134132843437, 12.960039536947001],
              [39.1139253240556, 12.959825055954401],
              [39.115549028931703, 12.959457373823],
              [39.117044723879701, 12.9592472695042],
              [39.1179385475874, 12.9593545102724],
              [39.118511223581102, 12.9599060334937],
              [39.119160256373803, 12.9606589044903],
              [39.119723949214602, 12.961361436128399],
              [39.120406668830498, 12.962114302723499],
              [39.121145533151697, 12.9626461228123],
              [39.122115713658602, 12.963086022520899],
              [39.123362126115303, 12.9633398946358],
              [39.141842717297799, 12.9558286722401],
              [39.149889376455299, 12.9535481222348],
              [39.150776462798397, 12.952738324983001],
              [39.1520049089495, 12.951906638578899],
              [39.153002038914799, 12.951271928665699],
              [39.153956498904201, 12.950293596770299],
              [39.154365232358501, 12.9496720147785],
              [39.155326429712503, 12.948973827523201],
              [39.155757621048899, 12.948875337000599],
              [39.156433603300201, 12.9492189593216],
              [39.1574374706302, 12.9484047706331],
              [39.161996420697101, 12.948829374743401],
              [39.161306963716498, 12.949586656567],
              [39.165358365647897, 12.947468012614401],
              [39.167795045856103, 12.9455244473635],
              [39.168311577144401, 12.944977269727699],
              [39.1685114522951, 12.943810682998199],
              [39.168565351212202, 12.943528337547001],
              [39.168859549467697, 12.942935192185701],
              [39.169420996520302, 12.9423354792048],
              [39.170328294957301, 12.9419458838676],
              [39.171204152359302, 12.941722632332599],
              [39.1720193734796, 12.9414753044182],
              [39.172324800676201, 12.9408427566893],
              [39.172389928534301, 12.9405472753665],
              [39.1722012823247, 12.939862195100501],
              [39.171958737197997, 12.938921027449],
              [39.171779074141099, 12.937469871296001],
              [39.172275393335603, 12.937113100089],
              [39.1739709634344, 12.936848257329199],
              [39.174516689969501, 12.937154686943099],
              [39.176115691175198, 12.937402019142599],
              [39.177934779625602, 12.9368373134075],
              [39.192808634942402, 12.9280732667523],
              [39.201701956255199, 12.9288634444226],
              [39.203042691816698, 12.9291151625456],
              [39.203934269736202, 12.929097651727901],
              [39.204428343142503, 12.9285373049117],
              [39.204935891277998, 12.927554506090599],
              [39.2055534830358, 12.9267490021669],
              [39.2062474315928, 12.925687396558301],
              [39.207421978826801, 12.925188330918401],
              [39.208618983942898, 12.9258406182651],
              [39.209449925580699, 12.9263856490028],
              [39.210069763126697, 12.9256939632048],
              [39.2109096879174, 12.924820597703301],
              [39.212468264935303, 12.9235729274015],
              [39.214493965900999, 12.9222508278574],
              [39.216259155434301, 12.9213686946325],
              [39.217981674991599, 12.9212811378149],
              [39.220050045933299, 12.920954988399201],
              [39.221788286008099, 12.920418701521101],
              [39.2227943991263, 12.9203705450921],
              [39.224595521270899, 12.920716395603799],
              [39.225507311284296, 12.920530336843701],
              [39.226470754426501, 12.9200750395286],
              [39.226967073620997, 12.9190922073987],
              [39.227636318507699, 12.917940822300899],
              [39.2290511650802, 12.916480788319401],
              [39.2297249015433, 12.916159010567201],
              [39.230638937344899, 12.9161261760794],
              [39.231049916587303, 12.9166449604822],
              [39.232139123869302, 12.9167565974913],
              [39.233127270681898, 12.9169142025957],
              [39.233199135904599, 12.916964548649799],
              [39.233762828745398, 12.9169536038563],
              [39.234346733680098, 12.916901068840801],
              [39.235155217435803, 12.916833211096099],
              [39.235797512863897, 12.916690928668499],
              [39.250783657591199, 12.917971467600401],
              [39.256290330282901, 12.9141604768815],
              [39.258935868794602, 12.9133614967295],
              [39.263052398584101, 12.8940823166144],
              [39.268606232828198, 12.881894972009199],
              [39.269571921758597, 12.8797757540249],
              [39.275251520142398, 12.8673118501308],
              [39.278999740665398, 12.8562354670484],
              [39.294872971735799, 12.8708914819477],
              [39.302966792445702, 12.8701098786269],
              [39.306804844497101, 12.866300348579699],
              [39.310573277114003, 12.8525986986081],
              [39.309643520794999, 12.8517929534998],
              [39.303914515070502, 12.847523337096201],
              [39.3012734681352, 12.846034428156599],
              [39.298407842378801, 12.843496693909],
              [39.295398486177, 12.841031191572499],
              [39.290365674797698, 12.837908771136],
              [39.278918892289902, 12.8260843872359],
              [39.282341473522401, 12.823572728950399],
              [39.286651141097899, 12.822048641608401],
              [39.292056753320097, 12.819738405414601],
              [39.293934232263901, 12.8181376511266],
              [39.296505659764698, 12.8171806973969],
              [39.303784259354302, 12.8133835041961],
              [39.306746454003701, 12.812606101758499],
              [39.309120252142002, 12.8102892092804],
              [39.306674588780901, 12.8057560971678],
              [39.306631918804896, 12.8033077447382],
              [39.3059222497305, 12.8008265189582],
              [39.305084570727999, 12.7976313299407],
              [39.304298544854397, 12.794068175605],
              [39.302858994611597, 12.792539529739299],
              [39.295708404950098, 12.785485300430301],
              [39.275750085125097, 12.7878374621327],
              [39.267919021635798, 12.773783298875699],
              [39.267364311947901, 12.768864047527501],
              [39.278831306549698, 12.758536812720999],
              [39.280908660644201, 12.743326645837801],
              [39.274726005701197, 12.727090362187999],
              [39.272947341438702, 12.726137444922101],
              [39.270418583913902, 12.725153855300601],
              [39.268779158520402, 12.723618221118899],
              [39.269338359784697, 12.720855808704799],
              [39.285499051746001, 12.7043595720959],
              [39.281791255410802, 12.689084811032],
              [39.280526876648402, 12.6879279931408],
              [39.279060376947101, 12.686979310907899],
              [39.276340727424397, 12.684829973876599],
              [39.2754873279045, 12.6841157151473],
              [39.274272356482697, 12.6830508962657],
              [39.272974290897203, 12.6819028149511],
              [39.268790387461401, 12.6780553902107],
              [39.268080718386997, 12.677711397810301],
              [39.267418210864903, 12.677459428559899],
              [39.264563814049602, 12.676821835690999],
              [39.263288206346203, 12.6764340206912],
              [39.261722891963601, 12.675945416771899],
              [39.258255394966902, 12.6743393712771],
              [39.2563082965886, 12.672678538684901],
              [39.255479600739001, 12.6718831757077],
              [39.254419588703698, 12.671278435253701],
              [39.2535527144545, 12.670908140571701],
              [39.251879602237899, 12.670421717154101],
              [39.247740614566297, 12.668500116466801],
              [39.246103434961, 12.667036447386799],
              [39.242373180743698, 12.663837380921001],
              [39.2401116720159, 12.6627001688567],
              [39.237477362445198, 12.662513919769401],
              [39.2361501016129, 12.662741800987],
              [39.235656028206698, 12.663464884271599],
              [39.232087470740503, 12.668079422023],
              [39.230209991796698, 12.665314215222599],
              [39.229300447571497, 12.664700694593],
              [39.228671626872597, 12.664891324375301],
              [39.225992401537702, 12.665498271123599],
              [39.2236949601986, 12.6654281546056],
              [39.221835447560501, 12.66532078865],
              [39.219077619638199, 12.6649176181272],
              [39.216890221921403, 12.6634626931132],
              [39.215423722220102, 12.662189626916399],
              [39.213355351278402, 12.661683466301399],
              [39.210577311262298, 12.6615695252452],
              [39.207642066071401, 12.661806171997201],
              [39.204922416548698, 12.6618280837224],
              [39.202359972200803, 12.661567334070501],
              [39.199761595241498, 12.660789465877899],
              [39.1979357694265, 12.659978727490399],
              [39.1950566689409, 12.658561024711901],
              [39.192577318756697, 12.657612233134699],
              [39.189675760389001, 12.6565297737697],
              [39.186810134632701, 12.6567138360101],
              [39.185318931261001, 12.655692727144499],
              [39.182284871388902, 12.6533524879786],
              [39.180515190279202, 12.6519698084985],
              [39.178013382212903, 12.6493139910863],
              [39.177207144245401, 12.648417756037899],
              [39.176306583173101, 12.6473703184247],
              [39.173589179438601, 12.644541338878801],
              [39.175167968550497, 12.6423982180977],
              [39.1765064583238, 12.6397379184143],
              [39.177054430647097, 12.6386247040653],
              [39.177910075955303, 12.6356488041012],
              [39.178565846112697, 12.6350615089979],
              [39.179533780831299, 12.6343383452631],
              [39.182246692989303, 12.632499747039301],
              [39.183657047985399, 12.6299313850714],
              [39.184240952920099, 12.6284280486051],
              [39.184308326566402, 12.6239705821231],
              [39.183966966758398, 12.621989460976801],
              [39.182794665312599, 12.619469207261499],
              [39.182170336190197, 12.617994299444],
              [39.181981689980503, 12.616918246922101],
              [39.1824645344457, 12.613852239472999],
              [39.182954116275603, 12.6123334673595],
              [39.183648064832603, 12.610659081891299],
              [39.184865282042502, 12.608342538397199],
              [39.185655799492601, 12.606977148643701],
              [39.186650683669697, 12.6053772444884],
              [39.188667401482597, 12.6022102814616],
              [39.190526914120703, 12.5994049112825],
              [39.194021360575903, 12.5946992722101],
              [39.197758352157898, 12.5901316293871],
              [39.198575819066399, 12.587751337983899],
              [39.198899212568698, 12.5854543139254],
              [39.200549866903302, 12.583284396359399],
              [39.201347121717902, 12.582712324127201],
              [39.202815867207498, 12.580912808907801],
              [39.203249304331997, 12.5780809031413],
              [39.203956727618298, 12.576607949095299],
              [39.204731524550802, 12.5753760971117],
              [39.205077375935197, 12.5738746325232],
              [39.205423227319599, 12.5716498184931],
              [39.205971199642903, 12.570084765356301],
              [39.207372571486196, 12.566803383508599],
              [39.2077902880933, 12.56525583685],
              [39.209193905724703, 12.560580260397399],
              [39.210262900912802, 12.556400008252201],
              [39.209952982139797, 12.5553960361619],
              [39.209077124737803, 12.5535524876891],
              [39.208486482438502, 12.5501174459677],
              [39.208901953257403, 12.5489863054111],
              [39.210977061563703, 12.546261463855499],
              [39.212322288701699, 12.5447159857955],
              [39.213941502001298, 12.5442622053694],
              [39.215637072100101, 12.5431200781491],
              [39.216000889790102, 12.5423791176656],
              [39.216701575711703, 12.539456906575699],
              [39.217361837445601, 12.5370608002801],
              [39.217707688829996, 12.5348356678917],
              [39.2179704460506, 12.532538171137499],
              [39.217674002006802, 12.530801881377901],
              [39.2179704460506, 12.5292015029342],
              [39.218796896111897, 12.527618653113],
              [39.222192527885902, 12.523854413057601],
              [39.2232480483448, 12.522260568593],
              [39.222637193951599, 12.5207522170318],
              [39.222069009534401, 12.517985421333],
              [39.222405877765901, 12.5153172550703],
              [39.224696581740403, 12.512475857879201],
              [39.223292964109, 12.510235166127099],
              [39.2253748097799, 12.508233435819101],
              [39.227328645522903, 12.505880889568999],
              [39.228954596187101, 12.5033660750577],
              [39.229430703287697, 12.502315853207699],
              [39.2300168540106, 12.5003074838801],
              [39.230603004733503, 12.4982903286708],
              [39.231570939452098, 12.494898402846299],
              [39.231912299260102, 12.493315342724999],
              [39.231234071220598, 12.491184116286201],
              [39.230764701484603, 12.487485125005399],
              [39.2308073714606, 12.486092782043199],
              [39.231099323927999, 12.4848977730536],
              [39.232197514362802, 12.4817227498839],
              [39.233412485784598, 12.4797953514894],
              [39.2361770510715, 12.4744845162595],
              [39.236417350410001, 12.473155694043101],
              [39.236399384104303, 12.471581272098501],
              [39.235750351311502, 12.4680004133008],
              [39.235231574234902, 12.4647374688707],
              [39.235577425619297, 12.4623318981845],
              [39.235736876582202, 12.458652230924899],
              [39.235898573333401, 12.4573299062779],
              [39.235797512863897, 12.4555755679827],
              [39.235972684344297, 12.453858497886801],
              [39.235826708110601, 12.451235722649299],
              [39.236251162082397, 12.450069061600001],
              [39.236403875680701, 12.4479243214015],
              [39.236071499025599, 12.446713785366899],
              [39.236192771588897, 12.443305832955099],
              [39.236790151252897, 12.441867198690399],
              [39.237387530916799, 12.4402377603171],
              [39.2388675053474, 12.438211365090901],
              [39.239303188260202, 12.4346015353015],
              [39.2404170992125, 12.4331738184281],
              [39.241072869369901, 12.432050938817699],
              [39.241160455110098, 12.431228514150201],
              [39.240971808900397, 12.430096853553801],
              [39.241252532426699, 12.4260109914458],
              [39.241569188564398, 12.4252236386584],
              [39.242631446387897, 12.418784363174799],
              [39.242108177734799, 12.416731481627099],
              [39.241176175627601, 12.414281600722401],
              [39.241086344099202, 12.4103841150935],
              [39.241820716843897, 12.4035825668463],
              [39.2398983221359, 12.394532569492],
              [39.240302564013803, 12.390744462293201],
              [39.240149850415499, 12.388880000211399],
              [39.240176799874, 12.387434484555],
              [39.240884223160201, 12.386030637707499],
              [39.242988526713297, 12.3811741467592],
              [39.242943610949098, 12.3793995527707],
              [39.243121028217701, 12.375240506491799],
              [39.243646542658901, 12.3725906154533],
              [39.2439631987966, 12.3677163251455],
              [39.244205743923303, 12.365393217795001],
              [39.2449356250916, 12.3640616445924],
              [39.2459282634806, 12.362170667366099],
              [39.246570558908701, 12.3599374539079],
              [39.246909672928503, 12.3576296337123],
              [39.246970309210099, 12.3552428172578],
              [39.247399254758299, 12.3529744439348],
              [39.248254900066399, 12.348468351936701],
              [39.250082971669599, 12.345375030629601],
              [39.252247911504298, 12.3428498880535],
              [39.255259513494401, 12.340504620877899],
              [39.2590122255938, 12.3382580590056],
              [39.262477476802303, 12.3355046780894],
              [39.2635442262022, 12.3349254771451],
              [39.264995005385998, 12.331298860310101],
              [39.2671105378801, 12.326412830759301],
              [39.269419208160301, 12.3218075493707],
              [39.2716425384885, 12.3181983037717],
              [39.274481214786299, 12.313871531761301],
              [39.276670858291297, 12.3101700226263],
              [39.279062622735303, 12.306398247428801],
              [39.2812904446399, 12.30358309428],
              [39.283902296328499, 12.300677947476199],
              [39.285622570097601, 12.2986768004815],
              [39.288479212701098, 12.296765603106399],
              [39.291308905846101, 12.295460012524901],
              [39.2927664223946, 12.295955918282599],
              [39.298353943461798, 12.297371219393],
              [39.299620568012401, 12.2973690251336],
              [39.302980267175002, 12.297419493094999],
              [39.305270971149497, 12.2971188794432],
              [39.307305655268003, 12.297270283442201],
              [39.309886065921702, 12.298531980041901],
              [39.311803969053301, 12.300256654638501],
              [39.314462982294302, 12.3023345872408],
              [39.3193430800752, 12.305068568563399],
              [39.321609080379403, 12.305031267244001],
              [39.324086184775403, 12.3044651642176],
              [39.328272333999401, 12.302281926005101],
              [39.3326022136688, 12.3001864390998],
              [39.333817185090602, 12.300548487769801],
              [39.3349557997132, 12.3010421896977],
              [39.340918367411597, 12.301461287050801],
              [39.342360163442599, 12.3009522263044],
              [39.343687424274897, 12.2996576277218],
              [39.350128344862, 12.298402519278399],
              [39.353523976635998, 12.299736620360401],
              [39.356039259431498, 12.299199031100899],
              [39.358163775078502, 12.2984902892945],
              [39.3600614661162, 12.2987777358922],
              [39.363517734171801, 12.300300539340601],
              [39.367993590074903, 12.3007196378766],
              [39.372554785930099, 12.3012835547471],
              [39.388573993234097, 12.300484855009399],
              [39.402863943616197, 12.295266916224501],
              [39.4194917595253, 12.296585673361101],
              [39.426053952675801, 12.3006406455335],
              [39.427769734868498, 12.302367500507801],
              [39.4288342384801, 12.3042830455428],
              [39.439813897040302, 12.3148786316161],
              [39.441628493914202, 12.316098555676099],
              [39.455541151877, 12.325015238428],
              [39.469449318263401, 12.321614472443899],
              [39.471540147087197, 12.321160302294199],
              [39.487134900419498, 12.3267024407973],
              [39.501002642618097, 12.3317003575663],
              [39.507418859534901, 12.332841220729801],
              [39.515135387825502, 12.329348408603799],
              [39.522638566236097, 12.3264018606758],
              [39.5292613956683, 12.312285177094299],
              [39.531170315647003, 12.3060471777553],
              [39.535250912825198, 12.3009258955493],
              [39.535327269624297, 12.298915973456999],
              [39.537094704945801, 12.294871946077601],
              [39.538210861686302, 12.292537221532999],
              [39.539374179979298, 12.290094954594901],
              [39.541316786781202, 12.2775190055015],
              [39.543250410430197, 12.2649973189055],
              [39.553985278075501, 12.256943273356599],
              [39.554627573503602, 12.254663077906001],
              [39.556873361713897, 12.254893512412799],
              [39.572522013963301, 12.255914005666201],
              [39.586609843406499, 12.2658553784633],
              [39.591847021512898, 12.270964176504499],
              [39.596940469173802, 12.269858156464201],
              [39.602745831697497, 12.270674505038],
              [39.607210458659601, 12.270428722937901],
              [39.614190368417198, 12.275719570868899],
              [39.625100407542803, 12.287988398856699],
              [39.620912012530603, 12.3002105756934],
              [39.631287554062197, 12.3071793757686],
              [39.633216686134801, 12.3081053170272],
              [39.640921985484297, 12.3076204050691],
              [39.647670579056303, 12.306703238826699],
              [39.651818549880701, 12.307058695980199],
              [39.657486919323503, 12.306417995084001],
              [39.6598697006146, 12.306323645162401],
              [39.665021538769103, 12.307607240026099],
              [39.679291277057303, 12.307804715602099],
              [39.688449601378899, 12.3069094918015],
              [39.706242981369101, 12.3061963824239],
              [39.721478408587799, 12.3082172196596],
              [39.7265291862727, 12.3102029349114],
              [39.736024378825903, 12.3121688879241],
              [39.737079899284701, 12.3122610416104],
              [39.737670541584002, 12.3120898990245],
              [39.738126436590697, 12.312043822155299],
              [39.738559873715303, 12.3120372397448],
              [39.738993310839902, 12.312107452115301],
              [39.7395165794929, 12.312498008083599],
              [39.739606411021299, 12.312886369341101],
              [39.740156629132798, 12.3133932128298],
              [39.740408157412404, 12.313592878177801],
              [39.741216641168101, 12.3141633497639],
              [39.741661307233699, 12.314670190786799],
              [39.747936039493297, 12.3164934939049],
              [39.758846078618902, 12.3180030301016],
              [39.761525303953803, 12.3032407976856],
              [39.777117811497902, 12.3108743446269],
              [39.800305574769197, 12.3163772065993],
              [39.817589160835702, 12.324019147667601],
              [39.824715046827002, 12.3177287690835],
              [39.823632576909603, 12.2916134197249],
              [39.824308559160897, 12.2822172274577],
              [39.825305689126303, 12.270988315779],
              [39.8255886584408, 12.256418764310499],
              [39.827555968913003, 12.2404459769647],
              [39.807523538077099, 12.2388569851133],
              [39.803604637650203, 12.2296762000407],
              [39.8030836147854, 12.213082817725599],
              [39.802225723689098, 12.208754319904401],
              [39.799838450821497, 12.1949737731225],
              [39.807078872011502, 12.170376417303],
              [39.810438571174103, 12.1643743605584],
              [39.812805631947803, 12.1566290043883],
              [39.816785168656402, 12.138645648371799],
              [39.819129771547999, 12.1234276763353],
              [39.828577802548701, 12.0998250384994],
              [39.843813229767399, 12.094552641400201],
              [39.8617031786506, 12.088357826262101],
              [39.859765063425101, 12.0833201785229],
              [39.843480853112197, 12.0652967454125],
              [39.841239556478399, 12.053535950055601],
              [39.844734002933599, 12.045750041871001],
              [39.8466024987246, 12.0434548580007],
              [39.8476265781485, 12.041109137853701],
              [39.849027949991701, 12.038576703501001],
              [39.8507976311014, 12.0350119267517],
              [39.852708796868399, 12.031165956235],
              [39.855464379002399, 12.0271354255192],
              [39.857139737007302, 12.0227709625585],
              [39.858673610354899, 12.0183603009812],
              [39.8609575769648, 12.0137650527725],
              [39.861795255967202, 12.0114168770163],
              [39.863048405788597, 12.0075683725664],
              [39.863511038159899, 12.0050334257866],
              [39.864811349533703, 12.001090371915399],
              [39.866715777936001, 11.995176781330199],
              [39.8698464067012, 11.991382964550199],
              [39.8710074792059, 11.987815366102801],
              [39.873572169341998, 11.982327674940199],
              [39.876273852559002, 11.976699270529799],
              [39.876774663329897, 11.9720000561612],
              [39.878719515919997, 11.9633154117445],
              [39.8805969948639, 11.948845511258],
              [39.883343593844998, 11.926578897520599],
              [39.884170043906401, 11.921272344672101],
              [39.885789257206099, 11.9138517860201],
              [39.887078339638798, 11.905727832658901],
              [39.880457755994797, 11.890211000277001],
              [39.878074974703701, 11.8826401525465],
              [39.878074974703701, 11.8635725511432],
              [39.877933490046402, 11.8583834536757],
              [39.874569299307403, 11.823329737578399],
              [39.873446405202301, 11.800756090633699],
              [39.873026442806903, 11.7975311321545],
              [39.873868613385802, 11.7951481118914],
              [39.8735878898595, 11.790940403808801],
              [39.874427814650197, 11.786734829656201],
              [39.873947215973203, 11.7839406044159],
              [39.874288575781101, 11.782107084758399],
              [39.874850022833698, 11.7787434017688],
              [39.8791956230206, 11.767385720904899],
              [39.885084079708001, 11.761777060880901],
              [39.902190248505903, 11.736537776244001],
              [39.907238780402601, 11.726583465288501],
              [39.910322247615397, 11.720133970772901],
              [39.912287312299398, 11.7164880596366],
              [39.9128465135637, 11.7153665694533],
              [39.919296417303698, 11.7072345299772],
              [39.923924986805098, 11.700784583853499],
              [39.947759537080998, 11.683679293686399],
              [39.947651739247, 11.6836023196919],
              [39.948740946528901, 11.683118482665501],
              [39.951965898398903, 11.6812952891898],
              [39.969633514249402, 11.6644681209536],
              [39.994090147859502, 11.6811457384085],
              [40.015263439106199, 11.645541561135399],
              [39.999448598529298, 11.6113011206448],
              [39.995215287752899, 11.6040855867661],
              [39.992715725474802, 11.599467987134201],
              [39.992329449902599, 11.5953299017922],
              [39.992810048579599, 11.585902934051999],
              [39.993773491721903, 11.5816700450761],
              [40.006450966168998, 11.558630037672501],
              [40.009413160818397, 11.554073295229299],
              [40.0239905720914, 11.530839578346299],
              [40.053147640425699, 11.4893820180726],
              [40.056563484293598, 11.4859641762534],
              [40.058842959327102, 11.4791305692062],
              [40.060891118174901, 11.4741191521716],
              [40.062941522810902, 11.4638693498267],
              [40.0638533128242, 11.458175343606699],
              [40.067042332082899, 11.442912992518499],
              [40.067042332082899, 11.4383565787404],
              [40.067042332082899, 11.437445287186],
              [40.066815507473599, 11.4353959710708],
              [40.067042332082899, 11.433802292866],
              [40.067269156692099, 11.430839436354701],
              [40.066759362768401, 11.426648240213201],
              [40.067981071554797, 11.4080512614411],
              [40.070163977695202, 11.373652007384701],
              [40.068679511688202, 11.367192189347699],
              [40.067197291469398, 11.3605560820208],
              [40.065712825462398, 11.3492056467271],
              [40.064933536953397, 11.337746863962799],
              [40.0712127607894, 11.3256336118785],
              [40.078983187996997, 11.3224912610769],
              [40.1025549810523, 11.3169023276501],
              [40.121588036134597, 11.298044928361],
              [40.122897330661203, 11.295774381216599],
              [40.123858528015198, 11.294466220773099],
              [40.125518165502598, 11.291583401702599],
              [40.127263142941999, 11.2871324836063],
              [40.129621220562797, 11.2833774495539],
              [40.133636689882799, 11.276043429146],
              [40.1360823532439, 11.2720283591014],
              [40.138350599336299, 11.268709221555699],
              [40.140533505476697, 11.265740245887301],
              [40.142453654396498, 11.2616369301094],
              [40.145597757890897, 11.255612901049499],
              [40.172628064790104, 11.2398837543442],
              [40.179695560287897, 11.216619960847799],
              [40.181168797353799, 11.211773560233],
              [40.1828239432648, 11.2063212624138],
              [40.183881709511901, 11.202844955834999],
              [40.185024815710896, 11.1990756053639],
              [40.185770417396697, 11.1966258316848],
              [40.186745089479999, 11.193415979935899],
              [40.187870229373402, 11.1897103964655],
              [40.188344090685703, 11.1881506004217],
              [40.188815706209901, 11.186604014696],
              [40.189298550675097, 11.1850111549552],
              [40.190297926428698, 11.181721860866],
              [40.192146210125799, 11.1756366785624],
              [40.194239284737797, 11.168744978309901],
              [40.195975279024303, 11.163029678184801],
              [40.197879707426701, 11.156763429561],
              [40.200019943591101, 11.1497192468004],
              [40.201526867480197, 11.144757148837],
              [40.2037659183259, 11.137382151450501],
              [40.205805094020803, 11.1306680310482],
              [40.206438406296101, 11.1285790640776],
              [40.207527613578101, 11.124993850612499],
              [40.2098609875286, 11.1173076389231],
              [40.212416694511901, 11.108896205468399],
              [40.1937744065782, 11.096354631912],
              [40.195490188770897, 11.0682129171753],
              [40.194776028120003, 11.0600535399254],
              [40.193972035940703, 11.055993603078701],
              [40.1931590606086, 11.0478537232547],
              [40.186918015172203, 11.0253306700174],
              [40.181427062997997, 11.0190548553644],
              [40.177101674905003, 11.013202157324001],
              [40.175659878874001, 11.0110814899912],
              [40.1745571968627, 11.0095538064504],
              [40.172776286811903, 11.0070098540802],
              [40.170995376761198, 11.004465879739699],
              [40.169807354797904, 11.0026846437303],
              [40.1682802188149, 11.000563900678801],
              [40.166838422783897, 10.9985269148154],
              [40.155301808747602, 10.974522931056701],
              [40.154199126736302, 10.9726577345191],
              [40.153352464581097, 10.9696041663121],
              [40.151910668550002, 10.966634348057999],
              [40.151486214578298, 10.965108639182301],
              [40.150976420654601, 10.964345781792501],
              [40.150212852663103, 10.962394533562099],
              [40.148685716680099, 10.960019946318599],
              [40.147583034668799, 10.9579848856323],
              [40.146397258493799, 10.9557778321672],
              [40.1446994426068, 10.9526403042594],
              [40.144191894471199, 10.952216966957099],
              [40.1434283264798, 10.951451871442901],
              [40.123748484392898, 10.9376268944561],
              [40.132920283443802, 10.915148355119801],
              [40.146626328891202, 10.893975870210999],
              [40.1482388048262, 10.89511381106],
              [40.149900688101802, 10.8967501447329],
              [40.150922521737499, 10.897266183405399],
              [40.153096444725101, 10.897396295579],
              [40.15699288727, 10.8961458931843],
              [40.180479340373303, 10.8968493830086],
              [40.178487326230702, 10.884314224356499],
              [40.175835050354401, 10.877770767877101],
              [40.1708875789271, 10.874054585239501],
              [40.168971921583697, 10.8729717007554],
              [40.166930500100499, 10.8474555118675],
              [40.167938859007002, 10.8423273088058],
              [40.168147717310497, 10.8418001466042],
              [40.167786145408598, 10.835348400071799],
              [40.166384773565397, 10.8327014894692],
              [40.164067120132401, 10.828596525996399],
              [40.1666722344563, 10.805035462894599],
              [40.143239680270099, 10.7840691721048],
              [40.141427329184403, 10.783215400112599],
              [40.139269126714296, 10.781225462188299],
              [40.137667879720297, 10.780062820525201],
              [40.134274493734601, 10.7794517148519],
              [40.132116291264502, 10.7778390076402],
              [40.130387034342498, 10.7753879408722],
              [40.129216978685001, 10.774042162153],
              [40.126861146852399, 10.7722705787011],
              [40.123937130602599, 10.7690362537627],
              [40.1223695704318, 10.7682398014332],
              [40.119721786131798, 10.7672028658824],
              [40.114668762658702, 10.7652216471835],
              [40.109287854106803, 10.7656077431634],
              [40.107515927208901, 10.7620181346031],
              [40.102588667875501, 10.7550329471268],
              [40.102523540017401, 10.7396720260619],
              [40.108676999713602, 10.7240609821185],
              [40.109431584552198, 10.7236836592791],
              [40.114489099601798, 10.6978502693688],
              [40.122657031322703, 10.656102199096001],
              [40.128473622787403, 10.6174718043663],
              [40.131579547882197, 10.596845737553799],
              [40.137425334593601, 10.558004745102799],
              [40.1401607046338, 10.5398387032618],
              [40.141759705839497, 10.5292119108486],
              [40.143459767514699, 10.5179157123869],
              [40.147913165535698, 10.4883153276468],
              [40.155270367712703, 10.439422677778101],
              [40.161565312066102, 10.4353278809351],
              [40.163617962490299, 10.412350521760301],
              [40.163418087339601, 10.381496397736701],
              [40.163036303343901, 10.374791937978101],
              [40.161383403221102, 10.364895141833401],
              [40.160687208875899, 10.3624186847715],
              [40.160096566576598, 10.360302303636001],
              [40.159072487152699, 10.3562661175334],
              [40.160006735048199, 10.3380706020574],
              [40.161199248587799, 10.310607429184],
              [40.158877103578398, 10.3083824316244],
              [40.159665375240202, 10.306435822061999],
              [40.160417714290702, 10.302697237393399],
              [40.160309916456598, 10.2988724337844],
              [40.157475731735197, 10.2853537787701],
              [40.1466375578323, 10.277204300052601],
              [40.154142982031097, 10.2594461940798],
              [40.1569726751761, 10.2553512579517],
              [40.149846789184799, 10.2333176926712],
              [40.143365444409902, 10.213271786292401],
              [40.1361407437373, 10.193326291958],
              [40.128972187770103, 10.173534282603001],
              [40.1359655722569, 10.144504962448799],
              [40.150251031062602, 10.109538955814299],
              [40.158124764527898, 10.090265788284499],
              [40.132563203118302, 10.071079914381601],
              [40.107129651636697, 10.0711683617352],
              [40.069002905190402, 10.0790489235757],
              [40.047393931031003, 10.0767913524521],
              [40.023986080515002, 10.0743458168263],
              [39.993539929748003, 10.076884220527999],
              [39.988929326552203, 10.088302367666699],
              [39.980157277802803, 10.087840254542201],
              [39.969148423995897, 10.0849282632845],
              [39.9481503042296, 10.084450667672501],
              [39.928281815933097, 10.0891226721061],
              [39.9325375845916, 10.062296971445299],
              [39.934237646266801, 10.0626131792278],
              [39.935964657400604, 10.0626176017121],
              [39.937781500062698, 10.062526940772401],
              [39.939629783759798, 10.0617928073475],
              [39.940429284362601, 10.061337289985699],
              [39.941902521428602, 10.060483746014601],
              [39.942964779252101, 10.059860171013799],
              [39.944496406811503, 10.0589845104696],
              [39.944327972695703, 10.057379126639599],
              [39.941597094232002, 10.043056361762],
              [39.922716752748002, 10.010728854663901],
              [39.9462773168623, 9.99422771340074],
              [39.948388357779898, 9.992498152426659],
              [39.961083798532698, 9.994083952289269],
              [39.992387840396098, 9.99329658261288],
              [40.000396321154, 9.99289405018785],
              [40.009785961661301, 9.991168905579819],
              [40.030685266746303, 9.994459942754229],
              [40.033175845871597, 9.994488694948361],
              [40.040800296845497, 9.993095316462661],
              [40.043225748112597, 9.992312367836259],
              [40.044261056477602, 9.989333164729519],
              [40.057515698494797, 9.97845563476603],
              [40.0589125787616, 9.977307700174549],
              [40.060221873288199, 9.97551611787196],
              [40.065252438879199, 9.96793385555071],
              [40.066644827569597, 9.965761774742001],
              [40.0676262370175, 9.96339281879062],
              [40.067251190386401, 9.96005722886115],
              [40.072138025531999, 9.955064839904511],
              [40.080400280357701, 9.94837797159135],
              [40.088819740358097, 9.9347936738223],
              [40.0792257331237, 9.90608141299596],
              [40.076378073673098, 9.90481818388645],
              [40.0693577397277, 9.90478499916828],
              [40.063536656686601, 9.90240011530225],
              [40.0567206894683, 9.86488569425852],
              [40.060096109148397, 9.86376833788313],
              [40.070671525830697, 9.85740486579178],
              [40.052649075443099, 9.842183809871029],
              [40.035183580531601, 9.842785673353429],
              [40.028868424084202, 9.83133459003394],
              [40.026685517943797, 9.828444650081421],
              [40.0174688031287, 9.819995967199389],
              [40.011003178871299, 9.818544312177499],
              [40.004434248356098, 9.79521737957127],
              [40.007616530250097, 9.78870652455881],
              [40.008052213162898, 9.781763980073629],
              [40.003241734816498, 9.775795090621889],
              [39.9934074282436, 9.76359139928454],
              [39.991132444786601, 9.756712516484219],
              [40.001811167726501, 9.747918937314401],
              [40.005894010692799, 9.74100874336674],
              [40.013116465577198, 9.739439432105391],
              [40.014686271536199, 9.73755580616678],
              [40.013430875926602, 9.735669956156039],
              [40.009662443309701, 9.733786308953141],
              [40.009033622610801, 9.731902651127299],
              [39.973509744700301, 9.729215487503341],
              [39.954708005803703, 9.73023590391225],
              [39.948918363797603, 9.73138691217358],
              [39.944058478110499, 9.73322630368202],
              [39.938738205840302, 9.734835486261741],
              [39.932027790667902, 9.736905056310279],
              [39.923698162195898, 9.740136658752929],
              [39.9181375905872, 9.741745808027501],
              [39.875777533364499, 9.75279715586692],
              [39.862118649469501, 9.753945872992221],
              [39.856558077860797, 9.75256696930019],
              [39.853077106134798, 9.74957674282275],
              [39.851677980079799, 9.74773744141781],
              [39.851112041450797, 9.747567012254249],
              [39.845706429228599, 9.73698474003433],
              [39.844439804677997, 9.7355260821851],
              [39.839106057678599, 9.72354669666862],
              [39.8484305703277, 9.70868475610269],
              [39.852657143739499, 9.698670172126199],
              [39.849935248428601, 9.6890648336074],
              [39.849856645841299, 9.686359616041059],
              [39.857640547778203, 9.65513956366402],
              [39.861575168722602, 9.65157725612705],
              [39.868424822763998, 9.6423956720982],
              [39.869803736725203, 9.638631748178179],
              [39.8793056666429, 9.624237673083719],
              [39.8848303056403, 9.612832241328899],
              [39.8827170189344, 9.60841034651882],
              [39.8824183291024, 9.59501366769573],
              [39.880495934394403, 9.590992346359091],
              [39.877848150094401, 9.582951775079129],
              [39.878319765618599, 9.57769463097655],
              [39.8759280011746, 9.575418133884551],
              [39.854840049879897, 9.568537616570859],
              [39.853699189469097, 9.56618354856959],
              [39.852297817625903, 9.56627434547396],
              [39.847556958713902, 9.564927891332511],
              [39.847556958713902, 9.563630152525169],
              [39.856746724070497, 9.556485856712261],
              [39.857301433758401, 9.547071368089069],
              [39.848691081760101, 9.525966997612571],
              [39.847087588977999, 9.524425479161749],
              [39.846371182538903, 9.512314572095811],
              [39.847781537534999, 9.509091864521681],
              [39.837504810684599, 9.5018866222409],
              [39.8478084869935, 9.48119590314043],
              [39.871975413924503, 9.46068337080704],
              [39.873030934383401, 9.457812404666219],
              [39.8730758501476, 9.446828965829869],
              [39.8713892632016, 9.441594097476029],
              [39.873192631134501, 9.416063283088761],
              [39.874879218080402, 9.41482701557878],
              [39.875564183484599, 9.41200662529609],
              [39.874841039680902, 9.4110738752914],
              [39.872844533961903, 9.408723156871311],
              [39.875171170547802, 9.40784578755939],
              [39.875440665132999, 9.40586062049598],
              [39.877169922054897, 9.40655853209056],
              [39.879572915440001, 9.40387322643126],
              [39.879682959062301, 9.402098520409361],
              [39.879676221697601, 9.401626593305849],
              [39.879474100758699, 9.40068495279575],
              [39.879056384151603, 9.399931638542901],
              [39.878759940107798, 9.39946413975558],
              [39.877953702140303, 9.399096344057281],
              [39.876835299611599, 9.398770645130689],
              [39.876601737637699, 9.398037267514241],
              [39.873837172350797, 9.397758096170371],
              [39.871901302913599, 9.397742586644659],
              [39.869713905196697, 9.398077149116411],
              [39.8680565134975, 9.39954168699146],
              [39.866376663916199, 9.400388058550689],
              [39.8654177123504, 9.400529858818899],
              [39.864896689485697, 9.400607405816009],
              [39.863740108557401, 9.400855556089979],
              [39.8611641894801, 9.401380659208071],
              [39.859551713545102, 9.4014161091789],
              [39.857788769800102, 9.401444912277521],
              [39.856474983696998, 9.40266350271491],
              [39.855477853731699, 9.40473952781201],
              [39.854269619674497, 9.40535768015633],
              [39.8301139216845, 9.41405379225338],
              [39.827376305856198, 9.41482258450448],
              [39.821433950251702, 9.417428046385419],
              [39.8072001445749, 9.412828595304079],
              [39.800694096129597, 9.401843724165809],
              [39.798861532949999, 9.397485571546101],
              [39.786630970356697, 9.39628468812602],
              [39.7837091998951, 9.39490433174319],
              [39.791457169220699, 9.369806588558269],
              [39.7520301114007, 9.36924155269465],
              [39.750900479930898, 9.367497691114361],
              [39.750684884262697, 9.36091215267405],
              [39.753968226626199, 9.35468768484637],
              [39.768476018464703, 9.33363800988044],
              [39.771348381585703, 9.33382194243662],
              [39.772102966424299, 9.33171446841863],
              [39.772473521479, 9.33059978458126],
              [39.7735559913964, 9.327437431519691],
              [39.773814257040598, 9.32554488135658],
              [39.773701967629997, 9.32420413410359],
              [39.773338149940002, 9.32269052398965],
              [39.773340395728198, 9.32142954656916],
              [39.788771206521098, 9.304892370358459],
              [39.815278244767299, 9.307248223347599],
              [39.818103446335897, 9.308061576243579],
              [39.821319415052997, 9.30956638492259],
              [39.823899825706597, 9.310674487319],
              [39.826565576312298, 9.31163188496026],
              [39.828708058264901, 9.313189866718],
              [39.831632074514701, 9.314588276859981],
              [39.836038310983298, 9.31489410907689],
              [39.842838557684097, 9.314264714947701],
              [39.845389773091, 9.314074123544049],
              [39.859976167516898, 9.30711303371827],
              [39.865738860064504, 9.297698388042621],
              [39.866405859163002, 9.29637748057775],
              [39.885966674474702, 9.26934654218144],
              [39.857514783638401, 9.271760261854521],
              [39.856452525814902, 9.26836243132613],
              [39.862933870589799, 9.24224923993838],
              [39.873206105863801, 9.233564362353601],
              [39.873408226802702, 9.229068889885729],
              [39.872606480411598, 9.225491091253559],
              [39.865267244540298, 9.2195390967834],
              [39.853483593800902, 9.210800456043559],
              [39.847745604923603, 9.201498509665219],
              [39.840592769473801, 9.192814846561451],
              [39.836094455688603, 9.18828560101025],
              [39.812992032369202, 9.171096997595409],
              [39.815244557944197, 9.16325066535623],
              [39.817157969499299, 9.15988061355017],
              [39.815572443022901, 9.155566013729199],
              [39.814829087125197, 9.152710278571901],
              [39.817398268837799, 9.136300443071161],
              [39.808365708655998, 9.136697338978371],
              [39.807058659917601, 9.13416739715859],
              [39.803456415628297, 9.127446666945371],
              [39.800422355756197, 9.1246306189361],
              [39.797109818145998, 9.12145311445005],
              [39.793781560018303, 9.11272979899665],
              [39.786332280524803, 9.108432368003159],
              [39.780225982380998, 9.10433889496546],
              [39.763896856303901, 9.09511621276466],
              [39.742126185393197, 9.091754387306599],
              [39.742734793998203, 9.0907365196787],
              [39.747686757002, 9.065322155505021],
              [39.747376838228902, 9.062310456227459],
              [39.7471410304668, 9.0595315978988],
              [39.746691872824798, 9.056561988110669],
              [39.7455981739664, 9.053725422319459],
              [39.744686383953002, 9.05109952755191],
              [39.744057563254103, 9.049103479852731],
              [39.742941406513602, 9.045874294791791],
              [39.740219511202703, 9.0395289504646],
              [39.741133547004303, 9.023011900998821],
              [39.746139408925004, 9.015246608038501],
              [39.746489751885903, 9.009745813895339],
              [39.746653694425198, 8.988906360880341],
              [39.747091623126202, 8.97198326602857],
              [39.748270661936601, 8.946729143092339],
              [39.747989938410299, 8.9102646362174],
              [39.738474533763302, 8.872047987501119],
              [39.736862057828297, 8.85719646999409],
              [39.731310469372403, 8.84417732223622],
              [39.733255321962602, 8.826226697715571],
              [39.716706108640899, 8.81606708709978],
              [39.679145300823599, 8.815638770595051],
              [39.662115488824902, 8.813179824783891],
              [39.650078064017698, 8.802740219279331],
              [39.6340453819844, 8.798783123435481],
              [39.616999849468201, 8.786856096405421],
              [39.598984136445203, 8.79286182642201],
              [39.591478712246399, 8.79685893422965],
              [39.561964563586699, 8.817826952292901],
              [39.556916031689902, 8.79143253364852],
              [39.551764193535497, 8.768736223992409],
              [39.533752972088898, 8.757946889300349],
              [39.519231705521101, 8.738975457059439],
              [39.515229710930299, 8.73374798847064],
              [39.514237072541398, 8.73244943395534],
              [39.500728656456403, 8.71479974331656],
            ],
          ],
          [
            [
              [37.403357851611702, 11.568462755643701],
              [37.403672261961098, 11.568865385873901],
              [37.403851925017896, 11.568865385873901],
              [37.404298836871803, 11.5697608510308],
              [37.405509316717101, 11.571239352341101],
              [37.406492971953199, 11.5732546778555],
              [37.406942129595301, 11.573837711459801],
              [37.4070319611237, 11.5743305389173],
              [37.407209378392302, 11.574418543729101],
              [37.407254294156502, 11.5749575725971],
              [37.407121792652099, 11.5755846048715],
              [37.407121792652099, 11.575943222698401],
              [37.406492971953199, 11.5769266692263],
              [37.405911312806801, 11.5783171291098],
              [37.405866397042601, 11.5793027673718],
              [37.405821481278402, 11.5804666080786],
              [37.405821481278402, 11.581945052803601],
              [37.405911312806801, 11.582930678276799],
              [37.405821481278402, 11.5834674906888],
              [37.405911312806801, 11.584228706522101],
              [37.4060011443352, 11.5847215156671],
              [37.405911312806801, 11.585304525363901],
              [37.406225723156197, 11.5858413332168],
              [37.406270638920397, 11.586290138991],
              [37.406897213831101, 11.587095347544],
              [37.407076876887899, 11.5875441513043],
              [37.4070319611237, 11.5878125532086],
              [37.407299209920701, 11.587946754064101],
              [37.407166708416298, 11.5883493562438],
              [37.407254294156502, 11.5885737572069],
              [37.4073890414491, 11.588976358483199],
              [37.4073890414491, 11.5898277592715],
              [37.407478872977499, 11.5920673489553],
              [37.4073890414491, 11.5936359310372],
              [37.4073890414491, 11.594172722882099],
              [37.407299209920701, 11.594441118417601],
              [37.407076876887899, 11.595382700715501],
              [37.406852298066902, 11.5958292912094],
              [37.406852298066902, 11.5960536861632],
              [37.406717550774303, 11.5966366722104],
              [37.406448056188999, 11.5974418509381],
              [37.406090975863599, 11.598742011686101],
              [37.406135891627798, 11.599951969717401],
              [37.406135891627798, 11.600847335283801],
              [37.4060011443352, 11.60143031132],
              [37.406135891627798, 11.601384113263499],
              [37.405956228571, 11.6019230901141],
              [37.406046060099399, 11.602728253592799],
              [37.405956228571, 11.603130834461],
              [37.405911312806801, 11.604206580488601],
              [37.405911312806801, 11.6046091592239],
              [37.405776565514103, 11.605236124949901],
              [37.405956228571, 11.605236124949901],
              [37.405911312806801, 11.6055045098476],
              [37.405956228571, 11.6054165148277],
              [37.406090975863599, 11.605550707221999],
              [37.406225723156197, 11.605775094360199],
              [37.406499709317899, 11.6058146920717],
              [37.406537887717398, 11.6058190918171],
              [37.406508692470702, 11.6058190918171],
              [37.406582803481598, 11.6058630892671],
              [37.406627719245797, 11.6060434787394],
              [37.406942129595301, 11.606177670832199],
              [37.407299209920701, 11.6064020574661],
              [37.407433957213399, 11.6063558602327],
              [37.407254294156502, 11.6065362493864],
              [37.4073890414491, 11.6065362493864],
              [37.407344125684901, 11.606580246723199],
              [37.407299209920701, 11.6067144385578],
              [37.407523788741798, 11.606580246723199],
              [37.407523788741798, 11.6068948274796],
              [37.407658536034397, 11.607475591020901],
              [37.407829215938399, 11.607523987928101],
              [37.407838199091202, 11.607521788068899],
              [37.407840444879398, 11.6075261877873],
              [37.407858411185103, 11.6075503862376],
              [37.407928030619601, 11.607655979450501],
              [37.407928030619601, 11.607746173621599],
              [37.407523788741798, 11.607834167906701],
              [37.407535017682797, 11.607979358416401],
              [37.4075574755649, 11.6079617595708],
              [37.407568704505998, 11.6079243620201],
              [37.407658536034397, 11.6078803648953],
              [37.4075574755649, 11.6079617595708],
              [37.407537263470999, 11.6080211556704],
              [37.407523788741798, 11.6080585532081],
              [37.407838199091202, 11.608029955091499],
              [37.407838199091202, 11.608014556104401],
              [37.407903326949302, 11.6080211556704],
              [37.407972946383801, 11.607968359138001],
              [37.407928030619601, 11.608012356249001],
              [37.407923539043203, 11.6080211556704],
              [37.407838199091202, 11.6081927443315],
              [37.407838199091202, 11.6081025503048],
              [37.407919047466798, 11.608023355525701],
              [37.4079078185257, 11.608023355525701],
              [37.407883114855402, 11.608036554657099],
              [37.407838199091202, 11.608069552482901],
              [37.407613620270197, 11.6082389412607],
              [37.407748367562803, 11.6083269353903],
              [37.407757350715599, 11.608601916866601],
              [37.407133021593197, 11.609193676084001],
              [37.406555854023097, 11.6094950548311],
              [37.405978686453103, 11.6096842413235],
              [37.404173072732, 11.609904225455599],
              [37.4042831163543, 11.6116838907035],
              [37.403382555282001, 11.6128563957169],
              [37.402812125076601, 11.6133887509621],
              [37.402106947578503, 11.6152475867184],
              [37.402758226159499, 11.6155731566759],
              [37.403914807087801, 11.6151419963803],
              [37.404550365151302, 11.6148934189681],
              [37.405776565514103, 11.614919816579899],
              [37.407054419005803, 11.6187342452066],
              [37.4076158660584, 11.6199837122053],
              [37.408940881102502, 11.6234549132976],
              [37.409443937661599, 11.6247043591242],
              [37.410048054690101, 11.6253774737428],
              [37.410652171718702, 11.626052786447501],
              [37.411449426533402, 11.6273528137861],
              [37.411543749638199, 11.628670432594101],
              [37.411579682249602, 11.6298780610501],
              [37.411615614860899, 11.6311406760925],
              [37.411480867568301, 11.6325814580381],
              [37.410697087482902, 11.6337516754422],
              [37.410122165701097, 11.634110218511699],
              [37.409549489707402, 11.6344709607658],
              [37.408981305290197, 11.635060465393799],
              [37.4087702011985, 11.635814941510001],
              [37.408125659982097, 11.6358303389604],
              [37.407537263470999, 11.6356169742139],
              [37.407202641027702, 11.6362020771485],
              [37.408345747226697, 11.6373194883568],
              [37.409482116061099, 11.638265325045399],
              [37.410196276712, 11.6387646375352],
              [37.410852046869401, 11.6392067593001],
              [37.411510062814997, 11.639706070100001],
              [37.411761591094603, 11.640330757239401],
              [37.411853668411197, 11.641478946139101],
              [37.412556600121, 11.641573528538199],
              [37.413785046272103, 11.641657112957301],
              [37.413818733095198, 11.6428624854695],
              [37.4140859818922, 11.644004065202701],
              [37.414750735202503, 11.644734321841799],
              [37.4160577839409, 11.645561357164899],
              [37.416718045674699, 11.646175033381001],
              [37.417746616674997, 11.6474133794697],
              [37.418770696098903, 11.6485329451636],
              [37.419410745738901, 11.6504113419136],
              [37.420587538761097, 11.6525250729559],
              [37.420670632924796, 11.6535962291658],
              [37.420704319747998, 11.6548037493863],
              [37.421896833287697, 11.65569014067],
              [37.421351106752603, 11.657027420429699],
              [37.421011992732801, 11.6594490241323],
              [37.421930520110799, 11.660918252052101],
              [37.422280863071599, 11.6629791170348],
              [37.421973190086803, 11.664424132769099],
              [37.421364581481797, 11.665647001698799],
              [37.4202910947173, 11.666940245711199],
              [37.4199025733569, 11.667582466688501],
              [37.418498955725497, 11.669572902397199],
              [37.4178342024152, 11.670914513989199],
              [37.417328900067901, 11.671558925119101],
              [37.416547365770697, 11.6729027264472],
              [37.416156598622102, 11.6734855515127],
              [37.415083111857598, 11.674780958309199],
              [37.414690098920801, 11.675365978749699],
              [37.413809749942402, 11.677226599463699],
              [37.413960217752503, 11.6784296182663],
              [37.415152731292103, 11.679260951792299],
              [37.415801764084897, 11.6795292652552],
              [37.4175557246772, 11.679540261703099],
              [37.418328275821501, 11.679980119259699],
              [37.4189166723326, 11.6801362685244],
              [37.419942997544702, 11.681315082669499],
              [37.4203696973047, 11.6818780966112],
              [37.419929522815501, 11.6849350664046],
              [37.4198509202281, 11.686204028850799],
              [37.420100202719397, 11.6867714313221],
              [37.420425842009898, 11.687970010240999],
              [37.421622847126002, 11.688913475136101],
              [37.422271879918803, 11.6891839784642],
              [37.422916421135099, 11.689223564294901],
              [37.423563208139697, 11.6893797083499],
              [37.423929271618, 11.689944905108399],
              [37.424122409404099, 11.6905716783749],
              [37.424373937683598, 11.6911962510256],
              [37.424704068550497, 11.6924519896617],
              [37.425357592919703, 11.6928940258998],
              [37.427174435581897, 11.693074358888801],
              [37.427664017411701, 11.693866063304],
              [37.4281625823944, 11.6948886781559],
              [37.428609494248199, 11.696254357904801],
              [37.429036194008198, 11.696876718582599],
              [37.430608245755401, 11.6987305973955],
              [37.430862019823202, 11.699355151636301],
              [37.432142119103098, 11.701217810313199],
              [37.432348731618397, 11.7023613489784],
              [37.433172935891598, 11.7045802403249],
              [37.433399760500798, 11.7064120767528],
              [37.433202131138302, 11.707625964075],
              [37.432964077587997, 11.7095281499747],
              [37.4330000101994, 11.7108497766335],
              [37.432916916035602, 11.7119998725603],
              [37.432645175662202, 11.712696964702699],
              [37.432193772231898, 11.713284104038999],
              [37.431118039679198, 11.714462776993001],
              [37.430486973192103, 11.714882787968801],
              [37.429918788774899, 11.715469922660599],
              [37.429289968075999, 11.7159471061165],
              [37.428090717171699, 11.7168992715633],
              [37.427583169036097, 11.717545774637999],
              [37.4270149846189, 11.7181351026532],
              [37.425485602847701, 11.7197843348189],
              [37.424236944602796, 11.721138896805099],
              [37.423123033650498, 11.7230080055194],
              [37.4235654539279, 11.7242042284565],
              [37.424194274626799, 11.726130488322401],
              [37.424719789068, 11.727735694609301],
              [37.424329021919398, 11.7283799734113],
              [37.4239809247468, 11.729945586620801],
              [37.423498080281597, 11.730631639048401],
              [37.4230736263099, 11.7312187402523],
              [37.422539128715798, 11.731904789514299],
              [37.422462771916699, 11.7326238200831],
              [37.4231185420741, 11.7330657920715],
              [37.424980300500401, 11.7327865362209],
              [37.425579925952597, 11.7332878772312],
              [37.425945989430801, 11.7339079556416],
              [37.426606251164699, 11.734466464789699],
              [37.427857155197799, 11.7352954310073],
              [37.429092338713502, 11.735605468130199],
              [37.429743617294399, 11.735875925761301],
              [37.431097827585297, 11.736243132039201],
              [37.431690715672801, 11.7365157878814],
              [37.432937128129502, 11.7372282099385],
              [37.4343609578548, 11.7379362325052],
              [37.4349583375188, 11.7383803948023],
              [37.436204749975502, 11.739035642825501],
              [37.436939122720297, 11.740165832065101],
              [37.437107556835997, 11.742001829999801],
              [37.435362579396603, 11.742276678937801],
              [37.434785411826603, 11.7425801118469],
              [37.4351649500341, 11.7435475768632],
              [37.434917913330999, 11.7451065076649],
              [37.434482230418197, 11.746267454634401],
              [37.435153721093101, 11.7472239129514],
              [37.435751100757003, 11.747725227708701],
              [37.436355217785596, 11.7483430749047],
              [37.437669003888601, 11.749341301966],
              [37.438327019834198, 11.7498997798453],
              [37.438870500581103, 11.7505176221642],
              [37.439355590834502, 11.751135463097301],
              [37.439858647393599, 11.752329365315299],
              [37.4399507247103, 11.753532057160101],
              [37.441120780367797, 11.753560640266199],
              [37.4424525327765, 11.753178066138799],
              [37.443658521045499, 11.7523997240322],
              [37.444107678687502, 11.751755501359201],
              [37.444559082117799, 11.7511684438911],
              [37.445127266535003, 11.7506363532696],
              [37.445697696740403, 11.750161428541],
              [37.447449411544497, 11.750113056531999],
              [37.448008612808799, 11.751249796498801],
              [37.4480288249027, 11.751937994624299],
              [37.4474583946973, 11.7524129162896],
              [37.446964321290999, 11.753573832468],
              [37.447819966599099, 11.7548754599389],
              [37.449043921173804, 11.7567377439483],
              [37.448316285793602, 11.7579074365545],
              [37.446566816777803, 11.7580107738202],
              [37.445989649207803, 11.758254825506601],
              [37.4455719326006, 11.7599917636906],
              [37.445765070386699, 11.760618377939799],
              [37.446131133865002, 11.761183428863999],
              [37.445994140784201, 11.762507006642601],
              [37.446418594755897, 11.7630720536886],
              [37.446786904022403, 11.763694263684799],
              [37.446804870328101, 11.7643252667315],
              [37.446883472915502, 11.7650134321674],
              [37.447135001195001, 11.765580672670101],
              [37.447635811965903, 11.7667745122322],
              [37.448062511725901, 11.7673967138542],
              [37.448639679295901, 11.7671504716845],
              [37.449032692232699, 11.7665656456478],
              [37.4493066783944, 11.765925853333799],
              [37.449758081824598, 11.7653388260872],
              [37.450333003606502, 11.7650354182552],
              [37.450701312873001, 11.7656004601084],
              [37.450892204870797, 11.7661698980003],
              [37.4514850929584, 11.766440325621],
              [37.452129634174703, 11.766539262488999],
              [37.4528527779784, 11.767324160380699],
              [37.452713539109403, 11.768535581021601],
              [37.453784780085698, 11.769252317399999],
              [37.454557331229999, 11.7696348691809],
              [37.455150219317602, 11.769962456202499],
              [37.455592639594997, 11.7711013127442],
              [37.455610605900702, 11.7717322987973],
              [37.455408484961801, 11.772888736375901],
              [37.456176544529697, 11.7731547603018],
              [37.456767186828998, 11.773310856865701],
              [37.457420711198203, 11.774913589697499],
              [37.457474610115199, 11.7755907360142],
              [37.457934996698299, 11.777360542372699],
              [37.458584029491099, 11.7796403879262],
              [37.458734497301201, 11.780785799815799],
              [37.458182033401499, 11.7819487947177],
              [37.457681222630598, 11.7828259842123],
              [37.456504429608401, 11.7825709619914],
              [37.455273737669103, 11.782430259975101],
              [37.4546943243109, 11.7826171298248],
              [37.454085715705901, 11.783784513890501],
              [37.454339489773602, 11.784468233704001],
              [37.4548245800271, 11.785143158024001],
              [37.456502183820199, 11.7865347719884],
              [37.456167561376802, 11.787119555530801],
              [37.455599376959597, 11.7876515755664],
              [37.455022209389597, 11.7878977993661],
              [37.454692078522697, 11.7885968978796],
              [37.454582034900398, 11.7892278437517],
              [37.454290082432998, 11.790905229383601],
              [37.4541306314701, 11.791483408425099],
              [37.453620837546303, 11.7920725782106],
              [37.452648411251303, 11.7927870547983],
              [37.451464880864499, 11.7923034093116],
              [37.451190894702798, 11.7928859821793],
              [37.450683346567303, 11.7935872663683],
              [37.450088212691497, 11.793200351175299],
              [37.449331382064699, 11.793336650907801],
              [37.4481590806189, 11.793195954408599],
              [37.447586404625298, 11.793613646929799],
              [37.446409611603102, 11.7933586347293],
              [37.445841427185897, 11.793947800488899],
              [37.446506180496101, 11.7946183047586],
              [37.447152967500699, 11.794831546756599],
              [37.4483275147347, 11.795029400008699],
              [37.448985530680297, 11.795528429243999],
              [37.449529011427202, 11.7961461690227],
              [37.449913041211097, 11.797284916997601],
              [37.450281350477603, 11.7979070495124],
              [37.450822585436299, 11.7984676270716],
              [37.4514267024649, 11.799085360231601],
              [37.452068997893001, 11.7990677735416],
              [37.452637182310198, 11.7984764204359],
              [37.4530279494588, 11.797891661092599],
              [37.4535983796642, 11.797359660907899],
              [37.454242920880603, 11.7973992312051],
              [37.454613475935297, 11.798080718761],
              [37.4552131013875, 11.7986368992858],
              [37.455873363121299, 11.7992524337298],
              [37.4570546477199, 11.7996789103558],
              [37.457387024375002, 11.799039195168101],
              [37.457836182017097, 11.7983950818049],
              [37.458469494292402, 11.798034553528501],
              [37.459048907650597, 11.7978454958282],
              [37.459749593572297, 11.7978279090588],
              [37.460987022876097, 11.798195032636899],
              [37.462130129075199, 11.7993139870982],
              [37.462516404647303, 11.8005692331337],
              [37.462534370953001, 11.8012001514758],
              [37.462509667282703, 11.8023498700181],
              [37.462179536415803, 11.8031060876166],
              [37.461611351998599, 11.8036380766543],
              [37.4592914527774, 11.804275582819001],
              [37.458840049347103, 11.804862526839299],
              [37.459864128771002, 11.805869341745201],
              [37.460055020768898, 11.806438696136],
              [37.459722644113697, 11.8070805923319],
              [37.459154459696499, 11.807669728613501],
              [37.458954584545801, 11.8088809712438],
              [37.459206112825399, 11.809450319378399],
              [37.4604323131882, 11.8094173455804],
              [37.461602368845703, 11.809443724619101],
              [37.461910041830599, 11.8100086750873],
              [37.462453522577398, 11.810569227902601],
              [37.463372049955503, 11.8099691066099],
              [37.4639402343727, 11.809437129859701],
              [37.464443290931797, 11.810630778730101],
              [37.464052523783202, 11.811272665107101],
              [37.463659510846398, 11.8118595941426],
              [37.4636931976695, 11.8130070697484],
              [37.4648744822682, 11.8134357231956],
              [37.4654449124736, 11.812960907029501],
              [37.4660737331725, 11.812483891811601],
              [37.466675604412799, 11.8130422413386],
              [37.467899558987398, 11.8129521141297],
              [37.468474480769302, 11.8125916049979],
              [37.468957325234499, 11.8131521525287],
              [37.469649028003303, 11.8148579685464],
              [37.469682714826398, 11.816007629792599],
              [37.469249277701799, 11.817168277168699],
              [37.469089826738902, 11.8177485990121],
              [37.469631061697598, 11.8183069378067],
              [37.469298685042403, 11.818891653591599],
              [37.468719271684201, 11.8190784985979],
              [37.468139858325898, 11.819267541651101],
              [37.467560444967702, 11.8195115388872],
              [37.4672280683126, 11.820096252099599],
              [37.467535741297397, 11.820661180596201],
              [37.468907917893901, 11.8216019927813],
              [37.469390762359097, 11.8221647200196],
              [37.469642290638603, 11.822731842394001],
              [37.469891573129999, 11.8232989635934],
              [37.4699185225885, 11.8262290710692],
              [37.470226195573296, 11.826796185021299],
              [37.470259882396498, 11.827945796131701],
              [37.471194130291899, 11.8299328754555],
              [37.473145720246698, 11.8307417708695],
              [37.474446031620502, 11.8312231504288],
              [37.475038919707998, 11.8314957119954],
              [37.475548713631703, 11.830906626978299],
              [37.475335363751697, 11.8295899734168],
              [37.474791883004798, 11.829029459543399],
              [37.474484210020002, 11.828464548320801],
              [37.4740417897426, 11.827325929938],
              [37.474066493412899, 11.8261763162231],
              [37.474032806589797, 11.8249717444721],
              [37.474542600513502, 11.8243826454078],
              [37.474816586675097, 11.823742987898299],
              [37.474971546061703, 11.823105527037301],
              [37.475011970249398, 11.8224724609888],
              [37.475036673919803, 11.8212656747781],
              [37.475135488600998, 11.820630406311199],
              [37.475452144738703, 11.8194741699552],
              [37.475961938662401, 11.818882860882299],
              [37.476536860444199, 11.8185245577372],
              [37.477172418507699, 11.818276163257099],
              [37.478513154069297, 11.818126686824201],
              [37.479678718150403, 11.818036561288499],
              [37.480329996731399, 11.8183069378067],
              [37.480720763880001, 11.819674203590401],
              [37.4804602524476, 11.8208326372634],
              [37.480399616165997, 11.822731842394001],
              [37.480240165203, 11.823309954302699],
              [37.479966179041398, 11.8239496128243],
              [37.479748337585001, 11.8245277221608],
              [37.480347963037097, 11.825028895806399],
              [37.4809992416181, 11.8252992654183],
              [37.481558442882502, 11.826435694118],
              [37.481578654976403, 11.8271237037838],
              [37.481827937467699, 11.8276908158817],
              [37.483415709732398, 11.8280513051313],
              [37.484707037953299, 11.828189785631301],
              [37.4858748478227, 11.828156814090001],
              [37.486512651674403, 11.827967777176699],
              [37.487143718161498, 11.827605089709699],
              [37.487718639943303, 11.8271874490007],
              [37.487875845117998, 11.826609345283201],
              [37.487550205827503, 11.825468521055701],
              [37.488174534949998, 11.8247607240575],
              [37.4887472109436, 11.8243452771408],
              [37.489313149572602, 11.823753978589799],
              [37.4902316769506, 11.823156084332201],
              [37.491473597830897, 11.8236396753768],
              [37.493431925150297, 11.8246771950985],
              [37.494797364382201, 11.8253893885629],
              [37.496106658908801, 11.8261587312722],
              [37.496701792784499, 11.8265434018157],
              [37.497301418236702, 11.8270467698815],
              [37.4978628652892, 11.828235945782399],
              [37.497880831594898, 11.828868998497599],
              [37.497721380632001, 11.8294470974405],
              [37.497269977201697, 11.830033987513101],
              [37.496701792784499, 11.8305659242438],
              [37.496504163422003, 11.8318364135717],
              [37.496930863182001, 11.832399119761901],
              [37.498882453136702, 11.8332102059442],
              [37.499473095436002, 11.8334234179018],
              [37.500295053921, 11.8335728859791],
              [37.501521254283801, 11.833482765530499],
              [37.502093930277397, 11.833122283439099],
              [37.503284198028901, 11.831825423204901],
              [37.503733355671002, 11.8311813869389],
              [37.503946705550902, 11.830486793225401],
              [37.504222937500799, 11.8299043003021],
              [37.504791121917997, 11.829315211857701],
              [37.505431171557902, 11.8291833262113],
              [37.506668600861801, 11.8294932573795],
              [37.508617945028398, 11.8301878536159],
              [37.509808212779802, 11.8309615789925],
              [37.511337594551001, 11.8333332974039],
              [37.511243271446197, 11.834082835275099],
              [37.5107918680159, 11.8346697154],
              [37.511101786788899, 11.8352368118521],
              [37.511753065369902, 11.835564320507601],
              [37.512916383662898, 11.8353577043545],
              [37.513493551232898, 11.835113721249201],
              [37.513922496781099, 11.8357335697933],
              [37.514113388779002, 11.836302862076201],
              [37.515144205567502, 11.8375997010746],
              [37.515451878552298, 11.838164593426599],
              [37.515312639683302, 11.839375701110599],
              [37.514558054844599, 11.8436486044065],
              [37.514773650512801, 11.8450223374138],
              [37.514634411643698, 11.846233414703599],
              [37.514477206469003, 11.8468686236944],
              [37.514378391787801, 11.8475016332607],
              [37.514003345156702, 11.848662146988399],
              [37.514605216397001, 11.849279766866999],
              [37.515979638781701, 11.8502754287125],
              [37.516228921273097, 11.850844690697199],
              [37.516752189926102, 11.8527590726799],
              [37.5168599877601, 11.854504203686499],
              [37.515627050032698, 11.854308591320301],
              [37.5134890596565, 11.8531019456475],
              [37.512831043710896, 11.8526008234727],
              [37.512287562963998, 11.852042554703701],
              [37.511492553937501, 11.8508556802991],
              [37.510407838231998, 11.8497369365537],
              [37.509228799421599, 11.849422632476299],
              [37.5086516318515, 11.8496116545522],
              [37.508669598157198, 11.8502424598354],
              [37.508864981731499, 11.850928211660699],
              [37.509347826196702, 11.8514886806236],
              [37.5102147004459, 11.853132716277299],
              [37.5096442702405, 11.8536668059434],
              [37.507822936001901, 11.8533700895912],
              [37.5075691619341, 11.856708679424599],
              [37.507602848757301, 11.857858164695401],
              [37.507212081608699, 11.858499940216401],
              [37.507052630645802, 11.859080174319701],
              [37.506385631547303, 11.8602472323587],
              [37.506641651403299, 11.8610450488232],
              [37.507241276855403, 11.861546155492199],
              [37.507317633654601, 11.8621747353633],
              [37.507221064761602, 11.8628692485015],
              [37.506944832811698, 11.8634516711308],
              [37.506904408623903, 11.864082444424399],
              [37.506329486842098, 11.864385742562],
              [37.505031421256497, 11.863961564638],
              [37.504386880040201, 11.8639220039689],
              [37.503237036476499, 11.8646428863709],
              [37.502659868906399, 11.8648868430937],
              [37.501521254283801, 11.865952777565701],
              [37.501072096641799, 11.866596731836101],
              [37.501523500071997, 11.8680208983369],
              [37.502120879735998, 11.8685219921884],
              [37.502314017521996, 11.869091216104099],
              [37.501682951035001, 11.8695109905082],
              [37.500499420648097, 11.8690252828928],
              [37.499324873414103, 11.8688274831635],
              [37.498875715772101, 11.8694714306443],
              [37.4986017296104, 11.870110981073701],
              [37.498329989237, 11.8708648135018],
              [37.498307531354897, 11.8720735783498],
              [37.498812833702203, 11.873381236103],
              [37.499553943811598, 11.8747987739746],
              [37.500645396881801, 11.8761481752677],
              [37.501013706148299, 11.876712987831301],
              [37.503560429978798, 11.8799194307352],
              [37.503827678775799, 11.8810622251137],
              [37.503865857175398, 11.882383025719299],
              [37.503591871013697, 11.882965406646701],
              [37.502118633947802, 11.884613647802899],
              [37.501492059037098, 11.885204814532999],
              [37.5003534444145, 11.88627066952],
              [37.499776276844401, 11.8865717453274],
              [37.499046395676103, 11.887626606842799],
              [37.498478211258899, 11.888160628926199],
              [37.498026807828602, 11.8887451951085],
              [37.493678961853497, 11.8920811540284],
              [37.492508906195901, 11.8921119202576],
              [37.491848644462102, 11.891498792605001],
              [37.491583641453197, 11.890356042038301],
              [37.491796991333203, 11.8897187367531],
              [37.492459498855297, 11.8863102269443],
              [37.492497677254804, 11.8855630301797],
              [37.4920866980124, 11.883503833188399],
              [37.491837415520997, 11.882936837045399],
              [37.491877839708799, 11.882303909723699],
              [37.492136105352998, 11.881145736822599],
              [37.491927247049396, 11.8800029427945],
              [37.4914444025842, 11.8794403347424],
              [37.490844777132097, 11.878941458661499],
              [37.490249643256298, 11.878554664243101],
              [37.4896545093806, 11.878225009475599],
              [37.489057129716699, 11.8777810737585],
              [37.487882582482698, 11.877583280384201],
              [37.488086949209801, 11.8806226893818],
              [37.488724753061497, 11.882501699669699],
              [37.488875220871599, 11.883646680867701],
              [37.489459125806299, 11.8857014814997],
              [37.489513024723301, 11.8875387018726],
              [37.489434422136, 11.888861668671501],
              [37.489490566841198, 11.890815340439],
              [37.489834172437398, 11.8925887963655],
              [37.4902765927148, 11.893724944830501],
              [37.490761682968298, 11.894344660175101],
              [37.491421944702097, 11.8949006447261],
              [37.492077714859498, 11.8953994915574],
              [37.492562805112897, 11.895962066621999],
              [37.493157938988702, 11.896348836304],
              [37.495024188991401, 11.8961818221906],
              [37.496358187188299, 11.8958016449449],
              [37.496975778946201, 11.892854703878401],
              [37.497853882136397, 11.890876873180201],
              [37.4992440250386, 11.890437353294899],
              [37.499818946820398, 11.8900769464592],
              [37.500391622814, 11.8896594013575],
              [37.500966544595897, 11.889298993491501],
              [37.501231547604696, 11.890384610860901],
              [37.500726245257397, 11.8911449799617],
              [37.499940219383802, 11.8922591585921],
              [37.499547206446998, 11.8928437159697],
              [37.498974530453303, 11.8932041191406],
              [37.498466982317801, 11.8938502065566],
              [37.498190750367897, 11.8944325629466],
              [37.4979729089115, 11.8950127205256],
              [37.497874094230298, 11.8956478158505],
              [37.497833670042503, 11.8962807121385],
              [37.499014954641098, 11.896649900959799],
              [37.502309525945599, 11.8952962061653],
              [37.502963050314797, 11.895682976793999],
              [37.503742338823798, 11.8963510338574],
              [37.504344210064197, 11.896909211856],
              [37.504941589728098, 11.8973531163756],
              [37.506828051824698, 11.897933267724801],
              [37.508197982633, 11.898816677629201],
              [37.509222062056899, 11.899823146112301],
              [37.509377021443399, 11.9011416581383],
              [37.509401725113797, 11.9019459473341],
              [37.509496048218601, 11.9032073141219],
              [37.509770034380203, 11.904635684079],
              [37.510198979928397, 11.9053147065492],
              [37.511270220904699, 11.9059761475184],
              [37.511964169461699, 11.905670698234401],
              [37.512415572892003, 11.905081773465801],
              [37.512986003097403, 11.9045499820933],
              [37.513435160739398, 11.901950342350499],
              [37.513578891184899, 11.9007966481092],
              [37.513558679090998, 11.900108824260601],
              [37.513486813868298, 11.8976409946619],
              [37.513527238056099, 11.8970059039913],
              [37.513978641486297, 11.8964191580052],
              [37.514138092449301, 11.8958412009836],
              [37.515402471211701, 11.8951160060254],
              [37.516217692331999, 11.8949797570599],
              [37.520639649318099, 11.8942281889592],
              [37.5223329736287, 11.896137871091099],
              [37.522815818093903, 11.896700444628401],
              [37.524210452572497, 11.898443096676599],
              [37.524637152332403, 11.899005665445101],
              [37.525953184223702, 11.9000033431331],
              [37.526550563887596, 11.900506575644],
              [37.527871087355301, 11.901620715924],
              [37.528529103300897, 11.9021195504275],
              [37.529373519667899, 11.903016131727201],
              [37.531711385194903, 11.9050202439388],
              [37.532371646928702, 11.9056377360609],
              [37.533570897833002, 11.906637586877499],
              [37.5396188054833, 11.910006287932999],
              [37.5405328412849, 11.912408082550099],
              [37.541089796761099, 11.913779271417001],
              [37.541217806689097, 11.914924120920601],
              [37.541806203200203, 11.915376785611601],
              [37.543620800074102, 11.915875594858299],
              [37.544206950796998, 11.9160975317176],
              [37.544862720954399, 11.917354438032],
              [37.545873325648998, 11.9190661944401],
              [37.547351054291397, 11.9208284792338],
              [37.548240386422698, 11.9221974282276],
              [37.5492015837767, 11.924654048110201],
              [37.549738327158899, 11.925853784619999],
              [37.550160535342499, 11.927053515824101],
              [37.550580497737798, 11.9281983093333],
              [37.551126224272899, 11.929914391896199],
              [37.551636018196596, 11.930650480320599],
              [37.552426535646703, 11.9315052192973],
              [37.553562904481097, 11.934076011804899],
              [37.553978375299998, 11.9346472957182],
              [37.554807071149597, 11.9358425935496],
              [37.555579622293898, 11.9370972111548],
              [37.556235392451299, 11.9383518229521],
              [37.556376877108598, 11.9408192824478],
              [37.556799085292099, 11.9419618206402],
              [37.557156165617499, 11.9425880174129],
              [37.5577535452815, 11.9438448009537],
              [37.558348679157199, 11.945101578663101],
              [37.559062839808099, 11.9463561533919],
              [37.560010562432801, 11.947437148321599],
              [37.560598958943899, 11.9480611353002],
              [37.5614299005817, 11.949201445817099],
              [37.561966643963999, 11.950401073662199],
              [37.5631614032919, 11.9527981163076],
              [37.563875563942801, 11.9541097796027],
              [37.564654852451703, 11.9559948725007],
              [37.565077060635303, 11.9572515938032],
              [37.566029274836403, 11.9590224184576],
              [37.566566018218701, 11.9601626827881],
              [37.567396959856502, 11.961302942311701],
              [37.568349174057701, 11.9630715434294],
              [37.568888163228102, 11.9642711097781],
              [37.569781986935801, 11.9660418884708],
              [37.570320976106302, 11.9672414416405],
              [37.570981237840101, 11.9689550797917],
              [37.571650482726803, 11.9713585488409],
              [37.571843620512901, 11.973076554748999],
              [37.572095148792499, 11.974796746650201],
              [37.572005317264001, 11.977263873767701],
              [37.571724593737798, 11.978415045584599],
              [37.571380988141598, 11.978992826536199],
              [37.570518605468799, 11.980150581604899],
              [37.570296272436003, 11.981299544251],
              [37.569725842230604, 11.9824528957182],
              [37.569265455647503, 11.983030668029301],
              [37.568933078992401, 11.984757387033399],
              [37.569290159317802, 11.985328563916401],
              [37.5709610257463, 11.9874199397255],
              [37.572730706855999, 11.987718706375199],
              [37.575293151203901, 11.988568871428599],
              [37.577244741158701, 11.9906887803717],
              [37.578733698742099, 11.9966991259252],
              [37.579203068478101, 11.997678869848899],
              [37.5805505414042, 11.9996383470151],
              [37.582129330516103, 12.002285375950301],
              [37.583651974922702, 12.0040207584043],
              [37.585291400316201, 12.007364083978301],
              [37.5867556542293, 12.011043451482299],
              [37.588042490873796, 12.014674443682001],
              [37.589740306760802, 12.019574997364099],
              [37.590443238470598, 12.0234914223208],
              [37.590384847977099, 12.025037768466101],
              [37.590912608206501, 12.0284950499902],
              [37.588568005314997, 12.0328682058274],
              [37.5894461085052, 12.0337951036531],
              [37.591143924392199, 12.034834016137101],
              [37.594377859414998, 12.035861942506999],
              [37.5928439860674, 12.0367844371834],
              [37.594775363928299, 12.037654214977699],
              [37.596533816096901, 12.039046732149499],
              [37.597411919287097, 12.042442335244299],
              [37.599754276390499, 12.0434834106478],
              [37.602449222242903, 12.043595424849499],
              [37.602213414480801, 12.0444629842411],
              [37.600573989087302, 12.0467054475554],
              [37.599635249615297, 12.050217357851199],
              [37.600807551061102, 12.0529275811617],
              [37.602213414480801, 12.0566678200766],
              [37.604380600103703, 12.0600697889271],
              [37.6074258889169, 12.065718411409399],
              [37.609534684046302, 12.071821513185199],
              [37.610073673216803, 12.0733060897896],
              [37.610763130197398, 12.075163995072799],
              [37.613399685556303, 12.080926494009301],
              [37.614980720456302, 12.083520017271001],
              [37.6159778504217, 12.087494800927599],
              [37.616680782131503, 12.0908875944065],
              [37.617734056802099, 12.092901290314201],
              [37.618787331472802, 12.0968210365708],
              [37.618145036044602, 12.098435033031601],
              [37.616678536343301, 12.1004991775685],
              [37.615506234897502, 12.1035492513218],
              [37.615214282430202, 12.1065400025745],
              [37.614511350720399, 12.1085513847309],
              [37.613276167204702, 12.1106154511076],
              [37.6129303158203, 12.111201730881699],
              [37.611052836876503, 12.1140782159886],
              [37.608593698786201, 12.117292816103699],
              [37.606013288132601, 12.1198201145525],
              [37.603201561293297, 12.120683035473901],
              [37.602615410570401, 12.1216008465206],
              [37.601854088367098, 12.1228633804804],
              [37.599015412069299, 12.1260229827547],
              [37.598285530901002, 12.1268419701469],
              [37.5977577706715, 12.1272371910449],
              [37.595824147022498, 12.1281593708622],
              [37.594299256827703, 12.1291364388552],
              [37.593713106104801, 12.1298829603019],
              [37.593773742386503, 12.1306887619879],
              [37.593713106104801, 12.1329853932099],
              [37.593362763144, 12.135631105410701],
              [37.592893393407998, 12.1366147327916],
              [37.592307242685102, 12.1400661822831],
              [37.591662701468799, 12.1427118241639],
              [37.591134941239403, 12.144551682441399],
              [37.589551660551102, 12.146569364432199],
              [37.588204187624903, 12.1488680542229],
              [37.585156653023603, 12.152830887330699],
              [37.5848040642745, 12.154620180446001],
              [37.583690153322202, 12.1563414032025],
              [37.5814645772058, 12.156510451265399],
              [37.579295145794703, 12.1562733448611],
              [37.578122844348897, 12.1564467838257],
              [37.576775371422698, 12.157480828976601],
              [37.574900138267097, 12.1582843517321],
              [37.572263582908199, 12.1578189235466],
              [37.5724387543886, 12.159546711813499],
              [37.573786227314798, 12.16075637646],
              [37.574900138267097, 12.160923226324201],
              [37.575192090734497, 12.162653189797499],
              [37.576306001686802, 12.1648463633479],
              [37.5760118034312, 12.166571910598201],
              [37.575250481227897, 12.167660799789401],
              [37.574897892478901, 12.1693863287648],
              [37.573959153007003, 12.171748485119601],
              [37.574329708061697, 12.172220474810199],
              [37.574895646690699, 12.172903211902099],
              [37.575073063959302, 12.1743411251172],
              [37.575129208664599, 12.176411267416899],
              [37.574132078699201, 12.177274004883399],
              [37.5738401262319, 12.178248697274499],
              [37.573312366002398, 12.179521938701299],
              [37.5703838581762, 12.1809532303935],
              [37.568742186994498, 12.1813505661358],
              [37.5677450570291, 12.181923518838399],
              [37.567978619003, 12.183245037213601],
              [37.568681550712803, 12.184750945479401],
              [37.568389598245503, 12.1865927079646],
              [37.568331207752003, 12.1872797984421],
              [37.567976373214798, 12.188715438615899],
              [37.566687290782099, 12.191883039219199],
              [37.564695276639497, 12.194005727529101],
              [37.561647742038097, 12.1942867025779],
              [37.5596534821074, 12.193246215360199],
              [37.557252734510598, 12.1923835298242],
              [37.557019172536698, 12.1930706053005],
              [37.557192098228903, 12.194853042005599],
              [37.557836639445298, 12.1955510866123],
              [37.5595367011205, 12.1972237520588],
              [37.560298023323803, 12.198483731921099],
              [37.560765147271503, 12.200277107562],
              [37.561351297994399, 12.2044762370702],
              [37.561057099738797, 12.209309653449999],
              [37.561113244444101, 12.212248731357899],
              [37.560527093721198, 12.215527992615201],
              [37.5595299637558, 12.2192110769595],
              [37.558649614777401, 12.222261984044801],
              [37.558591224283902, 12.222775586525399],
              [37.558591224283902, 12.2233550342805],
              [37.557886046785903, 12.2246719562752],
              [37.556805822656699, 12.225288712487901],
              [37.555054107852698, 12.2266824445386],
              [37.552179498943502, 12.2286380467305],
              [37.547315121680001, 12.2302929445798],
              [37.543153676126302, 12.229015557496799],
              [37.539987114749799, 12.2303895165482],
              [37.540456484485802, 12.2320663514642],
              [37.540510383402797, 12.2338287725066],
              [37.539634526000803, 12.2353980416241],
              [37.538403834061498, 12.2377596094697],
              [37.535237272685002, 12.239423229162201],
              [37.534076200180301, 12.2393003235803],
              [37.533168901743302, 12.2410034386915],
              [37.532115627072699, 12.242500239553401],
              [37.530828790428203, 12.2434768865681],
              [37.528895166779101, 12.243242052672],
              [37.526671836451001, 12.2427241005366],
              [37.524857239577003, 12.243698552016699],
              [37.524915630070502, 12.244504008070299],
              [37.525501780793398, 12.2456013556875],
              [37.525560171286799, 12.2485312514754],
              [37.524798849083602, 12.250080681343199],
              [37.5241543078672, 12.2512350665206],
              [37.5223981014868, 12.253423117342001],
              [37.521228045829197, 12.2535877142125],
              [37.519063105994498, 12.2519746604533],
              [37.518593736258502, 12.2530675877512],
              [37.520116380665101, 12.2548562039825],
              [37.520233161652001, 12.2566382242894],
              [37.519941209184701, 12.257676267514601],
              [37.520291552145501, 12.258246860359],
              [37.519179886981398, 12.2618130376629],
              [37.517715633068299, 12.2654384189248],
              [37.514964542510697, 12.268995720948899],
              [37.513617069584498, 12.269511427003501],
              [37.511568910736699, 12.268480013884901],
              [37.510749198039903, 12.2687104363148],
              [37.511041150507303, 12.269860350953],
              [37.511041150507303, 12.271008066092801],
              [37.5098710948497, 12.2720416637844],
              [37.508523621923501, 12.2747452377165],
              [37.507479330405701, 12.2768913992092],
              [37.507236785278998, 12.2773873399697],
              [37.506066729621502, 12.277788919635601],
              [37.503957934492, 12.277677004052601],
              [37.501325870709501, 12.278359468927199],
              [37.500153569263702, 12.278877351059799],
              [37.500328740744102, 12.2806043489431],
              [37.499392247060399, 12.2821535902614],
              [37.499917761501699, 12.2843457730878],
              [37.500211959757202, 12.2852059639626],
              [37.498806096337603, 12.2864084709819],
              [37.496346958247301, 12.288539177037901],
              [37.493413958844599, 12.292951943854501],
              [37.4919564422962, 12.2939393753205],
              [37.489789256673198, 12.2950299342076],
              [37.487974659799299, 12.2956070289311],
              [37.483700924835098, 12.296289447292599],
              [37.480655636021901, 12.297671832756199],
              [37.479016210628401, 12.2976630557286],
              [37.478724258161101, 12.2972110384095],
              [37.477376785234902, 12.2973756079118],
              [37.475270235893603, 12.2984112962813],
              [37.473805981980497, 12.2972637006614],
              [37.471171672409803, 12.294953134434],
              [37.4685957533326, 12.2934017742044],
              [37.465200121558702, 12.2915037043302],
              [37.4619235165598, 12.2902924433303],
              [37.459286961200903, 12.290004987455101],
              [37.4566526516303, 12.2893115811502],
              [37.454662883275901, 12.2880454516302],
              [37.452439552947702, 12.2868297860096],
              [37.448808113411701, 12.286430414489599],
              [37.445998632360599, 12.285328848143701],
              [37.4437169115389, 12.2848087843418],
              [37.4417855336781, 12.2843457730878],
              [37.439501567068199, 12.2822172274577],
              [37.437922777956402, 12.280147913501199],
              [37.4352300778922, 12.2784143293709],
              [37.432420596841098, 12.276808010847899],
              [37.429727896777003, 12.275418932420401],
              [37.428030080889997, 12.274613570797801],
              [37.427504566448803, 12.274040818935299],
              [37.425806750561797, 12.272710976474199],
              [37.424636694904201, 12.273057701723401],
              [37.424753475891201, 12.2742054029314],
              [37.424106688886603, 12.275820515088499],
              [37.422878242735599, 12.2771964248318],
              [37.4212388173421, 12.2781707489138],
              [37.419246803199499, 12.278232192653901],
              [37.418368700009303, 12.279548840782599],
              [37.4171402538583, 12.2808150111878],
              [37.414564334780998, 12.281324110916399],
              [37.414036574551602, 12.2806987080944],
              [37.414272382313698, 12.2791977352588],
              [37.412632956920199, 12.2789080728492],
              [37.411054167808302, 12.2778152527071],
              [37.409531523401803, 12.276549067873701],
              [37.408125659982097, 12.275682265386701],
              [37.405318424719198, 12.274817654493701],
              [37.404204513766899, 12.274130791532601],
              [37.403501582057103, 12.273204730646899],
              [37.402508943668202, 12.272513474545899],
              [37.4002833675518, 12.271703715091601],
              [37.396829345284303, 12.2729677287595],
              [37.395189919890797, 12.273428565567],
              [37.3935527402855, 12.2730225903265],
              [37.3902132532168, 12.273599733318701],
              [37.387051183416702, 12.275197293636401],
              [37.385178196049303, 12.277617754607],
              [37.3843000928591, 12.278074194436],
              [37.382721303747203, 12.2773237016058],
              [37.381198659340598, 12.275598876642499],
              [37.381140268847197, 12.274385347982699],
              [37.381315440327597, 12.273700678350901],
              [37.3819015910505, 12.272265499692899],
              [37.382487741773303, 12.271626908509999],
              [37.382606768548499, 12.2695575113252],
              [37.382959357297501, 12.2660528853829],
              [37.382492233349801, 12.2650192641975],
              [37.380385684008502, 12.264437713341],
              [37.378393669866, 12.265409890090201],
              [37.377223614208397, 12.2651246015739],
              [37.376522928286803, 12.2637464342386],
              [37.375997413845603, 12.2609220464998],
              [37.373890864504297, 12.2591378605623],
              [37.373890864504297, 12.257818915841501],
              [37.3745937962141, 12.2572439328834],
              [37.373949254997797, 12.2566074999033],
              [37.372723054635003, 12.2555145872717],
              [37.372253684899, 12.2559183948759],
              [37.368622245362999, 12.257235154507899],
              [37.366866038982501, 12.258093239330099],
              [37.363939776944498, 12.258843786936501],
              [37.361388561537602, 12.2592256436707],
              [37.359701974591701, 12.257968147854699],
              [37.358201788067198, 12.2564582671202],
              [37.357240590713197, 12.254717943283],
              [37.356297359664801, 12.253609660454099],
              [37.355230610264996, 12.2520668352195],
              [37.354577085895698, 12.251755196594599],
              [37.352236974580599, 12.252907379816],
              [37.352412146060999, 12.253820344280999],
              [37.352937660502199, 12.254050779524],
              [37.354458059120603, 12.254401918554899],
              [37.355922313033702, 12.2566448080859],
              [37.357265294383502, 12.259581164960601],
              [37.359547015205102, 12.2622892558428],
              [37.361536783559501, 12.263151713306],
              [37.362648448723597, 12.2636718198778],
              [37.361887126520301, 12.264815172911],
              [37.360539653594103, 12.266199918215801],
              [37.358549885239803, 12.2671194201906],
              [37.357552755274398, 12.268605100371801],
              [37.357494364780898, 12.2701588012601],
              [37.356205282348199, 12.27079300704],
              [37.3545074664612, 12.270674505038],
              [37.3520505741592, 12.2704967519349],
              [37.350235977285202, 12.271299931669301],
              [37.349297237813303, 12.272506891145699],
              [37.346779709229601, 12.272789977204001],
              [37.343967982390303, 12.2737116506347],
              [37.3427395362393, 12.275313599160199],
              [37.340338788642498, 12.2771525362747],
              [37.337468671309701, 12.2772622576536],
              [37.3354901318964, 12.2765073736422],
              [37.334600799765099, 12.276167236243699],
              [37.331557756740203, 12.275471599034701],
              [37.327227877070698, 12.2740254577571],
              [37.325004546742498, 12.2735075660816],
              [37.3214337434882, 12.271839772409599],
              [37.320730811778297, 12.2716642145667],
              [37.315466684213398, 12.268892579616899],
              [37.314002430300299, 12.269298561222501],
              [37.3121878334264, 12.26877407676],
              [37.310022893591601, 12.2670513903214],
              [37.307918590038597, 12.265545950658399],
              [37.305576232935202, 12.2642840959194],
              [37.304821648096599, 12.263774963253301],
              [37.303761636061303, 12.263070515141001],
              [37.302535435698502, 12.261453130265],
              [37.300604057837603, 12.259734785122699],
              [37.298616535271499, 12.256965219319101],
              [37.298032630336799, 12.255181006610499],
              [37.297976485631601, 12.251103387858199],
              [37.297394826485103, 12.248630011441],
              [37.2963415518145, 12.248507110144899],
              [37.294760516914401, 12.248046229774801],
              [37.294556150187297, 12.246933529564499],
              [37.294412419741803, 12.246154417157699],
              [37.2934759260582, 12.245118523297901],
              [37.294120467274503, 12.243277168034201],
              [37.295409549707202, 12.2412602218469],
              [37.2962315081922, 12.240055314108499],
              [37.297637371611799, 12.239313492038301],
              [37.298865817762902, 12.2393112972953],
              [37.298515474802102, 12.237645482141099],
              [37.298984844537998, 12.2363198457113],
              [37.2996293857544, 12.235637272041499],
              [37.299981974503403, 12.233967044145899],
              [37.300392953745899, 12.232244130458501],
              [37.301154275949202, 12.2312081820443],
              [37.3015091104864, 12.228905816050499],
              [37.299400315356898, 12.228910205709299],
              [37.297293766015699, 12.229421600451801],
              [37.295948538877703, 12.2284361221458],
              [37.296359518120198, 12.2266056248531],
              [37.296125956146298, 12.2253369993905],
              [37.296361763908401, 12.2240749524502],
              [37.296040616194297, 12.222924838341299],
              [37.295708239539202, 12.2217549652333],
              [37.294785220584799, 12.220104403756199],
              [37.294023898381496, 12.220154886507199],
              [37.292970623710801, 12.2192988733386],
              [37.293148040979503, 12.2178524242803],
              [37.292622526538203, 12.2170534717898],
              [37.291625396572897, 12.2172839391019],
              [37.290574367690397, 12.217683415300799],
              [37.290104997954501, 12.2174441686387],
              [37.288759770816498, 12.2162435422151],
              [37.288467818349197, 12.214568801926401],
              [37.287825522920997, 12.2129028308897],
              [37.287300008479797, 12.2114607368227],
              [37.286365760584303, 12.2098562058813],
              [37.284962142952899, 12.207948754944301],
              [37.2853910885011, 12.206486878758399],
              [37.285667320450898, 12.206054460382299],
              [37.286956402883703, 12.204561843304701],
              [37.2886519729824, 12.204274294048499],
              [37.289941055415099, 12.203585052576001],
              [37.288773245545798, 12.201861941049801],
              [37.287367382126099, 12.2015721938072],
              [37.286197326468603, 12.200770997131],
              [37.2849688803175, 12.2012868363647],
              [37.283212673937101, 12.2005888068638],
              [37.2823368165351, 12.1996690733618],
              [37.280463829167701, 12.199260789686299],
              [37.279349918215402, 12.2009268465372],
              [37.278296643544699, 12.2021933939464],
              [37.276832389631601, 12.202764106571401],
              [37.274784230783801, 12.2023624128135],
              [37.273966763875301, 12.2008653848105],
              [37.274847112853699, 12.199660293074301],
              [37.276369757260298, 12.197245702978799],
              [37.275141311109302, 12.1975815518273],
              [37.274555160386399, 12.198791043174101],
              [37.2734996399276, 12.2000576007914],
              [37.271509871573201, 12.2006327081445],
              [37.270514987396098, 12.2010344045247],
              [37.268936198284202, 12.200571246349501],
              [37.268408438054799, 12.198960064211301],
              [37.269288787033197, 12.197120582710699],
              [37.2695245947953, 12.1960274241726],
              [37.268118731375701, 12.1962557146815],
              [37.266890285224598, 12.196600345173399],
              [37.264664709108203, 12.1976891111724],
              [37.2636114344376, 12.197853742738699],
              [37.262735577035599, 12.1963018117796],
              [37.259753170292299, 12.1949737731225],
              [37.256885298747797, 12.1934723131408],
              [37.254720358912998, 12.193015727133],
              [37.254603577926098, 12.194163776030701],
              [37.254250989177102, 12.196347908869701],
              [37.252728344770503, 12.197034974072199],
              [37.2512079461521, 12.196229373478999],
              [37.250332088750099, 12.194442555796799],
              [37.249921109507603, 12.195417185116399],
              [37.250271452468397, 12.1968022940433],
              [37.250619549641002, 12.197781304862101],
              [37.250325351385499, 12.199677853649],
              [37.250266960891999, 12.2009970884932],
              [37.250089543623403, 12.202432654388801],
              [37.2495033929005, 12.2032953072178],
              [37.247630405533101, 12.2043247798184],
              [37.245815808659202, 12.205189621512201],
              [37.243179253300298, 12.206963196660199],
              [37.241598218400199, 12.207830224743301],
              [37.239258107085099, 12.208284590225499],
              [37.237620927479803, 12.2068358859685],
              [37.2366844337961, 12.2062607920813],
              [37.235453741856901, 12.2083855602268],
              [37.234925981627498, 12.2088530947309],
              [37.233755925969902, 12.2095335425341],
              [37.231824548109003, 12.2100537546753],
              [37.229542827287403, 12.2101020443581],
              [37.227258860677502, 12.2097003617287],
              [37.224276453934202, 12.2080804551053],
              [37.223400596532201, 12.2066997951614],
              [37.223286061333503, 12.2041140565413],
              [37.222818937385703, 12.2028453232682],
              [37.222235032451103, 12.2028519084046],
              [37.220067846828101, 12.2033062824265],
              [37.219425551400001, 12.203817726649399],
              [37.218953935875803, 12.2047923214907],
              [37.218837154888902, 12.2056593566803],
              [37.218134223179, 12.2065219990016],
              [37.217431291469197, 12.2070948973113],
              [37.216553188279001, 12.207496583892899],
              [37.215032789660597, 12.207496583892899],
              [37.214076083883, 12.2068753961897],
              [37.213159802293198, 12.206280547235499],
              [37.211814575155302, 12.206561509269701],
              [37.210291930748703, 12.206506633895801],
              [37.208421189169499, 12.2053564434429],
              [37.207078207819698, 12.2030516574668],
              [37.205438782426199, 12.2022460751633],
              [37.204854877491599, 12.2028211777663],
              [37.2031570616046, 12.2037321201745],
              [37.202045396440496, 12.2029287349848],
              [37.201346956307098, 12.200564661156299],
              [37.202285695778997, 12.198382758146],
              [37.2032805799561, 12.197577161649001],
              [37.205270348310499, 12.197932765854199],
              [37.206849137422303, 12.197932765854199],
              [37.207904657881102, 12.1971271679894],
              [37.208432418110597, 12.195864986575399],
              [37.208962424128202, 12.193048654034801],
              [37.208904033634703, 12.191782062930001],
              [37.207677833271902, 12.1899403364745],
              [37.206860366363401, 12.188678120850399],
              [37.205573529718798, 12.1880985972302],
              [37.204226056792699, 12.189013980550801],
              [37.203698296563303, 12.1904474161905],
              [37.202817947584798, 12.1913715719671],
              [37.200594617256598, 12.192515237721199],
              [37.199716514066402, 12.193549142439799],
              [37.197666109430401, 12.194293287927],
              [37.196615080548, 12.193889386210801],
              [37.195853758344697, 12.1938849959714],
              [37.193980770977298, 12.1939991421728],
              [37.192285200878501, 12.193246215360199],
              [37.189886699069902, 12.1924362129909],
              [37.188835670187501, 12.1912903517548],
              [37.1877846413051, 12.188355431718101],
              [37.187081709595198, 12.1888098305875],
              [37.186906538114798, 12.189731796916],
              [37.186259751110299, 12.190649369746801],
              [37.184153201769, 12.191619622731899],
              [37.182163433414701, 12.1921947483912],
              [37.180346590752599, 12.193105727321701],
              [37.179996247791799, 12.1939157276459],
              [37.178823946346, 12.1954084046881],
              [37.177299056151199, 12.197298385179201],
              [37.176070610000203, 12.1977593539865],
              [37.1746086018752, 12.198220321992199],
              [37.172964684905303, 12.199827118486599],
              [37.1719698007281, 12.2019453530761],
              [37.172142726420297, 12.203383108875],
              [37.173955077506101, 12.2052291319789],
              [37.174244784185198, 12.2061532362157],
              [37.173483461981903, 12.2077577895944],
              [37.172369551029597, 12.2089606495],
              [37.172250524254402, 12.210578355757301],
              [37.173067991163002, 12.2119568005083],
              [37.173827067578102, 12.213218905252001],
              [37.173885458071503, 12.213853247798699],
              [37.172421204158397, 12.2150626648433],
              [37.170898559751798, 12.215578476239299],
              [37.170076601266899, 12.216664969295],
              [37.1706021157081, 12.2190815772473],
              [37.171183774854498, 12.2212655045929],
              [37.171882214987903, 12.224316387982901],
              [37.173103923774299, 12.2289782454102],
              [37.173045533280899, 12.230758245557301],
              [37.1722235747959, 12.2315571566459],
              [37.171812595553398, 12.232369234123601],
              [37.172805233942398, 12.2342106654296],
              [37.171397124734497, 12.2365064009091],
              [37.170635802531201, 12.237024365238099],
              [37.169813844046303, 12.2388635693534],
              [37.1694028648038, 12.2404152506936],
              [37.168405734838402, 12.241909860168001],
              [37.1662969397089, 12.2432793627442],
              [37.164071363592498, 12.2454060281453],
              [37.162429692410797, 12.2461434437271],
              [37.160850903299, 12.246433142141999],
              [37.159094696918501, 12.247523897715901],
              [37.158447909913903, 12.249073333497799],
              [37.157801122909397, 12.2513118790433],
              [37.157154335904799, 12.2528064268206],
              [37.1575046788656, 12.254535790187299],
              [37.159431565150001, 12.257875975150601],
              [37.160597129231199, 12.260114445996701],
              [37.160770054923397, 12.262765473162499],
              [37.159831315451498, 12.264828340095599],
              [37.158715158710997, 12.2663754796998],
              [37.155377917430499, 12.267347649300699],
              [37.154021461351398, 12.267384955963299],
              [37.145020342204603, 12.2662394195599],
              [37.142037935461303, 12.264683501029401],
              [37.140050412895199, 12.2631231842239],
              [37.1370680061519, 12.260698200956],
              [37.135141119867498, 12.2585145996528],
              [37.133151351513099, 12.257300992317001],
              [37.1304025067437, 12.2560895739998],
              [37.127831079242902, 12.2538927668077],
              [37.124965453486602, 12.251939546248201],
              [37.122977930920499, 12.250840030336301],
              [37.121340751315202, 12.2496812541173],
              [37.120464893913201, 12.2482481393694],
              [37.120467139701397, 12.2465209294778],
              [37.120002261541799, 12.245423585683101],
              [37.118890596377703, 12.2435273648549],
              [37.117900203776998, 12.241567483414199],
              [37.117666641803098, 12.241168043307001],
              [37.115793654435699, 12.241508225854099],
              [37.112278995886598, 12.242954545468001],
              [37.0778780120813, 12.221432316392701],
              [37.077282878205502, 12.220927490886501],
              [37.076694481694403, 12.2206531287943],
              [37.075389678744202, 12.2197619987558],
              [37.0740848757941, 12.2189279334387],
              [37.073496479283001, 12.218653569272799],
              [37.072252312614502, 12.2178765684114],
              [37.071661670315201, 12.217545135146301],
              [37.071005900157701, 12.217042497150899],
              [37.070356867365, 12.21665399464],
              [37.069763979277397, 12.216265491558699],
              [37.068863418205098, 12.215372151801599],
              [37.068036968143701, 12.2147487876295],
              [37.067554123678498, 12.214127616941401],
              [37.066536781619199, 12.213001604168401],
              [37.065943893531703, 12.2125538316762],
              [37.0647491342039, 12.2114278122089],
              [37.064207899245197, 12.2107495642537],
              [37.063078267775403, 12.2097969412144],
              [37.062597669098402, 12.2092328287206],
              [37.0618700337183, 12.2080409450639],
              [37.061423121864401, 12.206324447573],
              [37.0611760851613, 12.2057559376396],
              [37.060742648036701, 12.2046145240506],
              [37.060434975051898, 12.203986744480201],
              [37.059943147433799, 12.2028475183137],
              [37.059157121560197, 12.201655605924699],
              [37.058910084857096, 12.201087085972],
              [37.058606903448698, 12.2005185647996],
              [37.058038719031501, 12.198687874436001],
              [37.057852318610003, 12.1981193481167],
              [37.057605281906902, 12.1975486254884],
              [37.057239218428599, 12.1968659518074],
              [37.056933791231998, 12.1962974215801],
              [37.0567473908106, 12.1957266950291],
              [37.056266792133499, 12.1951625525769],
              [37.055539156753397, 12.193970605627101],
              [37.055175339063297, 12.193344995967699],
              [37.0547508850916, 12.1927215799615],
              [37.054140030698399, 12.1915274268995],
              [37.053412395318297, 12.190276194576599],
              [37.052626369444702, 12.189029346670701],
              [37.052599419986102, 12.187876885207301],
              [37.052583699468698, 12.1872446756493],
              [37.0524422148114, 12.186098791985399],
              [37.052064922392098, 12.184898023525401],
              [37.0508274930882, 12.1843492244309],
              [37.049648454277801, 12.1838596946808],
              [37.049053320402102, 12.18335479758],
              [37.048689502712001, 12.1827862383905],
              [37.047890002109099, 12.181021282413701],
              [37.047411649220301, 12.180457108686999],
              [37.0471062220237, 12.1798885432902],
              [37.046859185320599, 12.179260705182299],
              [37.046612148617498, 12.1786899419776],
              [37.046248330927398, 12.1780664915364],
              [37.045576840252501, 12.176758118949],
              [37.0453298035494, 12.1761873503643],
              [37.045022130564597, 12.175504621715101],
              [37.044835730143099, 12.1749316551596],
              [37.044588693439998, 12.174360882649101],
              [37.044036229540303, 12.173223724529601],
              [37.043730802343703, 12.172598065958899],
              [37.043353509924302, 12.1714565096818],
              [37.043108719009403, 12.1708857297035],
              [37.042686510825902, 12.1702622609413],
              [37.042192437419601, 12.1690658115074],
              [37.0416983640133, 12.1678671613509],
              [37.041276155829799, 12.1672436855055],
              [37.041029119126698, 12.166672896476999],
              [37.040723691930097, 12.1661043015721],
              [37.040420510521699, 12.165535705450701],
              [37.040103854384, 12.1648902705415],
              [37.039501983143701, 12.1636564756392],
              [37.0391965559471, 12.1630307945412],
              [37.0388911287505, 12.162464387224601],
              [37.038583455765703, 12.161836507936201],
              [37.038221883863798, 12.1612700980752],
              [37.037858066173698, 12.1607036870075],
              [37.037132676581798, 12.1595686658488],
              [37.036827249385198, 12.158940779722201],
              [37.036638603175497, 12.158369972866099],
              [37.035780712079202, 12.156604854682399],
              [37.035475284882601, 12.1559791569916],
              [37.035041847758002, 12.154839724784599],
              [37.034383831812399, 12.154196459360101],
              [37.033867300524001, 12.151930747594299],
              [37.033076783074002, 12.1505673594142],
              [37.032876907923303, 12.1494191220822],
              [37.032802796912399, 12.148789016507999],
              [37.0327646185128, 12.1470633538841],
              [37.032748897995297, 12.146371768394699],
              [37.032910594746397, 12.145735069054099],
              [37.032879153711498, 12.1444133641823],
              [37.032852204252997, 12.143205820773201],
              [37.033130681991103, 12.142564722774299],
              [37.033220513519503, 12.141354975428399],
              [37.033382210270602, 12.1407753486186],
              [37.033467550222603, 12.139449227990999],
              [37.033689883255398, 12.137730091589001],
              [37.0336786543144, 12.1358748169975],
              [37.033492253892902, 12.135303960678],
              [37.033245217189801, 12.134735298743699],
              [37.033056570980101, 12.134164439983],
              [37.032620888067299, 12.132965632605],
              [37.032254824589003, 12.132282793025],
              [37.032066178379402, 12.1316548425423],
              [37.031574350761304, 12.1305131106018],
              [37.031327314058203, 12.1299444384455],
              [37.0300472147783, 12.129121069273999],
              [37.030285268328598, 12.1276653463567],
              [37.030314463575301, 12.1264555313691],
              [37.030534550819901, 12.125875872152299],
              [37.031046590531901, 12.125287428931401],
              [37.0312666777765, 12.1247077671757],
              [37.031300364599701, 12.1235550269857],
              [37.0313901961281, 12.1224022818138],
              [37.031643970195802, 12.120669861129601],
              [37.032214400401301, 12.1200836021527],
              [37.032492878139301, 12.1194995376213],
              [37.032769110089198, 12.118860578342501],
              [37.033164368814198, 12.118276511134001],
              [37.033384456058798, 12.1176968341442],
              [37.034992440417398, 12.117202790261301],
              [37.034868922065797, 12.116592370334001],
              [37.034747649502499, 12.115995123586099],
              [37.034103108286097, 12.115894118489299],
              [37.033460812858003, 12.1158523989816],
              [37.032870170558702, 12.115577928373799],
              [37.031623758101901, 12.11462935578],
              [37.031145405213103, 12.114062845539801],
              [37.030898368510002, 12.1134941383143],
              [37.030300988846101, 12.112929821452701],
              [37.029881026450802, 12.1123655033983],
              [37.029517208760701, 12.111796792557],
              [37.029025381142603, 12.110657171433701],
              [37.028836734933002, 12.1100862611425],
              [37.028472917242901, 12.109462650045],
              [37.0282124058105, 12.1083164314831],
              [37.028026005389002, 12.1077455161878],
              [37.0275903224763, 12.1066036819361],
              [37.027168114292699, 12.1059229707329],
              [37.026718956650697, 12.10420581412],
              [37.026458445218303, 12.1030046762718],
              [37.025730809838102, 12.1017530277965],
              [37.025542163628501, 12.1011820984747],
              [37.025297372713503, 12.100613363822299],
              [37.024801053519099, 12.099357312347999],
              [37.024598932580098, 12.0980968631516],
              [37.024571983121596, 12.0969462039918],
              [37.024603424156503, 12.095793343950699],
              [37.024767366695897, 12.0952158140992],
              [37.025045844433997, 12.094633891123999],
              [37.025542163628501, 12.0934129467277],
              [37.0257038603796, 12.0928332157819],
              [37.0258655571307, 12.092255679537001],
              [37.026146280657002, 12.091673750120099],
              [37.0267144650742, 12.0910259404119],
              [37.027226504786199, 12.0904396163741],
              [37.027561127229497, 12.089798391610501],
              [37.028073166941503, 12.089212064882],
              [37.028526816159903, 12.0886257368684],
              [37.029099492153598, 12.088151403153001],
              [37.029669922358998, 12.087562876818501],
              [37.030296497269703, 12.0868579611095],
              [37.030873664839703, 12.086614204778],
              [37.031450832409703, 12.086315548069001],
              [37.032607413338098, 12.085885130461],
              [37.033186826696301, 12.085641373243201],
              [37.0337752232074, 12.0859158745988],
              [37.0343658655067, 12.0861903756728],
              [37.034788073690301, 12.086756944998999],
              [37.034974474111699, 12.0873257091237],
              [37.0351631203214, 12.087898864026201],
              [37.035466301729798, 12.0884676257234],
              [37.036061435605497, 12.088915607058199],
              [37.036690256304396, 12.088324886415],
              [37.036663306845902, 12.087174185208699],
              [37.036099614005103, 12.0854591041875],
              [37.035852577301902, 12.084890336095199],
              [37.035491005400097, 12.0843237628167],
              [37.034318703954298, 12.0841195326199],
              [37.033141910932102, 12.083684719423699],
              [37.031971855274499, 12.083539781534601],
              [37.031383458763401, 12.0833223745539],
              [37.030795062252302, 12.0831071634293],
              [37.030143783671399, 12.0827184653474],
              [37.029077034271502, 12.081936675405499],
              [37.028538045101001, 12.0813722919215],
              [37.0279406654371, 12.080753005951101],
              [37.027347777349497, 12.0803072070076],
              [37.026170984327301, 12.0798745836778],
              [37.025575850451602, 12.079369489565501],
              [37.024980716575897, 12.0788643945003],
              [37.024502363687098, 12.0783000045394],
              [37.023963374516597, 12.077735613388899],
              [37.0234468432282, 12.076580474484601],
              [37.022968490339501, 12.075983138343901],
              [37.0227663694005, 12.0752803882372],
              [37.022454204839299, 12.0746852452986],
              [37.021892757786702, 12.0736091532332],
              [37.021580593225501, 12.0730140065812],
              [37.021376226498298, 12.0716370385946],
              [37.021347031251601, 12.070372066551601],
              [37.021203300806199, 12.0692234843323],
              [37.020884398880298, 12.068022189440001],
              [37.0209180857034, 12.066869204817101],
              [37.020886644668501, 12.065547109678199],
              [37.020641853753602, 12.064978299298099],
              [37.020336426557002, 12.064409487711099],
              [37.020322951827701, 12.063774789187301],
              [37.0203543928627, 12.062623982495399],
              [37.0205160896138, 12.062046381091699],
              [37.021364997557299, 12.0608758010468],
              [37.021396438592198, 12.059665683846401],
              [37.020902365185997, 12.058466542359399],
              [37.020307231310198, 12.0579636041325],
              [37.020060194607098, 12.057335479083701],
              [37.019696376916997, 12.0567688475331],
              [37.019509976495598, 12.0561978222789],
              [37.012105612766199, 12.0167809678297],
              [37.012628881419197, 12.016176908889101],
              [37.013021894356001, 12.0155926178606],
              [37.013414907292798, 12.0150061289739],
              [37.013924701216602, 12.0144218354042],
              [37.014564750856501, 12.014232928189299],
              [37.016112098933398, 12.013595914511001],
              [37.0167746064554, 12.0116497185578],
              [37.016992447911797, 12.011067614326601],
              [37.017562878117303, 12.010478918956499],
              [37.018072672041001, 12.009892418931299],
              [37.019332559227003, 12.008884163093899],
              [37.020495877519899, 12.0086820721452],
              [37.0206979984588, 12.007528832851801],
              [37.0203027397338, 12.0058132429774],
              [37.019995066748997, 12.005246502820601],
              [37.019307855556697, 12.0040163650451],
              [37.019442602849303, 12.0021579676842],
              [37.0195054849192, 12.000147984482201],
              [37.019456077578504, 11.9983686425833],
              [37.018957512595897, 11.9971736211848],
              [37.0182411061568, 11.996617846560801],
              [37.018517338106598, 11.996035709856701],
              [37.018910351043402, 11.9954513751464],
              [37.019186582993299, 11.994869235924099],
              [37.018997936783599, 11.9942980792394],
              [37.0184544560368, 11.993737905162099],
              [37.016624138645398, 11.993039333506101],
              [37.016026758981397, 11.9925933890186],
              [37.0156404834093, 11.991934356406301],
              [37.015256453625298, 11.9900275463127],
              [37.015092511085903, 11.988309648259399],
              [37.015061070050997, 11.987160715452699],
              [37.015009416922098, 11.9852648557855],
              [37.016091886839497, 11.9842037836702],
              [37.016855454831003, 11.9821167766565],
              [37.017583090211197, 11.980890927120599],
              [37.017976103148001, 11.980308756485],
              [37.018939546290198, 11.979190545969599],
              [37.019727817952003, 11.9780218023287],
              [37.020030999360401, 11.9762928433439],
              [37.020057948818902, 11.975141662481899],
              [37.020203925052598, 11.9719209662999],
              [37.020826008386798, 11.968974852476199],
              [37.020796813140102, 11.967825837410199],
              [37.021373980710102, 11.967524851776799],
              [37.021050587207903, 11.966384618491],
              [37.020399308626899, 11.966114389088199],
              [37.019806420539297, 11.965841962424999],
              [37.018611661211501, 11.964892860938001],
              [37.017356265601897, 11.963893224982399],
              [37.016815030643201, 11.963275865546199],
              [37.016370364577597, 11.962021369253801],
              [37.016089641051302, 11.9606943650909],
              [37.015496752963799, 11.9577920700508],
              [37.015231749954999, 11.956649598662899],
              [37.015040857957104, 11.9560783611599],
              [37.014659073961298, 11.9549402766936],
              [37.014611912408903, 11.95321776137],
              [37.014773609160102, 11.952639925329899],
              [37.015790951219302, 11.951407350795099],
              [37.016008792675699, 11.9508273137778],
              [37.016462441894198, 11.9502428812836],
              [37.0176055480933, 11.949293725070801],
              [37.018928317349101, 11.9479446871352],
              [37.018602678058599, 11.9471998571213],
              [37.017967119995099, 11.9453784205215],
              [37.017816652184997, 11.9441172497634],
              [37.017901992136999, 11.942908805374399],
              [37.018045722582499, 11.941755285246799],
              [37.019460569155001, 11.939997530845901],
              [37.019678410611398, 11.939417469391101],
              [37.0202308745111, 11.938252948327699],
              [37.020432995450001, 11.937042280558099],
              [37.021102240336702, 11.935875552054901],
              [37.021553643767, 11.9352339590992],
              [37.021623263201498, 11.933509121193101],
              [37.0213986843805, 11.9317337351739],
              [37.021248216570399, 11.9304725009146],
              [37.020271298698901, 11.9289453888643],
              [37.019846844727098, 11.9283828821147],
              [37.019420144967199, 11.927761047123701],
              [37.018997936783599, 11.927196340614],
              [37.017619022822501, 11.9259680449633],
              [37.0170216431586, 11.9255219898889],
              [37.016597189186797, 11.924959476040099],
              [37.015689890749897, 11.9238344448436],
              [37.015483278234498, 11.9226896329195],
              [37.015451837199599, 11.9215404214663],
              [37.0152250125903, 11.919593562660401],
              [37.014477165116297, 11.919503470662001],
              [37.013480035150899, 11.9199253646405],
              [37.013042106449902, 11.9210855696998],
              [37.013248718965301, 11.922228191046999],
              [37.012103366978003, 11.92306098319],
              [37.011202805905697, 11.9221644680601],
              [37.010836742427401, 11.9215997499014],
              [37.010513348925102, 11.920459323266799],
              [37.009922706625801, 11.920243982054201],
              [37.009556643147498, 11.9196792598994],
              [37.0095656263004, 11.9178400593677],
              [37.009138926540402, 11.917275332211799],
              [37.009120960234803, 11.9165875500812],
              [37.009147909693297, 11.915436115393801],
              [37.008422520101298, 11.914480245102499],
              [37.0078273862256, 11.914093501223901],
              [37.007503992723301, 11.912950845637701],
              [37.007369245430702, 11.9123223830161],
              [37.007117717151203, 11.911696116369599],
              [37.006982969858498, 11.911010517543],
              [37.005577106438899, 11.90874934761],
              [37.004436246028099, 11.9076286441663],
              [37.003841112152301, 11.9072418905325],
              [37.003239240912002, 11.9066244020556],
              [37.002756396446799, 11.906061849054399],
              [37.002197195182397, 11.9048114829396],
              [37.001465068225798, 11.9036819732789],
              [37.000962011666701, 11.902372263593101],
              [37.000519591389299, 11.9011768183714],
              [37.000155773699198, 11.9006120565762],
              [36.999731319727502, 11.9000494911314],
              [36.999190084768799, 11.899486924522501],
              [36.997431632600097, 11.899300134570799],
              [36.996993703899101, 11.900462625243501],
              [36.997591083563101, 11.900906523962099],
              [36.9981862174388, 11.901293286611301],
              [36.998727452397503, 11.9018558494824],
              [36.999109236393203, 11.902994156730699],
              [36.998862199690102, 11.9047235835235],
              [36.998235624779397, 11.9053147065492],
              [36.997658457209397, 11.905558626828199],
              [36.997440615753, 11.906138760666201],
              [36.997123959615301, 11.907468229373601],
              [36.997045357028, 11.9088460355336],
              [36.996652344091203, 11.9094327546958],
              [36.996077422309298, 11.9097909384303],
              [36.994900629287102, 11.909590970882199],
              [36.994478421103601, 11.9090262265722],
              [36.994723212018499, 11.907299024626701],
              [36.995174615448803, 11.9067101033871],
              [36.995509237892101, 11.9061299707688],
              [36.996079668097501, 11.9055959839687],
              [36.995338557988099, 11.904066534488001],
              [36.9948534677347, 11.903444643804001],
              [36.994429013763003, 11.9028820842209],
              [36.994002314002998, 11.902260190826899],
              [36.993461079044302, 11.901699826302799],
              [36.993211796552998, 11.9011306705645],
              [36.993088278201398, 11.898722183672101],
              [36.993818159369802, 11.897553092927399],
              [36.994312232775997, 11.8963927873694],
              [36.994606431031599, 11.8943160917683],
              [36.9943414280228, 11.8931184135518],
              [36.994076425014001, 11.8919734721987],
              [36.9939843476973, 11.890771388472499],
              [36.993964135603399, 11.890024203955299],
              [36.9943414280228, 11.888808925932199],
              [36.994851221946497, 11.8882199646486],
              [36.995246480671497, 11.887635397338199],
              [36.996820778206903, 11.885413590580701],
              [37.001826640127703, 11.882415990710699],
              [37.004270057700502, 11.8819500851284],
              [37.005545665404, 11.881574283346801],
              [37.0061812234675, 11.881330341561901],
              [37.008101372387301, 11.8809918994443],
              [37.011018651272501, 11.8807479571377],
              [37.0116070477835, 11.880903992332],
              [37.011696879311998, 11.8820511779168],
              [37.011842855545602, 11.883196161009201],
              [37.012033747543498, 11.8837675519224],
              [37.013899997546297, 11.883549983985301],
              [37.014535555609797, 11.8854289740726],
              [37.015676416020597, 11.8865475713697],
              [37.0160424794989, 11.887171698315599],
              [37.017520208141299, 11.887765057113199],
              [37.018685772222398, 11.8875628757422],
              [37.019260694004203, 11.8872618010311],
              [37.019516713860199, 11.8859344311808],
              [37.019676164823203, 11.8853564518888],
              [37.019471798095999, 11.884211477881101],
              [37.018928317349101, 11.883593937117899],
              [37.02064634533, 11.882286328388201],
              [37.022063437690697, 11.882824756272701],
              [37.0227079789071, 11.88286651186],
              [37.023866805623598, 11.8824907113432],
              [37.024441727405403, 11.8821918286904],
              [37.025135675962403, 11.881829213266901],
              [37.026281027949601, 11.8809940971217],
              [37.027437608877896, 11.8805062122939],
              [37.028014776448003, 11.8802051297794],
              [37.029272417845803, 11.879196391045999],
              [37.029851831203999, 11.8789524471312],
              [37.030444719291502, 11.8793392409848],
              [37.031185829400897, 11.8807567478552],
              [37.0314351118923, 11.8813237485376],
              [37.031917956357503, 11.8819456897889],
              [37.032663558043303, 11.8813501206336],
              [37.032710719595698, 11.8788227831597],
              [37.032627625431999, 11.877847004851301],
              [37.033202547213797, 11.8775459193974],
              [37.032995934698398, 11.8720933580937],
              [37.033959377840702, 11.870976899195099],
              [37.0340604383101, 11.870341746324099],
              [37.034145778262101, 11.869190115891],
              [37.034172727720602, 11.8680406783749],
              [37.035327062860702, 11.867495627916201],
              [37.035904230430802, 11.867249475739101],
              [37.037642470505503, 11.8666318965307],
              [37.038206163346302, 11.865928601778499],
              [37.038484641084402, 11.8653461844389],
              [37.038760873034299, 11.8647659636637],
              [37.039335794816097, 11.864405522863599],
              [37.041078526467302, 11.8645132155916],
              [37.042250827913101, 11.864102224748001],
              [37.042277777371602, 11.862952765785099],
              [37.042259811065897, 11.862319792050901],
              [37.042536043015801, 11.861739564837601],
              [37.043171601079301, 11.8614362637577],
              [37.044393309865697, 11.8612318650136],
              [37.044828992778498, 11.860073602570999],
              [37.045408406136801, 11.8598845877399],
              [37.046636852287797, 11.8599109619103],
              [37.047086009929799, 11.859209847681599],
              [37.047068043624201, 11.858579063121301],
              [37.047050077318502, 11.8579482771028],
              [37.047077026777004, 11.856798792211601],
              [37.047238723528103, 11.8562207511346],
              [37.046798549038897, 11.855082248398899],
              [37.047191561975701, 11.8544976100135],
              [37.047409403432098, 11.8539173661673],
              [37.046868168473502, 11.853356902190299],
              [37.045792435920703, 11.8525216988347],
              [37.045484762935899, 11.851954638258],
              [37.045336540914001, 11.850809523968],
              [37.045244463597399, 11.8495479145645],
              [37.044878400119103, 11.848983045747399],
              [37.043694869732299, 11.848437957220799],
              [37.043104227432998, 11.846097141302],
              [37.0435399103458, 11.8449388146442],
              [37.0438767785773, 11.844356352514501],
              [37.044269791514097, 11.843771691165699],
              [37.044779585437901, 11.8431826326016],
              [37.045936166366197, 11.8427518277731],
              [37.046138287305098, 11.841597882922001],
              [37.046562741276901, 11.8405846054252],
              [37.046796303250701, 11.840028510104601],
              [37.046897363720198, 11.839395483224299],
              [37.046589690735402, 11.838828395398901],
              [37.045911462695898, 11.8375227701192],
              [37.0458934963902, 11.8368919354685],
              [37.046037226835601, 11.8357379658772],
              [37.0464706639602, 11.834465296623099],
              [37.047045585742097, 11.834104815826899],
              [37.047687881170198, 11.8340894294408],
              [37.048967980450101, 11.833882812173201],
              [37.050885883581699, 11.833434408204401],
              [37.051999794533998, 11.8315682484956],
              [37.051961616134399, 11.830697809223601],
              [37.052907092970898, 11.830453821960999],
              [37.053549388399098, 11.830436237284999],
              [37.054782326126499, 11.8306340648247],
              [37.055429113131098, 11.830790128671699],
              [37.056215139004699, 11.829563596319099],
              [37.056790060786597, 11.8292053071568],
              [37.057416635697201, 11.828614019108],
              [37.057993803267301, 11.8283128793503],
              [37.0591593673484, 11.828224955271001],
              [37.061032354715799, 11.8282930964349],
              [37.0616229970151, 11.8285656609211],
              [37.0622720298079, 11.8287217259481],
              [37.062840214225098, 11.828189785631301],
              [37.0634128902187, 11.827714995053499],
              [37.064167475057403, 11.8275809105282],
              [37.065910206708601, 11.827191845222],
              [37.066608646841999, 11.827059958552001],
              [37.067890991910097, 11.826912685028701],
              [37.070943018087902, 11.8273523072515],
              [37.071535906175399, 11.827736976117],
              [37.071960360147102, 11.828301888842001],
              [37.072533036140698, 11.8278270984587],
              [37.072692487103701, 11.827248996092599],
              [37.073611014481699, 11.826593958475501],
              [37.074199410992797, 11.8267500246273],
              [37.074778824351, 11.8265631848576],
              [37.074453185060499, 11.825424558562499],
              [37.074203902569202, 11.8248552436384],
              [37.074423989813802, 11.8242749368597],
              [37.074998911595699, 11.823916640772101],
              [37.076155492524002, 11.8234836074512],
              [37.0767304143058, 11.823125310327701],
              [37.077305336087598, 11.822821966384099],
              [37.077880257869502, 11.8224636683944],
              [37.078457425439503, 11.822219673789499],
              [37.079665659496698, 11.8214986794518],
              [37.080391049088597, 11.8202721054531],
              [37.080781816237199, 11.819575285877001],
              [37.081058048187003, 11.818992769728499],
              [37.081743013591201, 11.8162758101157],
              [37.081592545781099, 11.815016239597201],
              [37.0815611047461, 11.813866574189801],
              [37.081588054204701, 11.8127169039573],
              [37.081439832182802, 11.811571625372199],
              [37.080846944095299, 11.811241889767199],
              [37.079683625802303, 11.811444127652001],
              [37.079131161902602, 11.812606992592499],
              [37.078679758472298, 11.8131939187694],
              [37.077500719661899, 11.8128202205999],
              [37.077294107146599, 11.811677140682001],
              [37.077318810816898, 11.8104703069009],
              [37.077478261779802, 11.8098899696381],
              [37.077502965450101, 11.8086831279858],
              [37.077473770203397, 11.8075356342727],
              [37.078030725679596, 11.8065442134221],
              [37.0786236137671, 11.806873954679499],
              [37.079234468160301, 11.805708867128001],
              [37.079393919123198, 11.805130718070799],
              [37.080027231398503, 11.804770198649701],
              [37.081248940184899, 11.8045657575479],
              [37.081893481401302, 11.8046075250972],
              [37.083236462751003, 11.804574550716699],
              [37.083827105050297, 11.804787784974],
              [37.085816873404703, 11.8049108892119],
              [37.086461414620999, 11.8049504584195],
              [37.088183934178304, 11.8038732946187],
              [37.0882849946478, 11.803237985821401],
              [37.088235587307203, 11.8014595530212],
              [37.089306828283497, 11.7999954698968],
              [37.0899378947706, 11.799577787092099],
              [37.090571207045897, 11.7992744170772],
              [37.091476259694602, 11.7981576607981],
              [37.092042198323597, 11.7974541899417],
              [37.092376820766901, 11.796871626773299],
              [37.092711443210298, 11.7962890623677],
              [37.0941262897828, 11.7946446852209],
              [37.095352490145601, 11.794554551964101],
              [37.096001522938401, 11.794824951645801],
              [37.096650555731102, 11.795040391851799],
              [37.097243443818698, 11.7953679485757],
              [37.098404516323399, 11.7950513836946],
              [37.099033337022298, 11.794576535688],
              [37.099673386662197, 11.794444633318101],
              [37.100320173666802, 11.794600717782201],
              [37.1009153075425, 11.7949876310007],
              [37.102154982634602, 11.795471271756499],
              [37.102857914344398, 11.7954536848349],
              [37.103459785584803, 11.7961285821443],
              [37.104962217897501, 11.7976410495639],
              [37.105503452856098, 11.7982016276667],
              [37.106222105083397, 11.798872121540001],
              [37.106877875240798, 11.799256830399401],
              [37.107526908033599, 11.7995294237789],
              [37.108175940826399, 11.799742661959399],
              [37.108717175785102, 11.800303235767],
              [37.109200020250299, 11.800866006750701],
              [37.110035453464498, 11.8014749412407],
              [37.110688977833703, 11.801861844763],
              [37.111991534995703, 11.8024597854963],
              [37.112680991976298, 11.8041656680688],
              [37.113139132771202, 11.8059924454975],
              [37.1125664567775, 11.8064101185306],
              [37.1119892892075, 11.8066541272186],
              [37.111713057257603, 11.807236669604499],
              [37.111805134574197, 11.808441319365301],
              [37.110578934211397, 11.8084720950198],
              [37.110210624944898, 11.8079071413839],
              [37.110414991672101, 11.8067530495978],
              [37.109815366219898, 11.80624964423],
              [37.108647556350597, 11.8062804201306],
              [37.108382553341698, 11.8051373129338],
              [37.108245560260897, 11.804394290699801],
              [37.1074572885991, 11.8033786771698],
              [37.1068801210291, 11.8036248868559],
              [37.106305199247203, 11.803985407782999],
              [37.105123914648601, 11.8035545412539],
              [37.1045332723493, 11.8033413060374],
              [37.102716429687199, 11.8030994927047],
              [37.101568831911699, 11.803875492916401],
              [37.101175818974902, 11.8044602395002],
              [37.101674383957601, 11.8056539100458],
              [37.1021010837175, 11.806216670047],
              [37.102586173970998, 11.8068937391423],
              [37.102952237449202, 11.807458694867201],
              [37.1055640891378, 11.811013272848101],
              [37.106103078308301, 11.8115738236082],
              [37.106352360799598, 11.8121431661475],
              [37.106386047622799, 11.8132906405655],
              [37.1060693914851, 11.8145634082919],
              [37.105793159535303, 11.8151437356548],
              [37.105242941423803, 11.8163659362309],
              [37.104937514227103, 11.8180959122544],
              [37.104661282277299, 11.818676232131899],
              [37.1039942831788, 11.8199006151111],
              [37.1035024555608, 11.8211777484346],
              [37.102929779567098, 11.8215932001591],
              [37.102478376136901, 11.8221823052272],
              [37.102026972706597, 11.822767012735101],
              [37.101456542501197, 11.8232967654515],
              [37.100378564160202, 11.8244749669868],
              [37.099096219092203, 11.826747826513101],
              [37.100940011212799, 11.8279084283514],
              [37.101539636665002, 11.828466746421199],
              [37.101456542501197, 11.829675698966801],
              [37.101609256099501, 11.830935202027099],
              [37.102208881551597, 11.8314957119954],
              [37.1029185506261, 11.831821027058],
              [37.103625973912301, 11.832034240098601],
              [37.103933646897097, 11.832601342016201],
              [37.104140259412503, 11.833801484057],
              [37.104146996777096, 11.8361006425594],
              [37.104528780772903, 11.837296373182101],
              [37.104607383360197, 11.8379843555267],
              [37.105047557849502, 11.8391207315178],
              [37.105472011821199, 11.839685620726399],
              [37.1060626541205, 11.839958173856999],
              [37.107549365915702, 11.8387119004674],
              [37.108277001295903, 11.8375447503944],
              [37.108611623739201, 11.8369622725052],
              [37.108768828913902, 11.8363248424495],
              [37.108970949852797, 11.835113721249201],
              [37.109067518745903, 11.8344235336283],
              [37.1092853602023, 11.8337838995963],
              [37.109619982645597, 11.8332036117573],
              [37.111362714296803, 11.832812356383],
              [37.112739382469698, 11.8339839227705],
              [37.113332270557201, 11.8343136309801],
              [37.114473130968101, 11.835432437874699],
              [37.114958221221499, 11.8360544837356],
              [37.115268139994498, 11.836678726215601],
              [37.116460653534197, 11.837566730667801],
              [37.117004134281103, 11.8381843756279],
              [37.117659904438497, 11.8386855242496],
              [37.118962461600503, 11.8392833845614],
              [37.1194453060657, 11.8398460754249],
              [37.120590658052897, 11.841081353648001],
              [37.121812366839301, 11.8430023980111],
              [37.123483233267798, 11.8442816214344],
              [37.123790906252601, 11.844848697943],
              [37.124098579237398, 11.8454135753105],
              [37.124289471235301, 11.845982847428999],
              [37.124307437540999, 11.8466136610935],
              [37.124222097588998, 11.847767584758801],
              [37.124298454388097, 11.8484555407386],
              [37.124889096687397, 11.8486115944098],
              [37.126043431827497, 11.8481236516484],
              [37.126409495305801, 11.8486885222431],
              [37.127424591576897, 11.849407246952801],
              [37.127918664983099, 11.8482467363912],
              [37.128486849400304, 11.8476576874769],
              [37.128644054575098, 11.8470202823699],
              [37.128729394526999, 11.8458136044906],
              [37.128709182433198, 11.8451234438902],
              [37.128500324129597, 11.8438640061987],
              [37.128116294345602, 11.842727649931],
              [37.127909681830303, 11.8415253490829],
              [37.127716544044198, 11.8408967216706],
              [37.127157342779803, 11.8397054028176],
              [37.126568946268698, 11.8395471460479],
              [37.125978303969397, 11.839331740853],
              [37.125385415881901, 11.839004236710901],
              [37.125353974847002, 11.8378546720862],
              [37.125380924305503, 11.8367051026271],
              [37.128033200181903, 11.835432437874699],
              [37.128426213118701, 11.8347884101036],
              [37.128217354815099, 11.8336454219284],
              [37.127619975151198, 11.833144264068],
              [37.1263960205766, 11.8332321865661],
              [37.125239439648198, 11.833663006398],
              [37.124073875567099, 11.833808078229501],
              [37.122903819909503, 11.8337817015386],
              [37.122313177610202, 11.833566291800899],
              [37.121724781099097, 11.833410229538099],
              [37.120642311181797, 11.832287018230399],
              [37.1201392546227, 11.831036313714099],
              [37.119402636089703, 11.8297328493185],
              [37.1190365726114, 11.8291679395484],
              [37.118342624054399, 11.827347911032801],
              [37.1178440590717, 11.826211486121601],
              [37.117594776580397, 11.825644370957701],
              [37.117345494089001, 11.8250750564908],
              [37.117195026278999, 11.823815526453901],
              [37.117314053054102, 11.8218020248618],
              [37.117469012440601, 11.8210502551863],
              [37.120518792830197, 11.812927933654001],
              [37.120736634286601, 11.8123476015968],
              [37.121421599690699, 11.8117562771377],
              [37.122385042833002, 11.8106417699478],
              [37.122831954686802, 11.8098811766398],
              [37.124087350296399, 11.808815023507],
              [37.124992402945097, 11.8077005043546],
              [37.125268634895001, 11.807117962954599],
              [37.125601011550103, 11.806421109917601],
              [37.1259940244869, 11.8058363675165],
              [37.1265644546923, 11.805363736468699],
              [37.127013612334402, 11.8047196379613],
              [37.128039937546497, 11.8037721729017],
              [37.128255533214698, 11.8031346655664],
              [37.132902069021803, 11.799914131716299],
              [37.134828955306197, 11.7998635701325],
              [37.136113546162498, 11.799830595181501],
              [37.137341992313601, 11.7998569751426],
              [37.139331760667901, 11.7999207267048],
              [37.141674117771203, 11.800089998022299],
              [37.142958708627503, 11.8001141796305],
              [37.145000130110702, 11.799947106657299],
              [37.146340865672201, 11.799797620226499],
              [37.147508675541602, 11.799766843598301],
              [37.155386900583302, 11.799335970440699],
              [37.156869120802099, 11.800100989662701],
              [37.158214347940103, 11.8001229729421],
              [37.159449531455699, 11.8004373336453],
              [37.160677977606802, 11.800463713548],
              [37.161973797404102, 11.800830833598001],
              [37.163911912629601, 11.8011847632408],
              [37.165268368708603, 11.801609038546101],
              [37.165861256796099, 11.8018816295875],
              [37.166573171658797, 11.8023234902968],
              [37.167168305534503, 11.8026510383232],
              [37.168403489050199, 11.8029653961283],
              [37.170851398199403, 11.802727979078099],
              [37.171486956262903, 11.802481768586601],
              [37.175437297724798, 11.8015760638049],
              [37.177833553745202, 11.8015716671725],
              [37.1796279385253, 11.8030775096639],
              [37.180867613617401, 11.803563334455101],
              [37.183690569397697, 11.804119503860599],
              [37.185810593468197, 11.804697655050401],
              [37.187867735468799, 11.805104338616999],
              [37.190221321513199, 11.80567589288],
              [37.192408719230102, 11.8066541272186],
              [37.194418699678302, 11.8074059364052],
              [37.196372535421297, 11.8083314062855],
              [37.198506034220998, 11.809366785749299],
              [37.2003947421059, 11.8100086750873],
              [37.203224435250903, 11.8109143520067],
              [37.203761178633101, 11.8112067179459],
              [37.205420816120601, 11.812121183832],
              [37.207909149457599, 11.813376371219899],
              [37.209148824549601, 11.8138050240892],
              [37.2166115787725, 11.817286979464001],
              [37.219403093517897, 11.818880662704901],
              [37.221534346529403, 11.819861048062799],
              [37.224754806823, 11.822191097830499],
              [37.225412822768597, 11.822690077608099],
              [37.227371150087997, 11.8237891487996],
              [37.231287804726797, 11.82587077754],
              [37.232478072478202, 11.826699467996299],
              [37.232808203345101, 11.828013937365199],
              [37.233059731624699, 11.8286403962972],
              [37.233776138063803, 11.829139364314999],
              [37.234348814057398, 11.8287217259481],
              [37.2347979716995, 11.8281348330603],
              [37.235283061952899, 11.828695348766701],
              [37.2357703979945, 11.8294317107925],
              [37.236365531870298, 11.8298163767342],
              [37.2366844337961, 11.8286601791875],
              [37.236944945228501, 11.8275039767545],
              [37.2371448203792, 11.826292816494499],
              [37.238353054436402, 11.825628984095699],
              [37.238847127842597, 11.8244661744568],
              [37.239181750286001, 11.823883668715901],
              [37.239756672067799, 11.8235803256128],
              [37.240394475919501, 11.823393483679],
              [37.241023296618401, 11.8228593348591],
              [37.241414063767003, 11.822217475638899],
              [37.241746440422098, 11.8215756149137],
              [37.242123732841499, 11.820417184384601],
              [37.242341574297903, 11.8198368681971],
              [37.242977132361403, 11.8195335206095],
              [37.243552054143201, 11.819230172685799],
              [37.244131467501497, 11.819043327783],
              [37.245306014735498, 11.819186209190701],
              [37.2465591645568, 11.8180607413132],
              [37.247134086338598, 11.817702437092301],
              [37.247709008120502, 11.8173397360232],
              [37.249712251204102, 11.8174826183199],
              [37.249842506920302, 11.8163087830881],
              [37.249864964802399, 11.815042618096699],
              [37.250534209689, 11.8138753670602],
              [37.251041757824602, 11.813231288558301],
              [37.252488045432003, 11.812734489771501],
              [37.253658101089599, 11.8127037145958],
              [37.255412061681803, 11.8127740578495],
              [37.256651736773897, 11.813143359635101],
              [37.258300145320199, 11.813558823548799],
              [37.259589227752898, 11.8137566632863],
              [37.260238260545698, 11.813910538538799],
              [37.262290910969902, 11.8142007030656],
              [37.262933206398102, 11.814128161962699],
              [37.264199830948698, 11.813464300068301],
              [37.264828651647598, 11.812930131879201],
              [37.265461963922903, 11.8125674244904],
              [37.266670197979998, 11.8119629111086],
              [37.267896398342799, 11.8118727835447],
              [37.269493153760401, 11.8125784156304],
              [37.271997207614902, 11.812224500699299],
              [37.2747505439607, 11.8103713858634],
              [37.2753209741661, 11.809839409894099],
              [37.2758914043715, 11.8093645874955],
              [37.276464080365102, 11.8088897642742],
              [37.276913238007197, 11.8082456740526],
              [37.277362395649298, 11.807544427346199],
              [37.278029394747698, 11.8063771443669],
              [37.279105127300397, 11.805143907796699],
              [37.279556530730702, 11.804556964378801],
              [37.2804570918031, 11.80326876206],
              [37.2812992623819, 11.8020399081324],
              [37.282624277426002, 11.8014309748968],
              [37.283318225983002, 11.8011254086121],
              [37.284771250955004, 11.8008000570858],
              [37.286109740728399, 11.800650571119901],
              [37.2873943315847, 11.8006175962635],
              [37.288097263294503, 11.8006000096719],
              [37.289309988927997, 11.8001053863187],
              [37.289644611371401, 11.7995228287809],
              [37.290780980205803, 11.7983445199411],
              [37.291412046692898, 11.797926834622199],
              [37.292611297597198, 11.7969177922015],
              [37.293329949824503, 11.795519635785199],
              [37.293824023230698, 11.794356698369601],
              [37.293981228405499, 11.7937213675295],
              [37.294257460355297, 11.793138796435199],
              [37.295701502174602, 11.7925254466636],
              [37.2962764239564, 11.7921671090659],
              [37.29748914959, 11.7916175106143],
              [37.29806631716, 11.791371290151],
              [37.298688400494299, 11.7906656034923],
              [37.299299254887501, 11.7895004470945],
              [37.3003233343114, 11.7884979689528],
              [37.300889272940303, 11.787906593069099],
              [37.302068311750801, 11.7861522437342],
              [37.302968872823101, 11.7849233131508],
              [37.303303495266398, 11.784340723385601],
              [37.304455584618303, 11.783793307725301],
              [37.305684030769299, 11.7837603308434],
              [37.3063263261975, 11.7837427431715],
              [37.306966375837398, 11.783613034056099],
              [37.307536806042798, 11.7831931278558],
              [37.308262195634804, 11.7819685809778],
              [37.308639488054098, 11.780807784642199],
              [37.309131315672097, 11.7796469834018],
              [37.309407547622001, 11.779064382445901],
              [37.309742170065299, 11.778481780254699],
              [37.309942045216097, 11.777894779816499],
              [37.310391202858099, 11.7765690775839],
              [37.310548408032801, 11.7759908671445],
              [37.310764003701003, 11.7753510967617],
              [37.310923454664, 11.774772883759899],
              [37.310903242570099, 11.7740253822592],
              [37.310710104784, 11.773398798552799],
              [37.310341795517502, 11.772776610511899],
              [37.310719087936803, 11.771617973905601],
              [37.311112100873601, 11.771033157369599],
              [37.311561258515702, 11.7704461410243],
              [37.311978975122798, 11.768654304354399],
              [37.312192325002798, 11.767957353686199],
              [37.311240110801599, 11.7652244985376],
              [37.3106966300547, 11.7646660517472],
              [37.310101496179001, 11.7642219318369],
              [37.309528820185399, 11.764637469794399],
              [37.309254834023697, 11.765277265104899],
              [37.3088034305934, 11.765864292482901],
              [37.308720336429701, 11.767073520961199],
              [37.308911228427498, 11.767642955803501],
              [37.308929194733203, 11.768273949792301],
              [37.308960635768202, 11.769423806195199],
              [37.308392451350997, 11.7699536619252],
              [37.3078175295691, 11.7703142270664],
              [37.306600312359102, 11.7706352175869],
              [37.305434748278003, 11.7707803227678],
              [37.303685279262197, 11.770940817802799],
              [37.302459078899297, 11.7709715976619],
              [37.301881911329303, 11.7712178364101],
              [37.301280040088898, 11.770602239125999],
              [37.300664694119298, 11.7694677776639],
              [37.300689397789597, 11.768317921444799],
              [37.302014412833699, 11.767651750154799],
              [37.302652216685402, 11.7674626715408],
              [37.303806551825502, 11.766915222262799],
              [37.304141174268899, 11.766330395725999],
              [37.304359015725304, 11.7657521637546],
              [37.304338803631403, 11.7650618015582],
              [37.304379227819098, 11.7644286015873],
              [37.304361261513499, 11.7637975987776],
              [37.304314099961097, 11.762076075041501],
              [37.3036358719215, 11.7608844137335],
              [37.303094636962904, 11.7603259581344],
              [37.302901499176798, 11.759699343219401],
              [37.302766751884199, 11.7590133631503],
              [37.302730819272803, 11.7578062979164],
              [37.3027150987553, 11.757175279922301],
              [37.302739802425599, 11.756025372331999],
              [37.302899253388603, 11.755447118707799],
              [37.303114849056797, 11.754807300544901],
              [37.3035056162053, 11.7541652821997],
              [37.304134436904199, 11.7536331973683],
              [37.304466813559401, 11.752991176285599],
              [37.305039489553003, 11.752575620745899],
              [37.305374111996301, 11.7519907637387],
              [37.304695883956803, 11.7507440907098],
              [37.304078292199002, 11.7496117461198],
              [37.303983969094098, 11.7482925038339],
              [37.304022147493697, 11.7476020978528],
              [37.304435372524402, 11.7456957768136],
              [37.304653213980799, 11.7450581347683],
              [37.305216906821599, 11.7443523292667],
              [37.305430256701598, 11.7436003475846],
              [37.305452714583701, 11.7423932148047],
              [37.305596445029103, 11.741241046710501],
              [37.304341049419598, 11.7402383926946],
              [37.303696508203203, 11.7401988141718],
              [37.302526452545699, 11.740170229679601],
              [37.300891518728598, 11.739840408400999],
              [37.3006602025429, 11.738151717273301],
              [37.300390707957703, 11.7368961863076],
              [37.300312105370303, 11.736207950620599],
              [37.300514226309197, 11.735051358984499],
              [37.300671431483899, 11.7344752602003],
              [37.301300252182799, 11.7339409384967],
              [37.301940301822803, 11.7338661773528],
              [37.3031103574803, 11.7338353933464],
              [37.303617905615901, 11.733248297710899],
              [37.304179352668399, 11.7324281208173],
              [37.304821648096599, 11.7324105298652],
              [37.305398815666599, 11.732166455289899],
              [37.305556020841301, 11.731530980833099],
              [37.305773862297698, 11.7309482780551],
              [37.306108484741102, 11.7303655740466],
              [37.306232003092703, 11.7285229019519],
              [37.3068001875099, 11.7279907676254],
              [37.308024142084498, 11.727843440998299],
              [37.312533684810703, 11.7281842711356],
              [37.313903615618997, 11.7270584306294],
              [37.314801930903201, 11.7257698653519],
              [37.315486896307299, 11.7251761557766],
              [37.316113471218003, 11.724644014998001],
              [37.316692884576199, 11.7244571058035],
              [37.317270052146299, 11.7241536529593],
              [37.317941542821103, 11.7231003611513],
              [37.318332309969698, 11.722458268975601],
              [37.318606296131399, 11.721875547044201],
              [37.319214904736398, 11.72059575343],
              [37.319706732354398, 11.7194346984224],
              [37.320333307265102, 11.7188431742027],
              [37.320784710695399, 11.718256046680001],
              [37.320944161658304, 11.7176777138551],
              [37.320560131874402, 11.7164814626489],
              [37.320133432114403, 11.7158591460945],
              [37.319396813581399, 11.714616707791199],
              [37.318806171282098, 11.7144012046497],
              [37.318264936323402, 11.7138404558919],
              [37.318051586443502, 11.7125254406163],
              [37.318076290113801, 11.7113181719193],
              [37.3184064209807, 11.710618877256801],
              [37.318738797635802, 11.7099789551191],
              [37.319309227841202, 11.709503960409601],
              [37.32000093061, 11.709141116678801],
              [37.320692633378798, 11.7087782724718],
              [37.322615028086801, 11.708556167600999],
              [37.323097872551998, 11.709116927079799],
              [37.323522326523701, 11.709679884471299],
              [37.323771609015097, 11.710247238807399],
              [37.324535177006602, 11.710343996795],
              [37.325709724240603, 11.710599085872699],
              [37.325961252520102, 11.711223613310599],
              [37.3264463427736, 11.711843741271601],
              [37.327737670994502, 11.712039455126501],
              [37.328966117145498, 11.712063644469801],
              [37.329763371960198, 11.7134798168738],
              [37.330414650541101, 11.7137502961815],
              [37.331571231469397, 11.713259914802601],
              [37.332213526897597, 11.713244521650999],
              [37.332801923408702, 11.7133984531278],
              [37.333167986886998, 11.7139636008146],
              [37.333938292243097, 11.7143462293316],
              [37.3345828334595, 11.7143880105744],
              [37.335341909874501, 11.7143682194602],
              [37.339642594297302, 11.713563379615],
              [37.341028245623001, 11.7129520516088],
              [37.341717702603603, 11.712589212404399],
              [37.342292624385401, 11.712228571753499],
              [37.342510465841798, 11.711650226312599],
              [37.342903478778602, 11.7110630835053],
              [37.343390814820303, 11.7097304626183],
              [37.343958999237501, 11.7091960930323],
              [37.344468793161198, 11.7086089450122],
              [37.345095368071902, 11.708017397618701],
              [37.346368729987098, 11.7076391584731],
              [37.347770101830299, 11.707544598605899],
              [37.349047955322, 11.707340085294099],
              [37.350316825660798, 11.706728743524501],
              [37.351161242027899, 11.705558833923901],
              [37.352311085591602, 11.7048969091944],
              [37.352802913209601, 11.7037335891364],
              [37.352436849731298, 11.703168420536899],
              [37.352418883425699, 11.7025372775843],
              [37.352868041067701, 11.701892938519901],
              [37.353258808216303, 11.701250797071699],
              [37.353049949912801, 11.7001072538158],
              [37.352859057914898, 11.699537679546101],
              [37.3528410916092, 11.698847151639001],
              [37.353447454425996, 11.697624429206799],
              [37.354855563633798, 11.6977607762205],
              [37.355769599435398, 11.697103231131999],
              [37.355848202022798, 11.6957221614449],
              [37.355767353647202, 11.69285224141],
              [37.355169973983301, 11.690338562869499],
              [37.354480517002699, 11.688748533952699],
              [37.353939282044003, 11.688130553445401],
              [37.352771472174702, 11.688161342542401],
              [37.352180829875401, 11.6879480180139],
              [37.351592433364303, 11.6877918731518],
              [37.351004036853197, 11.6876335289764],
              [37.350460556106299, 11.6870749251901],
              [37.3501506373333, 11.6864503432446],
              [37.350132671027602, 11.685816962932201],
              [37.349490375599402, 11.6837078840217],
              [37.348479770904802, 11.6831602686238],
              [37.347904849122997, 11.6834637664479],
              [37.3473299273411, 11.6837672639396],
              [37.347011025415298, 11.684924070170799],
              [37.346795429747097, 11.6855618510083],
              [37.345636603030599, 11.685880740876399],
              [37.345057189672303, 11.686067676145701],
              [37.344484513678701, 11.686426151662101],
              [37.343907346108601, 11.6866724658584],
              [37.343312212232902, 11.6862854005944],
              [37.342146648151697, 11.686430550131799],
              [37.341569480581697, 11.6866768643242],
              [37.34114502661, 11.686113860134],
              [37.340893498330402, 11.6855464563157],
              [37.340469044358699, 11.684983449828],
              [37.339759375284203, 11.6846557619315],
              [37.337870667399301, 11.684017979008001],
              [37.336689382800699, 11.6835319434496],
              [37.335505852413903, 11.682988726228301],
              [37.332388698377997, 11.6822651680326],
              [37.331162498015203, 11.6822981570492],
              [37.3299273144995, 11.682043041885301],
              [37.327566991090499, 11.681185325387901],
              [37.325462687537403, 11.6791157984992],
              [37.324382463408298, 11.678110719823501],
              [37.323789575320802, 11.677780824496599],
              [37.322572358110797, 11.6781569051379],
              [37.322064809975302, 11.6787463170464],
              [37.322096251010201, 11.679894347090899],
              [37.322147904139001, 11.681730745416701],
              [37.3218941300713, 11.6830613018702],
              [37.321256326219498, 11.6831910582733],
              [37.320030125856697, 11.6832240471796],
              [37.318862315987403, 11.683254836822],
              [37.318586084037499, 11.6838376401221],
              [37.318617525072398, 11.684985649074299],
              [37.318947655939397, 11.686298596009999],
              [37.318803925493903, 11.6874509932174],
              [37.318413158345301, 11.688035986911601],
              [37.317838236563503, 11.6883966590998],
              [37.317263314781599, 11.6887573308183],
              [37.316342541615398, 11.6892499548456],
              [37.314808668267801, 11.6887045496205],
              [37.314215780180298, 11.688434045824099],
              [37.313672299433399, 11.687816064615101],
              [37.313247845461603, 11.6872530627403],
              [37.3128817819833, 11.686690059721199],
              [37.312288893895797, 11.6863579753726],
              [37.311922830417501, 11.6857949705342],
              [37.311958763028898, 11.685045028718299],
              [37.312690889985497, 11.684050967816001],
              [37.313261320190897, 11.6835759286031],
              [37.313537552140801, 11.682993124752601],
              [37.312922206171102, 11.6818605024429],
              [37.311731938419697, 11.6811457384085],
              [37.312183341849902, 11.680556331602601],
              [37.312753772055402, 11.680081286399],
              [37.312976105088197, 11.6776070794667],
              [37.312825637278102, 11.676404057094601],
              [37.312794196243097, 11.6752560125957],
              [37.312717839443998, 11.674624806028],
              [37.312410166459202, 11.674059578021399],
              [37.311174982943498, 11.6738044552754],
              [37.311684776867303, 11.673217232200701],
              [37.312257452860898, 11.6728565402575],
              [37.312832374642703, 11.672498047190601],
              [37.314168618627797, 11.6722297269232],
              [37.313557764234602, 11.671270809747799],
              [37.312385462788903, 11.671187234240501],
              [37.311152525061402, 11.670932108852201],
              [37.3106449769259, 11.671576519941199],
              [37.310427135469503, 11.672157148445701],
              [37.310034122532699, 11.672742174421501],
              [37.309699500089401, 11.6733249998244],
              [37.309063942025801, 11.6735163422368],
              [37.307891640580102, 11.6733733852745],
              [37.306723830710702, 11.6734041760111],
              [37.305556020841301, 11.6734349667442],
              [37.304379227819098, 11.673179843423499],
              [37.303802060249097, 11.6734239700542],
              [37.303224892679097, 11.673613113061799],
              [37.302003183892701, 11.673815451950301],
              [37.300821899294, 11.673388780643201],
              [37.301080164938199, 11.6722297269232],
              [37.301059952844298, 11.671484147112499],
              [37.301041986538699, 11.670852931959899],
              [37.301259827994997, 11.6702723007253],
              [37.302414163135097, 11.669724658788001],
              [37.302921711270699, 11.6691374270758],
              [37.302438866805502, 11.668574387263099],
              [37.301205929078002, 11.668319259471801],
              [37.300038119208601, 11.6683522501477],
              [37.299672055730397, 11.667787009358801],
              [37.299930321374603, 11.666630131624901],
              [37.300264943817901, 11.6660472921562],
              [37.300422148992602, 11.6654094664291],
              [37.2992071775708, 11.663775306239099],
              [37.298535686896003, 11.662704189292899],
              [37.298342549109897, 11.662132338717599],
              [37.298151657112001, 11.6615626863962],
              [37.296898507290599, 11.6618090226786],
              [37.2957711216091, 11.6620289656032],
              [37.294592082798701, 11.6616572619591],
              [37.294003686287603, 11.6614989028589],
              [37.292838122206398, 11.6616462648023],
              [37.292588839715101, 11.6610766114835],
              [37.291995951627598, 11.6608060807336],
              [37.291398571963597, 11.6603046084024],
              [37.291030262697099, 11.659682165608601],
              [37.290531697714499, 11.6585450491985],
              [37.2903183478345, 11.6572297714114],
              [37.290495765103103, 11.6552128534072],
              [37.290794454935103, 11.653365282077599],
              [37.290931448015897, 11.652043381240199],
              [37.291600692902499, 11.650873240808799],
              [37.292966132134403, 11.6495755229135],
              [37.2935410539163, 11.6492148002527],
              [37.295643111681102, 11.649216999782499],
              [37.298153902900196, 11.6491510138798],
              [37.298903996162402, 11.6489024668388],
              [37.301006053927303, 11.648788091134699],
              [37.301765130342403, 11.648768295335],
              [37.302739802425599, 11.648110634076],
              [37.303307986842803, 11.647578345228499],
              [37.305055210070499, 11.6474177785579],
              [37.3054257651252, 11.6480974368441],
              [37.305733438110003, 11.6486627177126],
              [37.305776108086, 11.6502133850092],
              [37.305969245871999, 11.650785260126],
              [37.307318964586401, 11.651036004998399],
              [37.307339176680301, 11.651781639729499],
              [37.307476169761202, 11.6524106987429],
              [37.308066812060503, 11.6526812376708],
              [37.309358140281397, 11.652934180332601],
              [37.309919587334001, 11.6541834937538],
              [37.310566374338499, 11.654339657536401],
              [37.311139050332201, 11.653923953751899],
              [37.311354646000296, 11.6532839006769],
              [37.3116892684437, 11.6527010331917],
              [37.312082281380498, 11.6521159649769],
              [37.311426511223097, 11.6516738637416],
              [37.310808919465202, 11.6504839260765],
              [37.310615781679203, 11.6498570622259],
              [37.310350778670298, 11.6487155065288],
              [37.310966124639997, 11.6477785035511],
              [37.312684152620797, 11.6465269617826],
              [37.313366872236799, 11.645933082346501],
              [37.313759885173603, 11.645350199444],
              [37.314260695944498, 11.644853098342701],
              [37.316144912252902, 11.6429306724445],
              [37.318226757923902, 11.6421850139706],
              [37.320194068396098, 11.6415031416396],
              [37.320771235966099, 11.641256787353999],
              [37.321458447158498, 11.6407772757081],
              [37.322089513645601, 11.640359352039701],
              [37.322731809073701, 11.6403417552399],
              [37.323901864731297, 11.6404253400291],
              [37.324548651735803, 11.640581511541599],
              [37.325121327729498, 11.640163587579],
              [37.325397559679303, 11.639582892188599],
              [37.325480653843101, 11.638430296231499],
              [37.325741165275502, 11.6372754958744],
              [37.326073541930597, 11.6366332048394],
              [37.326581090066199, 11.636045903177401],
              [37.326740541029103, 11.6354673988301],
              [37.326664184229898, 11.634838301482899],
              [37.326217272376098, 11.633470119824601],
              [37.326693379476701, 11.631734587111],
              [37.327266055470297, 11.631259458397601],
              [37.3290716691914, 11.631094482960201],
              [37.330297869554201, 11.6310636875344],
              [37.330605542538997, 11.6316312026915],
              [37.3309132155238, 11.632196517026999],
              [37.331555510952001, 11.632178919710899],
              [37.3321259411574, 11.631703791755999],
              [37.3326761592689, 11.630540164774301],
              [37.333069172205697, 11.6299550499361],
              [37.333754137609901, 11.6294205266595],
              [37.334333550968097, 11.629174161676101],
              [37.334910718538197, 11.6289871881058],
              [37.336078528407498, 11.6289563924469],
              [37.336720823835698, 11.628938794925901],
              [37.336934173715598, 11.628186499866599],
              [37.337149769383799, 11.6276057794833],
              [37.338218764571899, 11.6261407749529],
              [37.338789194777398, 11.6256084440704],
              [37.3391799619259, 11.6250233188685],
              [37.338697117460697, 11.624462389419399],
              [37.338104229373201, 11.6241918230459],
              [37.3375652402027, 11.6236308919222],
              [37.337196930936301, 11.623063360459099],
              [37.337223880394802, 11.6219172953361],
              [37.337551765473499, 11.621158382055301],
              [37.338456818122197, 11.620100299509099],
              [37.339835732083401, 11.6192599899258],
              [37.340525189063897, 11.6188970281964],
              [37.3416885073568, 11.6187518433723],
              [37.342324065420399, 11.618448274859199],
              [37.343222380704503, 11.6172163992386],
              [37.343379585879198, 11.6165806612971],
              [37.343422255855202, 11.6159471216983],
              [37.343696242016897, 11.6153641760037],
              [37.344129679141403, 11.6142048803767],
              [37.3442891301044, 11.6136263306609],
              [37.345501855737901, 11.613133572541701],
              [37.346665174030903, 11.612986184899301],
              [37.347267045271202, 11.613547137450499],
              [37.348057562721301, 11.6146734387723],
              [37.348661679749803, 11.6153487774216],
              [37.3500271189817, 11.6161165056904],
              [37.351201666215701, 11.6163738811697],
              [37.352353755567599, 11.615823933275699],
              [37.3523807050261, 11.6146756385751],
              [37.3520954899234, 11.6129003920567],
              [37.352077523617702, 11.612269043915701],
              [37.352654691187702, 11.6120248635892],
              [37.353245333487003, 11.612238246408801],
              [37.353844958939199, 11.612739805382899],
              [37.354152631924002, 11.613305158057001],
              [37.354401914415298, 11.613874909203],
              [37.355003785655697, 11.614490855077999],
              [37.3562322318067, 11.614572247824],
              [37.356658931566699, 11.615192591755701],
              [37.356676897872397, 11.615826133069501],
              [37.3573281764534, 11.6160945077752],
              [37.357903098235198, 11.615793136161599],
              [37.3583522558773, 11.6152057905477],
              [37.3589226860827, 11.6146734387723],
              [37.360243209550298, 11.615903125839401],
              [37.360726054015601, 11.616464072520699],
              [37.3624171325379, 11.616362882222401],
              [37.362749509193002, 11.615777737603301],
              [37.363317693610199, 11.613120373651499],
              [37.363944268520903, 11.6125858180745],
              [37.365287249870697, 11.6125506209635],
              [37.366378702940899, 11.611888474484999],
              [37.366652689102501, 11.6112505245632],
              [37.367319688201, 11.6100824124754],
              [37.367524054928097, 11.609446658265901],
              [37.3678384652776, 11.608775705019699],
              [37.368015882546203, 11.6081927443315],
              [37.368375208659799, 11.607207208019499],
              [37.369136530863102, 11.605775094360199],
              [37.3696732742454, 11.6054605123411],
              [37.370928669854898, 11.6053263199034],
              [37.371465413237203, 11.6055045098476],
              [37.3721818196763, 11.6064900521752],
              [37.3721369039121, 11.6070290191629],
              [37.3721818196763, 11.6076999766108],
              [37.371914570879298, 11.6092244739271],
              [37.371959486643497, 11.6104321866646],
              [37.372765724611, 11.610880952906401],
              [37.373032973408002, 11.612044662002001],
              [37.372496230025703, 11.6126716110139],
              [37.372047072383701, 11.613791316443701],
              [37.373392299521598, 11.614015696951601],
              [37.373839211375497, 11.6138815086303],
              [37.374423116310197, 11.612895992423001],
              [37.374423116310197, 11.6121348547537],
              [37.374333284781699, 11.6118664762361],
              [37.373839211375497, 11.609895426093599],
              [37.3731677207006, 11.608775705019699],
              [37.374063790196502, 11.605728897023001],
              [37.375811013424098, 11.605057934834299],
              [37.375049691220802, 11.603579612449099],
              [37.3745556178146, 11.602818449356899],
              [37.374333284781699, 11.6006669424523],
              [37.374780196635598, 11.6000399664605],
              [37.375945760716696, 11.600086164739601],
              [37.376257925277997, 11.6012499188653],
              [37.376884500188702, 11.601205920688001],
              [37.377513320887502, 11.5997275778968],
              [37.378139895798199, 11.5986078160178],
              [37.3801116978469, 11.5963220798909],
              [37.3814995949608, 11.5959656881636],
              [37.378991049529901, 11.592469945196701],
              [37.376841830212697, 11.5873197495143],
              [37.376392672570603, 11.585438727423799],
              [37.376035592245202, 11.5843629090985],
              [37.375900844952497, 11.583826098406099],
              [37.375721181895699, 11.5833772886752],
              [37.375496603074701, 11.5825258682351],
              [37.375361855782103, 11.582079256476399],
              [37.375094606985002, 11.581945052803601],
              [37.375272024253697, 11.5815424413986],
              [37.375094606985002, 11.5810496266493],
              [37.374870028163997, 11.5805568110313],
              [37.375316940017903, 11.580151997552401],
              [37.375766097659898, 11.579749383565501],
              [37.3766621671558, 11.5790783589658],
              [37.3770641632455, 11.5786757434331],
              [37.377558236651701, 11.5783171291098],
              [37.378005148505601, 11.577912312388699],
              [37.378811386473103, 11.5770630754067],
              [37.3801116978469, 11.575943222698401],
              [37.3809606057903, 11.5750455772117],
              [37.383246818188397, 11.5743305389173],
              [37.385620616326698, 11.573298680434],
              [37.388486242083097, 11.5722250155416],
              [37.389696721928402, 11.5722250155416],
              [37.393191168383602, 11.5724934321509],
              [37.396416120253598, 11.572627640359],
              [37.3976243543108, 11.5728058511589],
              [37.399146998717299, 11.5746891583519],
              [37.401208632294399, 11.5736132986691],
              [37.402731276700997, 11.5728520539403],
              [37.4039395107581, 11.5722250155416],
              [37.403000771286202, 11.571551772749199],
              [37.402551613644199, 11.5708807284849],
              [37.402686360936798, 11.569089802472201],
              [37.403357851611702, 11.568462755643701],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 10,
        REGIONNAME: "Afar",
        RID: "02",
        latitude:13.833271,
        longtiude: 39.344733,
        GlobalID: "d9425508-8c16-4d06-a36e-a3747eb58744",
        Shape__Area: 99515563294.487305,
        Shape__Length: 1893957.4175251401,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.836709636368198, 13.8533302924907],
            [39.837785703992502, 13.8542098195639],
            [39.837522793159998, 13.854114659294501],
            [39.837036716556398, 13.853718513894099],
            [39.836709636368198, 13.8533302924907],
            [39.835309797949101, 13.853561692634299],
            [39.835013924335598, 13.855079372564401],
            [39.834802850786502, 13.855731063331501],
            [39.834924615626697, 13.856462986111501],
            [39.834144064984699, 13.8570092615461],
            [39.833502881670903, 13.8565831253194],
            [39.837767111561099, 13.863665870114099],
            [39.837995416593898, 13.8637848813998],
            [39.838135160316099, 13.864044336745099],
            [39.837921564297801, 13.864316307062699],
            [39.837831144372799, 13.864422852471201],
            [39.837635559575901, 13.8645568825401],
            [39.837484212315204, 13.8646082595934],
            [39.828600914404298, 13.8675035647949],
            [39.828630404298501, 13.8676639462719],
            [39.828725277172303, 13.867993629037899],
            [39.828961837722503, 13.8683194390844],
            [39.829070847384003, 13.868395917776599],
            [39.829395303593202, 13.868878519965],
            [39.829464532260602, 13.869152911743299],
            [39.829466212110198, 13.8692998255994],
            [39.828682220022401, 13.8692199921393],
            [39.8271411709323, 13.8789072586911],
            [39.828892114083501, 13.880558108262401],
            [39.830906470225401, 13.8812971421708],
            [39.830021101461199, 13.883128749415899],
            [39.8287243581957, 13.8852555947936],
            [39.826538069, 13.885947901711001],
            [39.827751609405503, 13.889843933095401],
            [39.829620756475002, 13.890716825027701],
            [39.829949049388901, 13.898732999857801],
            [39.828830797777101, 13.900487418684101],
            [39.833488920054698, 13.9078321586495],
            [39.833256155784902, 13.9183930683462],
            [39.826069001996302, 13.9213673154749],
            [39.824425080534702, 13.9246774617701],
            [39.825157678208498, 13.927165822864801],
            [39.827168139255399, 13.9278359382521],
            [39.828640532803298, 13.9299476839322],
            [39.828522261511303, 13.9323021875514],
            [39.826526777176802, 13.9325983109234],
            [39.819546256564799, 13.940972671924101],
            [39.817413305737396, 13.9425055743394],
            [39.815872317732698, 13.944234245012],
            [39.8135913047835, 13.944646505001201],
            [39.811519710686497, 13.9450546535308],
            [39.8016354730552, 13.945591592691001],
            [39.805801687764699, 13.9521982117314],
            [39.807403366848298, 13.953727624694301],
            [39.809135730144497, 13.955228336543099],
            [39.810851948918398, 13.957233135431601],
            [39.812641072265798, 13.9587358897278],
            [39.8135568732569, 13.961161506997501],
            [39.813923378706299, 13.9636929393674],
            [39.815628396386998, 13.968229268586301],
            [39.820007873839899, 13.9702962021342],
            [39.8223959237502, 13.9716013282672],
            [39.8250279695663, 13.972845701073],
            [39.828056965635199, 13.973921423142601],
            [39.830299999610801, 13.974623466587699],
            [39.833618640375001, 13.975455142950601],
            [39.836602163724201, 13.9757804964556],
            [39.838646790396297, 13.975853657562601],
            [39.845776510397201, 13.971176051703001],
            [39.847787182548203, 13.9705237062918],
            [39.850121635375402, 13.9699628801022],
            [39.859141951519902, 13.9657625820783],
            [39.858571176361401, 13.9731765493125],
            [39.869203415976997, 13.980492374879001],
            [39.868867519722599, 13.9853339423574],
            [39.867776309197502, 13.9899872285347],
            [39.8691571823842, 13.994475673949999],
            [39.872434425186903, 13.9978712101399],
            [39.875585508591101, 14.0004794538063],
            [39.877862906719599, 14.0025054198957],
            [39.880403549853902, 14.003746095470801],
            [39.8854505851574, 14.0099410822338],
            [39.886640389378201, 14.0121800161432],
            [39.885652578535598, 14.0144574562669],
            [39.883516345264098, 14.015784142825799],
            [39.881040793156899, 14.0163164406053],
            [39.868711231727701, 14.019072320027901],
            [39.866586470840701, 14.0204112540055],
            [39.865465049972698, 14.0224653372373],
            [39.864446208625203, 14.0244071635924],
            [39.861649880706501, 14.0274120171712],
            [39.859628492552503, 14.0290307995381],
            [39.854707413628198, 14.0309706503605],
            [39.8497176899415, 14.037220975563301],
            [39.849156802539397, 14.0400302041259],
            [39.847997283221801, 14.0423352399474],
            [39.846574068842401, 14.043928083220299],
            [39.846608406045902, 14.046332886762601],
            [39.8447886178079, 14.047913552485801],
            [39.844411179861503, 14.0499468572228],
            [39.843140684470299, 14.05200589216],
            [39.844222970233098, 14.059673442322],
            [39.844693819494303, 14.060523910783299],
            [39.847046040099499, 14.0715739768471],
            [39.849215223575598, 14.076440575767601],
            [39.848767245421499, 14.0772456810194],
            [39.8483037030568, 14.077912963523501],
            [39.847951643415499, 14.078358346923901],
            [39.846991933671603, 14.0797969967031],
            [39.844441310254503, 14.0870263766931],
            [39.844174086610302, 14.087561867698],
            [39.843838106812498, 14.088606159066501],
            [39.843140997982402, 14.0888990497045],
            [39.842598787453298, 14.0892806766955],
            [39.842318694544304, 14.089782307576201],
            [39.842183165717401, 14.0903953140664],
            [39.842199219509901, 14.090981795245201],
            [39.842571960165699, 14.091270930214099],
            [39.8427959056744, 14.091714285355399],
            [39.842923952433303, 14.092527529401799],
            [39.842841540090802, 14.092943706949001],
            [39.842487249034399, 14.093734912076499],
            [39.842351050962598, 14.094324186686199],
            [39.842167883577801, 14.0953645499318],
            [39.841950895520903, 14.0960253068512],
            [39.841826019815002, 14.097016868174901],
            [39.841828289857702, 14.097948087047801],
            [39.841863223542497, 14.0987876512885],
            [39.841638038358603, 14.1008396264635],
            [39.841485533169497, 14.1017182719253],
            [39.841567481082897, 14.1021180498593],
            [39.842840342636599, 14.1039263096617],
            [39.843430214181403, 14.106096343984801],
            [39.8435246980846, 14.107380968023699],
            [39.843502581562298, 14.1082907997097],
            [39.843222470687103, 14.108792431479101],
            [39.8428845478334, 14.109317998784899],
            [39.842849521622099, 14.110181540480699],
            [39.842902178169098, 14.1108006655426],
            [39.8428191397009, 14.1112055432851],
            [39.8426332656922, 14.112141942768501],
            [39.841901508841602, 14.1137357334428],
            [39.841561210842301, 14.115033185437699],
            [39.841456333431097, 14.1154833445646],
            [39.841226424007203, 14.116098941716],
            [39.838651642197199, 14.1246167010995],
            [39.838471931529597, 14.125346261476199],
            [39.838093540470702, 14.126528570265],
            [39.837884559098001, 14.127487649761299],
            [39.837553104114299, 14.128681093680701],
            [39.837092054574498, 14.141733419904],
            [39.837790741744598, 14.150800238609101],
            [39.837998173523502, 14.1519341579416],
            [39.837984878457299, 14.1527275535207],
            [39.837957969423002, 14.153440756944899],
            [39.838128884685602, 14.154092241197],
            [39.838157805946103, 14.154712578624],
            [39.837948980880199, 14.155246729376801],
            [39.837840063745297, 14.1555488553006],
            [39.837337872263802, 14.1556760573394],
            [39.836415721081899, 14.155930172890301],
            [39.8360637440856, 14.1559619169537],
            [39.828350720122998, 14.1556508547307],
            [39.827598810467201, 14.155266954040499],
            [39.826774231596602, 14.154805324488899],
            [39.824518785598698, 14.1540457544525],
            [39.822554742299097, 14.153635501050699],
            [39.822117190890502, 14.153543205039099],
            [39.821233981676201, 14.153519116261601],
            [39.8204669533743, 14.153434729303999],
            [39.819761639332299, 14.1534416689304],
            [39.818811013352601, 14.153511603489299],
            [39.818340829743001, 14.1535233835162],
            [39.817529545040202, 14.153531810015499],
            [39.8169269264015, 14.1534321579751],
            [39.815602160190799, 14.1531665783886],
            [39.8143724824495, 14.152933440688001],
            [39.813131967432597, 14.152745539812701],
            [39.8120169371795, 14.1528318293966],
            [39.811084764392298, 14.152717458061501],
            [39.810478883970802, 14.152513828459799],
            [39.8089198820497, 14.151839897015501],
            [39.808131374131001, 14.151411982493199],
            [39.807460991078898, 14.1509802754593],
            [39.806854027289198, 14.1503053743342],
            [39.806652546849001, 14.1498268762038],
            [39.806219556187102, 14.1490564552424],
            [39.797782514360001, 14.142518652405901],
            [39.792507288109697, 14.142719266546299],
            [39.791554719074, 14.1427212892113],
            [39.790550015412499, 14.1425505699469],
            [39.789958313999399, 14.142415776864601],
            [39.788930492194098, 14.142257557501299],
            [39.786667067359801, 14.1416355231671],
            [39.785240696300498, 14.141096611259099],
            [39.784777463854603, 14.140935390058299],
            [39.775398819624698, 14.139054895314599],
            [39.7671104651468, 14.1400331906978],
            [39.757982202946202, 14.145137768741399],
            [39.749518782989, 14.1517882357573],
            [39.739976585190298, 14.153820586844001],
            [39.731466857978603, 14.1553431011592],
            [39.720914330369702, 14.1570082377877],
            [39.715597865159701, 14.1565879789389],
            [39.715107575457402, 14.1562899552319],
            [39.714347547726398, 14.156033422957499],
            [39.707101815696497, 14.1577649017445],
            [39.706495478032501, 14.157952040673001],
            [39.705831413730102, 14.158199247295901],
            [39.705286126471201, 14.1584653140001],
            [39.704762700613003, 14.1586849790871],
            [39.7038480440755, 14.158800691410701],
            [39.702632563309002, 14.158646046823501],
            [39.702100803779899, 14.158981006746099],
            [39.701715032162603, 14.1595200957434],
            [39.701561009719001, 14.159903671322301],
            [39.700860495375601, 14.1605420064255],
            [39.700270739713503, 14.1604759265498],
            [39.699479749749599, 14.159943679081801],
            [39.6986623151804, 14.1592849298515],
            [39.698169476059299, 14.1589066368677],
            [39.6979055393507, 14.1591424816794],
            [39.697392500712198, 14.1597305313297],
            [39.697208231992903, 14.1598847710244],
            [39.696620027721302, 14.1603249884223],
            [39.696354753421303, 14.1605031964229],
            [39.695724010327702, 14.1606689077796],
            [39.695038742191301, 14.160996387905501],
            [39.694706785049398, 14.1613375320598],
            [39.694432630902803, 14.161643471894999],
            [39.694281700460301, 14.161716240823299],
            [39.694073580571903, 14.1618366428846],
            [39.693920734921299, 14.161851779368201],
            [39.6931600244707, 14.161560146239101],
            [39.692895873064799, 14.161348442406901],
            [39.692679345843601, 14.161180677688799],
            [39.692240521522301, 14.161041809715501],
            [39.691658625220597, 14.1612751786235],
            [39.681975894978798, 14.164676553729199],
            [39.676754935954897, 14.175178179181501],
            [39.6764300925717, 14.17578486591],
            [39.676043549301603, 14.176288883299],
            [39.675616601606599, 14.177034868645899],
            [39.675432270903599, 14.1771777907108],
            [39.674862474908799, 14.177409956786599],
            [39.674502802045403, 14.177603101291799],
            [39.673487855792999, 14.177915633212701],
            [39.672976548207998, 14.1781464982025],
            [39.672532692422799, 14.1782720676091],
            [39.671452229341703, 14.178758821613201],
            [39.671182421244502, 14.178788965163299],
            [39.670784826001402, 14.178890667608201],
            [39.6705169555702, 14.1789886144782],
            [39.669903512232402, 14.1793497293991],
            [39.667562718197601, 14.180223127244499],
            [39.666969196797801, 14.180467772553],
            [39.665952285929897, 14.180711338500901],
            [39.665320812506003, 14.1808532409894],
            [39.664328324135703, 14.181130637322999],
            [39.6631726487092, 14.181458210722299],
            [39.662719137831203, 14.181653860081299],
            [39.6623112074708, 14.1817894825202],
            [39.6616173927628, 14.181807230485299],
            [39.660358161346998, 14.181780206163999],
            [39.659290452546401, 14.181887135503301],
            [39.658660296052702, 14.1820866549337],
            [39.658299887469099, 14.1822334425118],
            [39.6579973276949, 14.1823563341417],
            [39.6575207085546, 14.1825746418312],
            [39.656797340172098, 14.1827879754079],
            [39.6556570286319, 14.182827278902],
            [39.649940163638497, 14.1886022535802],
            [39.6564087478448, 14.1928136005507],
            [39.657310875579, 14.197909279987099],
            [39.659410957050198, 14.1994337164946],
            [39.661821111804599, 14.1976379855238],
            [39.664245483296803, 14.196358676291201],
            [39.665537659527303, 14.196729153365901],
            [39.672247045230399, 14.2003245692141],
            [39.676276680982397, 14.207090724115799],
            [39.678186895433498, 14.208976044575699],
            [39.678535213591701, 14.209231599095901],
            [39.679197276447603, 14.209802700489],
            [39.679464030273003, 14.2100946494946],
            [39.680002694253297, 14.210851447167601],
            [39.6805497413119, 14.211505375458501],
            [39.681166840792898, 14.2121455385363],
            [39.682173968109097, 14.2128456120991],
            [39.683082654443197, 14.213362878358399],
            [39.683752975511503, 14.2137836319512],
            [39.684535238342399, 14.214442524973601],
            [39.685139441609302, 14.2150262068632],
            [39.685574637635199, 14.2154634539506],
            [39.686071514683398, 14.2159920651803],
            [39.686525438786397, 14.216682411284999],
            [39.686815476943899, 14.217387922018],
            [39.686923661053498, 14.217914262060599],
            [39.687055594822702, 14.2184405329561],
            [39.687221079769401, 14.2193328765344],
            [39.687875647082599, 14.231334352740999],
            [39.694064878591803, 14.2413012568555],
            [39.706452232236401, 14.2420524654601],
            [39.7160505567741, 14.240192900549999],
            [39.717694927393197, 14.240083966519],
            [39.719106674777997, 14.2400593566799],
            [39.720194527400501, 14.239837944630001],
            [39.732706143201803, 14.2369393134107],
            [39.7417378167465, 14.235023970795501],
            [39.742650208631098, 14.234839169893],
            [39.750724592493199, 14.2362140581321],
            [39.751594326079903, 14.236617030746199],
            [39.752426684360898, 14.236929706698],
            [39.7532461877502, 14.237208517139701],
            [39.753718485095703, 14.2372544852822],
            [39.754393985850001, 14.237433165634901],
            [39.755081073973201, 14.237611806663701],
            [39.756304556932299, 14.238466822548901],
            [39.7572426199728, 14.239201815652301],
            [39.757390603042801, 14.239451105159601],
            [39.757495602624701, 14.239839538036801],
            [39.757602953996098, 14.240756867476399],
            [39.757652805104499, 14.241284482655599],
            [39.755890187447903, 14.250030575513801],
            [39.757008544162503, 14.263751384718899],
            [39.7583567366393, 14.276499516210899],
            [39.757836617479597, 14.277248197805701],
            [39.756556059160701, 14.277754066127001],
            [39.7520967484284, 14.2782496867524],
            [39.742298619707, 14.281041486935401],
            [39.741678328511703, 14.2814785303737],
            [39.734067727762799, 14.287603836045101],
            [39.7334319235609, 14.288270327339299],
            [39.726696571156197, 14.2915617024808],
            [39.726619318736802, 14.2925824556695],
            [39.726897063449599, 14.2947932851865],
            [39.7269744461248, 14.29506993223],
            [39.726885564115598, 14.2960782892893],
            [39.725343964358203, 14.3012307993307],
            [39.727106287367398, 14.304673445460701],
            [39.733241807707401, 14.306583663488],
            [39.743050023611197, 14.3078821121969],
            [39.743824579795302, 14.3086877254407],
            [39.744601623414397, 14.308667200276201],
            [39.745079605298898, 14.3082848408395],
            [39.745949714381403, 14.3083691194899],
            [39.746215903166402, 14.3086033485491],
            [39.746748397517401, 14.309106838792401],
            [39.747000669602002, 14.3095128903384],
            [39.747269233532599, 14.3102873141587],
            [39.747567834431401, 14.310683047983501],
            [39.748417963185297, 14.311203611564],
            [39.748843655933896, 14.3111299367889],
            [39.749444489620103, 14.3110952611268],
            [39.749837595083299, 14.311503123069],
            [39.750011338242501, 14.311643838753399],
            [39.750209385321597, 14.3117743067064],
            [39.750361377572702, 14.3118224209231],
            [39.750758436521501, 14.3120245847786],
            [39.751231368975901, 14.3125610262998],
            [39.751593372069102, 14.313371311767201],
            [39.7515802593609, 14.314129672481901],
            [39.752033714543302, 14.315078671680199],
            [39.762613429664199, 14.3186860803923],
            [39.770012142092099, 14.317816776637301],
            [39.770442577047, 14.3181114685572],
            [39.7719085781834, 14.3197442431059],
            [39.771478073159798, 14.320781973388501],
            [39.771264496904401, 14.321536464521101],
            [39.771403912741597, 14.3223078857859],
            [39.772260950438401, 14.322449744489401],
            [39.773719841409303, 14.322509393496301],
            [39.774740012263401, 14.3227332092133],
            [39.779602654880001, 14.329352188092299],
            [39.779701461476499, 14.329767747452699],
            [39.779343071407297, 14.330036769664799],
            [39.779197352091799, 14.330298308163099],
            [39.778493233707501, 14.331480477205099],
            [39.778751302620698, 14.3321972554859],
            [39.779762721188902, 14.3335591099782],
            [39.779601486171899, 14.3340263829515],
            [39.779456225895501, 14.3342540167124],
            [39.779644049248297, 14.334934861721001],
            [39.779929760221101, 14.336099075541201],
            [39.780008602658597, 14.336939627323799],
            [39.779853582186703, 14.337695061512999],
            [39.779210023727202, 14.3387967947265],
            [39.774501614658298, 14.3472136166471],
            [39.7739630584758, 14.349030360025401],
            [39.773964817377099, 14.349540040410799],
            [39.773967282354299, 14.350087012322399],
            [39.773950383247097, 14.350396721265],
            [39.774075700025897, 14.3512721560469],
            [39.776181287271498, 14.352743441636701],
            [39.774850386465701, 14.3540531063798],
            [39.770736373755597, 14.3608258438704],
            [39.771064754704398, 14.361589864246101],
            [39.771711215620499, 14.3623121592393],
            [39.772208538233102, 14.3628156968363],
            [39.773058878989502, 14.3633237263279],
            [39.773995502030601, 14.363638218912399],
            [39.774295265349302, 14.364000003886099],
            [39.774222646440101, 14.36479132821],
            [39.773600849668398, 14.3718894011751],
            [39.7776524097033, 14.375152289752],
            [39.787028639261699, 14.3784798788387],
            [39.788268519169598, 14.3789605562127],
            [39.795968945158698, 14.3859718989525],
            [39.800925547960901, 14.3871677101899],
            [39.808840550030403, 14.384714316148401],
            [39.818494801881002, 14.383953184517599],
            [39.820217376235497, 14.381036034114899],
            [39.819967192733898, 14.3803091146043],
            [39.821205364944497, 14.380304802913001],
            [39.822199000463499, 14.381270972107201],
            [39.8236882679657, 14.3822365341015],
            [39.825919217804099, 14.3829565156516],
            [39.826662360801002, 14.382953909524],
            [39.827652130869197, 14.3827085914173],
            [39.828393794340798, 14.3824630128629],
            [39.829136357026101, 14.382460401508601],
            [39.838043296798503, 14.380487375774599],
            [39.8439671749559, 14.37561357431],
            [39.845700005376102, 14.375365523584],
            [39.846442539315198, 14.3753628590652],
            [39.849411074273803, 14.374623266773099],
            [39.854604980532997, 14.3731489296816],
            [39.853101802618603, 14.368544687362],
            [39.852848761576098, 14.367090031148701],
            [39.853338221235099, 14.3656315599734],
            [39.863491074899301, 14.366080563538199],
            [39.862021933252798, 14.3702107993746],
            [39.874895276364398, 14.3699204312975],
            [39.875883027019903, 14.369188983602299],
            [39.877121120178799, 14.369184383655901],
            [39.879345417094299, 14.3682053517628],
            [39.883563290322499, 14.3703740635936],
            [39.884801761672101, 14.3706112614079],
            [39.888513172827999, 14.3698695318256],
            [39.891230495714098, 14.368403722837201],
            [39.895448469553699, 14.370571086533101],
            [39.900640945694903, 14.368852795675799],
            [39.901621772136203, 14.366422747992701],
            [39.902109936424502, 14.3647223581068],
            [39.905078111158801, 14.363983217086],
            [39.907558552795997, 14.365187405291699],
            [39.9102861110864, 14.3661476439644],
            [39.914510552796003, 14.3700131547879],
            [39.917979290366198, 14.370484488668399],
            [39.920938922121998, 14.3678036967032],
            [39.924892628673, 14.3658478808733],
            [39.928849699414698, 14.3648616001559],
            [39.933795264616997, 14.3633865408154],
            [39.938491236510799, 14.3614264404533],
            [39.944678758526003, 14.3609169576958],
            [39.948637685187002, 14.3604151510992],
            [39.9518561880516, 14.3604021965991],
            [39.954830303344799, 14.361119085116],
            [39.956322017857801, 14.362568583086899],
            [39.958306663120098, 14.363531267917301],
            [39.965996394665801, 14.3668957881127],
            [39.9682307095933, 14.368343292410801],
            [39.970959566847597, 14.3695446446212],
            [39.973440380387402, 14.3707481171643],
            [39.978633789878202, 14.3692699786688],
            [39.980876690903202, 14.372657644261199],
            [39.984113446636002, 14.376768902613099],
            [39.985854021432203, 14.3784601188606],
            [39.987838921816, 14.379422538622],
            [39.989575258360702, 14.380143019144899],
            [39.990569081627498, 14.3808666073241],
            [39.991565554026103, 14.382317938637801],
            [39.991575221695101, 14.384502306840799],
            [39.990101366588299, 14.387176576947599],
            [39.9871348887716, 14.3881597415683],
            [39.9849135553983, 14.3898675181009],
            [39.9819538859132, 14.392549079107599],
            [39.981469481074299, 14.3949773434964],
            [39.980993577891297, 14.3993470211849],
            [39.981253873728001, 14.4022569786608],
            [39.979795734646999, 14.4085710611716],
            [39.9795513830083, 14.4092998393267],
            [39.9793144703184, 14.411727069643],
            [39.981061694444399, 14.414874925585901],
            [39.9838243121837, 14.4235976286464],
            [39.983361741796102, 14.431121242160801],
            [39.9851059736338, 14.433540174570901],
            [40.010195454192001, 14.439621018063599],
            [40.028175657710101, 14.4579709140354],
            [40.0431858287781, 14.47373083335],
            [40.055235971034698, 14.486890760150001],
            [40.075286135512599, 14.4939406679611],
            [40.0890262181262, 14.490240613886],
            [40.139896602635197, 14.4980403982567],
            [40.172966794330897, 14.4902702720221],
            [40.222567080504099, 14.4786200821775],
            [40.268477284221099, 14.4532499387896],
            [40.330927578868199, 14.423999722723799],
            [40.384647796750798, 14.389739558111501],
            [40.444488077662399, 14.363449350934101],
            [40.545568479843702, 14.3013990293696],
            [40.650508861054099, 14.229378705881199],
            [40.836719428963498, 14.084138160581199],
            [40.9708297756208, 13.9700377830607],
            [41.015569890442698, 13.932467656945899],
            [41.077280039770898, 13.878537486624699],
            [41.1686202177357, 13.7894172496515],
            [41.191500258614802, 13.7678271949273],
            [41.215850321489597, 13.7475271253366],
            [41.2503503813859, 13.7127070401902],
            [41.290780397440898, 13.662256949890301],
            [41.313680395292401, 13.630276908496599],
            [41.350390473382802, 13.597386808659699],
            [41.3784405190464, 13.5699167373874],
            [41.418650617638299, 13.537486620539701],
            [41.439680665326399, 13.519486561732499],
            [41.481570745551998, 13.4807164529222],
            [41.531200823453297, 13.433126320429601],
            [41.578070889627803, 13.385716207173299],
            [41.611660920208799, 13.3474961270954],
            [41.650960993078499, 13.3116560236203],
            [41.695431063546202, 13.2699459021824],
            [41.7373111302513, 13.231175793008299],
            [41.786401168851498, 13.175315684924399],
            [41.878661309702103, 13.0895954380176],
            [41.9304613301559, 13.029235323505199],
            [41.972531362695797, 12.983155225076199],
            [41.985971334342601, 12.958945212719501],
            [41.997761292153598, 12.9348452078485],
            [42.010191295113103, 12.9207051777967],
            [42.020531257969502, 12.900035174131601],
            [42.033761211587702, 12.8725151668277],
            [42.042651179489603, 12.8552651587342],
            [42.050211165562096, 12.8431151513406],
            [42.056421168815604, 12.836035133845201],
            [42.070411174419299, 12.8201251023282],
            [42.082841185463501, 12.8059750741978],
            [42.095381196112598, 12.7934850451666],
            [42.0963911567152, 12.7834350613248],
            [42.113491163070201, 12.763975022857601],
            [42.1356712034423, 12.7458549543018],
            [42.155771208248801, 12.721214919637699],
            [42.195961203442998, 12.671924835260601],
            [42.209731192768999, 12.65269480942],
            [42.218941192677299, 12.640424789408501],
            [42.227011204438902, 12.633284771806901],
            [42.234111200085401, 12.6236147546102],
            [42.242361208178799, 12.6155547411817],
            [42.249921194251399, 12.603404725999001],
            [42.280241143399103, 12.556424681365],
            [42.309661148617103, 12.5211746186213],
            [42.335321099804801, 12.479504590684099],
            [42.341001055718102, 12.464134590657199],
            [42.354771050434003, 12.444904566459099],
            [42.337000767028101, 12.402774720619201],
            [42.319300601621698, 12.3872948284542],
            [42.311470480465097, 12.369494891826101],
            [42.294890285147602, 12.3456050100155],
            [42.281310081993297, 12.316525117022],
            [42.266179882528697, 12.2892152326383],
            [42.244289641362201, 12.2606753757224],
            [42.2292694513839, 12.235025489639099],
            [42.211139254366103, 12.212905599175899],
            [42.199069164120303, 12.207045666737599],
            [42.180398933191299, 12.176635791051099],
            [42.164148766332801, 12.157715895199599],
            [42.124128339826598, 12.1070661482328],
            [42.111628216172697, 12.0945762240253],
            [42.089637970057197, 12.0643863601139],
            [42.078037810095999, 12.040166451289901],
            [42.063237632802199, 12.0178265497953],
            [42.050087469344099, 11.9953766399637],
            [42.040697355424697, 11.979346706433301],
            [42.027447184322703, 11.9552367960544],
            [42.019607067611901, 11.937436861288599],
            [42.010216947404302, 11.9214069262874],
            [41.996856775799102, 11.895637021439899],
            [41.980176580024199, 11.8700971272405],
            [41.964246433493997, 11.856157214894701],
            [41.942136193117797, 11.8243073505427],
            [41.9190259796083, 11.802517478794099],
            [41.901425829930702, 11.7886975696776],
            [41.885605692886799, 11.776417649883699],
            [41.866355537458197, 11.762697749066],
            [41.853315385283103, 11.7419078359978],
            [41.833845214475197, 11.7248779380536],
            [41.826015100505103, 11.707077991367999],
            [41.818505010456697, 11.694248044040499],
            [41.810984921260903, 11.681418087915],
            [41.803364816336199, 11.6669381457341],
            [41.796974699970001, 11.645708196149499],
            [41.790844494961597, 11.602808288475501],
            [41.787884404166597, 11.5830183274938],
            [41.781494288698603, 11.561788378645],
            [41.775004164688397, 11.5388984427665],
            [41.774614037140303, 11.5072884855417],
            [41.776183739793098, 11.4288985979821],
            [41.772593305654802, 11.3225487564972],
            [41.7768731859939, 11.2856187971871],
            [41.773022843192699, 11.200938922824999],
            [41.769182494149099, 11.116249053643701],
            [41.7638023518602, 11.0849591138892],
            [41.760431944779398, 10.981929265998801],
            [41.7395217934356, 10.9683193617767],
            [41.717591667968797, 10.9647694448084],
            [41.699521594294701, 10.9692995035773],
            [41.680811477268797, 10.9638695796367],
            [41.634161245013999, 10.965289744765199],
            [41.582210966955799, 10.9620599379551],
            [41.48405048291, 10.966890277454601],
            [41.410060067203801, 10.958460553988999],
            [41.392200000778502, 10.9663006082923],
            [41.348559765224202, 10.9625207658218],
            [41.300259521828501, 10.9640509329155],
            [41.280439399725203, 10.960221006026901],
            [41.268139328190202, 10.9578410532751],
            [41.240729053688803, 10.9213511890118],
            [41.233578907795, 10.8911712482215],
            [41.231958807858099, 10.8636412843621],
            [41.220038638348299, 10.8344513541025],
            [41.207438525016798, 10.820291412460801],
            [41.1908583557505, 10.7964014991484],
            [41.166408172942397, 10.7797016011859],
            [41.149128009457101, 10.7596716812383],
            [41.1330878750002, 10.744071748203099],
            [41.114847699937201, 10.7203018370886],
            [41.089957485471999, 10.696961942903201],
            [41.077247367145702, 10.6811419972331],
            [41.0789673040466, 10.6588320178617],
            [41.077867228048, 10.639462044081601],
            [41.082427229143804, 10.632502031815701],
            [41.0870171423419, 10.600552051078299],
            [41.080307017325303, 10.5743520949461],
            [41.071286857487699, 10.5409721622849],
            [41.061356724928501, 10.5166422136164],
            [41.056956647309498, 10.5002722458481],
            [41.047126523292498, 10.477612297127701],
            [41.038426375698499, 10.446542360392399],
            [41.031826261066598, 10.421992398259301],
            [41.030416182751601, 10.4004324245394],
            [41.015005915530899, 10.3431625276185],
            [41.005075785666598, 10.3188325841327],
            [40.999805658253898, 10.289202621362501],
            [40.989925447769998, 10.2398926971153],
            [40.978805245986997, 10.194662769309801],
            [40.974655099442998, 10.156632814197],
            [40.953824800243098, 10.0957129300844],
            [40.9486646886049, 10.067742967295001],
            [40.936534525321001, 10.0352330320479],
            [40.946014465379001, 10.0013030259296],
            [40.953454459885698, 9.987483014111501],
            [40.965784467777603, 9.97167298930815],
            [40.980634532112397, 9.969032944160141],
            [40.998914618425303, 9.967822891326771],
            [41.011884666075602, 9.96197285436395],
            [41.014784672154498, 9.957992851445461],
            [41.0195446642837, 9.948812842066779],
            [41.0139146043945, 9.93919286434808],
            [40.996534485367597, 9.92868292892838],
            [40.978724348204103, 9.9115329965305],
            [40.946654087420796, 9.880343116927129],
            [40.913023828484803, 9.85092323876534],
            [40.877513546576203, 9.818293372603121],
            [40.847313313211401, 9.790313483606329],
            [40.8255431220012, 9.763443569426039],
            [40.801452890534101, 9.72673366242131],
            [40.777362660863801, 9.690013757663699],
            [40.763072524530401, 9.667463811967901],
            [40.748672398473602, 9.651283862887819],
            [40.735302243890601, 9.62171392133274],
            [40.730512174369103, 9.606803946618991],
            [40.721802086018101, 9.590243978508751],
            [40.714501980760602, 9.56598401409317],
            [40.698981799275003, 9.532754077922389],
            [40.692961675618101, 9.498884117348579],
            [40.693431525952697, 9.447464139803589],
            [40.692181513048297, 9.44469414605525],
            [40.6936215196353, 9.446124138751239],
            [40.692791509550403, 9.44210414507503],
            [40.689641484361701, 9.43908415214915],
            [40.6881014674358, 9.437604158993031],
            [40.683801427105102, 9.43073417305901],
            [40.675521347009301, 9.41750420799444],
            [40.659531232543998, 9.40429426158066],
            [40.635951093241403, 9.3959343342562],
            [40.605940929916102, 9.39103442033538],
            [40.566240721367599, 9.38778453011485],
            [40.528390526671501, 9.38544464175992],
            [40.4988003544875, 9.375034724730741],
            [40.471000156355601, 9.35474480952564],
            [40.449649976049699, 9.329614882434701],
            [40.425749791265901, 9.304264954609099],
            [40.408099637766199, 9.28117501423376],
            [40.392289481903497, 9.255095070283019],
            [40.369509272598499, 9.21825514641875],
            [40.3495190832422, 9.18713520952887],
            [40.326998884109699, 9.15464528021559],
            [40.300588682454503, 9.12812535957255],
            [40.2714184654794, 9.101385445019231],
            [40.254288336816302, 9.08606549202629],
            [40.245478265075803, 9.074855516350461],
            [40.244248205550498, 9.05558552980944],
            [40.246008159702399, 9.035395529039841],
            [40.242238081371902, 9.01314554080521],
            [40.237788016100602, 8.99732556011786],
            [40.222307917442897, 8.98773560274234],
            [40.204057810606102, 8.980215654209021],
            [40.1999877803106, 8.97876566347187],
            [40.187437710327004, 8.97430569732677],
            [40.174377622739101, 8.967005735660191],
            [40.169867592227597, 8.96448574592611],
            [40.162897526208901, 8.95073576466673],
            [40.160447497933902, 8.94640576980027],
            [40.1522274261973, 8.93194579682034],
            [40.140887334799103, 8.918895831144241],
            [40.120517175145601, 8.895765887627229],
            [40.105007049401301, 8.87698593053144],
            [40.093486950891503, 8.862955959306159],
            [40.089956926659397, 8.85865597139192],
            [40.0809468602923, 8.85133599325669],
            [40.069386784955803, 8.844476020865329],
            [40.064476758004503, 8.843436035785279],
            [40.051166684544299, 8.84061607040811],
            [40.040326633162501, 8.84109609323537],
            [40.024196562972598, 8.845036135064101],
            [40.010386520463001, 8.85607616947736],
            [40.010896559626801, 8.87076616345739],
            [40.019246648127698, 8.88911614472562],
            [40.036596782288797, 8.906516094954689],
            [40.040566841771202, 8.92303608294141],
            [40.043856912516098, 8.94230607478509],
            [40.051076998543003, 8.961346048508251],
            [40.047437018723599, 8.977876054927361],
            [40.034796998633901, 8.99443608730877],
            [40.0219069513495, 9.002266119345849],
            [39.999536855311, 9.007146172435229],
            [39.968146683876498, 9.003546251989031],
            [39.947606580937602, 9.00358630585783],
            [39.9490266116876, 9.013456301267579],
            [39.951846668604503, 9.02997629195727],
            [39.942436673171301, 9.047676314926051],
            [39.928376613348398, 9.054136345614531],
            [39.918726602444302, 9.06839636917366],
            [39.9155866653361, 9.0973263722694],
            [39.910356704294699, 9.123516378434051],
            [39.909676710167403, 9.12695638083788],
            [39.908996720531597, 9.131786383103631],
            [39.898396683722297, 9.138926405494461],
            [39.8776365932518, 9.14471645907199],
            [39.8644665123472, 9.1410664915574],
            [39.848986428960899, 9.138116534016371],
            [39.839056379046703, 9.13836655592876],
            [39.833286349059897, 9.137466570768069],
            [39.824046298708701, 9.13679660102355],
            [39.817398553603802, 9.136301102051769],
            [39.814829712352697, 9.152709260436129],
            [39.815573436559603, 9.15556496101366],
            [39.8171589738158, 9.15987994308405],
            [39.815245187663201, 9.163251515846859],
            [39.812991186156196, 9.17109730177877],
            [39.836095499530899, 9.188286377837359],
            [39.8405917777337, 9.19281431626845],
            [39.847745127019898, 9.20149788184208],
            [39.853482611044001, 9.210799634042541],
            [39.865267788919397, 9.219540120047389],
            [39.872607279013899, 9.225490217858381],
            [39.873408063309299, 9.229068545851341],
            [39.873206388833097, 9.233563323170159],
            [39.862933213023098, 9.242249549380441],
            [39.856452541086298, 9.268362128999771],
            [39.857514036240097, 9.271760115568989],
            [39.885966955647397, 9.269346408307101],
            [39.866405090205099, 9.296378305038321],
            [39.865738587875001, 9.297699214273109],
            [39.859976203449499, 9.307113264205549],
            [39.845390332741403, 9.314074278676641],
            [39.8428379297617, 9.314265185664009],
            [39.836038992804603, 9.314893999154631],
            [39.831631340591102, 9.3145878150088],
            [39.828707533648803, 9.313190005007961],
            [39.826566411745503, 9.3116309452935],
            [39.8238991977843, 9.310674521005261],
            [39.821319845345997, 9.30956582023272],
            [39.818103367284102, 9.308061759746421],
            [39.815278329208901, 9.30724750883731],
            [39.788772099446497, 9.304892597301221],
            [39.773341515029003, 9.321428691141801],
            [39.773337518424299, 9.32269069064224],
            [39.773702524585502, 9.324203689107611],
            [39.773814527433501, 9.3255446889985],
            [39.773555531459003, 9.32743668957113],
            [39.772474533880299, 9.330600691399059],
            [39.772102535233003, 9.331713691022079],
            [39.771347537169298, 9.333821692465699],
            [39.768476521521201, 9.33363870128975],
            [39.753967500787397, 9.354687732710291],
            [39.750685500506997, 9.360911740520031],
            [39.750900350573502, 9.3674970706792],
            [39.752029373883801, 9.369240753223639],
            [39.791456736232703, 9.369806630215781],
            [39.783709941005199, 9.39490334798805],
            [39.786630858067298, 9.396285095806711],
            [39.798861478152801, 9.39748526755915],
            [39.800693594869699, 9.40184315608076],
            [39.8072003377127, 9.41282807420674],
            [39.821433664587502, 9.417427297539509],
            [39.827376614876599, 9.41482281137549],
            [39.830112814960103, 9.414054400198131],
            [39.8542691256011, 9.40535784854176],
            [39.855477194368198, 9.404739924847849],
            [39.856475599941298, 9.40266361172316],
            [39.857789752556997, 9.401445132953549],
            [39.8595519668701, 9.401417091143021],
            [39.861163317215997, 9.40137980043248],
            [39.863173509666602, 9.40097067648667],
            [39.863741114670503, 9.400855151959689],
            [39.864896562823198, 9.400606431825841],
            [39.866377672724298, 9.40038742931187],
            [39.868057006672601, 9.399542773538879],
            [39.869714854716001, 9.39807752577596],
            [39.8719014744918, 9.397743143215081],
            [39.873837949393597, 9.39775812453064],
            [39.876602712309797, 9.398036694991649],
            [39.876834523467203, 9.398769802301249],
            [39.877953273643897, 9.399097113326761],
            [39.878759631087398, 9.399464204452119],
            [39.879091056426603, 9.399993187923871],
            [39.879474801444601, 9.400684468902639],
            [39.879675842608599, 9.40162565919973],
            [39.879683300422101, 9.402097617323181],
            [39.879572820218499, 9.40387218686829],
            [39.877168991400303, 9.406558566653761],
            [39.8754413568358, 9.405860841169201],
            [39.8751720041844, 9.407845511941],
            [39.872843819801197, 9.40872290340938],
            [39.874841467278898, 9.411074431840371],
            [39.874967973427097, 9.411237733727431],
            [39.875563895125403, 9.41200697712627],
            [39.874878834499803, 9.41482697924398],
            [39.873193605806598, 9.416062834665651],
            [39.871390264823198, 9.441593635793909],
            [39.8730748979334, 9.44682809653375],
            [39.873031534458001, 9.45781253137914],
            [39.8719759079979, 9.46068432779176],
            [39.847809086169804, 9.481194851406149],
            [39.837503687790502, 9.50188621645724],
            [39.847781171920602, 9.50909192742569],
            [39.846371343337303, 9.51231498406886],
            [39.847087989626601, 9.524426385470329],
            [39.8486907332138, 9.52596671411556],
            [39.857302030239801, 9.547071994401501],
            [39.856745955112601, 9.55648574598116],
            [39.8475559508042, 9.563630400557621],
            [39.847556900323397, 9.564928192513641],
            [39.852297807744399, 9.56627425246251],
            [39.8537002261249, 9.56618374699408],
            [39.854839976218102, 9.568536852110411],
            [39.875928171854497, 9.57541841025399],
            [39.878320811257602, 9.5776947611882],
            [39.877848586675697, 9.582951527946561],
            [39.880496554231897, 9.590991480086929],
            [39.882418317424303, 9.595013307196149],
            [39.882716267044501, 9.60840973803378],
            [39.884830586812903, 9.61283122808611],
            [39.879305830136303, 9.624237121310159],
            [39.869802922851498, 9.63863070578685],
            [39.868424464336201, 9.642395270910431],
            [39.8615755091841, 9.65157726941106],
            [39.857640451658398, 9.65513907836031],
            [39.849857645666198, 9.68636068396431],
            [39.849936257236699, 9.689064580354019],
            [39.852656399934503, 9.698669077677851],
            [39.848430533496803, 9.70868429035623],
            [39.839105397416802, 9.723547101301079],
            [39.8444405628561, 9.735527091516561],
            [39.845706055529497, 9.736985836128071],
            [39.851112451980903, 9.74756691840753],
            [39.851678073504601, 9.747736354212821],
            [39.853078079908599, 9.749576353272619],
            [39.856558109301801, 9.752566341598911],
            [39.8621181455146, 9.753946323624969],
            [39.875778213389196, 9.75279628912627],
            [39.918138405359201, 9.741746176338079],
            [39.923698426300597, 9.74013615940637],
            [39.9320284635061, 9.73690614000906],
            [39.938738493301202, 9.73483612019403],
            [39.9440585140431, 9.7332261097828],
            [39.948918533579103, 9.73138609407372],
            [39.954708563657498, 9.730236081876139],
            [39.965574319432903, 9.72964665697922],
            [39.966248628122798, 9.73052604867777],
            [39.9674386326679, 9.730456047829771],
            [39.973508656840501, 9.729216032021389],
            [40.009033645068698, 9.731901751573011],
            [40.009661783048003, 9.733786136303429],
            [40.013430583974099, 9.735670512173479],
            [40.014686854542802, 9.737554896888611],
            [40.0131165284592, 9.73943928779039],
            [40.005893008172997, 9.7410096305014],
            [40.001810165206699, 9.747919060377439],
            [39.991131936340103, 9.75671289717279],
            [39.993407041967998, 9.763592263341209],
            [40.008051358865103, 9.7817634621991],
            [40.007617369276602, 9.78870757445001],
            [40.004434652598, 9.79521768674252],
            [40.011003348652899, 9.818544154619479],
            [40.0174686099909, 9.819995396275351],
            [40.026685346365603, 9.8284455617663],
            [40.028867342512598, 9.831333889901479],
            [40.035184618983997, 9.84278591675389],
            [40.052648597539303, 9.842184672837829],
            [40.070670814365002, 9.857404003749821],
            [40.060096504407099, 9.86376771835776],
            [40.056719658202397, 9.86488501101285],
            [40.063537060928503, 9.902400486973811],
            [40.069356757869102, 9.90478516287957],
            [40.076378441084003, 9.90481805026266],
            [40.079226435606301, 9.906080567010189],
            [40.0888204733834, 9.9347928624196],
            [40.080400752871597, 9.948378041491191],
            [40.072138505232402, 9.955063819741721],
            [40.06725010073, 9.960057741146681],
            [40.067625859725098, 9.963392309164631],
            [40.066643841219502, 9.965761358019609],
            [40.065251808261898, 9.967932768186779],
            [40.0602215696576, 9.9755156586948],
            [40.058912037975801, 9.97730697646575],
            [40.057516028176501, 9.97845537465645],
            [40.044261594568397, 9.98933210220858],
            [40.0432254166343, 9.992311684856521],
            [40.040800649883401, 9.993095877353991],
            [40.033176559133899, 9.994489737104759],
            [40.030684168106703, 9.99445905364788],
            [40.009786195223299, 9.991168257100661],
            [40.000397082925403, 9.99289342736807],
            [39.992387685885902, 9.9932961606176],
            [39.9610834257319, 9.994084634380609],
            [39.948387861011597, 9.992497874634539],
            [39.946276300867702, 9.99422667035881],
            [39.922717749877997, 10.010728368997301],
            [39.941597755392003, 10.043057371918501],
            [39.944327244161997, 10.057379935967401],
            [39.944495603717598, 10.0589846519907],
            [39.9429645753345, 10.0598605884999],
            [39.941902747804001, 10.060484225415101],
            [39.9404292376502, 10.0613362489288],
            [39.939629261838597, 10.0617929594813],
            [39.937781836930903, 10.0625273432186],
            [39.935963907307297, 10.0626172912537],
            [39.934236956360699, 10.0626131473859],
            [39.932536485053703, 10.0622980523016],
            [39.928282466313398, 10.0891237404904],
            [39.9481495505431, 10.084451552993899],
            [39.969147570596398, 10.084927308978401],
            [39.980156300435802, 10.0878394054916],
            [39.988928713901203, 10.0883026108839],
            [39.993540659179999, 10.076885030691299],
            [40.023986507214801, 10.0743447678511],
            [40.047393375872097, 10.076791631056301],
            [40.0690028584781, 10.079049080123699],
            [40.107128800932102, 10.071167438345],
            [40.132562902182698, 10.0710810049377],
            [40.158123744940099, 10.090266122595599],
            [40.150250802890604, 10.109538790437499],
            [40.135964718857402, 10.144505126039],
            [40.128971638899401, 10.173533748551399],
            [40.136140760805297, 10.193326578418199],
            [40.143366182824998, 10.213271762422201],
            [40.149846824219097, 10.233317170212199],
            [40.156971586417903, 10.255351283586601],
            [40.154143594682097, 10.259445277421101],
            [40.146637715935803, 10.2772046942733],
            [40.157475915889798, 10.2853530681306],
            [40.160310391665298, 10.298872965857401],
            [40.160416877060797, 10.302697622743899],
            [40.159665261154203, 10.306434940008799],
            [40.158877392835898, 10.308383073274401],
            [40.161198133778598, 10.3106082520104],
            [40.160006393688398, 10.338071637791399],
            [40.1590722338278, 10.3562665116551],
            [40.160096878292002, 10.360301466800999],
            [40.160687497235102, 10.362418693608101],
            [40.161384483894402, 10.3648949757059],
            [40.163035413113398, 10.3747917700887],
            [40.163418182561003, 10.381496596548899],
            [40.163617829539703, 10.4123514326718],
            [40.161565922920502, 10.4353281442054],
            [40.155269655348597, 10.439423180458601],
            [40.1506104613809, 10.4703920475957],
            [40.147913514980402, 10.4883163681816],
            [40.143459513291504, 10.5179153917774],
            [40.141759485752203, 10.5292119346947],
            [40.140160063236699, 10.539839476908901],
            [40.137426095466701, 10.558004511962601],
            [40.131579626035602, 10.5968449278474],
            [40.128474258794597, 10.617472480694801],
            [40.122657904485202, 10.6561029565587],
            [40.114488054861198, 10.6978513515619],
            [40.1094321513892, 10.723682996426099],
            [40.1086769080854, 10.7240618806317],
            [40.102523772681003, 10.7396720798993],
            [40.102588850233502, 10.755034046765999],
            [40.107515510390598, 10.7620187806041],
            [40.109287393271003, 10.7656079717321],
            [40.114669052814499, 10.765221265940999],
            [40.119721092632403, 10.7672036680747],
            [40.122369623432398, 10.7682407333499],
            [40.123936989567099, 10.7690364302616],
            [40.126860094925199, 10.7722697809346],
            [40.129216501679601, 10.7740425486792],
            [40.130388150050102, 10.775388983958701],
            [40.132115529493099, 10.7778388196744],
            [40.134274775805601, 10.7794506285388],
            [40.137667790787098, 10.7800632079261],
            [40.139269791467598, 10.7812260384339],
            [40.141428242770999, 10.783216446701701],
            [40.1432400198333, 10.7840687732372],
            [40.1666733366892, 10.805036235867201],
            [40.164066353869501, 10.8285957707309],
            [40.1663844636467, 10.8327023805997],
            [40.1677868137552, 10.835347590120801],
            [40.168148642575296, 10.8417990464003],
            [40.167938596698903, 10.842327690832599],
            [40.166930602508501, 10.847455131611699],
            [40.168971131066201, 10.8729715569586],
            [40.170887926575098, 10.874053834499801],
            [40.175835177915097, 10.8777704758774],
            [40.178487699929903, 10.884314795110701],
            [40.180479583816698, 10.8968498655271],
            [40.156993649041297, 10.8961450639916],
            [40.153096418673897, 10.897395198226301],
            [40.1509224471774, 10.8972666253458],
            [40.149900333267297, 10.896749408164199],
            [40.148239782193201, 10.8951144629489],
            [40.146625754867699, 10.8939755305924],
            [40.132921012875798, 10.9151492380654],
            [40.123749437505403, 10.937627197863],
            [40.143427764134401, 10.951452738405299],
            [40.144191148869602, 10.9522161132262],
            [40.144700071427501, 10.952640209008599],
            [40.146396485044299, 10.9557785315478],
            [40.147583975204903, 10.957983837892099],
            [40.148686643741399, 10.960019506235501],
            [40.150213415008402, 10.9623944506613],
            [40.150976803336903, 10.9643453002657],
            [40.151485726793098, 10.9651086762227],
            [40.1519098322185, 10.9666354275163],
            [40.153351785454703, 10.9696041107523],
            [40.154199996305501, 10.9726576154636],
            [40.155302664842097, 10.974523644503099],
            [40.166838288036601, 10.9985275673586],
            [40.168280237679497, 11.000563234031199],
            [40.169807008048203, 11.002683720482899],
            [40.170994496412199, 11.004464929162101],
            [40.172775728958101, 11.007009512827601],
            [40.174556960605798, 11.0095540965568],
            [40.175659628243999, 11.0110808462936],
            [40.177101577886901, 11.0132013337497],
            [40.181427425917398, 11.0190538748544],
            [40.186917002770898, 11.0253308304932],
            [40.193158375194102, 11.0478536130463],
            [40.193972484200103, 11.0559943833326],
            [40.194775045363102, 11.060054046863],
            [40.1954907627944, 11.0682128501733],
            [40.193774384120303, 11.0963553150909],
            [40.212415912977598, 11.1088962310314],
            [40.2098620610154, 11.117306666675001],
            [40.207527696223103, 11.124993921127301],
            [40.2064389919977, 11.128578990037999],
            [40.205804725711502, 11.130667577999599],
            [40.203765308369803, 11.137383033729099],
            [40.201525860468699, 11.1447568526951],
            [40.200019016529701, 11.149718243812901],
            [40.197879013927199, 11.156764113479699],
            [40.195975902455103, 11.163029778655201],
            [40.1942397051493, 11.168745708028],
            [40.192146235278599, 11.175637597738699],
            [40.190297990209103, 11.1817219498735],
            [40.189298573133001, 11.185011899613601],
            [40.188814628231498, 11.1866049620374],
            [40.188345092307301, 11.188150574865601],
            [40.187871058518397, 11.189710982489],
            [40.1867454326364, 11.1934162143422],
            [40.185770149698797, 11.196626496122599],
            [40.185025835298703, 11.199076472474299],
            [40.183881242387898, 11.202843928357799],
            [40.1828249790223, 11.206320568919001],
            [40.181168429044597, 11.2117728843747],
            [40.179695674373903, 11.2166201124067],
            [40.172626942794302, 11.2398832283363],
            [40.145597664466102, 11.2556137265814],
            [40.142454628170299, 11.2616378868958],
            [40.140533885464002, 11.265741301332801],
            [40.138351217377199, 11.268709730772001],
            [40.136081243824499, 11.2720273864918],
            [40.133636657543498, 11.276043495219101],
            [40.129620554911199, 11.2833772575063],
            [40.127263274994398, 11.287131446739201],
            [40.125517147711399, 11.291584086182899],
            [40.1238583214027, 11.2944652068363],
            [40.1228979478038, 11.2957748084599],
            [40.121588348748297, 11.2980447821304],
            [40.102555459854401, 11.316903056993199],
            [40.078982557379703, 11.322490751956],
            [40.071212233478299, 11.325633811825201],
            [40.064934199910098, 11.3377474329499],
            [40.065711963978003, 11.3492065961752],
            [40.067196221575898, 11.360556442233101],
            [40.0686804648007, 11.3671917331481],
            [40.070164707127198, 11.373652413375501],
            [40.067982144143201, 11.408051197159599],
            [40.0667599071474, 11.426647494421299],
            [40.067270138550697, 11.430840168047601],
            [40.067042359930603, 11.4338014070954],
            [40.066814575920702, 11.4353959208833],
            [40.0670423716087, 11.4374460074157],
            [40.067042375202, 11.438357158092],
            [40.067042390473297, 11.4429129088749],
            [40.063853394570899, 11.458174685058699],
            [40.062942256734402, 11.4638693771189],
            [40.060892188966697, 11.4741198238879],
            [40.0588421050292, 11.479131155517001],
            [40.056564237081801, 11.4859647898401],
            [40.053147412253701, 11.489381614888501],
            [40.023990549633503, 11.530839044074099],
            [40.009412125959201, 11.5540734210854],
            [40.006450883524003, 11.5586291813375],
            [39.9937728907489, 11.5816690744083],
            [39.992810840893704, 11.5859021332413],
            [39.9923298397715, 11.595330303943999],
            [39.9927146798358, 11.5994671520436],
            [39.995216062998999, 11.604085022715299],
            [39.999449171654398, 11.611300443977299],
            [40.015263342986501, 11.6455409250229],
            [39.9940909743096, 11.681146163308799],
            [39.969633435197601, 11.664469180188901],
            [39.951966562253901, 11.681294770160701],
            [39.948741653503099, 11.683117546659901],
            [39.947650642404, 11.683602442850299],
            [39.947760158715198, 11.6836784016678],
            [39.923923882775703, 11.7007844387115],
            [39.919296851189998, 11.7072342414593],
            [39.912847047162998, 11.715366603757699],
            [39.9122861947952, 11.7164883068038],
            [39.910323215100902, 11.720133845871599],
            [39.907238535162598, 11.726583643840501],
            [39.9021908746316, 11.736538767479299],
            [39.885084883700202, 11.761777123322201],
            [39.879195926651199, 11.767385653188301],
            [39.874849338317397, 11.7787429022698],
            [39.874288494932699, 11.7821080107549],
            [39.873946444320303, 11.7839399149796],
            [39.874428724643501, 11.786735031912301],
            [39.873587455973201, 11.790941418590901],
            [39.873867897428497, 11.795147800602599],
            [39.873026623368297, 11.797531420577799],
            [39.873447275669797, 11.8007563122246],
            [39.874569062152197, 11.8233305649589],
            [39.8779343111066, 11.8583837473098],
            [39.878074542614002, 11.8635716192684],
            [39.878074607292703, 11.8826405569167],
            [39.880458266237902, 11.8902120375456],
            [39.887077936295199, 11.9057267532579],
            [39.885789956993698, 11.9138527493655],
            [39.884170972764402, 11.9212717478713],
            [39.883342985685601, 11.926579745681799],
            [39.880597046067798, 11.948845731849699],
            [39.878719084728701, 11.963314724519501],
            [39.876775102606103, 11.9720007213947],
            [39.876274115765398, 11.976698718668001],
            [39.873571119211498, 11.982327721513499],
            [39.871008122399601, 11.9878157255011],
            [39.869847128048299, 11.9913827255402],
            [39.866716121990699, 11.995176732122999],
            [39.864811131243101, 12.0010907330535],
            [39.863510137149703, 12.005032733395799],
            [39.863049143305403, 12.0075687310598],
            [39.861794149242797, 12.011417731940501],
            [39.860957152061701, 12.0137647320689],
            [39.858673154010702, 12.018360735022],
            [39.857139159390499, 12.022771735735899],
            [39.855464165203401, 12.027135737420901],
            [39.8527091615844, 12.031166742567001],
            [39.850798164700699, 12.0350127455796],
            [39.849027165762401, 12.0385767474289],
            [39.847627166545003, 12.0411087495342],
            [39.846603167969398, 12.043454750818499],
            [39.844734164630303, 12.045749754589901],
            [39.841240170926, 12.0535367582848],
            [39.843480224291497, 12.0652967392632],
            [39.859764385197103, 12.08331966553],
            [39.8617024141843, 12.0883576540965],
            [39.843813727433997, 12.0945515961334],
            [39.828578253502997, 12.099825753483],
            [39.819129278372898, 12.123426761167],
            [39.8167843179518, 12.138644753455001],
            [39.812806355989899, 12.1566287479623],
            [39.810438369053202, 12.164373748491],
            [39.807079369678199, 12.170375752561799],
            [39.7998384103973, 12.1949737520494],
            [39.802225473059103, 12.208754729929799],
            [39.803083493512801, 12.2130827220253],
            [39.803603555180302, 12.229675704010701],
            [39.807524609767299, 12.238857681287],
            [39.8275567378709, 12.240446611681699],
            [39.82558878151, 12.2564186010322],
            [39.825304831235201, 12.270987586334],
            [39.824307864763199, 12.2822165770417],
            [39.823632894014899, 12.2916135698155],
            [39.824714527600797, 12.3177295694873],
            [39.817588970392897, 12.3240185561553],
            [39.817554867649697, 12.341807746057],
            [39.831307788087003, 12.3549394460613],
            [39.831615160136202, 12.355247070549],
            [39.8317125806322, 12.355513519316601],
            [39.831683808491903, 12.3556854110165],
            [39.831537993955003, 12.356291689758899],
            [39.831384189801902, 12.3567352320807],
            [39.831233044662099, 12.3571109496365],
            [39.830073471445601, 12.3583544221948],
            [39.830158355053399, 12.3586559484381],
            [39.830267851601697, 12.358923489727299],
            [39.830296304839997, 12.3595574924571],
            [39.8300624365407, 12.3599526775561],
            [39.829768352371197, 12.3603932573953],
            [39.829654442399899, 12.3610921206615],
            [39.829463052735299, 12.3616499363425],
            [39.829285708230302, 12.3621048532149],
            [39.829289994990802, 12.362369326793401],
            [39.829428822431403, 12.362822145225699],
            [39.829439820505399, 12.362845846961701],
            [39.829596010583899, 12.3635167576171],
            [39.8296893096093, 12.3639335464539],
            [39.829834885194401, 12.3641568968337],
            [39.829964509394898, 12.3653331247226],
            [39.829679370649004, 12.3658776639413],
            [39.829472279331597, 12.366560988309001],
            [39.8293802352527, 12.3669037462228],
            [39.829372988543298, 12.367156951739799],
            [39.829463580046401, 12.367262922023199],
            [39.829518061970099, 12.367402909993199],
            [39.829657429298202, 12.3678432930064],
            [39.829511944442999, 12.368012151092699],
            [39.829233077734401, 12.3683600016707],
            [39.828754211907601, 12.368726547928601],
            [39.8282763818383, 12.3690580512851],
            [39.828035133878799, 12.369304060396701],
            [39.827411312304498, 12.3698270268572],
            [39.826636867511503, 12.3707144026361],
            [39.826322891946702, 12.3710510550202],
            [39.826129252002403, 12.371264139220299],
            [39.825851885480297, 12.3715430350374],
            [39.8253908709748, 12.3721050594911],
            [39.824594922310602, 12.372922419259099],
            [39.824415938380099, 12.373043904035301],
            [39.823984280818102, 12.373802507848501],
            [39.8239027218751, 12.374191573152901],
            [39.8237160618405, 12.374979949688999],
            [39.823801027195103, 12.375672554636999],
            [39.823820549382802, 12.3762127710944],
            [39.823816384793197, 12.376350678247],
            [39.823807589388203, 12.376661532828001],
            [39.823988237896899, 12.3768847801952],
            [39.824238106988098, 12.3775474955282],
            [39.825022596742599, 12.3787432070246],
            [39.824926830045101, 12.378820357778601],
            [39.823938556570099, 12.378999691427699],
            [39.8236862656209, 12.379211821518],
            [39.822905450874202, 12.3799138414405],
            [39.822593003343698, 12.3801928412073],
            [39.822372187565399, 12.380542769975101],
            [39.822182318748602, 12.381044061752201],
            [39.822120663777397, 12.381548356858399],
            [39.822118041595097, 12.3816286137326],
            [39.822238114009203, 12.382333546328301],
            [39.822533472888097, 12.3826525186757],
            [39.822945010882698, 12.3829858320124],
            [39.823418823686097, 12.383194623804201],
            [39.823910428521899, 12.3835762942975],
            [39.824039845211701, 12.383960196372801],
            [39.824693896891901, 12.3848070465307],
            [39.824603419474798, 12.385103456773299],
            [39.824422346063002, 12.385293895859601],
            [39.823754490870101, 12.385723176691499],
            [39.823171944188097, 12.3860402979545],
            [39.822946343084297, 12.386149491184],
            [39.822792465269401, 12.3862132549199],
            [39.822624973486299, 12.386335834654799],
            [39.8223869073597, 12.3868689220712],
            [39.8223754439583, 12.386880259883201],
            [39.822326250416701, 12.3869595289159],
            [39.821762029366496, 12.387438223164599],
            [39.821298279491003, 12.3876894232094],
            [39.820984276976702, 12.3880260703733],
            [39.820849770432602, 12.388217497032599],
            [39.820592869329303, 12.388612744931001],
            [39.820550504780499, 12.388853623899699],
            [39.821119708785602, 12.3894102572159],
            [39.821252211188302, 12.389678861989101],
            [39.821291525956703, 12.3899319269696],
            [39.821124571366198, 12.390043200624],
            [39.820949201358097, 12.390038081035],
            [39.820413641057101, 12.389942502193399],
            [39.820206936015303, 12.390212135600001],
            [39.820084969952497, 12.390369615236301],
            [39.819845782728301, 12.390547795074699],
            [39.819516533108697, 12.390607568237501],
            [39.819040122377601, 12.390490331193],
            [39.8182826925744, 12.390779714975199],
            [39.8180890670031, 12.3910040970128],
            [39.818003214113098, 12.391127557150201],
            [39.817972921125097, 12.391368400455599],
            [39.818306953375298, 12.391550495394601],
            [39.818386554889202, 12.391633895504601],
            [39.817906083773003, 12.3920569452333],
            [39.8198726621183, 12.393251352251299],
            [39.820225486225901, 12.3931915077491],
            [39.820707727023198, 12.3935155661609],
            [39.820879779553003, 12.3948431260376],
            [39.821058898230703, 12.3951251534554],
            [39.821351109412902, 12.395535687911799],
            [39.8213868030724, 12.395916484907399],
            [39.821375301043503, 12.395915389945401],
            [39.821304691665503, 12.396315724585],
            [39.821097987522002, 12.3965864902289],
            [39.820977636630097, 12.3967100565169],
            [39.814794154338799, 12.4064955454106],
            [39.815019559609901, 12.4076059350524],
            [39.821125095084, 12.4141611633683],
            [39.822058448257501, 12.4154875336827],
            [39.833345419578002, 12.4334706833005],
            [39.838980500151301, 12.438954257451901],
            [39.8448489819001, 12.446469195875601],
            [39.844852752129299, 12.446744969898701],
            [39.844952342954997, 12.44695488863],
            [39.845092668785497, 12.4469589693703],
            [39.8454843773685, 12.4471657107082],
            [39.845857353382897, 12.447624559871601],
            [39.846021257294701, 12.447628566916601],
            [39.846055841534799, 12.447653323225],
            [39.845986333491403, 12.448029922858799],
            [39.845936598265702, 12.448120500515101],
            [39.845908942731398, 12.4482810854694],
            [39.845991806028103, 12.448651555227199],
            [39.846407097183999, 12.448859350482399],
            [39.846712327649499, 12.4488210916276],
            [39.847154810809002, 12.448902211095],
            [39.847314065939202, 12.4490678604844],
            [39.8476848114367, 12.449193283423],
            [39.8481907713509, 12.449518339622299],
            [39.848360456819798, 12.4497076903462],
            [39.848613453844898, 12.4498753032531],
            [39.849015169660099, 12.450150953482099],
            [39.849245091660499, 12.450296034602299],
            [39.849748402442799, 12.450688911022899],
            [39.849827532341202, 12.4507949069672],
            [39.8499495963204, 12.4510171846271],
            [39.849922979238499, 12.4511427285526],
            [39.849796354512698, 12.4514607349976],
            [39.849661379048001, 12.4516872146817],
            [39.849510712710199, 12.452039204327001],
            [39.849419711575301, 12.452348055391401],
            [39.863090361603902, 12.4633348585321],
            [39.882550193701299, 12.457651491866701],
            [39.8820276607685, 12.466944452668701],
            [39.871106546313698, 12.4674695271462],
            [39.8713691014134, 12.4701338374802],
            [39.866263575903403, 12.4726131782664],
            [39.881092604389302, 12.4835732809135],
            [39.880994046626199, 12.4853259429971],
            [39.868543071598097, 12.492987858560101],
            [39.867776018143303, 12.495623850721801],
            [39.863429406453399, 12.5062781631243],
            [39.860878424608501, 12.517156101210301],
            [39.862450991090299, 12.517832591618999],
            [39.864727361546201, 12.520496063859699],
            [39.864576186762001, 12.5208717971854],
            [39.864421404343602, 12.5213730029853],
            [39.864280657203203, 12.521771309422],
            [39.864173009387699, 12.5222633204496],
            [39.864100951027197, 12.522732610663899],
            [39.8640844247209, 12.523295534700001],
            [39.863979843753803, 12.5236722487533],
            [39.863814054278301, 12.524150882826699],
            [39.863524577567901, 12.524453596057599],
            [39.863201006199098, 12.5247089490405],
            [39.862948612841997, 12.5249199901489],
            [39.862852273917603, 12.525009591169001],
            [39.862669106532898, 12.5252803127779],
            [39.862697986470998, 12.5254881885602],
            [39.862642110362003, 12.5258093623127],
            [39.862895004080798, 12.526781702215899],
            [39.862921358854599, 12.5283006880692],
            [39.862821953081898, 12.528505586011001],
            [39.862942384822098, 12.5296015502458],
            [39.862859811681197, 12.530024534524101],
            [39.862598172863102, 12.530569033508799],
            [39.862758834755098, 12.5314817664875],
            [39.8633420327156, 12.531970418826701],
            [39.863385258748799, 12.5325116743913],
            [39.863598043588397, 12.532839893259199],
            [39.864372014969199, 12.5336093631325],
            [39.864134998074803, 12.534119876205301],
            [39.863616710579997, 12.5342261492603],
            [39.863707134098199, 12.5343269644149],
            [39.863460444144799, 12.5343537556145],
            [39.862309274667801, 12.534494229601099],
            [39.8530007816751, 12.5461951492265],
            [39.852372328387098, 12.547684579199499],
            [39.852122015528103, 12.547837962049],
            [39.851897368435203, 12.5479245781648],
            [39.840526566332301, 12.546720666785101],
            [39.835948566591497, 12.549926891221],
            [39.835404743586501, 12.5496934959463],
            [39.835105623461502, 12.549523760890001],
            [39.834649884761603, 12.549487885314001],
            [39.834217196832, 12.5494643698594],
            [39.8338085990233, 12.549418173708499],
            [39.833480196514998, 12.5494440636866],
            [39.8333387729432, 12.5494738916108],
            [39.833066439681701, 12.5495821163434],
            [39.832935485076199, 12.549646949095401],
            [39.832766202052497, 12.5497785883452],
            [39.832112662411902, 12.549594128352901],
            [39.831633787602001, 12.5493413087104],
            [39.831295168553901, 12.5490530130288],
            [39.830641159992801, 12.549972824225099],
            [39.830610760105301, 12.550536921603101],
            [39.8305199027008, 12.550905671749099],
            [39.830370941365402, 12.551274601997299],
            [39.830186740918101, 12.551598430260899],
            [39.825884192491202, 12.560943257398201],
            [39.826803222925697, 12.5719593901349],
            [39.815697941261298, 12.578793268031699],
            [39.815296028714997, 12.5796003796534],
            [39.815370893412499, 12.5803483889086],
            [39.815458666002201, 12.5811754775441],
            [39.815543696933801, 12.581681577380801],
            [39.8155849080457, 12.5825212411967],
            [39.815519415471599, 12.5830967489171],
            [39.815175606856201, 12.5839036821826],
            [39.814456445284101, 12.584460841143899],
            [39.814003762652902, 12.584682627585901],
            [39.812773684263, 12.5854255764461],
            [39.812346251477798, 12.5859920140316],
            [39.8117697756105, 12.5869160705236],
            [39.811054096806799, 12.587852981700101],
            [39.806243487306297, 12.594842476579499],
            [39.8071851696591, 12.597701469391399],
            [39.808230400814701, 12.602009146208401],
            [39.808562672366897, 12.602824191426301],
            [39.808834917593501, 12.603397541693999],
            [39.809047392514401, 12.6038105735919],
            [39.809556611516399, 12.6049008656168],
            [39.818680117137603, 12.618742452646799],
            [39.824315455527397, 12.6225272501013],
            [39.832556267567199, 12.6318759547732],
            [39.832545983653802, 12.636857043826099],
            [39.8233177398604, 12.645657775843601],
            [39.820055418074602, 12.658816214422901],
            [39.822470009608303, 12.673377789088599],
            [39.826081926058201, 12.6937304028746],
            [39.839391508139897, 12.7132633423057],
            [39.8497233915487, 12.7178010301407],
            [39.867583201055801, 12.7237266945918],
            [39.869527914407101, 12.732824024414001],
            [39.869991412754302, 12.7349921480742],
            [39.888625540391999, 12.755764710500101],
            [39.899026605755701, 12.762297013202501],
            [39.909194834984298, 12.7645771069435],
            [39.918744196847399, 12.7667180280877],
            [39.920363438893098, 12.767039345392901],
            [39.925775438137002, 12.7653121290983],
            [39.929294164257698, 12.7688113291051],
            [39.942685480453797, 12.771467736401201],
            [39.953656608612, 12.778167056119599],
            [39.962085711128701, 12.781128022862701],
            [39.963407382643297, 12.7822533945131],
            [39.964265213552501, 12.783137464651499],
            [39.977589805584202, 12.7897835943714],
            [39.977817227573098, 12.790076601594301],
            [39.978832008535498, 12.7917715222972],
            [39.979222685852598, 12.792588335296299],
            [39.979418964148898, 12.7942060572547],
            [39.979162821223703, 12.795122478576101],
            [39.977975539472297, 12.795729301204201],
            [39.9769171920152, 12.7958779068864],
            [39.9761538180598, 12.7959180419688],
            [39.974082309302901, 12.7967360907243],
            [39.973025460235696, 12.796827033825499],
            [39.972015299308801, 12.797380055406],
            [39.971110209829199, 12.798454840236801],
            [39.970827713926802, 12.8004653941108],
            [39.970375025905703, 12.8022426225437],
            [39.970233308584803, 12.803276156018599],
            [39.970143376445101, 12.8045999618344],
            [39.970673986130599, 12.806918314297],
            [39.971048315906103, 12.808425754430599],
            [39.970974468101502, 12.8115363561691],
            [39.970466964881801, 12.8131386064821],
            [39.969365869295302, 12.815073069532801],
            [39.9681172918987, 12.815794233608401],
            [39.967272373373397, 12.8168122766868],
            [39.965871137175803, 12.819028127248099],
            [39.965442638988598, 12.8197677304375],
            [39.965014715723299, 12.8205073314545],
            [39.9654980614484, 12.8223636125323],
            [39.965750023614298, 12.8240986814672],
            [39.965189752456403, 12.825469426116699],
            [39.963008940288901, 12.825937443434899],
            [39.962437740227301, 12.8277693477244],
            [39.961727552824897, 12.828040945101799],
            [39.960315651828203, 12.828239388458201],
            [39.959936462167001, 12.829383418218599],
            [39.959794698133699, 12.830418078825501],
            [39.958323098696503, 12.8306721118519],
            [39.957285473576498, 12.8323779996488],
            [39.956521049490497, 12.8324757302045],
            [39.9560522259303, 12.8324650094499],
            [39.954757208328502, 12.8326652597563],
            [39.952859633173397, 12.8360232422091],
            [39.952172352810798, 12.837794519591499],
            [39.951228207277502, 12.8404822001739],
            [39.949998424433304, 12.8428748001578],
            [39.948809456544097, 12.8460177067833],
            [39.9483913428816, 12.8487746750317],
            [39.947231186658499, 12.850708145875499],
            [39.945675803151403, 12.8519988242483],
            [39.944497686012397, 12.8546805498298],
            [39.9444782518596, 12.855487590612],
            [39.9431671229732, 12.8588004505564],
            [39.941958141517702, 12.860329463788201],
            [39.9402672264887, 12.862365411115499],
            [39.939241822049802, 12.8635524294539],
            [39.936837153306797, 12.866032834199499],
            [39.936471795904303, 12.8666015111134],
            [39.933611594175701, 12.868495812331499],
            [39.932246647220602, 12.8691550800892],
            [39.9307428315023, 12.870734829570999],
            [39.929530689773699, 12.872379086586101],
            [39.929036821183303, 12.8734048179721],
            [39.928691223123899, 12.8755873594524],
            [39.928959465457602, 12.8766307218108],
            [39.929054471282001, 12.8775560282341],
            [39.9292475075585, 12.879289087971101],
            [39.929384823134498, 12.8809070639964],
            [39.929358342596601, 12.8820012047824],
            [39.928964010932901, 12.883779303409501],
            [39.9272615363829, 12.891175819926399],
            [39.926282232585997, 12.892882532030701],
            [39.925313889133903, 12.894128073535301],
            [39.924245053845901, 12.8946800175958],
            [39.922807752984603, 12.8959159117968],
            [39.9216612134245, 12.8972728334771],
            [39.920582310717201, 12.8982271561974],
            [39.920102381285098, 12.8986775450227],
            [39.918727772949097, 12.8997414045709],
            [39.917309203758101, 12.902648792986],
            [39.916997959071701, 12.9033901786031],
            [39.916812774969102, 12.903789796137801],
            [39.916284318545401, 12.906256653246301],
            [39.915326865369401, 12.9070409973842],
            [39.9132910125427, 12.9087807641917],
            [39.912815027613, 12.9090582043693],
            [39.912457516096303, 12.9092809798888],
            [39.910915634267901, 12.909995022498901],
            [39.909418466082698, 12.9112853105637],
            [39.907676735154801, 12.9129742798208],
            [39.906415547208297, 12.9142140001422],
            [39.905145223395301, 12.9157984631383],
            [39.904413383001298, 12.9194131802216],
            [39.904502818372698, 12.920567950493799],
            [39.904571181962503, 12.9225874096742],
            [39.903412840336202, 12.9244054323814],
            [39.900336799495904, 12.925486412438399],
            [39.897561037607304, 12.9262871583155],
            [39.896561796600999, 12.926379890507601],
            [39.887538224962, 12.9308401734448],
            [39.885189161036699, 12.9309578137755],
            [39.879992731409899, 12.934814818521399],
            [39.879261565650701, 12.9359509058071],
            [39.873229511468502, 12.941952387050501],
            [39.872393997405901, 12.9434154421761],
            [39.871724342887397, 12.9445287013609],
            [39.871675999152103, 12.944874710995499],
            [39.871566969727802, 12.9451666747457],
            [39.867276687796, 12.9526009603461],
            [39.866730498628598, 12.953939840794],
            [39.866124193304003, 12.955224669072001],
            [39.862341298709303, 12.962010704038001],
            [39.859987789919998, 12.9696215423476],
            [39.861032543171902, 12.977310379786999],
            [39.8614827446563, 12.9866863471094],
            [39.861887214706201, 12.9924920841051],
            [39.861980717649203, 12.9936988505793],
            [39.862063026685398, 12.994502164088001],
            [39.861011607035898, 12.9945893003983],
            [39.859465677398802, 13.001332825749101],
            [39.859747628023797, 13.001873264881],
            [39.860087704139197, 13.002389776098299],
            [39.860240803114699, 13.002666171963901],
            [39.860493908835899, 13.0037427482517],
            [39.860714579985498, 13.004681353807801],
            [39.860932621766203, 13.005608768089701],
            [39.861159696107102, 13.0065745970601],
            [39.8614734426015, 13.007909057321999],
            [39.861676959522399, 13.0087746687735],
            [39.861848049058203, 13.0095023549624],
            [39.862013722651099, 13.010206990913799],
            [39.862361774009599, 13.011687314014001],
            [39.862492259694498, 13.012917687920201],
            [39.862504685191503, 13.013342609832099],
            [39.862823162214198, 13.0151012996402],
            [39.862953828460498, 13.0162163908983],
            [39.863154614502903, 13.0176070205078],
            [39.863202819897701, 13.0183765384902],
            [39.863204029030101, 13.0193880813933],
            [39.863064868314503, 13.0203074148983],
            [39.862901335406903, 13.0208482065234],
            [39.862691379362097, 13.021629889558801],
            [39.861476124072702, 13.024031141899499],
            [39.861078906122003, 13.024996543758],
            [39.860915537606097, 13.025857868315001],
            [39.8607339225102, 13.026815391532899],
            [39.860492237969503, 13.028089607478501],
            [39.860223242847603, 13.0295077913519],
            [39.860057294370201, 13.0303826962052],
            [39.859896822022797, 13.0312287222382],
            [39.859769151658, 13.0319018057194],
            [39.859536909309298, 13.033126198936399],
            [39.859286875826299, 13.0344443693191],
            [39.858659115139503, 13.0353103869089],
            [39.858182133978097, 13.035968391583999],
            [39.857622513200504, 13.0367403957324],
            [39.8572405639148, 13.0372672959368],
            [39.856855718460501, 13.0377981892404],
            [39.856338369705199, 13.038511867324599],
            [39.854888123222402, 13.040512440195499],
            [39.854256543797199, 13.0415588555725],
            [39.853543664143999, 13.04276603075],
            [39.852911730782601, 13.043880257931001],
            [39.852409508758399, 13.044834693961899],
            [39.851824555489799, 13.045868518067101],
            [39.8513805640589, 13.046661139057599],
            [39.8508077084023, 13.0476960520555],
            [39.850398179938999, 13.048454650388001],
            [39.850058797322902, 13.0489948860706],
            [39.849778640633602, 13.049661509239099],
            [39.8493695514465, 13.0507184843856],
            [39.848632976032597, 13.051752801075899],
            [39.848129504451798, 13.0523512153895],
            [39.847556373012402, 13.0531442557715],
            [39.847146980194701, 13.0539480590996],
            [39.846761573293399, 13.0548557628042],
            [39.846189206320197, 13.056212778253],
            [39.845979578160502, 13.057281525955499],
            [39.845758079662502, 13.058419255497199],
            [39.845619257611801, 13.059798579918001],
            [39.845234607092003, 13.0612691302152],
            [39.845024515401498, 13.061866576400501],
            [39.844124395401998, 13.063808988012299],
            [39.843726523477798, 13.064280463459699],
            [39.842977310564599, 13.065176922288],
            [39.842498831911598, 13.0664059302682],
            [39.842287307408299, 13.066949241890899],
            [39.841877785233201, 13.0680011141483],
            [39.841519054702601, 13.0689225202056],
            [39.841251287577698, 13.069610282320699],
            [39.840945927754703, 13.0703945956121],
            [39.840430701718397, 13.0717179338421],
            [39.839764512900402, 13.073428997132201],
            [39.839555157828499, 13.0745892866045],
            [39.839380736441299, 13.0756816504041],
            [39.839228806173899, 13.076267599794599],
            [39.838561988535197, 13.077336703436],
            [39.837965402982, 13.0780382542167],
            [39.8374271926508, 13.0787848232911],
            [39.837017652509402, 13.079566009005701],
            [39.836830739149903, 13.0800379189774],
            [39.836292800110002, 13.0810376551518],
            [39.835918058007799, 13.0813711577687],
            [39.8355434668225, 13.0817498695578],
            [39.835075567628699, 13.082324411152801],
            [39.834701287260501, 13.0827957987213],
            [39.834572547002203, 13.0830832933419],
            [39.834584546697798, 13.083392934663999],
            [39.834702990466297, 13.084323853031499],
            [39.834632548175001, 13.0844619689149],
            [39.8343874814773, 13.0851284648139],
            [39.833978390493499, 13.0860486624112],
            [39.8336273154057, 13.0865651824747],
            [39.833311138070101, 13.0870024734478],
            [39.833100954751501, 13.0874156850112],
            [39.832879406846303, 13.0882199909577],
            [39.8327519637552, 13.0897484640027],
            [39.832821298423802, 13.090905295797],
            [39.832916541403499, 13.0924943898336],
            [39.833029906995698, 13.094385794351499],
            [39.833110668234703, 13.095733215505399],
            [39.833157996873801, 13.0965228257649],
            [39.833243135603098, 13.0979432362581],
            [39.833209039148201, 13.0989435918708],
            [39.833105710432598, 13.100954590628399],
            [39.832874753573101, 13.1034146988054],
            [39.832665471264697, 13.1047818148825],
            [39.832398327570601, 13.1069425318176],
            [39.832188732648497, 13.108218100659],
            [39.832049426405803, 13.1091374210709],
            [39.831734828308498, 13.1107354412669],
            [39.831501543914001, 13.111826860890799],
            [39.831057701603498, 13.113056847958999],
            [39.830508503978201, 13.1145053072362],
            [39.829982671533301, 13.116045238998399],
            [39.829585317937102, 13.117044508790199],
            [39.829340290765202, 13.1177347358199],
            [39.828849225816903, 13.1189874758293],
            [39.828381990478, 13.120469574590899],
            [39.8278919612872, 13.1220331208145],
            [39.827283488327801, 13.123354051328199],
            [39.826956654278, 13.124411861794499],
            [39.826431323093097, 13.1262852012617],
            [39.825392169938702, 13.1296871214555],
            [39.8246437062204, 13.131226630035499],
            [39.824070793969803, 13.1323304388929],
            [39.823719638931998, 13.1330198715167],
            [39.823544621063498, 13.1334453976001],
            [39.822445666162402, 13.1360540855061],
            [39.821603139850701, 13.137582586755499],
            [39.820889384339999, 13.1388235968499],
            [39.820093742899601, 13.140064867482501],
            [39.819588636384999, 13.140719512382599],
            [39.8183248864433, 13.142357381934399],
            [39.817737959576, 13.143118052439],
            [39.817267273808199, 13.143728068325601],
            [39.816659523094302, 13.144515715392499],
            [39.815976837614301, 13.1454004723208],
            [39.815690187902099, 13.145771965740799],
            [39.815048574295197, 13.1466034839186],
            [39.814579939381197, 13.147179128444],
            [39.814264302831397, 13.1476378729113],
            [39.814042306666799, 13.148512239495],
            [39.813891231595598, 13.1493852514549],
            [39.8138797205835, 13.1499142315184],
            [39.813869388161102, 13.1507992279024],
            [39.813776591294001, 13.151683355961501],
            [39.813624502024801, 13.151901971629901],
            [39.812792592901403, 13.1524878103095],
            [39.812276972504698, 13.1528104303693],
            [39.811645066991197, 13.153717744199],
            [39.811587334962802, 13.1543960599714],
            [39.811575939833403, 13.1547871523655],
            [39.811577203763001, 13.155867640554201],
            [39.811519011797301, 13.1564069407712],
            [39.811332255642903, 13.1571308743597],
            [39.811168848499499, 13.157591393410099],
            [39.810935106862601, 13.1584070249087],
            [39.810702009317701, 13.1594181812471],
            [39.8104349392854, 13.161843351999501],
            [39.810342312199801, 13.1629580436084],
            [39.810319785147499, 13.163820474360501],
            [39.810333192503101, 13.164912225675099],
            [39.810404534008001, 13.1660840388667],
            [39.810222635044397, 13.1681532096264],
            [39.810148568050899, 13.1689957420946],
            [39.810042090740303, 13.1702069482885],
            [39.809929857025303, 13.171483630752601],
            [39.809734752827097, 13.173702953349199],
            [39.809705484816803, 13.174035871870201],
            [39.809606362911801, 13.175163369999501],
            [39.809373691168297, 13.1766582589545],
            [39.809046179788801, 13.177726223489501],
            [39.808543829305499, 13.179116855367001],
            [39.808029336294503, 13.1803244297825],
            [39.807374625250802, 13.182036522902701],
            [39.807090195275698, 13.1830351118974],
            [39.806721826720398, 13.184328388398299],
            [39.804952249815202, 13.186392130899399],
            [39.804472046396903, 13.187162194609],
            [39.8037940492245, 13.188644920351299],
            [39.803536912762603, 13.1895521669916],
            [39.803209956541899, 13.1909772744786],
            [39.803141018030402, 13.192115622760699],
            [39.803141659427503, 13.192838962714101],
            [39.804013928059902, 13.193834937135099],
            [39.803754091262299, 13.194747105126099],
            [39.8040240960906, 13.1952989322533],
            [39.804270731246802, 13.1957592850317],
            [39.804764511802297, 13.1964833278691],
            [39.805116654986897, 13.1969309137493],
            [39.805833292293102, 13.1978972483132],
            [39.806103380664702, 13.198471676382701],
            [39.806503290866303, 13.1995859378681],
            [39.806574293706298, 13.199954165596401],
            [39.8067156436163, 13.2006668859543],
            [39.806751165697499, 13.2007696235344],
            [39.806963368428903, 13.2018042323124],
            [39.8070460978767, 13.2022300630662],
            [39.8070462407088, 13.2024481945496],
            [39.8070350683616, 13.2029082286313],
            [39.807012023879601, 13.203091397711599],
            [39.806953709742999, 13.2035979199933],
            [39.806990012460197, 13.2042872385918],
            [39.807108175056101, 13.2051379190591],
            [39.807296627229597, 13.2059646415061],
            [39.807555788494199, 13.207336376278001],
            [39.8076417446904, 13.207791333991],
            [39.807822032076402, 13.2087455734183],
            [39.807994246302897, 13.2096570691709],
            [39.808231818356198, 13.210914488489999],
            [39.808259463110701, 13.2110608071462],
            [39.808689486637199, 13.2133367838652],
            [39.808877579484701, 13.214332280280701],
            [39.808906975954002, 13.2152186859987],
            [39.808927966887303, 13.2158516436954],
            [39.8089644905901, 13.216952933684601],
            [39.8089755254951, 13.2172856647343],
            [39.808981591818203, 13.217468584282299],
            [39.809004037123898, 13.218145352885401],
            [39.809017434597997, 13.2185493053915],
            [39.809030126894697, 13.218932028427],
            [39.8090473673616, 13.2194518296435],
            [39.809084541444697, 13.2205726800957],
            [39.808709786766002, 13.220975097322301],
            [39.8082876675157, 13.2210092135346],
            [39.807512487900802, 13.220331282786001],
            [39.807348065661103, 13.2201475792014],
            [39.806984107833898, 13.2198028861374],
            [39.806984071002901, 13.2197915839718],
            [39.8068437146297, 13.219734387519701],
            [39.806326975830501, 13.219411651975999],
            [39.806033423463603, 13.219250961353399],
            [39.805130419872, 13.2188141624135],
            [39.803405938394199, 13.218698675020701],
            [39.802889859856698, 13.2183996654457],
            [39.802654983647599, 13.2183653687184],
            [39.802069229081702, 13.2186294248853],
            [39.801693635376601, 13.218778666786401],
            [39.8011778389101, 13.2189170462701],
            [39.800978525206503, 13.2189979185986],
            [39.800006159996798, 13.2195717364568],
            [39.799665711978903, 13.219709561925599],
            [39.798696103698902, 13.2200697549177],
            [39.798989166483899, 13.227137222682201],
            [39.799079289066498, 13.2278840138778],
            [39.7984002857809, 13.2299815705314],
            [39.7977379606168, 13.2311839395619],
            [39.797231517409102, 13.2317223810323],
            [39.796337926365098, 13.232236038968599],
            [39.7958435681929, 13.232590213755699],
            [39.794981896087499, 13.2338734460526],
            [39.7942254813806, 13.235064798731299],
            [39.792621759527997, 13.2369990857452],
            [39.792299797041998, 13.2380421515126],
            [39.791791875207302, 13.238843928278801],
            [39.791083645234004, 13.2397830845499],
            [39.790280932255598, 13.2409395350229],
            [39.789078453704498, 13.242279189604499],
            [39.787590764542301, 13.2444436528382],
            [39.7874477644271, 13.2448215888534],
            [39.787269835118799, 13.245279879850701],
            [39.7870187263525, 13.2463012456457],
            [39.786685470246702, 13.247251661801],
            [39.785580530769103, 13.250232078976101],
            [39.7852239696699, 13.251114752459401],
            [39.784877069951598, 13.252490172277],
            [39.784600232537301, 13.253946750205801],
            [39.784673921340001, 13.255690451442099],
            [39.7852522495334, 13.257206548194601],
            [39.785620257864302, 13.258619314655199],
            [39.786011246896699, 13.2601800662017],
            [39.7862269925836, 13.261603474705201],
            [39.786021380791503, 13.262784058603399],
            [39.785523978228802, 13.2638276608614],
            [39.784849540181398, 13.2650763779688],
            [39.7839388236548, 13.266588034863799],
            [39.783064467337603, 13.267927783390901],
            [39.781990776655498, 13.2693596913658],
            [39.780846818853597, 13.2706527941224],
            [39.7799749634462, 13.2715223538004],
            [39.778664671789599, 13.273308735554201],
            [39.7780846394921, 13.2743288426348],
            [39.777364361314199, 13.2753358167871],
            [39.776810191513697, 13.2759659165645],
            [39.775856300157898, 13.276811981257399],
            [39.7741852908973, 13.278035465756201],
            [39.773161459408399, 13.2788466964659],
            [39.772383375540301, 13.2796481341119],
            [39.771675353976001, 13.2805296017813],
            [39.771130937184601, 13.281514550506801],
            [39.7706700286803, 13.2822709396644],
            [39.770395954483803, 13.283177079668301],
            [39.769781743086298, 13.283989313478701],
            [39.769287066910501, 13.284470029284],
            [39.768629349205597, 13.284663031197301],
            [39.768054499289001, 13.2847879688087],
            [39.7675356916697, 13.2853026608361],
            [39.7660898900508, 13.286067674243],
            [39.765186082466997, 13.2861687347827],
            [39.763188386480302, 13.284820752419],
            [39.762325195323797, 13.283889787266199],
            [39.762060046787902, 13.283051959376801],
            [39.7620181170237, 13.2820077595198],
            [39.761203441180797, 13.280903719625201],
            [39.760443722470299, 13.280397399233401],
            [39.7599888964588, 13.279971538168301],
            [39.759522678911097, 13.2794078235739],
            [39.758905232680398, 13.2786377296101],
            [39.758312024792602, 13.277683335682701],
            [39.758055103028099, 13.277442235453099],
            [39.757433093355701, 13.277612497985301],
            [39.756704012586297, 13.278046421617001],
            [39.755691446869399, 13.2787897377132],
            [39.754503909098098, 13.2794861027499],
            [39.753187523270597, 13.280125203970201],
            [39.752670584147097, 13.2803188772979],
            [39.751543056531702, 13.2806488568792],
            [39.750322018786797, 13.2809666772604],
            [39.749030987010002, 13.281227057311501],
            [39.748326959355502, 13.2813738049473],
            [39.746916763361199, 13.282286666308901],
            [39.746504839989399, 13.282298052126199],
            [39.746245803590703, 13.2823055963336],
            [39.745290168604903, 13.2822507664031],
            [39.743898989046897, 13.2818411904353],
            [39.742592210701403, 13.2822871191887],
            [39.742260300271901, 13.2824003793259],
            [39.741957436867096, 13.282503727646899],
            [39.741530304119202, 13.282649480450001],
            [39.740667230642003, 13.2829439898297],
            [39.739422293219, 13.283368795667601],
            [39.738088626500399, 13.284418145283899],
            [39.737689527477499, 13.284854444397499],
            [39.737017257963302, 13.285586527963],
            [39.7366865692426, 13.2859412519021],
            [39.736381403455802, 13.2863411095515],
            [39.735926649309498, 13.286882679427],
            [39.735618315164601, 13.287189866670101],
            [39.735365022593797, 13.2873816109727],
            [39.734956318784, 13.2875071224517],
            [39.734602588276204, 13.287494584595599],
            [39.734259065325098, 13.2874232461839],
            [39.733950124817397, 13.287339374258501],
            [39.732617810063303, 13.2869386081407],
            [39.731407616780501, 13.2866618084421],
            [39.730874181893498, 13.286561626749],
            [39.730329852238597, 13.2864840807449],
            [39.729077417476198, 13.2863577110772],
            [39.728101344919502, 13.2864192903217],
            [39.727787260658502, 13.286543387729701],
            [39.726687097523197, 13.287171563876401],
            [39.7260743009724, 13.2875451661552],
            [39.725683451178803, 13.2878548389698],
            [39.7251768085451, 13.2882258759804],
            [39.7245537586236, 13.2886458440203],
            [39.723674241485099, 13.289164872938599],
            [39.722795296573501, 13.2896838989979],
            [39.7211679894531, 13.2907871217311],
            [39.720539805659797, 13.2914263601696],
            [39.719674903212599, 13.292405339877501],
            [39.719306373858899, 13.293048358664199],
            [39.718981996701402, 13.2939885014446],
            [39.7188238914148, 13.2953508796497],
            [39.7188558328113, 13.295615264005701],
            [39.718981204387298, 13.295864688141201],
            [39.719240229107903, 13.2962369289589],
            [39.720227124567302, 13.2972807169993],
            [39.720908326928701, 13.29797500075],
            [39.722785569615603, 13.3003747774123],
            [39.723469949318101, 13.301161722845601],
            [39.723732792777, 13.301649230487399],
            [39.724193310514202, 13.302441334008201],
            [39.724664706849403, 13.3035905581186],
            [39.724843144604201, 13.3040229255228],
            [39.725354827684903, 13.304941465256],
            [39.725665670824398, 13.3054616097377],
            [39.726488223095799, 13.3065205341256],
            [39.726868818620403, 13.307016742350401],
            [39.727387600188599, 13.3077962381916],
            [39.727720208609, 13.308259805860001],
            [39.728210134493601, 13.3088438543487],
            [39.728647721834797, 13.309292425067101],
            [39.7289867361416, 13.309581916597301],
            [39.729203592146099, 13.3097372630533],
            [39.729854774607396, 13.3102146015339],
            [39.730181640098202, 13.3104928246097],
            [39.730675433230097, 13.310824814834399],
            [39.731132805965402, 13.3111343058013],
            [39.731638173889799, 13.311476433887],
            [39.732602181174002, 13.312164211543701],
            [39.733215014555803, 13.312550103808],
            [39.733808037390602, 13.3130626362498],
            [39.734569249101099, 13.3136628374733],
            [39.735150055746402, 13.314141495206499],
            [39.735476357993498, 13.314420843197301],
            [39.7358172937967, 13.3147679607619],
            [39.736122422752601, 13.3151050100484],
            [39.736627124126997, 13.315792978455599],
            [39.736858729570201, 13.3160511252019],
            [39.737692224219998, 13.317074938989199],
            [39.737705681881302, 13.3184215644749],
            [39.737713124423401, 13.3191663582211],
            [39.737717617796498, 13.3196159998807],
            [39.737721637757403, 13.3200182487106],
            [39.737722213577499, 13.32007582017],
            [39.737725206763997, 13.320375284197899],
            [39.737728897941501, 13.320744669975801],
            [39.737732804714703, 13.3211356186037],
            [39.737739731623797, 13.321828631155199],
            [39.737744466643697, 13.322302436117999],
            [39.737753624069697, 13.3232186634695],
            [39.737666580911899, 13.323439310568901],
            [39.737049867706403, 13.3244458805432],
            [39.736879733079803, 13.324669030038599],
            [39.736550900278502, 13.3250813911559],
            [39.736166371929599, 13.325586591963701],
            [39.735733302215898, 13.3260704595139],
            [39.734328381128996, 13.3274895879639],
            [39.735954600389498, 13.328251149126899],
            [39.736818908152003, 13.32876740265],
            [39.737232785359502, 13.329182117767701],
            [39.737609950218101, 13.3299032135136],
            [39.7377661384999, 13.330201825353999],
            [39.738247441456103, 13.331122009594401],
            [39.7385007483999, 13.331678469412999],
            [39.7386798796541, 13.332133425103001],
            [39.738898028334297, 13.3327035509484],
            [39.738974833392803, 13.333287651454601],
            [39.738922352915601, 13.3342451033496],
            [39.738967808567303, 13.334957012114399],
            [39.739162916358801, 13.3359182605704],
            [39.7392767607531, 13.336536159596401],
            [39.739409874908503, 13.3370387185787],
            [39.739612033576698, 13.337850756906301],
            [39.739652346373298, 13.338389755417399],
            [39.739629923525499, 13.3391730660653],
            [39.739508403027202, 13.339821039900601],
            [39.739485979281, 13.340604349405099],
            [39.739526914610202, 13.3415355335652],
            [39.739698433540703, 13.342139699711501],
            [39.740065033313201, 13.343315187104199],
            [39.740220492162997, 13.3441397925787],
            [39.740219176131099, 13.3448416650856],
            [39.740161625562401, 13.3456488130627],
            [39.740177637134103, 13.346166407807599],
            [39.7402915237491, 13.3467956074693],
            [39.740397069506798, 13.3471501875339],
            [39.740523138175497, 13.3474221999483],
            [39.741119452437502, 13.3487868872412],
            [39.741343379081599, 13.3495400875009],
            [39.741639584173498, 13.3503371534604],
            [39.741816192060099, 13.3510916214814],
            [39.741880825844802, 13.3516542820339],
            [39.741946068687199, 13.352227112394001],
            [39.741872555055899, 13.3529088517065],
            [39.741837375232798, 13.353669594199699],
            [39.7418674274723, 13.354623412401001],
            [39.741887257782203, 13.3552551474101],
            [39.741942328102397, 13.3558980813247],
            [39.7419660023034, 13.3562777655519],
            [39.742114456090597, 13.3568831262993],
            [39.742322365773198, 13.3578669366436],
            [39.742404919151198, 13.358625072069399],
            [39.742468295294501, 13.3591526988194],
            [39.742462559551399, 13.359727997053],
            [39.7421439477814, 13.3604726206649],
            [39.741365894455903, 13.3615870477668],
            [39.7408783401237, 13.3621999299175],
            [39.740242376021598, 13.3629760000633],
            [39.739787466466801, 13.3635074095476],
            [39.739152722276899, 13.364307208657699],
            [39.738640233407402, 13.3648851235503],
            [39.738040073457597, 13.365672385160901],
            [39.737577457256002, 13.366329268027],
            [39.737064924368902, 13.366895878392],
            [39.736617725952499, 13.3673108470337],
            [39.736264529044, 13.367700675898],
            [39.735536339403403, 13.3685267344978],
            [39.734897109027003, 13.369188648905901],
            [39.734206055740103, 13.3700824087197],
            [39.733722312061303, 13.3708207217931],
            [39.732857231747801, 13.3721908089046],
            [39.732137394642599, 13.3729241566838],
            [39.731679854820698, 13.3733617512873],
            [39.7311052276862, 13.3738268108642],
            [39.730576052610203, 13.3742442688279],
            [39.7297647131102, 13.375071683221501],
            [39.729287964612503, 13.375649477922],
            [39.728357541743499, 13.3768027376024],
            [39.728177725973097, 13.377083552139799],
            [39.7278814571008, 13.3777941888066],
            [39.7276658012455, 13.3788233139505],
            [39.727684938954297, 13.3801930881374],
            [39.727990781172601, 13.380932499347701],
            [39.728544226032596, 13.381664411750799],
            [39.729001738006801, 13.3819750284528],
            [39.729343450852703, 13.3819762991396],
            [39.730019190558899, 13.381727951407401],
            [39.730502278467497, 13.3813343628774],
            [39.7313533342829, 13.380654888753501],
            [39.731744317925397, 13.380345202833199],
            [39.732135301568, 13.380035514766901],
            [39.733266743143098, 13.379232220544299],
            [39.734141377039698, 13.378611245342601],
            [39.735076851135503, 13.3779868933863],
            [39.735476779303397, 13.377584498159701],
            [39.735954777357698, 13.3770417252416],
            [39.736263831053101, 13.3767458342504],
            [39.736694463637299, 13.3761930274186],
            [39.736739236569399, 13.3761115205915],
            [39.745710799346803, 13.372390886319399],
            [39.746140734838399, 13.3733543602401],
            [39.745357658133898, 13.376586842835],
            [39.741387384852402, 13.381932067175301],
            [39.741195389723401, 13.3821903232488],
            [39.741202481922599, 13.3824321685154],
            [39.7435183306402, 13.3860160415883],
            [39.743705784785497, 13.386344380796301],
            [39.743908020708801, 13.387535031716901],
            [39.7437986678909, 13.3885740252712],
            [39.743538880500601, 13.3896857977292],
            [39.743432065423498, 13.390425275043],
            [39.743221567694498, 13.391236263239801],
            [39.743103193096303, 13.391597152099999],
            [39.7426469549331, 13.392460856197999],
            [39.742322511300301, 13.393011099282299],
            [39.741996192883398, 13.3935150087102],
            [39.741747906623701, 13.3938661092995],
            [39.741249396438299, 13.3945016310774],
            [39.740750916795498, 13.3951484539029],
            [39.740368238077799, 13.395722600935599],
            [39.739636224308903, 13.397204211201499],
            [39.739492232453699, 13.3974962311536],
            [39.739181910337201, 13.398137975755899],
            [39.738895180674902, 13.398755918088399],
            [39.738662928444697, 13.399256157496399],
            [39.738254650436303, 13.4005220701877],
            [39.737763160584798, 13.401007246766],
            [39.7370234374742, 13.401488628965099],
            [39.735973909676602, 13.402219564805501],
            [39.7339254660628, 13.403058526199899],
            [39.7304281045745, 13.4073319018911],
            [39.731728498593299, 13.4078367163126],
            [39.732111144971697, 13.4079983522657],
            [39.732497065709303, 13.4082876939295],
            [39.7326205741794, 13.408468168452099],
            [39.732734445523199, 13.408706312859101],
            [39.732899604381402, 13.409104798101399],
            [39.7329770418539, 13.409321574222099],
            [39.733036533681897, 13.409711326319799],
            [39.732961007824301, 13.410691445673599],
            [39.732713989985797, 13.411848378711101],
            [39.732505314040303, 13.4123259398544],
            [39.732316524998502, 13.413079215821],
            [39.731691922788201, 13.4138450635393],
            [39.731366812605401, 13.4140031110884],
            [39.730903447208803, 13.414257629534101],
            [39.730626999663301, 13.414472045223],
            [39.7304785270114, 13.4146261864456],
            [39.730150215232896, 13.415061144966399],
            [39.729867941214501, 13.415448500523301],
            [39.729773847179999, 13.415830787404699],
            [39.729760426349699, 13.416164241332201],
            [39.7296996068117, 13.4164774881833],
            [39.729464120646497, 13.416874878775101],
            [39.7292407581444, 13.4172722346313],
            [39.729175439843402, 13.417435175804799],
            [39.728857980611899, 13.41821481819],
            [39.728681984478101, 13.4186222073276],
            [39.7283024327958, 13.420059816732399],
            [39.7282365314882, 13.4206025126807],
            [39.728123872870199, 13.421146475261001],
            [39.728031035578802, 13.4215637943154],
            [39.727860788662802, 13.422155393146801],
            [39.727684177183001, 13.4225514811521],
            [39.727236099315903, 13.423668305042501],
            [39.727114520427001, 13.423936519239099],
            [39.726889241818398, 13.424276237335199],
            [39.726015684103501, 13.4318941767428],
            [39.726043783405601, 13.4411912626015],
            [39.724183992289703, 13.4505231944364],
            [39.721352922925703, 13.465429878528701],
            [39.719494963474702, 13.475670462908299],
            [39.720487131146498, 13.486842038741299],
            [39.722412580126402, 13.498948973523801],
            [39.7234021902946, 13.509181308145999],
            [39.728190525169303, 13.5166165931096],
            [39.729143102289797, 13.524063019638801],
            [39.733922693861899, 13.528683971805499],
            [39.7397052203127, 13.5472892655485],
            [39.739433297581201, 13.5631377919371],
            [39.744495162273402, 13.5686640633916],
            [39.745022900943297, 13.569324779819],
            [39.753659313067899, 13.570914921660799],
            [39.756425528560001, 13.5708500053964],
            [39.761514340914097, 13.5709113152021],
            [39.7645048253084, 13.5716096354789],
            [39.771879182612302, 13.5673158302868],
            [39.773889407402301, 13.5664280281761],
            [39.783552059399099, 13.5633722332838],
            [39.786996837103999, 13.5611959052586],
            [39.789396555231498, 13.5593483480854],
            [39.7918073801291, 13.5570305738479],
            [39.794473907777501, 13.555018243731899],
            [39.797117546352403, 13.553774489618799],
            [39.8000889478351, 13.5534756251744],
            [39.802469368353698, 13.5536691323583],
            [39.8049452851768, 13.5537470305221],
            [39.807749188588303, 13.553966242106],
            [39.8107478126174, 13.554882112162201],
            [39.812460280026698, 13.556188673522101],
            [39.815062420926999, 13.5576980015096],
            [39.817419546333603, 13.5578224993411],
            [39.820063321452501, 13.5571515107723],
            [39.822474506574601, 13.5568745665654],
            [39.8245954056042, 13.556339751543399],
            [39.833434035685798, 13.554835416281501],
            [39.836009189398403, 13.5552087795927],
            [39.837885991012499, 13.5559043078503],
            [39.840235800539503, 13.557234516511],
            [39.842391459879003, 13.556251883022],
            [39.844752703162897, 13.557053085402799],
            [39.84614882076, 13.555432187740401],
            [39.8524357242934, 13.555157572347399],
            [39.854075929424503, 13.553004614571],
            [39.862087084467099, 13.5494293814874],
            [39.863669942048801, 13.5456717186382],
            [39.8657873098011, 13.5470849938431],
            [39.8643376894439, 13.5565145435916],
            [39.863048386025604, 13.5582481823492],
            [39.862716661547402, 13.5602734733096],
            [39.863479663600302, 13.562492745636],
            [39.865428629576101, 13.5631426018704],
            [39.867000675933397, 13.5654505884138],
            [39.8662949576496, 13.5718385147104],
            [39.866733721783802, 13.5743064076413],
            [39.867542227099101, 13.577098496916699],
            [39.867866741698798, 13.5807026057672],
            [39.868370261788598, 13.582859470517],
            [39.867500410522602, 13.5849521917281],
            [39.866458797781, 13.5866804666764],
            [39.8653221917913, 13.589295121668],
            [39.865585441288701, 13.5913070362955],
            [39.865772225290797, 13.593894472189501],
            [39.865562267449299, 13.5962030091013],
            [39.865093009104498, 13.598379090173101],
            [39.864593626654901, 13.6006931565367],
            [39.8644677789718, 13.6035743943158],
            [39.866069923382703, 13.6056618772859],
            [39.868461748912097, 13.6074030129689],
            [39.870254432282998, 13.6094423294593],
            [39.869892158796901, 13.6116395144103],
            [39.868995883586798, 13.614109810271801],
            [39.868423525596803, 13.6165292416854],
            [39.8687021919811, 13.6191027839869],
            [39.868656156916003, 13.6218334258174],
            [39.867366831938199, 13.624587637787],
            [39.866222259688598, 13.6269593400731],
            [39.865696146969398, 13.629240724788399],
            [39.866722125431799, 13.6317189793309],
            [39.867477687637503, 13.634032041232601],
            [39.867905030591203, 13.636018503085801],
            [39.868717286372799, 13.637870253264801],
            [39.8692630021281, 13.640301581096899],
            [39.868907896299604, 13.642887512953401],
            [39.867977224597297, 13.6454619021463],
            [39.8674396170357, 13.6482360805607],
            [39.8680117711082, 13.650781461231301],
            [39.868286643906998, 13.653722310085501],
            [39.867836058842002, 13.655805653267899],
            [39.867470326842003, 13.6578660937428],
            [39.8669359613003, 13.6607431035356],
            [39.867573011465502, 13.665372273008501],
            [39.869614094283797, 13.6653786581684],
            [39.871979317104397, 13.6652154878192],
            [39.871838107331598, 13.6673508603646],
            [39.875450534024701, 13.678284806632799],
            [39.875939072910498, 13.680831581381399],
            [39.8777777778717, 13.6818614024801],
            [39.877129476307601, 13.6839138147075],
            [39.876312841238999, 13.6867048159397],
            [39.877682529502103, 13.688878889876101],
            [39.878895467138101, 13.6907009050987],
            [39.877472656102299, 13.6975502015865],
            [39.8781133139017, 13.7050691088926],
            [39.877179064209599, 13.706864863804],
            [39.875996305475603, 13.709136166461599],
            [39.8739822089467, 13.709805593295],
            [39.873615798718802, 13.7124616304579],
            [39.874283659301597, 13.720004180537],
            [39.873802915995803, 13.722225514140399],
            [39.874115626732703, 13.7242485105357],
            [39.874493449158102, 13.7363126983147],
            [39.877461361584203, 13.740082484939199],
            [39.884046313552503, 13.748235826408401],
            [39.874680293347303, 13.7492296854074],
            [39.874239297797899, 13.7494064296276],
            [39.873608546619501, 13.7495442918592],
            [39.864610259860697, 13.7527213526831],
            [39.862882496837102, 13.757477472759099],
            [39.857350317381197, 13.7607132816486],
            [39.853174866194003, 13.766500674772701],
            [39.852990038722602, 13.775917781997499],
            [39.853710984348801, 13.7862222903732],
            [39.8526616362143, 13.792737898117201],
            [39.852435240999696, 13.793918567694201],
            [39.852281264370099, 13.7948220974606],
            [39.852012548624302, 13.796131752096301],
            [39.851862437445298, 13.7968273184563],
            [39.850817873738002, 13.7991195145124],
            [39.850182454913401, 13.7997636500706],
            [39.849248283374699, 13.7999816225026],
            [39.848425103180901, 13.8000342037479],
            [39.847732003531902, 13.800081811517501],
            [39.846610014030198, 13.8003286778691],
            [39.845417493303998, 13.800579173071201],
            [39.842888737575898, 13.801635550111],
            [39.8401653474684, 13.805334876457],
            [39.837578353960303, 13.809523067531501],
            [39.837200605196799, 13.8101233441591],
            [39.836870389888297, 13.8104341375965],
            [39.8356871577421, 13.812167324222999],
            [39.8353492142272, 13.8125821159298],
            [39.834961212991303, 13.8132287606433],
            [39.833678839177203, 13.8213736597926],
            [39.836592628107503, 13.8289312284247],
            [39.842186598180099, 13.8338044446577],
            [39.842557814394901, 13.8339512142636],
            [39.842852104278599, 13.8345740437647],
            [39.843005296678903, 13.8348786560521],
            [39.843112981325298, 13.8352320225183],
            [39.843232846228602, 13.8355955187766],
            [39.8433463079406, 13.8357872526558],
            [39.843298199563797, 13.8360767376737],
            [39.843045529525597, 13.836569226762],
            [39.842876193501198, 13.8367585472031],
            [39.8428088764488, 13.8368537128329],
            [39.842569660478503, 13.8370692667132],
            [39.842392644756799, 13.837373889689299],
            [39.842438124662998, 13.837637057138201],
            [39.842422063684097, 13.837833760247999],
            [39.8422937878548, 13.838183419559],
            [39.842147541228201, 13.838361358557],
            [39.842065479228701, 13.838697297614701],
            [39.8420987743864, 13.8389492064513],
            [39.842343661420998, 13.8391823034582],
            [39.842852142906104, 13.8395433277305],
            [39.842897003873098, 13.8397951972723],
            [39.842704004427603, 13.8399981612041],
            [39.8423518675312, 13.8403327712302],
            [39.842009433338099, 13.840632313538499],
            [39.841824731630901, 13.8407312726657],
            [39.841255959715497, 13.8409705659623],
            [39.840837194774402, 13.841112148251501],
            [39.840738398957797, 13.8412989634389],
            [39.840595954899797, 13.841890524831999],
            [39.8406286293216, 13.8421311348707],
            [39.840804956933802, 13.8424356683553],
            [39.840792077787597, 13.842712600761701],
            [39.841435143970202, 13.8438281064245],
            [39.841367160367803, 13.843899540408501],
            [39.8413202862763, 13.844211624122799],
            [39.841384395444898, 13.8443481516759],
            [39.8414709678854, 13.8444597380698],
            [39.841641476211201, 13.844913471656101],
            [39.841698523723302, 13.8451766000451],
            [39.841502918265199, 13.8456202956675],
            [39.8414259308487, 13.8457606993817],
            [39.841431639642302, 13.8462206519145],
            [39.841688141893599, 13.846465010594599],
            [39.841892681097299, 13.846572799241001],
            [39.842099162458602, 13.846738219099301],
            [39.842251702681999, 13.847020230484601],
            [39.842221562407602, 13.847481437375301],
            [39.842102240086703, 13.847749695789201],
            [39.841720680669802, 13.847947658530501],
            [39.8412756346168, 13.848021522955699],
            [39.8409485490387, 13.848090459401501],
            [39.840604163704903, 13.848333499088399],
            [39.840412434477102, 13.848570361580901],
            [39.840210999851102, 13.8488422924519],
            [39.840059043532598, 13.848882370992699],
            [39.839906423359103, 13.848898717795199],
            [39.839870453916802, 13.849188162068501],
            [39.839842201002803, 13.8493724736582],
            [39.839617758725701, 13.849679517438499],
            [39.839479888889102, 13.84977718535],
            [39.839072009732703, 13.849894994013599],
            [39.838868633847298, 13.8504313863414],
            [39.838572981219301, 13.850718331612301],
            [39.8385043346602, 13.8507671640465],
            [39.838349192017397, 13.8507258813299],
            [39.838023384742002, 13.8508298451725],
            [39.837866883846402, 13.8510541525124],
            [39.837913022217698, 13.8513399230432],
            [39.837932273114298, 13.851546675290299],
            [39.838048217769703, 13.852105702358299],
            [39.838165569186799, 13.8524127006621],
            [39.838431616936298, 13.8528977520269],
            [39.8384540496656, 13.853184734372499],
            [39.838409113240097, 13.8535544491455],
            [39.836709636368198, 13.8533302924907],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 11,
        REGIONNAME: "Oromia",
        RID: "04",
        latitude:13.833271,
        longtiude: 39.344733,
        GlobalID: "1a90b2b6-7380-4a72-bb7e-a288a3d8119c",
        Shape__Area: 331064517394.875,
        Shape__Length: 6289404.8694080003,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [38.702009587887197, 8.9257618315056],
              [38.702020816828302, 8.92576848728207],
              [38.702011833675499, 8.9257618315056],
              [38.702009587887197, 8.9257618315056],
            ],
          ],
          [
            [
              [38.911121665512802, 3.50952219164193],
              [38.908601891140798, 3.50964323676759],
              [38.905392659788298, 3.50970151774842],
              [38.895892975658803, 3.51150150163504],
              [38.884902088157602, 3.51558339494902],
              [38.8706031546226, 3.52065155283166],
              [38.856861176563797, 3.5258429676919],
              [38.838721945189199, 3.53194217065859],
              [38.8338014232204, 3.53155214564768],
              [38.828871918098798, 3.53219322115364],
              [38.801801187011897, 3.54066165251077],
              [38.794071183992003, 3.54314299134541],
              [38.781081544983699, 3.54717319145836],
              [38.7761610230149, 3.54810340756525],
              [38.774573250750201, 3.55171218889744],
              [38.7446818096711, 3.55868314890209],
              [38.740461973624001, 3.56029251417146],
              [38.734420803338303, 3.56250258501355],
              [38.729120743161999, 3.56394383524945],
              [38.723751063551198, 3.56534249583892],
              [38.718271340317997, 3.56679270737675],
              [38.713660737122296, 3.56835274690932],
              [38.7111027843507, 3.56981191941947],
              [38.708861487716902, 3.573113540826],
              [38.708111394454598, 3.57674239681349],
              [38.706900914609299, 3.58054382659098],
              [38.704612456423, 3.58441248243715],
              [38.701061865262503, 3.58865319183037],
              [38.6986409055718, 3.59172388349174],
              [38.6964624910078, 3.5952518040789],
              [38.694331237996202, 3.59855333366329],
              [38.691912524093702, 3.60168226715588],
              [38.689282706099497, 3.60545221428324],
              [38.6870414094656, 3.6092131804449],
              [38.686441784013503, 3.61116313889341],
              [38.68416230898, 3.61601336219796],
              [38.682882209700097, 3.61856397753886],
              [38.680342223234298, 3.62106303569329],
              [38.677831432015203, 3.62195283320971],
              [38.675361064983797, 3.62158301822723],
              [38.672542600779899, 3.6208232160619],
              [38.670011597466903, 3.61994238266516],
              [38.668502427789598, 3.61852363394259],
              [38.668691073999298, 3.61611198014768],
              [38.669742102881699, 3.61317360890855],
              [38.670611222919099, 3.61041229331989],
              [38.670460755108998, 3.60800285930233],
              [38.668931373337799, 3.60521239052587],
              [38.668320518944597, 3.60241294778378],
              [38.667022453359003, 3.59967401308396],
              [38.664772173572302, 3.59844350700588],
              [38.663422454857901, 3.59622231605304],
              [38.6613720502219, 3.59350353509916],
              [38.656961322176898, 3.59339370783345],
              [38.653372552616801, 3.59458387606938],
              [38.6492605144038, 3.59556335422475],
              [38.642862263792601, 3.59691265650514],
              [38.6372724969372, 3.59853316142108],
              [38.634130639231003, 3.59961349643047],
              [38.630472250236402, 3.60029262753353],
              [38.626420848305003, 3.60104348146385],
              [38.623620350406803, 3.60176295584299],
              [38.620422347995301, 3.60215295106318],
              [38.617201887701697, 3.6015119243485],
              [38.615701701177301, 3.60061314137655],
              [38.6112011416038, 3.60274242627992],
              [38.609512308869697, 3.60488291292429],
              [38.611302202073297, 3.60962334446627],
              [38.610410624153801, 3.61169209241715],
              [38.607441692139801, 3.61202380887649],
              [38.6051712002592, 3.60987213238898],
              [38.603720421075302, 3.60875370686769],
              [38.602721045321701, 3.60687322478143],
              [38.601490353382502, 3.60465205439763],
              [38.597930779069202, 3.60452205636654],
              [38.594321797415198, 3.60440326504603],
              [38.590081749274198, 3.60412309677582],
              [38.587162224600803, 3.60421275063167],
              [38.582172083197499, 3.60388327266796],
              [38.579301965864701, 3.60363224175838],
              [38.577011261890199, 3.60384292841931],
              [38.573561731199199, 3.60325345391549],
              [38.571271027224697, 3.60345293392341],
              [38.569761857547398, 3.60594306831842],
              [38.566691865063902, 3.60753217842259],
              [38.564111454410302, 3.60774286417986],
              [38.561890369870298, 3.60823371697803],
              [38.560080264572797, 3.61038315601647],
              [38.558160115653003, 3.61207311806955],
              [38.554490497717303, 3.61189381190011],
              [38.551871908664097, 3.61319378082594],
              [38.551301478458697, 3.61744332141901],
              [38.552460305175202, 3.62196403972197],
              [38.551530548856199, 3.62495393223548],
              [38.549260056975598, 3.6268836851989],
              [38.548020381883497, 3.62793260842652],
              [38.547420756431301, 3.63040250403763],
              [38.546830114132, 3.63293290732681],
              [38.5448201336838, 3.63686409552631],
              [38.543611899626697, 3.64009375327813],
              [38.542210527783404, 3.64218260278406],
              [38.539401046732401, 3.64623253645756],
              [38.537930055454602, 3.64751228481139],
              [38.5362816469083, 3.64857239021148],
              [38.535271042213601, 3.65025331587171],
              [38.532731055747803, 3.65269401432957],
              [38.526981837929398, 3.65168322082368],
              [38.522961877032998, 3.65070380341416],
              [38.520031123418498, 3.64994402578637],
              [38.516871299406603, 3.64947336675649],
              [38.514400932375302, 3.64927389690276],
              [38.512271925152, 3.64901391365614],
              [38.512240484117001, 3.6466023413354],
              [38.5127907022285, 3.64482279404343],
              [38.512871550604103, 3.64258378684465],
              [38.516280657107302, 3.64081319662716],
              [38.517461941705903, 3.638672794944],
              [38.516990326181798, 3.63414319514299],
              [38.516480532258001, 3.63077231541258],
              [38.517920082500801, 3.62753365913724],
              [38.516741043690402, 3.62508392733801],
              [38.512941170038602, 3.62347243500608],
              [38.509020023823403, 3.62232264804108],
              [38.504580100531697, 3.620372713592],
              [38.502181598723098, 3.61976307805197],
              [38.500110981993203, 3.61919378566981],
              [38.498080789451102, 3.61862225161918],
              [38.496371744622998, 3.61799244309091],
              [38.494631258760002, 3.61735366886956],
              [38.487480669098503, 3.61498235571762],
              [38.481271064696998, 3.61306378401715],
              [38.477749668783197, 3.61161364593946],
              [38.474580861618499, 3.61016350554515],
              [38.448729593529798, 3.62643990962078],
              [38.437320989421401, 3.60122278977353],
              [38.437020053801199, 3.60126313413834],
              [38.429799844705101, 3.60084400092784],
              [38.424070838980697, 3.60104348146385],
              [38.4203001605756, 3.60189295426828],
              [38.414800225248598, 3.60225381186878],
              [38.411539340767199, 3.60287242456531],
              [38.4039800176513, 3.60303380172134],
              [38.397689564874298, 3.60392361691483],
              [38.394251263124303, 3.60425309486393],
              [38.389380148496201, 3.60415223427994],
              [38.386339351259402, 3.60424412947913],
              [38.382620325983197, 3.60424412947913],
              [38.376720640354698, 3.60460274480181],
              [38.368759321149199, 3.60511377139232],
              [38.361420085277999, 3.6055037651761],
              [38.354720899046598, 3.605532902636],
              [38.349959828040802, 3.60554410935109],
              [38.341659394815501, 3.60616271980997],
              [38.3348501649619, 3.60717356425537],
              [38.330439436916897, 3.60746269693875],
              [38.328380049128, 3.6075523504653],
              [38.322509558746297, 3.60910335507441],
              [38.321979552728699, 3.60924231778587],
              [38.311958845734303, 3.60972420444315],
              [38.305250676350198, 3.60975334176772],
              [38.300550241625999, 3.61022402149663],
              [38.296200149862699, 3.61039436267174],
              [38.2919601017216, 3.61090314467554],
              [38.283888738893801, 3.61141416772277],
              [38.277589302963897, 3.61190277720942],
              [38.272998911862103, 3.61196329304498],
              [38.269508956983302, 3.61201260224133],
              [38.263988809562399, 3.61128417065909],
              [38.261480264131499, 3.61166295515481],
              [38.253399918150798, 3.61199243029769],
              [38.247789939201503, 3.61299430295655],
              [38.242750390457601, 3.61351429011975],
              [38.238279026130897, 3.61346273968153],
              [38.235880524322297, 3.61395358938745],
              [38.225558881707698, 3.61391324558603],
              [38.219149402155502, 3.61486356576523],
              [38.2144601963724, 3.61555389206348],
              [38.211648469533102, 3.61576233464115],
              [38.203289645814401, 3.61656248473616],
              [38.196938556755697, 3.61740297777286],
              [38.190870437011498, 3.61794313421969],
              [38.185999322383303, 3.61794313421969],
              [38.1820287688275, 3.61759348941948],
              [38.1798503542635, 3.61728418813764],
              [38.172839003470997, 3.61599319034327],
              [38.1686393795177, 3.6149039095244],
              [38.163739069642801, 3.61359273643316],
              [38.160460218855803, 3.61260431238861],
              [38.156269578055401, 3.61202380887649],
              [38.151389480274403, 3.61140296108008],
              [38.1446498698553, 3.60953369114408],
              [38.142979003426802, 3.60910335507441],
              [38.1386783190041, 3.6092131804449],
              [38.134730223330401, 3.60926248979059],
              [38.1312290395106, 3.60919300843906],
              [38.128529602081798, 3.60889266963262],
              [38.1270788228979, 3.60764424532084],
              [38.125448380657197, 3.60606410077951],
              [38.123539460678501, 3.60470360533584],
              [38.120258364103201, 3.60848250528133],
              [38.117439899899303, 3.61184450269731],
              [38.114158803324102, 3.6145923660043],
              [38.110648636351399, 3.61802382146211],
              [38.107708899584097, 3.62133423351765],
              [38.1056180707603, 3.62308244894776],
              [38.1035294877247, 3.62541339760033],
              [38.101319632125801, 3.62767261902204],
              [38.098429302699103, 3.63012458508648],
              [38.095139222971, 3.63264378277495],
              [38.091849143243003, 3.63520331628498],
              [38.087418203104001, 3.6381326508884],
              [38.084119140223102, 3.64053303962812],
              [38.081289447078099, 3.64304324323067],
              [38.077258257240601, 3.64625270763664],
              [38.0741388574165, 3.64847377580842],
              [38.072838546042803, 3.64964370052912],
              [38.0689780361093, 3.65290244832559],
              [38.064279847173303, 3.65681337830449],
              [38.061899311670402, 3.65902321429161],
              [38.060479973521502, 3.66019312525489],
              [38.058548595660596, 3.66137424071173],
              [38.055029445535098, 3.66434383151321],
              [38.0527679368073, 3.6664438305306],
              [38.050948848357002, 3.6677235500321],
              [38.048718780664203, 3.66821437016043],
              [38.045868875425299, 3.66905257377459],
              [38.043809487636501, 3.6700028357444],
              [38.041837685587801, 3.67221263915181],
              [38.040719283059097, 3.67435296076684],
              [38.039648042082803, 3.67574472735554],
              [38.037429203331001, 3.67721269137181],
              [38.036108679863297, 3.68072458936836],
              [38.032919660604698, 3.68226426204411],
              [38.0305481082546, 3.6850544917667],
              [38.027808246638102, 3.68623333318078],
              [38.0253491085478, 3.68701325021564],
              [38.023008997232701, 3.69164343323351],
              [38.021538005954902, 3.69326376747539],
              [38.019849173220798, 3.69558332794315],
              [38.017708937056398, 3.69819422691409],
              [38.015099331156001, 3.70035465595667],
              [38.012379681633298, 3.70297450526875],
              [38.012759219840902, 3.70498476933034],
              [38.012209001729303, 3.70694348376655],
              [38.012408876880102, 3.7086041295643],
              [38.0142683895182, 3.71030287062065],
              [38.010448303772499, 3.71207332286318],
              [38.008449552265297, 3.71252377913323],
              [38.005819734271, 3.71362414646835],
              [38.0046586617663, 3.71686473150627],
              [38.0036794981066, 3.72037423133309],
              [38.002567832942503, 3.72320467982356],
              [38.000128906946102, 3.72490339275956],
              [37.996859039312, 3.72511405086351],
              [37.994889483051502, 3.72749403572552],
              [37.990467526065402, 3.72767331869348],
              [37.986638457166897, 3.72858317919244],
              [37.983617872023999, 3.72959388519451],
              [37.979058921957098, 3.73201419639523],
              [37.974048568459899, 3.73443450092703],
              [37.970387933677202, 3.73586427028379],
              [37.966127673442202, 3.73844367587902],
              [37.960778205925301, 3.74167296445506],
              [37.956567353030998, 3.74425459395071],
              [37.9525878163223, 3.74654488735676],
              [37.948257936652901, 3.74907495989361],
              [37.943137539533403, 3.75184480947549],
              [37.936707847887298, 3.75537433808727],
              [37.929357383075001, 3.75908312896558],
              [37.9238978719358, 3.76260366450588],
              [37.922649213690804, 3.76348435640495],
              [37.917488392383603, 3.7678541885309],
              [37.915608667651497, 3.76914496551472],
              [37.910768994058401, 3.77150466223557],
              [37.905758640561203, 3.77421393575533],
              [37.900177856858598, 3.77767390552968],
              [37.893307990723301, 3.78241341426999],
              [37.885858711229702, 3.78734337221793],
              [37.880179112845902, 3.7912649096259],
              [37.876318602912399, 3.79418476278516],
              [37.872229022581401, 3.7971651092482],
              [37.8668279019357, 3.80085355385644],
              [37.860348802948899, 3.80525456918721],
              [37.853517115213201, 3.80982362394164],
              [37.847038016226499, 3.81387502640102],
              [37.842038891670398, 3.81720487054081],
              [37.839247376925002, 3.81931346637834],
              [37.833628414822797, 3.82329311879356],
              [37.827998223779602, 3.82681339278003],
              [37.819747197894898, 3.83209489701279],
              [37.816158428334901, 3.83420345620684],
              [37.812347325742003, 3.83655401181365],
              [37.8083767721862, 3.83947371105949],
              [37.806218569716101, 3.84117443687039],
              [37.803548327534102, 3.84321351077886],
              [37.799618198166002, 3.84562454132996],
              [37.793356940635697, 3.84932398107915],
              [37.782658005601903, 3.8555554056329],
              [37.777717271539203, 3.85901504773549],
              [37.772718146983102, 3.86303484734977],
              [37.767377662618998, 3.8667252486239],
              [37.763737239930101, 3.86959330895119],
              [37.762097814536602, 3.87076517768146],
              [37.759027822053099, 3.87292517621562],
              [37.754648535043003, 3.87625478971249],
              [37.749537121076401, 3.88000339002584],
              [37.744306680334603, 3.88392450226082],
              [37.739938622265498, 3.886904535376],
              [37.736127519672699, 3.88931544167913],
              [37.7337874083576, 3.89077408145649],
              [37.729967322611799, 3.89255536623849],
              [37.726546987167502, 3.8943254442389],
              [37.7214872263297, 3.89754518417037],
              [37.716766579511699, 3.9007133783056],
              [37.711637199239398, 3.90365526216414],
              [37.7081180491138, 3.90605491760288],
              [37.704428219084299, 3.90897437710462],
              [37.699768208547901, 3.91265561507897],
              [37.6964668998788, 3.91493425519097],
              [37.690616621590998, 3.9190949419293],
              [37.684126293663198, 3.92302483381987],
              [37.679008142331902, 3.92625566610224],
              [37.677018373977603, 3.92760445646283],
              [37.674797289437599, 3.92901373841441],
              [37.671736280106998, 3.93152534629163],
              [37.6676983529049, 3.93438422348036],
              [37.662746389901201, 3.93784354137998],
              [37.6612080249771, 3.9385537752065],
              [37.658138032493603, 3.94014451753976],
              [37.653067042714802, 3.94354555925187],
              [37.6486361025759, 3.94676510963081],
              [37.644656565867201, 3.94922513345023],
              [37.641916704250598, 3.95081361490216],
              [37.638217891068301, 3.95258356949583],
              [37.635987823375501, 3.95359401023452],
              [37.634096869702397, 3.95769401212135],
              [37.631907226197299, 3.96134366885641],
              [37.630036484618202, 3.96355496317234],
              [37.621217274316301, 3.9687840793915],
              [37.613067308901101, 3.97354492119073],
              [37.6107968170205, 3.97529466367296],
              [37.607226013766201, 3.97821388005993],
              [37.604317718033798, 3.98014508276047],
              [37.597155899431201, 3.9850156365046],
              [37.593906243890899, 3.98666453869036],
              [37.591276425896602, 3.98750467100517],
              [37.589845858806697, 3.98809388328964],
              [37.586766883170299, 3.98968453031288],
              [37.583925961084297, 3.99150368904845],
              [37.5814780519351, 3.99326459502024],
              [37.579836380753299, 3.99443404957044],
              [37.578926836528197, 3.99565503003297],
              [37.578336194228903, 3.99807458227694],
              [37.575647985741099, 3.99914545589797],
              [37.570747675866301, 4.00156499783851],
              [37.567096024236299, 4.00367537025086],
              [37.563006443905401, 4.00660568725316],
              [37.559366021216498, 4.00889527014439],
              [37.554807071149597, 4.01171579446023],
              [37.5525859866096, 4.01277544881583],
              [37.5432367702901, 4.02042597802935],
              [37.534657859326799, 4.02743572639489],
              [37.530455989585299, 4.03131581682503],
              [37.525396228747503, 4.03428411830757],
              [37.5202062121935, 4.03662514843106],
              [37.515945951958599, 4.03941421378555],
              [37.511016446836997, 4.0450259186326],
              [37.508608961875503, 4.0467239837392],
              [37.406775941267703, 4.11421581267668],
              [37.401085113942798, 4.11816492437],
              [37.3965351470288, 4.12084618756121],
              [37.393175447866199, 4.12276584997415],
              [37.391576446660402, 4.1237559190916],
              [37.384576324808897, 4.12800513837442],
              [37.379577200252797, 4.13109627991543],
              [37.374685873530801, 4.13451444124729],
              [37.3702953575796, 4.13679470057148],
              [37.366365228211599, 4.13935494462312],
              [37.3642070257415, 4.1405152274778],
              [37.359306715866602, 4.14337560863884],
              [37.353615888541697, 4.14680491650425],
              [37.349476900870201, 4.15028572716633],
              [37.3456568151244, 4.15261521865325],
              [37.342705849416099, 4.1548864662324],
              [37.339974970952397, 4.15714650787379],
              [37.338045838879701, 4.15830452471782],
              [37.329455698975401, 4.1642356945544],
              [37.322116463104102, 4.16888562877258],
              [37.318705110812701, 4.17161599640584],
              [37.3146065473289, 4.17360497029898],
              [37.310265438718297, 4.1757059304934],
              [37.307426762420498, 4.17779568596],
              [37.303555023545997, 4.18052602260134],
              [37.299526079496701, 4.1834848098158],
              [37.295425270224698, 4.18633607564123],
              [37.291326706740897, 4.18895439308441],
              [37.287686284052, 4.1912860097099],
              [37.283524838498302, 4.19355490566829],
              [37.281535070144002, 4.19460535971038],
              [37.280055095713401, 4.19565581233999],
              [37.273856720253001, 4.19949476792689],
              [37.2692461170572, 4.20234597534467],
              [37.265486667593201, 4.2050157532007],
              [37.261736201281998, 4.20803492049383],
              [37.258605572516799, 4.21012458951837],
              [37.2566158041625, 4.21129596637851],
              [37.252865337851297, 4.21413593201839],
              [37.249624665463898, 4.2165749794033],
              [37.246496282486902, 4.21843617570215],
              [37.241196222310599, 4.22164566498055],
              [37.235615438608001, 4.2248461822461],
              [37.233225919952297, 4.22630645585576],
              [37.230495041488503, 4.22816538914052],
              [37.226955679269103, 4.2298563426534],
              [37.223375892861903, 4.23206465519323],
              [37.219964540570402, 4.23456635661754],
              [37.213876208732302, 4.23880601656859],
              [37.208865855235103, 4.2420646934381],
              [37.205106405771097, 4.24450589253354],
              [37.201066232780803, 4.24729646479594],
              [37.197886196675, 4.24933675642699],
              [37.195146335058404, 4.25113516488137],
              [37.191225188843198, 4.25381597268297],
              [37.189938352198702, 4.25449233207492],
              [37.190030429515403, 4.25414743365125],
              [37.161425825080798, 4.27016713033796],
              [37.123665142112799, 4.28275332043101],
              [37.104212124635197, 4.29877465852933],
              [37.084759107157602, 4.32547315389592],
              [37.058440715121101, 4.34301858307763],
              [37.039369481639298, 4.36170787920188],
              [37.016761131726199, 4.37238470920593],
              [37.016435492435697, 4.37189655208955],
              [37.014764626007199, 4.37030668037803],
              [37.011054583883798, 4.37211599890598],
              [37.005983594104997, 4.37468665682645],
              [37.0035940754492, 4.37682513295563],
              [37.000683533928701, 4.37822689514576],
              [36.997703372973596, 4.37825600519535],
              [36.9948534677347, 4.37954580317953],
              [36.991875552567898, 4.38168650464761],
              [36.990175490892703, 4.38235603240527],
              [36.9867551554484, 4.38416532180758],
              [36.983963640703003, 4.38637542556708],
              [36.981414671084302, 4.3892057819257],
              [36.9773542860001, 4.39039703629845],
              [36.973415173479196, 4.39180549061289],
              [36.9705136151115, 4.39401557175239],
              [36.968914613905802, 4.39494707308715],
              [36.963704385257898, 4.39550686900246],
              [36.958323476705999, 4.3959659013393],
              [36.953923977602003, 4.39737658431223],
              [36.952875194507797, 4.4008853667244],
              [36.9520936602106, 4.40301705032601],
              [36.950384615382603, 4.40435606694482],
              [36.947644753765999, 4.40564581980378],
              [36.943413688777802, 4.40694676616804],
              [36.939645256160901, 4.40778644634752],
              [36.934953804589597, 4.40891721415706],
              [36.929534717638198, 4.41131533187633],
              [36.926734219739899, 4.4124863986866],
              [36.923253248013999, 4.41395526763848],
              [36.919664478453903, 4.41565700242377],
              [36.916015072612197, 4.41752666706311],
              [36.912473464604503, 4.4189373091206],
              [36.906924121936903, 4.42076666156936],
              [36.902243899306598, 4.42269676904942],
              [36.897334606278903, 4.4244074401529],
              [36.893673971496099, 4.42535681612827],
              [36.889384516014502, 4.4259770445868],
              [36.885604854456503, 4.42680550778209],
              [36.880924631826304, 4.42811537337418],
              [36.878683335192399, 4.42796535469745],
              [36.877144970268297, 4.42855647288927],
              [36.873884085786997, 4.42955734238714],
              [36.868563813516801, 4.43092541908479],
              [36.864343977469602, 4.43262711488159],
              [36.857323643524197, 4.43550655437135],
              [36.852753464516297, 4.4377859160858],
              [36.849164694956201, 4.43942714172483],
              [36.843682725934897, 4.44159677820218],
              [36.838773432907203, 4.44393657509414],
              [36.834124651311797, 4.44386716495827],
              [36.831312924472499, 4.44360743664995],
              [36.828963830004597, 4.44350667995398],
              [36.824723781863497, 4.44348652861313],
              [36.821054163927897, 4.44340592324424],
              [36.815432956037498, 4.44317754131787],
              [36.809753357653697, 4.44316634612359],
              [36.8076939698648, 4.44329621036687],
              [36.804024351929201, 4.44304543801443],
              [36.798403144038801, 4.44298722299186],
              [36.792213751731197, 4.44274764573517],
              [36.785494353406001, 4.44223714440519],
              [36.783432719829001, 4.44208712859631],
              [36.780333532098801, 4.44176694549918],
              [36.775572461092899, 4.44157662680914],
              [36.770002906331399, 4.44134600527189],
              [36.764273900606902, 4.44099671426263],
              [36.759802536280198, 4.44091610862163],
              [36.712962131578003, 4.43996675263156],
              [36.6918741802833, 4.49622517270462],
              [36.657655105323002, 4.58114526730405],
              [36.681527833998501, 4.60525696502026],
              [36.646336332743097, 4.71219276484643],
              [36.829269257201197, 4.71060364295907],
              [36.855322646228899, 4.73690426100805],
              [36.863858887216203, 4.79374114769327],
              [36.861233560798397, 4.93576109679634],
              [36.865181656472103, 4.94489434705883],
              [36.861092076141098, 4.97946172890521],
              [36.868051773804801, 5.00794433412513],
              [36.871822452209898, 5.02975234316201],
              [36.882568548796201, 5.07567473590595],
              [36.8933505779939, 5.11066912837024],
              [36.921568906856301, 5.14572423804528],
              [36.949737828377998, 5.19607343154266],
              [36.982039000206797, 5.19294000405417],
              [37.006536058004698, 5.20109896043148],
              [37.023345782758803, 5.19785820340371],
              [37.053014890805002, 5.19315695135579],
              [37.082915315036999, 5.18776679525889],
              [37.113026843360601, 5.1876572023958],
              [37.146105057910098, 5.19032768429332],
              [37.182626065786003, 5.1953085277687],
              [37.197955816109499, 5.21169784147794],
              [37.217705277630898, 5.21406853107238],
              [37.238905518336097, 5.20081715613477],
              [37.263966268974798, 5.19838826625311],
              [37.293157024132299, 5.19574689378783],
              [37.318047094866998, 5.1749174616202],
              [37.341535793758503, 5.160987549782],
              [37.367977704146597, 5.15648732659527],
              [37.418018357048503, 5.1679368873761],
              [37.442317785483901, 5.18136788213533],
              [37.466388143521897, 5.19455704245501],
              [37.492077714859498, 5.20429720670214],
              [37.518667847269398, 5.22185819618996],
              [37.552157041061399, 5.23530816810134],
              [37.578318227923198, 5.24297680381275],
              [37.5803686325592, 5.24895688774206],
              [37.601018655152899, 5.25661641035525],
              [37.630148774028697, 5.2665881848918],
              [37.6376789018978, 5.28384776534256],
              [37.647497487953203, 5.30065739206599],
              [37.655818133272398, 5.30318651145126],
              [37.6750375887761, 5.3090184289358],
              [37.701688357467702, 5.30725633698097],
              [37.729749481155402, 5.29355746577689],
              [37.747938119870597, 5.27798653901101],
              [37.773638920149303, 5.2847266090748],
              [37.793419822705602, 5.2755982019785],
              [37.810889809193498, 5.26967651770735],
              [37.827939833286102, 5.25111721810282],
              [37.853469953660799, 5.23602605837611],
              [37.883318724763903, 5.23404682618313],
              [37.884068818026101, 5.23452318433418],
              [37.887509365564298, 5.23671711085262],
              [37.901428760891697, 5.24558666798267],
              [37.902315847234803, 5.2547468246163],
              [37.902830132734898, 5.26006707838345],
              [37.904779476901503, 5.28005731817025],
              [37.905170244050097, 5.30463778748055],
              [37.901428760891697, 5.3275961170575],
              [37.896768750355299, 5.35055806080367],
              [37.901529821361201, 5.37399762915092],
              [37.919619645395102, 5.39655761335717],
              [37.935620886393501, 5.42370673715592],
              [37.940611027796798, 5.45059754176347],
              [37.947209153558703, 5.47495651294305],
              [37.949650325343299, 5.50826740791981],
              [37.948210775100399, 5.53835760577392],
              [37.941929305476201, 5.56911682812568],
              [37.940521196268399, 5.58496856571221],
              [37.942349267871599, 5.58657785521431],
              [37.9375006111255, 5.598057325332],
              [37.930570108708601, 5.61733678459941],
              [37.944300857826299, 5.64079714376121],
              [37.9645511301186, 5.64703698826188],
              [37.978279633448103, 5.65074689261532],
              [37.999473136788701, 5.65126761825224],
              [38.008831336260997, 5.65149781012273],
              [38.024890967752903, 5.66140714809759],
              [38.041631073072502, 5.67498807342085],
              [38.056320773755999, 5.68167673481693],
              [38.075600865541404, 5.68951843769971],
              [38.080860501529997, 5.7016975589929],
              [38.087481085173899, 5.7219165640125],
              [38.089282207318597, 5.74626870008077],
              [38.073170922697898, 5.76300711739292],
              [38.071870611324101, 5.7631344803122],
              [38.0545510926463, 5.7648080711056],
              [38.039612109471399, 5.77304859035153],
              [38.020271381404299, 5.79069782405752],
              [38.012671634100698, 5.80492808089791],
              [38.009410749619299, 5.8271988619763],
              [38.0075310248873, 5.8499289829259],
              [37.996701834137198, 5.86759814304038],
              [37.982870024550003, 5.88847692172424],
              [37.9793912986122, 5.90822905211258],
              [37.977980943616203, 5.92842723345096],
              [37.968970841316498, 5.95666815277632],
              [37.953090872881397, 5.96399898396841],
              [37.946890251632801, 5.96375775159372],
              [37.943211650544299, 5.9635187527475],
              [37.923219643896203, 5.96072893608383],
              [37.904370743447203, 5.96115779593324],
              [37.895380853241399, 5.97514916453048],
              [37.880879798767502, 5.98568936078028],
              [37.865929586651497, 5.98727740833511],
              [37.8569396964457, 5.9996577669738],
              [37.843359415138003, 6.01158892361136],
              [37.8274906756441, 6.01063747818282],
              [37.8137195023385, 5.99843828173546],
              [37.806400478561102, 5.99497859173882],
              [37.802000979457198, 5.99289919358614],
              [37.791879211993297, 5.9965576782749],
              [37.7835697956152, 6.01124944092636],
              [37.786761060662101, 6.02939806662008],
              [37.7901701671653, 6.05008756316544],
              [37.785530368722803, 6.07327967240976],
              [37.778119267628803, 6.09876862460449],
              [37.776270983931802, 6.11443790071225],
              [37.775089699333201, 6.12449975910079],
              [37.778489822683497, 6.15023858516314],
              [37.791099923484403, 6.17345761282883],
              [37.810611331455398, 6.19738784291252],
              [37.826210576364197, 6.22175903484524],
              [37.840659977709201, 6.24888840567033],
              [37.848441633857902, 6.2794586095613],
              [37.8516755688808, 6.29988611386883],
              [37.852551426282801, 6.30541977331973],
              [37.850680684703597, 6.32747792843206],
              [37.848601084820899, 6.33229925781027],
              [37.845811815863698, 6.35250817319172],
              [37.840480314652403, 6.37937850681062],
              [37.819989743021701, 6.39405961767109],
              [37.808230795952497, 6.41080913880592],
              [37.797850762844497, 6.42987011633198],
              [37.796680707187001, 6.45008856675502],
              [37.800321129875897, 6.4788080908596],
              [37.808120752330197, 6.50132957046027],
              [37.814480824541803, 6.50941813483443],
              [37.824211824857002, 6.52179952205654],
              [37.843292041491701, 6.54294914212298],
              [37.855241880558701, 6.55904001416096],
              [37.872296396227703, 6.5834899157924],
              [37.893590960037798, 6.59732848633761],
              [37.911689767224601, 6.59240482832528],
              [37.919276039799001, 6.57563680715755],
              [37.933516582840497, 6.55846885509954],
              [37.953479394241803, 6.55088308675712],
              [37.969581695709699, 6.55021821097372],
              [37.9842219890526, 6.55820335515672],
              [37.997672014644102, 6.57687501860877],
              [38.006587793839003, 6.57662737656532],
              [38.016743248125898, 6.57687501860877],
              [38.034332261388997, 6.56275476317267],
              [38.052413102270101, 6.53600349040727],
              [38.0742107226393, 6.53030499417956],
              [38.091550453411003, 6.52733300407451],
              [38.0960083430084, 6.52287498584639],
              [38.101955190189301, 6.51395659914394],
              [38.115331104769801, 6.50082974869453],
              [38.140099902941202, 6.50726937857301],
              [38.1505046397195, 6.49785758400579],
              [38.147284179426002, 6.47729739976526],
              [38.144310755835498, 6.45946331640547],
              [38.156042753446101, 6.44052841970757],
              [38.171442123204201, 6.45109946812452],
              [38.1937517832853, 6.45822927810933],
              [38.2149138455909, 6.46129763178367],
              [38.214943040837603, 6.46130209483001],
              [38.2222732935561, 6.46236876177564],
              [38.246413271028601, 6.46949841297158],
              [38.255612019537899, 6.45929818306464],
              [38.2609030965614, 6.45342921989195],
              [38.283682126378501, 6.43874758809918],
              [38.297482494930797, 6.41946823589111],
              [38.296568459129197, 6.41424155107701],
              [38.293583806597702, 6.3971975444564],
              [38.294053176333598, 6.37331891073942],
              [38.289986053884803, 6.36339798108443],
              [38.2878435719322, 6.35816849154543],
              [38.272913571910102, 6.34851734349139],
              [38.250383824584397, 6.33702902746673],
              [38.247623750873899, 6.3367991246113],
              [38.231761748744603, 6.33173900379096],
              [38.2142917622567, 6.33080822186524],
              [38.205802682821698, 6.32511858557646],
              [38.198202935518097, 6.32000924328035],
              [38.189242240558997, 6.30553808003735],
              [38.1825924616683, 6.28555725569346],
              [38.179151914130102, 6.27705886647754],
              [38.174561523028302, 6.2680982397403],
              [38.172733451425103, 6.25661930469737],
              [38.179872812145597, 6.23962814295715],
              [38.194131321492797, 6.22746766037356],
              [38.198741924688498, 6.20772720654344],
              [38.187962141279101, 6.18291775206067],
              [38.171433140051299, 6.16752735677731],
              [38.158791598215501, 6.16016803114282],
              [38.141781998310698, 6.15509727136986],
              [38.125461855386497, 6.15576712352061],
              [38.117401721499697, 6.16769928155004],
              [38.1020450217177, 6.17918685588478],
              [38.101762052403203, 6.17939896618718],
              [38.0870611227786, 6.16169751104395],
              [38.083872103520001, 6.13849806554373],
              [38.0806808384731, 6.11828759993043],
              [38.092891188972501, 6.09762752075712],
              [38.097102041866798, 6.08767904684277],
              [38.1009625518004, 6.07857899073448],
              [38.105802225393496, 6.06733713838164],
              [38.117311889971297, 6.05150792704757],
              [38.119860859589998, 6.03451914793926],
              [38.122412074996902, 6.0179876858988],
              [38.1396507452992, 6.01157775642115],
              [38.151162655665203, 6.00171703805164],
              [38.160141316929902, 5.98633708818843],
              [38.163373006164598, 5.97692709701813],
              [38.170972753468199, 5.95833892974346],
              [38.181792961065398, 5.94296893025331],
              [38.204551778788598, 5.93633698125886],
              [38.207518465014402, 5.92958432874868],
              [38.2126119126753, 5.9179886946218],
              [38.215632497818198, 5.89456883789638],
              [38.209681159060899, 5.88032745527111],
              [38.200051219215197, 5.86538869085305],
              [38.199271930706203, 5.85177657457554],
              [38.199152903931001, 5.84977706459844],
              [38.208812039023499, 5.84151756050317],
              [38.224202425628697, 5.8399380309626],
              [38.240053198817002, 5.84569759525767],
              [38.254042213578899, 5.85902836861616],
              [38.256602412138697, 5.86063688688318],
              [38.2712516886345, 5.86984779870781],
              [38.287803147744398, 5.86595836844267],
              [38.291012379096898, 5.86481677864837],
              [38.304572448310701, 5.86276816644712],
              [38.310252046694501, 5.86470731101109],
              [38.326152227223403, 5.87013822032686],
              [38.340612857509498, 5.88415646836829],
              [38.3502427973553, 5.89357697931302],
              [38.358963192975899, 5.90345750941523],
              [38.381701798605199, 5.90531832660169],
              [38.389647397293203, 5.91315468858705],
              [38.397303289302101, 5.92070724598841],
              [38.389023068170701, 5.9347085787443],
              [38.374081839207598, 5.94547738996854],
              [38.356611852719702, 5.9502865421019],
              [38.340532009134002, 5.95692725755886],
              [38.328353099669499, 5.95668825573796],
              [38.321671879743903, 5.97572766329788],
              [38.304213122196998, 5.981686835079],
              [38.2964022708016, 5.97524744235646],
              [38.283082500926298, 5.97201766638424],
              [38.271813135686997, 5.98600875749473],
              [38.280061915783499, 6.00759775523323],
              [38.299342007568903, 6.02024789387871],
              [38.316803010904003, 6.03035841263633],
              [38.320692716084203, 6.04872749425234],
              [38.328476618021099, 6.06144813642307],
              [38.333763203468102, 6.07008844375733],
              [38.3378527837991, 6.07611804113626],
              [38.352122522087299, 6.09718760336127],
              [38.355513662284899, 6.10461702283243],
              [38.362913534437801, 6.12083767544682],
              [38.364503552490703, 6.14126686870297],
              [38.366477600327599, 6.14493997648165],
              [38.377594251968503, 6.16560715422888],
              [38.395044026362598, 6.18306734459624],
              [38.414113014056198, 6.19041742122436],
              [38.435243635326898, 6.19937714240259],
              [38.439373639845599, 6.21751715326877],
              [38.436384495737698, 6.24114846251013],
              [38.4333841226888, 6.26616724246161],
              [38.425793358538002, 6.29072720864458],
              [38.427863975267897, 6.30082811250621],
              [38.425332971954901, 6.30724794392268],
              [38.423942829052699, 6.32629714229441],
              [38.422085562202803, 6.34813120452453],
              [38.421874458110999, 6.35062882659401],
              [38.422914258052401, 6.35444777667633],
              [38.423722741808099, 6.3574274754937],
              [38.426233533027201, 6.36669902710411],
              [38.427614692776501, 6.38597814005757],
              [38.441864218970899, 6.40663799032682],
              [38.455653358582097, 6.41879872332475],
              [38.463363149508098, 6.42363704766327],
              [38.476114734966103, 6.43164874740843],
              [38.504625016295897, 6.43761838587314],
              [38.535884142394998, 6.43806694278927],
              [38.540934920079998, 6.43806694278927],
              [38.567374584679797, 6.44172902696506],
              [38.5841438852461, 6.43828787366201],
              [38.605743876252802, 6.42450740555573],
              [38.627575183445103, 6.41830774688336],
              [38.648034314040899, 6.42518806902398],
              [38.656125888962599, 6.42244755613634],
              [38.662283840235297, 6.4203586862405],
              [38.672845782188297, 6.41025789749056],
              [38.6905358559208, 6.40795696070814],
              [38.692375156465097, 6.40703747579381],
              [38.696745460322298, 6.40244672171694],
              [38.707994613467697, 6.39372707664828],
              [38.718334222387902, 6.3983067500982],
              [38.739485055752503, 6.41022665302568],
              [38.757635516068099, 6.41113720521683],
              [38.759946432136502, 6.40954820131648],
              [38.767314863254498, 6.40447763891938],
              [38.772325216751703, 6.40103846559534],
              [38.784266072665901, 6.38932814115894],
              [38.795054839228101, 6.37509773881633],
              [38.812965000205303, 6.35719758174256],
              [38.835005165701098, 6.34364706087347],
              [38.8533757132614, 6.32161638129256],
              [38.874946509021299, 6.30211833535284],
              [38.886415749411299, 6.28605728793049],
              [38.898576692570103, 6.2598964748605],
              [38.921755472688602, 6.22984753833617],
              [38.926316668543699, 6.22159605792222],
              [38.930379299416103, 6.21423748558577],
              [38.937345734444499, 6.20162765399285],
              [38.943056773863297, 6.19320604747918],
              [38.950885591564401, 6.18166742473557],
              [38.961436304576303, 6.16056770587811],
              [38.988965176458201, 6.14839647055735],
              [39.012155185517699, 6.14356675070411],
              [39.035327228271598, 6.13873698702889],
              [39.043174012278399, 6.14188314873787],
              [39.056455603754102, 6.14720634948614],
              [39.080557402826997, 6.15429568051948],
              [39.103736182945497, 6.15863631400797],
              [39.132657443517701, 6.15930616169519],
              [39.130827126126299, 6.16663647297121],
              [39.113617651070797, 6.17719748038478],
              [39.104005677530701, 6.20288686462058],
              [39.100115972350501, 6.21916702601211],
              [39.0943959497789, 6.23452689167674],
              [39.0863762400799, 6.25264782911766],
              [39.091215913673103, 6.27166777542593],
              [39.110287147154899, 6.28861771332489],
              [39.120166369491997, 6.29983700488824],
              [39.126155886648903, 6.32252709061607],
              [39.1271777202846, 6.336486635129],
              [39.127797557830597, 6.34498628158132],
              [39.1250576962141, 6.36126646274963],
              [39.121637360769803, 6.38121757446885],
              [39.117067181761797, 6.3984161082705],
              [39.112267932356403, 6.4185978693832],
              [39.107938052686997, 6.43831688477958],
              [39.100376483782902, 6.45620750181277],
              [39.095806304774896, 6.4727162069542],
              [39.096298132393002, 6.49518663724333],
              [39.0970280135613, 6.52223684785948],
              [39.087867443451501, 6.54517806674959],
              [39.085388093267397, 6.54640742932911],
              [39.070197581812899, 6.55391740794155],
              [39.052976877816299, 6.55760765308964],
              [39.048858102238597, 6.57044744057402],
              [39.053246372401603, 6.58465671650757],
              [39.065216423562497, 6.5997780270134],
              [39.059716488235402, 6.60940652299097],
              [39.032607578748902, 6.61242710150463],
              [39.011706027875697, 6.6097166127726],
              [38.983667362070101, 6.60447852331589],
              [38.959307297353, 6.60152705410726],
              [38.9420865933564, 6.61095696995436],
              [38.944866879160699, 6.63089818415345],
              [38.9492573951119, 6.64258725573943],
              [38.950407238675602, 6.64625676389831],
              [38.936636065370003, 6.65567690167165],
              [38.933487470299198, 6.65638847945055],
              [38.918256534656898, 6.65982812928007],
              [38.902176691071197, 6.66627685006441],
              [38.877356239770997, 6.66882865527615],
              [38.855527178366799, 6.66931715493345],
              [38.839676405178601, 6.67644830222859],
              [38.833945153665901, 6.69227831936721],
              [38.818336925604299, 6.70422688780877],
              [38.810285774870401, 6.70193845961618],
              [38.779704876810698, 6.70082769820043],
              [38.754646371960199, 6.69764707019387],
              [38.742696532893198, 6.70155705387312],
              [38.7277665328712, 6.71258870412144],
              [38.721345824377899, 6.73071700311814],
              [38.705955437772701, 6.74265793669229],
              [38.688945837867898, 6.74497739659015],
              [38.6788263161923, 6.74957836927114],
              [38.672405607699098, 6.76288815928911],
              [38.658616468087899, 6.77023871730608],
              [38.643895326369297, 6.76933774473179],
              [38.636075491821103, 6.77232834276745],
              [38.637705934061799, 6.7851781137397],
              [38.637526271004901, 6.7881552332275],
              [38.636796389836597, 6.80031774575055],
              [38.6402549036805, 6.81362836414321],
              [38.648526141658998, 6.81934809256833],
              [38.6561461010565, 6.8246173180174],
              [38.6630451624386, 6.82896778588592],
              [38.6667147803742, 6.83151873378399],
              [38.670644909742201, 6.83423913525649],
              [38.683984891711397, 6.84156853331495],
              [38.699175403165903, 6.84980756111007],
              [38.712526614076097, 6.86102757248972],
              [38.711396982606303, 6.87181922590581],
              [38.705205344510503, 6.88604867208143],
              [38.700165795766601, 6.90394746953786],
              [38.702256624590397, 6.91563884473564],
              [38.705036910394703, 6.93123804169287],
              [38.701605346009401, 6.9456975479599],
              [38.695175654363297, 6.95809901830113],
              [38.7011764004612, 6.96978904920682],
              [38.711765291872801, 6.97435887144246],
              [38.714785877015601, 6.99201802858054],
              [38.710905154988197, 7.013588400651],
              [38.6998468938407, 7.01455800745249],
              [38.697556189866198, 7.01475861550418],
              [38.689716143224103, 7.00122852338072],
              [38.677995374554499, 7.00721792232817],
              [38.674786143201999, 7.01707897568702],
              [38.678484956384402, 7.02969921431546],
              [38.685166176309998, 7.03472759966277],
              [38.681276471129799, 7.04895879393204],
              [38.668405858896499, 7.06045933015209],
              [38.6539160333637, 7.06230919592516],
              [38.637355591100999, 7.06669758283923],
              [38.6408253338859, 7.07861889685215],
              [38.644074989426201, 7.09673763437198],
              [38.630746236397997, 7.11144827658802],
              [38.6118950901608, 7.12477904092364],
              [38.584045070564898, 7.126889375479],
              [38.566765976074798, 7.1129190920592],
              [38.547175965516402, 7.0915984928984],
              [38.546221505527001, 7.09160072150645],
              [38.528535923370903, 7.09162969340999],
              [38.505505365274303, 7.08843831584876],
              [38.480424402541701, 7.09099899694388],
              [38.464315363709197, 7.1004281532989],
              [38.460425658528997, 7.11878893596252],
              [38.445244130227401, 7.12775846502614],
              [38.440514500256498, 7.12685817736204],
              [38.423866472253501, 7.12370492815795],
              [38.418764041439701, 7.12273777882574],
              [38.401035789307599, 7.12206924122851],
              [38.378474600947001, 7.13035903851604],
              [38.357314784429498, 7.14644794792096],
              [38.342593642711002, 7.16504976471141],
              [38.323243931491099, 7.1639289531901],
              [38.321483233534202, 7.16087177535003],
              [38.314254041285302, 7.1483197510367],
              [38.305944624907198, 7.12239013939683],
              [38.289584057795103, 7.1077088184679],
              [38.272293734363998, 7.08407910798921],
              [38.254774340535498, 7.06044818635995],
              [38.247163364290799, 7.04024850791104],
              [38.242494370601598, 7.02972819009617],
              [38.2423012328155, 7.02929578210406],
              [38.2384047902706, 7.02050934289331],
              [38.216764375076202, 7.01500826095893],
              [38.194194203562702, 7.01294868195484],
              [38.1704741884855, 7.00147817608249],
              [38.146983243805799, 6.97806819680606],
              [38.131053868030101, 6.97481808038275],
              [38.127873831924397, 6.97416939189413],
              [38.117514010910199, 6.98151891300213],
              [38.109453877023498, 6.99942968255393],
              [38.1092225608378, 7.00103905472323],
              [38.102312270514702, 7.01137947279111],
              [38.0882738484122, 7.02354962121244],
              [38.073763810785401, 7.0302096335727],
              [38.064553833334998, 7.04398851258893],
              [38.063403989771302, 7.06604010751376],
              [38.064502180206098, 7.07310958975824],
              [38.066862503615198, 7.08830905567721],
              [38.069874105605201, 7.12298959440131],
              [38.076793379081103, 7.15467936765145],
              [38.078412592380701, 7.18085892962287],
              [38.087633798772202, 7.19601025621627],
              [38.101034417023101, 7.20830028429894],
              [38.105153192600802, 7.21207902217154],
              [38.125663976325399, 7.2230897636058],
              [38.144793600300801, 7.23340958375115],
              [38.164614927044802, 7.24624887515608],
              [38.181903004687697, 7.25909893968576],
              [38.199413415363402, 7.26275026936942],
              [38.205375983061799, 7.26663993877786],
              [38.2208539554072, 7.2767382187955],
              [38.234454448808698, 7.28912851789859],
              [38.247363239441498, 7.29783850147756],
              [38.266034722621903, 7.30791825800614],
              [38.276183439544297, 7.31961940061209],
              [38.281503711814501, 7.33866846232362],
              [38.277153620051102, 7.36116900418914],
              [38.279484748213399, 7.38113833704208],
              [38.293314312012399, 7.39259924905467],
              [38.309695091218401, 7.40267908246561],
              [38.319385667345799, 7.41482980822835],
              [38.325175309351899, 7.4338792261529],
              [38.328195894494797, 7.45636823379158],
              [38.327975807250198, 7.46026956608417],
              [38.326365577103402, 7.46326011859953],
              [38.3420546535406, 7.47976011866277],
              [38.362585649359097, 7.48890944504751],
              [38.379184270021398, 7.49599898327679],
              [38.392555693025599, 7.50309953887756],
              [38.406156186427097, 7.5044800034654],
              [38.414685690049801, 7.50534835797745],
              [38.427376639226203, 7.51543004954122],
              [38.429945820938798, 7.53447953252381],
              [38.426065098911401, 7.55444986520653],
              [38.419175020682196, 7.56983997238926],
              [38.401444522761899, 7.57927902576972],
              [38.399865733650103, 7.59420967179911],
              [38.402866106699001, 7.59800959093296],
              [38.409104906347203, 7.60588980815905],
              [38.420666224053903, 7.6249197131045],
              [38.418624802570697, 7.643058438352],
              [38.411285566699398, 7.66281005269376],
              [38.412704904848297, 7.68208891717217],
              [38.4263368392849, 7.69743882589939],
              [38.439056983707999, 7.71944005984186],
              [38.4430252914756, 7.73939952512889],
              [38.4451453155461, 7.76027928783274],
              [38.469166266243498, 7.77881941659826],
              [38.492946917602303, 7.79597920878122],
              [38.500367001849099, 7.81178998162944],
              [38.503865939880797, 7.83151812079864],
              [38.513136553612902, 7.84893826773023],
              [38.514886022628701, 7.85010848388726],
              [38.5289873268012, 7.85957910438043],
              [38.5332273749422, 7.86242892464021],
              [38.550317823222599, 7.87201939373191],
              [38.5637274246263, 7.88597868003544],
              [38.567006275413299, 7.90593018692251],
              [38.576056801900798, 7.92472852202805],
              [38.587166716177201, 7.93914867227805],
              [38.589077881944199, 7.96162868329903],
              [38.592336520637303, 7.97516013459788],
              [38.596996531173701, 7.99005889221737],
              [38.598617990261502, 7.99234959193569],
              [38.613336886191803, 8.007098721243191],
              [38.6115582219293, 8.008868936711799],
              [38.610168079027098, 8.012089107932431],
              [38.625127274295899, 8.02685976011997],
              [38.627658277608901, 8.03313968207256],
              [38.628567821834103, 8.035390112051109],
              [38.6130876037005, 8.043858021312101],
              [38.616516922297599, 8.057219968948001],
              [38.622257156963101, 8.068969401676149],
              [38.607468641598302, 8.07974905010901],
              [38.593816495067898, 8.09144001826297],
              [38.584087740540902, 8.10728832053155],
              [38.584956860578302, 8.110999063192921],
              [38.588886989946303, 8.127789245148429],
              [38.5883974081164, 8.14298028604604],
              [38.587667526948103, 8.156790227368599],
              [38.581866656000898, 8.1724292097194],
              [38.5684278593505, 8.19447835503969],
              [38.5519976728039, 8.21353891103313],
              [38.542958375257498, 8.22639954587601],
              [38.542457564486597, 8.246658755511801],
              [38.549808029298902, 8.263709915452401],
              [38.561578205309097, 8.26719917830107],
              [38.580058796491599, 8.264718915652431],
              [38.594377942120502, 8.261309640288349],
              [38.601557727028798, 8.250739366001129],
              [38.604378437020898, 8.224050166188389],
              [38.6136782459998, 8.195769833818551],
              [38.624588285125398, 8.164028473581279],
              [38.630858525808598, 8.14517897086955],
              [38.635967693986998, 8.133458440873669],
              [38.644517409703603, 8.125888379233309],
              [38.654928883846502, 8.114869849326841],
              [38.667428941025101, 8.09672952519934],
              [38.673678969614301, 8.090988661576329],
              [38.679888574015798, 8.0965294190131],
              [38.684919139606897, 8.123009272776409],
              [38.681858130276197, 8.149009411133781],
              [38.679048649225201, 8.17178009854417],
              [38.682468984669399, 8.19088842212874],
              [38.693987632400102, 8.20449887559225],
              [38.715428172443801, 8.2192780152971],
              [38.717267472987999, 8.22066943550757],
              [38.7163287335161, 8.2317094927357],
              [38.702458745529299, 8.24501849824952],
              [38.6828080986892, 8.258549305800679],
              [38.6624679948685, 8.271388475390459],
              [38.651358080592203, 8.285629529939969],
              [38.658019088423899, 8.3049790255302],
              [38.666288080614201, 8.327318539487489],
              [38.667637799328602, 8.35102996202111],
              [38.662048032473201, 8.376559627351121],
              [38.654859264412003, 8.395178118428399],
              [38.640048291165101, 8.412638252429741],
              [38.6171592177257, 8.431679321063219],
              [38.604679372641101, 8.43211029461831],
              [38.591287737543098, 8.426558696439111],
              [38.584577322370698, 8.433218827482619],
              [38.577857924045503, 8.444708283366509],
              [38.574936153583899, 8.445727922735889],
              [38.566078764882498, 8.44882014591539],
              [38.548507717925098, 8.45360948863074],
              [38.5272490867264, 8.458158864827331],
              [38.513628381231001, 8.45005969393725],
              [38.501858205220799, 8.44451057520309],
              [38.478288657953698, 8.44790047867996],
              [38.468607064979103, 8.43544032665713],
              [38.466098519548197, 8.41723920783304],
              [38.4624378847654, 8.4008901281444],
              [38.452538450334401, 8.38612893219593],
              [38.448646499365999, 8.36653904429051],
              [38.448197341723898, 8.364239384087959],
              [38.441556545986103, 8.336369090980559],
              [38.433296536948603, 8.31331899299528],
              [38.431017061915099, 8.299730066245729],
              [38.422036154862099, 8.289569730715311],
              [38.401267105493297, 8.28006028997099],
              [38.382806726404603, 8.27401982013181],
              [38.375206979101002, 8.268779337498451],
              [38.367355703517802, 8.263369877358651],
              [38.347747726653701, 8.254788819063551],
              [38.334906309667197, 8.258820450623061],
              [38.331795892995899, 8.25980057092753],
              [38.322287225713502, 8.27289083315506],
              [38.307435828278798, 8.30023896503115],
              [38.308275753069502, 8.330409483020359],
              [38.312605632738901, 8.352989739694159],
              [38.311866768417701, 8.37048953998055],
              [38.3012262238773, 8.376899567219271],
              [38.289655923017897, 8.381240995361701],
              [38.282446942862798, 8.400099205326169],
              [38.266717442237898, 8.40717968449767],
              [38.238737166925802, 8.409850104626461],
              [38.217945659674797, 8.40931913237244],
              [38.198775611511699, 8.401881001085099],
              [38.181936691510899, 8.39238095815926],
              [38.178925089520902, 8.394440505444701],
              [38.166326217661101, 8.40673090992502],
              [38.162697023913204, 8.410269994303871],
              [38.1571566643984, 8.40979012034969],
              [38.139356547043597, 8.40996118659595],
              [38.122946572590998, 8.407130808084281],
              [38.119036655316798, 8.402740792370601],
              [38.110284818661299, 8.390730202017989],
              [38.103356562032502, 8.38955045140599],
              [38.086935358638797, 8.390641332040181],
              [38.081864368860003, 8.38555127013618],
              [38.077496310790899, 8.381161010429789],
              [38.067105048741901, 8.37651074708422],
              [38.052895946735298, 8.38283846875076],
              [38.052754462078099, 8.38290067898641],
              [38.039324648580497, 8.3897615181628],
              [38.028886224978997, 8.390781302246021],
              [38.027994647059501, 8.39087017219182],
              [38.008635952686802, 8.373051341692729],
              [37.988565343451299, 8.36007107651294],
              [37.973754370204396, 8.36345949624187],
              [37.948774467941199, 8.36796104960446],
              [37.928384956779901, 8.37871035301526],
              [37.9103849642744, 8.371031674619781],
              [37.888023651064401, 8.350421140196399],
              [37.863344684421499, 8.33625132182957],
              [37.8395438209687, 8.33269156584895],
              [37.816654747529398, 8.332351587459341],
              [37.796983888595399, 8.33526028190858],
              [37.779392629544098, 8.34023989880157],
              [37.763863004069897, 8.34893018930908],
              [37.7483625738424, 8.35231203991134],
              [37.742983911078703, 8.34960122901224],
              [37.731283354503098, 8.34370181729291],
              [37.717442561763001, 8.334640325165321],
              [37.704942504584501, 8.33758011132444],
              [37.692613127309897, 8.354111830189501],
              [37.683903960630403, 8.36967189286756],
              [37.679102465436799, 8.378250436439791],
              [37.668643829741399, 8.38307175708314],
              [37.664273525884198, 8.38509136165168],
              [37.631053826677402, 8.36510148051752],
              [37.612362131403103, 8.35463176805764],
              [37.598433752922801, 8.365861368056841],
              [37.587683164760101, 8.39024141688839],
              [37.5764721900143, 8.41461104927185],
              [37.558373382827497, 8.42605218411415],
              [37.537581875576599, 8.424241619284111],
              [37.532933093981299, 8.423841738792699],
              [37.512601973313402, 8.41751246420835],
              [37.499012708852902, 8.40430040890798],
              [37.486631678449498, 8.3809410517822],
              [37.472191260257297, 8.35181209669426],
              [37.466963065303702, 8.332420471993521],
              [37.464092947970997, 8.312061231478481],
              [37.463381033108298, 8.307041256206],
              [37.464892448573799, 8.28353162916383],
              [37.472831309897202, 8.26720140075164],
              [37.487691690484802, 8.254611017385541],
              [37.490512400476902, 8.25297079313088],
              [37.501842401997898, 8.246380934638481],
              [37.5180727133937, 8.237481673171439],
              [37.534511883093103, 8.233652084225559],
              [37.551871825958699, 8.230291439384541],
              [37.574322970697096, 8.22603058082983],
              [37.596783098588297, 8.21924022905718],
              [37.611142668404902, 8.214470243044561],
              [37.622492882019799, 8.210151422474389],
              [37.623923449109697, 8.20094015264562],
              [37.617783464142803, 8.17832006763974],
              [37.606572489397003, 8.151521519778671],
              [37.599983346787901, 8.128200542436129],
              [37.596583223437499, 8.11366037162129],
              [37.595902749609799, 8.11204180017357],
              [37.592971995995399, 8.095431056620351],
              [37.592931571807597, 8.09348112248454],
              [37.592612669881703, 8.075141941843791],
              [37.595231258934902, 8.05810052115573],
              [37.598101376267699, 8.037131295853889],
              [37.600731194262003, 8.021011161941431],
              [37.602393077537599, 8.01180000520333],
              [37.602202185539703, 8.00281103179454],
              [37.602702996310597, 7.9961303303339],
              [37.602002310388997, 7.97749984707051],
              [37.601773239991502, 7.9729916628923],
              [37.601391455995802, 7.96544084353121],
              [37.589102502909, 7.96014073015173],
              [37.573161898192303, 7.95280097190532],
              [37.557910750456202, 7.94890194992842],
              [37.549832650263802, 7.94982054771836],
              [37.539672704400402, 7.95442017835454],
              [37.533202588566503, 7.96017186824007],
              [37.528812072615402, 7.96660183277182],
              [37.525351312983297, 7.97258020798908],
              [37.522121869536903, 7.97763106628988],
              [37.5207317266347, 7.98407187653033],
              [37.518822806655997, 7.99242075909965],
              [37.514391866517002, 8.00023127989097],
              [37.507191869514799, 8.00803053160098],
              [37.502082701336398, 8.012380434320979],
              [37.497440657105699, 8.01512022649035],
              [37.491190628516399, 8.01762204981687],
              [37.478032555392303, 8.014771081922859],
              [37.460041546039598, 8.008210666531649],
              [37.446411857391297, 8.005822204309361],
              [37.433011239140399, 8.003900749675751],
              [37.424930893159797, 8.003380354153141],
              [37.4170818633648, 8.001721310999489],
              [37.409702203305699, 7.99868119697299],
              [37.4062414436737, 7.99566107588431],
              [37.4027919129827, 7.99264093243454],
              [37.392241199970698, 7.99437117935529],
              [37.392241199970698, 7.99276102693079],
              [37.390621986671, 7.98586220802831],
              [37.388540141000099, 7.9755204333982],
              [37.390621986671, 7.96311218277579],
              [37.398030841976798, 7.95668216347174],
              [37.401480372667798, 7.95368174983278],
              [37.417420977384502, 7.9447204134248],
              [37.4296605231307, 7.93185080041988],
              [37.437520781866702, 7.91967033941449],
              [37.435212111586502, 7.89921009137446],
              [37.425049919934899, 7.8787510541389],
              [37.412581303791299, 7.85507185456494],
              [37.405650801374399, 7.82841223004041],
              [37.4045099409635, 7.80129032455236],
              [37.402899710816797, 7.77163220988334],
              [37.400831339875097, 7.74221010873992],
              [37.402450553174702, 7.71072071105512],
              [37.402001395532601, 7.68383158372153],
              [37.407779808597702, 7.65877032772212],
              [37.420951356451098, 7.63441097666705],
              [37.4288116151872, 7.61166186415523],
              [37.436211487340103, 7.58523175636202],
              [37.445911046620402, 7.56548992396529],
              [37.439461142880397, 7.54731007623562],
              [37.426080736723499, 7.52500165100166],
              [37.408100956311799, 7.49373007640373],
              [37.407950488501697, 7.49299084217097],
              [37.401430965327201, 7.46178154567429],
              [37.397530031205903, 7.43464973702277],
              [37.3910891106188, 7.40360999669186],
              [37.380268903021602, 7.38062163132732],
              [37.356980079280802, 7.36152091411966],
              [37.338539912286002, 7.35323091108701],
              [37.331429746812198, 7.35052025587225],
              [37.332078779604998, 7.34412999336318],
              [37.2880298896482, 7.33501107003818],
              [37.262659220236401, 7.32602115229867],
              [37.238218307143697, 7.31265176687265],
              [37.211938093506802, 7.29331200430729],
              [37.200819196077603, 7.28255025179084],
              [37.183138105498003, 7.26545031837405],
              [37.154569433674801, 7.24126068788658],
              [37.128978677018402, 7.2290406213137],
              [37.1099074435365, 7.22587025648162],
              [37.105218237753398, 7.22509047254522],
              [37.068517566820702, 7.23584023246333],
              [37.054858682925698, 7.23812159936238],
              [37.039897241868701, 7.24061014755992],
              [37.003677169612999, 7.2350315029517],
              [36.965168639170997, 7.2255316076231],
              [36.966183735442002, 7.24251943619619],
              [36.955850863886504, 7.26108389439956],
              [36.931517748627897, 7.28330988717986],
              [36.914517131875897, 7.29883423560656],
              [36.879538980500499, 7.330284480806],
              [36.8415582102879, 7.32694331772184],
              [36.803840197295997, 7.26985902688595],
              [36.7914973452922, 7.25287668482233],
              [36.784746505931999, 7.25609141585415],
              [36.762906215586902, 7.26650181821843],
              [36.739776842809, 7.28531255612603],
              [36.720126195968902, 7.29889215295973],
              [36.714565624360198, 7.30273250225651],
              [36.694456836725202, 7.31372097604622],
              [36.672946677246898, 7.32863172193228],
              [36.652125974749197, 7.34560227879075],
              [36.6384356498193, 7.37179077012605],
              [36.633335464793703, 7.37845235161137],
              [36.623175518930303, 7.37981093666189],
              [36.613325491839902, 7.37346118613378],
              [36.606345582082298, 7.36895104845371],
              [36.598716639531901, 7.36962144713323],
              [36.575364933721197, 7.37992229590725],
              [36.541615228496802, 7.39363263187247],
              [36.506935766953397, 7.40642277103664],
              [36.486584434191698, 7.41649115340819],
              [36.482014255183699, 7.41670271836305],
              [36.470605651075402, 7.41722160882176],
              [36.453544398041799, 7.41801219182094],
              [36.427455076402701, 7.41379202106072],
              [36.406674798092801, 7.40913308372915],
              [36.375013675904, 7.41616155727591],
              [36.362024036895697, 7.43200193533931],
              [36.349043381040097, 7.44600236754457],
              [36.3240836908709, 7.44754334534909],
              [36.302133356903397, 7.45046272630553],
              [36.287313400503699, 7.46031187486247],
              [36.279774289481701, 7.4636720695032],
              [36.265794257872599, 7.46991140816484],
              [36.241274742192502, 7.47788302457277],
              [36.225073626043397, 7.48497274210829],
              [36.216824845947002, 7.49156580878482],
              [36.207154481913499, 7.49929210578169],
              [36.200974072758697, 7.5042217233273],
              [36.181262789636897, 7.52418231771105],
              [36.156934165954802, 7.5432024734973],
              [36.127992693288597, 7.55622199287045],
              [36.087323714588301, 7.55447212816196],
              [36.062572882722598, 7.5580631278555],
              [36.045442010254497, 7.566062066924],
              [36.035702026786403, 7.57316370038417],
              [36.032863350488597, 7.59203255008915],
              [36.0401733911131, 7.61461352956036],
              [36.047503643831497, 7.63490289617053],
              [36.052282681142998, 7.65448350330362],
              [36.0422732030897, 7.67494235161966],
              [36.020933723515498, 7.69259377187252],
              [36.018613824294199, 7.69742324703627],
              [36.012543458761797, 7.71006196785716],
              [35.997613458739799, 7.74569271405161],
              [35.988792002649703, 7.75417323822942],
              [35.9867730390486, 7.75510339290766],
              [35.985203233089599, 7.75582214738692],
              [35.978831931937002, 7.75874388282803],
              [35.961463005918603, 7.7669726980464],
              [35.952520277265201, 7.77042839635349],
              [35.942003251076301, 7.77449375646964],
              [35.924843183361403, 7.78571278893928],
              [35.921023097615702, 7.78883234755037],
              [35.898163219423097, 7.80748246192111],
              [35.875431351158397, 7.82236279614011],
              [35.860362112267303, 7.82990288250579],
              [35.848991686558598, 7.83953414872313],
              [35.8515024777777, 7.84852446427017],
              [35.8597826989091, 7.86121424954554],
              [35.874993422457401, 7.87693356110305],
              [35.887421614413199, 7.89332407871726],
              [35.895692852391797, 7.90993416401795],
              [35.902803017865601, 7.92677268637361],
              [35.905502455294297, 7.94658431634141],
              [35.899162595176698, 7.97165276487878],
              [35.892142261231299, 7.99258310914597],
              [35.888412007013997, 8.0010830446094],
              [35.878393545807803, 8.04079376153333],
              [35.8730934856315, 8.05933462519228],
              [35.869033100547298, 8.065662962598161],
              [35.861242461245801, 8.07779459312985],
              [35.837713338166502, 8.080053668831241],
              [35.816721955764798, 8.082984222601681],
              [35.809822894382798, 8.081743520340829],
              [35.798452468674, 8.079693462726141],
              [35.783221533031799, 8.080734057263721],
              [35.766142313692498, 8.08179466047357],
              [35.753792724324001, 8.068573608779779],
              [35.744412066969602, 8.05143407069183],
              [35.728381630724499, 8.040313440049459],
              [35.725731600636301, 8.031004875306211],
              [35.723622805506899, 8.023604124978579],
              [35.730640893664102, 8.00701421327714],
              [35.739282686697301, 7.98996326078451],
              [35.768181489387402, 7.97025381194155],
              [35.772140814002199, 7.96403297285204],
              [35.783282169313502, 7.95509410442915],
              [35.796042737924402, 7.94916440664968],
              [35.800691519519702, 7.9418133307681],
              [35.800491644369004, 7.93375257469392],
              [35.792671809820703, 7.92128300332752],
              [35.784151289350802, 7.91112422749432],
              [35.776331454802602, 7.89957268124825],
              [35.762522103097503, 7.88317351304362],
              [35.739911507396201, 7.86719415412802],
              [35.716811329865003, 7.85604405424623],
              [35.7038419029506, 7.85967254142056],
              [35.694101919482499, 7.86492277939377],
              [35.681361562965499, 7.85690279165965],
              [35.6767913839575, 7.85403291097116],
              [35.667341107168603, 7.84546319577021],
              [35.641961454604001, 7.82946458673734],
              [35.618650172981098, 7.81531428289159],
              [35.606390415141099, 7.81270443386779],
              [35.589991669629498, 7.80920458514996],
              [35.570540897940099, 7.81349428759565],
              [35.555000043524799, 7.82078312075896],
              [35.544770478226901, 7.83156484247425],
              [35.5365800886239, 7.84995275188915],
              [35.533489884046602, 7.86790381714061],
              [35.530891507087198, 7.88055296714302],
              [35.522950399975599, 7.8957243184948],
              [35.519240357852198, 7.89662301450852],
              [35.512550154773699, 7.89291476859634],
              [35.505170494714697, 7.88527349710162],
              [35.497559518469998, 7.88086440810186],
              [35.490599820806302, 7.88313347087116],
              [35.485290777477097, 7.88034385665347],
              [35.474679428183499, 7.87407494208737],
              [35.460099771122202, 7.87308498771296],
              [35.445030532231101, 7.8785441673291],
              [35.433900405860904, 7.88332478343793],
              [35.4290607322677, 7.87984332579733],
              [35.418718877559201, 7.8645935291346],
              [35.410250010218199, 7.84451321905875],
              [35.400840157616997, 7.82995405406291],
              [35.389510156096101, 7.82759348134299],
              [35.372359071533999, 7.8330443594912],
              [35.3584194641127, 7.84356324017721],
              [35.350359330225899, 7.83569412795952],
              [35.342788778169002, 7.82252298823902],
              [35.342180169564003, 7.80455439114738],
              [35.341629951452497, 7.77737308458438],
              [35.327108684884699, 7.7664742562792],
              [35.323418854855198, 7.7653349585906],
              [35.311168080168002, 7.76155433729248],
              [35.293399403848099, 7.75247314159017],
              [35.290383310281698, 7.74800479202741],
              [35.275933908936601, 7.7465828321955],
              [35.248310713949998, 7.72708658931269],
              [35.2230388592195, 7.7037615714967],
              [35.220921080937202, 7.68141009876524],
              [35.221078286111897, 7.66370034505548],
              [35.212690267146399, 7.65207520608379],
              [35.173299141937797, 7.65480624080682],
              [35.116265104549001, 7.64582736976908],
              [35.077071608702902, 7.73099214102665],
              [35.038482229885297, 7.73949966500944],
              [34.995448436199602, 7.74796028631516],
              [34.931953266129803, 7.72815477798643],
              [34.937087137978601, 7.76477865976856],
              [34.932251955961803, 7.82794055979156],
              [34.944251202369401, 7.87937838770435],
              [34.9506629277098, 7.89027203914559],
              [35.000591291201196, 7.86230656741994],
              [35.013077873650403, 7.88244385507093],
              [35.008456041513597, 7.92348733785264],
              [35.048316536458202, 7.95442240253484],
              [35.108532855740997, 7.95709808268061],
              [35.1410002158973, 7.98901361720062],
              [35.140692542912497, 8.02167608285613],
              [35.1179112673072, 8.08257510256453],
              [35.089064117745899, 8.11390715872813],
              [35.1038548788989, 8.115103295891339],
              [35.143246004107603, 8.14284022785754],
              [35.157634769170897, 8.13669540452053],
              [35.138107640682399, 8.18181230495974],
              [35.118888185178697, 8.200595615003561],
              [35.073222327710397, 8.220171547686389],
              [35.018234203381297, 8.219629204884241],
              [34.964766477670501, 8.247332192498449],
              [34.925923324785202, 8.24902800398652],
              [34.882559400232502, 8.250917169421781],
              [34.855070952538398, 8.250632683910339],
              [34.826403466034002, 8.26192082285003],
              [34.785779403097898, 8.30362568076071],
              [34.795047771041801, 8.32689633826037],
              [34.785224693409901, 8.3552294735542],
              [34.767615468053002, 8.363366176224121],
              [34.729807623532601, 8.389388263188611],
              [34.667401660744801, 8.395024819459801],
              [34.630310222663503, 8.40198764196823],
              [34.5742486115698, 8.403465059519791],
              [34.556502393132099, 8.45512002965099],
              [34.522662856379299, 8.47553174496184],
              [34.471171424293502, 8.447562822526351],
              [34.436328020210802, 8.42176013200393],
              [34.394890981942503, 8.400770156809431],
              [34.347255568213903, 8.376632947739971],
              [34.329163498391701, 8.386946544720541],
              [34.313272301015601, 8.38991481920903],
              [34.315183466782599, 8.40677978638884],
              [34.334885766751597, 8.438592612062269],
              [34.344032862132103, 8.4692277525288],
              [34.357408776712603, 8.499913761056821],
              [34.358199294162702, 8.522028821099051],
              [34.3535617415084, 8.555659827627689],
              [34.345445462916402, 8.60008648943991],
              [34.312403180978301, 8.68208645526639],
              [34.279363144828302, 8.70882383217652],
              [34.255708257609299, 8.702625832392121],
              [34.235152558120397, 8.686133591906859],
              [34.223494671520697, 8.669753852633839],
              [34.198454132975897, 8.656157549444069],
              [34.189614710580202, 8.651619418564239],
              [34.154030196388, 8.651168710798981],
              [34.141031574226801, 8.652596320242971],
              [34.144463138612103, 8.669756072760769],
              [34.142174680425804, 8.688058353033011],
              [34.143317786624898, 8.69606587484118],
              [34.141031574226801, 8.69492036793169],
              [34.141031574226801, 8.70178447708731],
              [34.142174680425804, 8.708648460384961],
              [34.140643052866402, 8.71308600739335],
              [34.1399221548509, 8.72097534307772],
              [34.140223090471103, 8.72505535621427],
              [34.140122030001599, 8.729257412361941],
              [34.140652036019198, 8.73086674516018],
              [34.141543613938701, 8.73598548718202],
              [34.141103439449502, 8.743126303466431],
              [34.140782291735398, 8.74859558340798],
              [34.140043427414199, 8.75383616141999],
              [34.139832323322501, 8.75889687826583],
              [34.139562828737198, 8.764705519682639],
              [34.139472997208799, 8.771095586233219],
              [34.1404139824689, 8.775006368142449],
              [34.1404341945628, 8.77880613493506],
              [34.140452160868499, 8.783926436691891],
              [34.139892959604197, 8.790367218677821],
              [34.140312921999502, 8.79278636700354],
              [34.140512797150201, 8.80107571412505],
              [34.141402129281502, 8.807687084394299],
              [34.141593021279299, 8.81194590718246],
              [34.141332509846997, 8.817815856069521],
              [34.141123651543403, 8.824036346591621],
              [34.140912547451599, 8.830185718832441],
              [34.140923776392697, 8.8328353986896],
              [34.140883352204902, 8.836457040859029],
              [34.140903564298803, 8.84140567329314],
              [34.140463389809597, 8.84762576585427],
              [34.140593645525797, 8.852225859458621],
              [34.140553221338003, 8.856945722053849],
              [34.140683477054203, 8.859936934121739],
              [34.140573433431904, 8.862466575181729],
              [34.140782291735398, 8.872136744204729],
              [34.140703689148097, 8.88048643508578],
              [34.140773308582602, 8.88485757666847],
              [34.141083227355601, 8.89014725392283],
              [34.141054032108897, 8.8958473281722],
              [34.141013607921103, 8.901136846675181],
              [34.140344363034401, 8.90746686203765],
              [34.140234319412102, 8.909887459345381],
              [34.140883352204902, 8.913226570473659],
              [34.143567069116202, 8.96221369577284],
              [34.221457741614003, 8.96334949945852],
              [34.267217922187001, 8.964250153762819],
              [34.305364880727197, 8.96499773950638],
              [34.375929792082999, 8.966368679096909],
              [34.430625963944799, 8.96742239128378],
              [34.503683700213998, 9.00412072167579],
              [34.483642286225297, 9.047131817419469],
              [34.458305303636699, 9.073170640443029],
              [34.426810369775502, 9.0717823648414],
              [34.393244819184403, 9.116315386840499],
              [34.3408528260263, 9.168764621948119],
              [34.401718178101802, 9.186620650861819],
              [34.439535005775099, 9.21556217750426],
              [34.452365193820498, 9.257468317262109],
              [34.451702686298503, 9.30868876533162],
              [34.466246410748397, 9.365798137794799],
              [34.451781288885797, 9.44909745791545],
              [34.512949822369698, 9.447987582307389],
              [34.498707033540001, 9.5142171735116],
              [34.523206337126197, 9.5410983148467],
              [34.5321176247446, 9.590551681044129],
              [34.548491666585903, 9.6296158726228],
              [34.548408572422098, 9.629673440371979],
              [34.585369754787202, 9.630346539480531],
              [34.631990072244797, 9.58800732589188],
              [34.686070898136997, 9.56464664119226],
              [34.704207883723399, 9.55748907903452],
              [34.735112175285302, 9.54528854335895],
              [34.7826173332978, 9.553786221693731],
              [34.7920316774754, 9.60330195645802],
              [34.810828924795601, 9.630105198178139],
              [34.810431420282299, 9.662370334306379],
              [34.809935101087902, 9.70222755309792],
              [34.7961167662299, 9.736225530446641],
              [34.8072873167879, 9.76104171412145],
              [34.8316406441404, 9.803102380675631],
              [34.854439886051303, 9.81666998598906],
              [34.869702262728502, 9.820653193065491],
              [34.877050481752597, 9.84542323861832],
              [34.892128703796601, 9.86459142057077],
              [34.8976578343703, 9.88174514610888],
              [34.866771509114102, 9.902247464446081],
              [34.887756154151099, 9.90820079521697],
              [34.9043951990012, 9.955865573802489],
              [34.927630123824997, 9.935267065453759],
              [34.942971103089498, 9.933554888741099],
              [34.966091492714597, 9.965894488852079],
              [34.988158607669, 9.99675347517398],
              [35.014993530993799, 9.995178746844649],
              [35.0172774976037, 9.964826138733279],
              [35.019628837859898, 9.928778885932131],
              [35.0484894621504, 9.9177289968005],
              [35.080995000706302, 9.92190786757538],
              [35.080792879767401, 9.938994447586721],
              [35.088316270271903, 9.95047495991748],
              [35.159559409667203, 9.948389031691679],
              [35.220772858915304, 9.925411972222641],
              [35.2228636877391, 9.910242734028531],
              [35.230719454898697, 9.89323872591102],
              [35.278446945943998, 9.90706588765123],
              [35.297430593685597, 9.922469765667779],
              [35.310965959229101, 9.911222771651371],
              [35.324667513100103, 9.88478285313942],
              [35.349775425291298, 9.8660627863812],
              [35.363764440053203, 9.813029735395901],
              [35.386866863372603, 9.80188080422537],
              [35.408013205160699, 9.794506989972589],
              [35.419603718114097, 9.785132360547539],
              [35.378806729485802, 9.7653929879669],
              [35.3830063534391, 9.75898336029722],
              [35.375339232489097, 9.749937519164551],
              [35.365055768274097, 9.727453546412431],
              [35.369437301072402, 9.721563371906431],
              [35.389361934074202, 9.720036026740379],
              [35.400251761105899, 9.71612466319305],
              [35.410741837836198, 9.713665372718459],
              [35.424481570106899, 9.700735538544301],
              [35.441185742815101, 9.684998143961129],
              [35.455349929057398, 9.67221774754908],
              [35.468400204347503, 9.670362524022529],
              [35.484857340352498, 9.677991456439329],
              [35.505080663186298, 9.688688495317169],
              [35.533251830496297, 9.69040636549054],
              [35.533581961363197, 9.695865415149891],
              [35.534311842531501, 9.696945703172171],
              [35.537543531766097, 9.697494700669351],
              [35.541192937607903, 9.697755916904811],
              [35.553701977939198, 9.69610449558586],
              [35.561063671692601, 9.69200468525346],
              [35.569202408166703, 9.67806451270307],
              [35.578493233992702, 9.665505243542651],
              [35.5944023976745, 9.656025153226601],
              [35.624734013242801, 9.65115438181747],
              [35.634703067108298, 9.651475412101011],
              [35.653412728688302, 9.63491427857042],
              [35.650524645049899, 9.613016024389109],
              [35.6435559642333, 9.59453757762615],
              [35.645224584873603, 9.577643697842751],
              [35.647468127295603, 9.553296785980949],
              [35.671367805429597, 9.51722056001603],
              [35.686461747991103, 9.51040310040862],
              [35.710736472756203, 9.486757999518639],
              [35.731826669839101, 9.481486082441521],
              [35.752867459581402, 9.48168101191054],
              [35.764967766458497, 9.473336602491051],
              [35.770108375671803, 9.459458339575219],
              [35.777301635309399, 9.44012530717232],
              [35.778390842591399, 9.430685466272759],
              [35.7658975227775, 9.42758605826814],
              [35.753979124745499, 9.416535190487849],
              [35.750096156929899, 9.40307117564863],
              [35.7551536719795, 9.36477441326878],
              [35.748932838636897, 9.349874670017179],
              [35.749413437313898, 9.34732409971965],
              [35.750534085630903, 9.331113913553359],
              [35.7567234779385, 9.32362572749976],
              [35.7739307072058, 9.32431050762751],
              [35.797167877817699, 9.30860454919439],
              [35.808428259904197, 9.30886162997078],
              [35.859845580978998, 9.32495761253273],
              [35.911065272691303, 9.315944574217321],
              [35.917847553086403, 9.285626083638221],
              [35.930702444802101, 9.267218731461099],
              [35.949297571183401, 9.249140686397601],
              [35.975389138610602, 9.242894279730161],
              [36.031554055961998, 9.23341362751931],
              [36.043941823730002, 9.195049519056511],
              [36.0535874840932, 9.119424197621701],
              [36.033999719322999, 9.10740346044776],
              [36.028050626353902, 9.05596318406063],
              [36.042425916688103, 9.03216324279828],
              [36.0418397659652, 9.01554826206856],
              [36.063918109860602, 9.0198956009844],
              [36.086521968197303, 9.01260268840509],
              [36.0969514086459, 9.032400562435731],
              [36.075513114390397, 9.062625377363609],
              [36.084150415847198, 9.05840718542864],
              [36.094620280483603, 9.064965099693779],
              [36.108528446869997, 9.08001879673847],
              [36.128661938175298, 9.05110396320112],
              [36.131404045580098, 9.02077393453037],
              [36.159608899713199, 9.02064750786378],
              [36.167064916571398, 9.027977963332679],
              [36.1767667216399, 9.04451252811954],
              [36.225924779775099, 9.03095446278099],
              [36.2464535298055, 9.04094840105884],
              [36.235783790018402, 9.05093319066669],
              [36.207626097437597, 9.06713847739594],
              [36.184561852517902, 9.071937603593931],
              [36.161450446045698, 9.105338983983859],
              [36.171888869647098, 9.108283797483089],
              [36.203922792678803, 9.157333099228209],
              [36.228900449153798, 9.2290179054018],
              [36.226311055347303, 9.27171593290087],
              [36.194870020403101, 9.28895947308999],
              [36.194573576359403, 9.32627619586856],
              [36.200974072758697, 9.340257304734759],
              [36.205867645269002, 9.354727571431379],
              [36.234245425094301, 9.34699392026954],
              [36.214563337219197, 9.33240366479375],
              [36.214738508699597, 9.31485865028286],
              [36.218349736141803, 9.297104423557791],
              [36.253154961824997, 9.301809569803449],
              [36.270202740129399, 9.32835710968355],
              [36.275094066851402, 9.343820636030079],
              [36.289613087630997, 9.3215159757469],
              [36.295458874342401, 9.301678810588299],
              [36.318972276904198, 9.305836486444431],
              [36.326154307600802, 9.33075934192958],
              [36.333745071751601, 9.33978529356402],
              [36.349236518826203, 9.34487766199554],
              [36.374692528190003, 9.35800490353486],
              [36.408213163016903, 9.357772233917339],
              [36.436325939833402, 9.357958369623811],
              [36.461386690472096, 9.357142917219401],
              [36.484087117701797, 9.367854441146269],
              [36.475166846930499, 9.3946251578732],
              [36.483619993754097, 9.40514719829932],
              [36.499104703464099, 9.414722885317721],
              [36.507241194149998, 9.41933338872259],
              [36.514888103006101, 9.44412181997447],
              [36.4986398253046, 9.472871418401891],
              [36.494406514528201, 9.509353226129999],
              [36.461144145345401, 9.52804449279024],
              [36.419152397389198, 9.51525374351748],
              [36.400606678348602, 9.51097454961084],
              [36.3802845408336, 9.480467131141729],
              [36.355111500784403, 9.49471002359514],
              [36.335930223680201, 9.51198012069462],
              [36.314152815404903, 9.540675302657419],
              [36.3010014796454, 9.555502563089529],
              [36.266241169726399, 9.56868156187457],
              [36.231752600180897, 9.57030924683561],
              [36.229111553245602, 9.57981167099627],
              [36.200753985514098, 9.614915848244721],
              [36.192588299581502, 9.65848265206362],
              [36.191842697895602, 9.687864977115771],
              [36.177471899137899, 9.701302239216419],
              [36.161863671076397, 9.730452825003191],
              [36.159033977931401, 9.74692292203151],
              [36.143392063046697, 9.75773948870269],
              [36.132468549191799, 9.762584364508379],
              [36.1052630708122, 9.773309681622489],
              [36.136448085900398, 9.80743539997774],
              [36.132394438180803, 9.82982323867623],
              [36.1281835852865, 9.852200711097391],
              [36.152319071182603, 9.848919307750799],
              [36.152170849160697, 9.866350421034941],
              [36.134004668327599, 9.915877357295161],
              [36.119775354227201, 9.961320244710009],
              [36.120624262170701, 9.971561330947431],
              [36.139875158709302, 10.0096916142117],
              [36.167302970121703, 10.033834792631399],
              [36.184543886212197, 10.049648406318999],
              [36.218899954253303, 10.053199784212101],
              [36.258895196490499, 10.078438651256301],
              [36.267858137237802, 10.088477041832901],
              [36.269436926349698, 10.103600369736499],
              [36.271869114981399, 10.1130741975026],
              [36.289878090639803, 10.121185871410701],
              [36.3322920467795, 10.1383813229348],
              [36.369396959590098, 10.1616394770806],
              [36.395250473467001, 10.187084227281799],
              [36.416169990645997, 10.2167528365111],
              [36.445327058980297, 10.245830824058901],
              [36.490889610190798, 10.234256968165999],
              [36.509754231157302, 10.239357738070099],
              [36.515465270576101, 10.2399190829547],
              [36.522418230875203, 10.2504231210622],
              [36.536600383423199, 10.2610594031591],
              [36.5478966981211, 10.269527510990899],
              [36.562914283883302, 10.2771070707294],
              [36.571935615124097, 10.2637399638978],
              [36.610547451823798, 10.2592097367157],
              [36.659667331559397, 10.277027519442701],
              [36.671441999145998, 10.2840036491879],
              [36.692168378538902, 10.296276136844799],
              [36.686014918842602, 10.323049007570599],
              [36.6851705024756, 10.325349021728],
              [36.682664202832903, 10.3276998353381],
              [36.682387970882999, 10.3290718716251],
              [36.682235257284702, 10.3302163353162],
              [36.681691776537797, 10.3308901971234],
              [36.680791215465497, 10.333735866461501],
              [36.680966386945897, 10.335468000398301],
              [36.682293647778202, 10.337463035687801],
              [36.6826799233503, 10.338105951337299],
              [36.683564763905203, 10.339400615971501],
              [36.684004938394402, 10.3401584120782],
              [36.684835880032203, 10.341333765687001],
              [36.687703751576798, 10.342199812899199],
              [36.687908118303902, 10.34355190224],
              [36.688006932985203, 10.344208061144901],
              [36.68810125609, 10.344835498006701],
              [36.6882045623477, 10.345522584117401],
              [36.688388716980903, 10.346746521565899],
              [36.688485285874002, 10.347391627469699],
              [36.688795204647001, 10.349461701499401],
              [36.688999571374097, 10.350822596515499],
              [36.689089402902503, 10.3514124631381],
              [36.689181480219197, 10.352031048666801],
              [36.689374618005203, 10.353314609746899],
              [36.689473432686498, 10.353961911362701],
              [36.689969751881002, 10.357269093645099],
              [36.693289026855801, 10.357344206264401],
              [36.697746916453198, 10.3574480383849],
              [36.699601937514899, 10.357490013062201],
              [36.700875299430201, 10.357518732575],
              [36.701513103281897, 10.357534196926901],
              [36.707246600582799, 10.357664539291701],
              [36.707823768152799, 10.357677794444401],
              [36.710085276880598, 10.357728605857901],
              [36.711477665571003, 10.3577617437318],
              [36.713038488377201, 10.357797090793399],
              [36.714253459798897, 10.3578236010871],
              [36.716164625565902, 10.3578677849048],
              [36.7167462847124, 10.3578810400489],
              [36.717442479057603, 10.357896504383],
              [36.718080282909298, 10.3579119687163],
              [36.719412035318001, 10.357942897380701],
              [36.720103738086799, 10.357958361711701],
              [36.721898122866797, 10.3604083439495],
              [36.723047966430499, 10.360671235405301],
              [36.723851958609799, 10.3609297083024],
              [36.725176973653802, 10.3611395793013],
              [36.728826379495601, 10.3612412009976],
              [36.730274912891197, 10.3612389918307],
              [36.7316650557934, 10.3612809660002],
              [36.7323006138569, 10.3613582868243],
              [36.7337985545932, 10.3615769941946],
              [36.736756257666102, 10.361552693383199],
              [36.739181708933302, 10.361797910575399],
              [36.740466299789503, 10.3614930459291],
              [36.741113086794101, 10.361225736828599],
              [36.742395431862199, 10.360978310019901],
              [36.7437316758473, 10.358495194444499],
              [36.7451195729613, 10.3585261230513],
              [36.745755131024801, 10.3610224933929],
              [36.746502978498803, 10.361216900160199],
              [36.747134044985899, 10.361409097641101],
              [36.748450076877198, 10.361965806920599],
              [36.750087256482502, 10.3636381379744],
              [36.751872658109598, 10.3654628906047],
              [36.7540084026977, 10.3675991189789],
              [36.758277646085403, 10.368188954029099],
              [36.759443210166602, 10.367994551588501],
              [36.760671656317598, 10.3676874838511],
              [36.7640066518099, 10.366638149382799],
              [36.765246326902002, 10.364146242074799],
              [36.765917817576899, 10.363017366194301],
              [36.766227736349897, 10.362451822149501],
              [36.766876769142698, 10.3620696375101],
              [36.767359613607901, 10.361451071787901],
              [36.767900848566597, 10.360834714014601],
              [36.768462295619102, 10.3595865305813],
              [36.768653187616998, 10.3590187711625],
              [36.769021496883497, 10.358397990231801],
              [36.769466162949101, 10.3571453787034],
              [36.770005152119602, 10.356586451662301],
              [36.770532912348997, 10.3559612475068],
              [36.7716827559127, 10.354914082983701],
              [36.772199287201097, 10.354083418343],
              [36.772722555854102, 10.3531621733359],
              [36.773030228838898, 10.3525966115113],
              [36.773223366624997, 10.3519713994011],
              [36.773897103088103, 10.350840270437899],
              [36.774090240874102, 10.350215054827],
              [36.773944264640498, 10.3474026737921],
              [36.774552873245497, 10.3470690746835],
              [36.775215380767499, 10.3467045454508],
              [36.775866659348502, 10.346346643610399],
              [36.776535904235203, 10.3459776949896],
              [36.777178199663297, 10.3456264201512],
              [36.777699222528099, 10.3453392140164],
              [36.778804150327602, 10.3447316617113],
              [36.779394792626903, 10.3444068969059],
              [36.780497474638103, 10.343799342796199],
              [36.7810387095968, 10.3435032978221],
              [36.781977449068698, 10.342988532389001],
              [36.785388801360099, 10.3448089866154],
              [36.790215000224102, 10.344322944044301],
              [36.792559603115599, 10.3430282997058],
              [36.794755983985297, 10.3418176035822],
              [36.796258416298002, 10.340081086028601],
              [36.796849058597303, 10.339754107094301],
              [36.797156731582099, 10.3391885211069],
              [36.7976373302591, 10.338627352753401],
              [36.798003393737403, 10.338063974067],
              [36.798483992414397, 10.3375028037043],
              [36.7990768805019, 10.3371183793338],
              [36.799665277012998, 10.3368488401268],
              [36.800249181947699, 10.336691977039299],
              [36.800895968952297, 10.336369413260901],
              [36.801549493321502, 10.335870101553599],
              [36.8048081320146, 10.335452534959],
              [36.807830962945701, 10.3351432260125],
              [36.808639446701399, 10.3352802057264],
              [36.809270513188501, 10.335472419095099],
              [36.810577561926898, 10.3362589461374],
              [36.811772321254701, 10.336985819097],
              [36.8130232252879, 10.3385411890198],
              [36.814712058022003, 10.3412144630777],
              [36.817070135642801, 10.341744696549799],
              [36.817703447918099, 10.341879464081201],
              [36.818451295392201, 10.3420760920152],
              [36.822084980716397, 10.3425864403477],
              [36.822657656710099, 10.3428360909614],
              [36.825038192213, 10.3427344633019],
              [36.826268884152199, 10.342255045421],
              [36.827784791194198, 10.341954580385099],
              [36.829121035179298, 10.341824231493399],
              [36.832756966291797, 10.342392022033099],
              [36.833390278567101, 10.3424693475136],
              [36.834607495777099, 10.342506905597199],
              [36.838313046324103, 10.3407792291002],
              [36.840862015942697, 10.3407991128991],
              [36.843473867631303, 10.3406466704085],
              [36.845557959090499, 10.3407681825447],
              [36.846829075217499, 10.340863182909301],
              [36.848681850490998, 10.3409184156662],
              [36.850186528591898, 10.3409648111745],
              [36.852847787621101, 10.341159230372799],
              [36.856326513558898, 10.3410929511142],
              [36.857777292742703, 10.3410774859518],
              [36.858992264164499, 10.3411172535106],
              [36.859627822227999, 10.341134927979599],
              [36.8614244527962, 10.3411305093625],
              [36.862062256648002, 10.341150393139101],
              [36.8660148438981, 10.3407527173663],
              [36.867243290049103, 10.3404434136392],
              [36.867831686560201, 10.3401164350822],
              [36.871454142943399, 10.338207580494799],
              [36.874164809313299, 10.335938591259501],
              [36.874530872791503, 10.3353752077509],
              [36.875972668822499, 10.3336916792464],
              [36.8767070415673, 10.332450015960999],
              [36.877612094216097, 10.3313276541791],
              [36.8783734164194, 10.3292243197319],
              [36.8791639338694, 10.328040083220399],
              [36.878734988321199, 10.325545660006201],
              [36.8811289985534, 10.3244762997964],
              [36.881495062031703, 10.323912895744201],
              [36.881863371298202, 10.32328983597],
              [36.882245155293901, 10.322154184838199],
              [36.88243829308, 10.321584148288901],
              [36.882577531949003, 10.320839564108701],
              [36.883017706438302, 10.3197016946728],
              [36.883801486523701, 10.318749416312199],
              [36.884392128823002, 10.3183649690113],
              [36.885636295491501, 10.317483389806799],
              [36.888713025339598, 10.3155036941737],
              [36.894082704950399, 10.312388299718499],
              [36.895414457359102, 10.3124302804161],
              [36.896052261210798, 10.3124479564976],
              [36.896685573486103, 10.3125827365864],
              [36.898412584619898, 10.3129782384813],
              [36.899854380650901, 10.3131373229023],
              [36.900606719701301, 10.313159417954401],
              [36.902392121328504, 10.3135593381297],
              [36.907784258821401, 10.313490843549999],
              [36.909059866524899, 10.313413510942301],
              [36.910218693241397, 10.3134466534908],
              [36.910854251304897, 10.313526195593001],
              [36.912008586444998, 10.313674232229699],
              [36.913454874052398, 10.313718422257001],
              [36.914609209192498, 10.3138664588033],
              [36.918541584348802, 10.314158112691],
              [36.919756555770498, 10.314193464659001],
              [36.923709143020702, 10.314001238285],
              [36.925514756741698, 10.313561547632],
              [36.928566782919503, 10.3122734051493],
              [36.929218061500499, 10.311888949939499],
              [36.929927730575002, 10.3114514658541],
              [36.930520618662499, 10.3109521145896],
              [36.931290924018597, 10.310399733949801],
              [36.932469962829003, 10.309873866680601],
              [36.933226793455901, 10.3096529137846],
              [36.937493791055502, 10.310187619526699],
              [36.939503771503702, 10.310991885958201],
              [36.940709759772602, 10.311371923227799],
              [36.941340826259697, 10.311504494260401],
              [36.942605205022097, 10.311888949939499],
              [36.943171143651099, 10.3123065478178],
              [36.9446106938939, 10.3126379743111],
              [36.945241760381002, 10.3128302015174],
              [36.949639013696803, 10.313018009594099],
              [36.950979749258302, 10.3127705448108],
              [36.951675943603497, 10.312735192683],
              [36.952383366889798, 10.312721935634],
              [36.953537702029898, 10.312558431984501],
              [36.9546156803708, 10.312235843454101],
              [36.955293908410297, 10.3121187393167],
              [36.956565024537298, 10.312074549064899],
              [36.9580719484265, 10.312061291988099],
              [36.958711998066399, 10.3119662829217],
              [36.961204822979802, 10.3119839590293],
              [36.9619683909713, 10.3119309307036],
              [36.963398958061298, 10.3117387029484],
              [36.964225408122701, 10.3116657889416],
              [36.966747428282801, 10.311911045079199],
              [36.969498518840403, 10.3140432187675],
              [36.9703519183604, 10.314708277243501],
              [36.971306378349702, 10.3151104049427],
              [36.972909871131897, 10.3157246429643],
              [36.974068697848402, 10.315815231923599],
              [36.9777562820897, 10.3146044309959],
              [36.980267073308802, 10.313987981289401],
              [36.981493273671703, 10.3136808607342],
              [36.982254595874899, 10.3134731675271],
              [36.9841365663952, 10.312551803456399],
              [36.986786596483299, 10.3111951617641],
              [36.988205934632198, 10.3103157720077],
              [36.988511361828799, 10.3097501330779],
              [36.989106495704597, 10.309250779116001],
              [36.991017661471503, 10.307408023573901],
              [36.9947434241124, 10.3049907715444],
              [36.9954508473987, 10.3046659656994],
              [36.996036998121497, 10.3044538474159],
              [36.997171121167803, 10.3033932538565],
              [36.998190709015198, 10.302275207615001],
              [36.998801563408399, 10.300878749032],
              [36.9991721184631, 10.300036893638101],
              [37.000052467441598, 10.2982471148587],
              [37.000418530919802, 10.297624004307],
              [37.000919341690697, 10.296433020075201],
              [37.002700251741501, 10.293209164499601],
              [37.004739427436498, 10.2909707861788],
              [37.006161011373599, 10.2899764373101],
              [37.007351279124997, 10.2889776659592],
              [37.0075421711229, 10.288407569390699],
              [37.008022769799901, 10.2878463105228],
              [37.009271428044798, 10.2868497421212],
              [37.011692387735501, 10.285369246669701],
              [37.0147915754658, 10.2844831557726],
              [37.018209665121802, 10.284467687829601],
              [37.0202376118757, 10.284529559597001],
              [37.022871921446402, 10.2849317257894],
              [37.025369237936303, 10.2853140041677],
              [37.027325319467401, 10.285831073607801],
              [37.028823260203701, 10.2860476238055],
              [37.029458818267202, 10.2861824151801],
              [37.030729934394202, 10.286277432016099],
              [37.032003296309497, 10.286314996803799],
              [37.0332182677313, 10.2863503518941],
              [37.033981835722798, 10.2860873983154],
              [37.035149645592099, 10.285775831186699],
              [37.037123693429002, 10.2856609269197],
              [37.038345402215398, 10.2854686831488],
              [37.040903354986902, 10.285199099732701],
              [37.042648332426303, 10.2850775661502],
              [37.043665674485602, 10.2851085019756],
              [37.044952511130099, 10.287388905904301],
              [37.050104349284503, 10.283745113077501],
              [37.051388940140797, 10.2834379630643],
              [37.054521814694098, 10.2829429868749],
              [37.0572145147583, 10.282518720952201],
              [37.059193054171601, 10.282231457243499],
              [37.060531543944897, 10.2820414211079],
              [37.0612861287836, 10.2820038558118],
              [37.063257930832201, 10.2819486127211],
              [37.064812016273699, 10.2803841243819],
              [37.068025739202703, 10.2776042658174],
              [37.069925676028603, 10.2761657125535],
              [37.070945263876098, 10.275045360084],
              [37.071967097511703, 10.2738100950657],
              [37.072510578258601, 10.273136111222399],
              [37.074322929344397, 10.270716386896201],
              [37.075171837287797, 10.266290121790901],
              [37.075957863161399, 10.2656779973778],
              [37.076609141742402, 10.265178573694],
              [37.077628729589897, 10.264168674721001],
              [37.077938648362903, 10.2635477067937],
              [37.078839209435301, 10.2623676433884],
              [37.0792075187018, 10.261797498818201],
              [37.080447193793802, 10.261088131465501],
              [37.085057796989602, 10.2598970094996],
              [37.087157608966201, 10.259448403960899],
              [37.089019367392503, 10.2590970326762],
              [37.093097718782502, 10.258467215246499],
              [37.095197530759101, 10.257958940617799],
              [37.098707697731797, 10.2569070305333],
              [37.100879374931097, 10.2559390933767],
              [37.102889355379403, 10.254739112383101],
              [37.104998150508798, 10.2539280729303],
              [37.106518549127202, 10.2536871916029],
              [37.108678997385503, 10.2531170314007],
              [37.110909065078303, 10.252149082660701],
              [37.1129684528672, 10.2510573743685],
              [37.114309188428699, 10.250697154005399],
              [37.116467390898798, 10.2502971542265],
              [37.118459405041399, 10.249788866486099],
              [37.1203795539612, 10.249318147112399],
              [37.122598392712902, 10.2486374812415],
              [37.125697580443202, 10.247868415446399],
              [37.127788409266898, 10.2478175863194],
              [37.129769194468402, 10.2472982447728],
              [37.132567446578499, 10.246688293614399],
              [37.134797514271298, 10.2457777846288],
              [37.1370680061519, 10.2454374480743],
              [37.139978547672399, 10.245178880447799],
              [37.1420087402146, 10.2449490423804],
              [37.145837809113097, 10.2448385432503],
              [37.148047664712003, 10.244728044081601],
              [37.151357956534, 10.244478315818499],
              [37.154598628921498, 10.2443987563306],
              [37.155997754976497, 10.244208697473001],
              [37.157509170441998, 10.244137977869],
              [37.15906774746, 10.2441888075859],
              [37.160457890362203, 10.2442882570086],
              [37.161848033264299, 10.2443788664555],
              [37.163179785673002, 10.2444186462044],
              [37.164567682787002, 10.244347926647301],
              [37.1659084183486, 10.244268367126599],
              [37.167709540493199, 10.2440385283991],
              [37.1693377369457, 10.2437379698118],
              [37.173409350970999, 10.2433976310675],
              [37.174678221309797, 10.2433180713084],
              [37.1754395435131, 10.2431677917088],
              [37.179549335937899, 10.2415589115332],
              [37.182158941838303, 10.241298130736],
              [37.183259378061301, 10.2413290708418],
              [37.184728123550897, 10.2408472888518],
              [37.185958815490103, 10.2403080457565],
              [37.187198490582198, 10.239598630287899],
              [37.188029432219999, 10.2387389315344],
              [37.188808720729, 10.2379190110151],
              [37.189239912065297, 10.2373576625911],
              [37.1898395375175, 10.2366283501631],
              [37.190969168987301, 10.2355078578027],
              [37.192049393116399, 10.234449243042199],
              [37.1931790245862, 10.233448086376599],
              [37.194479335959997, 10.232449136622201],
              [37.195727994204901, 10.231397141889],
              [37.1961479566002, 10.2308291516868],
              [37.197538099502403, 10.2289174262246],
              [37.198658747819302, 10.2265371461804],
              [37.199619945173303, 10.225359155121],
              [37.199988254439802, 10.224729270679401],
              [37.200318385306801, 10.223478338576101],
              [37.2005182604575, 10.222329067991399],
              [37.200329614247799, 10.221007401679801],
              [37.199678335666803, 10.219548700037899],
              [37.198669976760399, 10.2165870730792],
              [37.1980097150266, 10.2153582108088],
              [37.197109153954202, 10.212747967818],
              [37.196069354012899, 10.210758775879301],
              [37.1944299286193, 10.2077572827203],
              [37.192709654850297, 10.204848592233001],
              [37.192049393116399, 10.203628525695301],
              [37.190268483065701, 10.200927564660899],
              [37.188498801955902, 10.198578019365801],
              [37.186958191243697, 10.1957687096236],
              [37.185478216813102, 10.1929085371972],
              [37.184979651830403, 10.192207857347601],
              [37.183448024271001, 10.1891686130389],
              [37.181548087445101, 10.1868189810332],
              [37.179868237863801, 10.1848672039349],
              [37.178368051339298, 10.1828689962075],
              [37.176418707172701, 10.1801081775474],
              [37.174808477025998, 10.177877843026801],
              [37.174249275761603, 10.177227970570801],
              [37.172389763123498, 10.175678439410101],
              [37.170808728223399, 10.174078059816001],
              [37.169598248378101, 10.1720886264842],
              [37.168468616908299, 10.1691287688462],
              [37.168098061853598, 10.1678488816569],
              [37.1677297525871, 10.164957511410901],
              [37.167518648495303, 10.164318665373401],
              [37.1675096653425, 10.162477278473199],
              [37.167958822984602, 10.1612879977422],
              [37.169809352469798, 10.1574283312869],
              [37.170307917452497, 10.156177135025899],
              [37.1719698007281, 10.155018779353901],
              [37.173148839538598, 10.154187590256299],
              [37.173797872331299, 10.153687991514801],
              [37.175048776364498, 10.152697634181299],
              [37.176007727930298, 10.151517159605],
              [37.176818457474198, 10.149697805428699],
              [37.178718394300098, 10.146417194118699],
              [37.179448275468403, 10.1452875418769],
              [37.179809847370301, 10.1447282409552],
              [37.180267988165198, 10.1430083292333],
              [37.180519516444797, 10.1423871249141],
              [37.180979903027897, 10.1404483406967],
              [37.181348212294402, 10.1398182885673],
              [37.181849023065197, 10.1385692341915],
              [37.182367800141797, 10.1367475957181],
              [37.182769796231497, 10.1348574137829],
              [37.183158317591896, 10.1335486497013],
              [37.1836478994217, 10.131749090364901],
              [37.184058878664203, 10.1305884355577],
              [37.184638292022399, 10.1287092712489],
              [37.185278341662404, 10.1267085018534],
              [37.185718516151603, 10.125397493703099],
              [37.186358565791501, 10.123518298984701],
              [37.1867987402808, 10.1223885660396],
              [37.187348958392299, 10.1212477749207],
              [37.1876094698247, 10.1205071428569],
              [37.187838540222103, 10.1185571720571],
              [37.187989008032197, 10.1172991914127],
              [37.188548209296599, 10.1160478384655],
              [37.189109656349103, 10.114798691526],
              [37.1900281837272, 10.113047666752999],
              [37.190338102500199, 10.1124772551067],
              [37.191088195762397, 10.110609039329701],
              [37.191398114535403, 10.1100386233538],
              [37.192819698472597, 10.107148942368701],
              [37.193298051361403, 10.106527668618099],
              [37.194308656056002, 10.1055791736367],
              [37.195449516466802, 10.104458221414401],
              [37.196698174711699, 10.103348320097099],
              [37.197288817011099, 10.102848642157801],
              [37.199148329649198, 10.101398245157601],
              [37.200421691564401, 10.100409937356901],
              [37.201663612444698, 10.0994415254254],
              [37.202795489702702, 10.0985593394159],
              [37.205032294760201, 10.096817070215501],
              [37.206550447590303, 10.0949421303847],
              [37.207172530924602, 10.094174905394],
              [37.207904657881102, 10.093268383595399],
              [37.210604095309897, 10.0899319191819],
              [37.211194737609198, 10.0894830747435],
              [37.212553439476501, 10.0885522180272],
              [37.214224305904899, 10.0869337149075],
              [37.216043394355303, 10.086002850822601],
              [37.217873711746599, 10.0850233401042],
              [37.219214447308197, 10.0845435335414],
              [37.221154808321899, 10.083621506942199],
              [37.221743204832997, 10.083351752858],
              [37.222394483414, 10.082962599027001],
              [37.223683565846699, 10.082372234079299],
              [37.224274208145999, 10.081983079065299],
              [37.225513883238101, 10.081333012255101],
              [37.226802965670799, 10.080853200197],
              [37.228204337514001, 10.080373387424499],
              [37.228844387153998, 10.0801633309168],
              [37.230133469586697, 10.079683517117299],
              [37.231483188301098, 10.079201491475001],
              [37.232123237941003, 10.078931733690901],
              [37.234833904310797, 10.077523240610899],
              [37.2360039599684, 10.0770920680296],
              [37.236652992761201, 10.076652050288001],
              [37.238063347757198, 10.0759422716604],
              [37.238714626338201, 10.0757322122654],
              [37.239994725618097, 10.075422650802199],
              [37.241153552334602, 10.075453606961901],
              [37.242653738859097, 10.0755022523497],
              [37.243754175082103, 10.0755332085017],
              [37.245254361606598, 10.0756924115225],
              [37.246464841452003, 10.075953327414201],
              [37.247783119131398, 10.0762827887048],
              [37.249054235258399, 10.0764928477411],
              [37.250904764743701, 10.076541492972099],
              [37.252294907645897, 10.0765835047566],
              [37.253624414266397, 10.0766830063297],
              [37.254783240982903, 10.0767714521466],
              [37.256755043031603, 10.076822308480301],
              [37.258255229555999, 10.0769815108646],
              [37.259405073119702, 10.0770235225917],
              [37.260795216021897, 10.0770036223006],
              [37.262834391716801, 10.076603405073699],
              [37.264004447374397, 10.076291633298499],
              [37.265863960012503, 10.0760528291817],
              [37.268195088174799, 10.075493407734299],
              [37.269484170607498, 10.075013586984401],
              [37.270784481981302, 10.0742418922071],
              [37.272194836977398, 10.073421549889],
              [37.272843869770099, 10.0730323840703],
              [37.2734345120694, 10.0727029194591],
              [37.274865079159397, 10.0715420515363],
              [37.275395085177003, 10.070982622264699],
              [37.276414673024497, 10.0698615496187],
              [37.276785228079198, 10.069293272647601],
              [37.277324217249699, 10.068623279446699],
              [37.277944054795697, 10.0673717372658],
              [37.278303380909399, 10.0667525979967],
              [37.280733323752898, 10.065091972163501],
              [37.28136439024, 10.065052170107901],
              [37.282583853238201, 10.0650322690783],
              [37.283284539159801, 10.064762499444599],
              [37.284454594817397, 10.0644529274559],
              [37.285685286756603, 10.0640814406777],
              [37.286325336396601, 10.0638116702494],
              [37.287623401982103, 10.0631615668156],
              [37.288804686580697, 10.0622814927347],
              [37.290003937484997, 10.060992334662799],
              [37.291133568954798, 10.059873438579499],
              [37.291735440195197, 10.0593117778195],
              [37.292633755479301, 10.0580734671816],
              [37.293433256082203, 10.056773235902799],
              [37.293925083700202, 10.055581352633499],
              [37.295115351451699, 10.054522142847301],
              [37.295703747962797, 10.0541329542457],
              [37.296294390262098, 10.053812315557],
              [37.296723335810199, 10.0532417990346],
              [37.297024271430402, 10.052682338055901],
              [37.297244358675002, 10.0513621832122],
              [37.297504870107403, 10.050101728832599],
              [37.298223522334702, 10.0475432124632],
              [37.298515474802102, 10.0455928000426],
              [37.298973615596999, 10.0436534327198],
              [37.299184719688697, 10.042333241015401],
              [37.299303746463899, 10.0403827971819],
              [37.299535062649497, 10.038602619929801],
              [37.300264943817901, 10.0373023103578],
              [37.300994824986198, 10.0360528583141],
              [37.301414787381603, 10.035493367605801],
              [37.301854961870802, 10.034852052956399],
              [37.302263695325102, 10.0342527543256],
              [37.302845354471501, 10.032313319050701],
              [37.303514599358202, 10.0311832664602],
              [37.304745291297401, 10.0289120920892],
              [37.3051854657867, 10.0277820276337],
              [37.305623394487696, 10.0264728286596],
              [37.306063568976903, 10.0253316982126],
              [37.306315097256402, 10.024701420952599],
              [37.3089853394385, 10.022591641838501],
              [37.310784215794897, 10.022361644124],
              [37.311484901716497, 10.0220918389047],
              [37.313474670070903, 10.021572131497001],
              [37.314054083429099, 10.021362036776599],
              [37.315284775368397, 10.0210524232563],
              [37.3158641887266, 10.0207826169475],
              [37.316834369233497, 10.019541947343001],
              [37.317474418873402, 10.0186020429674],
              [37.318163875853998, 10.0175714623331],
              [37.318725322906502, 10.0164325121149],
              [37.319275541018101, 10.015242691888099],
              [37.319834742282403, 10.0139334422839],
              [37.320283899924497, 10.0125733209687],
              [37.320535428204003, 10.0120027317928],
              [37.320854330129897, 10.0108018373428],
              [37.321494379769803, 10.009671709778299],
              [37.322345533501498, 10.008512827329],
              [37.323214653538898, 10.0080815627659],
              [37.324135426705197, 10.0076724138047],
              [37.326064558777801, 10.006922674640499],
              [37.327414277492203, 10.006562179971899],
              [37.328755013053701, 10.006022543032801],
              [37.330623508844702, 10.005511656415701],
              [37.331214151144003, 10.0052329906495],
              [37.333024256441497, 10.004761912262801],
              [37.337614647543397, 10.0045894045137],
              [37.339620136415199, 10.0044876691315],
              [37.340601545863102, 10.0044390130679],
              [37.343525562112902, 10.0042930448333],
              [37.343754632510297, 10.004562864851801],
              [37.3443542579625, 10.005261741890401],
              [37.344383453209197, 10.0053015512967],
              [37.345353633716101, 10.006732475049301],
              [37.345645586183402, 10.0072323016438],
              [37.346936914404303, 10.009032555656599],
              [37.348050825356601, 10.010558561682799],
              [37.348553881915699, 10.0111490577416],
              [37.350352758272201, 10.0129824637525],
              [37.351066918923102, 10.0142673898977],
              [37.351358871390403, 10.016173759760299],
              [37.351536288658998, 10.01802040666],
              [37.351785571150302, 10.0190841589638],
              [37.352248203521697, 10.021317806291799],
              [37.352919694196601, 10.024097680533099],
              [37.353440717061297, 10.026068125879499],
              [37.353687753764497, 10.0274547283861],
              [37.353824746845298, 10.028724116866],
              [37.354017884631403, 10.029993500372401],
              [37.354289625004803, 10.030634824642901],
              [37.3548645467867, 10.032720225476],
              [37.355235101841402, 10.0339387302459],
              [37.355452943297799, 10.0351749218697],
              [37.355776336799998, 10.039131145626699],
              [37.356903722481597, 10.0401992514087],
              [37.358201788067198, 10.0413292725286],
              [37.361280763703498, 10.0429104109158],
              [37.363061673754302, 10.043421238589399],
              [37.366243955648301, 10.0435119048887],
              [37.367517317563497, 10.0435494982249],
              [37.3687906794787, 10.043584880184399],
              [37.370829855173703, 10.0431249144095],
              [37.372235718593302, 10.0425897610983],
              [37.372821869316198, 10.0423774686304],
              [37.374283877441101, 10.0419573060438],
              [37.374928418657497, 10.041632232510301],
              [37.3762085179374, 10.041437630510901],
              [37.378339770948898, 10.041842314082899],
              [37.379478385571502, 10.042510151439201],
              [37.380217249892702, 10.0429900204765],
              [37.381638833829903, 10.043834765163],
              [37.382207018247101, 10.0442549253113],
              [37.384324796529398, 10.045062073538601],
              [37.386220241778901, 10.0456348158536],
              [37.388005643405997, 10.0458869106052],
              [37.388757982456497, 10.0459930557049],
              [37.389972953878299, 10.0460262260415],
              [37.391356359415802, 10.0462385161147],
              [37.395697468026299, 10.046362351926399],
              [37.396970829941601, 10.0463977335782],
              [37.397723168992002, 10.0464198471086],
              [37.3990639045536, 10.046054973663701],
              [37.399706199981701, 10.0459001787445],
              [37.400350741198103, 10.045690099807199],
              [37.401588170501903, 10.044918334960601],
              [37.402243940659297, 10.044363282313601],
              [37.4028952192403, 10.04386351298],
              [37.403663278808303, 10.0433106698973],
              [37.404256166895799, 10.0428661833737],
              [37.404844563406897, 10.0425388993739],
              [37.406142628992399, 10.0417693383942],
              [37.406784924420599, 10.041559256774301],
              [37.4080695152769, 10.0411921664583],
              [37.411400019192698, 10.0402501135009],
              [37.411988415703803, 10.039865330518101],
              [37.413162962937797, 10.039383245445901],
              [37.414568826357502, 10.038788378013701],
              [37.415851171425501, 10.0385362777312],
              [37.417138008069998, 10.0381138987476],
              [37.418368700009303, 10.0375721029093],
              [37.4195589677607, 10.0365150455613],
              [37.420857033346302, 10.035745470258099],
              [37.423738379620097, 10.0342173713459],
              [37.424924155795097, 10.0333305841553],
              [37.427560711154001, 10.030298682425],
              [37.429438190097798, 10.029431787726899],
              [37.430792400388697, 10.0286644070663],
              [37.432580047804102, 10.026990316724101],
              [37.433655780356801, 10.0258713030333],
              [37.434019598046902, 10.025305160247401],
              [37.435039185894297, 10.0241286416077],
              [37.435829703344403, 10.022826062418201],
              [37.438185535176999, 10.019499928146701],
              [37.439380294504801, 10.0182680998157],
              [37.440094455155702, 10.0175980009312],
              [37.440458272845802, 10.017034055252701],
              [37.440997262016303, 10.016472320151101],
              [37.442243674472998, 10.015415193975899],
              [37.442834316772299, 10.0149728807465],
              [37.444824085126598, 10.0143381602088],
              [37.445522525260003, 10.014185561706199],
              [37.446807116116297, 10.013820651952599],
              [37.451069622139499, 10.012617552645899],
              [37.454096944646899, 10.012126580691399],
              [37.456533624855098, 10.0119098450878],
              [37.460414346882501, 10.01196071162],
              [37.462199748509697, 10.0122393716115],
              [37.464045786418602, 10.012522454532499],
              [37.466004113737903, 10.0128652499266],
              [37.467153957301598, 10.013185929161001],
              [37.4688809684353, 10.013522089121601],
              [37.470147592985903, 10.0137299773441],
              [37.472676350510703, 10.014375756930299],
              [37.473951958214201, 10.0144133536474],
              [37.474729000935, 10.014304986627399],
              [37.4744954389611, 10.0129824637525],
              [37.476134864354599, 10.011812535177899],
              [37.477275724765398, 10.010631544399899],
              [37.477814713935899, 10.010072009815699],
              [37.478425568329101, 10.0088821662686],
              [37.479045405875098, 10.0075220237867],
              [37.4796046071395, 10.006102161653899],
              [37.481055386323398, 10.0040121663792],
              [37.481704419116099, 10.003572049257899],
              [37.4829957473371, 10.0032624187649],
              [37.484264617675898, 10.003302228416199],
              [37.485535733802898, 10.0033309798281],
              [37.487565926344999, 10.0033309798281],
              [37.488385639041802, 10.0031230849468],
              [37.489616330981001, 10.002640945242],
              [37.490966049695402, 10.001992930658201],
              [37.491745338204403, 10.0015616574373],
              [37.492834545486403, 10.001181251864701],
              [37.493425187785697, 10.000851714119101],
              [37.494716516006598, 10.0003120676931],
              [37.496124625214499, 9.999491538008281],
              [37.498175029850501, 9.998971794413711],
              [37.499515765411999, 9.99866215953673],
              [37.500095178770302, 9.99839233461765],
              [37.502165795500197, 9.997512084553721],
              [37.504135351760603, 9.997122827483819],
              [37.505395238946598, 9.99695252736916],
              [37.507706155015001, 9.997773063464519],
              [37.508916634860299, 9.99830165570112],
              [37.510066478424001, 9.9991111300121],
              [37.512725491665002, 10.0004514027168],
              [37.516774647808198, 10.0008318091439],
              [37.517414697448103, 10.0006017960087],
              [37.518054747088001, 10.0003828410459],
              [37.521306648416498, 9.999602121644561],
              [37.522694545530499, 9.99879264855657],
              [37.526555055464001, 9.997543048164269],
              [37.527284936632398, 9.99730197426139],
              [37.527875578931699, 9.997083017075401],
              [37.529966407755502, 9.99594178329394],
              [37.530655864735998, 9.9954817499389],
              [37.531484560585596, 9.994751887991839],
              [37.532645633090297, 9.993902592027201],
              [37.534985744405503, 9.990991967153869],
              [37.535515750423102, 9.99042134007442],
              [37.5369148764781, 9.98877138172427],
              [37.537965905360501, 9.98769204787366],
              [37.5389046448324, 9.98643134601445],
              [37.539205580452602, 9.98580099325312],
              [37.5401959730534, 9.984611060852799],
              [37.540905642127797, 9.9834122769721],
              [37.541956671010198, 9.98215155853368],
              [37.544415809100499, 9.979021859201501],
              [37.544995222458802, 9.97868123993254],
              [37.546634647852301, 9.976801192103],
              [37.548505389431497, 9.975202036996359],
              [37.550654608748701, 9.97413150553972],
              [37.551534957727199, 9.973631628076269],
              [37.551824664406297, 9.97248146462859],
              [37.5515551698211, 9.970121404996959],
              [37.551566398762098, 9.96897122916074],
              [37.551575381915001, 9.967652945710929],
              [37.551586610855999, 9.96622185009265],
              [37.551656230290497, 9.964901343618081],
              [37.5520155564042, 9.96312296464002],
              [37.552446747740603, 9.960431046670839],
              [37.5526960302319, 9.958542045779019],
              [37.553576379210298, 9.95665082198809],
              [37.556374631320402, 9.95432161552336],
              [37.5569562904668, 9.954091569538649],
              [37.558294780240203, 9.953131568198989],
              [37.560104885537697, 9.95177119280314],
              [37.562546057322301, 9.9503024234116],
              [37.564416798901398, 9.949101301364079],
              [37.566215675257901, 9.948371335530981],
              [37.568436759797898, 9.94632078641863],
              [37.570415299211099, 9.944792267327511],
              [37.570994712569401, 9.94434100996255],
              [37.572975497770898, 9.942861146878739],
              [37.573974873524499, 9.941662209541841],
              [37.5759466755731, 9.941281734043409],
              [37.579254721606901, 9.94084153218744],
              [37.580465201452199, 9.940852592542839],
              [37.585226272458101, 9.940091639218609],
              [37.587375491775298, 9.939472257298339],
              [37.588536564279998, 9.93879093583085],
              [37.589875054053401, 9.93777116305969],
              [37.590816039313502, 9.936001484759711],
              [37.590825022466298, 9.93468085608942],
              [37.590786844066798, 9.932842585195379],
              [37.590566756822199, 9.9315816713899],
              [37.590056962898402, 9.929741170937939],
              [37.589726832031502, 9.927781203548269],
              [37.589565135280402, 9.92589201394158],
              [37.590595952068902, 9.920270836142221],
              [37.591766007726498, 9.918501063228719],
              [37.592924834442996, 9.91817144196758],
              [37.5942049337228, 9.918060830731809],
              [37.597274926206303, 9.91826214315304],
              [37.599415162370697, 9.918162593070081],
              [37.6006368711571, 9.91771129898139],
              [37.601276920797098, 9.91743255820435],
              [37.602325703891303, 9.917091874710421],
              [37.602905117249499, 9.9160720343422],
              [37.6046051789247, 9.913961552005251],
              [37.605775234582303, 9.9131009845529],
              [37.606936307086997, 9.9122514761633],
              [37.610246598909001, 9.9108422607777],
              [37.611585088682297, 9.91011220960945],
              [37.613446847108698, 9.90915207923525],
              [37.614675293259701, 9.9082428287551],
              [37.615955392539597, 9.907561442314909],
              [37.617296128101103, 9.9064818401223],
              [37.617655454214798, 9.90528276958848],
              [37.617666683155797, 9.90396201708395],
              [37.617615030026997, 9.90275187570049],
              [37.617504986404697, 9.90206162852537],
              [37.617336552288897, 9.90068112982047],
              [37.617296128101103, 9.899132486543531],
              [37.617305111253998, 9.8979311767991],
              [37.617316340195003, 9.896030753329081],
              [37.617615030026997, 9.89483164461968],
              [37.618556015287098, 9.892951114765401],
              [37.619326320643196, 9.891692259942211],
              [37.6218258829213, 9.889530735225829],
              [37.622416525220601, 9.889240908415241],
              [37.624267054705903, 9.888862584484009],
              [37.625666180760902, 9.88847098557021],
              [37.627516710246198, 9.88779177052858],
              [37.628796809526101, 9.88734264710804],
              [37.630076908805897, 9.886951046383439],
              [37.632625878424598, 9.88663245561929],
              [37.635585827285801, 9.885902350620279],
              [37.637616019827902, 9.885172243999889],
              [37.639426125125397, 9.88415230446128],
              [37.640584951841902, 9.88324077230501],
              [37.641225001481899, 9.882731906167351],
              [37.642395057139403, 9.881711959059951],
              [37.643156379342699, 9.880512798111839],
              [37.644036728321197, 9.879141058825621],
              [37.644917077299603, 9.87783126371772],
              [37.645676153714703, 9.876572351005841],
              [37.646325186507497, 9.875140857413401],
              [37.647147144992402, 9.87400141061365],
              [37.648025248182698, 9.872972586328309],
              [37.648665297822603, 9.871830919486881],
              [37.650715702458598, 9.869952470661699],
              [37.652406780980897, 9.86824216576894],
              [37.654625619732698, 9.866020747449239],
              [37.656366105595701, 9.86489233200785],
              [37.658176210893203, 9.8635227402313],
              [37.660276022869802, 9.86153140116067],
              [37.661906465110498, 9.86028127659671],
              [37.663305591165503, 9.859602003385501],
              [37.664466663670197, 9.859212582281421],
              [37.665326800554801, 9.85867270226257],
              [37.6668359702321, 9.859281173531979],
              [37.6686258634357, 9.85981220208574],
              [37.669265913075698, 9.86028127659671],
              [37.674166222950497, 9.86238104063583],
              [37.675495729570997, 9.862622213941901],
              [37.676656802075797, 9.86257132417642],
              [37.679156364353801, 9.862562473781621],
              [37.680366844199199, 9.86237219023592],
              [37.681586307197399, 9.861980559801941],
              [37.683856799078001, 9.860391907280309],
              [37.684445195589099, 9.85913071528624],
              [37.684975201606697, 9.85787173109766],
              [37.685505207624303, 9.856672483340329],
              [37.68573652381, 9.856101622174579],
              [37.686035213642, 9.854842626420909],
              [37.686396785543799, 9.85352167140403],
              [37.687277134522297, 9.85250163384352],
              [37.6882652813348, 9.851362108761039],
              [37.689486990121203, 9.85056111984272],
              [37.6915261658161, 9.84937069625599],
              [37.693527163111497, 9.845662211611391],
              [37.694115559622603, 9.84382123001317],
              [37.694596158299603, 9.84182092106483],
              [37.695126164317301, 9.840672508129931],
              [37.696067149577402, 9.839422304538189],
              [37.6988070111939, 9.836742648689659],
              [37.699965837910398, 9.83635098783949],
              [37.701236954037498, 9.836651925031211],
              [37.702505824376303, 9.83706128768293],
              [37.704237327086403, 9.838510648670569],
              [37.705387170650098, 9.8390926040415],
              [37.706215866499697, 9.84029191567908],
              [37.708555977814797, 9.841581945291781],
              [37.710166207961599, 9.84291179901772],
              [37.711327280466399, 9.84292064993973],
              [37.712486107182897, 9.84299145730718],
              [37.714276000386498, 9.842661760373201],
              [37.715437072891199, 9.84244048707856],
              [37.716595899607697, 9.842502443616],
              [37.718417233846303, 9.84252235821485],
              [37.720186914956003, 9.842590952935041],
              [37.722556221517799, 9.84335213209958],
              [37.724285478439803, 9.84451159931543],
              [37.726127024772197, 9.845730805679301],
              [37.728556967615802, 9.846151220622881],
              [37.7297157943323, 9.84570204042676],
              [37.730306436631601, 9.845241796045091],
              [37.730885849989797, 9.8447904018937],
              [37.7319975151539, 9.843772550071989],
              [37.732576928512202, 9.843420726647411],
              [37.734497077432003, 9.8413717349781],
              [37.737466009446003, 9.840192342000369],
              [37.739837561796101, 9.83988255703024],
              [37.741117661075997, 9.839931237544789],
              [37.742907554279597, 9.840522041399449],
              [37.744636811201502, 9.84128101259085],
              [37.7458472910469, 9.841630625555871],
              [37.7479156619885, 9.843312303000729],
              [37.748497321134998, 9.844460706755889],
              [37.748486092194, 9.845671062459671],
              [37.748465880100099, 9.847392547937201],
              [37.748346853324897, 9.84859182942923],
              [37.747515911687103, 9.8504814633799],
              [37.747275612348602, 9.852430828516191],
              [37.747147602420597, 9.85358141320242],
              [37.747197009761202, 9.8547806722],
              [37.747767439966701, 9.855931248689449],
              [37.749377670113397, 9.85755089956041],
              [37.749957083471699, 9.857792076400299],
              [37.7517469766753, 9.858491266975779],
              [37.7529664396735, 9.857982362589841],
              [37.753545853031802, 9.857701358527089],
              [37.754127512178201, 9.85713049914251],
              [37.754715908689299, 9.85662159265636],
              [37.755005615368503, 9.856050731402529],
              [37.755416594610899, 9.855471018575351],
              [37.757687086491501, 9.853481843532419],
              [37.758327136131498, 9.8529707187533],
              [37.759497191789002, 9.8525215478391],
              [37.760826698409502, 9.852132118374209],
              [37.762167433971101, 9.85134219469548],
              [37.763447533250996, 9.85077132430505],
              [37.7652464096074, 9.850151773926539],
              [37.766407482112101, 9.85010088223661],
              [37.768136739034098, 9.85075141020384],
              [37.768716152392301, 9.850921786364269],
              [37.770447655102501, 9.85151035787797],
              [37.770966432179002, 9.852092290334831],
              [37.771186519423701, 9.85266094577447],
              [37.771417835609299, 9.85324066354052],
              [37.772096063648803, 9.854970960127201],
              [37.773546842832701, 9.85646228263843],
              [37.774166680378698, 9.856931361915381],
              [37.775907166241701, 9.857181389747931],
              [37.777126629239902, 9.85690038500257],
              [37.778867115102898, 9.85668133389326],
              [37.7800169586666, 9.85669239708408],
              [37.781175785383098, 9.85675213830814],
              [37.782907288093199, 9.85746239425381],
              [37.783486701451501, 9.85769029537011],
              [37.784757817578502, 9.858161585528419],
              [37.7864870745004, 9.85897140648061],
              [37.788227560363403, 9.85973033523948],
              [37.7894762186083, 9.860141881882489],
              [37.791167297130698, 9.860500325314231],
              [37.792447396410601, 9.8604007577334],
              [37.794237289614202, 9.860292339666749],
              [37.795578025175701, 9.860301190122501],
              [37.796727868739403, 9.860312253191861],
              [37.799216202076401, 9.86096054840816],
              [37.800366045640097, 9.86154246418875],
              [37.802097548350197, 9.862931977562351],
              [37.8039480778355, 9.86427059557413],
              [37.805216948174298, 9.86485029292654],
              [37.806366791738, 9.865261833175991],
              [37.807527864242701, 9.86556053142219],
              [37.808796734581499, 9.865801702401161],
              [37.809957807086199, 9.86592118153634],
              [37.812526988798801, 9.866270768386739],
              [37.8147570564917, 9.866702220000761],
              [37.816486313413598, 9.867290763342581],
              [37.817647385918299, 9.867640348739529],
              [37.818976892538799, 9.86799214632774],
              [37.8203063991593, 9.86823110296581],
              [37.821325987006801, 9.87031090342029],
              [37.822172649162098, 9.873100912973831],
              [37.823856990319797, 9.87553689722195],
              [37.824434157889797, 9.87582673610944],
              [37.825592984606303, 9.87595063662469],
              [37.826325111562902, 9.87865209876183],
              [37.827066221672297, 9.88038004939864],
              [37.827293046281497, 9.880955293435591],
              [37.827578261384303, 9.881588060714311],
              [37.827863476487003, 9.88216330263862],
              [37.830107018908997, 9.88418991617073],
              [37.831490424446599, 9.88505940919573],
              [37.833511633835897, 9.88582049026172],
              [37.834147191899397, 9.88605500907232],
              [37.834782749962898, 9.88640457453609],
              [37.835359917532898, 9.88675192719203],
              [37.835934839314803, 9.88727406170409],
              [37.837717995153803, 9.889066127237969],
              [37.8383513074291, 9.889528522808391],
              [37.8407498092377, 9.89407943396982],
              [37.841037270128602, 9.894656866416421],
              [37.84143702043, 9.8952320854689],
              [37.842180376327597, 9.896442254570919],
              [37.843040513212202, 9.89765463157627],
              [37.843500899795302, 9.898289579061339],
              [37.844078067365302, 9.898579397888559],
              [37.8452368940818, 9.89858824731437],
              [37.846977379944803, 9.897911265550819],
              [37.847617429584801, 9.89763029558552],
              [37.849036767733701, 9.89776082496939],
              [37.849939574594202, 9.896957736581831],
              [37.852493035789301, 9.896344910237691],
              [37.853710252999299, 9.8961236730094],
              [37.854871325504, 9.89584491388956],
              [37.856784737059201, 9.895455535675319],
              [37.858522977134001, 9.89564137533516],
              [37.859097898915799, 9.89616128334873],
              [37.859428029782698, 9.898574973175551],
              [37.859360656136403, 9.89977849291285],
              [37.859005821599197, 9.90092448738549],
              [37.859001330022799, 9.90155500387376],
              [37.858754293319599, 9.903448758394971],
              [37.858743064378601, 9.905169941692749],
              [37.858734081225698, 9.906373437246829],
              [37.858720606496497, 9.9082693762501],
              [37.859681803850499, 9.91143293735162],
              [37.860196089350602, 9.912583315639971],
              [37.861224660350899, 9.914313300005039],
              [37.862956163061099, 9.91541499884498],
              [37.864177871847502, 9.914631863905569],
              [37.8633581591507, 9.913660685492101],
              [37.863537822207597, 9.91245057988961],
              [37.864768514146803, 9.911311262488949],
              [37.865058220825901, 9.910740496166881],
              [37.8673983321411, 9.90794195699498],
              [37.867977745499303, 9.90777161036138],
              [37.869197208497503, 9.907211899370999],
              [37.871627151341102, 9.90740215696894],
              [37.873637131789302, 9.909771518508091],
              [37.874667948577802, 9.911550187631111],
              [37.875007062597597, 9.91213201386948],
              [37.875516856521301, 9.9138509393487],
              [37.875568509650101, 9.915061039782049],
              [37.875846987388201, 9.916271135748],
              [37.8760670746328, 9.917481227246],
              [37.8765274612159, 9.91880192529845],
              [37.8777267121202, 9.920702216840739],
              [37.878366761760198, 9.921160143422229],
              [37.878937191965598, 9.92151188369505],
              [37.879577241605503, 9.921520732502071],
              [37.880738314110197, 9.921580461943231],
              [37.881888157673899, 9.921761862401469],
              [37.883687034030402, 9.92223084848645],
              [37.890498509672199, 9.926071199840599],
              [37.891066694089403, 9.92648045121755],
              [37.891648353235901, 9.92688085342097],
              [37.893377610157799, 9.92781217377887],
              [37.895118096020802, 9.92800020725887],
              [37.8963285758661, 9.928230271605139],
              [37.897548038864301, 9.928590852899431],
              [37.898697882428003, 9.92894037303871],
              [37.899908362273301, 9.92958189637374],
              [37.901067188989899, 9.93016147844353],
              [37.902277668835197, 9.93080078724601],
              [37.903427512398899, 9.93144009479956],
              [37.9045885849036, 9.9319710066975],
              [37.906497504882402, 9.932041794885469],
              [37.907716967880603, 9.93193118833503],
              [37.908878040385297, 9.931590519924759],
              [37.910036867101802, 9.9307410594869],
              [37.910337802721997, 9.93012165986088],
              [37.9108588255868, 9.92903107124169],
              [37.911447222097898, 9.927880750707629],
              [37.911858201340301, 9.92731222541256],
              [37.9138479696947, 9.92422181967215],
              [37.914907981729897, 9.92291220443227],
              [37.917068429988198, 9.920520815795699],
              [37.919278285587197, 9.9184103621094],
              [37.919927318379997, 9.91721133519666],
              [37.921557760620601, 9.91550127630863],
              [37.922137173978903, 9.91515174182836],
              [37.923358882765299, 9.914760174278049],
              [37.9246367362569, 9.91431108775466],
              [37.925218395403398, 9.91395049074127],
              [37.928697121341202, 9.91417171594988],
              [37.929968237468202, 9.9145212514752],
              [37.931488636086598, 9.91503006834586],
              [37.934587823816798, 9.916731280756361],
              [37.934987574118203, 9.9173108855686],
              [37.935436731760298, 9.919040844980531],
              [37.935427748607403, 9.92017128666958],
              [37.935297492891202, 9.92196096034975],
              [37.9351268129872, 9.922531707130791],
              [37.934307100290503, 9.923730714570899],
              [37.934066800952003, 9.92431030699948],
              [37.933777094272898, 9.924881049686221],
              [37.9332470882552, 9.92540091134525],
              [37.932607038615302, 9.926020319903939],
              [37.932258941442697, 9.926650788126359],
              [37.931847962200202, 9.927221526733311],
              [37.931198929407401, 9.92894037303871],
              [37.930668923389803, 9.93071008953344],
              [37.930428624051302, 9.931860400123121],
              [37.930008661655997, 9.93363010082621],
              [37.929997432714899, 9.93478040114986],
              [37.929988449562103, 9.935930697428519],
              [37.931129309972903, 9.937810980711291],
              [37.931776096977501, 9.939773100091889],
              [37.932178093067101, 9.94040796596094],
              [37.932636233861999, 9.9409831047083],
              [37.932923694753001, 9.94155824244381],
              [37.933381835547799, 9.942135591229761],
              [37.9339926899411, 9.9427416957546],
              [37.936263181821701, 9.94422155937929],
              [37.938057566601699, 9.94463521213884],
              [37.9392186391064, 9.94429898105861],
              [37.939744153547601, 9.943730484300509],
              [37.940325812694098, 9.943447341577359],
              [37.941605911974001, 9.94265100135341],
              [37.942769230266897, 9.94185687125351],
              [37.943350889413402, 9.941516213182011],
              [37.944572598199798, 9.94083710804518],
              [37.945794306986201, 9.940213303386489],
              [37.94724508617, 9.939649223681011],
              [37.949160743513403, 9.93920238338038],
              [37.950380206511603, 9.93875111829858],
              [37.952181328656302, 9.93807421950819],
              [37.9533401553728, 9.93791273691],
              [37.954498982089298, 9.937976887540749],
              [37.955830734498001, 9.93810076458518],
              [37.956987315426296, 9.938510000916271],
              [37.958314576258601, 9.939264321676321],
              [37.960681637032202, 9.940315058837591],
              [37.963050943594098, 9.941250765088],
              [37.964205278734198, 9.94171529911107],
              [37.966435346426998, 9.943250468758089],
              [37.968910205034803, 9.944478156874681],
              [37.969628857262101, 9.94496701878675],
              [37.970783192402202, 9.9456638116507],
              [37.9721104532345, 9.94636060302877],
              [37.9738419559446, 9.947347168597171],
              [37.9756902396417, 9.948163405570931],
              [37.976846820570003, 9.94863235380412],
              [37.978059546203497, 9.948984064537211],
              [37.979272271837097, 9.94950831190812],
              [37.980487243258899, 9.949919745964319],
              [37.982281628038898, 9.95014094685558],
              [37.984657671965401, 9.94994850208866],
              [37.985818744470102, 9.9498401136068],
              [37.987559230333098, 9.94939328724057],
              [37.991790295321302, 9.94901945679127],
              [37.9929491220378, 9.949028304854179],
              [37.994107948754298, 9.94909245330315],
              [37.994743506817798, 9.949152177709831],
              [37.998204266449903, 9.95147257305522],
              [37.998606262539603, 9.952049904321481],
              [37.999237329026599, 9.95320235180429],
              [37.999495594670798, 9.954202168589999],
              [37.999747122950403, 9.954927697437689],
              [37.999973947559603, 9.9555603218852],
              [38.001289979450902, 9.957920486888399],
              [38.001516804060103, 9.95849780675122],
              [38.0034144950978, 9.960747353713741],
              [38.003931026386198, 9.96132688050715],
              [38.005601892814603, 9.96277126914142],
              [38.007333395524803, 9.96364497738529],
              [38.008548366946499, 9.964111691462829],
              [38.009758846791897, 9.96480844346374],
              [38.010915427720199, 9.96527515587627],
              [38.013803511358702, 9.966558058592209],
              [38.016233454202201, 9.966858876429541],
              [38.017450671412199, 9.96686772400879],
              [38.018609498128697, 9.96693408084551],
              [38.020403882908703, 9.967232686443589],
              [38.022775435258801, 9.967821049266179],
              [38.023932016187103, 9.96823024834757],
              [38.025726400967102, 9.96847355566598],
              [38.027260274314798, 9.968482403201399],
              [38.028102444893598, 9.96848903885282],
              [38.029840684968399, 9.968327571296721],
              [38.031639561324802, 9.967823261154489],
              [38.032800633829602, 9.967599860359689],
              [38.034020096827803, 9.96732116212385],
              [38.034657900679498, 9.96715305831084],
              [38.035816727396004, 9.967219415089501],
              [38.0381927713225, 9.967119879916449],
              [38.042468752074903, 9.968756676680609],
              [38.043045919645003, 9.969101730084731],
              [38.044195763208599, 9.970316049736921],
              [38.044541614593001, 9.970893347657031],
              [38.045103061645598, 9.97330648612866],
              [38.045675737639201, 9.974631382236209],
              [38.046068750575998, 9.97635661450026],
              [38.046915412731302, 9.979804839806629],
              [38.047485842936702, 9.981014695965809],
              [38.048404370314699, 9.98216704107042],
              [38.0495587054548, 9.983093780143349],
              [38.0512969455296, 9.982817306949061],
              [38.052460263822503, 9.98219358256029],
              [38.053100313462501, 9.98179545998515],
              [38.053681972608899, 9.981399548719271],
              [38.055422458471902, 9.98118058082912],
              [38.057277479533603, 9.981193851614529],
              [38.058494696743601, 9.98120048700703],
              [38.059651277671897, 9.981381854349779],
              [38.060805612811997, 9.98196134445063],
              [38.061382780381997, 9.98231080744795],
              [38.061843166965197, 9.982697870456491],
              [38.0631030541511, 9.98501802707036],
              [38.062689829120401, 9.986221228563011],
              [38.062512411851799, 9.986851580510271],
              [38.062153085738203, 9.988627618200891],
              [38.061912786399702, 9.989775512869841],
              [38.0617353691311, 9.99034835281762],
              [38.061557951862397, 9.99097869676805],
              [38.061784776471697, 9.99155374632387],
              [38.062397876653101, 9.995287120246569],
              [38.062741482249301, 9.99586437388071],
              [38.063370302948201, 9.99713167424059],
              [38.063772299037801, 9.997706712913841],
              [38.065384774972799, 9.999210655402949],
              [38.065903552049399, 9.99978569039769],
              [38.066480719619399, 10.000192637625201],
              [38.069020706085297, 10.001311739873],
              [38.070525384186197, 10.0017098385573],
              [38.072319768966203, 10.0018956177764],
              [38.073478595682701, 10.0021322649611],
              [38.074637422399199, 10.0021964029534],
              [38.076431807179297, 10.002379970240799],
              [38.077649024389203, 10.0023888168545],
              [38.080025068315699, 10.002116783374801],
              [38.081186140820499, 10.002010623906401],
              [38.082342721748802, 10.0023622770128],
              [38.083532989500199, 10.006387461552899],
              [38.081837419401403, 10.0086145614501],
              [38.080379902852997, 10.0122836033342],
              [38.0796500216846, 10.0137078615821],
              [38.079438917592803, 10.0154373096215],
              [38.079398493405101, 10.016702322042599],
              [38.079694937448799, 10.0172751145059],
              [38.081686951591401, 10.018630793023901],
              [38.082270856526002, 10.019081947424199],
              [38.085066862847903, 10.019968773606999],
              [38.085650767782496, 10.020249638251601],
              [38.087680960324597, 10.0203403110376],
              [38.0890711032268, 10.0203226187887],
              [38.090232175731501, 10.0203093496013],
              [38.093710901669297, 10.020207619147],
              [38.0944070960145, 10.020200984551],
              [38.098867231400099, 10.019915696796501],
              [38.101593618287403, 10.019999735075899],
              [38.104021315342798, 10.019508774293699],
              [38.105180142059297, 10.0193782936005],
              [38.106397359269302, 10.019307524388999],
              [38.107313640859097, 10.0193760820629],
              [38.108313016612698, 10.019398197438401],
              [38.110724993150498, 10.0176444434729],
              [38.111297669144101, 10.0171755946524],
              [38.112501411624898, 10.0161273170082],
              [38.1134760837081, 10.015366539550101],
              [38.114861735033898, 10.014831340385699],
              [38.1157712792591, 10.014729608213299],
              [38.117882320176697, 10.015253749717001],
              [38.119043392681498, 10.015357693290101],
              [38.118904153812402, 10.0179452140574],
              [38.117893549117802, 10.019811754684],
              [38.117543206157002, 10.020320407257501],
              [38.117051378538903, 10.022454527836],
              [38.116945826493001, 10.023204231109199],
              [38.1172040921372, 10.025154778403699],
              [38.117536468792402, 10.027114159899099],
              [38.118239400502198, 10.028938629758899],
              [38.119135469998099, 10.030884719618401],
              [38.120327983537798, 10.033284143861399],
              [38.1208669727082, 10.034657446880599],
              [38.1211656625402, 10.035230207610301],
              [38.121462106583998, 10.035858252960001],
              [38.12408967879, 10.0414044597039],
              [38.125684188419299, 10.043686603296299],
              [38.1260412687448, 10.044257136679001],
              [38.126811574100898, 10.045455695779101],
              [38.127341580118497, 10.0461390051602],
              [38.129674954069003, 10.0470876750142],
              [38.130955053348899, 10.047417165757601],
              [38.132120617429997, 10.047806362444801],
              [38.134903149022598, 10.047713486005099],
              [38.136118120444401, 10.0475830166758],
              [38.138027040423097, 10.047215933195799],
              [38.138662598486597, 10.047092097710999],
              [38.140609696864999, 10.0452279256646],
              [38.142154799153701, 10.0435981484182],
              [38.142718491994401, 10.0423841027722],
              [38.143057606014203, 10.041747224545899],
              [38.1438391403114, 10.039436319067001],
              [38.144113126473002, 10.0382819658437],
              [38.144445503128203, 10.037127608503599],
              [38.144670081949201, 10.0365504282899],
              [38.145009195968903, 10.035971035622699],
              [38.145642508244201, 10.0355597104281],
              [38.146275820519499, 10.035323087633101],
              [38.146855233877801, 10.035143961850901],
              [38.148070205299597, 10.0349559902445],
              [38.149287422509602, 10.034940510225001],
              [38.151200834064703, 10.0349736816944],
              [38.151784738999403, 10.0351970361671],
              [38.152368643934103, 10.035477887612],
              [38.153963153563403, 10.037642868131501],
              [38.154733458919502, 10.0387839552034],
              [38.1552050744437, 10.0393522858295],
              [38.155618299474398, 10.039922826854999],
              [38.155800208319398, 10.0404955782681],
              [38.156155042856597, 10.0410683286674],
              [38.156451486900401, 10.0416388666673],
              [38.158447992619401, 10.0434544091897],
              [38.1608397570633, 10.0445180779683],
              [38.162061465849703, 10.0446750849138],
              [38.163739069642801, 10.0449824644879],
              [38.166458719165497, 10.0456436612868],
              [38.168174501358202, 10.0464972444531],
              [38.169789223081402, 10.0500442343051],
              [38.169977869291003, 10.0512494059153],
              [38.169888037762597, 10.0531467128496],
              [38.169672442094402, 10.0543585067878],
              [38.169690408400101, 10.055738354206101],
              [38.169883546186199, 10.057000997882399],
              [38.170179990229997, 10.0575715077673],
              [38.171078305514101, 10.059632411050201],
              [38.171549921038299, 10.0602007050287],
              [38.174013550704899, 10.0621841979655],
              [38.175412676759997, 10.062913908020301],
              [38.176636631334603, 10.063300874804501],
              [38.177858340120999, 10.063515364764999],
              [38.179136193612599, 10.0637298545831],
              [38.180357902399003, 10.064001836312499],
              [38.182812548912899, 10.0652954048153],
              [38.183755779961203, 10.066491674663199],
              [38.184970751382998, 10.0674889313509],
              [38.185574868411599, 10.068021832219999],
              [38.187807181892602, 10.0701799611805],
              [38.1883371879102, 10.0707482365898],
              [38.190468440921798, 10.0726255283942],
              [38.1917418028371, 10.073753224932],
              [38.193482288699997, 10.073731113273],
              [38.195977359401702, 10.073872627863899],
              [38.197091270354001, 10.0747792042387],
              [38.198917096168898, 10.076711751222801],
              [38.199918717710702, 10.077907978735],
              [38.2004487237284, 10.078533729990699],
              [38.201095510732898, 10.0790997795502],
              [38.202858454477997, 10.0808620446654],
              [38.203860076019801, 10.0819985568305],
              [38.204446226742697, 10.0824496228044],
              [38.206487648225902, 10.0832898420527],
              [38.207129943654003, 10.0835684405828],
              [38.207772239082203, 10.0839620158737],
              [38.208358389804999, 10.084415290191499],
              [38.210745662672601, 10.0850764062239],
              [38.212138051362999, 10.085058717518301],
              [38.214172735481498, 10.085379325156801],
              [38.215336053774401, 10.085593800433299],
              [38.215978349202601, 10.0858723969711],
              [38.21785133657, 10.087172510961301],
              [38.218495877786303, 10.087738545344401],
              [38.221042601616801, 10.0914464881616],
              [38.222861690067198, 10.093034014129699],
              [38.224849212633302, 10.094216914878899],
              [38.226598681649101, 10.094884643849401],
              [38.227238731288999, 10.0949907728297],
              [38.228402049582002, 10.0952074527225],
              [38.229688886226498, 10.0959392990368],
              [38.2320896338233, 10.0976351419541],
              [38.233967112767097, 10.0992204264021],
              [38.234555509278202, 10.099788650585401],
              [38.237304354047602, 10.101477840284501],
              [38.2378860131941, 10.101701148729999],
              [38.239233486120199, 10.1026629209559],
              [38.2406932484569, 10.103335054321199],
              [38.2419711019486, 10.103317366619001],
              [38.243305100145498, 10.103359374909999],
              [38.244692997259499, 10.1031692320754],
              [38.246655816155297, 10.1024550899599],
              [38.247289128430602, 10.102158820010001],
              [38.2479808311993, 10.1018050644885],
              [38.2491351663394, 10.101329704893599],
              [38.250502851359499, 10.0995299649922],
              [38.251814391674301, 10.098198946941601],
              [38.250981204248298, 10.097250427395601],
              [38.250830736438203, 10.096379290430701],
              [38.251080018929599, 10.0944910819086],
              [38.2517290517223, 10.090420561923199],
              [38.252090623624198, 10.088711414616],
              [38.252710461170203, 10.0875506043509],
              [38.252881141074198, 10.086980147487401],
              [38.256759617313399, 10.0868695937148],
              [38.2581093360278, 10.0878314102654],
              [38.259389435307703, 10.0882205582145],
              [38.2613702205092, 10.088541162705599],
              [38.262760363411303, 10.088919254488401],
              [38.264049445844002, 10.089421165116701],
              [38.265329545123898, 10.0896400598153],
              [38.267299101384303, 10.0897904116433],
              [38.269349506020298, 10.090971113263601],
              [38.270050191941998, 10.0914199556276],
              [38.272439710597702, 10.092140755356001],
              [38.2751099527798, 10.0926205505995],
              [38.277829602302397, 10.092180554066999],
              [38.279280381486302, 10.091760456313899],
              [38.2809512479147, 10.0908804602964],
              [38.282269525594202, 10.0901707130124],
              [38.283419369157897, 10.0895803612759],
              [38.284690485284898, 10.088930309797],
              [38.285260915490298, 10.0885212631258],
              [38.286651058392501, 10.088331111523299],
              [38.287809885108999, 10.088320056194201],
              [38.291760226570901, 10.088439453729199],
              [38.292921299075601, 10.088541162705599],
              [38.294201398355497, 10.088930309797],
              [38.296770580068099, 10.0896113160755],
              [38.297349993426302, 10.0898103111446],
              [38.298511065931102, 10.090080059819799],
              [38.299869767798299, 10.0917891998619],
              [38.299991040361697, 10.092419346229599],
              [38.300240322853, 10.093739333043599],
              [38.300321171228603, 10.095289260399101],
              [38.300521046379302, 10.097131033128701],
              [38.3005906658138, 10.0978208660591],
              [38.300981432962402, 10.100920673982101],
              [38.3011610960192, 10.101659140222401],
              [38.301650677849103, 10.102919393063701],
              [38.302531026827502, 10.1041796409658],
              [38.3030093797163, 10.104750067339699],
              [38.3041300280332, 10.1061694960169],
              [38.306649802405197, 10.107980253409099],
              [38.3078715111916, 10.1080797452779],
              [38.3092594083056, 10.1080001517853],
              [38.3105305244326, 10.107699465080101],
              [38.311860031053101, 10.107449629589],
              [38.313250173955304, 10.107259489173501],
              [38.314521290082297, 10.107190950159],
              [38.315680116798802, 10.107060504897399],
              [38.317009623419302, 10.1067509735564],
              [38.318231332205698, 10.106680223493701],
              [38.319560838826199, 10.1067200204059],
              [38.320780301824399, 10.1068195126647],
              [38.321431580405402, 10.107040606463],
              [38.323291093043501, 10.107529223217799],
              [38.323870506401803, 10.107759160257199],
              [38.325673874334598, 10.1105802974637],
              [38.327189781376603, 10.112950387319099],
              [38.327030330413699, 10.1136998803787],
              [38.326711428487798, 10.1155393367579],
              [38.326381297620898, 10.1169299764742],
              [38.3259815473195, 10.117509223673199],
              [38.325310056644597, 10.1193000194727],
              [38.324550980229503, 10.1202705824048],
              [38.323940125836302, 10.121221244846501],
              [38.323720038591702, 10.1219110260647],
              [38.323511180288101, 10.1230606581339],
              [38.323230456761799, 10.1242699781927],
              [38.323131642080597, 10.1254792936938],
              [38.3231608373273, 10.127320893369401],
              [38.3241197888931, 10.129960574825301],
              [38.324959713683803, 10.131439582827101],
              [38.325610992264799, 10.132810256797599],
              [38.326980923073002, 10.1348109881214],
              [38.329781420971301, 10.139740913659001],
              [38.330239561766199, 10.140519083314601],
              [38.330780796724902, 10.141089445100301],
              [38.3309604597817, 10.1416598058705],
              [38.3313108027425, 10.1438196512094],
              [38.3314208463648, 10.145570507978899],
              [38.331441058458701, 10.146830588365599],
              [38.3319912765702, 10.1494104216846],
              [38.3322899664022, 10.1500404549402],
              [38.332831201360897, 10.151240830657599],
              [38.3339496038896, 10.1525495224416],
              [38.335420595167299, 10.1535111422158],
              [38.337459770862303, 10.154629712385599],
              [38.338310924593998, 10.155080676328],
              [38.341331509736797, 10.156999476582699],
              [38.341971559376802, 10.1575101230982],
              [38.343200005527798, 10.1581799309276],
              [38.345531133690102, 10.158670680327999],
              [38.346310422199103, 10.158829842133899],
              [38.347619716725703, 10.1589293182224],
              [38.349391643623598, 10.158969108649099],
              [38.350051905357397, 10.1589602663325],
              [38.350631318715699, 10.158610994630299],
              [38.352351592484801, 10.1568005231742],
              [38.352861386408499, 10.1561594502381],
              [38.353490207107399, 10.155580272894399],
              [38.354631067518199, 10.1545302349598],
              [38.355259888217098, 10.153940001596901],
              [38.355720274800198, 10.1533608202348],
              [38.3565197754031, 10.152140556834],
              [38.35684990627, 10.1515105277131],
              [38.357240673418602, 10.150179725281999],
              [38.357350717040902, 10.1494900049012],
              [38.357321521794198, 10.1475291571097],
              [38.3572519023597, 10.146379612797],
              [38.357290080759199, 10.145230064356801],
              [38.357721272095603, 10.143209501904099],
              [38.358121022397, 10.142630301125701],
              [38.3586914526025, 10.142110788086001],
              [38.360009730281902, 10.141350307904199],
              [38.363445786243702, 10.141160187576601],
              [38.3642542699994, 10.1409214316565],
              [38.365527631914603, 10.140673832736701],
              [38.3668548927469, 10.140256009125499],
              [38.368069864168703, 10.139950931223799],
              [38.369520643352502, 10.1398757670585],
              [38.372071858759398, 10.139840395680499],
              [38.373347466462903, 10.1398249207014],
              [38.374566929461103, 10.1398094457216],
              [38.377888450224098, 10.1408595319445],
              [38.386788508901503, 10.1425838765905],
              [38.387374659624399, 10.1430348575048],
              [38.389297054332403, 10.1435853186427],
              [38.390743341939803, 10.143335511095],
              [38.391902168656301, 10.1431498133226],
              [38.393123877442797, 10.1434769950397],
              [38.394810464388698, 10.143687010154901],
              [38.396032173175101, 10.143958924257401],
              [38.397482952358899, 10.143939028111401],
              [38.398123001998897, 10.144160096330999],
              [38.3988843242022, 10.144611075025701],
              [38.399531111206699, 10.1452366963789],
              [38.400775277875198, 10.1471157637649],
              [38.400972907237701, 10.1487251209458],
              [38.400993119331602, 10.1500470868626],
              [38.401484946949701, 10.152054342395999],
              [38.401675838947597, 10.153259131795799],
              [38.401691559465, 10.154410862008],
              [38.402475339550399, 10.1565286199823],
              [38.402834665664102, 10.157213904006699],
              [38.404310148518199, 10.1589757403865],
              [38.405311770060003, 10.160114186585499],
              [38.407121875357497, 10.1608370425534],
              [38.408406466213798, 10.1615112645365],
              [38.410465854002702, 10.163438868394101],
              [38.411525866037898, 10.1645750881578],
              [38.4135695333093, 10.165527829631801],
              [38.416248758644201, 10.166182146956899],
              [38.417474959007002, 10.166741410365301],
              [38.418820186144998, 10.1674708829051],
              [38.420279948481699, 10.1682556166563],
              [38.422388743611201, 10.1695509757579],
              [38.423673334467402, 10.170225179355],
              [38.424964662688403, 10.171186745957399],
              [38.426253745121102, 10.1720908369726],
              [38.426898286337398, 10.172541776277701],
              [38.4276012180473, 10.172992714946],
              [38.428131224064899, 10.173618280769601],
              [38.427607955411901, 10.177537433810199],
              [38.426449128695403, 10.177667850371099],
              [38.4252925477671, 10.177968471393401],
              [38.424133721050602, 10.178098887778299],
              [38.422923241205197, 10.1785763439189],
              [38.421654370866399, 10.1792240031901],
              [38.420288931634502, 10.1809680347599],
              [38.4200149454729, 10.182179346387001],
              [38.420255244811401, 10.1827518442598],
              [38.421789118158998, 10.184515750164501],
              [38.422611076644003, 10.185136872603101],
              [38.424358299871599, 10.1857469418634],
              [38.425523863952698, 10.1859613499857],
              [38.4261616678045, 10.186067448797001],
              [38.428095291453502, 10.187364945988],
              [38.4286904253293, 10.188277832670201],
              [38.4305184969324, 10.190497042661899],
              [38.431010324550499, 10.192331636865299],
              [38.429815565222597, 10.193956238577501],
              [38.428737586881702, 10.195697979239499],
              [38.427374393438001, 10.197786729277899],
              [38.426873582667099, 10.199344994194799],
              [38.426368280319799, 10.200558446275],
              [38.425874206913498, 10.2024062442032],
              [38.425038773699299, 10.204833120404],
              [38.423954057993697, 10.206112859145801],
              [38.4232758299542, 10.207388172254401],
              [38.423051251133202, 10.2079650453091],
              [38.422480820927802, 10.2086634802516],
              [38.421589243008299, 10.209876896806501],
              [38.421115381695898, 10.2105222822325],
              [38.420776267676203, 10.2111013598222],
              [38.419981258649699, 10.2124937939858],
              [38.419406336867901, 10.2128452168827],
              [38.418499038430902, 10.2141801783101],
              [38.418159924411199, 10.214931644198],
              [38.4178297935443, 10.216085361898701],
              [38.4186854388524, 10.219181813419601],
              [38.420037403355003, 10.2204880162567],
              [38.422676204502103, 10.2223489592484],
              [38.424596353421897, 10.222784356451101],
              [38.425180258356598, 10.222950116650599],
              [38.427131848311298, 10.225513863389301],
              [38.427208205110503, 10.2267758380203],
              [38.427228417204297, 10.2281571530152],
              [38.428297412392503, 10.2299848997806],
              [38.429961541456301, 10.230603723387],
              [38.430747567329902, 10.230813681122299],
              [38.431877198799697, 10.232639202525601],
              [38.432591359450498, 10.2340094417172],
              [38.434868588695799, 10.2349575553721],
              [38.436034152776898, 10.2352316016818],
              [38.437431033043701, 10.2354990176106],
              [38.438832404887002, 10.236113410378399],
              [38.440058605249803, 10.236672550964],
              [38.440642510184503, 10.237010686887499],
              [38.441289297189002, 10.237576455992601],
              [38.441875447911897, 10.2379720517597],
              [38.442463844423003, 10.2385378191503],
              [38.443169021921101, 10.239163256146099],
              [38.445147561334302, 10.239481499232401],
              [38.446310879627298, 10.239695871131399],
              [38.448291664828702, 10.2401312445421],
              [38.448931714468699, 10.240409706410199],
              [38.449517865191602, 10.2407478383499],
              [38.450101770126203, 10.2409688394229],
              [38.452224039984998, 10.2433578511904],
              [38.453059473199197, 10.2448407532332],
              [38.453888169048803, 10.2460385617414],
              [38.454779746968299, 10.2475789103101],
              [38.455253608280699, 10.248206539430999],
              [38.455801580604003, 10.250038590564801],
              [38.456118236741602, 10.2517601343888],
              [38.4560194220604, 10.2529689663741],
              [38.455581493359396, 10.2547590016079],
              [38.455192971998997, 10.2560296994917],
              [38.4545214813241, 10.257764470109199],
              [38.454422666642898, 10.2590307361637],
              [38.454440632948497, 10.2602373305187],
              [38.454348555631903, 10.261965448488199],
              [38.455294032468501, 10.2631035261407],
              [38.4572186729647, 10.2638239382285],
              [38.4578025778994, 10.2640471330518],
              [38.4603178606949, 10.2653929282035],
              [38.462067329710699, 10.2663917741058],
              [38.463077934405298, 10.267600550216599],
              [38.463848239761496, 10.2687408174141],
              [38.4645062557071, 10.270055655299901],
              [38.464989100172303, 10.2713130329708],
              [38.4631183585931, 10.274501755679699],
              [38.462543436811302, 10.2748553196262],
              [38.4606996446906, 10.275798891472199],
              [38.460124722908802, 10.276267361693501],
              [38.459554292703402, 10.276850738734],
              [38.4590983976967, 10.2774319049421],
              [38.458069826696402, 10.278479327272001],
              [38.457330962375202, 10.2796416526283],
              [38.457521854373098, 10.2809034116248],
              [38.4583527960109, 10.2821585364143],
              [38.458669452148499, 10.28399481035],
              [38.457755416346899, 10.2849847586556],
              [38.457423039691797, 10.286140430987601],
              [38.457683551124198, 10.2879766817662],
              [38.458512246973797, 10.28911687543],
              [38.460219046013599, 10.290760868345201],
              [38.461555289998799, 10.2909729958395],
              [38.462253730132197, 10.290964157196701],
              [38.4641693874756, 10.2909950924453],
              [38.464807191327303, 10.2909884634637],
              [38.466662212388997, 10.2907918036137],
              [38.467879429599002, 10.290716675099301],
              [38.469846740071198, 10.2903476612543],
              [38.471120101986401, 10.290097969006601],
              [38.471942060471399, 10.290049356333601],
              [38.4731031329761, 10.290705626786799],
              [38.473767886286403, 10.2925374317057],
              [38.474017168777699, 10.2937416884941],
              [38.4737454284043, 10.295067470467799],
              [38.471337943442798, 10.2971688234804],
              [38.469500888686802, 10.298576346532499],
              [38.4684678261101, 10.299451349847001],
              [38.4679557863981, 10.300089923965499],
              [38.467277558358603, 10.301307409444901],
              [38.466433141991502, 10.303161248539601],
              [38.466037883266502, 10.303855054407499],
              [38.4657638971049, 10.305008448043401],
              [38.465957034890899, 10.306444660271101],
              [38.466147926888802, 10.307593625339701],
              [38.466513990367098, 10.308738167145799],
              [38.466646491871501, 10.309944571574601],
              [38.467068700055101, 10.311089104838301],
              [38.467542561367402, 10.311656950879],
              [38.469525592357101, 10.312379461676199],
              [38.470109497291801, 10.3126600693983],
              [38.472036383576203, 10.3134400249814],
              [38.473082920882199, 10.3134245584588],
              [38.473496145912897, 10.313994609787301],
              [38.474086788212198, 10.314619897460499],
              [38.474567386889198, 10.315762204243301],
              [38.474922221426503, 10.316334460821601],
              [38.475256843869801, 10.3193216674591],
              [38.475216419681999, 10.320528031332399],
              [38.475321971727901, 10.3238642882884],
              [38.475348921186402, 10.3258196277392],
              [38.475387099586001, 10.328464287856701],
              [38.4753466753982, 10.3296153819284],
              [38.474852601991898, 10.331634757505901],
              [38.474580861618499, 10.3329029364111],
              [38.476038378166997, 10.337370243629699],
              [38.477212925400998, 10.3383335122313],
              [38.477857466617301, 10.3386096781433],
              [38.478762519266098, 10.3395552683867],
              [38.479910117041499, 10.3400788767126],
              [38.481037502723098, 10.341791091936001],
              [38.481172250015703, 10.343169694568999],
              [38.481134071616196, 10.344435617616501],
              [38.4812665731206, 10.3455844438864],
              [38.481282293638003, 10.346790706944001],
              [38.4807410586793, 10.3495014679959],
              [38.4802874094609, 10.350139940500201],
              [38.478917478652598, 10.351825590037199],
              [38.478122469626101, 10.3529876446004],
              [38.477325214811501, 10.354149694857901],
              [38.476530205784996, 10.3553095316006],
              [38.475957529791401, 10.3559502014858],
              [38.475452227444102, 10.3571652614652],
              [38.475065951871898, 10.3584929852582],
              [38.474980611919896, 10.360565195180801],
              [38.474881797238702, 10.3617736097811],
              [38.4755353216079, 10.362915745074],
              [38.477001821309202, 10.3639297456458],
              [38.477587972032097, 10.364210307315901],
              [38.478174122755, 10.364778057328399],
              [38.478762519266098, 10.365173493499601],
              [38.479348669989001, 10.365624157577701],
              [38.4810981390048, 10.3662338785338],
              [38.481740434432901, 10.3663995633711],
              [38.482378238284703, 10.3663907268486],
              [38.483647108623501, 10.3656838042454],
              [38.484217538828901, 10.365100591896701],
              [38.484909241597698, 10.3646874824927],
              [38.485542553873003, 10.364278790835],
              [38.486117475654801, 10.363925327349801],
              [38.486750787930099, 10.363514425547001],
              [38.487902877282004, 10.362979810566699],
              [38.488480044851997, 10.3627434309251],
              [38.489814043049002, 10.3626108815153],
              [38.491150287034102, 10.3627655224879],
              [38.491853218743898, 10.3631013140514],
              [38.492383224761497, 10.3636668569238],
              [38.492913230779202, 10.364236817067001],
              [38.494712107135598, 10.364212516461899],
              [38.495347665199098, 10.363859052901899],
              [38.496674926031403, 10.3632670005455],
              [38.497946042158397, 10.362904699297999],
              [38.500616284340502, 10.362867143656899],
              [38.5042252659944, 10.3635100072451],
              [38.505824267200197, 10.363761850353001],
              [38.512267433575502, 10.3657036864654],
              [38.513556516008201, 10.3665497842145],
              [38.515016278344902, 10.366989400680501],
              [38.516936427264703, 10.367424598282801],
              [38.519846968785302, 10.3678465405584],
              [38.521066431783503, 10.3678863047226],
              [38.521762626128698, 10.367877468242099],
              [38.522400429980401, 10.3678111946305],
              [38.5251874531494, 10.3677162024293],
              [38.526463060852798, 10.3676985294584],
              [38.527103110492803, 10.3678045672685],
              [38.531556508513802, 10.3665365294371],
              [38.532470544315402, 10.3656042753527],
              [38.5337461520188, 10.365471727153301],
              [38.534327811165298, 10.3656374123938],
              [38.534976843958098, 10.3662603881136],
              [38.535392314776999, 10.3668325526655],
              [38.535980711288097, 10.367512963204399],
              [38.536512963093898, 10.368080707230501],
              [38.539201171581603, 10.369311183941001],
              [38.541363865628199, 10.3702588907144],
              [38.542944900528198, 10.371157994488801],
              [38.5448785241773, 10.372339859158499],
              [38.547964237178199, 10.372989330553899],
              [38.549300481163399, 10.373026884981201],
              [38.551389064198901, 10.3730003759742],
              [38.553311458906897, 10.3734930013206],
              [38.554539905058, 10.374107125106001],
              [38.556354501931899, 10.3751166713002],
              [38.557704220646301, 10.3759627436109],
              [38.559105592489502, 10.376634298072499],
              [38.559691743212397, 10.3769126393018],
              [38.561456932745699, 10.376126214237599],
              [38.562860550377103, 10.3752028249821],
              [38.563886875589198, 10.3740960797249],
              [38.564955870777297, 10.373930398960299],
              [38.566226986904397, 10.374217578896401],
              [38.567015258566201, 10.374537894668199],
              [38.567608146653697, 10.374723456758399],
              [38.570604028126198, 10.3749730817776],
              [38.571340646659202, 10.3750238903854],
              [38.572115443591798, 10.3750791171237],
              [38.572786934266702, 10.3753729232076],
              [38.574823864173403, 10.376797768348199],
              [38.575924300396402, 10.377367704587799],
              [38.576809140951298, 10.377608491291801],
              [38.578482253167998, 10.377696853338501],
              [38.579290736923703, 10.3773478231086],
              [38.580121678561497, 10.3769767019306],
              [38.580746007683999, 10.376806604576901],
              [38.581554491439697, 10.376479663949199],
              [38.582794166531698, 10.376119587051701],
              [38.583427478807003, 10.376110750803599],
              [38.584191046798601, 10.376062151434599],
              [38.5851365236351, 10.374233042424001],
              [38.5864143771268, 10.374018762045701],
              [38.587110571471896, 10.374067361731999],
              [38.588963346745402, 10.3736388006017],
              [38.590122173461999, 10.3735659009696],
              [38.591359602765799, 10.3747565928343],
              [38.593106825993402, 10.375021681315699],
              [38.5938569192557, 10.374838328473301],
              [38.5951280353827, 10.374418604694901],
              [38.597214372630098, 10.3741601429303],
              [38.5994197366526, 10.3740717798849],
              [38.6006391996508, 10.3740563163494],
              [38.601973197847698, 10.3740364346597],
              [38.603192660845899, 10.3740209711225],
              [38.604355979138802, 10.3742352514993],
              [38.606458036903703, 10.375010635966801],
              [38.607223850683397, 10.375633593036],
              [38.609916550747499, 10.377149008246599],
              [38.611138259533902, 10.377363286481399],
              [38.612299332038702, 10.3773456140552],
              [38.613633330235601, 10.377270506232801],
              [38.615021227349501, 10.376906012132499],
              [38.616411370251697, 10.3766585977134],
              [38.6177431226604, 10.376408974038799],
              [38.6192478007613, 10.376044478934899],
              [38.620756970438599, 10.376079823933299],
              [38.621924780307999, 10.376466409592201],
              [38.622513176819098, 10.3769767019306],
              [38.622870257144498, 10.377546637844199],
              [38.623570943066099, 10.3782645788935],
              [38.625513549868103, 10.379581168044099],
              [38.626158091084399, 10.3799743764809],
              [38.6286621449389, 10.380630459120599],
              [38.629670503845297, 10.3819404112503],
              [38.630728270092398, 10.3829035428742],
              [38.631952224667003, 10.383175251488501],
              [38.633322155475298, 10.381602430871901],
              [38.633950976174098, 10.380959604438299],
              [38.634525897956003, 10.380436064407901],
              [38.635100819737801, 10.3801400540451],
              [38.636841305600797, 10.380058319791001],
              [38.638637936168998, 10.3798617156872],
              [38.6390938311757, 10.3792785297533],
              [38.642370436174602, 10.377104827148999],
              [38.644113167825701, 10.377082736597799],
              [38.645269748753996, 10.376835322318501],
              [38.645909798394001, 10.3768264860906],
              [38.648467751165498, 10.3771379629727],
              [38.649101063440803, 10.376839740432301],
              [38.6496759852227, 10.3764288555777],
              [38.650895448220801, 10.3763559565955],
              [38.652510169944101, 10.375644638362999],
              [38.6536892087545, 10.3730379304001],
              [38.655553212968996, 10.3734156834924],
              [38.656193262608902, 10.373521719366099],
              [38.6581179031052, 10.374014343892],
              [38.658755706956903, 10.3740032985075],
              [38.660601744865801, 10.3732301206268],
              [38.661812224711099, 10.372695522232499],
              [38.662389392281199, 10.372459149924],
              [38.664010851369, 10.3721476683852],
              [38.6652303143672, 10.3721322047546],
              [38.6657041756796, 10.372697731318601],
              [38.666290326402397, 10.3731505936508],
              [38.667026944935401, 10.373431147059501],
              [38.6689134070321, 10.37386412663],
              [38.670191260523701, 10.373903890029901],
              [38.671471359803597, 10.3739988803536],
              [38.6721091636553, 10.373992253122699],
              [38.673919268952801, 10.3746571845959],
              [38.6753969975952, 10.376420019338299],
              [38.676055013540797, 10.3776173274976],
              [38.676526629065002, 10.3781872622442],
              [38.678462498502299, 10.3794839703768],
              [38.679913277686097, 10.379461879993601],
              [38.681132740684298, 10.379444207685999],
              [38.683104542732998, 10.3793028291889],
              [38.683679464514803, 10.3788919475691],
              [38.684153325827197, 10.378494319681201],
              [38.685525502423701, 10.378118781767499],
              [38.688083455195198, 10.378370613124201],
              [38.689424190756696, 10.3786953427322],
              [38.690879461517, 10.3788499757603],
              [38.6921573150087, 10.378887529484199],
              [38.694987008153603, 10.3779862388668],
              [38.696206471151797, 10.377968566475801],
              [38.697749327652303, 10.3801908618138],
              [38.698391623080497, 10.3804117650603],
              [38.699550449797002, 10.3803388670062],
              [38.701302164601003, 10.380058319791001],
              [38.702218446190798, 10.3801290088768],
              [38.702759681149502, 10.381385946516],
              [38.702943835782698, 10.381958083416899],
              [38.703415451306903, 10.382528010254401],
              [38.705870097820799, 10.3835286932351],
              [38.7064540027554, 10.383751803132],
              [38.708385380616299, 10.3847591091992],
              [38.709090558114298, 10.385267179010601],
              [38.710902909200001, 10.3861618216715],
              [38.711664231403297, 10.386552812845199],
              [38.713593363476001, 10.387332584742699],
              [38.714289557821203, 10.387381182360301],
              [38.716193986223502, 10.3866632622408],
              [38.716768908005299, 10.386252390292],
              [38.717402220280597, 10.3858415178032],
              [38.717916505780799, 10.385258343021],
              [38.719192113484198, 10.385183237098699],
              [38.719549193809698, 10.3857531580577],
              [38.720137590320803, 10.3863208689876],
              [38.722522617400102, 10.3865174690304],
              [38.7239150060905, 10.386440154421599],
              [38.726407831003897, 10.386289943126901],
              [38.726984998573997, 10.385993938305599],
              [38.727620556637497, 10.385755367051599],
              [38.728831036482902, 10.3852760150001],
              [38.729466594546402, 10.384980009218699],
              [38.7308005927433, 10.3849623372229],
              [38.731449625536101, 10.385700142198299],
              [38.733839144191798, 10.3862413453397],
              [38.735121489259903, 10.386568275763],
              [38.736855237758199, 10.3859696393899],
              [38.737490795821799, 10.3857862929652],
              [38.7386451309618, 10.3853113589552],
              [38.739332342154199, 10.3846685401461],
              [38.739965654429497, 10.3843725337896],
              [38.740664094562902, 10.3845360000212],
              [38.741254736862203, 10.3850440701951],
              [38.741901523866801, 10.385609573417799],
              [38.742546065083097, 10.3860624170579],
              [38.744926600586098, 10.385971848382299],
              [38.745139950465997, 10.3848165432193],
              [38.745643007025102, 10.383488931058601],
              [38.747078065691497, 10.382433022520299],
              [38.747709132178599, 10.3819625014584],
              [38.749730341567897, 10.3812423598708],
              [38.7511137471054, 10.3806481313612],
              [38.752438762149502, 10.3799390319225],
              [38.754165773283198, 10.378995772545499],
              [38.7546216682899, 10.378414794043],
              [38.755371761552198, 10.3780017022082],
              [38.7575075061402, 10.3771644716292],
              [38.758475440858803, 10.3768110226912],
              [38.759470325035998, 10.3764465280557],
              [38.760793094291799, 10.375737419093699],
              [38.762753667399402, 10.3748471647574],
              [38.763966393033002, 10.3743700050632],
              [38.7664412516407, 10.3728965490085],
              [38.7670700723396, 10.3723111410045],
              [38.767456347911804, 10.370985684874499],
              [38.767728088285203, 10.3697154508175],
              [38.768181737503703, 10.369017372167701],
              [38.7685747504405, 10.368264064030701],
              [38.769836883414698, 10.367155085118201],
              [38.771274187869302, 10.366271433771299],
              [38.773219040459402, 10.365686013381],
              [38.774164517295901, 10.3654827728387],
              [38.776021784145897, 10.365456263193099],
              [38.777474809117898, 10.3655490469431],
              [38.778757154186003, 10.3658185614897],
              [38.781721594623598, 10.366065984475901],
              [38.783181356960299, 10.366677913700499],
              [38.784578237227102, 10.3668877808487],
              [38.786033507987398, 10.367038001458001],
              [38.787522465570802, 10.366878944340099],
              [38.789332570868297, 10.366357589887301],
              [38.790666569065202, 10.366339916839699],
              [38.792009550415003, 10.366836970920501],
              [38.794291271236602, 10.3678995594429],
              [38.796092393381301, 10.3680453613289],
              [38.797480290495301, 10.367680856486601],
              [38.798641363, 10.3676079554672],
              [38.799395947838597, 10.3676521379052],
              [38.800035997478602, 10.367760384852],
              [38.801848348564299, 10.368537994472],
              [38.802432253498999, 10.3688185520143],
              [38.803018404221902, 10.3690969001937],
              [38.804763381661303, 10.3693597843581],
              [38.805773986355902, 10.369194101086901],
              [38.807307859703499, 10.368632986423901],
              [38.807938926190602, 10.368105007535499],
              [38.811368244787801, 10.364720619630701],
              [38.812578724633099, 10.364071131085799],
              [38.813971113323497, 10.364051248762101],
              [38.8153679935903, 10.3642611176702],
              [38.817169115734899, 10.364351692629199],
              [38.818678285412297, 10.3643296011782],
              [38.821577597991798, 10.364115314022699],
              [38.824180466527501, 10.363386294767],
              [38.826729436146202, 10.363118987281601],
              [38.828645093489598, 10.362977601412],
              [38.829920701192997, 10.362959928173799],
              [38.831834112748197, 10.3627588950192],
              [38.833105228875198, 10.362451822149501],
              [38.833799177432198, 10.3622132329495],
              [38.8350658019828, 10.361561530042099],
              [38.835699114258098, 10.3611506251394],
              [38.836274036040002, 10.360739719698],
              [38.838129057101703, 10.360655771208201],
              [38.839878526117502, 10.3610909776088],
              [38.841158625397398, 10.361300848499701],
              [38.842613896157602, 10.361512928415101],
              [38.843833359155802, 10.361550484218499],
              [38.845052822154003, 10.361532810899799],
              [38.845726558617102, 10.361300848499701],
              [38.844565486112401, 10.356915622253799],
              [38.844897862767503, 10.354823505297301],
              [38.845668168123602, 10.352510451612501],
              [38.848410275528401, 10.3450011941518],
              [38.8512422144616, 10.340118644397901],
              [38.852432482213104, 10.3380065314775],
              [38.8530253703006, 10.3352802057264],
              [38.853395925355301, 10.3322489632593],
              [38.854548014707198, 10.325388791277399],
              [38.855491245755502, 10.316005247704799],
              [38.8551588691004, 10.3124987752264],
              [38.8553991684389, 10.308046581571],
              [38.855585568860299, 10.3001407446875],
              [38.855657434083, 10.296377779509999],
              [38.855652942506602, 10.293074376126],
              [38.855700104058997, 10.2881424078452],
              [38.857341775240798, 10.2878927138529],
              [38.858947513811103, 10.287782229722501],
              [38.860005280058203, 10.287771181307299],
              [38.860833975907802, 10.285285278062901],
              [38.860950756894702, 10.2829827617759],
              [38.860984443717904, 10.280063712678499],
              [38.860928299012599, 10.277520295146299],
              [38.860551006593298, 10.276322605777599],
              [38.855994302314599, 10.2703429300766],
              [38.8552689127227, 10.269154052767201],
              [38.853231982815899, 10.266561931168599],
              [38.8521629876278, 10.2654371249916],
              [38.851260180767298, 10.2641951928972],
              [38.850130549297504, 10.2630173417627],
              [38.849003163615897, 10.262009645755001],
              [38.847166108859902, 10.2603323551534],
              [38.845935416920703, 10.2597887254623],
              [38.844772098627701, 10.2572584042485],
              [38.844675529734701, 10.256537977217601],
              [38.8445767150534, 10.2558109188282],
              [38.844208405786901, 10.2546109373489],
              [38.843550389841297, 10.2528186913509],
              [38.843795180756302, 10.2520739449636],
              [38.8443117120446, 10.2505048889813],
              [38.844996677448798, 10.249280577930399],
              [38.845479521914001, 10.24852477344],
              [38.8460230026609, 10.2476739387428],
              [38.8467169512179, 10.246588844944601],
              [38.847666919630797, 10.245105951078999],
              [38.848408029740199, 10.242173292563701],
              [38.845775965957699, 10.239125685760699],
              [38.844834980697598, 10.238336706638799],
              [38.844172473175597, 10.2377797790183],
              [38.843682891345701, 10.237355452556001],
              [38.842822754461203, 10.236610669840999],
              [38.842209654279799, 10.2360780496758],
              [38.8415044767818, 10.235350944355501],
              [38.841014894951897, 10.234809481864399],
              [38.840325437971302, 10.234051432824799],
              [38.839865051388202, 10.2335453291051],
              [38.839454072145699, 10.2330922661387],
              [38.838382831169397, 10.2314369232659],
              [38.835658690070296, 10.227237750423299],
              [38.834841223161803, 10.224205471087201],
              [38.832689758056297, 10.2145713828321],
              [38.832606663892498, 10.211795367453],
              [38.832685266479899, 10.209936572911101],
              [38.833008659982198, 10.2057128032008],
              [38.8334578176243, 10.202985336562101],
              [38.8344886344128, 10.201992921913501],
              [38.834924317325601, 10.201539813974099],
              [38.837071290854603, 10.2000898642347],
              [38.838421009568997, 10.198666431544],
              [38.838412026416201, 10.1972098377792],
              [38.838403043263298, 10.1962417186634],
              [38.838376093804797, 10.1945066119731],
              [38.838263804394302, 10.193441229679699],
              [38.8379246903745, 10.1916199039823],
              [38.837563118472701, 10.1903754727935],
              [38.834847960526403, 10.190112439646599],
              [38.834944529419502, 10.1884369797177],
              [38.834091129899598, 10.181151500137601],
              [38.834331429238098, 10.179462730508],
              [38.834706475869197, 10.1770842911077],
              [38.834944529419502, 10.1734790206393],
              [38.834843468949998, 10.169820657367501],
              [38.8342550724389, 10.165054775055401],
              [38.833549894940901, 10.162813282636501],
              [38.833403918707198, 10.1606469338028],
              [38.8336779048689, 10.158843105614199],
              [38.834755883209802, 10.157076847319299],
              [38.835696868469903, 10.1556222743401],
              [38.836976967749798, 10.154185379644099],
              [38.8383266864642, 10.1494900049012],
              [38.840040222868602, 10.148389101983],
              [38.843323565232097, 10.145431235631801],
              [38.844340907291397, 10.144370110998199],
              [38.845234730999103, 10.1430834926636],
              [38.846173470471001, 10.141277354768601],
              [38.846952758979903, 10.1398801884805],
              [38.847709589606801, 10.137903806774901],
              [38.847947643157099, 10.1358279296427],
              [38.847377212951699, 10.133944374539601],
              [38.847168354648097, 10.1325692838951],
              [38.847738784853497, 10.132211140347501],
              [38.848257561930097, 10.1299738395014],
              [38.846782079076, 10.1292686001294],
              [38.847033607355499, 10.128264902549599],
              [38.848320444, 10.124866898662299],
              [38.848814517406304, 10.1228882135861],
              [38.849375964458901, 10.1206110525331],
              [38.849768977395598, 10.118879957157199],
              [38.849948640452503, 10.1180928915495],
              [38.848924561028603, 10.117938131231201],
              [38.8487336690307, 10.116178280078101],
              [38.8480329831091, 10.114738997656801],
              [38.847673656995397, 10.113598179358201],
              [38.8465934328663, 10.1113098978827],
              [38.844192685269498, 10.109967874014901],
              [38.842854195496102, 10.1095787523724],
              [38.840713959331701, 10.1101889656473],
              [38.8401031049385, 10.1120991910156],
              [38.839723566731003, 10.1133682464973],
              [38.839503479486403, 10.1140580445862],
              [38.839173348619497, 10.1152187591806],
              [38.838544527920597, 10.1158599140152],
              [38.837343031228102, 10.1157095743757],
              [38.835503730683797, 10.1142879769533],
              [38.834264055591703, 10.113158211528599],
              [38.833612777010799, 10.1125900109776],
              [38.832384330859703, 10.1116879629026],
              [38.831153638920497, 10.1107284285919],
              [38.830264306789203, 10.109618548925701],
              [38.828862934946002, 10.109149833652401],
              [38.827652455100598, 10.1092891217531],
              [38.8269540149672, 10.1094682063653],
              [38.825042849200301, 10.1097290948811],
              [38.8238233862021, 10.109737938555901],
              [38.821914466223298, 10.109538955814299],
              [38.819502489685398, 10.1076198714934],
              [38.819363250816401, 10.1063596370771],
              [38.819354267663599, 10.1057184632848],
              [38.8186423528009, 10.1045798239155],
              [38.818152770971103, 10.1032687254331],
              [38.817854081139103, 10.1021190225333],
              [38.817072546841899, 10.1006885210712],
              [38.816603177106003, 10.100118087492699],
              [38.816183214710598, 10.0994990111466],
              [38.813434369941199, 10.094017922533601],
              [38.812843727641898, 10.093389990326401],
              [38.810813535099797, 10.0934187337289],
              [38.810593447855197, 10.094059932039],
              [38.810312724328902, 10.0946281653358],
              [38.809793947252302, 10.095218507815099],
              [38.808653086841502, 10.0960387946308],
              [38.806263568185699, 10.095559004483],
              [38.8054932628296, 10.0944181181251],
              [38.805003680999803, 10.092578540906301],
              [38.804343419265898, 10.091377945777801],
              [38.801533938214902, 10.0899297081271],
              [38.8001437953127, 10.0901198587856],
              [38.798803059751101, 10.0903188535405],
              [38.797543172565099, 10.090679254838999],
              [38.795753279361499, 10.09110819819],
              [38.794403560647197, 10.090259155126899],
              [38.794333941212599, 10.089109405743599],
              [38.794203685496399, 10.087689901803699],
              [38.7926226505964, 10.0872985421405],
              [38.7914233996921, 10.0890386518013],
              [38.791333568163701, 10.0903586524767],
              [38.791113480919101, 10.0909998568821],
              [38.789943425261498, 10.0904382503343],
              [38.789292146680502, 10.0897594568609],
              [38.788703750169397, 10.0891293052871],
              [38.788003064247803, 10.088737947373099],
              [38.787354031455003, 10.0883488000492],
              [38.786294019419799, 10.0872100992129],
              [38.785234007384503, 10.0860780275944],
              [38.783882042881899, 10.085348369950401],
              [38.782433509486303, 10.0849680628868],
              [38.7809131108679, 10.084068148454101],
              [38.779403941190601, 10.083858094354801],
              [38.7774837922708, 10.0837696504826],
              [38.775433387634799, 10.0823081119786],
              [38.775002196298402, 10.0810499895607],
              [38.774683294372501, 10.079270036449801],
              [38.773863581675798, 10.0781290923917],
              [38.773252727282603, 10.0766984843494],
              [38.7714538509261, 10.0762584660707],
              [38.770052479082899, 10.075769801851401],
              [38.767052106033901, 10.073280035100201],
              [38.766924096105903, 10.072068312179001],
              [38.766912867164898, 10.071378424102701],
              [38.766672567826397, 10.0707482365898],
              [38.764164022395498, 10.070160060467099],
              [38.762942313609102, 10.069658119845201],
              [38.761772257951499, 10.069328651787901],
              [38.759443375577398, 10.068778063478],
              [38.758313744107703, 10.0683689912339],
              [38.755942191757597, 10.0675685348561],
              [38.754803577135, 10.065169365035301],
              [38.754022042837803, 10.0633981692372],
              [38.752272573821898, 10.0624384910506],
              [38.750473697465502, 10.062469448456],
              [38.749263217620097, 10.0628895843784],
              [38.748573760639601, 10.063128398238],
              [38.746662594872603, 10.0633782681057],
              [38.743642009729797, 10.0633097197541],
              [38.742361910449901, 10.0629890901747],
              [38.741072828017202, 10.062369942499499],
              [38.741501773565403, 10.0599088187522],
              [38.740513626752801, 10.0598491147086],
              [38.739931967606402, 10.059508580323],
              [38.739343571095297, 10.059059693538799],
              [38.738642885173597, 10.0588982710452],
              [38.737423422175503, 10.058338819860801],
              [38.735182125541598, 10.056178400460899],
              [38.7340232988251, 10.0563685710187],
              [38.732282812962097, 10.0563995290058],
              [38.730302027760601, 10.055908623431099],
              [38.729021928480698, 10.0555194364994],
              [38.727562166143997, 10.054568580092299],
              [38.726322491051903, 10.0534894652455],
              [38.725323115298401, 10.052178158953],
              [38.723521993153703, 10.052149411875201],
              [38.722563041587897, 10.0521582571302],
              [38.7217231167973, 10.0520587479976],
              [38.720133098744398, 10.0504688090363],
              [38.718731726901098, 10.050318438882799],
              [38.7166723391123, 10.0516098508634],
              [38.716443268714798, 10.0521892155207],
              [38.7161019089069, 10.0528282844915],
              [38.714842021720898, 10.053358998247299],
              [38.714082945305798, 10.053378899996201],
              [38.713202596327399, 10.0530781623268],
              [38.711641773521201, 10.053009611789401],
              [38.708163047583497, 10.0528282844915],
              [38.706891931456397, 10.053188727679],
              [38.705443398060801, 10.0534386052356],
              [38.704632668516901, 10.0537393425696],
              [38.703242525614698, 10.0534894652455],
              [38.7030628625579, 10.052419192043301],
              [38.702341964542399, 10.0517602204163],
              [38.701791746430899, 10.0499181885744],
              [38.701082077356403, 10.0487793521133],
              [38.697733607134801, 10.0494294844873],
              [38.697383264174, 10.0495201491023],
              [38.696172784328702, 10.050059713602],
              [38.694791624579402, 10.050709843401201],
              [38.693621568921799, 10.050610333822799],
              [38.692292062301298, 10.0502896916396],
              [38.690481957003797, 10.049849637366901],
              [38.689152450383297, 10.049869539331899],
              [38.688573037025002, 10.050218929183901],
              [38.686441784013503, 10.050939820976399],
              [38.684142096886099, 10.052759733901199],
              [38.683461623058399, 10.053339096497201],
              [38.682882209700097, 10.0535801287217],
              [38.682242160060198, 10.0534187034903],
              [38.681833426605898, 10.0528503975821],
              [38.680652142007297, 10.0521184534808],
              [38.679441662161999, 10.052018944336],
              [38.678222199163798, 10.0520388461675],
              [38.676832056261603, 10.0523992902353],
              [38.6756822126979, 10.052879144597499],
              [38.674471732852602, 10.0533501530252],
              [38.673202862513698, 10.053659735655501],
              [38.672041790008997, 10.054079883036399],
              [38.670083462689597, 10.0547985549217],
              [38.668702302940297, 10.055329265441801],
              [38.667552459376601, 10.0560987941476],
              [38.665661505703603, 10.0574985356289],
              [38.663761568877597, 10.0585090877151],
              [38.661111538789498, 10.0590486372062],
              [38.661183404012199, 10.0568196728243],
              [38.660763441616901, 10.056249161611699],
              [38.659231814057499, 10.0546592432658],
              [38.657282469890902, 10.0523285282414],
              [38.657143231021898, 10.050548416736399],
              [38.659642793300002, 10.0469992210662],
              [38.660943104673699, 10.0450886098845],
              [38.661282218693501, 10.0439298540864],
              [38.6616123495604, 10.042720232441701],
              [38.661641544807097, 10.040880360501401],
              [38.661562942219803, 10.039668516057001],
              [38.661311413940197, 10.038518586475901],
              [38.661183404012199, 10.0373797098833],
              [38.660981283073298, 10.035539807582801],
              [38.660842044204301, 10.034389863335299],
              [38.660711788488101, 10.0332399150039],
              [38.6606331859007, 10.0320302534099],
              [38.661562942219803, 10.0278196227964],
              [38.662191762918702, 10.027578571383501],
              [38.663981656122303, 10.026808974846899],
              [38.664552086327703, 10.0266298443563],
              [38.666402615812999, 10.025738613406601],
              [38.666973046018398, 10.025210065854401],
              [38.667943226525203, 10.024049027410101],
              [38.668331747885603, 10.023409901615601],
              [38.669533244578098, 10.0222400106513],
              [38.669562439824901, 10.0204597336927],
              [38.669481591449298, 10.018739158598899],
              [38.6693019283925, 10.018159734083399],
              [38.669501803543199, 10.016200298472899],
              [38.671343349875599, 10.015428463363399],
              [38.672731246989599, 10.0150701897087],
              [38.674532369134198, 10.0150392277693],
              [38.676562561676398, 10.015008265826999],
              [38.678992504519897, 10.014459796644999],
              [38.680252391705899, 10.0139290191344],
              [38.6814134642106, 10.0136193985189],
              [38.681992877568902, 10.0130488111823],
              [38.682821573418501, 10.011998308616899],
              [38.683733363431799, 10.010419230995399],
              [38.684452015659097, 10.009309006555799],
              [38.684663119750901, 10.008329263507999],
              [38.684952826429999, 10.0077188577668],
              [38.686821322221, 10.006989023309],
              [38.688561808084003, 10.0070199860162],
              [38.689902543645502, 10.0075198121682],
              [38.691422942263898, 10.0080793511513],
              [38.692642405262099, 10.008400035114001],
              [38.694611961522497, 10.0084287860745],
              [38.6957618050862, 10.0077785714225],
              [38.696033545459599, 10.006349863906101],
              [38.697841404968898, 10.0042687167878],
              [38.700763175430502, 10.003649456834999],
              [38.7014616155639, 10.004688928226299],
              [38.702133106238797, 10.004469976018299],
              [38.706442773814402, 10.000548716031201],
              [38.708073216055098, 9.99912882341715],
              [38.708652629413301, 9.998779378489139],
              [38.709872092411501, 9.99741034695545],
              [38.711091555409702, 9.9971891781537],
              [38.712602970875203, 9.997248893745009],
              [38.714161547893198, 9.99725995218664],
              [38.715502283454697, 9.997268798939659],
              [38.716622931771703, 9.99702993652325],
              [38.7181635424839, 9.99669818287591],
              [38.719502032257303, 9.99624920906734],
              [38.719913011499699, 9.99566974441746],
              [38.720321744953999, 9.99509912554675],
              [38.720672087914799, 9.9945285056745],
              [38.721772524137897, 9.9935000603524],
              [38.724332722697604, 9.99127949204715],
              [38.726513383049799, 9.9913790198356],
              [38.729322864100901, 9.992779040832049],
              [38.730533343946298, 9.99413924503199],
              [38.731182376739, 9.99386941635856],
              [38.732071708870301, 9.99354871805386],
              [38.732952057848699, 9.993199267127221],
              [38.733852618921098, 9.99286972128905],
              [38.734602712183303, 9.992668454874631],
              [38.735862599369298, 9.99231900300202],
              [38.737192105989799, 9.991858964521199],
              [38.737933216099201, 9.99141883094245],
              [38.739723109302801, 9.98960963238771],
              [38.739592853586601, 9.9874996252742],
              [38.741342322602399, 9.985438263391361],
              [38.7443516788042, 9.985279016007251],
              [38.744933337950698, 9.985568757717459],
              [38.746152800948899, 9.98585849916968],
              [38.747893286811902, 9.985869557998621],
              [38.750262593373698, 9.985599722465111],
              [38.752122106011903, 9.98479906161438],
              [38.753923228156502, 9.98371971458757],
              [38.756072447473798, 9.98082005761842],
              [38.757797212819298, 9.98038212091137],
              [38.759014430029303, 9.98050377005575],
              [38.760229401450999, 9.98085102281784],
              [38.761965395737597, 9.98143493745537],
              [38.763124222454103, 9.981554374411299],
              [38.764981489303999, 9.980930647602801],
              [38.766782611448697, 9.9797915689646],
              [38.767422661088602, 9.979450950500761],
              [38.769455099418899, 9.97842466933883],
              [38.771604318736202, 9.977343089355349],
              [38.772760899664497, 9.97752003520166],
              [38.7733987035162, 9.977811995637911],
              [38.7739758710863, 9.97833177303953],
              [38.775354785047398, 9.980689561388539],
              [38.7763945849888, 9.98167159952915],
              [38.777607310622301, 9.982421396925931],
              [38.778763891550597, 9.98294337875479],
              [38.780560522118897, 9.983009732317139],
              [38.782590714660998, 9.98261603431591],
              [38.784337937888601, 9.98067186698046],
              [38.784629890355902, 9.979983996121071],
              [38.7848814186355, 9.97813934490671],
              [38.784760146072102, 9.97700025650453],
              [38.785054344327698, 9.975852316783531],
              [38.787789714367797, 9.97310963132243],
              [38.7897704995693, 9.97082256789041],
              [38.790929326285799, 9.970711974474289],
              [38.791852345240301, 9.970623499714369],
              [38.792919094640098, 9.97051954184078],
              [38.794351907518298, 9.96992233639266],
              [38.796263073285303, 9.96993118388877],
              [38.797138930687296, 9.96884515194726],
              [38.797257957462399, 9.96769718349703],
              [38.797376984237602, 9.967009285244121],
              [38.7975566472944, 9.96586131032962],
              [38.797967626536902, 9.964715543282971],
              [38.798378605779398, 9.96351226235976],
              [38.798960264925803, 9.96317162688039],
              [38.801574362402597, 9.961344575965571],
              [38.802367125640899, 9.961198588404979],
              [38.802753401213003, 9.956719392866731],
              [38.802793825400798, 9.955429815543599],
              [38.802793825400798, 9.954268528002819],
              [38.802712977025301, 9.952868341532101],
              [38.8025625092152, 9.95169819688997],
              [38.802252590442201, 9.950269243303859],
              [38.801962883762997, 9.94869871434879],
              [38.801971866915899, 9.947238779252141],
              [38.801533938214902, 9.94613939957768],
              [38.801062322690697, 9.945500120762221],
              [38.800482909332402, 9.94491835383281],
              [38.799683408729599, 9.9441286533399],
              [38.799211793205401, 9.943668546850789],
              [38.798693016128802, 9.94315977449808],
              [38.797873303432098, 9.942359009451829],
              [38.797433128942799, 9.94196968651063],
              [38.7973230853205, 9.941870143638729],
              [38.796263073285303, 9.94011818413183],
              [38.7962024370036, 9.938638301930821],
              [38.796222649097501, 9.93723804847969],
              [38.796323709566998, 9.935908576384399],
              [38.796433753189298, 9.93492861262818],
              [38.7964225242482, 9.93399952464029],
              [38.796413541095397, 9.933369070574329],
              [38.796352904813702, 9.93243997815747],
              [38.796292268532, 9.93156839858716],
              [38.796483160529903, 9.92966817010563],
              [38.796703247774502, 9.92881870467814],
              [38.797442112095702, 9.928020116679839],
              [38.798122585923402, 9.927429470009489],
              [38.798623396694303, 9.926779093791369],
              [38.799431880450001, 9.92590971131869],
              [38.800283034181703, 9.92463992208334],
              [38.800772616011599, 9.923699743954259],
              [38.801221773653602, 9.92287902154567],
              [38.801612540802203, 9.921739740399749],
              [38.801983095856897, 9.92061815295431],
              [38.801983095856897, 9.91939922417702],
              [38.802223395195398, 9.91783960814827],
              [38.802382846158302, 9.916698097243721],
              [38.802443482439998, 9.91597912031145],
              [38.802513101874503, 9.91460974142249],
              [38.802522085027398, 9.914019070571911],
              [38.802533313968397, 9.912979310309231],
              [38.802542297121299, 9.91215856104966],
              [38.802623145496803, 9.91089977989159],
              [38.802573738156198, 9.909928590423471],
              [38.802362634064501, 9.908809174849001],
              [38.802203183101497, 9.908169823132789],
              [38.8014216488043, 9.90639998489311],
              [38.8010129153501, 9.904988532053579],
              [38.800763632858697, 9.90379830534793],
              [38.800572740860801, 9.902749663372109],
              [38.800343670463398, 9.90165013432649],
              [38.800022522749302, 9.900428922699071],
              [38.799723832917401, 9.89925859062642],
              [38.799472304637803, 9.898128076855791],
              [38.799121961677002, 9.896639155520379],
              [38.798973739655104, 9.896059514185319],
              [38.798771618716202, 9.894878104632751],
              [38.798571743565503, 9.893798460726391],
              [38.798392080508599, 9.89238916610816],
              [38.798212417451801, 9.89160818862228],
              [38.797329822685199, 9.89015906117417],
              [38.796671806739603, 9.88907940176103],
              [38.795113229721601, 9.88784929368096],
              [38.793372743858598, 9.886738652574991],
              [38.790543050713602, 9.88442886104793],
              [38.790682289582698, 9.883568216185919],
              [38.790861952639503, 9.881900018959669],
              [38.791232507694197, 9.880559260148811],
              [38.791713106371198, 9.87927823301164],
              [38.792052220391, 9.878360049870301],
              [38.792393580198897, 9.87753921409762],
              [38.792703498972003, 9.876868826489289],
              [38.793172868707899, 9.876118787249331],
              [38.794603435797903, 9.874839955340841],
              [38.796303497473097, 9.87362970672671],
              [38.796981725512602, 9.872738058191739],
              [38.797763259809798, 9.87181985680431],
              [38.798163010111203, 9.8712689347427],
              [38.798693016128802, 9.870319753606919],
              [38.799173614805802, 9.869368357182161],
              [38.799582348260103, 9.86839925753314],
              [38.800193202653297, 9.86703853061055],
              [38.800732191823798, 9.86491003267207],
              [38.800911854880603, 9.86408916336879],
              [38.801383470404801, 9.86192966993737],
              [38.804033500492899, 9.86219960739016],
              [38.805261946643903, 9.862628851736829],
              [38.805342795019499, 9.85930993760056],
              [38.805371990266302, 9.858104057188379],
              [38.805461821794701, 9.854420009897209],
              [38.806301746585298, 9.85020930365353],
              [38.805881784189999, 9.849237934995021],
              [38.8055516533231, 9.84847898209448],
              [38.805041859399303, 9.846668994080799],
              [38.8049228326242, 9.844759423841079],
              [38.8057133500742, 9.843628722931131],
              [38.806162507716301, 9.843159624744059],
              [38.807712101581401, 9.841770028090609],
              [38.808102868730003, 9.84111948242507],
              [38.808612662653701, 9.83858809526196],
              [38.808502619031401, 9.83793975609392],
              [38.808363380162398, 9.836328860093991],
              [38.808291514939597, 9.83460952973843],
              [38.808262319692901, 9.833927990905851],
              [38.808192700258402, 9.833328324082521],
              [38.808091639788898, 9.83273972016481],
              [38.808091639788898, 9.831978516551761],
              [38.808111851882799, 9.83135007968999],
              [38.808091639788898, 9.829889623214729],
              [38.808091639788898, 9.82889827938665],
              [38.808192700258402, 9.82728955216327],
              [38.808331939127399, 9.82593971998301],
              [38.808383592256298, 9.825328974432599],
              [38.809232500199798, 9.822899258143989],
              [38.8085924505598, 9.82233940295461],
              [38.807732313675302, 9.821367952374549],
              [38.807103492976402, 9.82093865439883],
              [38.806101871434599, 9.818528821921911],
              [38.806521833829898, 9.81804862363901],
              [38.807002432506899, 9.817479908642699],
              [38.8075032432778, 9.81703954110681],
              [38.808172488164502, 9.81631813372719],
              [38.808742918369902, 9.81566975090694],
              [38.809591826313401, 9.81455001512926],
              [38.810283529082199, 9.81391933224401],
              [38.810831501405502, 9.813408146287459],
              [38.811592823608798, 9.812368068529249],
              [38.8118735471351, 9.81155813337516],
              [38.812111600685398, 9.8108986765141],
              [38.812473172587197, 9.80940936139131],
              [38.812751650325303, 9.8083582062724],
              [38.8133130973779, 9.806888796476461],
              [38.814031749605199, 9.80548798257899],
              [38.816322453579701, 9.804164617409249],
              [38.8175014923901, 9.804649261788439],
              [38.8185323091786, 9.80472007734617],
              [38.819241978253103, 9.80267969802898],
              [38.819091510443002, 9.802369877945781],
              [38.818581716519198, 9.801898508264021],
              [38.818312221934001, 9.801139446758089],
              [38.818042727348796, 9.800119247653599],
              [38.818372858215703, 9.798379812010021],
              [38.8186423528009, 9.79772918106681],
              [38.818943288421103, 9.79673995402487],
              [38.8192127830063, 9.79575957621684],
              [38.819383462910302, 9.795038122526259],
              [38.8197832132117, 9.794560103266219],
              [38.8206523332491, 9.793719141785861],
              [38.821162127172897, 9.79324997287614],
              [38.821651709002701, 9.792798507827531],
              [38.821503486980802, 9.79171853012207],
              [38.820423262851698, 9.79011847472735],
              [38.8206523332491, 9.78753800953467],
              [38.820742164777499, 9.78621899899994],
              [38.820973480963197, 9.78442858873696],
              [38.821451833852002, 9.782919236619479],
              [38.821972856716798, 9.78232833003743],
              [38.822302987583697, 9.78181930854382],
              [38.823241727055603, 9.780849952413019],
              [38.823661689450901, 9.780199287123031],
              [38.825792942462499, 9.777439478351461],
              [38.826401551067498, 9.776439125008279],
              [38.826931557085103, 9.774759321363859],
              [38.827333553174803, 9.7729998341407],
              [38.827562623572199, 9.771718390418879],
              [38.827863559192402, 9.770979178842181],
              [38.828263309493799, 9.77034841317421],
              [38.828712467135901, 9.769469765671859],
              [38.829062810096701, 9.768898754212451],
              [38.829502984585901, 9.767929360421521],
              [38.829902734887298, 9.767008655028519],
              [38.830152017378701, 9.76638894804155],
              [38.832871666901397, 9.764919352574839],
              [38.834273038744598, 9.764819756261801],
              [38.835541909083403, 9.76376846002926],
              [38.836972476173401, 9.76233869182615],
              [38.837183580265098, 9.76167913724232],
              [38.837992064020803, 9.76032903821547],
              [38.839292375394599, 9.758058203575141],
              [38.839793186165501, 9.757358801094361],
              [38.840282767995298, 9.75688958088565],
              [38.841592062521897, 9.75567890116319],
              [38.843031612764698, 9.75455896317094],
              [38.843792934968, 9.75395915293584],
              [38.844783327568798, 9.753248675209189],
              [38.846303726187202, 9.75222832915831],
              [38.847233482506198, 9.751898542022831],
              [38.8480329831091, 9.75181886186062],
              [38.848852695805803, 9.75172811498603],
              [38.849953132028901, 9.75150899438213],
              [38.8514825138001, 9.750909178659009],
              [38.852522313741503, 9.750039333276259],
              [38.853292619097601, 9.749249166419711],
              [38.854532294189703, 9.747879096760199],
              [38.856362611581098, 9.746227922939021],
              [38.856953253880398, 9.745778607468401],
              [38.8577527544833, 9.745178781438799],
              [38.859012641669203, 9.7445789543307],
              [38.860101848951203, 9.744098649189899],
              [38.860602659722097, 9.743669251381871],
              [38.8615324160412, 9.7424895112605],
              [38.862143270434402, 9.74077854866465],
              [38.862751879039401, 9.739408444220579],
              [38.862971966284, 9.73880860674085],
              [38.863533413336597, 9.73802948043477],
              [38.864813512616401, 9.736548692756189],
              [38.865222246070701, 9.73563896792138],
              [38.865323306540198, 9.73429983074805],
              [38.867133411837699, 9.732208109874669],
              [38.8677824446305, 9.73183846048545],
              [38.868842456665703, 9.73118991296395],
              [38.870302219002397, 9.73036871276015],
              [38.8708816323607, 9.730078746707379],
              [38.871642954564003, 9.729689173599111],
              [38.872633347164701, 9.729319521423029],
              [38.873583315577697, 9.728768362628671],
              [38.8778772626357, 9.72727425289127],
              [38.878557736463499, 9.726647831562319],
              [38.879507704876403, 9.725784563496299],
              [38.879932158848199, 9.725335220518209],
              [38.880482376959698, 9.724757492944621],
              [38.881317810173897, 9.72396947977875],
              [38.883082999707199, 9.72236909900975],
              [38.884196910659497, 9.72188433485878],
              [38.8850817512144, 9.7202684492808],
              [38.886858169688701, 9.721246835727589],
              [38.887758730761099, 9.72064032500951],
              [38.888544756634701, 9.720250740902451],
              [38.890783807480297, 9.71788887753973],
              [38.891913438950098, 9.717908799605951],
              [38.8927578553172, 9.718884979398061],
              [38.895558353215399, 9.715887810621471],
              [38.896133274997297, 9.714351585199671],
              [38.896524042145899, 9.713857955072941],
              [38.897330280113401, 9.712928246131041],
              [38.897739013567602, 9.71245896349398],
              [38.898498089982702, 9.711969757780469],
              [38.899151614351901, 9.71165321252608],
              [38.899888232884898, 9.711487192168111],
              [38.901794907075399, 9.711301249269439],
              [38.902998649556203, 9.710880663760539],
              [38.9037936585826, 9.71053976774987],
              [38.904680744925699, 9.710116967670039],
              [38.905814867971898, 9.70973179854623],
              [38.907299333978898, 9.709386474127511],
              [38.908592907988002, 9.7084279756378],
              [38.909313806003503, 9.70859842476146],
              [38.910052670324703, 9.709129694200669],
              [38.914247802701503, 9.710196657778271],
              [38.915002387540198, 9.709317851924959],
              [38.915792904990198, 9.709809275129709],
              [38.916473378817898, 9.710289629547241],
              [38.917302074667496, 9.710889518197749],
              [38.917782673344497, 9.711248122707991],
              [38.918892092720398, 9.71228851633558],
              [38.919828586404101, 9.71317838236218],
              [38.921393900786697, 9.71469911808088],
              [38.922042933579498, 9.71543845434738],
              [38.922433700728099, 9.71630838983873],
              [38.922763831594999, 9.717178323068451],
              [38.9234330764817, 9.71851974239436],
              [38.923902446217603, 9.71968849940251],
              [38.924142745556097, 9.720387980810109],
              [38.924832202536699, 9.72166962174545],
              [38.926163954945402, 9.723469223265869],
              [38.926882607172701, 9.72407794237529],
              [38.927652912528799, 9.72462910890378],
              [38.928533261507198, 9.72536842322198],
              [38.929492213072997, 9.726218411315839],
              [38.930112050619101, 9.72670980969667],
              [38.931472998274501, 9.72755979437958],
              [38.932182667348997, 9.727708098777271],
              [38.933027083716098, 9.728688676944641],
              [38.934428455559299, 9.72829910221582],
              [38.935948854177703, 9.728212775936059],
              [38.937338997079799, 9.727382714415571],
              [38.938920031979897, 9.725895239013971],
              [38.940359582222698, 9.72508730689243],
              [38.941460018445703, 9.724469735543151],
              [38.942769312972302, 9.723130553608851],
              [38.943315039507397, 9.722570530482249],
              [38.944424458883297, 9.721585507292341],
              [38.944981414359503, 9.72091480444884],
              [38.945872992279, 9.7198368072915],
              [38.946980165866599, 9.718836281153941],
              [38.948197383076597, 9.71778262649979],
              [38.948882348480801, 9.717532493710049],
              [38.950029946256201, 9.71711191603141],
              [38.950602622249903, 9.7167975892106],
              [38.951411106005601, 9.716206565445161],
              [38.953508672193998, 9.714028401432181],
              [38.954079102399398, 9.713556906745881],
              [38.957135620153601, 9.71090279985313],
              [38.957908171298001, 9.7104135918657],
              [38.959363442058198, 9.70895703172539],
              [38.9600528990388, 9.708217681144911],
              [38.961276853613398, 9.707099797676641],
              [38.961272362037001, 9.708941536370681],
              [38.961357701989002, 9.71070578857764],
              [38.961627196574199, 9.71243904110332],
              [38.961577789233601, 9.71356354752073],
              [38.960758076536798, 9.71557126907248],
              [38.959855269676297, 9.717222594419161],
              [38.958175420095003, 9.71993641703077],
              [38.957517404149399, 9.72129110653985],
              [38.956104803365101, 9.72396947977875],
              [38.954948222436798, 9.72659249393266],
              [38.954389021172403, 9.728093247204161],
              [38.953578291628503, 9.730295667900339],
              [38.952644043733002, 9.73264637628523],
              [38.951864755224001, 9.73506789851076],
              [38.951500937534, 9.73701351470778],
              [38.9515076748986, 9.73836370813537],
              [38.952217343973103, 9.739421724743],
              [38.953034810881597, 9.739802432827419],
              [38.9539713045653, 9.741022023825479],
              [38.9544766069126, 9.741752448242259],
              [38.955240174904098, 9.742113233046179],
              [38.955875732967598, 9.742414255651569],
              [38.956630317806301, 9.74316902437257],
              [38.957328757939699, 9.743868456619269],
              [38.958323642116902, 9.74439967000419],
              [38.959224203189201, 9.74488218809579],
              [38.960308918894803, 9.745209768787779],
              [38.961056766368799, 9.745785247602919],
              [38.962190889414998, 9.746659530826101],
              [38.963179036227501, 9.74782818937846],
              [38.963949341583699, 9.748740094343921],
              [38.964423202896, 9.749384181057231],
              [38.965236178228203, 9.750488643006671],
              [38.965721268481602, 9.751143793055579],
              [38.966242291346397, 9.75236112925495],
              [38.966644287435997, 9.753295155106761],
              [38.967131623477698, 9.75442837727071],
              [38.967672858436302, 9.75568996772537],
              [38.968110787137299, 9.757511518849951],
              [38.9683196454409, 9.75823305396592],
              [38.970349837983001, 9.759339699880391],
              [38.971748964037999, 9.7607030825923],
              [38.973127877999197, 9.76095539631339],
              [38.974014964342203, 9.76165921778826],
              [38.975247902069697, 9.762088592422939],
              [38.975923884320999, 9.7608580120928],
              [38.976323634622403, 9.76028919914466],
              [38.977212966753697, 9.757719569013039],
              [38.977424070845501, 9.75694934009989],
              [38.977713777524599, 9.75583825962301],
              [38.978203359354403, 9.754547896571211],
              [38.978863621088301, 9.75354968776424],
              [38.979312778730304, 9.752918889083141],
              [38.980103296180303, 9.752358915920439],
              [38.980974662005899, 9.751905182035481],
              [38.981082459840003, 9.75184763525472],
              [38.981271106049697, 9.751657288139899],
              [38.981823569949398, 9.751099526200941],
              [38.982452390648298, 9.750417815897251],
              [38.984403980603098, 9.74953911581985],
              [38.985053013395799, 9.74933770061442],
              [38.9863443416168, 9.74901897740342],
              [38.987584016708801, 9.74822880812912],
              [38.988143217973203, 9.747097778263081],
              [38.988974159610997, 9.74639835278678],
              [38.989362680971396, 9.745187634967371],
              [38.990384514607101, 9.74474938502091],
              [38.9912334225506, 9.745729913144601],
              [38.992394495055301, 9.746584276157771],
              [38.993867732121302, 9.746641823846851],
              [38.994995117802802, 9.747334608709069],
              [38.9961157661198, 9.7481314201898],
              [38.998204349155301, 9.74912964521954],
              [38.998734355172999, 9.747808269096531],
              [38.999583263116499, 9.74878657487003],
              [39.0007128945863, 9.74918940582498],
              [39.002316387368403, 9.749829064967861],
              [39.003127116912303, 9.750285015026529],
              [39.0039603043383, 9.74981578485971],
              [39.004634040801399, 9.749884398746079],
              [39.005687315472102, 9.75027616163327],
              [39.006271220406703, 9.75047093623073],
              [39.0071964851494, 9.750831711605009],
              [39.008449634970702, 9.75139611401481],
              [39.009222186115103, 9.751690488225901],
              [39.010302410244201, 9.751770168418791],
              [39.011079452964999, 9.751834355226981],
              [39.011775647310202, 9.751867555295339],
              [39.012505528478499, 9.751874195308609],
              [39.013931603992098, 9.751867555295339],
              [39.014701909348197, 9.75212651571505],
              [39.014904030287099, 9.752828142507999],
              [39.014904030287099, 9.75465413591343],
              [39.015186999601603, 9.756623984248231],
              [39.015600224632301, 9.75740085381665],
              [39.0161437053792, 9.757781541376939],
              [39.017183505320602, 9.758129029060941],
              [39.0187241160328, 9.75862702033289],
              [39.020767783304201, 9.758757604587659],
              [39.021481943955102, 9.75860267411022],
              [39.0221803840885, 9.75829945282446],
              [39.023040520972998, 9.75792097915355],
              [39.023905149434, 9.75774170200147],
              [39.025079696668001, 9.757484959245319],
              [39.025872459906203, 9.757190590152121],
              [39.0264271695942, 9.75675235598275],
              [39.028574143123201, 9.75552618256769],
              [39.0305055209841, 9.755380103845621],
              [39.032571646137498, 9.75550847605933],
              [39.034512007151299, 9.75436861760439],
              [39.034669212326001, 9.753728967171551],
              [39.034848875382799, 9.753053902234591],
              [39.035201464131802, 9.751902968697941],
              [39.035500153963802, 9.75090253862651],
              [39.0352126930729, 9.748735667626811],
              [39.034449125081402, 9.747852536388089],
              [39.034089798967699, 9.747363382490111],
              [39.033865220146701, 9.74673257210558],
              [39.033858482782101, 9.7460597063805],
              [39.035120615756199, 9.745501935079529],
              [39.036416435553598, 9.74592469039716],
              [39.037155299874797, 9.746278830564989],
              [39.037748187962301, 9.746821106968349],
              [39.0386487490346, 9.74765554689554],
              [39.039194475569701, 9.74808272620947],
              [39.040166901864801, 9.74943730155538],
              [39.041590731590098, 9.750783018019],
              [39.043636644649702, 9.75101541916092],
              [39.045413063124002, 9.75165950147907],
              [39.046095782739997, 9.752000355535699],
              [39.047465713548299, 9.75348550128634],
              [39.048557166618501, 9.75410965892652],
              [39.050041632625501, 9.753684700660189],
              [39.0510387625908, 9.75324646188057],
              [39.052260471377203, 9.75301184896378],
              [39.053210439790199, 9.75300299564293],
              [39.054816178360603, 9.75330400841985],
              [39.056055853452598, 9.753682487334469],
              [39.056976626618898, 9.753950299640151],
              [39.057722228304698, 9.75407203243523],
              [39.058782240340001, 9.75388832656375],
              [39.059592969883902, 9.753454514706361],
              [39.060358783663602, 9.75305611556449],
              [39.061524347744701, 9.75283478250214],
              [39.063067204245201, 9.752870195801989],
              [39.065200703045001, 9.75252270263452],
              [39.065930584213298, 9.75248507596408],
              [39.067289286080602, 9.752626729289529],
              [39.068266203952, 9.75325531519495],
              [39.069191468694697, 9.75405432584961],
              [39.069905629345598, 9.753923739751681],
              [39.070480551127403, 9.753286301793439],
              [39.071075685003102, 9.75299635565214],
              [39.072185104379002, 9.75247622262925],
              [39.073373126342297, 9.75225267584665],
              [39.075526837235898, 9.753591740967209],
              [39.076216294216501, 9.75423581830754],
              [39.076892276467802, 9.75483562804475],
              [39.077422282485401, 9.755621355033981],
              [39.079578239167297, 9.757053365373199],
              [39.0802879082418, 9.75716403052191],
              [39.0819340709999, 9.757128617678321],
              [39.082645985862598, 9.756894007494481],
              [39.083382604395602, 9.756725796317591],
              [39.084590838452698, 9.756827608355851],
              [39.086014668178002, 9.758598247524089],
              [39.086205560175898, 9.75975137122242],
              [39.086436876361603, 9.76061233813709],
              [39.086791710898801, 9.76136927893317],
              [39.087202690141297, 9.76199563507477],
              [39.0875889657135, 9.76258879104153],
              [39.087997699167701, 9.763226211201181],
              [39.088467068903697, 9.76383928430079],
              [39.089668565596199, 9.76508534636371],
              [39.090834129677297, 9.76538413497509],
              [39.092004185334901, 9.76445678277986],
              [39.092563386599302, 9.76371534181574],
              [39.092774490690999, 9.7629362737104],
              [39.092841864337302, 9.76191817059811],
              [39.093255089368, 9.760869078482941],
              [39.0935852202349, 9.76018074831666],
              [39.0963542770982, 9.75842118403073],
              [39.097036996714202, 9.758301666119509],
              [39.097703995812601, 9.758182148165419],
              [39.098348537028997, 9.758018364232759],
              [39.09957698318, 9.757170670429661],
              [39.099947538234701, 9.756654970533511],
              [39.1008480993071, 9.75560586184365],
              [39.101122085468702, 9.75503261329491],
              [39.102027138117499, 9.754215898408461],
              [39.102631255146001, 9.75378872695255],
              [39.103300500032702, 9.75368027400873],
              [39.104553649853997, 9.754184911896379],
              [39.104501996725197, 9.75220840913925],
              [39.104486276207702, 9.75112387297172],
              [39.104542420912999, 9.750163280848581],
              [39.104708609240603, 9.749452795032409],
              [39.10502301959, 9.74817568743846],
              [39.105213911587903, 9.74748954442385],
              [39.105485651961303, 9.746765772681879],
              [39.105456456714599, 9.74606191975681],
              [39.104216781622497, 9.74563916461309],
              [39.103287025303402, 9.745464307616921],
              [39.102530194676604, 9.745411186486001],
              [39.101542047864001, 9.745280597003211],
              [39.100731318320101, 9.745218622315519],
              [39.100071056586302, 9.745209768787779],
              [39.099431006946403, 9.745176568056619],
              [39.0984675638041, 9.745083605991789],
              [39.097744420000403, 9.74490432193646],
              [39.097003309891001, 9.744860054253641],
              [39.095312231368702, 9.743556368361229],
              [39.095402062897101, 9.74258468744747],
              [39.095720964822902, 9.741387236233701],
              [39.096852842080899, 9.73945492604672],
              [39.097205430830002, 9.73876433824946],
              [39.097468188050598, 9.73819327418415],
              [39.097533315908599, 9.737150747731659],
              [39.097279541840898, 9.73626758583338],
              [39.097019030408497, 9.735636754475941],
              [39.096673179024101, 9.734361807462999],
              [39.096626017471699, 9.732883216984931],
              [39.0969763604325, 9.732170480913631],
              [39.098573115850002, 9.73296732859489],
              [39.099550033721499, 9.73358267078136],
              [39.100203558090698, 9.733717691756951],
              [39.101014287634598, 9.733781882037681],
              [39.101955272894699, 9.73385271267796],
              [39.102822147143897, 9.73381065698711],
              [39.103538553583, 9.733680062965959],
              [39.105195945282198, 9.7333082017447],
              [39.107185713636497, 9.732686218657671],
              [39.107980722663001, 9.73252020874112],
              [39.108786960630503, 9.73240953541759],
              [39.10967853855, 9.73237854688043],
              [39.111093385122402, 9.73240510848389],
              [39.112762005762697, 9.732380760347461],
              [39.115142541265598, 9.73143118164289],
              [39.1160520854908, 9.73245823168447],
              [39.116925697104598, 9.73395231824043],
              [39.117797062930201, 9.73446583978011],
              [39.118967118587697, 9.734012081563661],
              [39.120678409203997, 9.733974452805841],
              [39.121843973285102, 9.73416923691817],
              [39.122859069556199, 9.734943945331411],
              [39.123651832794401, 9.735539362861941],
              [39.1243120945283, 9.736010826547499],
              [39.125241850847303, 9.737031222197899],
              [39.126194065048502, 9.73798078498028],
              [39.126712842125102, 9.73836149470799],
              [39.127957008793601, 9.73893477190912],
              [39.128761000972901, 9.739182675258769],
              [39.129861437195899, 9.739620932515759],
              [39.132360999474002, 9.740849377820661],
              [39.1335580045901, 9.74144699822609],
              [39.134424878839198, 9.74182106378716],
              [39.136964865305103, 9.742389908245061],
              [39.138548145993298, 9.743153530603671],
              [39.139057939917102, 9.743494393353259],
              [39.140764738956896, 9.743671464774049],
              [39.1413194486449, 9.743206652094051],
              [39.141427246478898, 9.74247623086015],
              [39.1410791493064, 9.741595296457261],
              [39.140558126441597, 9.7411083467944],
              [39.139927059954502, 9.74083388394407],
              [39.138370728724702, 9.73986219510358],
              [39.138132675174397, 9.739151687350139],
              [39.138932175777299, 9.738290665024071],
              [39.141366610197302, 9.737416359875731],
              [39.142141407129799, 9.73734110312302],
              [39.1431991733769, 9.73707327748318],
              [39.143866172475299, 9.735962130798599],
              [39.143399048527598, 9.734968293280989],
              [39.142725312064499, 9.733536188137791],
              [39.1434978632088, 9.73335911134112],
              [39.143922317180603, 9.732770330317241],
              [39.1434911258442, 9.73089773414701],
              [39.144789191429801, 9.730441757604],
              [39.145972721816598, 9.728704171384679],
              [39.145651574102502, 9.72793608899161],
              [39.145139534390601, 9.727451332912761],
              [39.144196303342198, 9.72635122176027],
              [39.143884138780997, 9.72416205620145],
              [39.143897613510298, 9.72285607598811],
              [39.143902105086703, 9.72217430801984],
              [39.144634232043202, 9.72044774655876],
              [39.144358000093398, 9.71913511117658],
              [39.144557875244097, 9.71837807460077],
              [39.144899235052101, 9.717725073839061],
              [39.146215266943301, 9.71619328400023],
              [39.146945148111598, 9.716210992593339],
              [39.147758123443801, 9.71629953554487],
              [39.148496987765, 9.716120236043709],
              [39.148582327717001, 9.715458376559511],
              [39.148393681507301, 9.71486292326281],
              [39.147937786500599, 9.71423647868812],
              [39.147439221517899, 9.7133798193682],
              [39.147084386980701, 9.711746183890581],
              [39.147493120435001, 9.71051320440978],
              [39.146713831926, 9.70898138156703],
              [39.1440615560496, 9.70906328556687],
              [39.143071163448901, 9.70879543727683],
              [39.142502979031697, 9.707896705085391],
              [39.1437898156762, 9.70612136987622],
              [39.145557250997697, 9.705990765044129],
              [39.146179334331897, 9.70541079042883],
              [39.146460057858199, 9.704651509166689],
              [39.146527431504502, 9.70360002352778],
              [39.146235479037202, 9.70176932381564],
              [39.145626870432203, 9.70101888900013],
              [39.146325310565601, 9.698922532692521],
              [39.146978834934799, 9.699128405645411],
              [39.147733419773502, 9.696985549794951],
              [39.147376339448002, 9.69616205196165],
              [39.1469990470287, 9.69544259655421],
              [39.146498236257798, 9.694920160275601],
              [39.1458581866179, 9.694725353319329],
              [39.1450339823447, 9.6946877201443],
              [39.144041343955699, 9.69440879177407],
              [39.143583203160802, 9.693204526941511],
              [39.143778586735102, 9.69181430407453],
              [39.145004787098003, 9.6903997242568],
              [39.145570725726898, 9.68991270009122],
              [39.147650325609703, 9.690594533725109],
              [39.149428989872199, 9.69081148049986],
              [39.150136413158499, 9.69086461030086],
              [39.152914453174603, 9.68945224023005],
              [39.153880142105102, 9.688770404275081],
              [39.155236598184104, 9.68837192807562],
              [39.156447078029402, 9.68851803607035],
              [39.157255561785099, 9.688500326013781],
              [39.158275149632601, 9.68847818844174],
              [39.160334537421399, 9.688422844505251],
              [39.161381074727402, 9.688513608556301],
              [39.161987437544198, 9.688730556676431],
              [39.163337156258599, 9.689394682702959],
              [39.164242208907403, 9.691247587367551],
              [39.164644204997003, 9.692013540189389],
              [39.165612139715599, 9.693160252416741],
              [39.166582320222503, 9.69343918182526],
              [39.167815257949997, 9.69328422107138],
              [39.168572088576802, 9.692885750232699],
              [39.170157615053299, 9.692861399221661],
              [39.170642705306697, 9.694134290611821],
              [39.171736404165102, 9.69607571739426],
              [39.172535904767997, 9.69616205196165],
              [39.173081631303099, 9.695732592611829],
              [39.1738092666832, 9.69526771331428],
              [39.175720432450198, 9.6952345076255],
              [39.176542390935197, 9.69513710425282],
              [39.176984811212598, 9.693532158243221],
              [39.1773621036319, 9.69151323594315],
              [39.177692234498799, 9.69086903778391],
              [39.178118934258798, 9.68991270009122],
              [39.178446819337502, 9.68913124620304],
              [39.178936401167398, 9.688345362978829],
              [39.179448440879298, 9.688000016529241],
              [39.180283874093497, 9.68714771768107],
              [39.181941265792702, 9.685921289047281],
              [39.182913692087801, 9.685403265498559],
              [39.1839175594178, 9.68434065058938],
              [39.185029224581903, 9.6839798035115],
              [39.184613753763003, 9.68527265259727],
              [39.184589050092697, 9.68615373525164],
              [39.1845396427521, 9.686904203326931],
              [39.184474514893999, 9.68798009268477],
              [39.184160104544503, 9.691882929860791],
              [39.1843060807782, 9.69303628371628],
              [39.184355488118797, 9.69455932457412],
              [39.184187054002997, 9.69607793110139],
              [39.184000653581599, 9.69825621183846],
              [39.184074764592502, 9.69980136581354],
              [39.184115188780297, 9.70085286337148],
              [39.184146629815203, 9.701649785638971],
              [39.184283622896103, 9.70377268876439],
              [39.184618245339401, 9.705477199786831],
              [39.184836086795798, 9.70675447054402],
              [39.184858544677901, 9.707644351277761],
              [39.184710322656002, 9.708629415501891],
              [39.184492481199598, 9.709616690446209],
              [39.184225232402603, 9.710927149553291],
              [39.183971458334803, 9.71223981713152],
              [39.183782812125202, 9.71348164462181],
              [39.183529038057401, 9.71483857384893],
              [39.183239331378303, 9.716233128333419],
              [39.182945133122701, 9.71732663207024],
              [39.182444322351799, 9.7190576367519],
              [39.182239955624702, 9.71968628585142],
              [39.181359606646303, 9.721417278322649],
              [39.182812631618297, 9.721189283662881],
              [39.1840343404047, 9.720536288388971],
              [39.1847664673613, 9.72018654802307],
              [39.185765843114901, 9.719794749837121],
              [39.186623734211203, 9.719416232509751],
              [39.1873019622507, 9.71915946027779],
              [39.188532654189999, 9.718853989607171],
              [39.189338892157501, 9.71866362368584],
              [39.190165342218798, 9.71843562714915],
              [39.190917681269298, 9.71832716272278],
              [39.191874387046902, 9.718214271139621],
              [39.192474012498998, 9.718096952395189],
              [39.193419489335596, 9.71791322673163],
              [39.1942975925258, 9.71783796558729],
              [39.195629344934503, 9.71776270442604],
              [39.196287360880099, 9.717700724633479],
              [39.197017242048503, 9.71771843314681],
              [39.198077254083699, 9.71746387317752],
              [39.198885737839397, 9.71647662137762],
              [39.199029468284898, 9.71583689836457],
              [39.199240572376702, 9.71453752640393],
              [39.199321420752199, 9.713669799900259],
              [39.199415743857102, 9.7128219935161],
              [39.199563965879001, 9.711814805595431],
              [39.199626847948799, 9.711141869559819],
              [39.199885113592998, 9.709968655473681],
              [39.199943504086498, 9.70903229486659],
              [39.200134396084401, 9.707467260772921],
              [39.200583553726403, 9.70612801079853],
              [39.200985549816103, 9.70550597717121],
              [39.203224600661699, 9.70430175220465],
              [39.204239696932802, 9.704602808852091],
              [39.205059409629499, 9.705313390013281],
              [39.205477126236701, 9.70717506123521],
              [39.2055669577651, 9.70841026663296],
              [39.2056635266581, 9.70945952355348],
              [39.205708442422299, 9.710754488004831],
              [39.206166583217197, 9.71230401153538],
              [39.207224349464298, 9.71156466834541],
              [39.208091223713502, 9.71144070645313],
              [39.2092949661942, 9.71189892250478],
              [39.209941753198699, 9.711825873611041],
              [39.2100854836442, 9.71105996606842],
              [39.210029338938902, 9.710218793916081],
              [39.209881116917103, 9.70948165974001],
              [39.209714928589499, 9.7084213347611],
              [39.209802514329702, 9.706468911300041],
              [39.2099821773865, 9.704868446845749],
              [39.210554853380202, 9.703781543389519],
              [39.212960092553402, 9.70404054107077],
              [39.213550734852703, 9.704512049155969],
              [39.214314302844201, 9.70619220637402],
              [39.214547864818101, 9.707194983939059],
              [39.214655662652198, 9.70780373265208],
              [39.214893716202397, 9.70891940014844],
              [39.2152216012812, 9.709964228242921],
              [39.216097458683201, 9.709807061513301],
              [39.216510683713899, 9.7091385486842],
              [39.217572941537298, 9.70860506563465],
              [39.218264644306103, 9.708463393644671],
              [39.218888973428598, 9.70840583938161],
              [39.219625591961602, 9.70824867192059],
              [39.220137631673502, 9.707878996052459],
              [39.221426714106201, 9.70713300218987],
              [39.222531641905697, 9.70641578397198],
              [39.223097580534699, 9.705490481656851],
              [39.223742121751002, 9.70481753291328],
              [39.224523656048198, 9.70414015551026],
              [39.225518540225401, 9.70318164200849],
              [39.225585913871697, 9.70096133345819],
              [39.224932389502499, 9.69941618483001],
              [39.2247976422099, 9.6987144459235],
              [39.224793150633403, 9.698045910961721],
              [39.224820100092003, 9.69735081028321],
              [39.224754972233903, 9.69526107217678],
              [39.2246808612229, 9.694244976591371],
              [39.225572439142397, 9.69279055990674],
              [39.227095083549003, 9.69219285259161],
              [39.229480110628302, 9.69253155353428],
              [39.230090965021503, 9.69297208562125],
              [39.230706310991202, 9.693516662175339],
              [39.231146485480402, 9.694444211262519],
              [39.231355343783903, 9.695418245728799],
              [39.231636067310198, 9.697156004739],
              [39.232197514362802, 9.69784003734409],
              [39.232505187347599, 9.698358041689261],
              [39.232947607625, 9.69934756058535],
              [39.233390027902502, 9.700301657694579],
              [39.234041306483498, 9.701886648280921],
              [39.236531885608699, 9.70193092165337],
              [39.237674991807701, 9.70219213443178],
              [39.239089838380202, 9.702566243903849],
              [39.240176799874, 9.702564030239561],
              [39.241537747529399, 9.7026370811533],
              [39.243174927134703, 9.70236258672824],
              [39.243936249337999, 9.702019468380721],
              [39.244863759868899, 9.70114285474923],
              [39.2470242081272, 9.70170955473262],
              [39.248665879308902, 9.703383085027429],
              [39.249135249044897, 9.70403390010709],
              [39.249945978588798, 9.70491493347299],
              [39.2505770450759, 9.705561318288069],
              [39.251091330576003, 9.70657737956868],
              [39.251387774619801, 9.707726255604589],
              [39.251524767700602, 9.708578502141091],
              [39.252133376305601, 9.710103685983491],
              [39.252160325764102, 9.71070357496715],
              [39.252097443694304, 9.711420784001881],
              [39.251962696401598, 9.712441254702339],
              [39.252018841106903, 9.713711858124499],
              [39.252429820349398, 9.71434937161329],
              [39.252883469567898, 9.714847428181461],
              [39.253534748148901, 9.715434027188961],
              [39.254612726489803, 9.717213740149489],
              [39.254781160605603, 9.718792010016781],
              [39.255032688885102, 9.719943057679],
              [39.255394260787, 9.72096350239034],
              [39.256059014097197, 9.72201271995347],
              [39.256874235217602, 9.723081855982899],
              [39.257366062835601, 9.723717138091461],
              [39.257774796289901, 9.72427494577696],
              [39.258075731910097, 9.72516920694985],
              [39.258239674449399, 9.726539369799569],
              [39.258801121502003, 9.728088820213269],
              [39.259528756882098, 9.72889231809941],
              [39.260487708447897, 9.72958735328004],
              [39.261289454839002, 9.73062104942572],
              [39.262830065551299, 9.732356412209271],
              [39.263178162723896, 9.733305988283821],
              [39.263452148885499, 9.73536892746908],
              [39.2636677445537, 9.73607058950236],
              [39.263905798103998, 9.73672355442341],
              [39.264352709957898, 9.737442921078509],
              [39.265870862787999, 9.739173821570891],
              [39.267433931382399, 9.7400879636058],
              [39.268188516221102, 9.740118951427521],
              [39.269246282468103, 9.739817926751901],
              [39.2697358642979, 9.73944828578623],
              [39.2715190201369, 9.738124657893531],
              [39.272248901305304, 9.737728453875],
              [39.2729585703797, 9.73734110312302],
              [39.274970796616202, 9.737239285136489],
              [39.275940977123, 9.736719127546889],
              [39.277063871228201, 9.735712011612771],
              [39.277908287595203, 9.73482663245858],
              [39.278541599870501, 9.734246707840359],
              [39.279990133266203, 9.73335911134112],
              [39.2812837072753, 9.732301075524949],
              [39.282287574605299, 9.73176984288914],
              [39.283516020756302, 9.73136256396295],
              [39.285927997294202, 9.73218376172389],
              [39.286945339353501, 9.732874363130019],
              [39.287414709089397, 9.733560536189991],
              [39.287684203674701, 9.734293190385049],
              [39.288459000607197, 9.735094459900351],
              [39.288937353496003, 9.735705371277851],
              [39.289496554760397, 9.7380759624529],
              [39.289240534904401, 9.73947041998732],
              [39.289992873954802, 9.74302958042658],
              [39.290700297241102, 9.74354308800333],
              [39.292436291527601, 9.74474938502091],
              [39.292858499711201, 9.745816234895569],
              [39.2929999843684, 9.74681668022577],
              [39.293547956691697, 9.7478658165744],
              [39.293604101397001, 9.749525835700149],
              [39.296761679620701, 9.7483704632628],
              [39.298904161573297, 9.747668827089701],
              [39.301230798159203, 9.746739212221099],
              [39.303424933240599, 9.745862715829141],
              [39.304615200992103, 9.74538683929817],
              [39.308075960624201, 9.74400347343477],
              [39.310187001541799, 9.74039784169355],
              [39.3108562464285, 9.739392950277111],
              [39.311390744022603, 9.73812244446456],
              [39.312077955214903, 9.736654937830741],
              [39.312744954313402, 9.7349395184313],
              [39.312902159488097, 9.7338350050193],
              [39.3127494458898, 9.732856655419489],
              [39.312722496431299, 9.73165916931703],
              [39.312693301184503, 9.7303709262405],
              [39.3133266134598, 9.72890781253002],
              [39.317609331576897, 9.724000469095641],
              [39.318745700411299, 9.72410229111661],
              [39.320450253662898, 9.72437676771434],
              [39.321550689886003, 9.724248383527319],
              [39.323787494943403, 9.72383224134086],
              [39.326720494346098, 9.724058020676511],
              [39.328865222086897, 9.724286013380359],
              [39.3306640984434, 9.72472207666241],
              [39.332357422753901, 9.725235612387079],
              [39.333343323778202, 9.72547688536466],
              [39.335050122818103, 9.72577349594253],
              [39.336509885154797, 9.72610552239659],
              [39.338331219393297, 9.72655265083363],
              [39.340118866808702, 9.72699977867255],
              [39.3416729522502, 9.727413703416049],
              [39.343494286488799, 9.72792944850214],
              [39.347815183005402, 9.72665668558221],
              [39.3470224197672, 9.72553000966661],
              [39.346124104483003, 9.724277159297669],
              [39.344718241063397, 9.72282730009546],
              [39.342714997979797, 9.720850611696919],
              [39.341771766931501, 9.71926349732656],
              [39.3411743872676, 9.717652026216969],
              [39.339822422764897, 9.715812549021569],
              [39.339364281969999, 9.714006265546271],
              [39.338894912234103, 9.712073797064461],
              [39.3378349001988, 9.71065930275417],
              [39.337459853567701, 9.70970523514203],
              [39.337837145987002, 9.70836820774273],
              [39.338281812052699, 9.706411356694259],
              [39.338156047912904, 9.704556322181549],
              [39.339245255194903, 9.703451708445799],
              [39.339961661634, 9.702725627694081],
              [39.340722983837303, 9.701955272005719],
              [39.341787487448997, 9.701180487199711],
              [39.342479190217702, 9.70025959778642],
              [39.343182121927597, 9.69936084269832],
              [39.3447339615809, 9.698933601134071],
              [39.348967272357299, 9.69965304905045],
              [39.350799835536897, 9.70022639259204],
              [39.351880059666001, 9.700627068384531],
              [39.354424537708297, 9.700892709529869],
              [39.356665834342202, 9.70110300862059],
              [39.357712371648198, 9.69905092659195],
              [39.359118235067797, 9.69897123383258],
              [39.358489414368997, 9.70176711014609],
              [39.359677436332198, 9.70200397270513],
              [39.361873817201896, 9.701260179433829],
              [39.362855226649799, 9.70129559819846],
              [39.363890535014697, 9.701614366911761],
              [39.365363772080698, 9.701474905636999],
              [39.366580989290703, 9.701552384130141],
              [39.368249609930899, 9.701643144627891],
              [39.369374749824303, 9.701860084254641],
              [39.370437007647702, 9.701016675325629],
              [39.370618916492802, 9.69968404061831],
              [39.370614424916397, 9.698486435804879],
              [39.370677306986202, 9.697058601924439],
              [39.370672815409797, 9.69580343130534],
              [39.370481923411901, 9.69232567653145],
              [39.370425778706696, 9.691291862144761],
              [39.369624032315599, 9.688117345811589],
              [39.369042373169101, 9.68721855818727],
              [39.368182236284603, 9.686565496704221],
              [39.366035262755602, 9.68577296614705],
              [39.365278432128697, 9.685095550277071],
              [39.364254352704798, 9.684026293034091],
              [39.3635109968072, 9.683359942595191],
              [39.361896275084, 9.68226854184363],
              [39.3613123701493, 9.681429511268091],
              [39.360407317500602, 9.680601547642709],
              [39.359637012144397, 9.67935959837782],
              [39.358619670085098, 9.6785294158274],
              [39.358981241987003, 9.67736937064088],
              [39.361195589162399, 9.67806894035491],
              [39.363016923400899, 9.678181845457059],
              [39.364952792838203, 9.678385517310049],
              [39.366679803971898, 9.678739728934209],
              [39.368139566308599, 9.678381089662389],
              [39.367683671301897, 9.677300741887951],
              [39.367032392720901, 9.676472768086549],
              [39.366300265764401, 9.675806402663101],
              [39.365588350901703, 9.67479246204198],
              [39.365069573825103, 9.672618456775419],
              [39.366879679122597, 9.672904044827529],
              [39.368074438450499, 9.673178563346591],
              [39.369536446575403, 9.673499572620811],
              [39.372018042547801, 9.671228148684159],
              [39.371272440862001, 9.66994852883138],
              [39.370538068117199, 9.66868661521104],
              [39.369543183940003, 9.66597237794093],
              [39.369291655660497, 9.6639931405448],
              [39.368844743806598, 9.66234598108359],
              [39.3683349498829, 9.661010978992159],
              [39.366854975452299, 9.660187392971331],
              [39.365756785017503, 9.66063682322712],
              [39.364750671899301, 9.661110606196759],
              [39.363360528997099, 9.661548965546629],
              [39.362084921293601, 9.66167737373291],
              [39.360878933024701, 9.661323144134659],
              [39.358729713707497, 9.6598730128298],
              [39.357781991082703, 9.657504082603779],
              [39.357335079228903, 9.655593428605769],
              [39.357063338855397, 9.65443109033728],
              [39.356587231754801, 9.650910841398289],
              [39.356616427001597, 9.64929904218671],
              [39.357283426099997, 9.647802364587349],
              [39.357683176401501, 9.646132985578131],
              [39.357923475740002, 9.645083530881269],
              [39.3575327085914, 9.64317502050773],
              [39.356798335846598, 9.641806731480299],
              [39.356295279287501, 9.640896749781611],
              [39.355632771765499, 9.63994248409788],
              [39.354898399020698, 9.63870038481384],
              [39.354036016347898, 9.63768854654468],
              [39.353532959788801, 9.636619138686189],
              [39.3533443135792, 9.635388097700551],
              [39.3532342699569, 9.634099485239769],
              [39.353335330426297, 9.632799797241979],
              [39.353126472122803, 9.629967921397069],
              [39.3524954056357, 9.628103608619449],
              [39.352212436321203, 9.627091738587159],
              [39.352093409546001, 9.625997941313679],
              [39.352088917969603, 9.624731434783129],
              [39.352095655334203, 9.62363762986792],
              [39.352185486862602, 9.62262574645953],
              [39.352052985358199, 9.621474365591959],
              [39.351770016043702, 9.62042704843468],
              [39.351040134875397, 9.617054797996341],
              [39.353056852688198, 9.61711236788549],
              [39.35506234156, 9.617510928387791],
              [39.356131336748099, 9.61842982997784],
              [39.357234018759399, 9.618509541805279],
              [39.358334454982398, 9.61857818253052],
              [39.360721727849999, 9.61862025264556],
              [39.361718857815397, 9.61873317766528],
              [39.362554291029603, 9.61935537170536],
              [39.3636726935583, 9.62048240354537],
              [39.364880927615502, 9.62141236804935],
              [39.366131831648602, 9.6216869285089],
              [39.3671671400135, 9.622526107885641],
              [39.368040751627397, 9.62385904815279],
              [39.369215298861299, 9.624997135847419],
              [39.370585229669601, 9.625524109006721],
              [39.371629521187401, 9.62584516367982],
              [39.3725817353886, 9.626363278853599],
              [39.374295271793002, 9.628938343760231],
              [39.375559650555402, 9.62914425953872],
              [39.376439999533901, 9.62956937558744],
              [39.378110865962299, 9.629941351691061],
              [39.3795257125348, 9.63025575974482],
              [39.380639623487099, 9.630667589569549],
              [39.381683915004899, 9.630747298509871],
              [39.382692273911303, 9.63087350429355],
              [39.385836377405802, 9.63080929433932],
              [39.3878373747011, 9.625482039751089],
              [39.3879608930527, 9.62433509697393],
              [39.388322464954598, 9.621926061630649],
              [39.388677299491803, 9.6207791067841],
              [39.388686282644599, 9.619517009115579],
              [39.388704248950297, 9.616587595841009],
              [39.3888884035836, 9.61486713494582],
              [39.388841242031098, 9.613144451046621],
              [39.388560518504903, 9.611877896362721],
              [39.387698135832103, 9.610839406554501],
              [39.3865415549038, 9.61060026559278],
              [39.385921717357803, 9.61099440523569],
              [39.385378236610897, 9.611339830612661],
              [39.384163265189102, 9.611446115273081],
              [39.382371126197299, 9.6109744768378],
              [39.382553035042299, 9.60982527057404],
              [39.382907869579498, 9.608680488970609],
              [39.383199822046898, 9.608109203859399],
              [39.3837275822763, 9.60696441645149],
              [39.383909491121301, 9.6057576247601],
              [39.384091399966401, 9.604553043075439],
              [39.384383352433701, 9.60398175099882],
              [39.384920095816, 9.60163235755982],
              [39.384753907488403, 9.600480905248389],
              [39.384351911398802, 9.59990517762486],
              [39.383193084682198, 9.599838747451489],
              [39.381174121081202, 9.599021655253511],
              [39.380722717650897, 9.597121742199439],
              [39.379923217048002, 9.595394539269879],
              [39.378768881908002, 9.59486973355917],
              [39.377610055191397, 9.594860876071859],
              [39.375874060904898, 9.594907377877719],
              [39.374142558194698, 9.59426078076666],
              [39.373565390624698, 9.593857764135111],
              [39.372988223054598, 9.59345031826226],
              [39.372503132801199, 9.592799289601221],
              [39.371845116855603, 9.591145139726549],
              [39.372489658071999, 9.59000029499424],
              [39.372840001032799, 9.58942897838234],
              [39.373774248928299, 9.588286342271219],
              [39.372869196279503, 9.58489162739918],
              [39.372071941464803, 9.58304921084831],
              [39.372539065412603, 9.582480096982909],
              [39.373697892129101, 9.58225865164116],
              [39.374969008256102, 9.58171832440097],
              [39.3759594008569, 9.5812975771838],
              [39.3764265248046, 9.580726245917109],
              [39.377008183951098, 9.580557946600941],
              [39.378524090992997, 9.578438696755709],
              [39.379236005855702, 9.57619763655239],
              [39.3788250266132, 9.57416693978243],
              [39.378744178237604, 9.5731770537495],
              [39.377985101822603, 9.571657894183049],
              [39.3777358193312, 9.570198520214641],
              [39.377336069029802, 9.568577478353371],
              [39.376965513975101, 9.56802827116057],
              [39.376496144239098, 9.567357262781091],
              [39.376035757655998, 9.56672832963207],
              [39.375424903262797, 9.565917801393059],
              [39.374034760360601, 9.564848166906931],
              [39.372184230875398, 9.56374752501009],
              [39.371306127685102, 9.563408694896131],
              [39.370814300067103, 9.56304771865168],
              [39.370365142425001, 9.562607017073059],
              [39.369684668597301, 9.56108781026912],
              [39.369675685444399, 9.559446793547551],
              [39.369635261256697, 9.558197755272101],
              [39.369745304878997, 9.55668738726162],
              [39.368714488090397, 9.55575724526893],
              [39.368325966730097, 9.5552877440596],
              [39.366814551264497, 9.55380836808942],
              [39.365803946569898, 9.55296680403991],
              [39.364755163475699, 9.55190820209714],
              [39.363365020573497, 9.55020734522178],
              [39.3633942158202, 9.549328122907379],
              [39.3634458689491, 9.54869694176068],
              [39.363055101800498, 9.548127770390391],
              [39.362814802461997, 9.54715774072724],
              [39.362814802461997, 9.54630730148995],
              [39.363475064195796, 9.54550779808352],
              [39.3636345151588, 9.5443384379222],
              [39.362084921293601, 9.543837915111601],
              [39.360575751616302, 9.544387161342369],
              [39.358794841565597, 9.54476808965995],
              [39.358294030794703, 9.54532840786483],
              [39.357804448964799, 9.5458377872515],
              [39.355994343667298, 9.54730833912091],
              [39.355275691439999, 9.5476781907758],
              [39.354004575312999, 9.54834702328767],
              [39.3535060103303, 9.54888740356326],
              [39.353205074710097, 9.549527443026459],
              [39.351624039810098, 9.5502184185362],
              [39.3508739465478, 9.5498374963132],
              [39.3486955319838, 9.54828722705696],
              [39.346674322594602, 9.54690748148462],
              [39.341794224813597, 9.545806781571461],
              [39.340963283175803, 9.54494969487075],
              [39.339218305736402, 9.543155785495831],
              [39.338744444424002, 9.542566672456211],
              [39.338883683292998, 9.541297639780121],
              [39.337895536480502, 9.540088400059499],
              [39.336844507598101, 9.539107273837651],
              [39.334005831300303, 9.535707638567221],
              [39.333725107774001, 9.53213743136924],
              [39.334154053322202, 9.53168783066771],
              [39.3345740157175, 9.531258162448641],
              [39.334834527149901, 9.530538356230149],
              [39.334704271433701, 9.52974767503418],
              [39.330805583100599, 9.527127561509159],
              [39.329624298501997, 9.52659822083192],
              [39.328584498560602, 9.525907197457579],
              [39.3256649738872, 9.525337988050691],
              [39.324975516906697, 9.52564806333165],
              [39.3241153800221, 9.525827463901321],
              [39.323223802102603, 9.525927130843749],
              [39.322044763292197, 9.525938204946661],
              [39.320975768104098, 9.52575880443518],
              [39.319444140544697, 9.525608196525591],
              [39.317784503057297, 9.526957020878511],
              [39.318094421830303, 9.52773884930353],
              [39.318754683564102, 9.52881745951729],
              [39.3188445150926, 9.529408811103719],
              [39.318714259376399, 9.53036781730361],
              [39.317703654681701, 9.531107556965461],
              [39.316953561419503, 9.53132682080863],
              [39.315554435364497, 9.532407634461491],
              [39.315035658287897, 9.53397790885251],
              [39.314514635423102, 9.53516723739615],
              [39.314114885121697, 9.535997771629591],
              [39.313364791859399, 9.536956759308669],
              [39.312585503350398, 9.537556955768149],
              [39.311453626092501, 9.537858160973339],
              [39.310714761771301, 9.53747722493401],
              [39.309895049074498, 9.536547030491651],
              [39.309724369170503, 9.535787369815059],
              [39.3094750866792, 9.53475750642939],
              [39.309205592093903, 9.533656767287111],
              [39.309075336377802, 9.53290817405067],
              [39.309075336377802, 9.532248170367341],
              [39.309124743718399, 9.531428700930251],
              [39.309443645644201, 9.5300068060481],
              [39.309275211528501, 9.5290677320366],
              [39.307395486796402, 9.528737726640729],
              [39.306723996121598, 9.528108722183809],
              [39.305655000933498, 9.525687930133049],
              [39.305533728370101, 9.524658036270321],
              [39.304893678730203, 9.523346855073241],
              [39.3042042217496, 9.522976977034689],
              [39.303584384203603, 9.522666899328449],
              [39.3027646715068, 9.522558372064751],
              [39.301533979567601, 9.52254729785224],
              [39.3005143917201, 9.52271784068523],
              [39.298863737385503, 9.5222770869555],
              [39.298863737385503, 9.5214376599374],
              [39.299135477759002, 9.51960818915782],
              [39.302144833960803, 9.51287715848304],
              [39.302744459412899, 9.5119181031803],
              [39.302863486188102, 9.510708759403011],
              [39.305385506348202, 9.50807742520098],
              [39.306463484689203, 9.50729776673534],
              [39.307644769287798, 9.50609726641923],
              [39.307835661285701, 9.505428351131609],
              [39.307175399551802, 9.504688555880129],
              [39.304714015673298, 9.50442719070835],
              [39.303404721146698, 9.50421676945045],
              [39.3021942413014, 9.503787066690339],
              [39.300624435342399, 9.50324882999773],
              [39.299544211213203, 9.5028279859036],
              [39.298374155555699, 9.50217678402235],
              [39.2952435267905, 9.49916883544763],
              [39.292943839663202, 9.49709780983927],
              [39.290823815592603, 9.493728767094449],
              [39.292593496702402, 9.491896933104769],
              [39.2933435899646, 9.49173745011749],
              [39.294643901338397, 9.49144727949191],
              [39.295804973843097, 9.49110837761053],
              [39.297224311991997, 9.490448293245541],
              [39.300045021984097, 9.488997432203631],
              [39.301114017172203, 9.487677254072221],
              [39.301965170903898, 9.486647245791611],
              [39.302894927223001, 9.48478879291566],
              [39.303685444673, 9.483346768310049],
              [39.304334477465801, 9.482017708005261],
              [39.305055375481302, 9.48044719510785],
              [39.304085194974498, 9.47893869851822],
              [39.304094178127301, 9.477197606105049],
              [39.304363672712498, 9.47548751691847],
              [39.305223809597102, 9.47442867504294],
              [39.3059739028593, 9.47297774624926],
              [39.306463484689203, 9.47202743994559],
              [39.3078738396852, 9.469347074664901],
              [39.309333602021901, 9.46783852930465],
              [39.310173526812598, 9.4671784001766],
              [39.311294175129497, 9.466307825259801],
              [39.313263731390002, 9.4666179539818],
              [39.314705527420998, 9.466888208782731],
              [39.316513386930303, 9.46746859132535],
              [39.322314257877501, 9.46923853050628],
              [39.324825049096603, 9.47069833580932],
              [39.327695166429301, 9.471896744933559],
              [39.330545071668197, 9.47279831798773],
              [39.333204084909198, 9.47343849987405],
              [39.334704271433701, 9.4737973556343],
              [39.337114002183299, 9.47490714792669],
              [39.337785492858202, 9.47497803274165],
              [39.338144818971898, 9.4749470206369],
              [39.338564781367197, 9.47490714792669],
              [39.339555173967902, 9.47466791156817],
              [39.340105392079401, 9.474198298972199],
              [39.341365279265403, 9.47313723795858],
              [39.3430743240935, 9.472497055510591],
              [39.3436447542989, 9.47228882982045],
              [39.344835022050297, 9.47191668146403],
              [39.345854609897799, 9.47159769683755],
              [39.348035270250001, 9.47135845817326],
              [39.3506336472093, 9.47200750342156],
              [39.352425786201202, 9.47247711901381],
              [39.353975380066302, 9.47343849987405],
              [39.355304886686802, 9.4742270959895],
              [39.357505759132799, 9.474997969093209],
              [39.3611439360335, 9.475017905443631],
              [39.361525720029299, 9.474898287323789],
              [39.362534078935703, 9.474548293326389],
              [39.363084297047202, 9.473928049922961],
              [39.364633890912302, 9.472536928500681],
              [39.365435637303399, 9.4721581349079],
              [39.367703883395798, 9.471077131012221],
              [39.369354537730402, 9.46991859232222],
              [39.371124218840102, 9.468777771246989],
              [39.373623781118198, 9.46763694638328],
              [39.375123967642601, 9.46701669049879],
              [39.376395083769701, 9.46647839609154],
              [39.377594334674001, 9.46591794875504],
              [39.378315232689502, 9.465567945606921],
              [39.3797457997794, 9.465058446716199],
              [39.380015294364703, 9.464517934023821],
              [39.379195581667901, 9.46374703740169],
              [39.3772237796193, 9.46333722096476],
              [39.375204816018197, 9.462028020317071],
              [39.373695646340899, 9.45992797231737],
              [39.373154411382203, 9.458297546460811],
              [39.373105004041598, 9.455716761472351],
              [39.3730353846071, 9.453368562600589],
              [39.372914112043702, 9.4521169217491],
              [39.372714236893003, 9.4509671803893],
              [39.372305503438703, 9.449677870481249],
              [39.371625029611003, 9.44768851341785],
              [39.371845116855603, 9.445557363143729],
              [39.3733138623451, 9.445597239257459],
              [39.374504130096597, 9.445568439842461],
              [39.376693773601602, 9.445548501784501],
              [39.378804814519299, 9.44620867242322],
              [39.380114109045898, 9.446496665929541],
              [39.381263952609601, 9.44705714490906],
              [39.383725336488098, 9.448217975941461],
              [39.385063826261401, 9.448576858025859],
              [39.386144050390598, 9.448798389989999],
              [39.3878957651946, 9.4484882452003],
              [39.389755277832698, 9.4508076783944],
              [39.390345920131999, 9.451237447489699],
              [39.392245856957999, 9.453957829106679],
              [39.393074552807597, 9.45458696967599],
              [39.3937640097881, 9.45526706047632],
              [39.394354652087401, 9.455847462636809],
              [39.396083909009398, 9.455517386719061],
              [39.401374986032799, 9.451328274910219],
              [39.4035938247846, 9.45086749165114],
              [39.405004179780697, 9.45032695665714],
              [39.407193823285702, 9.44965793266125],
              [39.410005550125, 9.448317665446879],
              [39.412455705062399, 9.44661850926355],
              [39.415815404225, 9.44316700322627],
              [39.416704736356301, 9.44311826540944],
              [39.417695128957099, 9.44202830879507],
              [39.419244722822199, 9.44151655968289],
              [39.4198353651215, 9.44121748517451],
              [39.420655077818203, 9.44061712000744],
              [39.422534802550302, 9.438698602904481],
              [39.4238553260179, 9.43756653988088],
              [39.424724446055301, 9.436888629519361],
              [39.425425131976901, 9.43646770411419],
              [39.426184208392002, 9.435938223532579],
              [39.427533927106403, 9.43454694850532],
              [39.429013901536997, 9.434017464973969],
              [39.430965491491698, 9.433246500051959],
              [39.43154490485, 9.43361647481297],
              [39.432454449075102, 9.43388675552521],
              [39.4341253155036, 9.433767123104939],
              [39.4354346100302, 9.434068419491419],
              [39.436575470440999, 9.434327622789731],
              [39.438154259552903, 9.434387438907811],
              [39.4396948702652, 9.434176974742631],
              [39.442234856730998, 9.434008603318],
              [39.444455941271002, 9.433707306879249],
              [39.445805659985403, 9.433718383958871],
              [39.447025122983597, 9.43339714850555],
              [39.448285010169499, 9.433067051072831],
              [39.449246207523601, 9.432816708513849],
              [39.449866045069598, 9.431737796468051],
              [39.450494865768498, 9.43068768170812],
              [39.450144522807697, 9.430118314351921],
              [39.449594304696099, 9.42966857993936],
              [39.448684760470996, 9.42780760372424],
              [39.449434853733202, 9.42688818915172],
              [39.449735789353397, 9.425937755609061],
              [39.449645957824998, 9.424767987655789],
              [39.449556126296599, 9.42400807945593],
              [39.448745396752599, 9.42215815016897],
              [39.448815016187197, 9.41997810101714],
              [39.448945271903398, 9.41891687292331],
              [39.447123937664799, 9.41863771843628],
              [39.445554131705798, 9.417638520764619],
              [39.443544151257598, 9.415598021641751],
              [39.443115205709397, 9.414237682198181],
              [39.442535792351201, 9.413267273915309],
              [39.443865298971701, 9.410847887975629],
              [39.4454845122713, 9.41033830819203],
              [39.446205410286801, 9.41021645204555],
              [39.4465355411537, 9.40912860708911],
              [39.448145771300503, 9.40951854783366],
              [39.449425870580399, 9.4095584281123],
              [39.449205783335799, 9.40835758659521],
              [39.448774591999403, 9.40769734354148],
              [39.448615141036498, 9.405767565510301],
              [39.450474653674597, 9.403627293930381],
              [39.4524352267822, 9.403197465536079],
              [39.454025244835101, 9.40394855707223],
              [39.454254315232497, 9.4032373465436],
              [39.454604658193297, 9.401296465512811],
              [39.454254315232497, 9.40013769231026],
              [39.453865793872097, 9.399027659275131],
              [39.4535244340642, 9.397476708953089],
              [39.452495863063902, 9.39453653120073],
              [39.451945644952303, 9.39326695162816],
              [39.450564485203003, 9.39190652437299],
              [39.448904847715603, 9.390266914392219],
              [39.447665172623502, 9.38904828032563],
              [39.447144149758699, 9.38761693374876],
              [39.445643963234197, 9.384716555454689],
              [39.445596801681802, 9.38306804849595],
              [39.440462929833103, 9.381377434428661],
              [39.425914713806797, 9.37940319446378],
              [39.420387829021202, 9.371439647826371],
              [39.411862816974903, 9.374437632157621],
              [39.403845353064099, 9.380424660091061],
              [39.394828513399801, 9.391891014585211],
              [39.376260336477102, 9.383941049125211],
              [39.374748921011502, 9.37697027723345],
              [39.371234262462401, 9.374481948065871],
              [39.3566838006479, 9.369017753921909],
              [39.346660847865301, 9.37849251432913],
              [39.337134214277199, 9.38049556431795],
              [39.3250945436818, 9.380506643102089],
              [39.320072961243604, 9.374034357132579],
              [39.319563167319799, 9.36556768924685],
              [39.306521875182597, 9.36607733486923],
              [39.303002725057098, 9.35661553122207],
              [39.292966297545298, 9.349152268858949],
              [39.291445898926902, 9.33171890055401],
              [39.285415957582202, 9.315782793926809],
              [39.278258630556003, 9.31223690572822],
              [39.2643931341456, 9.32417754071753],
              [39.2661830273493, 9.288438633078719],
              [39.282916395304198, 9.278108110197239],
              [39.266147094737903, 9.24447473305645],
              [39.260402368495903, 9.250311044085841],
              [39.257563692198097, 9.25610071082219],
              [39.233639310393798, 9.293598239534051],
              [39.207165958970798, 9.29192713820605],
              [39.177721429745603, 9.271059863732219],
              [39.1940640305519, 9.25239905892778],
              [39.199721171053703, 9.24995639065205],
              [39.213660778475003, 9.228109050320739],
              [39.1885663410131, 9.20924645391124],
              [39.201589666844598, 9.190309690983231],
              [39.214615238464397, 9.17336506301578],
              [39.233655030911301, 9.157911779019139],
              [39.239662514373798, 9.14844215338745],
              [39.249499066734899, 9.135573168976419],
              [39.294104912167903, 9.149255872633431],
              [39.314433787047498, 9.14891885562383],
              [39.323147445303498, 9.148772519191301],
              [39.320142580678102, 9.14020951589128],
              [39.3288225521109, 9.137194012563381],
              [39.335023173359502, 9.121009636346949],
              [39.337266715781602, 9.11408021942345],
              [39.340103146291199, 9.10829044989576],
              [39.3429058899777, 9.09963112128135],
              [39.345744566275499, 9.093841117775121],
              [39.348580996785103, 9.088051020612991],
              [39.348446249492497, 9.076565905261219],
              [39.354186484157999, 9.07072895724666],
              [39.356989227844501, 9.06206650304488],
              [39.347002207673299, 9.064162277256489],
              [39.3152782034146, 9.06021467121205],
              [39.2967279927975, 9.05325524662506],
              [39.293478337257199, 9.032644535731871],
              [39.293179647425298, 9.03075928324216],
              [39.2927664223946, 9.02814209285442],
              [39.292380146822403, 9.02568014218517],
              [39.292182517459899, 9.024433634627499],
              [39.291975904944501, 9.02312945484986],
              [39.2918074708288, 9.02204928877787],
              [39.291762555064601, 9.021772038237909],
              [39.291758063488103, 9.02173655015342],
              [39.291740097182497, 9.021632303885029],
              [39.291690689841801, 9.02131734689126],
              [39.291616578830897, 9.02084269287432],
              [39.2914503905033, 9.01978470013217],
              [39.288167048139897, 8.998965789028169],
              [39.269612345946399, 8.98054806120637],
              [39.258576542680999, 8.96262187563115],
              [39.265358823076099, 8.92237180691713],
              [39.260132873910699, 8.91154259731324],
              [39.257985900381698, 8.908465277168309],
              [39.240992020994298, 8.88890915678018],
              [39.237468379292402, 8.862011684871661],
              [39.260501183177198, 8.850539387052169],
              [39.265504799309703, 8.843063339339411],
              [39.244964820338403, 8.83809475706745],
              [39.242961577254803, 8.83510780577812],
              [39.243832943080399, 8.827666951906259],
              [39.254464504467897, 8.813182044056459],
              [39.258965064041298, 8.8037145061554],
              [39.266470488240202, 8.79374514435386],
              [39.271471858584498, 8.785273639147499],
              [39.272468988549903, 8.78078812401724],
              [39.272960816167902, 8.76684073302914],
              [39.261929504478999, 8.74692195675974],
              [39.268424323983098, 8.72599654986934],
              [39.272426318573899, 8.723004256161641],
              [39.285793250001603, 8.717778783693911],
              [39.298960306278602, 8.718500231778],
              [39.303968413987498, 8.719989740184291],
              [39.312902159488097, 8.7316769583556],
              [39.324519621900002, 8.746873124039031],
              [39.331524235327898, 8.740888847618001],
              [39.342025540999302, 8.726931081787461],
              [39.3610473671405, 8.72093760607788],
              [39.377077803385603, 8.730884503276471],
              [39.374598453201401, 8.752804033085321],
              [39.373610306388898, 8.766754170564541],
              [39.374625402660001, 8.779705022673101],
              [39.378142306997297, 8.791157327574529],
              [39.386162016696296, 8.798621110197059],
              [39.405462320575602, 8.809948549816189],
              [39.417728815780201, 8.814529140034869],
              [39.424225881072601, 8.803064242078671],
              [39.431221511347701, 8.79010532827847],
              [39.439708344994401, 8.767677502480449],
              [39.449203537547596, 8.752224708185061],
              [39.470221869407702, 8.74173899363513],
              [39.492230593868697, 8.72527067667016],
              [39.500728656456403, 8.71479974331656],
              [39.514237072541398, 8.73244943395534],
              [39.515229710930299, 8.73374798847064],
              [39.519231705521101, 8.738975457059439],
              [39.533752972088898, 8.757946889300349],
              [39.551764193535497, 8.768736223992409],
              [39.556916031689902, 8.79143253364852],
              [39.561964563586699, 8.817826952292901],
              [39.591478712246399, 8.79685893422965],
              [39.598984136445203, 8.79286182642201],
              [39.616999849468201, 8.786856096405421],
              [39.6340453819844, 8.798783123435481],
              [39.650078064017698, 8.802740219279331],
              [39.662115488824902, 8.813179824783891],
              [39.679145300823599, 8.815638770595051],
              [39.716706108640899, 8.81606708709978],
              [39.733255321962602, 8.826226697715571],
              [39.731310469372403, 8.84417732223622],
              [39.736862057828297, 8.85719646999409],
              [39.738474533763302, 8.872047987501119],
              [39.747989938410299, 8.9102646362174],
              [39.748270661936601, 8.946729143092339],
              [39.747091623126202, 8.97198326602857],
              [39.746653694425198, 8.988906360880341],
              [39.746489751885903, 9.009745813895339],
              [39.746139408925004, 9.015246608038501],
              [39.741133547004303, 9.023011900998821],
              [39.740219511202703, 9.0395289504646],
              [39.742941406513602, 9.045874294791791],
              [39.744057563254103, 9.049103479852731],
              [39.744686383953002, 9.05109952755191],
              [39.7455981739664, 9.053725422319459],
              [39.746691872824798, 9.056561988110669],
              [39.7471410304668, 9.0595315978988],
              [39.747376838228902, 9.062310456227459],
              [39.747686757002, 9.065322155505021],
              [39.742734793998203, 9.0907365196787],
              [39.742126185393197, 9.091754387306599],
              [39.763896856303901, 9.09511621276466],
              [39.780225982380998, 9.10433889496546],
              [39.786332280524803, 9.108432368003159],
              [39.793781560018303, 9.11272979899665],
              [39.797109818145998, 9.12145311445005],
              [39.800422355756197, 9.1246306189361],
              [39.803456415628297, 9.127446666945371],
              [39.807058659917601, 9.13416739715859],
              [39.808365708655998, 9.136697338978371],
              [39.817398268837799, 9.136300443071161],
              [39.824045801940301, 9.13679711720952],
              [39.833287220425703, 9.13746673928207],
              [39.839056650338001, 9.138366957700621],
              [39.848987525803899, 9.138116404526899],
              [39.864465498149301, 9.141067599304071],
              [39.877637046002697, 9.144717202292311],
              [39.898397112218703, 9.138925712816031],
              [39.908997232571302, 9.131785998176021],
              [39.909677706399002, 9.126956631842461],
              [39.910355934438499, 9.1235152803893],
              [39.915586375180297, 9.09732710583126],
              [39.918725987098298, 9.06839592563008],
              [39.928376139038001, 9.05413571664478],
              [39.942437019022599, 9.047677407880419],
              [39.9518468716238, 9.029976346075371],
              [39.9490261616317, 9.013456641073811],
              [39.947606823482701, 9.003586155913119],
              [39.9681468024541, 9.003546229807849],
              [39.999536184269502, 9.00714621590989],
              [40.021906480632303, 9.00226637398659],
              [40.034797304959397, 8.994436308238781],
              [40.047436601007, 8.97787505049275],
              [40.051077023695797, 8.961346312051241],
              [40.0438568145997, 8.9423054979773],
              [40.040566734871597, 8.92303517153695],
              [40.036596181315801, 8.9065150370742],
              [40.019247467391303, 8.889115506594541],
              [40.010897626825397, 8.87076545073848],
              [40.010385587113397, 8.85607586946664],
              [40.024197184606798, 8.845036107547489],
              [40.040326435533103, 8.841094998772959],
              [40.051166855224302, 8.8406156718564],
              [40.064477641946702, 8.843436146739799],
              [40.069386934974403, 8.844476898735079],
              [40.080946006892802, 8.85133602432329],
              [40.089956109192499, 8.858656574071951],
              [40.093486488259103, 8.86295696849497],
              [40.105007381778002, 8.876985046526469],
              [40.120516795158302, 8.89576523353257],
              [40.140888340013902, 8.91889522412602],
              [40.152227324687701, 8.93194499547843],
              [40.160446909537399, 8.946405247129579],
              [40.162897064474798, 8.950735667942739],
              [40.169867991079599, 8.96448529959285],
              [40.174377533805902, 8.96700534345754],
              [40.187436792248803, 8.97430582953722],
              [40.199988502556103, 8.97876457740743],
              [40.2040578707932, 8.980215322357321],
              [40.222307145790097, 8.987735145790269],
              [40.237787363923701, 8.99732657603295],
              [40.242238516156497, 9.013146113064209],
              [40.246009194561601, 9.03539476873638],
              [40.244248496604698, 9.055586158776659],
              [40.245479188543896, 9.07485607964067],
              [40.254289415692902, 9.086066266415219],
              [40.2714180423729, 9.101385178917591],
              [40.3005885854365, 9.128125175976169],
              [40.326999054789603, 9.15464588417611],
              [40.349519818962399, 9.187134990491289],
              [40.369509579822299, 9.21825558090851],
              [40.392288609639401, 9.255094397337629],
              [40.408098958639897, 9.28117560467955],
              [40.425750853972801, 9.304265174460291],
              [40.449650532106801, 9.329615845986741],
              [40.471001240622101, 9.35474529880101],
              [40.498799607089197, 9.37503368064967],
              [40.528390112548102, 9.38544552982261],
              [40.5662406270445, 9.387785327770541],
              [40.605941671026201, 9.391033543808611],
              [40.635952138880398, 9.395934614315401],
              [40.659530669300302, 9.404294191567541],
              [40.675520681357597, 9.417503374072719],
              [40.683800902488997, 9.430734205847431],
              [40.688101586911699, 9.437604201528529],
              [40.689642197624003, 9.439084079484649],
              [40.692790792694801, 9.442103631111051],
              [40.698542256301401, 9.447633130261639],
              [40.707071759924098, 9.446213103094371],
              [40.719511180821002, 9.443864839393051],
              [40.7485829092033, 9.44485288437315],
              [40.783201734465003, 9.44787460075743],
              [40.815743205632302, 9.452723912748271],
              [40.842342321195098, 9.46357424999055],
              [40.862161402150903, 9.46300272178112],
              [40.889973243347299, 9.44863445635025],
              [40.9164016790061, 9.42289369210253],
              [40.918602551452203, 9.420753526581169],
              [40.927493626976698, 9.405122826707601],
              [40.913832497293498, 9.39718424325652],
              [40.8911927063455, 9.389524655971011],
              [40.858202077536198, 9.385354685076161],
              [40.843112626551203, 9.389223320755169],
              [40.8280411418719, 9.39308305053631],
              [40.797621940563403, 9.39554465826153],
              [40.788802730261501, 9.386883532515521],
              [40.792602603913402, 9.36738468326385],
              [40.800301165898297, 9.34511477220123],
              [40.808491555501199, 9.32674379313187],
              [40.812540711644402, 9.31091383697521],
              [40.824730850049903, 9.305344482605021],
              [40.844082807058001, 9.304783774682759],
              [40.8740820459712, 9.30943341239894],
              [40.902942670261801, 9.31820284195517],
              [40.926952392018102, 9.323113803613911],
              [40.953533541275199, 9.33556375677167],
              [40.970693608989997, 9.35149231134338],
              [40.976002652319202, 9.352044080431741],
              [40.976431597867403, 9.35208396731988],
              [40.996062032613601, 9.354093817373061],
              [41.014053041966299, 9.3571938830507],
              [41.030063266117502, 9.372443410430369],
              [41.056462506529598, 9.391053485013581],
              [41.083524254463697, 9.4044027561775],
              [41.114693549034399, 9.41404271454274],
              [41.145422669115902, 9.42461290448556],
              [41.170434012413999, 9.44139249917759],
              [41.194785093978297, 9.46228277210284],
              [41.211273671018297, 9.48076174128505],
              [41.2114645630162, 9.480972176946841],
              [41.211603801885197, 9.48114274049378],
              [41.214943288953897, 9.486082401227289],
              [41.219863810922703, 9.490703023939719],
              [41.228604418637197, 9.494293599057761],
              [41.2325233190642, 9.497042434651091],
              [41.239294370518202, 9.498783426282969],
              [41.249914702964702, 9.500422519729719],
              [41.254545518254297, 9.50031177041968],
              [41.260483382282402, 9.50017222623803],
              [41.267535157262699, 9.49935267989636],
              [41.279653430445499, 9.501523365923539],
              [41.308675751487201, 9.50306277267256],
              [41.336474117954303, 9.494621422672131],
              [41.359015094221, 9.490111605428829],
              [41.393065735065598, 9.485741278961321],
              [41.432596099143304, 9.47584194024769],
              [41.458856100686297, 9.466861626352729],
              [41.479014295661898, 9.460012151703371],
              [41.4909461684232, 9.457411442209869],
              [41.506635244860398, 9.4704923242078],
              [41.522045843559503, 9.485311553026399],
              [41.540584825235499, 9.49957196410972],
              [41.564136406196901, 9.50693230121794],
              [41.597706448364399, 9.51140202842849],
              [41.632015354853202, 9.51318060036272],
              [41.633416726696403, 9.513441958846141],
              [41.642577296806202, 9.51513192455584],
              [41.6541363687246, 9.481220269350731],
              [41.670175788122499, 9.47062080457594],
              [41.702256872706698, 9.466640106022661],
              [41.721925485852502, 9.4675217560894],
              [41.734596222934996, 9.47147143200772],
              [41.754507381207503, 9.48456063888873],
              [41.772976743449, 9.491041926221939],
              [41.792526329819601, 9.48950025038984],
              [41.815177349708698, 9.476730212108681],
              [41.8386570654474, 9.47789980347191],
              [41.859686626248603, 9.48800065388329],
              [41.882398282419402, 9.481171536928411],
              [41.908276499966597, 9.47388153162253],
              [41.9133564728983, 9.472450537016289],
              [41.9387271423101, 9.47953013629744],
              [41.952156955807702, 9.48808925651802],
              [41.956776542156298, 9.490109390376309],
              [41.981796868607198, 9.508819428056359],
              [41.983207223603301, 9.50813944341173],
              [41.989987258210199, 9.50661113422885],
              [41.995338971515302, 9.506449443147829],
              [42.002307652331901, 9.50770088582296],
              [42.019076952898203, 9.508159377834209],
              [42.031698282640001, 9.504389536387491],
              [42.043888421045502, 9.49830055671735],
              [42.052116989048102, 9.49193901888072],
              [42.057798833220097, 9.48507010883699],
              [42.066997581729503, 9.47548087147754],
              [42.068187849480999, 9.47410969274998],
              [42.074478302258001, 9.472598953142731],
              [42.075158776085701, 9.475359038371259],
              [42.081038249620299, 9.48814906328351],
              [42.096237744227601, 9.50129965299972],
              [42.1096585745723, 9.50794895887152],
              [42.121848712977901, 9.51920951383803],
              [42.138878524976498, 9.53467998970463],
              [42.152577833059397, 9.556359623230451],
              [42.146107717225497, 9.57061928118239],
              [42.133389818590601, 9.582480096982909],
              [42.130027873639797, 9.592090685556],
              [42.127829246981896, 9.598359565550149],
              [42.124837857085801, 9.614510641953981],
              [42.142229240986303, 9.620278696693349],
              [42.159568971757999, 9.630669803707031],
              [42.180358233220801, 9.64432854141014],
              [42.200509690831801, 9.653129266714361],
              [42.220499451691602, 9.656149135579531],
              [42.240749723983903, 9.656629566765909],
              [42.256629692418898, 9.652429645137859],
              [42.2606002459747, 9.65070936691848],
              [42.262630438516901, 9.6568886008078],
              [42.271829187026199, 9.66693985328102],
              [42.2859192622577, 9.67612962326608],
              [42.302740215952802, 9.69111919048061],
              [42.311260736422703, 9.699079704528179],
              [42.318119373616902, 9.70564986405601],
              [42.328319743668096, 9.713368751403991],
              [42.334109385674203, 9.715978567234121],
              [42.338670581529399, 9.718008409919319],
              [42.346169268363496, 9.72134865858704],
              [42.355740817715798, 9.7178490334037],
              [42.376269567746199, 9.714619429044459],
              [42.376738937482102, 9.71330013001768],
              [42.395289148099202, 9.715440667926551],
              [42.42423735813, 9.71917495515943],
              [42.448514328683302, 9.721975089844619],
              [42.474659795027598, 9.72104097637359],
              [42.4989367655809, 9.72104097637359],
              [42.529751225614397, 9.72757750237083],
              [42.568037423023597, 9.736916123495311],
              [42.595114891475198, 9.748120353376709],
              [42.618459859921202, 9.758392411204181],
              [42.647405824163798, 9.77519974501095],
              [42.663788849157903, 9.77641256691391],
              [42.663764145487598, 9.776618392089791],
              [42.681023027883697, 9.77426578060402],
              [42.694095761055898, 9.768661939094949],
              [42.703431502646097, 9.758392411204181],
              [42.71370373792, 9.740650170781061],
              [42.730511216885901, 9.72104097637359],
              [42.750121439538198, 9.71076998327565],
              [42.779067403780701, 9.69863032570362],
              [42.790271641161901, 9.693027428807349],
              [42.822954596986399, 9.67435190610261],
              [42.853769057019903, 9.66221314529183],
              [42.897656250225602, 9.65007394661612],
              [42.907926239711301, 9.65007394661612],
              [42.917264227089703, 9.6575439339043],
              [42.925667966572597, 9.672485625040609],
              [42.935005953950999, 9.67995511473026],
              [42.9508791850214, 9.672485625040609],
              [42.963951918193601, 9.655677559600941],
              [42.977958899261203, 9.62859736466816],
              [42.979827395052197, 9.607121631199149],
              [42.968620911882802, 9.592181475532019],
              [42.943409693433999, 9.58751351062619],
              [42.938796844450003, 9.583412380286919],
              [42.937858104978098, 9.58356739151432],
              [42.920998972883403, 9.565102842089789],
              [42.908860487606802, 9.563235957845359],
              [42.8920530086409, 9.574439324023681],
              [42.879914523364199, 9.584712257958181],
              [42.864039046505603, 9.586579024164941],
              [42.840696323847801, 9.582843267030549],
              [42.813616609607998, 9.57257249106231],
              [42.801478124331297, 9.549228462804081],
              [42.787471143263701, 9.52308328932631],
              [42.776264660094299, 9.511878234772301],
              [42.747318695851803, 9.50160753510456],
              [42.736114458470603, 9.495071072119449],
              [42.7286427210949, 9.48293254506742],
              [42.714635740027298, 9.474528356948721],
              [42.700631004747798, 9.47172617679219],
              [42.681955029991002, 9.469858782379371],
              [42.666081798920601, 9.458654202777449],
              [42.655809563646699, 9.455854108457389],
              [42.640868334683603, 9.44744925811916],
              [42.617525612025702, 9.42690591282928],
              [42.603518630958099, 9.41663488915165],
              [42.584842656201303, 9.409166271839309],
              [42.567100929339901, 9.396091926008269],
              [42.552161946165, 9.382086474097351],
              [42.537431821293602, 9.372968556596531],
              [42.551560074924602, 9.34522778728917],
              [42.556269492801597, 9.321039506936691],
              [42.546929259635, 9.30521815718321],
              [42.551339987680002, 9.28655916991886],
              [42.561059759054203, 9.27028853760071],
              [42.5709704224262, 9.258168742487079],
              [42.579589757577402, 9.23748787874184],
              [42.583569294286001, 9.215369315731561],
              [42.595458497071299, 9.193577474474781],
              [42.614280448061898, 9.173948150805611],
              [42.624750312698303, 9.16601763135716],
              [42.634678942375999, 9.158508195135701],
              [42.635089921618501, 9.1439589039003],
              [42.625938334661498, 9.13206759832658],
              [42.619010078032701, 9.12641781418739],
              [42.610999351486598, 9.11987876468965],
              [42.607819315380802, 9.117018308622431],
              [42.599310023851999, 9.10935926925594],
              [42.599310023851999, 9.107538726480829],
              [42.599310023851999, 9.10464934418413],
              [42.599348202251598, 9.10463825671666],
              [42.599379643286497, 9.10194842695341],
              [42.619279572617998, 9.0892684789192],
              [42.644329094315701, 9.07296883074515],
              [42.6622684505395, 9.04894823123413],
              [42.673048233948997, 9.02843930017664],
              [42.673099887077797, 9.028348363633921],
              [42.680108992082097, 9.01500927711713],
              [42.703049718650298, 8.980898545797119],
              [42.730619014719998, 8.948009194864079],
              [42.761159488591801, 8.917697159845829],
              [42.783978942596697, 8.892827564582809],
              [42.805138759114101, 8.870017672046041],
              [42.828050290435598, 8.85577852142665],
              [42.849529008878903, 8.844277181096251],
              [42.859758574176801, 8.831847873665239],
              [42.865310162632603, 8.825097126165669],
              [42.8653393578794, 8.80433813718258],
              [42.858159572970997, 8.78667854193033],
              [42.856569554918202, 8.78275900523467],
              [42.843898817835701, 8.757909156043681],
              [42.837109800075901, 8.72781899593156],
              [42.831969190862502, 8.69521784486891],
              [42.838398882508599, 8.664378886893561],
              [42.859859634646199, 8.63628839111345],
              [42.8839996121187, 8.615838650636441],
              [42.884019824212601, 8.615827548413771],
              [42.898767915389698, 8.60265786246185],
              [42.9052986675052, 8.582208525193851],
              [42.907589371479702, 8.5490173669446],
              [42.912348196697302, 8.522088787888499],
              [42.910807585985097, 8.498767661080461],
              [42.897009463221004, 8.457739027183599],
              [42.898559057086104, 8.42915789398584],
              [42.8988285516714, 8.409088081457361],
              [42.884388133479099, 8.3984973595109],
              [42.858078724595501, 8.39167888776414],
              [42.8308979498862, 8.381138792390219],
              [42.7981678325093, 8.37005849796647],
              [42.779357110459898, 8.373249086310519],
              [42.7681483815023, 8.363159538962419],
              [42.753928050554698, 8.33710903596671],
              [42.7476465809305, 8.3230098434735],
              [42.747377086345203, 8.322418755123749],
              [42.734856817072803, 8.294358810481681],
              [42.719127316447903, 8.24564970916677],
              [42.7111368019956, 8.206748345488659],
              [42.701576481584397, 8.179598261169939],
              [42.678496516147099, 8.147308726859],
              [42.661336448432301, 8.13026813862909],
              [42.651385360872403, 8.12965897698724],
              [42.630607328350699, 8.140419214286389],
              [42.615955806066701, 8.15958906109282],
              [42.594755565361503, 8.197830411759901],
              [42.578076096323599, 8.22851997689099],
              [42.567006606235097, 8.255600038203699],
              [42.559977289136803, 8.29197872291085],
              [42.550057642611897, 8.350490021545379],
              [42.5387074289971, 8.39720876861935],
              [42.5345257713495, 8.428789120012841],
              [42.529746734038, 8.45482014330172],
              [42.520116794192198, 8.47869925112929],
              [42.502536764082002, 8.492068682625479],
              [42.323015191915403, 8.53676037957427],
              [42.307126240327499, 8.559630591329441],
              [42.294666607336801, 8.58603023014312],
              [42.274975536308901, 8.615090360098121],
              [42.2630571382768, 8.63593980003998],
              [42.239647041972702, 8.63986975717895],
              [42.232655903274001, 8.63502058381763],
              [42.2155968960286, 8.611191231735621],
              [42.098274674134302, 8.516911620431641],
              [42.069706002311101, 8.493961111472011],
              [42.041285552509798, 8.447451751358599],
              [42.025984997433, 8.42582114318423],
              [42.027033780527198, 8.323012065608481],
              [42.008768785012897, 8.294736600834179],
              [41.962584150468103, 8.22359006844122],
              [41.948384031614403, 8.200880136432669],
              [41.937453780394797, 8.143560526590811],
              [41.929793396809501, 8.101380854308481],
              [41.9263236540246, 8.08055173106426],
              [41.9254230929523, 8.03973082711582],
              [41.922141996377, 7.97809144525188],
              [41.918863145590002, 7.93591235679755],
              [41.917113676574203, 7.91286149156094],
              [41.915141874525503, 7.88333145713051],
              [41.913001638361102, 7.85380153988124],
              [41.912381800815098, 7.84438195746894],
              [41.957122393540601, 7.7747718995376],
              [41.942473117044898, 7.72745155408014],
              [41.929443053848701, 7.69700039196118],
              [41.904782053511397, 7.63591121808905],
              [41.888331654871003, 7.59111094309859],
              [41.878052682232401, 7.56260248274871],
              [41.867771463805703, 7.53816197978161],
              [41.8670820068251, 7.53276074956875],
              [41.872732409962197, 7.50845215454455],
              [41.882710446980603, 7.46547128836082],
              [41.893582307706701, 7.41867138106683],
              [41.896102082078599, 7.41119086136082],
              [41.965211722674098, 7.38410047453207],
              [42.070721098582197, 7.34274011580749],
              [42.111241855260602, 7.32634190570578],
              [42.133562744282798, 7.31732062810475],
              [42.145972969932899, 7.31322201208389],
              [42.154241962123201, 7.30912113089579],
              [42.156721312307397, 7.30830139564844],
              [42.174912196810801, 7.30010173289098],
              [42.198492973018901, 7.29094181233345],
              [42.2098319576927, 7.26399113548875],
              [42.209822974539897, 7.22689065742881],
              [42.208363212203203, 7.21759111036385],
              [42.2037121848197, 7.19590999174596],
              [42.203022727839098, 7.19268146396251],
              [42.204401641800203, 7.15921171028362],
              [42.204682365326498, 7.14102190390335],
              [42.186942884253398, 7.09638106092689],
              [42.157590432344797, 7.05131018648564],
              [42.162620997935797, 7.00710201358841],
              [42.147560742197598, 6.98061165352198],
              [42.139920570706103, 6.9672522213357],
              [42.134142157641001, 6.95415100348949],
              [42.106031626612697, 6.93020138103602],
              [42.098710357047104, 6.91572133434177],
              [42.076209804968201, 6.88672200728952],
              [42.075500135893698, 6.86397077496538],
              [42.066901012836503, 6.84107128838652],
              [42.062860839846103, 6.82590841435084],
              [42.059611184305801, 6.79181472341395],
              [42.055510375033798, 6.74874203478312],
              [42.025470711932897, 6.70640155376668],
              [42.0146190633007, 6.6804320634066],
              [42.013440024490301, 6.65859235643481],
              [41.999159057260997, 6.64160127988468],
              [42.000360553953499, 6.62863172137677],
              [42.006460114732697, 6.61437239783606],
              [42.0040189429481, 6.59761181270016],
              [41.999520629162902, 6.58983254893649],
              [41.987898675174598, 6.58238111792526],
              [41.964679470868298, 6.57026226441156],
              [41.936389276783203, 6.55316106049104],
              [41.913659654306699, 6.54400224889186],
              [41.908429213564901, 6.54224186366121],
              [41.887958854028099, 6.5353319012348],
              [41.8625881846163, 6.53580268310802],
              [41.858869159340102, 6.53486111891844],
              [41.858788310964499, 6.53484103814628],
              [41.853618506504397, 6.56853097550989],
              [41.819639730882599, 6.57046082679996],
              [41.7987494089504, 6.55745147639995],
              [41.757858097217301, 6.54052163331815],
              [41.738908136298797, 6.51989179534112],
              [41.704477957246702, 6.45239154442221],
              [41.6903092794279, 6.42940146720826],
              [41.684149082367, 6.42412132390509],
              [41.656108170773201, 6.40916210832903],
              [41.633326895167997, 6.39137250147488],
              [41.6196680112729, 6.36170169496043],
              [41.571868655004899, 6.4155225624777],
              [41.5279881991639, 6.46492161244429],
              [41.4762474845868, 6.51024148760092],
              [41.437768149391601, 6.54395316345621],
              [41.380017705563702, 6.56551236073727],
              [41.358368307216502, 6.56551236073727],
              [41.318516795424699, 6.58314188292569],
              [41.283026604337401, 6.598843276582],
              [41.228447213462502, 6.62299228847381],
              [41.1743057512886, 6.6589135684484],
              [41.156267580383499, 6.67687433870726],
              [41.131006954594, 6.69124337266112],
              [41.0720572598619, 6.6943125185688],
              [41.071185894036297, 6.68788424173384],
              [41.015515050091203, 6.64454358659656],
              [40.9899467513169, 6.60422420239904],
              [40.972216253396603, 6.58706393149564],
              [40.936126436857101, 6.55114412834932],
              [40.900034374529397, 6.511624895713],
              [40.885596202125399, 6.47210478304535],
              [40.860335576335999, 6.43618344740574],
              [40.856724348893799, 6.38589332961854],
              [40.8609846091287, 6.36109460172386],
              [40.864413927725899, 6.35226488524209],
              [40.8619255943889, 6.34570276323084],
              [40.864485792948599, 6.32024361788479],
              [40.872974872383502, 6.29084328644543],
              [40.879674058614803, 6.26649316851519],
              [40.881414544477799, 6.24647289487868],
              [40.8817850995325, 6.2421843311617],
              [40.880004189481802, 6.21721352253824],
              [40.877953784845801, 6.18765334000064],
              [40.873954036043202, 6.17175401067426],
              [40.873004067630298, 6.16797391451291],
              [40.867854475263997, 6.15127463227085],
              [40.866215049870497, 6.1312834794068],
              [40.866414925021203, 6.11375459853501],
              [40.869493900657602, 6.09448333164755],
              [40.866655224359697, 6.07662273770619],
              [40.871164767086, 6.05575336350148],
              [40.878384976182097, 5.98750299558802],
              [40.877513610356502, 5.94433373032339],
              [40.876433386227397, 5.8904740589222],
              [40.875714734000098, 5.85463396245385],
              [40.874773748739997, 5.8078840147864],
              [40.901253837527598, 5.74010367234343],
              [40.922274415175998, 5.68336397258552],
              [40.864984357931299, 5.66900329824947],
              [40.832182375331698, 5.65588261254521],
              [40.811462733303401, 5.63489247265005],
              [40.783424067497897, 5.61463242403034],
              [40.752863381532102, 5.59438284655542],
              [40.694351615500999, 5.56715432999408],
              [40.6795631001362, 5.5406242001321],
              [40.633032614207004, 5.53005339144261],
              [40.615942165926597, 5.50688368258858],
              [40.605362257667899, 5.49027425656934],
              [40.581022405044699, 5.4759334443994],
              [40.563482799122198, 5.45389285695744],
              [40.5489615325545, 5.42766397279872],
              [40.532872705815898, 5.4106342608289],
              [40.501144209980801, 5.40571104871053],
              [40.455871365449397, 5.39868388777943],
              [40.369480384575603, 5.38639342680428],
              [40.310351026786599, 5.37629391158849],
              [40.276650728902901, 5.36815291406031],
              [40.240610319703997, 5.36606454397037],
              [40.208329359969198, 5.35553314694686],
              [40.174411220628997, 5.35336423796468],
              [40.1345709377783, 5.35785409463158],
              [40.097930903127299, 5.36486383972845],
              [40.063379451511899, 5.37853428785905],
              [40.0336586903368, 5.396774489218],
              [39.942268584906898, 5.28497483188786],
              [39.907279204590402, 5.21959488686567],
              [39.878378156112099, 5.14030460061245],
              [39.833567943951998, 5.03275457861012],
              [39.812837072982703, 5.00205372011826],
              [39.804428841923396, 4.9514634075478],
              [39.783967465539298, 4.89057405089211],
              [39.765046699867597, 4.84683402394369],
              [39.758486752505299, 4.80105467170955],
              [39.7435365403893, 4.72346423490256],
              [39.7458766517044, 4.66656338224519],
              [39.739687259396902, 4.65471349181422],
              [39.725107602335598, 4.63304338989405],
              [39.716986832167201, 4.61566385068669],
              [39.709807047258799, 4.59735488300506],
              [39.703247099896601, 4.56815447583906],
              [39.690127205171997, 4.54327364427939],
              [39.6746267749445, 4.50517390827936],
              [39.655566770403702, 4.46887454144944],
              [39.642035896436603, 4.43592301980714],
              [39.631435776083997, 4.40269461118155],
              [39.618796480036501, 4.36845257220798],
              [39.603186006186696, 4.33375437848623],
              [39.585756443886602, 4.296523987639],
              [39.566945721837101, 4.26457270409048],
              [39.539666132446598, 4.23515314664919],
              [39.528765076473803, 4.1667241743305],
              [39.509076251234099, 4.11440397272526],
              [39.478255053836001, 4.04031253721213],
              [39.499655169691898, 3.96571248171444],
              [39.482825232843901, 3.94198394960018],
              [39.431794187341303, 3.85302340475656],
              [39.399594075982101, 3.79564356523373],
              [39.2846748474729, 3.62306227725052],
              [39.220474499905002, 3.60246225749827],
              [39.092844110125597, 3.53396178030611],
              [39.080813422683001, 3.53861291787424],
              [39.073833512925397, 3.53451319349699],
              [39.067082673565203, 3.52775275550608],
              [39.064273192514101, 3.52804191317816],
              [39.046794222873402, 3.52152127374799],
              [39.031372395233198, 3.52450252742327],
              [39.025452497510898, 3.52163335112657],
              [39.022943952079999, 3.5222027040014],
              [39.015263356400801, 3.51277247197805],
              [39.007852255306801, 3.51735198568223],
              [39.006302661441701, 3.51721300909208],
              [39.002112020641299, 3.51972130919999],
              [39.001193493263301, 3.52210183442297],
              [38.985293312734299, 3.52018306880558],
              [38.981113900875002, 3.52042291472396],
              [38.970131996526597, 3.52560312317037],
              [38.963493446576997, 3.52825261716163],
              [38.961892199582998, 3.52819209580958],
              [38.9565517152189, 3.52316208522313],
              [38.9551121649761, 3.51937162701627],
              [38.9468521559387, 3.51285316844935],
              [38.935973557848001, 3.51143201290679],
              [38.9320816068795, 3.51115181636944],
              [38.928122282264802, 3.51093214222535],
              [38.9238934630648, 3.51082230513393],
              [38.911121665512802, 3.50952219164193],
            ],
          ],
          [
            [
              [38.7988928912795, 8.83272000264345],
              [38.799434126238197, 8.83284205615271],
              [38.803305865112797, 8.83664788621188],
              [38.807406674384801, 8.837828462244509],
              [38.807878289908899, 8.83780183275163],
              [38.812403553152699, 8.839850078959939],
              [38.812646098279401, 8.840154097199131],
              [38.817719333846497, 8.83679878711858],
              [38.8171893278288, 8.841742976761219],
              [38.817238735169497, 8.84177848237146],
              [38.824371358525397, 8.84131912827455],
              [38.823866056178097, 8.845921519763481],
              [38.823863810389902, 8.84594149144305],
              [38.8238615646016, 8.84596146312153],
              [38.823859318813398, 8.845972558498],
              [38.823857073025202, 8.84600806370044],
              [38.823854827237, 8.846023597225431],
              [38.823852581448797, 8.84604356889945],
              [38.823850335660602, 8.84605910242294],
              [38.823848089872399, 8.846079074095041],
              [38.823845844084197, 8.84609904576606],
              [38.823843598296001, 8.846119017435999],
              [38.823841352507699, 8.846134550956309],
              [38.823841352507699, 8.84615230355014],
              [38.823839106719497, 8.84616783706905],
              [38.823836860931301, 8.84618558966128],
              [38.823834615143099, 8.84620334225265],
              [38.823832369354903, 8.846218875769409],
              [38.823830123566701, 8.846238847432829],
              [38.823830123566701, 8.84624994280095],
              [38.823825631990303, 8.84628322890326],
              [38.8238233862021, 8.846314295929369],
              [38.823818894625603, 8.846345362952849],
              [38.823816648837401, 8.846374210900869],
              [38.823814403049198, 8.84638308719213],
              [38.823812157261003, 8.846400839773979],
              [38.8238099114728, 8.84642081142754],
              [38.8238099114728, 8.84643856400757],
              [38.823807665684598, 8.846458535659091],
              [38.8238031741082, 8.8464829454539],
              [38.823800928319997, 8.846509574319141],
              [38.823798682531802, 8.846527326894901],
              [38.8237964367435, 8.84656061297213],
              [38.823791945167102, 8.846593899046351],
              [38.823787453590697, 8.84662718511755],
              [38.823785207802501, 8.846658252114629],
              [38.823782962014299, 8.84667378561219],
              [38.823778470437901, 8.8467159479594],
              [38.823776224649599, 8.84673591959586],
              [38.823773978861396, 8.84675811030178],
              [38.823771733073201, 8.84677808193595],
              [38.823769487284999, 8.846800272639319],
              [38.823767241496803, 8.846820244271219],
              [38.823764995708601, 8.84684465404205],
              [38.823762749920398, 8.84686462567154],
              [38.823760504132203, 8.84688459729993],
              [38.823758258344, 8.84690678799689],
              [38.823756012555798, 8.846926759623001],
              [38.823756012555798, 8.84693119776199],
              [38.8237515209793, 8.846964483802701],
              [38.823747029402902, 8.846997769840399],
              [38.823243972843798, 8.851602309377469],
              [38.824526317911896, 8.85388791483201],
              [38.826255574833802, 8.857604767159181],
              [38.827023634401698, 8.863245434844091],
              [38.829348025199401, 8.86736160307313],
              [38.829406415692901, 8.869460720160831],
              [38.829406415692901, 8.86948290949513],
              [38.826913590779398, 8.87365226160656],
              [38.824059193964104, 8.87819877625703],
              [38.8240883892109, 8.87898869692237],
              [38.824508351606198, 8.879545634346391],
              [38.824901364543003, 8.880320020036351],
              [38.826556510453997, 8.880739385816261],
              [38.828234114247103, 8.881693496650289],
              [38.829500738797698, 8.881733436119429],
              [38.831903732182703, 8.88279848702501],
              [38.832862683748502, 8.88309581318369],
              [38.834118079358099, 8.882800705877839],
              [38.840664551991097, 8.87983852540135],
              [38.8470538194494, 8.880007159539],
              [38.852960242442499, 8.88177115672519],
              [38.858365854664697, 8.88362611819648],
              [38.863787187404299, 8.88283842637378],
              [38.8680609223685, 8.87276913509662],
              [38.868099100768099, 8.872682597459971],
              [38.8699226807949, 8.87361675907429],
              [38.869808145596103, 8.880761574468499],
              [38.873390177791599, 8.888279012541661],
              [38.877468529181499, 8.89577410862965],
              [38.872563727730203, 8.902243997120269],
              [38.872900595961703, 8.90378823342694],
              [38.873841581221797, 8.907147368619921],
              [38.877160856196703, 8.91251437723832],
              [38.879424610712597, 8.918300629752411],
              [38.873855055951097, 8.923556544221251],
              [38.873453059861497, 8.92375400006464],
              [38.873098225324199, 8.92391373954516],
              [38.868047447639299, 8.927605476941959],
              [38.862639589628898, 8.9311574077601],
              [38.8596976070734, 8.93257950152943],
              [38.858267039983403, 8.933764205675381],
              [38.855531669943304, 8.938278913699831],
              [38.859511206651902, 8.94318845651166],
              [38.863281885056999, 8.94213911107907],
              [38.8674904921631, 8.940788046650329],
              [38.870740147703401, 8.93972982027225],
              [38.872161731640503, 8.937118627985759],
              [38.879671647415798, 8.936978861006009],
              [38.883900466615799, 8.93556787706501],
              [38.884991919686001, 8.935208474622041],
              [38.891401399238198, 8.934318841124471],
              [38.8975818083929, 8.935259500916411],
              [38.895892975658803, 8.93876920540519],
              [38.901597277712902, 8.943796321192821],
              [38.900555231983297, 8.94845954129709],
              [38.894493849603698, 8.952528163113501],
              [38.895030592985997, 8.953828160272479],
              [38.894871142023099, 8.95557849040307],
              [38.894871142023099, 8.95563838748277],
              [38.897521172111198, 8.95545869621408],
              [38.899061782823502, 8.95554965106489],
              [38.901851051780703, 8.963668943605271],
              [38.9040721363207, 8.969529806483299],
              [38.906371823447998, 8.974949134852061],
              [38.906120295168499, 8.97742917707869],
              [38.906171948297299, 8.979838217959241],
              [38.905561093904097, 8.982238369843261],
              [38.904730152266303, 8.98368910089172],
              [38.904341630905897, 8.986379814510791],
              [38.904150738908001, 8.990538957414151],
              [38.904480869775, 8.993748673975819],
              [38.905491474469599, 8.996459276302261],
              [38.906571698598697, 8.998579831562409],
              [38.904900832170298, 9.008335112872039],
              [38.905055791556798, 9.01063748056948],
              [38.901136891129802, 9.01957177040037],
              [38.896820486189597, 9.02940854921939],
              [38.896360099606497, 9.0320989224958],
              [38.894480374874497, 9.03550788270894],
              [38.892160475653199, 9.0389190285689],
              [38.887601525586298, 9.04542850529209],
              [38.885000902838797, 9.048178640664201],
              [38.883211009635197, 9.050199089627769],
              [38.882750623052097, 9.05179814162976],
              [38.882802276180897, 9.054279873999279],
              [38.883431096879796, 9.05698780371714],
              [38.883410884785903, 9.059469500260469],
              [38.882570959995299, 9.061789283318131],
              [38.882400280091296, 9.063607840823989],
              [38.882591172089199, 9.06660844057741],
              [38.881690611016801, 9.068708623713469],
              [38.8815221769011, 9.07031868187309],
              [38.880830474132303, 9.073008749155919],
              [38.8800107614355, 9.07423956464212],
              [38.878820493684103, 9.07567883721477],
              [38.877971585740603, 9.07712697466788],
              [38.877803151624803, 9.07753280693273],
              [38.877300095065699, 9.079967790876401],
              [38.876271524065402, 9.0812274117815],
              [38.875321555652398, 9.08351600745085],
              [38.8681979154494, 9.08489536716182],
              [38.856728675059401, 9.08350935457764],
              [38.851392682271701, 9.07787876194341],
              [38.849921690994002, 9.078089439510769],
              [38.847202041471299, 9.07849970599724],
              [38.844471163007498, 9.07825798147554],
              [38.841311338995702, 9.07785880300956],
              [38.824712718333302, 9.07587621004487],
              [38.813353521565702, 9.08000770850821],
              [38.805329320290298, 9.082582386369889],
              [38.805127199351297, 9.082387234862759],
              [38.804855458977897, 9.08197919045903],
              [38.801235248382902, 9.07968836732937],
              [38.800943295915502, 9.07946216715558],
              [38.799011918054703, 9.07774348470333],
              [38.798571743565503, 9.077308823061919],
              [38.792314977611603, 9.07711810401215],
              [38.7906059327835, 9.07803621571597],
              [38.7781642660985, 9.09363931971698],
              [38.771224780528698, 9.09818085727124],
              [38.765192593395803, 9.09813650659725],
              [38.759268204096998, 9.095881267577919],
              [38.752935081343999, 9.091796521198329],
              [38.748730965814303, 9.089232997497049],
              [38.737998343957301, 9.086849081395309],
              [38.727079321678801, 9.085700360809589],
              [38.7166206859835, 9.087736121798249],
              [38.708776147764901, 9.08417907652766],
              [38.700659869172902, 9.080497807958571],
              [38.695856128190997, 9.07832007586364],
              [38.693646272592098, 9.07731769371294],
              [38.686576531306102, 9.074113156759729],
              [38.684281335755202, 9.072877905459549],
              [38.684274598390502, 9.072798068604429],
              [38.684270106814097, 9.072742626333479],
              [38.684263369449504, 9.072680530979831],
              [38.684263369449504, 9.07266057175673],
              [38.684261123661301, 9.07265835406521],
              [38.684258877872999, 9.072625088690719],
              [38.684256632084796, 9.072594041005059],
              [38.684254386296601, 9.07256521100885],
              [38.684249894720203, 9.072536381010311],
              [38.684249894720203, 9.072529727933389],
              [38.684247648932001, 9.072511986394341],
              [38.684245403143798, 9.07248980946931],
              [38.684245403143798, 9.072472067928301],
              [38.684243157355603, 9.07246097946472],
              [38.684243157355603, 9.07245876177196],
              [38.684243157355603, 9.07244989100079],
              [38.684243157355603, 9.07244767330797],
              [38.684243157355603, 9.07244545561513],
              [38.6842409115674, 9.07242549637896],
              [38.684238665779198, 9.072407754834749],
              [38.684238665779198, 9.072403319448579],
              [38.684238665779198, 9.07240110175546],
              [38.684236419990903, 9.072392230982871],
              [38.684236419990903, 9.072383360210059],
              [38.684236419990903, 9.07237670713031],
              [38.6842341742027, 9.07236561866377],
              [38.684231928414498, 9.07234565942316],
              [38.684231928414498, 9.072327917875009],
              [38.684229682626302, 9.072316829406979],
              [38.684229682626302, 9.07231239401967],
              [38.684229682626302, 9.07230130555117],
              [38.6842274368381, 9.07229687016366],
              [38.6842274368381, 9.07228799938849],
              [38.684225191049897, 9.072268040143561],
              [38.684225191049897, 9.072256951673671],
              [38.684222945261702, 9.07223921012115],
              [38.684222945261702, 9.072236992427021],
              [38.684222945261702, 9.072232557038729],
              [38.684220699473499, 9.07221038009642],
              [38.684218453685297, 9.07219929162474],
              [38.684216207897101, 9.07217711468039],
              [38.684213962108799, 9.07213276078755],
              [38.684211716320597, 9.072117236923759],
              [38.684211716320597, 9.07211501922888],
              [38.684211716320597, 9.072112801533979],
              [38.684211716320597, 9.07210614844921],
              [38.684207224744199, 9.07207288302352],
              [38.684204978955997, 9.072044052985429],
              [38.684198241591403, 9.07197752211944],
              [38.684195995803201, 9.071957562857239],
              [38.684195995803201, 9.071955345161371],
              [38.684193750014899, 9.071915426633391],
              [38.684191504226703, 9.071897685063989],
              [38.684187012650298, 9.0718533311366],
              [38.684184766862103, 9.07182893647421],
              [38.684184766862103, 9.07182450108086],
              [38.684184766862103, 9.071822283384179],
              [38.684184766862103, 9.071817847990751],
              [38.6841825210739, 9.071800106416511],
              [38.6841825210739, 9.07179788871967],
              [38.6841825210739, 9.071795671022819],
              [38.684180275285698, 9.07178901793217],
              [38.684180275285698, 9.071786800235261],
              [38.684180275285698, 9.07178458253834],
              [38.684180275285698, 9.0717823648414],
              [38.684178029497502, 9.071762405568339],
              [38.6841757837093, 9.07173135780805],
              [38.684173537921097, 9.071706963137361],
              [38.684171292132802, 9.07168256846502],
              [38.6841690463446, 9.071653738395559],
              [38.684164554768202, 9.07162047292794],
              [38.68416230898, 9.07158498975906],
              [38.684157817403602, 9.071549506586679],
              [38.684155571615399, 9.07151402341078],
              [38.684153325827197, 9.071498499520221],
              [38.684153325827197, 9.0714807579302],
              [38.684151080039001, 9.071456363242479],
              [38.684148834250699, 9.07144083934943],
              [38.684148834250699, 9.071429750853991],
              [38.684146588462497, 9.071418662358189],
              [38.684144342674301, 9.071403138463509],
              [38.684144342674301, 9.07139870306491],
              [38.684142096886099, 9.071363219874099],
              [38.684135359521498, 9.07129668888189],
              [38.684133113733303, 9.07127451188175],
              [38.6841308679451, 9.07125677028065],
              [38.684128622156798, 9.07122572247661],
              [38.684126376368603, 9.071207980873099],
              [38.684126376368603, 9.0711902392687],
              [38.6841241305804, 9.071170279962709],
              [38.684121884792198, 9.07115475605729],
              [38.684119639004003, 9.071137014450271],
              [38.6841173932158, 9.071092660428871],
              [38.684115147427597, 9.07107048341612],
              [38.684112901639402, 9.0710460887005],
              [38.6841106558512, 9.07103056478971],
              [38.684108410062997, 9.071012823176551],
              [38.684106164274702, 9.07099286386069],
              [38.684106164274702, 9.07097512224566],
              [38.6841039184865, 9.07095516292771],
              [38.684101672698297, 9.070937421310809],
              [38.684101672698297, 9.07093520360864],
              [38.683670481362, 9.06630239385724],
              [38.683677218726601, 9.059529380126181],
              [38.683683956091201, 9.052450180211389],
              [38.683686201879397, 9.047823785668211],
              [38.6830214485692, 9.047375780735971],
              [38.676739978945001, 9.043152974168891],
              [38.672574041814897, 9.038659534212799],
              [38.672059756314702, 9.03313248182875],
              [38.667199870627599, 9.02621911120095],
              [38.666514905223501, 9.01671938709115],
              [38.6679275060078, 9.010952446878729],
              [38.666101680192803, 9.009075953404221],
              [38.6621243892723, 9.009404229561641],
              [38.660707296911603, 9.009404229561641],
              [38.659380036079398, 9.00925340001284],
              [38.6580078594829, 9.00922900110932],
              [38.653314162123301, 9.012216745497639],
              [38.6505248931662, 9.008326240521139],
              [38.650850532456602, 9.002641236905889],
              [38.651205366993899, 9.00231739085643],
              [38.657156705751198, 8.99668552860631],
              [38.6582279467275, 8.9934314745046],
              [38.658412101360703, 8.99313867474603],
              [38.658488458159901, 8.992939038411221],
              [38.658421084513598, 8.992741620149671],
              [38.658369431384699, 8.9926817291951],
              [38.658369431384699, 8.992617401862489],
              [38.659355332409, 8.98597831605518],
              [38.659451901302099, 8.985321720937041],
              [38.659283467186299, 8.984864765524289],
              [38.656381908818602, 8.97703876010681],
              [38.652860512904901, 8.976808058971329],
              [38.648541862176501, 8.975998385555799],
              [38.648431818554101, 8.97596732955318],
              [38.640562576665303, 8.97436128693688],
              [38.640108927446803, 8.97447442000589],
              [38.639089339599302, 8.973913190905179],
              [38.638732259273901, 8.97380671255884],
              [38.6384200947126, 8.97355826296255],
              [38.642123399471402, 8.9659605034492],
              [38.642586031842697, 8.965867332856609],
              [38.643095825766501, 8.965794127374229],
              [38.643378795080999, 8.96574088701413],
              [38.651881349245201, 8.96347594613353],
              [38.6536892087545, 8.962779379692989],
              [38.653702683483701, 8.962759414393149],
              [38.653716158213001, 8.96273723072539],
              [38.653722895577602, 8.96272170215714],
              [38.653731878730497, 8.96270173685414],
              [38.653734124518699, 8.96269730012],
              [38.6537543366126, 8.9626684613467],
              [38.653774548706501, 8.96263740420366],
              [38.6537992523768, 8.96261078379324],
              [38.653828447623503, 8.96259081848415],
              [38.653830693411699, 8.96259081848415],
              [38.6538509055056, 8.962584163380861],
              [38.6538711175995, 8.962573071541801],
              [38.653889083905199, 8.962561979702389],
              [38.653904804422702, 8.962553106230621],
              [38.6539227707283, 8.962546451126659],
              [38.653947474398599, 8.96253757765451],
              [38.6539676864925, 8.962530922550259],
              [38.653992390162799, 8.962526485814021],
              [38.654010356468497, 8.96252426744589],
              [38.654028322774202, 8.962522049077741],
              [38.654053026444501, 8.962519830709571],
              [38.654066501173801, 8.962517612341379],
              [38.654100187996903, 8.962506520500289],
              [38.654133874820097, 8.962497647027179],
              [38.654165315855003, 8.96247768171186],
              [38.6541877737371, 8.96245106128972],
              [38.654199002678197, 8.96243996944661],
              [38.6542012484664, 8.96241778575933],
              [38.6542012484664, 8.96239782043962],
              [38.654205740042798, 8.96237785511882],
              [38.654212477407498, 8.96236010816607],
              [38.654221460560301, 8.96234457958171],
              [38.654225952136699, 8.96232683262734],
              [38.654234935289601, 8.962309085672089],
              [38.654248410018802, 8.96229355708555],
              [38.654268622112703, 8.962280246867991],
              [38.654291079994799, 8.962269155019641],
              [38.654309046300497, 8.96226471828021],
              [38.654335995758998, 8.96226249991048],
              [38.654356207852899, 8.96226471828021],
              [38.654358453641102, 8.96226471828021],
              [38.654394386252498, 8.962273591759031],
              [38.654430318863902, 8.962280246867991],
              [38.654464005686997, 8.96228912034641],
              [38.6544999382984, 8.962297993824629],
              [38.654522396180496, 8.962300212194149],
              [38.654540362486202, 8.962304648933159],
              [38.654565066156501, 8.962309085672089],
              [38.654583032462199, 8.96231352241098],
              [38.6546032445561, 8.96231795914983],
              [38.654623456649901, 8.962322395888609],
              [38.654643668743802, 8.96232461425797],
              [38.654650406108502, 8.96232683262734],
              [38.654670618202402, 8.962329050996679],
              [38.654693076084499, 8.96233348773533],
              [38.654711042390097, 8.96233570610462],
              [38.6547335002723, 8.962337924473911],
              [38.654758203942599, 8.96234236121245],
              [38.654776170248198, 8.96234679795093],
              [38.654787399189303, 8.96234679795093],
              [38.654823331800699, 8.96234679795093],
              [38.654859264412003, 8.96234679795093],
              [38.6548951970234, 8.962337924473911],
              [38.654931129634797, 8.962331269366009],
              [38.654949095940403, 8.96232683262734],
              [38.654994011704602, 8.962329050996679],
              [38.655034435892397, 8.962309085672089],
              [38.655369058335801, 8.962224787622869],
              [38.655780037578197, 8.96201847915674],
              [38.6558159701896, 8.96199407707236],
              [38.655851902800997, 8.96196301987168],
              [38.655892326988798, 8.96194083615527],
              [38.655937242752998, 8.96193639941184],
              [38.656006862187503, 8.96192530755299],
              [38.6560495321635, 8.96190534220621],
              [38.656076481622001, 8.961887595230399],
              [38.6561011852923, 8.961869848253709],
              [38.6561461010565, 8.96185431964838],
              [38.656177542091498, 8.96182991755296],
              [38.656191016820699, 8.96181438894595],
              [38.656197754185399, 8.961812170573459],
              [38.6563347472662, 8.96184544615932],
              [38.656361696724701, 8.96185431964838],
              [38.656402120912503, 8.96187650337006],
              [38.656426824582802, 8.96188981360242],
              [38.656460511406003, 8.96191865243753],
              [38.6564964440173, 8.96193639941184],
              [38.656536868205102, 8.96194749127034],
              [38.656584029757497, 8.96194083615527],
              [38.6567165312619, 8.96189868709039],
              [38.657785526449999, 8.96219594881207],
              [38.661848157322503, 8.96211165073661],
              [38.661861632051703, 8.96208946702928],
              [38.661877352569199, 8.962065064949661],
              [38.661895318874897, 8.962042881239491],
              [38.661920022545203, 8.96202957101274],
              [38.6619492177919, 8.96202513427038],
              [38.661953709368397, 8.96202513427038],
              [38.661978413038703, 8.962031789383911],
              [38.662000870920799, 8.96205175472373],
              [38.662012099861798, 8.9620783751751],
              [38.662027820379301, 8.962104995624539],
              [38.662045786684999, 8.962127179330921],
              [38.662077227719898, 8.962151581406371],
              [38.662095194025603, 8.96216710999898],
              [38.662119897695902, 8.96218485696116],
              [38.6621378640016, 8.96220482229257],
              [38.662158076095501, 8.962222569252891],
              [38.662180533977597, 8.96224031621237],
              [38.662202991859701, 8.96225806317096],
              [38.662205237647903, 8.96226028154071],
              [38.662238924471097, 8.962275810128689],
              [38.662272611294199, 8.96228468360723],
              [38.662308543905603, 8.96228468360723],
              [38.662333247575901, 8.96225584480119],
              [38.662348968093397, 8.96223587947258],
              [38.6623714259755, 8.96221813251289],
              [38.662398375434002, 8.96220260392246],
              [38.662427570680698, 8.962191512071749],
              [38.662456765927502, 8.96218485696116],
              [38.662485961174198, 8.96217820185044],
              [38.662517402209097, 8.962171546739601],
              [38.662546597455901, 8.962164891628641],
              [38.6625780384908, 8.96216045488794],
              [38.662598250584701, 8.96215823651757],
              [38.662634183196097, 8.96215601814718],
              [38.662667870019199, 8.96215601814718],
              [38.662706048418798, 8.96215823651757],
              [38.662744226818397, 8.96216045488794],
              [38.662750964182997, 8.9621626732583],
              [38.662777913641499, 8.96216710999898],
              [38.662807108888302, 8.962171546739601],
              [38.662840795711404, 8.962175983480179],
              [38.662872236746402, 8.96217820185044],
              [38.662901431993099, 8.962180420220699],
              [38.662930627239803, 8.96218263859093],
              [38.6629351188163, 8.96218263859093],
              [38.662971051427597, 8.962180420220699],
              [38.663009229827203, 8.962180420220699],
              [38.6630451624386, 8.96217820185044],
              [38.663081095049897, 8.962175983480179],
              [38.663108044508498, 8.962171546739601],
              [38.663137239755201, 8.9621626732583],
              [38.663159697637298, 8.962144926295039],
              [38.663164189213703, 8.9621160874779],
              [38.6631619434255, 8.96208946702928],
              [38.6631619434255, 8.96208724865847],
              [38.663159697637298, 8.962058409836761],
              [38.6631552060609, 8.96202957101274],
              [38.663150714484402, 8.961998513815089],
              [38.663143977119802, 8.961969674986319],
              [38.663143977119802, 8.961943054526969],
              [38.663141731331599, 8.96190756057814],
              [38.663141731331599, 8.961894250346431],
              [38.663143977119802, 8.96188094011424],
              [38.663150714484402, 8.96184988290389],
              [38.663164189213703, 8.961825480808169],
              [38.663188892884001, 8.96180773382844],
              [38.663215842342503, 8.96179664196567],
              [38.663229317071803, 8.961794423593091],
              [38.663265249683199, 8.961792205220471],
              [38.663301182294497, 8.96178776847521],
              [38.6633371149059, 8.96178111335723],
              [38.663357326999801, 8.96177889498454],
              [38.6633820306701, 8.96177223986639],
              [38.663404488552203, 8.96176336637533],
              [38.663424700646097, 8.9617522745112],
              [38.6634404211636, 8.961738964273801],
              [38.6634606332575, 8.96173674590087],
              [38.663480845351401, 8.961730090781931],
              [38.663489828504197, 8.96172565403592],
              [38.663523515327398, 8.96170790705132],
              [38.663552710574102, 8.96168794169261],
              [38.663581905820799, 8.961667976332791],
              [38.663617838432202, 8.961659102839191],
              [38.663626821584998, 8.961659102839191],
              [38.663647033678899, 8.961659102839191],
              [38.663669491561002, 8.96165466609231],
              [38.663691949443098, 8.96165466609231],
              [38.663709915748797, 8.961652447718841],
              [38.663727882054502, 8.961645792598381],
              [38.6637458483602, 8.96163248235708],
              [38.663752585724801, 8.96162360886261],
              [38.663757077301199, 8.96161917211529],
              [38.663772797818702, 8.96160364349927],
              [38.6637907641244, 8.961596988377901],
              [38.663810976218301, 8.961592551630259],
              [38.663831188312201, 8.96158145976092],
              [38.6638491546179, 8.9615725862652],
              [38.663867120923499, 8.961565931143269],
              [38.663880595652799, 8.96156149439526],
              [38.663912036687698, 8.961541529028491],
              [38.663934494569801, 8.96151269016346],
              [38.664006359792602, 8.96144835730243],
              [38.664037800827501, 8.96142173680488],
              [38.664046783980403, 8.96140177143044],
              [38.664055767133199, 8.9613818060549],
              [38.664064750286002, 8.9613640590535],
              [38.6640759792271, 8.96134409367588],
              [38.664091699744603, 8.96133078342354],
              [38.664084962379903, 8.961313036419661],
              [38.664098437109203, 8.96129750779053],
              [38.664116403414901, 8.961284197536481],
              [38.6641545818145, 8.961255358651011],
              [38.664159073390898, 8.9612509218992],
              [38.664183777061197, 8.96122430138718],
              [38.6642062349433, 8.96119768087323],
              [38.664230938613599, 8.961168841980889],
              [38.6642578880721, 8.961146658216],
              [38.664260133860303, 8.96114443983944],
              [38.664282591742399, 8.96113556633305],
              [38.664300558048097, 8.961133347956411],
              [38.664323015930201, 8.96113112957978],
              [38.664343228024102, 8.961122256073059],
              [38.6643611943298, 8.961113382566131],
              [38.664383652211903, 8.961124474449759],
              [38.664401618517601, 8.96113778470966],
              [38.6644195848233, 8.96114443983944],
              [38.664435305340703, 8.96115775009862],
              [38.664451025858199, 8.96117106035731],
              [38.664466746375702, 8.96117993386283],
              [38.664482466893197, 8.961191025744419],
              [38.664498187410601, 8.961206554378091],
              [38.664507170563503, 8.96121542788274],
              [38.664525136869202, 8.96124870352328],
              [38.664554332115898, 8.96126423215448],
              [38.664588018939, 8.96124870352328],
              [38.664614968397601, 8.96122651976326],
              [38.664619459973999, 8.961219864635],
              [38.664610476821103, 8.961199899249459],
              [38.664605985244698, 8.961182152239161],
              [38.664617214185803, 8.961162186851571],
              [38.664630688914997, 8.961146658216],
              [38.664637426279697, 8.96112669282644],
              [38.664648655220702, 8.961104509058989],
              [38.6646531467971, 8.96109785392848],
              [38.6646598841618, 8.961084543667109],
              [38.664671113102798, 8.961066796651179],
              [38.664680096255701, 8.96104683125724],
              [38.664700308349602, 8.96101133722086],
              [38.664718274655201, 8.960975843181],
              [38.664722766231698, 8.96097140642577],
              [38.664749715690199, 8.960947004271061],
              [38.664772173572302, 8.96092038373679],
              [38.664781156725098, 8.96089598157864],
              [38.664787894089798, 8.96088710806619],
              [38.664814843548299, 8.960864924284101],
              [38.664823826701102, 8.96085826914921],
              [38.664844038795003, 8.960851614014191],
              [38.664862005100701, 8.96084495887906],
              [38.664882217194602, 8.960833866986899],
              [38.664902429288503, 8.9608227750944],
              [38.664922641382397, 8.96081833833731],
              [38.664942853476298, 8.960813901580149],
              [38.664960819782003, 8.96081833833731],
              [38.6649967523933, 8.960820556715859],
              [38.665032685004697, 8.960816119958739],
              [38.665066371827798, 8.960805028065691],
              [38.665102304439202, 8.960800591308381],
              [38.6651067960156, 8.960800591308381],
              [38.665127008109501, 8.960798372929711],
              [38.665149465991597, 8.96078949941486],
              [38.665174169661903, 8.96078284427859],
              [38.665196627543999, 8.96078062589981],
              [38.665219085426102, 8.96077397076337],
              [38.665239297520003, 8.960771752384529],
              [38.665246034884703, 8.960771752384529],
              [38.665261755402099, 8.960771752384529],
              [38.665286459072398, 8.96077397076337],
              [38.665306671166299, 8.96077397076337],
              [38.665331374836597, 8.9607761891422],
              [38.665358324295198, 8.96077840752101],
              [38.665383027965497, 8.96077840752101],
              [38.665389765330097, 8.96077840752101],
              [38.665425697941501, 8.96077840752101],
              [38.6654638763411, 8.96078284427859],
              [38.665499808952397, 8.96078506265736],
              [38.665535741563801, 8.96078728103611],
              [38.665540233140199, 8.96078728103611],
              [38.665562691022302, 8.96078949941486],
              [38.665582903116203, 8.96078949941486],
              [38.665603115210097, 8.96078949941486],
              [38.665623327303997, 8.96078949941486],
              [38.665648030974303, 8.96078506265736],
              [38.665668243068197, 8.96078284427859],
              [38.665679472009202, 8.96078284427859],
              [38.665692946738503, 8.96078062589981],
              [38.665713158832403, 8.9607761891422],
              [38.665731125138102, 8.96076953400568],
              [38.6657490914438, 8.960762878869049],
              [38.665769303537701, 8.960754005353341],
              [38.665791761419797, 8.96074291345839],
              [38.6658142193019, 8.96072960318404],
              [38.665843414548597, 8.96070963777156],
              [38.665870364007098, 8.960685235599261],
              [38.665897313465599, 8.96066083342534],
              [38.665922017135998, 8.96063421287009],
              [38.665931000288801, 8.960623120971491],
              [38.665942229229799, 8.96060537393303],
              [38.665957949747302, 8.96059428203356],
              [38.665975916053, 8.960589845273679],
              [38.665998373935103, 8.960589845273679],
              [38.666018586028997, 8.960580971753741],
              [38.6660343065465, 8.960569879853519],
              [38.6660410439111, 8.96056322471323],
              [38.666052272852099, 8.96055213281247],
              [38.666070239157797, 8.960534385770551],
              [38.666088205463502, 8.960516638727761],
              [38.666101680192803, 8.960501110064611],
              [38.666121892286696, 8.960483363020201],
              [38.666146595957002, 8.960463397594181],
              [38.666175791203699, 8.960441213786231],
              [38.6662094780269, 8.960430121881741],
              [38.666245410638197, 8.96041902997692],
              [38.666279097461398, 8.96041015645282],
              [38.666299309555299, 8.96040793807175],
              [38.6663262590138, 8.96040128292849],
              [38.666344225319499, 8.960399064547371],
              [38.666371174778, 8.960403501309591],
              [38.666391386871901, 8.96040793807175],
              [38.666411598965801, 8.9604145932149],
              [38.6664295652715, 8.96042568511985],
              [38.666445285789003, 8.96044565054792],
              [38.666461006306399, 8.9604589608327],
              [38.666474481035699, 8.96047448949766],
              [38.666496938917803, 8.960487799781371],
              [38.666528379952702, 8.960509983586491],
              [38.666530625740897, 8.96051220196693],
              [38.666564312564098, 8.96052551224926],
              [38.666597999387299, 8.96053882253111],
              [38.666633931998597, 8.96054991443228],
              [38.666667618821798, 8.9605610063331],
              [38.666674356186398, 8.96056322471323],
              [38.666701305644899, 8.960576534993701],
              [38.6667215177388, 8.96058319013375],
              [38.666746221409099, 8.960589845273679],
              [38.666764187714797, 8.96059428203356],
              [38.666784399808698, 8.960598718793401],
              [38.666802366114403, 8.96060759231289],
              [38.666820332420102, 8.960618684211971],
              [38.666842790302198, 8.96062755773098],
              [38.666865248184301, 8.960638649629431],
              [38.666885460278202, 8.96064752314796],
              [38.666905672372103, 8.960654178286701],
              [38.666916901313101, 8.96066083342534],
              [38.6669483423481, 8.96067414370223],
              [38.666979783382999, 8.96069410911665],
              [38.667011224417998, 8.96070963777156],
              [38.667042665452897, 8.96072960318404],
              [38.667044911241099, 8.96073182156313],
              [38.667067369123203, 8.96074069507938],
              [38.667085335428901, 8.960749568595411],
              [38.667105547522802, 8.960762878869049],
              [38.667125759616702, 8.960771752384529],
              [38.667143725922401, 8.9607761891422],
              [38.667163938016301, 8.96077840752101],
              [38.667184150110103, 8.96078062589981],
              [38.667204362203996, 8.96077840752101],
              [38.667215591145101, 8.96076509724794],
              [38.667215591145101, 8.96074735021641],
              [38.667217836933297, 8.960727384804921],
              [38.667222328509702, 8.96070741939233],
              [38.667224574297897, 8.960685235599261],
              [38.6672268200861, 8.960667488563841],
              [38.667244786391798, 8.960636431249769],
              [38.667269490062097, 8.960609810692731],
              [38.667303176885298, 8.96059650041348],
              [38.6673368637084, 8.960587626893711],
              [38.667341355284897, 8.960587626893711],
              [38.667363813167, 8.96059428203356],
              [38.667381779472599, 8.96060537393303],
              [38.6674019915665, 8.960612029072561],
              [38.667424449448603, 8.96060759231289],
              [38.667442415754302, 8.96060537393303],
              [38.667464873636398, 8.96060315555316],
              [38.667482839942103, 8.960598718793401],
              [38.667496314671403, 8.960580971753741],
              [38.667505297824199, 8.960558787952969],
              [38.667521018341702, 8.96054104091138],
              [38.667530001494498, 8.960521075488529],
              [38.667541230435603, 8.9605033284451],
              [38.667547967800203, 8.960483363020201],
              [38.667552459376601, 8.960478926258951],
              [38.667577163047, 8.96045230569038],
              [38.667597375140801, 8.96042124835791],
              [38.667624324599402, 8.96039684616626],
              [38.667651274057903, 8.96037466235423],
              [38.667658011422503, 8.96037244397295],
              [38.667682715092802, 8.96037688073549],
              [38.6677006813985, 8.960381317497969],
              [38.667723139280596, 8.96039019102278],
              [38.667747842950902, 8.96039684616626],
              [38.667768055044803, 8.960399064547371],
              [38.667786021350501, 8.960403501309591],
              [38.667792758715102, 8.960403501309591],
              [38.667812970809003, 8.960405719690691],
              [38.667839920267603, 8.960405719690691],
              [38.667864623937902, 8.96040128292849],
              [38.667887081819998, 8.960399064547371],
              [38.667905048125697, 8.96039240940396],
              [38.6679275060078, 8.96039240940396],
              [38.667936489160603, 8.96039240940396],
              [38.667972421771999, 8.96039684616626],
              [38.668010600171499, 8.96039684616626],
              [38.668046532782903, 8.96039684616626],
              [38.668082465394299, 8.96039240940396],
              [38.668100431699898, 8.96039019102278],
              [38.668122889582101, 8.9603879726416],
              [38.668145347464197, 8.960385754260409],
              [38.668165559557998, 8.960381317497969],
              [38.668192509016599, 8.96037688073549],
              [38.6682127211105, 8.96037466235423],
              [38.668226195839701, 8.96037466235423],
              [38.668262128451097, 8.96037466235423],
              [38.668286832121403, 8.96037244397295],
              [38.668311535791702, 8.96037244397295],
              [38.668336239462, 8.96037244397295],
              [38.668363188920601, 8.96037466235423],
              [38.668372172073397, 8.96037466235423],
              [38.668408104684801, 8.960379099116739],
              [38.668444037296098, 8.960385754260409],
              [38.668475478331104, 8.96040128292849],
              [38.668506919366003, 8.96042124835791],
              [38.668513656730603, 8.960430121881741],
              [38.668524885671701, 8.96044786892876],
              [38.668531623036301, 8.96047005273631],
              [38.668538360401001, 8.96049001816194],
              [38.668551835130202, 8.96050554682558],
              [38.668567555647698, 8.960516638727761],
              [38.668585521953403, 8.96052773062959],
              [38.668605734047297, 8.96052773062959],
              [38.668623700352903, 8.96052329386891],
              [38.668641666658601, 8.960521075488529],
              [38.6686663703289, 8.96051442034735],
              [38.668668616117202, 8.96051442034735],
              [38.668695565575703, 8.9604922365425],
              [38.668704548728499, 8.960456742451941],
              [38.668718023457799, 8.96042346673889],
              [38.6687382355517, 8.960394627785121],
              [38.6687382355517, 8.96039240940396],
              [38.668758447645601, 8.960379099116739],
              [38.668778659739502, 8.96038353587921],
              [38.668798871833303, 8.9603879726416],
              [38.668816838139001, 8.96039240940396],
              [38.668837050232902, 8.96039240940396],
              [38.668857262326803, 8.960394627785121],
              [38.674377409747699, 8.957992113060531],
              [38.676483959088998, 8.95775252623377],
              [38.678291818598296, 8.95795440032974],
              [38.679318143810399, 8.955862446841561],
              [38.680387138998498, 8.95116604506431],
              [38.680427563186299, 8.948426264486139],
              [38.680490445256197, 8.94684672169244],
              [38.678610720524098, 8.94399154759042],
              [38.679924506627202, 8.94168653833121],
              [38.681352827928897, 8.939727601765091],
              [38.682592503020999, 8.93890009764932],
              [38.687263742498402, 8.93914191540035],
              [38.687418701884901, 8.939226218798749],
              [38.6874568802845, 8.93925284092051],
              [38.687528745507201, 8.93928168155022],
              [38.687555694965802, 8.93928168155022],
              [38.687607348094602, 8.939303866648441],
              [38.687638789129601, 8.939323833235679],
              [38.687641034917803, 8.93932827025492],
              [38.687661247011697, 8.939359329388081],
              [38.687739849598998, 8.93960114683424],
              [38.687726374869698, 8.939496876945951],
              [38.687755570116501, 8.93955233965621],
              [38.687760061692899, 8.939572306229829],
              [38.687760061692899, 8.939585617278301],
              [38.687762307481101, 8.939592272802351],
              [38.687753324328298, 8.93961223937378],
              [38.687751078540103, 8.939632205944131],
              [38.687739849598998, 8.93960114683424],
              [38.687746586963598, 8.93965217251338],
              [38.687744341175403, 8.939669920574],
              [38.687744341175403, 8.939694324155971],
              [38.687746586963598, 8.93971650922906],
              [38.6877488327519, 8.939727601765091],
              [38.6877870111514, 8.939774190412759],
              [38.687831926915599, 8.939829653080791],
              [38.687849893221298, 8.939849619639199],
              [38.687876842679799, 8.93988289723414],
              [38.687899300561902, 8.939918393332061],
              [38.687921758443998, 8.93994501540323],
              [38.687944216326201, 8.939958326438081],
              [38.687989132090401, 8.939962763449589],
              [38.688031802066298, 8.93996498195532],
              [38.688056505736697, 8.939967200461041],
              [38.688638164883102, 8.94041311983664],
              [38.688660622765198, 8.940444178877289],
              [38.688710030105902, 8.94050407844821],
              [38.688725750623298, 8.940539574485589],
              [38.688727996411501, 8.94057950752349],
              [38.688730242199703, 8.9406039110445],
              [38.688997490996798, 8.940572852017469],
              [38.692864738294901, 8.94241198555228],
              [38.694005598705701, 8.94215020354131],
              [38.694593995216799, 8.94109198112135],
              [38.695750576145102, 8.93471152251208],
              [38.696785884510099, 8.93165436471566],
              [38.697306907374902, 8.930908929028289],
              [38.699492059303502, 8.92946686161666],
              [38.702146580968098, 8.925990346427801],
              [38.7021623014856, 8.925959286155511],
              [38.702178022002997, 8.92593710024514],
              [38.702054503651503, 8.925779580242549],
              [38.702018571040099, 8.92576626868992],
              [38.702020816828302, 8.92576848728207],
              [38.701957934758397, 8.92573964558321],
              [38.702009587887197, 8.9257618315056],
              [38.701924247935303, 8.92570636669709],
              [38.701917510570603, 8.92569971091948],
              [38.702011833675499, 8.9257618315056],
              [38.702018571040099, 8.92576626868992],
              [38.701877086382801, 8.92532920577546],
              [38.701814204313003, 8.925588781275099],
              [38.701809712736498, 8.925571032528881],
              [38.701807466948303, 8.925551065188341],
              [38.701802975371898, 8.925531097846701],
              [38.701832170618601, 8.925360266101389],
              [38.701827679042196, 8.92538467064132],
              [38.701827679042196, 8.92538023345238],
              [38.701832170618601, 8.925360266101389],
              [38.701843399559699, 8.92534251734403],
              [38.701877086382801, 8.92532920577546],
              [38.701897298476702, 8.92532033139614],
              [38.701910773206002, 8.92530480123181],
              [38.7019220021471, 8.92528927106682],
              [38.704890934161099, 8.921708441091541],
              [38.7056567479408, 8.92163522672922],
              [38.706651632117897, 8.921266936077799],
              [38.707505031637901, 8.920619098608491],
              [38.709690183566501, 8.92029739806808],
              [38.711578891451303, 8.9206967504206],
              [38.719120248261497, 8.91970280597816],
              [38.722906647184097, 8.915274395623189],
              [38.722906647184097, 8.91526995831134],
              [38.722902155607699, 8.91524999040732],
              [38.722902155607699, 8.91523002250219],
              [38.722902155607699, 8.91520783593969],
              [38.722911138760502, 8.915192305345119],
              [38.722920121913297, 8.9151767747499],
              [38.7229291050662, 8.91515458818415],
              [38.722933596642598, 8.915136838930581],
              [38.722940334007198, 8.915116871019279],
              [38.722944825583703, 8.91509912176387],
              [38.722951562948303, 8.91507915385052],
              [38.722962791889302, 8.915059185936061],
              [38.722976266618602, 8.915041436677861],
              [38.722983003983202, 8.91502590607622],
              [38.722998724500698, 8.915012594131429],
              [38.723000970288901, 8.91501037547391],
              [38.723034657112102, 8.91499706352857],
              [38.723059360782401, 8.914970439636409],
              [38.723075081299797, 8.91493937842643],
              [38.723097539182, 8.914910535871959],
              [38.7231042765466, 8.91489944258117],
              [38.723113259699403, 8.91488169331519],
              [38.723119997064103, 8.91486172538993],
              [38.723128980216899, 8.91484397612215],
              [38.723137963369702, 8.914824008194829],
              [38.723144700734402, 8.914804040266439],
              [38.723151438099002, 8.91478629099583],
              [38.723158175463603, 8.914779635019119],
              [38.723164912828302, 8.914770760383339],
              [38.723173895981098, 8.914750792452031],
              [38.723182879133901, 8.91473526183804],
              [38.723194108074999, 8.91471751256409],
              [38.7232008454396, 8.914699763289279],
              [38.723209828592502, 8.914684232673119],
              [38.723223303321703, 8.91466426473708],
              [38.723230040686403, 8.91465539009848],
              [38.723248006992002, 8.914624328861709],
              [38.723270464874098, 8.91459548628235],
              [38.723290676967999, 8.914566643700709],
              [38.723308643273697, 8.914533363795989],
              [38.723317626426599, 8.91451339585171],
              [38.7233311011558, 8.914493427906329],
              [38.7233445758851, 8.914482334602869],
              [38.723355804826099, 8.91446680397746],
              [38.723364787979001, 8.914444617368581],
              [38.723373771131797, 8.914424649419439],
              [38.723378262708202, 8.91440911879158],
              [38.723380508496497, 8.914404681469209],
              [38.723387245861097, 8.914386932179211],
              [38.723393983225698, 8.914369182888329],
              [38.723400720590298, 8.91435143359659],
              [38.7234029663786, 8.914331465642359],
              [38.723400720590298, 8.914313716348801],
              [38.723400720590298, 8.914295967054359],
              [38.723400720590298, 8.914271561773109],
              [38.723400720590298, 8.9142693431111],
              [38.723400720590298, 8.91423162585482],
              [38.7233962290139, 8.91420500190687],
              [38.723393983225698, 8.914185033944641],
              [38.723393983225698, 8.91416284731865],
              [38.723411949531403, 8.91412734871426],
              [38.7234411447781, 8.91410294342174],
              [38.723459111083798, 8.91409406876952],
              [38.723495043695202, 8.91408519411708],
              [38.723562417341498, 8.91410072475872],
              [38.723605087317502, 8.91408741278021],
              [38.723627545199598, 8.91408075679078],
              [38.723647757293499, 8.914076319464421],
              [38.7236679693874, 8.914069663474789],
              [38.723685935693098, 8.91406078882174],
              [38.723701656210501, 8.914051914168491],
              [38.723721868304402, 8.914045258178399],
              [38.7237330972455, 8.914045258178399],
              [38.723766784068602, 8.91404303951502],
              [38.723856615597001, 8.914045258178399],
              [38.723910514514102, 8.914027508870941],
              [38.723980133948601, 8.914007540898989],
              [38.7240250497128, 8.91400975956261],
              [38.724063228112399, 8.914005322235379],
              [38.724898661326598, 8.91345287457553],
              [38.726194481123997, 8.9119153351243],
              [38.727229789488902, 8.910723904000671],
              [38.727333095746602, 8.910670655591611],
              [38.728047256397502, 8.91001170588739],
              [38.730602963380797, 8.90563864593067],
              [38.7314945413003, 8.904515974713361],
              [38.734688052135297, 8.901567282316501],
              [38.738299279577497, 8.90098597220681],
              [38.7420048301245, 8.9004978485005],
              [38.742110382170303, 8.895816265337761],
              [38.7420362711594, 8.895743045788979],
              [38.7419778806659, 8.89570088907243],
              [38.741928473325302, 8.89565429480113],
              [38.741858853890797, 8.89561657562477],
              [38.741809446550199, 8.89556332501626],
              [38.741665716104698, 8.895479011536921],
              [38.741562409846999, 8.895403573144209],
              [38.741461349377602, 8.89533035351289],
              [38.741369272060901, 8.8952660089763],
              [38.741346814178797, 8.89524603997989],
              [38.741333339449596, 8.895219414649659],
              [38.741319864720303, 8.89519500809523],
              [38.741308635779298, 8.895157288871561],
              [38.741301898414598, 8.8951351010911],
              [38.741290669473599, 8.895095163082919],
              [38.741301898414598, 8.89505522507036],
              [38.741326602085003, 8.89501528705345],
              [38.741358043119902, 8.894946504903],
              [38.741371517849203, 8.89487994151931],
              [38.741376009425601, 8.89481115934343],
              [38.741376009425601, 8.89474237715463],
              [38.741400713095899, 8.894644750799969],
              [38.741400713095899, 8.894538249292451],
              [38.741396221519501, 8.894458373141489],
              [38.741364780484503, 8.89434077766529],
              [38.741331093661401, 8.894136649579201],
              [38.741306389991102, 8.89400352250521],
              [38.741283932108999, 8.89385264509618],
              [38.741236770556498, 8.89343329432393],
              [38.741234524768302, 8.893291291837169],
              [38.741268211591503, 8.89320032146515],
              [38.741306389991102, 8.893124882602921],
              [38.7414141878252, 8.893082725584909],
              [38.741573638788097, 8.89306497526008],
              [38.741694911351402, 8.89307385042261],
              [38.741784742879901, 8.89310269469931],
              [38.7418296586441, 8.89315594566572],
              [38.741930719113498, 8.89318700872589],
              [38.742029533794799, 8.893235822100831],
              [38.742128348476001, 8.893317917307639],
              [38.742256358403999, 8.89340888765043],
              [38.742597718211996, 8.893406668861861],
              [38.742761660751299, 8.893246916048771],
              [38.742775135480599, 8.89321141541418],
              [38.7427818728452, 8.89315150808549],
              [38.742775135480599, 8.89311156986092],
              [38.742768398115999, 8.893084944375451],
              [38.7427504318103, 8.89305831888805],
              [38.742734711292798, 8.89301838065332],
              [38.742714499198897, 8.8929651296669],
              [38.742714499198897, 8.89292962900498],
              [38.742718990775302, 8.892894128339631],
              [38.7427302197164, 8.892865284046479],
              [38.742759414963103, 8.89283643975107],
              [38.742772889692397, 8.892823126998589],
              [38.742817805456603, 8.8928075954534],
              [38.742862721220803, 8.89279428269986],
              [38.742903145408597, 8.89278096994583],
              [38.742945815384601, 8.892756563228859],
              [38.743008697454499, 8.89272550013218],
              [38.743076071100802, 8.892696655825761],
              [38.743127724229602, 8.892674467896191],
              [38.743163656840998, 8.89265006117213],
              [38.743219801546203, 8.892592372545129],
              [38.7432557341576, 8.89254577787824],
              [38.743305141498197, 8.89249252682308],
              [38.743336582533203, 8.892459244909681],
              [38.743359040415299, 8.892428181787791],
              [38.743392727238401, 8.892403775047301],
              [38.743430905638, 8.89238602468957],
              [38.743473575613997, 8.892368274330961],
              [38.743525228742797, 8.892348305176521],
              [38.743581373448102, 8.892330554816089],
              [38.743689171282199, 8.89229505409266],
              [38.743866588550802, 8.8922462405923],
              [38.743990106902402, 8.892204083473141],
              [38.744145066288901, 8.8921486135721],
              [38.744338204075, 8.8921486135721],
              [38.744508883978902, 8.892170801533529],
              [38.744677318094702, 8.892215177452339],
              [38.744755920682103, 8.89224402179668],
              [38.744830031692999, 8.892301710478559],
              [38.744899651127497, 8.89234608638151],
              [38.745319613522902, 8.892567965815591],
              [38.7466850527547, 8.89254577787824],
              [38.7457777543178, 8.890653141873621],
              [38.746274073512197, 8.89014947273116],
              [38.747008446256999, 8.88925085425913],
              [38.743700400223197, 8.88278961161358],
              [38.742921111714303, 8.88030005022536],
              [38.742790855998102, 8.878110021019779],
              [38.747515994392501, 8.87803679793288],
              [38.747028658350899, 8.876252812754229],
              [38.745178128865597, 8.86947625269498],
              [38.745494785003302, 8.86340963866475],
              [38.754179248012498, 8.860480586520181],
              [38.754008568108503, 8.85667944086814],
              [38.756443002528499, 8.853226644034249],
              [38.761536450189404, 8.853601660169501],
              [38.771492029325699, 8.855769645362059],
              [38.772102883718901, 8.856952379081021],
              [38.773874810616803, 8.85950644961131],
              [38.775646737514698, 8.85309572115143],
              [38.7783910907077, 8.852605314684681],
              [38.780086660806496, 8.85085005360262],
              [38.778415794377999, 8.84278373354144],
              [38.778305750755699, 8.8408065150553],
              [38.779576866882799, 8.837167162601901],
              [38.779920472478899, 8.83670558362468],
              [38.780398825367698, 8.836026528886199],
              [38.781299386440097, 8.834874795930171],
              [38.781667695706503, 8.83475718137954],
              [38.7848746812709, 8.83522320107687],
              [38.786785847037798, 8.834899206492571],
              [38.788232134645199, 8.835678124498809],
              [38.792002813050303, 8.835762451705589],
              [38.794978482429002, 8.83463956679139],
              [38.7969413013248, 8.83368533383995],
              [38.7988928912795, 8.83272000264345],
            ],
          ],
          [
            [
              [36.223416234344199, 8.951975773835439],
              [36.247897571624698, 8.973516115246291],
              [36.221015486747397, 8.988524829107799],
              [36.178967594085996, 8.98630439494533],
              [36.174269405150099, 8.97941009307895],
              [36.185125545358602, 8.97334308772826],
              [36.185684746622996, 8.971564005129309],
              [36.188146130501501, 8.96913494435621],
              [36.223416234344199, 8.951975773835439],
            ],
          ],
          [
            [
              [42.233697949003599, 9.185900130728619],
              [42.246007114184202, 9.18698866981063],
              [42.252888209260597, 9.18968894172845],
              [42.259427944529001, 9.19226061012856],
              [42.263557949047701, 9.196470568334551],
              [42.265837424081198, 9.19927939622028],
              [42.2721480889521, 9.21485058354445],
              [42.271887577519699, 9.21645998092178],
              [42.2686985582611, 9.229589817886151],
              [42.262237425579997, 9.243610250348871],
              [42.2563085447049, 9.252538702790311],
              [42.253629319369999, 9.26152012487818],
              [42.249328634947297, 9.270020346221081],
              [42.250287586512997, 9.285519698346301],
              [42.259888331112101, 9.29881982570209],
              [42.266028316079002, 9.30975919499204],
              [42.264948091949897, 9.32130987536463],
              [42.258498188209899, 9.334400332131461],
              [42.264588765836201, 9.349039255045181],
              [42.269558695145598, 9.360659542051851],
              [42.2661181476075, 9.365968758255841],
              [42.261249278767501, 9.37350034937289],
              [42.252978040789003, 9.383790379122789],
              [42.238059269708003, 9.38492926545903],
              [42.221858153558898, 9.379458588555419],
              [42.204758722125703, 9.37021873178476],
              [42.187418991353901, 9.36166998343907],
              [42.168558861963902, 9.362820021706479],
              [42.157448947687499, 9.37630997434943],
              [42.146597299055301, 9.387499501027261],
              [42.129127312567398, 9.38866053222381],
              [42.114208541486398, 9.372498805633059],
              [42.108688394065503, 9.36410965546767],
              [42.0996086723313, 9.350340019662349],
              [42.085237873573597, 9.329099498721771],
              [42.070447112420503, 9.303930521771751],
              [42.064657470414403, 9.284879169551941],
              [42.060487041707901, 9.265860028911931],
              [42.058856599467198, 9.249419976654149],
              [42.059716736351703, 9.237640828495911],
              [42.061796336234501, 9.22725117764414],
              [42.060987852478803, 9.22591005467952],
              [42.073667572714101, 9.22095118053215],
              [42.090827640428998, 9.225109811936081],
              [42.112387207247899, 9.23072920665977],
              [42.120597808944702, 9.228430474946389],
              [42.127328436211002, 9.226539607251651],
              [42.137248082735901, 9.215810459171511],
              [42.148537660069003, 9.206249272154929],
              [42.1656977277839, 9.21041029352592],
              [42.182127914330501, 9.21687895506879],
              [42.197437452560102, 9.21986052921652],
              [42.208468764249098, 9.21191995405766],
              [42.220768946276898, 9.19635972226218],
              [42.233697949003599, 9.185900130728619],
            ],
          ],
        ],
      },
    },
  ],
};

export default ethiopianRegions;
import Chart from "chart.js";
import { useRef, useEffect, useState } from "react";
import LineChart from "./chart_types/CustomChart";
import Dashboard from "./pages/Dashboard/dashboard";


export default () => {
  const [data, setData] = useState([0, 1, 2, 3, 4, 5, 6, 7]);

  const onClick = () => {
    setData((prevData) => prevData.slice(1).concat(10 * Math.random()));
  };

  return (
   <Dashboard/>
  );
};

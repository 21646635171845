/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import "./styles.css";
import {
  FaBars,
  FaChevronDown,
  FaTimes,
  FaChartBar,
  FaPlus,
  FaTable,
  FaCog,
  FaBalanceScale,
  FaComment,
  FaChevronUp,
  FaInfoCircle,
  FaQuestionCircle
} from "react-icons/fa";
import mohLogo from "../../assets/images/moh-logo.png";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";

type NavigationProps = {
  onShowChange: () => void; // Specify the type of the onShowChange prop function
  onNavItemClicked: (navItem: string) => void;
};
const Navigation = ({ onShowChange, onNavItemClicked }: NavigationProps) => {
  const [showServiceReport, setShowServiceReport] = useState(false);
  const [showServiceList, setShowServiceList] = useState(false);
  const [showAdditionalList, setShowAdditionalList] = useState(false);
  const [showTabularList, setShowTabularList] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState("dashboard");
  return (
    <div className="navigation">
      <div>
        <div className="navHeader">
          <img src={mohLogo} className="logo" />
          <FaTimes
            color="white"
            size={20}
            className="icon"
            onClick={() => {
              onShowChange();
            }}
          />
        </div>
        <nav>
          <ul>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <li
                className={`parentList ${
                  selectedNavItem === "dashboard" ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedNavItem("dashboard");
                  // onNavItemClicked('dashboard');
                }}
              >
                <p>
                  {" "}
                  <FaChartBar
                    color="white"
                    size={16}
                    className="li-icon"
                  />{" "}
                  Dashboard
                </p>
              </li>
            </Link>
       
            {/* <li
              className="parentList"
              onClick={() => {
                setShowServiceList(!showServiceList);
              }}
            >
              <p>
                <FaBars color="white" size={16} className="li-icon" />
                Service Reports
              </p>

              <div className="expand-icon">
                {showServiceList ? (
                  <FaChevronUp color="white" size={15} className="li-icon" />
                ) : (
                  <FaChevronDown color="white" size={15} className="li-icon" />
                )}
              </div>
            </li>
            {showServiceList && (
              <ul>
                <Link to="/EHS" style={{ textDecoration: "none" }}>
                  <li
                    className={`parentList ${
                      selectedNavItem === "EHS" ? "selected" : ""
                    }`}
                    onClick={() => {
                      setSelectedNavItem("EHS");
                    }}
                  >
                    <p>Essenial Health Service</p>
                  </li>
                </Link>
                <li
                  className={`parentList ${
                    selectedNavItem === "VI" ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedNavItem("VI");
                    onNavItemClicked("VI");
                  }}
                >
                  <p>Vaccination / Immunization</p>
                </li>
                <li
                  className="parentList"
                  onClick={() => onNavItemClicked("DP")}
                >
                  <p>Disease Prevention</p>
                </li>
                <li
                  className="parentList"
                  onClick={() => onNavItemClicked("CS")}
                >
                  <p>Clinical Services</p>
                </li>
                <li
                  className="parentList"
                  onClick={() => onNavItemClicked("PHE")}
                >
                  <p>Public Health Emmergency</p>
                </li>
                <li
                  className="parentList"
                  onClick={() => onNavItemClicked("MHS")}
                >
                  <p>Mental Health and SGBV</p>
                </li>
              </ul>
            )} */}

            {/* <li
              className="parentList"
              onClick={() => {
                setShowAdditionalList(!showAdditionalList);
              }}
            >
              <p>
                {" "}
                <FaPlus color="white" size={16} className="li-icon" />{" "}
                Additional Reports
              </p>
              <div className="expand-icon">
                {showAdditionalList ? (
                  <FaChevronUp color="white" size={15} className="li-icon" />
                ) : (
                  <FaChevronDown color="white" size={15} className="li-icon" />
                )}
              </div>
            </li> */}
            {showAdditionalList && (
              <ul>
                <li className="parentList">
                  <p>Tigray Region Report</p>
                </li>
              </ul>
            )}

            <li
              className="parentList"
              onClick={() => {
                setShowTabularList(!showTabularList);
              }}
            >
              <p>
                <FaTable color="white" size={16} className="li-icon" />
                Tabular Reports
              </p>

              <div className="expand-icon">
                <FaChevronDown color="white" size={15} className="li-icon" />
              </div>
            </li>
            {showTabularList && (
              <ul>
              
                <Link
                  to="/pivot-data-visualizer"
                  style={{ textDecoration: "none" }}
                >
                  <li
                    className={`parentList ${
                      selectedNavItem === "pivot-data-visualizer"
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedNavItem("pivot-data-visualizer");
                      // onNavItemClicked('dashboard');
                    }}
                  >
                    <p>Pivot Table</p>
                  </li>
                </Link>
              </ul>
            )} 
            <Link to={"/login"}   style={{ textDecoration: "none" }}>
            <li      className={`parentList ${
                  selectedNavItem === "admin-login" ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedNavItem("admin-login");
                  // onNavItemClicked('dashboard');
                }}
                >
              <p>
                {" "}
                <FaCog color="white" size={16} className="li-icon" />{" "}
                Admin Portal
              </p>
            </li>
            </Link>
             <Link
                  to="/feedback"
                  style={{ textDecoration: "none" }}
                >
            <li className={`parentList ${
                      selectedNavItem === "feedback"
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedNavItem("feedback");
                      // onNavItemClicked('dashboard');
                    }}
                    >
              <p>
                {" "}
                <FaComment color="white" size={16} className="li-icon" />
                Feedback
              </p>
            </li>
            </Link>

            <Link
                  to="/userguide"
                  style={{ textDecoration: "none" }}
                >
            <li className={`parentList ${
                      selectedNavItem === "userguide"
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedNavItem("userguide");
                      // onNavItemClicked('dashboard');
                    }}
                    >
              <p>
                {" "}
                <FaInfoCircle color="white" size={16} className="li-icon" />
                User Guide
              </p>
            </li>
            </Link>


            <Link
                  to="/faq"
                  style={{ textDecoration: "none" }}
                >
            <li className={`parentList ${
                      selectedNavItem === "faq"
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedNavItem("faq");
                      // onNavItemClicked('dashboard');
                    }}
                    >
              <p>
                {" "}
                <FaQuestionCircle color="white" size={16} className="li-icon" />
                  Frequently Asked Questions
              </p>
            </li>
            </Link>
          </ul>
        </nav>
      </div>
      <div className="footer">
        <p className="footerText">Federal Ministry of Health © 2023</p>
      </div>
    </div>
  );
};

export default Navigation;

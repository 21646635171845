import ChartHeader from "../../component/ChartHeader/chartHeader";
import CustomChart from "../../chart_types/CustomChart";
import Modal from "react-modal";
import React, { useState, useEffect } from "react";
import modalStyle from "../../styles/modal";
import Table from "../../Table/dataTable";
import getFAF from "../../service/functionalHealthFacilities";
import { ColorRing } from "react-loader-spinner";
import CustomChartProp from "../../models/CustomChart";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import Dataset from "../../models/Dataset";
import { TableColumn } from "react-data-table-component";

import { DataObject } from "@mui/icons-material";
import theme from "../../utils/theme";
import "./styles.css";
import Backdrop from "@mui/material/Backdrop";
import exportData from "../../utils/exporttocsv";
const FunctionalHealthFacilities = () => {
  const [modalIsOpen, setModal] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [rawData, setRawData] = useState<any>({});
  const [fhfData, setFhfData] = useState<CustomChartProp>({});
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumn<any>[]>([]);
  const [error, setError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  //todo: chartType has to be initialized by the type from the api.
  const [chartType, setChartType] = useState("");

  function closeModal() {
    setModal(false);
  }

  const expandChart = () => {
    setModal(true);
  };

  async function fetchData() {
    setLoading(true);
    try {
      await getFAF().then((res: any) => {
        setRawData(res.data);

        let dataObject = {
          labels: res.data.labels,
          datasets: res.data.datasets.map((dataset: any) => {
            // Adding backgroundColor property to each dataset
            dataset.backgroundColor =
              dataset.label === "Hospital"
                ? theme.teritaryChartColor
                : theme.primaryChartColor;
            dataset.barPercentage = 0.5; // Adjust bar width
            dataset.categoryPercentage = 0.6; // Replace with your desired background colors
            return dataset;
          }),
        };

        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      // Process data here, set state variables, etc.
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    setError(false);

    fetchData();
  }, []);

  useEffect(() => {
    setError(false);

    fetchData();
  }, [tryAgain]);
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "white",
          zIndex: 10000,
          height: "100%",
          padding: "50px",
        }}
        open={modalIsOpen}
      >
        <div
          style={{
            height: "100%",
            width: "90%",
            alignSelf: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <FaTimesCircle
              size={20}
              color="#25476A"
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            />
          </div>
          {loading ? (
            <div className="loadingContainer">
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
              />
            </div>
          ) : (
            <div>
              {error ? (
                <div className="loadingContainer">
                  <p className="errorMessage">Error fetching data</p>
                  <p
                    className="tryAgain"
                    onClick={() => {
                      setTryAgain(!tryAgain);
                    }}
                  >
                    Try Again!
                  </p>
                </div>
              ) : (
                <div>
                  <ChartHeader
                    forExpanded={true}
                    title={"Functional Health Facilities by Region"}
                    onExpand={expandChart}
                    onChangeChartType={(newType) => {
                      setChartType(newType);
                    }}
                    showTypes={false}
                    type={chartType}
                    onExport={() =>
                      exportData(
                        rawData,
                        "Functional Health Facilities by Region"
                      )
                    }
                  />
                  {/* <div style={{width:'500px',height:'500px', backgroundColor:'blue'}}> */}
                    <CustomChart props={fhfData} type={chartType} />
                  {/* </div> */}
                </div>
              )}
            </div>
          )}
        </div>
      </Backdrop>
      <>
        {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  title={"Functional Health Facilities by Region"}
                  onExpand={expandChart}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  type={chartType}
                  onExport={() =>
                    exportData(
                      rawData,
                      "Functional Health Facilities by Region"
                    )
                  }
                />
                {chartType === "bar" && (
                  <CustomChart props={fhfData} type={chartType} />
                )}
                {chartType === "pie" && (
                  <CustomChart props={fhfData} type={chartType} />
                )}
                {chartType === "line" && (
                  <CustomChart props={fhfData} type={chartType} />
                )}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default FunctionalHealthFacilities;

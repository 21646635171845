import {
  FaFilter,
  FaExpand,
  FaChartBar,
  FaChartPie,
  FaChartLine,
  FaChartArea,
} from "react-icons/fa";
import { useState, useRef, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import "./styles.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import TableViewIcon from "@mui/icons-material/TableView";
import { CSVLink } from "react-csv";
import theme from "../../utils/theme";
import { InfoOutlined } from "@mui/icons-material";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import React from "react";
import { Button } from "@mui/material";

type HeaderProps = {
  title: String;
  onExpand: () => void;
  onChangeChartType: (newType: string) => void;
  type?: String;
  showTypes?: boolean;
  showExport?: boolean;
  forExpanded?:boolean;
  onExport?:()=>void;
};

const ChartHeader = (props: HeaderProps) => {
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const referenceElement = useRef<HTMLButtonElement | null>(null);
  const popperElement = useRef<HTMLDivElement | null>(null);
  const [chartType, setChartType] = useState(props.type);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  
  // const [anchorElExpanded, setAnchorElExpanded] = useState<HTMLButtonElement | null>(null);
  const [anchorElInfo, setAnchorElInfo] = useState<HTMLButtonElement | null>(
    null
  );

  const [anchorElExpanded, setAnchorElExpanded] = React.useState<null | HTMLElement>(null);
  const openExpanded = Boolean(anchorEl);

  const handleClickExpanded = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExpanded(event.currentTarget);
  };
  const handleCloseExpanded = () => {
    setAnchorElExpanded(null);
  };


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleClickExport = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickExport2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElExpanded(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElInfo(null);
  };

  const open = Boolean(anchorEl);
  const openInfo = Boolean(anchorElInfo);
  const id = openInfo ? "simple-popover" : undefined;

  const headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
  ];

  const data = [
    { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        setAnchorEl(null);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl, open]);


  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: props.forExpanded === true ?  "space-between" : "center",
          alignContent: "center",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        <p className="chartTitle" style={{ flex: 1, textAlign: props.forExpanded === true ? 'left':'center', fontWeight: props.forExpanded === true ? 'bold':'normal', fontSize: props.forExpanded === true ? '24px':'smaller', }}>
          {props.title}
        </p>

        <IconButton aria-describedby={id} onClick={handleClick}>
          <InfoOutlined style={{ color: theme.greenChartColor }} />
        </IconButton>
        {props.forExpanded === true && (
                 <div>
              <Button className="exportButton" variant="outlined" color="success" onClick={props.onExport}>
                   Export As CSV
                 </Button>
               
               </div>

             )}   
            

      </div>
      <Popover
        id={id}
        open={openInfo}
        anchorEl={anchorElInfo}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 2, color: theme.textColor, width: "200px" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget
          nulla porttitor, efficitur elit id, lacinia enim. Vestibulum a leo
          lacus. Etiam ornare facilisis felis nec tincidunt. Duis mattis
          hendrerit lectus eget convallis. Nulla facilisi.
        </Typography>
      </Popover>
      {props.showExport === false ? (
        <></>
      ) : (
        <>
          <div className="exportButContainer">
            <div>
             {!props.forExpanded === true && (
                    <>
                        <button
                id="export-menu2"
                onClick={props.onExport}
                className="exportButton"
              >
                Export
              </button>
              <Menu
                id="export-menu2"
                aria-labelledby="demo-positioned-button"
                style={{ marginTop: 30 }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {}}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <CSVLink
                    data={data}
                    headers={headers}
                    style={{
                      textDecoration: "none",
                      color: theme.textColor,
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <ListItemIcon>
                      <TableViewIcon color="info" fontSize="small" />
                    </ListItemIcon>{" "}
                    .csv
                  </CSVLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <TableViewIcon color="success" fontSize="small" />
                  </ListItemIcon>
                  .xlsx
                </MenuItem>
              </Menu>
                    </>

             )}   
            

              {props.showTypes == false ? (
                <></>
              ) : (
                <>
                  <FaFilter
                    className="chartTypeIcon"
                    onClick={() => {
                      setTooltipVisible(!tooltipVisible);
                    }}
                  />
                  {tooltipVisible && (
                    <ul className="filterPopup">
                      <li className={chartType == "bar" ? "active" : ""}>
                        <FaChartBar
                          className="chartFilter"
                          color={chartType == "bar" ? "white" : "#7E909A"}
                          onClick={() => {
                            props.onChangeChartType("bar");
                            setChartType("bar");
                          }}
                        />
                      </li>
                      <li className={chartType == "pie" ? "active" : ""}>
                        <FaChartPie
                          className="chartFilter"
                          color={chartType == "pie" ? "white" : "#7E909A"}
                          onClick={() => {
                            props.onChangeChartType("pie");
                            setChartType("pie");
                          }}
                        />
                      </li>
                      <li className={chartType == "line" ? "active" : ""}>
                        <FaChartLine
                          className="chartFilter"
                          color={chartType == "line" ? "white" : "#7E909A"}
                          onClick={() => {
                            props.onChangeChartType("line");
                            setChartType("line");
                          }}
                        />
                      </li>
                      <li>
                        <FaChartArea className="chartFilter" color="#7E909A" />
                      </li>
                    </ul>
                  )}
                </>
              )}

             {props.forExpanded === true ? <></> : <FaExpand className="chartTypeIcon" onClick={props.onExpand} />} 
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChartHeader;

import axios from "axios";

async function getMAMByRegion() {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://hed.k8s.sandboxaddis.com/malnutrition/total_malnutration/by_region/all/mam",
    headers: {},
  };
  try {
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export default getMAMByRegion;

import axios from "axios";

async function getFAF() {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://hed.k8s.sandboxaddis.com/stat/get_functional_health_facilities",
    headers: {},
  };
  try {
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export default getFAF;

import { Button, TextField } from "@mui/material";

import mohLogo from "../../assets/images/moh-logo.png";
import "./styles.css";
import { useFormik } from "formik";
function AdminPortal() {
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
        alert(JSON.stringify(values,null))
    },
  });

  return (
    <div className="main">
      <div className="formWrapper">
        <div className="header">
          <div className="icon">
            <img src={mohLogo} className="logo" />
          </div>
          <div className="headerText">
            <p>Login</p>
            <p>Please enter your username and password</p>
          </div>
        </div>
        <div className="inputWrapper">
          <TextField
            size="small"
            name="username"
            id="outlined-basic"
            label="User Name"
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.username}
          />
          <TextField
            size="small"
            name="password"
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <Button
            variant="contained"
            color="info"
            style={{ fontWeight: "bold", fontSize: "12px" }}
            onClick={()=>{
                formik.handleSubmit()}}
          >
            Login
          </Button>
          <div className="footerWrapper">
            <p>Didn't have an account?</p>
            <a href=""> sign up</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPortal;

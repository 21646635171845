import React, { useState, useEffect } from "react";
import Navigation from "../../component/Navigation/navigation";
import Visualization from "../../component/Visuals/visualization";

import Header from "../../component/Header/header";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  Outlet,
  useNavigate,
} from "react-router-dom";
import "../../utils/theme";
import "./styles.css";
import Pivot from "../../component/Table/PivotTable/pivotTable";
import FeedBack from "../../component/Feedback";
import UserGuide from "../../component/UserGuide";
import FAQ from "../../component/FAQ";
import HeatMaps from "../../component/HeatMap";
import InstitutionsOnMap from "../../component/InstitutionsOnMap";
import PivotExpermental from "../../component/PivotExpermental";
import AdminPortal from "../../component/AdminPortal";
import MobileNavigation from "../../component/MobileNavigation";

const Dashboard = () => {
  const [showNavigation, setShowNavigation] = useState(true);
  const [showMobileNavigation, setShowMobileNavigation] = useState(true);
  const [selectedNavItem, setSelectedNavItem] = useState("");
  const [showBarIcon, setShowBarIcon] = useState(false);
  const toggleNavigation = () => {
    setShowNavigation(!showNavigation);
  };
  const toggleMobileNavigation = () => {
    setShowMobileNavigation(!showMobileNavigation);
  };
  // Function to handle the navigation item click
  const handleNavigationItemClick = (itemName: string): void => {
    setSelectedNavItem(itemName);
  };
  useEffect(() => {
    // Function to update showBarIcon based on screen width
    const updateShowBarIcon = () => {
      setShowBarIcon(window.innerWidth <= 767); // Adjust the breakpoint as needed
    };

    // Initial update on component mount
    updateShowBarIcon();

    // Event listener to update showBarIcon on window resize
    window.addEventListener('resize', updateShowBarIcon);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateShowBarIcon);
    };
  }, []);
  return (
    <Router>
      <div className="dashboardWrapper">
        {/* {showNavigation && ( */}
        {/* <div
          className="navigationContainer"
          // style={{ display: showNavigation ? "block" : "none" }}
        >
          {showNavigation && (
            <Navigation
              onShowChange={toggleNavigation}
              onNavItemClicked={handleNavigationItemClick}
            />
          )}
        </div> */}

        {/* )} */}
          <div
          className="navigationContainer"
          // style={{ display: showNavigation ? "block" : "none" }}
        >
           <Navigation
              onShowChange={toggleNavigation}
              onNavItemClicked={handleNavigationItemClick}
            />
        </div>
       
        <div className="mainWrapper" style={showNavigation ? { flex: 1 } : {}}>
          <div className="mobileNavigationContainer">
          {!showNavigation && (
            <Navigation
              onShowChange={toggleNavigation}
              onNavItemClicked={handleNavigationItemClick}
            />
          )}
          </div>
       
          <div className="header">
            <Header
              showBarIcon={false}
              onToggle={() => {
                setShowNavigation(!showNavigation);
                console.log('hfuierufhi');
              }}
            />
          </div>
          <div className="mobileHeader">
            <Header
              showBarIcon={true}
              onToggle={() => {
                setShowNavigation(!showNavigation);
                console.log('hfuierufhi');
              }}
            />
          </div>
          <div className="visualsContainer">
            <Routes>
              <Route path="/" element={<Visualization />} />
              <Route path="/dashboard" element={<Visualization />} />
              <Route path="/login" element={<AdminPortal />} />
              <Route path="/pivot-data-visualizer" element={<Pivot />} />
              <Route path="/feedback" element={<FeedBack />} />
              <Route path="/userguide" element={<UserGuide />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/pivotExperment" element={<PivotExpermental />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Dashboard;

import axios from "axios";
import { ServiceProps } from "../utils/ServiceProp";

async function getMaleriaReport(props:ServiceProps) {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://hed.k8s.sandboxaddis.com/MalariaReport/getMalariaAndOpdReport?years=${props.year}&quarter=${props.quarter}&months=${props.month}&weeks=${props.weeks}`,
    headers: {},
  };
  try {
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export default getMaleriaReport;

/* eslint-disable react/jsx-no-undef */
import "./styles.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Modal from "react-modal";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Theme, useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useRef, useState, useEffect } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SaveIcon from "@mui/icons-material/Save";
import { getPivotTable, getAllDimensions, savePivotTable, getAllSavedTables } from "../../../service/pivotTable";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridToolbar,
} from "@mui/x-data-grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { Key } from "readline";
import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  TextField,
  Box,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { error } from "console";
const Pivot = () => {
  interface Dimension {
    tableName: string;
    title?: string;
    columnName: String[]; // dimension name
  }

  const [modalIsOpen, setOpenModal] = useState(false);
  const [checked, setChecked] = useState([0]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [SaveModal, setShowSaveModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [dimensions, setDimensions] = useState<Dimension[]>([]);
  const [savedTables, setSavedTables] = useState<any>([]);
  const [saveTableName, setSaveTableName] = useState("");
  const [selectedDimension, setSelectedDimension] = useState<Dimension[]>([]);
  const [usedDimensions, setUsedDimensions] = useState<Dimension[]>([]);
  const [pivotPayload, setPivotPayload] = useState<Dimension[]>([]);
  let [tempSelectedIndicators, setTempIndicators] = useState<String[]>();
  const [renderTable, setRenderTable] = useState(false);
  const [allIndicators, setAllIndicators] = useState(false);
  const [columnNames, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [Periods, setPeriods] = useState<string[]>([]);
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const period = [
    "Last 3 Months",
    "Last 6 Months",
    "Last 5 Months",
    "Last 8 Months",
  ];

  // const [columns, setColumns] = useState([]);
  // const [rows, setRows] = useState([]);

  const [selectedPayloadColnames, setSelectedDimensionPayloadColnames] =
    useState<any>([]);
  useEffect(() => {
    setLoading(true);

    async function getDimensions() {
      await getAllDimensions().then((res: any) => {
        console.log(res.data);

        setDimensions(res.data);
        setLoading(false);
      });
    }

    async function getSavedTables() {
      await getAllSavedTables().then((res: any) => {
        console.log('saved tables: ',res);
        setSaveLoading(false);
        setSavedTables(res.data);
        setLoading(false);
      });
    }
    setTimeout(() => {
      getDimensions();
      getSavedTables();
    }, 1000);
  }, []);
  function getStyles(name: string, Periods: string[], theme: Theme) {
    return {
      fontWeight:
        Periods.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  async function createTable() {
    // return { name, calories, fat, carbs, protein };
    let tableColumns = pivotPayload.map((payload) => {
      return payload.columnName;
    });

    console.log("table columns: ", pivotPayload);

    await getPivotTable(JSON.stringify(pivotPayload)).then((res: any) => {
      console.log("res😒: ", res.data);

      const dataArrayWithId = res.data.map((item: any, index: any) => {
        return { id: index, ...item };
      });
      setRows(dataArrayWithId);
      let columns = Object.keys(res.data[0]);
      console.log("returned columns: ", columns);
      let columnsReturned: GridColDef[] = [];
      columns.forEach((element) => {
        let colObj = {
          field: element,
          headerName: element.toString().replaceAll(/_/g, " "),
          width: 250,
          editable: false,
        };
        columnsReturned.push(colObj);
      });

      setColumns(columnsReturned);
    });
    setRenderTable(true);
  }
  
  async function openTable(payload:any) {
    // return { name, calories, fat, carbs, protein };
    let tableColumns = pivotPayload.map((payload) => {
      return payload.columnName;
    });

    console.log("table columns: ", pivotPayload);

    await getPivotTable(JSON.parse(payload)).then((res: any) => {
      console.log("res😒: ", res.data);

      const dataArrayWithId = res.data.map((item: any, index: any) => {
        return { id: index, ...item };
      });
      setRows(dataArrayWithId);
      let columns = Object.keys(res.data[0]);
      console.log("returned columns: ", columns);
      let columnsReturned: GridColDef[] = [];
      columns.forEach((element) => {
        let colObj = {
          field: element,
          headerName: element.toString().replaceAll(/_/g, " "),
          width: 250,
          editable: false,
        };
        columnsReturned.push(colObj);
      });

      setColumns(columnsReturned);
    });
    setRenderTable(true);
  }

  function generateTable() {
    setOpenModal(false);
    setTableLoading(true);
    setTimeout(() => {
      createTable()
        .then((res) => {
          setTableLoading(false);
        })
        .catch((error) => {
          setTableLoading(false);
        });
    }, 2000);
  }

  
  function openSavedTable(payload:any) {
    setOpenModal(false);
    setTableLoading(true);
    setTimeout(() => {
      openTable(payload)
        .then((res) => {
          setTableLoading(false);
        })
        .catch((error) => {
          setTableLoading(false);
        });
    }, 2000);
  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessMessage(false);
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const closeModal = () => {
    console.log("close");
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #f1f1f1',
    boxShadow: 24,
    borderRadius:2,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "55%",
      right: "auto",
      width: "50%",
      bottom: "auto",
      zIndex: 300,
      //   marginRight: '-50%',
      transform: "translate(-50%, -50%)",
    },
  };
  const customStyles2 = {
    content: {
      top: "50%",
      left: "55%",
      right: "auto",
      width: "30%",
      bottom: "auto",
      zIndex: 300,
      //   marginRight: '-50%',
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <div className="container">
       <Snackbar open={successMessage} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Table Saved Successfullly!
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={tableLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        isOpen={SaveModal}
        onRequestClose={closeModal}
        style={{...customStyles2}}
      >
        <Box sx={{  display:'flex', flexDirection:'column', gap:2 }}>
          <Typography variant='body2' color={'black'}>Add New Table</Typography>
          <TextField
            onChange={(val) => {
              setSaveTableName(val.target.value);
            }}
            label="Table Name"
            variant="outlined"
            size="small"
            sx={{padding:0}}
          />

        <Box sx={{display:'flex', flexDirection:'row', gap:2}}>
            <Button
              variant="outlined"
              color="error"
              style={{ fontSize: 12, fontWeight: "normal", flex:'0.5' }}
              onClick={() => {
                setSaveLoading(false);
                setShowSaveModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              style={{ fontSize: 12, fontWeight: "normal",  flex:'0.5' }}
              onClick={() => {
                setSaveLoading(true);
              setTimeout(async() => {
               let saveTablePayload = {
                 title: saveTableName,
                 body: JSON.stringify(pivotPayload),
                };
              await savePivotTable(saveTablePayload).then((res)=>{
               setSaveLoading(false);
               setShowSaveModal(false);
               setSuccessMessage(true);
              }).catch((err)=>{
                console.log(err);
              })
            }, 4000);
             
                
              }}
            >
                {saveLoading ?  <CircularProgress color="inherit" size={'16px'} /> :  'Save' }
            </Button>
            </Box>
        </Box>
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginBottom: "10px",
          }}
        >
          <div className="titleContainer">
            <h2 style={{ fontWeight: "normal" }}>
              {selectedDimension[0]?.title}
            </h2>
          </div>

          <div className="instructionContainer">
            <FormControlLabel
              style={{ alignSelf: "flex-start" }}
              control={
                <Checkbox
                  checked={allIndicators}
                  onChange={() => {
                    setAllIndicators(!allIndicators);
                  }}
                />
              }
              label="Select All indicators"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={allIndicators === true ? false : true}
                  onChange={() => {
                    setAllIndicators(!allIndicators);
                  }}
                />
              }
              label="Manually select indicators"
            />
          </div>
          <div>
            {!allIndicators ? (
              <List
                sx={{
                  width: "100%",
                  maxHeight: 200,
                  overflow: "auto",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {selectedDimension[0]?.columnName.map((colname: String) => {
                  const labelId = `checkbox-list-label-${colname}`;
                  // const currentPayloadColnames = selectedPayloadColnames[0];
                  const isChecked = selectedPayloadColnames.includes(colname); // Check if colname is in selectedPayloadColnames

                  return (
                    <ListItem disablePadding dense>
                      <ListItemButton role={undefined} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            defaultChecked={isChecked}
                            tabIndex={-1}
                            onChange={() => {
                              if (!isChecked) {
                                setSelectedDimensionPayloadColnames(
                                  (prevCols: any) => [...prevCols, colname]
                                ); // Add to selectedPayloadColnames
                              } else {
                                setSelectedDimensionPayloadColnames(
                                  (prevCols: any) =>
                                    prevCols.filter(
                                      (item: any) => item !== colname
                                    )
                                ); // Remove from selectedPayloadColnames
                              }
                            }}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={colname}
                          primaryTypographyProps={{
                            color: "black",
                            fontWeight: "small",
                            variant: "body2",
                            fontFamily: "",
                            fontSize: 12,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <div style={{ height: 100 }}>
                <h6
                  style={{
                    textAlign: "left",
                    fontWeight: "normal",
                    fontSize: 15,
                  }}
                >
                  All indicators are selected from{" "}
                  {selectedDimension[0].tableName}
                </h6>
              </div>
            )}
          </div>
          <div className="modalFooter">
            <Button
              variant="outlined"
              color="error"
              style={{ fontSize: 12, fontWeight: "normal" }}
              onClick={() => {
                setOpenModal(!modalIsOpen);
                setTempIndicators([]);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              style={{ fontSize: 12, fontWeight: "normal" }}
              onClick={() => {
                console.log(tempSelectedIndicators);

                let payload: Dimension = {
                  title: selectedDimension[0].title,
                  tableName: selectedDimension[0].tableName,
                  columnName: selectedPayloadColnames,
                };
                console.log(
                  "temp selected indicators: ",
                  tempSelectedIndicators
                );

                let payloadToBeUpdated = pivotPayload.filter(
                  (payload: Dimension) => {
                    return payload.tableName === selectedDimension[0].tableName;
                  }
                );

                if (payloadToBeUpdated.length === 0) {
                  pivotPayload.push(payload);
                } else {
                  console.log("payload exsist");

                  pivotPayload.forEach((element, index) => {
                    if (element.tableName === selectedDimension[0].tableName) {
                      console.log("here is the  ppayhload: ", element);
                      if (allIndicators) {
                        element.columnName = selectedDimension[0]?.columnName;
                      } else {
                        element.columnName = payload.columnName;
                      }
                    }
                  });
                  setPivotPayload(pivotPayload);
                  console.log(pivotPayload);
                  // pivotPayload[0].columnName = tempSelectedIndicators;
                }
                let updatedDimensions: Dimension[] = [
                  ...usedDimensions,
                  payload,
                ];
                setUsedDimensions(updatedDimensions);
                setTempIndicators([]);
                setSelectedDimensionPayloadColnames([]);
                setOpenModal(false);
                generateTable();
                console.log(pivotPayload);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <div className="tableWrapper">
        {renderTable ? (
          <>
            <div style={{ maxWidth: "1200px", height: "80vh" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={1}
                sx={{ mb: 0 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From"
                      onChange={(val) => {
                        console.log(val);
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To"
                      sx={{ padding: 0 }}
                      onChange={(val) => {
                        console.log(val);
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderTop: "1px solid #f5f5f5",
                    borderLeft: "1px solid #f5f5f5",
                    borderRight: "1px solid #f5f5f5",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <Button
                    size="small"
                    variant="text"
                    color="success"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      setShowSaveModal(true);
                    }}
                  >
                    <SaveIcon sx={{ fontSize: "14px" }} /> Save table
                  </Button>
                </div>
              </Stack>
              <DataGrid
                density="compact"
                rows={rows}
                columns={columnNames}
                slots={{ toolbar: GridToolbar }}
              />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <AddBoxIcon /> */}
              <h6
                style={{
                  textAlign: "left",
                  color: "black",
                  fontWeight: "normal",
                  padding: 5,
                  borderBottomWidth: 1,
                  borderBottomColor: "grey",
                  fontSize: 18,
                }}
              >
                Select Dimensions and indicators!
              </h6>
            </div>
          </>
        )}
      </div>
      <div className="diemensionsWrapper">
        <>
          <div className="dimensionContainer">
            <p
              style={{
                textAlign: "left",
                color: "black",
                fontWeight: "bold",
                padding: 5,
                borderBottomWidth: 1,
                borderBottomColor: "grey",
                fontSize: 14,
              }}
            >
              Dimensions
            </p>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "40%",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <LinearProgress color="info" />
              </div>
            ) : (
              <>
                <List
                  dense={true}
                  sx={{
                    width: "100%",
                    height: '40%',
                    position: "relative",
                    overflow: "auto",
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {dimensions.map((dimension: Dimension, index: any) => (
                    <ListItem
                      dense={true}
                      sx={{
                        backgroundColor: usedDimensions.some(
                          (value) => value.title === dimension.title
                        )
                          ? "#17B169"
                          : "#D3D3D3",
                        margin: "4px",
                      }}
                      key={`item-${index}-${dimension}`}
                      onClick={(value: any) => {
                        console.log(
                          "selected dimensionsP: ",
                          selectedDimension
                        );

                        let tempSelectedDimension: any = dimensions.filter(
                          (value: Dimension) => {
                            return value.title === dimension.title;
                          }
                        );

                        console.log("temp selected Dimension: ", value);
                        let pivotPayloadColnames: any = pivotPayload.filter(
                          (payload) => {
                            return payload.tableName === dimension.tableName;
                          }
                        );

                        let pivotPayloadColnamesMapped =
                          pivotPayloadColnames.map((value: any) => {
                            return value.columnName;
                          });
                        if (pivotPayloadColnamesMapped.length !== 0) {
                          console.log(
                            "pivot payload colnames: ",
                            pivotPayloadColnamesMapped[0]
                          );
                          setTempIndicators(pivotPayloadColnamesMapped[0]);
                          setSelectedDimensionPayloadColnames(
                            pivotPayloadColnamesMapped[0]
                          );
                        }

                        setSelectedDimension(tempSelectedDimension);

                        console.log(
                          "temp selected dimensions: ",
                          tempSelectedDimension
                        );

                        setOpenModal(true);
                      }}
                    >
                      <ListItemText
                        sx={{
                          color: usedDimensions.some(
                            (value) => value.title === dimension.title
                          )
                            ? "white"
                            : "black",
                          fontWeight: "bold",
                        }}
                        primary={dimension.title}
                      />
                      <ListItemAvatar>
                        {usedDimensions.some(
                          (value) => value.title === dimension.title
                        ) && (
                          <Avatar variant="rounded" sx={{ bgcolor: "#00AB66" }}>
                            <p style={{ fontSize: "12px" }}>
                              {usedDimensions.filter(
                                (value) => value.title === dimension.title
                              ).length !== 0
                                ? usedDimensions.filter(
                                    (value) => value.title === dimension.title
                                  )[0].columnName.length
                                : ""}
                            </p>
                          </Avatar>
                        )}
                      </ListItemAvatar>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </div>
          <p
              style={{
                textAlign: "left",
                color: "black",
                fontWeight: "bold",
                padding: 5,
                borderBottomWidth: 1,
                borderBottomColor: "grey",
                fontSize: 14,
              }}
            >
              Saved Tables
            </p>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "40%",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <LinearProgress color="info" />
              </div>
            ) : (
              <List
              dense={true}
              sx={{
                width: "100%",
                maxHeight: "200px",
                position: "relative",
                overflow: "auto",
                "& ul": { padding: 0 },
              }}
              subheader={<li />}
            >
              {savedTables?.map((table: any, index: any) => (
                <ListItem
                  dense={true}
                  sx={{
                    backgroundColor: usedDimensions.some(
                      (value) => value.title === table.tableName
                    )
                      ? "#17B169"
                      : "#D3D3D3",
                    margin: "4px",
                  }}
                  key={`item-${index}-${table}`}
               
                    onClick={() => {
                      console.log(table); 
                      openSavedTable(table.queryBuilder);
                      
                    }}
              
                >
                  <ListItemText
                    sx={{
                      color: usedDimensions.some(
                        (value) => value.title === table.tableName
                      )
                        ? "white"
                        : "black",
                      fontWeight: "bold",
                    }}
                    primary={table.title}
                  />
                  <ListItemAvatar></ListItemAvatar>
                </ListItem>
              ))}
            </List>
            )}
        
          {/* <div className="dimensionContainer">
            <p
              style={{
                textAlign: "left",
                color: "black",
                fontWeight: "normal",
                padding: 5,
                fontSize: 16,
                borderBottomWidth: 1,
                borderBottomColor: "grey",
              }}
            >
              Saved Tables
            </p>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "50%",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <LinearProgress color="info" />
              </div>
            ) : (
              <>
                <nav>
                  <List
                    sx={{
                      width: "100%",
                      maxHeight: 200,
                      maxWidth: 360,
                      bgcolor: "white",
                      borderWidth: 1,
                      borderColor: "red",
                      overflow: "scroll",
                    }}
                  >
                    <ListItem disablePadding dense>
                      <ListItemButton>
                        <ListItemText
                          primary="Saved Table"
                          primaryTypographyProps={{
                            color: "black",
                            fontWeight: "small",
                            variant: "body2",
                            fontFamily: "",
                            fontSize: 12,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
              </>
            )}
          </div> */}
        </>
      </div>
    </div>
  );
};

export default Pivot;

const exportData: (rawData: any, title:string) => void = (rawData: any,title:string) => {
    // Extract labels and data
    console.log(rawData);
    
    // let dataRows = [];
    // let labels = [];
    // labels.push('Weeks');
    // labels.push(rawData.datasets.map((data:any) => data?.label));
    // dataRows.push(rawData?.labels);
    // dataRows.push(rawData?.datasets.map((dataset:any) => dataset?.data.join(",")));
    const labels = ["Weeks", ...rawData.labels];
    const dataRows = rawData.datasets.map((dataset:any) => [dataset.label, ...dataset.data]);
  
    // Transpose data for CSV
    const transposedData = labels.map((label, i) => [label, ...dataRows.map((row:any) => row[i])]);

   // Create CSV content
   const csvContent = `${transposedData.map(row => row.join(",")).join("\n")}`;

   // Create a Blob containing the CSV data
   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
 
    // Create a link element and trigger a click event to download the CSV file
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `${title}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  export default exportData;
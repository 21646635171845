import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import theme from "../../utils/theme";
import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface faqObj {
  question: string;
  answer: string;
}
export default function FAQ() {
  const [loading, setloading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [faq, setFAQ] = useState<faqObj[]>([
    {
      question: "What is a health emergency reporting dashboard?",
      answer: `A health emergency reporting dashboard is a digital tool or platform used by emergency services, organizations, or government agencies to collect, manage, and display real-time data and information related to emergency situations.`,
    },
    {
      question: "What are the key features of this health emergency reporting dashboard?",
      answer: `Key features typically include Essential Health Service, Vaccination/Immunization, Disease Prevention, Clinical Prevention, Clinical Services, Public Health Emergency.`,
    },
    {
      question: "How does a health emergency reporting dashboard benefit emergency management?",
      answer: ` Health emergency reporting dashboards help in better coordination, communication, and decision-making during crises. They enable both responders and the public to stay informed and respond effectively to emergency situations.`,
    },
    {
      question: "Who uses health emergency reporting dashboard?",
      answer: `This dashboard is used by various entities, including the public, emergency services, government agencies, public health organizations, and non-governmental organizations involved in disaster response and recovery.`,
    },
    {
      question: "Can the public access the dashboard?",
      answer: `Yes, the dashboard is publicly accessible to keep citizens informed. However, some data may be restricted for security reasons.`,
    },
    {
      question: "Is the data displayed on the dashboard real-time?",
      answer: `The data displayed is typically as real-time as possible, with updates occurring continuously or at regular intervals. Timely information is critical during emergencies.`,
    },
    {
      question: "Is training required to use this dashboard?",
      answer: ` Training is not required to use this dashboard as it is designed to be easy and user-friendly, but in case of any difficulties, a technical guide book is available for assistance.`,
    },
  ]);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "80%" }}>
        <Typography
          color={theme.textColor}
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: 27,
            margin: 20,
          }}
        >
          FAQs
        </Typography>
        {faq.map((element) => (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography
                  color={theme.textColor}
                  style={{ fontWeight: "bold" }}
                >
                  {element.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color={theme.textColor}>
                  {element.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </>
        ))}
      </div>
    </div>
  );
}

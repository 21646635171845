import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Polygon, GeoJSON } from "react-leaflet";
import Backdrop from '@mui/material/Backdrop';
import "leaflet/dist/leaflet.css";
import ethiopianRegions from "../../utils/GeoJson/geoJson";
import ChartHeader from "../ChartHeader/chartHeader";
import InstitutionsOnMap from "../InstitutionsOnMap";
import { ColorRing } from "react-loader-spinner";
import theme from "../../utils/theme";
import { FaExpand, FaTimesCircle } from "react-icons/fa";
import Modal from "react-modal";
const center = [9.145, 40.4897];

export default function HeatMap(props) {
  const [showMap, setShowMap] = useState(true);
  const [modalIsOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setShowMap(false);
    setTimeout(() => {
      setShowMap(true);
    }, 1000);
  }, [props]);
  function closeModal() {
    setModalOpen(false);
  }
  function afterOpenModal() {
    // setModalOpen(false);
  }

  const onEachFeature = (feature, layer) => {
    var fillColor = '';
    if (feature.properties && feature.properties.REGIONNAME) {
      if(props.regions.length !== 0) {
        fillColor =  props.regions.indexOf(feature.properties.REGIONNAME) !== -1 ? "#1670C1": "#CCCCCC";
      }else{
        fillColor = "#1670C1";
      }
     

      layer.setStyle({
        fillColor: fillColor,
      });
      layer.bindPopup(`<div>${feature.properties.REGIONNAME}</div>
      <div>${feature.properties.data}</div>`,
      {
        autoPan: false,
        className: 'custom-popup', // Define a custom class for the popup
        style: {
          zIndex: 1000, // Replace 1000 with your desired z-index value
        },
      }
      );
      layer.on({
        mouseover: (e) => {
          // e.target.setStyle({ fillColor: '#1670C1' }); // Change color on hover
          layer.openPopup();
        },
        mouseout: (e) => {
          // Restore original color on hover out
          layer.closePopup();
          // e.target.setStyle({ fillColor: '#CCCCCC' });
        },
      });
    }
  };
  return (
    <div>
         <Backdrop
          sx={{ color: '#fff', zIndex:10000, height:'100%'}}
          open={modalIsOpen}
         
        >
          <InstitutionsOnMap onClose={()=>{
            closeModal();
          }}
          region={props.regions}/>
        </Backdrop>
      {showMap ? (
       
     
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            paddingLeft: 10,
            paddingRight: 10,
            alignSelf: "center",
          }}
        >
          <FaExpand
            className="chartTypeIcon"
            onClick={() => {
              setModalOpen(true);
            }}
            style={{ alignSelf: "flex-end", marginRight: 30, marginTop:10 }}
          />
          <MapContainer
            center={center}
            fadeAnimation={true}
            zoom={4.5}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              overflow: "hidden",
              zIndex:1,
            }}
            scrollWheelZoom={false}
            zoomControl={false}
            doubleClickZoom={false}
          >
            <GeoJSON
              data={ethiopianRegions}
              style={{ color: "white", weight: 1, opacity: 1, fillOpacity: 1 }}
              onEachFeature={onEachFeature}
            />
          </MapContainer>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <ColorRing
            visible={true}
            height="50"
            width="50"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
          />
        </div>
      )}
    </div>
  );
}

import "./styles.css";
import "../../utils/theme";
import {
  FaBars,
  FaRegBell,
  FaRegQuestionCircle,
  FaPowerOff,
} from "react-icons/fa";

type NavigationProps = {
  showBarIcon: Boolean;
  onToggle: () => void; // Specify the type of the onShowChange prop function
};

const Header = (props: NavigationProps) => {
  return (
    <div className="headerContainer">
      {props.showBarIcon && <FaBars onClick={props.onToggle} />}
      <h5 className="title" style={{ marginLeft: 20 }}>
        Health Emergency Reporting Dashboard
      </h5>
      <div className="icons">
        <FaRegBell />
        <FaPowerOff />
      </div>
    </div>
  );
};

export default Header;

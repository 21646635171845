import React from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import theme from "../../utils/theme";
// Point PDF.js to the worker script location
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function UserGuide() {
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pageNumber, setPageNumber] = React.useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }
  function handlePdfError(error: any) {
    console.log(error);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "850px",
          height: "100%",
          borderWidth: 1,
          borderColor: theme.primaryColor,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Document
          file="/Health Emergency Reporting Dashboard user guide manual.pdf" // Provide the path to your PDF file
          onLoadSuccess={onDocumentLoadSuccess}
          onError={handlePdfError}
        >
          {Array.from({ length: 12 }, (_, index) => (
            <Page
              key={index}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </div>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
}

export default UserGuide;

import ChartHeader from "../../component/ChartHeader/chartHeader";
import CustomChart from "../../chart_types/CustomChart";
import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import modalStyle from "../../styles/modal";
import Chart, { ChartData } from "chart.js";
import Table from "../../Table/dataTable";
import getFAF from "../../service/functionalHealthFacilities";
import { ColorRing } from "react-loader-spinner";
import CustomChartProp from "../../models/CustomChart";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import Dataset from "../../models/Dataset";
import { TableColumn } from "react-data-table-component";

import { DataObject } from "@mui/icons-material";
import theme from "../../utils/theme";
import "./styles.css";
import getSAMOtpByRegion from "../../service/samOtpByRegion";
import Backdrop from "@mui/material/Backdrop";
const SAMOTPbyRegion = () => {
  const [modalIsOpen, setModal] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [otpData, setOTPData] = useState<ChartData>({});
  const [total,setTotal] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumn<any>[]>([]);
  const [error, setError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  //todo: chartType has to be initialized by the type from the api.
  const [chartType, setChartType] = useState("line");

  function closeModal() {
    setModal(false);
  }

  const expandChart = () => {
    setModal(true);
  };

  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      console.log("under ctx");

      chartRef.current = new Chart(ctx, {
        type: "doughnut",
        data: otpData,
        options: {
          responsive: true,
          plugins: {
            fill: true,
            legend: {
              display: false,
              labels: {
                color: "red",
                usePointStyle: true,
              },
            },

            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  };

  async function fetchData() {
    setLoading(true);
    let datasets: any[] = [];
    try {
      // Process data here, set state variables, etc.
      await getSAMOtpByRegion().then((res: any) => {
            var sum =0;
            console.log('elemerntngrigrgrt: ', res);
        res.data.datasets.forEach((element: { label: string; data: any; }, index:number) => {
        console.log(element.data.indexOf(element.data[index]));
        console.log(element.data);
        
          
          let dataObj = {
            label: element.label,
            backgroundColor: [theme.secondaryChartColor,theme.primaryChartColor,theme.orangeChartColor ],
            data: element.data,
            // backgroundColor: theme.primaryChartColor,
          };

          datasets.push(dataObj);
          element.data.forEach((val:any) => {
            sum += parseInt(val);
          });

        });
        setTotal(sum.toLocaleString());
        let dataObject = {
          labels: res.data.labels,
          borderColor: theme.primaryChartColor,
           backgroundColor: ["red","yellow","blue"],
          borderWidth: 1,
          datasets: datasets
        };


        // console.log("filterdfhf, ", dataObject);
       
        setOTPData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    setError(false);

    fetchData();
  }, []);

  useEffect(() => {
    setError(false);

    fetchData();
  }, [tryAgain]);
  return (
    <div>
        <Backdrop
          sx={{ color: '#fff',backgroundColor:"white", zIndex:10000, height:'100%', padding:'50px'}}
          open={modalIsOpen}
         
        >
          <div style={{height:'100%', width:'90%', alignSelf:'center', justifyContent:"center", alignContent:'center'}}>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
         
          <FaTimesCircle
            size={20}
            color="#25476A"
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          />
        </div>
            {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  forExpanded={true}
                  title={"SAM-OTP by Region"}
                  onExpand={expandChart}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  showTypes={false}
                />
                <div>
                  <div  style={{ flex: '1'}}>
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "80%", height:'50vh' }}
                    ></canvas>
                       <div className="infoContainer">
                  <h2
                      style={{
                        textAlign: "center",
                        margin: 0,
                        fontWeight: "normal",
                        color: "#7E909A",
                      }}
                    >
                      Total
                    </h2>
                    <h1 className="totalNumber">{total}</h1>
               
                  </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        </div>
        </Backdrop>
      <>
        {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  title={"SAM-OTP by Region"}
                  onExpand={expandChart}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  showTypes={false}
                />
                <div>
                  <div className=" w-full" style={{ flex: 1, height: "100%" }}>
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "80%" }}
                    ></canvas>
                  </div>
                  <div className="infoContainer">
                  <h2
                      style={{
                        textAlign: "center",
                        margin: 0,
                        fontWeight: "normal",
                        color: "#7E909A",
                      }}
                    >
                      Total
                    </h2>
                    <h1 className="totalNumber">{total}</h1>
               
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default SAMOTPbyRegion;

import ChartHeader from "../../component/ChartHeader/chartHeader";
import CustomChart from "../../chart_types/CustomChart";
import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import modalStyle from "../../styles/modal";
import Chart, { ChartData } from "chart.js";
import Table from "../../Table/dataTable";
import getFAF from "../../service/functionalHealthFacilities";
import { ColorRing } from "react-loader-spinner";
import CustomChartProp from "../../models/CustomChart";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import Dataset from "../../models/Dataset";
import { TableColumn } from "react-data-table-component";

import { DataObject } from "@mui/icons-material";
import theme from "../../utils/theme";
import "./styles.css";
import getSAMOtp from "../../service/severeAcuteMalNutrition";
import { Backdrop } from "@mui/material";
import exportData from "../../utils/exporttocsv";
const SevereAcuteMalnutritionOTP = () => {
  const [modalIsOpen, setModal] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [rawData,setRawData] = useState<any>({});
  const [fhfData, setFhfData] = useState<ChartData>({
    datasets: [
      {
        type: "bar",
        label: "SC",
        backgroundColor: theme.greenChartColor,
        data: [10, 20, 30, 40, 22, 5, 19, 78, 10, 10],
      },
      {
        type: "bar",
        label: "OTP",
        backgroundColor: theme.purpleChartColor,
        data: [10, 20, 15, 30, 12, 5, 3, 78, 140, 10],
      },
      {
        type: "line",
        label: "SAM",
        fill: false,
        borderColor: theme.secondaryChartColor,
        pointStyle: "circle",
        barThickness: 2,
        data: [12, 22, 32, 42, 24, 6, 22, 81, 12, 14],
      },
    ],
    labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    // These labels appear in the legend and in the tooltips when hovering different arcs
  });
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumn<any>[]>([]);
  const [error, setError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  //todo: chartType has to be initialized by the type from the api.
  const [chartType, setChartType] = useState("line");

  function closeModal() {
    setModal(false);
  }

  const expandChart = () => {
    setModal(true);
  };

  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      chartRef.current = new Chart(ctx, {
        type: "bar",
        data: fhfData,
        options: {
          responsive: true,
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Week",
                },
              },
            ],
          },
          plugins: {
            fill: true,
            legend: {
              display: false,
              labels: {
                color: "red",
                usePointStyle: true,
              },
            },
            scales: {
              x: {
                beginAtZero: true, // Customize as needed
              },
              y: {
                beginAtZero: true, // Customize as needed
              },
            },
            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  };

  async function fetchData() {
    setLoading(true);
    let datasets:any[] = [];
    try {
      // Process data here, set state variables, etc.

      await getSAMOtp().then((res: any) => {
        setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any }) => {
          let dataObj = {
            label: element.label,
            type:
              element.label === "SC" || element.label === "OTP"
                ? "bar"
                : "line",
            backgroundColor:
              element.label === "SC"
                ?  theme.greenChartColor
                :  element.label === "OTP" ? theme.purpleChartColor : theme.secondaryChartColor,
            borderWidth: 1,
            pointStyle: element.label === "SAM" ? "circle" : "rectRot",
            pointRadius: 3,
            pointBorderColor: theme.secondaryChartColor,
            barThickness: 6,
            data: element.data,
            fill:false,
            borderColor:   element.label === "SAM" ? theme.secondaryChartColor : 'transparent',
          };
         console.log('data obj. ', dataObj);
         
          datasets.push(dataObj);
        });
        let dataObject = {
          labels: res.data.labels,
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets: datasets,
        };

        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    setError(false);

    fetchData();
  }, []);

  useEffect(() => {
    setError(false);

    fetchData();
  }, [tryAgain]);
  return (
    <div>
       <Backdrop
          sx={{ color: '#fff', zIndex:20000, height:'100%',backgroundColor:'white', padding:'50px'}}
          open={modalIsOpen}
         
        >
          <div style={{height:'100%', width:'70%', alignSelf:'center', justifyContent:"center", alignContent:'center'}}>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
         
          <FaTimesCircle
            size={20}
            color="#25476A"
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          />
        </div>
            {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  forExpanded={true}
                  title={"Severe Acute Malnutrition OTP-SC"}
                  onExpand={expandChart}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  onExport = {()=>exportData(rawData,'Severe Acute Malnutrition OTP-SC')}
                  showTypes={false}
                />
                <div>
                  <div  style={{ flex: '1'}}>
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "80%", height:'50vh' }}
                    ></canvas>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        </div>
        </Backdrop>
   
      <>
        {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  title={"Severe Acute Malnutrition OTP-SC"}
                  onExpand={expandChart}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  onExport = {()=>exportData(rawData,'Severe Acute Malnutrition OTP-SC')}
                  showTypes={false}
                />
                <div>
                  <div className=" w-full" style={{ flex: 1, height: "100%" }}>
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "80%" }}
                    ></canvas>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default SevereAcuteMalnutritionOTP;

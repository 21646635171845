import axios from "axios";

interface RmnchProps {
    year: string,
    quarter: string,
    month?:string,
    weeks?:string,
}

async function getRmnch(props:RmnchProps) {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://hed.k8s.sandboxaddis.com/maternalHealth/rmnch?years=${props.year}&quarter=${props.quarter}&months=${props.month}&weeks=${props.weeks}`,
        headers: {},
    };
    try {
        const response = await axios.request(config);
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export default getRmnch;

import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import theme from "../../utils/theme";
import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function FeedBack() {
  const [loading, setloading] = useState(false);
  const [success, showSuccessMessage] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmailAddress] = useState("");
  const [feedback, setFeedBack] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailError, setEmailAddressError] = useState(false);
  const [feedbackError, setFeedBackError] = useState(false);

  const onSubmit = () => {
    if (email === "") {
      setEmailAddressError(true);
      setloading(false);
    } else if (feedback === "") {
      setFeedBackError(true);
      setloading(false);
    } else if (fullName === "") {
      setFullNameError(true);
      setloading(false);
    } else if (phoneNumber === "") {
      setPhoneNumberError(true);
      setloading(false);
    } else {
      setloading(false);
      setEmailAddressError(false);
      setFeedBackError(false);
      setFullNameError(false);
      showSuccessMessage(true);
    }
  };
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{
        width: "50%",
        display: "flex",
        // alignSelf: "center",
        flexDirection: "column",
        gap: 20,
        // justifyContent: "center",
        // alignItems: "center",
        margin: "auto",
      }}
    >
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => {
          showSuccessMessage(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => {
            showSuccessMessage(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Feedback Submitted Successfully! Thank You!
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => {
          setloading(false);
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <h1 style={{ color: theme.textColor }}>User FeedBack</h1>
        <h4 style={{ color: theme.textColor }}>
          JSI USAID DHA User Feedback for [Health Emergency Report Dashboard]
        </h4>
      </div>
      <TextField fullWidth label="Full Name" id="fullWidth" />

      <TextField
        // error={emailError}
        id="outlined-multiline-flexible"
        label="Email"
        multiline
        fullWidth
        
        onChange={(e) => {
          setEmailAddress(e.target.value);
        }}
      />

      <TextField
        // error={phoneNumberError}
        id="outlined-multiline-flexible"
        label="Phone Number"
        fullWidth
        multiline
        onChange={(e) => {
          setPhoneNumber(e.target.value);
        }}
      />

      <TextField
        // error={feedbackError}
        id="outlined-multiline-flexible"
        label="Please let us know your feedback on the Health Emergency Reporting Dashboard here."
        minRows={6}
        fullWidth
        multiline
        onChange={(e) => {
          setFeedBack(e.target.value);
        }}
      />
      <Button
        variant="contained"
        style={{ fontWeight: "bold" }}
        fullWidth
        onClick={() => {
          setloading(true);
          setTimeout(() => {
            setloading(false);
      setEmailAddressError(false);
      setFeedBackError(false);
      setFullNameError(false);
      showSuccessMessage(true);
          }, 2000);
        }}
        color="info"
      >
        Submit
      </Button>
    </Box>
  );
}

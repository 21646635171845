/* eslint-disable @typescript-eslint/no-unused-vars */
interface Theme {
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  teritaryColor: string;
  lightBlue: string;
  darkGray: string;
  backgroundColor: string;
  primaryChartColor: string;
  secondaryChartColor: string;
  teritaryChartColor: string;
  lightBlueChartColor:string;
  purpleChartColor:string;
  greenChartColor:string;
  orangeChartColor:string;
}

const theme: Theme = {
  primaryColor: "#25476A",
  secondaryColor: "#0091D5",
  teritaryColor: "#EA6A47",
  darkGray: "#7E909A",
  lightBlue: "#A5D8DD",
  backgroundColor: "#f3f3f3",
  textColor: "#202020",
  primaryChartColor: "#509EE3",
  secondaryChartColor: "#EF8C8C",
  teritaryChartColor: "#F9D45C",
  lightBlueChartColor: "#98D9D9",
  purpleChartColor: "#A989C5",
  greenChartColor:"#A7C192",
  orangeChartColor:"#F2A86F",
};

export default theme;

const root = document.documentElement;
root.style.setProperty("--primaryColor", theme.primaryColor);
root.style.setProperty("--secondaryColor", theme.secondaryColor);
root.style.setProperty("--textColor", theme.textColor);
root.style.setProperty("--teritaryColor", theme.teritaryColor);
root.style.setProperty("--lightBlue", theme.lightBlue);
root.style.setProperty("--darkGray", theme.darkGray);
root.style.setProperty("--backgroundColor", theme.backgroundColor);
root.style.setProperty("--primaryChartColor", theme.primaryChartColor);
root.style.setProperty("--secondaryChartColor", theme.secondaryChartColor);
root.style.setProperty("--teritaryChartColor", theme.teritaryChartColor);
root.style.setProperty("--lightBlueChartColor:", theme.lightBlueChartColor);
root.style.setProperty("--lightBlueChartColor:", theme.purpleChartColor);
root.style.setProperty("--greenChartColor:", theme.greenChartColor);
root.style.setProperty("--orangeChartColor:", theme.orangeChartColor);

export function getCurrentQuarter(): string {
    const currentMonth = new Date().getMonth() + 1;
  
    if (currentMonth >= 1 && currentMonth <= 3) {
        return '1';
    } else if (currentMonth >= 4 && currentMonth <= 6) {
        return '2';
    } else if (currentMonth >= 7 && currentMonth <= 9) {
        return '3';
    } else {
        return '4';
    }
  }
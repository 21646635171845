import ChartHeader from "../../component/ChartHeader/chartHeader";
import CustomChart from "../../chart_types/CustomChart";
import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import modalStyle from "../../styles/modal";
import Chart, { ChartData } from "chart.js";
import Table from "../../Table/dataTable";
import getFAF from "../../service/functionalHealthFacilities";
import { ColorRing } from "react-loader-spinner";
import CustomChartProp from "../../models/CustomChart";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import Dataset from "../../models/Dataset";
import { TableColumn } from "react-data-table-component";

import { DataObject } from "@mui/icons-material";
import theme from "../../utils/theme";
import "./styles.css";
import getRmnch from "../../service/rmnchService";
import { Backdrop } from "@mui/material";
import getRmnchPnc from "../../service/rmnchPnc";
import getRmnchVaccination from "../../service/rmnchVaccination";
import { getCurrentQuarter } from "../../utils/getCurrentQuarter";
import { FilterProps } from "../../utils/filterProp";
import exportData from "../../utils/exporttocsv";



const RMNCH_VACCINATION = (props:FilterProps) => {
  const [modalIsOpen, setModal] = useState(false);
  const [rawData,setRawData] = useState<any>({});
  const [displayTable, setDisplayTable] = useState(false);
  const [fhfData, setFhfData] = useState<ChartData>({});
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumn<any>[]>([]);
  const [error, setError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  //todo: chartType has to be initialized by the type from the api.
  const [chartType, setChartType] = useState("line");

  function closeModal() {
    setModal(false);
  }

  const expandChart = () => {
    setModal(true);
  };

  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      console.log("under ctx");

      chartRef.current = new Chart(ctx, {
        type: "bar",
        data: fhfData,
        options: {
          responsive: true,
          scales: {
            xAxes: [
              {
                stacked: false,
                scaleLabel: {
                  display: true,
                  labelString: "Week",
                },
              },
            ],

          },
          plugins: {
            fill: true,
            legend: {
              display: false,
              labels: {
                color: "red",
                usePointStyle: true,
              },
            },

            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  };

  async function fetchData() {
    setLoading(true);
    let datasets: any[] = [];
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    try {
      // Process data here, set state variables, etc.
      await getRmnchVaccination({year:(currentYear-1).toString(),quarter:currentQuarter}).then((res: any) => {
        setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any; }) => {
         
          let dataObj = {
            type: element.label === 'penta3' ? 'bar' : 'line',
            label: element.label,
            fill: element.label === 'penta3' ? true : false,
            borderColor: element.label === 'penta3' ? '#88BF4D' : theme.secondaryChartColor,
            backgroundColor: element.label === "penta3" ? '#88BF4D' : 'white',
            borderWidth: 2,
            pointStyle: "circle",
            pointRadius: 4,
            pointBorderColor: theme.secondaryChartColor,
            data: element.data,

          };

          datasets.push(dataObj);
        });
        datasets.sort((a, b) => {
          if (a.type === 'line') {
            return -1;
          } else if (b.type === 'line') {
            return 1;
          } else {
            return 0;
          }
        });
        let dataObject = {
          labels: res.data.labels,
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets: datasets
        };


        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  async function fetchPropData() {
    setLoading(true);
    let datasets: any[] = [];
    
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    
     
    console.log('current year: ', currentYear);
    console.log('current quarter: ', currentQuarter);
    
    
    try {
      // Process data here, set state variables, etc.
      let passedYear = props.years.join(',');
      let quarter = props.quarters.length != 0 ? props.quarters.join(',') : '0';
      let months = props.months.length != 0 ? props.months.join(',') : '0';
      let weeks = props.weeks.length != 0 ? props.weeks.join(',') : '0';
        //console.log('months: ', months);
        
      await getRmnchVaccination({year:passedYear,quarter:quarter, month:months, weeks:weeks}).then((res: any) => {
          setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any; }) => {
          let dataObj = {
            label: element.label,
            type:  element.label === "PNC" ? "line" : "bar",
            backgroundColor: element.label === "ANC" ? theme.orangeChartColor : element.label === "PNC" ? 'white' :   theme.primaryChartColor,
            fill: element.label === 'PNC' ? false:true,
            borderColor: element.label === 'PNC' ? '#88BF4D' : 'transparent',
            borderWidth: 3,
            pointStyle: "circle",
            pointRadius: 4,
            // pointBorderColor: theme.secondaryChartColor,
            pointBorderColor: '#88BF4D',
            data: element.data,
          };
          
          datasets.push(dataObj);
        });
        datasets.sort((a, b) => {
          if (a.type === 'line') {
            return -1;
          } else if (b.type === 'line') {
            return 1;
          } else {
            return 0;
          }
        });
        let dataObject = {
          labels: res.data.labels,
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets: datasets
        };


        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    setError(false);

    fetchData();
  }, []);
  useEffect(() => {
    console.log('propsss: ',props);

    if(props.years.length != 0) {
      fetchPropData();
    }
    props.months.toString();
    setError(false);

   
  }, [props]); 
  useEffect(() => {
    setError(false);

    fetchData();
  }, [tryAgain]);
  return (
    <div>
        <Backdrop
          sx={{ color: '#fff',backgroundColor:"white", zIndex:10000, height:'100%', padding:'50px'}}
          open={modalIsOpen}
         
        >
          <div style={{height:'100%', width:'90%', alignSelf:'center', justifyContent:"center", alignContent:'center'}}>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
         
          <FaTimesCircle
            size={20}
            color="#25476A"
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          />
        </div>
            {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  forExpanded={true}
                  title={"Vaccination"}
                  onExpand={expandChart}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  showTypes={false}
                  onExport = {()=>exportData(rawData,'Vaccination')}
                />
                <div>
                  <div  style={{ flex: '1'}}>
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "80%", height:'50vh' }}
                    ></canvas>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        </div>
        </Backdrop>
      <>
        {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  title={"Vaccination"}
                  onExpand={expandChart}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                    onExport = {()=>exportData(rawData,'Vaccination')}
                  showTypes={false}
                />
                <div>
                  <div className="self-center w-full">
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "80%",}}
                    ></canvas>
                  </div>
                </div>

              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default RMNCH_VACCINATION;

const modalStyle = {
  content: {
    top: "50%",
    left: "55%",
    right: "auto",
    width: "50%",
    bottom: "auto",
    zIndex: 9000,
    //marginRight: '-50%',
    transform: "translate(-50%, -50%)",
  },
};

export default modalStyle;

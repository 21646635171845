import React, { useRef, useState, useEffect } from "react";
import CustomChart from "../../chart_types/CustomChart";
import CustomChartProp from "../../models/CustomChart";
import { GeoJSON } from "react-leaflet";
import ethiopianRegions from "../../utils/GeoJson/geoJson";
import "./styles.css";
import Multiselect from "multiselect-react-dropdown";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import ChartHeader from "../ChartHeader/chartHeader";
import Modal from "react-modal";
import theme from "../../utils/theme";
import { TileLayer, Marker, Popup } from "react-leaflet";
import am5geodata_ethiopiaLow from "@amcharts/amcharts5-geodata/ethiopiaLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5map from "@amcharts/amcharts5/map";
import MapComponent from "../RegionalHeatmap/heatmap";
import { ColorRing } from "react-loader-spinner";
import { ethioData } from "./ethiopia";
import DataTable, { TableColumn } from "react-data-table-component";
import FunctionalHealthFacilities from "../../charts/FunctionalHealthFacilities";
import ReportingHealthFacilitiesByRegion from "../../charts/ReportingHealthFacilitiesByRegion";
import UnderFiveMalnutrition from "../../charts/UnderFiveMalnutrition";
import MAMByRegion from "../../charts/MMAMbyRegion";
import SAMByRegion from "../../charts/SAMByRegion";
import RMNCH from "../../charts/RMNCH";
import HeatMap from "../HeatMap";
import SevereAcuteMalnutritionOTP from "../../charts/SevereAcuteMalnutritionOTP";
import PatientsLinked from "../../charts/PatientsLinked";
import ARTByRegion from "../../charts/ARTByRegion";
import MalariaCasesAndOPDAttendants from "../../charts/MalariaCasesAndOPDAttendants";
import MentalHealthScreenedCareTreatment from "../../charts/MentalHealthScreenedCareTreatment";
import IDPPopulationBySitesAgewithZone from "../../charts/IDPPopulationBySitesAgewithZone";
import TotalFeMALEIDPbyRegion from "../../charts/TotalFEMALEIDPbyRegion";
import TotalMALEIDPbyRegion from "../../charts/TotalMALEIDPbyRegion";
import SAMOTPbyRegion from "../../charts/SAMOTPbyRegion";
import SAMSCbyRegion from "../../charts/SAMSCbyRegion";
import { MapContainer } from "react-leaflet";
import HeatMaps from "../HeatMap";
import { log } from "console";
import getRegions from "../../service/regionService";
import getZones from "../../service/zoneService";
import HTNScreened from "../../charts/HTNScreened";
import RMNCH_PNC from "../../charts/RMNCH_PNC";
import RMNCH_VACCINATION from "../../charts/RMNCH_VACCINATION";
import SGBVMHPSS from "../../charts/SGBV&MHPSS";
import NumberOfHivTesting from "../../charts/NumberOfHivTesting";
import NumberOfEmergencyAttendants from "../../charts/NumberOfEmergencyAttendants";
import { Button } from "@mui/material";
interface Movie {
  id: number;
  year: string;
  week: string;
  serviceSite: string;
  hfType: string;
  hfSiteName: string;
  siteCode: string;
}
const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};
const columns: TableColumn<Movie>[] = [
  {
    name: "Year",
    selector: (row: Movie) => row.year,
  },
  {
    name: "Week",
    selector: (row: Movie) => row.week,
  },
  {
    name: "Service Site",
    selector: (row: Movie) => row.serviceSite,
  },
  {
    name: "HF Type",
    selector: (row: Movie) => row.hfType,
  },
  {
    name: "HF Site Name",
    selector: (row: Movie) => row.hfSiteName,
  },
  {
    name: "Site Code",
    selector: (row: Movie) => row.siteCode,
  },
];

const tabledata: Movie[] = [
  {
    id: 1,
    year: "2022",
    week: "weel 1",
    serviceSite: "test",
    hfType: "test",
    hfSiteName: "test",
    siteCode: "test",
  },
  {
    id: 2,
    year: "2022",
    week: "weel 1",
    serviceSite: "test",
    hfType: "test",
    hfSiteName: "test",
    siteCode: "test",
  },
  {
    id: 3,
    year: "2023",
    week: "weel 3",
    serviceSite: "test",
    hfType: "test",
    hfSiteName: "test",
    siteCode: "test",
  },
];

const Visualization = () => {
  const dataTest = [10, 20, 30, 40, 50];
  const [showExpandedChart, setShowExpandedChart] = useState(false);
  const [labels, setLabels] = useState<string[]>([
    "test",
    "anothertest",
    "another_test",
    "another_test_",
  ]);
  const regionsData = [
    {
      coordinates: [
        { lat: 9.14, lng: 40.5 },
        { lat: 9.14, lng: 40.6 },
        { lat: 9.2, lng: 40.6 },
        { lat: 9.2, lng: 40.5 },
      ],
      color: "blue", // You can specify colors here
    },
    // Add more regions as needed
  ];

  const quarters = ["I", "II", "III", "IV"];
  const rawMonths: Month[] = [
    { cat: "January", key: "jan", quarter: "II", value: "1", startingWeek: 1 },
    { cat: "February", key: "feb", quarter: "II", value: "2", startingWeek: 5 },
    { cat: "March", key: "mar", quarter: "II", value: "3", startingWeek: 9 },
    { cat: "April", key: "apr", quarter: "III", value: "4", startingWeek: 13 },
    { cat: "May", key: "may", quarter: "III", value: "5", startingWeek: 17 },
    { cat: "June", key: "jun", quarter: "III", value: "6", startingWeek: 21 },
    { cat: "July", key: "jul", quarter: "IV", value: "7", startingWeek: 25 },
    { cat: "August", key: "aug", quarter: "IV", value: "8", startingWeek: 29 },
    {
      cat: "September",
      key: "sep",
      quarter: "IV",
      value: "9",
      startingWeek: 33,
    },
    { cat: "October", key: "oct", quarter: "I", value: "10", startingWeek: 37 },
    {
      cat: "November",
      key: "nov",
      quarter: "I",
      value: "11",
      startingWeek: 40,
    },
    {
      cat: "December",
      key: "dec",
      quarter: "I",
      value: "12",
      startingWeek: 44,
    },
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [modalIsOpen, setIsOpen] = useState(false);
  const [weekLoading, setWeekLoading] = useState(false);
  const [monthLoading, setMonthLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [weekChanged, setWeekChanged] = useState(false);

  const [data, setData] = useState<number[]>([1, 2, 3, 4, 5, 54, 6]);
  const onHover = (feature: any, layer: any) => {
    layer.on({
      click: () => {
        console.log("hover on layer");
      },
    });
  };
  const [barGraph, setBarGraph] = useState<CustomChartProp>({
    labels: ["week 1", "week 2", "week 3", "week 4", "week 5"],

    datasets: [
      {
        label: "MAM",
        backgroundColor: "#64C2A6",
        borderWidth: 1,
        pointStyle: "rectRot",
        pointRadius: 5,
        pointBorderColor: "black",
        data: [2343, 5657, 2354, 3545],
      },
      {
        label: "YAY",
        backgroundColor: "#F7E987",
        borderWidth: 1,
        pointStyle: "rectRot",
        pointRadius: 5,
        pointBorderColor: "black",
        data: [2343, 5657, 2354, 3545],
      },
      {
        label: "SAM",
        borderColor: "#2D87BB",
        backgroundColor: "#2D87BB",
        borderWidth: 1,
        pointStyle: "rectRot",
        pointRadius: 5,
        pointBorderColor: "black",
        data: [4564, 3464, 1234, 546],
      },
    ],
  });
  const [mamByRegion, setMamByRegion] = useState<CustomChartProp>({
    labels: ["Mam"],
    datasets: [
      {
        label: "MAM",
        backgroundColor: "#e9785875",
        borderWidth: 1,
        pointStyle: "rectRot",
        pointRadius: 5,
        pointBorderColor: "black",
        data: [2343, 5657, 2354, 3545],
      },
    ],
  });
  const multiselectStyle = {
    chips: {
      background: "#DADADA",
      "font-size": "11px",
      "font-weight": "normal",
      color: "#202020",
    },
    multiselectContainer: {
      color: "#202020",
      background: "white",
      innerWidth: 600,
      outerWidth: 600,
    },
    inputField: {
      // To change input field position or margin
      "max-width": "100%",
    },
    searchBox: {
      "max-height": "40px",
      "margin-right": "10px",
      border: "1.5px solid #DADADA",
      "border-radius": "5px",
      "min-width": "100%",
    },
    option: {
      // To change css for dropdown options
      color: "black",
      background: "white",
      "font-size": "13px",
    },
    optionContainer: {
      // To change css for option container
      border: "1.5px solid #DADADA",
      // zIndex:50000,
    },
  };

  const expandChart = () => {
    setIsOpen(true);
  };
  interface Year {
    cat: string;
    key: string;
  }
  interface Month {
    cat: string;
    key: string;
    quarter: string;
    value: string;
    startingWeek: number;
  }
  interface Quarter {
    cat: string;
    key: string;
    value: string;
  }
  interface Week {
    cat: string;
    key: string;
    value: string;
  }
  interface Region {
    name: string;
    key: string;
  }
  interface Woreda {
    cat: string;
    key: string;
  }
  interface Zone {
    name: string;
    key: string;
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  const [year, setYear] = useState<Year[]>([
    {
      cat: "2021",
      key: "1",
    },
    {
      cat: "2022",
      key: "2",
    },
    {
      cat: "2023",
      key: "3",
    },
  ]);
  const [month, setMonth] = useState<Month[]>([
    { cat: "January", key: "jan", quarter: "II", value: "1", startingWeek: 1 },
    { cat: "February", key: "feb", quarter: "II", value: "2", startingWeek: 5 },
    { cat: "March", key: "mar", quarter: "II", value: "3", startingWeek: 9 },
    { cat: "April", key: "apr", quarter: "III", value: "4", startingWeek: 13 },
    { cat: "May", key: "may", quarter: "III", value: "5", startingWeek: 17 },
    { cat: "June", key: "jun", quarter: "III", value: "6", startingWeek: 21 },
    { cat: "July", key: "jul", quarter: "IV", value: "7", startingWeek: 25 },
    { cat: "August", key: "aug", quarter: "IV", value: "8", startingWeek: 29 },
    {
      cat: "September",
      key: "sep",
      quarter: "IV",
      value: "9",
      startingWeek: 33,
    },
    { cat: "October", key: "oct", quarter: "I", value: "10", startingWeek: 37 },
    {
      cat: "November",
      key: "nov",
      quarter: "I",
      value: "11",
      startingWeek: 40,
    },
    {
      cat: "December",
      key: "dec",
      quarter: "I",
      value: "12",
      startingWeek: 44,
    },
  ]);
  const [quarter, setQuarter] = useState<Quarter[]>([
    { cat: "I", key: "I", value: "1" },
    { cat: "II", key: "II", value: "2" },
    { cat: "III", key: "III", value: "3" },
    { cat: "IV", key: "IV", value: "4" },
  ]);
  const [region, setRegions] = useState<Region[]>([]);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [selectedYears, setSelectedYears] = useState<Year[]>([]);
  const [selectedMonths, setSelectedMonths] = useState<Month[]>([]);
  const [selectedWeeks, setSelectedWeeks] = useState<Week[]>([]);
  const [selectedQuarters, setSelectedQuarters] = useState<Quarter[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<Region[]>(region);
  const [week, setWeeks] = useState<Week[]>([]);
  const [refresh, setRefresh] = useState(true);

  const [displayTable, setDisplayTable] = useState(false);
  const [zone, setZones] = useState<Zone[]>([]);

  const [woreda, setWoredas] = useState<Woreda[]>([
    {
      cat: "woreda 1",
      key: "1",
    },
    {
      cat: "woreda 2",
      key: "2",
    },
    {
      cat: "woreda 3",
      key: "3",
    },
  ]);

  const prevSelectedYears = useRef<Year[]>([]);

  const generateWeeks = (years: Year[], months?: Month[]) => {
    const newWeeks: Week[] = [];
    if (months?.length !== 0) {
      years.forEach((selectedYear) => {
        months?.forEach((element) => {
          for (
            let index = element.startingWeek;
            index < element.startingWeek + 4;
            index++
          ) {
            newWeeks.push({
              cat: `${selectedYear.cat} - W${index}`,
              key: `${selectedYear.key}-${index}`,
              value: `${index}`,
            });
          }
        });
      });
    } else {
      years.forEach((selectedYear) => {
        for (let i = 1; i <= 52; i++) {
          newWeeks.push({
            cat: `${selectedYear.cat} - W${i}`,
            key: `${selectedYear.key}-${i}`,
            value: `${i}`,
          });
        }
      });
    }
    console.log("New Weeks : ", newWeeks);

    return newWeeks;
  };
  const generateMonths = (selectedQuarter: Quarter[]) => {
    console.log("quarters: ", selectedQuarter);
    console.log(month);

    const newMonths: Month[] = [];
    if (selectedQuarter.length !== 0) {
      selectedQuarter.forEach((quarter) => {
        let filteredMonths = rawMonths.filter((val) => {
          return val.quarter.toString() === quarter.cat.toString();
        });
        console.log("filteredMonths: ", filteredMonths);

        filteredMonths.forEach((filteredMonth) => {
          newMonths.push(filteredMonth);
        });
      });
      return newMonths;
    } else {
      return month;
    }
  };
  async function fetchRegion() {
    try {
      // Process data here, set state variables, etc.
      await getRegions().then((res: any) => {
        console.log("response regions: ", res);
        setRegions(res.data);
      });
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  }

  async function fetchZones(selectedZone: any) {
    console.log("selected zone: ", selectedZone.name);
    setZoneLoading(true);
    try {
      // Process data here, set state variables, etc.
      await getZones(selectedZone.name).then((res: any) => {
        console.log("response zones: ", res);
        let zoneNames: any[] = [];

        res.data.forEach((element: any, index: number) => {
          let zoneObj = {
            key: index + 1,
            name: element.name,
          };
          console.log("zone obj: ", zoneObj);

          zoneNames.push(zoneObj);
        });

        setZones(zoneNames);
        setZoneLoading(false);
      });
    } catch (err) {
      console.error("Error fetching data:", err);
      setZoneLoading(false);
    }
  }

  useEffect(() => {
    const weeksArray = [];
    for (let i = 1; i <= 52; i++) {
      weeksArray.push({
        cat: `Week ${i}`,
        key: i.toString(),
        value: i.toString(),
      });
    }

    setWeeks(weeksArray);
    fetchRegion();
  }, []);

  useEffect(() => {
    setRefresh(true);
    setTimeout(() => {}, 1000);
  }, [changed]);
  useEffect(() => {
    setWeekLoading(true);
    setRefresh(true);
    setTimeout(() => {
      const newWeeks = generateWeeks(selectedYears, selectedMonths);
      setWeeks(newWeeks);
      setWeekLoading(false);
      setRefresh(false);
    }, 1000);
  }, [selectedYears, selectedMonths, changed]);
  useEffect(() => {
    // setWeekLoading(true);
    setRefresh(true);
    setTimeout(() => {
      // const newWeeks = generateWeeks(selectedYears, selectedMonths);
      // set(selectedWeeks);
      // setWeekLoading(false);
      setRefresh(false);
    }, 1000);
  }, [selectedWeeks]);

  useEffect(() => {
    setRefresh(true);
    setTimeout(() => {}, 1000);
  }, [changed]);

  const onYearSelected = (selectedItems: Year[]) => {
    setSelectedYears(selectedItems);
  };
  const onMonthSelected = (selectedItems: Month[]) => {
    setSelectedMonths(selectedItems);
  };
  const onWeekSelected = (selectedItems: Week[]) => {
    setSelectedWeeks(selectedItems);
  };
  const onQuarterSelected = (selectedQuarter: Quarter[]) => {
    setSelectedQuarters(selectedQuarter);
  };
  const onRegionSelected = (selectedItems: Region) => {
    console.log("selected region: ", selectedItems);

    setSelectedRegions([...selectedRegions, selectedItems]);
    // selectedItems.forEach(element => {
    fetchZones(selectedItems);
    // });
  };

  //   const onQuarterSelected = (selectedQuarter: Quarter) => {
  //     console.log('quarter: ', selectedQuarter);

  //     setMonthLoading(true);
  //      setTimeout(() => {
  //       selectedQuarter.forEach(element => {

  //       });
  //       let filteredMonth = month.filter((elt)=>{
  //         return elt.quarter === selectedQuarter.cat
  //       });
  //  console.log('filtered month: ', filteredMonth);

  //       setMonth(filteredMonth);
  //       setMonthLoading(false);
  //      }, 2000);
  //   }
  function closeModal() {
    setIsOpen(false);
    setDisplayTable(false);
  }
  const [chartTypes, setChartTypes] = useState({
    chart1: "bar",
    chart2: "line",
    chart3: "bar",
    chart4: "pie",
    chart5: "bar",
    chart6: "line",
    chart7: "bar",
    chart8: "pie",
    chart9: "bar",
    chart10: "bar",
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "55%",
      right: "auto",
      width: "80%",
      bottom: "auto",
      zIndex: 100,
      //   marginRight: '-50%',
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <div className="main_containt">
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <FaTimesCircle
            size={20}
            color="#25476A"
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="filterMobileForm">
          <div className="filterheader">
            <p
              className="label"
              style={{
                fontSize: 14,
                color: "#7E909A",
                marginLeft: 20,
                verticalAlign: "center",
              }}
            >
              <FaFilter className="filterIcon" color="#EA6A47" /> Filter
            </p>
          </div>
          <div className="filterInputs">
            <div className="filterRaw">
              <div>
                {/* <p className="label">Year</p> */}
                <Multiselect
                  id="yearr"
                  className="multiselect"
                  style={multiselectStyle}
                  placeholder="Year"
                  keepSearchTerm={false}
                  displayValue="cat"
                  onRemove={(val) => {
                    console.log(val);
                    onYearSelected(val);
                    setChanged(!changed);
                  }}
                  onSelect={(val: Year[]) => {
                    onYearSelected(val);
                    setChanged(!changed);
                    console.log(val);
                  }}
                  options={year}
                  showCheckbox
                  hidePlaceholder
                />
              </div>

              <div>
                {/* <p className="label">Month</p> */}
                {monthLoading ? (
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                ) : (
                  <Multiselect
                    id="month"
                    className="multiselect"
                    style={multiselectStyle}
                    placeholder="month"
                    keepSearchTerm={false}
                    displayValue="cat"
                    onRemove={(val) => {
                      console.log(val);
                      onMonthSelected(val);
                      // onYearSelected(val);
                      setChanged(!changed);
                    }}
                    onSelect={(val: Month[]) => {
                      onMonthSelected(val);
                      setChanged(!changed);
                      console.log(val);
                    }}
                    options={month}
                    showCheckbox
                    hidePlaceholder
                  />
                )}
              </div>
              <div>
                {/* <p className="label">Week</p> */}
                {weekLoading ? (
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                ) : (
                  <Multiselect
                    className="multiselect"
                    style={multiselectStyle}
                    placeholder="Week"
                    displayValue="cat"
                    onRemove={(val) => {
                      console.log(val);
                      onWeekSelected(val);
                      // onYearSelected(val);
                      setWeekChanged(!weekChanged);
                    }}
                    onSelect={(val: Week[]) => {
                      onWeekSelected(val);
                      setWeekChanged(!weekChanged);
                      console.log(val);
                    }}
                    options={week}
                    showCheckbox
                  />
                )}
              </div>
              <div>
                {/* <p className="label">Region</p> */}
                <Multiselect
                  className="multiselect"
                  displayValue="name"
                
                  style={multiselectStyle}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={(value) => {
                    onRegionSelected(value[0]);
                    setChanged(!changed);
                  }}
                  onSearch={function noRefCheck() {}}
                  onSelect={(value) => {
                    console.log("select value: ", value[0]);

                    onRegionSelected(value[0]);
                    setChanged(!changed);
                  }}
                  options={region}
                  placeholder="Region"
                  showCheckbox
                />
              </div>
            </div>

            <div className="filterRaw">
              <div>
                {/* <p className="label">Zone</p> */}
                {zoneLoading ? (
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                ) : (
                  <Multiselect
                    className="multiselect"
                    displayValue="name"
                    style={multiselectStyle}
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={() => {
                      setChanged(!changed);
                    }}
                    options={zone}
                    placeholder="Zone"
                    showCheckbox
                  />
                )}
              </div>
              <div>
                {/* <p className="label">Woreda</p> */}
                <Multiselect
                  id="yearr"
                  className="multiselect"
                  style={multiselectStyle}
                  placeholder="Woreda"
                  keepSearchTerm={false}
                  displayValue="cat"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={() => {
                    setChanged(!changed);
                  }}
                  options={woreda}
                  showCheckbox
                  hidePlaceholder
                />
              </div>
              <div>
                {/* <p className="label">Service Type</p> */}
                <Multiselect
                  className="multiselect"
                  style={multiselectStyle}
                  placeholder="Service Type"
                  displayValue="key"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={() => {}}
                  options={[
                    {
                      cat: "Type 1",
                      key: "1",
                    },
                    {
                      cat: "Type 2",
                      key: "2",
                    },
                  ]}
                  showCheckbox
                  hidePlaceholder
                />
              </div>
              <div>
                {/* <p className="label">HF Type</p> */}
                <Multiselect
                  className="multiselect"
                  displayValue="key"
                  style={multiselectStyle}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={() => {
                    setChanged(!changed);
                  }}
                  options={[
                    {
                      cat: "Group 1",
                      key: "Option 1",
                    },
                    {
                      cat: "Group 1",
                      key: "Option 2",
                    },
                  ]}
                  showCheckbox
                  placeholder="HF Type"
                  hidePlaceholder
                />
              </div>
              <div>
                {/* <p className="label">HF Name</p> */}
                <Multiselect
                  className="multiselect"
                  displayValue="key"
                  style={multiselectStyle}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={() => {
                    setChanged(!changed);
                  }}
                  options={[
                    {
                      cat: "Group 1",
                      key: "Option 1",
                    },
                    {
                      cat: "Group 1",
                      key: "Option 2",
                    },
                  ]}
                  placeholder="HF Name"
                  hidePlaceholder
                  showCheckbox
                />
              </div>
            </div>
          </div>
          {/* <div className="filterButontainer">
                   <button type="button" className='filterButton'>Filter</button>
                </div> */}
          <div></div>
        </div>
      </Modal>

      <div className="filterMobile">
        <div className="filterheader">
          <Button
            variant="contained"
            title="Filter"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Filter
          </Button>
        </div>
      </div>
      <div className="filter">
        <div className="filterheader">
          <p
            className="label"
            style={{
              fontSize: 14,
              color: "#7E909A",
              marginLeft: 20,
              verticalAlign: "center",
            }}
          >
            <FaFilter className="filterIcon" color="#EA6A47" /> Filter
          </p>
        </div>
        <div className="filterInputs">
          <div className="filterRaw">
            <div>
              <p className="label">Year</p>
              <Multiselect
                id="yearr"
                className="multiselect"
                style={multiselectStyle}
                placeholder=""
                keepSearchTerm={false}
                displayValue="cat"
                onRemove={(val) => {
                  console.log(val);
                  onYearSelected(val);
                  setChanged(!changed);
                }}
                onSelect={(val: Year[]) => {
                  onYearSelected(val);
                  setChanged(!changed);
                  console.log(val);
                }}
                options={year}
                showCheckbox
                hidePlaceholder
              />
            </div>

            <div>
              <p className="label">Month</p>
              {monthLoading ? (
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              ) : (
                <Multiselect
                  id="month"
                  className="multiselect"
                  style={multiselectStyle}
                  placeholder=""
                  keepSearchTerm={false}
                  displayValue="cat"
                  onRemove={(val) => {
                    console.log(val);
                    onMonthSelected(val);
                    // onYearSelected(val);
                    setChanged(!changed);
                  }}
                  onSelect={(val: Month[]) => {
                    onMonthSelected(val);
                    setChanged(!changed);
                    console.log(val);
                  }}
                  options={month}
                  showCheckbox
                  hidePlaceholder
                />
              )}
            </div>
            <div>
              <p className="label">Week</p>
              {weekLoading ? (
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              ) : (
                <Multiselect
                  className="multiselect"
                  style={multiselectStyle}
                  placeholder=""
                  displayValue="cat"
                  onRemove={(val) => {
                    console.log(val);
                    onWeekSelected(val);
                    // onYearSelected(val);
                    setWeekChanged(!weekChanged);
                  }}
                  onSelect={(val: Week[]) => {
                    onWeekSelected(val);
                    setWeekChanged(!weekChanged);
                    console.log(val);
                  }}
                  options={week}
                  showCheckbox
                />
              )}
            </div>
            <div>
              <p className="label">Region</p>
              <Multiselect
                className="multiselect"
                displayValue="name"
                style={multiselectStyle}
                onKeyPressFn={function noRefCheck() {}}
                onRemove={(value) => {
                  onRegionSelected(value[0]);
                  setChanged(!changed);
                }}
                onSearch={function noRefCheck() {}}
                onSelect={(value) => {
                  console.log("select value: ", value[0]);

                  onRegionSelected(value[0]);
                  setChanged(!changed);
                }}
                options={region}
                placeholder=""
                showCheckbox
              />
            </div>
          </div>

          <div className="filterRaw">
            <div>
              <p className="label">Zone</p>
              {zoneLoading ? (
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              ) : (
                <Multiselect
                  className="multiselect"
                  displayValue="name"
                  style={multiselectStyle}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={() => {
                    setChanged(!changed);
                  }}
                  options={zone}
                  placeholder=""
                  showCheckbox
                />
              )}
            </div>
            <div>
              <p className="label">Woreda</p>
              <Multiselect
                id="yearr"
                className="multiselect"
                style={multiselectStyle}
                placeholder=""
                keepSearchTerm={false}
                displayValue="cat"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={() => {
                  setChanged(!changed);
                }}
                options={woreda}
                showCheckbox
                hidePlaceholder
              />
            </div>
            <div>
              <p className="label">Service Type</p>
              <Multiselect
                className="multiselect"
                style={multiselectStyle}
                placeholder=""
                displayValue="key"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={() => {}}
                options={[
                  {
                    cat: "Type 1",
                    key: "1",
                  },
                  {
                    cat: "Type 2",
                    key: "2",
                  },
                ]}
                showCheckbox
                hidePlaceholder
              />
            </div>
            <div>
              <p className="label">HF Type</p>
              <Multiselect
                className="multiselect"
                displayValue="key"
                style={multiselectStyle}
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={() => {
                  setChanged(!changed);
                }}
                options={[
                  {
                    cat: "Group 1",
                    key: "Option 1",
                  },
                  {
                    cat: "Group 1",
                    key: "Option 2",
                  },
                ]}
                showCheckbox
                placeholder=""
                hidePlaceholder
              />
            </div>
            <div>
              <p className="label">HF Name</p>
              <Multiselect
                className="multiselect"
                displayValue="key"
                style={multiselectStyle}
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={() => {
                  setChanged(!changed);
                }}
                options={[
                  {
                    cat: "Group 1",
                    key: "Option 1",
                  },
                  {
                    cat: "Group 1",
                    key: "Option 2",
                  },
                ]}
                placeholder=""
                hidePlaceholder
                showCheckbox
              />
            </div>
          </div>
        </div>
        {/* <div className="filterButontainer">
                   <button type="button" className='filterButton'>Filter</button>
                </div> */}
        <div></div>
      </div>

      <div className="threeCol">
        <div>
          <FunctionalHealthFacilities />
        </div>
        <div>
          <ReportingHealthFacilitiesByRegion
            years={selectedYears.map((year) => {
              return year.cat;
            })}
            months={selectedMonths.map((month) => {
              return month.value;
            })}
            quarters={selectedQuarters.map((quarter) => {
              return quarter.value;
            })}
            weeks={selectedWeeks.map((week) => {
              return week.value;
            })}
          />
        </div>
        <div>
          <HeatMap
            regions={selectedRegions.map((region) => {
              return region.name;
            })}
          />
        </div>
      </div>
      <div className="threeCol2">
        <div>
          <UnderFiveMalnutrition
            years={selectedYears.map((year) => {
              return year.cat;
            })}
            months={selectedMonths.map((month) => {
              return month.value;
            })}
            quarters={selectedQuarters.map((quarter) => {
              return quarter.value;
            })}
            weeks={selectedWeeks.map((week) => {
              return week.value;
            })}
          />
        </div>
        <div>
          <MAMByRegion />
        </div>
        <div>
          <SAMByRegion />
        </div>
      </div>
      <div className="threeCol2">
        <div>
          <SevereAcuteMalnutritionOTP />
        </div>
        <div>
          <SAMOTPbyRegion />
        </div>
        <div>
          <SAMSCbyRegion />
        </div>
      </div>
      <div className="twoCol">
        <div>
          <RMNCH
            years={selectedYears.map((year) => {
              return year.cat;
            })}
            months={selectedMonths.map((month) => {
              return month.value;
            })}
            quarters={selectedQuarters.map((quarter) => {
              return quarter.value;
            })}
            weeks={selectedWeeks.map((week) => {
              return week.value;
            })}
          />
        </div>

        <div>
          <RMNCH_VACCINATION
            years={selectedYears.map((year) => {
              return year.cat;
            })}
            months={selectedMonths.map((month) => {
              return month.value;
            })}
            quarters={selectedQuarters.map((quarter) => {
              return quarter.value;
            })}
            weeks={selectedWeeks.map((week) => {
              return week.value;
            })}
          />
          {/* <HeatMap regions={selectedRegions.map((region)=>{
            return region.name;
          })}/> */}
        </div>
      </div>

      <div className="twoCol">
        <div style={{ flex: 2 }}>
          <PatientsLinked regions={region} />
        </div>
        <div
          style={{
            flex: 1,
            maxHeight: "50vh",
            maxWidth: "30vw",
            backgroundColor: "white",
            borderRadius: "5px",
            overflow: "hidden",
            position: "relative",
            padding: 10,
          }}
        >
          <HeatMap
            regions={selectedRegions.map((region) => {
              return region.name;
            })}
          />
        </div>
      </div>
   
      {/* <div className="twoRowCol2">
        <div>
        <SevereAcuteMalnutritionOTP />
        </div>
        <div className="childContainer">
           <div className="childCol">
           <MAMByRegion />
           </div>
           <div className="childCol">
           <MAMByRegion />
           </div>
        </div>
      </div> */}

      {/* <div className="twoCol">
        <div>
          <PatientsLinked />
        </div>
        <div
          style={{
            flex: 0.4,
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
        >
          <div className="childColumn">
            <ARTByRegion />
          </div>
          <div className="childColumn">
            <ARTByRegion />
          </div>
        </div>
      </div> */}
      <div className="twoCol">
        <div>
          <MalariaCasesAndOPDAttendants
            years={selectedYears.map((year) => {
              return year.cat;
            })}
            months={selectedMonths.map((month) => {
              return month.value;
            })}
            quarters={selectedQuarters.map((quarter) => {
              return quarter.value;
            })}
            weeks={selectedWeeks.map((week) => {
              return week.value;
            })}
          />
        </div>
        <div>
          <MentalHealthScreenedCareTreatment />
        </div>
      </div>
      <div className="oneCol">
        <div>
          <SGBVMHPSS />
        </div>
      </div>
      <div className="twoCol">
        <div>
          <NumberOfHivTesting
            years={selectedYears.map((year) => {
              return year.cat;
            })}
            months={selectedMonths.map((month) => {
              return month.value;
            })}
            quarters={selectedQuarters.map((quarter) => {
              return quarter.value;
            })}
            weeks={selectedWeeks.map((week) => {
              return week.value;
            })}
          />
        </div>
        <div>
          <NumberOfEmergencyAttendants
            years={selectedYears.map((year) => {
              return year.cat;
            })}
            months={selectedMonths.map((month) => {
              return month.value;
            })}
            quarters={selectedQuarters.map((quarter) => {
              return quarter.value;
            })}
            weeks={selectedWeeks.map((week) => {
              return week.value;
            })}
          />
        </div>
      </div>
      {/* <div className="twoCol">
        <div>
          <NumberOfHivTesting />
        </div>
        <div>
          <NumberOfEmergencyAttendants />
        </div>
      </div> */}
    </div>
  );
};

export default Visualization;

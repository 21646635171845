/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    "total_sgbv": 6,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Abiyi Adi HC",
    "hfsitename": "Abiyi Adi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 3,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 3,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Abiadi",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Abrha Atsibha HC",
    "hfsitename": "Abrha Atsibha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Adekil HC",
    "hfsitename": "Adekil HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Adi ayno HC",
    "hfsitename": "Adi ayno HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 76,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Adigrat Hosp",
    "hfsitename": "Adigrat Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 69,
    "region": "Tigray",
    "woreda": "Adigrat",
    "gbvsurvivorsprovidedclinicalservice": 7
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Adigudom Cluster",
    "hfsitename": "Adigudom Cluster",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 100,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Adigudom PH",
    "hfsitename": "Adigudom PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 50,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 50,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Adiha HC",
    "hfsitename": "Adiha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Adikeslbes HC",
    "hfsitename": "Adikeslbes HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Adishmdhun HC",
    "hfsitename": "Adishmdhun HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawelti",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Agazi HC",
    "hfsitename": "Agazi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Agbe HC",
    "hfsitename": "Agbe HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tanqua Abergele",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "agulae HC",
    "hfsitename": "agulae HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Alasa HC",
    "hfsitename": "Alasa HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Aragure HC",
    "hfsitename": "Aragure HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Arena HC",
    "hfsitename": "Arena HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": "NULL",
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": "NULL"
  },
  {
    "total_sgbv": 3,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Axum HC",
    "hfsitename": "Axum HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 3,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Axum R.Hosp",
    "hfsitename": "Axum R.Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 492,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Ayder R.Hosp",
    "hfsitename": "Ayder R.Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 164,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 164,
    "individualsprovidedpsychologicalcounselingindividualgroup": 164,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Azeba HC",
    "hfsitename": "Azeba HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Beatiakor HC",
    "hfsitename": "Beatiakor HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "betmera HC",
    "hfsitename": "betmera HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Chelekot HC",
    "hfsitename": "Chelekot HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Conerel Tadele HC",
    "hfsitename": "Conerel Tadele HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Wukro",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "daerere HC",
    "hfsitename": "daerere HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Debri HC",
    "hfsitename": "Debri HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hadnet",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Degamba HC",
    "hfsitename": "Degamba HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Dengelet HC",
    "hfsitename": "Dengelet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Dera HC",
    "hfsitename": "Dera HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Dongolat HC",
    "hfsitename": "Dongolat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "E/Hamus HC",
    "hfsitename": "E/Hamus HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Endamariam HC",
    "hfsitename": "Endamariam HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": "NULL"
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "E/Reboue HC",
    "hfsitename": "E/Reboue HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "E/Selus HC",
    "hfsitename": "E/Selus HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Esret HC",
    "hfsitename": "Esret HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Finarwa HC",
    "hfsitename": "Finarwa HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Fresemaetat PH",
    "hfsitename": "Fresemaetat PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Freweyni PH",
    "hfsitename": "Freweyni PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Gijet HC",
    "hfsitename": "Gijet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "guagot HC",
    "hfsitename": "guagot HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 24,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Guya HC",
    "hfsitename": "Guya HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 12,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 12,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 36,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Guya HC",
    "hfsitename": "Guya HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 12,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 12,
    "individualsprovidedpsychologicalcounselingindividualgroup": 12,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": "NULL"
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Guya HC",
    "hfsitename": "Guya HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": "NULL",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": "NULL",
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 12,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Guya HC",
    "hfsitename": "Guya HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": "NULL",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": "NULL",
    "individualsprovidedpsychologicalcounselingindividualgroup": 12,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": "NULL"
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Habes HC",
    "hfsitename": "Habes HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Hawelti HC",
    "hfsitename": "Hawelti HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Hreko HC",
    "hfsitename": "Hreko HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "H/Selam PH",
    "hfsitename": "H/Selam PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "H/Selam PH",
    "hfsitename": "H/Selam PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 138,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "H/Selam PH",
    "hfsitename": "H/Selam PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 69,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 69,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 138,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "H/Selam PH",
    "hfsitename": "H/Selam PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 69,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 69,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 1,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Kara HC",
    "hfsitename": "Kara HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 1
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Kasech HC",
    "hfsitename": "Kasech HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hadnet",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "kelisha HC",
    "hfsitename": "kelisha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Koraro HC",
    "hfsitename": "Koraro HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Lachi HC",
    "hfsitename": "Lachi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Semen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Lekatit 11 P.Hos",
    "hfsitename": "Lekatit 11 P.Hos",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adi Haki",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Mariam Meko HC",
    "hfsitename": "Mariam Meko HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "MeadiHC",
    "hfsitename": "MeadiHC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Mechare HC",
    "hfsitename": "Mechare HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Megab HC",
    "hfsitename": "Megab HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Mekelle HC",
    "hfsitename": "Mekelle HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kedamay Weyane",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 3,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Mekelle Hosp",
    "hfsitename": "Mekelle Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Semen",
    "gbvsurvivorsprovidedclinicalservice": 3
  },
  {
    "total_sgbv": 52,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Mekoni PH",
    "hfsitename": "Mekoni PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 50,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 2
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Miliniem HC",
    "hfsitename": "Miliniem HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": "NULL",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": "NULL",
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "M/kdusan HC",
    "hfsitename": "M/kdusan HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "M/mekedm HC",
    "hfsitename": "M/mekedm HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "M/miety HC",
    "hfsitename": "M/miety HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Mugulat HC",
    "hfsitename": "Mugulat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Mulu Asefa PH",
    "hfsitename": "Mulu Asefa PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Negash HC",
    "hfsitename": "Negash HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Romnanat HC",
    "hfsitename": "Romnanat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 16,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "S.Adiyabo HC",
    "hfsitename": "S.Adiyabo HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": "NULL",
    "region": "Tigray",
    "woreda": "tahtay adyabo",
    "gbvsurvivorsprovidedclinicalservice": 16
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "samre HC",
    "hfsitename": "samre HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "samre HC",
    "hfsitename": "samre HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": "NULL",
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Semen HC",
    "hfsitename": "Semen HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Semha HC",
    "hfsitename": "Semha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Serawat HC",
    "hfsitename": "Serawat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawelti",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Sewne HC",
    "hfsitename": "Sewne HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Sheraro HC",
    "hfsitename": "Sheraro HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sheraro",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Tikul HC",
    "hfsitename": "Tikul HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Tsigereda HC",
    "hfsitename": "Tsigereda HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Welwalo HC",
    "hfsitename": "Welwalo HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "WorkamabaHC",
    "hfsitename": "WorkamabaHC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Wukro HC",
    "hfsitename": "Wukro HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Wukro",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 5,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Wukro Hosp",
    "hfsitename": "Wukro Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Wukro",
    "gbvsurvivorsprovidedclinicalservice": 5
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 4,
    "nameofhealthfacility": "Yechila PH",
    "hfsitename": "Yechila PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tanqua Abergele",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Abiyi Adi HC",
    "hfsitename": "Abiyi Adi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Abiadi",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Abrha Atsibha HC",
    "hfsitename": "Abrha Atsibha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Adekil HC",
    "hfsitename": "Adekil HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Adi ayno HC",
    "hfsitename": "Adi ayno HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "adidawro HC",
    "hfsitename": "adidawro HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Lailayadiabo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 120,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Adigrat Hosp",
    "hfsitename": "Adigrat Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 40,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 40,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adigrat",
    "gbvsurvivorsprovidedclinicalservice": 40
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Adigudom Cluster",
    "hfsitename": "Adigudom Cluster",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": "NULL",
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Adigudom PH",
    "hfsitename": "Adigudom PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Adiha HC",
    "hfsitename": "Adiha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 1,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Adikeslbes HC",
    "hfsitename": "Adikeslbes HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 1
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Adishmdhun HC",
    "hfsitename": "Adishmdhun HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawelti",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Agazi HC",
    "hfsitename": "Agazi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Agbe HC",
    "hfsitename": "Agbe HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tanqua Abergele",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Agoustin HC",
    "hfsitename": "Agoustin HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Shire",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "agulae HC",
    "hfsitename": "agulae HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Alasa HC",
    "hfsitename": "Alasa HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Aragure HC",
    "hfsitename": "Aragure HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Arena HC",
    "hfsitename": "Arena HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 1,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "axum HC",
    "hfsitename": "axum HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 1,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Axum HC",
    "hfsitename": "Axum HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 13,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Axum R.Hosp",
    "hfsitename": "Axum R.Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 13,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 164,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Ayder R.Hosp",
    "hfsitename": "Ayder R.Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 164,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Azeba HC",
    "hfsitename": "Azeba HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Beatiakor HC",
    "hfsitename": "Beatiakor HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "betmera HC",
    "hfsitename": "betmera HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Chelekot HC",
    "hfsitename": "Chelekot HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Conerel Tadele HC",
    "hfsitename": "Conerel Tadele HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Wukro",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "daerere HC",
    "hfsitename": "daerere HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "daerere HC",
    "hfsitename": "daerere HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Debri HC",
    "hfsitename": "Debri HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hadnet",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Degamba HC",
    "hfsitename": "Degamba HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Dengelet HC",
    "hfsitename": "Dengelet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Dera HC",
    "hfsitename": "Dera HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Dongolat HC",
    "hfsitename": "Dongolat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "E/Hamus HC",
    "hfsitename": "E/Hamus HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Endamariam HC",
    "hfsitename": "Endamariam HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "E/Reboue HC",
    "hfsitename": "E/Reboue HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "E/Selus HC",
    "hfsitename": "E/Selus HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 1,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Esret HC",
    "hfsitename": "Esret HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": "NULL",
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 1
  },
  {
    "total_sgbv": 3,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Finarwa HC",
    "hfsitename": "Finarwa HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 3,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Fresemaetat PH",
    "hfsitename": "Fresemaetat PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Freweyni PH",
    "hfsitename": "Freweyni PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Gijet HC",
    "hfsitename": "Gijet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "guagot HC",
    "hfsitename": "guagot HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Gurero HC",
    "hfsitename": "Gurero HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Guya HC",
    "hfsitename": "Guya HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Guya HC",
    "hfsitename": "Guya HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Habes HC",
    "hfsitename": "Habes HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "hadnet HC",
    "hfsitename": "hadnet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "medebayzana",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 2,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Hawelti HC",
    "hfsitename": "Hawelti HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 2
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Hreko HC",
    "hfsitename": "Hreko HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "H/Selam PH",
    "hfsitename": "H/Selam PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "H/Selam PH",
    "hfsitename": "H/Selam PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguea Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Kara HC",
    "hfsitename": "Kara HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Kasech HC",
    "hfsitename": "Kasech HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hadnet",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "kelisha HC",
    "hfsitename": "kelisha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "k/geba HC",
    "hfsitename": "k/geba HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Asgede tsimbla",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Koraro HC",
    "hfsitename": "Koraro HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Kubrto",
    "hfsitename": "Kubrto",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "medebayzana",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 6,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Kukfto HC",
    "hfsitename": "Kukfto HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 2,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 2,
    "individualsprovidedpsychologicalcounselingindividualgroup": "NULL",
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 2
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Lachi HC",
    "hfsitename": "Lachi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Semen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Lekatit 11 P.Hos",
    "hfsitename": "Lekatit 11 P.Hos",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adi Haki",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Mariam Meko HC",
    "hfsitename": "Mariam Meko HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "MeadiHC",
    "hfsitename": "MeadiHC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Mechare HC",
    "hfsitename": "Mechare HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Meda Agame HC",
    "hfsitename": "Meda Agame HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adigrat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Megab HC",
    "hfsitename": "Megab HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Mekelle HC",
    "hfsitename": "Mekelle HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kedamay Weyane",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 6,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Mekelle Hosp",
    "hfsitename": "Mekelle Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 2,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 2,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Semen",
    "gbvsurvivorsprovidedclinicalservice": 2
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Mekoni PH",
    "hfsitename": "Mekoni PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "M/ Hance HC",
    "hfsitename": "M/ Hance HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Asgede tsimbla",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 152,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Miliniem HC",
    "hfsitename": "Miliniem HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 2,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 2,
    "individualsprovidedpsychologicalcounselingindividualgroup": 146,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 2
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "M/kdusan HC",
    "hfsitename": "M/kdusan HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "M/mekedm HC",
    "hfsitename": "M/mekedm HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "M/miety HC",
    "hfsitename": "M/miety HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Mugulat HC",
    "hfsitename": "Mugulat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Mulu Asefa PH",
    "hfsitename": "Mulu Asefa PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Negash HC",
    "hfsitename": "Negash HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Romnanat HC",
    "hfsitename": "Romnanat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Ruba keza HC",
    "hfsitename": "Ruba keza HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tanqua Abergele",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 1,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "S.Adiyabo HC",
    "hfsitename": "S.Adiyabo HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tahtya Adyak",
    "gbvsurvivorsprovidedclinicalservice": 1
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "samre HC",
    "hfsitename": "samre HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 14,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "samre HC",
    "hfsitename": "samre HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 7,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Semen HC",
    "hfsitename": "Semen HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Semha HC",
    "hfsitename": "Semha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Serawat HC",
    "hfsitename": "Serawat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawelti",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Sewne HC",
    "hfsitename": "Sewne HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 24,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Sheraro HC",
    "hfsitename": "Sheraro HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 3,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 3,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sheraro",
    "gbvsurvivorsprovidedclinicalservice": 18
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Teklisweat HC",
    "hfsitename": "Teklisweat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adigrat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Tikul HC",
    "hfsitename": "Tikul HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Tikul HC",
    "hfsitename": "Tikul HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "medebayzana",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Tsigereda HC",
    "hfsitename": "Tsigereda HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Welwalo HC",
    "hfsitename": "Welwalo HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsada Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "WorkamabaHC",
    "hfsitename": "WorkamabaHC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 114,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "wukro",
    "hfsitename": "wukro",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 114,
    "region": "Tigray",
    "woreda": "tahtya maichew",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Wukro HC",
    "hfsitename": "Wukro HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Wukro",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 37,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Wukro Hosp",
    "hfsitename": "Wukro Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 4,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 4,
    "individualsprovidedpsychologicalcounselingindividualgroup": 24,
    "region": "Tigray",
    "woreda": "Wukro",
    "gbvsurvivorsprovidedclinicalservice": 5
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Yechila PH",
    "hfsitename": "Yechila PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tanqua Abergele",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "zana HC",
    "hfsitename": "zana HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "medebayzana",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 5,
    "nameofhealthfacility": "Zizet HC",
    "hfsitename": "Zizet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Abi adi HC",
    "hfsitename": "Abi adi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Abi Adi",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Abrha Atsibha HC",
    "hfsitename": "Abrha Atsibha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adekeala HC",
    "hfsitename": "Adekeala HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 4,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adi Aynom HC",
    "hfsitename": "Adi Aynom HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 4,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adi beladay HC",
    "hfsitename": "Adi beladay HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Laelay Adiabo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adigudom PH",
    "hfsitename": "Adigudom PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adi Hageray HC",
    "hfsitename": "Adi Hageray HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tahtay Adiabo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adiha HC",
    "hfsitename": "Adiha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adikeslbes HC",
    "hfsitename": "Adikeslbes HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsaeda Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adikeyh HC",
    "hfsitename": "Adikeyh HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adi nebried HC",
    "hfsitename": "Adi nebried HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Laelay Adiabo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adishihu PH",
    "hfsitename": "Adishihu PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Emba Alaje",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adishmdhun HC",
    "hfsitename": "Adishmdhun HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawelti",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 4,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Adwa HC",
    "hfsitename": "Adwa HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 3,
    "individualsprovidedpsychologicalcounselingindividualgroup": 1,
    "region": "Tigray",
    "woreda": "Adwa",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Agazi HC",
    "hfsitename": "Agazi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsaeda Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Agbe HC",
    "hfsitename": "Agbe HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tanqua Abergele",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Agoustina HC",
    "hfsitename": "Agoustina HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Shire",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Agulae HC",
    "hfsitename": "Agulae HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Alasa HC",
    "hfsitename": "Alasa HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguia Tembien",
    "gbvsurvivorsprovidedclinicalservice": "NULL"
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Aragure HC",
    "hfsitename": "Aragure HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 15,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Arena HC",
    "hfsitename": "Arena HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 15,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 2,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Axum HC",
    "hfsitename": "Axum HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 2,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 115,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Axum K/mariam Hosp",
    "hfsitename": "Axum K/mariam Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 2,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 3,
    "individualsprovidedpsychologicalcounselingindividualgroup": 102,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 8
  },
  {
    "total_sgbv": 44,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Axum R.Hosp",
    "hfsitename": "Axum R.Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 10,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 25,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 9
  },
  {
    "total_sgbv": 82,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Ayder R.Hosp",
    "hfsitename": "Ayder R.Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 41,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 41
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Azeba HC",
    "hfsitename": "Azeba HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Beatiakor HC",
    "hfsitename": "Beatiakor HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 4,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Betmera HC",
    "hfsitename": "Betmera HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 4
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Birshwa PH",
    "hfsitename": "Birshwa PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tahtay Maichew",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Bizet HC",
    "hfsitename": "Bizet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "B/yowhans HC",
    "hfsitename": "B/yowhans HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adwa Geter",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Chelekot HC",
    "hfsitename": "Chelekot HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Conerel Tadele HC",
    "hfsitename": "Conerel Tadele HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Wukro",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Daerere HC",
    "hfsitename": "Daerere HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguia Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 22,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Daerere HC",
    "hfsitename": "Daerere HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 11,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "D/birhan HC",
    "hfsitename": "D/birhan HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Laelay Maichew",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Debri HC",
    "hfsitename": "Debri HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hadnet",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Debub HC",
    "hfsitename": "Debub HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Degamba HC",
    "hfsitename": "Degamba HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Denglet HC",
    "hfsitename": "Denglet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsaeda Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Dera HC",
    "hfsitename": "Dera HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "E/Hamus HC",
    "hfsitename": "E/Hamus HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsaeda Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Endabaguna PH",
    "hfsitename": "Endabaguna PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Asgede Tsimbla",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Endamariam HC",
    "hfsitename": "Endamariam HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguia Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "E/Reboue HC",
    "hfsitename": "E/Reboue HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsaeda Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "E/selus HC",
    "hfsitename": "E/selus HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Eshir HC",
    "hfsitename": "Eshir HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adwa Geter",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Esret HC",
    "hfsitename": "Esret HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Finarwa HC",
    "hfsitename": "Finarwa HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Fresemaetat PH",
    "hfsitename": "Fresemaetat PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Freweyni PH",
    "hfsitename": "Freweyni PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Sasie Tsaeda Emba",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Gendebta HC",
    "hfsitename": "Gendebta HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adwa Geter",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Gijet HC",
    "hfsitename": "Gijet HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Guhgot HC",
    "hfsitename": "Guhgot HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 15,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Gurero HC",
    "hfsitename": "Gurero HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 15,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 18,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Guya HC",
    "hfsitename": "Guya HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 18,
    "region": "Tigray",
    "woreda": "Kola Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Habes HC",
    "hfsitename": "Habes HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Hareko HC",
    "hfsitename": "Hareko HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 3,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Hawelti HC",
    "hfsitename": "Hawelti HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 3
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Hiwane HC",
    "hfsitename": "Hiwane HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "H/Meshal HC",
    "hfsitename": "H/Meshal HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "H/Selam PH",
    "hfsitename": "H/Selam PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Deguia Tembien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Kasech HC",
    "hfsitename": "Kasech HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hadnet",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Kelisha HC",
    "hfsitename": "Kelisha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 2,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "K/geba HC",
    "hfsitename": "K/geba HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 2,
    "region": "Tigray",
    "woreda": "Asgede Tsimbla",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Koraro HC",
    "hfsitename": "Koraro HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Lachi HC",
    "hfsitename": "Lachi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Semen",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Lekatit 11 HC",
    "hfsitename": "Lekatit 11 HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Laelay Maichew",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Lekatit 11 P.Hos",
    "hfsitename": "Lekatit 11 P.Hos",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adi Haki",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Mariam Meko HC",
    "hfsitename": "Mariam Meko HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "May Atsmi HC",
    "hfsitename": "May Atsmi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tahtay Maichew",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Meadi HC",
    "hfsitename": "Meadi HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Meda Agame HC",
    "hfsitename": "Meda Agame HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adigrat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Megab HC",
    "hfsitename": "Megab HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawzien",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Mekelle HC",
    "hfsitename": "Mekelle HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kedamay Weyane",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 6,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Mekelle Hosp",
    "hfsitename": "Mekelle Hosp",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 3,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Semen",
    "gbvsurvivorsprovidedclinicalservice": 3
  },
  {
    "total_sgbv": 2,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Mekoni PH",
    "hfsitename": "Mekoni PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 2,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Raya Azebo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 31,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "M/Hanse",
    "hfsitename": "M/Hanse",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Asgede Tsimbla",
    "gbvsurvivorsprovidedclinicalservice": 31
  },
  {
    "total_sgbv": 4,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Millenium HC",
    "hfsitename": "Millenium HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 4,
    "region": "Tigray",
    "woreda": "Axum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "M/kdusan HC",
    "hfsitename": "M/kdusan HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "M/Mekden HC",
    "hfsitename": "M/Mekden HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "M/miety HC",
    "hfsitename": "M/miety HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Mugulat HC",
    "hfsitename": "Mugulat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ganta Afeshum",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Mulu Asefa PH",
    "hfsitename": "Mulu Asefa PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Atsbi Wonberta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 2,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "M/weyni HC",
    "hfsitename": "M/weyni HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 2,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Laelay Maichew",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Negash HC",
    "hfsitename": "Negash HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Kilte Awlaelo",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Rahya HC",
    "hfsitename": "Rahya HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adwa Geter",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Romanat HC",
    "hfsitename": "Romanat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Rubakeze HC",
    "hfsitename": "Rubakeze HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Tanqua Abergele",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Samre HC",
    "hfsitename": "Samre HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Samre PH",
    "hfsitename": "Samre PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Seharti Samre",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Selam HC",
    "hfsitename": "Selam HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Adwa Geter",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Selekleka PH",
    "hfsitename": "Selekleka PH",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Medebay Zana",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 46,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Selewa HC",
    "hfsitename": "Selewa HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 44,
    "region": "Tigray",
    "woreda": "Emba Alaje",
    "gbvsurvivorsprovidedclinicalservice": 2
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Semen HC",
    "hfsitename": "Semen HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Ayder",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Semha HC",
    "hfsitename": "Semha HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Enderta",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Senale HC",
    "hfsitename": "Senale HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hintalo Wajirat",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
  {
    "total_sgbv": 0,
    "year": 2023,
    "week": 6,
    "nameofhealthfacility": "Serawat HC",
    "hfsitename": "Serawat HC",
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtom": 0,
    "totalnumberofsexualandgenderbasedviolence_sgbv_casesreferredtos": 0,
    "individualsprovidedpsychologicalcounselingindividualgroup": 0,
    "region": "Tigray",
    "woreda": "Hawelti",
    "gbvsurvivorsprovidedclinicalservice": 0
  },
 
]
  
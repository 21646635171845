import axios from "axios";

async function getAllDimensions() {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://hed.k8s.sandboxaddis.com/dimenssion",
    headers: {},
  };
  try {
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

async function getPivotTable(datas: string) {
  console.log("data: ", datas);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://hed.k8s.sandboxaddis.com/dimenssion/buildtable",
    headers: {
      "Content-Type": "application/json"
    },
    data: datas
  };
  try {
    const response = await axios.request(config);
    console.log("RES: ", response);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}


async function savePivotTable(payload:any){
  console.log("payload data: ", payload);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://hed.k8s.sandboxaddis.com/Dimenssion/savePivotTable",
    headers: {
      "Content-Type": "application/json"
    },
    data: payload
  };
  try {
    const response = await axios.request(config);
    console.log("RES: ", response);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

async function getAllSavedTables() {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://hed.k8s.sandboxaddis.com/Dimenssion/getPivotTables",
    headers: {},
  };
  try {
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export { getAllDimensions, getPivotTable,getAllSavedTables, savePivotTable};

import DataTable, { TableColumn } from "react-data-table-component";

interface TableProps {
  columns: TableColumn<any>[];
  rows: any[];
}

const Table = (props: TableProps) => {
  return <DataTable columns={props.columns} data={props.rows} />;
};

export default Table;

import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Polygon,
  GeoJSON,
  Marker,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { FaTimesCircle, FaTimes } from "react-icons/fa";
import ethiopianRegions from "../../utils/GeoJson/geoJson";
import ChartHeader from "../ChartHeader/chartHeader";
import { ColorRing } from "react-loader-spinner";
import theme from "../../utils/theme";

import Modal from "react-modal";
import L from "leaflet";
import marker from "../../assets/images/marker_icon.png";
const center = [9.145, 40.4897];

export default function InstitutionsOnMap({ onClose, region }) {
  const [showMap, setShowMap] = useState(true);
  const [filterdRegion,setFilteredRegion] = useState(ethiopianRegions);
  const markerCoordinates = [
    { lat: 7.828711, lng: 36.151236 },
    { lat: 6.643195, lng: 42.029683 },
    { lat: 11.904862, lng: 36.400058 },
    // Add more marker coordinates as needed
  ];
  const [modalIsOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setShowMap(false);
    setTimeout(() => {
      setShowMap(true);
       console.log("passed regions", region);
    }, 1000);
  }, [region]);
  const customIcon = new L.Icon({
    iconUrl: marker,
    iconSize: [10, 10], // Adjust the size of the icon
  });
  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.REGIONNAME) {
      const fillColor = "#cccccc";

      layer.setStyle({
        fillColor: fillColor,
      });
      layer.bindPopup(
        `<div>${feature.properties.REGIONNAME}</div>
      <div>${feature.properties.data}</div>`,
        {
          autoPan: false,
          className: "custom-popup", // Define a custom class for the popup
          style: {
            zIndex: 3000,
            // Replace 1000 with your desired z-index value
          },
        }
      );
      layer.on({
        mouseover: (e) => {
          // e.target.setStyle({ fillColor: '#1670C1' }); // Change color on hover
          layer.openPopup();
        },
        mouseout: (e) => {
          // Restore original color on hover out
          layer.closePopup();
          // e.target.setStyle({ fillColor: '#CCCCCC' });
        },
      });
    }
  };
  return (
    <div>
      {showMap ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "90vh",
            padding: 10,
            width: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              height: 30,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: 0.05,
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <FaTimes
              size={24}
              color={theme.secondaryColor}
              onClick={onClose}
              style={{ cursor: "pointer" }}
            />
            <p style={{ color: theme.textColor, marginLeft:15, fontSize:18 }}>Institution Details</p>
          </div>
          <MapContainer
            center={center}
            fadeAnimation={true}
            zoom={6}
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
            
              overflow: "hidden",
              padding: 10,
              zIndex: 2000,
            }}
            scrollWheelZoom={false}
            zoomControl={true}
            doubleClickZoom={false}
            dragging={true}
          >
            <GeoJSON
              data={ethiopianRegions.features.filter((regions,index)=>{
                 return region.indexOf(regions.properties.REGIONNAME) !== -1;
                })
               }
              style={{
                color: "white",
                weight: 1.5,
                opacity: 1,
                fillOpacity: 1,
                fillColor: "#C5D5E4",
              }}
            />
            {/* {markers.map((location, index) => (
              <Marker
                key={index}
                position={[location.x, location.y]}
                icon={customIcon}
              >
                <Popup autoPan={false} keepInView>
                  <div style={{  borderRadius: 5 }}>

                    <h4>    Zone: {location.Zone}
  </h4>
                    <h4>  Wereda: {location.Wereda}
  </h4>
                    <h4> Name_of_Health_Facility: {location.Name_of_Health_Facility},
 </h4>
                    <h4>  Type_of_Facility: {location.Type_of_Facility},
 
 </h4>

  <h4>
  CatchmentPop: {location. CatchmentPop}
  </h4>
                  </div>
                </Popup>
              </Marker>
            ))} */}
          </MapContainer>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <ColorRing
            visible={true}
            height="50"
            width="50"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
          />
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import data from "./data";
import { getAllDimensions } from "../../service/pivotTable";

export default function PivotExpermental() {
  const [settings, setSettings] = useState({});
  const [cols, setCols] = useState([]);

  return (
    <div
      style={{
        position: "absolute",
        maxWidth: "85%",
        minHeight:"80%",
        maxHeight:"80%",
        overflow: "scroll",
      }}
    >
      <PivotTableUI
        data={data}
        onChange={(s) => setSettings(s)}
        cols={["year", "week","region","woreda"]}
        rows={["nameofhealthfacility", "hfsitename"]}
        vals={["paxtotal"]}
        aggregatorName="Integer Sum"
        rendererName="Table Heatmap"
        {...settings}
        hiddenAttributes={["nameofhealthfacility"]}
        hiddenFromAggregators={["nameofhealthfacility", "hfsitename"]}
        stringLocales={"fr"}
        rendererOptions={{
          table: {
            rowTotals: false,
            colTotals: false,
          },
        }}
      />
    </div>
  );
}

import axios from "axios";

async function getZones(zone:string) {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    
    url: `https://hed.k8s.sandboxaddis.com/LookUp/getZone/${zone}`,
    headers: {},
  };
  try {
    const response = await axios.request(config);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export default getZones;

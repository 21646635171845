import ChartHeader from "../../component/ChartHeader/chartHeader";
import CustomChart from "../../chart_types/CustomChart";
import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import modalStyle from "../../styles/modal";
import Chart, { ChartData } from "chart.js";
import Table from "../../Table/dataTable";
import getFAF from "../../service/functionalHealthFacilities";
import { ColorRing } from "react-loader-spinner";
import CustomChartProp from "../../models/CustomChart";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import Dataset from "../../models/Dataset";
import { TableColumn } from "react-data-table-component";

import { DataObject } from "@mui/icons-material";
import theme from "../../utils/theme";
import "./styles.css";
import getUnderFiveMalNutrition from "../../service/underFiveMalnutrition";
import { Backdrop } from "@mui/material";
import { getCurrentQuarter } from "../../utils/getCurrentQuarter";
import { FilterProps } from "../../utils/filterProp";
import exportData from "../../utils/exporttocsv";
const UnderFiveMalnutrition = (props:FilterProps) => {
  const [modalIsOpen, setModal] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [rawData,setRawData] = useState<any>({});
  const [fhfData, setFhfData] = useState<ChartData>({});
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumn<any>[]>([]);
  const [error, setError] = useState(false);

  const [tryAgain, setTryAgain] = useState(false);
  //todo: chartType has to be initialized by the type from the api.
  const [chartType, setChartType] = useState("line");

  function closeModal() {
    setModal(false);
  }

  const expandChart = () => {
    setModal(true);
  };

  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      console.log("under ctx");

      chartRef.current = new Chart(ctx, {
        type: "bar",
        data: fhfData,
        options: {
          responsive: true,
          scales: {
          
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Week",
                },
              },
            ],
          
          },
          plugins: {
            fill: false,
            legend: {
              display: false,
              labels: {
                color: "red",
                usePointStyle: true,
              },
            },
            scales: {
              x: {
                beginAtZero: true, // Customize as needed
              },
              y: {
                beginAtZero: true, // Customize as needed
              },
            },
            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  };
  async function fetchPropData() {
    setLoading(true);
    let datasets:any[] = [];
   
    try {
      // Process data here, set state variables, etc.
      let passedYear = props.years.join(',');
      let quarter = props.quarters.length !== 0 ? props.quarters.join(',') : '0';
      let months = props.months.length !== 0 ? props.months.join(',') : '0';
      let weeks = props.weeks.length !== 0 ? props.weeks.join(',') : '0';
      await getUnderFiveMalNutrition({year:passedYear, quarter:quarter,month:months, weeks:weeks,}).then((res: any) => {
        setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any; }) => {
          let dataObj = {
           label: element.label,
           backgroundColor: element.label === "MAM" ? theme.lightBlue : theme.secondaryChartColor,
         borderWidth: 1,
         pointStyle: "rectRot",
         pointRadius: 5,
         pointBorderColor: "black",
         data: element.data,
 
          };

          datasets.push(dataObj);
        });
        let dataObject = {
          labels: res.data.labels.map((elt:string)=>{
            return elt.replace('_','-');
          }),
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets:datasets
      };
   

        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  async function fetchData() {
    setLoading(true);
    let datasets:any[] = [];
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    try {
      // Process data here, set state variables, etc.
      await getUnderFiveMalNutrition({year:(currentYear-1).toString(), quarter:currentQuarter}).then((res: any) => {
        setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any; }) => {
          let dataObj = {
           label: element.label,
           backgroundColor: element.label === "MAM" ? theme.lightBlue : theme.secondaryChartColor,
         borderWidth: 1,
         pointStyle: "rectRot",
         pointRadius: 5,
         pointBorderColor: "black",
         data: element.data,
 
          };

          datasets.push(dataObj);
        });
        let dataObject = {
          labels: res.data.labels.map((elt:string)=>{
            return elt.replace('_','-');
          }),
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets:datasets
      };
   

        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    setError(false);

    fetchData();
  }, []);

  useEffect(() => {
   
    if(props.years.length !== 0)
        fetchPropData();
    props.months.toString();
    setError(false);
  }, [props]);

  useEffect(() => {
    setError(false);

    fetchData();
  }, [tryAgain]);
  return (
    <div>
     <Backdrop
          sx={{ color: '#fff', zIndex:10000, height:'100%', padding:'50px'}}
          open={modalIsOpen}
         
        >
          <div style={{height:'100%', width:'90%', alignSelf:'center', justifyContent:"center", alignContent:'center'}}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
     
          <FaTimesCircle
            size={20}
            color="#25476A"
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          />
        </div>
        {displayTable ? (
          <Table columns={tableColumns} rows={dataset} />
        ) : (
          <div>
              <ChartHeader
                  title={"Under Five Malnutrition"}
                  onExpand={expandChart}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  showTypes={false}
                  forExpanded={true}
                  onExport = {()=>exportData(rawData,'Under Five Malnutrition')}

                />
            <div className="self-center w-full">
              <canvas ref={canvasCallback}    style={{ width: "80%", height:'50vh' }}></canvas>
            </div>
        
          </div>
        )}
        </div>
      </Backdrop>
      <>
        {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  title={"Under Five Malnutrition"}
                  onExpand={expandChart}
                  onExport = {()=>exportData(rawData,'Under Five Malnutrition')}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  showTypes={false}
                />
                <div>
                  <div className="self-center w-full">
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "80%" }}
                    ></canvas>
                  </div>
                </div>
             
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default UnderFiveMalnutrition;

import ChartHeader from "../../component/ChartHeader/chartHeader";
import CustomChart from "../../chart_types/CustomChart";
import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import modalStyle from "../../styles/modal";
import Chart, { ChartData } from "chart.js";
import Table from "../../Table/dataTable";
import getFAF from "../../service/functionalHealthFacilities";
import { ColorRing } from "react-loader-spinner";
import CustomChartProp from "../../models/CustomChart";
import { FaFilter, FaExpand, FaTimesCircle, FaTable } from "react-icons/fa";
import Dataset from "../../models/Dataset";
import { TableColumn } from "react-data-table-component";

import { DataObject } from "@mui/icons-material";
import theme from "../../utils/theme";
import "./styles.css";
import { getCurrentQuarter } from "../../utils/getCurrentQuarter";
import getMaleriaReport from "../../service/maleriaReport";
import { FilterProps } from "../../utils/filterProp";
const MalariaCasesAndOPDAttendants = (props:FilterProps) => {
  const [modalIsOpen, setModal] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [fhfData, setFhfData] = useState<ChartData>({
    // These labels appear in the legend and in the tooltips when hovering different arcs
  });
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumn<any>[]>([]);
  const [error, setError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  //todo: chartType has to be initialized by the type from the api.
  const [chartType, setChartType] = useState("line");
  const [rawData, setRawData] = useState<any>({});

  function closeModal() {
    setModal(false);
  }

  const expandChart = () => {
    setModal(true);
  };

  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      chartRef.current = new Chart(ctx, {
        type: "bar",
        data: fhfData,
        options: {
          responsive: true,
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Week",
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Malaria Cases",
                },
              },
            ],
          },
          plugins: {
            fill: true,
            legend: {
              display: false,
              labels: {
                color: "red",
                usePointStyle: true,
              },
            },

            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  };

  async function fetchPropData() {
    setLoading(true);
    let datasets: any[] = [];
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    try {
      // Process data here, set state variables, etc.
      let passedYear = props.years.join(',');
      let quarter = props.quarters.length !== 0 ? props.quarters.join(',') : '0';
      let months = props.months.length !== 0 ? props.months.join(',') : '0';
      let weeks = props.weeks.length !== 0 ? props.weeks.join(',') : '0';
      await getMaleriaReport({year:passedYear, quarter:quarter,month:months, weeks:weeks,}).then((res: any) => {
        setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any }) => {
          let dataObj = {
            type: element.label === "Opd Attendant" ? "line" : "bar",
            label: element.label,
            fill: element.label === "Opd Attendant" ? false : true,
            borderColor:
              element.label === "Opd Attendant"
                ? theme.lightBlue
                : "transparent",
            backgroundColor:
              element.label === "Opd Attendant"
                ? "transparent"
                : theme.secondaryChartColor,
            borderWidth: 2,
            pointStyle: "circle",
            pointRadius: 4,
            data: element.data,
          };

          datasets.push(dataObj);
        });

        datasets.sort((a, b) => {
          if (a.type === "line") {
            return -1;
          } else if (b.type === "line") {
            return 1;
          } else {
            return 0;
          }
        });
        let dataObject = {
          labels: res.data.labels,
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets: datasets,
        };

        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }

  async function fetchData() {
    setLoading(true);
    let datasets: any[] = [];
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    try {
      // Process data here, set state variables, etc.
      await getMaleriaReport({
        year: (currentYear - 1).toString(),
        quarter: currentQuarter,
      }).then((res: any) => {
        
        setRawData(res.data);
        res.data.datasets.forEach((element: { label: string; data: any }) => {
          let dataObj = {
            type: element.label === "Opd Attendant" ? "line" : "bar",
            label: element.label,
            fill: element.label === "Opd Attendant" ? false : true,
            borderColor:
              element.label === "Opd Attendant"
                ? theme.lightBlue
                : "transparent",
            backgroundColor:
              element.label === "Opd Attendant"
                ? "transparent"
                : theme.secondaryChartColor,
            borderWidth: 2,
            pointStyle: "circle",
            pointRadius: 4,
            data: element.data,
          };

          datasets.push(dataObj);
        });

        datasets.sort((a, b) => {
          if (a.type === "line") {
            return -1;
          } else if (b.type === "line") {
            return 1;
          } else {
            return 0;
          }
        });
        let dataObject = {
          labels: res.data.labels,
          borderColor: theme.primaryChartColor,
          backgroundColor: theme.primaryChartColor,
          borderWidth: 1,
          datasets: datasets,
        };

        // console.log("filterdfhf, ", dataObject);
        setFhfData(dataObject);
        setChartType(res.data.type);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    setError(false);

    fetchData();
  }, []);

  useEffect(() => {
   
    if(props.years.length !== 0)
        fetchPropData();
    props.months.toString();
    setError(false);
  }, [props]);
  useEffect(() => {
    setError(false);

    fetchData();
  }, [tryAgain]);
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyle}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <button
            style={{
              backgroundColor: "#25476A",
              border: "1px solid #25476A",
              margin: "5px",
              borderRadius: "3px",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setDisplayTable(!displayTable);
            }}
          >
            <FaTable size={16} color="#f3f3f3" style={{ cursor: "pointer" }} />
            <p style={{ color: "white", marginLeft: 3 }}>Table View</p>
          </button>
          <FaTimesCircle
            size={20}
            color="#25476A"
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          />
        </div>
        {displayTable ? (
          <Table columns={tableColumns} rows={dataset} />
        ) : (
          <div>
            <div className="self-center w-full">
              <canvas ref={canvasCallback} style={{ width: "80%" }}></canvas>
            </div>
          </div>
        )}
      </Modal>
      <>
        {loading ? (
          <div className="loadingContainer">
            <ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#25476A", "#25476A", "#25476A", "#25476A", "#25476A"]}
            />
          </div>
        ) : (
          <div>
            {error ? (
              <div className="loadingContainer">
                <p className="errorMessage">Error fetching data</p>
                <p
                  className="tryAgain"
                  onClick={() => {
                    setTryAgain(!tryAgain);
                  }}
                >
                  Try Again!
                </p>
              </div>
            ) : (
              <div>
                <ChartHeader
                  title={"Malaria Cases and OPD Attendants"}
                  onExpand={expandChart}
                  type={"bar"}
                  onChangeChartType={(newType) => {
                    setChartType(newType);
                  }}
                  showTypes={false}
                />
                <div>
                  <div className=" w-full" style={{ flex: 1, height: "100%" }}>
                    <canvas
                      ref={canvasCallback}
                      style={{ width: "60%" }}
                    ></canvas>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default MalariaCasesAndOPDAttendants;
